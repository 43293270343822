import { v4 as uuidv4 } from 'uuid';

const skipVad = false;
const noInputTimeout = 10000;
const speechCompleteTimeout = 500;
const frameLength = 250;

export const META = () => ({
  access_token: 'random-access-token',
  phone: 'random-phone-number',
  app_id: 'random-app-id',
  session_id: `random-session-${uuidv4()}`,
  message_id: `random-message-${uuidv4()}`,
});

// eslint-disable-next-line camelcase
export const CONFIG = (session_id) => ({
  specification: {
    model: 'Wav2vec2',
    record: false,
    partial_results: true,
    silence_aware: true,
    single_utterance: false,
    interim_results: true,
    config_audio: {
      // encoding: 1,
      // max_alternatives: 1,
      session_id,
      sample_rate_hertz: 48000,
      sample_size_byte: 2,
      channel: 1,
      model_param: {
        graph: 'street_name',
      },
      vad_params: {
        no_input_timeout_ms: noInputTimeout,
        speech_complete_timeout_ms: speechCompleteTimeout,
        frame_length_ms: frameLength,
        skip: skipVad,
      },
    },
    domain: 'debt',
    joint: true,
  },
});
