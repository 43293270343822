import { REQUEST_STATUS } from '@src/constants/voice';
import React from 'react';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import ErrorReportButton from '../ActionButtonNew/ErrorReportButton';
import SuccessButton from '../ActionButtonNew/SuccessButton';

const ActionButtonsNew = ({ request, audioRef, setCloseMoreMenu }) => {
  const { displayStatus } = useRequestAnimationStatus(request);

  if (!request.id) return <></>;

  switch (displayStatus) {
    case REQUEST_STATUS.FAILURE:
      return <ErrorReportButton request={request} />;

    case REQUEST_STATUS.SUCCESS:
      // TODO
      return (
        <SuccessButton
          request={request}
          audioRef={audioRef}
          setCloseMoreMenu={setCloseMoreMenu}
        />
      );

    default:
      return <div />;
  }
};

export default ActionButtonsNew;
