import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  getPackageDuration,
  getPackageTypeFromCode,
} from '@src/services/package';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { PACKAGE_TYPE } from '@src/constants/package';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import CountdownBanner from './CountdownInBanner';
import { StyledHeaderBanner, StyledCountdownInBanner } from './index.style';

const HeaderBanner = ({ headerVoucher, isAgreeToTerm }) => {
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = user;
  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const useFeatureFakeEndTime = getFeatureValue(
    FEATURE_KEYS.FAKE_END_TIME_VOUCHER,
    {
      userId,
      email,
      phoneNumber,
    },
  );
  const history = useHistory();
  const isDubbingRoute = useCheckDubbingRoute();
  const duaration = ['MONTH', 'QUARTER', 'YEAR'];
  const redirectUrl = headerVoucher?.headerBannerInfo?.redirectUrl;
  const listPackages = headerVoucher?.policy?.packages || [];
  const isApplyAllPackages = headerVoucher?.policy?.applyAllPackages;
  const sortPackagesInVoucher = (voucher) => {
    const comparator = (a, b) => {
      const aIndex = duaration.findIndex((keyword) => a.includes(keyword));
      const bIndex = duaration.findIndex((keyword) => b.includes(keyword));
      return aIndex - bIndex;
    };

    const packageCodes =
      voucher?.policy?.packages?.map((pkg) => pkg.code) || [];

    const sortedArrayByDuration = packageCodes.sort(comparator);

    const grouped = { STUDIO: [], DUBBING: [] };

    // Group elements based on "STUDIO", "API", "DUBBING", "CLONING"
    sortedArrayByDuration.forEach((element) => {
      if (element.includes('DUBBING')) {
        grouped.DUBBING.unshift(element);
      } else if (
        element.includes('STUDIO') ||
        element.includes('API') ||
        element.includes('CLONING')
      ) {
        grouped.STUDIO.push(element);
      } else {
        grouped.STUDIO.push(element);
      }
    });

    // Sort grouped.STUDIO based on "STUDIO", "CLONING", "API"
    grouped.STUDIO.sort((a, b) => {
      if (a.includes('STUDIO')) return -1;
      if (b.includes('STUDIO')) return 1;
      if (a.includes('CLONING')) return -1;
      if (b.includes('CLONING')) return 1;
      if (a.includes('API')) return -1;
      if (b.includes('API')) return 1;
      return 0;
    });

    return [{ STUDIO: grouped.STUDIO }, { DUBBING: grouped.DUBBING }];
  };

  const findCategoryElement = (sortedPackage) => {
    const dubbingObject = sortedPackage.find((obj) => obj.DUBBING);
    const listDubbingPackage = dubbingObject.DUBBING;
    if (isDubbingRoute) {
      if (listDubbingPackage?.length > 0) {
        return { type: 'DUBBING', code: listDubbingPackage[0] };
      }
    }

    const studioObject = sortedPackage.find((obj) => obj.STUDIO);
    const listStudioPackage = studioObject.STUDIO;
    if (listStudioPackage?.length > 0) {
      return {
        type: getPackageTypeFromCode(listStudioPackage[0]),
        code: listStudioPackage[0],
      };
    }

    return { type: 'DUBBING', code: listDubbingPackage[0] };
  };

  const handleNavigateToPayment = (pkgType, pkgCode) => {
    if (pkgType === PACKAGE_TYPE.DUBBING) {
      history.push(
        `/dubbing/payments?package=${pkgCode}&voucherCode=${headerVoucher?.code}`,
      );
      return;
    }

    if (pkgType === PACKAGE_TYPE.STUDIO || pkgType === PACKAGE_TYPE.API) {
      history.push(
        `/studio/payments?package=${pkgCode}&voucherCode=${headerVoucher?.code}`,
      );
    }
  };

  const handleNavigate = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }
    if (isApplyAllPackages) {
      history.push(`/studio/payments`);
      return;
    }
    if (listPackages?.length === 1) {
      const packageType = getPackageTypeFromCode(listPackages[0].code);
      handleNavigateToPayment(packageType, listPackages[0].code);
      return;
    }
    const sortedPackages = sortPackagesInVoucher(headerVoucher);
    const { type, code } = findCategoryElement(sortedPackages);
    const packageType = getPackageTypeFromCode(code);
    const duration = getPackageDuration(code);

    if (type === PACKAGE_TYPE.DUBBING) {
      history.push(
        `/dubbing/payments?packageType=${packageType}&duration=${duration}`,
      );
      return;
    }
    history.push(
      `/studio/payments?packageType=${packageType}&duration=${duration}`,
    );
  };

  return (
    <StyledHeaderBanner
      useVoucherByProduct={useVoucherByProduct}
      isAgreeToTerm={isAgreeToTerm}
      redirectUrl={redirectUrl}
      onClick={handleNavigate}
    >
      <img
        className="banner-image"
        src={headerVoucher?.headerBannerInfo?.imageUrl}
        alt="header-flash-sale"
      />
      <div className="banner-information">
        <div className="empty-div" />
        <div className="banner-content">
          {headerVoucher?.headerBannerInfo?.content || headerVoucher?.title?.vi}
        </div>
        <StyledCountdownInBanner isHeader>
          <CountdownBanner
            date={
              useFeatureFakeEndTime
                ? new Date(headerVoucher?.fakeEndTimeForVoucher).getTime() + 100
                : new Date(headerVoucher?.endTime).getTime() + 100
            }
          />
        </StyledCountdownInBanner>
      </div>
    </StyledHeaderBanner>
  );
};

export default HeaderBanner;
