import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';
import AffiliateBackground from '@src/assets/images/affiliate-resource-background.png';
import { Typography } from '@mui/material';

export const AffiliateDetailStyled = styled('div')`
  .stats {
    display: flex;
    width: 100%;
    gap: 20px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .statistic-and-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const CommissionBoxStyled = styled('div')`
  .commission-box {
    max-width: 382px;
    background-color: ${COLOR.white};
    .commission {
      background-color: ${COLOR.white};
      color: ${COLOR.darkBlue};
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: ${BORDER_RADIUS};
      .title {
        font-size: 16px;
      }

      .current-commission {
        font-size: 40px;
        font-weight: 600;
      }

      .break-line {
        height: 1px;
        background-color: ${COLOR.gray};
        margin: 12px 0;
      }

      .received-commission {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .button-commission {
      background-color: ${COLOR.white};
    }

    .note {
      display: flex;
      align-items: center;
      padding: 12px;
      color: ${COLOR.darkBlue};
      gap: 8px;
      .text-note {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`;

export const StatisticsBoxStyled = styled('div')`
  width: 100%;

  .stats-box {
    display: flex;
    justify-content: space-between;
    padding: 12px;

    @media (max-width: 460px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .stats-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .stats-title {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .stats-value {
      font-size: 40px;
      font-weight: 600;
    }
  }
`;

export const LinkBoxStyled = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .link-box {
    padding: 12px;
    color: ${COLOR.dark};
  }
  .title-link {
    font-size: 24px;
    font-weight: 600;
  }

  .title-link-description {
    font-size: 16px;
    font-weight: 400;
  }

  .title-link-note {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
  }

  .link-affiliate {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
  }

  .text-link {
    width: 100%;
    background-color: ${COLOR.white};
  }

  .button-copy {
    width: 200px;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }
    @media (max-width: 460px) {
      font-size: 8px;
    }
  }
`;

export const CopyButtonStyled = styled('div')``;

export const CommissionStyled = styled('div')`
  color: ${(props) => COLOR[props.color] || COLOR.success};
  font-weight: 600;
  text-transform: uppercase;
  width: 120px;
  display: flex;
  justify-content: flex-end;
`;

export const OrdersListStyled = styled('div')`
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 12px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .filter-box {
    display: flex;

    .filter-package {
      width: 160px;
      margin-right: 12px;
    }

    .filter-date {
      display: flex;
    }

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const ResourceStyled = styled('div')`
  .background {
    margin-top: 20px;
    background-image: url(${AffiliateBackground});
    background-repeat: no-repeat;
    background-size: cover;
    height: 380px;
    width: 100%;
    display: flex;
  }

  .image-woman-container {
    display: flex;
    align-items: flex-end;
  }

  .image-woman {
    height: 90%;
    margin-left: 20px;

    @media (max-width: 1440px) {
      height: 60%;
    }
    @media (max-width: 1024px) {
      height: 60%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .image-icon {
    margin-left: 20px;

    @media (max-width: 1280px) {
      height: 20%;
    }
    @media (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .content {
    width: 40%;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;

    .title {
      font-size: 42px;
      font-weight: 600;

      @media (max-width: 1440px) {
        font-size: 28px;
      }
    }

    .description {
      margin: 20px 0px;
      font-size: 16px;
      font-weight: 400;

      @media (max-width: 1280px) {
        font-size: 16px;
      }
    }

    .button-group {
      display: flex;
      justify-content: center;
      gap: 20px;

      .button {
        font-size: 12px;
      }
      @media (max-width: 1280px) {
        flex-direction: column;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const PaymentRequestStyled = styled('div')`
  .input-control {
    margin-bottom: 15px;
  }

  .red {
    font-size: 16px;
    font-weight: 600;
    color: ${COLOR.error};
  }

  .send-request-button {
    margin-top: 20px;
    width: 100%;
  }
`;

export const StyledChip = styled(Typography)`
  background-color: ${({ color }) => TRANSPARENT_COLOR[color]};
  color: ${({ color }) => COLOR[color]};
  text-align: center;
  padding: 1px 10px;
  width: 60px;
  border-radius: 17px;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
`;
