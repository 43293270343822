import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import { CancelRounded } from '@mui/icons-material';
import { COLOR } from '@src/styles/color';
import apis from '@src/apis';
import { StyledButton, StyledDialog } from './index.style';

const DeleteRequestDialog = ({ open, onClose, request }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteRequest = async () => {
    try {
      const data = await apis.requests.deleteRequest({
        requestIds: [request.id],
      });
      if (!data?.status) {
        dispatch(
          actions.noti.push({
            severity: 'error',
            message: 'deleteRequestFailure',
          }),
        );
        return;
      }
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'deleteRequestSuccess',
        }),
      );
      // TODO: update request list
      onClose();
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: error?.response?.data?.error_message,
        }),
      );
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className="wrap-title">
        <Box className="title">
          <Typography className="text-title">{t('deleteFile')}</Typography>
        </Box>
        <CancelRounded sx={{ color: COLOR.darkV2[32] }} onClick={onClose} />
      </DialogTitle>
      <Divider />
      <DialogContent className="wrap-content">
        <Typography className="desc">
          {t('deleteFileDescription', { title: request.title })}
        </Typography>
      </DialogContent>
      <DialogActions className="wrap-actions">
        <StyledButton
          variant="outlined"
          onClick={handleDeleteRequest}
          borderColor={COLOR.indigo[32]}
          colorText="#FF3B30"
        >
          {t('delete')}
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={onClose}
          bgrDisble={COLOR.yellow[32]}
          colorDisable="#3c164e99"
        >
          {t('cancel')}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default DeleteRequestDialog;
