import { all, put, takeLatest } from 'redux-saga/effects';
import apis from '@src/apis';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import { convertTextToParagraphs } from '@src/services/paragraph';
import actions from '../actions';

function* getFeatureFlagsSaga() {
  try {
    const featureFlags = yield apis.featureFlags.getFeatureFlags();
    yield put(actions.featureFlag.getFeatureFlagsSuccess(featureFlags));

    const advanceFeature = featureFlags.find(
      (featureFlag) => featureFlag.name === FEATURE_FLAG.ADVANCE_TTS,
    );
    if (advanceFeature) {
      const newParagraphs = convertTextToParagraphs('');
      yield put(actions.synthesisRequest.updateParagraphs(newParagraphs));
      yield put(
        actions.synthesisRequest.switchAdvanceFeature(advanceFeature.active),
      );
    }
  } catch (error) {
    throw new Error();
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.featureFlag.actionTypes.GET_FEATURE_FLAGS,
      getFeatureFlagsSaga,
    ),
  ]);
}
