import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Typography } from '@mui/material';
import Dropzone from '@src/components/Dropzone';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { isValidFile } from '@src/utils/checkValid';
import {
  DUB_FILE_FORMAT,
  INPUT_FILE_TYPES,
  MAX_FILE_NAME_LENGTH,
  SRT_FRANC_LANGUAGE_CODE,
} from '@src/constants/dubbing';
import { validateSubtitleFile } from '@src/services/dubbing';
import apis from '@src/apis';
import { LANGUAGE } from '@src/constants';
import DefaultDropzoneContent from './DefaultDropzoneContent';
import { StyledUploadSubtitleFile } from './index.style';
import SuccessDropzoneContent from './SuccessDropzoneContent';
import FailedDropzoneContent from './FailedDropzoneContent';
import YoutubeContent from './YoutubeContent';

const UPLOAD_STATUS = {
  INIT: 'INIT',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const UploadSubtitleFile = ({
  onResetFile,
  resetRef,
  subtitleFile,
  setSubtitleFile,
  title,
  setTitle,
  setIsDisableContinue,
  setLinkVideoYoutubeSelected,
  originalLanguage,
  setOriginalLanguage,
  targetLanguage,
  setTargetLanguage,
  loadingHandleFile,
  setLoadingHandleFile,
  languageError,
  setLanguageError,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVietNameseLanguage = language === LANGUAGE.VN;
  const fileInputRef = useRef(null);
  const { getFeatureValue } = useFeatureFlags();
  const [status, setStatus] = useState(UPLOAD_STATUS.INIT); // ['INIT', 'SUCCESS', 'FAILED']
  const [error, setError] = useState();
  const [youtubeVideoMetadata, setYoutubeVideoMetadata] = useState({});
  const [availableLanguages, setAvailableLanguages] = useState([]); // [{ value, country, label, flag }]

  const { user } = useSelector((state) => state.auth);

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const isMultipleLanguagesSrt = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_LANGUAGES_SRT,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const dubbingFileFormat = isMultipleInputDubbing
    ? [...DUB_FILE_FORMAT, 'mp4']
    : DUB_FILE_FORMAT;

  const fileFormat = dubbingFileFormat?.map((type) => `.${type}`)?.join(', ');

  const handleResetFile = () => {
    onResetFile();
    setSubtitleFile(null);
    setError();
    setTitle('');
    setYoutubeVideoMetadata({});
    setLinkVideoYoutubeSelected('');
    setStatus(UPLOAD_STATUS.INIT);
    setOriginalLanguage('');
    setLanguageError(false);
  };

  const handleSelectFile = (e) => {
    const { files } = e.target;
    if (files[0]) setSubtitleFile(files[0]);
  };

  // Process with input is srt file
  const handleWithSrtFile = async (file) => {
    try {
      setLoadingHandleFile(true);

      // Validate srt file
      const {
        detectLanguage,
        error: isError,
        message,
        detail,
      } = await validateSubtitleFile(file, isMultipleLanguagesSrt);

      if (isError) {
        const err = new Error(message);
        err.detail = detail;
        throw err;
      }

      if (isMultipleInputDubbing && !isMultipleLanguagesSrt)
        setOriginalLanguage('vi-VN');

      if (isMultipleLanguagesSrt)
        setOriginalLanguage(SRT_FRANC_LANGUAGE_CODE[detectLanguage]);

      setStatus(UPLOAD_STATUS.SUCCESS);
      if (!title || title.trim().length === 0) {
        const fileName = file.name
          .replace('.srt', '')
          .slice(0, MAX_FILE_NAME_LENGTH);
        setTitle(fileName);
      }

      setError();
      setLoadingHandleFile(false);
      setIsDisableContinue(false);
    } catch (err) {
      setLoadingHandleFile(false);
      setError({ message: err.message, detail: err.detail });
      setStatus(UPLOAD_STATUS.FAILED);
      setIsDisableContinue(true);
    }
  };

  const handleWithMp4File = async (file) => {
    setLoadingHandleFile(true);

    setStatus(UPLOAD_STATUS.SUCCESS);
    if (!title || title.trim().length === 0) {
      const fileName = file.name
        .replace('.mp4', '')
        .slice(0, MAX_FILE_NAME_LENGTH);
      setTitle(fileName);
    }

    setError();
    setLoadingHandleFile(false);
    setIsDisableContinue(false);
  };

  // Handle change subtitle file
  const handleChangeSubtitleFile = async (file) => {
    if (!file) {
      setStatus(UPLOAD_STATUS.INIT);
      return;
    }
    if (isValidFile(file.name, INPUT_FILE_TYPES.SRT)) {
      handleWithSrtFile(file);
      return;
    }

    if (isValidFile(file.name, INPUT_FILE_TYPES.MP4)) {
      handleWithMp4File(file);
      return;
    }

    setStatus(UPLOAD_STATUS.FAILED);
    setIsDisableContinue(true);
    setError({
      message: t('fileFormatError', { fileType: fileFormat }),
    });
  };

  const handleWithYoutubeLink = (videoMetadata, videoUrl) => {
    setTitle(videoMetadata.title.slice(0, MAX_FILE_NAME_LENGTH));
    setYoutubeVideoMetadata(videoMetadata);
    setSubtitleFile(null);
    setLinkVideoYoutubeSelected(videoUrl);
    setStatus(UPLOAD_STATUS.SUCCESS);
    setError();
    setIsDisableContinue(false);
  };

  const fetchLanguages = async () => {
    const res = await apis.dubbing.getLanguages({});

    if (res?.status) {
      const totalLanguages = res.result.languages
        .filter((lang) => lang.available)
        .sort((a, b) => a.rank - b.rank)
        .map((lang) => ({
          value: lang.code,
          country: lang.code,
          label: isVietNameseLanguage ? lang.vietnameseName : lang.globalName,
          flag: lang.roundImage,
        }));

      setAvailableLanguages(totalLanguages);
    }
  };

  // Render dropzone content based on status
  const renderDropzoneContent = () => {
    switch (status) {
      case UPLOAD_STATUS.INIT:
        return <DefaultDropzoneContent fileFormat={fileFormat} />;
      case UPLOAD_STATUS.SUCCESS:
        return (
          <SuccessDropzoneContent
            fileName={subtitleFile?.name || youtubeVideoMetadata?.title}
            fileFormat={fileFormat}
            handleResetFile={handleResetFile}
            loadingHandleFile={loadingHandleFile}
            youtubeVideoMetadata={youtubeVideoMetadata}
            languages={availableLanguages}
            originalLanguage={originalLanguage}
            setOriginalLanguage={setOriginalLanguage}
            targetLanguage={targetLanguage}
            setTargetLanguage={setTargetLanguage}
            languageError={languageError}
            setLanguageError={setLanguageError}
          />
        );
      case UPLOAD_STATUS.FAILED:
        return (
          <FailedDropzoneContent
            fileName={subtitleFile?.name}
            error={error}
            fileFormat={fileFormat}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  // Process when user select file
  useEffect(() => {
    handleChangeSubtitleFile(subtitleFile);
  }, [subtitleFile]);

  // Reset input value when user upload same file
  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (!fileInput) return () => {};

    // Reset input value to fix bug when user upload same file
    const handleResetInputValue = () => {
      fileInput.value = null;
    };
    fileInput.addEventListener('click', handleResetInputValue);
    return () => {
      fileInput.removeEventListener('click', handleResetInputValue);
    };
  }, [fileInputRef]);

  return (
    <StyledUploadSubtitleFile
      isSuccess={status === UPLOAD_STATUS.SUCCESS}
      isShowFileInformation={status === UPLOAD_STATUS.SUCCESS}
    >
      <Button
        ref={resetRef}
        sx={{ display: 'none' }}
        onClick={handleResetFile}
      />
      <Dropzone
        onDropFiles={(files) => {
          handleResetFile();
          setSubtitleFile(files[0]);
        }}
      >
        <Box className="dropzone">
          <div
            role="button"
            onClick={() => fileInputRef.current.click()}
            tabIndex={0}
            className="dropzone-content"
          >
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              accept={dubbingFileFormat?.map((type) => `.${type}`)?.join(',')}
              onChange={handleSelectFile}
            />
            {renderDropzoneContent()}
          </div>
          {isMultipleInputDubbing && status !== UPLOAD_STATUS.SUCCESS && (
            <>
              <Box className="divider">
                <Divider className="divider-line" />
                <Typography className="divider-text">{t('or')}</Typography>
                <Divider className="divider-line" />
              </Box>
              <YoutubeContent handleWithYoutubeLink={handleWithYoutubeLink} />
            </>
          )}
        </Box>
      </Dropzone>
    </StyledUploadSubtitleFile>
  );
};

export default UploadSubtitleFile;
