import jwt from 'jsonwebtoken';
import api from '@src/apis/api';
import apis from '@src/apis';
import { LANDING_PAGE_URL } from '@src/configs';
import { setCookie } from '@src/utils/cookie';

const handleReceivingTokens = (tokens) => {
  if (!tokens.token) return;
  api.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;

  setCookie({ cname: 'accessToken', cvalue: tokens.token });
  setCookie({ cname: 'refreshToken', cvalue: tokens.refreshToken });
};

const handleEvent = (event) => {
  const redirectedEvents = [
    'onInitError',
    'onAuthRefreshError',
    'onAuthLogout',
  ];
  if (redirectedEvents.includes(event))
    window.location.assign(LANDING_PAGE_URL);
};

const validateKcToken = async (accessToken, refreshToken, idToken) => {
  if (!accessToken || !refreshToken || !idToken) {
    return false;
  }
  try {
    const publicKey = await apis.keycloak.getKeycloakPublicKey();
    const isValidToken = jwt.verify(accessToken, publicKey);
    return isValidToken;
  } catch (err) {
    return false;
  }
};

export { handleReceivingTokens, handleEvent, validateKcToken };
