import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledPaymentHistory = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 36px;
  gap: 0 16px;

  .title {
    text-transform: uppercase;
    font-weight: 600;
  }

  .table-wrapper {
    width: 98%;
    height: 100%;
    background: white;
    padding: 16px;
    border-radius: 16px;

    .filter-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 8px;
      margin-bottom: 16px;

      @media (max-width: 850px) {
        display: block;
      }

      .input-label {
        font-weight: 500;
        width: fit-content;
        font-size: 14px;
        white-space: nowrap;
      }
    }

    .text-field {
      background-color: ${COLOR.white};
      height: fit-content;
      border-radius: 100px;
      flex-grow: 1;
      max-width: 200px;

      @media (max-width: 530px) {
        width: 100%;
      }

      @media (max-width: 850px) {
        margin-bottom: 16px;
        margin-right: 16px;
        width: 100%;
      }

      .MuiInputLabel-root {
        font-size: 14px;
      }

      .MuiOutlinedInput-root {
        border-radius: 100px;
      }
    }

    .refresh-icon {
      cursor: pointer;
    }

    .text-light-body-cell {
      color: ${COLOR.dark};
      font-size: 14px;
    }

    .text-bold-body-cell {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .header-payment {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .title {
      font-size: 24px;
      font-weight: 700;
      color: ${COLOR.indigo.default};
      line-height: 28px;
    }

    .icon {
      display: flex;
    }

    .icon-button {
      display: none;
    }

    .back-to-package {
      font-size: 16px;
      text-transform: none;
      padding: 8px 12px 8px 12px;
      border-radius: 100px;
      background-color: ${COLOR.white};
      position: absolute;
      left: 0;

      :hover {
        background-color: ${COLOR.black[16]};
      }
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  @media (max-width: 650px) {
    .header-payment {
      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .icon {
        display: none;
      }
    }
  }

  .using-package-list {
    display: grid;
    gap: 16px;
    width: 100%;
    grid-template-rows: auto;
  }

  @media (max-width: 900px) {
    .using-package-list {
      grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
  }

  @media (min-width: 900px) and (max-width: 1500px) {
    .using-package-list {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
  }

  @media (min-width: 1500px) {
    .using-package-list {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }
  }

  .pkgNameWrapper {
    display: flex;
    align-items: center;

    .typeOfExpire {
      padding: 4px 10px;
      background: rgba(30, 22, 78, 0.08);
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1e164e;
      margin-left: 8px;
    }
  }

  .price-wrapper {
    display: flex;
    align-items: center;
  }

  .vnd {
    font-size: 14px;
    text-decoration: underline;
  }
`;

export const StyledChip = styled(Typography)`
  display: block;
  background-color: ${({ color }) => TRANSPARENT_COLOR[color]};
  color: ${({ color }) => COLOR[color]};
  padding: 1px 10px;
  border-radius: 17px;
  font-size: 13px;
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
  margin: auto;
`;

export const StyledUsingPackage = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border-radius: 16px;
  background-color: ${COLOR.white};
  height: calc(100% - 32px);

  .package-name {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid ${COLOR.label[16]};
    height: 50px;

    .logo {
      width: 133px;
      height: 40px;
    }

    .package-name-text {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: ${COLOR.black[80]};
      }

      .auto-renewal {
        display: flex;
        width: fit-content;
        padding: 4px 10px;
        border-radius: 16px;
        background-color: ${COLOR.indigo[8]};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: ${COLOR.indigo[100]};
      }
    }
  }

  .margin-bottom-8 {
    margin-bottom: 8px !important;
  }

  .margin-right-2 {
    margin-right: 2px !important;
  }

  .text-16 {
    font-size: 16px !important;
  }

  .underline {
    text-decoration: underline;
  }

  .package-info-item {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .expire-date {
      color: ${COLOR.red} !important;
    }

    .font-weight-400 {
      font-weight: 400 !important;
    }

    .label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${COLOR.black[80]};
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${COLOR.black[80]};
    }

    .discount-unit-price {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.lightText};
      text-transform: lowercase;
    }
  }

  .cancel-auto-renewal-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 12px;
    text-transform: none;
    color: ${COLOR.indigo.default};
    border: 1px solid ${COLOR.indigo[32]};
  }

  .buy-more-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 12px;
    text-transform: none;
    background-color: ${COLOR.yellow.default};
  }

  .collapse {
    ${(props) =>
      props.showdetail && {
        borderBottom: `1px solid ${COLOR.label[16]}`,
        marginTop: '16px',
      }}
  }
`;
