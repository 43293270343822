import { all, put, takeLatest } from 'redux-saga/effects';
import apis from '@src/apis';
import actions from '../actions';

function* getDubbingVoicesSaga() {
  const { status, result } = yield apis.voices.getVoices({
    hasDubbing: true,
    active: true,
  });
  if (!status) {
    // eslint-disable-next-line no-console
    console.error('Cannot get dubbing voices');
    return;
  }

  yield put(actions.voice.getDubbingVoicesSuccess(result?.voices || []));
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.voice.actionTypes.GET_DUBBING_VOICES,
      getDubbingVoicesSaga,
    ),
  ]);
}
