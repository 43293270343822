import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import moment from 'moment';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import {
  ArrowBack,
  ArrowDropDown,
  ArrowDropUp,
  Code,
  FolderOpen,
  HelpOutlineOutlined,
  Launch,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getCollapseStates,
  getCollapseInitialState,
} from '@src/services/sidebar';
import {
  DOCUMENT_LINK,
  FAQ_LINK,
  HOME_PAGE_URL,
  VBEE_CONSOLE_URL,
} from '@src/configs';
import ROUTES from '@src/constants/route';
import appRoutes from '@src/router/appRoutes';
import logo from '@src/assets/logo/logo-v2.svg';
import vbeeGrayIcon from '@src/assets/icons/vbee-icon-gray.svg';
import vbeeWhiteIcon from '@src/assets/icons/vbee-icon-white.svg';
import videoTutorialImg from '@src/assets/images/video-tutorial.svg';

import FEATURE_FLAG from '@src/constants/featureFlags.json';
import { BANNER_TYPE } from '@src/constants/banner';
import actions from '@src/redux/actions';
import { getPackageName } from '@src/services/package';
import { delimitNumber } from '@src/utils/number';
import { COLOR } from '@src/styles/color';

import Feature from '@src/components/Feature';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { LANGUAGE } from '@src/constants';
import logoDubbing from '@src/assets/logo/logo-dubbing-en-v3.svg';
import logoDubbingVi from '@src/assets/logo/logo-dubbing-vi-v3.svg';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledSidebar, StyledDrawer, StyledBetaWrapper } from './index.style';
import SidebarImgIcon from './Icon';

const Logo = () => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { language } = useTranslation().i18n;
  let logoSrc = logo;
  if (isDubbingRoute)
    logoSrc = language === LANGUAGE.VN ? logoDubbingVi : logoDubbing;
  return (
    <div className="logo">
      <img
        src={logoSrc}
        alt="vbee tts logo"
        className="logo-img"
        onClick={() => window.location.assign(HOME_PAGE_URL)}
      />
    </div>
  );
};

const PackageInfo = () => {
  const { t, i18n } = useTranslation();
  const isDubbingRoute = useCheckDubbingRoute();

  const { user } = useSelector((state) => state.auth);
  const { usingPackage, lastExpiredOrder } = useSelector((state) => state.user);
  const { remainingCharacters, lockCharacters, bonusCharacters } = useSelector(
    (state) => state.auth.user,
  );

  const isExpiredPackage =
    !usingPackage.id ||
    (user.packageExpiryDate && moment().isAfter(user.packageExpiryDate));

  const { language } = i18n;
  const { getFeatureValue } = useFeatureFlags();
  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const packageName = usingPackage.id
    ? getPackageName(usingPackage.name, usingPackage.code, language)
    : getPackageName(null, user.packageCode, language);

  const preservationExpiryDate = isDubbingRoute
    ? user?.dubbing?.preservationExpiryDate
    : user.preservationExpiryDate;

  const renderPackageExpired = () => {
    if (preservationExpiryDate && usePreservationExpiryDate)
      return moment(preservationExpiryDate).format('DD/MM/YYYY - HH:mm');

    if (user.packageExpiryDate)
      return moment(user.packageExpiryDate)
        .add(30, 'days')
        .format('DD/MM/YYYY - HH:mm');

    if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      return moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days')
        .format('DD/MM/YYYY - HH:mm');
    }

    return '';
  };

  return (
    <div className="sidebar-package-info">
      <div className="package-item-wrapper">
        <Typography className="text">{t('package')}:</Typography>
        <Typography className="value">{packageName}</Typography>
      </div>
      <div className="package-item-wrapper">
        <Typography className={`text ${isExpiredPackage && 'error-text'}`}>
          {t('expiryDate')}:
        </Typography>
        <Typography className={`value ${isExpiredPackage && 'error-text'}`}>
          {(user.packageExpiryDate &&
            moment(user.packageExpiryDate).format('DD/MM/YYYY - HH:mm')) ||
            (!user.packageExpiryDate && user.packageCode && t('endless'))}
        </Typography>
      </div>
      {remainingCharacters > 0 && (
        <div className="package-item-wrapper">
          <Typography className="text">{t('paidCharacters')}:</Typography>
          <Typography className="value">
            {delimitNumber(remainingCharacters, language)}
          </Typography>
        </div>
      )}
      {bonusCharacters > 0 && (
        <div className="package-item-wrapper">
          <Typography className="text">{t('freeCharacters')}:</Typography>
          <Typography className="value">
            {delimitNumber(bonusCharacters, language)}
          </Typography>
        </div>
      )}
      {lockCharacters > 0 && (
        <div className="package-item-wrapper">
          <Typography className="text error-text">
            {t('lockCharacters')}:
          </Typography>
          <Typography className="value error-text">
            {delimitNumber(lockCharacters, language)}
          </Typography>
        </div>
      )}
      {lockCharacters > 0 && (
        <div className="package-item-wrapper">
          <Typography className="text error-text">
            {t('preservedCharactersDate')}:
          </Typography>
          <Typography className="value error-text">
            {renderPackageExpired()}
          </Typography>
        </div>
      )}
    </div>
  );
};

const Sidebar = ({ openSidebar, handleCloseSidebar }) => {
  const [collapseState, setCollapseState] = useState({});

  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setCollapseState(getCollapseStates(appRoutes));
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    rootElement.removeAttribute('aria-hidden');
  }, []);

  const handlePlayVideoTutorial = () =>
    dispatch(
      actions.banner.updateDisplayBanner(BANNER_TYPE.SERVICE_MANUAL, true),
    );

  const activeRoute = (routePath) =>
    matchPath(location.pathname, { path: routePath, exact: true });

  const sidebarRoute = appRoutes.filter((route) => route.sidebar);

  const createLinks = (aRoutes, props) =>
    aRoutes.map((route) => {
      const hasEnabledRoute = route.featureKey
        ? useFeatureIsOn(route.featureKey)
        : true;
      if (!hasEnabledRoute) return null;

      const isActiveRoute =
        activeRoute(route.path) ||
        route.subPaths?.some((path) => activeRoute(path));

      if (route.collapse) {
        const collapse = {};
        collapse[route.name] = !collapseState[route.name];

        return (
          <ListItem key={route.name} className="item">
            <NavLink
              to="#"
              className={classNames('item-link', {
                'collapse-active': getCollapseInitialState(route.items),
              })}
              onClick={(e) => {
                e.preventDefault();
                setCollapseState(collapse);
              }}
            >
              <route.icon className="item-icon" />
              <ListItemText
                primary={t(route.name)}
                secondary={
                  collapseState[route.name] ? (
                    <ArrowDropUp className="caret" />
                  ) : (
                    <ArrowDropDown className="caret" />
                  )
                }
                disableTypography
                className="item"
              />
            </NavLink>
            <Collapse in={collapseState[route.state]} unmountOnExit>
              <List className="list collapse-list">
                {createLinks(route.items)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      return (
        <ListItem key={route.name} className="item">
          <NavLink
            to={route.path}
            className={classNames('item-link', {
              'active-link': isActiveRoute,
            })}
            onClick={() => {
              if (props?.mobile) handleCloseSidebar();
            }}
          >
            <route.icon className="item-icon" />
            <div className="item-wrapper">
              <ListItemText
                primary={t(route.name)}
                disableTypography
                className="item-text"
              />
              {route.beta ? (
                <StyledBetaWrapper active={isActiveRoute}>
                  <div className="beta-background" />
                  <Typography className="beta-text">Beta</Typography>
                </StyledBetaWrapper>
              ) : null}
            </div>
          </NavLink>
        </ListItem>
      );
    });

  const renderSideBarItems = (props) => (
    <div className="sidebar-wrapper">
      <List className="list">{createLinks(sidebarRoute, props)}</List>
      <Divider sx={{ my: '12px', mx: 'auto', width: '80%' }} />
      <a
        href={DOCUMENT_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="item-link"
        onClick={() => {
          if (props?.mobile) handleCloseSidebar();
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px', py: '3px' }}>
          <FolderOpen />
          <Typography>{t('userGuide')}</Typography>
        </Box>
      </a>
      <div
        className="item-button"
        role="button"
        tabIndex="0"
        onClick={handlePlayVideoTutorial}
      >
        <Box sx={{ display: 'flex', gap: '16px', py: '3px' }}>
          <img src={videoTutorialImg} alt="icon" className="img" />
          <Typography className="video-tutorial-text">
            {t('videoTutorial')}
          </Typography>
        </Box>
      </div>
      <a
        href={FAQ_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="item-link"
        onClick={() => {
          if (props?.mobile) handleCloseSidebar();
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px', py: '3px' }}>
          <HelpOutlineOutlined />
          <Typography>{t('faq')}</Typography>
        </Box>
      </a>
      <Feature name={FEATURE_FLAG.OTHER_SERVICES}>
        <NavLink
          to={ROUTES.OTHER_SERVICES}
          className={classNames('item-link', {
            'active-link': activeRoute(ROUTES.OTHER_SERVICES),
          })}
          onClick={() => {
            if (props?.mobile) handleCloseSidebar();
          }}
        >
          <Box sx={{ display: 'flex', gap: '16px', py: '3px' }}>
            <SidebarImgIcon
              src={
                activeRoute(ROUTES.OTHER_SERVICES)
                  ? vbeeWhiteIcon
                  : vbeeGrayIcon
              }
              name={t('otherServices')}
            />
          </Box>
        </NavLink>
      </Feature>
      <Divider sx={{ my: '12px', mx: 'auto', width: '80%' }} />
      <a
        href={VBEE_CONSOLE_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="item-link"
        onClick={() => {
          if (props?.mobile) handleCloseSidebar();
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            py: '3px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', gap: '16px' }}>
            <Code color={COLOR.darkBlue} />
            <Typography color={COLOR.darkBlue} fontWeight={500}>
              {t('api')}
            </Typography>
          </div>
          <Launch color={COLOR.darkBlue} />
        </Box>
      </a>
    </div>
  );

  return (
    <>
      <StyledDrawer
        sx={{ display: { md: 'none', xs: 'block' } }}
        variant="temporary"
        anchor="right"
        open={openSidebar}
        onClose={handleCloseSidebar}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledSidebar>
          <div className="brand">
            <Logo />
          </div>
          <div className="mobile package-wrapper">
            <PackageInfo />
            <Divider />
          </div>
          {renderSideBarItems({ mobile: true })}
        </StyledSidebar>
      </StyledDrawer>

      <StyledDrawer
        sx={{ display: { md: 'block', xs: 'none' } }}
        variant="persistent"
        anchor="left"
        open={openSidebar}
      >
        <StyledSidebar>
          <div className="brand">
            <Logo />
            <IconButton color="secondary" onClick={handleCloseSidebar}>
              <ArrowBack />
            </IconButton>
          </div>
          {renderSideBarItems()}
        </StyledSidebar>
      </StyledDrawer>
    </>
  );
};

export default Sidebar;
