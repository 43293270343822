import { IP_INFO_URL } from '@src/configs';

const axios = require('axios');

const getCountry = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: IP_INFO_URL,
      timeout: 3000,
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

export { getCountry };
