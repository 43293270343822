import { Button, Dialog, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS, BREAKPOINTS } from '@src/styles/config';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledTitleBar = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 5px;

  .title-wrapper {
    display: flex;
    flex: 1;
    gap: 8px;

    .estimate-audio-length {
      padding: 0px 12px 0px 16px;
      color: ${COLOR.subText};
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }

    .back-button {
      padding: 10px;
      border-radius: 8px;
      background-color: ${COLOR.charcoal};
    }

    .title-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px 4px;

      .base-input {
        flex: 1;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.4px;
        color: ${COLOR.indigo.default};
      }

      .time-updated {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.4px;
        color: ${COLOR.black[80]};
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 8px;
  }
`;

export const StyledGroupConvert = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;

  .clock-icon {
    color: ${COLOR.label[64]};
    width: 16px;
    height: 16px;
  }
  .duration {
    margin-left: 2px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.4px;
    color: ${COLOR.blue};
  }

  .estimate-audio-length {
    padding: 0px 12px 0px 16px;
    color: ${COLOR.subText};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }

  .button-save {
    text-transform: none;
    padding: 8px 16px 8px 12px;
    border-radius: 25px;
    background-color: ${COLOR.white};
    color: ${COLOR.secondary[100]};
    height: 44px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border: 1px solid ${COLOR.secondary[100]};
  }

  .button-convert {
    text-transform: none;
    padding: 8px 16px 8px 12px;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    width: 140px;
    color: ${COLOR.darkBlue};
    height: 44px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const StyledConvertButton = styled(Button)`
  text-transform: none;
  padding: 8px 16px 8px 12px;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  width: 140px;
  color: ${COLOR.darkBlue};
  height: 44px;
  font-weight: 700;
  line-height: 20px;
`;

export const StyledUpdateButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.black.default};
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px;
`;

export const StyledDownloadButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 42px;

  .download-button {
    white-space: nowrap;
    border-radius: 20px 0px 0px 20px;
    font-weight: 700;
    font-size: 14px;
    color: ${COLOR.darkBlue};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: ${COLOR.secondary[32]};
    border-style: solid none solid solid;
    border-width: 1px;
    background: inherit !important;
    &:hover {
      background: ${TRANSPARENT_COLOR.dark} !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD}px) {
    .download-button {
      padding-right: 16px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    .download-button {
      padding-right: 10px;
      padding-left: 10px;
    }

    .arrow-button {
      padding-left: 4px;
      padding-right: 10px;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-left: 12px;

    .download-button {
      padding-right: ${(props) => (props.langEng ? '30px' : '46px')};
      padding-left: 16px;
    }

    .arrow-button {
      padding-left: 6px;
      padding-right: 12px;
    }
  }

  .arrow-button {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    border-color: ${COLOR.secondary[32]};
    border-style: solid;
    border-width: 1px;
    &:hover {
      background: ${TRANSPARENT_COLOR.dark};
    }
  }
`;

export const StyledMenu = styled('div')`
  padding: 8px 0px;
  width: ${(props) => (props.engButton ? '190px' : '210px')};
`;

export const StyledTextIcon = styled(IconButton)`
  width: 100%;
  padding: ${(props) =>
    props.langEng ? '8px 0px 8px 18px' : '8px 0px 8px 20px'};
  color: ${(props) => (props.disabled ? COLOR.secondary[32] : COLOR.darkBlue)};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  justify-content: start;
  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    color: ${({ colorHover }) => COLOR[colorHover]};
    opacity: 1;
  }
  .text-action {
    color: ${(props) =>
      props.disabled ? COLOR.secondary[32] : COLOR.darkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: ${(props) => (props.langEng ? '4px' : '4px')};
    &:hover {
      color: ${COLOR.black};
    }
  }
  .text-download {
    color: ${(props) =>
      props.disabled ? COLOR.secondary[32] : COLOR.darkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: ${(props) => (props.langEng ? '4px' : '4px')};
    &:hover {
      color: ${COLOR.black};
    }
  }
`;

export const StyledActionTooltip = styled(
  ({ className, disabled, ...props }) => (
    // eslint-disable-next-line react/jsx-filename-extension, react/react-in-jsx-scope
    <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
  ),
)(({ disabled }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: disabled ? COLOR.darkV2[80] : COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 20px',
    textAlign: 'center',
    maxWidth: 180,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: disabled ? COLOR.darkV2[80] : COLOR.label[80],
  },
}));

export const StyledDownloadAudioButton = styled('div')`
  color: ${(props) =>
    props.disabled ? COLOR.secondary[32] : COLOR.darkBlue} !important;

  .text-download {
    font-weight: 700;
    font-size: 14px;
    margin-left: 3px;
  }

  @media (max-width: ${BREAKPOINTS.MD}px) {
    .text-download {
      display: none;
    }
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    .text-download {
      display: block;
    }
  }
`;

export const StyledSaveChangeDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: ${({ maxWidthPx }) =>
      maxWidthPx ? `${maxWidthPx}px` : '580px'};
    border-radius: 16px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
  }

  .title-content {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${COLOR.indigo[100]};
  }

  .content {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.indigo[100]};
  }

  .action {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;

    .action-button {
      text-transform: none;
      height: 40px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: -0.4px;
      border-radius: 500px;
      min-width: 120px;
    }

    .single {
      display: flex;
      justify-content: center !important;
    }

    .left-button {
      border: 1px solid ${COLOR.indigo[100]};
      color: ${COLOR.indigo[100]};
      width: 100%;
    }

    .right-button {
      border: 1px solid ${COLOR.yellow.default};
      background-color: ${COLOR.yellow.default};
      color: ${COLOR.indigo[100]};
      width: 100%;
    }
  }
`;
