import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import apis from '@src/apis';
import actions from '@src/redux/actions';
import { checkVietNam } from '@src/utils/checkCountry';
import { PACKAGE_TYPE } from '@src/constants/package';
import SubscriptionItem from './SubscriptionItem';
import Dialog from './Dialog';
import { StyledSubscription } from './index.style';

const DIALOG_NAME = {
  CANCEL_AUTO_RENEWAL: 'CANCEL_AUTO_RENEWAL',
  REGISTER_AUTO_RENEWAL: 'REGISTER_AUTO_RENEWAL',
};

const Subscription = () => {
  const [openDialog, setOpenDialog] = useState({});
  const [usingOrders, setUsingOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});

  const isVietNam = checkVietNam();

  const { subscriptionPurchase } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  const { user: userInfo } = useSelector((state) => state.auth);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenDialog = (dialogName, orderInfo) => {
    setOpenDialog({ ...openDialog, [dialogName]: true });
    setSelectedOrder(orderInfo);
  };

  const handleSetUsingOrders = (currUsingOrders) => {
    // Check saveUsingOrder have studio order
    const hasStudioOrder = currUsingOrders.find(
      (order) => order?.package?.type === PACKAGE_TYPE.STUDIO,
    );

    if (!hasStudioOrder) {
      const { usingPackage } = user;
      const studioOrder = {
        package: usingPackage,
        packageExpiryDate: userInfo.packageExpiryDate,
      };

      // Add studio order to first of saveUsingOrders
      currUsingOrders.unshift(studioOrder);
    }

    setUsingOrders(currUsingOrders);
  };

  const fetchUsingOrders = async () => {
    const data = await apis.orders.getUsingOrders();
    if (data && data.status) {
      handleSetUsingOrders(data.result);
    }
  };

  const handleCloseDialog = (dialogName) => {
    setOpenDialog({ ...openDialog, [dialogName]: false });
  };

  const handleCloseAutoRenewalDialog = () => {
    handleCloseDialog(DIALOG_NAME.CANCEL_AUTO_RENEWAL);
  };

  const handleConfirmCancelAutoRenew = async () => {
    const res = await apis.subscription.cancelSubscription();
    if (res?.status !== 1) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'cancelSubscriptionFail',
        }),
      );
    } else {
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'cancelSubscriptionSuccess',
        }),
      );
      dispatch(actions.user.updateSubscriptionPurchase({}));
    }
    handleCloseAutoRenewalDialog();
  };

  const handleCloseRegisterAutoRenewalDialog = () => {
    handleCloseDialog(DIALOG_NAME.REGISTER_AUTO_RENEWAL);
  };

  useEffect(() => {
    fetchUsingOrders();
  }, []);

  return (
    <StyledSubscription>
      <div className="header-payment">
        <Typography className="title">{t('myPackages')}</Typography>
      </div>
      <Box className="using-package-list">
        {usingOrders.map((usingOrder) => (
          <Box key={usingOrder.id}>
            <SubscriptionItem
              packageInfo={usingOrder?.package}
              usingOrder={usingOrder}
              isVietNam={isVietNam}
              subscriptionPurchase={subscriptionPurchase}
              handleOpenDialog={handleOpenDialog}
              dialogName={DIALOG_NAME}
            />
          </Box>
        ))}
      </Box>
      <Dialog
        dialogName={DIALOG_NAME}
        openDialog={openDialog}
        selectedOrder={selectedOrder}
        handleCloseAutoRenewalDialog={handleCloseAutoRenewalDialog}
        handleConfirmCancelAutoRenew={handleConfirmCancelAutoRenew}
        handleCloseRegisterAutoRenewalDialog={
          handleCloseRegisterAutoRenewalDialog
        }
      />
    </StyledSubscription>
  );
};

export default Subscription;
