import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyledBack } from './index.style';

const BackButton = () => {
  const { t } = useTranslation();

  return (
    <StyledBack>
      <div className="button-back">
        <Typography className="title-back">{t('back')}</Typography>
      </div>
    </StyledBack>
  );
};

export default BackButton;
