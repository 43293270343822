import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  LinearScale as PendingIcon,
  Menu as MenuIcon,
  ShoppingCartOutlined,
  StarOutline,
  WarningAmberRounded,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  MenuItem,
  Menu,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import BadgeAvatar from '@src/components/BadgeAvatar';
import ROUTES from '@src/constants/route';
import { ORDER_STATUS } from '@src/constants/order';
import { VBEE_DOMAIN } from '@src/configs';
import { TIME_STORE_LANGUAGE } from '@src/constants/device';
import i18n from '@src/languages';
import { PACKAGE_LEVEL } from '@src/constants/package';
import { COUNTRY, LANGUAGE } from '@src/constants';
import { delimitNumber } from '@src/utils/number';
import { getPackageName } from '@src/services/package';

import IconAlertTriangle from '@src/assets/icons/alert-triangle.svg';
import IconPackage from '@src/assets/icons/package.svg';
import IconAlertAlphabet from '@src/assets/icons/alert-alphabet.png';
import IconAlphabet from '@src/assets/icons/alphabet.png';
import IconBlackGift from '@src/assets/icons/black-gift.png';
import lockIcon from '@src/assets/icons/lock.svg';
import redAlertTriangleIcon from '@src/assets/icons/red-alert-triangle.svg';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';

import { COLOR } from '@src/styles/color';
import { getCookie, setCookie } from '@src/utils/cookie';
import useCustomSSO from '@src/hooks/useCustomSSO';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import PackageInfoItem from './PackageInfoItem';
import { StyledAppBar, StyledNavBar } from './index.style';
import OldProfileAction from './OldProfileAction';

const languages = [
  {
    value: LANGUAGE.VN,
    country: COUNTRY.VN,
    label: 'vietnamese',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/VietNam.png',
  },
  {
    value: LANGUAGE.EN,
    country: COUNTRY.EN,
    label: 'english',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/UnitedKingdom.png',
  },
  // {
  //   value: LANGUAGE.PH,
  //   country: COUNTRY.PH,
  //   label: 'filipino',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/Philippines.png',
  // },
  // {
  //   value: LANGUAGE.TH,
  //   country: COUNTRY.TH,
  //   label: 'thailand',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/Thailand.png',
  // },
  // {
  //   value: LANGUAGE.CN,
  //   country: COUNTRY.CN,
  //   label: 'chinese',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/China.png',
  // },
];

const Language = ({ location }) => {
  const [anchorLanguage, setAnchorLanguage] = useState(null);
  const [language, setLanguage] = useState({});

  const { t } = useTranslation();

  const handleClickLanguage = (event) => setAnchorLanguage(event.currentTarget);

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
    setLanguage(lng);
    setAnchorLanguage(null);
  };

  const handleClickChangeLanguage = (lng) => {
    handleChangeLanguage(lng);
    setCookie({
      cname: 'lng',
      cvalue: lng.value,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_LANGUAGE,
    });
  };

  useEffect(() => {
    const defaultLanguageValue = localStorage.getItem('i18nextLng');
    const defaultLanguage = languages.find(
      (lng) => lng.value === defaultLanguageValue,
    );

    const currentLanguageValue = getCookie('lng');
    const currentLanguage = languages.find(
      (lng) => lng.value === currentLanguageValue,
    );

    if (location) {
      const { country: countryCode } = location;
      localStorage.setItem('country', COUNTRY[countryCode] || COUNTRY.EN);
    }

    if (currentLanguage) {
      handleChangeLanguage(currentLanguage);
      return;
    }

    const countryLanguageValue = localStorage.getItem('country');
    const countryLanguage = languages.find(
      (lng) => lng.country === countryLanguageValue,
    );
    if (countryLanguage) {
      handleChangeLanguage(countryLanguage);
      return;
    }

    if (defaultLanguage) {
      setLanguage(defaultLanguage);
      return;
    }
    handleChangeLanguage(languages[0]);
  }, [location]);

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Button
        color="secondary"
        className="language-text large-language-button"
        startIcon={
          <img className="language-icon" src={language.icon} alt="language" />
        }
        onClick={handleClickLanguage}
      >
        {t(language.label)}
      </Button>
      <IconButton
        onClick={handleClickLanguage}
        className="medium-language-button"
        color="secondary"
      >
        <img className="language-icon" src={language.icon} alt="language" />
      </IconButton>
      <Menu
        anchorEl={anchorLanguage}
        open={Boolean(anchorLanguage)}
        onClose={() => setAnchorLanguage(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languages.map((lng) => (
          <MenuItem onClick={() => handleClickChangeLanguage(lng)}>
            <img
              style={{ width: 16, marginRight: 8 }}
              src={lng.icon}
              alt="language"
            />
            {t(lng.label)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const Profile = ({ packageName, useNewSideBar }) => {
  const [anchorProfile, setAnchorProfile] = useState(null);

  const { keycloak } = useCustomSSO();

  const { user } = useSelector((state) => state.auth);

  const handleClickProfile = (event) => setAnchorProfile(event.currentTarget);

  return (
    <div className="profile" style={{ gap: useNewSideBar ? '0px' : '8px' }}>
      <div
        className="profile-info"
        onClick={handleClickProfile}
        role="button"
        tabIndex="0"
      >
        <div className="profile-content">
          <Typography className="text bold normal-username-info">
            {`${user.lastName} ${user.firstName}`}
          </Typography>
          <Typography className="text bold small-username-info">
            {`${user.firstName}`}
          </Typography>
          <Typography className="mobile">{packageName}</Typography>
        </div>
        <IconButton>
          <div className="button-avatar">
            <BadgeAvatar
              img={user.avatar}
              active={keycloak && keycloak.authenticated}
              name={user.firstName || user.lastName}
              number={new Date(user.createdAt)}
              smallImgWidth={30}
              type="dot"
            />
          </div>
        </IconButton>
      </div>

      <OldProfileAction
        anchorProfile={anchorProfile}
        setAnchorProfile={setAnchorProfile}
      />
    </div>
  );
};

const PackageInfo = () => {
  const { t } = useTranslation();
  const isDubbingRoute = useCheckDubbingRoute();

  const { user } = useSelector((state) => state.auth);
  const { usingPackage, lastExpiredOrder } = useSelector((state) => state.user);
  const { remainingCharacters, lockCharacters, bonusCharacters } = useSelector(
    (state) => state.auth.user,
  );

  const isExpiredPackage =
    !usingPackage.id ||
    (!isDubbingRoute &&
      user.packageExpiryDate &&
      moment().isAfter(user.packageExpiryDate)) ||
    (isDubbingRoute &&
      user?.dubbing?.packageExpiryDate &&
      moment().isAfter(user?.dubbing?.packageExpiryDate));

  const { language } = i18n;
  const { getFeatureValue } = useFeatureFlags();
  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const packageName = usingPackage.id
    ? getPackageName(usingPackage.name, usingPackage.code, language)
    : getPackageName(
        lastExpiredOrder?.package?.name,
        user.packageCode,
        language,
      );

  const renderPackageExpired = () => {
    const preservationExpiryDate = isDubbingRoute
      ? user?.dubbing?.preservationExpiryDate
      : user.preservationExpiryDate;

    if (preservationExpiryDate && usePreservationExpiryDate)
      return moment(preservationExpiryDate).format('DD/MM/YYYY - HH:mm');

    if (user.packageExpiryDate)
      return moment(user.packageExpiryDate)
        .add(30, 'days')
        .format('DD/MM/YYYY - HH:mm');

    if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      return moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days')
        .format('DD/MM/YYYY - HH:mm');
    }

    return '';
  };

  return (
    <>
      <PackageInfoItem
        name="package"
        value={packageName}
        icon={IconPackage}
        required
      />
      <PackageInfoItem
        name="expiryDate"
        value={
          (user.packageExpiryDate &&
            moment(user.packageExpiryDate).format('DD/MM/YYYY - HH:mm')) ||
          (!user.packageExpiryDate && user.packageCode && t('endless'))
        }
        icon={IconAlertTriangle}
        dangerIcon={redAlertTriangleIcon}
        isDangerText={isExpiredPackage}
      />
      {remainingCharacters > 0 && (
        <PackageInfoItem
          name="paidCharacters"
          value={delimitNumber(remainingCharacters, language)}
          icon={IconAlphabet}
          required
        />
      )}
      {bonusCharacters > 0 && (
        <PackageInfoItem
          name="freeCharacters"
          value={delimitNumber(bonusCharacters, language)}
          icon={IconBlackGift}
          required
        />
      )}
      {lockCharacters > 0 && (
        <PackageInfoItem
          name="lockCharacters"
          value={delimitNumber(lockCharacters, language)}
          iconAfter={
            <InfoOutlined
              fontSize="small"
              color="error"
              className="lock-icon"
            />
          }
          icon={IconAlertAlphabet}
          tooltipTitle={
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('noteLockCharacter', {
                  expiryDate: `<b>${renderPackageExpired()}</b>`,
                }),
              }}
            />
          }
          isDangerText
        />
      )}
      {lockCharacters > 0 && (
        <PackageInfoItem
          name="preservedCharactersDate"
          value={renderPackageExpired()}
          icon={lockIcon}
          isDangerText
        />
      )}
    </>
  );
};

const OrderButton = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { latestOrder, usingPackage } = useSelector((state) => state.user);
  const history = useHistory();

  const goToExactPackage = (code) => {
    if (code) history.push(`${ROUTES.PAYMENT}?package=${code}`);
    else history.push(ROUTES.PAYMENT);
  };

  const goToPackages = () => history.push(ROUTES.PAYMENT);

  const isExpiredPackage =
    user.packageExpiryDate && moment().isAfter(user.packageExpiryDate);

  const getButton = () => {
    if (latestOrder.status === ORDER_STATUS.PENDING)
      return (
        <Button
          className="btn-order pending-button"
          startIcon={<PendingIcon />}
          onClick={() => goToExactPackage(latestOrder.package.code)}
        >
          <span>{t('pending')}</span>
        </Button>
      );

    if (isExpiredPackage && usingPackage.level !== PACKAGE_LEVEL.TRIAL)
      return (
        <Button
          className="btn-order error-button"
          startIcon={<WarningAmberRounded />}
          onClick={() => goToExactPackage(usingPackage.code)}
        >
          <span>{t('renewalNow')}</span>
        </Button>
      );

    if (usingPackage.level === PACKAGE_LEVEL.PRO)
      return (
        <Button
          className="btn-order"
          startIcon={<ShoppingCartOutlined />}
          onClick={() => goToExactPackage(usingPackage.code)}
          color="primary"
          variant="contained"
        >
          <span>{t('buyMore')}</span>
        </Button>
      );

    return (
      <Button
        className="btn-order"
        startIcon={<StarOutline />}
        onClick={goToPackages}
        color="primary"
        variant="contained"
      >
        <span>{t('upgrade')}</span>
      </Button>
    );
  };

  return <Box className="btn-order">{getButton()}</Box>;
};

const Navbar = ({
  openSidebar,
  handleOpenSidebar,
  location,
  useNewSideBar,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { runningTourGuide } = useSelector((state) => state.user);
  const { usingPackage } = useSelector((state) => state.user);
  const [userPackage, setUserPackage] = useState({ color: '', icon: '' });

  const { language } = i18n;

  const packageName = usingPackage.id
    ? getPackageName(usingPackage.name, usingPackage.code, language)
    : getPackageName(null, user.packageCode, language);

  const getUserPackage = async () => {
    const { level, icon } = usingPackage;
    switch (level) {
      case PACKAGE_LEVEL.ADVANCE:
      case PACKAGE_LEVEL.PRO:
        setUserPackage({ color: COLOR.primary, icon });
        break;
      case PACKAGE_LEVEL.PROFESSIONAL:
        setUserPackage({ color: COLOR.warning, icon });
        break;
      case PACKAGE_LEVEL.ENTERPRISE:
        setUserPackage({ color: COLOR.success, icon });
        break;
      default:
        setUserPackage({ color: '', icon: '' });
        break;
    }
  };

  useEffect(() => {
    getUserPackage();
  }, [usingPackage]);

  return (
    <StyledAppBar
      openSidebar={openSidebar}
      runningTourGuide={runningTourGuide}
      useNewSideBar={useNewSideBar}
    >
      <Toolbar className="container">
        {!openSidebar && (
          <IconButton color="secondary" onClick={handleOpenSidebar}>
            <MenuIcon />
          </IconButton>
        )}
        <StyledNavBar colorpackageavatar={userPackage.color}>
          <Box
            sx={{ gap: { xs: '24px', lg: '12px', xl: '24px' } }}
            className="navbar-item"
          >
            <div className="web">
              <OrderButton />
            </div>
            {user.packageCode && (
              <>
                <div className="web package-info large-package-info">
                  <PackageInfo />
                </div>
                <div className="web package-info medium-package-info">
                  <PackageInfo />
                </div>
              </>
            )}
          </Box>
          <Box
            sx={{ gap: { xs: '24px', lg: '4px', xl: '24px' } }}
            className="navbar-item"
          >
            <Language location={location} />
            <Profile
              userPackage={userPackage}
              packageName={packageName}
              useNewSideBar={useNewSideBar}
              location={location}
            />
          </Box>
        </StyledNavBar>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
