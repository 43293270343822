import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import useCustomSSO from '@src/hooks/useCustomSSO';
import ROUTE from '@src/constants/route';

import { LANDING_PAGE_URL } from '@src/configs';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useCustomKeycloak from '@src/hooks/useCustomKeycloak';
import queryString from 'query-string';

const PrivateRoute = ({ component: Component, featureKey, ...rest }) => {
  const { verifying, accessToken, user } = useSelector((state) => state.auth);
  const { loginInNewTab } = useCustomKeycloak();
  const { getFeatureValue } = useFeatureFlags();

  const { keycloak, initialized } = useCustomSSO();

  const enabled = featureKey
    ? getFeatureValue(featureKey, {
        userId: user?.id,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
      })
    : true;
  const useRedirectVoucherLink = getFeatureValue(
    FEATURE_KEYS.REDIRECT_VOUCHER_LINK,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const handleRedirectLogin = () => {
    const params = queryString.parse(window.location.search);
    const { isAdvertisement, code } = params;
    if (code || !initialized) return;

    if (isAdvertisement && useRedirectVoucherLink) {
      loginInNewTab();
      return;
    }
    window.location.assign(LANDING_PAGE_URL);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!enabled) return <Redirect to={ROUTE.TTS} />;
        if (accessToken) return <Component {...props} />;
        if (!verifying && !accessToken && !keycloak.token) {
          handleRedirectLogin();
        }
        return null;
      }}
    />
  );
};

export default PrivateRoute;
