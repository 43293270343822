import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@src/components/Tooltip';
import { checkVietNam } from '@src/utils/checkCountry';
import { getPackageCurrentPrice } from '@src/services/package';
import { StyledSubscriptionInfo } from './index.style';

const SubscriptionInfo = ({ buyPackage }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVietNam = checkVietNam();
  const [showTooltip, setShowTooltip] = useState(false);

  const { expiresIn } = buyPackage;
  const { price, usdPrice } = getPackageCurrentPrice(buyPackage, true);

  const nextPaymentDate = moment().add(expiresIn, 'days').format('DD/MM/YYYY');
  const subscriptionCycle = expiresIn;

  const onClickInfo = () => setShowTooltip(!showTooltip);

  const onMouseEnterInfo = () => setShowTooltip(true);

  const onMouseLeaveInfo = () => setShowTooltip(false);

  return (
    <StyledSubscriptionInfo>
      <Typography className="title">{t('nextTimeRenewal')}</Typography>
      <Box className="content">
        <Box display="flex" justifyContent="space-between">
          <Typography className="text-content-bold">
            {t('nextPayment')}
          </Typography>
          <Typography className="text-content-bold">
            {nextPaymentDate}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className="text-content-bold">
            {t('subscriptionCycle')}
          </Typography>
          <Typography className="text-content-bold">{`${subscriptionCycle} ${t(
            'day',
          ).toLowerCase()}`}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap="4px">
            <Typography className="text-content-bold">
              {t('amountToBePaid')}
            </Typography>
            <div
              onMouseOver={onMouseEnterInfo}
              onMouseLeave={onMouseLeaveInfo}
              onFocus={onMouseEnterInfo}
              onBlur={onMouseLeaveInfo}
            >
              <Tooltip
                open={showTooltip}
                arrow
                title={t('subscriptionInfoNote')}
                placement="top"
                onClick={onClickInfo}
              >
                <IconButton>
                  <InfoIcon className="subscription-info-note" />
                </IconButton>
              </Tooltip>
            </div>
          </Box>
          <div>
            {!isVietNam && (
              <span className="discount-unit-price font-size-16">$</span>
            )}
            {isVietNam && (
              <span className="discount-unit-price underline font-size-16">
                đ
              </span>
            )}
            {isVietNam
              ? price?.toLocaleString(language)
              : usdPrice?.toLocaleString(language)}
          </div>
        </Box>
      </Box>
    </StyledSubscriptionInfo>
  );
};

export default SubscriptionInfo;
