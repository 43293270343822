import { all, put, takeLatest } from 'redux-saga/effects';
import apis from '@src/apis';
import actions from '../actions';

function* getLanguagesSaga() {
  const { status, result } = yield apis.voices.getLanguages({});
  if (!status) throw new Error();
  const languages = result.languages
    .sort((a, b) => a.rank - b.rank)
    .map((item) => ({
      value: item.code,
      label: { vi: item.vietnameseName, en: item.globalName },
      roundImage: item.roundImage,
    }));
  yield put(actions.language.getLanguagesSuccess(languages));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.language.actionTypes.GET_LANGUAGES, getLanguagesSaga),
  ]);
}
