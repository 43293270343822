import { PACKAGE_TYPE } from '@src/constants/package';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import { checkVietNam } from '@src/utils/checkCountry';
import moment from 'moment';

export const verifyPolicy = (voucher = {}, currPackageId) => {
  const { policy } = voucher;

  const isSatisfiedPackage =
    policy?.applyAllPackages ||
    policy?.packages?.find((pkg) => pkg.id === currPackageId);

  return isSatisfiedPackage;
};

export const verifyTime = (voucher = {}) => {
  const { endTime, startTime } = voucher;

  const isNotSatisfiedTime =
    moment().isBefore(startTime) || moment().isAfter(endTime);

  return !isNotSatisfiedTime;
};

export const getSavedPrice = ({ voucher, currPrice, currUsdPrice }) => {
  if (!voucher) return 0;

  const isVietNam = checkVietNam();
  const { type, value } = voucher;
  if (type === VOUCHER_TYPE.PERCENT) {
    let savedPrice = isVietNam
      ? (currPrice * value) / 100
      : (currUsdPrice * value) / 100;

    // Round to nearest 1000 for VND and round to nearest 0.01 for USD

    if (isVietNam) {
      savedPrice = Math.ceil(savedPrice / 1000) * 1000;
    } else {
      const finalUsdPrice = Math.round(currUsdPrice - savedPrice) - 0.01;
      savedPrice = currUsdPrice - finalUsdPrice;
    }

    return savedPrice;
  }
  if (type === VOUCHER_TYPE.PRICE) return value;
  return 0;
};

export const checkDiscount = (data) => {
  const { startAt, endAt } = data.discountConfig || {};
  const inDiscountTime = startAt && moment().isBetween(startAt, endAt);

  if (inDiscountTime) return true;
  return false;
};

export const getCharactersExtention = ({ voucher, currCharacters }) => {
  if (!voucher) return 0;

  const { type, value } = voucher;
  if (type === VOUCHER_TYPE.CHARACTER) {
    return Math.ceil((currCharacters * value) / 100);
  }
  return 0;
};

export const getTimeExtention = ({ voucher, currTime }) => {
  if (!voucher) return 0;

  const { type, value } = voucher;
  if (type === VOUCHER_TYPE.PERCENT_TIME)
    return Math.ceil((currTime * value) / 100);

  if (type === VOUCHER_TYPE.TIME) return value;

  return 0;
};

export const checkVoucherByProduct = ({ voucher, product }) => {
  if (voucher?.policy?.applyAllPackages) return true;
  const numberListPackageOfVoucher = voucher?.policy?.packages.filter((pkg) =>
    pkg.code.includes(product),
  ).length;

  return numberListPackageOfVoucher > 0;
};

export const checkVoucherBypProductForHeaderBanner = ({ voucher, product }) => {
  const numberOfListPackagesAPI = voucher?.policy?.packages.filter((pkg) =>
    pkg.code.includes(PACKAGE_TYPE.API),
  ).length;
  if (product === PACKAGE_TYPE.STUDIO && numberOfListPackagesAPI > 0) {
    return true;
  }
  return checkVoucherByProduct({ voucher, product });
};

export const getVouchersByProduct = ({ vouchers = [], product = '' }) => {
  const result =
    vouchers.length > 0 &&
    vouchers.filter((voucher) => checkVoucherByProduct({ voucher, product }));
  return result.length > 0 ? result : [];
};
