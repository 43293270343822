const getNextStreamAudio = ({ currStreamAudio, tts, lastPhraseIndex }) => {
  const { index, subIndex, phraseIndex } = currStreamAudio;
  const ttsItem = tts?.find((audio) => audio.index === index);
  if (!ttsItem) return null;

  const { lastSubIndex } = ttsItem;
  if (phraseIndex < lastPhraseIndex)
    return { index, subIndex, phraseIndex: phraseIndex + 1 };
  if (subIndex < lastSubIndex)
    return { index, subIndex: subIndex + 1, phraseIndex: 0 };

  const nextTtsItem = tts?.find((audio) => audio.index > index);
  if (!nextTtsItem) return null;

  return { index: nextTtsItem.index, subIndex: 0, phraseIndex: 0 };
};

export { getNextStreamAudio };
