import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FETCH_REQUESTS_INTERVAL } from '@src/constants/websocket';
import apis from '@src/apis';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import NotificationDialog from '@src/components/NotificationDialog';
import actions from '@src/redux/actions';
import {
  FIELDS_FILTER,
  MAX_REQUEST_DELETE,
  REQUEST_TYPE,
} from '@src/constants/request';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import RequestTable from '@src/containers/TTSNew/Requests/RequestTable';
import { StyledRequests } from '@src/containers/TTSNew/Requests/index.style';
import { StyledActionWarning } from './index.style';

const HistoryChangedTable = ({
  page,
  dubbingRequests,
  requestLoading,
  onChangePage,
  onChangeIsExpand,
  isExpand,
  totalRequests,
  onChangeRequestLoading,
  showTitle = true,
}) => {
  const [requests, setRequests] = useState(dubbingRequests);
  const [sort, setSort] = useState('createdAt_desc');
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [validateDeleteRequest, setValidateDeleteRequest] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [selectedFields] = useState(Object.values(FIELDS_FILTER));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requestsRef = useRef(requests);

  const { user } = useSelector((state) => state.auth);

  const handleUpdateRequests = (newRequests) => {
    setRequests(newRequests);
  };
  const { getFeatureValue } = useFeatureFlags();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const useDubbingSubtitleTimeline = getFeatureValue(
    FEATURE_KEYS.DUBBING_SUBTITLE_TIMELINE,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const handleChangeSort = (newSort) => {
    setSort(newSort);
  };

  const fetchProgressRequest = async (requestId) => {
    const data = await apis.requests.getProgressRequest(requestId);
    if (data && data.status) {
      const newRequests = requestsRef.current.map((request) => {
        if (request.id === requestId) {
          const { progress, status, audioLink } = data.result;
          return {
            ...request,
            progress,
            status,
            audioLink,
          };
        }
        return request;
      });
      handleUpdateRequests(newRequests);
    }
  };

  const handleCloseDeleteWarningDialog = () => {
    setDeleteWarning(false);
  };

  const handleCloseValidateDeleteRequestDialog = () => {
    setValidateDeleteRequest(false);
  };

  const handleSelectRequest = (selectReqs) => {
    setSelectedRequests(selectReqs);
  };

  const handleDeleteRequestConfirm = async () => {
    try {
      const requestIds = selectedRequests.map((req) => req.id);
      const data = await apis.requests.deleteRequest({ requestIds });
      if (!data?.status) {
        dispatch(
          actions.noti.push({
            severity: 'error',
            message: 'deleteRequestFailure',
          }),
        );
        setDeleteWarning(false);
        return;
      }
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'deleteRequestSuccess',
        }),
      );
      setDeleteWarning(false);
      setSelectedRequests([]);
      onChangeRequestLoading(true);
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: error?.response?.data?.error_message,
        }),
      );
      setDeleteWarning(false);
    }
  };

  const handleDeleteAllRequests = async () => {
    const data = await apis.requests.deleteRequest({
      isDeleteAll: true,
      type: REQUEST_TYPE.DUBBING,
    });
    if (!data?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'deleteRequestFailure',
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'success',
        message: 'deleteRequestSuccess',
      }),
    );
    onChangeRequestLoading(true);
    setSelectedRequests([]);
  };

  const updateInprogressRequest = (requestIds) => {
    Promise.all(
      requestIds.map(async (requestId) => {
        await fetchProgressRequest(requestId);
        return null;
      }),
    );
  };

  const updateRequest = (requestId, updateInfo) => {
    const newRequests = requestsRef.current.map((request) => {
      if (request.id === requestId) {
        return {
          ...request,
          ...updateInfo,
        };
      }
      return request;
    });
    handleUpdateRequests(newRequests);
  };

  const handleCollapseRequestTable = () => onChangeIsExpand(false);

  const renderConfirmDeleteRequestACtion = (handleClose, handleConfirm) => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleClose}>
        {t('noValue')}
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const renderCloseButton = (handleClose) => (
    <Button variant="contained" onClick={handleClose}>
      {t('understood')}
    </Button>
  );

  useEffect(() => {
    setRequests(dubbingRequests);
  }, [dubbingRequests]);

  useEffect(() => {
    requestsRef.current = requests;
  }, [requests]);

  useEffect(() => {
    const fetchRequestsInterval = setInterval(async () => {
      const inProgressRequest = requestsRef.current
        .filter((request) => request.status === REQUEST_STATUS.IN_PROGRESS)
        .map((request) => request.id);
      if (inProgressRequest?.length) updateInprogressRequest(inProgressRequest);
    }, FETCH_REQUESTS_INTERVAL);

    return () => clearInterval(fetchRequestsInterval);
  }, []);

  return (
    <StyledRequests
      sx={{
        width: useDubbingSubtitleTimeline && '100%',
        height: useDubbingSubtitleTimeline && '100%',
      }}
    >
      {showTitle && (
        <div
          className={isExpand ? 'request-wrapper' : 'title-request'}
          role="button"
          tabIndex="0"
          onClick={handleCollapseRequestTable}
        >
          <div className="request-list-title">
            <IconButton className="expand-icon" size="small">
              {isExpand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <span>{t('historyChanges', { value: totalRequests })}</span>
          </div>
        </div>
      )}
      {(!!isExpand || !showTitle) && (
        <>
          <div id={TTS_GUIDE.REQUESTS_TABLE}>
            <RequestTable
              requests={requests}
              selectedFields={selectedFields}
              page={page}
              total={totalRequests}
              sort={sort}
              loading={requestLoading}
              playable={false}
              tableHeight={useDubbingSubtitleTimeline ? '340px' : '200px'}
              selectedRequests={selectedRequests}
              isProcessBySeconds={isProcessDubbingByUnitSecond}
              onUpdateRequest={updateRequest}
              onChangeSort={handleChangeSort}
              onChangePage={onChangePage}
              onSelectRequest={handleSelectRequest}
              onDeleteSelectedRequests={handleDeleteRequestConfirm}
              onDeleteAllRequests={handleDeleteAllRequests}
              isShowCheckbox={false}
            />
          </div>
          <NotificationDialog
            name="ssml"
            title={t('deleteRequestTitle')}
            description={t('deleteRequestTitleDescription')}
            variant="warning"
            open={deleteWarning}
            onClose={handleCloseDeleteWarningDialog}
            actionComponent={renderConfirmDeleteRequestACtion(
              handleCloseDeleteWarningDialog,
              handleDeleteRequestConfirm,
            )}
          />
          <NotificationDialog
            name="ssml"
            title={t('exceedRequests')}
            description={t('validateRequestDescription', {
              maxRequests: MAX_REQUEST_DELETE,
            })}
            variant="warning"
            open={validateDeleteRequest}
            onClose={handleCloseValidateDeleteRequestDialog}
            actionComponent={renderCloseButton(
              handleCloseValidateDeleteRequestDialog,
            )}
          />
        </>
      )}
    </StyledRequests>
  );
};

export default HistoryChangedTable;
