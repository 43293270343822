import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledSampleFileReference = styled('div')`
  height: 28px;
  display: flex;
  gap: 4px;
  align-items: center;
  .sample-text {
    font-size: 14px;
    color: ${COLOR.black[80]};
    font-weight: 500;
    line-height: 20px;
  }

  .sample-file {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    line-height: 20px;
    color: ${COLOR.black[80]};
    font-weight: 500;
  }

  @media (max-width: 600px) {
    .sample-text {
      font-size: 12px;
      line-height: 16px;
    }

    .sample-file {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const StyledDefaultDropzoneContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .upload-image {
    width: 40px;
    height: 40px;
  }

  .upload-instruction {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .upload-file-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .text-blue {
    color: ${COLOR.labelBlue} !important;
  }

  @media (max-width: 600px) {
    .upload-instruction {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
    }

    .upload-file-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
`;

export const StyledUploadSubtitleFile = styled('div')`
  .dropzone {
    box-sizing: border-box;
    background: ${(props) =>
      props?.isShowFileInformation ? COLOR.white : COLOR.charcoal};
    border: ${(props) =>
      props.isSuccess ? '' : `2px dashed ${TRANSPARENT_COLOR.darkBlue}`};
    border-radius: 16px;
    padding: ${(props) => (props?.isShowFileInformation ? '0px' : '24px')};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    .dropzone-content {
      width: 100%;
      cursor: pointer;
    }

    .divider {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 8px;
      margin-bottom: 8px;

      .divider-line {
        flex: 1;
        height: 1px;
        background-color: ${COLOR.darkV2[32]};
      }

      .divider-text {
        font-size: 12px;
        font-weight: 400;
        color: ${COLOR.darkV2[80]};
        text-transform: lowercase;
      }
    }

    .view-subtitle-tooltip {
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.white};
      line-height: 20px;
      letter-spacing: -0.4px;
      max-width: 232px;
    }

    .view-subtitle {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px 8px 12px;
      cursor: pointer;
      border-radius: 480px;
      border: 1px solid ${COLOR.darkBlue};
      margin-top: 16px;
      text-transform: none;

      :hover {
        background-color: ${COLOR.white};
      }

      .view-subtitle-text {
        font-size: 14px;
        font-weight: 700;
        color: ${COLOR.darkBlue};
        line-height: 20px;
        letter-spacing: -0.4px;
      }
    }

    .view-subtitle-disabled {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px 8px 12px;
      border-radius: 480px;
      margin-top: 16px;
      text-transform: none;
      background-color: ${COLOR.darkV2[8]};
      border: none;

      .view-subtitle-text {
        font-size: 14px;
        font-weight: 700;
        color: ${COLOR.darkV2[32]};
        line-height: 20px;
        letter-spacing: -0.4px;
      }
    }
  }
`;

export const StyledSuccessDropzoneContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .upload-instruction {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .upload-file-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .text-blue {
    color: ${COLOR.labelBlue} !important;
  }
`;

export const StyledFailedDropzoneContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;

  .multiple-input-dubbing-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 100%;
    justify-content: center;
  }

  .file-name {
    font-size: 16px;
    font-weight: 600;
  }

  .upload-instruction {
    display: flex;
    gap: 10px;
  }

  .upload-file-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .text-blue {
    color: ${COLOR.labelBlue} !important;
  }

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin: 4px;
    width: 100%;

    .error-text {
      font-size: 14px;
      color: ${COLOR.error};
      max-width: 490px;
    }

    .error-detail-wrapper {
      padding: 12px 16px 12px 16px;
      border-radius: 12px;
      font-size: 14px;
      background: ${COLOR.white};
      height: 80px;
      overflow-y: auto;
      box-sizing: border-box;
      width: 490px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 12px;
        background-color: ${COLOR.white};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${COLOR.indigo[64]};
        border-radius: 12px;
      }

      .error-detail {
        margin-bottom: 16px;
      }
    }
  }
`;

export const StyledFileSelected = styled('div')`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  .multiple-input-dubbing {
    padding: 12px !important;
    background-color: ${COLOR.white} !important;
    border: 1px solid ${COLOR.darkV2[16]} !important;
  }

  .file-info {
    display: flex;
    gap: 16px;
    align-items: center;

    .file-name {
      max-width: 388px;
    }
  }

  .cancel-icon {
    width: 24px;
    height: 24px;
    color: ${COLOR.danger};
    cursor: pointer;
  }

  .blue {
    color: ${COLOR.blue};
  }
`;

export const StyledFileSelectedMultipleInput = styled('div')`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  padding: 24px 10px;
  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.black[16]};
  border-radius: 12px;

  .file-info {
    display: flex;
    gap: 16px;
    align-items: center;
    overflow: hidden;

    .image-wrapper {
      width: 96px;
      height: 48px;
      border-radius: 8px;
      overflow: hidden;

      .thumbnail-image {
        width: 100%;
        height: 100%;
        transform: scale(1.3);
        transform-origin: center;
      }
    }

    .file-name {
      max-width: 388px;
    }
  }

  .cancel-icon {
    width: 24px;
    height: 24px;
    color: ${COLOR.danger};
    cursor: pointer;
  }

  .blue {
    color: ${COLOR.blue};
  }
`;

export const StyledYoutubeContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4px;

  .youtube-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid ${(props) => (props.error ? COLOR.red : COLOR.darkV2[32])};
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;

    .youtube-icon {
      width: 24px;
      height: 24px;
      color: ${COLOR.red};
    }

    .youtube-text {
      width: 100%;
      font-size: 14px;
      color: ${COLOR.black[80]};
      font-weight: 500;
      border: none;
      background-color: transparent;

      :focus {
        outline: none;
        background-color: ${COLOR.white};
      }
    }

    .loading-icon {
      width: 24px !important;
      height: 24px !important;
      color: ${COLOR.blue};
    }

    :focus-within {
      background-color: ${COLOR.white};
    }
  }

  .error-message {
    font-size: 12px;
    line-height: 24px;
    color: ${COLOR.error};
  }
`;
