import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import ROUTES from '@src/constants/route';
import { delimitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledHeaderLockCharacterBanner } from './index.style';

const SmallLockCharacterBanner = () => {
  const { t } = useTranslation();
  const { language } = i18n;
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { lockCharacters } = useSelector((state) => state.auth.user);
  const { lastExpiredOrder } = useSelector((state) => state.user);
  const { getFeatureValue } = useFeatureFlags();
  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const handleRedirectPayment = () => history.push(ROUTES.PAYMENT);

  const getPackageExpired = () => {
    if (user.preservationExpiryDate && usePreservationExpiryDate)
      return moment(user.preservationExpiryDate);

    if (user.packageExpiryDate)
      return moment(user.packageExpiryDate).add(30, 'days');

    if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      return moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days');
    }

    return '';
  };

  const preservedDate = getPackageExpired();

  return (
    <StyledHeaderLockCharacterBanner>
      <Box className="banner-item">
        <div className="header">
          <Typography
            className="title"
            dangerouslySetInnerHTML={{
              __html: t('headerPreservedCharactersBannerTitle', {
                numberOfCharacters: delimitNumber(lockCharacters, language),
              }),
            }}
          />
          <Typography className="description">
            {`${t('preservedCharactersDate')}: ${
              preservedDate ? moment(preservedDate).format('DD/MM/YYYY') : ''
            }`}
          </Typography>
        </div>
        <div className="button-wrapper">
          <Button className="button" onClick={handleRedirectPayment}>
            {t('renewNow')}
          </Button>
        </div>
      </Box>
    </StyledHeaderLockCharacterBanner>
  );
};

export default SmallLockCharacterBanner;
