import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Typography, DialogTitle, DialogContent } from '@mui/material';
import updateImg from '@src/assets/images/update.svg';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import route from '@src/constants/route';
import { useTranslation } from 'react-i18next';
import {
  StyledDialogMigrateFreePackage,
  StyledModalConvertFreePackage,
} from './index.style';
import ProcessHandler from '../ProcessHandler';

const ModalConvertFreePackage = ({
  openMigrateFreePlanPopup = false,
  setOpenMigrateFreePlanPopup,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpenMigrateFreePlanPopup(false);
    }
  };

  const handleAgreeMigrationFreePlan = async () => {
    setLoading(true);
    const response = await apis.orders.migrateFreePlan();
    setLoading(false);

    if (!response.status)
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'migrationFreePlanFailed',
        }),
      );
    else {
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'migrationFreePlanSuccess',
        }),
      );
      handleClose();
    }
  };

  const handleRedirectToPackagePage = () => history.push(route.PAYMENT);

  return (
    <StyledDialogMigrateFreePackage
      open={openMigrateFreePlanPopup}
      onClose={handleClose}
      maxWidth="lg"
      sx={{ borderRadius: 100 }}
    >
      <StyledModalConvertFreePackage>
        <DialogContent className="dialog-container">
          <img className="img" src={updateImg} alt="update img" />
          <DialogTitle className="dialog-title">
            {t('notificationSwitchTitle')}
          </DialogTitle>
          <Typography className="dialog-content">
            <div
              className="error-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('notificationSwitchTitleContent'),
              }}
            />
          </Typography>
          <div className="dialog-buttons">
            <Button
              className="dialog-button dialog-button-upgrade"
              variant="contained"
              onClick={handleRedirectToPackagePage}
            >
              {t('updatePackage')}
            </Button>
            <Button
              className="dialog-button dialog-button-switch"
              variant="contained"
              color="primary"
              onClick={handleAgreeMigrationFreePlan}
              disabled={loading}
            >
              <ProcessHandler
                color="secondary"
                size={20}
                ml={10.25}
                mr={10.25}
                mt={0.25}
                mb={0.25}
                loading={loading}
              >
                {t('switchToNewFreePackage')}
              </ProcessHandler>
            </Button>
          </div>
        </DialogContent>
      </StyledModalConvertFreePackage>
    </StyledDialogMigrateFreePackage>
  );
};

export default ModalConvertFreePackage;
