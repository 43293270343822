import { PAGINATION_LIMIT, RESOURCE } from '@src/constants';
import { REQUEST_TYPE } from '@src/constants/request';
import { omitIsNil } from '@src/utils/omit';
import api from './api';

// TODO api delete selected dubbing
const deleteDubbingRequest = async (requestIds) => {
  const status = 1;

  return requestIds && status;
};

const createDubbingRequest = async (payload) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.DUBBING}`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};

const createDubbingRequestWithFileVideo = async (payload) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.DUBBING}/video`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};

const getDubbingRequests = async ({
  offset,
  limit = PAGINATION_LIMIT,
  sort,
  search,
  fields,
  startDate,
  endDate,
  status,
  projectId,
  notExistsProject,
}) => {
  const params = omitIsNil({
    offset,
    limit,
    sort,
    type: REQUEST_TYPE.DUBBING,
    search,
    fields,
    startDate,
    endDate,
    status,
    projectId,
    notExistsProject,
  });

  try {
    const response = await api({
      method: 'GET',
      url: RESOURCE.REQUESTS,
      params,
    });
    return response;
  } catch (error) {
    return null;
  }
};

const generateDubbingSubtitles = async (link, source) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.SRT}/extract-from-link`,
      data: { link, source },
      timeout: 15 * 60 * 1000,
    });
    return response;
  } catch (error) {
    return null;
  }
};

const createDubbingProject = async (payload) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.DUBBING}/projects`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};

const getListDubbingProjects = async ({
  search,
  offset,
  limit,
  fields,
  sort,
  startDate,
  endDate,
  status,
}) => {
  try {
    const params = omitIsNil({
      search,
      offset,
      limit,
      fields,
      sort,
      startDate,
      endDate,
      status,
    });

    const response = await api({
      method: 'GET',
      url: `${RESOURCE.DUBBING}/projects`,
      params,
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};
const updateDubbingProject = async (projectId, payload) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `${RESOURCE.DUBBING}/projects/${projectId}`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};

const deleteDubbingProjects = async (projectIds) => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `${RESOURCE.DUBBING}/projects`,
      data: { projectIds },
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};

const getDubbingProjectDetail = async (projectId) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${RESOURCE.DUBBING}/projects/${projectId}`,
    });
    return response;
  } catch (error) {
    return error.response?.result;
  }
};

const getLanguages = async ({ search, searchFields }) => {
  const params = omitIsNil({
    search,
    searchFields,
  });

  const response = await api({
    method: 'GET',
    url: `${RESOURCE.DUBBING}/languages`,
    params,
  });
  return response;
};

export {
  deleteDubbingRequest,
  createDubbingRequest,
  getDubbingRequests,
  generateDubbingSubtitles,
  createDubbingRequestWithFileVideo,
  createDubbingProject,
  getListDubbingProjects,
  updateDubbingProject,
  deleteDubbingProjects,
  getDubbingProjectDetail,
  getLanguages,
};
