import { VBEE_DOMAIN } from '@src/configs';

const setCookie = ({ cname, cvalue, extime, domain }) => {
  const d = new Date();
  d.setTime(d.getTime() + extime);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};domain=${domain};path=/`;
};

const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const removeCookie = (cname) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const removeCookieByDomain = (cname, domain) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
};

const removeCookieFromAllSubdomains = (key) => {
  removeCookie(key);

  const domains = [VBEE_DOMAIN];
  domains.forEach((domain) => {
    removeCookieByDomain(key, domain);
  });
};

export { setCookie, getCookie, removeCookie, removeCookieFromAllSubdomains };
