import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import ROUTES from '@src/constants/route';
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import { Check, Clear } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  DURATION,
  PACKAGE_CODE,
  PACKAGE_EXPIRED,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
} from '@src/constants/package';
import actions from '@src/redux/actions';
import i18n from '@src/languages';
import apis from '@src/apis';
import ProcessHandler from '@src/components/ProcessHandler';
import { LANGUAGE } from '@src/constants';
import { ORDER_STATUS } from '@src/constants/order';
import NotificationDialog from '@src/components/NotificationDialog';
import StackedAvatars from '@src/components/StackedAvatars';
import { VBEE_CONSOLE_URL } from '@src/configs';
import { checkVietNam } from '@src/utils/checkCountry';
import { COLOR } from '@src/styles/color';
import { getPackageCurrentPrice } from '@src/services/package';
import PopularIcon from '@src/assets/icons/popular.svg';

import avatar1 from '@src/assets/avatars/avatar1.png';
import avatar2 from '@src/assets/avatars/avatar2.png';
import avatar3 from '@src/assets/avatars/avatar3.png';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { getSavedPrice } from '@src/services/voucher';
import dataSenses from '@src/services/dataSenses';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';

import {
  StyledNewPackageItem,
  StyledPackageItemWrapper,
} from './indexOld.style';

import CountdownBanner from './CountdownBanner';

const checkPackageDiscountForFirstUser = ({
  packageId,
  isFirstTimePaid,
  firstPaidVoucher,
}) => {
  if (!isFirstTimePaid) return false;
  if (!firstPaidVoucher) return false;
  const { packages } = firstPaidVoucher?.policy || {};
  const packageIds = packages?.map((item) => item.id);

  return packageIds?.includes(packageId);
};

const getPriceForFirstPaidUser = ({
  isVietNam,
  voucher,
  currPrice,
  currUsdPrice,
}) => {
  const savedMoney = getSavedPrice({ voucher, currPrice, currUsdPrice });
  return isVietNam ? currPrice - savedMoney : currUsdPrice - savedMoney;
};

const NewPackageItemOld = ({
  data,
  hasDiscountPackage,
  onDiscountEnd,
  duration,
  isShowAllPackageInfo,
  setIsShowAllPackageInfo,
  isFirstTimePaid,
  firstPaidVoucher,
}) => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { voucherCode, bannerType } = camelcaseKeys(
    queryString.parse(location.search) || {},
  );
  const { user = {} } = useSelector((state) => state.auth);

  const { usingPackage, latestOrder, usingDubbingPackage, latestDubbingOrder } =
    useSelector((state) => state.user);

  const userRef = useRef(user);

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState({
    createFreeOrder: false,
    cancelOrder: false,
  });
  const [highlight, setHighlight] = useState(false);
  const [isDiscountPackage, setIsDiscountPackage] = useState(false);

  const { getFeatureValue } = useFeatureFlags();
  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSensesFromServer = dataSensesFeatures?.s2s;

  const isVietNam = checkVietNam();
  const isDiscountForFirstUser = checkPackageDiscountForFirstUser({
    packageId: data.id,
    isFirstTimePaid,
    firstPaidVoucher,
  });

  const { language } = i18n;

  const {
    code,
    price: originalPrice,
    usdPrice: originalUsdPrice,
    monthlyPrice: originalMonthlyPrice,
    usdMonthlyPrice: originalMonthlyUsdPrice,
  } = data;
  const { price } = getPackageCurrentPrice(data, true);

  const isUsingFreePackage =
    [PACKAGE_LEVEL.BASIC, PACKAGE_LEVEL.DUBBING_BASIC].includes(data.level) &&
    ((!isDubbingRoute && usingPackage.code === data.code) ||
      (isDubbingRoute && usingDubbingPackage?.code === data.code));
  const isPaygPackage =
    code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2 && isVietNam;
  const showPackageTotalPrice =
    duration !== DURATION.MONTHLY && price !== 0 && !isPaygPackage;

  const LIMIT_NUMBER_FEATURES_DISPLAYED = 6;

  const handleCreateFreeOrder = async (packageId) => {
    if (!isDubbingRoute && usingPackage.level === PACKAGE_LEVEL.BASIC) return;

    if (
      isDubbingRoute &&
      usingDubbingPackage?.level === PACKAGE_LEVEL.DUBBING_BASIC
    )
      return;

    if (latestOrder.status === ORDER_STATUS.PENDING) {
      setOpenDialog(true);
      return;
    }

    setLoading({ ...loading, createFreeOrder: true });
    const orderData = await apis.orders.createOrder({
      packageId,
      isGlobal: !isVietNam,
      datasenses: sendDataSensesFromServer
        ? dataSenses.getDataSensesRequireFields()
        : undefined,
    });
    setLoading({ ...loading, createFreeOrder: false });

    if (orderData.status) {
      if (isDubbingRoute)
        dispatch(actions.user.getLatestDubbingOrderSuccess(orderData.result));
      else dispatch(actions.user.getLatestOrderSuccess(orderData.result));
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'buyPackageSuccessfully',
        }),
      );
      if (orderData.result.type === PACKAGE_TYPE.DUBBING) {
        dispatch(
          actions.user.getUsingDubbingPackageSuccess(orderData.result.package),
        );
        dispatch(
          actions.auth.updateUserInfo({
            ...userRef.current,
            dubbing: {
              ...userRef.current.dubbing,
              packageCode: orderData.result?.package?.code,
              packageExpiryDate: orderData.result?.packageExpiryDate,
            },
          }),
        );
        history.push(ROUTES.HOME_DUBBING);
      } else {
        dispatch(actions.user.getUsingPackageSuccess(orderData.result.package));
        history.push(ROUTES.TTS);
      }
      return;
    }

    dispatch(
      actions.noti.push({ severity: 'error', message: 'buyPackageFailure' }),
    );
  };

  const handleBuyPackage = async () => {
    if (data.price === 0) {
      if (isUsingFreePackage) return;
      // window.dataLayer.push({
      //   event: EVENT_TYPE.STUDIO_ACTIVE_FREE_PLAN,
      //   userId: user?.id,
      //   email: user.email,
      //   package: data.code,
      //   createdAt: moment().format('DD/MM/YYYY - HH:mm'),
      // });
      // await delay(1000);
      handleCreateFreeOrder(data.id);
      return;
    }
    const search = voucherCode
      ? `?package=${data.code}&&voucherCode=${voucherCode}&&bannerType=${bannerType}`
      : `?package=${data.code}`;

    if (data.type === PACKAGE_TYPE.API) {
      window.location.assign(`${VBEE_CONSOLE_URL}/payments${search}`);
    } else {
      history.replace({ search });
    }
  };

  const handleCancelPendingOrder = async () => {
    if (!isDubbingRoute && latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (isDubbingRoute && latestDubbingOrder.status !== ORDER_STATUS.PENDING)
      return;

    setLoading({ ...loading, cancelOrder: true });
    const orderData = await apis.orders.cancelOrder(latestOrder.id);
    setLoading({ ...loading, cancelOrder: false });
    if (!orderData.status)
      dispatch(actions.noti.push({ severity: 'error', code: orderData.code }));
    else {
      if (data.result.type === PACKAGE_TYPE.STUDIO)
        dispatch(actions.user.getLatestOrderSuccess(data.result));
      else if (data.result.type === PACKAGE_TYPE.DUBBING)
        dispatch(actions.user.getLatestDubbingOrderSuccess(data.result));

      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'cancelOrderSuccessfully',
        }),
      );
    }

    setOpenDialog(false);
    setLoading({ ...loading, createFreeOrder: false });
  };

  const handleContinuePayingDialog = () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (latestOrder.paymentLink)
      window.location.assign(latestOrder.paymentLink);
    else history.replace({ search: `?package=${data.code}` });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const renderButtonText = () => {
    if (highlight && data.mostPopular) return t('buyNow');
    return t('choose');
  };

  const renderPackagePriceUnit = (value) => {
    if (!value) return '';
    if (value < 1) return `/${t('character')}`;
    return `/${t('month')}`;
  };

  const renderBilledTotalDesc = (packageDuration) => {
    switch (packageDuration) {
      case PACKAGE_EXPIRED.MONTHLY:
        return t('billedMonthly');
      case PACKAGE_EXPIRED.QUARTERLY:
        return t('billedQuarterly');
      case PACKAGE_EXPIRED.YEARLY:
        return t('billedAnnually');
      default:
        return '';
    }
  };

  // display buy button when
  // 1. package item is free and user is not using package, paid order expired
  // 2. package item is free and user is using trial package
  // 3. package item is not free
  const checkDisplayBuyButton = (studioPackages, dubbingPackages) => {
    const basicTrialStudioPackages = [PACKAGE_LEVEL.TRIAL];
    const basicTrialDubbingPackages = [PACKAGE_LEVEL.DUBBING_TRIAL];

    if (isDubbingRoute)
      return (
        data.price > 0 ||
        !dubbingPackages?.id ||
        basicTrialDubbingPackages.includes(dubbingPackages?.level)
      );

    return (
      data.price > 0 ||
      !studioPackages?.id ||
      basicTrialStudioPackages.includes(studioPackages?.level)
    );
  };
  const displayBuyButton = checkDisplayBuyButton(
    usingPackage,
    usingDubbingPackage,
  );

  const renderPrice = (priceValue, monthlyPrice) => (
    <>
      <Typography
        className={classnames('price', {
          'price-en': !isVietNam && priceValue > 0,
        })}
      >
        {!isVietNam && <span className="unit-price">$</span>}
        {isVietNam && <span className="unit-price underlined">đ</span>}
        {/* PSUGO pakage have month price < 1 */}
        {monthlyPrice > 1
          ? monthlyPrice.toLocaleString(language)
          : priceValue.toLocaleString(language)}
      </Typography>
      <Typography className="sub-price">
        {renderPackagePriceUnit(data.price)}
      </Typography>
    </>
  );

  const renderTotalPrice = (value) => (
    <>
      <Typography
        className={classnames('original-price', {
          'price-en': !isVietNam && value > 0,
        })}
      >
        {!isVietNam && (
          <span className="original-price-unit font-size-14">$</span>
        )}
        {isVietNam && <span className="original-price-unit underlined">đ</span>}
        {value}
      </Typography>
      <Typography className="sub-origin-price">
        {renderBilledTotalDesc(data.expiresIn)}
      </Typography>
    </>
  );

  const MostPopularBanner = () => (
    <div className="most-popular-banner">
      <img src={PopularIcon} alt="most-popular" />
      <Typography className="most-popular-text">{t('mostPopular')}</Typography>
      <img src={PopularIcon} alt="most-popular" />
    </div>
  );

  const EmptyBanner = () => <div className="regular-package-banner" />;

  useEffect(() => {
    const { startAt, endAt } = data.discountConfig || {};
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);
    if (inDiscountTime || data.mostPopular) {
      setHighlight(true);
    } else setHighlight(false);

    if (inDiscountTime) setIsDiscountPackage(true);
    else setIsDiscountPackage(false);
  }, [data, hasDiscountPackage]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  const renderBannerPackage = (discount = {}, mostPopular) => {
    const { startAt, endAt } = discount;
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);

    if (inDiscountTime)
      return (
        <CountdownBanner
          date={new Date(endAt).getTime() + 100} //  + 100ms to fix countdown bug
          onComplete={onDiscountEnd}
          completeComponent={
            mostPopular ? <MostPopularBanner /> : <EmptyBanner />
          }
        />
      );
    if (mostPopular) return <MostPopularBanner />;

    return <EmptyBanner />;
  };

  const PackageHeader = () => (
    <div className="package-header">
      <div className="">
        <Typography className="package-name">
          {data.name && (data.name[language] || data.name[LANGUAGE.EN])}
        </Typography>
        {isDiscountPackage && (
          <Typography className="package-limited-deal">
            {t('limitedTime')}
          </Typography>
        )}
        {isDiscountForFirstUser && (
          <Typography
            className="package-limited-deal"
            dangerouslySetInnerHTML={{
              __html: t('discountForFirstPurchase', {
                percent: firstPaidVoucher.value || 0,
              }),
            }}
          />
        )}
      </div>
      {data.icon ? (
        <div
          className={`${highlight ? 'package-icon-active' : 'package-icon'}`}
        >
          <img src={data.icon} alt="icon-type" />
        </div>
      ) : (
        <SchoolIcon
          fontSize="small"
          className={classnames({ 'most-popular': data.mostPopular })}
        />
      )}
    </div>
  );

  const PackagePrice = () => {
    const originalVndPackagePrice = originalMonthlyPrice || originalPrice;
    const originalUsdPackagePrice = originalMonthlyUsdPrice || originalUsdPrice;

    let monthlyPrice = isVietNam ? data.monthlyPrice : data.usdMonthlyPrice;
    let totalPrice = isVietNam ? data.price : data.usdPrice;

    if (isDiscountPackage) {
      monthlyPrice = isVietNam
        ? data?.discountConfig?.monthlyPrice
        : data?.discountConfig?.usdMonthlyPrice;

      totalPrice = isVietNam
        ? data?.discountConfig?.price
        : data?.discountConfig?.usdPrice;
    }

    if (isDiscountForFirstUser) {
      monthlyPrice = getPriceForFirstPaidUser({
        isVietNam,
        voucher: firstPaidVoucher,
        currPrice: data.monthlyPrice,
        currUsdPrice: data.usdMonthlyPrice,
      });
      totalPrice = getPriceForFirstPaidUser({
        isVietNam,
        voucher: firstPaidVoucher,
        currPrice: data.price,
        currUsdPrice: data.usdPrice,
      });
    }
    return (
      <>
        <div className="price-wrapper">
          {(isDiscountPackage || isDiscountForFirstUser) && (
            <div className="price-original-discount">
              <Typography>
                {!isVietNam && (
                  <span className="discount-unit-price font-size-14">$</span>
                )}
                {isVietNam && (
                  <span className="discount-unit-price underlined">đ</span>
                )}
                <span className="original-price">
                  {`${
                    isVietNam
                      ? originalVndPackagePrice?.toLocaleString(language)
                      : originalUsdPackagePrice?.toLocaleString(language)
                  }`}
                </span>
              </Typography>
            </div>
          )}
          {/* Render monthly price */}
          <div className="price-package">
            {isVietNam
              ? renderPrice(price, monthlyPrice)
              : renderPrice(price, monthlyPrice)}
          </div>
        </div>

        {showPackageTotalPrice ? (
          <div className="original-price-wrapper">
            {isVietNam
              ? renderTotalPrice(totalPrice?.toLocaleString(language))
              : renderTotalPrice(`${totalPrice?.toLocaleString(language)}`)}
          </div>
        ) : (
          <div className="original-price-wrapper" />
        )}
      </>
    );
  };

  const displayedContents = isShowAllPackageInfo
    ? data?.contents
    : data?.contents?.slice(0, LIMIT_NUMBER_FEATURES_DISPLAYED);

  return (
    <StyledPackageItemWrapper>
      {renderBannerPackage(data.discountConfig, data.mostPopular)}
      <StyledNewPackageItem
        highlight={highlight}
        isDiscountPackage={
          isDiscountPackage || (data.mostPopular && !hasDiscountPackage)
        }
        isMostPopular={data.mostPopular}
        free={data.price ? 0 : 1}
      >
        <div className="card-package">
          {/** Package Header */}
          <PackageHeader />

          {/** Package Price */}
          <PackagePrice />

          {/** Button buy package */}
          <div className="card-content">
            <Button
              fullWidth
              className={classnames('button-start', {
                'button-highlight': highlight && data.mostPopular,
              })}
              variant={highlight && data.mostPopular ? 'contained' : 'outlined'}
              onClick={handleBuyPackage}
              disabled={!displayBuyButton}
            >
              {renderButtonText()}
            </Button>

            {/* Package popularity */}
            {data.mostPopular ? (
              <div className="popularity">
                <StackedAvatars images={[avatar1, avatar2, avatar3]} />
                <Typography
                  className="popularity-text"
                  variant="body2"
                  sx={{ color: COLOR.black[64] }}
                >
                  {t('mostPeopleChooseThis', {
                    percent: data?.popularPercent || 86,
                  })}
                </Typography>
              </div>
            ) : (
              <div style={{ height: '30px' }} />
            )}

            <Divider sx={{ mt: '12px' }} />
            {/* Package features */}
            <List>
              {data?.contents &&
                data?.contents?.map((item) => (
                  <Collapse
                    in={
                      isShowAllPackageInfo ||
                      (displayedContents && displayedContents.includes(item))
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box display="flex" justifyContent="space-between">
                      <ListItem className="clarify" key={item.vi}>
                        <Box display="flex" justifyContent="space-between">
                          <div
                            alignItems="flex-start"
                            className="icon-contents"
                          >
                            {item.active ? (
                              <Check fontSize="small" color="success" />
                            ) : (
                              <Clear fontSize="small" color="light" />
                            )}
                          </div>
                          <div
                            className={
                              item.active ? 'content' : 'content-deactivate'
                            }
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                language === LANGUAGE.VN
                                  ? item?.[language]
                                  : item[LANGUAGE.EN],
                              ),
                            }}
                          />
                        </Box>
                      </ListItem>
                      {[PACKAGE_LEVEL.BASIC, PACKAGE_LEVEL.TRIAL].includes(
                        data.level,
                      ) &&
                        item.en.includes('Voices') && (
                          <Box paddingTop="8px">
                            <Tooltip
                              arrow
                              title={t('internationalVoicesNotSupport')}
                              placement="top"
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  width: '16px',
                                  height: '16px',
                                  color: COLOR.darkV2[32],
                                }}
                              />
                            </Tooltip>
                          </Box>
                        )}
                    </Box>
                  </Collapse>
                ))}
            </List>
            {data?.contents?.length > LIMIT_NUMBER_FEATURES_DISPLAYED && (
              <Button
                variant="text"
                className="show-more-btn"
                onClick={() => setIsShowAllPackageInfo(!isShowAllPackageInfo)}
                disableRipple
              >
                {isShowAllPackageInfo ? t('collapse') : t('showMore')}
              </Button>
            )}
          </div>
        </div>
        {data.price === 0 && (
          <NotificationDialog
            name="pendingOrder"
            title={t('havePendingOrderTitle')}
            description={t('hasOrderWaitForConfirmTransferDescription')}
            variant="warning"
            open={openDialog}
            onClose={handleCloseDialog}
            actionComponent={
              <>
                <Button variant="outlined" onClick={handleCancelPendingOrder}>
                  <ProcessHandler loading={loading.cancelOrder} size={25}>
                    {t('cancelOrder')}
                  </ProcessHandler>
                </Button>
                <Button
                  variant="contained"
                  onClick={handleContinuePayingDialog}
                >
                  {t('continue')}
                </Button>
              </>
            }
          />
        )}
      </StyledNewPackageItem>
    </StyledPackageItemWrapper>
  );
};

export default NewPackageItemOld;
