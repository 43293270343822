import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { COLOR } from '@src/styles/color';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledDialog = styled(Dialog)`
  min-width: ${(props) => props.width || 'auto'};
  .MuiDialog-paper {
    ${(props) =>
      props.useNewLegal &&
      `
        max-width: 812px;
        border-radius: 24px;
      `}
  }

  li::marker {
    color: ${COLOR.blueV2};
  }

  .dialog-wrapper {
    display: flex;
    flex-direction: column;
    padding: ${(props) => (props.useNewLegal ? '24px' : '16px 20px 0px 20px')};
    gap: 16px;
    height: ${(props) => (props.useNewLegal ? 'auto' : '512px')};

    .dialog-logo {
      margin-bottom: 4px;
    }

    .new-dialog-title {
      font-weight: 510;
      font-size: 24px;
      line-height: 32px;
      color: ${COLOR.black.default};
      text-transform: uppercase;
      word-wrap: break-word;
    }

    .dialog-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: ${COLOR.black.default};
      text-transform: uppercase;
    }

    ul {
      font-size: 16px;
      font-weight: 510;
      line-height: 24px;
      letter-spacing: -0.4px;
    }

    .dialog-content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      overflow-y: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background: ${COLOR.white};
      }
      &::-webkit-scrollbar-thumb {
        background: ${COLOR.subText};
        border-radius: 100px;
        border: 1px solid ${COLOR.subText};
      }

      a:-webkit-any-link {
        color: ${COLOR.blue};
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .dialog-checkbox {
      display: flex;
      justifiy-content: start;
      align-items: start;

      .checkbox {
        padding: 0px 6px 0px 0px;
        margin-left: -2px;
        color: ${COLOR.darkBlue};
      }

      .show-text-checkbox-note {
        font-weight: 400;
        color: ${COLOR.functionalRed.default};
        font-size: 14px;
      }

      .not-show-text-checkbox-note {
        font-weight: 400;
        color: ${COLOR.functionalRed.default};
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
      }

      .text-checkbox-content {
        font-weight: ${(props) => (props.useNewLegal ? 400 : 500)};
        font-size: ${(props) => (props.useNewLegal ? '16px' : '14px')};
        line-height: 24px;
        a {
          font-weight: ${(props) => (props.useNewLegal ? 510 : 500)};
        }
      }
    }

    .dialog-action {
      margin-left: auto;
      margin-bottom: ${(props) => (props.useNewLegal ? '0' : '16px')};
    }

    .button-agree-policy {
      border-radius: ${(props) => props.useNewLegal && '12px'};
      text-transform: ${(props) => props.useNewLegal && 'none'};
      font-weight: ${(props) => props.useNewLegal && 700};
      color: ${(props) => props.useNewLegal && COLOR.darkBlue};
      font-size: ${(props) => props.useNewLegal && '14px'};
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .logo-img {
      height: 32px;
    }

    .dialog-wrapper {
      .new-dialog-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      ul {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .dialog-content {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }
      .dialog-checkbox {
        .text-checkbox-content {
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 20px !important;

          a {
            font-weight: ${(props) => (props.useNewLegal ? 400 : 500)};
          }
        }

        .show-text-checkbox-note {
          font-size: 12px;
        }

        .not-show-text-checkbox-note {
          font-size: 12px;
          line-height: 16px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
`;
export { StyledDialog };
