import { PACKAGE_TYPE } from '@src/constants/package';

const {
  useCheckCloningRoute,
  useCheckDubbingRoute,
  useCheckSTTRoute,
  useCheckAPIRoute,
} = require('./useCheckDubbingRoute');

// TODO: API if needed
export const useCheckProductName = () => {
  const checkCloning = useCheckCloningRoute();
  const checkDubbing = useCheckDubbingRoute();
  const checkSTT = useCheckSTTRoute();
  const checkAPI = useCheckAPIRoute();
  if (checkCloning) return PACKAGE_TYPE.CLONING;
  if (checkDubbing) return PACKAGE_TYPE.DUBBING;
  if (checkSTT) return PACKAGE_TYPE.STT;
  if (checkAPI) return PACKAGE_TYPE.API;
  return PACKAGE_TYPE.STUDIO;
};
