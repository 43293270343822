import React from 'react';
import {
  Checkbox,
  IconButton,
  // TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  UnfoldMore,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { StyledTableCell } from './index.style';

const CustomTableHeader = ({
  columns,
  selection,
  sort,
  showNumber,
  numSelected,
  rowCount,
  onSelectAll,
  onChangeSort,
  renderExtraHeader,
  showExtraHeaderComponent,
  headerStyle,
  isShowCheckbox = true,
}) => {
  const { t } = useTranslation();

  const showSelection =
    selection && renderExtraHeader && showExtraHeaderComponent;

  const renderSorter = (column) => {
    if (!column.sortable) return '';
    if (sort) {
      const [sortField, sorter] = sort.split('_');
      if (column.field === sortField)
        return sorter === 'asc' ? (
          <IconButton onClick={() => onChangeSort(`${column.field}_desc`)}>
            <KeyboardArrowDown className="header-icon" fontSize="small" />
          </IconButton>
        ) : (
          <IconButton onClick={() => onChangeSort(`${column.field}_asc`)}>
            <KeyboardArrowUp className="header-icon" fontSize="small" />
          </IconButton>
        );
    }
    return (
      <IconButton
        sx={{ opacity: '0.5' }}
        onClick={() => onChangeSort(`${column.field}_asc`)}
      >
        <UnfoldMore className="header-icon" fontSize="small" />
      </IconButton>
    );
  };

  return (
    <TableHead style={headerStyle}>
      <TableRow>
        {selection && isShowCheckbox && (
          <StyledTableCell
            className="header-checkbox-cell"
            padding="checkbox"
            align="left"
          >
            <Checkbox
              className="header-checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAll}
            />
          </StyledTableCell>
        )}
        {showNumber && (
          <StyledTableCell className="header-cell" align="center">
            <Typography className="header-title">{t('no')}</Typography>
          </StyledTableCell>
        )}
        {columns.map((column, index) => (
          <StyledTableCell
            className="header-cell"
            key={column.field}
            align={column.align}
            width={column.width}
          >
            {showSelection && (index === 0 || index === columns.length - 1) ? (
              renderExtraHeader(index)
            ) : (
              <div
                id={column.id}
                className={`header-item header-${column.align} ${
                  showSelection && 'hidden'
                }`}
              >
                <Typography className="header-title">{column.title}</Typography>
                {renderSorter(column)}
              </div>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
