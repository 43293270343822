import { styled } from '@mui/material/styles';

export const StyledNoData = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding: 12px 0;
  height: ${(props) => props.height || '100%'};
  justify-content: center;
`;
