import { RESOURCE } from '@src/constants';
import { omitIsNil } from '@src/utils/omit';
import { API_URL } from '@src/configs';
import api from './api';

const getVoices = async ({
  search,
  languageCode,
  gender,
  features,
  level,
  offset,
  limit,
  active,
  isSample,
  sort,
  hasDubbing,
  code,
}) => {
  const params = omitIsNil({
    search,
    languageCode,
    gender,
    features,
    level,
    offset,
    limit,
    active,
    isSample,
    sort,
    hasDubbing,
    code,
  });

  const response = await api({
    method: 'GET',
    url: RESOURCE.VOICES,
    params,
  });
  return response;
};

const getVoicesV2 = async ({
  search,
  languageCode,
  gender,
  features,
  level,
  offset,
  limit,
  active,
  isSample,
  sort,
  hasDubbing,
  code,
  voiceOwnership,
}) => {
  const params = omitIsNil({
    search,
    languageCode,
    gender,
    features,
    level,
    offset,
    limit,
    active,
    isSample,
    sort,
    hasDubbing,
    code,
    voiceOwnership,
  });

  const response = await api({
    method: 'GET',
    url: `${API_URL}/api/v2${RESOURCE.VOICES}`,
    params,
  });
  return response;
};

const getLanguages = async ({ search, searchFields }) => {
  const params = omitIsNil({
    search,
    searchFields,
  });

  const response = await api({
    method: 'GET',
    url: RESOURCE.LANGUAGES,
    params,
  });
  return response;
};

const createDictionary = async (words) => {
  try {
    const response = await api({
      method: 'POST',
      url: RESOURCE.DICTIONARY,
      data: { words },
    });
    return response;
  } catch {
    return null;
  }
};

const getDictionary = async () => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.DICTIONARY,
  });
  return response;
};

export {
  getVoices,
  getLanguages,
  createDictionary,
  getDictionary,
  getVoicesV2,
};
