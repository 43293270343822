import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledUpgradePrompt = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
  padding: 50px 0;
  color: ${COLOR.black.default};

  .upgrade-prompt-title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .upgrade-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLOR.black.default};
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 12px;
  }
`;
