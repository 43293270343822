import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const MobileDubbingContainer = styled('div')`
  .icon-mobile-web {
    height: 100px;
    width: 148.13px;
    margin-top: 16px;
  }

  .button-copy-url {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${COLOR.darkBlue};
    background-color: ${COLOR.yellow.default};
    border-radius: 50px;
    padding: 12px 48px;
    width: 100%;
    margin-top: 16px;
  }

  .copy-icon {
    color: ${COLOR.darkBlue};
    width: 24px;
    height: 24px;
  }

  .checked-icon {
    border-radius: 50%;
    background-color: ${COLOR.darkBlue};
    color: ${COLOR.yellow.default};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-copy-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
    text-transform: none;
  }
`;

export const ContentMobileDubbing = styled('div')`
  color: #1c1c1e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  text-align: center;
  padding: 8px 8px;
`;

export const ListFeatureDubbing = styled('div')`
  padding: 8px 16px;
`;

export const FeatureDubbingContainer = styled('div')`
  display: flex;
  gap: 8px;
  padding: 4px 8px;

  .small-check-icon {
    width: 24px;
    height: 24px;
    color: rgba(30, 22, 78, 0.64);
  }
`;

export const Feature = styled('div')`
  display: flex;
  align-items: center;
  color: #1c1c1e;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
`;
