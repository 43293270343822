import { COLOR } from '@src/styles/color';
import styled from 'styled-components';

export const NewUserInformationNoteWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.charcoal};
  padding: 16px;

  .header-user-info {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 12px;
  }

  .user-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .username {
    color: black;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
  }

  .package-info-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .package-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .package-info {
    color: rgba(28, 28, 30, 0.64);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
  }

  .package-info-value {
    color: ${COLOR.darkBlue};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .package-info-value-expired {
    color: ${COLOR.functionalRed.default};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .button-upgrade {
    margin-top: 12px;
    border-radius: 12px;
    background-color: ${COLOR.yellow.default};
    color: ${COLOR.darkBlue};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
    text-transform: none;
  }

  .avatar {
    border: 2px solid ${COLOR.primary};
    border-radius: 100%;
  }

  .box-icon {
    border-radius: 50%;
    z-index: 10000;
    background-color: ${COLOR.primary};
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    width: 12.31px;
    height: 12.31px;
    color: ${COLOR.secondary[8]};
  }
`;
