import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';
import { checkVietNam } from '@src/utils/checkCountry';

import apis from '@src/apis';
import {
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
  PREFIX_AUTO_RENEW,
} from '@src/constants/package';
import actions from '@src/redux/actions';
import {
  Box,
  Divider,
  FormControlLabel,
  MenuItem,
  MenuList,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import { ArrowDropUpRounded, ArrowDropDownRounded } from '@mui/icons-material';
import { LANGUAGE } from '@src/constants';
import CustomRadio from '@src/components/CustomRadio';
import { COLOR } from '@src/styles/color';
import {
  checkAutoRenewalPackage,
  getPackageCurrentPrice,
} from '@src/services/package';
import { checkDiscount } from '@src/services/voucher';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import CustomSwitch from '@src/components/Switch';
import {
  StyledPackageInfo,
  StyledPackageInfoItem,
  StyledPopover,
} from './index.style';

const DURATION_REGEX = {
  MONTHLY: /MONTH/i,
  QUARTERLY: /QUART/i,
  YEARLY: /YEAR/i,
};

const isSameDuration = (p1, p2) => {
  if (DURATION_REGEX.MONTHLY.test(p1) && DURATION_REGEX.MONTHLY.test(p2))
    return true;
  if (DURATION_REGEX.QUARTERLY.test(p1) && DURATION_REGEX.QUARTERLY.test(p2))
    return true;
  if (DURATION_REGEX.YEARLY.test(p1) && DURATION_REGEX.YEARLY.test(p2))
    return true;
  return false;
};

const PackageDuration = ({
  data,
  originMonthlyPrice,
  originalNormalPackageMonthlyPrice,
  isShowAutoRenewalPackage,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const isMonthly = DURATION_REGEX.MONTHLY.test(data.code);
  const isQuarterly = DURATION_REGEX.QUARTERLY.test(data.code);
  const isYearly = DURATION_REGEX.YEARLY.test(data.code);
  const isVietNam = checkVietNam();

  const getDuration = () => {
    if (isMonthly) return t('monthlyPackage');
    if (isQuarterly) return t('quarterlyPackage');
    if (isYearly) return t('yearlyPackage');

    return '';
  };

  const getDurationLabel = () => {
    if (isMonthly) return t('month');
    if (isQuarterly) return t('quarter');
    if (isYearly) return t('year');

    return '';
  };

  const isDiscountPackage = checkDiscount(data);

  const { price, usdPrice, monthlyPrice, usdMonthlyPrice } =
    getPackageCurrentPrice(data, true);
  const packagePrice = isVietNam
    ? monthlyPrice || price
    : usdMonthlyPrice || usdPrice;

  const getSavePrice = () => {
    const packageOriginalPrice = isVietNam
      ? data.price || data.originalPrice
      : data.usdPrice || data.originalUsdPrice;

    if (isMonthly) return null;
    if (isQuarterly) return originMonthlyPrice * 3 - packageOriginalPrice;

    if (isYearly) return originMonthlyPrice * 12 - packageOriginalPrice;

    return null;
  };

  const getSavePriceDiscount = () => {
    const packageOriginalPrice = isVietNam ? price : usdPrice;

    if (isMonthly) return null;
    if (isQuarterly) return originMonthlyPrice * 3 - packageOriginalPrice;

    if (isYearly) return originMonthlyPrice * 12 - packageOriginalPrice;

    return null;
  };

  const Price = ({
    showingPrice,
    lineThrough = false,
    duration = '',
    isOriginalPrice = false,
  }) => (
    <div className="price">
      {isVietNam && (
        <span
          className={`underline ${
            lineThrough || duration || isOriginalPrice
              ? 'original-price-unit font-size-14'
              : 'discount-price-unit font-size-16'
          }`}
        >
          đ
        </span>
      )}
      {!isVietNam && (
        <span
          className={`${
            lineThrough || duration || isOriginalPrice
              ? 'original-price-unit font-size-14'
              : 'discount-price-unit font-size-14'
          }`}
        >
          $
        </span>
      )}
      {isOriginalPrice ? (
        <span
          className={`original-price ${lineThrough ? 'line-through ' : ''}`}
        >
          {`${showingPrice?.toLocaleString(language)}${
            duration.length > 0 ? `/${duration}` : ''
          }`}
        </span>
      ) : (
        <span className="discount-price">
          {showingPrice?.toLocaleString(language)}
        </span>
      )}
    </div>
  );

  const PackageName = () => (
    <div className="title-wrapper">
      <div className="title">{getDuration()}</div>
      {isYearly && (
        <div className="most-popular banner">
          <span> {t('bestPrice')}</span>
        </div>
      )}
    </div>
  );

  const PackagePriceMonth = () => (
    <div className="price-wrapper">
      {(isDiscountPackage || isShowAutoRenewalPackage) &&
        originalNormalPackageMonthlyPrice && (
          <Price
            showingPrice={originalNormalPackageMonthlyPrice}
            lineThrough
            isOriginalPrice
          />
        )}
      <Price showingPrice={packagePrice} />
    </div>
  );

  const PackageThrifty = () => {
    const durationPrice = isVietNam ? price : usdPrice;

    return (
      !isMonthly && (
        <>
          <Box>
            {((isDiscountPackage && getSavePriceDiscount() > 0) ||
              (!isDiscountPackage && getSavePrice() > 0)) && (
              <div className="flex flex-end text-14 text-dark-v2-64 align-item-center">
                <span>{t('save')}&nbsp;</span>
                <Price
                  showingPrice={
                    isDiscountPackage ? getSavePriceDiscount() : getSavePrice()
                  }
                  isOriginalPrice
                />
              </div>
            )}
          </Box>
          <Box>
            <div className="flex flex-end text-14 text-dark-v2-64">
              <Price
                showingPrice={durationPrice}
                duration={getDurationLabel()}
                isOriginalPrice
              />
            </div>
          </Box>
        </>
      )
    );
  };

  return (
    <StyledPackageInfoItem>
      <div className="package-info-title-and-price">
        <PackageName />
        <PackagePriceMonth />
      </div>

      <div className="flex space-between">
        <PackageThrifty />
      </div>
    </StyledPackageInfoItem>
  );
};

const PackageDurationSelect = ({
  packages = [],
  level,
  onSelectPackage,
  selectedPackage,
  isShowAutoRenewalPackage,
}) => {
  const isVietNam = checkVietNam();

  const sameLevelPackages = packages
    .filter((item) => {
      if (isShowAutoRenewalPackage)
        return item?.level === level && checkAutoRenewalPackage(item);

      return item.level === level && !checkAutoRenewalPackage(item);
    })
    .sort((item1, item2) => item1.price - item2.price);

  const monthlyPackage = sameLevelPackages.find((item) => {
    if (isShowAutoRenewalPackage)
      return (
        checkAutoRenewalPackage(item) && DURATION_REGEX.MONTHLY.test(item.code)
      );
    return (
      !checkAutoRenewalPackage(item) && DURATION_REGEX.MONTHLY.test(item.code)
    );
  });

  const sameLevelNormalPackages = packages.filter(
    (item) => item.level === level && !checkAutoRenewalPackage(item),
  );
  const isInDiscount = checkDiscount(monthlyPackage?.discountConfig || {});

  let originMonthlyPrice;
  if (isVietNam) {
    originMonthlyPrice = isInDiscount
      ? monthlyPackage?.discountConfig?.monthlyPrice
      : monthlyPackage?.price;
  } else {
    originMonthlyPrice = isInDiscount
      ? monthlyPackage?.discountConfig?.usdMonthlyPrice
      : monthlyPackage?.usdPrice;
  }

  const getNormalMonthlyPrice = (autoRenewPackage) => {
    const sameLevelPackage = sameLevelNormalPackages.find((p) =>
      autoRenewPackage.code.includes(p.code),
    );
    const isDiscountPackage = checkDiscount(sameLevelPackage);
    const isDiscountAutoRenewPackage = checkDiscount(autoRenewPackage);
    const autoRenewPackageMonthlyPrice = isDiscountAutoRenewPackage
      ? autoRenewPackage?.discountConfig?.monthlyPrice
      : autoRenewPackage?.monthlyPrice;

    if (
      isDiscountPackage &&
      sameLevelPackage?.discountConfig?.monthlyPrice >
        autoRenewPackageMonthlyPrice
    ) {
      return isVietNam
        ? sameLevelPackage?.discountConfig?.monthlyPrice
        : sameLevelPackage?.discountConfig?.usdMonthlyPrice;
    }

    if (sameLevelPackage?.monthlyPrice < autoRenewPackageMonthlyPrice)
      return null;

    if (isVietNam)
      return sameLevelPackage?.monthlyPrice || sameLevelPackage?.price;
    return sameLevelPackage?.usdMonthlyPrice || sameLevelPackage?.usdPrice;
  };

  const handleSelect = (item) => onSelectPackage(item.code);

  return (
    <div className="package-duration-select">
      <RadioGroup
        value={selectedPackage}
        aria-labelledby="row-radio-buttons-group-package-duration"
        name="row-radio-buttons-group"
        className="flex-col"
      >
        {sameLevelPackages.map((item) => {
          let originalNormalPackageMonthlyPrice;
          if (isShowAutoRenewalPackage) {
            originalNormalPackageMonthlyPrice = getNormalMonthlyPrice(item);
          } else {
            originalNormalPackageMonthlyPrice = isVietNam
              ? item?.monthlyPrice
              : item?.usdMonthlyPrice;
          }

          return (
            <Box key={item.code} onClick={() => handleSelect(item)}>
              <div className="flex align-item-start pb-12 pt-12">
                <FormControlLabel
                  value={item.code}
                  control={<CustomRadio sx={{ paddingLeft: '11px' }} />}
                  label=""
                />

                <PackageDuration
                  data={item}
                  originMonthlyPrice={originMonthlyPrice}
                  originalNormalPackageMonthlyPrice={
                    originalNormalPackageMonthlyPrice
                  }
                  isShowAutoRenewalPackage={isShowAutoRenewalPackage}
                />
              </div>
              <Divider />
            </Box>
          );
        })}
      </RadioGroup>
    </div>
  );
};

const PackageInfo = ({
  onChangeSelectedPackageCode,
  selectedPackageCode,
  isShowAutoRenewalPackage,
  setIsShowAutoRenewalPackage,
}) => {
  const history = useHistory();
  const isDubbingRoute = useCheckDubbingRoute();

  const { package: packageState } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);
  const { getFeatureValue } = useFeatureFlags();
  const isVietNam = checkVietNam();

  const autoRenewPayment = getFeatureValue(FEATURE_KEYS.AUTO_RENEWAL_PAYMENT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const discount = '20%';

  const {
    package: packageCode,
    voucherCode,
    bannerType,
  } = camelcaseKeys(queryString.parse(location.search) || {});

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => setAnchorEl(null);

  const changeRoute = (code) => {
    const search = queryString.stringify({
      package: code,
      voucherCode,
      bannerType,
    });
    history.replace({ search });
  };

  const handleSelectLevel = (item) => {
    const curPackage = packages.find((p) => p?.code === selectedPackage);
    const sameLevelPackages = packages.filter((p) => p?.level === item?.level);

    const newSelectedPackage = sameLevelPackages.find((p) => {
      if (checkAutoRenewalPackage(curPackage))
        return (
          checkAutoRenewalPackage(p) && isSameDuration(p.code, curPackage?.code)
        );
      return isSameDuration(p.code, curPackage?.code);
    });

    setSelectedPackage(newSelectedPackage?.code || '');
    dispatch(actions.order.updatePackage(newSelectedPackage));
    changeRoute(newSelectedPackage?.code);
  };

  const handleSelectPackage = (item) => {
    const newLevelPackage = packages.find((p) => p.code === item);
    setSelectedPackage(item);
    dispatch(actions.order.updatePackage(newLevelPackage));

    changeRoute(item);
  };

  const getUniqueLevelPackages = (packageList = []) => {
    const uniquePackages = [];

    packageList
      .filter((item) => isSameDuration(item.code, selectedPackage))
      .forEach((item) => {
        if (!uniquePackages.find((p) => p.level === item.level)) {
          uniquePackages.push(item);
        }
      });

    return uniquePackages;
  };

  const fetchPackages = async () => {
    setLoading(true);
    const res = await apis.packages.getPackages({
      type: isDubbingRoute ? PACKAGE_TYPE.DUBBING : PACKAGE_TYPE.STUDIO,
    });
    if (res?.status) {
      const { result = [] } = res;
      const packageList = [];

      result?.packages.forEach((item) => {
        if (!item.active) return;
        if ([PACKAGE_LEVEL.TRIAL, PACKAGE_LEVEL.BASIC].includes(item.level))
          return;
        if (item.originalPackageCode) {
          const originalPackage = result?.packages.find(
            (pkgItem) => pkgItem.code === item.originalPackageCode,
          );
          if (originalPackage) packageList.push({ ...item, originalPackage });
          return;
        }
        packageList.push(item);
      });

      setPackages(packageList);

      let packageSelected;
      if (selectedPackageCode) {
        packageSelected = packageList?.find(
          (item) => item.code === selectedPackageCode,
        );
      } else {
        packageSelected = packageList?.find(
          (item) => item.code === packageCode,
        );
      }

      if (packageSelected) {
        setSelectedPackage(packageSelected.code);
        dispatch(actions.order.updatePackage(packageSelected));
      }
      if (selectedPackageCode) onChangeSelectedPackageCode('');
    }
    setLoading(false);
  };

  // Check package exist and replace history
  const checkPackageExistAndReplaceHistory = (type, typeString) => {
    if (location.pathname.includes(typeString)) {
      const packageExist = packages.find(
        (item) => item.code === packageCode && item.type === type,
      );
      if (!packageExist) {
        history.replace(location.pathname);
      }
    }
  };

  useEffect(() => {
    if (packages.length) {
      checkPackageExistAndReplaceHistory(PACKAGE_TYPE.DUBBING, 'dubbing');
      checkPackageExistAndReplaceHistory(PACKAGE_TYPE.STUDIO, 'studio');
    }
  }, [packages, packageCode]);

  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    if (selectedPackageCode) fetchPackages();
  }, [selectedPackageCode]);

  return (
    <StyledPackageInfo>
      {loading ? (
        <Box className="package-header vertical-center">
          <Skeleton
            animation="wave"
            variant="circular"
            width={48}
            height={48}
            sx={{ bgcolor: COLOR.grayV2[100] }}
          />
          <Skeleton
            animation="wave"
            width={150}
            height={48}
            sx={{ bgcolor: COLOR.grayV2[100] }}
          />
        </Box>
      ) : (
        <Box className="package-header vertical-center">
          <Box display="flex" gap="6px" onClick={handleClickPopover}>
            <div className="package-icon-active">
              <img src={packageState?.icon} alt="icon-type" />
            </div>
            <div className="vertical-center">
              <Typography className="package-name">
                {packageState?.name &&
                  (packageState?.name[language] ||
                    packageState?.name[LANGUAGE.EN])}
              </Typography>
            </div>
            <Box className="vertical-center">
              {openPopover ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
            </Box>
          </Box>
          {autoRenewPayment && isVietNam && !isDubbingRoute && (
            <Box className="package-toggle">
              <div className="banner auto-renewal">
                <span> {t('autoRenewal')}</span>
              </div>
              <div className="save-percent banner">
                <span className="website-label">
                  {t('savePercent', { discount })}
                </span>
                <span className="mobile-label">{`- ${discount}`}</span>
              </div>
              <CustomSwitch
                className="custom-switch"
                checked={isShowAutoRenewalPackage}
                noneCheckedColor={COLOR.secondary[80]}
                checkedColor={COLOR.success}
                onChange={() => {
                  const suffix = !isShowAutoRenewalPackage
                    ? `-${PREFIX_AUTO_RENEW}`
                    : '';
                  const newSelectedPackage = packages.find(
                    (p) =>
                      p.code ===
                      selectedPackage.replace(`-${PREFIX_AUTO_RENEW}`, '') +
                        suffix,
                  );
                  setSelectedPackage(newSelectedPackage?.code || '');
                  dispatch(actions.order.updatePackage(newSelectedPackage));
                  changeRoute(newSelectedPackage?.code);
                  setIsShowAutoRenewalPackage(!isShowAutoRenewalPackage);
                }}
              />
            </Box>
          )}
        </Box>
      )}

      <StyledPopover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ marginTop: '8px' }}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            boxShadow:
              '0px 40px 80px 0px rgba(0, 0, 0, 0.2), 0px 7px 10px 0px rgba(0, 0, 0, 0.09)',
          },
        }}
      >
        <MenuList className="menu" onClick={handleClosePopover}>
          {getUniqueLevelPackages(packages).map((item) => (
            <MenuItem
              className={`menu-item ${
                item.level === packageState?.level && 'selected'
              }`}
              key={item.code}
              onClick={() => handleSelectLevel(item)}
            >
              <div>
                <div className="package-icon">
                  <img src={item?.icon} alt="icon-type" />
                </div>
              </div>
              <div className="vertical-center">
                <div className="name">
                  {item?.name &&
                    (item?.name[language] || item?.name[LANGUAGE.EN])}
                </div>
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </StyledPopover>

      <Box>
        {loading ? (
          <Skeleton
            animation="wave"
            width="100%"
            height={210}
            sx={{ bgcolor: COLOR.grayV2[100] }}
          />
        ) : (
          <PackageDurationSelect
            packages={packages}
            level={packageState?.level}
            onSelectPackage={handleSelectPackage}
            selectedPackage={selectedPackage}
            isShowAutoRenewalPackage={isShowAutoRenewalPackage}
          />
        )}
      </Box>
    </StyledPackageInfo>
  );
};

export default PackageInfo;
