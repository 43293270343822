import React from 'react';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

import { StyledCircularProcess } from './index.style';

const CircularProcess = ({ color, value, size = 32, thickness = 6 }) => (
  <StyledCircularProcess>
    <CircularProgress
      variant="determinate"
      size={size}
      value={value}
      thickness={thickness}
      className="circular-background"
    />
    <CircularProgress
      variant="determinate"
      color={color}
      sx={{
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      value={value}
      size={size}
      thickness={thickness}
    />
    <Typography className="value-progress" variant="caption">
      {`${Math.round(value)}%`}
    </Typography>
  </StyledCircularProcess>
);

export default CircularProcess;
