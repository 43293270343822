import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledModalConvertFreePackage = styled('div')`
  position: relative;
  z-index: 1000;
  display: flex;
  height: fit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;

  .dialog-container {
    display: flex;
    flex-direction: column;
    height: fit;
    align-items: center;
    padding: 24px 48px;
    border-radius: 24px;
    text-align: center;
  }
  .img {
    width: 80px;
    height: 80px;
    margin: auto;
  }

  .dialog-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.4px;
    margin-top: 16px;
  }

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
    color: ${COLOR.label2[64]};
    @media (min-width: 1001px) {
      width: 475px;
    }

    @media (max-width: 1000px) {
      width: 390px;
    }
  }

  .dialog-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
    @media (min-width: 1001px) {
      flex-direction: row;
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .dialog-button {
    height: 56px;
    font-size: 1rem;
    border-radius: 500px;
    cursor: pointer;
    width: 260px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.4px;
    display: flex;
    padding: 16px;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
  }

  .dialog-button-upgrade {
    background-color: ${COLOR.white};
    color: ${COLOR.secondary};
    border: 1px solid ${COLOR.secondary[32]};
  }

  .dialog-button-upgrade:hover {
    background-color: ${COLOR.black[16]};
  }

  .dialog-button-switch {
    background-color: ${COLOR.primary};
    color: ${COLOR.secondary};
  }

  .dialog-button-switch:hover {
    background-color: ${COLOR.yellow[64]};
  }

  .bold-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
  }

  @media (min-width: 1001px) {
    .dialog-title {
      width: 480px;
    }
    .dialog-content {
      width: 544px;
    }
    .dialog-buttons {
      flex-direction: row;
    }
  }

  @media (max-width: 1000px) and (min-width: 600px) {
    overflow-x: hidden;
    .dialog-title {
      width: 540px;
    }
    .dialog-content {
      width: 540px;
    }
    .dialog-buttons {
      flex-direction: row;
    }
  }

  @media (max-width: 600px) {
    overflow-x: hidden;
    .dialog-title {
      width: 256px;
    }
    .dialog-content {
      width: 256px;
    }
    .dialog-buttons {
      flex-direction: column-reverse;
    }
  }
`;

export const StyledDialogMigrateFreePackage = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 640px;
    border-radius: 24px;
  }
`;
