const MB_UNIT = 1024 * 1024;

const checkFileSize = (file, maxSize = 5) => {
  if (!file || !file.size) return false;
  return file.size / MB_UNIT < maxSize;
};

const getFileExtension = (file) => {
  if (!file) return null;
  return file.name.split('.').pop();
};

const getFileName = (url) => {
  if (!url) return '';
  let fileName = url.split('/').pop();
  if (fileName.includes('?')) {
    fileName = fileName.substring(0, fileName.indexOf('?'));
  }
  return fileName;
};

const readFileAsText = async (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => resolve(null);

    reader.readAsText(file);
  });

const createFileFromText = (fileName, content) =>
  new File([content], fileName, { type: 'text/plain' });

const downloadFileUsingUrl = (url) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getVideoDuration = (file) =>
  new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration * 1000);
    };
    video.src = URL.createObjectURL(file);
  });

export {
  checkFileSize,
  getFileExtension,
  getFileName,
  readFileAsText,
  createFileFromText,
  downloadFileUsingUrl,
  getVideoDuration,
};
