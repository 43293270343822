/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import SrtFailIcon from '@src/assets/icons/srt-fail.svg';
import { getShortFileName } from '@src/services/file';
import {
  DUBBING_LENGTH_LIMIT,
  MAX_SENTENCE_LENGTH,
} from '@src/constants/dubbing';
import { MAX_CHARACTER_LENGTH } from '@src/constants/tts';

import { StyledFailedDropzoneContent } from './index.style';
import SampleFileReference from './SampleFileReference';

const FailedDropzoneContent = ({
  fileName,
  error = {},
  fileFormat,
  dubbingFileFormat,
  isMultipleInputDubbing,
}) => {
  const { t } = useTranslation();

  const OldDropzone = () => (
    <>
      <img src={SrtFailIcon} alt="srt-icon" />
      <Typography className="file-name">
        {getShortFileName(fileName)}
      </Typography>
      <div className="upload-instruction">
        <Typography align="center" variant="h5" className="upload-file-title">
          {t('dropFileSuccessInstruct', { fileFormat })}
        </Typography>
        <SampleFileReference />
      </div>
    </>
  );

  const NewDropzone = () => (
    <>
      <img src={SrtFailIcon} alt="srt-icon" />
      <div className="upload-instruction">
        <Typography
          align="center"
          variant="h5"
          className="upload-file-title text-blue"
        >
          {t('dropFileSuccessInstructSubtitle', { fileFormat })}
        </Typography>
        <SampleFileReference isMultipleInputDubbing />
      </div>
    </>
  );

  return (
    <StyledFailedDropzoneContent>
      {isMultipleInputDubbing ? <NewDropzone /> : <OldDropzone />}
      <div className="error">
        <div
          className="error-text"
          dangerouslySetInnerHTML={{
            __html: t(error.message, {
              maxAudioLength: DUBBING_LENGTH_LIMIT,
              fileType: dubbingFileFormat?.map((type) => `.${type}`)?.join(','),
              maxSentenceLength: MAX_SENTENCE_LENGTH,
              maxCharacterLength: MAX_CHARACTER_LENGTH,
            }),
          }}
        />
        {error.detail && (
          <div
            className="error-detail-wrapper"
            role="button"
            onClick={(e) => e.stopPropagation()}
            tabIndex={0}
          >
            <div
              className="error-detail"
              dangerouslySetInnerHTML={{
                __html: t(error.detail),
              }}
            />
          </div>
        )}
      </div>
    </StyledFailedDropzoneContent>
  );
};
export default FailedDropzoneContent;
