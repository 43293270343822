import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import IconPlus from '@src/assets/icons/plus.svg';
import IconDocument from '@src/assets/icons/document.svg';
import IconLink from '@src/assets/icons/link.svg';
import IconSubtitle from '@src/assets/icons/subtitle.svg';
import { checkVietNam } from '@src/utils/checkCountry';
import { PACKAGE_CODE, PRODUCT_TYPE } from '@src/constants/package';
import { VBEE_CONSOLE_URL } from '@src/configs';
import route from '@src/constants/route';
import { INPUT_TTS_TYPE } from '@src/constants/tts';
import { getImageThumbnailFromYoutubeLink } from '@src/services/youtube';
import LogoAIVoiceAPI from '@src/assets/logo/small-logo-api.svg';
import LogoAIVoiceDubbing from '@src/assets/logo/small-logo-dubbing.svg';
import LogoAIVoice from '@src/assets/logo/small-logo.svg';
import IconPlay from '@src/assets/icons/play.svg';
import { StyledGuideVideo, StyledHome } from './index.style';
import VoiceList from './VoiceList';
import { GLOBAL_SAMPLE_AUDIOS, VN_SAMPLE_AUDIOS } from './sampleAudiosData';
import VideoGuideDialog from './VideoGuideDialog';
import SecctionLoading from './SecctionLoading';

const Home = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [voiceList, setVoiceList] = useState([]);
  const [guideVideos, setGuideVideos] = useState([]);
  const [openVideoGuideDialog, setOpenVideoGuideDialog] = useState(false);
  const [selectVideoGuide, setSelectVideoGuide] = useState({});

  const { manualBanner } = useSelector((state) => state.voucher);

  const handelChangeVoiceList = (voices) => setVoiceList(voices);

  const INPUT_TYPES = [
    {
      id: 1,
      icon: IconPlus,
      content: t('textInput'),
      type: INPUT_TTS_TYPE.TEXT,
    },
    {
      id: 2,
      icon: IconDocument,
      content: t('docTxtFileUpload'),
      type: INPUT_TTS_TYPE.FILE,
    },
    {
      id: 3,
      icon: IconLink,
      content: t('inputLink'),
      type: INPUT_TTS_TYPE.LINK,
    },
    {
      id: 4,
      icon: IconSubtitle,
      content: t('srtFileUpload'),
      type: INPUT_TTS_TYPE.SRT,
    },
  ];

  const handleCloseVideoGuideDialog = () => {
    setOpenVideoGuideDialog(false);
    setSelectVideoGuide({});
  };

  const handleChooseVideoGuide = (video) => {
    setSelectVideoGuide(video);
    setOpenVideoGuideDialog(true);
  };

  const PRODUCT_LIST = [
    {
      type: PRODUCT_TYPE.aivoiceStudio,
      name: t('STUDIO'),
      value: t('convertDocToVoice'),
      logo: LogoAIVoice,
    },
    {
      type: PRODUCT_TYPE.aivoiceApi,
      name: t('API'),
      value: t('forDeveloper'),
      logo: LogoAIVoiceAPI,
    },
    {
      type: PRODUCT_TYPE.aivoiceDubbing,
      name: t('DUBBING'),
      value: t('convertSubtitleToDubbingVoice'),
      logo: LogoAIVoiceDubbing,
    },
  ];

  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  const isVietNam = checkVietNam();

  const getSampleVoices = () => {
    const { packageCode } = user;
    const isFreeUser = packageCode === PACKAGE_CODE.STUDIO_FREE;

    if (isVietNam || isFreeUser) return VN_SAMPLE_AUDIOS;
    return GLOBAL_SAMPLE_AUDIOS;
  };

  const handleChooseInputType = (input) => {
    switch (input.type) {
      case INPUT_TTS_TYPE.TEXT: {
        history.push(route.TTS);
        break;
      }

      case INPUT_TTS_TYPE.FILE: {
        history.push(`${route.TTS}?inputType=${INPUT_TTS_TYPE.FILE}`);
        break;
      }

      case INPUT_TTS_TYPE.LINK: {
        history.push(`${route.TTS}?inputType=${INPUT_TTS_TYPE.LINK}`);
        break;
      }
      case INPUT_TTS_TYPE.SRT: {
        history.push(route.HOME_DUBBING);
        break;
      }
      default:
        break;
    }
  };

  const handleChooseProduct = (product) => {
    const { type } = product;
    switch (type) {
      case PRODUCT_TYPE.aivoiceStudio: {
        history.push(route.TTS);
        break;
      }
      case PRODUCT_TYPE.aivoiceDubbing: {
        history.push(route.HOME_DUBBING);
        break;
      }
      case PRODUCT_TYPE.aivoiceApi: {
        window.location.href = VBEE_CONSOLE_URL;
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    setVoiceList(getSampleVoices());
  }, [user]);

  useEffect(() => {
    const listGuideVideo = manualBanner?.guideSteps || [];
    setGuideVideos(listGuideVideo);
  }, [manualBanner]);

  return (
    <StyledHome>
      <div className="section">
        <VoiceList
          voiceList={voiceList}
          onChangeVoiceList={handelChangeVoiceList}
        />
      </div>
      <div className="section">
        <span className="title">{t('textInput')}</span>
        <span className="blur-txt">{t('selectTemplateOrUploadToConvert')}</span>
        <div className="inputs-wrapper">
          {INPUT_TYPES.map((input) => (
            <div
              className="input-box"
              key={input.id}
              onClick={() => handleChooseInputType(input)}
              tabIndex={0}
              role="button"
            >
              <img src={input.icon} alt="plus" />
              <span className="blur-txt">{input.content}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="section">
        <span className="title">{t('vbeeOtherProduct')}</span>
        <div className="other-product-wrapper">
          {PRODUCT_LIST.map((product) => (
            <div
              className="other-product-box"
              key={product.type}
              onClick={() => handleChooseProduct(product)}
              tabIndex={0}
              role="button"
            >
              <div className="product-info">
                <img src={product.logo} alt="arrow" className="logo" />
                <div>
                  <span className="name">{product.name}</span>
                  <span className="value">{product.value}</span>
                </div>
              </div>
              <IconButton
                className="button"
                onClick={() => handleChooseProduct(product)}
              >
                <ArrowForward />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
      <div className="section">
        <span className="title">{t('guide')}</span>
        <div className="video-guide-wrapper">
          {manualBanner.loading ? (
            <SecctionLoading />
          ) : (
            guideVideos.map((video) => (
              <div
                className="video-guide-box"
                onClick={() => handleChooseVideoGuide(video)}
                role="button"
                tabIndex={0}
              >
                <StyledGuideVideo
                  img={getImageThumbnailFromYoutubeLink(video.videoLink)}
                >
                  <img src={IconPlay} alt="play" className="play-video" />
                </StyledGuideVideo>

                <span className="title">{video[language]}</span>
              </div>
            ))
          )}
        </div>
      </div>
      <VideoGuideDialog
        open={openVideoGuideDialog}
        video={selectVideoGuide}
        onClose={handleCloseVideoGuideDialog}
      />
    </StyledHome>
  );
};

export default Home;
