import styled from 'styled-components';
import { COLOR } from '@src/styles/color';

const StyledImportantTag = styled.div`
  position: absolute;
  z-index: 1000;
  top: ${(props) => props.top || '-10px'};
  right: ${(props) => props.right || '-10px'};
  padding: ${(props) => props.padding || '3px 3px 3px 10px'};
  background: ${(props) => props.bgColor || COLOR.primary};
  color: ${(props) => props.color || COLOR.white};
  border-radius: ${(props) => props.borderRadius || '0px 8px 2px 20px'};

  .new-label-text {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: -0.4px;
  }
`;
export { StyledImportantTag };
