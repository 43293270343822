import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { LINEAR_COLOR } from '@src/styles/color';

export const StyledLinearStatus = styled(Typography)`
  background: ${({ color, progress, toright }) =>
    `linear-gradient(to ${toright ? 'right' : 'left'}, ${
      toright ? LINEAR_COLOR[color].linear : LINEAR_COLOR[color].background
    } ${progress}%, ${
      toright ? LINEAR_COLOR[color].background : LINEAR_COLOR[color].linear
    } ${100 - progress}%)`};
  color: ${({ color }) => LINEAR_COLOR[color].text};
  border-radius: 17px;
  font-size: 13px;
  font-weight: 500;
  width: 80px;
  padding: 1px 10px;
  text-align: center;
  white-space: nowrap;
`;
