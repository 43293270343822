import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Typography, Checkbox, Button } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import CustomFooter from '@src/components/CustomFooter';
import { PAGINATION_LIMIT } from '@src/constants';
import NotificationDialog from '@src/components/NotificationDialog';
import actions from '@src/redux/actions';
import Sentence from './Sentence';
import {
  StyledSentences,
  StyledSentence,
  StyledActionWarning,
} from './index.style';

const DubbingSentences = ({ onResetFile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [displayingSentences, setDisplayingSentences] = useState([]);
  const { sentences, fileId } = useSelector((state) => state.dubbingRequest);
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);

  const totalSentences = Object.keys(sentences).length;

  const getDisplayingSentences = (pageNum) => {
    const total = Object.keys(sentences).length;
    const startIndex = (pageNum - 1) * PAGINATION_LIMIT;
    const endIndex = Math.min(pageNum * PAGINATION_LIMIT, total);
    return Object.entries(sentences)
      .slice(startIndex, endIndex)
      .map(([key, value]) => ({ key, ...value }));
  };

  const handleChagePage = (value) => setPage(value);

  const isSelected = (key) => !!selected.find((item) => item === key);

  const handleSelectSentence = (key) => (event) => {
    const isSelectedSentence = isSelected(key);

    if (event.target.checked && !isSelectedSentence) {
      setSelected((prev) => [...prev, key]);
    } else {
      // If line is already selected, remove it from selected list
      setSelected((prev) => prev.filter((item) => item !== key));
    }
  };

  const handleSelectAll = (event) => {
    const displayingKeys = displayingSentences.map((item) => item.key);
    if (event.target.checked) {
      const newSelected = Array.from(new Set(selected.concat(displayingKeys)));
      setSelected(newSelected);
    } else {
      const newSelected = selected.filter(
        (item) => !displayingKeys.includes(item),
      );
      setSelected(newSelected);
    }
  };

  const handleChangeSentence = ({ sentenceKey, name, value }) => {
    const newSentences = { ...sentences };
    newSentences[sentenceKey][name] = value;
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        newSentences || {},
      ),
    );
  };

  const handleSelectDelete = (value) => {
    setOpenDeleteWarning(true);
    setIsDeleteAll(value);
  };

  const handleCloseDeleteWarning = () => setOpenDeleteWarning(false);

  const handleDeleteAllSentences = () => {
    onResetFile();
    dispatch(
      actions.dubbingRequest.resetDubbingRequest([
        'fileId',
        'error',
        'fileName',
        'sentences',
      ]),
    );
    setSelected([]);
    setOpenDeleteWarning(false);
  };

  const handleDeleteSelectedSentences = async () => {
    if (totalSentences === selected.length) {
      handleDeleteAllSentences();
      return;
    }
    const newSentences = Object.fromEntries(
      Object.entries(sentences).filter(([key]) => !selected.includes(key)),
    );

    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        newSentences,
      ),
    );

    setSelected([]);
    setOpenDeleteWarning(false);

    const numberOfPage = Math.ceil(
      Object.keys(newSentences).length / PAGINATION_LIMIT,
    );

    setPage(Math.min(page, numberOfPage));
  };

  const renderConfirmDeleteSentences = () => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleCloseDeleteWarning}>
        {t('noValue')}
      </Button>
      <Button
        variant="contained"
        onClick={
          isDeleteAll ? handleDeleteAllSentences : handleDeleteSelectedSentences
        }
      >
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  useEffect(() => {
    const newData = getDisplayingSentences(page);
    setDisplayingSentences(newData);
  }, [page, sentences]);

  useEffect(() => {
    setSelected([]);
    setPage(1);
  }, [fileId]);

  return (
    <StyledSentences>
      <StyledSentence isSelected height="52px" padding="0px 12px">
        <Checkbox
          className="checkbox"
          indeterminate={
            displayingSentences.some((item) => isSelected(item.key)) &&
            !displayingSentences.every((item) => isSelected(item.key))
          }
          checked={displayingSentences.every((item) =>
            selected.includes(item.key),
          )}
          onChange={handleSelectAll}
        />
        {selected?.length ? (
          <>
            <Button
              size="medium"
              color="error"
              startIcon={<DeleteOutlineOutlined />}
              onClick={() => handleSelectDelete(false)}
              className="delete-button"
            >
              <span>
                {t('deleteSentencesRequests', { numRequests: selected.length })}
              </span>
            </Button>
            <Button
              size="medium"
              color="error"
              onClick={() => handleSelectDelete(true)}
              className="delete-button"
            >
              {t('deleteAll')}
            </Button>
          </>
        ) : (
          <>
            <Typography className="time-range-header">
              {t('numberSentencesAndTime')}
            </Typography>
            <Typography className="content-header">
              {t('conversation')}
            </Typography>
          </>
        )}
      </StyledSentence>
      {displayingSentences.map(({ key }) => (
        <Sentence
          key={key}
          sentenceKey={key}
          isSelected={isSelected(key)}
          onSelectSentence={handleSelectSentence}
          onChangeSentence={handleChangeSentence}
        />
      ))}
      <CustomFooter
        total={totalSentences}
        page={page}
        onChangePage={handleChagePage}
        paginationLimit={PAGINATION_LIMIT}
      />
      <NotificationDialog
        title={t('confirmDeletion')}
        description={
          isDeleteAll
            ? t('deleteAllSentences')
            : t('deleteSelectedSentences', {
                value: selected.length,
              })
        }
        variant="warning"
        open={openDeleteWarning}
        onClose={handleCloseDeleteWarning}
        actionComponent={renderConfirmDeleteSentences()}
      />
    </StyledSentences>
  );
};

export default DubbingSentences;
