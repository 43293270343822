import {
  Box,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  Dialog,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS, MOBILE_BREAKPOINT } from '@src/styles/config';

export const StyledPaymentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 20px 0;
  position: relative;

  .content-wrapper {
    width: 100%;
    margin: unset;
  }

  .column {
    padding: 10px;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .invoice-check-box {
    width: 97%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 10px 10px;

    .checkbox-color {
      color: ${COLOR.darkBlue};
    }
  }

  .invoice-info {
    cursor: pointer;
  }

  @media only screen and (max-width: 500px) {
    .content-wrapper {
      margin-top: 80px;
    }
    .transfer-info {
      margin-top: 0px;
    }
  }
`;

export const StyledStepper = styled(Stepper)`
  .label-above {
    display: flex;
    flex-direction: column-reverse !important;
    margin-top: -46px;
    gap: 8px;
    .MuiStepLabel-label {
      font-weight: 400;
      color: ${COLOR.dark};
    }
    &.active {
      .MuiStepLabel-label {
        color: ${COLOR.black.default} !important;
        font-weight: 600;
      }
    }
    &.completed {
      .MuiStepLabel-label {
        color: ${COLOR.dark} !important;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    width: 831px;
  }

  @media only screen and (min-width: 600px) and (max-width: 1280px) {
    width: 600px;
    margin-top: 20px;

    .step2 {
      .MuiStepLabel-label {
        margin-top: -5px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 500px;
    margin-top: 20px;

    .step1,
    .step2 {
      .MuiStepLabel-label {
        margin-top: -5px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    position: absolute;
    width: 500px;
    top: 20px;
    left: -10px;
  }
`;

export const StyledStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR.primary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLOR.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: COLOR.light,
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

export const StyledStepIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-weight: 600;
  color: ${COLOR.black.default};
  background-color: ${({ active, completed }) =>
    active || completed ? COLOR.primary : COLOR.light};
`;

export const StyledBox = styled(Box)`
  position: relative;
  border-radius: ${BORDER_RADIUS};
  padding: 20px;
  ${({ bordered }) =>
    bordered
      ? `border: 2px solid ${COLOR.divider};`
      : `background: ${TRANSPARENT_COLOR.light};`}

  .title {
    font-weight: ${({ bold }) => (bold ? 'bold' : 600)};
    color: ${COLOR.dark};
    width: fit-content;
    position: absolute;
    font-size: 18px;
    ${({ bordered }) =>
      bordered
        ? ` top: -14px;
        background: ${COLOR.white};
        padding-right: 30px;
        padding-left: 12px;
        position: absolute;`
        : `top: 0;`}
  }

  .box-content {
    ${({ bordered }) => !bordered && `margin-top: 30px;`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .box-content {
      margin-top: 10px;
    }
  }
`;

export const StyledCreateInvoice = styled('div')`
  border-radius: ${BORDER_RADIUS};
  border: 2px solid ${COLOR.divider};
  padding: ${(props) => (props.open ? '25px 20px' : '0px')};
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: opacity 1s ease-out;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  height: ${(props) => (props.open ? 'auto' : '0')};
  opacity: ${(props) => (props.open ? '1' : '0')};

  .invoice-input {
    width: 100%;
    input {
      font-size: 15px;
    }
  }
  p {
    font-weight: 500;
  }
`;

export const StyledCompletedAction = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  @media only screen and (max-width: 500px) {
    justify-content: center;
  }
`;

export const StyledPaymentDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 750px;
  }

  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .title {
      color: ${COLOR.lightText};
      font-weight: 700;
      font-size: 14px;
    }
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .payment-icon {
    width: 50px;
    height: 50px;
  }

  .dialog-content {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
    margin: 15px 0;
  }

  .payment-method-qr {
    padding: 10px;
    width: fit-content;
    border: 1px solid ${COLOR.lightDivider};
    border-radius: ${BORDER_RADIUS};
  }

  .payment-method-qr-img {
    width: 240px;
    height: 240px;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 30px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    margin-top: 25px;
    line-height: 21px;
  }

  .footer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 260px auto;
    gap: 25px;
  }

  .clock {
    font-weight: 500;
    font-size: 28px;
    line-height: 21px;
    margin-left: 15px;
    color: ${COLOR.lightText};
  }

  .time {
    color: ${COLOR.lightText};
    font-weight: 500;
    font-size: 20px;
    margin-left: 20px;
  }

  .total-money {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
  }

  .dialog-action {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .dialog-mobile {
    display: none;
    align-items: center;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .MuiDialog-paper {
      max-width: 400px;
    }
    .dialog-mobile {
      display: block;
      .payment-method-qr-img {
        width: 180px;
        height: 180px;
      }
      .dialog-content {
        gap: 10px;
        margin: 5px 0;
      }
      .payment-icon {
        width: 20px;
        height: 20px;
      }
      .dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content {
        text-align: center;
        margin: 0;
      }
      .description {
        margin-top: 0px;
      }
      .time,
      .clock {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        margin: 0px;
      }
      .dialog-action {
        justify-content: center;
        align-items: center;
        margin: 0px 0px 16px 0px;
        padding: 0px;
        button {
          padding: 5px 10px;
        }
      }
    }
    .dialog-web {
      display: none;
    }
  }
`;
