import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@src/redux/actions';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import {
  newSplitParagraphIntoSentence,
  splitParagraphIntoSentence,
} from '@src/services/sentence';
import NotificationDialog from '@src/components/NotificationDialog';
import { VOICE_PROVIDER } from '@src/constants/voice';
import SentenceItem from './SentenceItem';
import { StyledSentences } from './index.style';
import { StyledActionWarning } from '../Requests/index.style';

const Sentences = ({
  advanceFeature,
  characterExceed,
  boxSize,
  onChangeInputLength,
  onChangeCharacterExceed,
  onTryListeningSentence,
  audioRef,
}) => {
  // const [selectedSentences, setSelectedSentences] = useState([]);

  const { voice, selectedSentences, replace, search } = useSelector(
    (state) => state.synthesisRequest,
  );
  const { speed, breakTime } = useSelector(
    (state) => state.synthesisRequest.synthesisRequest,
  );
  const [isOpenDeleteSentenceDialog, setIsOpenDeleteSentenceDialog] =
    useState(false);

  const dispatch = useDispatch();
  const { sentences } = useSelector((state) => state.synthesisRequest);
  const { t } = useTranslation();

  const isAllSelected = () => selectedSentences.length === sentences.length;

  const isIndeterminate = () =>
    selectedSentences.length > 0 && selectedSentences.length < sentences.length;

  const handleCloseDeleteDialog = () => setIsOpenDeleteSentenceDialog(false);

  const handleShowDeleteDialog = () => setIsOpenDeleteSentenceDialog(true);

  const renderConfirmDeleteRequestACtion = (handleClose, handleConfirm) => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleClose}>
        {t('noValue')}
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelected = sentences.map((sentence) => sentence.id);
      // setSelectedSentences(newSelected);
      dispatch(actions.synthesisRequest.updateSelectedSentences(newSelected));
      return;
    }
    // setSelectedSentences([]);
    dispatch(actions.synthesisRequest.updateSelectedSentences([]));
  };

  const handleChangeSentenceItem = (sentenceId, name, value) => {
    dispatch(
      actions.audioPlayer.updateTryListeningSentence({
        sentenceId: '',
        sentenceAudioLink: '',
      }),
    );
    const newSentences = sentences.map((item) =>
      item.id === sentenceId
        ? { ...item, [name]: value, onLoad: false }
        : { ...item, onLoad: false },
    );
    dispatch(actions.synthesisRequest.updateSentences(newSentences));
  };

  const handleAddSentences = (sentenceId, subSentences) => {
    const index = sentences.findIndex((item) => item.id === sentenceId);
    if (index === -1) return;

    const newSentences = [
      ...sentences.slice(0, index),
      ...subSentences,
      ...sentences.slice(index + 1),
    ];
    dispatch(actions.synthesisRequest.updateSentences(newSentences));
  };

  const handleConfirmDeleteSentence = () => {
    let newSentences = sentences.filter(
      (item) => !selectedSentences.includes(item.id),
    );

    if (newSentences.length === 0) {
      let initSentences;
      const { voice: sentenceVoice = {} } = sentences[0];
      if (advanceFeature) {
        initSentences = newSplitParagraphIntoSentence([], sentenceVoice);
        dispatch(actions.synthesisRequest.updateParagraphs([]));
      } else {
        initSentences = splitParagraphIntoSentence('', sentenceVoice);
      }
      newSentences = initSentences;
    }
    dispatch(actions.synthesisRequest.updateSentences(newSentences));
    // setSelectedSentences([]);
    dispatch(actions.synthesisRequest.updateSelectedSentences([]));
    const newTextLength = newSentences.reduce(
      (total, item) => total + item.text.length,
      0,
    );
    onChangeInputLength(newTextLength);
    setIsOpenDeleteSentenceDialog(false);
  };

  // Update content sentences in redux
  useEffect(() => {
    if (!search || !replace) return;
    const newSentences = sentences.map((sentence) => {
      const regex = new RegExp(search, 'gi');
      const newText = sentence.text.replace(regex, replace);
      return { ...sentence, text: newText };
    });
    dispatch(actions.synthesisRequest.updateSentences(newSentences));
  }, [replace]);

  useEffect(() => {
    if (selectedSentences.length > 0) {
      const detailSelectedList = sentences.filter((item) =>
        selectedSentences.includes(item.id),
      );

      const commonVoice = detailSelectedList[0]?.voice || {};
      const isSameVoice = detailSelectedList.every(
        (item) => item.voice?.id === commonVoice.id,
      );

      const isVbeeVoice = detailSelectedList.every(
        (sentence) => sentence?.voice?.provider === VOICE_PROVIDER.VBEE,
      );

      const commonSpeed = detailSelectedList[0]?.speed;
      const isSameSpeed = detailSelectedList.every(
        (item) => item.speed === commonSpeed,
      );

      const commonBreakTime = detailSelectedList[0]?.breakTime;
      const isSameBreakTime = detailSelectedList.every(
        (item) => item.breakTime === commonSpeed,
      );

      dispatch(
        actions.synthesisRequest.updateVoice(
          isSameVoice
            ? commonVoice
            : {
                provider: isVbeeVoice ? VOICE_PROVIDER.VBEE : null,
              },
        ),
      );

      dispatch(
        actions.synthesisRequest.updateSynthesisRequest(
          'speed',
          isSameSpeed ? commonSpeed : 0,
        ),
      );
      dispatch(
        actions.synthesisRequest.updateSynthesisRequest(
          'breakTime',
          isSameBreakTime ? commonBreakTime : 0,
        ),
      );
    }
  }, [selectedSentences]);

  useEffect(() => {
    if (sentences.length > 0) {
      dispatch(
        actions.audioPlayer.updateTryListeningSentence({
          sentenceId: '',
          sentenceAudioLink: '',
        }),
      );

      const newUpdate = {};
      if (voice.code) newUpdate.voice = voice;
      if (speed) newUpdate.speed = speed;
      if (breakTime) newUpdate.breakTime = breakTime;

      const newSentences = sentences.map((item) =>
        selectedSentences.includes(item.id)
          ? { ...item, ...newUpdate, onLoad: false }
          : { ...item, onLoad: false },
      );

      dispatch(actions.synthesisRequest.updateSentences(newSentences));
    }
  }, [voice, speed, breakTime]);

  return (
    <StyledSentences boxSize={boxSize}>
      <div className="sentence-header">
        <FormControlLabel
          label={t('selectAll')}
          control={
            <Checkbox
              checked={isAllSelected()}
              indeterminate={isIndeterminate()}
              onChange={handleSelectAllClick}
              classes={{ root: 'check-box-all' }}
              className="checkbox-color"
            />
          }
        />
        <div
          className="delete-sentence"
          onClick={handleShowDeleteDialog}
          role="button"
          tabIndex="0"
        >
          <DeleteOutlineOutlined />
          <div>{t('delete')}</div>
        </div>
      </div>

      {sentences.map((sentenceItem) => {
        const isSelected = selectedSentences.includes(sentenceItem.id);
        return (
          <SentenceItem
            key={sentenceItem.id}
            sentence={sentenceItem}
            characterExceed={characterExceed}
            selectedList={selectedSentences}
            // setSelectedList={handleUpdateSelectedSentences}
            isSelected={isSelected}
            onChangeInputLength={onChangeInputLength}
            onChangeSentenceItem={handleChangeSentenceItem}
            onChangeCharacterExceed={onChangeCharacterExceed}
            onTryListeningSentence={onTryListeningSentence}
            onAddSentences={handleAddSentences}
            audioRef={audioRef}
          />
        );
      })}
      <NotificationDialog
        name="delete-sentence"
        open={isOpenDeleteSentenceDialog}
        variant="warning"
        title={t('deleteContentTitle')}
        description={t('deleteSentencesDescription', {
          numSentenceDelete:
            selectedSentences.length === sentences.length
              ? t('all')
              : selectedSentences.length,
        })}
        onClose={handleCloseDeleteDialog}
        actionComponent={renderConfirmDeleteRequestACtion(
          handleCloseDeleteDialog,
          handleConfirmDeleteSentence,
        )}
      />
    </StyledSentences>
  );
};

export default Sentences;
