import * as auth from './auth/actions';
import * as user from './user/actions';
import * as audioPlayer from './audioPlayer/actions';
import * as noti from './noti/actions';
import * as dialog from './dialog/actions';
import * as request from './request/actions';
import * as synthesisRequest from './synthesisRequest/actions';
import * as featureFlag from './featureFlag/actions';
import * as voucher from './voucher/actions';
import * as banner from './banner/actions';
import * as language from './language/actions';
import * as voice from './voice/actions';
import * as device from './device/actions';
import * as configTable from './configTable/actions';
import * as dubbingRequest from './dubbingRequest/actions';
import * as layout from './layout/actions';
import * as audioPreview from './audioPreview/actions';
import * as order from './order/actions';
import * as category from './category/actions';
import * as audioVoiceCloning from './audioVoiceCloning/actions';
import * as province from './province/actions';
import * as voiceVoiceCloning from './voiceVoiceCloning/actions';

export default {
  auth,
  user,
  audioPlayer,
  noti,
  dialog,
  request,
  synthesisRequest,
  featureFlag,
  voucher,
  banner,
  language,
  voice,
  device,
  configTable,
  dubbingRequest,
  layout,
  audioPreview,
  order,
  category,
  audioVoiceCloning,
  province,
  voiceVoiceCloning,
};
