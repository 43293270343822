import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TTS_BOX_SIZE } from '@src/constants/tts';
import { COLOR } from '@src/styles/color';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledContent = styled('div')`
  padding-top: 8px;
  .editor-wrapper {
    height: ${(props) => props.boxSize || TTS_BOX_SIZE.DEFAULT};
    overflow: auto;

    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${COLOR.indigo[64]};
      border-radius: 100px;
    }

    a:-webkit-any-link {
      color: ${COLOR.blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .character-exceed {
    color: ${COLOR.danger};
  }
  .DraftEditor-root {
    height: 90%;
    font-family: 'SF Pro', sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }

  .search-highlight {
    color: ${COLOR.darkBlue};
    background-color: ${COLOR.primary};
  }

  .black-word-highlight {
    color: ${COLOR.darkBlue};
    background-color: ${COLOR.error};
  }
`;

const StyledSentenceItem = styled('div')`
  .check-box {
    padding: 0;
    color: ${COLOR.darkBlue};
  }

  .voice-info {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  .editor-container {
    flex: 1;
    font-size: 14px;
  }
  .character-exceed {
    color: ${COLOR.danger};
  }

  .sentence-toolbar {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${COLOR.divider};
    padding: 3px 0;

    .play-audio {
      margin: 0 10px;
    }

    .voice-info {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-bottom: 1px solid ${COLOR.divider};

    .editor-container {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
`;

const StyledSentences = styled('div')`
  height: ${(props) => props.boxSize || TTS_BOX_SIZE.DEFAULT};
  overflow-y: auto;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLOR.white};
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLOR.indigo[64]};
    border-radius: 100px;
  }

  a:-webkit-any-link {
    color: ${COLOR.blue};
    cursor: pointer;
    text-decoration: underline;
  }

  .check-box-all {
    padding: 10px;
  }

  .sentence-header {
    display: flex;
    align-items: center;
  }

  .checkbox-color {
    color: ${COLOR.darkBlue};
  }

  .delete-sentence {
    display: flex;
    align-items: center;
    color: ${COLOR.danger};
    cursor: pointer;
  }
`;

const StyledChangeBoxTtsSizeButton = styled('div')`
  display: flex;
  align-items: top;
  justify-content: center;
  position: relative;

  .line {
    width: 100%;
    height: 1px;
    background-color: ${COLOR.light};
  }

  .button {
    position: relative;
    display: flex;
    justify-content: center;
    width: 180px;
    margin-top: -1px;

    .size {
      width: 180px;
    }

    .button-content {
      cursor: pointer;
      position: absolute;
      margin-left: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      z-index: 30;
      color: ${COLOR.darkBlue};
      font-weight: 600;
      font-size: 14px;
      transform: rotateX(4deg) !important;
    }
  }
`;

const StyledPreviewButton = styled(Paper)`
  border-radius: 100%;
  width: 34px;
  height: 34px;
  border: 1px solid ${COLOR.subText};
  display: flex;
  align-items: center;
  justify-content: center;
  over-flow: hidden;
  &:hover {
    border: 1px solid ${COLOR.primary};
  }
`;

export {
  StyledContent,
  StyledSentenceItem,
  StyledSentences,
  StyledChangeBoxTtsSizeButton,
  StyledPreviewButton,
};
