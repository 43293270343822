import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import Card from '@src/components/Card';
import { Box, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { StyledDialogHistoryChanged } from './index.style';
import HistoryChangedTable from './HistoryChangedTable';

const HistoryChanged = ({
  page,
  openDialogHistoryChanged,
  isExpandRequest,
  dubbingRequests,
  requestLoading,
  isExpand,
  totalRequests,
  requestTableRef,
  onChangePage,
  onChangeIsExpand,
  onChangeRequestLoading,
  onCloseDialogHistoryChanged,
}) => {
  const { t } = useTranslation();

  // Redux
  const { user } = useSelector((state) => state.auth);

  // Feature flags
  const { getFeatureValue } = useFeatureFlags();
  const useDubbingSubtitleTimeline = getFeatureValue(
    FEATURE_KEYS.DUBBING_SUBTITLE_TIMELINE,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  return (
    <>
      {!!dubbingRequests?.length && useDubbingSubtitleTimeline && (
        <StyledDialogHistoryChanged
          open={openDialogHistoryChanged}
          borderRadius="12px"
          width="700px"
          height="470px"
          titlefontsize="16px"
          titlefontweight="700"
        >
          <DialogTitle classes={{ root: 'dialog-title' }}>
            {t('historyChanges', { value: totalRequests })}
            <IconButton
              aria-label="close"
              onClick={onCloseDialogHistoryChanged}
              color="secondary"
              className="close-button"
            >
              <Close color="secondary" />
            </IconButton>
          </DialogTitle>
          <Box className="request-table-wrapper">
            <HistoryChangedTable
              page={page}
              dubbingRequests={dubbingRequests}
              requestLoading={requestLoading}
              onChangePage={onChangePage}
              onChangeIsExpand={onChangeIsExpand}
              isExpand={isExpand}
              totalRequests={totalRequests}
              onChangeRequestLoading={onChangeRequestLoading}
              showTitle={false}
            />
          </Box>
        </StyledDialogHistoryChanged>
      )}
      {!!dubbingRequests?.length && !useDubbingSubtitleTimeline && (
        <div className="request" ref={requestTableRef} id="requests">
          <Card
            flexDirection="column"
            margin-top="4px"
            padding="4px 16px 0px 16px"
          >
            <HistoryChangedTable
              totalRequests={totalRequests}
              dubbingRequests={dubbingRequests}
              page={page}
              isExpand={isExpandRequest}
              requestLoading={requestLoading}
              onChangePage={onChangePage}
              onChangeIsExpand={onChangeIsExpand}
              onChangeRequestLoading={onChangeRequestLoading}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default HistoryChanged;
