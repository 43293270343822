/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import i18n from '@src/languages';
import { LANGUAGE } from '@src/constants';
import { DialogActions, Typography, Button, CardMedia } from '@mui/material';
import Dialog from '@src/components/Dialog';
import AutoPlayMobileStepper from '@src/components/AutoPlayMobileStepper';
import { StyledServiceManual, StyledGuideStep } from './index.style';

const ServiceManual = ({
  open,
  bannerIndex,
  banner = {},
  closeAction,
  onCloseBanner,
  onActionChange,
}) => {
  const { language } = i18n;
  const [activeStep, setActionStep] = useState('');

  const handleClose = (action) => (event, reason) => {
    const { url, type } = action;
    if (reason === 'backdropClick') return;
    if (onActionChange) onActionChange(url, type);
    onCloseBanner(bannerIndex);
  };

  const handleViewStep = (index) => {
    const step = banner.guideSteps.find(
      (item, itemIndex) => itemIndex === index,
    );
    setActionStep(step);
  };

  useEffect(() => {
    if (banner.guideSteps?.length) setActionStep(banner.guideSteps[0]);
  }, [banner]);

  return (
    <Dialog
      title={
        banner.title && (banner.title[language] || banner.title[LANGUAGE.EN])
      }
      subTitle={
        banner.description &&
        (banner.description[language] || banner.description[LANGUAGE.EN])
      }
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={handleClose(closeAction)}
    >
      <StyledServiceManual>
        <div className="guide-steps">
          <div className="detail-guide-step">
            {banner.guideSteps?.map((guideStep, index) => (
              <StyledGuideStep
                key={guideStep.vi}
                active={guideStep.vi === activeStep?.vi}
                role="button"
                tabIndex="0"
                onClick={() => handleViewStep(index)}
              >
                <Typography className="number">{index + 1}</Typography>
                <Typography className="content">
                  {guideStep[language] || guideStep[LANGUAGE.EN]}
                </Typography>
              </StyledGuideStep>
            ))}
          </div>
          <div className="video-wrapper">
            <CardMedia
              component="iframe"
              autoPlay
              className="video"
              image={activeStep?.videoLink}
              alt="Service manual video"
            />
          </div>
        </div>

        <div className="guide-steps-mobile">
          <AutoPlayMobileStepper items={banner?.guideSteps} play={false}>
            {banner?.guideSteps?.map((guideStep, index) => (
              <>
                <StyledGuideStep key={guideStep.vi} role="button" tabIndex="0">
                  <Typography className="number">{index + 1}</Typography>
                  <Typography className="content">
                    {guideStep[language] || guideStep[LANGUAGE.EN]}
                  </Typography>
                </StyledGuideStep>
                <div className="video-wrapper">
                  <CardMedia
                    component="iframe"
                    autoPlay
                    className="video"
                    image={guideStep?.videoLink}
                    alt="Service manual video"
                  />
                </div>
              </>
            ))}
          </AutoPlayMobileStepper>
        </div>
        <DialogActions classes={{ root: 'dialog-action' }}>
          {banner.actions?.map((action, index) => (
            <Button
              variant={index === 0 ? 'contained' : 'outlined'}
              color={index === 0 ? 'primary' : 'secondary'}
              onClick={handleClose(action)}
              className="action-button"
            >
              {action[language] || action[LANGUAGE.EN]}
            </Button>
          ))}
        </DialogActions>
      </StyledServiceManual>
    </Dialog>
  );
};

export default ServiceManual;
