import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

const PopoverUploadButton = styled('div')`
  width: ${(props) => props.width || 'auto'};

  .custom-button {
    border-radius: 12px;
    padding: 4px 6px;
  }

  .toolbar-text {
    text-transform: lowercase;
  }
`;

const PopoverUploadMenuItem = styled(MenuItem)`
  padding: 12px;
  cursor: pointer;
  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};
  display: flex;
  gap: 10px;
  border-radius: 8px;

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }
  .wrap-label {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${COLOR.secondary[100]};
  }
  .button-img {
    width: 24px;
    height: 24px;
  }
`;

export { PopoverUploadButton, PopoverUploadMenuItem };
