export const actionTypes = {
  UPDATE_DUBBING_REQUEST_BY_KEY: 'UPDATE_DUBBING_REQUEST_BY_KEY',
  UPDATE_DUBBING_REQUEST_ERROR_BY_KEY: 'UPDATE_DUBBING_REQUEST_ERROR_BY_KEY',
  RESET_DUBBING_REQUEST: 'RESET_DUBBING_REQUEST',
};

const updateDubbingRequestByKey = (name, value) => ({
  type: actionTypes.UPDATE_DUBBING_REQUEST_BY_KEY,
  name,
  value,
});

const updateDubbingRequestErrorByKey = (name, value) => ({
  type: actionTypes.UPDATE_DUBBING_REQUEST_ERROR_BY_KEY,
  name,
  value,
});

const resetDubbingRequest = (keys) => ({
  type: actionTypes.RESET_DUBBING_REQUEST,
  keys,
});

export {
  updateDubbingRequestByKey,
  updateDubbingRequestErrorByKey,
  resetDubbingRequest,
};
