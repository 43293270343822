const checkUrlError = () => {
  const currentUrl = new URL(window.location.href);
  return currentUrl.pathname === '/error';
};

const getDomain = (url) => {
  const domain = new URL(url).hostname;
  return domain;
};

export { checkUrlError, getDomain };
