import { RESOURCE } from '@src/constants';
import api from './api';

export const createFeedback = async (payload) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.FEEDBACKS}`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};
