const CONVERT_METHOD = 'convert-method';

const TTS_GUIDE = {
  ENTER_TEXT_HERE: 'enter-text-here',
  UPLOAD_FILE_OR_IMPORT_URL: 'upload-file-or-import-url',
  VOICE: 'voice',
  TRY_LISTENING: 'try-listening',
  CONVERT: 'convert-tts',
  REQUESTS_TABLE: 'requests-table',
};

const TTS_TOUR_GUIDE = 'tts-tour-guide';

const PAYMENT_TOUR_GUIDE = {
  title: 'payment-tour-guide',
  CHOOSE_PAYMENT_METHOD: {
    title: 'choosePaymentMethod',
    content: 'choosePaymentMethodContentTourGuide',
  },
  USE_VOUCHER: {
    title: 'useVoucher',
    content: 'useVoucherContentTourGuide',
  },
  EXPORT_INVOICE: {
    title: 'exportInvoice',
    content: 'exportInvoiceTourGuide',
  },
  MAKE_PAYMENT: {
    title: 'makePayment',
    content: 'makePaymentTourGuide',
  },
};

const TOUR_GUIDE_TYPE = {
  PAYMENT_TOUR_GUIDE: 'payment-tour-guide',
};

export {
  CONVERT_METHOD,
  TTS_GUIDE,
  TTS_TOUR_GUIDE,
  PAYMENT_TOUR_GUIDE,
  TOUR_GUIDE_TYPE,
};
