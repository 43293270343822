import React from 'react';
import { Pagination } from '@mui/material';
import { StyledPagination } from './index.style';

const CustomPagination = ({ page, count, onChangePage }) => {
  const handleChangePage = (e, value) => onChangePage(value);

  return (
    <StyledPagination>
      <Pagination
        classes={{ root: 'pagination' }}
        count={count}
        page={page}
        onChange={handleChangePage}
      />
    </StyledPagination>
  );
};

export default CustomPagination;
