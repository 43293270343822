import React, { useEffect, useState } from 'react';
import { CircularProgress, Table } from '@mui/material';
import {
  StyledFooterLoading,
  StyledFooterNoData,
  StyledNodataLoading,
  StyledTable,
  StyledTableScrollbar,
} from './index.style';
import CustomTableHeader from './TableHeader';
import CustomTableBody from './TableBody';
import CustomTableFooter from './TableFooter';
import NoData from '../NoData';

// columns, data, total, page, onChangePage are required
const CustomTable = ({
  columns,
  hoverColumns,
  data,
  total,
  page,
  sort,
  cellWidth,
  selection,
  selected = [],
  loading,
  headerPadding,
  footerPadding,
  showNumber,
  onChangePage,
  onChangeSort,
  onSelect,
  onRowClick,
  minWidth,
  renderExtraHeader,
  showExtraHeaderComponent,
  tableHeight,
  isStickyHeader = false,
  headerStyle,
  isShowCheckbox = true,
  limit,
}) => {
  const [numSelected, setNumSelected] = useState(0);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const isSelected = (row) =>
    selected.filter((item) => item.id === row.id).length > 0;

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((rowData) => {
        const {
          id,
          title,
          audioLink,
          status,
          retentionPeriod,
          createdAt,
          latestRequestId,
        } = rowData;
        return {
          id,
          title,
          audioLink,
          status,
          retentionPeriod,
          createdAt,
          latestRequestId,
        };
      });
      setNumSelected(newSelected.length);
      if (onSelect)
        onSelect([
          ...new Map(
            [...selected, ...newSelected].map((item) => [item.id, item]),
          ).values(),
        ]);

      return;
    }

    // If row is already selected, remove it from selected list
    if (onSelect) {
      const newSelected = selected.filter(
        (rowSelected) =>
          !data.map((rowData) => rowData.id).includes(rowSelected.id),
      );
      onSelect(newSelected);
      setNumSelected(0);
    }
  };

  const handleSelectRow = (row) => (event) => {
    const {
      id,
      title,
      audioLink,
      status,
      retentionPeriod,
      createdAt,
      latestRequestId,
    } = row;

    const isSelectedRow = selected.filter((item) => item.id === id).length > 0;

    if (event.target.checked && !isSelectedRow) {
      if (onSelect)
        onSelect([
          ...selected,
          {
            id,
            title,
            audioLink,
            status,
            retentionPeriod,
            createdAt,
            latestRequestId,
          },
        ]);
      setNumSelected(numSelected + 1);
      return;
    }

    // If row is already selected, remove it from selected list
    if (onSelect) {
      onSelect(selected.filter((item) => item.id !== id));
      setNumSelected(numSelected - 1);
    }
  };

  useEffect(() => {
    const rowIds = data.map((rowData) => rowData.id);
    const filteredArray =
      selected.filter((row) => rowIds.includes(row.id)) || [];
    setNumSelected(filteredArray.length);
  }, [selected, data]);

  // TODO: fixed table body height when change page
  // TODO: add loading data and show no data
  // TODO: add handle when selection checkbox
  return (
    <>
      <StyledTableScrollbar>
        <StyledTable
          height={data && data.length ? tableHeight : '0px'}
          cellWidth={cellWidth}
          headerPadding={headerPadding}
          stickyHeader={isStickyHeader}
          onMouseEnter={() => setShowScrollbar(true)}
          onMouseLeave={() => setShowScrollbar(false)}
          className={`sidebar-wrapper ${
            showScrollbar ? 'small-scrollbar' : 'hidden-scrollbar'
          } `}
        >
          <Table style={{ minWidth }} stickyHeader={isStickyHeader}>
            <CustomTableHeader
              columns={columns}
              selection={selection}
              sort={sort}
              showNumber={showNumber}
              numSelected={numSelected}
              rowCount={data.length}
              onSelectAll={handleSelectAll}
              onChangeSort={onChangeSort}
              renderExtraHeader={renderExtraHeader}
              showExtraHeaderComponent={showExtraHeaderComponent}
              headerStyle={headerStyle}
              isShowCheckbox={isShowCheckbox}
            />
            <CustomTableBody
              page={page}
              columns={columns}
              hoverColumns={hoverColumns}
              data={data}
              selection={selection}
              showNumber={showNumber}
              isSelected={isSelected}
              loading={loading}
              onSelectRow={handleSelectRow}
              onRowClick={onRowClick}
              isShowCheckbox={isShowCheckbox}
            />
          </Table>
        </StyledTable>
      </StyledTableScrollbar>
      {!loading && data && data.length ? (
        <CustomTableFooter
          total={total}
          page={page}
          onChangePage={onChangePage}
          footerPadding={footerPadding}
          limit={limit}
        />
      ) : null}
      {!loading && !data?.length ? (
        <div>
          <NoData height={tableHeight} />
          <StyledFooterNoData />
        </div>
      ) : null}
      {loading && !data?.length ? (
        <StyledNodataLoading height={tableHeight}>
          <CircularProgress />
        </StyledNodataLoading>
      ) : null}
      {loading && (
        <div>
          <StyledFooterLoading />
        </div>
      )}
    </>
  );
};

export default CustomTable;
