import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Typography } from '@mui/material';
import { FileDownloadOutlined, FlagRounded } from '@mui/icons-material';
import { StyledMenuItem } from './index.style';

const ActionPopper = ({
  anchorEl,
  onClose,
  onDownloadAudio,
  onHandleErrorReport,
  showDownloadAudio,
  showErrorReport,
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {showDownloadAudio && (
        <StyledMenuItem onClick={onDownloadAudio}>
          <FileDownloadOutlined sx={{ fontSize: 24 }} color="iconPrimary" />
          <Typography className="text-action">{t('download')}</Typography>
        </StyledMenuItem>
      )}
      {showErrorReport && (
        <StyledMenuItem onClick={onHandleErrorReport}>
          <FlagRounded sx={{ fontSize: 24 }} color="iconPrimary" />
          <Typography className="text-action">{t('errorReport')}</Typography>
        </StyledMenuItem>
      )}
    </Popover>
  );
};

export default ActionPopper;
