import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import ROUTES from '@src/constants/route';
import { Button, Divider, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import { Check, Clear } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import {
  DURATION,
  PACKAGE_CODE,
  PACKAGE_EXPIRED,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
} from '@src/constants/package';
import actions from '@src/redux/actions';
import i18n from '@src/languages';
import apis from '@src/apis';
import ProcessHandler from '@src/components/ProcessHandler';
import { LANGUAGE } from '@src/constants';
import { ORDER_STATUS } from '@src/constants/order';
import NotificationDialog from '@src/components/NotificationDialog';
import StackedAvatars from '@src/components/StackedAvatars';
import { VBEE_CONSOLE_URL } from '@src/configs';
import { checkVietNam } from '@src/utils/checkCountry';
import { COLOR } from '@src/styles/color';
import { getPackageCurrentPrice } from '@src/services/package';
import PopularIcon from '@src/assets/icons/popular.svg';

import avatar1 from '@src/assets/avatars/avatar1.png';
import avatar2 from '@src/assets/avatars/avatar2.png';
import avatar3 from '@src/assets/avatars/avatar3.png';

import dataSenses from '@src/services/dataSenses';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledPackageItem, StyledPackageItemWrapper } from './index.style';
import CountdownBanner from './CountdownBanner';

const PackageItem = ({ data, hasDiscountPackage, onDiscountEnd, duration }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { voucherCode, bannerType } = camelcaseKeys(
    queryString.parse(location.search) || {},
  );

  const { usingPackage, latestOrder } = useSelector((state) => state.user);

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState({
    createFreeOrder: false,
    cancelOrder: false,
  });
  const [highlight, setHighlight] = useState(false);
  const [isDiscountPackage, setIsDiscountPackage] = useState(false);

  const isVietNam = checkVietNam();

  const { getFeatureValue } = useFeatureFlags();
  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSensesFromServer = dataSensesFeatures?.s2s;

  const { language } = i18n;

  const {
    code,
    price: originalPrice,
    usdPrice: originalUsdPrice,
    monthlyPrice: originalMonthlyPrice,
    usdMonthlyPrice: originalMonthlyUsdPrice,
  } = data;
  const { price, usdPrice, monthlyPrice, usdMonthlyPrice } =
    getPackageCurrentPrice(data, true);

  const isUsingFreePackage =
    [PACKAGE_LEVEL.BASIC, PACKAGE_LEVEL.TRIAL].includes(data.level) &&
    usingPackage.code === data.code;
  const isPaygPackage =
    code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2 && isVietNam;
  const showPackageTotalPrice =
    duration !== DURATION.MONTHLY && price !== 0 && !isPaygPackage;

  const handleCreateFreeOrder = async (packageId) => {
    if (usingPackage.level === PACKAGE_LEVEL.BASIC) return;

    if (latestOrder.status === ORDER_STATUS.PENDING) {
      setOpenDialog(true);
      return;
    }

    setLoading({ ...loading, createFreeOrder: true });
    const orderData = await apis.orders.createOrder({
      packageId,
      isGlobal: !isVietNam,
      datasenses: sendDataSensesFromServer
        ? dataSenses.getDataSensesRequireFields()
        : undefined,
    });
    setLoading({ ...loading, createFreeOrder: false });

    if (orderData.status) {
      dispatch(actions.user.getLatestOrderSuccess(orderData.result));
      dispatch(actions.user.getUsingPackageSuccess(orderData.result.package));
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'buyPackageSuccessfully',
        }),
      );
      history.push(ROUTES.TTS);
      return;
    }

    dispatch(
      actions.noti.push({ severity: 'error', message: 'buyPackageFailure' }),
    );
  };

  const handleBuyPackage = async () => {
    if (data.price === 0) {
      if (isUsingFreePackage) return;
      // window.dataLayer.push({
      //   event: EVENT_TYPE.STUDIO_ACTIVE_FREE_PLAN,
      //   userId: user?.id,
      //   email: user.email,
      //   package: data.code,
      //   createdAt: moment().format('DD/MM/YYYY - HH:mm'),
      // });
      // await delay(1000);
      handleCreateFreeOrder(data.id);
      return;
    }
    const search = voucherCode
      ? `?package=${data.code}&&voucherCode=${voucherCode}&&bannerType=${bannerType}`
      : `?package=${data.code}`;

    if (data.type === PACKAGE_TYPE.API) {
      window.location.assign(`${VBEE_CONSOLE_URL}/payments${search}`);
    } else {
      history.replace({ search });
    }
  };

  const handleCancelPendingOrder = async () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;

    setLoading({ ...loading, cancelOrder: true });
    const orderData = await apis.orders.cancelOrder(latestOrder.id);
    setLoading({ ...loading, cancelOrder: false });
    if (!orderData.status)
      dispatch(actions.noti.push({ severity: 'error', code: orderData.code }));
    else {
      if (orderData.result.type !== PACKAGE_TYPE.API)
        dispatch(actions.user.getLatestOrderSuccess(orderData.result));

      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'cancelOrderSuccessfully',
        }),
      );
    }

    setOpenDialog(false);
    setLoading({ ...loading, createFreeOrder: false });
  };

  const handleContinuePayingDialog = () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (latestOrder.paymentLink)
      window.location.assign(latestOrder.paymentLink);
    else history.replace({ search: `?package=${data.code}` });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const renderButtonText = () => {
    if (highlight) return t('buyNow');
    return t('choose');
  };

  const renderPackagePriceUnit = (value) => {
    if (!value) return '';
    if (value < 1) return `/${t('character')}`;
    return `/${t('month')}`;
  };

  const renderBilledTotalDesc = (packageDuration) => {
    switch (packageDuration) {
      case PACKAGE_EXPIRED.MONTHLY:
        return t('billedMonthly');
      case PACKAGE_EXPIRED.QUARTERLY:
        return t('billedQuarterly');
      case PACKAGE_EXPIRED.YEARLY:
        return t('billedAnnually');
      default:
        return '';
    }
  };

  // display buy button when
  // 1. package item is free and user is not using package
  // 2. package item is free and user is using basic package
  // 3. package item is not free
  const displayBuyButton =
    !usingPackage.id ||
    usingPackage.level === PACKAGE_LEVEL.BASIC ||
    usingPackage.level === PACKAGE_LEVEL.TRIAL ||
    data.price > 0;

  const renderPrice = (priceValue, priceUnit) => (
    <>
      {!isVietNam && <span className="dollar-unit-price">$</span>}
      <Typography
        className={classnames('price', {
          'price-en': !isVietNam && priceValue > 0,
        })}
      >
        {priceUnit}
      </Typography>
      <Typography className="sub-price">
        {isVietNam && <span className="unit-price">đ</span>}
        {renderPackagePriceUnit(data.price)}
      </Typography>
    </>
  );

  const renderTotalPrice = (value) => (
    <>
      {!isVietNam && <span className="original-price">$</span>}
      <Typography
        className={classnames('original-price', {
          'price-en': !isVietNam && value > 0,
        })}
      >
        {value}
        {isVietNam && <span>đ</span>}
      </Typography>
      <Typography className="sub-origin-price">
        {renderBilledTotalDesc(data.expiresIn)}
      </Typography>
    </>
  );

  const MostPopularBanner = () => (
    <div className="most-popular-banner">
      <img src={PopularIcon} alt="most-popular" />
      <Typography className="most-popular-text">{t('mostPopular')}</Typography>
      <img src={PopularIcon} alt="most-popular" />
    </div>
  );

  const EmptyBanner = () => <div className="regular-package-banner" />;

  useEffect(() => {
    const { startAt, endAt } = data.discountConfig || {};
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);
    if (inDiscountTime || (!hasDiscountPackage && data.mostPopular)) {
      setHighlight(true);
    } else setHighlight(false);

    if (inDiscountTime) setIsDiscountPackage(true);
    else setIsDiscountPackage(false);
  }, [data, hasDiscountPackage]);

  const renderBannerPackage = (discount = {}, mostPopular) => {
    const { startAt, endAt } = discount;
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);

    if (inDiscountTime)
      return (
        <CountdownBanner
          date={new Date(endAt).getTime() + 100} //  + 100ms to fix countdown bug
          onComplete={onDiscountEnd}
          completeComponent={
            mostPopular ? <MostPopularBanner /> : <EmptyBanner />
          }
        />
      );
    if (mostPopular && !hasDiscountPackage) return <MostPopularBanner />;

    return <EmptyBanner />;
  };

  return (
    <StyledPackageItemWrapper>
      {renderBannerPackage(data.discountConfig, data.mostPopular)}
      <StyledPackageItem
        highlight={highlight}
        isDiscountPackage={isDiscountPackage}
        free={data.price ? 0 : 1}
      >
        <div className="card-package">
          <div className="package-header">
            <div className="">
              <Typography className="package-name">
                {data.name && (data.name[language] || data.name[LANGUAGE.EN])}
              </Typography>
              {isDiscountPackage && (
                <Typography className="package-limited-deal">
                  {t('limitedTime')}
                </Typography>
              )}
            </div>
            {data.icon ? (
              <div
                className={`${
                  highlight ? 'package-icon-active' : 'package-icon'
                }`}
              >
                <img src={data.icon} alt="icon-type" />
              </div>
            ) : (
              <SchoolIcon
                fontSize="small"
                className={classnames({ 'most-popular': data.mostPopular })}
              />
            )}
          </div>

          {/** Package Price */}

          <div className="price-wrapper">
            {isDiscountPackage && (
              <div className="price-original-discount">
                {isDiscountPackage && isVietNam && (
                  <Typography>{`${(
                    originalMonthlyPrice || originalPrice
                  )?.toLocaleString(language)}`}</Typography>
                )}
                {isDiscountPackage && !isVietNam && (
                  <Typography>{`$${(
                    originalMonthlyUsdPrice || originalUsdPrice
                  )?.toLocaleString(language)}`}</Typography>
                )}
              </div>
            )}
            <div className="price-package">
              {isVietNam
                ? renderPrice(
                    price,
                    monthlyPrice?.toLocaleString(language) ||
                      price?.toLocaleString(language),
                  )
                : renderPrice(
                    usdPrice,
                    `${
                      usdMonthlyPrice?.toLocaleString(language) ||
                      usdPrice?.toLocaleString(language)
                    }`,
                  )}
            </div>
          </div>
          {showPackageTotalPrice ? (
            <div className="original-price-wrapper">
              {isVietNam
                ? renderTotalPrice(
                    (price || originalPrice)?.toLocaleString(language),
                  )
                : renderTotalPrice(
                    `${(usdPrice || originalUsdPrice)?.toLocaleString(
                      language,
                    )}`,
                  )}
            </div>
          ) : (
            <div className="original-price-wrapper" />
          )}

          {/** Button buy package */}
          <div className="card-content">
            {/* Package popularity */}
            {highlight ? (
              <div className="popularity">
                <StackedAvatars images={[avatar1, avatar2, avatar3]} />
                <Typography
                  className="popularity-text"
                  variant="body2"
                  sx={{ color: COLOR.black[64] }}
                >
                  {t('mostPeopleChooseThis', {
                    percent: data?.popularPercent || 86,
                  })}
                </Typography>
              </div>
            ) : (
              <div style={{ height: '30px' }} />
            )}

            <Button
              fullWidth
              className={classnames('button-start', {
                'button-highlight': highlight,
              })}
              variant={highlight ? 'contained' : 'outlined'}
              onClick={handleBuyPackage}
              disabled={!displayBuyButton}
            >
              {renderButtonText()}
            </Button>

            <Divider sx={{ mt: '12px' }} />
            {/* Package features */}
            <List>
              {data?.contents?.length &&
                data.contents.map((item) => (
                  <ListItem className="clarify" key={item.vi}>
                    <div alignItems="flex-start" className="icon-contents">
                      {item.active ? (
                        <Check fontSize="small" color="success" />
                      ) : (
                        <Clear fontSize="small" color="light" />
                      )}
                    </div>
                    <div
                      className={item.active ? 'content' : 'content-deactivate'}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          language === LANGUAGE.VN
                            ? item?.[language]
                            : item[LANGUAGE.EN],
                        ),
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        </div>
        {data.price === 0 && (
          <NotificationDialog
            name="pendingOrder"
            title={t('havePendingOrderTitle')}
            description={t('hasOrderWaitForConfirmTransferDescription')}
            variant="warning"
            open={openDialog}
            onClose={handleCloseDialog}
            actionComponent={
              <>
                <Button variant="outlined" onClick={handleCancelPendingOrder}>
                  <ProcessHandler loading={loading.cancelOrder} size={25}>
                    {t('cancelOrder')}
                  </ProcessHandler>
                </Button>
                <Button
                  variant="contained"
                  onClick={handleContinuePayingDialog}
                >
                  {t('continue')}
                </Button>
              </>
            }
          />
        )}
      </StyledPackageItem>
    </StyledPackageItemWrapper>
  );
};

export default PackageItem;
