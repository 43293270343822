import { useEffect } from 'react';
import { CDN_URL } from '@src/configs';

const FontLoader = () => {
  useEffect(() => {
    const fontUrl = CDN_URL;
    if (fontUrl) {
      const style = document.createElement('style');
      style.innerHTML = `
        /* SF Pro */
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Thin.otf);
          font-weight: 100;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Ultralight.otf);
          font-weight: 200;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Light.otf);
          font-weight: 300;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Regular.otf);
          font-weight: 400;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Medium.otf);
          font-weight: 500;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Semibold.otf);
          font-weight: 600;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Bold.otf);
          font-weight: 700;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Black.otf);
          font-weight: 800;
        }
        @font-face {
          font-family: 'SF Pro';
          src: url(${fontUrl}/fonts/SFProText-Heavy.otf);
          font-weight: 900;
        }

        /* SF Pro Italic */
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-ThinItalic.otf);
          font-weight: 100;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-UltralightItalic.otf);
          font-weight: 200;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-LightItalic.otf);
          font-weight: 300;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-RegularItalic.otf);
          font-weight: 400;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-MediumItalic.otf);
          font-weight: 500;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-SemiboldItalic.otf);
          font-weight: 600;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-BoldItalic.otf);
          font-weight: 700;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-BlackItalic.otf);
          font-weight: 800;
        }
        @font-face {
          font-family: 'SF Pro Italic';
          src: url(${fontUrl}/fonts/SFProText-HeavyItalic.otf);
          font-weight: 900;
        }
      `;

      document.head.appendChild(style);
    }
  }, []);

  return null;
};

export default FontLoader;
