import React from 'react';
import { useTranslation } from 'react-i18next';
import { zeroPad } from 'react-countdown';
import { StyledCountdownTimeInBanner } from './index.style';

const CountdownTime = ({ days, hours, minutes, seconds }) => {
  const { t } = useTranslation();

  const CountDownElement = ({ value, unit }) => (
    <div className="countdown-time">
      <div className="countdown-time-value">{zeroPad(value)}</div>
      <div className="unit-time">{t(unit)}</div>
    </div>
  );

  return (
    <StyledCountdownTimeInBanner>
      <div className="countdown">
        <CountDownElement value={days} unit="day" />
        <div className="two-dot">:</div>
        <CountDownElement value={hours} unit="hour" />
        <div className="two-dot">:</div>
        <CountDownElement value={minutes} unit="minute" />
        <div className="two-dot">:</div>
        <CountDownElement value={seconds} unit="second" />
      </div>
    </StyledCountdownTimeInBanner>
  );
};

export default CountdownTime;
