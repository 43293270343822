import { RESOURCE } from '@src/constants';
import { ERROR_CODE } from '@src/errors/code';
import api from './api';

export const createSynthesis = async (payload, { recaptchaToken }) => {
  const data = { ...payload };
  if (payload.volume) delete data.volume;

  try {
    const response = await api({
      method: 'POST',
      url: RESOURCE.SYNTHESIS,
      data,
      headers: {
        'recaptcha-token': recaptchaToken || '',
      },
    });

    return response;
  } catch (error) {
    return { status: 0, errorCode: ERROR_CODE.BAD_REQUEST };
  }
};
