import moment from 'moment';
import { TIME_DELTA_REGEX } from '@src/constants';
import { isNaN, isNil } from 'lodash';

const formatAudioTime = (numberOfSeconds) => {
  let minutes = Math.floor(numberOfSeconds / 60);
  let seconds = Math.floor(numberOfSeconds) - 60 * minutes;

  minutes = minutes.toString().padStart(2, '0');
  seconds = seconds.toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
};

const isSameToday = (date) => moment().isSame(date, 'day');

// Convert time delta format (hh:mm:ss,SSS) to milliseconds
const convertTimeDeltaToMillis = (timeDelta = '') => {
  const [hour, minute, secondPart] = timeDelta.split(':');
  const second = parseFloat(secondPart.replace(',', '.'));

  return (
    parseInt(hour, 10) * 3600 * 1000 +
    parseInt(minute, 10) * 60 * 1000 +
    second * 1000
  );
};

const isValidTimeDelta = (timeDelta) => {
  const res = TIME_DELTA_REGEX.test(timeDelta);
  return res;
};

const convertSecondsToHMS = (seconds) => {
  if (isNil(seconds) || isNaN(seconds)) return '';

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = Math.ceil(seconds % 60);

  // Add leading zeros if necessary
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  remainingSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${hours}:${minutes}:${remainingSeconds}`;
};

// convert seconds to hh:mm:ss,SSS
const convertSecondsToSubtitleTime = (seconds) => {
  if (isNil(seconds) || isNaN(seconds)) return '';

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;
  let milliseconds = Math.floor((remainingSeconds % 1) * 1000);

  // Format remainingSeconds to include milliseconds
  remainingSeconds = Math.floor(remainingSeconds).toString().padStart(2, '0');
  milliseconds = milliseconds.toString().padStart(3, '0');

  // Add leading zeros if necessary
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}:${remainingSeconds},${milliseconds}`;
};

// Convert format hh:mm:ss,miliseconds to seconds
const convertSubtitleTimeToSeconds = (time) => {
  const [hour, minute, secondPart] = time.split(':');
  const second = parseFloat(secondPart.replace(',', '.'));

  return parseInt(hour, 10) * 3600 + parseInt(minute, 10) * 60 + second;
};

export {
  formatAudioTime,
  isSameToday,
  convertTimeDeltaToMillis,
  isValidTimeDelta,
  convertSecondsToHMS,
  convertSubtitleTimeToSeconds,
  convertSecondsToSubtitleTime,
};
