const setDataLocalStorage = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getDataLocalStorage = (keyName) => {
  const data = localStorage.getItem(keyName);
  if (!data) {
    return null;
  }
  const item = JSON.parse(data);
  if (Date.now() > item.expiry) {
    localStorage.removeItem(keyName);
    return null;
  }
  return item.value;
};

const deleteDataLocalStorage = (keyName) => {
  localStorage.removeItem(keyName);
};

export { setDataLocalStorage, getDataLocalStorage, deleteDataLocalStorage };
