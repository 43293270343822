export const SUBSCRIPTION_PURCHASE_STATUS = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
};

export const AUTO_RENEW_STATUS = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  UPCOMING: 'UPCOMING',
};

export const PAYMENT_HUB_RENEWAL_TYPE = {
  AUTO_RENEW: 'AUTO_RENEW',
  PREPAID: 'PREPAID',
};
