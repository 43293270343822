import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Checkbox, InputBase } from '@mui/material';
import actions from '@src/redux/actions';
import { MAX_SENTENCE_LENGTH } from '@src/constants/dubbing';
import { StyledSentence } from './index.style';

const Sentence = ({
  sentenceKey,
  isSelected,
  onSelectSentence,
  onChangeSentence,
}) => {
  const dispatch = useDispatch();
  const {
    sentences: { [sentenceKey]: data },
  } = useSelector((state) => state.dubbingRequest);

  const handleChangeContent = (e) => {
    const { name, value } = e.target;

    if (value.length > MAX_SENTENCE_LENGTH) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateContentMaxLength',
          value: MAX_SENTENCE_LENGTH,
        }),
      );
      return;
    }
    onChangeSentence({ sentenceKey, name, value });
  };

  return (
    <StyledSentence isSelected={isSelected}>
      <Checkbox
        className="checkbox-cell"
        checked={isSelected}
        onChange={onSelectSentence(sentenceKey)}
      />
      <div className="time-range">
        <Typography className="text-id">
          {parseInt(data?.id, 10)?.toLocaleString()}
        </Typography>
        <Typography className="text-start-end">{`${data?.start} --> ${data?.end}`}</Typography>
      </div>
      <InputBase
        name="content"
        value={data?.content}
        className="content"
        multiline
        fullWidth
        onChange={handleChangeContent}
        spellCheck={false}
      />
    </StyledSentence>
  );
};

export default Sentence;
