import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import { Box, Paper, Popover, Typography } from '@mui/material';
import CopyableButton from '@src/components/CopyableButton';
import inactiveGiftIcon from '@src/assets/icons/inactive-gift.svg';
import activeGiftIcon from '@src/assets/icons/active-gift.svg';
import actions from '@src/redux/actions';
import { getPackageName } from '@src/services/package';

import classNames from 'classnames';
import { StyledVoucher } from './index.style';

const Voucher = ({ voucher, isAutoVoucher, voucherText, applied, onApply }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleApplyVoucher = () => {
    if (voucher.canUse || isAutoVoucher) {
      const { type, value } = voucher;
      dispatch(
        actions.voucher.applyVoucher({
          id: voucher.id,
          type,
          value,
          displayText: voucherText,
        }),
      );
      onApply(voucher);
    }
  };

  const handleUnSelectVoucher = () => {
    dispatch(actions.voucher.unselectVoucher());
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const suitablePackages = voucher.policy?.packages.length
    ? voucher.policy.packages
    : [];

  const getButtonText = () => {
    // if (voucher.used) return t('used');
    if (!voucher.satisfiedPolicy) return t('notEnoughCondition');
    if (!voucher.satisfiedTime) return t('expired');
    if (!voucher.remainingAmount || voucher.usedAmount >= voucher.numReuse)
      return t('notEnoughAmountError');
    return t('apply');
  };

  return (
    <StyledVoucher applied={applied ? 1 : 0}>
      <div className="left-column">
        <img src={applied ? activeGiftIcon : inactiveGiftIcon} alt="icon" />
        <Typography className={classNames({ 'satisfied-text': applied })}>
          {voucherText}
        </Typography>
      </div>
      <div className="right-column">
        <ErrorOutline
          aria-owns={open ? voucher.id : undefined}
          aria-haspopup="true"
          onMouseEnter={handleOpen}
        />
        {!applied ? (
          <Typography onClick={handleApplyVoucher}>
            {getButtonText()}
          </Typography>
        ) : (
          <Typography onClick={handleUnSelectVoucher}>
            {t('unselect')}
          </Typography>
        )}
        <Popover
          id={voucher.id}
          open={open}
          sx={{
            pointerEvents: 'auto',
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            onMouseLeave: handleClose,
          }}
          disableRestoreFocus
        >
          <Paper sx={{ p: 2, display: 'flex', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography>
                <strong>{t('code')}</strong>: {voucher.code}
              </Typography>
              <Typography>
                <strong>{t('expiredDate')}</strong>:{' '}
                {moment(voucher.endTime).format('DD/MM/YYYY - HH:mm')}
              </Typography>
              <Typography>
                <strong>{t('appliedCondition')}</strong>
              </Typography>
              {suitablePackages.length ? (
                suitablePackages.map((item) => (
                  <Typography key={item.id}>
                    {getPackageName(item.name, item.code, i18n.language)}
                  </Typography>
                ))
              ) : (
                <Typography>{t('allPackages')}</Typography>
              )}
            </Box>
            <CopyableButton content={voucher.code} />
          </Paper>
        </Popover>
      </div>
    </StyledVoucher>
  );
};

export default Voucher;
