import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledUploadSubtitleFile = styled('div')`
  .dropzone {
    box-sizing: border-box;
    background: ${COLOR.charcoal};
    border: 2px dashed
      ${(props) => (props.isError ? COLOR.error : TRANSPARENT_COLOR.darkBlue)};
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 32px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    .dropzone-content {
      width: 100%;
      cursor: pointer;
    }

    .divider {
      font-size: 12px;
      font-weight: 400;
      color: ${COLOR.darkV2[80]};
      text-transform: lowercase;
    }

    .view-subtitle-tooltip {
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.white};
      line-height: 20px;
      letter-spacing: -0.4px;
      max-width: 232px;
    }

    .view-subtitle {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px 8px 12px;
      cursor: pointer;
      border-radius: 480px;
      border: 1px solid ${COLOR.darkBlue};
      margin-top: 16px;
      text-transform: none;

      :hover {
        background-color: ${COLOR.white};
      }

      .view-subtitle-text {
        font-size: 14px;
        font-weight: 700;
        color: ${COLOR.darkBlue};
        line-height: 20px;
        letter-spacing: -0.4px;
      }
    }

    .view-subtitle-disabled {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px 8px 12px;
      border-radius: 480px;
      margin-top: 16px;
      text-transform: none;
      background-color: ${COLOR.darkV2[8]};
      border: none;

      .view-subtitle-text {
        font-size: 14px;
        font-weight: 700;
        color: ${COLOR.darkV2[32]};
        line-height: 20px;
        letter-spacing: -0.4px;
      }
    }
  }
`;

export const StyledDefaultDropzoneContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .upload-instruction {
    display: flex;
    gap: 4px;
  }

  .upload-file-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .text-blue {
    color: ${COLOR.labelBlue} !important;
  }
`;

export const StyledSuccessDropzoneContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .file-name {
    font-size: 16px;
    font-weight: 600;
  }

  .upload-file-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .total-characters {
    display: flex;
    gap: 4px;
    margin-top: 8px;
    font-size: 14px;

    .total-characters-text {
      font-size: 14px;
      color: ${COLOR.black[64]};
    }

    .total-characters-number {
      font-size: 14px;
      color: ${COLOR.info};
    }
  }
`;

export const StyledFailedDropzoneContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;

  .file-name {
    font-size: 16px;
    font-weight: 600;
  }

  .upload-instruction {
    display: flex;
    gap: 10px;
  }

  .upload-file-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .text-blue {
    color: ${COLOR.labelBlue} !important;
  }

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin: 4px;
    width: 100%;

    .error-text {
      font-size: 14px;
      color: ${COLOR.error};
    }

    .error-detail-wrapper {
      padding: 12px 14px 12px 16px;
      border-radius: 12px;
      font-size: 14px;
      background: ${COLOR.white};
      width: 100%;

      .error-detail {
        max-height: 160px;
        overflow-y: overlay !important;

        ::-webkit-scrollbar {
          width: 2px;
        }
        ::-webkit-scrollbar-track {
          border-radius: 12px;
          background-color: ${COLOR.white};
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${COLOR.black[80]};
          border-radius: 12px;
        }
      }
    }
  }
`;

export const StyledSampleFileReference = styled('div')`
  height: 28px;
  display: flex;
  gap: 4px;
  align-items: center;
  .sample-text {
    font-size: 14px;
    color: ${COLOR.black[80]};
    font-weight: 500;
  }

  .sample-file {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 14px;

    color: ${COLOR.black[80]};
    font-weight: 500;
  }
`;

export const StyledYoutubeContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .youtube-input {
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 100px;
    border: 1px solid ${(props) => (props.error ? COLOR.red : COLOR.darkV2[32])};
    gap: 6px;
    width: 420px;
    box-sizing: border-box;
    background-color: transparent;

    .youtube-icon {
      width: 24px;
      height: 24px;
      color: ${COLOR.red};
    }

    .youtube-text {
      width: 100%;
      font-size: 14px;
      color: ${COLOR.black[80]};
      font-weight: 500;
      border: none;
      background-color: transparent;

      :focus {
        outline: none;
        background-color: ${COLOR.white};
      }
    }

    .loading-icon {
      width: 24px !important;
      height: 24px !important;
      color: ${COLOR.blue};
    }

    :focus-within {
      background-color: ${COLOR.white};
    }
  }

  .error-message {
    font-size: 12px;
    line-height: 24px;
    color: ${COLOR.error};
  }
`;

export const StyledFileSelected = styled('div')`
  display: flex;
  width: 540px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLOR.white};
  padding: 12px 16px;
  border-radius: 16px;
  cursor: default;

  .file-info {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .youtube-thumbnail {
    width: 96px;
    height: 48px;
    border-radius: 8px;
  }

  .cancel-icon {
    width: 24px;
    height: 24px;
    color: ${COLOR.danger};
    cursor: pointer;
  }

  .blue {
    color: ${COLOR.blue};
  }
`;
