import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyledVoiceDetailItem } from './index.style';

const VoiceDetailItem = ({ title, value }) => {
  const { t } = useTranslation();

  return (
    <StyledVoiceDetailItem>
      <Typography variant="subtitle2">{t(title)}</Typography>
      <div className="content">
        {typeof value === 'string' ? (
          <Typography variant="subtitle2" className={`detail ${value}`}>
            {t(value)}
          </Typography>
        ) : (
          value?.map((item) => (
            <Typography variant="subtitle2" className={`detail ${item}`}>
              {t(item)}
            </Typography>
          ))
        )}
      </div>
    </StyledVoiceDetailItem>
  );
};

export default VoiceDetailItem;
