import {
  Home as HomeIcon,
  GridViewOutlined as DashboardIcon,
  TextFieldsRounded as TTSIcon,
  ShoppingCartOutlined as PackageIcon,
  Groups as AffiliateIcon,
  LocalOfferOutlined as LiveSupportIcon,
  PhoneAndroidRounded as MobileAppIcon,
  SubtitlesOutlined as DubbingIcon,
  CardMembershipRounded as CardMembershipRoundedIcon,
  MicRounded as VoiceCloningIcon,
  Add as AddIcon,
  RecordVoiceOverRounded as VoiceCloningIconMobileSidebar,
  TheatersRounded as DubbingIconMobileSidebar,
  ShoppingCartRounded as PaymentIconMobileSidebar,
  GraphicEqRounded as TTSIconMobileSidebar,
} from '@mui/icons-material';
import Home from '@src/pages/Home';
import ComingSoon from '@src/pages/ComingSoon';
import TTS from '@src/pages/TTS';
import VoiceCloning from '@src/pages/VoiceCloning';
import STT from '@src/pages/STT';
import Dubbing from '@src/pages/Dubbing';
import Payment from '@src/pages/Payment';
import PaymentHistory from '@src/pages/PaymentHistory';
import MobileApp from '@src/pages/MobileApp';
import ManagerDevice from '@src/pages/ManageDevice';
import ConfigAutoRenew from '@src/pages/ConfigAutoRenew';
import Subscription from '@src/pages/Subscription';
import VoiceCloningCreate from '@src/pages/VoiceCloningCreate';

import Affiliate from '@src/pages/Affiliate';
import AffiliateDetail from '@src/pages/AffliateDetail';
import routes from '@src/constants/route';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import DubbingProjectDetail from '@src/pages/DubbingProjectDetail';
import DubbingProject from '@src/pages/DubbingProject';

const ttsAppRoutes = [
  {
    name: 'home',
    path: routes.HOME,
    component: Home,
    icon: HomeIcon,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    featureKey: FEATURE_KEYS.HOME_PAGE,
  },
  {
    name: 'dashboard',
    path: routes.DASHBOARD,
    component: ComingSoon,
    icon: DashboardIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'convertText',
    path: routes.TTS,
    component: TTS,
    icon: TTSIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    isMobileSidebar: true,
    labelMobileSidebar: 'STUDIO',
    iconMobileSidebar: TTSIconMobileSidebar,
  },
  {
    name: 'voiceCloning',
    path: routes.VOICE_CLONING_VOICES,
    component: VoiceCloning,
    icon: VoiceCloningIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    beta: true,
    featureKey: FEATURE_KEYS.VOICE_CLONING,
    isMobileSidebar: true,
    labelMobileSidebar: 'voiceCloning',
    iconMobileSidebar: VoiceCloningIconMobileSidebar,
  },
  {
    name: 'voiceCloningCreate',
    path: routes.VOICE_CLONING_CREATE,
    component: VoiceCloningCreate,
    icon: VoiceCloningIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'voiceCloningVoices',
    path: routes.VOICE_CLONING_VOICE_DETAIL,
    component: VoiceCloningCreate,
    icon: VoiceCloningIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'dubbing',
    path: routes.DUBBING,
    component: Dubbing,
    icon: DubbingIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    beta: false,
    featureKey: FEATURE_KEYS.DUBBING,
    isMobileSidebar: true,
    labelMobileSidebar: 'DUBBING',
    iconMobileSidebar: DubbingIconMobileSidebar,
  },
  {
    name: 'buyPackage',
    path: routes.PAYMENT,
    component: Payment,
    icon: PackageIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    banner: true,
    isMobileSidebar: true,
    labelMobileSidebar: 'buyPackage',
    iconMobileSidebar: PaymentIconMobileSidebar,
  },
  {
    name: 'buyPackage',
    path: routes.PAYMENT_HISTORY,
    component: PaymentHistory,
    icon: PackageIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    banner: true,
  },
  {
    name: 'speechToText',
    path: routes.STT,
    component: STT,
    icon: TTSIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: false,
    beta: true,
  },
  {
    name: 'mobileApp',
    path: routes.MOBILE_APP,
    component: MobileApp,
    icon: MobileAppIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: 'affiliate',
    path: routes.AFFILIATE,
    subPaths: [routes.AFFILIATE_DETAIL],
    component: Affiliate,
    icon: AffiliateIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: 'affiliate-detail',
    path: routes.AFFILIATE_DETAIL,
    component: AffiliateDetail,
    icon: AffiliateIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'onlineSupport',
    path: routes.LIVE_SUPPORT,
    component: ComingSoon,
    icon: LiveSupportIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'otherServices',
    path: routes.OTHER_SERVICES,
    component: ComingSoon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'manageDevice',
    path: routes.MANAGE_DEVICE,
    component: ManagerDevice,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'configAutoRenew',
    path: routes.CONFIG_AUTO_RENEW,
    component: ConfigAutoRenew,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'subscription',
    path: routes.SUBSCRIPTION,
    component: Subscription,
    icon: CardMembershipRoundedIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
];

const dubbingAppRoutes = [
  {
    name: 'home',
    path: routes.DUBBING_HOME,
    component: Home,
    icon: HomeIcon,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    featureKey: FEATURE_KEYS.HOME_PAGE,
  },
  {
    name: 'dubbingProject',
    path: routes.DUBBING_PROJECT,
    component: DubbingProject,
    icon: AddIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    isNewFeature: true,
    featureKey: FEATURE_KEYS.NEW_DUBBING_UI,
  },
  {
    name: 'dubbingRouteName',
    path: routes.HOME_DUBBING,
    component: Dubbing,
    icon: DubbingIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    beta: false,
    featureKey: FEATURE_KEYS.DUBBING,
  },
  {
    name: 'buyPackage',
    path: routes.PAYMENT_DUBBING,
    component: Payment,
    icon: PackageIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    banner: true,
  },
  {
    name: 'buyPackage',
    path: routes.PAYMENT_HISTORY_DUBBING,
    component: PaymentHistory,
    icon: PackageIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    banner: true,
  },
  {
    name: 'affiliate',
    path: routes.AFFILIATE_DUBBING,
    subPaths: [routes.AFFILIATE_DETAIL_DUBBING],
    component: Affiliate,
    icon: AffiliateIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: 'affiliate-detail',
    path: routes.AFFILIATE_DETAIL_DUBBING,
    component: AffiliateDetail,
    icon: AffiliateIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: 'subscription',
    path: routes.SUBSCRIPTION_DUBBING,
    component: Subscription,
    icon: CardMembershipRoundedIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'dubbing-project-detail',
    path: routes.DUBBING_PROJECT_DETAIL,
    component: DubbingProjectDetail,
    icon: CardMembershipRoundedIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
];

export { ttsAppRoutes, dubbingAppRoutes };
