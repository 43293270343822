import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography, Button, Divider, CircularProgress } from '@mui/material';
import { MAX_FILE_NAME_LENGTH } from '@src/constants/dubbing';
import { isValidFile } from '@src/utils/checkValid';
import { StyledFileDropzone } from './index.style';

const MEGA_BYTE = 15 * 1024 * 1024;

export default function FileDropzone({
  fileType,
  onAddFile,
  onChangeFile,
  onChangeFileError,
  className = '',
  showFinishButton = true,
  note,
  hasResetFile,
  onChangeHasResetFile,
}) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [hightlight, setHightlight] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [oversize, setOverSize] = useState(false);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const isValidSize = (size) => size <= MEGA_BYTE;

  const acceptFileTypes = fileType?.map((type) => `.${type}`)?.join(',');

  const getShortFileName = (value) => {
    if (value && value?.length > MAX_FILE_NAME_LENGTH) {
      return `${value.slice(0, MAX_FILE_NAME_LENGTH - 3)}...`;
    }
    return value;
  };

  const handleReset = () => {
    setFile(null);
    setFileName(null);
    setFormatError(false);
    setOverSize(false);
    setHightlight(false);
    setErrorMessage('');
  };

  const handleChangeFileError = () => {
    if (!onChangeFileError) return;
    onChangeFileError();
  };

  const handleChangeFile = (e) => {
    const { files } = e.target;
    handleReset();
    const shortFileName = getShortFileName(files[0]?.name);
    setFileName(shortFileName);
    if (!files || files.length !== 1) return;
    if (!isValidFile(files[0].name, fileType)) {
      setFormatError(true);
      handleChangeFileError();
      return;
    }
    if (!isValidSize(files[0].size)) {
      setOverSize(true);
      handleChangeFileError();
      return;
    }
    setFile(files[0]);
  };

  const handleChangeFileState = async (fileState) => {
    if (!onChangeFile) return;
    try {
      setIsUploadingFile(true);
      await onChangeFile(fileState);
      setIsUploadingFile(false);
    } catch (err) {
      setIsUploadingFile(false);
      setErrorMessage(err.message);
      setFile(null);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setHightlight(true);
  };

  const onDragLeave = () => setHightlight(false);

  const onDrop = (e) => {
    e.preventDefault();
    handleReset();
    const { files } = e.dataTransfer;
    const shortFileName = getShortFileName(files[0]?.name);
    setFileName(shortFileName);
    if (!files || files.length !== 1) return;
    if (!isValidFile(files[0].name, fileType)) {
      setFormatError(true);
      handleChangeFileError();
      return;
    }
    if (!isValidSize(files[0].size)) {
      setOverSize(true);
      handleChangeFileError();
      return;
    }
    setFile(files[0]);
  };

  const openFileDialog = () => fileInputRef.current.click();

  const handleAddSuccessFile = () => {
    onAddFile(file);
    handleReset();
  };

  useEffect(() => {
    if (hasResetFile) {
      handleReset();
      onChangeHasResetFile(false);
    }
  }, [hasResetFile]);

  const ChooseOtherFileButton = () => (
    <div className="other-case">
      <label htmlFor="contained-other-file">
        <input
          id="contained-other-file"
          className="file-input"
          type="file"
          onChange={handleChangeFile}
          accept={acceptFileTypes}
        />
        <Typography
          align="center"
          variant="body1"
          className="choose-other-file"
        >
          {t('chooseOtherFile')}
        </Typography>
      </label>
    </div>
  );

  const UploadSuccess = () => (
    <div
      className="upload-success"
      onClick={(e) => e.stopPropagation()}
      role="presentation"
    >
      <Typography variant="body1" className="success-file-name">
        {fileName}
      </Typography>
      {showFinishButton && (
        <Button
          variant="contained"
          color="primary"
          className="done-button"
          onClick={handleAddSuccessFile}
        >
          {t('done')}
        </Button>
      )}
      <ChooseOtherFileButton />
    </div>
  );

  const UploadError = () => (
    <>
      <Typography variant="body1" className="success-file-name">
        {fileName}
      </Typography>
      <ChooseOtherFileButton />
      {formatError && (
        <Typography align="center" variant="subtitle2" color="error">
          {t('fileFormatError', { fileType: acceptFileTypes })}
        </Typography>
      )}
      {oversize && (
        <Typography align="center" variant="subtitle2" color="error">
          {t('oversizeError')}
        </Typography>
      )}
      {errorMessage && (
        <Typography align="center" variant="subtitle2" color="error">
          {t(errorMessage)}
        </Typography>
      )}
    </>
  );

  const UploadNote = () => (
    <Typography align="center" variant="body2" className="upload-note">
      {note || t('allowedFileFormats', { fileType: fileType.join(', ') })}
    </Typography>
  );

  useEffect(() => {
    if (file) handleChangeFileState(file);
  }, [file]);

  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (!fileInput) return () => {};

    // Reset input value to fix bug when user upload same file
    const handleResetInputValue = () => {
      fileInput.value = null;
    };
    fileInput.addEventListener('click', handleResetInputValue);
    return () => {
      fileInput.removeEventListener('click', handleResetInputValue);
    };
  }, [fileInputRef]);

  return (
    <StyledFileDropzone
      className={className}
      isError={formatError || oversize || errorMessage}
    >
      {isUploadingFile ? (
        <div className={classNames('dropzone', { hightlight })}>
          <CircularProgress />
        </div>
      ) : (
        <div
          className={classNames('dropzone', { hightlight })}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          role="presentation"
        >
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            accept={acceptFileTypes}
            onChange={handleChangeFile}
          />
          {(() => {
            if (file === null) {
              if (formatError || oversize || errorMessage)
                return <UploadError />;
              return (
                <>
                  <Typography
                    align="center"
                    variant="h5"
                    className="upload-file-title"
                  >
                    {t('dropFileInstruct', { fileFormat: acceptFileTypes })}
                  </Typography>
                  <Divider className="divider">{t('or')}</Divider>
                  <Button
                    size="small"
                    className="upload-button"
                    variant="outlined"
                    onClick={openFileDialog}
                  >
                    {t('uploadFile')}
                  </Button>
                  <UploadNote />
                </>
              );
            }
            return <UploadSuccess />;
          })()}
        </div>
      )}
    </StyledFileDropzone>
  );
}
