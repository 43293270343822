import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import Tooltip from '@src/components/Tooltip';
import { COLOR } from '@src/styles/color';
import actions from '@src/redux/actions';
import { StyledAudioSelection } from './index.style';

const DEFAULT_LANGUAGE = {
  value: 'vi-VN',
  country: 'vi-VN',
  label: 'vietnamese',
  flag: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
  type: 'audioTranslated',
};

const AudioSelection = ({ availableLanguages }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [open, setOpen] = useState(false);
  const languageRef = useRef(null);

  const { projectInfo, isSelectListenOriginalAudio } = useSelector(
    (state) => state.dubbingRequest,
  );
  const originalLanguage = projectInfo?.originalInfo?.language;
  const originalLanguageInfo =
    availableLanguages.find((lang) => lang.value === originalLanguage) || {};
  originalLanguageInfo.type = 'audioOriginal';

  const targetLanguageInfo = DEFAULT_LANGUAGE;

  const handleToggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLanguageChange = (newLanguage) => {
    if (newLanguage.type === 'audioOriginal') {
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey(
          'isSelectListenOriginalAudio',
          true,
        ),
      );
    } else {
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey(
          'isSelectListenOriginalAudio',
          false,
        ),
      );
    }
    setOpen(false);
  };

  const renderLanguageOption = (langInfo) => (
    <Box
      key={langInfo.value}
      display="flex"
      gap="8px"
      padding="8px"
      alignItems="center"
      width="152px"
      boxSizing="border-box"
      onClick={() => handleLanguageChange(langInfo)}
      backgroundColor={
        language.type === langInfo.type && COLOR.functionYellow[64]
      }
    >
      <Box
        borderRadius="50%"
        border={`1px solid ${COLOR.label[16]}`}
        width="24px"
        height="24px"
        display="flex"
        justifyContent="center"
      >
        <img src={langInfo.flag} alt="flag" width={32} height={24} />
      </Box>
      <Typography fontSize="14px" fontWeight="500" color={COLOR.secondary[100]}>
        {t(langInfo.type)}
      </Typography>
    </Box>
  );

  useEffect(() => {
    if (isSelectListenOriginalAudio) {
      setLanguage(originalLanguageInfo);
    } else {
      setLanguage(targetLanguageInfo);
    }
  }, [isSelectListenOriginalAudio]);

  return (
    <>
      <StyledAudioSelection ref={languageRef} onClick={handleToggleOpen}>
        <Tooltip title={t(language.label)} arrow placement="top">
          <Box
            borderRadius="50%"
            border={`1px solid ${COLOR.label[16]}`}
            width="24px"
            height="24px"
            display="flex"
            justifyContent="center"
          >
            <img src={language.flag} alt="flag" width={32} height={24} />
          </Box>
        </Tooltip>
        <Typography
          fontSize="14px"
          fontWeight="500"
          color={COLOR.secondary[100]}
        >
          {t(language.type)}
        </Typography>

        {open ? (
          <ArrowDropUpRounded
            className="option-button"
            sx={{ fontSize: '26px' }}
          />
        ) : (
          <ArrowDropDownRounded
            className="option-button"
            sx={{ fontSize: '26px' }}
          />
        )}
      </StyledAudioSelection>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper
            open={open}
            anchorEl={languageRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            style={{
              zIndex: 11,
              background: 'white',
              cursor: 'pointer',
            }}
            disablePortal
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 5],
                },
              },
            ]}
          >
            <Paper>
              {renderLanguageOption(targetLanguageInfo)}
              {renderLanguageOption(originalLanguageInfo)}
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default React.memo(AudioSelection);
