import { useEffect, useState } from 'react';

const useElementWidth = (elementRef) => {
  const [width, setWidth] = useState(elementRef?.current?.offsetWidth);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.target === elementRef.current) {
          if (entry.contentRect.width !== width) {
            setWidth(entry.contentRect.width);
          }
        }
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, [elementRef]);

  return width;
};

export default useElementWidth;
