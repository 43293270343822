import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Typography } from '@mui/material';
import SimpleDialog from '@src/components/Dialog/SimpleDialog';
import DownloadIcon from '@src/assets/icons/download.svg';
import DeleteIcon from '@src/assets/icons/delete.svg';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { downloadAudioLinks } from '@src/apis/requests';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FREE_PACKAGE_CODE } from '@src/constants/package';
import { DOWNLOAD_QUOTA_EXCEEDED_CODE } from '@src/constants/tts';
import { StyledActionWarning, StyledProjectsActionHeader } from './index.style';

const ProjectsActionHeader = ({
  selectedProjects,
  onDeleteSelectedRequests,
  colPosition,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getFeatureValue } = useFeatureFlags();

  const [notification, setNotification] = useState({});

  const { usingDubbingPackage } = useSelector((state) => state.user);

  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const renderConfirmActions = ({ handleClose, handleConfirm }) => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleClose} className="no-button">
        {t('noValue')}
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const getAlreadyConvertedProjects = (projects) => {
    const alreadyConvertedProjects = projects.filter(
      ({ latestRequestId }) => latestRequestId,
    );

    return alreadyConvertedProjects;
  };

  const getDownloadableRequests = (requests) => {
    const downloadableRequests = requests.filter(
      ({ status, retentionPeriod, createdAt }) => {
        const audioSuccess = status === REQUEST_STATUS.SUCCESS;
        const audioExpired = moment().isAfter(
          moment(createdAt).add(retentionPeriod, 'days'),
        );
        return audioSuccess && !audioExpired;
      },
    );

    return downloadableRequests;
  };

  const getExpiredRequests = (requests) => {
    const expiredRequests = requests.filter(
      ({ status, retentionPeriod, createdAt }) =>
        status === REQUEST_STATUS.SUCCESS &&
        moment().isAfter(moment(createdAt).add(retentionPeriod, 'days')),
    );
    return expiredRequests;
  };

  const getFailedRequests = (requests) => {
    const failedRequests = requests.filter(
      ({ status }) => status !== REQUEST_STATUS.SUCCESS,
    );
    return failedRequests;
  };

  const handleCloseNotification = () => setNotification({});

  const handleDeleteSelectedRequests = () => onDeleteSelectedRequests();

  const handleOpenDeleteRequests = () => {
    setNotification({
      open: true,
      title: t('deleteProjectTitle'),
      description: t('deleteProjectTitleDescription', {
        total: selectedProjects.length,
      }),
      actionComponent: renderConfirmActions({
        handleClose: handleCloseNotification,
        handleConfirm: () => {
          handleDeleteSelectedRequests();
          handleCloseNotification();
        },
      }),
    });
  };

  const handleDownloadRequests = async (requests) => {
    if (
      isLimitDownloadFreePackage &&
      requests.length > 1 &&
      FREE_PACKAGE_CODE.includes(usingDubbingPackage?.code)
    ) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }

    const validRequests = getDownloadableRequests(requests);

    const useOldDownloadAll = getFeatureValue(FEATURE_KEYS.DOWNLOAD_AUDIO_LINK);
    if (useOldDownloadAll) {
      const listAudioLink = validRequests.map((req) => req.audioLink);
      downloadAudioLinks(listAudioLink);
      return;
    }

    const presignedUrls = [];
    await Promise.all(
      validRequests.map(async ({ id, title }) => {
        try {
          const res = isLimitDownloadFreePackage
            ? await apis.requests.sendDownloadAudioEvent(id)
            : await apis.requests.getPresignedAudioUrl(id);
          if (res?.status === 1) presignedUrls.push(res.result?.audio);
          else {
            if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
              dispatch(actions.user.updateShowBlockDownloadDialog(true));
              return;
            }
            throw new Error();
          }
        } catch (error) {
          dispatch(
            actions.noti.push({
              severity: 'error',
              message: 'downloadError',
              value: title,
            }),
          );
        }
      }),
    );

    downloadAudioLinks(presignedUrls);
  };

  const validateRequestsForDownload = (requests) => {
    const validAudios = getDownloadableRequests(requests);
    const expiredAudios = getExpiredRequests(requests);
    const failedAudios = getFailedRequests(requests);

    return {
      validAudios,
      expiredAudios,
      failedAudios,
    };
  };

  const showNotification = (title, description, actionComponent) => {
    setNotification({
      open: true,
      title,
      description,
      actionComponent,
    });
  };

  const handleOpenDownloadPopup = async () => {
    let description = '';
    let actionComponent;

    const alreadyConvertedProjects =
      getAlreadyConvertedProjects(selectedProjects);

    const totalAlreadyConvertedProjects = alreadyConvertedProjects?.length || 0;

    // Check if there is exist project has not been converted
    if (
      !totalAlreadyConvertedProjects ||
      totalAlreadyConvertedProjects < selectedProjects.length
    ) {
      description = t('cannotDownloadProjectHasNotConvert');
      actionComponent = (
        <StyledActionWarning>
          <Button onClick={handleCloseNotification} variant="contained">
            {t('understood')}
          </Button>
        </StyledActionWarning>
      );

      showNotification(t('downloadTitle'), description, actionComponent);
      return;
    }

    // Get requests of selected projects
    const requests = await Promise.all(
      selectedProjects.map(async (project) => {
        const res = await apis.requests.getRequest(project.latestRequestId);
        if (res?.status === 1) return res.result;
        return null;
      }),
    );

    const { validAudios, expiredAudios, failedAudios } =
      validateRequestsForDownload(requests);

    const totalValid = validAudios?.length || 0;
    const totalExpired = expiredAudios?.length || 0;
    const totalFailed = failedAudios?.length || 0;

    if (!totalValid) {
      // There is no valid audios
      const expireTime = expiredAudios[0]?.retentionPeriod;
      if (totalExpired > 0) description = t('invalidAudios', { expireTime });
      else description = t('cannotDownloadFailedRequests');
      actionComponent = (
        <StyledActionWarning>
          <Button onClick={handleCloseNotification} variant="contained">
            {t('understood')}
          </Button>
        </StyledActionWarning>
      );
      showNotification(t('downloadTitle'), description, actionComponent);
      return;
    }

    if (totalValid !== selectedProjects.length) {
      // There are some valid audios, some invalid
      if (totalFailed === 0) {
        description = t('downloadDescriptionForExpired', {
          total: selectedProjects.length,
          totalValid,
          totalInvalid: selectedProjects.length - totalValid,
          expire: expiredAudios[0]?.retentionPeriod,
        });
      } else if (totalExpired === 0) {
        description = t('downloadDescriptionForFailed', {
          total: selectedProjects.length,
          totalValid,
          totalInvalid: selectedProjects.length - totalValid,
        });
      } else {
        description = t('downloadDescription', {
          total: selectedProjects.length,
          totalValid,
          totalExpired,
          totalFailed,
          expire: expiredAudios[0]?.retentionPeriod,
        });
      }
      actionComponent = renderConfirmActions({
        handleClose: handleCloseNotification,
        handleConfirm: () => {
          handleDownloadRequests(requests);
          handleCloseNotification();
        },
      });
      showNotification(t('downloadTitle'), description, actionComponent);
      return;
    }

    handleDownloadRequests(requests);
  };

  return (
    <StyledProjectsActionHeader>
      {colPosition === 0 ? (
        <>
          <Typography className="selected-requests ">
            {t('selectedProjects', {
              value: selectedProjects?.length || 0,
            })}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenDownloadPopup}
            disabled={selectedProjects?.length === 0}
            className="download-button"
            startIcon={<img src={DownloadIcon} alt="download" />}
          >
            {t('download')}
          </Button>
        </>
      ) : (
        <Button
          variant="outlined"
          color="error"
          onClick={handleOpenDeleteRequests}
          disabled={selectedProjects?.length === 0}
          className="delete-button"
          startIcon={<img src={DeleteIcon} alt="delete" />}
        >
          {t('delete')}
        </Button>
      )}

      <SimpleDialog
        name="notification-dialog"
        className="notification-dialog"
        title={notification?.title}
        description={notification?.description || ''}
        open={notification?.open}
        onClose={handleCloseNotification}
        actionComponents={notification?.actionComponent}
        PaperProps={{ sx: { borderRadius: '12px', padding: '20px' } }}
      />
    </StyledProjectsActionHeader>
  );
};

export default ProjectsActionHeader;
