import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '@src/styles/config';
import { convertSubtitleTimeToSeconds } from '@src/utils/time';
import Toolbar from './Toolbar';
import { StyledTimeFrame } from './index.style';
import TimeLine from './TimeLine';

const TimeFrame = forwardRef(
  (
    {
      dubbingRequests,
      timeFrameRef,
      availableLanguages,
      isExpandTimeFrame,
      setIsExpandTimeFrame,
      selectedSentencesId,
      setSelectedSentencesId,
      setIsChangeTimeByClick,
    },
    ref,
  ) => {
    const { user } = useSelector((state) => state.auth);
    const { openSidebar } = useSelector((state) => state.layout);
    const { sentences } = useSelector((state) => state.dubbingRequest);
    const { audioLink } = useSelector((state) => state.audioPlayer);
    const { sentencesChanged } = useSelector(
      (state) => state.dubbingRequest.changedProjectInfo,
    );
    const [mockData, setMockData] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(5); // Initial value for the slider

    const { isSelectListenOriginalAudio } = useSelector(
      (state) => state.dubbingRequest,
    );

    const { volume } = useSelector((state) => state.audioPlayer);

    const audioRef = useRef(null);

    const { getFeatureValue } = useFeatureFlags();

    const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
      screenWidth: window.innerWidth,
    });
    let useNewSideBar = getFeatureValue(FEATURE_KEYS.NEW_SIDEBAR);
    useNewSideBar = useNewSideBar && useNewTtsUI;

    const getSidebarWidth = () => {
      let sidebarWidth = 0;
      if (useNewSideBar) {
        sidebarWidth = openSidebar
          ? NEW_SIDEBAR_WIDTH
          : SIDEBAR_WIDTH_COLLAPSED;
      } else {
        sidebarWidth = openSidebar ? SIDEBAR_WIDTH : 0;
      }
      return sidebarWidth;
    };

    const handleChangeTimePositionWhenClick = (time) => {
      ref.current.setTime(time);
      setIsChangeTimeByClick(true);
      if (!isSelectListenOriginalAudio && audioRef) {
        audioRef.current.currentTime = time;
      }
    };

    const handleSelectSentence = (sentence) =>
      setSelectedSentencesId(sentence.id);

    const getMockData = useCallback(() => {
      if (!sentences) return [];

      const displaySentences = JSON.parse(JSON.stringify(sentences));

      if (Object.keys(sentencesChanged).length > 0) {
        Object.keys(sentencesChanged).forEach((key) => {
          displaySentences[key] = {
            ...displaySentences[key],
            ...sentencesChanged[key],
          };
        });
      }

      const actions = Object.values(displaySentences)?.map((sentence) => ({
        id: sentence.id,
        start: convertSubtitleTimeToSeconds(sentence.start),
        end: convertSubtitleTimeToSeconds(sentence.end),
        effectId: 'subtitle',
        value: sentence.content,
        selected: selectedSentencesId === sentence.id,
      }));

      return [{ id: '0', actions }];
    }, [sentences, sentencesChanged, selectedSentencesId]);

    useEffect(() => {
      if (!sentences) return;
      setMockData(getMockData());
    }, [sentences, getMockData]);

    useEffect(() => {
      if (audioLink && audioRef.current) {
        audioRef.current.src = audioLink;
      }
    }, [audioLink]);

    useEffect(() => {
      if (audioRef.current) {
        audioRef.current.volume = volume;
      }
    }, [volume]);

    return (
      <StyledTimeFrame
        isExpandTimeFrame={isExpandTimeFrame}
        ref={timeFrameRef}
        sidebarWidth={getSidebarWidth()}
      >
        <Toolbar
          dubbingRequests={dubbingRequests}
          availableLanguages={availableLanguages}
          isExpandTimeFrame={isExpandTimeFrame}
          setIsExpandTimeFrame={setIsExpandTimeFrame}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          timelineRef={ref}
          setIsChangeTimeByClick={setIsChangeTimeByClick}
          translatedAudioRef={audioRef}
        />
        <TimeLine
          ref={ref}
          data={mockData}
          zoomLevel={zoomLevel}
          handleSelectSentence={handleSelectSentence}
          handleChangeData={() => {}}
          handleClickTimeArea={(time) => {
            handleChangeTimePositionWhenClick(time);
          }}
          handleChangeTimePositionWhenClick={handleChangeTimePositionWhenClick}
        />
      </StyledTimeFrame>
    );
  },
);

export default TimeFrame;
