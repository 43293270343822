import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledDialog = styled(Dialog)`
  min-width: ${(props) => props.width || 'auto'};
  .dialog-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 20px 0px 20px;
    gap: 10px;
    .close-button {
      display: flex;
      justify-content: end;
      margin-bottom: -20px;
      margin-right: -8px;
    }
    .dialog-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 4px;
    }
    .dialog-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: ${COLOR.text};
      margin-bottom: 8px;
    }
    .dialog-description {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: ${COLOR.black.default};
      gap: 8px;
      border: 2px solid;
      border-radius: 20px;
      padding: 12px 16px 12px 16px;
      border-color: ${COLOR.gray};
    }
    .dialog-action {
      display: flex;
      padding-bottom: 16px;
      align-items: center;
      justify-content: end;
    }
    .submit-button {
      padding: 8px 22px 8px 22px;
      width: 160px;
      height: 40px;
    }
  }
`;

export const StyledCheckBoxContent = styled('div')`
  display: flex;
  justifiy-content: start;
  align-items: start;
  padding: 4px 0px 4px 0px;
  .checkbox {
    color: ${COLOR.darkBlue}!important;
    padding: 0px 16px 0px 16px;
    font-weight: 400;
    width: 24px;
    height: 24px;
    margin-left: -2px;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${COLOR.black.default};
  }
`;

export const StyledRatingSuccess = styled('div')`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  align-items: center;
  justify-content: center;
  width: 560px;
  gap: 10px;
  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: ${COLOR.text};
  }
  .img {
    width: 240px;
    height: 240px;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    width: 300px;
    color: ${COLOR.text};
    text-align: center;
    padding: 0px 0px 16px 0px;
  }
  .button {
    display: flex;
    padding-bottom: 16px;
    align-items: center;
    justify-content: center;
  }
`;
