import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { PAYMENT_METHOD_TYPE } from '@src/constants/payment';

import { StyledPaymentMethodItem } from './index.style';

const PaymentMethodItem = ({
  method,
  useAutoRenewWithMomo,
  isAutoRenewYearPackage = false,
  selected,
  onSelect,
  disabled,
}) => {
  const { t } = useTranslation();

  const isMomoPayMethod = method?.type === PAYMENT_METHOD_TYPE.MOMOPAY;
  const isAutoRenewMomoDisabled = !useAutoRenewWithMomo && isMomoPayMethod;
  const isAutoRenewYearPackageEnabled =
    isAutoRenewYearPackage && isMomoPayMethod;

  const isDisabledMomoPay = useMemo(
    () => isAutoRenewMomoDisabled || isAutoRenewYearPackageEnabled,
    [isAutoRenewMomoDisabled, isAutoRenewYearPackageEnabled],
  );

  const handleSelectBank = () => {
    if (!(disabled || isDisabledMomoPay)) onSelect(method);
  };

  return (
    <StyledPaymentMethodItem
      selected={selected}
      onClick={handleSelectBank}
      disabled={disabled || isDisabledMomoPay}
    >
      <div className="logo">
        <img src={method && method.logo} alt="i" className="logo-img" />
      </div>
      <Typography className="name">
        {disabled ? `${method?.name} (${t('haveSoon')})` : method?.name}
      </Typography>
    </StyledPaymentMethodItem>
  );
};

export default PaymentMethodItem;
