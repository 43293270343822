const DURATION = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
};

const PACKAGE_EXPIRES = {
  MONTHLY: 30,
  QUARTERLY: 90,
  YEARLY: 365,
};

const PACKAGE_DURATION_LABEL = {
  MONTHLY: 'monthlyPackage',
  QUARTERLY: 'quarterlyPackage',
  YEARLY: 'yearlyPackage',
};

const PACKAGE_DURATION = {
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
};

const PACKAGE_STATUS = {
  USING: 'USING',
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
  CANCEL: 'CANCEL',
  PAID: 'PAID',
};

const ORDER_STATUS = {
  PENDING: 'PENDING',
  CANCEL: 'CANCEL',
  PAID: 'PAID',
};

const FREE_PACKAGE_CODES = [
  'STUDIO-TRIAL',
  'STUDIO-BASIC',
  'STUDIO-FREE',
  'DUBBING-BASIC',
  'DUBBING-TRIAL',
];

const PACKAGE_LEVEL = {
  TRIAL: 'TRIAL',
  BASIC: 'BASIC',
  ADVANCE: 'ADVANCE',
  PRO: 'PRO',
  ENTERPRISE: 'ENTERPRISE',
  PROFESSIONAL: 'PROFESSIONAL',
  STANDARD: 'STANDARD',
  SPECIAL: 'SPECIAL',
  allPackages: 'allPackages',
  DUBBING_BASIC: 'DUBBING-BASIC',
  DUBBING_TRIAL: 'DUBBING-TRIAL',
};

const AFFILIATE_PACKAGE_CODE_LEVEL = {
  TRIAL: 'TRIAL',
  BASIC: 'BASIC',
  PRO: 'PRO',
  ENTERPRISE: 'ENTERPRISE',
  PROFESSIONAL: 'PROFESSIONAL',
  allPackages: 'allPackages',
  ADV: 'ADVANCE',
};

const PACKAGE_TYPE = {
  STUDIO: 'STUDIO',
  API: 'API',
  ARTICLE: 'ARTICLE',
  CHARACTER: 'CHARACTER',
  DUBBING: 'DUBBING',
  CLONING: 'CLONING',
  STT: 'STT',
};

const FEATURE = {
  VIETNAM_VOICE: 'vietnam-voice',
  GLOBAL_VOICE: 'global-voice',
  TTS_BY_SENTENCE: 'tts-by-sentences',
  TTS_BY_SSML: 'tts-by-ssml',
  TTS_BY_FILE: 'tts-by-file',
  TTS_BY_URL: 'tts-by-url',
  FREE_TRIAL: 'free-trial',
  VOICE_EFFECT: 'voice-effect',
  EMPHASIS: 'emphasis',
  PREVIEW: 'preview',
  CLIENT_PAUSE: 'client-pause',
  INVOICE: 'invoice',
  STREAMING_PREVIEW: 'streaming-preview',
};

const FREE_PACKAGE_CODE = [
  'STUDIO-TRIAL',
  'STUDIO-BASIC',
  'STUDIO-FREE',
  'DUBBING-BASIC',
];

const LIMITED_PREVIEW_PACKAGE_LEVEL = ['TRIAL'];

const PACKAGE_CODE = {
  API_PAYG: 'API-PAYG',
  API_ENTERPRISE_YEAR_V2: 'API-ENTERPRISE-YEAR-V2',
  STUDIO_FREE: 'STUDIO-FREE',
  STUDIO_BASIC: 'STUDIO-BASIC',
  DUBBING_TRIAL: 'DUBBING-TRIAL',
};

const PRODUCT_TYPE = {
  aivoiceStudio: 'STUDIO',
  aivoiceApi: 'API',
  aivoiceDubbing: 'DUBBING',
};

const PREFIX_AUTO_RENEW = 'AUTO-RENEW';

export {
  DURATION,
  PACKAGE_EXPIRES as PACKAGE_EXPIRED,
  PACKAGE_DURATION_LABEL,
  PACKAGE_STATUS,
  FREE_PACKAGE_CODES,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
  FEATURE,
  FREE_PACKAGE_CODE,
  LIMITED_PREVIEW_PACKAGE_LEVEL,
  PACKAGE_CODE,
  PACKAGE_DURATION,
  AFFILIATE_PACKAGE_CODE_LEVEL,
  ORDER_STATUS,
  PRODUCT_TYPE,
  PREFIX_AUTO_RENEW,
};
