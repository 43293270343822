import { RESOURCE } from '@src/constants';
import api from './api';

const getSampleScript = async () => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.SAMPLE_SCRIPTS,
  });
  return response;
};

export { getSampleScript };
