import { RESOURCE } from '@src/constants';
import api from './api';

const getBlackWords = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.BLACK_WORDS}`,
  });
  return response;
};

export { getBlackWords };
