import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledSentence = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: ${({ height }) => height || '60px'};
  padding: ${({ padding }) => padding || '8px 12px'} ;
  border-bottom: 1px solid ${COLOR.divider};

  background-color: ${({ isSelected }) => (isSelected ? COLOR.charcoal : null)};
  white-space: nowrap;
  min-width: 900px;

  .checkbox-cell {
    border-radius: 0%;
    color: ${COLOR.darkBlue} !important;
    border-right: 1px solid ${COLOR.divider};
  }

  .time-range {
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 0px 12px;

    .text-id {
      border-right: 1px solid ${COLOR.divider};
      padding-right: 8px;
      width: 40px;
      text-align: right;
    }

    .text-start-end {
      padding-left: 8px;
      width: 240px;
      text-align: left;
    }
  }

  .content {
    padding: 8px 28px;
    border-left: 1px solid ${COLOR.divider};
    outline: none;
    background-color: transparent;
  }

  .checkbox {
    border-radius: 0%;
    color: ${COLOR.darkBlue} !important;
  }

  .time-range-header {
    padding: 0px 16px;
    width: 292px;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
  }

  .content-header {
    padding: 8px 26px;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
  }

  .delete-button {
    color: ${COLOR.red};
    border: 1px solid ${COLOR.gray};
    border-top: none;
    border-bottom: none;
    border-right: none;

    &:hover {
      background: ${COLOR.gray}
    },
  }
`;

export const StyledSentences = styled('div')`
  position: relative;
  overflow: auto;

  .select-request-popper {
    position: fixed;
    bottom: 10%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: ${COLOR.white};
    box-shadow: 0px 4px 15px rgba(44, 63, 88, 0.35);
    border-radius: ${BORDER_RADIUS};
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 480px;

    .button-group {
      display: flex;
      gap: 8px;
    }
  }
`;

export const StyledActionWarning = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;
