import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledCircularProcess = styled(Box)`
  position: relative;
  .circular-background {
    color: ${TRANSPARENT_COLOR.secondary};
  }

  .value-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
