import React from 'react';
import { useTranslation } from 'react-i18next';
import { calculateElapsedDateTime } from '@src/utils/date';

import { StyledTableTitle } from './index.style';

const TitleTable = ({ title, createdAt }) => {
  const { t } = useTranslation();
  return (
    <StyledTableTitle>
      <div>{title}</div>
      <div className="title-time">{calculateElapsedDateTime(createdAt, t)}</div>
    </StyledTableTitle>
  );
};

export default TitleTable;
