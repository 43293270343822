import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import {
  Step,
  StepLabel,
  Grid,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import { CRM_URL } from '@src/configs';
import { ORDER_STATE, ORDER_STATUS } from '@src/constants/order';
import { PAYMENT_METHOD_TYPE, PAYMENT_STEP } from '@src/constants/payment';
import ROUTES from '@src/constants/route';
import { BANNER_TYPE } from '@src/constants/banner';
import apis from '@src/apis';
import ComeBack from '@src/components/ComeBack';
import actions from '@src/redux/actions';
import Card from '@src/components/Card';
import {
  FEATURE,
  PACKAGE_STATUS,
  PACKAGE_TYPE,
  FREE_PACKAGE_CODE,
} from '@src/constants/package';
import { validateEmail } from '@src/utils/checkValid';
import { PAYMENT_TOUR_GUIDE } from '@src/constants/tourGuide';
import { checkVietNam } from '@src/utils/checkCountry';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { getPackageCurrentPrice, getPackageName } from '@src/services/package';
import dataSenses from '@src/services/dataSenses';
import {
  StyledPaymentWrapper,
  StyledStepConnector,
  StyledStepIcon,
  StyledStepper,
} from './index.style';
import InvoiceDialog from './CreateInvoice';
import PaymentBox from './PaymentBox';
import OrderBox from './OrderBox';
import PaymentNotificationDialogs from './PaymentNotificationDialogs';
import VoucherBox from './VoucherBox';
import PaymentDialog from './PaymentDialog';
import TourGuide from '../TourGuide';

const steps = [
  'choosePackage',
  'paymentMethod',
  'payment',
  'pendingPayment',
  'completed',
];

const CustomStepIcon = (props) => {
  const { icon, active, completed } = props;
  return (
    <StyledStepIcon active={active} completed={completed}>
      {icon}
    </StyledStepIcon>
  );
};

const DIALOG_NAMES = {
  NOT_SELECTED_BANK: 'notSelectedBank',
  UNPAID_ORDER: 'unpaidOrder', // Create an order but customer haven't transfer
  NOT_CONFIRMED_ORDER: 'notConfirmedOrder', // Customer confirmed transfer, waiting for confirmation form customer service
  CONFIRM_TRANSACTION: 'confirmTransaction', // Customer click "I have transferred"
  COMPLETED_ORDER: 'completedOrder',
  DOWNGRADE_PACKAGE: 'downgradePackage',
  CONFIRM_CANCEL_ORDER: 'confirmCancelOrder',
};

const initialInvoice = {
  companyName: '',
  companyAddress: '',
  companyTax: '',
  companyEmail: '',
};

const Payment = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(PAYMENT_STEP.PAYMENT_METHOD);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [selectedBank, setSelectedBank] = useState();
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const [openDialog, setOpenDialog] = useState({});
  const [currPackage, setCurrPackage] = useState({});
  const [hasPaid, setHasPaid] = useState(false);
  const [downgradePackage, setDowngradePackage] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(null);
  const [loading, setLoading] = useState({
    transfer: false,
    cancelOrder: false,
  });
  const [isTransferOrder, setIsTransferOrder] = useState(false);
  const [isShowVoucherBox, setIsShowVoucherBox] = useState(true);

  const [invoice, setInvoice] = useState(initialInvoice);
  const [invoiceError, setInvoiceError] = useState({});
  const [checkedInvoice, setCheckedInvoice] = useState(false);
  const [runTourGuide, setRunTourGuide] = useState(false);
  const [hasFirstNotify, setHasFirstNotify] = useState(true);

  const { user } = useSelector((state) => state.auth);
  const { latestOrder, usingPackage } = useSelector((state) => state.user);
  const { appliedVoucher } = useSelector((state) => state.voucher);

  const { getFeatureValue } = useFeatureFlags();
  const enableNewPaymentPackage = getFeatureValue(
    FEATURE_KEYS.NEW_PAYMENT_PACKAGES,
  );

  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSensesFromServer = dataSensesFeatures?.s2s;

  const isVietNam = checkVietNam();
  const { price: currPrice, usdPrice: currUsdPrice } = getPackageCurrentPrice(
    currPackage,
    enableNewPaymentPackage,
  );

  const { language } = i18n;

  const getSavedPrice = () => {
    if (!appliedVoucher) return 0;

    const { type, value } = appliedVoucher;
    if (type === VOUCHER_TYPE.PERCENT) {
      const priceValue = isVietNam ? currPrice : currUsdPrice;
      return Math.round((value * priceValue) / 100);
    }
    if (type === VOUCHER_TYPE.PRICE) return value;
    return 0;
  };

  const savedPrice = getSavedPrice();

  const getTotalPrice = () => {
    const money = isVietNam ? currPrice : currUsdPrice;
    if (!appliedVoucher) return money;
    let price = money - savedPrice >= 0 ? money - savedPrice : 0;
    // round price to at most 2 decimal
    price = Math.round(price * 100) / 100;
    return price >= 0 ? price : 0;
  };

  const totalPrice = getTotalPrice();

  const handleChangeRunTourGuide = (value) => {
    setRunTourGuide(value);
    dispatch(actions.user.isRunningTourGuide(value));
  };

  const handleChangeCheckedInvoice = () => {
    setCheckedInvoice(!checkedInvoice);
  };

  const handleChangeInvoice = (event) => {
    const { name, value } = event.target;
    setInvoiceError({ ...invoiceError, [name]: null });
    setInvoice({ ...invoice, [name]: value });
  };

  const verifyInvoice = () => {
    const { companyName, companyAddress, companyTax, companyEmail } = invoice;
    const errors = {};

    if (!companyName) errors.companyName = 'companyNameRequired';
    if (companyName && companyName.length < 2)
      errors.companyName = 'companyNameInvalid';
    if (!companyAddress) errors.companyAddress = 'companyAddressRequired';
    if (!companyTax) errors.companyTax = 'companyTaxRequired';
    if (!companyEmail) errors.companyEmail = 'emailRequired';
    if (companyEmail && !validateEmail(companyEmail))
      errors.companyEmail = 'emailInvalid';

    return errors;
  };

  const disableInvoice =
    hasPaid ||
    !currPackage?.features?.includes(FEATURE.INVOICE) ||
    [PAYMENT_STEP.PAYMENT, PAYMENT_STEP.PENDING_PAYMENT].includes(activeStep);

  const handleComeBackToPackage = () => {
    history.replace();
    setActiveStep(PAYMENT_STEP.PAYMENT_METHOD);
    setHasPaid(false);
  };

  const checkNoneRedirectOnlineGateway = (paymentMethodType) => {
    const NONE_REDIRECT_ONLINE_GATEWAY = [PAYMENT_METHOD_TYPE.SHOPEEPAY];
    return paymentMethodType.includes(NONE_REDIRECT_ONLINE_GATEWAY);
  };

  const createOrder = async (bankCode, callbackUrl, phoneNumber) => {
    setLoading({ ...loading, transfer: true });

    const createFields = {
      packageId: currPackage.id,
      paymentMethodId: selectedPaymentMethod.id,
      bankCode,
      callbackUrl,
      downgradePackage,
      phoneNumber,
      voucherId: appliedVoucher?.id,
      isGlobal: !isVietNam,
      affiliateMarketerId: user.affiliateMarketerId,
    };
    if (checkedInvoice) createFields.invoice = invoice;
    if (sendDataSensesFromServer)
      createFields.datasenses = dataSenses.getDataSensesRequireFields();

    const data = await apis.orders.createOrder(createFields);
    setLoading({ ...loading, transfer: false });
    if (!data?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          code: data?.code,
          message: 'buyPackageFailure',
        }),
      );
      handleComeBackToPackage();
      return;
    }
    if (isBankTransfer) {
      setIsTransferOrder(true);
      if (data.result.type !== PACKAGE_TYPE.API)
        dispatch(actions.user.getLatestOrderSuccess(data.result));
      return;
    }
    if (checkNoneRedirectOnlineGateway(selectedPaymentMethod.type)) {
      setPaymentDialog({
        name: data.result.paymentMethod?.name,
        icon: data.result.paymentMethod?.icon,
        paymentLink: data.result.paymentLink,
        price: data.result.price,
        createdAt: data.result.createdAt,
      });
      if (data.result.type !== PACKAGE_TYPE.API)
        dispatch(actions.user.getLatestOrderSuccess(data.result));
      return;
    }
    window.location.href = data.result.paymentLink;
  };

  const handleCloseDialog = (dialogName) => {
    setOpenDialog({ ...openDialog, [dialogName]: false });
  };

  const handleOpenDialog = (dialogName) =>
    setOpenDialog({ ...openDialog, [dialogName]: true });

  const handleAgreeToPay = (phoneNumber) => {
    if (checkedInvoice) {
      const errors = verifyInvoice();
      if (Object.keys(errors).length) {
        setInvoiceError(errors);
        return;
      }
    }
    if (selectedPaymentMethod) {
      const { bankCode, bankNumber } = selectedBank || {};
      createOrder(
        isBankTransfer ? bankNumber : bankCode,
        `${CRM_URL}${ROUTES.PAYMENT}`,
        phoneNumber,
      );
      setActiveStep(PAYMENT_STEP.PAYMENT);
    } else handleOpenDialog(DIALOG_NAMES.NOT_SELECTED_BANK);
  };

  const handleSelectBank = (bank) => setSelectedBank(bank);

  const handleSelectPaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setIsBankTransfer(
      paymentMethod && paymentMethod.type === PAYMENT_METHOD_TYPE.BANK,
    );
  };

  const handleConfirmTransferOrder = async () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;
    const data = await apis.orders.confirmByCustomer(latestOrder.id);
    if (data?.status) {
      handleOpenDialog(DIALOG_NAMES.CONFIRM_TRANSACTION);
      dispatch(
        actions.user.getLatestOrderSuccess({
          ...latestOrder,
          isConfirmedByCustomer: true,
        }),
      );
      return;
    }
    dispatch(actions.noti.push({ severity: 'error', message: data?.message }));
  };

  const handleConfirmDowngradePackage = () => {
    setDowngradePackage(true);
    handleCloseDialog(DIALOG_NAMES.DOWNGRADE_PACKAGE);
    setHasFirstNotify(false);
  };

  const handleCancelPendingOrder = async () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;

    setLoading({ ...loading, cancelOrder: true });
    const data = await apis.orders.cancelOrder(latestOrder.id);
    setLoading({ ...loading, cancelOrder: false });
    if (!data.status) {
      if (latestOrder.isConfirmedByCustomer)
        handleCloseDialog(DIALOG_NAMES.NOT_CONFIRMED_ORDER);
      else handleCloseDialog(DIALOG_NAMES.UNPAID_ORDER);
      dispatch(actions.noti.push({ severity: 'error', code: data.code }));
    } else if (data.result.type !== PACKAGE_STATUS.API)
      dispatch(actions.user.getLatestOrderSuccess(data.result));
    handleComeBackToPackage();
  };

  const handleContinuePayingDialog = () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;

    if (latestOrder.paymentLink)
      window.location.assign(latestOrder.paymentLink);

    if (latestOrder.paymentMethod.type === PAYMENT_METHOD_TYPE.ADMIN) {
      dispatch(
        actions.noti.push({ severity: 'info', message: 'pendingAdminConfirm' }),
      );
      handleComeBackToPackage();
    }

    if (latestOrder.isConfirmedByCustomer)
      handleCloseDialog(DIALOG_NAMES.NOT_CONFIRMED_ORDER);
    else handleCloseDialog(DIALOG_NAMES.UNPAID_ORDER);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialog(null);
    handleComeBackToPackage();
  };

  const handleContinueToPay = () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;

    if (checkNoneRedirectOnlineGateway(latestOrder?.paymentMethod?.type)) {
      setPaymentDialog({
        name: latestOrder?.paymentMethod?.name,
        icon: latestOrder?.paymentMethod?.icon,
        paymentLink: latestOrder?.paymentLink,
        price: latestOrder?.price,
        createdAt: latestOrder?.createdAt,
      });
    } else {
      window.location.assign(latestOrder.paymentLink);
    }
  };

  const handleCancelOnlinePayment = () => {
    handleCancelPendingOrder();
    handleClosePaymentDialog();
  };

  const handleOpenConfirmCancelOrder = () =>
    handleOpenDialog(DIALOG_NAMES.CONFIRM_CANCEL_ORDER);

  const fetchPackages = async (packageCode) => {
    const data = await apis.packages.getPackages({});
    if (data?.status) {
      const currentPackage = data.result.packages.find(
        (item) => item.code === packageCode,
      );

      if (!currentPackage?.active) handleComeBackToPackage();

      let openDowngradePackageDialog = false;

      // check using package when buy api package
      if (currentPackage.type === PACKAGE_TYPE.API) {
        const apiQuery = { userId: user?.id, type: PACKAGE_TYPE.API, limit: 1 };
        const [apiUsingOrders, apiLatestOrders] = await Promise.all([
          apis.orders.getOrders({
            ...apiQuery,
            packageStatus: PACKAGE_STATUS.USING,
          }),
          apis.orders.getOrders({ ...apiQuery, sort: 'createdAt_desc' }),
        ]);

        if (apiUsingOrders?.status && apiLatestOrders?.status) {
          const [usingApiOrder] = apiUsingOrders.result.orders;
          const usingApiPackage = usingApiOrder?.package;

          const [latestApiOrder] = apiLatestOrders.result.orders;

          openDowngradePackageDialog =
            usingApiPackage &&
            usingApiPackage.rank > currentPackage.rank &&
            latestApiOrder.status !== ORDER_STATUS.PENDING;
        }
      } else {
        openDowngradePackageDialog =
          usingPackage &&
          usingPackage.rank > currentPackage.rank &&
          latestOrder.status !== ORDER_STATUS.PENDING;
      }

      if (openDowngradePackageDialog) {
        handleOpenDialog(DIALOG_NAMES.DOWNGRADE_PACKAGE);
      } else {
        setHasFirstNotify(false);
      }
      setCurrPackage(currentPackage);
    }
  };

  const fetchOrderState = async (token, packageCode) => {
    const data = await apis.orders.getOrderState(token);
    if (data.status) {
      switch (data.result.state) {
        case ORDER_STATE.SUCCESS: {
          handleOpenDialog(DIALOG_NAMES.COMPLETED_ORDER);
          setHasPaid(true);
          setActiveStep(PAYMENT_STEP.COMPLETED);
          setIsBankTransfer(false);
          const packageName = getPackageName(
            null,
            packageCode,
            language,
            t,
            true,
          );
          dispatch(
            actions.banner.updateDisplayBanner(
              BANNER_TYPE.JOIN_ZALO_GROUP,
              true,
              {
                packageName: packageName.toUpperCase(),
              },
            ),
          );
          break;
        }
        case ORDER_STATE.FAILED:
          // TODO: handle Failed Dialog
          break;
        case ORDER_STATE.PROCESSING:
          // TODO: handle processing dialog
          break;
        default:
          break;
      }
    }
  };

  const fetchOrder = async (orderId) => {
    const data = await apis.orders.getOrderById(orderId);
    if (data.status) {
      setCurrPackage(data.result.package);
      if (isVietNam) setSelectedPaymentMethod(data.result.paymentMethod);
    }
  };

  useEffect(() => {
    const {
      token,
      orderId,
      package: packageCode,
    } = camelcaseKeys(queryString.parse(location.search) || {});

    if (FREE_PACKAGE_CODE.includes(packageCode)) history.push(ROUTES.PAYMENT);

    if (token && orderId) {
      fetchOrderState(token, packageCode);
      fetchOrder(orderId);
    }
    if (packageCode) {
      fetchPackages(packageCode);
    }
  }, [location]);

  useEffect(() => {
    if (latestOrder.status === ORDER_STATUS.PENDING) {
      history.replace({ search: `?package=${latestOrder.package?.code}` });
      setCurrPackage(latestOrder.package);
      setSelectedPaymentMethod(latestOrder.paymentMethod);

      setIsBankTransfer(
        latestOrder.paymentMethod?.type === PAYMENT_METHOD_TYPE.BANK,
      );
      if (!latestOrder.isConfirmedByCustomer) {
        if (checkNoneRedirectOnlineGateway(latestOrder?.paymentMethod?.type)) {
          setPaymentDialog({
            name: latestOrder?.paymentMethod?.name,
            icon: latestOrder?.paymentMethod?.icon,
            paymentLink: latestOrder?.paymentLink,
            price: latestOrder?.price,
            createdAt: latestOrder?.createdAt,
          });
        } else {
          handleOpenDialog(DIALOG_NAMES.UNPAID_ORDER);
        }
        setActiveStep(PAYMENT_STEP.PAYMENT);
      } else {
        setActiveStep(PAYMENT_STEP.PENDING_PAYMENT);
        handleOpenDialog(DIALOG_NAMES.NOT_CONFIRMED_ORDER);
      }
      const isRenderedBankTransfer =
        latestOrder.paymentMethod?.type === PAYMENT_METHOD_TYPE.BANK &&
        !latestOrder.paymentLink;
      if (isRenderedBankTransfer) setIsShowVoucherBox(false);

      if (latestOrder?.invoice) {
        const { companyName, companyAddress, companyTax, companyEmail } =
          latestOrder.invoice;
        setCheckedInvoice(true);
        setInvoice({ companyName, companyAddress, companyTax, companyEmail });
      }
    }
  }, [latestOrder]);

  useEffect(() => {
    if (isTransferOrder && latestOrder.status === ORDER_STATUS.PENDING) {
      if (latestOrder.isConfirmedByCustomer)
        handleCloseDialog(DIALOG_NAMES.NOT_CONFIRMED_ORDER);
      else handleCloseDialog(DIALOG_NAMES.UNPAID_ORDER);
    }
  }, [isTransferOrder, latestOrder]);

  const stepsGuide = [
    {
      title: PAYMENT_TOUR_GUIDE.CHOOSE_PAYMENT_METHOD.title,
      steps: [
        {
          name: PAYMENT_TOUR_GUIDE.CHOOSE_PAYMENT_METHOD.content,
          target: `#${PAYMENT_TOUR_GUIDE.CHOOSE_PAYMENT_METHOD.title}`,
        },
      ],
    },
    {
      title: PAYMENT_TOUR_GUIDE.USE_VOUCHER.title,
      steps: [
        {
          name: PAYMENT_TOUR_GUIDE.USE_VOUCHER.content,
          target: `#${PAYMENT_TOUR_GUIDE.USE_VOUCHER.title}`,
        },
      ],
    },
    {
      title: PAYMENT_TOUR_GUIDE.EXPORT_INVOICE.title,
      steps: [
        {
          name: PAYMENT_TOUR_GUIDE.EXPORT_INVOICE.content,
          target: `#${PAYMENT_TOUR_GUIDE.EXPORT_INVOICE.title}`,
        },
      ],
    },
    {
      title: PAYMENT_TOUR_GUIDE.MAKE_PAYMENT.title,
      steps: [
        {
          name: PAYMENT_TOUR_GUIDE.MAKE_PAYMENT.content,
          target: `#${PAYMENT_TOUR_GUIDE.MAKE_PAYMENT.title}`,
        },
      ],
    },
  ];

  return (
    <Card padding="16px 0px">
      <StyledPaymentWrapper>
        <ComeBack
          title={t('backToPackage')}
          sx={{
            position: 'absolute',
            top: { lg: '20px', md: '70px', xs: '70px' },
            left: '25px',
          }}
          onClick={handleComeBackToPackage}
        />
        <StyledStepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StyledStepConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                className={classNames('label-above', {
                  active: index === activeStep,
                  completed: index < activeStep,
                  step1: index === 0,
                  step2: index === 1,
                })}
                StepIconComponent={CustomStepIcon}
              >
                {t(label)}
              </StepLabel>
            </Step>
          ))}
        </StyledStepper>
        <Grid container spacing={3} className="content-wrapper">
          <Grid item xs={12} sm={7} md={8} className="column">
            <PaymentBox
              hasFirstNotify={hasFirstNotify}
              hasPaid={hasPaid}
              activeStep={activeStep}
              selectedBank={selectedBank}
              selectedPaymentMethod={selectedPaymentMethod}
              loadingTransfer={loading.transfer}
              isBankTransfer={isBankTransfer}
              onSelectBank={handleSelectBank}
              onSelectPaymentMethod={handleSelectPaymentMethod}
              onChangeRunTourGuide={handleChangeRunTourGuide}
              totalPrice={totalPrice}
            />
            {(!disableInvoice || checkedInvoice) && (
              <>
                <Box
                  className="invoice-check-box"
                  id={PAYMENT_TOUR_GUIDE.EXPORT_INVOICE.title}
                >
                  <Checkbox
                    checked={checkedInvoice}
                    onChange={handleChangeCheckedInvoice}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={disableInvoice}
                    className="checkbox-color"
                  />
                  <Typography className="invoice-info">
                    {t('exportInvoice')}
                  </Typography>
                </Box>
                <InvoiceDialog
                  checkedInvoice={checkedInvoice}
                  invoice={invoice}
                  error={invoiceError}
                  onChangeInvoice={handleChangeInvoice}
                  disableInvoice={disableInvoice}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={5} md={4} className="column right">
            {isShowVoucherBox && <VoucherBox currPackage={currPackage} />}
            <OrderBox
              currPackage={currPackage}
              isBankTransfer={isBankTransfer}
              activeStep={activeStep}
              hasPaid={hasPaid}
              onContinueToPay={handleContinueToPay}
              onAgreeToPay={handleAgreeToPay}
              onConfirmTransferOrder={handleConfirmTransferOrder}
              onOpenConfirmCancelOrder={handleOpenConfirmCancelOrder}
              savedPrice={savedPrice}
              totalPrice={totalPrice}
            />
          </Grid>
        </Grid>
      </StyledPaymentWrapper>
      <PaymentNotificationDialogs
        DIALOG_NAMES={DIALOG_NAMES}
        openDialog={openDialog}
        loading={loading}
        onCloseDialog={handleCloseDialog}
        onComeBackToPackage={handleComeBackToPackage}
        onConfirmDowngradePackage={handleConfirmDowngradePackage}
        onCancelPendingOrder={handleCancelPendingOrder}
        onContinuePayingDialog={handleContinuePayingDialog}
      />
      <PaymentDialog
        open={!!paymentDialog}
        onClose={handleClosePaymentDialog}
        payment={paymentDialog}
        onCancelPayment={handleCancelOnlinePayment}
      />
      {runTourGuide && (
        <TourGuide
          stepsGuide={stepsGuide}
          run={runTourGuide}
          onChangeRunTourGuide={handleChangeRunTourGuide}
          stepDisplay={false}
        />
      )}
    </Card>
  );
};

export default Payment;
