import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckCircle } from '@mui/icons-material';
import { StyledStatusSaving } from './index.style';

const StatusSaving = ({ savingStatus }) => (
  <StyledStatusSaving>
    {savingStatus ? (
      <div className="base-loading">
        <CircularProgress size={20} thickness={6} className="loading-saving" />
      </div>
    ) : (
      <div className="base-loading">
        <CheckCircle className="loading-saved" />
      </div>
    )}
  </StyledStatusSaving>
);
export default StatusSaving;
