import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Editor, EditorState, ContentState } from 'draft-js';
import { OrderedSet } from 'immutable';
import actions from '@src/redux/actions';
import classNames from 'classnames';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

import { Button, Popper } from '@mui/material';
import {
  KeyboardArrowDownOutlined,
  // KeyboardArrowUpOutlined,
} from '@mui/icons-material';
import PreviewButton from '@src/components/PreviewButton';
import { VBEE_DOMAIN } from '@src/configs';
import { TIME_STORE_TRY_LISTEN_NOTE } from '@src/constants/device';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { BREAK_TIME_REGEX, REGEX } from '@src/constants/voice';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { countTextLength } from '@src/services/tts';
import { getSelectedText } from '@src/services/sentence';
import { generateDecorator } from '@src/services/editor';
import {
  handleEditParagraphs,
  convertParagraphsToState,
  handleSelectedParagraph,
  countEditorStateLength,
  convertTextToParagraphs,
} from '@src/services/paragraph';
import { handleSelection } from '@src/services/entity';
import debounce from '@src/utils/debounce';

import BreakTimeHighlight from '@src/containers/TTS/BreakTimeHightLight';
import { StyledMapEditor } from '@src/containers/TTS/index.style';
import { getCookie, setCookie } from '@src/utils/cookie';

import {
  // TTS_BOX_SIZE,
  TTS_VERSION,
} from '@src/constants/tts';
import PreviewButtonWithDescription from '@src/components/PreviewButtonWithDescription';
import apis from '@src/apis';
import { checkVietNam } from '@src/utils/checkCountry';
import PreviewGuide from './PreviewGuide';
import Sentences from './Sentences';

import {
  StyledContent,
  StyledPreviewButton,
  StyledPreviewButtonNew,
} from './index.style';
import SampleScripts from '../SampleScripts';
// import RefreshButton from './RefreshButton';

const Content = ({
  openSentences,
  content,
  isSmallContent,
  inputLength,
  characterExceed,
  onChangeContent,
  requestTableHeight,
  onChangeInputLength,
  onChangeCharacterExceed,
  onchangeSynthesisSentence,
  onTryListeningSentence,
  onChangeVoice,
  audioRef,
  synthesisSentence,
  enableEditor,
  onEnableEditor,
  // onResetContentEditor,
  onChangeExpandRequestTable,
}) => {
  const [isInputText, setIsInputText] = useState(false);
  const [isHaveScroll, setIsHaveScroll] = useState(false);
  const showPreviewGuide = getCookie('showPreviewGuide');
  const [sampleScripts, setSampleScripts] = useState([]);
  const [defaultSampleScript, setDefaultSampleScript] = useState(null);
  const [openPreviewGuide, setOpenPreviewGuide] = useState(!showPreviewGuide);
  // const [ttsBoxSize, setTtsBoxSize] = useState(TTS_BOX_SIZE.DEFAULT);
  const boxRef = useRef(null);
  const {
    synthesisRequest,
    sentences,
    loadingSynthesisConfig,
    paragraphs,
    isLoadingParagraphs,
    advanceFeature,
    showSample,
    previewParagraphs: previewContent,
  } = useSelector((state) => state.synthesisRequest);
  const user = useSelector((state) => state.auth.user);
  const { usingPackage, ttsUser } = useSelector((state) => state.user);
  const { isAudioLoading, popupPreviewAnchorEl, showAudioPlayer } = useSelector(
    (state) => state.audioPlayer,
  );

  const maxLengthInputText = ttsUser?.maxLengthInputText || 0;
  const isVietNam = checkVietNam();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ttsRef = useRef(null);
  const { getFeatureValue } = useFeatureFlags();

  const useHeaderBanner = getFeatureValue(
    FEATURE_KEYS.HEADER_BANNER_FLASH_SALE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const useTextSample = getFeatureValue(FEATURE_KEYS.SAMPLE_SCRIPTS, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const { headerVoucher } = useSelector((state) => state.voucher);

  const handleChangePopupPreviewAnchorEl = (value) => {
    dispatch(actions.audioPreview.updatePopupPreviewAnchorEl(value));
  };

  const useOldSaveDraftFeatureFlag = getFeatureValue(
    FEATURE_KEYS.OLD_AUTO_SAVE_DRAFT,
  );

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });

  const isNewPreviewBtn = getFeatureValue(FEATURE_KEYS.NEW_PREVIEW_BUTTON, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const useTitleProduct = getFeatureValue(FEATURE_KEYS.TITLE_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  let useHighlightToPreview = getFeatureValue(
    FEATURE_KEYS.HIGHLIGHT_TO_PREVIEW,
  );
  useHighlightToPreview = useHighlightToPreview && useNewTtsUI;

  const locationRouter = useLocation();

  const canPreview = advanceFeature
    ? previewContent?.length && usingPackage.id && !isAudioLoading
    : synthesisSentence && usingPackage.id && !isAudioLoading;

  const handleOpenPreviewButton = () => {
    const selection = window.getSelection();

    const getBoundingClientRect = () => {
      if (selection && selection.rangeCount > 0) {
        const clientRects = selection?.getRangeAt(0)?.getClientRects();
        const position = clientRects[clientRects.length - 1];
        const fixPosition = new DOMRect(
          (position?.left || 0) + 3,
          position?.top || 0,
          position?.width || 0,
          position?.height || 0,
        );
        return fixPosition || {};
      }
      handleChangePopupPreviewAnchorEl(null);
      return {};
    };

    handleChangePopupPreviewAnchorEl({ getBoundingClientRect });
  };

  const handleSkipNote = () => {
    setCookie({
      cname: 'showPreviewGuide',
      cvalue: true,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_TRY_LISTEN_NOTE,
    });
    setOpenPreviewGuide(false);
  };

  const fetchSampleScript = async () => {
    const response = await apis.sampleScripts.getSampleScript();
    const totalSampleScript = response?.result?.sampleScripts;
    let sampleScript = [];
    if (isVietNam)
      sampleScript = totalSampleScript.filter(
        (item) => item.isVietnamese && !item.isDefault,
      );
    else
      sampleScript = totalSampleScript.filter(
        (item) => !item.isVietnamese && !item.isDefault,
      );
    setSampleScripts(sampleScript);

    const defaultSample = totalSampleScript.find(
      (item) =>
        item.isDefault && (isVietNam ? item.isVietnamese : !item.isVietnamese),
    );
    setDefaultSampleScript(defaultSample);
    dispatch(actions.synthesisRequest.updateDefaultSample(defaultSample));
  };

  useEffect(() => {
    if (useTextSample) fetchSampleScript();
  }, [isVietNam, useTextSample]);

  const handleChangeEditor = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const lengthContent = countTextLength(contentState.getPlainText());

    if (contentState.getPlainText() !== synthesisRequest?.text) {
      onEnableEditor(true);
      if (!enableEditor)
        dispatch(actions.synthesisRequest.cloneSynthesisRequest());
    }

    onChangeInputLength(lengthContent);

    let newContent = editorState;
    const currentStyle = editorState.getCurrentInlineStyle();
    if (currentStyle && currentStyle.size) {
      newContent = EditorState.setInlineStyleOverride(
        editorState,
        OrderedSet.of(),
      );
    }
    onChangeContent(newContent);

    if (lengthContent > maxLengthInputText) {
      onChangeCharacterExceed(true);
    } else {
      onChangeCharacterExceed(false);
    }

    const selectedText = getSelectedText(editorState);
    onchangeSynthesisSentence(selectedText);
  };

  // Define event when editor state changes
  const handleChangeEditorState = (newEditorState) => {
    const selectionState = newEditorState.getSelection();
    // If dont focus on the editor, cant do anything
    if (!selectionState.toJS().hasFocus) {
      onChangeContent(newEditorState);
      return;
    }
    if (!isInputText) setIsInputText(true);

    const contentState = newEditorState.getCurrentContent();

    const currentPlainText = synthesisRequest?.text
      ?.replace(REGEX.ADVANCE_TAG, '')
      .trim();

    if (contentState.getPlainText()?.trim() !== currentPlainText) {
      onEnableEditor(true);
      if (!enableEditor)
        dispatch(actions.synthesisRequest.cloneSynthesisRequest());
    }

    // Selection area when the user highlights
    const selection = handleSelection({
      selectionState,
      contentState,
      paragraphs,
    });
    if (selection) {
      onChangeContent(EditorState.forceSelection(newEditorState, selection));
      // previewParagraphs: preview data, format same paragraphs
      // selectedParagraphs: selected data, format same content editor state to support for emphasis, speed, break time
      const { previewParagraphs, selectedParagraph } = handleSelectedParagraph(
        selection,
        contentState,
        paragraphs,
      );
      dispatch(
        actions.synthesisRequest.updateSelectedParagraph(selectedParagraph),
      );
      dispatch(
        actions.synthesisRequest.updatePreviewParagraphs(previewParagraphs),
      );
    } else {
      dispatch(actions.synthesisRequest.updatePreviewParagraphs([]));
      onChangeContent(newEditorState);
    }
  };

  const handleChangeParagraphs = () => {
    // Update paragraphs when editor state changes
    const newParagraphs = handleEditParagraphs(paragraphs, content);
    if (newParagraphs !== null) {
      dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));

      const contentLength = countEditorStateLength(content, newParagraphs);

      onChangeInputLength(contentLength);

      if (contentLength > maxLengthInputText) {
        onChangeCharacterExceed(true);
      } else {
        onChangeCharacterExceed(false);
      }
    }
    setIsInputText(false);
  };

  const handleExpandEditor = () => onChangeExpandRequestTable(false);

  // const handleCollapseEditor = () => onChangeExpandRequestTable(true);

  const handleBlurEditor = () => {
    if (advanceFeature) {
      dispatch(actions.synthesisRequest.updatePreviewParagraphs([]));
    } else {
      onchangeSynthesisSentence();
    }
  };

  useEffect(() => {
    if (advanceFeature && isInputText)
      debounce(handleChangeParagraphs, 300)(content);
  }, [content]);

  useEffect(() => {
    // loadingSynthesisConfig: load again tts when user click to request in request table or change voice
    if (loadingSynthesisConfig) {
      const {
        text,
        sentences: synthesisConfigSentences,
        characters,
        voice,
        paragraphs: synthesisParagraphs = [],
      } = synthesisRequest;

      onChangeInputLength(characters);
      onChangeVoice(voice, loadingSynthesisConfig);

      // Reset audio
      // dispatch(
      //   actions.audioPlayer.updateMetaData({ currentTime: 0, duration: 0 }),
      // );
      // dispatch(actions.audioPlayer.updateStatus(false));
      // const audioLink = synthesisRequest.audioLink || '';
      // dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(false));

      const processBySentence = !!synthesisConfigSentences?.length;
      // When process method change => update open sentence
      if (processBySentence !== openSentences)
        dispatch(
          actions.synthesisRequest.updateOpenSentences(processBySentence),
        );

      // Sentences processing
      if (processBySentence) {
        const convertSentences = synthesisConfigSentences.map((item) => ({
          ...item,
          id: uuidv4(),
          onLoad: true,
          text: item.text,
        }));

        dispatch(actions.synthesisRequest.updateSentences(convertSentences));
        return;
      }

      // Paragraph process
      const isAdvanceFeature = voice?.version === TTS_VERSION.EMPHASIS;
      if (!isAdvanceFeature) {
        const contentEditor = EditorState.createWithContent(
          ContentState.createFromText(text),
          generateDecorator(BREAK_TIME_REGEX, BreakTimeHighlight),
        );
        const newEditorState = EditorState.moveFocusToEnd(contentEditor);
        onChangeContent(newEditorState);
      }

      if (isAdvanceFeature && isAdvanceFeature === advanceFeature) {
        dispatch(
          actions.synthesisRequest.updateParagraphs(synthesisParagraphs),
        );
        dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
      }

      if (isAdvanceFeature !== advanceFeature)
        dispatch(
          actions.synthesisRequest.switchAdvanceFeature(isAdvanceFeature),
        );
    }
  }, [loadingSynthesisConfig]);

  const handleShowContent = () => {
    let contentParagraphs = paragraphs;
    if (!paragraphs?.length) {
      contentParagraphs = convertTextToParagraphs(synthesisRequest?.text || '');
    }
    const contentState = convertParagraphsToState(contentParagraphs);
    onChangeContent(EditorState.push(content, contentState));
  };
  // const handleChangeTtsBoxSize = (value) => setTtsBoxSize(value);

  const handleResetContent = () => {
    dispatch(actions.synthesisRequest.updateParagraphs([]));
    dispatch(actions.synthesisRequest.updateSelectedSentences([]));

    if (useOldSaveDraftFeatureFlag) {
      dispatch(actions.synthesisRequest.updateOpenSentences(false));
      dispatch(actions.synthesisRequest.switchAdvanceFeature(false));
    }
  };

  const placeholder = useMemo(() => {
    if (useTextSample) return defaultSampleScript?.text || '';
    return t('enterTextHere');
  }, [useTextSample, defaultSampleScript]);

  useEffect(() => {
    // isLoadingParagraphs: Load again editor state when paragraphs change
    if (isLoadingParagraphs) {
      handleShowContent();
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(false));
    }
  }, [isLoadingParagraphs]);

  useEffect(() => {
    if (advanceFeature && !openSentences) {
      dispatch(
        actions.synthesisRequest.updateParagraphs(synthesisRequest?.paragraphs),
      );
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
    }
  }, [advanceFeature]);

  useEffect(() => {
    const box = boxRef.current;

    if (box.scrollHeight > box.clientHeight) {
      setIsHaveScroll(true);
    } else {
      setIsHaveScroll(false);
    }
  }, [inputLength, content, sentences.length]);

  useEffect(() => {
    dispatch(actions.synthesisRequest.updateLoadingParagraphs(false));
    return () => {
      handleResetContent();
    };
  }, [locationRouter]);

  useEffect(() => {
    if (!openSentences) {
      dispatch(actions.synthesisRequest.updateSelectedSentences([]));
    }
  }, [openSentences]);

  useEffect(() => {
    if (!canPreview) {
      handleChangePopupPreviewAnchorEl(null);
      return;
    }
    if (
      useHighlightToPreview &&
      (synthesisSentence || !!previewContent.length)
    ) {
      debounce(handleOpenPreviewButton, 300)();
    }
  }, [synthesisSentence, previewContent]);

  const handlePastedText = () => {
    const currentScrollHeight = boxRef.current?.scrollHeight;
    setTimeout(() => {
      const newScrollHeight = boxRef.current?.scrollHeight;
      boxRef.current.scrollTop += newScrollHeight - currentScrollHeight;
    }, 100);
  };

  const handleTryListeningSentence = (sentence) => {
    handleChangePopupPreviewAnchorEl(null);
    onTryListeningSentence(sentence);
  };

  useEffect(() => {
    if (inputLength && showSample)
      dispatch(actions.synthesisRequest.updateShowSampleScripts(false));
  }, [inputLength]);

  return (
    <StyledContent
      ref={ttsRef}
      requestTableHeight={requestTableHeight}
      showaudioplayer={showAudioPlayer}
      isSmallContent={isSmallContent}
      id={TTS_GUIDE.ENTER_TEXT_HERE}
      openPreviewGuide={
        useHighlightToPreview && !openSentences && openPreviewGuide
      }
      isNewPreviewBtn={isNewPreviewBtn}
      useHeaderBanner={useHeaderBanner}
      openSentences={openSentences}
      useTextSample={useTextSample}
      showSample={showSample}
      headerVoucher={headerVoucher}
      useTitleProduct={useTitleProduct}
    >
      {openSentences ? (
        <Sentences
          advanceFeature={advanceFeature}
          isSmallContent={isSmallContent}
          requestTableHeight={requestTableHeight}
          characterExceed={characterExceed}
          onChangeInputLength={onChangeInputLength}
          onChangeCharacterExceed={onChangeCharacterExceed}
          onTryListeningSentence={onTryListeningSentence}
          audioRef={audioRef}
          enableEditor={enableEditor}
          onEnableEditor={onEnableEditor}
          editorRef={boxRef}
          isNewPreviewBtn={isNewPreviewBtn}
          useHeaderBanner={useHeaderBanner}
          headerVoucher={headerVoucher}
        />
      ) : (
        <div
          id="editor-wrapper"
          className={classNames('editor-wrapper', {
            'character-exceed': characterExceed,
          })}
          ref={boxRef}
          onBlur={handleBlurEditor}
        >
          <Editor
            placeholder={placeholder}
            editorState={content}
            onChange={
              advanceFeature ? handleChangeEditorState : handleChangeEditor
            }
            customStyleMap={StyledMapEditor}
            stripPastedStyles
            handlePastedText={handlePastedText}
          />
        </div>
      )}
      <div className="preview-guide">
        {!openSentences && !useTextSample && (
          <PreviewGuide
            openPreviewGuide={openPreviewGuide}
            onSkipNote={handleSkipNote}
          />
        )}
        {isHaveScroll && isSmallContent ? (
          <Button
            size="small"
            variant="outlined"
            className="change-size-editor"
            onClick={handleExpandEditor}
            endIcon={<KeyboardArrowDownOutlined className="expand-icon" />}
          >
            {t('expand')}
          </Button>
        ) : null}
        {/* {!isSmallContent ? (
        <Button
          size="small"
          variant="outlined"
          className="change-size-editor"
          onClick={handleCollapseEditor}
          endIcon={<KeyboardArrowUpOutlined className="expand-icon" />}
        >
          {t('collapse')}
        </Button>
      ) : null} */}
      </div>
      {isNewPreviewBtn && (
        <StyledPreviewButtonNew openSentences={openSentences}>
          <div className="tool-row">
            {useTextSample && showSample && (
              <SampleScripts
                audioRef={audioRef}
                sampleScripts={sampleScripts}
                onChangeInputLength={onChangeInputLength}
              />
            )}
            <PreviewButtonWithDescription
              synthesisRequest={synthesisRequest}
              synthesisSentence={synthesisSentence}
              openSentences={openSentences}
              onTryListeningSentence={onTryListeningSentence}
              audioRef={audioRef}
              canPreview={canPreview}
            />
          </div>
        </StyledPreviewButtonNew>
      )}
      {/* <RefreshButton
        inputLength={inputLength}
        enableEditor={enableEditor}
        onResetContentEditor={onResetContentEditor}
      /> */}
      <Popper
        open={Boolean(popupPreviewAnchorEl)}
        anchorEl={popupPreviewAnchorEl}
        placement="right"
        style={{ zIndex: 1, cursor: 'pointer' }}
      >
        <StyledPreviewButton>
          <PreviewButton
            synthesisRequest={synthesisRequest}
            synthesisSentence={synthesisSentence}
            onTryListeningSentence={handleTryListeningSentence}
            audioRef={audioRef}
            canPreview={canPreview}
            isPopupPreview
          />
        </StyledPreviewButton>
      </Popper>
      {/* <ChangeBoxTTSSizeButton
        isShow={isHaveScroll}
        ttsBoxSize={ttsBoxSize}
        toolbarRef={toolbarRef}
        onChangeTtsBoxSize={handleChangeTtsBoxSize}
          /> */}
    </StyledContent>
  );
};

export default Content;
