import React from 'react';
import {
  Checkbox,
  TableBody,
  // TableCell,
  TableRow,
  CircularProgress,
} from '@mui/material';
import { PAGINATION_LIMIT } from '@src/constants';
import { StyledTableCell } from './index.style';

const CustomTableBody = ({
  page,
  columns,
  hoverColumns,
  data,
  selection,
  showNumber,
  isSelected,
  loading,
  onSelectRow,
  onRowClick,
  isShowCheckbox,
}) => {
  const handleRowClick = (rowId) => {
    if (onRowClick) onRowClick(rowId);
  };

  return (
    <TableBody>
      {loading ? (
        <TableRow className="body-row">
          <StyledTableCell>
            <CircularProgress />
          </StyledTableCell>
        </TableRow>
      ) : (
        data.map((rowData, index) => (
          <TableRow
            className="body-row"
            key={rowData.id}
            onClick={() => handleRowClick(rowData.id)}
          >
            {selection && isShowCheckbox && (
              <StyledTableCell
                className="checkbox-cell"
                padding="checkbox"
                align="left"
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox
                  className="body-checkbox"
                  checked={isSelected(rowData)}
                  onChange={onSelectRow(rowData)}
                />
              </StyledTableCell>
            )}
            {showNumber && (
              <StyledTableCell className="body-cell" align="center">
                {(page - 1) * PAGINATION_LIMIT + index + 1}
              </StyledTableCell>
            )}
            {columns.map((column) => (
              <StyledTableCell
                width={column.width}
                className="body-cell"
                align={column.align}
              >
                {column.render ? column.render(rowData) : rowData[column.field]}
              </StyledTableCell>
            ))}
            {hoverColumns &&
              hoverColumns.map((column) => (
                <StyledTableCell
                  width={column.width}
                  className="body-cell hover-visible"
                  align={column.align}
                >
                  {column.render
                    ? column.render(rowData)
                    : rowData[column.field]}
                </StyledTableCell>
              ))}
          </TableRow>
        ))
      )}
    </TableBody>
  );
};

export default CustomTableBody;
