import React from 'react';
import { StyledDotsStep } from './index.style';

const DotsStep = ({ totalSteps, currentStep }) => {
  const dots = Array.from({ length: totalSteps });

  return (
    <StyledDotsStep>
      {dots.map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`dot ${index === currentStep ? 'active' : ''}`}
        />
      ))}
    </StyledDotsStep>
  );
};

export default DotsStep;
