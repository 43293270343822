import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Checkbox, InputBase, Typography } from '@mui/material';
import actions from '@src/redux/actions';
import { MAX_SENTENCE_LENGTH } from '@src/constants/dubbing';
import Skeleton from '@mui/material/Skeleton';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import BadgeAvatar from '@src/components/BadgeAvatar';
import { PACKAGE_TYPE } from '@src/constants/package';
import VoicesDialog from '@src/components/VoicesDialog';
// import useElementHeight from '@src/hooks/useElementHeight';
import debounce from '@src/utils/debounce';
import { StyledSentence } from './index.style';

const useStyles = makeStyles(() => ({
  // Define custom styles for the disabled state
  disabledInput: {
    '-webkit-text-fill-color': '#242424 !important',
    cursor: 'not-allowed',
  },
}));

const Sentence = ({
  sentenceKey,
  isSelected,
  onSelectSentence,
  isLoading,
  data,
  originalData,
  handleChangeSentence,
  isShowOriginal,
  isSelectedTimeFrame,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [content, setContent] = useState(
    data?.content !== 'undefined' ? data?.content : '',
  );
  const [voice, setVoice] = useState({});
  const [openVoices, setOpenVoices] = useState(false);
  const [calculatedSentenceHeight, setCalculatedSentenceHeight] = useState(0);

  const contentRef = useRef(null);
  const originalContentRef = useRef(null);
  const sentenceRef = useRef(null);
  // const sentenceHeight = useElementHeight(sentenceRef);

  const {
    changedProjectInfo,
    projectInfo,
    openSplitVoices,
    showOriginalSubtitle,
  } = useSelector((state) => state.dubbingRequest);
  const { user } = useSelector((state) => state.auth);
  const { dubbingVoices } = useSelector((state) => state.voice);

  const { getFeatureValue } = useFeatureFlags();

  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const updateSentence = ({ key, name, value }) => {
    handleChangeSentence({
      sentenceKey: key,
      name,
      value,
    });

    debounce(() => {
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
          ...changedProjectInfo,
          sentencesChanged: {
            ...changedProjectInfo.sentencesChanged,
            [sentenceKey]: {
              ...changedProjectInfo.sentencesChanged[sentenceKey],
              [name]: value,
            },
          },
        }),
      );
    }, 200)(value);
  };

  const handleChangeContent = (e) => {
    const { name, value } = e.target;

    if (value.length > MAX_SENTENCE_LENGTH) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateContentMaxLength',
          value: MAX_SENTENCE_LENGTH,
        }),
      );
      return;
    }
    setContent(value);
    updateSentence({
      key: sentenceKey,
      name,
      value,
    });
  };

  const handleChangeVoice = (voiceValue) => {
    setVoice(voiceValue);
    updateSentence({
      key: sentenceKey,
      name: 'voiceCode',
      value: voiceValue.code,
    });
    setOpenVoices(false);
  };

  const getVoiceOfSentence = () => {
    // Check if sentence has changed voice then set it
    if (changedProjectInfo?.sentencesChanged[sentenceKey]?.voiceCode) {
      const selectedVoice = dubbingVoices.find(
        (dubbingVoice) =>
          dubbingVoice.code ===
          changedProjectInfo?.sentencesChanged[sentenceKey]?.voiceCode,
      );

      setVoice(selectedVoice);
      return;
    }

    // Check if sentence has default voice then set it
    if (data?.voiceCode) {
      const selectedVoice = dubbingVoices.find(
        (dubbingVoice) => dubbingVoice.code === data?.voiceCode,
      );

      setVoice(selectedVoice);
      return;
    }

    // Check if project has default voice then set it
    if (projectInfo?.voiceCode) {
      const defaultVoice = dubbingVoices.find(
        (dubbingVoice) => projectInfo?.voiceCode === dubbingVoice.code,
      );

      setVoice(defaultVoice);
    }
  };

  const handleCloseVoices = () => setOpenVoices(false);

  const SkeletonLoadingContent = () => (
    <>
      <Skeleton className="checkbox" width="2%" animation="wave" />
      <Box className="time-range">
        <Skeleton className="time-range-header" animation="wave" />
      </Box>
      {isDubbingMultipleVoices && openSplitVoices && (
        <div className="voice-and-speed">
          <Skeleton width="100%" animation="wave" />
        </div>
      )}
      <Skeleton width="100%" animation="wave" />
    </>
  );

  useEffect(() => {
    getVoiceOfSentence();
  }, [
    data?.voiceCode,
    projectInfo?.voiceCode,
    dubbingVoices,
    changedProjectInfo?.sentencesChanged,
  ]);

  useEffect(() => {
    const originalContentHeight = originalContentRef.current?.scrollHeight;
    const contentHeight = contentRef.current?.scrollHeight;
    if (contentHeight !== calculatedSentenceHeight) {
      const currentSentenceHeight = Math.max(
        originalContentHeight,
        contentHeight,
        0,
      );

      if (currentSentenceHeight > 0)
        setCalculatedSentenceHeight(currentSentenceHeight);
    }
  }, [contentRef.current?.scrollHeight]);

  return (
    <StyledSentence
      isSelected={isSelected}
      isDubbingMultipleVoices={isDubbingMultipleVoices}
      isSelectedTimeFrame={isSelectedTimeFrame}
      sentenceHeight={calculatedSentenceHeight}
      ref={sentenceRef}
    >
      {isLoading ? (
        <SkeletonLoadingContent />
      ) : (
        <>
          <Box sx={{ height: `${calculatedSentenceHeight}px` }}>
            <Checkbox
              className="checkbox-cell"
              checked={isSelected}
              onChange={onSelectSentence(sentenceKey)}
            />
          </Box>
          <Box
            className="time-range"
            sx={{
              height: `${calculatedSentenceHeight}px`,
              alignItems: 'center',
            }}
          >
            <Typography className="text-start-end">{`${data?.start} --> ${data?.end}`}</Typography>
          </Box>
          {isDubbingMultipleVoices && openSplitVoices && (
            <Box className="voice-and-speed">
              <Box className="voice" onClick={() => setOpenVoices(true)}>
                <BadgeAvatar
                  width={25}
                  smallImgWidth={10}
                  img={voice?.roundImage}
                  smallImg={
                    voice && voice.language && voice.language.roundImage
                  }
                  type="image"
                />
              </Box>
            </Box>
          )}
          {isMultipleInputDubbing && isShowOriginal && showOriginalSubtitle && (
            <InputBase
              inputRef={originalContentRef}
              value={originalData?.content || ''}
              className="content"
              multiline
              fullWidth
              spellCheck={false}
              disabled
              classes={{
                disabled: classes.disabledInput,
              }}
            />
          )}
          <InputBase
            key={sentenceKey}
            name="content"
            inputRef={contentRef}
            value={content}
            className="content"
            multiline
            fullWidth
            onChange={handleChangeContent}
            spellCheck={false}
          />
        </>
      )}
      <VoicesDialog
        open={openVoices}
        activeVoiceId={voice?.id}
        hasLanguageFilter={false}
        usingPackageType={PACKAGE_TYPE.DUBBING}
        onClose={handleCloseVoices}
        onChangeVoice={handleChangeVoice}
        defaultFilter={{
          hasDubbing: true,
        }}
        oldDubbingVoices={false}
      />
    </StyledSentence>
  );
};

export default memo(Sentence);
