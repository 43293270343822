import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProcessHandler from '@src/components/ProcessHandler';
import Dialog from '@src/components/Dialog';
import { createSRTFile, validateSubtitleFile } from '@src/services/dubbing';
import actions from '@src/redux/actions';
import { createDubbingProject } from '@src/apis/dubbing';
import { CUSTOMER_SUPPORT_PHONE_NUMBER } from '@src/configs';
import { MAX_LENGTH_TITLE } from '@src/constants/request';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { COLOR } from '@src/styles/color';
import { uploadFileToS3 } from '@src/services/upload';
import { Box, Button, TextField } from '@mui/material';
import UploadSubtitleFile from '../UploadSubtitleFile';
import { StyledModalCreateProject } from './index.style';

const ModalCreateProject = ({
  isShowModalCreateProject,
  closeModalCreateProject,
}) => {
  const DEFAULT_LANGUAGE = {
    value: 'vi-VN',
    country: 'vi-VN',
    label: 'vietnamese',
    flag: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
  };

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitleFile, setSubtitleFile] = useState(null);
  const [isDisableContinue, setIsDisableContinue] = useState(true);
  const [linkYoutube, setLinkYoutube] = useState('');
  const [originalLanguage, setOriginalLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState(DEFAULT_LANGUAGE.value);
  const [languageError, setLanguageError] = useState(false);
  const [loadingHandleFile, setLoadingHandleFile] = useState(false);

  const { dubbingVoices } = useSelector((state) => state.voice);

  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();
  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const uploadComponentRef = useRef();

  const handleResetFile = () => uploadComponentRef.current?.click();
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    if (newTitle.length > MAX_LENGTH_TITLE) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );
      return;
    }
    setTitle(newTitle);
  };
  const resetState = () => {
    setTitle('');
    setLinkYoutube('');
    setSubtitleFile(null);
    setIsDisableContinue(true);
    setOriginalLanguage('');
  };

  const handleCreateProject = async () => {
    try {
      setIsLoading(true);
      const { subtitles } = await validateSubtitleFile(subtitleFile);

      const { error, fileUrl } = await createSRTFile(`${title}.srt`, subtitles);
      if (error) {
        setIsLoading(false);
        throw new Error('Create project failed');
      }

      const projectData = {
        title,
        subtitleLink: fileUrl,
        voiceCode: dubbingVoices[0]?.code,
      };

      const { status, result } = await createDubbingProject(projectData);
      if (!status) {
        throw new Error('Create project failed');
      }
      setIsLoading(false);
      closeModalCreateProject();
      resetState();
      dispatch(
        actions.noti.push({
          message: t('createProjectSuccess'),
          severity: 'success',
        }),
      );

      // TODO: Navigate to the project detail page
      history.push(`/dubbing/projects/${result.id}`);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        actions.noti.push({
          message: t('systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
  };

  const createProjectWithSrtFile = async () => {
    try {
      setIsLoading(true);
      setLoadingHandleFile(true);
      const { subtitles } = await validateSubtitleFile(subtitleFile, true);

      const { error, fileUrl } = await createSRTFile(`${title}.srt`, subtitles);
      if (error) {
        setIsLoading(false);
        throw new Error('Create project failed');
      }

      const projectData = {
        title,
        voiceCode: dubbingVoices[0]?.code,
      };
      if (isMultipleInputDubbing) {
        projectData.originalInfo = {
          language: originalLanguage,
          subtitleLink: fileUrl,
        };
        projectData.targetLanguage = targetLanguage;
      }
      const { status, result } = await createDubbingProject(projectData);
      if (!status) {
        throw new Error('Create project failed');
      }
      setIsLoading(false);
      setLoadingHandleFile(false);
      closeModalCreateProject();
      resetState();
      dispatch(
        actions.noti.push({
          message: t('createProjectSuccess'),
          severity: 'success',
        }),
      );

      history.push(`/dubbing/projects/${result.id}`);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        actions.noti.push({
          message: t('systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
  };

  const createProjectWithInputYoutube = async () => {
    try {
      setIsLoading(true);
      setLoadingHandleFile(true);
      const projectData = {
        title,
        voiceCode: dubbingVoices[0]?.code,
        originalInfo: {
          videoLink: linkYoutube,
          language: originalLanguage,
        },
        targetLanguage,
      };

      const { status, result } = await createDubbingProject(projectData);
      if (!status) {
        throw new Error('Create project failed');
      }
      setIsLoading(false);
      setLoadingHandleFile(false);
      closeModalCreateProject();
      resetState();
      dispatch(
        actions.noti.push({
          message: t('createProjectSuccess'),
          severity: 'success',
        }),
      );

      history.push(`/dubbing/projects/${result.id}`);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        actions.noti.push({
          message: t('systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
  };

  const createProjectWithVideo = async () => {
    try {
      setIsLoading(true);
      setLoadingHandleFile(true);

      const name = subtitleFile.name.split('.').shift();
      const extension = subtitleFile.name.split('.').pop();
      const file = subtitleFile;
      const directory = 'videos';

      const nameWithOutSpace = name.replace(/\s/g, '-');

      const url = await uploadFileToS3(
        nameWithOutSpace,
        directory,
        extension,
        file,
      );

      const projectData = {
        title,
        voiceCode: dubbingVoices[0]?.code,
      };
      if (isMultipleInputDubbing) {
        projectData.originalInfo = {
          language: originalLanguage,
          videoLink: url,
        };
        projectData.targetLanguage = targetLanguage;
      }
      const { status, result } = await createDubbingProject(projectData);
      if (!status) {
        throw new Error('Create project failed');
      }
      setIsLoading(false);
      setLoadingHandleFile(false);
      closeModalCreateProject();
      resetState();
      dispatch(
        actions.noti.push({
          message: t('createProjectSuccess'),
          severity: 'success',
        }),
      );
      history.push(`/dubbing/projects/${result.id}`);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        actions.noti.push({
          message: t('systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
  };

  const handleCreateProjectWithMultipleInput = async () => {
    if (!originalLanguage || !targetLanguage) {
      setLanguageError(true);
      return;
    }

    if (linkYoutube) {
      createProjectWithInputYoutube();
      return;
    }

    if (subtitleFile && subtitleFile.name.split('.').pop() === 'srt') {
      createProjectWithSrtFile();
    }

    if (subtitleFile && subtitleFile.name.split('.').pop() === 'mp4') {
      createProjectWithVideo();
    }
  };

  const SingleInputTitleAndButton = () => {
    const isDisableButton =
      isDisableContinue ||
      isLoading ||
      (subtitleFile && !title?.trim()?.length) ||
      !subtitleFile;

    return (
      <>
        <TextField
          value={title}
          onChange={handleChangeTitle}
          placeholder={t('enterTitleName')}
          className="title-input"
          autoFocus
          InputProps={{
            style: {
              borderRadius: '12px',
              borderColor: COLOR.black[16],
            },
          }}
          sx={{
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          }}
        />
        <Box display="flex" width="100%" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className="continue-button-multiple-input"
            disabled={isDisableButton}
            onClick={() => handleCreateProject()}
          >
            {isLoading ? (
              <ProcessHandler
                loading={isLoading}
                ml="16px"
                mr="17px"
                size={24}
                align="center"
                color="divider"
              >
                {t('continue')}
              </ProcessHandler>
            ) : (
              t('continue')
            )}
          </Button>
        </Box>
      </>
    );
  };

  const MultipleInputTitleAndButton = () => {
    const isDisableButton =
      isDisableContinue ||
      isLoading ||
      (!subtitleFile && !linkYoutube?.trim()?.length);

    return (
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          className="continue-button-multiple-input"
          disabled={isDisableButton}
          onClick={handleCreateProjectWithMultipleInput}
        >
          {isLoading ? (
            <ProcessHandler
              loading={isLoading}
              ml="16px"
              mr="17px"
              size={24}
              align="center"
              color="divider"
            >
              {t('continue')}
            </ProcessHandler>
          ) : (
            t('continue')
          )}
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      title={t('uploadSubtitle')}
      maxWidth="sm"
      open={isShowModalCreateProject}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') return;
        resetState();
        closeModalCreateProject();
      }}
      borderRadius="16px"
      titleFontSize="20px"
      titleFontWeight="600"
    >
      <StyledModalCreateProject>
        <UploadSubtitleFile
          resetRef={uploadComponentRef}
          onResetFile={handleResetFile}
          subtitleFile={subtitleFile}
          setSubtitleFile={setSubtitleFile}
          title={title}
          setTitle={setTitle}
          setIsDisableContinue={setIsDisableContinue}
          linkYoutube={linkYoutube}
          setLinkVideoYoutubeSelected={setLinkYoutube}
          originalLanguage={originalLanguage}
          setOriginalLanguage={setOriginalLanguage}
          targetLanguage={targetLanguage}
          setTargetLanguage={setTargetLanguage}
          loadingHandleFile={loadingHandleFile}
          setLoadingHandleFile={setLoadingHandleFile}
          languageError={languageError}
          setLanguageError={setLanguageError}
        />
        {isMultipleInputDubbing ? (
          <MultipleInputTitleAndButton />
        ) : (
          <SingleInputTitleAndButton />
        )}
      </StyledModalCreateProject>
    </Dialog>
  );
};
export default ModalCreateProject;
