import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Divider, InputBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dialog from '@src/components/Dialog';
import actions from '@src/redux/actions';

import { StyledInputWrapper, StyledVoucherDialog } from './index.style';
import Voucher from './Voucher';

const VoucherDialog = ({
  open,
  vouchers,
  autoVouchers,
  appliedVoucher,
  getVoucherText,
  onClose,
  checkCanApplyVoucher,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const handleApplyVoucher = () => {
    onClose();
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value.toUpperCase());
    setError(null);
  };

  useEffect(() => {
    if (open) {
      setCode('');
      setError(null);
    }
  }, [open]);

  const handleApplyCode = () => {
    const matchedVoucher = vouchers.find((voucher) => voucher.code === code);
    if (!matchedVoucher) {
      setError('enterVoucherError');
      return;
    }
    if (!matchedVoucher.satisfiedPolicy) {
      setError('notSatisfiedVoucherError');
      return;
    }
    if (!matchedVoucher.satisfiedTime) {
      setError('expiredVoucherError');
      return;
    }
    // if (matchedVoucher.used) {
    //   setError('usedVoucherError');
    //   return;
    // }
    if (!matchedVoucher.remainingAmount) {
      setError('outOfVouchersError');
      return;
    }
    if (matchedVoucher.usedAmount >= matchedVoucher.numReuse) {
      setError('notEnoughAmountError');
      return;
    }
    dispatch(
      actions.voucher.applyVoucher({
        ...matchedVoucher,
        displayText: getVoucherText(matchedVoucher),
      }),
    );
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleApplyCode();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledVoucherDialog>
        <Typography className="promotion-title">
          {t('promotionTitle')}
        </Typography>
        <StyledInputWrapper>
          <InputBase
            autoFocus
            className="input-promotion"
            value={code}
            onChange={handleChangeCode}
            onKeyDown={handleKeyDown}
            placeholder={t('enterPromotionCode')}
          />
          <Button variant="contained" onClick={handleApplyCode}>
            {t('apply')}
          </Button>
        </StyledInputWrapper>
        {error && <Typography className="voucher-error">{t(error)}</Typography>}
        <Divider />
        <div className="voucher-title">
          <Typography>{t('voucher')}</Typography>
          <Typography>{t('applyUpTo')}</Typography>
        </div>

        {vouchers &&
          vouchers.map((voucher) => {
            const autoVoucher = autoVouchers.find(
              (item) => item.voucherCode === voucher.code,
            );
            const isAutoVoucher =
              autoVoucher?.voucherCode &&
              checkCanApplyVoucher(
                autoVoucher.voucherCode,
                autoVoucher.bannerType,
              );
            const showVoucher = !voucher.isPrivate || isAutoVoucher;
            if (showVoucher)
              return (
                <Voucher
                  voucher={voucher}
                  isAutoVoucher={isAutoVoucher}
                  voucherText={getVoucherText(voucher)}
                  key={voucher.id}
                  applied={appliedVoucher?.id === voucher.id}
                  onApply={handleApplyVoucher}
                />
              );
            return <div />;
          })}
      </StyledVoucherDialog>
    </Dialog>
  );
};

export default VoucherDialog;
