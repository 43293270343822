import * as React from 'react';
import { AppBar, Drawer, Menu, Popover } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import {
  BOX_SHADOW,
  BORDER_RADIUS,
  SIDEBAR_WIDTH,
  TOOLTIP_BOX_SHADOW,
  MOBILE_BREAKPOINT,
  SIDEBAR_WIDTH_COLLAPSED,
  NEW_SIDEBAR_WIDTH,
  FLASH_SALE_BANNER_HEIGHT,
} from '@src/styles/config';

const StyledWrapper = styled('div')`
  position: relative;
  top: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `${FLASH_SALE_BANNER_HEIGHT}px`
      : 0};
  height: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `calc(100vh - ${FLASH_SALE_BANNER_HEIGHT}px)`
      : `100vh`};

  &:after {
    display: table;
    clear: both;
    content: '';
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: block;
    }

    .mobile {
      display: none;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .small-username-info {
      display: none;
    }
    .web {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`;

const sidebarWidth = ({ useNewSideBar, openSidebar }) => {
  if (useNewSideBar)
    return openSidebar ? NEW_SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED;
  return openSidebar ? SIDEBAR_WIDTH : 0;
};

const StyledMainPanel = styled('div')(
  ({ theme, openSidebar, useNewSideBar }) => ({
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${sidebarWidth({ useNewSideBar, openSidebar })}px)`,
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${SIDEBAR_WIDTH_COLLAPSED}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% )`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    height: '100%',
    width: '100%',
  }),
);

const StyledNewAppBar = styled(AppBar)`
  background: transparent;
  box-shadow: none;
  position: absolute;
  width: 100%;
  min-height: 64px;
  display: none;
  position: sticky;
  top: 0px;

  .container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 64px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: ${BORDER_RADIUS};
    color: ${COLOR.text};
    box-shadow: ${BOX_SHADOW};
    background-color: ${COLOR.white};
    box-sizing: border-box;
  }

  z-index: ${(props) => (props.runningTourGuide ? -1000 : 1029)};
  opacity: ${(props) => (props.runningTourGuide ? 0 : 1)};

  @media only screen and (max-width: 599px) {
    z-index: 1029;
    display: block;
    opacity: 1;
  }
`;

const StyledAppBar = styled(AppBar)`
  background: transparent;
  box-shadow: none;
  position: absolute;
  width: 100%;
  min-height: 64px;
  display: ${(props) => (props.useNewSideBar ? 'none' : 'block')};
  position: sticky;
  top: 16px;

  .container {
    min-height: 64px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 16px;
    border-radius: ${BORDER_RADIUS};
    color: ${COLOR.text};
    box-shadow: ${BOX_SHADOW};
    background-color: ${COLOR.white};

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  // @media only screen and (max-height: 900px) {
  z-index: ${(props) => (props.runningTourGuide ? -1000 : 1029)};
  opacity: ${(props) => (props.runningTourGuide ? 0 : 1)};
  // }

  // @media only screen and (max-width: 1199px) {
  //   z-index: ${(props) => (props.runningTourGuide ? -1000 : 1029)};
  //   opacity: ${(props) => (props.runningTourGuide ? 0 : 1)};
  // }

  // @media only screen and (min-height: 900px) and (min-width: 1199px) {
  //   opacity: 1;
  //   z-index: 1029;
  // }

  @media only screen and (max-width: 599px) {
    z-index: 1029;
    display: block;
    opacity: 1;
  }
`;

const StyledContent = styled('div')`
  flex-grow: 1;
  margin: ${(props) =>
    props.useNewSideBar ? '8px 16px 8px 16px' : '32px 16px 8px 16px'};
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 8px;
  }

  .banner {
    width: 100%;
  }

  .content-layout {
    flex-grow: 1;
    overflow-x: hidden;
  }
`;

const StyledSidebar = styled('div')`
  height: 100%;
  position: relative;

  .brand {
    padding: 16px 0;
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
    .logo {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
    .logo-img {
      cursor: pointer;
      width: 144px;
    }
    .small-logo-img {
      cursor: pointer;
      width: 32px;
    }
  }

  .sidebar-wrapper {
    position: relative;
    /* height: calc(100vh - 75px); */
    height: ${(props) => (props.useNewSideBar ? '394px' : '')};
    overflow: auto;
    width: 260px;
    z-index: 4;
    color: inherit;
    padding-bottom: 30px;
  }

  .hidden-scrollbar {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
  }

  .small-scrollbar {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${COLOR.indigo[64]};
      border-radius: 100px;
    }
  }

  .menu-submenu {
    position: relative;

    &:hover {
      & > .placement-right-top {
        display: block;
      }
    }
  }

  .list {
    margin-top: 16px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    list-style: none;
    color: inherit;

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  .collapse-list {
    margin-top: 0;

    &.caret {
      margin-top: 8px;
    }
  }

  .collapse-active {
    outline: none;
    background-color: ${COLOR.primary};
    box-shadow: none;
  }

  .item {
    color: inherit;
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  .item-link {
    margin: 10px 15px 0;
    border-radius: ${BORDER_RADIUS};
    position: relative;
    display: block;
    padding: 10px 15px;
    width: auto;
    text-decoration: none;
    line-height: 30px;
    border-width: 2px;
    border-style: solid;
    border-color: white;

    &:hover {
      outline: none;
      background-color: ${TRANSPARENT_COLOR.dark};
      box-shadow: none;
    }

    &,
    &:focus {
      color: inherit;
    }

    .item-wrapper {
      display: flex;
      gap: 0px;
      justify-content: flex-start;
    }
  }

  .item-button {
    text-transform: capitalize;
    margin: 10px 15px 0;
    border-radius: ${BORDER_RADIUS};
    padding: 10px 15px;
    text-decoration: none;
    line-height: 30px;
    text-align: left;
    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    border-color: white;

    &:hover {
      outline: none;
      background-color: ${TRANSPARENT_COLOR.primary};
      box-shadow: none;
    }

    &:hover,
    &:focus {
      color: inherit;
    }

    .img {
      width: 27px;
      height: 27px;
    }

    .video-tutorial-text {
      color: ${COLOR.darkBlue};
    }
  }

  .item-icon {
    color: inherit;
    width: 30px;
    height: 24px;
    float: left;
    position: inherit;
    top: 3px;
    margin-right: 15px;
    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
  }

  .item-text {
    color: inherit;
    margin: 0;
    line-height: 30px;
    position: relative;
    display: block;
    height: auto;
    white-space: nowrap;
  }

  .caret {
    margin-top: 4px;
    position: absolute;
    right: 18px;
  }

  .active-link {
    &,
    &:hover,
    &:focus {
      font-weight: 600;
      border-color: ${COLOR.primary};
      background-color: ${TRANSPARENT_COLOR.primary};
    }
  }

  .convert-ole-version-button {
    margin: 10px 15px 0;
    border-radius: 5px;
    padding: 10px 15px;
    min-width: 225px;
  }

  .vbee-v3-img {
    margin-right: 10px;
  }

  .package-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin: 0 20px;
  }

  .sidebar-package-info {
    padding: 16px 0;
  }

  .package-item-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .text {
      font-size: 14px;
    }

    .value {
      font-size: 14px;
      font-weight: bold;
    }

    .error-text {
      color: ${COLOR.danger};
    }
  }
`;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    boxShadow: BOX_SHADOW,
    width: SIDEBAR_WIDTH,

    [theme.breakpoints.up('md')]: {
      width: SIDEBAR_WIDTH,
      position: 'fixed',
      height: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      width: SIDEBAR_WIDTH,
      boxShadow: BOX_SHADOW,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },

    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
}));

const StyledNavBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .navbar-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
  }

  .btn-order {
    display: flex;
    border-radius: 5px;
  }

  .pending-button {
    color: ${COLOR.dark};

    &:hover,
    &:focus {
      background: ${TRANSPARENT_COLOR.primary};
      color: ${COLOR.darkBlue};
    }
  }

  .error-button {
    color: ${COLOR.danger};
  }

  .upgrade-button {
    color: ${COLOR.primary};
    font-weight: 600;
    background: ${TRANSPARENT_COLOR.primary};

    &:hover {
      background: ${COLOR.primary};
      color: ${COLOR.white};
    }
  }

  .language-icon {
    width: 16px;
  }
  .language-text {
    font-weight: normal;
    text-transform: none;
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 8px;
    .menu-item {
      gap: 10px;
    }
    .style-text {
      text-decoration: none;
      color: ${COLOR.text};
    }
    .profile-info {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .profile-content {
    text-align: right;
  }

  .light-text {
    color: ${COLOR.light};
  }
  .text {
    font-size: 14px;
    white-space: nowrap;
  }
  .bold {
    font-weight: bold;
  }
  .error-text {
    color: ${COLOR.danger};
  }

  .profile-icon {
    margin-right: 5px;
  }

  .package-info {
    margin-left: 12px;
  }

  @media only screen and (min-width: 1599px) {
    .large-package-info {
      display: flex;
      gap: 15px;
    }

    .large-language-button,
    .normal-username-info,
    .normal-package-info-item {
      display: flex;
    }

    .medium-language-button,
    .small-username-info,
    .medium-package-info,
    .small-package-info-item {
      display: none;
    }
  }

  @media only screen and (max-width: 1599px) and (min-width: 1439px) {
    .large-package-info {
      display: flex;
      gap: 15px;
    }

    .small-username-info,
    .medium-language-button,
    .normal-package-info-item {
      display: flex;
    }

    .large-language-button,
    .normal-username-info,
    .medium-package-info,
    .small-package-info-item {
      display: none;
    }
  }

  @media only screen and (max-width: 1439px) and (min-width: 1199px) {
    .large-package-info,
    .normal-username-info,
    .medium-language-button,
    .small-package-info-item {
      display: none;
    }

    .large-language-button,
    .small-username-info,
    .normal-package-info-item {
      display: flex;
    }

    .medium-package-info {
      display: grid;
      grid-template-columns: 230px 230px;
    }
  }

  @media only screen and (max-width: 1199px) and (min-width: 1023px) {
    .large-language-button,
    .normal-username-info,
    .large-package-info,
    .small-package-info-item {
      display: none;
    }

    .medium-language-button,
    .small-username-info,
    .normal-package-info-item {
      display: flex;
    }

    .medium-package-info {
      display: grid;
      grid-template-columns: 220px 220px;
    }
  }

  @media only screen and (max-width: 1023px) and (min-width: ${MOBILE_BREAKPOINT}) {
    .large-language-button,
    .normal-username-info,
    .small-username-info,
    .large-package-info,
    .normal-package-info-item {
      display: none;
    }

    .medium-language-button,
    .small-package-info-item {
      display: flex;
    }

    .medium-package-info {
      display: grid;
      grid-template-columns: 190px 190px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .large-language-button,
    .normal-username-info,
    .small-username-info,
    .large-package-info,
    .normal-package-info-item {
      display: none;
    }

    .normal-username-info {
      display: block;
    }
  }

  .button-avatar {
    position: relative;
    border: 3px solid transparent;
    border-radius: 100%;

    :before {
      content: '';
      position: absolute;
      inset: 0;
      margin: -3px;
      background-color: ${(props) => props.colorpackageavatar};
      border-radius: inherit;
    }
  }
`;

const StyledPolicyAndTermComponent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  padding: 10px 30px 10px 10px;
`;

const StyledMenu = styled(Menu)`
  .menu-item {
    grid-gap: 10px;
  }

  .text-link {
    color: ${COLOR.text};
    text-decoration: none;
  }

  .logout-style {
    color: ${COLOR.danger};
  }
  .style-version {
    margin-left: 20px;
    color: ${COLOR.light};
  }

  .icon-download-container {
    margin: 0px 0px 12px -14px;
  }
`;

const StyledDownloadAppContainer = styled('div')`
  padding: 40px 80px;
  overflow: hidden;
  text-align: center;
  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
  }

  .description-2 {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0px;
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .qr-code {
      width: 154px;
      height: 154px;
    }
  }
`;

const StyledTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Tooltip {...props} classes={{ popper: className }} placement="bottom" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: BORDER_RADIUS,
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 10px',
    boxShadow: TOOLTIP_BOX_SHADOW,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.label[80],
  },
}));

const StyledCustomerCare = styled('div')`
  position: relative;
  z-index: 1000;
  .customer-care-container {
    position: absolute;
    bottom: 16px;
    right: 24px;
    pointer-events: none;

    .zalo {
      color: ${COLOR.labelBlue};
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .call {
      color: ${COLOR.boldSuccess};
      font-weight: 600;
      font-size: 16px;
    }

    .MuiSpeedDial-actions {
      margin-bottom: 6px;
      padding-bottom: 0px;
    }

    .MuiSpeedDial-fab {
      animation-name: zoom;
      animation-delay: 0s;
      animation-duration: 1.15s;
      animation-iteration-count: infinite;
      border-radius: 100%;
      color: ${COLOR.darkBlue};
      width: 44px;
      height: 44px;

      &:hover {
        animation-name: none;
        background-color: ${COLOR.primary};
      }

      @keyframes zoom {
        0% {
          transform: scale(0.9);
        }

        50% {
          transform: scale(1);
          box-shadow: 0 0 0 8px ${TRANSPARENT_COLOR.primary};
        }

        100% {
          transform: scale(0.9);
          box-shadow: 0 0 0 0 ${TRANSPARENT_COLOR.primary};
        }
      }
    }

    .MuiSpeedDialAction-fab {
      box-shadow: 0px 2px 4px opx rgba(0, 0, 0, 0.1);
      width: 44px;
      height: 44px;

      &:hover {
        background: ${COLOR.white};
      }
    }

    .MuiSpeedDialAction-staticTooltipLabel {
      display: none;
    }
  }
`;

const StyledIconWraper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100%;
  text-transform: capitalize;

  .close-icon {
    transform: translate(5px, 3px);
    font-size: 18px;
  }
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    overflow: visible;
    margin-left: 10px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 0px;
      left: 5px;
      top: 80%;
      border: 7px solid transparent;
      border-left: 0;
      border-right: 7px solid ${COLOR.white};
      transform: translate(calc(-100% - 5px), -50%);
    }
  }

  .link {
    text-decoration: none;
    color: ${COLOR.text};
  }

  .menu-item {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:hover {
      background-color: ${TRANSPARENT_COLOR.primary};
      border-radius: 5px;
    }
  }

  .title {
    font-weight: 500;
    font-size: 14px;
  }

  .sub-title {
    font-weight: 300;
    font-size: 12px;
  }
`;

const StyledPackageInfoItem = styled('div')`
  .package-info-item {
    margin-right: 5px;
    gap: 5px;
    align-items: center;
  }

  .lock-icon {
    margin-bottom: 2px;
  }

  .package-info-icon {
    width: 18px;
    height: 18px;
  }
`;

const StyledBetaWrapper = styled('div')`
  position: relative;

  .beta-background {
    background-color: ${COLOR.paleBlue};
    border-radius: 100px;
    width: 40px;
    height: 25px;
    margin-top: 3px;
  }

  .beta-text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 700;
    color: ${COLOR.info};
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export {
  StyledWrapper,
  StyledMainPanel,
  StyledAppBar,
  StyledContent,
  StyledSidebar,
  StyledDrawer,
  StyledNavBar,
  StyledPolicyAndTermComponent,
  StyledMenu,
  StyledDownloadAppContainer,
  StyledTooltip,
  StyledCustomerCare,
  StyledPackageInfoItem,
  StyledPopover,
  StyledBetaWrapper,
  StyledIconWraper,
  StyledNewAppBar,
};
