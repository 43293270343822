import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import debounce from '@src/utils/debounce';
import { StyledSelectionComponent } from './index.style';

const SelectionComponent = ({
  value,
  label,
  handleSelectLanguage,
  languages = [],
  defaultLanguage = {},
  canSelect = true,
  languageError,
  setLanguageError,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredLanguages, setFilteredLanguages] = useState(languages);

  const toggleOpen = () => {
    if (languages.length > 0 && canSelect) setOpen(!open);
  };

  const selectProps = {
    open,
    onOpen: toggleOpen,
    onClose: toggleOpen,
    MenuProps: {
      PaperProps: {
        className: 'custom-scrollbar',
        style: {
          maxHeight: 250,
          borderRadius: 12,
        },
      },
    },
    renderValue: (selected) => {
      const selectedLanguage =
        languages?.find((lang) => lang.value === selected) || defaultLanguage;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <img src={selectedLanguage.flag} alt="flag" width={32} height={24} />
          <Box>{t(selectedLanguage.label)}</Box>
        </Box>
      );
    },
  };

  if (languages.length === 0 || !canSelect)
    selectProps.IconComponent = () => null;

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      const result = languages.filter((language) =>
        language.label.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredLanguages(result);
    }, 200),
    [languages],
  );

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const SearchInput = () => (
    <TextField
      size="small"
      variant="standard"
      placeholder={t('search')}
      value={search}
      onKeyDown={(e) => e.stopPropagation()}
      onClick={stopImmediatePropagation}
      onChange={(e) => setSearch(e.target.value)}
      autoFocus
      InputProps={{
        sx: {
          padding: '6px 16px',
          display: 'flex',
          alignItems: 'center',
        },
        disableUnderline: true,
        startAdornment: <Search sx={{ height: 24, width: 24 }} />,
      }}
    />
  );

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  // Create an array of menu items
  const menuItems = canSelect
    ? [
        <SearchInput key="search-input" />,
        ...filteredLanguages.map((language) => (
          <MenuItem key={language.id} value={language.value}>
            <ListItemIcon>
              <img src={language.flag} alt="flag" width={32} height={24} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography className="language-text">
                  {t(language.label)}
                </Typography>
              }
            />
          </MenuItem>
        )),
      ]
    : [];

  return (
    <Box display="flex" flexDirection="column" gap="4px" width="100%">
      <StyledSelectionComponent
        error={languageError}
        size="small"
        variant="outlined"
        value={value}
        label={label}
        select
        onChange={(e) => {
          handleSelectLanguage(e);
          setLanguageError(false);
        }}
        SelectProps={selectProps}
        onBlur={() => {
          setOpen(false);
          if (!value) setLanguageError(true);
        }}
      >
        {menuItems}
      </StyledSelectionComponent>

      {languageError && (
        <Typography variant="caption" color="error" marginLeft="12px">
          {t('pleaseSelectOriginalLanguage')}
        </Typography>
      )}
    </Box>
  );
};

export default SelectionComponent;
