import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Emoji from 'react-emoji-render';
import { Box, Button, Typography } from '@mui/material';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import { StyledButton } from './index.style';

const SampleScriptItem = ({
  sampleScript,
  audioRef,
  name,
  className,
  onChangeInputLength,
}) => {
  const dispatch = useDispatch();

  const { audioLink: audioPlayerLink, isPlaying } = useSelector(
    (state) => state.audioPlayer,
  );

  const handleChangePlayAudio = (audioLink) => {
    if (audioPlayerLink === audioLink) {
      dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    } else {
      // eslint-disable-next-line no-param-reassign
      audioRef.current.src = SILENCE_AUDIO_URL;
      audioRef.current.load();
      audioRef.current.play();
      dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      dispatch(actions.audioPlayer.updateShowAudioPlayer(true));
    }
  };

  const getVoiceInfo = async (code) => {
    if (!code) return null;
    const voiceExist = await apis.voices.getVoices({ code });
    if (!voiceExist.status) return null;
    return voiceExist?.result?.voices[0];
  };

  const handleClick = async () => {
    const { text, title } = sampleScript;
    const defaultSample = sampleScript.audios?.[0] || {};
    const { audioLink, audioType, bitrate, sampleRate, voiceCode, speed } =
      defaultSample;

    // If move handleChangePlayAudio behind dispatch, it will not work, and I don't know why
    handleChangePlayAudio(audioLink);
    const voice = await getVoiceInfo(voiceCode);
    const dataSynthesis = {
      text,
      title,
      audioLink,
      audioType,
      bitrate,
      sampleRate,
      voice,
      speed,
    };

    dispatch(actions.synthesisRequest.updateSynthesisConfig(dataSynthesis));
    dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
    // Update request to show voice in audio player
    dispatch(actions.audioPlayer.updatePreviewRequestInfo(dataSynthesis));
    if (onChangeInputLength) onChangeInputLength(text.length);
  };

  const hideSampleButton = () => {
    dispatch(actions.synthesisRequest.updateShowSampleScripts(false));
    handleClick();
  };

  return (
    <StyledButton>
      <div className={`wrapper ${className}`}>
        <Box>
          <Button
            className="support-button"
            onMouseDown={(e) => e.preventDefault()}
            onClick={hideSampleButton}
          >
            <Box className="button-wrapper">
              <Box className="icon-button">
                <Emoji
                  onlyEmojiClassName="make-emojis-large"
                  text={`${sampleScript?.icon}`}
                />
              </Box>
              <Typography className="button-content">{name}</Typography>
            </Box>
          </Button>
        </Box>
      </div>
    </StyledButton>
  );
};

export default SampleScriptItem;
