import React, { useState } from 'react';
import { Typography, Menu, MenuItem, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PAGINATION_LIMIT } from '@src/constants';
import actions from '@src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomPagination from '@src/components/Pagination';
import { StyledTableFooter } from './index.style';

const CustomTableFooter = ({
  total,
  page,
  onChangePage,
  isSettingRow = false,
  footerPadding,
  limit = PAGINATION_LIMIT,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowPerPagination } = useSelector((state) => state.configTable);
  const [anchorSelectAmount, setAnchorSelectAmount] = useState(null);

  const handleCloseOpen = () => setAnchorSelectAmount(null);

  const handleClickOpen = (event) => {
    setAnchorSelectAmount(event.currentTarget);
  };

  const handleClickSelect = (value) => {
    setAnchorSelectAmount(null);
    dispatch(
      actions.configTable.selectPaginationLimit({ rowPerPagination: value }),
    );
  };

  const optionShowPerPage = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
  ];
  const paginationLimit = isSettingRow ? rowPerPagination : limit;
  const from = (page - 1) * paginationLimit + 1;
  const to = page * paginationLimit < total ? page * paginationLimit : total;
  return (
    <StyledTableFooter footerpadding={footerPadding}>
      {isSettingRow ? (
        <div className="one-line">
          <Typography className="footer-label">
            {t('displayedRows')}:
          </Typography>
          <div>
            <Button
              className="button-select-amount-page"
              onClick={handleClickOpen}
            >
              {rowPerPagination}
              {anchorSelectAmount ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </Button>
            <Menu
              anchorEl={anchorSelectAmount}
              open={Boolean(anchorSelectAmount)}
              onClose={handleCloseOpen}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              {optionShowPerPage.map((option) => (
                <MenuItem
                  key={option.label}
                  value={option.value}
                  onClick={() => handleClickSelect(option.label)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      ) : (
        ''
      )}
      {total && (
        <Typography className="footer-label">
          {t('footerLabel', { from, to, total })}
        </Typography>
      )}
      {total > paginationLimit && (
        <div className="pagination">
          <CustomPagination
            count={Math.ceil(total / paginationLimit)}
            page={page}
            onChangePage={onChangePage}
          />
        </div>
      )}
    </StyledTableFooter>
  );
};

export default CustomTableFooter;
