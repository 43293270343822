export const actionTypes = {
  UPDATE_PACKAGE: 'UPDATE_PACKAGE',
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  UPDATE_VOUCHER: 'UPDATE_VOUCHER',
  UPDATE_USER_ORDER: 'UPDATE_USER_ORDER',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  UPDATE_HAS_INVOICE: 'UPDATE_HAS_INVOICE',
  UPDATE_ERROR_INVOICE: 'UPDATE_ERROR_INVOICE',
};

const updatePackage = (packageSelected) => ({
  type: actionTypes.UPDATE_PACKAGE,
  packageSelected,
});

const updatePaymentMethod = (paymentMethod) => ({
  type: actionTypes.UPDATE_PAYMENT_METHOD,
  paymentMethod,
});

const updateVoucher = (voucher) => ({
  type: actionTypes.UPDATE_VOUCHER,
  voucher,
});

const updateUserOrder = (data) => ({
  type: actionTypes.UPDATE_USER_ORDER,
  data,
});

const updateInvoice = (invoice) => ({
  type: actionTypes.UPDATE_INVOICE,
  invoice,
});

const updateHasInvoice = (hasInvoice) => ({
  type: actionTypes.UPDATE_HAS_INVOICE,
  hasInvoice,
});

const updateErrorInvoice = (errors) => ({
  type: actionTypes.UPDATE_ERROR_INVOICE,
  errors,
});

export {
  updatePackage,
  updatePaymentMethod,
  updateVoucher,
  updateUserOrder,
  updateInvoice,
  updateHasInvoice,
  updateErrorInvoice,
};
