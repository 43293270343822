import React from 'react';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import NewLogo from './NewLogo';
import OldLogo from './OldLogo';

const Logo = (props) => {
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);

  const separateAIVoiceProducts = getFeatureValue(
    FEATURE_KEYS.SEPARATE_AIVOICE_PRODUCTS,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  return separateAIVoiceProducts ? (
    <NewLogo {...props} />
  ) : (
    <OldLogo {...props} />
  );
};

export default Logo;
