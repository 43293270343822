import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

const StyledEmphasisButton = styled('div')`
  position: relative;

  .emphasis-button {
    cursor: pointer;
    padding: 5px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    border-radius: ${BORDER_RADIUS};
    text-transform: capitalize;
    color: ${COLOR.text};

    &:hover {
      background: ${TRANSPARENT_COLOR.dark};
    }

    ${(props) =>
      props.disabled && {
        cursor: 'none',
        color: COLOR.light,
        '&:hover': {
          background: 'inherit',
        },
      }}

    ${(props) =>
      props.active && {
        background: TRANSPARENT_COLOR.primary,
        '&:hover': {
          background: TRANSPARENT_COLOR.primary,
        },
      }}
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 20px;
  cursor: pointer;
  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }

  .text {
    font-weight: ${(props) => (props.active ? '600' : '400')};
  }
`;

export { StyledEmphasisButton, StyledMenuItem };
