import * as React from 'react';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledMapEditor = {
  SPEED: {
    backgroundColor: COLOR.orange,
    color: COLOR.white,
    padding: '0 1px',
  },
  EMPHASIS: {
    backgroundColor: COLOR.lightGreen,
    color: COLOR.white,
    padding: '0 1px',
  },
  PITCH: {
    backgroundColor: COLOR.lightBlue,
    color: COLOR.white,
    padding: '0 1px',
  },
  BREAK_TIME: {
    backgroundColor: COLOR.purple,
    color: COLOR.white,
    padding: '0 1px',
  },
};

export const StyledHightLightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 10px',
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.label[80],
  },
}));

export const StyledTooltipTitle = styled('div')`
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    font-size: 14px;
  }

  .icon-button {
    padding: 0 0 0 8px;
  }

  .icon {
    font-size: 14px;
  }
`;
