import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Book, Close } from '@mui/icons-material';
import { COLOR } from '@src/styles/color';
import { USER_GUIDE_DUBBING_PROJECTS_URL } from '@src/configs';
import { StyledNotiNewFeature } from './index.style';

const NotiNewFeature = ({ handleCloseNoti }) => {
  const { t } = useTranslation();

  return (
    <StyledNotiNewFeature>
      <Box display="flex" flexDirection="column">
        <Box className="title">{t('projectNotiNewFeatureTitle')}</Box>
      </Box>
      <Box display="flex" gap="12px">
        <Button
          component="a"
          href={USER_GUIDE_DUBBING_PROJECTS_URL}
          className="guide-button"
          sx={{ border: `1px solid ${COLOR.functionYellow[100]}` }}
        >
          <Book className="icon" />
          <Typography className="text">{t('userGuide')}</Typography>
        </Button>
        <IconButton onClick={handleCloseNoti}>
          <Close className="icon" />
        </IconButton>
      </Box>
    </StyledNotiNewFeature>
  );
};

export default NotiNewFeature;
