import * as React from 'react';
import { Drawer } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import {
  BOX_SHADOW,
  FLASH_SALE_BANNER_HEIGHT,
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '@src/styles/config';

const StyledSidebarTooltip = styled(
  ({ className, placement = 'right', ...props }) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow
      placement={placement}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 16px',
    textAlign: 'center',
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.label[80],
  },
}));

const StyledSidebarNew = styled('div')`
  height: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `calc(100% - ${FLASH_SALE_BANNER_HEIGHT}px)`
      : '100%'};
  top: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `${FLASH_SALE_BANNER_HEIGHT}px`
      : '0'};
  max-height: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `calc(${props.heightScreen}px - ${FLASH_SALE_BANNER_HEIGHT}px)`
      : `${props.heightScreen}px`};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiBox-root {
    margin-top: 0;
  }

  .wrap-information {
    padding: 0 8px 12px 8px;
  }

  .brand {
    padding: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    .logo {
      height: 32px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .logo-small {
      height: 58px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 2px;
    }
    .logo-img {
      cursor: pointer;
      height: 32px;
    }
  }

  .sidebar-wrapper {
    overflow-y: auto;
    flex: 1;
    padding: 0 8px 0 10px;
    position: relative;
    color: inherit;
    @media screen and (max-width: 600px) {
      padding-bottom: 88px;
    }
  }

  .hidden-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar {
      width: 4px;
      background: ${COLOR.white};
    }
  }

  .small-scrollbar {
    // scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${COLOR.indigo[64]};
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${COLOR.indigo[80]};
      border-radius: 2px;
    }
  }

  .menu-submenu {
    position: relative;
    &:hover {
      & > .placement-right-top {
        display: block;
      }
    }
  }

  .list {
    // padding: 8px 0 16px 0;
    list-style: none;
    &:before,
    &:after {
      display: table;
      content: '';
    }
    &:after {
      clear: both;
    }
  }

  .wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 10px;
    gap: 10px;
    box-sizing: border-box;

    .wallet-ads {
      width: ${`${NEW_SIDEBAR_WIDTH - 20}px`};
    }

    .wallet-character {
      display: flex;
      gap: 12px;
      align-items: center;
      width: 100%;

      .icon {
        padding: 10px;
        border-radius: 50%;
        background-color: ${COLOR.primary};
      }
    }
  }

  .danger-text {
    color: ${COLOR.red} !important;
  }

  .wallet-package {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;

    .characters-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 2px;
      align-items: center;
    }

    .lock-icon {
      color: ${COLOR.red};
      cursor: pointer;
    }

    .remain-characters {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: ${COLOR.darkBlue};
    }

    .expired-date {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.4px;
      color: ${COLOR.label[80]};
    }
  }

  .collapse-list {
    margin-top: 0;
    &.caret {
      margin-top: 8px;
    }
  }

  .collapse-active {
    outline: none;
    background-color: ${COLOR.primary};
    box-shadow: none;
  }

  .item {
    display: block;
    padding: 0;
    margin: 0;
  }

  .item-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 2px 0;
    height: 28px;
    border-radius: 8px;
    padding: 8px;
    text-decoration: none;
    &:hover {
      outline: none;
      background-color: ${COLOR.label[8]};
      box-shadow: none;
    }
    &,
    &:focus {
      color: inherit;
    }

    .item-wrapper {
      flex: 1;
      display: flex;
      gap: 0px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .item-icon {
    color: ${COLOR.indigo[100]};
    width: 24px;
    height: 24px;
    float: left;
    top: 3px;
    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
  }

  .item-icon-active {
    color: ${COLOR.indigo[100]};
  }

  .item-text {
    color: ${COLOR.indigo[100]};
    margin: 0;
    line-height: 20px;
    letter-spacing: -0.4px;
    display: block;
    height: auto;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
  }

  .item-text-active {
    font-weight: 600;
    color: ${COLOR.indigo[100]};
  }

  .caret {
    margin-top: 4px;
    position: absolute;
    right: 18px;
  }

  .active-link {
    &,
    &:hover,
    &:focus {
      background-color: ${COLOR.indigo[8]};
    }
  }

  .convert-ole-version-button {
    margin: 10px 15px 0;
    border-radius: 5px;
    padding: 10px 15px;
    min-width: 225px;
  }

  .vbee-v3-img {
    margin-right: 10px;
  }

  .package-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin: 0 20px;
  }

  .package-wrapper-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 15px;
    margin-top: 15px;
    position: relative;
    overflow: auto;
    z-index: 4;
    .close-button {
      height: 24px;
      width: 24px;
      color: ${COLOR.darkBlue};
    }
  }

  .sidebar-package-info {
    padding: 16px 0;
  }

  .package-item-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .text {
      font-size: 14px;
    }
    .value {
      font-size: 14px;
      font-weight: bold;
    }
    .error-text {
      color: ${COLOR.danger};
    }
  }

  .gift-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 16px 0;

    .gift-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      background-color: ${COLOR.darkBlue};
      cursor: pointer;

      .number-of-gift {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: ${COLOR.red};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 16px;
        color: ${COLOR.white};
      }
    }
  }

  .footer-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 10px 4px 10px;
    gap: 4px;
  }
`;

const StyledDrawerNew = styled(Drawer)(({ theme, opensidebar }) => ({
  '& .MuiDrawer-paper': {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: NEW_SIDEBAR_WIDTH,
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      width: opensidebar
        ? `${NEW_SIDEBAR_WIDTH}px`
        : `${SIDEBAR_WIDTH_COLLAPSED}px`,
      position: 'fixed',
      height: '100%',
    },

    [theme.breakpoints.down('md')]: {
      width: opensidebar
        ? `${NEW_SIDEBAR_WIDTH}px`
        : `${SIDEBAR_WIDTH_COLLAPSED}px`,
      boxShadow: BOX_SHADOW,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: 'auto',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      boxShadow: BOX_SHADOW,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: 'auto',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },
  },
}));

const StyledBetaWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.bluev2[10]};
  border-radius: 100px;
  padding: 2px 0px;
  width: 52px;

  .beta-background {
    background-color: ${COLOR.paleBlue};
    border-radius: 100px;
    width: 40px;
    height: 25px;
    margin-top: 3px;
  }

  .beta-text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 700;
    color: ${COLOR.info};
    font-size: 12px;
    text-transform: uppercase;
  }

  .new-text {
    margin: 0;
    color: ${COLOR.bluev2[100]};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
  }
`;

export {
  StyledSidebarTooltip,
  StyledSidebarNew,
  StyledDrawerNew,
  StyledBetaWrapper,
};
