import React from 'react';
import STTContainer from '@src/containers/STT';
import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';

const STT = () => (
  <Feature name={FEATURE_FLAG.STT}>
    <STTContainer />;
  </Feature>
);
export default STT;
