export const VOUCHER_TYPE = {
  PERCENT: 'PERCENT',
  PRICE: 'PRICE',
  CHARACTER: 'CHARACTER',
  TIME: 'TIME',
  PERCENT_TIME: 'PERCENT_TIME',
};

export const VOUCHER_COMPARISION_TYPE = {
  MONEY_DECREASE: 'MONEY_DECREASE',
  CHARACTER_EXTENSION: 'CHARACTER_EXTENSION',
  TIME_EXTENSION: 'TIME_EXTENSION',
};
