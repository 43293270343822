import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Collapse, Typography } from '@mui/material';
import logoTTSVi from '@src/assets/logo/logo-package-tts-vi.png';
import logoTTSEn from '@src/assets/logo/logo-package-tts-en.png';
import logoApi from '@src/assets/logo/logo-package-api.png';
import logoDubbingVi from '@src/assets/logo/logo-package-dubbing-vi.png';
import logoDubbingEn from '@src/assets/logo/logo-package-dubbing-en.png';
import { FREE_PACKAGE_CODES, PACKAGE_TYPE } from '@src/constants/package';
import {
  checkAutoRenewalPackage,
  getPackageCurrentPrice,
  getPackageName,
} from '@src/services/package';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import { LANGUAGE } from '@src/constants';
import { PAYMENT_METHOD_TYPE } from '@src/constants/payment';

import { delimitNumber } from '@src/utils/number';
import route from '@src/constants/route';
import { useSelector } from 'react-redux';
import { convertSecondsToHMS } from '@src/utils/time';
import { VBEE_CONSOLE_URL } from '@src/configs';
import apis from '@src/apis';
import { StyledSubscriptionItem } from './index.style';

const SubscriptionItem = ({
  packageInfo = {},
  usingOrder = {},
  isVietNam,
  subscriptionPurchase,
  handleOpenDialog,
  dialogName,
}) => {
  const { i18n } = useTranslation();
  const { t, language } = i18n;
  const history = useHistory();
  const [showPackageDetail, setShowPackageDetail] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});
  const { user } = useSelector((state) => state.auth);
  const {
    remainingCharacters = 0,
    bonusCharacters = 0,
    lockCharacters = 0,
    apiCharacters,
    dubbing,
  } = user;
  const paymentAccount = useMemo(
    () => subscriptionPurchase?.paymentAccounts?.[0],
    [subscriptionPurchase],
  );

  const { packageExpiryDate } = usingOrder;
  const { expiresIn, type, code } = packageInfo;
  const isPackageExpired =
    packageExpiryDate && moment(packageExpiryDate).isBefore(moment());

  const { price, usdPrice } = getPackageCurrentPrice(packageInfo, true);

  const isAutoRenewalPackage = checkAutoRenewalPackage(packageInfo);

  const isFreePackage = FREE_PACKAGE_CODES.includes(code);

  const calculateRemainingResource = () => {
    switch (type) {
      case PACKAGE_TYPE.STUDIO:
        return isPackageExpired
          ? lockCharacters
          : remainingCharacters + bonusCharacters;

      case PACKAGE_TYPE.API:
        return isPackageExpired
          ? apiCharacters?.lockCharacters
          : apiCharacters?.remainingCharacters ||
              0 + apiCharacters?.bonusCharacters ||
              0;

      case PACKAGE_TYPE.DUBBING:
        return isPackageExpired
          ? dubbing?.lockSeconds
          : dubbing?.remainingSeconds;

      default:
        return 0;
    }
  };

  const handleRedirectToBuyPackage = () => {
    switch (type) {
      case PACKAGE_TYPE.STUDIO:
        history.push(
          isFreePackage ? route.PAYMENT : `${route.PAYMENT}?package=${code}`,
        );
        break;
      case PACKAGE_TYPE.API:
        window.open(
          isFreePackage
            ? `${VBEE_CONSOLE_URL}/payments`
            : `${VBEE_CONSOLE_URL}/payments?package=${code}`,
        );
        break;
      case PACKAGE_TYPE.DUBBING:
        history.push(
          isFreePackage
            ? route.PAYMENT_DUBBING
            : `${route.PAYMENT_DUBBING}?package=${code}`,
        );
        break;

      default:
        break;
    }
  };

  const packagePrice =
    isVietNam || isAutoRenewalPackage
      ? price || packageInfo.price
      : usdPrice || packageInfo.usdPrice;

  const returnAmountToBePaid = () => (
    <>
      {isVietNam || isAutoRenewalPackage ? (
        <span className="discount-unit-price text-16 underline">đ</span>
      ) : (
        <span className="discount-unit-price text-16">$</span>
      )}
      {packagePrice?.toLocaleString(language)}
    </>
  );

  const getPaymentMethodInfo = () => {
    switch (paymentMethod?.type) {
      case PAYMENT_METHOD_TYPE.VNPAYQR:
        return [
          {
            label: t('paymentMethod'),
            src: paymentMethod?.autoRenewIcon,
          },
          {
            label: t('paymentAccountName'),
            value: paymentAccount.cardNumber,
          },
        ];
      case PAYMENT_METHOD_TYPE.MOMOPAY:
        return [
          {
            label: t('paymentMethod'),
            src: paymentMethod?.autoRenewIcon,
          },
          {
            label: t('phoneNumber'),
            value: paymentAccount.phoneNumber,
          },
        ];
      default:
        return [];
    }
  };

  const autoRenewalPackageDetail = useMemo(() => {
    const packageDetail = [
      {
        label: t('nextTimePayment'),
        value: moment(packageExpiryDate).format('DD/MM/YYYY'),
      },
      {
        label: t('subscriptionCycle'),
        value: `${expiresIn} ${t('days')}`,
      },
      {
        label: t('amountToBePaid'),
        value: returnAmountToBePaid(),
      },
    ];

    if (paymentAccount) {
      const paymentMethodInfo = getPaymentMethodInfo();
      // Insert payment method info after the second item
      packageDetail.splice(1, 0, ...paymentMethodInfo);
    }

    return packageDetail;
  }, [paymentMethod, paymentAccount]);

  const getPackageLogo = () => {
    switch (packageInfo?.type) {
      case PACKAGE_TYPE.STUDIO:
        return language === LANGUAGE.VN ? logoTTSVi : logoTTSEn;
      case PACKAGE_TYPE.API:
        return logoApi;
      case PACKAGE_TYPE.DUBBING:
        return language === LANGUAGE.VN ? logoDubbingVi : logoDubbingEn;
      default:
        return language === LANGUAGE.VN ? logoTTSVi : logoTTSEn;
    }
  };

  const renderPackageButton = () => {
    const enableCancelAutoRenew =
      isAutoRenewalPackage &&
      subscriptionPurchase?.status &&
      subscriptionPurchase?.status !== SUBSCRIPTION_PURCHASE_STATUS.CANCELED;

    const enableRegisterAutoRenew =
      isAutoRenewalPackage &&
      (!subscriptionPurchase?.status ||
        subscriptionPurchase?.status === SUBSCRIPTION_PURCHASE_STATUS.CANCELED);

    if (isAutoRenewalPackage && isPackageExpired) {
      return (
        <div className="button-group">
          <Button className="buy-more-btn" onClick={handleRedirectToBuyPackage}>
            {t('renewNow')}
          </Button>
          {enableCancelAutoRenew ? (
            <Button
              className="cancel-auto-renewal-btn"
              onClick={() =>
                handleOpenDialog(dialogName.CANCEL_AUTO_RENEWAL, usingOrder)
              }
            >
              {t('cancelAutoRenew')}
            </Button>
          ) : null}
        </div>
      );
    }

    if (isFreePackage || isPackageExpired) {
      return (
        <Button
          fullWidth
          className="buy-more-btn"
          onClick={handleRedirectToBuyPackage}
        >
          {t('upgradeNow')}
        </Button>
      );
    }

    if (enableCancelAutoRenew) {
      return (
        <Button
          fullWidth
          className="cancel-auto-renewal-btn"
          onClick={() =>
            handleOpenDialog(dialogName.CANCEL_AUTO_RENEWAL, usingOrder)
          }
        >
          {t('cancelAutoRenew')}
        </Button>
      );
    }

    if (enableRegisterAutoRenew) {
      return (
        <Button
          fullWidth
          className="buy-more-btn"
          onClick={() =>
            handleOpenDialog(dialogName.REGISTER_AUTO_RENEWAL, usingOrder)
          }
        >
          {t('registerAutoRenew')}
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        className="buy-more-btn"
        onClick={handleRedirectToBuyPackage}
      >
        {t('buyMore')}
      </Button>
    );
  };

  const renderAutoRenewPackage = () => (
    <>
      <Box
        className="package-info-item"
        sx={{ cursor: 'pointer' }}
        onClick={() => setShowPackageDetail(!showPackageDetail)}
      >
        <Typography className="label">{t('nextTimeRenewal')}</Typography>
        {showPackageDetail ? (
          <ExpandLess className="expand-icon" />
        ) : (
          <ExpandMore className="expand-icon" />
        )}
      </Box>
      <Collapse
        in={showPackageDetail}
        timeout="auto"
        unmountOnExit
        className="collapse"
      >
        {autoRenewalPackageDetail.map((item) => (
          <Box className="package-info-item margin-bottom-8" key={item}>
            <Typography className="label font-weight-400">
              {item.label}
            </Typography>
            {item.src ? (
              <img src={item.src} alt="Payment method" />
            ) : (
              <Typography className="content">{item.value}</Typography>
            )}
          </Box>
        ))}
      </Collapse>
    </>
  );

  const remainingResource = calculateRemainingResource();

  const fetchPaymentMethods = async () => {
    const paymentMethodId = paymentAccount?.paymentMethodId;
    const data = await apis.paymentMethods.getPaymentMethods();
    if (data.status) {
      const totalPaymentMethods = data.result.paymentMethods;
      const method = totalPaymentMethods.find(
        (item) => item.id === paymentMethodId,
      );
      setPaymentMethod(method);
    }
  };

  useEffect(() => {
    if (paymentAccount) fetchPaymentMethods();
  }, [paymentAccount]);

  return (
    <StyledSubscriptionItem showdetail={showPackageDetail}>
      {/* Package name and logo */}
      <Box className="package-name">
        <img src={getPackageLogo()} alt="logo" className="logo" />
        <Box className="package-name-text">
          <Typography className="name">
            {getPackageName(
              packageInfo?.name,
              packageInfo?.code,
              language,
              t,
              true,
            )}
          </Typography>
          {isAutoRenewalPackage && (
            <Box display="flex" justifyContent="end" width="100%">
              <Box className="auto-renewal">{t('autoRenewal')}</Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* Remaining resource */}
      <Box className="package-info-item">
        <Typography className="label">
          {t(
            type === PACKAGE_TYPE.DUBBING
              ? 'remainingDuration'
              : 'remainingCharacter',
          )}
        </Typography>
        <Typography className="content">
          {type === PACKAGE_TYPE.DUBBING
            ? convertSecondsToHMS(remainingResource)
            : `${delimitNumber(remainingResource, language)} ${t(
                'characters',
              ).toLowerCase()}`}
        </Typography>
      </Box>

      {/* Package expiry date */}
      <Box className="package-info-item">
        <Typography className="label">{t('expiryDate')}</Typography>
        <Typography
          className={`content ${isPackageExpired ? 'expire-date' : ''}`}
        >
          {packageExpiryDate
            ? moment(packageExpiryDate).format('DD/MM/YYYY')
            : t('endless')}
        </Typography>
      </Box>

      {/* Auto renewal package detail */}
      {isAutoRenewalPackage && renderAutoRenewPackage()}

      {/* Cancel auto renewal or buy more button */}
      <Box flex={1} display="grid" alignItems="end">
        {renderPackageButton()}
      </Box>
    </StyledSubscriptionItem>
  );
};

export default SubscriptionItem;
