import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { StyledPreviewGuide } from './index.style';

const PreviewGuide = ({ openPreviewGuide, onSkipNote }) => {
  const { t } = useTranslation();

  if (openPreviewGuide)
    return (
      <StyledPreviewGuide>
        <Typography className="description">{t('tryListenNote')}</Typography>
        <Typography className="ignore-text" onClick={onSkipNote}>
          {t('skip')}
        </Typography>
      </StyledPreviewGuide>
    );

  return null;
};

export default PreviewGuide;
