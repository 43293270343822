import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  HelpRounded as ContactSupport,
  KeyboardArrowDownRounded,
  ChevronRightRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import ROUTES from '@src/constants/route';
import vbeeGrayIcon from '@src/assets/icons/vbee-icon-gray.svg';
import vbeeWhiteIcon from '@src/assets/icons/vbee-icon-white.svg';
import { getCollapseInitialState } from '@src/services/sidebar';
import logoAiVoice from '@src/assets/logo/logo-AiVoice-new.svg';
import NewInformationNote from '@src/containers/NewUserInformationNote';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import useSidebarRoutes from '@src/hooks/useSidebarRoutes';

import {
  StyledBetaWrapper,
  StyledDrawerNew,
  StyledSidebarNew,
  StyledSidebarTooltip,
} from './index.style';

import SidebarImgIcon from '../../Icon';

const MobileSidebar = ({
  openSidebar,
  handleCloseSidebar,
  useHeaderBanner,
  headerVoucher,
  renderSubMenuSupport,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const sidebarBodyRef = useRef(null);
  const { height: heightScreen } = useWindowDimensions();
  const [collapseState, setCollapseState] = useState({});
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const { mobileSidebarRoutes } = useSidebarRoutes();

  const activeRoute = (routePath) =>
    matchPath(location.pathname, { path: routePath, exact: true });

  const createLinks = (aRoutes, props) =>
    aRoutes.map((route) => {
      const hasEnabledRoute = route?.featureKey
        ? getFeatureValue(route.featureKey, {
            userId: user?.id,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          })
        : true;
      if (!hasEnabledRoute) return null;

      const isActiveRoute =
        activeRoute(route.path) ||
        route.subPaths?.some((path) => activeRoute(path));

      if (route.collapse) {
        const collapse = {};
        collapse[route?.labelSidebar] = !collapseState[route?.labelSidebar];

        return (
          <ListItem
            key={route?.labelSidebar}
            className="item"
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
          >
            <NavLink
              to="#"
              className={classNames('item-link', {
                'collapse-active': getCollapseInitialState(route.items),
              })}
              onClick={(e) => {
                e.preventDefault();
                setCollapseState(collapse);
              }}
            >
              {openSidebar ? (
                <>
                  <route.iconSidebar
                    className={classNames('item-icon', {
                      'item-icon-active': isActiveRoute,
                    })}
                  />
                  <ListItemText
                    primary={t(route?.labelSidebar)}
                    secondary={
                      collapseState[route?.labelSidebar] ? (
                        <ArrowDropUp className="caret" />
                      ) : (
                        <ArrowDropDown className="caret" />
                      )
                    }
                    disableTypography
                    className={classNames('item-text', {
                      'item-text-active': isActiveRoute,
                    })}
                  />
                </>
              ) : (
                <StyledSidebarTooltip title={t(route?.labelSidebar)}>
                  <route.iconSidebar className="item-icon" />
                </StyledSidebarTooltip>
              )}
            </NavLink>
            <Collapse in={collapseState[route.state]} unmountOnExit>
              <List className="list collapse-list">
                {createLinks(route.items)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      if (route?.externalUrl) {
        return (
          <ListItem
            key={route?.labelSidebar}
            className="item"
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
          >
            <a
              href={route.path}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames('item-link', {
                'active-link': isActiveRoute,
              })}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              {openSidebar ? (
                <>
                  <route.iconSidebar
                    className={classNames('item-icon', {
                      'item-icon-active': isActiveRoute,
                    })}
                  />
                  <div className="item-wrapper">
                    <ListItemText
                      primary={t(route?.labelSidebar)}
                      disableTypography
                      className={classNames('item-text', {
                        'item-text-active': isActiveRoute,
                      })}
                    />
                    {route.beta && (
                      <StyledBetaWrapper active={isActiveRoute}>
                        <div className="beta-background" />
                        <Typography className="beta-text">Beta</Typography>
                      </StyledBetaWrapper>
                    )}
                    {route.isNewFeature && (
                      <StyledBetaWrapper active={isActiveRoute}>
                        <div className="beta-background" />
                        <Typography className="beta-text">New</Typography>
                      </StyledBetaWrapper>
                    )}
                  </div>
                </>
              ) : (
                <StyledSidebarTooltip title={t(route?.labelSidebar)}>
                  <route.iconSidebar className="item-icon" />
                </StyledSidebarTooltip>
              )}
            </a>
          </ListItem>
        );
      }

      return (
        <ListItem
          key={route?.labelSidebar}
          className="item"
          onMouseEnter={() => setShowScrollbar(true)}
          onMouseLeave={() => setShowScrollbar(false)}
        >
          <NavLink
            to={route.path}
            className={classNames('item-link', {
              'active-link': isActiveRoute,
            })}
            onClick={() => {
              if (props?.mobile) handleCloseSidebar();
            }}
          >
            {openSidebar ? (
              <>
                <route.iconSidebar
                  className={classNames('item-icon', {
                    'item-icon-active': isActiveRoute,
                  })}
                />
                <div className="item-wrapper">
                  <ListItemText
                    primary={t(route?.labelSidebar)}
                    disableTypography
                    className={classNames('item-text', {
                      'item-text-active': isActiveRoute,
                    })}
                  />
                  {route.beta && (
                    <StyledBetaWrapper active={isActiveRoute}>
                      <div className="beta-background" />
                      <Typography className="beta-text">Beta</Typography>
                    </StyledBetaWrapper>
                  )}
                  {route.isNewFeature && (
                    <StyledBetaWrapper active={isActiveRoute}>
                      <Typography
                        className="new-text"
                        sx={{ textTransform: 'none !important' }}
                      >
                        {t('new')}
                      </Typography>
                    </StyledBetaWrapper>
                  )}
                </div>
              </>
            ) : (
              <StyledSidebarTooltip title={t(route?.labelSidebar)}>
                <route.iconSidebar className="item-icon" />
              </StyledSidebarTooltip>
            )}
          </NavLink>
        </ListItem>
      );
    });

  const renderSideBarItems = (props) => (
    <div
      ref={sidebarBodyRef}
      onMouseEnter={() => setShowScrollbar(true)}
      onMouseLeave={() => setShowScrollbar(false)}
      className={`sidebar-wrapper ${
        showScrollbar ? 'small-scrollbar' : 'hidden-scrollbar'
      } `}
    >
      <List className="list">{createLinks(mobileSidebarRoutes, props)}</List>
      <Box marginY={1}>
        <Box
          className="item-link"
          onClick={() => setOpenSubMenu((prev) => !prev)}
        >
          <ContactSupport className="item-icon" />
          <Box className="item-wrapper">
            <Typography className="item-text">{t('support')}</Typography>
            {openSubMenu ? (
              <KeyboardArrowDownRounded className="item-icon" />
            ) : (
              <ChevronRightRounded className="item-icon" />
            )}
          </Box>
        </Box>
      </Box>

      <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
        {renderSubMenuSupport({ props, itemClassName: 'sub-menu-item' })}
      </Collapse>
      <Feature name={FEATURE_FLAG.OTHER_SERVICES}>
        <NavLink
          to={ROUTES.OTHER_SERVICES}
          className={classNames('item-link', {
            'active-link': activeRoute(ROUTES.OTHER_SERVICES),
          })}
          onClick={() => {
            if (props?.mobile) handleCloseSidebar();
          }}
        >
          <SidebarImgIcon
            src={
              activeRoute(ROUTES.OTHER_SERVICES) ? vbeeWhiteIcon : vbeeGrayIcon
            }
            name={t('otherServices')}
          />
        </NavLink>
      </Feature>
    </div>
  );

  useEffect(() => {
    if (openSubMenu) {
      // Scroll to bottom when open sub menu on small screen
      // Delay to scroll to bottom when open sub menu on small screen to wait for the sub menu to be rendered
      setTimeout(() => {
        const sidebarBody = sidebarBodyRef.current;
        sidebarBody.lastElementChild.scrollIntoView({
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [openSubMenu]);

  return (
    <StyledDrawerNew
      sx={{ display: { md: 'none', xs: 'block', sm: 'none' } }}
      variant="temporary"
      anchor="left"
      open={openSidebar}
      onClose={handleCloseSidebar}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <StyledSidebarNew
        useHeaderBanner={useHeaderBanner}
        headerVoucher={headerVoucher}
        heightScreen={heightScreen}
      >
        <div className="mobile package-wrapper-mobile">
          <img
            src={logoAiVoice}
            alt="vbee tts logo"
            className="logo-img"
            width={108}
          />
          <IconButton
            aria-label="close"
            onClick={handleCloseSidebar}
            color="secondary"
            variant="outlined"
          >
            <Close className="close-button" fontSize="small" />
          </IconButton>
        </div>
        {renderSideBarItems({ mobile: true })}
        <div className="wrap-information">
          <NewInformationNote isShowUpgradeButton />
        </div>
      </StyledSidebarNew>
    </StyledDrawerNew>
  );
};

export default MobileSidebar;
