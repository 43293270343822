import React from 'react';
import { Typography } from '@mui/material';
import { checkVietNam } from '@src/utils/checkCountry';
import { StyledVoiceDetail } from './index.style';
import VoiceDetailItem from './VoiceDetailItem';

const VoiceDetail = ({ selectedVoice }) => {
  const isVietNam = checkVietNam();
  return (
    <StyledVoiceDetail>
      <div className="header">
        <Typography variant="subtitle2" className="title">
          {selectedVoice.name} &nbsp;• &nbsp;
          {isVietNam
            ? selectedVoice?.language?.vietnameseName
            : selectedVoice?.language?.globalName}
        </Typography>
      </div>
      <VoiceDetailItem title="gender" value={selectedVoice.gender} />
      <VoiceDetailItem
        title="voiceLevel"
        value={`${selectedVoice?.level?.toLowerCase()}Voice`}
      />
    </StyledVoiceDetail>
  );
};

export default VoiceDetail;
