import React from 'react';
import { useSelector } from 'react-redux';
import DubbingContainer from '@src/containers/Dubbing';
import ComingSoonContainer from '@src/containers/ComingSoon';
import FEATURE_FLAG from '@src/constants/featureFlags.json';

const Dubbing = () => {
  const { featureFlags } = useSelector((state) => state.featureFlag);

  const feature = featureFlags.find(
    (featureFlag) => featureFlag.name === FEATURE_FLAG.DUBBING,
  );
  if (feature && feature.active) return <DubbingContainer />;

  return <ComingSoonContainer />;
};

export default Dubbing;
