import styled from 'styled-components';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledFileDropzone = styled.div`
  .dropzone {
    padding: 32px;
    height: 200px;
    border: 2px dashed
      ${(props) => (props.isError ? COLOR.error : COLOR.primary)};
    background: ${(props) =>
      props.isError ? TRANSPARENT_COLOR.error : TRANSPARENT_COLOR.primary};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
  }

  .hightlight {
    background-color: ${TRANSPARENT_COLOR.primary};
  }

  .file-input {
    display: none;
  }

  .upload-file-title {
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
  }

  .success-file-name {
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .divider {
    :before {
      border-top-color: ${COLOR.light};
      top: 0%;
    }
    :after {
      border-top-color: ${COLOR.light};
      top: 0%;
    }
    color: ${COLOR.lightText};
    text-transform: lowercase;
    min-width: 200px;
    max-width: 300px;
    margin: 5px auto;
    font-size: 14px;
    font-weight: 500;
  }

  .upload-button {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    padding: 4px 10px;
    background: transparent;
    text-transform: none;
    margin-top: 4px;

    &:hover {
      background: ${COLOR.gray};
    }

    &:focus {
      background: ${COLOR.gray};
    }
  }

  .upload-success {
    text-align: center;
  }

  .upload-note {
    color: ${COLOR.lightText};
    margin-top: 4px;
  }

  .done-button {
    margin: 10px 0;
  }

  .other-case {
    display: flex;
    justify-content: center;
  }

  .choose-other-file {
    cursor: pointer;
    padding: 4px 10px;
    color: ${COLOR.darkBlue};
    border: 1px solid ${COLOR.darkBlue};
    border-radius: 8px;
    &:hover {
      background: ${COLOR.gray};
    }

    &:focus {
      background: ${COLOR.gray};
    }
    margin-bottom: 10px;
  }

  .allow-format-file {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .dropzone {
      margin: 20px;
    }
  }
`;
export { StyledFileDropzone };
