import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton } from '@mui/material';
import { USER_GUIDE_DUBBING_URL } from '@src/configs';
import { Close, MenuBook } from '@mui/icons-material';
import { StyledUserGuide } from './index.style';

const UserGuide = ({ onCloseUserGuide }) => {
  const { t } = useTranslation();

  return (
    <StyledUserGuide>
      <Typography variant="subtitle1">{t('sloganUserGuideDubbing')}</Typography>
      <div className="display-flex">
        <Button
          component="a"
          href={USER_GUIDE_DUBBING_URL}
          variant="outlined"
          color="secondary"
          className="user-guide-button"
          target="_blank"
        >
          <MenuBook />
          {t('userGuide')}
        </Button>
        <IconButton color="secondary" onClick={onCloseUserGuide}>
          <Close />
        </IconButton>
      </div>
    </StyledUserGuide>
  );
};

export default UserGuide;
