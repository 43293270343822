import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@mui/material';

import { StyledCreateInvoice } from './index.style';

const CreateInvoice = ({ invoice, error, onChangeInvoice, disableInvoice }) => {
  const { t } = useTranslation();

  return (
    <StyledCreateInvoice>
      <div>
        <Typography>
          {t('companyName')} <span>*</span>
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          fullWidth
          placeholder={t('companyPlaceHolder')}
          className="invoice-input"
          value={invoice.companyName}
          name="companyName"
          onChange={onChangeInvoice}
          size="small"
          inputProps={{ min: 0, max: 10, readOnly: disableInvoice }}
          helperText={t(error.companyName)}
          error={!!error.companyName}
        />
      </div>
      <div>
        <Typography>
          {t('address')} <span>*</span>
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          fullWidth
          placeholder={t('companyAddress')}
          className="invoice-input"
          value={invoice.companyAddress}
          name="companyAddress"
          onChange={onChangeInvoice}
          size="small"
          inputProps={{ min: 0, max: 10, readOnly: disableInvoice }}
          helperText={t(error.companyAddress)}
          error={!!error.companyAddress}
        />
      </div>
      <div>
        <Typography>
          {t('taxNumber')} <span>*</span>
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          fullWidth
          placeholder={t('taxNumber')}
          className="invoice-input"
          value={invoice.companyTax}
          name="companyTax"
          onChange={onChangeInvoice}
          size="small"
          inputProps={{ min: 0, max: 10, readOnly: disableInvoice }}
          helperText={t(error.companyTax)}
          error={!!error.companyTax}
        />
      </div>
      <div>
        <Typography>
          {t('receivedInvoiceEmail')} <span>*</span>
        </Typography>
        <TextField
          variant="outlined"
          type="text"
          fullWidth
          placeholder="Email"
          className="invoice-input"
          value={invoice.companyEmail}
          name="companyEmail"
          onChange={onChangeInvoice}
          size="small"
          inputProps={{ min: 0, max: 10, readOnly: disableInvoice }}
          helperText={t(error.companyEmail)}
          error={!!error.companyEmail}
        />
      </div>
    </StyledCreateInvoice>
  );
};

export default CreateInvoice;
