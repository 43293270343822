import React from 'react';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { StyledZoomActions } from './index.style';

const ZOOM_MAX_VALUE = 10;

const ZoomActions = ({ zoomLevel, setZoomLevel }) => {
  const handleZoom = (newZoomLevel) => {
    // TODO: Scroll by cursor of timeline

    // const currentTime = timelineRef.current.getTime();
    // const scrollLeftPosition = currentTime * (100 / newZoomLevel);
    // timelineRef.current.setScrollLeft(scrollLeftPosition);
    setZoomLevel(newZoomLevel);
  };

  const handleZoomIn = () => {
    const newZoomLevel = Math.max(zoomLevel - 1, 1);
    handleZoom(newZoomLevel);
  };

  const handleZoomOut = () => {
    const newZoomLevel = Math.min(zoomLevel + 1, ZOOM_MAX_VALUE + 1);
    handleZoom(newZoomLevel);
  };

  const progressValue =
    (Math.max(ZOOM_MAX_VALUE - zoomLevel + 1, 0) / ZOOM_MAX_VALUE) * 100;

  return (
    <StyledZoomActions progressValue={progressValue}>
      <IconButton onClick={handleZoomOut}>
        <ZoomOut />
      </IconButton>
      <Box className="zoom-progress-wrapper">
        <Box className="zoom-progress" />
      </Box>
      <IconButton onClick={handleZoomIn}>
        <ZoomIn />
      </IconButton>
    </StyledZoomActions>
  );
};

export default React.memo(ZoomActions);
