import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, Skeleton, Typography } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import Tooltip from '@src/components/Tooltip';
import { COLOR } from '@src/styles/color';

import { StyledSentenceHeader } from './index.style';

const DEFAULT_LANGUAGE = {
  value: 'vi-VN',
  country: 'vi-VN',
  label: 'vietnamese',
  flag: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
};

const SentencesHeader = ({
  isLoading,
  displayingSentences,
  isSelected,
  selected,
  handleSelectAll,
  handleSelectDelete,
  isSmallContent,
  isShowOriginal,
  availableLanguages,
}) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const { openSplitVoices, showOriginalSubtitle, projectInfo } = useSelector(
    (state) => state.dubbingRequest,
  );

  const { getFeatureValue } = useFeatureFlags();

  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const CheckBox = () => {
    const isHasContent = displayingSentences.some(
      (sentence) => Object.keys(sentence).length > 0,
    );
    const isChecked = displayingSentences?.some((item) => isSelected(item.key));
    const isIndeterminate =
      displayingSentences?.some((item) => isSelected(item.key)) &&
      !displayingSentences?.every((item) => isSelected(item.key));

    return (
      <>
        {isLoading && (
          <Skeleton className="checkbox" width="2%" animation="wave" />
        )}
        {!isLoading && isHasContent && (
          <Checkbox
            className="checkbox"
            indeterminate={isIndeterminate}
            checked={isChecked}
            onChange={handleSelectAll}
          />
        )}
      </>
    );
  };

  const SubtitleLanguage = ({ language, title }) => (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      padding="8px 12px"
      width="50%"
    >
      <Tooltip title={t(language.label)} arrow placement="bottom">
        <Box
          borderRadius="50%"
          border={`1px solid ${COLOR.label[16]}`}
          width="24px"
          height="24px"
          display="flex"
          justifyContent="center"
        >
          <img src={language.flag} alt="flag" width={32} height={24} />
        </Box>
      </Tooltip>
      <Typography fontSize="14px" fontWeight="500" color={COLOR.secondary[100]}>
        {t(title)}
      </Typography>
    </Box>
  );

  const HeaderActions = () => (
    <>
      <Button
        size="medium"
        color="error"
        startIcon={<DeleteOutlineOutlined />}
        onClick={() => handleSelectDelete(false)}
        className="delete-button"
      >
        <span>
          {t('deleteSentencesRequests', {
            numRequests: selected.length,
          })}
        </span>
      </Button>
      <Button
        size="medium"
        color="error"
        onClick={() => handleSelectDelete(true)}
        className="delete-button"
      >
        {t('deleteAll')}
      </Button>
    </>
  );

  const HeaderWithMultipleInput = () => (
    <>
      <Typography className="time-range-header">{t('time')}</Typography>
      {isDubbingMultipleVoices && openSplitVoices && (
        <Typography className="voice-and-speed-header">
          {t('onlyVoice')}
        </Typography>
      )}
      <Box display="flex" flex={1}>
        {isShowOriginal && showOriginalSubtitle && (
          <SubtitleLanguage
            language={
              availableLanguages?.find(
                (lang) => lang.value === projectInfo?.originalInfo?.language,
              ) || DEFAULT_LANGUAGE
            }
            title="originalSubtitle"
          />
        )}
        <SubtitleLanguage
          language={DEFAULT_LANGUAGE}
          title="translatedSubtitle"
        />
      </Box>
    </>
  );

  const HeaderColumns = () => (
    <>
      {isLoading && (
        <>
          <div className="time-range">
            <Skeleton className="time-range-header" animation="wave" />
          </div>

          <Skeleton width="100%" animation="wave" />
        </>
      )}

      {!isLoading && !isMultipleInputDubbing && (
        <>
          <Typography className="time-range-header">{t('time')}</Typography>
          {isDubbingMultipleVoices && openSplitVoices && (
            <Typography className="voice-and-speed-header">
              {t('onlyVoice')}
            </Typography>
          )}
          <Typography className="content-header">
            {t('conversation')}
          </Typography>
        </>
      )}

      {!isLoading && isMultipleInputDubbing && <HeaderWithMultipleInput />}
    </>
  );

  return (
    <StyledSentenceHeader isSmallContent={isSmallContent}>
      <CheckBox />
      {selected?.length ? <HeaderActions /> : <HeaderColumns />}
    </StyledSentenceHeader>
  );
};

export default SentencesHeader;
