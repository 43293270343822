import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import { StyledVolume, StyledPopover, StyledSlider } from './index.style';

const VolumeNew = ({
  volume = 100,
  icon,
  disableIcon,
  tooltipTitle,
  max,
  width,
  active,
  disabled,
  onChange,
  handleCloseSearchBoxAndMoreMenu,
  handleCloseSearchBox,
  isMoreButton,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = (e) => {
    if (disabled) return;
    setAnchorEl(e.currentTarget);
    if (isMoreButton) handleCloseSearchBox();
    else handleCloseSearchBoxAndMoreMenu();
  };

  const handleClose = () => setAnchorEl(null);

  const handleChangeVolume = (e, newValue) => {
    if (onChange) onChange(newValue);
  };

  const valueText = (value) => `${value}%`;

  return (
    <StyledVolume
      isEdit={Boolean(anchorEl)}
      active={active}
      disabled={disabled}
      width={width}
    >
      <Tooltip arrow title={t(tooltipTitle)} placement="top">
        <IconButton
          className="custom-button"
          color="iconPrimary"
          onClick={handleClick}
        >
          {typeof icon !== 'string' && icon}
          {typeof icon === 'string' && disabled && (
            <img src={disableIcon} alt="audio-format" className="button-img" />
          )}
          {typeof icon === 'string' && !disabled && (
            <img src={icon} alt="audio-format" className="button-img" />
          )}
        </IconButton>
      </Tooltip>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box className="volume-wrapper">
          <StyledSlider
            size="small"
            aria-label="Volume"
            color="primary"
            valueLabelDisplay="on"
            value={volume}
            min={0}
            max={max}
            valueLabelFormat={valueText}
            onChange={handleChangeVolume}
          />
        </Box>
      </StyledPopover>
    </StyledVolume>
  );
};

export default VolumeNew;
