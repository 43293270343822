import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import logo from '@src/assets/logo/logo.svg';
import newLogo from '@src/assets/logo/logo-AiVoice-new.svg';
import { StyledDialog } from './index.style';

const ServiceTerm = ({ open }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { getFeatureValue } = useFeatureFlags();
  const useNewLegal = getFeatureValue(FEATURE_KEYS.NEW_LEGAL, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const handleAgreedToTerm = async () => {
    if (!isChecked) {
      setShowNote(true);
      return;
    }
    setShowNote(false);
    await apis.user.agreeToTerms();
    setAgreedToTerms(true);
    dispatch(actions.auth.agreedToTerms());
  };

  return (
    <StyledDialog useNewLegal={useNewLegal} open={open && !agreedToTerms}>
      <div className="dialog-wrapper">
        <div className="logo">
          <img
            src={useNewLegal ? newLogo : logo}
            alt="vbee tts logo"
            className="logo-img"
          />
        </div>
        <Typography
          className={useNewLegal ? 'new-dialog-title' : 'dialog-title'}
        >
          {useNewLegal ? t('serviceTermTitleNew') : t('serviceTermTitle')}
        </Typography>
        <div
          className="dialog-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: useNewLegal
              ? t('serviceTermContentNew')
              : t('serviceTermContent'),
          }}
        />
        <div className="dialog-checkbox">
          <Checkbox
            className="checkbox"
            checked={isChecked}
            onChange={() => {
              setShowNote(isChecked);
              setIsChecked(!isChecked);
            }}
          />
          <div>
            <div
              className="text-checkbox-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: useNewLegal
                  ? t('agreeToTermContentNew')
                  : t('agreeToTermsContent'),
              }}
            />
            <Typography
              className={`${
                showNote
                  ? 'show-text-checkbox-note'
                  : 'not-show-text-checkbox-note'
              }`}
            >
              {useNewLegal ? t('agreeToTermsNoteNew') : t('agreeToTermsNote')}
            </Typography>
          </div>
        </div>
        <div className="dialog-action">
          <Button
            className="button-agree-policy"
            useNewLegal={useNewLegal}
            variant="contained"
            color="primary"
            onClick={handleAgreedToTerm}
          >
            {t('agreeTerm')}
          </Button>
        </div>
      </div>
    </StyledDialog>
  );
};

export default ServiceTerm;
