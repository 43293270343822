import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookie, setCookie } from '@src/utils/cookie';

import { Typography } from '@mui/material';
import { VBEE_DOMAIN } from '@src/configs';
import { TIME_STORE_TRY_LISTEN_NOTE } from '@src/constants/device';
import { StyledTryListenNote } from './index.style';

const TryListenNote = () => {
  const { t } = useTranslation();
  const hasReadTryListenNote = getCookie('hasReadTryListenNote');
  const [openTryListenNote, setOpenTryListenNote] = useState(
    !hasReadTryListenNote,
  );

  const handleSkipNote = () => {
    setCookie({
      cname: 'hasReadTryListenNote',
      cvalue: true,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_TRY_LISTEN_NOTE,
    });
    setOpenTryListenNote(false);
  };

  if (openTryListenNote)
    return (
      <StyledTryListenNote>
        <Typography className="description">{t('tryListenNote')}</Typography>
        <Typography className="ignore-text" onClick={handleSkipNote}>
          {t('skip')}
        </Typography>
      </StyledTryListenNote>
    );

  return null;
};

export default TryListenNote;
