import { all, put, takeLatest } from 'redux-saga/effects';
import { HAVE_NO_ORDER, ORDER_STATUS } from '@src/constants/order';
import { PACKAGE_STATUS, PACKAGE_TYPE } from '@src/constants/package';
import apis from '@src/apis';
import actions from '../actions';

function* getUsingPackageSaga({ userId }) {
  if (!userId) return;
  try {
    const { result: dubbingResult } = yield apis.orders.getOrders({
      userId,
      packageStatus: PACKAGE_STATUS.USING,
      type: PACKAGE_TYPE.DUBBING,
      limit: 1,
    });

    const dubbingPackage = dubbingResult?.orders[0]?.package;
    yield put(actions.user.getUsingDubbingPackageSuccess(dubbingPackage));
  } catch (error) {
    yield put(actions.user.getUsingPackageFailure());
  }
}

function* getLatestOrderSaga({ userId }) {
  try {
    const { status, result } = yield apis.orders.getOrders({
      userId,
      type: PACKAGE_TYPE.STUDIO,
      sort: 'createdAt_desc',
      limit: 1,
    });
    if (!status) throw new Error();
    const latestOrder = result.orders[0] || { status: HAVE_NO_ORDER };
    yield put(actions.user.getLatestOrderSuccess(latestOrder));

    if (latestOrder.status === ORDER_STATUS.PENDING && latestOrder.voucher)
      yield put(actions.voucher.applyVoucher(latestOrder.voucher));
  } catch (error) {
    yield put(actions.user.getLatestOrderFailure());
  }
}

function* getLatestDubbingOrderSaga({ userId }) {
  try {
    const { status, result } = yield apis.orders.getOrders({
      userId,
      type: PACKAGE_TYPE.DUBBING,
      sort: 'createdAt_desc',
      limit: 1,
    });
    if (!status) throw new Error();
    const latestOrder = result.orders[0] || { status: HAVE_NO_ORDER };
    yield put(actions.user.getLatestDubbingOrderSuccess(latestOrder));

    if (latestOrder.status === ORDER_STATUS.PENDING && latestOrder.voucher)
      yield put(actions.voucher.applyVoucher(latestOrder.voucher));
  } catch (error) {
    yield put(actions.user.getLatestDubbingOrderFailure());
  }
}

function* getLastExpiredOrderSaga() {
  try {
    const { status, result } = yield apis.orders.getLastExpiredOrder();
    if (!status) throw new Error();
    yield put(actions.user.getLastExpiredOrderSuccess(result));
  } catch (error) {
    yield put(actions.user.getLatestOrderFailure());
  }
}

function* getTtsUserSaga() {
  try {
    const { status, result } = yield apis.user.getTtsUser();
    if (!status) throw new Error();
    yield put(actions.user.getTtsUserSuccess(result));
    const { packageCode } = result;
    const { status: getUsingPackageStatus, result: currPackage } =
      yield apis.packages.getPackages({ code: packageCode });

    if (!getUsingPackageStatus || !currPackage?.packages?.length)
      throw new Error();
    yield put(actions.user.getUsingPackageSuccess(currPackage.packages[0]));
  } catch (error) {
    yield put(actions.user.getTtsUserFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.user.actionTypes.GET_USING_PACKAGE, getUsingPackageSaga),
    takeLatest(actions.user.actionTypes.GET_LATEST_ORDER, getLatestOrderSaga),
    takeLatest(
      actions.user.actionTypes.GET_LATEST_DUBBING_ORDER,
      getLatestDubbingOrderSaga,
    ),
    takeLatest(
      actions.user.actionTypes.GET_LAST_EXPIRED_ORDER,
      getLastExpiredOrderSaga,
    ),
    takeLatest(actions.user.actionTypes.GET_TTS_USER, getTtsUserSaga),
  ]);
}
