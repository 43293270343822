import React from 'react';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import FileSelected from './FileSelected';
import { StyledSuccessDropzoneContent } from './index.style';
import LanguageSelection from '../LanguageSelection';

const SuccessDropzoneContent = ({
  fileName,
  handleResetFile,
  loadingHandleFile,
  youtubeVideoMetadata,
  languages,
  originalLanguage,
  setOriginalLanguage,
  targetLanguage,
  setTargetLanguage,
  languageError,
  setLanguageError,
}) => {
  const thumbnail = youtubeVideoMetadata?.thumbnails?.default?.url;

  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const OldSuccessDropzone = () => (
    <StyledSuccessDropzoneContent>
      <FileSelected
        fileName={fileName}
        handleResetFile={handleResetFile}
        isLoading={loadingHandleFile}
      />
    </StyledSuccessDropzoneContent>
  );

  const MultipleInputSuccessDropzone = () => (
    <StyledSuccessDropzoneContent
      sx={{ gap: '24px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <FileSelected
        fileName={fileName}
        handleResetFile={handleResetFile}
        isLoading={loadingHandleFile}
        multipleInputDubbing
        youtubeThumbnail={thumbnail}
        isYoutubeVideo={Object.keys(youtubeVideoMetadata || {}).length > 0}
      />
      <LanguageSelection
        isSrtFile={fileName?.split('.').pop() === 'srt'}
        languages={languages}
        originalLanguage={originalLanguage}
        setOriginalLanguage={setOriginalLanguage}
        targetLanguage={targetLanguage}
        setTargetLanguage={setTargetLanguage}
        languageError={languageError}
        setLanguageError={setLanguageError}
      />
    </StyledSuccessDropzoneContent>
  );

  return isMultipleInputDubbing ? (
    <MultipleInputSuccessDropzone />
  ) : (
    <OldSuccessDropzone />
  );
};

export default SuccessDropzoneContent;
