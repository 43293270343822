/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import actions from '@src/redux/actions';
import { formatAudioTime } from '@src/utils/time';

import {
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import {
  PlayArrowRounded,
  PauseOutlined,
  FileDownloadOutlined,
  Forward5Rounded,
  Replay5Rounded,
} from '@mui/icons-material';

import { SILENCE_AUDIO_URL, TTS_VERSION } from '@src/constants/tts';
import { getNextStreamAudio } from '@src/utils/streamAudio';
import AudioPlayer from './AudioPlayer';
import { StyledTTSDemo } from './index.style';

const REWIND_TIME_IN_SECOND = 5;

const initialNextStreamAudio = {
  index: 0,
  subIndex: 0,
  phraseIndex: 0,
};

const TTSDemo = ({ audioRef }) => {
  const {
    audioLink,
    isPlaying,
    currentTime,
    duration,
    isAudioLoading,
    isPreview,
    streamAudios,
    isRunStreamAudio,
    ttsVersion,
    finalStreamAudio,
  } = useSelector((state) => state.audioPlayer);

  const [audioEnd, setAudioEnd] = useState(false);
  const [nextStreamAudio, setNextStreamAudio] = useState(
    initialNextStreamAudio,
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleAudioTimeUpdate = () => {
    const url = audioRef.current.src ? new URL(audioRef.current.src) : '';
    const time =
      url?.pathname === SILENCE_AUDIO_URL ? 0 : audioRef.current.currentTime;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleLoadedMetadata = () => {
    const audioDuration = audioRef.current.duration;
    dispatch(actions.audioPlayer.updateMetaData({ duration: audioDuration }));
  };

  const handleAudioEnd = () => {
    if (audioEnd) {
      audioRef.current.pause();
      dispatch(actions.audioPlayer.updateStatus(false));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      setAudioEnd(false);
      return;
    }

    if (streamAudios?.length && !!nextStreamAudio) {
      const nextAudio = streamAudios.find(
        (streamAudio) =>
          streamAudio.index === nextStreamAudio.index &&
          streamAudio.subIndex === nextStreamAudio.subIndex,
      );

      if (nextAudio) {
        if (ttsVersion === TTS_VERSION.EMPHASIS) {
          const { phraseIndex = 0 } = nextStreamAudio;
          const { audioLink: streamAudioLink } = nextAudio.phrases[phraseIndex];
          audioRef.current.src = streamAudioLink;
        } else {
          audioRef.current.src = nextAudio.audioLink;
        }
        audioRef.current.load();
        audioRef.current.play();
        const nextAudioToPlay = getNextStreamAudio({
          currStreamAudio: nextStreamAudio,
          tts: nextAudio.tts,
          lastPhraseIndex: nextAudio.phrases?.length - 1,
        });

        setNextStreamAudio(nextAudioToPlay);
        return;
      }
    }

    if (audioLink) {
      setAudioEnd(true);
      audioRef.current.src = audioLink;
      if (!finalStreamAudio && isRunStreamAudio) {
        audioRef.current.load();
        audioRef.current.play();
        return;
      }
      audioRef.current.pause();
      dispatch(actions.audioPlayer.updateStatus(false));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      return;
    }

    if (
      !nextStreamAudio &&
      isRunStreamAudio &&
      finalStreamAudio &&
      finalStreamAudio !== audioRef.current.src
    ) {
      dispatch(actions.audioPlayer.updateStatusStreamAudio(false));
      dispatch(actions.audioPlayer.updateAudioLink(finalStreamAudio, true));
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const handleSeeked = (time) => {
    audioRef.current.currentTime = time;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleChangePlayAudio = () => {
    dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    setAudioEnd(true);
  };

  const isShowLoading = isPlaying && !duration;
  const isShowPlaying = isPlaying && !!duration;

  useEffect(() => {
    audioRef.current.load();
    audioRef.current.currentTime = currentTime;

    if ((audioLink && isPlaying) || isRunStreamAudio) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }

    if (isPlaying && isAudioLoading) {
      dispatch(
        actions.audioPlayer.updateTryListeningSentence({
          sentenceId: '',
          isAudioLoading: false,
        }),
      );
    }
  }, [audioLink, isPlaying, isRunStreamAudio]);

  useEffect(() => {
    if (audioLink) {
      audioRef.current.src = audioLink;
      dispatch(actions.audioPlayer.updateStatusStreamAudio(false));
      setNextStreamAudio(null);
    }
    setAudioEnd(false);
  }, [audioLink]);

  useEffect(() => {
    if (isRunStreamAudio) setNextStreamAudio(initialNextStreamAudio);
  }, [isRunStreamAudio]);

  return (
    <StyledTTSDemo isPreview={isPreview}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        autoPlay={isPlaying}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleAudioTimeUpdate}
        onEnded={handleAudioEnd}
      >
        <source src={audioLink} type="audio/ogg" />
      </audio>
      <div className="action-wrapper">
        <div className="time-line">
          <Typography className="current-time">
            {formatAudioTime(isRunStreamAudio ? 0 : currentTime)}
          </Typography>
          <Typography> / </Typography>
          <Typography className="duration">
            {formatAudioTime(isRunStreamAudio ? 0 : duration)}
          </Typography>
        </div>
        <div className="audio-action align-center">
          <Tooltip
            arrow
            title={t('rewind', { second: REWIND_TIME_IN_SECOND })}
            placement="bottom"
          >
            <IconButton
              onClick={() => handleSeeked(currentTime - REWIND_TIME_IN_SECOND)}
              disabled={currentTime === 0 || !audioLink || isRunStreamAudio}
              className="rewind-button"
            >
              <Replay5Rounded />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={handleChangePlayAudio}
            disabled={!audioLink || isRunStreamAudio}
            className="audio-action-button"
          >
            {isShowLoading && !isRunStreamAudio && (
              <CircularProgress size={15} thickness={8} className="loading" />
            )}
            {isShowPlaying && !isRunStreamAudio && (
              <Tooltip arrow title={t('pause')} placement="bottom">
                <PauseOutlined fontSize="large" />
              </Tooltip>
            )}
            {((!isShowLoading && !isShowPlaying) || isRunStreamAudio) && (
              <Tooltip arrow title={t('playAudioGuide')} placement="bottom">
                <PlayArrowRounded fontSize="large" />
              </Tooltip>
            )}
          </IconButton>
          <Tooltip
            arrow
            title={t('forward', { second: REWIND_TIME_IN_SECOND })}
            placement="bottom"
          >
            <IconButton
              onClick={() => handleSeeked(currentTime + REWIND_TIME_IN_SECOND)}
              disabled={
                currentTime > duration || !audioLink || isRunStreamAudio
              }
              className="forward-button"
            >
              <Forward5Rounded />
            </IconButton>
          </Tooltip>
        </div>
        <div className="extra-action align-right">
          <Tooltip arrow title={t('downloadGuide')} placement="bottom">
            <IconButton disabled={!audioLink || isPreview || isRunStreamAudio}>
              <a href={audioLink} download className="download-link">
                <FileDownloadOutlined />
              </a>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="audio-player">
        <AudioPlayer
          currentTime={isRunStreamAudio ? 0 : currentTime}
          duration={isRunStreamAudio ? 0 : duration}
          onSeeked={handleSeeked}
        />
      </div>
    </StyledTTSDemo>
  );
};

export default TTSDemo;
