import { SelectionState } from 'draft-js';
import { validateNumber } from '@src/utils/number';
import { TTS_ATTRIBUTE } from '@src/constants/voice';

const findItemKeyInMap = (object, value) => {
  let out;
  Object.keys(object).forEach((key) => {
    if (object[key] === value) out = key;
  });
  return out;
};

const getEntityStrategy =
  (mutability) => (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return contentState.getEntity(entityKey).getMutability() === mutability;
    }, callback);
  };

const getStartIndicate = (text, start, elements = []) => {
  if (start === 0) return start;

  let startIndicate = 0;
  const spacingLetter = new RegExp(/\s/);

  let position = start;
  while (position >= 0) {
    if (spacingLetter.test(text.charAt(position))) {
      startIndicate = position;
      position = -1;
      break;
    }

    const hasStartBreakTimeElement = elements.find(
      // eslint-disable-next-line no-loop-func
      (item) =>
        item.name === TTS_ATTRIBUTE.BREAK_TIME && item.endOffset === position,
    );
    if (hasStartBreakTimeElement) {
      startIndicate = position;
      position = -1;
      break;
    }

    if (spacingLetter.test(text.charAt(position - 1))) {
      startIndicate = position;
      position = -1;
      break;
    }
    position -= 1;
  }

  return startIndicate;
};

const getEndIndicate = (text, end, elements = []) => {
  if (end >= text.length) return text.length;

  let endIndicate = text.length;
  const spacingLetter = new RegExp(/\s/);

  let position = end;
  while (position <= text.length) {
    if (spacingLetter.test(text.charAt(position))) {
      endIndicate = position;
      position = text.length;
      break;
    }

    const hasStartBreakTimeElement = elements.find(
      // eslint-disable-next-line no-loop-func
      (item) =>
        item.name === TTS_ATTRIBUTE.BREAK_TIME && item.startOffset === position,
    );
    if (hasStartBreakTimeElement) {
      endIndicate = position;
      position = text.length;
      break;
    }

    if (spacingLetter.test(text.charAt(position))) {
      endIndicate = position;
      position = text.length;
      break;
    }
    position += 1;
  }

  return endIndicate;
};

const handleSelection = ({
  selectionState,
  contentState,
  paragraphs = [],
  elements = [],
}) => {
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();

  let startElements = [];
  let endElements = [];
  if (elements?.length) {
    startElements = elements;
    endElements = elements;
  } else {
    const startParagraph = paragraphs.find((item) => item.key === startKey);
    const endParagraph = paragraphs.find((item) => item.key === endKey);
    startElements = startParagraph?.elements || [];
    endElements = endParagraph?.elements || [];
  }

  if (start !== end || startKey !== endKey) {
    const startIndicate = getStartIndicate(
      contentState.getBlockForKey(startKey).getText(),
      start,
      startElements,
    );
    const endIndicate = getEndIndicate(
      contentState.getBlockForKey(endKey).getText(),
      end,
      endElements,
    );
    const newSelectionState = new SelectionState({
      anchorKey: startKey,
      anchorOffset: startIndicate,
      focusKey: endKey,
      focusOffset: endIndicate,
    });
    return newSelectionState;
  }
  return null;
};

const checkSyntaxTagsElements = (elements) => {
  let detailError = '';
  const invalidSyntax = elements.some((element) => {
    const { name, value, text } = element;
    switch (name) {
      case TTS_ATTRIBUTE.BREAK_TIME: {
        const breakTimeValue = text.slice(0, text.length - 1);
        const breakTimeUnit = text.slice(text.length - 1);

        const invalidBreakTime =
          !validateNumber(breakTimeValue) ||
          Number(breakTimeValue) !== Number(value) ||
          breakTimeUnit !== 's' ||
          value < 0.1 ||
          value > 60;

        detailError = 'errorInvalidBreakTimeDesc';
        return invalidBreakTime;
      }

      case TTS_ATTRIBUTE.SPEED: {
        const invalidSpeed = value < 0.1 || value > 2;

        detailError = 'errorInvalidSpeedDesc';
        return invalidSpeed;
      }

      default:
        return false;
    }
  });

  return { error: invalidSyntax, detailError };
};

const convertElementToText = (element) => {
  const { text, name } = element;
  let { value } = element;
  let textElement = '';
  switch (name) {
    case TTS_ATTRIBUTE.EMPHASIS:
      textElement = `<emphasis level="${value}">${text}</emphasis>`;
      break;
    case TTS_ATTRIBUTE.BREAK_TIME: {
      textElement = `<break time="${value}s"/>`;
      break;
    }
    case TTS_ATTRIBUTE.SPEED: {
      if (value > 1.9) value = 1.9;
      if (value < 0.1) value = 0.1;
      const speedPercent = Math.round(value * 100);
      textElement = `<prosody rate="${speedPercent}%">${text}</prosody>`;
      break;
    }
    default:
      textElement = text;
      break;
  }
  return textElement;
};

const convertElementsToText = (elements) =>
  elements.reduce((prev, element) => {
    const textElement = convertElementToText(element);
    return `${prev}${textElement}`;
  }, '');

export {
  findItemKeyInMap,
  getEntityStrategy,
  handleSelection,
  checkSyntaxTagsElements,
  convertElementsToText,
};
