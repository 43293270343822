import React, { useState } from 'react';
import moment from 'moment';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { vi, enGB } from 'date-fns/locale';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { EventNoteOutlined } from '@mui/icons-material';
import { LANGUAGE } from '@src/constants';
import i18n from '@src/languages';
import { useTranslation } from 'react-i18next';
import {
  CustomDateRangePickerDay,
  StyledCustomDatePickerRange,
} from './index.style';
import CustomAdapter from './CustomDateAdapter';

const CustomDatePickerRange = ({
  value = [null, null],
  showNewUI = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);

  const renderWeekPickerDay = (date, dateRangePickerDayProps) => (
    <CustomDateRangePickerDay {...dateRangePickerDayProps} />
  );

  const handleChange = (newValue) => onChange && onChange(newValue);

  const renderDate = (dates) => {
    const startDate = dates[0] ? moment(dates[0]).format('DD/MM/YYYY') : null;
    const endDate = dates[1] ? moment(dates[1]).format('DD/MM/YYYY') : null;

    if (startDate && !endDate) return `${startDate} - ${t('end')}`;
    if (!startDate && endDate) return `${t('end')} - ${endDate}`;
    if (startDate && endDate) return `${startDate} - ${endDate}`;
    return '';
  };

  const handleOpenDateRangePicker = () => setOpenDateRangePicker(true);
  const handleClose = () => setOpenDateRangePicker(false);

  return (
    <StyledCustomDatePickerRange showNewUI={showNewUI}>
      <LocalizationProvider
        dateAdapter={CustomAdapter}
        localeText={{ start: t('start'), end: t('end') }}
        locale={language === LANGUAGE.VN ? vi : enGB}
      >
        <DateRangePicker
          open={openDateRangePicker}
          calendars={1}
          value={value}
          onChange={handleChange}
          onClose={handleClose}
          endText={t('end')}
          startText={t('start')}
          toolbarTitle={t('selectDateRange')}
          renderDay={renderWeekPickerDay}
          renderInput={(startProps) => (
            <>
              <TextField
                ref={startProps.inputRef}
                {...startProps.inputProps}
                size="small"
                className="text-field"
                value={renderDate(value)}
                onClick={handleOpenDateRangePicker}
                variant="outlined"
                placeholder={`${t('start')} - ${t('end')}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={handleOpenDateRangePicker}
                      >
                        <EventNoteOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </>
          )}
        />
      </LocalizationProvider>
    </StyledCustomDatePickerRange>
  );
};
export default CustomDatePickerRange;
