import React, { useState } from 'react';
import { Box, ClickAwayListener, IconButton, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import logoAiVoice from '@src/assets/logo/logo-AiVoice-new.svg';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useSelector } from 'react-redux';
import { StyledNewAppBar } from './index.style';
import UserInfo from './SidebarNew/UserInfo';
import Logo from './SidebarNew/Logo';
import MobileProfileAction from './SidebarNew/MobileProfileAction';

const NewNavbar = ({ handleOpenSidebar, location }) => {
  const [isProfileActionOpen, setProfileActionOpen] = useState(false);
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);

  const useNewHeaderMobile = getFeatureValue(FEATURE_KEYS.NEW_HEADER_MOBILE, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const handleClickProfile = () => {
    setProfileActionOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    if (isProfileActionOpen) {
      setProfileActionOpen(false);
    }
  };

  if (useNewHeaderMobile)
    return (
      <StyledNewAppBar>
        <Toolbar className="container">
          <IconButton color="secondary" onClick={handleOpenSidebar}>
            <MenuIcon />
          </IconButton>
          <img
            src={logoAiVoice}
            alt="vbee tts logo"
            className="logo-img"
            height={34}
          />
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <Box onClick={handleClickProfile}>
                <UserInfo ipLocation={location} isMobile />
              </Box>
              {isProfileActionOpen && (
                <MobileProfileAction
                  onShowProfile={handleClickProfile}
                  location={location}
                />
              )}
            </div>
          </ClickAwayListener>
        </Toolbar>
      </StyledNewAppBar>
    );

  return (
    <>
      <StyledNewAppBar>
        <Toolbar className="container">
          <IconButton color="secondary" onClick={handleOpenSidebar}>
            <MenuIcon />
          </IconButton>
          <Logo height={32} isMobile />
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <Box onClick={handleClickProfile}>
                <UserInfo ipLocation={location} isMobile />
              </Box>
              {isProfileActionOpen && (
                <MobileProfileAction location={location} />
              )}
            </div>
          </ClickAwayListener>
        </Toolbar>
      </StyledNewAppBar>
    </>
  );
};

export default NewNavbar;
