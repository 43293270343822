import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledStep = styled('div')``;

const StyledSkip = styled('div')`
  .button-back {
    color: ${COLOR.backTour};
    border: 1px solid ${COLOR.light};
    border-radius: 6px;
    cursor: pointer;
    padding: 8px 12px;
  }
`;

const StyledNext = styled('div')`
  .button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 78px;
    min-height: 32px;
  }
  .title-next {
    font-size: 14px;
    font-weight: 500;
  }
`;

const StyledBack = styled('div')`
  .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 78px;
    min-height: 32px;
  }
  .title-back {
    font-size: 14px;
    font-weight: 500;
  }
`;

const StyledTooltipBody = styled('div')`
  background-color: ${COLOR.white};
  border-radius: 12px;
  padding: 16px;
  width: 352px;
`;

const StyledTooltipTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLOR.indigo.default};
  align-text: left;
  // padding: 9px 0px 9px 12px;
  font-size: 16px;

  .MuiTypography-root {
    font-weight: 700;
  }

  .button-close {
    color: ${COLOR.white};
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
`;

const StyledTooltipContent = styled('div')`
  align-text: left;
  // padding: 0px 12px;
  margin: 16px 0;
  color: ${COLOR.indigo[64]};
  .text-content {
    font-size: 14px;
  }
  .grid-status {
    margin: 6px 0px;
  }
`;

const StepButton = styled('button')`
  border: none;
  background-color: ${COLOR.white};
  color: ${COLOR.backTour};
  padding: 0px;
  cursor: pointer;
`;

const NextButton = styled('button')`
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${COLOR.yellow.default};
  color: ${COLOR.indigo.default};
  font-weight: 500;
  // padding: 8px 12px;
`;

const BackButton = styled('button')`
  border-radius: 8px;
  cursor: pointer;
  // padding: 8px 12px;
  border: 1px solid ${COLOR.indigo.default};
  background-color: ${COLOR.white};
`;

const SkipButton = styled('button')`
  color: ${COLOR.backTour};
  border: none;
  background-color: ${COLOR.white};
  margin-right: 12px;
  cursor: pointer;
`;

const CloseButton = styled('button')`
  color: ${COLOR.indigo[64]};
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const StyledTooltipFooter = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons-right {
    display: flex;
    algin-items: center;
    gap: 8px;
  }
`;

const StyledDotsStep = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${COLOR.indigo[8]};
    transition: background-color 0.3s;
  }

  .dot.active {
    background-color: ${COLOR.indigo[100]};
  }
`;

export {
  StyledStep,
  StyledSkip,
  StyledNext,
  StyledBack,
  StyledTooltipBody,
  StyledTooltipTitle,
  StyledTooltipContent,
  StepButton,
  NextButton,
  SkipButton,
  BackButton,
  StyledTooltipFooter,
  CloseButton,
  StyledDotsStep,
};
