/* eslint-disable func-names */
function getAudioStream() {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      const getUserMedia =
        navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser'),
        );
      }
      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }

  const params = { audio: true, video: false };

  return navigator.mediaDevices.getUserMedia(params);
}

function downSampleBuffer(buffer, originalSampleRate, exportSampleRate) {
  if (exportSampleRate === originalSampleRate) {
    return buffer;
  }

  const sampleRateRatio = originalSampleRate / exportSampleRate;
  const newLength = Math.round(buffer.length / sampleRateRatio);
  const result = new Float32Array(newLength);

  let offsetResult = 0;
  let offsetBuffer = 0;

  while (offsetResult < result.length) {
    const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0;
    let count = 0;
    for (
      let i = offsetBuffer;
      i < nextOffsetBuffer && i < buffer.length;
      i += 1
    ) {
      accum += buffer[i];
      count += 1;
    }
    result[offsetResult] = accum / count;
    offsetResult += 1;
    offsetBuffer = nextOffsetBuffer;
  }
  return result;
}

function floatTo16BitPCM(output, offset, input) {
  // eslint-disable-next-line no-param-reassign
  for (let i = 0; i < input.length; i += 1, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}

function writeString(view, offset, string) {
  for (let i = 0; i < string.length; i += 1) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function encodeWAV(samples) {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  writeString(view, 0, 'RIFF');
  view.setUint32(4, 32 + samples.length * 2, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, 1, true);
  view.setUint32(24, 16000, true);
  view.setUint32(28, 16000 * 2, true);
  view.setUint16(32, 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, 'data');
  view.setUint32(40, samples.length * 2, true);
  floatTo16BitPCM(view, 44, samples);

  return view;
}

function exportBuffer(recBuffer, originalSampleRate) {
  const downsSampledBuffer = downSampleBuffer(
    recBuffer,
    originalSampleRate,
    16000,
  );
  const encodedWav = encodeWAV(downsSampledBuffer);
  const audioBlob = new Blob([encodedWav], { type: 'audio/wav' });
  return audioBlob;
}

function decodeWAV(rawWav) {
  if (typeof rawWav === 'string') {
    // eslint-disable-next-line no-param-reassign
    rawWav = Buffer.from(rawWav, 'binary');
  }
  if (!Buffer.isBuffer(rawWav)) {
    throw new TypeError('pcm data must be Buffer or string');
  }
  // remove the header of pcm format
  // eslint-disable-next-line no-param-reassign
  rawWav = rawWav.subarray(44);
  return rawWav;
}

function toArrayBuffer(buffer) {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
}

function toBuffer(arrayBuffer) {
  const buffer = Buffer.alloc(arrayBuffer.byteLength);
  const view = new Uint8Array(arrayBuffer);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < buffer.length; ++i) {
    buffer[i] = view[i];
  }
  return buffer;
}

function blobToBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

function concatenateBlobs(blobs, type, callback) {
  const buffers = [];

  let index = 0;

  // eslint-disable-next-line consistent-return
  function readAsArrayBuffer() {
    if (!blobs[index]) {
      // eslint-disable-next-line no-use-before-define
      return concatenateBuffers();
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      buffers.push(event.target.result);
      // eslint-disable-next-line no-plusplus
      index++;
      readAsArrayBuffer();
    };
    reader.readAsArrayBuffer(blobs[index]);
  }

  readAsArrayBuffer();

  function concatenateBuffers() {
    let byteLength = 0;
    buffers.forEach((buffer) => {
      byteLength += buffer.byteLength;
    });

    const tmp = new Uint16Array(byteLength);
    let lastOffset = 0;
    buffers.forEach((buffer) => {
      // BYTES_PER_ELEMENT == 2 for Uint16Array
      const reusableByteLength = buffer.byteLength;
      if (reusableByteLength % 2 !== 0) {
        // eslint-disable-next-line no-param-reassign
        buffer = buffer.slice(0, reusableByteLength - 1);
      }
      tmp.set(new Uint16Array(buffer), lastOffset);
      lastOffset += reusableByteLength;
    });

    const blob = new Blob([tmp.buffer], {
      type,
    });

    callback(blob);
  }
}

function concatArrayBuffer(arrayBuffer1, arrayBuffer2) {
  if (!arrayBuffer1) {
    return arrayBuffer2;
  }
  if (!arrayBuffer2) {
    return arrayBuffer1;
  }
  const tmp = new Uint8Array(arrayBuffer1.byteLength + arrayBuffer2.byteLength);
  tmp.set(new Uint8Array(arrayBuffer1), 0);
  tmp.set(new Uint8Array(arrayBuffer2), arrayBuffer1.byteLength);
  return tmp.buffer;
}

export {
  getAudioStream,
  exportBuffer,
  concatenateBlobs,
  blobToBuffer,
  encodeWAV,
  decodeWAV,
  concatArrayBuffer,
  toArrayBuffer,
  toBuffer,
};
