import { Box, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BREAKPOINTS } from '@src/styles/config';

export const StyledMenuVoice = styled(Box)`
  .open-menu-voice {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    border-radius: 12px;

    &:hover,
    &:focus {
      background: ${COLOR.secondary[80]};
    }
  }

  .name-voice-select {
    line-height: 24px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
  }

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    .open-menu-voice {
      padding: 4px 6px;
    }

    .name-voice-select {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    .open-menu-voice {
      padding: 4px 4px 4px 0;
    }

    .name-voice-select {
      margin-left: 3px;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-beetwen;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;

  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }

  .button-try-listen {
    display: flex;
    align-items: center;
  }

  .voice-name {
    display: flex;
    align-items: center;
    margin-left: 5px;
    gap: 10px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledMenu = styled('div')`
  margin: 8px;
`;
