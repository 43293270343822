import React from 'react';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

import TTSContainer from '@src/containers/TTS';
import NewTTSContainer from '@src/containers/TTSNew';
import { useSelector } from 'react-redux';

const TTS = () => {
  const user = useSelector((state) => state.auth.user);
  const { getFeatureValue } = useFeatureFlags();
  const showNewTTS = getFeatureValue(FEATURE_KEYS.NEW_EDITOR);

  const showNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });

  if (showNewTTS && showNewTtsUI) return <NewTTSContainer />;
  return <TTSContainer />;
};
export default TTS;
