import { CompositeDecorator } from 'draft-js';

import { getEntityStrategy } from '@src/services/entity';

import HightLightSpan from './HightLightSpan';

const Decorator = new CompositeDecorator([
  {
    strategy: getEntityStrategy('MUTABLE'),
    component: HightLightSpan,
  },
]);

export default Decorator;
