import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import MobilePage from '@src/assets/images/wave.png';

const StyledMobilePage = styled('div')`
  background-image: url(${MobilePage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  padding: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 70px;
  align-items: center;

  .tag {
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: ${COLOR.darkBlue};
  }

  .title {
    font-weight: bold;
    font-size: 40px;
  }

  .content {
    padding: 16px 0;
  }

  .description {
    padding-bottom: 0;
    padding-top: 0;
  }

  .icon {
    color: ${COLOR.darkBlue};
    width: 10px;
    height: 10px;
  }

  .text-download {
    font-weight: bold;
  }

  .download-app {
    display: flex;
  }

  .qr-code {
    width: 160px;
  }

  .download-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .iphones {
    max-width: 550px;
  }

  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 30px;
    grid-gap: 20px;

    .iphones {
      max-width: 300px;
    }
  }

  @media only screen and (max-width: 412px) {
    .tag {
      font-size: 18px;
    }

    .title {
      font-size: 20px;
    }

    .download-app {
      margin-top: 5px;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export { StyledMobilePage };
