import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckCircleRounded } from '@mui/icons-material';
import { SCREEN_TYPE } from '@src/constants';
import { REQUEST_STATUS } from '@src/constants/voice';
import CircularProcess from '@src/components/CircularProcess';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledChip, StyledLinearStatus } from './index.style';

const END_POINT = 99;
const SWAP_POINT = 50;

const RequestStatus = ({ request, screenType, marginLeft }) => {
  const { t } = useTranslation();
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, email } = user;

  const isNewDubbingUi = getFeatureValue(FEATURE_KEYS.NEW_DUBBING_UI, {
    userId,
    email,
  });

  const { displayProgress, displayStatus } = useRequestAnimationStatus(request);

  const requestStatus = isNewDubbingUi
    ? {
        ...REQUEST_STATUS,
        DRAFT: 'DRAFT',
      }
    : REQUEST_STATUS;

  if (!request.id) return <></>;

  if (screenType === SCREEN_TYPE.MOBILE) {
    return (
      <>
        {displayStatus === requestStatus.SUCCESS && (
          <CheckCircleRounded color="success" />
        )}
        {displayStatus === requestStatus.FAILURE && (
          <CheckCircleRounded color="error" />
        )}
        {displayStatus === requestStatus.DRAFT && (
          <CheckCircleRounded color="info" />
        )}
        {displayStatus === requestStatus.IN_PROGRESS && (
          <CircularProcess value={displayProgress} size={28} thickness={6} />
        )}
      </>
    );
  }

  return (
    <>
      {displayStatus === requestStatus.SUCCESS && (
        <StyledChip color="success">{t(requestStatus.SUCCESS)}</StyledChip>
      )}
      {displayStatus === requestStatus.FAILURE && (
        <StyledChip color="error">{t(requestStatus.FAILURE)}</StyledChip>
      )}
      {displayStatus === requestStatus.DRAFT && (
        <StyledChip color="info">{t(requestStatus.DRAFT)}</StyledChip>
      )}
      {displayStatus === requestStatus.IN_PROGRESS && (
        <StyledLinearStatus
          color="info"
          progress={Math.max(displayProgress, END_POINT - displayProgress)}
          toright={displayProgress > SWAP_POINT}
          marginLeft={marginLeft}
        >
          {displayProgress}%
        </StyledLinearStatus>
      )}
    </>
  );
};

export default RequestStatus;
