import styled from 'styled-components';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

const StyledFileDropzone = styled.div`
  .dropzone {
    padding: 16px;
    // height: 100px;
    border: 2px dashed ${(props) => (props.isError ? COLOR.error : '#3C3C4329')};
    background: ${(props) =>
      props.isError ? TRANSPARENT_COLOR.error : TRANSPARENT_COLOR.bgrGray};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    cursor: pointer;
  }

  .hightlight {
    background-color: ${TRANSPARENT_COLOR.primary};
  }

  .file-input {
    display: none;
  }

  .upload-file-title {
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
  }

  .success-file-name {
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .divider {
    :before {
      border-top-color: ${COLOR.light};
      top: 0%;
    }
    :after {
      border-top-color: ${COLOR.light};
      top: 0%;
    }
    color: ${COLOR.lightText};
    text-transform: lowercase;
    min-width: 200px;
    max-width: 300px;
    margin: 5px auto;
    font-size: 14px;
    font-weight: 500;
  }

  .upload-button {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    padding: 4px;
    background: transparent;
    text-transform: none;
    margin-top: 4px;
  }

  .upload-success {
    text-align: center;
  }

  .upload-note {
    color: ${COLOR.lightText};
    margin-top: 4px;
  }

  .done-button {
    margin: 10px 0;
  }

  .other-case {
    display: flex;
    justify-content: center;
  }

  .choose-other-file {
    cursor: pointer;
    padding: 4px 10px;
    color: ${COLOR.darkBlue};
    border: 1px solid ${COLOR.darkBlue};
    border-radius: 8px;
    &:hover {
      background: ${COLOR.gray};
    }

    &:focus {
      background: ${COLOR.gray};
    }
    margin-bottom: 10px;
  }

  .allow-format-file {
    margin-bottom: 10px;
  }

  .wrap-title {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: center;
  }
  .wrap-file-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }
  .file-icon {
    width: 32px;
    height: 32px;
  }
`;

const StyleBoxAddSuccess = styled.div`
  .wrap-file {
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #3c3c4329;
    border-radius: 12px;
    padding: 16px;
  }
  .success-file-name {
    flex: 1;
  }
  .file-icon {
    width: 40px;
    height: 40px;
  }
  .clear-icon {
    width: 24px;
    height: 24px;
  }
  .btn-continue {
    margin-left: auto;
  }
`;

const StyleButtonSuccess = styled.div`
  display: flex;
  justify-content: right;
  .btn-continue {
    text-transform: none;
    font-weight: 700;
  }
  margin: 20px 0 8px 0;
  .btn-loading {
    color: #3c164e99;
  }
`;

export { StyledFileDropzone, StyleBoxAddSuccess, StyleButtonSuccess };
