export const INIT_NOTIFICATION_DIALOG = {
  name: '',
  title: '',
  description: '',
  subDescription: '',
  variant: 'info',
  open: false,
  width: undefined,
  contentComponent: undefined,
  actionComponent: undefined,
  onClose: undefined,
};
