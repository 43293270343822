import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledPlaceHolder = styled(Typography)`
  .text-light {
    color: ${COLOR.lightText};
    font-weight: 400;
    font-size: 16px;
  }

  .text-red {
    color: ${COLOR.error};
  }
`;

export const StyledTitleBar = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 5px;

  .base-input {
    flex: 1;
    font-size: 19px;
    font-weight: 600;
  }
`;
