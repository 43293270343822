import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlagRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { StyledErrorReportButton } from './index.style';
import ErrorReport from '../Requests/ErrorReport';

const ErrorReportButton = ({ request = {} }) => {
  const { t } = useTranslation();

  const [openErrorReport, setOpenErrorReport] = useState(false);

  const handleOpenErrorReport = (e) => {
    e.stopPropagation();
    setOpenErrorReport(true);
  };

  const handleCloseErrorReport = () => setOpenErrorReport(false);

  return (
    <StyledErrorReportButton>
      <Button
        className="error-report-button"
        color="error"
        variant="outlined"
        size="small"
        startIcon={<FlagRounded />}
        onClick={handleOpenErrorReport}
      >
        {t('errorReport')}
      </Button>

      <ErrorReport
        open={openErrorReport}
        request={request}
        onClose={handleCloseErrorReport}
      />
    </StyledErrorReportButton>
  );
};

export default ErrorReportButton;
