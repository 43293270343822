import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import {
  AUDIO_PLAYER_HEIGHT,
  FLASH_SALE_BANNER_HEIGHT,
  MOBILE_BREAKPOINT,
  TITLE_PRODUCT_HEIGHT,
} from '@src/styles/config';

const StyledContent = styled('div')`
  position: relative;
  padding-top: 8px;

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .preview-guide {
    @media (max-width: 1000px) {
      display: none;
    }

    @media (min-width: 1000px) {
      display: flex;
    }

    position: absolute;
    font-size: 14px;
    bottom: ${(props) =>
      props.useTextSample && props.showSample && !props.openSentences
        ? '42px'
        : '-8px'};
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%);
    z-index: 1024;
    gap: 12px;

    .change-size-editor {
      border: 1px solid ${COLOR.secondary[32]};
      text-transform: none;
      padding: 2px 10px;
      border-radius: 20px;
      background: ${COLOR.white};
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      color: ${COLOR.darkBlue};
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background: ${COLOR.charcoal};
      }
    }

    .expand-icon {
      margin-left: -8px;
    }
  }

  .editor-wrapper {
    height: ${(props) =>
      props.requestTableHeight
        ? `calc(100vh - ${
            props.useTitleProduct ? TITLE_PRODUCT_HEIGHT : 0
          }px - ${props.isNewPreviewBtn ? 32 : 0}px -  ${
            props.requestTableHeight
          }px - ${props.showaudioplayer ? AUDIO_PLAYER_HEIGHT : 0}px - ${
            props.useHeaderBanner && props.headerVoucher
              ? FLASH_SALE_BANNER_HEIGHT
              : 0
          }px)`
        : `calc(100vh - ${
            props.useTitleProduct ? TITLE_PRODUCT_HEIGHT : 0
          }px - ${props.isNewPreviewBtn ? 32 : 0}px - 320px - ${
            props.showaudioplayer ? AUDIO_PLAYER_HEIGHT : 0
          }px - ${
            props.useHeaderBanner && props.headerVoucher
              ? FLASH_SALE_BANNER_HEIGHT
              : 0
          }px)`};
    overflow-y: auto;
    overflow-x: hidden;

    // scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${COLOR.indigo[64]};
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${COLOR.indigo[80]};
      border-radius: 100px;
    }

    a:-webkit-any-link {
      color: ${COLOR.blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .editor-wrapper {
      height: ${(props) =>
        props.requestTableHeight
          ? `calc(100vh - ${props.isNewPreviewBtn ? 25 : 0}px -  ${
              props.requestTableHeight
            }px)`
          : `calc(100vh - ${props.isNewPreviewBtn ? 25 : 0}px - 320px - ${
              props.showaudioplayer ? AUDIO_PLAYER_HEIGHT : 0
            }px)`};
    }
  }

  .character-exceed {
    color: ${COLOR.danger};
  }
  .DraftEditor-root {
    height: 90%;
    font-family: 'SF Pro', sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }

  .search-highlight {
    color: ${COLOR.darkBlue};
    background-color: ${COLOR.primary};
  }

  .black-word-highlight {
    color: ${COLOR.darkBlue};
    background-color: ${COLOR.error};
  }
`;

const StyledSentenceItem = styled('div')`
  position: relative;
  .block-wrapper {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
  }

  .check-box {
    padding: 0;
    color: ${COLOR.darkBlue};
  }

  .voice-info {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  .editor-container {
    flex: 1;
    font-size: 14px;
  }
  .character-exceed {
    color: ${COLOR.danger};
  }

  .sentence-toolbar {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${COLOR.divider};
    padding: 3px 0;

    .play-audio {
      margin: 0 10px;
    }

    .voice-info {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-bottom: 1px solid ${COLOR.divider};

    .editor-container {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
`;

const StyledSentences = styled('div')`
  height: ${(props) =>
    props.requestTableHeight
      ? `calc(100vh - 50px - ${props.requestTableHeight}px - ${
          props.showaudioplayer ? AUDIO_PLAYER_HEIGHT : 0
        }px - ${props.headerVoucher ? FLASH_SALE_BANNER_HEIGHT : 0}px)`
      : `calc(100vh - 50px - 320px  - ${
          props.showaudioplayer ? AUDIO_PLAYER_HEIGHT : 0
        }px)`};
  overflow-y: auto;

  // scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLOR.white};
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLOR.indigo[64]};
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.indigo[80]};
    border-radius: 100px;
  }

  a:-webkit-any-link {
    color: ${COLOR.blue};
    cursor: pointer;
    text-decoration: underline;
  }

  .check-box-all {
    padding: 10px;
  }

  .sentence-header {
    display: flex;
    align-items: center;
    position: relative;

    .block-wrapper {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
    }
  }

  .checkbox-color {
    color: ${COLOR.darkBlue};
  }

  .delete-sentence {
    display: flex;
    align-items: center;
    color: ${COLOR.danger};
    cursor: pointer;
  }
`;

const StyledChangeBoxTtsSizeButton = styled('div')`
  display: flex;
  align-items: top;
  justify-content: center;
  position: relative;

  .line {
    width: 100%;
    height: 1px;
    background-color: ${COLOR.light};
  }

  .button {
    position: relative;
    display: flex;
    justify-content: center;
    width: 180px;
    margin-top: -1px;

    .size {
      width: 180px;
    }

    .button-content {
      cursor: pointer;
      position: absolute;
      margin-left: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      z-index: 30;
      color: ${COLOR.darkBlue};
      font-weight: 600;
      font-size: 14px;
      transform: rotateX(4deg) !important;
    }
  }
`;

const StyledPreviewButton = styled(Paper)`
  border-radius: 100%;
  width: 34px;
  height: 34px;
  border: 1px solid ${COLOR.subText};
  display: flex;
  align-items: center;
  justify-content: center;
  over-flow: hidden;
  &:hover {
    border: 1px solid ${COLOR.primary};
  }
`;

const StyledConvertAction = styled('div')`
  position: absolute;
  right: 0px;
  bottom: -10px;
  z-index: 100;
  border-radius: 12px;

  .custom-button {
    text-transform: none;
    border-radius: 12px;
    padding: 4px 6px;
    min-width: fit-content;
    color: ${(props) =>
      props.disabled ? COLOR.disableButton : COLOR.darkBlue};

    &:hover,
    &:focus {
      background: ${COLOR.secondary[80]};
    }
  }
`;

const StyledActionReset = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  .button {
    display: flex;
    height: 28px;
    padding: 0px 12px;
    width: 70px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 500px;
    text-transform: none;
  }
  .outlined-button {
    border: 1px solid ${COLOR.indigo[32]};
  }
`;

const StyledPreviewGuide = styled('div')`
  display: inline-flex;
  padding: 6px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  background: ${COLOR.grayV2[100]};

  .description {
    color: ${COLOR.secondary[8]};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
  }

  .ignore-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 510;
    line-height: 20px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const StyledPreviewButtonNew = styled('div')`
  .tool-row {
    display: ${(props) => (props.openSentences ? `none` : `flex`)};
    height: 38px;
    border-radius: 8px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const StyledSentencesHeader = styled('div')`
  a:-webkit-any-link {
    color: ${COLOR.blue};
    cursor: pointer;
    text-decoration: underline;
  }

  .check-box-all {
    padding: 10px;
  }

  .sentence-header {
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${COLOR.white};
  }

  .checkbox-color {
    color: ${COLOR.darkBlue};
  }

  .delete-sentence {
    display: flex;
    align-items: center;
    color: ${COLOR.danger};
    cursor: pointer;
  }
`;

export {
  StyledContent,
  StyledSentenceItem,
  StyledSentences,
  StyledSentencesHeader,
  StyledChangeBoxTtsSizeButton,
  StyledPreviewButton,
  StyledConvertAction,
  StyledActionReset,
  StyledPreviewGuide,
  StyledPreviewButtonNew,
};
