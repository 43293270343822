import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

// import required modules
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Box } from '@mui/material';
import GiftIcon from '@src/assets/icons/gift-icon.png';
import { getVouchersByProduct } from '@src/services/voucher';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckProductName } from '@src/hooks/useCheckProduct';
import SwiperPagination from '@src/components/Swiper/SwiperPagination';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  useCheckDubbingRoute,
  useCheckTTSRoute,
} from '@src/hooks/useCheckDubbingRoute';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import { PACKAGE_TYPE } from '@src/constants/package';
import moment from 'moment';
import LockCharactersBanner from './LockCharactersBanner';
import AutoRenewSidebar from '../AutoRenewal/AutoRenewSidebar';
import VoucherBanner, { VOUCHER_BANNER_TEMPLATE } from './VoucherBanner';

const VoucherAds = ({
  handleOpenSidebar,
  isSmallSidebar = false,
  isMobile = false,
  vouchers,
  showAutoRenewalBanner,
}) => {
  // use to detect screen product
  const productName = useCheckProductName();
  const isDubbingRoute = useCheckDubbingRoute();
  const { getFeatureValue } = useFeatureFlags();
  const showSidebarVoucher = getFeatureValue(FEATURE_KEYS.SIDEBAR_VOUCHER);
  const discountForFirstPaidUser = getFeatureValue(
    FEATURE_KEYS.DISCOUNT_FOR_FIRST_PAID_USER,
  );
  const swiperRef = useRef(null);
  const { lastExpiredOrder, usingPackage } = useSelector((state) => state.user);
  const { lockCharacters } = useSelector((state) => state.auth.user);
  const { user } = useSelector((state) => state.auth);
  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const isUserUseMonthlyPackage =
    usingPackage?.type === PACKAGE_TYPE.STUDIO &&
    usingPackage?.code.includes('MONTH');

  const autoRenewalPackageCode = `${usingPackage?.code?.split('-')[0]}-${
    usingPackage?.code?.split('-')[1]
  }-MONTH-AUTO-RENEWAL`;

  const numberOfVouchers = vouchers.filter(
    (voucher) =>
      voucher.canUse &&
      (!isDubbingRoute || voucher?.type !== VOUCHER_TYPE.CHARACTER), // if is not dubbing router=>take all voucher
  ).length;

  const numberOfVouchersProduct = getVouchersByProduct({
    vouchers,
    product: productName,
  }).length;

  const finalNumberOfVouchers = useVoucherByProduct
    ? numberOfVouchersProduct
    : numberOfVouchers;

  const checkCharacterReservationPeriod = () => {
    let preservedCharacterDate;

    preservedCharacterDate = user.preservationExpiryDate;
    if (user.preservationExpiryDate && usePreservationExpiryDate)
      preservedCharacterDate = moment(user.preservationExpiryDate);
    else if (user.packageExpiryDate) {
      preservedCharacterDate = moment(user.packageExpiryDate).add(30, 'days');
    } else if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      preservedCharacterDate = moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days');
    }

    if (!preservedCharacterDate) return false;
    return moment().isBefore(moment(preservedCharacterDate));
  };

  // hasLockCharacters only valid if product is TTS
  const hasLockCharacters =
    useCheckTTSRoute() &&
    lockCharacters > 0 &&
    checkCharacterReservationPeriod();

  const showGiftForCollapseSidebar =
    hasLockCharacters ||
    (showSidebarVoucher && finalNumberOfVouchers > 0) ||
    showAutoRenewalBanner;

  const calculateNumberOfVouchers = () => {
    let number = finalNumberOfVouchers || 0;
    if (hasLockCharacters) number += 1;
    if (showAutoRenewalBanner) number += 1;
    return number;
  };

  const renderVoucherForCollapseSidebar = () =>
    showGiftForCollapseSidebar && (
      <Box className="gift-wrapper">
        <Box onClick={handleOpenSidebar} className="gift-button">
          <Box className="number-of-gift">{calculateNumberOfVouchers()}</Box>
          <img src={GiftIcon} alt="gift-icon" />
        </Box>
      </Box>
    );

  const getVoucherAdsComponents = () => {
    const ads = [];
    if (hasLockCharacters)
      ads.push({ renderContent: <LockCharactersBanner /> });

    if (showAutoRenewalBanner)
      ads.push({
        renderContent: (
          <AutoRenewSidebar
            isUserUseMonthlyPackage={isUserUseMonthlyPackage}
            autoRenewalPackageCode={autoRenewalPackageCode}
          />
        ),
      });

    if (showSidebarVoucher)
      vouchers.forEach((voucher) => {
        // first paid at time i code only valid with TTS
        const isFirstPaidVoucher =
          useCheckTTSRoute() && voucher.policy?.totalStudioPaidOrders === 0;
        if (isFirstPaidVoucher && discountForFirstPaidUser) {
          ads.push({
            renderContent: (
              <VoucherBanner
                voucher={voucher}
                template={VOUCHER_BANNER_TEMPLATE.FIRST_PAID_VOUCHER}
              />
            ),
          });
          return;
        }
        // if feature flag off => REFACTOR: remove this below IF (in case delete feature flag and still use rule voucher by product )
        if (
          !useVoucherByProduct &&
          !isFirstPaidVoucher &&
          voucher.canUse &&
          (!isDubbingRoute || voucher?.type !== VOUCHER_TYPE.TIME)
        ) {
          ads.push({
            renderContent: <VoucherBanner voucher={voucher} />,
          });
        }
        // if feature flag is on
        if (
          useVoucherByProduct &&
          !isFirstPaidVoucher &&
          ((voucher.canUse && voucher?.policy?.applyAllPackages) ||
            (voucher.canUse &&
              voucher.policy.packages.filter((pkg) =>
                pkg.code.includes(productName),
              ).length > 0))
        ) {
          ads.push({
            renderContent: <VoucherBanner voucher={voucher} />,
          });
        }
      });
    return ads;
  };
  const handleMouseEnter = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  };
  const renderVoucherForLargeSidebar = () => {
    const ads = getVoucherAdsComponents();
    return (
      <div style={{ width: '100%' }}>
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          modules={[Autoplay, Pagination]}
        >
          {Array.isArray(ads) &&
            ads.map((ad) => (
              <SwiperSlide
                style={{ maxWidth: '240px' }}
                key={ad}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {ad.renderContent}
              </SwiperSlide>
            ))}
          {ads.length > 1 && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '8px',
                left: '60px',
                zIndex: '32',
              }}
            >
              <SwiperPagination totalSlides={ads.length} />
            </Box>
          )}
        </Swiper>
      </div>
    );
  };

  const renderVoucherForMobile = () => {
    const ads = getVoucherAdsComponents();

    return (
      <div style={{ width: '100%' }}>
        <Swiper
          direction="horizontal"
          slidesPerView={1}
          freeMode
          mousewheel
          autoplay={{
            delay: 3000,
            disableOnInteraction: false, // keeps autoplay after user interaction
          }}
          modules={[Autoplay, Pagination]}
        >
          {ads.map((ad) => (
            <SwiperSlide key={ad}>{ad.renderContent}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  return (
    <>
      {isMobile && renderVoucherForMobile()}
      {!isMobile && isSmallSidebar && renderVoucherForCollapseSidebar()}
      {!isMobile && !isSmallSidebar && renderVoucherForLargeSidebar()}
    </>
  );
};

export default VoucherAds;
