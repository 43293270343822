import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import NotificationDialog from '@src/components/NotificationDialog';
import ProcessHandler from '@src/components/ProcessHandler';
import { StyledCompletedAction } from './index.style';

const PaymentNotificationDialogs = ({
  openDialog,
  loading,
  DIALOG_NAMES,
  onCloseDialog,
  onComeBackToPackage,
  onConfirmDowngradePackage,
  onCancelPendingOrder,
  onContinuePayingDialog,
}) => {
  const { t } = useTranslation();

  const handleCloseDialog = (dialogName) => onCloseDialog(dialogName);

  const activeDialogName = Object.keys(openDialog).find(
    (key) => openDialog[key],
  );

  const renderActionComponent = () => {
    switch (activeDialogName) {
      case DIALOG_NAMES.NOT_SELECTED_BANK:
        return (
          <Button
            variant="contained"
            onClick={() => handleCloseDialog(activeDialogName)}
          >
            {t('understood')}
          </Button>
        );
      case DIALOG_NAMES.UNPAID_ORDER:
      case DIALOG_NAMES.NOT_CONFIRMED_ORDER:
        return (
          <>
            <Button variant="outlined" onClick={onCancelPendingOrder}>
              <ProcessHandler loading={loading.cancelOrder} size={25}>
                {t('cancelOrder')}
              </ProcessHandler>
            </Button>
            <Button variant="contained" onClick={onContinuePayingDialog}>
              {activeDialogName === DIALOG_NAMES.NOT_CONFIRMED_ORDER
                ? t('pendingPayment')
                : t('continue')}
            </Button>
          </>
        );
      case DIALOG_NAMES.CONFIRM_TRANSACTION:
      case DIALOG_NAMES.COMPLETED_ORDER:
        return (
          <StyledCompletedAction>
            <Button variant="contained" onClick={onComeBackToPackage}>
              {t('completed')}
            </Button>
          </StyledCompletedAction>
        );
      case DIALOG_NAMES.DOWNGRADE_PACKAGE:
        return (
          <>
            <Button variant="outlined" onClick={onComeBackToPackage}>
              {t('chooseAnotherPackage')}
            </Button>
            <Button variant="contained" onClick={onConfirmDowngradePackage}>
              {t('continue')}
            </Button>
          </>
        );
      case DIALOG_NAMES.CONFIRM_CANCEL_ORDER:
        return (
          <>
            <Button variant="outlined" onClick={onCancelPendingOrder}>
              {t('cancelOrder')}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleCloseDialog(activeDialogName)}
            >
              {t('notCancel')}
            </Button>
          </>
        );
      default:
        return '';
    }
  };

  const dialogList = [
    {
      name: DIALOG_NAMES.NOT_SELECTED_BANK,
      title: t('haveNotSelectedBankTitle'),
      description: t('haveNotSelectedBankDescription'),
      variant: 'warning',
      onClose: handleCloseDialog,
    },
    {
      name: DIALOG_NAMES.UNPAID_ORDER,
      title: t('havePendingOrderTitle'),
      description: t('hasOrderWaitForConfirmTransferDescription'),
      variant: 'warning',
      onClose: handleCloseDialog,
    },
    {
      name: DIALOG_NAMES.NOT_CONFIRMED_ORDER,
      title: t('havePendingOrderTitle'),
      description: t('hasOrderWaitForApprovalDescription'),
      variant: 'warning',
      onClose: handleCloseDialog,
    },
    {
      name: DIALOG_NAMES.CONFIRM_TRANSACTION,
      title: t('waitingForApprovalTitle'),
      description: t('waitingForApprovalDescription'),
      variant: 'info',
      onClose: handleCloseDialog,
    },
    {
      name: DIALOG_NAMES.COMPLETED_ORDER,
      title: t('completedOrderTitle'),
      description: t('completedTransactionDescription'),
      variant: 'info',
      onClose: handleCloseDialog,
    },
    {
      name: DIALOG_NAMES.DOWNGRADE_PACKAGE,
      title: t('warningSelectSmallerPackageTitle'),
      description: t('warningSelectSmallerPackageDescription'),
      variant: 'warning',
      onClose: () => ({}),
    },
    {
      name: DIALOG_NAMES.CONFIRM_CANCEL_ORDER,
      title: t('confirmCancelOrderTitle'),
      description: t('confirmCancelOrderDescription'),
      variant: 'warning',
      onClose: () => ({}),
    },
  ];

  const activeDialog = dialogList.find(
    (dialog) => dialog.name === activeDialogName,
  );

  if (!activeDialog) return '';

  return (
    <NotificationDialog
      name={activeDialog.name}
      title={activeDialog.title}
      description={activeDialog.description}
      variant={activeDialog.variant}
      open={openDialog[activeDialog.name]}
      onClose={activeDialog.onClose}
      actionComponent={renderActionComponent()}
      contentComponent={activeDialog.contentComponent}
    />
  );
};

export default PaymentNotificationDialogs;
