export const BANNER_TYPE = {
  FIRST_LOGIN: 'FIRST_LOGIN',
  JOIN_ZALO_GROUP: 'JOIN_ZALO_GROUP',
  FIRST_BUY_PACKAGE_TRIAL: 'FIRST_BUY_PACKAGE_TRIAL', // First buy package while using trial package
  LAST_DAY_TRIAL: 'LAST_DAY_TRIAL', // Last day of trial package
  TRIAL_EXPIRY: 'TRIAL_EXPIRY', // trial expiry
  FIRST_BUY_PACKAGE_BASIC: 'FIRST_BUY_PACKAGE_BASIC', // First buy package while using basic package
  PACKAGE_EXPIRY: 'PACKAGE_EXPIRY',
  USER_RETURN: 'USER_RETURN',
  SERVICE_MANUAL: 'SERVICE_MANUAL',
  IMAGE: 'IMAGE',
  GROUP_CUSTOMERS_BUY_PACKAGE: 'GROUP_CUSTOMERS_BUY_PACKAGE',
  RECENT_NO_ANNUAL_PURCHASE_USER: 'RECENT_NO_ANNUAL_PURCHASE_USER',
};

export const ACTION_TYPE = { BUTTON: 'button' };

export const ACTION_BANNERS = ['JOIN_ZALO_GROUP', 'SERVICE_MANUAL'];
