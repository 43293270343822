import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCallButton = styled('a')`
  text-decoration: none;
  color: ${COLOR.text};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;
