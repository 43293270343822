import React from 'react';
import Countdown from 'react-countdown';

import { StyledCountdownInBanner } from './index.style';
import CountdownTime from './CountdownTimeInBanner';

const CountdownBanner = ({
  date,
  onComplete,
  completeComponent,
  backgroundColor,
}) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return completeComponent || <div className="h-20" />;

    return (
      <div className="countdown-box">
        <div className="countdown">
          <CountdownTime
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      </div>
    );
  };
  return (
    <StyledCountdownInBanner backgroundColor={backgroundColor}>
      <Countdown date={date} renderer={renderer} onComplete={onComplete} />
    </StyledCountdownInBanner>
  );
};

export default CountdownBanner;
