export const actionTypes = {
  UPDATE_AUDIO_LINK_PREVIEW: 'UPDATE_AUDIO_LINK_PREVIEW',
  UPDATE_SELECTED_AUDIO_REQUEST_PREVIEW:
    'UPDATE_SELECTED_AUDIO_REQUEST_PREVIEW',
  UPDATE_STATUS_PREVIEW: 'UPDATE_STATUS_PREVIEW',
  UPDATE_META_DATA_PREVIEW: 'UPDATE_META_DATA_PREVIEW',

  UPDATE_TRY_LISTENING_SENTENCE_PREVIEW:
    'UPDATE_TRY_LISTENING_SENTENCE_PREVIEW',

  UPDATE_REQUEST_PREVIEW_ID_PREVIEW: 'UPDATE_REQUEST_PREVIEW_ID_PREVIEW',

  UPDATE_AUDIO_TYPE_PREVIEW: 'UPDATE_AUDIO_TYPE_PREVIEW',
  UPDATE_STREAM_AUDIOS_PREVIEW: 'UPDATE_STREAM_AUDIOS_PREVIEW',
  INIT_STREAM_AUDIOS_PREVIEW: 'INIT_STREAM_AUDIOS_PREVIEW',
  UPDATE_STATUS_STREAM_AUDIO_PREVIEW: 'UPDATE_STATUS_STREAM_AUDIO_PREVIEW',
  UPDATE_FINAL_STREAM_AUDIO_PREVIEW: 'UPDATE_FINAL_STREAM_AUDIO_PREVIEW',

  RESET_AUDIO_PREVIEW: 'RESET_AUDIO_PREVIEW',
  UPDATE_POPUP_PREVIEW_ANCHOR_El: 'UPDATE_POPUP_PREVIEW_ANCHOR_El',
};

const updatePopupPreviewAnchorEl = (value) => ({
  type: actionTypes.UPDATE_POPUP_PREVIEW_ANCHOR_El,
  value,
});

const resetAudioPreview = () => ({
  type: actionTypes.RESET_AUDIO_PREVIEW,
});

const updateAudioLink = (audioLink, isPreview) => ({
  type: actionTypes.UPDATE_AUDIO_LINK_PREVIEW,
  audioLink,
  isPreview,
});

const updateSelectedAudioRequest = (requestId) => ({
  type: actionTypes.UPDATE_SELECTED_AUDIO_REQUEST_PREVIEW,
  requestId,
});

const updateStatus = (isPlaying) => ({
  type: actionTypes.UPDATE_STATUS_PREVIEW,
  isPlaying,
});

const updateMetaData = ({ currentTime, duration }) => ({
  type: actionTypes.UPDATE_META_DATA_PREVIEW,
  currentTime,
  duration,
});

const updateTryListeningSentence = ({
  sentenceId,
  isAudioLoading,
  audioLink,
}) => ({
  type: actionTypes.UPDATE_TRY_LISTENING_SENTENCE_PREVIEW,
  sentenceId,
  isAudioLoading,
  audioLink,
});

const updateRequestPreviewId = (previewRequestId) => ({
  type: actionTypes.UPDATE_REQUEST_PREVIEW_ID_PREVIEW,
  previewRequestId,
});

const updateAudioType = (audioType) => ({
  type: actionTypes.UPDATE_AUDIO_TYPE_PREVIEW,
  audioType,
});

const updateStreamAudios = (streamAudio) => ({
  type: actionTypes.UPDATE_STREAM_AUDIOS_PREVIEW,
  streamAudio,
});

const initStreamAudios = (ttsVersion) => ({
  type: actionTypes.INIT_STREAM_AUDIOS_PREVIEW,
  ttsVersion,
});

const updateStatusStreamAudio = (isRunStreamAudio) => ({
  type: actionTypes.UPDATE_STATUS_STREAM_AUDIO_PREVIEW,
  isRunStreamAudio,
});

const updateFinalStreamAudio = (streamAudio) => ({
  type: actionTypes.UPDATE_FINAL_STREAM_AUDIO_PREVIEW,
  streamAudio,
});

export {
  updatePopupPreviewAnchorEl,
  resetAudioPreview,
  updateAudioLink,
  updateSelectedAudioRequest,
  updateStatus,
  updateMetaData,
  updateTryListeningSentence,
  updateRequestPreviewId,
  updateAudioType,
  updateStreamAudios,
  initStreamAudios,
  updateStatusStreamAudio,
  updateFinalStreamAudio,
};
