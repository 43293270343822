import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, TextField, Typography } from '@mui/material';

import logo from '@src/assets/logo/logo.svg';
import apis from '@src/apis';
import { StyledCheckBoxContent } from './index.style';
import RatingSuccess from './RatingSuccess';

const RatingContentItem = ({ content, checked, onChange }) => (
  <StyledCheckBoxContent>
    <Checkbox className="checkbox" checked={checked} onChange={onChange} />
    <Typography className="content">{content}</Typography>
  </StyledCheckBoxContent>
);

const RatingContents = ({ setRatingChoice, data, ratingChoice }) =>
  Object.keys(data).map((key) => (
    <RatingContentItem
      content={data[key]}
      checked={ratingChoice[key]}
      onChange={() =>
        setRatingChoice((prev) => ({ ...prev, [key]: !prev[key] }))
      }
    />
  ));

const Rating = ({ onClose, setShowRatingPopUpCookie }) => {
  const { t } = useTranslation();

  const [hasSubmitedRating, setHasSubmitedRating] = useState(false);
  const [ratingChoice, setRatingChoice] = useState({});
  const [opinionRating, setOpinionRating] = useState('');
  const [notShowAgain, setNotShowAgain] = useState(false);

  const listRatingContent = {
    satisfied: t('satisfiedRating'),
    unsatisfied: t('unsatisfiedRating'),
    voice: t('voiceRating'),
    download: t('downloadRating'),
    upload: t('uploadRating'),
  };

  const initCheckedValueOfRatingChoice = () => {
    const defaultRatingChoice = {};

    Object.keys(listRatingContent).forEach((key) => {
      defaultRatingChoice[key] = false;
    });
    setRatingChoice(defaultRatingChoice);
  };

  const handleChangeOpinion = (e) => setOpinionRating(e.target.value);

  const handleNotShowAgain = (e) => {
    const { checked } = e.target;

    if (checked) setShowRatingPopUpCookie(false);
    else setShowRatingPopUpCookie(true);
    setNotShowAgain(checked);
  };

  const getSelectedOpinions = () => {
    const selectedOpinions = Object.keys(ratingChoice)
      .filter((key) => ratingChoice[key])
      .map((key) => listRatingContent[key]);

    if (opinionRating.trim()) selectedOpinions.push(opinionRating);

    return selectedOpinions;
  };

  const handleSubmitRating = async () => {
    const feedbackContent = {
      feature: 'DUBBING',
      opinions: getSelectedOpinions(),
    };

    await apis.feedbacks.createFeedback(feedbackContent);
    setHasSubmitedRating(true);
  };

  useEffect(() => {
    initCheckedValueOfRatingChoice();
  }, []);

  return hasSubmitedRating ? (
    <RatingSuccess onClose={onClose} />
  ) : (
    <>
      <div className="dialog-logo">
        <img src={logo} alt="vbee tts logo" className="logo-img" />
      </div>
      <div className="dialog-title">
        <Typography>{t('descriptionRatingDubbing')}</Typography>
      </div>
      <RatingContents
        setRatingChoice={setRatingChoice}
        data={listRatingContent}
        ratingChoice={ratingChoice}
      />
      <TextField
        variant="standard"
        placeholder={t('opinionRating')}
        value={opinionRating}
        onChange={handleChangeOpinion}
        className="dialog-description"
        InputProps={{ disableUnderline: true }}
        multiline
        rows={6}
      />
      <RatingContentItem
        content={t('notShowAgain')}
        checked={notShowAgain}
        onChange={handleNotShowAgain}
      />
      <div className="dialog-action">
        <Button
          variant="contained"
          onClick={handleSubmitRating}
          disabled={
            Object.values(ratingChoice).every((value) => !value) &&
            !opinionRating.trim()
          }
          className="submit-button"
        >
          {t('send')}
        </Button>
      </div>
    </>
  );
};

export default Rating;
