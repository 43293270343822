const COMMISSION_PAYMENT_LIMIT = 2000000;

const AFFILIATE_TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

const AFFILIATE_TABS = {
  ORDER_LIST: 'ORDER_LIST',
  AFFILIATE_TRANSACTION: 'AFFILIATE_TRANSACTION',
  CUSTOMERS_LIST: 'CUSTOMERS_LIST',
};

const AFFILIATE_NOTIFICATION_TYPE = {
  CREATE_AFFILIATE_TRANSACTION_SUCCESS: 'create-affiliate-transaction-success',
  CREATE_AFFILIATE_TRANSACTION_FAILURE: 'create-affiliate-transaction-failure',
  AFFILIATE_TRANSACTION_APPROVED: 'affiliate-transaction-approved',
  AFFILIATE_TRANSACTION_REJECTED: 'affiliate-transaction-rejected',
};

export {
  COMMISSION_PAYMENT_LIMIT,
  AFFILIATE_TRANSACTION_STATUS,
  AFFILIATE_TABS,
  AFFILIATE_NOTIFICATION_TYPE,
};
