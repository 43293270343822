const { MAX_FILE_NAME_LENGTH } = require('@src/constants/dubbing');

const getShortFileName = (fileName, maxCharacters = MAX_FILE_NAME_LENGTH) => {
  if (fileName && fileName?.length > maxCharacters) {
    return `${fileName.slice(0, maxCharacters - 3)}...`;
  }
  return fileName;
};

export { getShortFileName };
