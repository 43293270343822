import { all, put, takeEvery } from 'redux-saga/effects';
import { BLOCK_USER_PAGE_URL } from '@src/configs';
import apis from '@src/apis';
import api from '@src/apis/api';
import actions from '../actions';

const MAX_RETRIES = 3;

function* verifyTokenSaga({ accessToken }) {
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      const { status } = yield apis.account.verifyToken(accessToken);
      if (!status) throw new Error();

      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      const { status: getMeStatus, result: user } = yield apis.account.getMe();
      if (!getMeStatus) throw new Error();
      if (user?.isBlock) {
        window.location.assign(BLOCK_USER_PAGE_URL);
      }

      yield all([
        yield put(actions.user.getLatestOrder(user.id)),
        yield put(actions.user.getLatestDubbingOrder(user.id)),
        yield put(actions.user.getUsingPackage(user.id)),
        yield put(actions.user.getLastExpiredOrder()),
      ]);

      yield put(actions.auth.verifyTokenSuccess(accessToken, user));
      return; // Exit the loop if successful
    } catch (error) {
      retries += 1;
      if (retries >= MAX_RETRIES) {
        yield put(actions.auth.verifyTokenFailure());
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.auth.actionTypes.VERIFY_TOKEN, verifyTokenSaga),
  ]);
}
