import { IAM_REALM, IAM_URL } from '../configs';
import api from './api';

const getKeycloakPublicKey = async () => {
  try {
    let { publicKey } = await api({
      method: 'GET',
      url: `${IAM_URL}/auth/realms/${IAM_REALM}`,
    });
    publicKey = `-----BEGIN PUBLIC KEY-----\r\n${publicKey}\r\n-----END PUBLIC KEY-----`;
    return publicKey;
  } catch (err) {
    return null;
  }
};

export { getKeycloakPublicKey };
