import * as packages from './packages';
import * as account from './account';
import * as voices from './voices';
import * as paymentMethods from './paymentMethods';
import * as banks from './banks';
import * as orders from './orders';
import * as upload from './upload';
import * as requests from './requests';
import * as synthesis from './synthesis';
import * as featureFlags from './featureFlags';
import * as version from './version';
import * as user from './user';
import * as vouchers from './voucher';
import * as banners from './banners';
import * as warning from './warning';
import * as clientPause from './clientPause';
import * as country from './country';
import * as backgroundMusic from './backgroundMusic';
import * as blackWords from './blackWord';
import * as devices from './device';
import * as stt from './stt';
import * as affiliateMarketer from './affiliateMarketer';
import * as affiliateTransaction from './affiliateTransaction';
import * as files from './files';
import * as dubbing from './dubbing';
import * as feedbacks from './feedbacks';
import * as subscription from './subscription';
import * as adsProvider from './adsProvider';
import * as keycloak from './keycloak';
import * as voiceCloning from './voiceCloning';
import * as sampleScripts from './sampleScripts';

export default {
  adsProvider,
  packages,
  account,
  voices,
  paymentMethods,
  banks,
  orders,
  upload,
  requests,
  synthesis,
  featureFlags,
  version,
  user,
  vouchers,
  banners,
  warning,
  clientPause,
  country,
  backgroundMusic,
  blackWords,
  devices,
  stt,
  affiliateMarketer,
  affiliateTransaction,
  files,
  dubbing,
  feedbacks,
  subscription,
  keycloak,
  voiceCloning,
  sampleScripts,
};
