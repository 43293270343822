import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import { COLOR } from '@src/styles/color';

const StyledTitleProduct = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 20px 0 12px 0;
  .lable-create-new {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: ${COLOR.indigo.default};
  }
  .icon {
    color: ${COLOR.indigo.default}; !important;
  }
`;

const PopoverUploadMenuItem = styled(MenuItem)``;

export { StyledTitleProduct, PopoverUploadMenuItem };
