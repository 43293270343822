import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Dialog, MenuItem, Typography } from '@mui/material';
import {
  ArticleOutlined,
  FilterCenterFocus,
  ImportantDevicesOutlined,
  InsertDriveFileOutlined,
  LogoutOutlined,
  PaymentOutlined,
  PersonOutline,
} from '@mui/icons-material';

import {
  APP_STORE_URL,
  CH_PLAY_URL,
  CUSTOMER_SUPPORT_PHONE_NUMBER,
  LANDING_PAGE_URL,
  SUPPORT_CENTER_URL,
  VBEE_APP,
  VBEE_FACEBOOK_SOCIAL_URL,
  VBEE_HOTLINE,
  VBEE_POLICY,
  VBEE_TERMS,
  VBEE_ZALO_URL,
} from '@src/configs';
import ROUTES from '@src/constants/route';
import CallButton from '@src/components/CallButton';
import IconFaceBook from '@src/assets/icons/icon-facebook.svg';
import IconZalo from '@src/assets/icons/icon-zalo.svg';
import IconDownload from '@src/assets/icons/download.png';
import QRDownloadApp from '@src/assets/images/qr_code.png';
import ImageAppStore from '@src/assets/images/app_store.png';
import ImageGooglePlay from '@src/assets/images/googleplay.png';
import ImageDotRed from '@src/assets/icons/dot-red.png';
import apis from '@src/apis';
import { getCookie } from '@src/utils/cookie';
import useCustomKeycloak from '@src/hooks/useCustomKeycloak';

import { StyledDownloadAppContainer, StyledMenu } from './index.style';

const OldProfileAction = ({ anchorProfile, setAnchorProfile }) => {
  const { t } = useTranslation();
  const { keycloak, removeKcTokenCookie } = useCustomKeycloak();
  const history = useHistory();

  const [latestVersion, setLatestVersion] = useState('');
  const [openModalDownloadApp, setOpenModalDownloadApp] = useState(false);
  const handleOpenModalDownloadApp = () => setOpenModalDownloadApp(true);
  const handleCloseModalDownloadApp = () => setOpenModalDownloadApp(false);

  const handleGoToProfile = () => keycloak.accountManagement();
  const handleRedirectToManageDevice = () => history.push(ROUTES.MANAGE_DEVICE);
  const handleRedirectToPaymentHistory = () =>
    history.push(ROUTES.PAYMENT_HISTORY);

  const fetchVersion = async () => {
    const data = await apis.version.getVersion();
    if (data && data.status) {
      const { version } = data.result;
      setLatestVersion(version);
    }
  };

  const handleLogout = async () => {
    const fingerprint =
      getCookie('fingerprint') || localStorage.getItem('fingerprint');

    await apis.devices.deactivateDevice(fingerprint);
    removeKcTokenCookie();
    keycloak.logout({ redirectUri: LANDING_PAGE_URL });
  };

  const handleClickDownload = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    fetchVersion();
  }, []);

  return (
    <>
      <StyledMenu
        anchorEl={anchorProfile}
        open={Boolean(anchorProfile)}
        onClose={() => setAnchorProfile(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem classes={{ root: 'menu-item' }} onClick={handleGoToProfile}>
          <PersonOutline /> {t('profile')}
        </MenuItem>
        <MenuItem
          classes={{ root: 'menu-item' }}
          onClick={handleRedirectToPaymentHistory}
        >
          <PaymentOutlined /> {t('paymentHistory')}
        </MenuItem>
        <MenuItem
          classes={{ root: 'menu-item' }}
          onClick={handleRedirectToManageDevice}
        >
          <ImportantDevicesOutlined /> {t('manageDevice')}
        </MenuItem>
        <MenuItem classes={{ root: 'menu-item' }} CallOutlined>
          <FilterCenterFocus />
          <a
            href={SUPPORT_CENTER_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="text-link"
          >
            {t('supportCenter')}
          </a>
        </MenuItem>
        <MenuItem classes={{ root: 'menu-item' }} CallOutlined>
          <ArticleOutlined />
          <a
            href={VBEE_TERMS}
            target="_blank"
            rel="noopener noreferrer"
            className="text-link"
          >
            {t('termOfUse')}
          </a>
        </MenuItem>
        <MenuItem classes={{ root: 'menu-item' }} divider>
          <InsertDriveFileOutlined />
          <a
            href={VBEE_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="text-link"
          >
            {t('policy')}
          </a>
        </MenuItem>
        <MenuItem classes={{ root: 'menu-item' }}>
          <CallButton
            title={t('customerSupport')}
            phoneNumber={CUSTOMER_SUPPORT_PHONE_NUMBER}
            label="support call"
          />
        </MenuItem>
        <MenuItem classes={{ root: 'menu-item' }}>
          <CallButton
            title={t('hotline')}
            phoneNumber={VBEE_HOTLINE}
            label="hotline call"
          />
        </MenuItem>
        <MenuItem classes={{ root: 'menu-item' }} divider>
          <a href={VBEE_FACEBOOK_SOCIAL_URL} target="_blank" rel="noreferrer">
            <img src={IconFaceBook} alt="icon-fb" />
          </a>
          <a href={VBEE_ZALO_URL} target="_blank" rel="noreferrer">
            <img src={IconZalo} alt="icon-zalo" />
          </a>
        </MenuItem>
        <MenuItem
          classes={{ root: 'menu-item' }}
          onClick={handleOpenModalDownloadApp}
          divider
        >
          <img src={IconDownload} alt="icon-fb" />
          <img
            src={ImageDotRed}
            alt="icon-fb"
            className="icon-download-container"
          />
          <Typography variant="subtitle1">{t('downloadApp')}</Typography>
        </MenuItem>
        <MenuItem
          classes={{ root: 'menu-item logout-style' }}
          onClick={handleLogout}
        >
          <LogoutOutlined />
          {t('logout')}
        </MenuItem>
        <div className="style-version">
          <Typography variant="caption">
            {t('ver')} <span>{latestVersion}</span>
          </Typography>
        </div>
      </StyledMenu>
      <Dialog open={openModalDownloadApp} onClose={handleCloseModalDownloadApp}>
        <StyledDownloadAppContainer>
          <p className="title">{VBEE_APP}</p>
          <p className="description">{t('downloadAppDescription')}</p>
          <div className="image-container">
            <img src={QRDownloadApp} alt="qr-code" className="qr-code" />
          </div>
          <p className="description-2">{t('scanQrOrApp')}:</p>
          <div className="image-container">
            <img
              src={ImageAppStore}
              alt="app-store"
              onClick={() => handleClickDownload(APP_STORE_URL)}
            />
            <img
              src={ImageGooglePlay}
              alt="google-play"
              onClick={() => handleClickDownload(CH_PLAY_URL)}
            />
          </div>
        </StyledDownloadAppContainer>
      </Dialog>
    </>
  );
};

export default OldProfileAction;
