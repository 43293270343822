import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BREAKPOINTS } from '@src/styles/config';

export const StyledToolBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${COLOR.secondary[40]};
  border-radius: 8px;
  padding: 8px;

  .border-horizontal {
    display: flex;
    align-items: center;
    border-left: 1px solid ${COLOR.light};
    height: 28px;

    .icon-content {
      display: flex;
      gap: 4px;
      text-transform: lowercase;
    }

    .no-text-transform {
      text-transform: none !important;
    }

    .cursor-pointer {
      cursor: pointer !important;
    }
  }

  .no-border {
    border: none !important;
  }

  .speed {
    display: flex;
    align-items: center;
    border-left: 1px solid ${COLOR.light};
    height: 28px;
    font-weight: 400;
  }

  .title {
    color: ${COLOR.darkBlue};
    font-weight: 400;
  }

  .character-default {
    font-size: 14px;
    font-weight: 500;
    color: ${COLOR.darkBlue};
    text-transform: lowercase;
  }

  .character-blue {
    color: ${COLOR.info};
  }

  .convert-button {
    margin-left: auto;
    padding: 8px 22px;
  }

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    .speed {
      padding-left: 16px;
    }

    .border-horizontal {
      padding-right: 16px;
      padding-left: 16px;
      margin-right: 16px;
      margin-left: 16px;
    }

    .no-margin {
      margin: 0 !important;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    .speed {
      padding-left: 8px;
    }

    .border-horizontal {
      padding-left: 8px;
      margin-left: 8px;
    }
  }
`;
