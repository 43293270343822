import React from 'react';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import NewDubbingContainer from '@src/containers/NewDubbing';
import ComingSoonContainer from '@src/containers/ComingSoon';

const DubbingProject = () => {
  const { user } = useSelector((state) => state.auth);
  const { getFeatureValue } = useFeatureFlags();
  const { id: userId, email } = user;
  const isNewDubbingUi = getFeatureValue(FEATURE_KEYS.NEW_DUBBING_UI, {
    userId,
    email,
  });

  return isNewDubbingUi ? <NewDubbingContainer /> : <ComingSoonContainer />;
};

export default DubbingProject;
