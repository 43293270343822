import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import ProcessHandler from '@src/components/ProcessHandler';
import {
  checkCreateSrtFileSuccess,
  createSRTFile,
} from '@src/services/dubbing';
import actions from '@src/redux/actions';
import { CUSTOMER_SUPPORT_PHONE_NUMBER } from '@src/configs';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import apis from '@src/apis';
import SaveChangeDialog from './SaveChangeDialog';

const SaveDraftButton = ({ hasDeleteSentences, setHasDeleteSentences }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[3];

  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState({});
  const [redirectAction, setRedirectAction] = useState(null);

  const { user } = useSelector((state) => state.auth);

  const {
    sentences,
    title,
    projectInfo,
    changedProjectInfo,
    openSplitVoices,
    originalSentences,
  } = useSelector((state) => state.dubbingRequest);
  const {
    title: updateTitle,
    voiceCode,
    speed,
    sentencesChanged,
  } = useSelector((state) => state.dubbingRequest.changedProjectInfo);

  const { getFeatureValue } = useFeatureFlags();
  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const showErrorMessage = () => {
    dispatch(
      actions.noti.push({
        message: t('systemError', { hotline: CUSTOMER_SUPPORT_PHONE_NUMBER }),
        severity: 'error',
      }),
    );
  };

  const redirectToNewLocation = () => {
    if (redirectAction === 'PUSH') {
      history.push(redirectLocation.pathname);
    } else if (redirectAction === 'REPLACE') {
      history.replace(redirectLocation.pathname);
    } else if (redirectAction === 'POP') {
      history.goBack();
    }
  };

  const updateSentences = (oldSentences, changedSentences) => {
    const newSentences = { ...oldSentences };
    let hasChangedContent = false;

    if (Object.keys(changedSentences).length === 0) {
      if (!openSplitVoices) {
        Object.keys(newSentences).forEach((key) => {
          newSentences[key].voiceCode = voiceCode;
        });
      }

      return { newSentences, hasChangedContent };
    }

    // changedSentences is an object with key is index of sentence and value is things that changed in that sentence
    // Example: changedSentences = { 0: { content: 'new content' }, 1: { voiceCode: 'new voice code' } }

    Object.keys(changedSentences).forEach((key) => {
      // Check if content of sentence is changed and update it
      const checkContentChanged =
        changedSentences[key] &&
        'content' in changedSentences[key] &&
        oldSentences[key] &&
        changedSentences[key].content !== oldSentences[key].content;

      if (checkContentChanged) {
        hasChangedContent = true;
        newSentences[key].content = changedSentences[key].content;
      }

      if (
        changedSentences[key]?.voiceCode &&
        oldSentences[key] &&
        openSplitVoices
      ) {
        newSentences[key].voiceCode = changedSentences[key].voiceCode;
      }
    });

    return { newSentences, hasChangedContent };
  };

  // Helper function to handle subtitle file creation
  const handleSubtitleFileCreation = async ({
    hasChangedContent,
    fileTitle,
    newSentences,
    currentLink,
  }) => {
    if (hasChangedContent || hasDeleteSentences) {
      const { error: isError } = await checkCreateSrtFileSuccess(
        `${fileTitle}.srt`,
        newSentences,
      );
      if (isError) return { invalidFormat: true, fileUrl: null };

      const { error, fileUrl } = await createSRTFile(
        `${fileTitle}.srt`,
        newSentences,
      );
      if (error) return { fileUrl: null };
      return { fileUrl };
    }
    return { fileUrl: currentLink };
  };

  const handleOriginalSubtitleFileCreation = async ({
    fileTitle,
    newSentences,
    currentLink,
  }) => {
    if (!hasDeleteSentences) return { fileUrl: currentLink };
    const { error, fileUrl } = await createSRTFile(
      `${fileTitle}.srt`,
      newSentences,
    );
    if (error) return { fileUrl: null };
    return { fileUrl };
  };

  const getSentencesVoiceCode = (newSentences, defaultVoiceCode) => {
    const sentencesVoiceCode = {};

    if (!openSplitVoices) return sentencesVoiceCode;

    Object.keys(newSentences).forEach((key, index) => {
      const sentenceVoiceCode =
        newSentences[key]?.voiceCode || defaultVoiceCode;
      if (!sentencesVoiceCode[sentenceVoiceCode]) {
        sentencesVoiceCode[sentenceVoiceCode] = [];
      }
      sentencesVoiceCode[sentenceVoiceCode].push(index);
    });

    return sentencesVoiceCode;
  };

  const handleSuccessfulUpdate = (result, newSentences) => {
    const actionsUpdate = [
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        newSentences,
      ),
      actions.dubbingRequest.updateDubbingRequestByKey('title', result?.title),
      actions.dubbingRequest.updateDubbingRequestByKey('speed', result?.speed),
      actions.dubbingRequest.updateDubbingRequestByKey('projectInfo', {
        ...projectInfo,
        currentSubtitleLink: result?.currentSubtitleLink,
        updatedAt: result?.updatedAt,
        hasChanged: false,
        voiceCode: result?.voiceCode,
        projectStatus: result?.status || projectInfo.projectStatus,
      }),
      actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
        ...changedProjectInfo,
        sentencesChanged: {},
      }),
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        [],
      ),
    ];

    actionsUpdate.forEach((action) => dispatch(action));

    dispatch(
      actions.noti.push({
        message: t('saveDraftSuccess'),
        severity: 'success',
      }),
    );
  };

  const handleSaveDraft = async () => {
    const newTitle = updateTitle !== title ? updateTitle : title;

    if (newTitle.length === 0) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'projectTitleCannotBeEmpty',
        }),
      );
      return false;
    }

    setLoadingSaveDraft(true);

    const projectUpdatedInfo = {
      title: updateTitle.length > 0 ? updateTitle : title,
      voiceCode,
      speed,
    };

    let updatedNewSentences = sentences;

    if (projectInfo?.currentSubtitleLink) {
      // Check if the content of sentences has changed and update sentences
      const { newSentences, hasChangedContent } = updateSentences(
        JSON.parse(JSON.stringify(sentences)),
        sentencesChanged,
      );

      updatedNewSentences = newSentences;

      // Handle subtitle file creation if content has changed
      const { invalidFormat, fileUrl } = await handleSubtitleFileCreation({
        hasChangedContent,
        fileTitle: title,
        newSentences,
        currentLink: projectInfo.currentSubtitleLink,
      });
      if (invalidFormat) {
        dispatch(
          actions.noti.push({
            message: t('invalidSrtFormat'),
            severity: 'error',
          }),
        );
        setLoadingSaveDraft(false);
        return false;
      }

      if (!fileUrl) {
        showErrorMessage();
        setLoadingSaveDraft(false);
        return false;
      }
      projectUpdatedInfo.currentSubtitleLink = fileUrl;

      const { fileUrl: originalSubtitleFileUrl } =
        await handleOriginalSubtitleFileCreation({
          fileTitle: title,
          newSentences: originalSentences,
          currentLink: projectInfo?.originalInfo?.subtitleLink,
        });

      // Update voice code for each sentence if dubbing multiple voices feature is enabled
      if (isDubbingMultipleVoices)
        projectUpdatedInfo.sentencesVoiceCode = getSentencesVoiceCode(
          newSentences,
          voiceCode,
        );

      if (isMultipleInputDubbing)
        projectUpdatedInfo.originalInfo = {
          ...projectInfo?.originalInfo,
          subtitleLink: originalSubtitleFileUrl,
        };
    }

    const res = await apis.dubbing.updateDubbingProject(
      projectId,
      projectUpdatedInfo,
    );

    if (res?.status) {
      handleSuccessfulUpdate(res.result, updatedNewSentences);
    } else {
      showErrorMessage();
    }
    setHasDeleteSentences(false);
    setLoadingSaveDraft(false);
    return true;
  };

  const handleCloseSavingDialog = (event, reason) => {
    if (reason && reason === 'backdropClick' && loadingSaveDraft) return;

    const newProjectInfo = { ...projectInfo };
    newProjectInfo.showSavingDialog = false;
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'projectInfo',
        newProjectInfo,
      ),
    );
  };

  const handleCancelSaving = () => {
    redirectToNewLocation();
  };

  const handleConfirmSaving = async () => {
    const isValid = await handleSaveDraft();
    if (isValid) {
      redirectToNewLocation();
      dispatch(actions.dubbingRequest.resetDubbingRequest());
    }
  };

  useEffect(() => {
    const unblockHistory = history.block((location, action) => {
      // Remove all button focus
      document.activeElement.blur();

      if (projectInfo.showSavingDialog) {
        dispatch(actions.dubbingRequest.resetDubbingRequest());
        dispatch(actions.audioPlayer.resetAudioPlayer());
        return true;
      }

      if (projectInfo.hasChanged) {
        setRedirectLocation(location);
        setRedirectAction(action);
        const newProjectInfo = { ...projectInfo };
        newProjectInfo.showSavingDialog = true;
        dispatch(
          actions.dubbingRequest.updateDubbingRequestByKey(
            'projectInfo',
            newProjectInfo,
          ),
        );
        return false;
      }
      return true;
    });

    return () => unblockHistory();
  }, [history, projectInfo.hasChanged, projectInfo.showSavingDialog]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    if (projectInfo.hasChanged) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [projectInfo.hasChanged]);

  return projectInfo.hasChanged ? (
    <>
      <Button
        className="button-save"
        onClick={handleSaveDraft}
        disabled={loadingSaveDraft}
      >
        <ProcessHandler
          loading={loadingSaveDraft}
          ml="26px"
          mr="26px"
          size={24}
          align="center"
          color="divider"
        >
          {t('saveChange')}
        </ProcessHandler>
      </Button>

      <SaveChangeDialog
        maxWidthPx={400}
        open={projectInfo.showSavingDialog}
        title={t('saveChange')}
        description={t('doYouWantToSaveChangesBeforeLeave')}
        isLoadingRightButton={loadingSaveDraft}
        leftButtonMessage={t('cancel')}
        rightButtonMessage={t('saveWhenLeave')}
        onClose={handleCloseSavingDialog}
        onHandleLeftButton={handleCancelSaving}
        onHandleRightButton={handleConfirmSaving}
      />
    </>
  ) : null;
};

export default SaveDraftButton;
