import { PAGINATION_LIMIT, RESOURCE } from '@src/constants';
import api from './api';

const createAffiliateTransaction = async ({
  fullName,
  phoneNumber,
  email,
  withTheAmount,
  address,
  bankName,
  bankAccountNumber,
  bankAccountOwner,
  files,
}) => {
  const response = await api({
    method: 'POST',
    url: RESOURCE.AFFILIATE_TRANSACTION,
    data: {
      bank: {
        name: bankName,
        accountNumber: bankAccountNumber,
        accountOwner: bankAccountOwner,
      },
      user: {
        name: fullName,
        email,
        phoneNumber,
        address,
      },
      money: withTheAmount,
      images: files,
    },
  });
  return response;
};

const getAffiliateTransaction = async ({
  userId,
  packageLevel,
  packageStatus,
  type,
  startDate,
  endDate,
  search,
  searchFields,
  offset,
  limit = PAGINATION_LIMIT,
  fields,
  sort,
  status,
}) => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.AFFILIATE_TRANSACTION,
    params: {
      userId,
      packageLevel,
      packageStatus,
      type,
      startDate,
      endDate,
      search,
      searchFields,
      offset,
      limit,
      fields,
      sort,
      status,
    },
  });

  return response;
};

export { createAffiliateTransaction, getAffiliateTransaction };
