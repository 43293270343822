import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGrowthBook } from '@growthbook/growthbook-react';

import { InputBase, Button, Typography } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Tooltip from '@src/components/Tooltip';
import ResizeTextBox from '@src/components/ResizeTextBox';
import ProcessHandler from '@src/components/ProcessHandler';
import { delay } from '@src/utils/delay';
import { splitParagraphIntoSentence } from '@src/services/sentence';

import actions from '@src/redux/actions';
import { TTS_GUIDE, TTS_TOUR_GUIDE } from '@src/constants/tourGuide';
import { MAX_LENGTH_TITLE } from '@src/constants/request';
import {
  TIME_DELAY_TRANSFER_BUTTON,
  LOCAL_STORAGE_EXPIRES_TIME,
  SET_TIME_AUTO_SAVE,
} from '@src/constants/tts';
import { PREDICT_SECONDS_PER_CHARACTER } from '@src/constants';
import { FEATURE_KEYS } from '@src/configs/featureKeys';

import {
  setDataLocalStorage,
  getDataLocalStorage,
  deleteDataLocalStorage,
} from '@src/utils/localStorage';

import debounce from '@src/utils/debounce';
import { formatAudioTime } from '@src/utils/time';

import StatusSaving from './StatusSaving';
import { StyledTitleBar } from './index.style';

const TitleBar = ({
  loading,
  onCreateSynthesis,
  characterExceed,
  inputLength,
  isLoadDraft,
  onLoadEditContent,
  onChangeInputLength,
  onOpenConfirmDialog,
  content,
}) => {
  const {
    synthesisRequest,
    openSentences,
    voice,
    advanceFeature,
    sentences,
    paragraphs,
  } = useSelector((state) => state.synthesisRequest);
  const { ttsUser } = useSelector((state) => state.user);
  const user = useSelector((state) => state.auth.user);
  const hasConvertTts = !loading;
  const totalCharacters = ttsUser?.maxLengthInputText || 0;
  const estimateAudioLength = formatAudioTime(
    inputLength * PREDICT_SECONDS_PER_CHARACTER,
  );

  const growthbook = useGrowthBook();
  const useOldSaveDraftFeatureFlag = growthbook.getFeatureValue(
    FEATURE_KEYS.OLD_AUTO_SAVE_DRAFT,
  );

  const routerPath = useLocation();
  const [isOpenDialog, setIsOpenDialog] = useState(true);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isShowUp, setIsShowUp] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isClickTransfer, setIsClickTransfer] = useState(false);

  const handleChangeShowUp = () => {
    if (!isSaving) setIsShowUp(false);
  };

  const handleChangeTTSTitleInput = ({ newTitle }) => {
    if (newTitle.length > MAX_LENGTH_TITLE) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );
      return;
    }
    dispatch(
      actions.synthesisRequest.updateSynthesisRequest('title', newTitle),
    );
  };

  const handleChangeTTSTitle = (e) => {
    const { name, value } = e.target;
    if (value.length > MAX_LENGTH_TITLE) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );
      return;
    }
    dispatch(actions.synthesisRequest.updateSynthesisRequest(name, value));
  };

  const handleClickTransfer = async () => {
    if (!isClickTransfer) {
      onCreateSynthesis();
      setIsClickTransfer(true);
      await delay(TIME_DELAY_TRANSFER_BUTTON);
      setIsClickTransfer(false);
    }
  };
  const handleSaveDraftOld = () => {
    if (isOpenDialog && !inputLength) return;
    let sentencesSave;
    if (advanceFeature) sentencesSave = paragraphs;

    if (!advanceFeature) {
      if (openSentences) {
        sentencesSave = sentences;
      } else {
        sentencesSave = splitParagraphIntoSentence(
          content.getCurrentContent().getPlainText('\n'),
          voice,
        );
      }
    }
    const dataDraft = {
      advanceFeature,
      openSentences,
      inputLength,
      synthesisRequest,
      sentencesSave,
      voice,
    };
    setDataLocalStorage('dataDraft', dataDraft, LOCAL_STORAGE_EXPIRES_TIME);
    setIsSaving(false);
  };

  const processContentDraft = () => {
    if (inputLength) handleSaveDraftOld();

    if (!isOpenDialog && !inputLength) {
      setIsSaving(false);
      deleteDataLocalStorage('dataDraft');
    }
  };

  const handleLoadDraft = () => {
    const data = getDataLocalStorage('dataDraft');
    if (!data) return;

    // Switch opensentences types
    dispatch(actions.synthesisRequest.updateOpenSentences(data.openSentences));

    // Load Voice Type
    dispatch(actions.synthesisRequest.updateVoice(data.voice));

    // Load inputLengh
    onChangeInputLength(data.inputLength);
    // Load setting of voice type
    const synthesisRequestSave = data.synthesisRequest;
    Object.keys(synthesisRequestSave).map((key) =>
      dispatch(
        actions.synthesisRequest.updateSynthesisRequest(
          key,
          synthesisRequestSave[key],
        ),
      ),
    );
    // Load content  edit save
    onLoadEditContent(
      data.sentencesSave,
      data.advanceFeature,
      data.openSentences,
    );
  };

  const handleSaveDraft = () => {
    const configSave = {
      ...synthesisRequest,
      paragraphs: [],
      sentences: [],
      voice,
      characters: inputLength,
      characterExceed,
    };

    if (advanceFeature) configSave.paragraphs = paragraphs;

    if (!advanceFeature) {
      if (openSentences) {
        configSave.sentences = sentences;
      } else {
        configSave.text = content.getCurrentContent().getPlainText('\n');
      }
    }

    setDataLocalStorage(
      `dataDraft-${user.id}`,
      configSave,
      LOCAL_STORAGE_EXPIRES_TIME,
    );
    setIsSaving(false);
  };

  useEffect(() => {
    if (useOldSaveDraftFeatureFlag) {
      if (inputLength) {
        setIsOpenDialog(false);
        setIsSaving(true);
        setIsShowUp(true);
      }
      debounce(processContentDraft, SET_TIME_AUTO_SAVE)();
      return;
    }
    if (inputLength) {
      setIsSaving(true);
      setIsShowUp(true);
      debounce(handleSaveDraft, SET_TIME_AUTO_SAVE)();
      return;
    }
    setIsShowUp(false);
  }, [
    advanceFeature,
    openSentences,
    inputLength,
    sentences,
    voice,
    synthesisRequest,
    paragraphs,
  ]);

  useEffect(() => {
    if (useOldSaveDraftFeatureFlag) {
      const data = getDataLocalStorage('dataDraft');
      const doneTourGuide = localStorage.getItem(TTS_TOUR_GUIDE);
      if (data && user.hasAgreedToTerms && doneTourGuide) {
        onOpenConfirmDialog();
      }
      setIsOpenDialog(true);
    }

    return () => {
      setIsOpenDialog(true);
    };
  }, [routerPath, user.hasAgreedToTerms]);

  useEffect(() => {
    if (isLoadDraft && useOldSaveDraftFeatureFlag) handleLoadDraft();
  }, [isLoadDraft]);

  useEffect(() => {
    if (isSaving) {
      setIsShowUp(true);
    } else {
      debounce(handleChangeShowUp, 1000)();
    }
  }, [isSaving]);

  return (
    <StyledTitleBar>
      {useOldSaveDraftFeatureFlag ? (
        <>
          <InputBase
            id={TTS_GUIDE.ADD_TITLE}
            value={synthesisRequest.title}
            placeholder={t('addTitle')}
            name="title"
            maxLength={MAX_LENGTH_TITLE + 1}
            className="base-input"
            onChange={handleChangeTTSTitle}
          />
          {isShowUp && <StatusSaving savingStatus={isSaving} />}
        </>
      ) : (
        <div className="base-input">
          <ResizeTextBox
            value={synthesisRequest.title}
            placeholder={t('noTitle')}
            onChange={handleChangeTTSTitleInput}
            maxWidth={500}
            maxWidthxl={350}
          />
          {isShowUp && <StatusSaving savingStatus={isSaving} />}
        </div>
      )}
      <Tooltip arrow title={t('charactersInUse')} placement="left">
        <Typography variant="body2" className="character-default">
          <span
            className={characterExceed ? 'character-red' : 'character-in-use'}
            translate="no"
          >
            {inputLength?.toLocaleString() || 0}
          </span>
          <span translate="no">
            {' '}
            {`/ ${totalCharacters?.toLocaleString() || 0}`}
          </span>
        </Typography>
      </Tooltip>
      <Tooltip arrow title={t('estimatedAudioLength')} placement="top">
        <Typography variant="body2" className="estimate-audio-length">
          <TimerOutlinedIcon sx={{ fontSize: 16 }} color="iconPrimary" />
          <span translate="no"> {estimateAudioLength}</span>
        </Typography>
      </Tooltip>

      <Button
        id={TTS_GUIDE.CONVERT}
        color="primary"
        variant="contained"
        disabled={!hasConvertTts || isClickTransfer}
        onClick={handleClickTransfer}
        className="text-transfer-button"
      >
        <ProcessHandler
          loading={loading}
          ml="46px"
          mr="46px"
          size={24}
          align="center"
          color="divider"
        >
          {t('textTransfer')}
        </ProcessHandler>
      </Button>
    </StyledTitleBar>
  );
};

export default TitleBar;
