import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getCookie, setCookie } from '@src/utils/cookie';
import { VBEE_DOMAIN } from '@src/configs';
import { TIME_STORE_USER_GUIDE_DUBBING } from '@src/constants/device';
import ModalCreateProject from './ModalCreateProject';
import { StyledNewDubbing } from './index.style';
import ListProject from './ListProject';
import DubbingDialog from './DubbingDialog';
import NotiNewFeature from './NotiNewFeature';

const NewDubbing = () => {
  const location = useLocation();
  const { open } = queryString.parse(location.search);
  const { user } = useSelector((state) => state.auth);
  const isNotiProjectHidden = getCookie(`hideNotiProject_${user.id}`);

  const [isShowModalCreateProject, setIsShowModalCreateProject] = useState(
    Boolean(open) || false,
  );
  const [isShowNotiNewFeature, setIsShowNotiNewFeature] = useState(
    !isNotiProjectHidden || false,
  );
  const checkCloseNotiRef = useRef(false);

  const closeModalCreateProject = () => setIsShowModalCreateProject(false);
  const showModalCreateProject = () => setIsShowModalCreateProject(true);

  const handleCloseNoti = () => {
    setIsShowNotiNewFeature(false);
    setCookie({
      cname: `hideNotiProject_${user.id}`,
      cvalue: true,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_USER_GUIDE_DUBBING,
    });
    checkCloseNotiRef.current = true;
  };

  return (
    <StyledNewDubbing>
      {isShowNotiNewFeature && (
        <NotiNewFeature handleCloseNoti={handleCloseNoti} />
      )}
      <ListProject
        showModalCreateProject={showModalCreateProject}
        isShowNotiNewFeature={isShowNotiNewFeature}
        checkCloseNotiRef={checkCloseNotiRef}
      />
      <ModalCreateProject
        isShowModalCreateProject={isShowModalCreateProject}
        closeModalCreateProject={closeModalCreateProject}
      />
      <DubbingDialog />
    </StyledNewDubbing>
  );
};

export default NewDubbing;
