import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import {
  FlagRounded,
  ArrowDropUpRounded,
  ArrowDropDownRounded,
} from '@mui/icons-material';
import { LANGUAGE, MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import { REQUEST_STATUS } from '@src/constants/voice';
import { Paper, Popper, Typography, ClickAwayListener } from '@mui/material';
import i18n from '@src/languages';
import DownloadButton from './DownloadButton';
import PlayAudioButton from './PlayAudioButton';
import EffectGuideButton from './EffectGuideButton';
import { StyledSuccessButton, StyledMenu, StyledTextIcon } from './index.style';
import ErrorReport from '../Requests/ErrorReport';

const SuccessButton = ({ request = {}, audioRef, setCloseMoreMenu }) => {
  const {
    id: requestId,
    title,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    status,
    userId,
  } = request;

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );
  const isDisabledAudio = !audioLink || audioExpired;

  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const handleCloseMoreMenu = () => {
    setCloseMoreMenu(true);
  };

  const { t } = useTranslation();
  const { language } = i18n;

  const [openErrorReport, setOpenErrorReport] = useState(false);

  const handleOpenErrorReport = (e) => {
    e.stopPropagation();
    handleClosePopup();
    setOpenErrorReport(true);
  };
  const handleCloseErrorReport = () => setOpenErrorReport(false);

  const handleOpenListAction = () => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(buttonRef.current);
  };

  const getTitleActionTooltip = (action) => {
    if (status !== REQUEST_STATUS.SUCCESS) return t('');
    if (audioExpired) return t('notiAudioExpired', { retentionPeriod });

    return t(action);
  };

  return (
    <StyledSuccessButton langEng={language !== LANGUAGE.VN} ref={buttonRef}>
      <DownloadButton
        onClick={handleClosePopup}
        hasDisable={isDisabledAudio}
        requestId={requestId}
        title={title}
        status={status}
        userId={userId}
        audioExpired={audioExpired}
        retentionPeriod={retentionPeriod}
        getTitleActionTooltip={getTitleActionTooltip}
      />
      <div
        className="arrow-button default-background"
        onClick={handleOpenListAction}
        role="button"
        tabIndex="0"
      >
        {anchorEl ? (
          <ArrowDropUpRounded
            className="option-button"
            onClick={handleCloseMoreMenu}
            sx={{ fontSize: '26px' }}
          />
        ) : (
          <ArrowDropDownRounded
            className="option-button"
            onClick={handleCloseMoreMenu}
            sx={{ fontSize: '26px' }}
          />
        )}
      </div>
      {anchorEl && (
        <ClickAwayListener onClickAway={handleClosePopup}>
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            style={{
              zIndex: 11,
              background: 'white',
              left: language !== LANGUAGE.VN ? '-48px' : '-5px',
            }}
            disablePortal
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 5],
                },
              },
            ]}
          >
            <Paper>
              <StyledMenu engButton={language !== LANGUAGE.VN}>
                <PlayAudioButton
                  onClick={handleClosePopup}
                  audioRef={audioRef}
                  request={request}
                  langEng={language !== LANGUAGE.VN}
                />
                <EffectGuideButton
                  hasDisable={isDisabledAudio}
                  onClick={handleClosePopup}
                  requestId={requestId}
                  audioLink={audioLink}
                  title={title}
                  langEng={language !== LANGUAGE.VN}
                />
                <StyledTextIcon
                  disableRipple
                  onClick={handleOpenErrorReport}
                  langEng={language !== LANGUAGE.VN}
                >
                  <FlagRounded />
                  <Typography className="text-action">
                    {t('errorReport')}
                  </Typography>
                </StyledTextIcon>
              </StyledMenu>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
      <ErrorReport
        open={openErrorReport}
        request={request}
        onClose={handleCloseErrorReport}
      />
    </StyledSuccessButton>
  );
};

export default SuccessButton;
