import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogContent,
  TextField,
  Button,
  InputAdornment,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { ArrowForwardRounded, LinkRounded } from '@mui/icons-material';
import ProcessHandler from '@src/components/ProcessHandler';
import Dialog from '@src/components/Dialog';
import { isValidHttpUrl } from '@src/utils/checkValid';
import { LIST_ICON_WEB_SAMPLE } from '@src/constants/upload';
import { COLOR } from '@src/styles/color';
import debounce from '@src/utils/debounce';
import IconClose from '@src/assets/icons/icon-close.png';
import ImgNoThumbnail from '@src/assets/images/no-thumbnail.jpg';
import apis from '@src/apis';
import {
  StyleBoxAddSuccess,
  StyleButtonSuccess,
  StyledHandleByNewUrl,
} from './index.style';

const NewImportURL = ({ open, onClose, onHandleUploadContent }) => {
  const [link, setLink] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datacrawlers, setDataCrawlers] = useState(null);
  const { t } = useTranslation();

  const handleChange = (e) => {
    setError(false);
    setLink(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setError(false);
    setLink('');
    setLoading(false);
    setDataCrawlers(null);
  };

  const handleClear = () => {
    setError(false);
    setLink('');
    setLoading(false);
    setDataCrawlers(null);
  };

  const handleCrawNewsByUrl = async () => {
    // Reset error state when user input a new link with debounce
    setError(false);

    if (!link || !isValidHttpUrl(link)) {
      setError(true);
      return;
    }
    setLoading(true);
    const data = await apis.upload.crawlNewsByUrl(link);
    setLoading(false);
    if (data && data.status) {
      setError(false);
      setDataCrawlers(data);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (link) {
      debounce(handleCrawNewsByUrl, 500)(link);
    }
  }, [link]);

  const boxAddSuccess = () => (
    <StyleBoxAddSuccess>
      <div className="wrap-file">
        {datacrawlers.results?.thumbnail ? (
          <img
            src={datacrawlers.results.thumbnail}
            alt="thumbnail"
            className="img-thumbnail"
          />
        ) : (
          <img
            src={ImgNoThumbnail}
            alt="no thumbnail"
            className="img-thumbnail"
          />
        )}
        <Typography variant="body1" className="success-file-name">
          {datacrawlers.results?.title}
        </Typography>
        <IconButton onClick={() => handleClear()}>
          <img src={IconClose} alt="file-word" className="clear-icon" />
        </IconButton>
      </div>
    </StyleBoxAddSuccess>
  );

  const boxImportByUrl = () => (
    <StyledHandleByNewUrl>
      <Box className="handle-url-content">
        <TextField
          error={error}
          helperText={error && t('errorHandleByUrl')}
          fullWidth
          color="secondary"
          classes={{ input: 'paste-input' }}
          placeholder={t('exampleValidUrl')}
          value={link}
          onChange={handleChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: COLOR.white,
            },
          }}
          InputProps={{
            sx: { borderRadius: '8px', height: '40px' },
            startAdornment: (
              <InputAdornment position="start">
                <LinkRounded />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ProcessHandler loading={loading} align="center" size={24} />
              </InputAdornment>
            ),
          }}
        />
        <div className="wrap-icon">
          {LIST_ICON_WEB_SAMPLE.map((item) => (
            <img
              key={item.alt}
              src={item.icon}
              alt={item.alt}
              className="file-icon"
            />
          ))}
        </div>
      </Box>
    </StyledHandleByNewUrl>
  );

  return (
    <Dialog title={t('webLink')} open={open} fullWidth onClose={handleClose}>
      <DialogContent
        dividers
        sx={{
          '&.MuiDialogContent-root': {
            paddingTop: '24px !important',
          },
        }}
      >
        {datacrawlers && datacrawlers.status
          ? boxAddSuccess()
          : boxImportByUrl()}
        <StyleButtonSuccess>
          <Button
            variant="contained"
            color="primary"
            className="btn-continue"
            onClick={() => {
              onHandleUploadContent(datacrawlers.results.text);
              handleClose();
            }}
            endIcon={<ArrowForwardRounded />}
            sx={{
              '&.Mui-disabled': {
                background: COLOR.yellow[32],
                color: '#3c164e99',
              },
            }}
            disabled={loading || !datacrawlers}
          >
            {t('continue')}
          </Button>
        </StyleButtonSuccess>
      </DialogContent>
    </Dialog>
  );
};

export default NewImportURL;
