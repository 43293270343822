import React, { useState, useEffect, useRef } from 'react';
import Table from '@src/components/Table';
import { useTranslation } from 'react-i18next';
import Card from '@src/components/Card';
import apis from '@src/apis';
import { DEVICE_STATUS } from '@src/constants/device';
import moment from 'moment';
import { PAGINATION_LIMIT } from '@src/constants';
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import NotificationDialog from '@src/components/NotificationDialog';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import LetterImage from '@src/assets/images/letter.png';
import { LANDING_PAGE_URL, NOTIFICATION_URL, VBEE_HOTLINE } from '@src/configs';
import actions from '@src/redux/actions';
import { getCookie } from '@src/utils/cookie';
import useCustomKeycloak from '@src/hooks/useCustomKeycloak';
import {
  StyledActionSelectedDevices,
  StyledChip,
  StyledManagerDevice,
  StyledOSName,
} from './index.styled';
import { StyledActionWarning } from '../TTS/Requests/index.style';

const ManageDevice = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const user = useSelector((state) => state.user || {});
  const userInfo = useSelector((state) => state.auth.user || {});
  const [devices, setDevices] = useState([]);
  const [totalFetchDevices, setTotalFetchDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedOfflineDevices, setSelectedOfflineDevices] = useState([]);
  const [loginDevices, setLoginDevices] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showConfirmLogoutDialog, setShowConfirmLogoutDialog] = useState(false);
  const [showNotiSendMailDialog, setShowNotiSendEmailDialog] = useState(false);
  const [showNotiLogoutOfflineDevice, setShowNotiLogoutOfflineDevices] =
    useState(false);

  const responseDevice = useSelector((state) => state.device);
  const dispatch = useDispatch();

  const ws = useRef(null);

  const { keycloak, removeKcTokenCookie } = useCustomKeycloak();

  const renderStatus = (status) => {
    switch (status) {
      case DEVICE_STATUS.ONLINE:
        return <StyledChip color="success">{t(status)}</StyledChip>;
      case DEVICE_STATUS.OFFLINE:
        return <StyledChip color="error">{t(status)}</StyledChip>;
      case DEVICE_STATUS.RESTRICTED:
        return <StyledChip color="light">{t(status)}</StyledChip>;
      default:
        return <div />;
    }
  };

  const fetchDevices = async () => {
    setLoading(true);
    const data = await apis.devices.getDevices({
      sort: 'lastLoginAt_desc',
      offset: (page - 1) * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
    });
    if (data?.status) {
      setDevices(data?.result?.devices);
      setTotalFetchDevices([
        ...new Set([...devices, ...data?.result?.devices]),
      ]);
      setTotal(data?.result?.total);
      setLoginDevices(data?.result?.loginDevice);
    }
    setLoading(false);
  };

  const callApiLogoutDevices = async (logoutDevices) => {
    const deviceIds = logoutDevices.map((device) => device.id);
    if (deviceIds.length === 0) return;
    if (deviceIds.length === 1) {
      const detailDevice = totalFetchDevices.filter((device) =>
        deviceIds.includes(device.id),
      )[0];
      const fingerprint =
        getCookie('fingerprint') || localStorage.getItem('fingerprint');
      if (detailDevice.fingerprint === fingerprint) {
        await apis.devices.deactivateDevice(fingerprint);
        removeKcTokenCookie();
        keycloak.logout({ redirectUri: LANDING_PAGE_URL });
        return;
      }
    }

    setShowNotiSendEmailDialog(true);
    setSelectedDevices([]);
    setSelectedOfflineDevices([]);
    await apis.devices.sendEmailConfirmLogoutDevices(userInfo.email, deviceIds);
  };

  const handleChangePage = (value) => setPage(value);

  const renderOSName = (os, fingerprintDevice) => {
    const fingerprint =
      getCookie('fingerprint') || localStorage.getItem('fingerprint');

    return fingerprintDevice === fingerprint ? (
      <StyledOSName>
        <div>{t('thisDevice')}</div>
        <div className="blue-dot" />
      </StyledOSName>
    ) : (
      os?.name
    );
  };

  const handleSelectDevices = (deviceIds) => {
    setSelectedDevices(deviceIds);
    let offlineDevices = totalFetchDevices
      .filter(
        (device) =>
          deviceIds.includes(device.id) &&
          device.status === DEVICE_STATUS.OFFLINE,
      )
      .map((device) => device.id);
    offlineDevices = [...new Set(offlineDevices)];

    setSelectedOfflineDevices(offlineDevices);
  };

  const handleShowConfirmLogoutDialog = async () =>
    setShowConfirmLogoutDialog(true);

  const handleCloseConfirmLogoutDialog = () => {
    setSelectedDevices([]);
    setSelectedOfflineDevices([]);
    setShowConfirmLogoutDialog(false);
  };

  const handleCloseNotiLogoutOfflineDevices = () =>
    setShowNotiLogoutOfflineDevices(false);

  const handleConfirmForceLogoutDevices = async () => {
    setShowConfirmLogoutDialog(false);
    setShowNotiLogoutOfflineDevices(false);
    const deviceIds = selectedDevices.filter(
      (deviceId) => !selectedOfflineDevices.includes(deviceId),
    );
    await callApiLogoutDevices(deviceIds);
  };

  const handleConfirmLogoutDevices = async () => {
    setShowConfirmLogoutDialog(false);
    if (selectedOfflineDevices.length > 0) {
      setShowNotiLogoutOfflineDevices(true);
      return;
    }
    await callApiLogoutDevices(selectedDevices);
  };

  const handleCloseNotiSendEmail = () => setShowNotiSendEmailDialog(false);

  const renderConfirmLogoutDevicesAction = (handleClose, handleConfirm) => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleClose}>
        {t('noValue')}
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const handleCancelSelectDevices = () => {
    setSelectedDevices([]);
    setSelectedOfflineDevices([]);
  };

  useEffect(() => {
    fetchDevices();
  }, [page]);

  useEffect(() => {
    ws.current = new WebSocket(NOTIFICATION_URL);
  }, []);

  useEffect(() => {
    const newDevices = devices.map((device) => {
      if (
        responseDevice[device.fingerprint]?.status &&
        device.status !== responseDevice[device.fingerprint]?.status
      ) {
        return {
          ...device,
          status: responseDevice[device.fingerprint]?.status,
        };
      }
      return device;
    });

    const newTotalFetchDevices = totalFetchDevices.map((device) => {
      if (
        responseDevice[device.fingerprint]?.status &&
        device.status !== responseDevice[device.fingerprint]?.status
      ) {
        return {
          ...device,
          status: responseDevice[device.fingerprint]?.status,
        };
      }
      return device;
    });

    setDevices(newDevices);
    setTotalFetchDevices(newTotalFetchDevices);
    dispatch(actions.device.removeDevice(Object.keys(responseDevice)));
  }, [responseDevice]);

  const columns = [
    {
      field: 'os',
      title: t('operatingSystem'),
      sortable: false,
      align: 'left',
      render: (row) => renderOSName(row?.os, row?.fingerprint),
    },
    {
      field: 'browser',
      title: t('browser'),
      sortable: false,
      align: 'left',
      render: (row) => `${row?.client?.name}`,
    },
    {
      field: 'status',
      title: t('status'),
      sortable: false,
      align: 'left',
      render: (row) => renderStatus(row?.status),
    },
    {
      field: 'lastLoginAt',
      title: t('lastLoginAt'),
      sortable: true,
      align: 'left',
      render: (row) => moment(row.lastLoginAt).format('DD/MM/YYYY - HH:mm'),
    },
    {
      field: 'ip',
      title: t('ipAddress'),
      sortable: true,
      align: 'left',
    },
  ];

  return (
    <div className="">
      <Card>
        <StyledManagerDevice>
          <div className="manager-device-header">
            <div className="title">{t('manageDevice')}</div>
            {user?.usingPackage?.device ? (
              <div
                className="description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('loginDevicesDescription', {
                    loginDevices: loginDevices.toLocaleString(language),
                    maxDevices:
                      userInfo.maxDevices?.toLocaleString(language) ||
                      user?.usingPackage?.device?.toLocaleString(language),
                  }),
                }}
              />
            ) : null}
          </div>
          <Table
            columns={columns}
            data={devices}
            page={page}
            total={total}
            selected={selectedDevices}
            selection
            loading={loading}
            onChangePage={handleChangePage}
            onSelect={handleSelectDevices}
          />
        </StyledManagerDevice>
      </Card>
      <Card>
        <StyledActionSelectedDevices>
          <p>
            {t('selectedDevices', { numberDevices: selectedDevices.length })}
          </p>
          <div className="action-buttons">
            <Button variant="outlined" onClick={handleCancelSelectDevices}>
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={!selectedDevices.length}
              onClick={handleShowConfirmLogoutDialog}
            >
              {t('logout')}
            </Button>
          </div>
        </StyledActionSelectedDevices>
      </Card>
      <NotificationDialog
        name="ssml"
        title={t('confirmLogoutDevicesTitle')}
        description={t('confirmLogoutDevicesDescription', {
          numberDevices: selectedDevices.length,
        })}
        variant="warning"
        open={showConfirmLogoutDialog}
        onClose={handleCloseConfirmLogoutDialog}
        actionComponent={renderConfirmLogoutDevicesAction(
          handleCloseConfirmLogoutDialog,
          handleConfirmLogoutDevices,
        )}
      />

      <NotificationDialog
        name="ssml"
        title={t('logoutOfflineDevicesTitle')}
        description={t('logoutedDevicesDescription', {
          numberDevices: selectedOfflineDevices.length,
        })}
        variant="warning"
        open={showNotiLogoutOfflineDevice}
        onClose={handleCloseNotiLogoutOfflineDevices}
        actionComponent={renderConfirmLogoutDevicesAction(
          handleCloseNotiLogoutOfflineDevices,
          handleConfirmForceLogoutDevices,
        )}
      />

      <ActionDialog
        image={LetterImage}
        open={showNotiSendMailDialog}
        title={t('sendEmailTitle')}
        description={t('sendEmailDescription', { hotline: VBEE_HOTLINE })}
        onClose={handleCloseNotiSendEmail}
      />
    </div>
  );
};

export default ManageDevice;
