import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import ReserveBackground from '@src/assets/banners/reserve-banner.png';
import AdsBackground from '@src/assets/banners/ads.png';
import { FLASH_SALE_BANNER_HEIGHT, TEXT_SHADOW } from '@src/styles/config';

const StyledHeaderLockCharacterBanner = styled('div')`
  .banner-item {
    background-color: ${COLOR.primary};
    background-image: ${(props) =>
      `url(${props.background || ReserveBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    height: ${FLASH_SALE_BANNER_HEIGHT}px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .auto-renewal-offer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .title {
      text-shadow: ${TEXT_SHADOW};
      font-size: 1px;
      font-weight: 860;
      text-transform: uppercase;
    }
  }

  .register-button {
    font-size: 12px;
    border-radius: 480px;
    font-weight: 510;
    padding: 6px;
    text-transform: none;
    height: 32px;
    margin-bottom: 8px;

    &:hover {
      opacity: 0.9;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  .icon {
    cursor: pointer;
  }

  .tooltip-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
  }

  .title {
    font-size: 16px;
    font-weight: 860;
    line-height: 19.2px;
    color: ${COLOR.white};
  }

  .description {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: ${COLOR.white};
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .button-highlight {
      text-transform: uppercase;
      color: ${COLOR.darkBlue};
      font-weight: 700;
      font-size: 14px;
    }
    margin-right: 16px;
  }

  .button {
    background: ${COLOR.white};
    color: ${COLOR.darkBlue};
    height: 28px;
    padding: 4px 8px;
    border-radius: 500px;
    text-transform: none;
    display: flex;
    gap: 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;

    &:hover {
      opacity: 0.9;
      background: ${COLOR.white};
    }
  }
`;

const OtherVoucherBannerContainer = styled('div')`
  .banner-item {
    background-color: ${COLOR.primary};
    background-image: ${(props) => `url(${props.background || AdsBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    height: ${FLASH_SALE_BANNER_HEIGHT}px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .register-button {
    font-size: 12px;
    border-radius: 480px;
    font-weight: 510;
    padding: 6px;
    text-transform: none;
    height: 32px;
    margin-bottom: 8px;

    &:hover {
      opacity: 0.9;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  .icon {
    cursor: pointer;
  }

  .tooltip-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
  }

  .title {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    color: ${COLOR.darkBlue};
    text-transform: uppercase;
    word-wrap: break-word;
  }

  .description {
    display: flex;
    gap: 4px;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    color: ${COLOR.darkBlue};
    word-wrap: break-word;
  }

  .countdown-in-header {
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    color: ${COLOR.darkBlue};
    word-wrap: break-word;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .button-highlight {
      text-transform: uppercase;
      color: ${COLOR.darkBlue};
      font-weight: 700;
      font-size: 14px;
    }
    margin-right: 16px;
  }

  .button {
    background: ${COLOR.white};
    color: ${COLOR.darkBlue};
    height: 28px;
    padding: 4px 8px;
    border-radius: 500px;
    text-transform: none;
    display: flex;
    gap: 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;

    &:hover {
      opacity: 0.9;
      background: ${COLOR.white};
    }
  }
`;

export { StyledHeaderLockCharacterBanner, OtherVoucherBannerContainer };
