import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Table from '@src/components/Table';
import LinearStatus from '@src/components/LinearStatus';
import BadgeAvatar from '@src/components/BadgeAvatar';
import { REQUEST_STATUS } from '@src/constants/voice';
import { getCookie, setCookie } from '@src/utils/cookie';
import { extractVoiceName } from '@src/services/voice';
import { VBEE_DOMAIN } from '@src/configs';
import { TIME_CONVERT_THOUSANDS_CHARACTERS } from '@src/constants';
import i18n from '@src/languages';
import RequestAction from './RequestAction';
import TitleTable from './TitleTable';
import { StyledChip, StyledListDubbing, StyleVoiceItem } from './index.style';
import ErrorReport from './ErrorReport';
import RatingPopUp from '../RatingPopUp';

const ListDubbing = ({
  data,
  total,
  loading,
  sort,
  page,
  handleChangePage,
  handleChangeSort,
}) => {
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openRatingPopUp, setOpenRatingPopUp] = useState(false);

  const { t } = useTranslation();
  const { language } = i18n;

  const { user } = useSelector((state) => state.auth);
  const { languages } = useSelector((state) => state.language);

  const setShowRatingPopUp = (value) => {
    setCookie({
      cname: `showRatingPopUp-${user.id}`,
      cvalue: value,
      extime: Infinity,
      domain: VBEE_DOMAIN,
    });
  };

  const handleOpenRatingPopUp = () => {
    if (total < 2) return;

    const showRatingPopUpCookie = getCookie(`showRatingPopUp-${user?.id}`);

    if (showRatingPopUpCookie === 'false') return;
    if (!showRatingPopUpCookie) setShowRatingPopUp(true);
    setOpenRatingPopUp(true);
  };

  const handleOpenErrorReport = (request) => {
    setOpenErrorReport(true);
    setSelectedRequest(request);
  };

  const handleCloseErrorReport = () => {
    setOpenErrorReport(false);
    setSelectedRequest(null);
  };
  const renderStatus = ({ status, progress, processingAt, characters }) => {
    const timeConvert = (characters / 1000) * TIME_CONVERT_THOUSANDS_CHARACTERS;
    let processConvert;
    if (progress > 0) processConvert = progress;
    else processConvert = processingAt ? 1 : 0; // 1 is 1% - start percent when request start processing

    switch (status) {
      case REQUEST_STATUS.IN_PROGRESS:
      case REQUEST_STATUS.SUCCESS:
        return (
          <LinearStatus
            color="info"
            progressConvert={processConvert}
            startTime={processingAt}
            time={timeConvert}
            requestStatus={status}
          />
        );
      case REQUEST_STATUS.FAILURE:
        return <StyledChip color="error">{t(status)}</StyledChip>;
      default:
        return <div />;
    }
  };

  const renderVoice = (voice) => {
    const { voiceName } = extractVoiceName({
      name: voice?.name,
      provider: voice?.provider,
    });
    const smallImgSrc = languages.find(
      (lang) => lang?.value === voice?.languageCode,
    )?.roundImage;

    return (
      <StyleVoiceItem>
        <BadgeAvatar
          width={30}
          img={voice?.roundImage}
          type="image"
          smallImgWidth={10}
          smallImg={smallImgSrc}
        />
        <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
          {voiceName}
        </Typography>
      </StyleVoiceItem>
    );
  };

  const columns = [
    {
      field: 'title',
      title: t('title'),
      align: 'left',
      sortable: false,
      width: 'full',
      cellWidth: 'full',
      render: (row) => (
        <TitleTable title={row.title} createdAt={row.createdAt} />
      ),
    },
    {
      field: 'voice',
      title: t('voice'),
      align: 'left',
      sort: true,
      width: '140px',
      cellWidth: '140px',
      render: (row) => renderVoice(row?.voice),
    },
    {
      field: 'characters',
      title: t('character'),
      align: 'right',
      width: '100px',
      cellWidth: '100px',
      sort: true,
      render: (row) => row?.characters?.toLocaleString(language) || 0,
    },
    {
      field: 'audioType',
      title: t('audioType'),
      align: 'center',
      width: '100px',
      cellWidth: '100px',
    },
    {
      field: 'status',
      title: t('status'),
      sort: true,
      sortable: false,
      align: 'left',
      width: '100px',
      cellWidth: '100px',
      render: (row) =>
        renderStatus({
          status: row.status,
          progress: row.progress,
          processingAt: row.processingAt,
          characters: row.characters,
        }),
    },
  ];

  const actionCell = {
    field: 'action',
    title: t('action'),
    sortable: false,
    align: 'center',
    width: '100px',
    cellWidth: '100px',
    render: (row) => (
      <RequestAction
        request={row}
        onOpenErrorReport={handleOpenErrorReport}
        onOpenRatingPopUp={handleOpenRatingPopUp}
      />
    ),
  };

  return (
    <StyledListDubbing>
      <Table
        loading={loading}
        columns={[...columns, actionCell]}
        data={data}
        sort={sort}
        page={page}
        total={total}
        headerPadding="16px 12px"
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
        minWidth="1170px"
        footerPadding="0px 20px 40px"
      />
      <ErrorReport
        open={openErrorReport}
        request={selectedRequest}
        onClose={handleCloseErrorReport}
      />
      <RatingPopUp
        open={openRatingPopUp}
        onClose={() => setOpenRatingPopUp(false)}
        setShowRatingPopUpCookie={setShowRatingPopUp}
      />
    </StyledListDubbing>
  );
};

export default ListDubbing;
