import { styled } from '@mui/material/styles';
import { Accordion, Button, Dialog } from '@mui/material';
import { COLOR, TEXT_BUTTON_COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledVoices = styled('div')`
  .dialog-action {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }

  .dialog-action-footer {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px;
    background: ${COLOR.background};
    padding: 20px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .action-button {
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: ${COLOR.primary};
    cursor: pointer;
    font-weight: 600;

    &.active {
      color: ${COLOR.primary};
    }
  }

  .action-icon {
    margin-left: 5px;
    color: ${COLOR.darkBlue};
  }

  .content {
    display: flex;
  }

  .label-checkbox > span {
    font-size: 12px;
  }
  .btn-choose-voice {
    padding: 8px 20px;
    font-size: 12px;
  }

  .voice-active {
    display: flex;
  }

  .voice-search {
    margin-right: 15px;
    display: none;
    &.active {
      display: block;
    }
  }

  .voice-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 10px;
    margin-bottom: 15px;
  }

  .voice-detail {
    padding: 5px 20px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }

  .Mui-selected {
    color: ${COLOR.darkBlue} !important;
  }

  .search-voice {
    width: 270px;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: flex;
      width: ${(props) => props.openSearch && 'calc(100% / 3)'};
    }

    .voice-search {
      width: 100%;
    }

    .voice-content {
      width: ${(props) => (props.openSearch ? 'calc(100% * 2 / 3)' : '100%')};
    }

    .voice-detail {
      display: flex;
      width: ${(props) => props.openSearch && 'calc(100% / 5)'};
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: none;
    }

    .voice-content {
      width: 100%;
    }
  }
`;

const StyledVoiceItem = styled(Button)`
  padding: 10px;
  background: ${(props) =>
    props.active ? TRANSPARENT_COLOR.primary : COLOR.white};
  color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.active || props.playingAudio ? COLOR.primary : COLOR.white};
  text-transform: capitalize;
  opacity: ${(props) => (props.disabledVoice ? 0.6 : 1)};

  &:hover {
    border-width: 2px;
    border-color: ${COLOR.primary};
    background: ${(props) =>
      props.active ? TRANSPARENT_COLOR.primary : COLOR.white};
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
  }

  &:focus {
    border-width: 2px;
    border-color: ${COLOR.primary};
    background: ${(props) =>
      props.active ? TRANSPARENT_COLOR.primary : COLOR.white};
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
  }

  .voice-info {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .detail-voice {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .voice-name {
    margin-left: 10px;
    font-size: 16px;
    text-align: left;
  }

  .features {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.subText)};
    margin-left: 10px;
  }

  .play-icon {
    color: ${(props) => props.active && COLOR.darkBlue};
  }
`;

const StyledVoicesSearch = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
  padding-right: 10px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .title {
    flex: 1;
    font-weight: bold;
  }

  .reset {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${COLOR.blue};
  }

  .reset-title {
    margin-right: 10px;
    font-weight: bold;
  }
`;

const StyledSearch = styled(Accordion)`
  box-shadow: none;

  .checkbox-color {
    color: ${COLOR.darkBlue} !important;
  }

  .search-item-title {
    padding: 0;
    min-height: 0 !important;
  }

  .accordion-expanded {
    margin: 0 !important;
  }

  .search-item-content {
    padding: 0;
  }

  .form-group {
    display: flex;
    flex-direction: column;
  }

  .checkbox-label {
    font-size: 14px;
  }
`;

const StyledMobileVoicesFilter = styled(Dialog)`
  .close-button {
    position: absolute;
    right: 0;
  }

  .mobile-filter-voice-wrapper {
    padding: 40px 20px;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    display: block;
  }
`;

const StyledVoiceDetail = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .title {
    flex: 1;
    font-weight: bold;
  }
`;

const StyledVoiceDetailItem = styled('div')`
  overflow-x: hidden;
  padding-right: 10px;
  margin-bottom: 17px;

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
  }

  .detail {
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
  }

  .male {
    color: ${COLOR.labelBlue};
    background: ${TEXT_BUTTON_COLOR.info.hover};
  }

  .female {
    color: ${COLOR.danger};
    background: ${TEXT_BUTTON_COLOR.error.hover};
  }

  .basicVoice {
    color: ${COLOR.subText};
    background: ${TEXT_BUTTON_COLOR.secondary.hover};
  }

  .proVoice {
    color: ${COLOR.orange};
    background: ${TEXT_BUTTON_COLOR.warning.hover};
  }
`;

export {
  StyledVoices,
  StyledVoiceItem,
  StyledVoicesSearch,
  StyledSearch,
  StyledMobileVoicesFilter,
  StyledVoiceDetail,
  StyledVoiceDetailItem,
};
