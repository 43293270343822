import { RESOURCE } from '@src/constants';
import axios from 'axios';
import api from './api';

export const uploadByPresignedUrl = async (url, file) => {
  try {
    const response = await axios({
      method: 'PUT',
      url,
      data: file,
    });

    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const uploadFileToGoogleStorage = async (url, file, contentType) => {
  try {
    const response = await axios({
      method: 'PUT',
      url,
      data: file,
      headers: {
        'Content-Type': contentType,
      },
    });

    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const crawlNewsByUrl = async (link) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.CRAWLERS}/link`,
      data: { link },
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};
