import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { getPackageName } from '@src/services/package';
import Card from '@src/components/Card';
// import GiftIcon from '@src/assets/icons/black-gift-icon.svg';
import { checkVietNam } from '@src/utils/checkCountry';
import { StyledSubscriptionInfo } from './index.style';

const SubscriptionInfo = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVietNam = checkVietNam();

  const { user } = useSelector((state) => state.auth);
  const { usingPackage, lastExpiredOrder } = useSelector((state) => state.user);

  const packageName = usingPackage.id
    ? getPackageName(usingPackage.name, usingPackage.code, language)
    : getPackageName(
        lastExpiredOrder?.package?.name,
        user.packageCode,
        language,
      );

  const nextPaymentDate = moment(user.packageExpiryDate).format('DD/MM/YYYY');
  const originalPrice = usingPackage?.price;

  const renderLocalizationPrice = (price) => (
    <div>
      {!isVietNam && <span className="discount-unit-price text-16">$</span>}
      {isVietNam && (
        <span className="discount-unit-price underline text-16">đ</span>
      )}
      {price?.toLocaleString(language)}
    </div>
  );

  return (
    <StyledSubscriptionInfo>
      <Card className="card">
        <Typography className="title">{t('orderInfo')}</Typography>
        <Box display="flex" flexDirection="column" gap="8px">
          <Box className="content">
            <Typography className="text-content-bold">
              {t('subscriptionInfo')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="12px">
          <Box className="description-item">
            <Typography>{t('pack')}</Typography>
            <Box display="flex" gap="8px">
              <Typography>{packageName}</Typography>
              <Box className="auto-renewal">{t('autoRenewal')}</Box>
            </Box>
          </Box>
          <Box className="description-item">
            <Typography>{t('packagePrice')}</Typography>
            <Typography>{renderLocalizationPrice(originalPrice)}</Typography>
          </Box>
          <Box className="description-item">
            <Typography>{t('nextPayment')}</Typography>
            <Typography>{nextPaymentDate}</Typography>
          </Box>
          <Box className="description-item">
            <Typography>{t('subscriptionCycle')}</Typography>
            <Typography>
              {usingPackage?.expiresIn} {t('days')}
            </Typography>
          </Box>
        </Box>
        {/* <Divider />
         <Box className="content">
          <Typography className="text-content-bold">
            {t('discountTitle')}
          </Typography>
        </Box>
        <Box className="description-item">
          <Typography className="discount-title">
            <Box className="voucher-gift-icon">
              <img src={GiftIcon} alt="gift-icon" />
            </Box>
            {t('discountWhenUseSubscription', { percent: 20 })}
          </Typography>
          <Typography className="discount-title">
            - {renderLocalizationPrice(discountPrice)}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Typography className="text-content-bold">{t('total')}</Typography>
          <Typography className="text-content-bold">
            {renderLocalizationPrice(packagePrice)}
          </Typography>
        </Box> */}
      </Card>
    </StyledSubscriptionInfo>
  );
};

export default SubscriptionInfo;
