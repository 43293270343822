import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Typography } from '@mui/material';
import {
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '@src/styles/config';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import api from '@src/apis';
import actions from '@src/redux/actions';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import NewConfirmDialog from '@src/components/Dialog/NewConfirmDialog';
import { StyledConfigAutoRenewFooter } from './index.style';

const ConfigAutoRenewFooter = ({ paymentMethodId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { subscriptionPurchase } = useSelector((state) => state.user);
  const { openSidebar } = useSelector((state) => state.layout);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { getFeatureValue } = useFeatureFlags();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let useNewSideBar = getFeatureValue(FEATURE_KEYS.NEW_SIDEBAR);
  useNewSideBar = useNewSideBar && useNewTtsUI;

  const isSubscriptionActive =
    subscriptionPurchase?.status === SUBSCRIPTION_PURCHASE_STATUS.ACTIVE;

  const getSidebarWidth = () => {
    let sidebarWidth = 0;
    if (useNewSideBar) {
      sidebarWidth = openSidebar ? NEW_SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED;
    } else {
      sidebarWidth = openSidebar ? SIDEBAR_WIDTH : 0;
    }
    return sidebarWidth;
  };

  const handleRegisterAutoRenew = async () => {
    const response = await api.subscription.registerAutoRenew({
      paymentMethodId,
    });
    const { status, result } = response || {};
    if (status === 1) {
      const { paymentInfo } = result || {};
      const paymentLink = paymentInfo?.link;
      window.location.assign(paymentLink);
    } else {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'registerAutoRenewFail',
        }),
      );
    }
  };

  const handleCancelAutoRenew = async () => {
    const res = await api.subscription.cancelSubscription();
    if (res?.status !== 1) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'cancelSubscriptionFail',
        }),
      );
    } else {
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'cancelSubscriptionSuccess',
        }),
      );
      dispatch(actions.user.updateSubscriptionPurchase({}));
    }
    setOpenConfirmDialog(false);
  };

  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);
  const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);

  return (
    <StyledConfigAutoRenewFooter sidebarwidth={getSidebarWidth()}>
      {isSubscriptionActive ? (
        <div className="container">
          <Typography
            className="term"
            dangerouslySetInnerHTML={{ __html: t('cancelAutoRenewTerm') }}
          />
          <Button
            className="agreeToPayButton"
            variant="contained"
            onClick={handleOpenConfirmDialog}
          >
            {t('cancelAutoRenew')}
          </Button>
        </div>
      ) : (
        <div className="container">
          <div className="">
            <Typography
              className="term"
              dangerouslySetInnerHTML={{ __html: t('registerRenewTerm') }}
            />
          </div>
          <Button
            className="agreeToPayButton"
            variant="contained"
            onClick={handleRegisterAutoRenew}
          >
            {t('registerAutoRenew')}
          </Button>
        </div>
      )}
      <NewConfirmDialog
        open={openConfirmDialog}
        title={t('confirmCancelAutoRenewTitle')}
        description={t('confirmCancelAutoRenewDescription')}
        leftButtonMessage={t('agree')}
        rightButtonMessage={t('notAgree')}
        onClose={handleCloseConfirmDialog}
        onHandleLeftButton={handleCancelAutoRenew}
        onHandleRightButton={handleCloseConfirmDialog}
      />
    </StyledConfigAutoRenewFooter>
  );
};

export default ConfigAutoRenewFooter;
