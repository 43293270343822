/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import { LockRounded } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '@src/languages';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import BadgeAvatar from '@src/components/BadgeAvatar';
import actions from '@src/redux/actions';
import useCustomSSO from '@src/hooks/useCustomSSO';
import { checkAutoRenewalPackage, getPackageName } from '@src/services/package';
import { delimitNumber } from '@src/utils/number';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import { FREE_PACKAGE_CODES, PACKAGE_TYPE } from '@src/constants/package';
import route from '@src/constants/route';
import { VBEE_CONSOLE_URL } from '@src/configs';

import { NewUserInformationNoteWrapper } from './index.style';

const checkExpiredDate = (expiryDate) => {
  if (!expiryDate) return false;
  return moment().isAfter(expiryDate);
};

const NewInformationNote = ({ isShowUpgradeButton }) => {
  const { keycloak } = useCustomSSO();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { usingPackage, lastExpiredOrder, subscriptionPurchase } = useSelector(
    (state) => state.user,
  );

  const { remainingCharacters, bonusCharacters, lockCharacters } = useSelector(
    (state) => state.auth.user,
  );
  const { language } = i18n;
  const packageName = usingPackage.id
    ? getPackageName(usingPackage.name, usingPackage.code, language)
    : getPackageName(
        lastExpiredOrder?.package?.name,
        user.packageCode,
        language,
      );
  const { packageExpiryDate } = user;
  const isExpiredPackage = checkExpiredDate(packageExpiryDate);
  const totalCharacters =
    remainingCharacters + bonusCharacters > 0
      ? remainingCharacters + bonusCharacters
      : 0;

  // this below code is the logic to render button
  // clone from src/containers/Subcription/
  // if have time => use redux and refactor as an utils function

  const { type, code } = usingPackage;
  const isAutoRenewalPackage = checkAutoRenewalPackage(usingPackage);
  const isFreePackage = FREE_PACKAGE_CODES.includes(code);

  const handleRedirectToBuyPackage = () => {
    switch (type) {
      case PACKAGE_TYPE.STUDIO:
        history.push(
          isFreePackage ? route.PAYMENT : `${route.PAYMENT}?package=${code}`,
        );
        dispatch(actions.layout.handleChangeOpenSidebar(false));
        break;
      case PACKAGE_TYPE.API:
        window.open(
          isFreePackage
            ? `${VBEE_CONSOLE_URL}/payments`
            : `${VBEE_CONSOLE_URL}/payments?package=${code}`,
        );
        dispatch(actions.layout.handleChangeOpenSidebar(false));
        break;
      case PACKAGE_TYPE.DUBBING:
        history.push(
          isFreePackage
            ? route.PAYMENT_DUBBING
            : `${route.PAYMENT_DUBBING}?package=${code}`,
        );
        dispatch(actions.layout.handleChangeOpenSidebar(false));
        break;

      default:
        break;
    }
  };

  const handleNavigateRegisterAutoRenew = () => {
    if (type === PACKAGE_TYPE.STUDIO) {
      history.push(route.CONFIG_AUTO_RENEW);
    }
  };

  const renderPackageButton = () => {
    const enableCancelAutoRenew =
      isAutoRenewalPackage &&
      subscriptionPurchase?.status &&
      subscriptionPurchase?.status !== SUBSCRIPTION_PURCHASE_STATUS.CANCELED;

    const enableRegisterAutoRenew =
      isAutoRenewalPackage &&
      (!subscriptionPurchase?.status ||
        subscriptionPurchase?.status === SUBSCRIPTION_PURCHASE_STATUS.CANCELED);

    if (enableCancelAutoRenew) return <></>;

    if (isAutoRenewalPackage && isExpiredPackage) {
      return (
        <Button className="button-upgrade" onClick={handleRedirectToBuyPackage}>
          {t('upgradeNow')}
        </Button>
      );
    }

    if (enableRegisterAutoRenew) {
      return (
        <Button
          onClick={handleNavigateRegisterAutoRenew}
          className="button-upgrade"
        >
          {t('registerAutoRenew')}
        </Button>
      );
    }

    if (isFreePackage || isExpiredPackage) {
      return (
        <Button className="button-upgrade" onClick={handleRedirectToBuyPackage}>
          {t('upgradeNow')}
        </Button>
      );
    }

    return (
      <Button className="button-upgrade" onClick={handleRedirectToBuyPackage}>
        {t('buyMore')}
      </Button>
    );
  };

  return (
    <NewUserInformationNoteWrapper>
      <div className="header-user-info">
        <div className="avatar">
          <BadgeAvatar
            width={32}
            img={user.avatar}
            active={keycloak && keycloak.authenticated}
            name={user.firstName || user.lastName}
            number={new Date(user.createdAt)}
            icon={
              usingPackage.price > 0 ? (
                <Box className="box-icon">
                  <img
                    src={usingPackage.icon}
                    alt="package-icon"
                    className="icon"
                  />
                </Box>
              ) : null
            }
            smallImgWidth={20}
            type="icon"
            removeDefaultIcon
          />
        </div>
        <div className="user-info-wrapper">
          <div className="username">
            {`${user?.firstName} ${user?.lastName}`}
          </div>
          <div className="package-info">{packageName}</div>
        </div>
      </div>
      <div className="package-info-container">
        <div className="package-info-item">
          <div className="package-info">{t('numberCharacters')}</div>
          <div
            className={
              (isExpiredPackage && lockCharacters === 0) ||
              (!isExpiredPackage && totalCharacters === 0)
                ? 'package-info-value-expired'
                : 'package-info-value'
            }
          >
            {isExpiredPackage
              ? `${delimitNumber(lockCharacters, language)} ${t(
                  'characterNew',
                )}`
              : `${delimitNumber(totalCharacters, language)} ${t(
                  'characterNew',
                )}`}

            {isExpiredPackage && (
              <LockRounded
                sx={{
                  height: 14,
                  width: 14,
                  fontSize: 14,
                  color: '#FF3B30',
                }}
                className="lock-icon"
              />
            )}
          </div>
        </div>
        <div className="package-info-item">
          <div className="package-info">{t('expiryDate')}</div>
          <div
            className={
              packageExpiryDate && isExpiredPackage
                ? 'package-info-value-expired'
                : 'package-info-value'
            }
          >
            {packageExpiryDate
              ? isExpiredPackage
                ? t('outOfDate')
                : moment(packageExpiryDate).format('DD/MM/YYYY - HH:mm')
              : t('endless')}
          </div>
        </div>
        {isExpiredPackage && (
          <div className="package-info-item">
            <div className="package-info">{t('preservedCharactersDate')}</div>
            <div className="package-info-value">
              {moment(user?.preservationExpiryDate).format('DD/MM/YYYY')}
            </div>
          </div>
        )}
      </div>
      {isShowUpgradeButton && renderPackageButton()}
    </NewUserInformationNoteWrapper>
  );
};

export default NewInformationNote;
