import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyledNext } from './index.style';

const NextButton = ({ title }) => {
  const { t } = useTranslation();

  return (
    <StyledNext>
      <div className="button-next">
        <Typography className="title-next">{t(title)}</Typography>
      </div>
    </StyledNext>
  );
};

export default NextButton;
