const RESOURCE = {
  USERS: '/users',
  USER_TTS: '/user-tts',
  VOICES: '/voices',
  PACKAGES: '/packages',
  PAYMENT_METHODS: '/payment-methods',
  BANKS: '/banks',
  ORDERS: '/orders',
  LANGUAGES: '/languages',
  UPLOAD_PRESIGNED_URL: '/presigned-url-for-uploading',
  SHARING_PRESIGNED_URL: '/presigned-url-for-sharing',
  REQUESTS: '/requests',
  SYNTHESIS: '/synthesis',
  BACKGROUND_MUSICS: '/background-musics',
  DICTIONARY: '/dictionary',
  FEATURE_FLAG: '/feature-flags',
  VERSION: '/version',
  VOUCHERS: '/vouchers',
  BANNERS: 'banners',
  WARNINGS: 'warnings',
  CLIENT_PAUSE: '/client-pause',
  BLACK_WORDS: '/black-words',
  CRAWLERS: '/crawlers',
  DEVICES: '/devices',
  AFFILIATE_MARKETER: '/affiliateMarketer',
  AFFILIATE_TRANSACTION: '/affiliateTransaction',
  FILES: '/files',
  DUBBING: '/dubbing',
  FEEDBACKS: '/feedbacks',
  SRT: '/srt',
  VOICE_CLONING: '/voice-cloning',
  SAMPLE_SCRIPTS: '/sample-scripts',
};

const PAGINATION_LIMIT = 5;

const ZERO_BREAK_TIME_REGEX = new RegExp('<break time=0s/>');

const UPDATE_ONLINE_STATUS_INTERVAL = 5 * 60 * 1000;

const TIMER_INTERVAL = 1000;

const POPUP_TIME_OUT = 5000;

const PREDICT_SECONDS_PER_CHARACTER = 60 / 1000;

const AUTO_PLAY_INTERVAL = 7000;

const BREAK_LINE_DELIMITER = /\r?\n/;

const MAX_AUDIO_RETENTION_PERIOD = 15; // days

const SCROLL_THRESHOLD = 100;

const COUNTRY = { VN: 'VN', EN: 'EN', PH: 'PH', TH: 'TH', CN: 'CN' };

const LANGUAGE = { VN: 'vi', EN: 'en', PH: 'fil', TH: 'th', CN: 'cn' };

const VIETNAM_LOCATION = {
  city: '',
  country: 'VN',
  ip: '',
  loc: '',
  org: '',
  postal: '',
  region: '',
  timezone: 'Asia/Bangkok',
};

const HELP_CONTACT_METHOD = {
  ZALO: 'zalo',
  CALL: 'call',
};

const MOBILE_BREAKPOINT = 768;

const EVENT_TYPE = {
  STUDIO_CONVERT_TTS: 'Studio_Convert_TTS',
  STUDIO_PREVIEW_TTS: 'Studio_Preview_TTS',
  STUDIO_ACTIVE_FREE_PLAN: 'Active_FreePlan',
  BUY_PACKAGE: 'Buy_Package',
  DEVICE_MANAGER: 'Device_Manager',
  AFFILIATE_REGISTRATION: 'Affiliate_registration',
  SIGN_UP_SUCCESS: 'STUDIOStartRegistration',
};

const DATASENSES_EVENT_TYPE = {
  CHARGED: 'charged',
  MAKE_REQUEST: 'make_request',
  PREVIEW: 'preview',
  ADD_TO_CART: 'add_to_cart',
  LOGIN: 'login',
  VIEW: 'view',
  VIEW_PACKAGE: 'view_package',
  SIGN_UP_SUCCESS: 'sign_up_success',
  DOWNLOAD: 'download',
};

const IMAGE_TYPE_FILE = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

const SPECIAL_CHARACTER_REGEX = /[^a-zA-Z0-9-_]/g;
const HTML_TAG_REGEX = /<.*?>|\n/g;
const TIME_DELTA_REGEX =
  /^(?:[0-9]{2}:[0-5][0-9]:[0-5][0-9](?:[.,][0-9]{1,3})?)$/; // Eg: 00:59:59,000 or 00:59:59

const SERVICE_SELECT_TYPE = {
  STUDIO: 'STUDIO',
  API: 'API',
  DUBBING: 'DUBBING',
};

const TIME_CONVERT_THOUSANDS_CHARACTERS = 10 * 1000; // 10s

const SCREEN_TYPE = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
};

const TIME_DELAY_CALL_API_GET_USER_INFO = 3000;

const ERROR_MESSAGE_AUTH = {
  user_already_exists: {
    message: 'user_already_exists',
    description: 'userAlreadyExists',
  },
  // TODO: Add more error message
};

export {
  RESOURCE,
  PAGINATION_LIMIT,
  ZERO_BREAK_TIME_REGEX,
  UPDATE_ONLINE_STATUS_INTERVAL,
  POPUP_TIME_OUT,
  PREDICT_SECONDS_PER_CHARACTER,
  TIMER_INTERVAL,
  BREAK_LINE_DELIMITER,
  MAX_AUDIO_RETENTION_PERIOD,
  SCROLL_THRESHOLD,
  COUNTRY,
  LANGUAGE,
  HELP_CONTACT_METHOD,
  AUTO_PLAY_INTERVAL,
  VIETNAM_LOCATION,
  MOBILE_BREAKPOINT,
  EVENT_TYPE,
  DATASENSES_EVENT_TYPE,
  IMAGE_TYPE_FILE,
  SPECIAL_CHARACTER_REGEX,
  HTML_TAG_REGEX,
  TIME_DELTA_REGEX,
  SERVICE_SELECT_TYPE,
  TIME_CONVERT_THOUSANDS_CHARACTERS,
  SCREEN_TYPE,
  TIME_DELAY_CALL_API_GET_USER_INFO,
  ERROR_MESSAGE_AUTH,
};
