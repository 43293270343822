import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import apis from '@src/apis';
import {
  getCharactersExtention,
  getTimeExtention,
  getSavedPrice,
  verifyPolicy,
  verifyTime,
} from '@src/services/voucher';
import { COLOR } from '@src/styles/color';
import GiftIcon from '@src/assets/icons/black-gift-icon.svg';
import InfoIcon from '@src/assets/icons/icon-info.svg';
import Tooltip from '@src/components/Tooltip';
import { delimitNumber, getRandomIntNumber } from '@src/utils/number';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import actions from '@src/redux/actions';
import { checkVietNam } from '@src/utils/checkCountry';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';

import { getPackageCurrentPrice } from '@src/services/package';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import camelcaseKeys from 'camelcase-keys';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { StyledVoucherBox } from './index.style';

const getPromotion = (voucher, packageState, language, t) => {
  switch (voucher?.type) {
    case VOUCHER_TYPE.PRICE:
      return `${t('reduce')} ${voucher.value.toLocaleString(language)} VNĐ`;
    case VOUCHER_TYPE.PERCENT:
      return `${t('reduce')} ${voucher.value}%`;
    case VOUCHER_TYPE.CHARACTER:
      return `${t('multiply')} x${voucher.value} ${t(
        'character',
      )} (${delimitNumber(
        packageState.maxCharacters * (voucher.value - 1),
        language,
      )} ${t('character')})`;
    case VOUCHER_TYPE.TIME:
      return `${t('extra')} ${voucher.value} ${t('dayLowercase')}`;
    case VOUCHER_TYPE.PERCENT_TIME:
      return `${t('donate')} ${voucher.value}% ${t('timeLowercase')}`;

    default:
      return voucher.description;
  }
};

const SelectedVoucherBox = ({ voucher, setSelectedVoucher }) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;

  const { package: packageState } = useSelector((state) => state.order);

  const isVietNam = checkVietNam();

  const moneyVoucherType = [VOUCHER_TYPE.PERCENT, VOUCHER_TYPE.PRICE];

  const { price: currPrice, usdPrice: currUsdPrice } = getPackageCurrentPrice(
    packageState,
    true,
  );

  return (
    <>
      <Box className="selected-voucher">
        <Box display="flex" gap="4px" alignItems="center">
          <Box className="voucher-gift-icon">
            <img src={GiftIcon} alt="gift-icon" />
          </Box>
          <Typography className="voucher-name">
            {getPromotion(voucher, packageState, language, t)}
          </Typography>
        </Box>
        {moneyVoucherType.includes(voucher.type) && (
          <Typography className="voucher-value">
            -{' '}
            {!isVietNam && (
              <span className="discount-unit-price font-size-16">$</span>
            )}
            {isVietNam && (
              <span className="discount-unit-price underline font-size-16">
                đ
              </span>
            )}
            {getSavedPrice({
              voucher,
              currPrice,
              currUsdPrice,
            })?.toLocaleString(language)}
          </Typography>
        )}
        <Button
          color="primary"
          variant="outlined"
          className="selected-voucher-button"
          onClick={setSelectedVoucher}
        >
          {t('unselect')}
        </Button>
      </Box>
      <Divider />
    </>
  );
};

const VoucherBox = ({
  voucher,
  autoVouchers,
  setSelectedVoucher,
  selectedVoucher,
  checkCanApplyVoucher,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { package: packageState } = useSelector((state) => state.order);

  const autoVoucher = autoVouchers.find(
    (item) => item.voucherCode === voucher.code,
  );
  const isAutoVoucher =
    autoVoucher?.voucherCode && checkCanApplyVoucher(autoVoucher.voucherCode);
  const showVoucher = !voucher.isPrivate || isAutoVoucher;

  const VoucherInfoTooltip = () => (
    <Box className="voucher-info-tooltip" textAlign="left">
      <Typography variant="body2">{`${t('code')}: ${
        voucher?.code
      }`}</Typography>
      <Typography variant="body2">{`${t('exp')}: ${moment(
        voucher?.fakeEndTimeForVoucher || voucher.endTime,
      ).format('DD/MM/YYYY - HH:mm')}`}</Typography>
      {voucher.policy?.applyAllPackages && (
        <Typography variant="body2">{`${t('appliedCondition')}: ${t(
          'applyAllPackages',
        )}`}</Typography>
      )}
    </Box>
  );

  return showVoucher ? (
    <Box
      className="voucher"
      sx={{
        border: selectedVoucher
          ? `2px solid ${COLOR.yellow.default} !important`
          : 'none',
        boxShadow: selectedVoucher
          ? `0px 4px 16px 0px ${COLOR.black[16]} `
          : 'none',
        backgroundColor: selectedVoucher ? COLOR.yellow[8] : COLOR.white,
        ':hover': {
          backgroundColor: selectedVoucher ? COLOR.yellow[8] : COLOR.indigo[4],
        },
      }}
      onClick={setSelectedVoucher}
    >
      <Box display="flex" gap="4px" onClick={(e) => e.stopPropagation()}>
        <Typography className="voucher-name">
          {getPromotion(voucher, packageState, language, t)}
        </Typography>
        <Tooltip arrow placement="top" title={<VoucherInfoTooltip />}>
          <img src={InfoIcon} alt="gift-icon" className="voucher-gift-icon" />
        </Tooltip>
      </Box>

      <Button
        color="primary"
        variant={selectedVoucher ? 'outlined' : 'contained'}
        className={
          selectedVoucher
            ? 'voucher-button'
            : 'voucher-button selected-voucher-button'
        }
        onClick={setSelectedVoucher}
      >
        {selectedVoucher ? t('unselect') : t('apply')}
      </Button>
    </Box>
  ) : (
    <div />
  );
};

const Voucher = ({ currPackage }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const isDubbingRoute = useCheckDubbingRoute();
  const { getFeatureValue } = useFeatureFlags();
  const location = useLocation();
  const history = useHistory();
  const {
    voucherCode: voucherCodeParamURL,
    bannerType,
    package: packageCodeParamURL,
  } = camelcaseKeys(queryString.parse(location.search) || {});

  const isNewAutoSelectVoucher = getFeatureValue(
    FEATURE_KEYS.NEW_AUTO_SELECT_VOUCHER,
  );

  const { autoVouchers } = useSelector((state) => state.voucher);
  const { voucher: voucherState, package: packageState } = useSelector(
    (state) => state.order,
  );

  const [openVoucherCollapse, setOpenVoucherCollapse] = useState(false);
  const [vouchersDisplay, setVouchersDisplay] = useState([]);
  const [typingVoucher, setTypingVoucher] = useState('');
  const [error, setError] = useState(null);
  const [totalVoucher, setTotalVoucher] = useState([]);

  const { language } = i18n;
  const isVietNam = checkVietNam();

  const generateDisplayVoucherName = (voucherData) => {
    const value = delimitNumber(voucherData.value, language);

    switch (voucherData.type) {
      case VOUCHER_TYPE.PERCENT:
        return `${t('reduce')} ${value}%`;
      case VOUCHER_TYPE.PRICE:
        return `${t('reduce')} ${value}VND`;
      case VOUCHER_TYPE.CHARACTER:
        return `x${value} ${t('character')}`;
      case VOUCHER_TYPE.TIME:
        return `${t('extra')} ${value} ${t('day')}`;
      case VOUCHER_TYPE.PERCENT_TIME:
        return `${t('extra')} ${value}% ${t('day')}`;
      default:
        return '';
    }
  };

  const filterUseableVoucher = (voucherData, currPackageId) =>
    voucherData
      .filter((voucher) => {
        const satisfiedTime = verifyTime(voucher);
        const satisfiedPolicy = verifyPolicy(voucher, currPackageId);

        const isValidVoucherType =
          !isDubbingRoute || voucher.type !== VOUCHER_TYPE.CHARACTER;

        // Remove voucher decrease price vnd when pay using $
        const isValidVoucherTypeInCountry =
          isVietNam || voucher.type !== VOUCHER_TYPE.PRICE;

        const newCanUse =
          satisfiedTime &&
          satisfiedPolicy &&
          voucher.remainingAmount &&
          !voucher.isPrivate &&
          voucher.usedAmount < voucher.numReuse &&
          isValidVoucherType &&
          isValidVoucherTypeInCountry;

        const canUse =
          satisfiedTime &&
          satisfiedPolicy &&
          voucher.remainingAmount &&
          !voucher.isPrivate &&
          voucher.usedAmount < voucher.numReuse &&
          isValidVoucherType;

        return isNewAutoSelectVoucher ? newCanUse : canUse;
      })
      .map((voucher) => ({
        ...voucher,
        satisfiedTime: verifyTime(voucher),
        satisfiedPolicy: verifyPolicy(voucher, currPackageId),
        canUse: true,
      }))
      .sort((a) => (a.canUse ? -1 : 1));

  // Change route when apply voucher
  const changeRoute = (voucherCode) => {
    const search = queryString.stringify({
      package: packageCodeParamURL,
      voucherCode,
      bannerType,
    });
    history.replace({ search });
  };

  const checkSelectedVoucher = (voucherCode, vouchersToSelect, allVouchers) => {
    // Check if the selected voucher is in the list of vouchers to select
    const selectedVoucher = vouchersToSelect.find(
      (voucher) => voucher.code === voucherCode,
    );
    if (!selectedVoucher) return false;

    // Check if the selected voucher can be applied
    const matchedVoucher = allVouchers.find(
      (voucher) => voucher.code === voucherCode,
    );
    if (!matchedVoucher) return false;

    return matchedVoucher;
  };

  const applyVoucher = (matchedVoucher) => {
    dispatch(
      actions.voucher.applyVoucher({
        ...matchedVoucher,
        displayText: generateDisplayVoucherName(matchedVoucher),
      }),
    );
    dispatch(actions.order.updateVoucher(matchedVoucher));
    changeRoute(matchedVoucher.code);
  };

  const handleChangeTypingVoucher = (e) => {
    setTypingVoucher(e.target.value.toUpperCase());
    setError(null);
  };

  const validateVoucher = (voucherCode) => {
    const matchedVoucher = totalVoucher.find(
      (voucher) => voucher.code === voucherCode,
    );

    if (!matchedVoucher) return false;

    const isSatisfiedTime = verifyTime(matchedVoucher);
    const isSatisfiedPolicy = verifyPolicy(matchedVoucher, currPackage.id);
    const hasRemainingAmount = matchedVoucher.remainingAmount > 0;
    const isUnderReuseLimit =
      matchedVoucher.usedAmount < matchedVoucher.numReuse;

    return isSatisfiedTime &&
      isSatisfiedPolicy &&
      hasRemainingAmount &&
      isUnderReuseLimit
      ? matchedVoucher
      : false;
  };

  const handleApplyTypingVoucher = () => {
    const matchedVoucher = totalVoucher.find(
      (voucher) => voucher.code === typingVoucher,
    );
    if (!matchedVoucher) {
      setError('enterVoucherError');
      return;
    }

    const satisfiedTime = verifyTime(matchedVoucher);
    const satisfiedPolicy = verifyPolicy(matchedVoucher, currPackage.id);
    if (!satisfiedPolicy) {
      setError('notSatisfiedVoucherError');
      return;
    }
    if (!satisfiedTime) {
      setError('expiredVoucherError');
      return;
    }
    if (!matchedVoucher.remainingAmount) {
      setError('outOfVouchersError');
      return;
    }
    if (matchedVoucher.usedAmount >= matchedVoucher.numReuse) {
      setError('notEnoughAmountError');
      return;
    }
    applyVoucher(matchedVoucher);
    setTypingVoucher('');
    setError(null);
    setOpenVoucherCollapse(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleApplyTypingVoucher();
  };

  const checkCanApplyVoucher = (
    autoVoucherCode,
    listVoucherToSelect = vouchersDisplay,
  ) => {
    const matchedVoucher = listVoucherToSelect.find(
      (voucher) => voucher.code === autoVoucherCode,
    );

    const satisfiedPolicy = matchedVoucher
      ? verifyPolicy(matchedVoucher, currPackage.id)
      : false;

    const satisfiedTime = verifyTime(matchedVoucher);

    const validVoucher =
      satisfiedPolicy &&
      matchedVoucher &&
      satisfiedTime &&
      matchedVoucher.usedAmount < matchedVoucher.numReuse &&
      matchedVoucher.remainingAmount;

    return validVoucher;
  };

  const { price: currPrice, usdPrice: currUsdPrice } = getPackageCurrentPrice(
    packageState,
    true,
  );

  const autoSelectVoucher = (listVoucherToSelect, totalVoucherExisted) => {
    const listVoucherSortByRanking = listVoucherToSelect
      // .sort((a, b) => {
      //   if (a?.ranking === undefined && b?.ranking === undefined) return 0;
      //   if (a?.ranking === undefined) return 1;
      //   if (b?.ranking === undefined) return -1;
      //   return a.ranking - b.ranking;
      // })
      .map((voucherItem) => {
        const reducedPrice = getSavedPrice({
          voucher: voucherItem,
          currPrice,
          currUsdPrice,
        });
        return { ...voucherItem, reducedPrice };
      })
      .sort((a, b) => b.reducedPrice - a.reducedPrice);

    let canApplyVoucher = false;
    let voucherCode;
    Object.values(listVoucherSortByRanking).forEach((voucher) => {
      if (
        !canApplyVoucher &&
        checkCanApplyVoucher(voucher.code, listVoucherToSelect)
      ) {
        canApplyVoucher = true;
        voucherCode = voucher.code;
      }
    });

    if (canApplyVoucher) {
      const matchedVoucher = totalVoucherExisted.find(
        (voucher) => voucher.code === voucherCode,
      );
      dispatch(
        actions.voucher.applyVoucher({
          ...matchedVoucher,
          displayText: generateDisplayVoucherName(matchedVoucher),
        }),
      );
      dispatch(actions.order.updateVoucher(matchedVoucher));
    }
  };

  const newAutoSelectVoucher = (vouchersToSelect, allVouchers) => {
    let voucherCode = voucherCodeParamURL;
    let matchedVoucher = validateVoucher(voucherCode);
    if (matchedVoucher) {
      applyVoucher(matchedVoucher);
      return;
    }

    if (vouchersToSelect.length === 0) {
      dispatch(actions.order.updateVoucher(null));
      return;
    }

    voucherCode = vouchersToSelect[0]?.code;
    matchedVoucher = checkSelectedVoucher(
      voucherCode,
      vouchersToSelect,
      allVouchers,
    );
    if (matchedVoucher) {
      applyVoucher(matchedVoucher);
    }
  };

  const sortVouchersByType = (listVoucherToSelect) => {
    if (listVoucherToSelect.length === 0) return [];

    const listVoucherMoneyDecrease = listVoucherToSelect
      .filter(
        (voucher) =>
          voucher.type === VOUCHER_TYPE.PERCENT ||
          voucher.type === VOUCHER_TYPE.PRICE,
      )
      .map((voucherItem) => {
        const reducedPrice = getSavedPrice({
          voucher: voucherItem,
          currPrice,
          currUsdPrice,
        });
        return { ...voucherItem, reducedPrice };
      })
      .sort((a, b) => b.reducedPrice - a.reducedPrice);

    const listVoucherCharacterExtension = listVoucherToSelect
      .filter((voucher) => voucher.type === VOUCHER_TYPE.CHARACTER)
      .map((voucherItem) => {
        const charactersExtension = getCharactersExtention({
          voucher: voucherItem,
          currCharacters: packageState?.maxCharacters || 0,
        });
        return { ...voucherItem, charactersExtension };
      })
      .sort((a, b) => b.charactersExtension - a.charactersExtension);

    const listVoucherTimeExtension = listVoucherToSelect
      .filter(
        (voucher) =>
          voucher.type === VOUCHER_TYPE.TIME ||
          voucher.type === VOUCHER_TYPE.PERCENT_TIME,
      )
      .map((voucherItem) => {
        const timeExtension = getTimeExtention({
          voucher: voucherItem,
          currTime: packageState?.expiresIn || 0,
        });
        return { ...voucherItem, timeExtension };
      })
      .sort((a, b) => b.timeExtension - a.timeExtension);

    return [
      ...listVoucherMoneyDecrease,
      ...listVoucherCharacterExtension,
      ...listVoucherTimeExtension,
    ];
  };

  const selectRandomAutoVoucher = (listAutoVoucher) => {
    const randomPosition = getRandomIntNumber(0, listAutoVoucher.length - 1);
    const autoVoucher = listAutoVoucher[randomPosition];
    const { voucherCode } = autoVoucher;

    const canApplyVoucher = checkCanApplyVoucher(voucherCode, totalVoucher);

    if (canApplyVoucher) {
      const matchedVoucher = totalVoucher.find(
        (voucher) => voucher.code === voucherCode,
      );

      dispatch(
        actions.voucher.applyVoucher({
          ...matchedVoucher,
          displayText: generateDisplayVoucherName(matchedVoucher),
        }),
      );
      dispatch(actions.order.updateVoucher(matchedVoucher));
    }
  };

  const fetchTotalVouchers = async () => {
    const data = await apis.vouchers.getVouchers();
    if (data.status) {
      setTotalVoucher(data.result);
    }
  };

  const handleSelectVoucher = (voucher) => {
    if (voucherState?.id === voucher.id) {
      dispatch(actions.order.updateVoucher(null));
      changeRoute(undefined);
    } else {
      dispatch(actions.order.updateVoucher(voucher));
      changeRoute(voucher.code);
      setOpenVoucherCollapse(false);
    }
  };

  useEffect(() => {
    fetchTotalVouchers();
  }, []);

  useEffect(() => {
    if (currPackage?.code) {
      let useableVoucher = filterUseableVoucher(totalVoucher, currPackage?.id);
      if (isNewAutoSelectVoucher)
        useableVoucher = sortVouchersByType(useableVoucher);
      setVouchersDisplay(useableVoucher);

      if (isNewAutoSelectVoucher) {
        newAutoSelectVoucher(useableVoucher, totalVoucher);
      } else autoSelectVoucher(useableVoucher, totalVoucher);

      if (autoVouchers.length > 0 && !voucherState?.code)
        selectRandomAutoVoucher(autoVouchers);
    }
  }, [currPackage?.code, totalVoucher]);

  return (
    <StyledVoucherBox>
      <Typography className="info-title">{t('promotionOrder')}</Typography>
      <Box
        className="add-voucher-wrapper"
        onClick={() => setOpenVoucherCollapse(!openVoucherCollapse)}
      >
        <Typography className="add-voucher-title">
          {t('addPromotionCodeAndInvite')}
        </Typography>
        {openVoucherCollapse ? (
          <ExpandLess className="expand-icon" />
        ) : (
          <ExpandMore className="expand-icon" />
        )}
      </Box>
      {openVoucherCollapse && (
        <Box>
          <Box display="flex" gap="8px">
            <TextField
              size="small"
              className="text-field-request"
              variant="outlined"
              value={typingVoucher}
              placeholder={t('enterPromotionCode')}
              onChange={handleChangeTypingVoucher}
              onKeyDown={handleKeyDown}
            />
            <Button
              color="primary"
              variant="contained"
              className="apply-button"
              disabled={!typingVoucher}
              onClick={handleApplyTypingVoucher}
            >
              {t('apply')}
            </Button>
          </Box>
          {error && (
            <Typography className="voucher-error">{t(error)}</Typography>
          )}
        </Box>
      )}
      <Collapse in={openVoucherCollapse} timeout="auto" unmountOnExit>
        {vouchersDisplay
          ?.filter((voucher) => voucher?.satisfiedPolicy)
          .map((voucher) => (
            <VoucherBox
              voucher={voucher}
              autoVouchers={autoVouchers}
              setSelectedVoucher={() => handleSelectVoucher(voucher)}
              selectedVoucher={voucherState?.id === voucher.id}
              checkCanApplyVoucher={checkCanApplyVoucher}
            />
          ))}
      </Collapse>
      <Divider />
      {voucherState && (
        <SelectedVoucherBox
          voucher={voucherState}
          setSelectedVoucher={() => handleSelectVoucher(voucherState)}
        />
      )}
    </StyledVoucherBox>
  );
};

export default Voucher;
