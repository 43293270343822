import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { StyledResizeTextBox } from './index.style';

const ResizeTextBox = ({
  value,
  onChange,
  onClick,
  onValidate,
  placeholder,
  maxWidth,
  maxWidthxl,
  footerLabel,
}) => {
  const [inputValue, setInputValue] = useState(value || placeholder);

  const handleChangeValue = (e) => {
    if (!onValidate) return;
    const { value: text } = e.target;
    const changeable = onValidate(text);
    if (changeable) {
      if (text) setInputValue(text);
      else setInputValue(placeholder);
      onChange({ newTitle: text });
    }
  };

  useEffect(() => {
    if (value) setInputValue(value);
    else setInputValue(placeholder);
  }, [value, placeholder]);

  return (
    <StyledResizeTextBox maxWidth={maxWidth} maxwidthxl={maxWidthxl}>
      <div className="input-wrapper">
        <span className="size-span">{inputValue}</span>
        <input
          value={value}
          placeholder={placeholder}
          className="size-input"
          onChange={handleChangeValue}
          onClick={onClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </div>
      {footerLabel && (
        <Typography className="footer-label">{footerLabel}</Typography>
      )}
    </StyledResizeTextBox>
  );
};

export default ResizeTextBox;
