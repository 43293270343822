import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProcessHandler from '@src/components/ProcessHandler';
import NotificationDialog from '@src/components/NotificationDialog';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import ROUTES from '@src/constants/route';
import StarsIcon from '@mui/icons-material/Stars';
import { checkFeaturePermission } from '@src/services/tts';
import UpgradeImg from '@src/assets/images/space-shuttle.png';
import actions from '@src/redux/actions';
import { Button } from '@mui/material';
import { CUSTOMER_SUPPORT_PHONE_NUMBER } from '@src/configs';
import {
  checkCreateSrtFileSuccess,
  createSRTFile,
} from '@src/services/dubbing';
import apis from '@src/apis';
import { REQUEST_TYPE } from '@src/constants/request';
import { REQUEST_STATUS } from '@src/constants/voice';
import datasenses from '@src/services/dataSenses';
import { StyledConvertButton, StyledUpdateButton } from './index.style';

const ConvertButton = ({
  totalSeconds,
  onChangeRequestLoading,
  hasDeleteSentences,
  setHasDeleteSentences,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = history.location.pathname.split('/').pop();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [openWrongSyntaxDialog, setOpenWrongSyntaxDialog] = useState(null);
  const [actionDialog, setActionDialog] = useState();

  // Redux
  const { user } = useSelector((state) => state.auth);
  const { dubbing } = user;
  const {
    title,
    voice,
    audioType,
    sentences,
    speed,
    projectInfo,
    openSplitVoices,
    changedProjectInfo,
  } = useSelector((state) => state.dubbingRequest);
  const { ttsUser } = useSelector((state) => state.user);
  const {
    title: changedTitle,
    voiceCode: changedVoiceCode,
    speed: changedSpeed,
    sentencesChanged,
  } = useSelector((state) => state.dubbingRequest.changedProjectInfo);

  // Feature Flags
  const { getFeatureValue } = useFeatureFlags();

  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isExpiredPackage = isProcessDubbingByUnitSecond
    ? user?.dubbing?.packageExpiryDate &&
      moment().isAfter(user?.dubbing?.packageExpiryDate)
    : user?.packageExpiryDate && moment().isAfter(user.packageExpiryDate);

  const handleUpgradeNow = () => history.push(ROUTES.PAYMENT_DUBBING);

  const checkVoicePermission = () =>
    !voice?.features?.length ||
    voice.features.every((item) =>
      checkFeaturePermission(ttsUser?.dubbing?.features, item),
    );

  const renderUpgradeButton = () => (
    <StyledUpdateButton onClick={handleUpgradeNow} variant="contained">
      <StarsIcon />
      {t('upgradeNow')}
    </StyledUpdateButton>
  );

  const showNotificationDialog = (message, severity) => {
    dispatch(
      actions.noti.push({
        message,
        severity,
      }),
    );
  };

  const validateInputDubbingRequest = () => {
    const newTitle = changedTitle !== title ? changedTitle : title;
    if (newTitle.length === 0) {
      showNotificationDialog('projectTitleCannotBeEmpty', 'error');
      return false;
    }

    // Check if package is expired
    if (isExpiredPackage) {
      setActionDialog({
        image: UpgradeImg,
        title: t('packageExpired'),
        description: t('notePackageExpired'),
        onClose: () => setActionDialog(),
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    // Check if user has permission to use voice
    const canUseVoice = checkVoicePermission();
    if (!canUseVoice) {
      showNotificationDialog('limitedVoiceDescription', 'warning');
      return false;
    }

    if ((totalSeconds || 0) > (dubbing?.remainingSeconds || 0)) {
      setActionDialog({
        image: UpgradeImg,
        title: t('notEnoughDuration'),
        description: t('noteNotEnoughDuration'),
        onClose: () => setActionDialog(),
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    const detailErrors = [];
    if (!voice) detailErrors.push(t('voiceCannotBeEmpty'));

    if (detailErrors.length > 0) {
      setOpenWrongSyntaxDialog({
        name: 'error-syntax',
        variant: 'warning',
        title: t('dubbingRequestInvalid'),
        description: t('dubbingRequestInvalidDescription'),
        subDescription: detailErrors.join('; '),
      });
      return false;
    }
    return true;
  };

  const updateSentences = (oldSentences, changedSentences) => {
    const newSentences = { ...oldSentences };
    let hasChangedContent = false;

    if (Object.keys(changedSentences).length === 0) {
      if (!openSplitVoices) {
        Object.keys(newSentences).forEach((key) => {
          newSentences[key].voiceCode = changedVoiceCode;
        });
      }

      return { newSentences, hasChangedContent };
    }

    // changedSentences is an object with key is index of sentence and value is things that changed in that sentence
    // Example: changedSentences = { 0: { content: 'new content' }, 1: { voiceCode: 'new voice code' } }
    Object.keys(changedSentences).forEach((key) => {
      // Check if content of sentence is changed and update it
      const checkContentChanged =
        changedSentences[key] &&
        'content' in changedSentences[key] &&
        oldSentences[key] &&
        changedSentences[key].content !== oldSentences[key].content;

      if (checkContentChanged) {
        hasChangedContent = true;
        newSentences[key].content = changedSentences[key].content;
      }
      if (
        changedSentences[key]?.voiceCode &&
        oldSentences[key] &&
        openSplitVoices
      ) {
        newSentences[key].voiceCode = changedSentences[key].voiceCode;
      }
    });

    return { newSentences, hasChangedContent };
  };

  // Helper function to handle subtitle file creation
  const handleSubtitleFileCreation = async ({
    hasChangedContent,
    fileTitle,
    newSentences,
    currentLink,
  }) => {
    if (hasChangedContent || hasDeleteSentences) {
      const { error: isError } = await checkCreateSrtFileSuccess(
        `${fileTitle}.srt`,
        newSentences,
      );
      if (isError) return { invalidFormat: true, fileUrl: null };

      const { error, fileUrl } = await createSRTFile(
        `${fileTitle}.srt`,
        newSentences,
      );
      if (error) return { fileUrl: null };
      return { fileUrl };
    }
    return { fileUrl: currentLink };
  };

  const getSentencesVoiceCode = (newSentences, defaultVoiceCode) => {
    const sentencesVoiceCode = {};

    if (!openSplitVoices) return sentencesVoiceCode;

    Object.keys(newSentences).forEach((key, index) => {
      const sentenceVoiceCode =
        newSentences[key]?.voiceCode || defaultVoiceCode;
      if (!sentencesVoiceCode[sentenceVoiceCode]) {
        sentencesVoiceCode[sentenceVoiceCode] = [];
      }
      sentencesVoiceCode[sentenceVoiceCode].push(index);
    });

    return sentencesVoiceCode;
  };

  const handleSuccessfulCreate = (
    result,
    newSentences,
    subtitleLink,
    newProjectVoiceCode,
  ) => {
    const actionsUpdate = [
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        newSentences,
      ),
      actions.dubbingRequest.updateDubbingRequestByKey('title', result?.title),
      actions.dubbingRequest.updateDubbingRequestByKey('speed', result?.speed),
      actions.dubbingRequest.updateDubbingRequestByKey('projectInfo', {
        ...projectInfo,
        hasChanged: false,
        latestRequestId: result?.requestId,
        projectStatus: REQUEST_STATUS.IN_PROGRESS,
        updatedAt: result?.createdAt,
        currentSubtitleLink: subtitleLink,
        voiceCode: newProjectVoiceCode,
      }),
      actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
        ...changedProjectInfo,
        sentencesChanged: {},
      }),
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        [],
      ),
    ];

    actionsUpdate.forEach((action) => dispatch(action));
  };

  const showErrorMessage = () => {
    dispatch(
      actions.noti.push({
        message: t('systemError', { hotline: CUSTOMER_SUPPORT_PHONE_NUMBER }),
        severity: 'error',
      }),
    );
  };

  const handleConvertSubtitle = async () => {
    const isValidRequest = validateInputDubbingRequest();
    if (!isValidRequest) return;
    setIsLoading(true);
    const { newSentences, hasChangedContent } = updateSentences(
      JSON.parse(JSON.stringify(sentences)),
      sentencesChanged,
    );

    if (Object.keys(newSentences).length === 0) {
      showNotificationDialog(t('cannotConvertEmptyContent'), 'error');
      setIsLoading(false);
      return;
    }

    const { invalidFormat, fileUrl } = await handleSubtitleFileCreation({
      hasChangedContent,
      fileTitle: title,
      newSentences,
      currentLink: projectInfo.currentSubtitleLink,
    });
    if (invalidFormat) {
      showNotificationDialog(t('invalidSrtFormat'), 'error');
      setIsLoading(false);
      return;
    }

    if (!fileUrl) {
      showNotificationDialog(
        t('systemError', { hotline: CUSTOMER_SUPPORT_PHONE_NUMBER }),
        'error',
      );
      setIsLoading(false);
      return;
    }

    const selectedVoiceCode =
      projectInfo.hasChanged && changedVoiceCode.length > 0
        ? changedVoiceCode
        : voice?.code;
    const requestPayload = {
      title:
        projectInfo.hasChanged && changedTitle.length > 0
          ? changedTitle
          : title,
      subtitleLink: fileUrl,
      voiceCode: selectedVoiceCode,
      audioType,
      speed: projectInfo.hasChanged && changedSpeed > 0 ? changedSpeed : speed,
      projectId,
    };

    // Update voice code for each sentence if dubbing multiple voices feature is enabled
    if (isDubbingMultipleVoices) {
      requestPayload.sentencesVoiceCode = getSentencesVoiceCode(
        newSentences,
        selectedVoiceCode,
      );

      if (Object.keys(requestPayload.sentencesVoiceCode).length === 0)
        await apis.dubbing.updateDubbingProject(projectId, {
          sentencesVoiceCode: {},
        });
    }

    const newReq = await apis.dubbing.createDubbingRequest(requestPayload);

    datasenses.sendMakeRequestEvent({
      userId: user.id,
      requestType: REQUEST_TYPE.DUBBING,
      voice: voice?.code,
      seconds: totalSeconds,
      status: REQUEST_STATUS.IN_PROGRESS,
    });

    if (newReq.status) {
      handleSuccessfulCreate(
        newReq.result,
        newSentences,
        fileUrl,
        selectedVoiceCode,
      );
    } else {
      showErrorMessage();
    }
    setHasDeleteSentences(false);
    onChangeRequestLoading(true);
    setIsLoading(false);
  };

  const renderCloseButton = (handleClose) => (
    <Button variant="contained" onClick={handleClose}>
      {t('understood')}
    </Button>
  );

  return (
    <>
      <StyledConvertButton
        className="convert-button"
        color="primary"
        variant="contained"
        onClick={handleConvertSubtitle}
        disabled={
          isLoading ||
          !projectInfo?.currentSubtitleLink ||
          projectInfo?.currentSubtitleLink?.length === 0
        }
      >
        <ProcessHandler
          loading={isLoading}
          ml="46px"
          mr="46px"
          size={24}
          align="center"
          color="divider"
        >
          {t('convertSubtitle')}
        </ProcessHandler>
      </StyledConvertButton>
      <NotificationDialog
        name={openWrongSyntaxDialog?.name}
        title={openWrongSyntaxDialog?.title}
        description={openWrongSyntaxDialog?.description}
        subDescription={openWrongSyntaxDialog?.subDescription}
        variant="warning"
        open={!!openWrongSyntaxDialog?.title}
        onClose={() => setOpenWrongSyntaxDialog(null)}
        actionComponent={renderCloseButton(() =>
          setOpenWrongSyntaxDialog(null),
        )}
      />
      <ActionDialog
        image={actionDialog?.image}
        open={!!actionDialog?.title}
        title={actionDialog?.title}
        description={actionDialog?.description}
        onClose={actionDialog?.onClose}
        actionComponents={actionDialog?.actionComponents}
      />
    </>
  );
};

export default ConvertButton;
