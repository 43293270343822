const ORDER_STATE = {
  PROCESSING: 0,
  SUCCESS: 1,
  FAILED: 2,
};

const ORDER_STATUS = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  CANCEL: 'CANCEL',
};

const HAVE_NO_ORDER = 'HAVE_NO_ORDER';

const CANCEL_ORDER_TIMER = {
  BANK_TRANSFER: 2, // hours
  ONLINE_GATEWAY: 15 * 60, // second
};

export { ORDER_STATE, ORDER_STATUS, HAVE_NO_ORDER, CANCEL_ORDER_TIMER };
