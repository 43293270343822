import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import apis from '@src/apis';
import actions from '@src/redux/actions';
import Table from '@src/components/Table';
import { AFFILIATE_TABS } from '@src/constants/affiliate';
import { PAGINATION_LIMIT } from '@src/constants';
import {
  PACKAGE_CODE,
  PACKAGE_DURATION,
  AFFILIATE_PACKAGE_CODE_LEVEL,
} from '@src/constants/package';

const ResignedCustomerList = ({ activeTab }) => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState('createdAt_desc');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const renderPackageCode = (packageCode) => {
    if (!packageCode) return '';
    if (packageCode === PACKAGE_CODE.API_PAYG) return 'API PSUGO';
    // eslint-disable-next-line no-unused-vars
    const [type, level, duration] = packageCode.split('-');
    const levelKey = AFFILIATE_PACKAGE_CODE_LEVEL[level];
    if (!duration) return t(levelKey);

    const durationKey = PACKAGE_DURATION[duration];
    return `${t(levelKey)} - ${t(durationKey)}`;
  };

  const handleChangePage = (newPage) => setPage(newPage);

  const handleChangeSort = (newSort) => {
    setSort(newSort);
    handleChangePage(1);
  };

  const fetchCustomers = async () => {
    setIsLoading(true);
    const response = await apis.affiliateMarketer.getAffiliateCustomers({
      offset: (page - 1) * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
      sort,
    });
    setIsLoading(false);
    if (!response?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'getAffiliateCustomersFailed',
        }),
      );
    } else {
      setCustomers(response?.result?.users);
      setTotal(response?.result?.total);
    }
  };

  const columns = [
    {
      field: 'id',
      title: t('id'),
      align: 'left',
      render: (row) => row?.id,
    },
    {
      field: 'fullName',
      title: t('fullName'),
      align: 'left',
      render: (row) => `${row.lastName || ''} ${row.firstName || ''}`,
    },
    {
      field: 'phoneNumber',
      title: t('phoneNumber'),
      align: 'left',
      render: (row) => row?.phoneNumber,
    },
    {
      field: 'email',
      title: t('email'),
      align: 'left',
      render: (row) => row?.email,
    },
    {
      field: 'studioPackage',
      title: t('studioPackage'),
      align: 'left',
      render: (row) => renderPackageCode(row.packageCode),
    },
    {
      field: 'apiPackage',
      title: t('apiPackage'),
      align: 'left',
      render: (row) => renderPackageCode(row.apiPackage?.packageCode),
    },
    {
      field: 'createdAt',
      title: t('createdAt'),
      align: 'left',
      sort: true,
      sortable: true,
      render: (row) => moment(row?.createdAt).format('DD/MM/YYYY'),
    },
  ];

  useEffect(() => {
    if (activeTab === AFFILIATE_TABS.CUSTOMERS_LIST) fetchCustomers();
  }, [activeTab, page, sort]);

  return (
    <div>
      <Table
        showNumber
        columns={columns}
        data={customers}
        loading={isLoading}
        sort={sort}
        page={page}
        total={total}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
      />
    </div>
  );
};

export default ResignedCustomerList;
