import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@src/components/Card';
import { useTranslation } from 'react-i18next';
import AffiliateWoman2 from '@src/assets/images/affiliate-woman-2.svg';
import { AFFILIATE_RESOURCE_LINK } from '@src/configs';
import SmileFace from '@src/assets/images/smile-face.svg';
import { East, WestOutlined } from '@mui/icons-material';
import route from '@src/constants/route';
import { ResourceStyled } from './index.style';

const Resource = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickResource = () =>
    window.open(AFFILIATE_RESOURCE_LINK, '_blank', 'noopener,noreferrer');

  const handleClickServiceIntro = () =>
    history.push(route.AFFILIATE, { from: route.AFFILIATE_DETAIL });

  return (
    <ResourceStyled>
      <Card className="background">
        <div className="image-woman-container">
          <img src={AffiliateWoman2} alt="woman" className="image-woman" />
        </div>
        <div className="content">
          <div className="title">{t('resourceTitle')}</div>
          <div className="description">{t('resourceDescription')}</div>
          <div className="button-group">
            <Button
              className="button"
              variant="contained"
              onClick={handleClickServiceIntro}
              startIcon={<WestOutlined />}
            >
              {t('serviceIntro')}
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={handleClickResource}
              endIcon={<East />}
            >
              {t('picturesAndVideos')}
            </Button>
          </div>
        </div>
        <div className="image-woman-container">
          <img src={SmileFace} alt="icon" className="image-icon" />
        </div>
      </Card>
    </ResourceStyled>
  );
};

export default Resource;
