import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Collapse, IconButton, MenuItem, Typography } from '@mui/material';
import {
  CallOutlined,
  ChevronLeft,
  Close,
  ContactSupport,
  ExpandLess,
  ExpandMore,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import NewInformationNote from '@src/containers/NewUserInformationNote';
import logoAiVoice from '@src/assets/logo/logo-AiVoice-new.svg';
import i18n from '@src/languages';
import { getPackageName } from '@src/services/package';
import { delimitNumber } from '@src/utils/number';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { getCookie, setCookie } from '@src/utils/cookie';
import { convertSecondsToHMS } from '@src/utils/time';
import {
  CUSTOMER_SUPPORT_PHONE_NUMBER,
  SUPPORT_CENTER_URL,
  VBEE_DOMAIN,
  VBEE_HOTLINE,
} from '@src/configs';
import { TIME_STORE_LANGUAGE } from '@src/constants/device';
import { COUNTRY, LANGUAGE } from '@src/constants';
import { COLOR } from '@src/styles/color';
import { verifyTime } from '@src/services/voucher';
import apis from '@src/apis';
import {
  StyledMobileProfileAction,
  StyledMobileProfileContent,
} from './index.style';
import VoucherAds from './VoucherAds';
import UserActions from './UserActions';

const languages = [
  {
    value: LANGUAGE.VN,
    country: COUNTRY.VN,
    label: 'vietnamese',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
  },
  {
    value: LANGUAGE.EN,
    country: COUNTRY.EN,
    label: 'english',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
  },
  // {
  //   value: LANGUAGE.PH,
  //   country: COUNTRY.PH,
  //   label: 'filipino',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/Philippines.png',
  // },
  // {
  //   value: LANGUAGE.TH,
  //   country: COUNTRY.TH,
  //   label: 'thailand',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/Thailand.png',
  // },
  // {
  //   value: LANGUAGE.CN,
  //   country: COUNTRY.CN,
  //   label: 'chinese',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/China.png',
  // },
];

const CollapseHeader = ({ title, open, onClick }) => (
  <Box className="collapse-header" onClick={onClick}>
    <Typography className="text-info">{title}</Typography>
    {open ? (
      <ExpandLess className="item-icon" />
    ) : (
      <ExpandMore className="item-icon" />
    )}
  </Box>
);

const UserProfile = () => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t } = useTranslation();
  const [openInformation, setOpenInformation] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { usingPackage, lastExpiredOrder, usingDubbingPackage } = useSelector(
    (state) => state.user,
  );
  const { remainingCharacters, bonusCharacters, dubbing } = useSelector(
    (state) => state.auth.user,
  );
  const { language } = i18n;

  let packageName;
  if (isDubbingRoute)
    packageName = getPackageName(
      usingDubbingPackage?.name,
      usingDubbingPackage?.code,
      language,
    );
  else
    packageName = usingPackage.id
      ? getPackageName(usingPackage.name, usingPackage.code, language)
      : getPackageName(
          lastExpiredOrder?.package?.name,
          user.packageCode,
          language,
        );
  const packageExpiryDate = isDubbingRoute
    ? dubbing.packageExpiryDate
    : user.packageExpiryDate;

  const totalCharacters =
    remainingCharacters + bonusCharacters > 0
      ? remainingCharacters + bonusCharacters
      : 0;
  const totalSeconds =
    dubbing?.remainingSeconds + dubbing?.lockSeconds > 0
      ? dubbing?.remainingSeconds + dubbing?.lockSeconds
      : 0;

  const userInformation = [
    {
      label: t('accountName'),
      value: `${user.lastName} ${user.firstName}`,
    },
    {
      label: t('package'),
      value: packageName,
    },
    {
      label: isDubbingRoute ? t('remainingTime') : t('numberCharacters'),
      value: isDubbingRoute
        ? convertSecondsToHMS(totalSeconds || 0)
        : delimitNumber(totalCharacters, language),
    },
    {
      label: t('expiredDate'),
      value: packageExpiryDate
        ? moment(packageExpiryDate).format('DD/MM/YYYY - HH:mm')
        : t('endless'),
    },
  ];

  return (
    <>
      <CollapseHeader
        title={t('accountInformation')}
        open={openInformation}
        onClick={() => setOpenInformation((prev) => !prev)}
      />
      <Collapse in={openInformation} timeout="auto" unmountOnExit>
        <Box className="profile-content">
          {userInformation.map((item) => (
            <Box className="profile-item" key={item}>
              <Typography className="profile-item-label">
                {item.label}
              </Typography>
              <Typography className="profile-item-value">
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    </>
  );
};

const Voucher = () => {
  const { t } = useTranslation();
  const [openVoucher, setOpenVoucher] = useState(false);
  const { lockCharacters } = useSelector((state) => state.auth.user);
  const { getFeatureValue } = useFeatureFlags();
  const showSidebarVoucher = getFeatureValue(FEATURE_KEYS.SIDEBAR_VOUCHER);
  const hasLockCharacters = lockCharacters > 0;
  const showGift = hasLockCharacters || showSidebarVoucher;

  const [vouchers, setVouchers] = useState([]);

  const getSatisfiedVouchers = (voucherData) => {
    const newVouchers = voucherData.reduce((acc, currVoucher) => {
      const satisfiedTime = verifyTime(currVoucher);
      if (!satisfiedTime) return acc;

      const canUse =
        satisfiedTime &&
        currVoucher.remainingAmount &&
        !currVoucher.isPrivate &&
        currVoucher.usedAmount < currVoucher.numReuse;
      return [...acc, { ...currVoucher, satisfiedTime, canUse }];
    }, []);

    const sortVouchers = newVouchers.sort((voucher) =>
      voucher.canUse ? -1 : 1,
    );

    setVouchers(sortVouchers);
  };

  const fetchVouchers = async () => {
    const data = await apis.vouchers.getVouchers();
    if (data.status) getSatisfiedVouchers(data.result);
  };

  useEffect(() => {
    if (showSidebarVoucher) fetchVouchers();
  }, []);

  return (
    <>
      {showGift ? (
        <>
          <CollapseHeader
            title={t('specialOffers')}
            open={openVoucher}
            onClick={() => setOpenVoucher((prev) => !prev)}
          />
          <Collapse in={openVoucher} timeout="auto" unmountOnExit>
            <VoucherAds isMobile vouchers={vouchers} />
          </Collapse>
        </>
      ) : null}
    </>
  );
};

const Setting = ({
  onShowProfile,
  useNewProfileMobile,
  location,
  setScreenOpenMobile,
}) => {
  const { t } = useTranslation();
  const [openSetting, setOpenSetting] = useState(false);
  return (
    <>
      {useNewProfileMobile && (
        <Box marginTop="-8px">
          <UserActions
            onShowProfile={onShowProfile}
            useNewProfileMobile={useNewProfileMobile}
            location={location}
            setScreenOpenMobile={setScreenOpenMobile}
          />
        </Box>
      )}
      {!useNewProfileMobile && (
        <>
          <CollapseHeader
            title={t('setting')}
            open={openSetting}
            onClick={() => setOpenSetting((prev) => !prev)}
          />
          <Collapse in={openSetting} timeout="auto" unmountOnExit>
            <Box marginLeft="-8px" marginTop="-8px">
              <UserActions
                location={location}
                setScreenOpenMobile={setScreenOpenMobile}
              />
            </Box>
          </Collapse>
        </>
      )}
    </>
  );
};

const LanguageSetting = ({ location, handleGoBackProfile }) => {
  const [language, setLanguage] = useState({});
  const { t } = useTranslation();

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
    setLanguage(lng);
  };

  const handleClickChangeLanguage = (lng) => {
    handleChangeLanguage(lng);
    setCookie({
      cname: 'lng',
      cvalue: lng.value,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_LANGUAGE,
    });
  };

  useEffect(() => {
    const defaultLanguageValue = localStorage.getItem('i18nextLng');
    const defaultLanguage = languages.find(
      (lng) => lng.value === defaultLanguageValue,
    );

    const currentLanguageValue = getCookie('lng');
    const currentLanguage = languages.find(
      (lng) => lng.value === currentLanguageValue,
    );

    if (location) {
      const { country: countryCode } = location;
      localStorage.setItem('country', COUNTRY[countryCode] || COUNTRY.EN);
    }

    if (currentLanguage) {
      handleChangeLanguage(currentLanguage);
      return;
    }

    const countryLanguageValue = localStorage.getItem('country');
    const countryLanguage = languages.find(
      (lng) => lng.country === countryLanguageValue,
    );
    if (countryLanguage) {
      handleChangeLanguage(countryLanguage);
      return;
    }

    if (defaultLanguage) {
      setLanguage(defaultLanguage);
      return;
    }
    handleChangeLanguage(languages[0]);
  }, [location]);

  return (
    <Box className="sub-setting">
      <Box className="sub-setting-back" onClick={handleGoBackProfile}>
        <ChevronLeft className="icon" />
        <Typography className="text">{t('language')}</Typography>
      </Box>
      {languages.map((lng) => (
        <MenuItem
          className={`sub-setting-item ${
            lng.value === language.value
              ? 'selected-language'
              : 'unselected-language'
          }`}
          onClick={() => handleClickChangeLanguage(lng)}
        >
          <Box display="flex" gap="12px">
            {lng.value === language.value ? (
              <RadioButtonChecked />
            ) : (
              <RadioButtonUnchecked color={COLOR.label[80]} />
            )}
            {t(lng.label)}
          </Box>
        </MenuItem>
      ))}
    </Box>
  );
};

const HelpSetting = ({ handleGoBackProfile }) => {
  const { t } = useTranslation();

  return (
    <Box className="sub-setting">
      <Box className="sub-setting-back" onClick={handleGoBackProfile}>
        <ChevronLeft className="icon" />
        <Typography className="text">{t('help')}</Typography>
      </Box>
      <a
        href={SUPPORT_CENTER_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="text-link"
      >
        <MenuItem className="item">
          <ContactSupport />
          {t('supportCenter')}
        </MenuItem>
      </a>
      <a className="text-link" href={`tel:${CUSTOMER_SUPPORT_PHONE_NUMBER}`}>
        <MenuItem className="item">
          <CallOutlined />
          <Typography>
            {t('customerSupport')}: {CUSTOMER_SUPPORT_PHONE_NUMBER}
          </Typography>
        </MenuItem>
      </a>
      <a className="text-link" href={`tel:${VBEE_HOTLINE}`}>
        <MenuItem className="item">
          <CallOutlined />
          <Typography>
            {t('hotline')}: {VBEE_HOTLINE}
          </Typography>
        </MenuItem>
      </a>
    </Box>
  );
};

const MobileProfileAction = ({ onShowProfile, location }) => {
  const [screenOpen, setScreenOpen] = useState('profile');
  const { user } = useSelector((state) => state.auth);
  const { headerVoucher } = useSelector((state) => state.voucher);
  const { getFeatureValue } = useFeatureFlags();
  const useNewProfileMobile = getFeatureValue(FEATURE_KEYS.NEW_PROFILE_MOBILE, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const { height: heightScreen } = useWindowDimensions();
  const handleGoBackProfile = () => setScreenOpen('profile');

  return (
    <StyledMobileProfileAction
      heightScreen={heightScreen}
      headerVoucher={headerVoucher}
      useNewProfileMobile={useNewProfileMobile}
      sx={{
        display: { md: 'none', xs: 'block', sm: 'none' },
      }}
      variant="persistent"
      anchor="bottom"
      open
      ModalProps={{
        keepMounted: true,
      }}
    >
      <StyledMobileProfileContent
        heightScreen={heightScreen}
        headerVoucher={headerVoucher}
        useNewProfileMobile={useNewProfileMobile}
      >
        {screenOpen === 'profile' && !useNewProfileMobile && (
          <>
            <UserProfile />
            <Voucher />
            <Setting location={location} setScreenOpenMobile={setScreenOpen} />
          </>
        )}
        {screenOpen === 'profile' && useNewProfileMobile && (
          <>
            <div className="mobile package-wrapper-mobile">
              <img
                src={logoAiVoice}
                alt="vbee tts logo"
                className="logo-img"
                width={108}
              />
              <IconButton
                aria-label="close"
                onClick={onShowProfile}
                color="secondary"
                variant="outlined"
              >
                <Close className="close-button" fontSize="small" />
              </IconButton>
            </div>
            <div className="new-profile-mobile">
              <NewInformationNote />
              <Setting
                onShowProfile={onShowProfile}
                useNewProfileMobile={useNewProfileMobile}
                location={location}
                setScreenOpenMobile={setScreenOpen}
              />
            </div>
          </>
        )}
        {screenOpen === 'language' && (
          <LanguageSetting
            location={location}
            handleGoBackProfile={handleGoBackProfile}
            setScreenOpenMobile={setScreenOpen}
          />
        )}
        {screenOpen === 'help' && (
          <HelpSetting handleGoBackProfile={handleGoBackProfile} />
        )}
      </StyledMobileProfileContent>
    </StyledMobileProfileAction>
  );
};

export default MobileProfileAction;
