import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Check, ContentCopyOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const CopyButton = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  const handleClickCopy = () => {
    if (content) {
      navigator.clipboard.writeText(content);
      setCopied(true);
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const renderIcon = () => {
    if (copied) {
      return <Check className="check" />;
    }
    return <ContentCopyOutlined className="copy" />;
  };

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      className="button-copy"
      startIcon={renderIcon()}
      onClick={handleClickCopy}
    >
      {t('copy')}
    </Button>
  );
};

export default CopyButton;
