import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Card from '@src/components/Card';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import apis from '@src/apis';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import EmptyProject from '@src/assets/images/empty-project.svg';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { getListDubbingProjects } from '@src/apis/dubbing';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import ErrorReport from '@src/containers/TTSNew/Requests/ErrorReport';
import actions from '@src/redux/actions';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FIELDS_FILTER } from '@src/constants/request';
import { FETCH_REQUESTS_INTERVAL } from '@src/constants/websocket';
import ProjectTable from './ProjectTable';
import ListProjectHeader from './ListProjectHeader';
import { StyledListProject } from './index.style';

const DEFAULT_PAGE_LIMIT = 10;
const PADDING_HEIGHT = 20;
const MOBILE_FOOTER_HEIGHT = 60;

const initialFilter = {
  status: '',
  createdAt: [null, null],
};

const ListProject = ({
  showModalCreateProject,
  isShowNotiNewFeature,
  checkCloseNotiRef,
}) => {
  const { t } = useTranslation();

  const isMobileScreen = window.innerWidth < 480;

  // State
  const [searchField, setSearchField] = useState('');
  const [filterField, setFilterFiled] = useState(initialFilter);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState([]);
  const [sort, setSort] = useState('updatedAt_desc');
  const [loading, setLoading] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [selectedFields, setSelectedFields] = useState(
    Object.values(FIELDS_FILTER),
  );
  const [selectedErrorReportProject, setSelectedErrorReportProject] =
    useState();
  const [isFilter, setIsFilter] = useState(false);

  // Ref
  const projectRefs = useRef(projects);
  const listProjectRefs = useRef();
  const listProjectHeaderRefs = useRef();

  // Redux
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  // Feature flag
  const { getFeatureValue } = useFeatureFlags();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const calculateListProjectTableHeight = () => {
    const contentHeight = listProjectRefs.current?.offsetHeight || 0;
    const headerHeight = listProjectHeaderRefs.current?.offsetHeight || 0;
    const notiHeight = checkCloseNotiRef.current ? 60 : 0;
    const resultHeight =
      contentHeight - headerHeight - PADDING_HEIGHT + notiHeight;
    return isMobileScreen ? resultHeight - MOBILE_FOOTER_HEIGHT : resultHeight;
  };

  const handleOpenErrorReport = (project) => {
    setOpenErrorReport(true);
    setSelectedErrorReportProject(project);
  };

  const handleCloseErrorReport = () => {
    setOpenErrorReport(false);
    setSelectedErrorReportProject(null);
  };

  const handleChangePage = (newPage) => setPage(newPage);

  const updateProject = (projectId, updateInfo) => {
    const newProjects = projects.map((project) => {
      if (project.id === projectId) {
        return {
          ...project,
          ...updateInfo,
        };
      }
      return project;
    });
    setProjects(newProjects);
  };

  const fetchProjects = async ({
    hasLoading = true,
    search = searchField,
    filter = filterField,
    newPage = page,
  }) => {
    if (hasLoading) setLoading(true);
    const { status, createdAt } = filter;
    const startDate = createdAt?.[0]
      ? moment(createdAt[0]).startOf('day').toISOString()
      : undefined;

    const endDate = createdAt?.[1]
      ? moment(createdAt[1]).endOf('day').toISOString()
      : undefined;

    const response = await getListDubbingProjects({
      sort,
      search,
      status,
      startDate,
      endDate,
      offset: (newPage - 1) * DEFAULT_PAGE_LIMIT,
      limit: DEFAULT_PAGE_LIMIT,
    });

    if (!response?.status) {
      setLoading(false);
      return;
    }

    setTotal(response?.result?.total);

    const newProjects = await Promise.all(
      response?.result?.projects.map(async (project) => {
        if (!project?.latestRequestId) return project;

        const reqRes = await apis.requests.getRequest(project?.latestRequestId);
        if (!reqRes.status) return project;

        return {
          ...project,
          requestInfo: reqRes.result,
        };
      }),
    );

    setProjects(newProjects || []);
    setLoading(false);
  };

  const handleChangeSort = (newSort) => {
    setSort(newSort);
  };

  const handleSelectRequest = (project) => {
    setSelectedProjects(project);
  };

  const handleDeleteRequestConfirm = async () => {
    const listProjectIds = selectedProjects.map((project) => project.id);
    const response = await apis.dubbing.deleteDubbingProjects(listProjectIds);
    if (response.status) {
      const totalProjects = total - listProjectIds.length;
      const newPage = Math.max(
        Math.ceil(totalProjects / DEFAULT_PAGE_LIMIT),
        1,
      );
      setPage(Math.min(newPage, page));

      await fetchProjects({
        hasLoading: false,
        newPage: Math.min(newPage, page),
      });
      setSelectedProjects([]);
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'removeProjectSuccess',
        }),
      );
      return;
    }

    dispatch({
      severity: 'error',
      message: 'removeProjectFailed',
    });
  };

  const fetchProgressRequest = async (requestId) => {
    const data = await apis.requests.getProgressRequest(requestId);
    if (data && data.status) {
      const newProjects = projectRefs.current.map((project) => {
        if (project?.requestInfo?.id === requestId) {
          const { progress, status, audioLink } = data.result;
          return {
            ...project,
            requestInfo: {
              ...project.requestInfo,
              progress,
              status,
              audioLink,
            },
          };
        }
        return project;
      });
      setProjects(newProjects);
    }
  };

  const updateInprogressRequest = (requestIds) => {
    Promise.all(
      requestIds.map(async (requestId) => {
        await fetchProgressRequest(requestId);
        return null;
      }),
    );
  };

  const fetchProcessingProject = async (projectId) => {
    const response = await apis.dubbing.getDubbingProjectDetail(projectId);
    if (response && response.status) {
      const newProjects = projectRefs.current.map((project) => {
        if (project.id === projectId) {
          return {
            ...project,
            currentSubtitleLink: response.result.currentSubtitleLink,
          };
        }
        return project;
      });
      setProjects(newProjects);
    }
  };

  const updateInProcessingSubtitlesProject = async (projectIds) => {
    Promise.all(
      projectIds.map(async (projectId) => {
        await fetchProcessingProject(projectId);
        return null;
      }),
    );
  };

  useEffect(() => {
    projectRefs.current = projects;
  }, [projects]);

  useEffect(() => {
    const fetchRequestsInterval = setInterval(async () => {
      const inProgressProjects = projectRefs.current
        .filter((project) => project.status === REQUEST_STATUS.IN_PROGRESS)
        .map((project) => project?.requestInfo?.id);

      if (inProgressProjects?.length)
        updateInprogressRequest(inProgressProjects);
    }, FETCH_REQUESTS_INTERVAL);

    return () => clearInterval(fetchRequestsInterval);
  }, []);

  useEffect(() => {
    const fetchProjectsSubtitleInterval = setInterval(async () => {
      const inProcessingSubtitlesProjects = projectRefs.current
        .filter((project) => project?.currentSubtitleLink === undefined)
        .map((project) => project.id);
      if (inProcessingSubtitlesProjects?.length)
        updateInProcessingSubtitlesProject(inProcessingSubtitlesProjects);
    }, FETCH_REQUESTS_INTERVAL);

    return () => clearInterval(fetchProjectsSubtitleInterval);
  }, []);

  return (
    <Card
      sx={{
        height: !isShowNotiNewFeature ? '95vh' : `calc(95vh - 60px)`,
        boxSizing: 'border-box',
      }}
    >
      <StyledListProject ref={listProjectRefs}>
        <ListProjectHeader
          page={page}
          showModalCreateProject={showModalCreateProject}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          fetchProjects={fetchProjects}
          sort={sort}
          setIsFilter={setIsFilter}
          searchField={searchField}
          setSearch={setSearchField}
          filterField={filterField}
          setFilter={setFilterFiled}
          setPage={setPage}
          headerRef={listProjectHeaderRefs}
        />

        {loading && (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}

        {projects.length > 0 && !loading && (
          <Box sx={{ height: `${calculateListProjectTableHeight()}px` }}>
            <ProjectTable
              projects={projects}
              selectedFields={selectedFields}
              sort={sort}
              loading={loading}
              playable={false}
              total={total}
              page={page}
              selectedProjects={selectedProjects}
              isProcessBySeconds={isProcessDubbingByUnitSecond}
              onChangePage={handleChangePage}
              onUpdateProject={updateProject}
              onChangeSort={handleChangeSort}
              onSelectRequest={handleSelectRequest}
              onDeleteSelectedRequests={handleDeleteRequestConfirm}
              handleOpenErrorReport={handleOpenErrorReport}
              tableHeight={calculateListProjectTableHeight()}
            />
          </Box>
        )}
        {projects.length === 0 && !loading && (
          <Box id={TTS_GUIDE.REQUESTS_TABLE} className="project-content">
            <img src={EmptyProject} alt="empty" />
            {!isFilter ? (
              <>
                <Typography className="project-info">
                  {t('youDontHaveProjectCreateNew')}
                </Typography>
                <Button
                  className="project-button"
                  variant="contained"
                  color="primary"
                  onClick={() => showModalCreateProject()}
                >
                  <Add />
                  <Typography>{t('createNewProject')}</Typography>
                </Button>
              </>
            ) : null}
          </Box>
        )}
        <ErrorReport
          open={openErrorReport}
          request={selectedErrorReportProject}
          onClose={handleCloseErrorReport}
        />
      </StyledListProject>
    </Card>
  );
};

export default ListProject;
