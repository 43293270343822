import React from 'react';
import { useTranslation } from 'react-i18next';
import SampleScriptItem from './SampleScriptItem';
import { StyledSampleScripts } from './index.style';

const SampleScripts = ({
  audioRef,
  sampleScripts,
  setShowSampleButton,
  onChangeInputLength,
  onMobile,
}) => {
  const { t } = useTranslation();

  return (
    <StyledSampleScripts className="tool-row-left" onMobile={onMobile}>
      <span className="tool-row-left-title">{t('trySampleScripts')}</span>
      <div className="tool-row-left-content">
        {sampleScripts.map((item, index) => (
          <SampleScriptItem
            key={item.title}
            audioRef={audioRef}
            sampleScript={item}
            className={
              onMobile
                ? `sample-button`
                : `sample-button-${index + 1 > 8 ? 9 : index + 1}`
            }
            setShowSampleButton={setShowSampleButton}
            name={item.title}
            onChangeInputLength={onChangeInputLength}
          />
        ))}
      </div>
    </StyledSampleScripts>
  );
};

export default SampleScripts;
