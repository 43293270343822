import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledMusic = styled('div')`
  .file-input {
    display: none;
  }

  .upload-music-button {
    text-transform: initial;
    margin-left: 50px;
    cursor: pointer;
    text-decoration: underline;
  }

  .upload-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upload-filename {
    flex: 1;
  }

  .upload-button,
  .reject-button {
    margin-right: 10px;
    padding: 5px;
  }

  .music-list {
    min-height: 240px;
  }

  .list-item {
    cursor: pointer;
    border-radius: 10px;

    &:hover {
      background-color: ${TRANSPARENT_COLOR.dark};
    }
  }

  .icon {
    cursor: pointer;
  }

  .list-item-icon {
    padding: 10px;
    color: ${COLOR.darkBlue};
  }

  .selected {
    background-color: ${TRANSPARENT_COLOR.primary};
    color: ${COLOR.darkBlue};

    &:hover {
      background-color: ${TRANSPARENT_COLOR.primary};
    }
  }

  .loading {
    color: ${COLOR.darkBlue};
  }

  .dialog-action {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .apply-button {
    width: 150px;
    padding: 5px;
    text-transform: initial;
  }

  .play-audio-button {
    cursor: pointer;
  }

  .description {
    margin: 24px 0px;
    text-align: center;
  }

  a:link,
  a:visited {
    color: ${COLOR.text};
    text-decoration: underline;
    cursor: pointer;
  }

  a:link:active,
  a:visited:active {
    color: ${COLOR.text};
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .upload-wrapper {
      margin: 10px 0 10px 20px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .upload-filename {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
    }

    .reject-button {
      margin-top: 5px;
      margin-right: 0;
    }

    .upload-button {
      margin-right: 0;
    }
  }
`;

export { StyledMusic };
