import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import {
  AddCircleRounded,
  CheckCircleRounded,
  ErrorOutlineRounded,
  MoreVertRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import BadgeAvatar from '@src/components/BadgeAvatar';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import { useSelector } from 'react-redux';
import { calculateElapsedDateTime } from '@src/utils/date';
import { REQUEST_STATUS } from '@src/constants/voice';
import CircularProcess from '@src/components/CircularProcess';
import { SCROLL_THRESHOLD } from '@src/constants';
import { StyledBtnCreate, StyledRequests, StyledTitle } from './index.style';
import ActionDrawer from './ActionDrawer';

const Avatar = ({ voice }) => {
  const { languages } = useSelector((state) => state.language);
  const smallImgSrc = languages.find(
    (lang) => lang?.value === voice?.languageCode,
  )?.roundImage;

  return (
    <Box>
      <BadgeAvatar
        width={40}
        img={voice?.roundImage}
        type="image"
        smallImgWidth={10}
        smallImg={smallImgSrc}
      />
    </Box>
  );
};

const Title = ({ request }) => {
  const { t, language } = useTranslation();
  return (
    <StyledTitle>
      <Typography className="title">{request.title}</Typography>
      <Typography className="text-time">
        {calculateElapsedDateTime(request?.createdAt, t)} •{' '}
        {request.characters?.toLocaleString(language) || 0} {t('characters')}
      </Typography>
    </StyledTitle>
  );
};

const Status = ({ request }) => {
  const { displayProgress, displayStatus } = useRequestAnimationStatus(request);

  return (
    <Box>
      {displayStatus === REQUEST_STATUS.SUCCESS && (
        <CheckCircleRounded color="success" />
      )}
      {displayStatus === REQUEST_STATUS.FAILURE && (
        <ErrorOutlineRounded color="error" />
      )}
      {displayStatus === REQUEST_STATUS.DRAFT && (
        <CheckCircleRounded color="info" />
      )}
      {displayStatus === REQUEST_STATUS.IN_PROGRESS && (
        <CircularProcess value={displayProgress} size={28} thickness={6} />
      )}
    </Box>
  );
};

const ButtonAction = ({
  request,
  handleHideButtonCreate,
  handleShowButtonCreate,
}) => {
  const [openActionDrawer, setOpenActionDrawer] = useState(false);

  const handleOpenActionDrawer = () => {
    handleHideButtonCreate();
    setOpenActionDrawer(true);
  };
  const handleCloseActionDrawer = () => {
    setOpenActionDrawer(false);
    setTimeout(() => {
      handleShowButtonCreate();
    }, 100);
  };

  return (
    <Box>
      <Box role="button" tabIndex="0" onClick={handleOpenActionDrawer}>
        <MoreVertRounded />
      </Box>
      <ActionDrawer
        open={openActionDrawer}
        onOpen={handleOpenActionDrawer}
        onClose={handleCloseActionDrawer}
        request={request}
      />
    </Box>
  );
};

const ButtonCreate = () => {
  const { t } = useTranslation();
  return (
    <StyledBtnCreate>
      <Button
        color="primary"
        variant="contained"
        className="btn-create-new"
        onClick={() => {}}
      >
        <AddCircleRounded className="icon" />
        <Typography className="text-create-new">{t('createNew')}</Typography>
      </Button>
    </StyledBtnCreate>
  );
};

const Requests = ({ requests, hasMore, loading, setParams }) => {
  const [showButtonCreate, setShowButtonCreate] = useState(true);
  const refRequests = useRef(null);

  const handleHideButtonCreate = () => setShowButtonCreate(false);
  const handleShowButtonCreate = () => setShowButtonCreate(true);

  // Fetch more requests when scroll to bottom
  const handleScroll = useCallback(() => {
    const { scrollHeight, scrollTop, clientHeight } = refRequests.current;
    if (
      scrollHeight - scrollTop <= clientHeight + SCROLL_THRESHOLD &&
      hasMore &&
      !loading
    ) {
      setParams((prevParams) => ({
        ...prevParams,
        offset: prevParams.offset + 1,
      }));
    }
  }, [hasMore, loading]);

  // Add event listener to scroll event
  useEffect(() => {
    refRequests.current.addEventListener('scroll', handleScroll);
    return () => {
      refRequests.current.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <StyledRequests ref={refRequests}>
      {requests.map((request) => (
        <Box key={request.id} className="request-item">
          <Avatar voice={request.voice} />
          <Title request={request} />
          <Status request={request} />
          <ButtonAction
            request={request}
            handleHideButtonCreate={handleHideButtonCreate}
            handleShowButtonCreate={handleShowButtonCreate}
          />
        </Box>
      ))}
      {loading && <CircularProgress />}
      {showButtonCreate && <ButtonCreate />}
    </StyledRequests>
  );
};

export default Requests;
