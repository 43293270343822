export const actionTypes = {
  UPDATE_SYNTHESIS_REQUEST: 'UPDATE_SYNTHESIS_REQUEST',
  UPDATE_SYNTHESIS_CONFIG: 'UPDATE_SYNTHESIS_CONFIG',
  UPDATE_LOADING_SYNTHESIS_CONFIG: 'UPDATE_LOADING_SYNTHESIS_CONFIG',
  UPDATE_BACKGROUND_MUSIC: 'UPDATE_BACKGROUND_MUSIC',
  UPDATE_VOLUME_BACKGROUND_MUSIC: 'UPDATE_VOLUME_BACKGROUND_MUSIC',
  UPDATE_PARAGRAPHS: 'UPDATE_PARAGRAPHS',
  UPDATE_LOADING_PARAGRAPHS: 'UPDATE_LOADING_PARAGRAPHS',
  UPDATE_SELECTED_PARAGRAPH: 'UPDATE_SELECTED_PARAGRAPH',
  UPDATE_PREVIEW_PARAGRAPHS: 'UPDATE_PREVIEW_PARAGRAPHS',
  UPDATE_SENTENCES: 'UPDATE_SENTENCES',
  UPDATE_BLACK_WORDS: 'UPDATE_BLACK_WORDS',
  UPDATE_ELEMENT_OF_SENTENCES: 'UPDATE_ELEMENT_OF_SENTENCES',
  UPDATE_SELECTED_SENTENCE: 'UPDATE_SELECTED_SENTENCE',
  UPDATE_OPEN_SENTENCE: 'UPDATE_OPEN_SENTENCE',
  REMOVE_TTS_ATTRIBUTE_BY_SENTENCE: 'REMOVE_TTS_ATTRIBUTE_BY_SENTENCE',
  SWITCH_ADVANCE_FEATURE: 'SWITCH_ADVANCE_FEATURE',
  UPDATE_VOICE: 'UPDATE_VOICE',
  UPDATE_SELECTED_SENTENCES: 'UPDATE_SELECTED_SENTENCES',
  CHANGE_SEARCH_CONTENT: 'CHANGE_SEARCH_CONTENT',
  REPLACE_IN_SENTENCES: 'REPLACE_IN_SENTENCES_NEW',
  CLONE_REQUEST: 'CLONE_REQUEST',
  UPDATE_DEFAULT_REQUEST: 'UPDATE_DEFAULT_REQUEST',
  UPDATE_SHOW_SAMPLE_SCRIPTS: 'UPDATE_SHOW_SAMPLE_SCRIPTS',
};

const updateSynthesisRequest = (name, value) => ({
  type: actionTypes.UPDATE_SYNTHESIS_REQUEST,
  name,
  value,
});

const updateSynthesisConfig = (synthesisRequest) => ({
  type: actionTypes.UPDATE_SYNTHESIS_CONFIG,
  synthesisRequest,
});

const updateLoadingSynthesisConfig = (loading) => ({
  type: actionTypes.UPDATE_LOADING_SYNTHESIS_CONFIG,
  loading,
});

const updateBackgroundMusic = (name, link) => ({
  type: actionTypes.UPDATE_BACKGROUND_MUSIC,
  name,
  link,
});

const updateVolumeBackgroundMusic = (volume) => ({
  type: actionTypes.UPDATE_VOLUME_BACKGROUND_MUSIC,
  volume,
});

const updateParagraphs = (paragraphs) => ({
  type: actionTypes.UPDATE_PARAGRAPHS,
  paragraphs,
});

const updateLoadingParagraphs = (loading) => ({
  type: actionTypes.UPDATE_LOADING_PARAGRAPHS,
  loading,
});

const updateSelectedParagraph = (data) => ({
  type: actionTypes.UPDATE_SELECTED_PARAGRAPH,
  data,
});

const updatePreviewParagraphs = (data) => ({
  type: actionTypes.UPDATE_PREVIEW_PARAGRAPHS,
  data,
});

const updateSentences = (sentences) => ({
  type: actionTypes.UPDATE_SENTENCES,
  sentences,
});

const updateBlackWordsInText = (blackWordsInText) => ({
  type: actionTypes.UPDATE_BLACK_WORDS,
  blackWordsInText,
});

const updateElementOfSentence = ({
  sentenceId,
  text,
  elements,
  onLoad,
  mousePointerPosition,
}) => ({
  type: actionTypes.UPDATE_ELEMENT_OF_SENTENCES,
  elements,
  text,
  sentenceId,
  onLoad,
  mousePointerPosition,
});

const updateSelectedSentence = ({
  selectedText,
  startOffset,
  endOffset,
  sentenceId,
}) => ({
  type: actionTypes.UPDATE_SELECTED_SENTENCE,
  selectedText,
  startOffset,
  endOffset,
  sentenceId,
});

const updateOpenSentences = (openSentences) => ({
  type: actionTypes.UPDATE_OPEN_SENTENCE,
  openSentences,
});

const removeTtsAttributeBySentence = (data) => ({
  type: actionTypes.REMOVE_TTS_ATTRIBUTE_BY_SENTENCE,
  data,
});

const switchAdvanceFeature = (advanceFeature) => ({
  type: actionTypes.SWITCH_ADVANCE_FEATURE,
  advanceFeature,
});

const updateVoice = (voice) => ({ type: actionTypes.UPDATE_VOICE, voice });

const updateSelectedSentences = (selectedSentences) => ({
  type: actionTypes.UPDATE_SELECTED_SENTENCES,
  selectedSentences,
});

const changeSearchContent = (search) => ({
  type: actionTypes.CHANGE_SEARCH_CONTENT,
  search,
});

const replaceInSentences = (replace) => ({
  type: actionTypes.REPLACE_IN_SENTENCES,
  replace,
});

const cloneSynthesisRequest = () => ({
  type: actionTypes.CLONE_REQUEST,
});

const updateDefaultSample = (defaultSample) => ({
  type: actionTypes.UPDATE_DEFAULT_REQUEST,
  defaultSample,
});

const updateShowSampleScripts = (showSample) => ({
  type: actionTypes.UPDATE_SHOW_SAMPLE_SCRIPTS,
  showSample,
});

export {
  updateSynthesisRequest,
  updateSynthesisConfig,
  updateLoadingSynthesisConfig,
  updateBackgroundMusic,
  updateVolumeBackgroundMusic,
  updateParagraphs,
  updateLoadingParagraphs,
  updateSelectedParagraph,
  updatePreviewParagraphs,
  updateSentences,
  updateBlackWordsInText,
  updateElementOfSentence,
  updateSelectedSentence,
  updateOpenSentences,
  removeTtsAttributeBySentence,
  switchAdvanceFeature,
  updateVoice,
  updateSelectedSentences,
  changeSearchContent,
  replaceInSentences,
  cloneSynthesisRequest,
  updateDefaultSample,
  updateShowSampleScripts,
};
