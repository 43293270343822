import { actionTypes } from './actions';

export const initialState = {
  package: {},
  paymentMethod: {},
  voucher: null,
  userOrder: {
    user: '',
    phoneNumber: '',
    email: '',
  },
  invoice: {
    companyName: '',
    companyAddress: '',
    companyTax: '',
    companyEmail: '',
  },
  hasInvoice: false,
  invoiceError: {},
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PACKAGE: {
      const { packageSelected } = action;
      return { ...state, package: packageSelected };
    }

    case actionTypes.UPDATE_PAYMENT_METHOD: {
      const { paymentMethod } = action;
      return { ...state, paymentMethod };
    }

    case actionTypes.UPDATE_VOUCHER: {
      const { voucher } = action;
      return { ...state, voucher };
    }

    case actionTypes.UPDATE_USER_ORDER: {
      const { data } = action;
      return { ...state, userOrder: { ...state.userOrder, ...data } };
    }

    case actionTypes.UPDATE_INVOICE: {
      const { invoice } = action;
      return { ...state, invoice };
    }

    case actionTypes.UPDATE_HAS_INVOICE: {
      const { hasInvoice } = action;
      return { ...state, hasInvoice };
    }

    case actionTypes.UPDATE_ERROR_INVOICE: {
      const { errors } = action;
      return { ...state, invoiceError: errors };
    }

    default:
      return state;
  }
}
