import { actionTypes } from './actions';

export const initialState = {
  usingPackage: {},
  usingDubbingPackage: {},
  latestOrder: {},
  latestDubbingOrder: {},
  lastExpiredOrder: {},
  backToTTS: false,
  backToDubbing: false,
  closeQRPayment: false,
  runningTourGuide: false,
  fetchPaymentHistory: false,
  subscriptionPurchase: {},
  ttsUser: {},
  showBlockDownloadDialog: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USING_PACKAGE:
      return { ...state };

    case actionTypes.GET_USING_PACKAGE_SUCCESS: {
      const { usingPackage } = action;
      return { ...state, usingPackage };
    }

    case actionTypes.GET_USING_DUBBING_PACKAGE_SUCCESS: {
      const { usingDubbingPackage } = action;
      return { ...state, usingDubbingPackage };
    }

    case actionTypes.GET_USING_PACKAGE_FAILURE:
      return { ...state };

    case actionTypes.GET_TTS_USER:
      return { ...state };

    case actionTypes.GET_TTS_USER_SUCCESS: {
      const { ttsUser } = action;
      return { ...state, ttsUser };
    }

    case actionTypes.GET_TTS_USER_FAILURE:
      return { ...state };

    case actionTypes.GET_LATEST_ORDER:
      return { ...state };
    case actionTypes.GET_LATEST_ORDER_SUCCESS: {
      const { latestOrder } = action;
      return { ...state, latestOrder };
    }
    case actionTypes.GET_LATEST_ORDER_FAILURE:
      return { ...state };

    case actionTypes.GET_LATEST_DUBBING_ORDER:
      return { ...state };
    case actionTypes.GET_LATEST_DUBBING_ORDER_SUCCESS: {
      const { latestDubbingOrder } = action;
      return { ...state, latestDubbingOrder };
    }
    case actionTypes.GET_LATEST_DUBBING_ORDER_FAILURE:
      return { ...state };

    case actionTypes.GET_LAST_EXPIRED_ORDER:
      return { ...state };
    case actionTypes.GET_LAST_EXPIRED_ORDER_SUCCESS: {
      const { lastExpiredOrder } = action;
      return { ...state, lastExpiredOrder };
    }
    case actionTypes.GET_LAST_EXPIRED_ORDER_FAILURE:
      return { ...state };

    case actionTypes.BACK_TO_TTS:
      return { ...state, backToTTS: true };

    case actionTypes.BACK_TO_DUBBING:
      return { ...state, backToDubbing: true };

    case actionTypes.CLOSE_QR_PAYMENT:
      return { ...state, closeQRPayment: true };

    case actionTypes.RESET_CLOSE_QR_PAYMENT:
      return { ...state, closeQRPayment: false };

    case actionTypes.RESET_BACK_TO_TTS:
      return { ...state, backToTTS: false };

    case actionTypes.RESET_BACK_TO_DUBBING:
      return { ...state, backToDubbing: false };

    case actionTypes.RUNNING_TOUR_GUIDE: {
      const { status } = action;
      return { ...state, runningTourGuide: status };
    }
    case actionTypes.FETCH_PAYMENT_HISTORY: {
      const { status } = action;
      return { ...state, fetchPaymentHistory: status };
    }
    case actionTypes.UPDATE_SUBSCRIPTION_PURCHASE: {
      const { subscriptionPurchase } = action;
      return { ...state, subscriptionPurchase };
    }

    case actionTypes.UPDATE_SHOW_BLOCK_DOWNLOAD_DIALOG: {
      const { showBlockDownloadDialog } = action;
      return { ...state, showBlockDownloadDialog };
    }

    case actionTypes.UPDATE_BONUS_CHARACTERS: {
      const { bonusCharacters } = action;
      return { ...state, user: { ...state.user, bonusCharacters } };
    }

    default:
      return state;
  }
};

export default userReducer;
