import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import i18n from '@src/languages';
import sanitizeHtml from 'sanitize-html';
import ProcessHandler from '@src/components/ProcessHandler';
import {
  GLOBAL_PAYMENT_METHOD,
  PAYMENT_METHOD_TYPE,
  PAYMENT_STEP,
  PRICE_UNIT,
} from '@src/constants/payment';
import apis from '@src/apis';
import { ORDER_STATUS } from '@src/constants/order';
import CopyableButton from '@src/components/CopyableButton';
import actions from '@src/redux/actions';
import ROUTES from '@src/constants/route';
import { delimitNumber } from '@src/utils/number';
import { QR_CODE_URL, TEMPLATE_QR_CODE } from '@src/configs';

import { COUNTRY, LANGUAGE } from '@src/constants';
import { PAYMENT_TOUR_GUIDE } from '@src/constants/tourGuide';
import { removeVietnameseTones } from '@src/utils/string';
import BankItem from './BankItem';
import CommonBox from '../CommonBox';
import { StyledBankList, StyledTransferInformation } from './index.style';
import PaymentMethodItem from './PaymentMethodItem';

const PaymentBox = ({
  hasFirstNotify,
  hasPaid,
  activeStep,
  selectedBank,
  selectedPaymentMethod,
  loadingTransfer,
  isBankTransfer,
  onSelectBank,
  onSelectPaymentMethod,
  onChangeRunTourGuide,
  totalPrice,
}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [banks, setBanks] = useState([]);
  const [usdToVndRate, setUsdToVndRate] = useState(1);
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const [loadingBank, setLoadingBank] = useState(false);

  const { t, language } = i18n;
  const dispatch = useDispatch();
  const history = useHistory();

  const { latestOrder, backToTTS } = useSelector((state) => state.user);
  const { seenAllBanner } = useSelector((state) => state.banner);
  const [moneyUnit, setMoneyUnit] = useState(PRICE_UNIT.VND);
  const [isVietNam, setIsVietnam] = useState(PRICE_UNIT.VND);

  const handleSelectBank = (bankName) => {
    const bank = banks.find((item) => item.name === bankName);
    onSelectBank(bank);
  };

  const fetchBanks = async (getBankApi) => {
    setLoadingBank(true);
    const data = await getBankApi();
    if (data.status) {
      setBanks(data.result);
      onSelectBank(data.result[0] || null);
      setLoadingBank(false);
    }
  };

  const fetchCountry = async () => {
    try {
      const ipInfo = await apis.country.getCountry();
      if (ipInfo?.country && ipInfo?.country !== COUNTRY.VN)
        setIsVietnam(false);
      else setIsVietnam(true);
    } catch (error) {
      setIsVietnam(true);
    }
  };

  const fetchUsdToVndRate = async () => {
    const data = await apis.banks.getUSDToVNDRate();
    if (data.status) {
      const { result } = data;
      setUsdToVndRate(result || 1);
    }
  };

  const setDefaultPaymentMethods = (paymentMethodList) => {
    const paymentMethod = paymentMethodList.find(
      (item) => item.type === PAYMENT_METHOD_TYPE.BANK,
    );
    onSelectPaymentMethod(paymentMethod);
    fetchBanks(apis.banks.getTransferBanks);
  };

  const fetchPaymentMethods = async () => {
    setLoadingPaymentMethod(true);

    const data = await apis.paymentMethods.getPaymentMethods();
    setLoadingPaymentMethod(false);
    if (data.status) {
      const { result } = data;
      let displayPaymentMethods = [];

      displayPaymentMethods = result.paymentMethods.filter(
        (method) =>
          method.active &&
          method.type !== PAYMENT_METHOD_TYPE.APPLE &&
          method.type !== PAYMENT_METHOD_TYPE.GOOGLE,
      );

      if (!isVietNam) {
        displayPaymentMethods = result.paymentMethods.filter((method) =>
          GLOBAL_PAYMENT_METHOD.includes(method.type),
        );
        setMoneyUnit(PRICE_UNIT.USD);
      }

      setPaymentMethods(displayPaymentMethods);
      if (!hasPaid) setDefaultPaymentMethods(result.paymentMethods);
    }
  };

  const handleSelectPaymentMethod = (paymentMethodId, paymentMethodType) => {
    if (hasPaid) return;
    if (paymentMethodType === PAYMENT_METHOD_TYPE.BANK)
      fetchBanks(apis.banks.getTransferBanks);
    if (paymentMethodType === PAYMENT_METHOD_TYPE.ATM)
      fetchBanks(apis.banks.getBanks);

    const paymentMethod = paymentMethods.find(
      (item) => item.id === paymentMethodId,
    );
    onSelectPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchUsdToVndRate();
  }, [isVietNam]);

  useEffect(() => {
    if (!latestOrder.status) return;
    if (latestOrder.status === ORDER_STATUS.PENDING) {
      onSelectBank(latestOrder.bank);
    } else fetchPaymentMethods();
  }, [latestOrder, hasPaid, isVietNam]);

  useEffect(() => {
    if (backToTTS) {
      dispatch(actions.user.resetBackToTTS());
      history.push(ROUTES.TTS);
    }
  }, [backToTTS]);

  useEffect(() => {
    const doneTourGuide = localStorage.getItem(PAYMENT_TOUR_GUIDE.title);
    if (!doneTourGuide && seenAllBanner && !loadingBank && !hasFirstNotify) {
      onChangeRunTourGuide(true);
      localStorage.setItem(PAYMENT_TOUR_GUIDE.title, true);
    }
  }, [loadingBank, loadingPaymentMethod, seenAllBanner, hasFirstNotify]);

  const renderPaymentMethodBody = (paymentMethodType) => {
    if (
      paymentMethodType === PAYMENT_METHOD_TYPE.BANK ||
      (paymentMethodType === PAYMENT_METHOD_TYPE.ATM && !hasPaid)
    )
      return (
        <ProcessHandler loading={loadingBank} mt="10px" ml="10px" size={25}>
          <StyledBankList>
            {banks &&
              banks.map((bank) => (
                <BankItem
                  onSelect={handleSelectBank}
                  bank={bank}
                  selected={selectedBank && selectedBank.name === bank.name}
                />
              ))}
          </StyledBankList>
        </ProcessHandler>
      );
    return '';
  };

  const renderTransferInformation = () => (
    <ProcessHandler loading={loadingTransfer} mt="20px" ml="10px" size={25}>
      {isBankTransfer && selectedBank && (
        // <PaymentMethodItem
        //   paymentMethod={selectedPaymentMethod}
        //   selected
        //   darkRadio
        //   showDescription={false}
        // >
        <StyledTransferInformation>
          <div className="content-wrapper transfer-info">
            <img
              src={`${QR_CODE_URL}/image/${selectedBank?.name}-${
                selectedBank?.number
              }-${TEMPLATE_QR_CODE}.png?amount=${
                isVietNam ? totalPrice : Math.ceil(totalPrice * usdToVndRate)
              }&addInfo=${latestOrder.code}&accountName=${
                selectedBank?.holder
              }`}
              alt="bank-qr"
              className="bank-qr"
            />
            <div className="transfer-information">
              {/* <div
                  // TODO: fix dangerouslySetInnerHTML
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(t('transferNote')),
                  }}
                  className="transfer-note"
                /> */}
              <div className="content-information">
                <div className="row-content">
                  <Typography className="label">{t('bank')}:</Typography>
                  <Typography className="value">
                    {removeVietnameseTones(
                      selectedBank?.address.split('-')[0],
                      language,
                    )}
                  </Typography>
                </div>
                <div className="row-content">
                  <Typography className="label">{t('bankBrand')}:</Typography>
                  <Typography className="value">
                    {removeVietnameseTones(
                      selectedBank?.address.split('-')[1],
                      language,
                    )}
                  </Typography>
                </div>
                <div className="row-content">
                  <Typography className="label">
                    {t('accountOwner')}:
                  </Typography>
                  <Typography className="value">
                    {removeVietnameseTones(selectedBank?.holder, language)}
                  </Typography>
                </div>
                <div className="row-content last-item">
                  <Typography className="label">{t('cardNumber')}:</Typography>
                  <Typography className="value card-number">
                    <CopyableButton
                      content={selectedBank?.bankNumber || selectedBank?.number}
                    />
                    <b>{selectedBank?.bankNumber || selectedBank?.number}</b>
                  </Typography>
                </div>
                <div className="row-content">
                  <Typography className="label">
                    {t('amountToTransfer')}:
                  </Typography>
                  <Typography className="value">
                    <b>
                      {delimitNumber(totalPrice, language)} {t(moneyUnit)}
                    </b>
                  </Typography>
                </div>
                <div className="row-content">
                  <Typography className="label">
                    {t('transferContent')}:
                  </Typography>
                  <Typography className="value card-number">
                    <CopyableButton content={latestOrder.code} />
                    <b className="important-content">{latestOrder.code}</b>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="note">
            <Typography className="note">{t('note')}:</Typography>
            <div
              // TODO: fix dangerouslySetInnerHTML
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(t('transferInformationNoteContent')),
              }}
            />
          </div>
          <div className="payment-method-description">
            {selectedPaymentMethod.description[language] ||
              selectedPaymentMethod.description[LANGUAGE.EN]}
          </div>
        </StyledTransferInformation>
        // </PaymentMethodItem>
      )}
    </ProcessHandler>
  );

  const renderRedirectProcessing = () => (
    <ProcessHandler loading={loadingTransfer} mt="20px" ml="10px" size={25} />
  );

  const isPaymentStep = activeStep === PAYMENT_STEP.PAYMENT_METHOD;
  const isRenderedBankTransfer =
    isBankTransfer &&
    latestOrder.status === ORDER_STATUS.PENDING &&
    !latestOrder.paymentLink;

  const renderTitle = () => {
    if (isPaymentStep) return t('choosePaymentMethod');
    if (latestOrder.status === ORDER_STATUS.PENDING && latestOrder.paymentLink)
      return '';
    if (isBankTransfer) return t('transferInformation');
    if (loadingTransfer) return t('processing');
    if (hasPaid) return t('successfulPayment');
    return t('redirecting');
  };

  return (
    <div id={PAYMENT_TOUR_GUIDE.CHOOSE_PAYMENT_METHOD.title}>
      <CommonBox
        title={renderTitle()}
        bordered={isPaymentStep || hasPaid || isBankTransfer}
      >
        {(isPaymentStep || hasPaid) && <Box height={12} />}
        {(isPaymentStep || hasPaid) && (
          <ProcessHandler
            loading={loadingPaymentMethod}
            mt="20px"
            ml="10px"
            size={25}
          >
            {paymentMethods?.length &&
              paymentMethods
                .sort((a, b) => a.rank - b.rank)
                .map((paymentMethod) => {
                  const selected =
                    selectedPaymentMethod &&
                    selectedPaymentMethod.id === paymentMethod.id;
                  return (
                    paymentMethod.active && (
                      <PaymentMethodItem
                        paymentMethod={paymentMethod}
                        selected={selected}
                        onCheck={handleSelectPaymentMethod}
                      >
                        {selected &&
                          renderPaymentMethodBody(paymentMethod.type)}
                      </PaymentMethodItem>
                    )
                  );
                })}
          </ProcessHandler>
        )}
        {[PAYMENT_STEP.PAYMENT, PAYMENT_STEP.PENDING_PAYMENT].includes(
          activeStep,
        ) &&
        selectedPaymentMethod &&
        isRenderedBankTransfer
          ? renderTransferInformation()
          : renderRedirectProcessing()}
      </CommonBox>
    </div>
  );
};

export default PaymentBox;
