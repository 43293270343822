import React from 'react';
import BadgeAvatar from '@src/components/BadgeAvatar';
import { PlayArrowRounded } from '@mui/icons-material';
import { TTS_VERSION } from '@src/constants/tts';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@src/redux/actions';
import { INIT_NOTIFICATION_DIALOG } from '@src/constants/dialog';
import { PACKAGE_LEVEL, PACKAGE_TYPE } from '@src/constants/package';
import { checkVietNam } from '@src/utils/checkCountry';
import { checkFeaturePermission } from '@src/services/tts';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import { StyledVoice } from './index.styled';

const Voice = ({
  voice,
  onSelectedVoice,
  usingPackageType,
  onChangePlayAudio,
}) => {
  const { t } = useTranslation();
  const { openSentences } = useSelector((state) => state.synthesisRequest);
  const { usingPackage, ttsUser, usingDubbingPackage } = useSelector(
    (state) => state.user,
  );
  const { defaultGlobalVoice } = useSelector((state) => state.voice);

  const isVietNam = checkVietNam();
  const isDefaultGlobalVoice =
    !isVietNam &&
    usingPackage?.level === PACKAGE_LEVEL.BASIC &&
    voice?.code === defaultGlobalVoice;

  const dispatch = useDispatch();

  const canUseVoice = () => {
    if (!voice?.features?.length) return true;

    const usingPackageByType =
      usingPackageType === PACKAGE_TYPE.DUBBING ? ttsUser?.dubbing : ttsUser;

    if (!usingDubbingPackage && isDefaultGlobalVoice) return true;

    return voice.features.every((item) =>
      checkFeaturePermission(usingPackageByType?.features, item),
    );
  };

  const handleChangePlayAudio = (e) => {
    e.stopPropagation();
    onChangePlayAudio(voice);
  };

  const handleCloseNotificationDialog = () =>
    dispatch(
      actions.dialog.displayDialog({
        notificationDialog: INIT_NOTIFICATION_DIALOG,
      }),
    );

  const handleSelectedVoice = () => {
    if (voice?.version === TTS_VERSION.EMPHASIS && openSentences) {
      dispatch(
        actions.dialog.displayDialog({
          notificationDialog: {
            name: 'cannotUseVoice',
            title: t('cannotUseVoice'),
            description: t('cannotUseVoiceDes'),
            variant: 'warning',
            open: true,
            actionComponent: (
              <Button
                variant="contained"
                onClick={handleCloseNotificationDialog}
              >
                {t('understood')}
              </Button>
            ),
          },
        }),
      );
      return;
    }

    if (canUseVoice()) {
      onSelectedVoice(voice);
    } else {
      dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
    }
  };
  return (
    <StyledVoice onClick={handleSelectedVoice}>
      <BadgeAvatar
        width={40}
        smallImgWidth={10}
        img={voice && voice.roundImage}
        smallImg={voice && voice.language && voice.language.roundImage}
        type="image"
      />
      <div className="content">
        <span className="voice-name">{voice.name}</span>
        {/* <span className="voice-infor"> HN - Nữ - Giải trí</span> */}
      </div>
      <IconButton
        onClick={handleChangePlayAudio}
        aria-label="play"
        className="icon-button"
      >
        {voice.playStatus ? (
          <PauseRoundedIcon className="play-icon" fontSize="large" />
        ) : (
          <PlayArrowRounded className="play-icon" fontSize="large" />
        )}
      </IconButton>
    </StyledVoice>
  );
};

export default Voice;
