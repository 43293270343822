import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledOrderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 16px;

  .information {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
    }

    .value {
      text-align: right;
    }

    .change-phone-number {
      justify-content: flex-end;
    }

    .error {
      background: ${TRANSPARENT_COLOR.error};
    }
  }

  .label {
    width: fit-content;
  }

  .payment-sum {
    width: 97%;
    display: flex;
    justify-content: space-between;
    border-radius: ${BORDER_RADIUS};
    padding: 14px 6px;
    font-weight: 500;
    background: ${TRANSPARENT_COLOR.dark};

    .label {
      text-transform: uppercase;
    }

    .sum {
      text-align: right;
      .price {
        color: ${COLOR.darkBlue};
        b {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .vat {
        font-size: 10px;
        font-style: italic;
      }
    }
  }

  button {
    text-transform: unset;
    font-weight: bold;
    font-size: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .text-link {
    text-decoration: none;
    color: ${COLOR.info};
  }

  .update-phone-number-button {
    color: ${COLOR.darkBlue};
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .update-phone-number-text {
    color: ${COLOR.error};
    font-size: 16px;
    font-weight: 500;
  }

  .make-payment {
    width: 100%;
  }
`;

export const StyledChangePhoneNumberDialog = styled('div')`
  .dialog-content {
    padding: 0 24px;
  }

  .row {
    padding: 10px 0;
  }

  .title {
    margin-bottom: 8px;
  }

  .dialog-actions {
    justify-content: center;
    padding: 20px 0;
  }
`;
