import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSkip } from './index.style';

const SkipButton = ({ handleSkipTour }) => {
  const { t } = useTranslation();
  return (
    <StyledSkip onClick={handleSkipTour}>
      <div className="button-back">{t('skip')}</div>
    </StyledSkip>
  );
};

export default SkipButton;
