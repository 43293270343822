import { RESOURCE } from '@src/constants';
import api from './api';

const updateAdsProvider = async ({
  adsProvider,
  adsProviderTime,
  accessToken,
}) => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.USERS}/update-ads-provider`,
    headers: { authorization: `Bearer ${accessToken}` },
    data: { adsProvider, adsProviderTime },
  });
  return response;
};

export { updateAdsProvider };
