import React from 'react';
// import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import {
  StyledTooltipBody,
  StyledTooltipContent,
  StyledTooltipTitle,
  StepButton,
  NextButton,
  SkipButton,
  StyledTooltipFooter,
  BackButton,
  // CloseButton,
} from './index.style';

const Tooltip = ({
  continuous,
  step,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
}) => (
  <StyledTooltipBody {...tooltipProps}>
    {step.title && (
      <StyledTooltipTitle>
        <Typography>{step.title}</Typography>
        {/* <CloseButton {...skipProps} aria-label="close">
          <Close color="divider" />
        </CloseButton> */}
      </StyledTooltipTitle>
    )}
    <StepButton disabled>{step.locale.step}</StepButton>
    {step.content && (
      <StyledTooltipContent>{step.content}</StyledTooltipContent>
    )}
    <StyledTooltipFooter>
      <SkipButton aria-label="skip" {...skipProps}>
        {step.locale.skip}
      </SkipButton>
      <div className="buttons-right">
        {step.locale.back && (
          <BackButton aria-label="back" {...backProps}>
            {step.locale.back}
          </BackButton>
        )}
        {continuous && (
          <NextButton aria-label="next" {...primaryProps}>
            {step.locale.next}
          </NextButton>
        )}
      </div>
    </StyledTooltipFooter>
  </StyledTooltipBody>
);
export default Tooltip;
