import { DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledVoucherBox = styled('div')`
  .choose {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;

    .choose-text {
      color: ${COLOR.info};
      font-weight: 500;
    }

    .tag {
      color: ${COLOR.darkBlue};
      font-weight: 500;
    }
  }

  .note {
    font-size: 12px;
    font-weight: 500;
  }

  .applied-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .unselect {
      font-weight: 500;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const StyledVoucherDialog = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 46px;

  .promotion-title {
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 15px;
  }

  hr {
    border-width: 2;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .voucher-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    p:first-child {
      font-weight: 500;
      font-size: 21px;
    }

    p:last-child {
      font-weight: 500;
      color: ${COLOR.dark};
    }
  }

  .voucher-error {
    color: ${COLOR.danger};
    font-size: 13px;
    margin: 3px 0;
  }
`;

export const StyledInputWrapper = styled('div')`
  display: flex;
  border: 1px solid ${COLOR.light};
  border-radius: ${BORDER_RADIUS};
  padding: 2px 2px 2px 12px;

  .input-promotion {
    flex: 1;
    width: 100%;
    font-size: 14px;
  }

  button {
    border-radius: 0px ${BORDER_RADIUS} ${BORDER_RADIUS} 0px;
    text-transform: unset;
    padding: 6px 12px;
  }
`;

export const StyledVoucher = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${(props) => (props.applied ? COLOR.primary : COLOR.light)};
  margin: 6px 0;
  background: ${(props) =>
    props.applied ? TRANSPARENT_COLOR.primary : COLOR.white};

  &:hover {
    background: ${(props) =>
      props.applied ? TRANSPARENT_COLOR.primary : TRANSPARENT_COLOR.dark};
  }

  .left-column {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      font-size: 18px;
    }
  }

  .satisfied-text {
    color: ${COLOR.darkBlue} !important;
    font-weight: 600 !important;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    gap: 5px;

    svg {
      cursor: pointer;
    }

    p {
      font-weight: 600;
      cursor: pointer;
    }

    .detail {
      background: ${COLOR.error};
      width: 25px;
      height: 25px;
      z-index: 200000;
    }
  }
`;
