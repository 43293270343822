import { DEFAULT_SYNTHESIS_REQUEST } from '@src/constants/voice';
import { actionTypes } from './actions';

export const initialState = {
  synthesisRequest: DEFAULT_SYNTHESIS_REQUEST,
  loadingSynthesisConfig: false,
  paragraphs: [],
  isLoadingParagraphs: false,
  selectedParagraph: [],
  previewParagraphs: [],
  sentences: [],
  selectedSentences: [], // list of id sentences selected by user
  selectedSentence: {},
  openSentences: false,
  ttsAttributeSentence: {},
  advanceFeature: false,
  blackWordsInText: [],
  defaultSample: {},
  showSample: true,
  voice: {},
  search: '',
  replace: '',
};

const synthesisRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SYNTHESIS_REQUEST: {
      const { name, value } = action;
      return {
        ...state,
        synthesisRequest: { ...state.synthesisRequest, [name]: value },
      };
    }

    case actionTypes.UPDATE_SYNTHESIS_CONFIG: {
      const { synthesisRequest } = action;
      return { ...state, synthesisRequest };
    }

    case actionTypes.UPDATE_LOADING_SYNTHESIS_CONFIG: {
      const { loading } = action;
      return { ...state, loadingSynthesisConfig: loading };
    }

    case actionTypes.UPDATE_BACKGROUND_MUSIC: {
      const { name, link } = action;
      const backgroundMusic = {
        ...state.synthesisRequest.backgroundMusic,
        name,
        link,
      };

      return {
        ...state,
        synthesisRequest: {
          ...state.synthesisRequest,
          backgroundMusic,
        },
      };
    }

    case actionTypes.UPDATE_VOLUME_BACKGROUND_MUSIC: {
      const { volume } = action;
      const backgroundMusic = {
        ...state.synthesisRequest.backgroundMusic,
        volume,
      };

      return {
        ...state,
        synthesisRequest: {
          ...state.synthesisRequest,
          backgroundMusic,
        },
      };
    }

    case actionTypes.UPDATE_PARAGRAPHS: {
      const { paragraphs } = action;
      return { ...state, paragraphs };
    }

    case actionTypes.UPDATE_LOADING_PARAGRAPHS: {
      const { loading } = action;
      return { ...state, isLoadingParagraphs: loading };
    }

    case actionTypes.UPDATE_SELECTED_PARAGRAPH: {
      const { data } = action;
      return { ...state, selectedParagraph: data };
    }

    case actionTypes.UPDATE_PREVIEW_PARAGRAPHS: {
      const { data } = action;
      return { ...state, previewParagraphs: data };
    }

    case actionTypes.UPDATE_SENTENCES: {
      const { sentences } = action;
      return { ...state, sentences };
    }

    case actionTypes.UPDATE_BLACK_WORDS: {
      const { blackWordsInText } = action;
      return { ...state, blackWordsInText };
    }

    case actionTypes.UPDATE_ELEMENT_OF_SENTENCES: {
      const { elements, sentenceId, text, onLoad, mousePointerPosition } =
        action;
      const newSentences = [...state.sentences];

      const index = newSentences.findIndex((item) => item.id === sentenceId);
      if (index === -1) return state;
      newSentences[index] = {
        ...newSentences[index],
        elements,
        text,
        onLoad,
        mousePointerPosition,
      };
      return { ...state, sentences: newSentences };
    }

    case actionTypes.UPDATE_SELECTED_SENTENCE: {
      const { selectedText, sentenceId, startOffset, endOffset } = action;
      const newSelectedSentence = { focusPosition: startOffset, sentenceId };

      if (
        startOffset !== undefined &&
        endOffset !== undefined &&
        startOffset !== endOffset
      ) {
        return {
          ...state,
          selectedSentence: {
            ...newSelectedSentence,
            selectedText,
            startOffset,
            endOffset,
          },
        };
      }
      return { ...state, selectedSentence: newSelectedSentence };
    }

    case actionTypes.UPDATE_OPEN_SENTENCE: {
      const { openSentences } = action;
      return { ...state, openSentences };
    }

    case actionTypes.REMOVE_TTS_ATTRIBUTE_BY_SENTENCE: {
      const { data } = action;
      return { ...state, ttsAttributeSentence: data };
    }

    case actionTypes.SWITCH_ADVANCE_FEATURE: {
      const { advanceFeature } = action;
      return { ...state, advanceFeature };
    }

    case actionTypes.UPDATE_VOICE: {
      const { voice } = action;
      return { ...state, voice };
    }

    case actionTypes.UPDATE_SELECTED_SENTENCES: {
      const { selectedSentences } = action;
      return { ...state, selectedSentences };
    }

    case actionTypes.CHANGE_SEARCH_CONTENT: {
      const { search } = action;
      return { ...state, search };
    }

    case actionTypes.REPLACE_IN_SENTENCES: {
      const { replace } = action;
      return { ...state, replace };
    }

    case actionTypes.CLONE_REQUEST: {
      const { synthesisRequest } = state;
      const { id, status, createdAt, endedAt, progress, title, ...rest } =
        synthesisRequest;

      return { ...state, synthesisRequest: { ...rest, title: '' } };
    }

    case actionTypes.UPDATE_DEFAULT_REQUEST: {
      const { defaultSample } = action;
      return { ...state, defaultSample };
    }

    case actionTypes.UPDATE_SHOW_SAMPLE_SCRIPTS: {
      const { showSample } = action;
      return { ...state, showSample };
    }

    default:
      return state;
  }
};

export default synthesisRequestReducer;
