import AdapterDateFns from '@mui/lab/AdapterDateFns';

export default function CustomAdapter(options) {
  const adapter = new AdapterDateFns(options);

  const constructUpperObject = (text) => ({ toUpperCase: () => text });
  const constructDayObject = (day) => ({
    charAt: () => constructUpperObject(day),
  });

  return {
    ...adapter,

    getWeekdays() {
      const customWeekdays = adapter.getWeekdays();
      return customWeekdays.map((day) => constructDayObject(day));
    },
  };
}
