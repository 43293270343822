import { v4 as uuidv4 } from 'uuid';
import apis from '@src/apis';
import { nonAccentVietnamese } from '@src/utils/accent';

const uploadFileToS3 = async (fileName, directory, extension, file) => {
  let refactorFileName = fileName.trim().replace(/[()\s]/g, '-');
  refactorFileName = nonAccentVietnamese(refactorFileName);
  refactorFileName = `${refactorFileName}-${uuidv4()}}`;
  const presignedUrlData = await apis.files.getFilePresignedUrlForUploading(
    refactorFileName,
    directory,
    extension,
  );
  if (!presignedUrlData || !presignedUrlData.status) return false;

  const presignedUrl = presignedUrlData.result.url;

  const uploadData = await apis.upload.uploadByPresignedUrl(presignedUrl, file);
  if (!uploadData || !uploadData.status) return false;

  const [url] = presignedUrl.split('?');
  return url;
};

const uploadAudioLinkToS3 = async (
  fileName,
  directory,
  extension,
  file,
  nonSuffix = true,
) => {
  try {
    const timestamp = new Date().getTime();
    const refactorFileName = `${fileName}_temp_${timestamp}`;
    const presignedUrlData = await apis.files.getFilePresignedUrlForUploading(
      refactorFileName,
      directory,
      extension,
      nonSuffix,
    );
    if (!presignedUrlData || !presignedUrlData.status) return false;

    const presignedUrl = presignedUrlData.result.url;
    const uploadData = await apis.upload.uploadByPresignedUrl(
      presignedUrl,
      file,
    );
    if (!uploadData || !uploadData.status) return false;
    const [url] = presignedUrl.split('?');
    return { error: false, url };
  } catch (error) {
    return { error: true };
  }
};

const uploadToS3FromFile = async (file, directory) => {
  if (!file) return null;

  const fileName = file.name?.slice(0, file.name?.lastIndexOf('.'));
  const extension = file.name?.split('.')?.pop();
  const url = await uploadFileToS3(fileName, directory, extension, file);
  return url;
};

const uploadFileToGoogleStorage = async (
  fileName,
  directory,
  extension,
  file,
) => {
  let refactorFileName = fileName.trim().replace(/[()\s]/g, '-');
  refactorFileName = nonAccentVietnamese(refactorFileName);
  refactorFileName = `${directory}/${refactorFileName}-${uuidv4()}.${extension}`;

  const contentType = file.type;

  const googlePresignedUrlData =
    await apis.files.getGooglePresignedUrlForUploading(
      refactorFileName,
      contentType,
    );

  if (!googlePresignedUrlData || !googlePresignedUrlData.status) return false;

  const presignedUrl = googlePresignedUrlData.result.url;
  const bucketName = presignedUrl.split('/')[3];

  const uploadData = await apis.upload.uploadFileToGoogleStorage(
    presignedUrl,
    file,
    contentType,
  );

  if (!uploadData || !uploadData.status) return false;

  const fileLocation = `gs://${bucketName}/${refactorFileName}`;

  return fileLocation;
};

export {
  uploadFileToS3,
  uploadToS3FromFile,
  uploadFileToGoogleStorage,
  uploadAudioLinkToS3,
};
