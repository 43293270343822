/* eslint-disable no-param-reassign */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@mui/material';
import {
  Pause,
  PlayArrowRounded,
  FavoriteBorderOutlined,
} from '@mui/icons-material';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import Feature from '@src/components/Feature';
import BadgeAvatar from '@src/components/BadgeAvatar';
import ImportantTag from '@src/components/ImportantTag';

import { checkVietNam } from '@src/utils/checkCountry';
import { PACKAGE_LEVEL, PACKAGE_TYPE } from '@src/constants/package';
import { INIT_NOTIFICATION_DIALOG } from '@src/constants/dialog';
import { TTS_VERSION } from '@src/constants/tts';
import { VOICE_LEVEL } from '@src/constants/voice';
import { COLOR } from '@src/styles/color';
import { checkFeaturePermission } from '@src/services/tts';
import actions from '@src/redux/actions';

import { StyledVoiceItem } from './index.style';

const VoiceItem = ({
  voice,
  activeVoiceId,
  usingPackageType,
  onChangePlayAudio,
  onSelectedVoice,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { usingPackage, ttsUser } = useSelector((state) => state.user);
  const { openSentences } = useSelector((state) => state.synthesisRequest);
  const { defaultGlobalVoice } = useSelector((state) => state.voice);
  const { usingDubbingPackage } = useSelector((state) => state.user);

  const isVietNam = checkVietNam();
  const isDefaultGlobalVoice =
    !isVietNam &&
    usingPackage?.level === PACKAGE_LEVEL.BASIC &&
    voice?.code === defaultGlobalVoice;

  const canUseVoice = () => {
    if (!voice?.features?.length) return true;

    const usingPackageByType =
      usingPackageType === PACKAGE_TYPE.DUBBING ? ttsUser?.dubbing : ttsUser;

    if (!usingDubbingPackage && isDefaultGlobalVoice) return true;

    return voice.features.every((item) =>
      checkFeaturePermission(usingPackageByType?.features, item),
    );
  };

  const handleChangePlayAudio = (e) => {
    e.stopPropagation();
    onChangePlayAudio(voice);
  };

  const handleCloseNotificationDialog = () =>
    dispatch(
      actions.dialog.displayDialog({
        notificationDialog: INIT_NOTIFICATION_DIALOG,
      }),
    );

  const handleSelectedVoice = () => {
    if (voice?.version === TTS_VERSION.EMPHASIS && openSentences) {
      dispatch(
        actions.dialog.displayDialog({
          notificationDialog: {
            name: 'cannotUseVoice',
            title: t('cannotUseVoice'),
            description: t('cannotUseVoiceDes'),
            variant: 'warning',
            open: true,
            actionComponent: (
              <Button
                variant="contained"
                onClick={handleCloseNotificationDialog}
              >
                {t('understood')}
              </Button>
            ),
          },
        }),
      );
      return;
    }

    if (canUseVoice()) {
      onSelectedVoice(voice);
    } else {
      dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
    }
  };

  const styles =
    !!voice?.styles?.length && voice.styles.map((item) => t(item)).join(' - ');

  const getImportantTagName = () => {
    if (voice?.beta) return t('beta');
    if (voice?.level === VOICE_LEVEL.PREMIUM) return t(voice.level);
    return '';
  };

  const getImportantTagBg = () => {
    if (voice.id === activeVoiceId) return COLOR.white;
    if (openSentences && voice?.version === TTS_VERSION.EMPHASIS)
      return COLOR.lightText;
    if (voice?.beta) return COLOR.skyBlue;
    return COLOR.primary;
  };

  return (
    <StyledVoiceItem
      disabledVoice={openSentences && voice?.version === TTS_VERSION.EMPHASIS}
      active={voice.id === activeVoiceId}
      playingAudio={voice.playStatus}
      onClick={handleSelectedVoice}
      variant="outlined"
      aria-label={`${voice.name} voice`}
    >
      {getImportantTagName() && (
        <ImportantTag
          tag={getImportantTagName()}
          top="0px"
          right="0px"
          padding="5px 5px 4px 15px"
          borderRadius="0 4px 0px 20px"
          bgColor={getImportantTagBg()}
          color={
            voice.id === activeVoiceId ? COLOR.black.default : COLOR.darkBlue
          }
        />
      )}
      <div className="voice-info">
        <BadgeAvatar
          img={voice.roundImage}
          smallImg={voice && voice.language && voice.language.roundImage}
          type="image"
        />
        <div className="detail-voice">
          <Typography variant="h6" className="voice-name">
            {voice.name}
          </Typography>
          <Typography variant="subtitle1" className="features">
            {styles}
          </Typography>
        </div>
      </div>
      <IconButton onClick={handleChangePlayAudio} aria-label="play">
        <Feature name={FEATURE_FLAG.NEW_VOICE_DIALOG}>
          <FavoriteBorderOutlined className="favorite-icon" color="error" />
        </Feature>
        {voice.playStatus ? (
          <Pause className="play-icon" fontSize="large" />
        ) : (
          <PlayArrowRounded className="play-icon" fontSize="large" />
        )}
      </IconButton>
    </StyledVoiceItem>
  );
};
export default VoiceItem;
