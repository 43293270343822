import { useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useAuthSSO } from 'sso-package';
import useFeatureFlags from './useFeatureFlags';

const useCustomSSO = () => {
  const { user: userStore } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = userStore;
  const { getFeatureValue } = useFeatureFlags();
  const useSSO = getFeatureValue(FEATURE_KEYS.NEW_SSO_STUDIO, {
    userId,
    email,
    phoneNumber,
  });

  let keycloak;
  let initialized;
  const { keycloak: authKeycloakSSO, initialized: initializedKeycloak } =
    useKeycloak();
  const { authSSO } = useAuthSSO();

  if (useSSO) {
    keycloak = authSSO;
    initialized = authSSO.initialized;
  } else {
    keycloak = authKeycloakSSO;
    initialized = initializedKeycloak;
  }

  return { keycloak, initialized };
};

export default useCustomSSO;
