const checkValidNumber = (string) => {
  const numberRegex = new RegExp(/^\d+(\.\d)?\d*$/);
  return numberRegex.test(string);
};

const checkValidPhoneNumber = (number) => {
  const phoneNumberRegex = new RegExp(
    /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/g,
  );
  return number.match(phoneNumberRegex);
};

export { checkValidNumber, checkValidPhoneNumber };
