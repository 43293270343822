import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledNotiNewFeature = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  background-color: ${COLOR.functionYellow[16]};
  border-radius: 8px;
  margin-bottom: 12px;

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${COLOR.indigo[100]};
  }

  .icon {
    cursor: pointer;
    color: ${COLOR.indigo[100]};
    width: 24px;
    height: 24px;
  }

  .guide-button {
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    background-color: ${COLOR.functionYellow[16]};
    border-radius: 500px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: ${COLOR.indigo[100]};
    cursor: pointer;
    text-transform: none;

    .text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: ${COLOR.indigo[100]};
    }
  }
`;
