import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledModalCreateProject = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-top: 1px solid ${COLOR.label[16]};

  .title-input {
    font-size: 14px;
    font-weight: 500;
    color: ${COLOR.label2[100]};
  }

  .continue-button {
    border-radius: 100px;
    text-transform: none;
  }

  .continue-button-multiple-input {
    padding: 8px 24px;
    border-radius: 8px;
    text-transform: none;
    width: fit-content;
    font-size: 14px;
    font-weight: 700;
  }
`;
