import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import DirectBoxSendIcon from '@src/assets/icons/directbox-send.svg';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import SrtFileIcon from '@src/assets/icons/icon-srt-file.svg';
import Mp4FileIcon from '@src/assets/icons/icon-mp4-file.svg';
import SampleFileReference from './SampleFileReference';
import { StyledDefaultDropzoneContent } from './index.style';

const DefaultDropzoneContent = ({ fileFormat }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const OldDefaultDropzone = () => (
    <StyledDefaultDropzoneContent>
      <img src={DirectBoxSendIcon} alt="srt-icon" className="upload-image" />

      <div className="upload-instruction">
        <Typography
          align="center"
          variant="h5"
          className="upload-file-title text-blue"
        >
          {t('dropFileSuccessInstructSubtitle', { fileFormat })}
        </Typography>
        <SampleFileReference />
      </div>
    </StyledDefaultDropzoneContent>
  );

  const MultipleInputDefaultDropzone = () => (
    <StyledDefaultDropzoneContent>
      <Box className="upload-instruction">
        <Typography
          align="center"
          variant="h5"
          className="upload-file-title text-blue"
        >
          {t('dropFileSuccessInstructSubtitle', { fileFormat })}
        </Typography>
        <SampleFileReference />
      </Box>
      <Box display="flex" gap="8px">
        <img src={SrtFileIcon} alt="srt-icon" />
        <img src={Mp4FileIcon} alt="mp4-icon" />
      </Box>
    </StyledDefaultDropzoneContent>
  );

  return isMultipleInputDubbing ? (
    <MultipleInputDefaultDropzone />
  ) : (
    <OldDefaultDropzone />
  );
};

export default DefaultDropzoneContent;
