import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledTitleBar = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;

  .base-input {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
    margin-left: 5px;
    margin-right: 20px;
    font-size: 19px;
    font-weight: 600;
  }

  .text-transfer-button {
    text-transform: none;
    padding: 8px 16px 8px 12px;
    border-radius: 25px;
    margin-left: 12px;
    display: flex;
    justify-content: space-around;
    width: 140px;
    color: ${COLOR.darkBlue};
    height: 44px;
    font-weight: 700;
    line-height: 20px;
  }

  .character-default {
    font-size: 14px;
    font-weight: 500;
    color: ${COLOR.subText};
    margin-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;

    .character-red {
      color: ${COLOR.red} !important;
    }

    .character-in-use {
      color: ${COLOR.info};
    }
  }

  .estimate-audio-length {
    padding: 0px 12px 0px 16px;
    color: ${COLOR.subText};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 50px;
  }
`;

const StyledStatusSaving = styled('div')`
  .base-loading {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .loading-saved {
    margin-right: 5px;
    color: ${COLOR.lightGreen};
  }

  .loading-saving {
    margin-right: 5px;
    color: ${COLOR.primary};
  }
  .font-weight {
    font-weight: light;
  }
`;

export { StyledTitleBar, StyledStatusSaving };
