import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Divider, Typography } from '@mui/material';
import { ORDER_STATUS } from '@src/constants/order';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { delimitNumber } from '@src/utils/number';
import { PACKAGE_TYPE } from '@src/constants/package';
import { convertSecondsToHMS } from '@src/utils/time';
import moment from 'moment';

import { StyledNotConfirmOrderDialog } from './index.style';

const PaymentNotiDialog = ({
  openDialog,
  DIALOG_NAMES,
  onCloseDialog,
  onChangeLoading,
  onComeBackToPackage,
  onConfirmDowngradePackage,
  onConfirmDowngradePackageAndCancelSubscription,
  onConfirmCancelSubscription,
  onConfirmUpgradeOrBuyMore,
  onContinuePaying,
  onRedirectToTTS,
  useNewWarningBuyPackage,
  isDubbingRoute,
  productInfo,
}) => {
  const { t, language } = useTranslation();
  const dispatch = useDispatch();
  const { latestOrder, latestDubbingOrder } = useSelector(
    (state) => state.user,
  );

  const activeDialogName = Object.keys(openDialog).find(
    (key) => openDialog[key],
  );

  const ACTION_TYPE = {
    DOWNGRADE: 'downgrade',
    UPGRADE_OR_BUY_MORE: 'upgradeOrBuyMore',
  };

  const generateContent = (actionType) => {
    const { total, expires, packageType } = productInfo;
    const formatExpires = moment(expires).format('DD/MM/YYYY');

    const messageKeys = {
      [ACTION_TYPE.DOWNGRADE]: {
        studio: 'warningDowngradePackageDescriptionStudio',
        dubbing: 'warningDowngradePackageDescriptionDubbing',
      },
      [ACTION_TYPE.UPGRADE_OR_BUY_MORE]: {
        studio: 'warningUpgradePackageOrBuyMoreDescriptionStudio',
        dubbing: 'warningUpgradePackageOrBuyMoreDescriptionDubbing',
      },
    };

    if (packageType === PACKAGE_TYPE.STUDIO) {
      const formatTotal = delimitNumber(total, language);
      return t(messageKeys[actionType].studio, {
        formatTotal,
        formatExpires,
      });
    }

    const formatTotal = convertSecondsToHMS(total || 0);
    return t(messageKeys[actionType].dubbing, {
      formatTotal,
      formatExpires,
    });
  };

  const dialogList = [
    {
      name: DIALOG_NAMES.NOT_CONFIRMED_ORDER,
      title: t('warning'),
      description: t('hasOrderWaitForApprovalDescription'),
    },
    {
      name: DIALOG_NAMES.DOWNGRADE_PACKAGE,
      title: t('warningSelectSmallerPackageTitle'),
      description: useNewWarningBuyPackage
        ? generateContent(ACTION_TYPE.DOWNGRADE)
        : t('warningSelectSmallerPackageDescription'),
    },
    {
      name: DIALOG_NAMES.UPGRADE_PACKAGE_OR_BUY_MORE,
      title: t('warningSelectSmallerPackageTitle'),
      description: generateContent(ACTION_TYPE.UPGRADE_OR_BUY_MORE),
    },
    {
      name: DIALOG_NAMES.DOWNGRADE_PACKAGE_AND_CANCEL_SUBSCRIPTION,
      title: t('warningSelectSmallerPackageTitle'),
      description: t('downgradePackageAndCancelSubscriptionDescription'),
    },
    {
      name: DIALOG_NAMES.CANCEL_SUBSCRIPTION,
      title: t('warningSelectSmallerPackageTitle'),
      description: t('cancelSubscriptionDescription'),
    },
    {
      name: DIALOG_NAMES.UNPAID_ORDER,
      title: t('havePendingOrderTitle'),
      description: t('hasOrderWaitForConfirmTransferDescription'),
    },
    {
      name: DIALOG_NAMES.COMPLETED_ORDER,
      title: t('completedOrderTitle'),
      description: t('completedTransactionDescription'),
    },
  ];

  const activeDialog = dialogList.find(
    (dialog) => dialog.name === activeDialogName,
  );

  const handleCancelPendingOrder = async () => {
    if (!isDubbingRoute && latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (isDubbingRoute && latestDubbingOrder.status !== ORDER_STATUS.PENDING)
      return;

    onChangeLoading(true);
    const data = await apis.orders.cancelOrder(
      isDubbingRoute ? latestDubbingOrder?.id : latestOrder.id,
    );
    onChangeLoading(false);
    if (!data.status) {
      dispatch(actions.noti.push({ severity: 'error', code: data.code }));
    } else if (data.result.type === PACKAGE_TYPE.STUDIO)
      dispatch(actions.user.getLatestOrderSuccess(data.result));
    else if (data.result.type === PACKAGE_TYPE.DUBBING)
      dispatch(actions.user.getLatestDubbingOrderSuccess(data.result));
    onComeBackToPackage();
  };

  const renderNotiAction = () => {
    switch (activeDialogName) {
      case DIALOG_NAMES.UNPAID_ORDER:
      case DIALOG_NAMES.NOT_CONFIRMED_ORDER:
        return (
          <Box className="action-wrapper">
            <Button
              className="action-button cancel-button"
              onClick={handleCancelPendingOrder}
            >
              {t('cancelOrder')}
            </Button>
            <Button
              variant="contained"
              className="action-button pending-button"
              onClick={() => {
                if (activeDialogName === DIALOG_NAMES.UNPAID_ORDER)
                  onContinuePaying();
                else onComeBackToPackage();
              }}
            >
              {activeDialogName === DIALOG_NAMES.NOT_CONFIRMED_ORDER
                ? t('pending')
                : t('continue')}
            </Button>
          </Box>
        );

      case DIALOG_NAMES.COMPLETED_ORDER:
        return (
          <Box className="action-wrapper">
            <Button
              variant="contained"
              className="action-button pending-button"
              onClick={onRedirectToTTS}
            >
              {t('completed')}
            </Button>
          </Box>
        );

      case DIALOG_NAMES.DOWNGRADE_PACKAGE:
        return (
          <Box className="action-wrapper">
            <Button
              className="action-button cancel-button"
              onClick={() => onCloseDialog(DIALOG_NAMES.DOWNGRADE_PACKAGE)}
            >
              {t('chooseAnotherPackage')}
            </Button>
            <Button
              variant="contained"
              className="action-button pending-button"
              onClick={onConfirmDowngradePackage}
            >
              {t('continue')}
            </Button>
          </Box>
        );

      case DIALOG_NAMES.UPGRADE_PACKAGE_OR_BUY_MORE:
        return (
          <Box className="action-wrapper">
            <Button
              className="action-button cancel-button"
              onClick={() =>
                onCloseDialog(DIALOG_NAMES.UPGRADE_PACKAGE_OR_BUY_MORE)
              }
            >
              {t('chooseAnotherPackage')}
            </Button>
            <Button
              variant="contained"
              className="action-button pending-button"
              onClick={onConfirmUpgradeOrBuyMore}
            >
              {t('continue')}
            </Button>
          </Box>
        );

      case DIALOG_NAMES.DOWNGRADE_PACKAGE_AND_CANCEL_SUBSCRIPTION:
        return (
          <Box className="action-wrapper">
            <Button
              className="action-button cancel-button"
              onClick={() =>
                onCloseDialog(
                  DIALOG_NAMES.DOWNGRADE_PACKAGE_AND_CANCEL_SUBSCRIPTION,
                )
              }
            >
              {t('chooseAnotherPackage')}
            </Button>
            <Button
              variant="contained"
              className="action-button pending-button"
              onClick={onConfirmDowngradePackageAndCancelSubscription}
            >
              {t('continue')}
            </Button>
          </Box>
        );

      case DIALOG_NAMES.CANCEL_SUBSCRIPTION:
        return (
          <Box className="action-wrapper">
            <Button
              className="action-button cancel-button"
              onClick={() => onCloseDialog(DIALOG_NAMES.CANCEL_SUBSCRIPTION)}
            >
              {t('chooseAnotherPackage')}
            </Button>
            <Button
              variant="contained"
              className="action-button pending-button"
              onClick={onConfirmCancelSubscription}
            >
              {t('continue')}
            </Button>
          </Box>
        );

      default:
        return null;
    }
  };

  const renderNotiContent = () => (
    <>
      <Box className="title-wrapper ">
        <Typography className="title-content">{activeDialog.title}</Typography>
      </Box>
      <Divider />
      <Box className="content-wrapper">
        <Typography
          className="content"
          dangerouslySetInnerHTML={{
            __html: activeDialog.description,
          }}
        />
      </Box>
    </>
  );

  if (!activeDialog) return '';

  return (
    <StyledNotConfirmOrderDialog
      open={openDialog[activeDialog.name]}
      fullWidth
      maxWidth="sm"
    >
      <Box className="wrapper">
        {renderNotiContent()}
        {renderNotiAction()}
      </Box>
    </StyledNotConfirmOrderDialog>
  );
};

export default PaymentNotiDialog;
