import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { GRADIENT_COLOR, COLOR } from '@src/styles/color';
import PopularBackground from '@src/assets/images/popular-background.png';

import {
  BOX_SHADOW,
  MOBILE_BREAKPOINT,
  PACKAGE_HEIGHT_EXTRA,
} from '@src/styles/config';

const StyledPackages = styled('div')`
  width: 100%;

  .package-duration-item {
    position: relative;

    .package-duration-label {
      color: ${COLOR.lightText};
      font-weight: 700 !important;
    }

    .package-duration-label-active {
      color: ${COLOR.darkBlue};
      font-weight: 700 !important;
    }
  }

  .discount-year {
    display: block;
    position: absolute;
    width: 76px;
    top: 5px;
    left: ${(props) => (props.isVietNam ? '120px' : '90px')};
    padding: 8px 16px;
    border-radius: 16px;
    font-weight: 700;
    background: ${COLOR.functionYellow[64]};
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
  }

  .discount-year-height {
    display: none;
  }

  .policy-voice {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 500;
    color: ${COLOR.lightText};
    margin-left: 36px;
    margin-top: 20px;
  }

  .tabs-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .package-type-label {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.4px;
      color: ${COLOR.black.default};
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
  .tabs-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .tab {
    min-width: 300px;
    background-color: ${COLOR.white};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tab-active {
    border: 1px solid ${COLOR.darkBlue};
    margin-bottom: -1px;
    border-bottom: none;
    color: ${COLOR.darkBlue};
  }
  .tab-inactive {
    background-color: ${COLOR.divider};
    color: ${COLOR.dark};
  }

  .duration-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    .checkbox-button {
      color: ${COLOR.darkBlue};
      border-radius: 100%;
    }
  }
  .duration-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .duration {
    min-width: 200px;
    background-color: ${COLOR.primary};
    color: ${COLOR.darkBlue};
    text-transform: none;
  }
  .duration-inactive {
    background-color: ${COLOR.divider};
    color: ${COLOR.dark};
  }
  .empty-duration {
    height: 48px;
  }

  .packages-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .packages {
    width: 100%;
    max-width: ${(props) => (props.num < 3 ? '800px' : '1600px')};
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 16px;
    margin-bottom: 24px;
  }

  .mobile-packages {
    display: none;
    max-width: 400px;
  }

  .step-buttons {
    display: none;
    position: fixed;
    top: 70%;
    z-index: 1000;
  }

  .discount-year-mobile {
    display: none;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .mobile-packages,
    .step-buttons {
      display: block;
    }

    .package-duration-label {
      font-size: 14px;
    }

    .discount-year {
      display: none;
    }

    .discount-year-mobile {
      display: block;
      justify-content: center;
      padding: 8px 16px;
      border-radius: 16px;
      font-weight: 700;
      background: ${COLOR.functionYellow[64]};
      width: fit-content;
      text-transform: uppercase;
      font-size: 14px;
    }

    .discount-year-height {
      height: 35px;
    }

    .tab,
    .duration {
      min-width: 0px;
    }

    .packages-wrapper {
      margin: 0;
    }
  }

  @media only screen and (max-width: 400px) {
    .mobile-packages {
      max-width: 360px;
    }
  }
`;

const StyledNewPackageItem = styled(Card)`
  box-shadow: none;
  overflow: visible;
  padding: 16px;
  border-radius: ${(props) => (props.highlight ? '0px 0px 16px 16px' : '16px')};
  box-shadow: ${(props) => (props.highlight ? BOX_SHADOW : 'none')};
  border: ${(props) =>
    props.isDiscountPackage || props.isMostPopular
      ? `2px solid ${COLOR.yellow.default}`
      : `2px solid ${COLOR.white}`};

  .package-header {
    display: flex;
    justify-content: space-between;
    height: 60px;
  }

  .package-icon {
    border-radius: 9999px;
    background-color: ${COLOR.black[8]};
    width: 48px;
    height: 48px;
  }

  .package-icon-active {
    border-radius: 9999px;
    background-color: ${COLOR.primary};
    width: 48px;
    height: 48px;
  }

  .card-content {
    position: relative;
  }

  .card-info {
    text-align: center;
  }

  .disabled-icon-content {
    color: ${COLOR.light} !important;
  }

  .icon-content {
    color: ${COLOR.primary};
  }

  .most-popular {
    background: ${GRADIENT_COLOR.orange};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .popularity {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    background: ${COLOR.yellow[16]};
    padding: 2px;
    border-radius: 0px 0px 12px 12px;

    .popularity-text {
      font-size: 12px;
    }
  }

  .package-name {
    font-size: 20px;
    font-weight: bold;
    color: ${COLOR.darkBlue};
  }

  .package-limited-deal {
    font-size: 12px;
    font-weight: 500;
    color: ${COLOR.darkBlue};
    background: ${COLOR.yellow[64]};
    padding: 2px 8px;
    border-radius: 20px;
    width: fit-content;
  }

  .chip-wrapper {
    display: flex;
    top: 0;
    left: 0;
    gap: 8px;
  }

  .price-package {
    display: flex;
    align-items: center;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 4px;
  }

  .original-price-wrapper {
    display: flex;
    align-items: center;
    height: 20px;
  }

  .price-original-discount {
    padding-top: 2px;
    .discount-unit-price {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      color: ${COLOR.lightText};
      text-transform: lowercase;
      padding-bottom: -2px;
    }

    .original-price {
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      text-decoration: line-through;
      white-space: nowrap;
      color: ${COLOR.lightText};
    }
  }

  .price-en {
  }

  .original-card {
    min-height: 52px;
  }

  .price {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    white-space: nowrap;
    color: ${COLOR.darkBlue};
  }

  .unit-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${COLOR.darkBlue};
    text-transform: lowercase;
    padding-bottom: -2px;
  }

  .sub-price {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: 6px;
    color: ${COLOR.black[64]};
  }

  .original-price {
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    color: ${COLOR.lightText};
  }

  .original-price-unit {
    font-size: 12px;
    font-weight: 700;
    color: ${COLOR.lightText};
  }

  .font-size-14 {
    font-size: 14px !important;
  }

  .font-size-16 {
    font-size: 16px !important;
  }

  .underlined {
    text-decoration: underline;
  }

  .sub-origin-price {
    font-size: 14px;
    font-weight: 400;
    color: ${COLOR.lightText};
    margin-left: 4px;
  }

  .description {
  }

  .chip {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLOR.darkBlue};
    color: ${COLOR.primary};
    border-radius: 6px 0px;
    padding: 0px 6px;
    height: ${PACKAGE_HEIGHT_EXTRA}px;
  }

  .chip-none {
    height: ${PACKAGE_HEIGHT_EXTRA}px;
    padding: 0px 6px;
  }

  .text-chip {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: auto;
  }

  .button-start {
    font-weight: bold;
    margin-top: 20px;
    border-radius: ${(props) =>
      props.highlight && props.isMostPopular ? '12px 12px 0px 0px' : '12px'};
    border-color: ${COLOR.lightText};
    color: ${COLOR.darkBlue};
    text-transform: none;
  }

  .button-highlight {
    background: ${COLOR.primary};
    color: ${COLOR.darkBlue};
    border-color: ${COLOR.primary};
    text-transform: uppercase;
  }

  .free-package-button {
    height: 78px;
  }

  .text-limited {
    margin: 6px 0px 3px 0px;
    color: ${COLOR.lightText};
  }

  .icon-contents {
    margin-left: -16px;
    margin-right: 5px;
  }
  .clarify {
    opacity: 1 !important;
    // height: 54px;
    display: flex;
    align-items: start;
  }

  .disabled-content {
    color: ${COLOR.red};
  }

  .content {
    color: ${COLOR.dark};
    font-size: 14px;
  }

  .content-deactivate {
    color: ${COLOR.light};
    font-size: 14px;
  }

  .show-more-btn {
    padding: 0px !important;
    margin: 10px 26px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.4px;
    background-color: transparent;
    color: ${COLOR.indigo[100]};
    text-decoration: underline;
    text-transform: none;
    min-width: 0px !important;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 0px;
    overflow: ${(props) => (props.mostpopular ? 'hidden' : 'visible')};

    .chip-none {
      height: 0px;
    }

    .card-info {
      margin-top: 0px !important;
    }
  }
`;

const StyledPackageItem = styled(Card)`
  box-shadow: none;
  overflow: visible;
  padding: 20px;
  border-radius: ${(props) => (props.highlight ? '0px 0px 16px 16px' : '16px')};
  box-shadow: ${(props) => (props.highlight ? BOX_SHADOW : 'none')};
  border: ${(props) =>
    props.isDiscountPackage
      ? `2px solid ${COLOR.yellow.default}`
      : `2px solid ${COLOR.white}`};

  .package-header {
    display: flex;
    justify-content: space-between;
    height: 60px;
  }

  .package-icon {
    border-radius: 9999px;
    background-color: ${COLOR.black[8]};
    width: 48px;
    height: 48px;
  }

  .package-icon-active {
    border-radius: 9999px;
    background-color: ${COLOR.primary};
    width: 48px;
    height: 48px;
  }

  .card-content {
    position: relative;
  }

  .card-info {
    text-align: center;
  }

  .disabled-icon-content {
    color: ${COLOR.light} !important;
  }

  .icon-content {
    color: ${COLOR.primary};
  }

  .most-popular {
    background: ${GRADIENT_COLOR.orange};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .popularity {
    margin-top: 20px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    background: ${COLOR.yellow[16]};
    padding: 2px;
    border-radius: 12px 12px 0px 0px;

    .popularity-text {
      font-size: 12px;
    }
  }

  .package-name {
    font-size: 20px;
    font-weight: bold;
    color: ${COLOR.darkBlue};
  }

  .package-limited-deal {
    font-size: 12px;
    font-weight: 500;
    color: ${COLOR.darkBlue};
    background: ${COLOR.yellow[64]};
    padding: 2px 8px;
    border-radius: 20px;
  }

  .chip-wrapper {
    display: flex;
    top: 0;
    left: 0;
    gap: 8px;
  }

  .price-package {
    display: flex;
    align-items: center;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 4px;
  }

  .original-price-wrapper {
    display: flex;
    align-items: center;
    height: 20px;
  }

  .price-original-discount {
    color: ${COLOR.lightText};
    text-decoration-line: line-through;
    font-size: 20px;
    font-weight: 500;
  }

  .price-en {
  }

  .original-card {
    min-height: 52px;
  }

  .price {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    white-space: nowrap;
    color: ${COLOR.darkBlue};
  }

  .dollar-unit-price {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    white-space: nowrap;
    color: ${COLOR.darkV2[32]};
  }

  .unit-price {
    font-weight: 500;
    font-weight: normal !important;
    font-size: 14px;
    font-weight: bold;
    text-transform: lowercase;
  }

  .sub-price {
    font-weight: 500;
    font-weight: normal !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 6px;
    text-transform: lowercase;
    color: ${COLOR.dark};
  }

  .original-price {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    white-space: nowrap;
    color: ${COLOR.lightText};
  }

  .original-price-unit {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${COLOR.lightText};
    text-decoration: underline;
  }

  .sub-origin-price {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${COLOR.lightText};
    margin-left: 4px;
  }

  .description {
  }

  .chip {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLOR.darkBlue};
    color: ${COLOR.primary};
    border-radius: 6px 0px;
    padding: 0px 6px;
    height: ${PACKAGE_HEIGHT_EXTRA}px;
  }

  .chip-none {
    height: ${PACKAGE_HEIGHT_EXTRA}px;
    padding: 0px 6px;
  }

  .text-chip {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: auto;
  }

  .button-start {
    font-weight: bold;
    margin-top: 20px;
    border-radius: ${(props) =>
      props.highlight ? '0px 0px 12px 12px' : '12px'};
    border-color: ${COLOR.lightText};
    color: ${COLOR.black.default};
    text-transform: none;
  }

  .button-highlight {
    margin-top: 0px;
    background: ${COLOR.primary};
    color: ${COLOR.darkBlue};
    border-color: ${COLOR.primary};
    text-transform: uppercase;
  }

  .free-package-button {
    height: 78px;
  }

  .text-limited {
    margin: 6px 0px 3px 0px;
    color: ${COLOR.lightText};
  }

  .icon-contents {
    margin-left: -16px;
    margin-right: 5px;
  }
  .clarify {
    opacity: 1 !important;
    height: 54px;
    display: flex;
    align-items: start;
  }

  .disabled-content {
    color: ${COLOR.red};
  }

  .content {
    color: ${COLOR.dark};
  }

  .content-deactivate {
    color: ${COLOR.light};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 0px;
    overflow: ${(props) => (props.mostpopular ? 'hidden' : 'visible')};

    .chip-none {
      height: 0px;
    }

    .card-info {
      margin-top: 0px !important;
    }
  }
`;

const StyledPackageItemWrapper = styled('div')`
  width: 280px;

  .most-popular-banner {
    margin-top: 14px;
    height: 62px;
    background-image: url(${PopularBackground});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px 16px 0px 0px;
    gap: 8px;

    .most-popular-text {
      font-weight: 700;
      font-size: 24px;
      color: ${COLOR.darkBlue};
    }
  }

  .regular-package-banner {
    height: 76px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .most-popular-banner {
      margin-top: 14px;
      height: 62px;
      background-image: url(${PopularBackground});
      background-size: cover;
      color: ${COLOR.darkBlue};
      font-weight: 700;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px 16px 0px 0px;
    }

    .regular-package-banner {
      height: 76px;
    }
  }

  @media only screen and (max-width: 600px) {
    .regular-package-banner {
      height: 0px;
  }
`;

const StyledCountdownTime = styled('div')`
.countdown {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  gap: 6px;
  margin-top: 4px;
  color: ${COLOR.red};

  .countdown-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 32px;

    .countdown-time-value {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .unit-time {
    color: ${COLOR.lightText};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px
    text-transform: lowercase;
  }
`;

const StyledCountdownBanner = styled('div')`
  .countdown-box {
    background: ${(props) => props.backgroundColor || COLOR.white};
    padding: 12px 24px 24px 24px;
    border: 2px solid ${COLOR.primary};
    border-bottom: none;
    border-radius: 16px 16px 0px 0px;
    height: 38px;
    position: relative;
  }

  .countdown-deal {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    color: ${COLOR.black};
    text-align: center;
    background: ${COLOR.background};
    padding: 8px 12px;
    border-radius: 8px;
    left: calc(50% - 50px);
    top: -20px;
  }
`;

const StyledPackageTypes = styled('div')`
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: ${COLOR.black[48]};
  margin-bottom: 16px;
  height: 48px;
  box-sizing: border-box;

  :hover {
    cursor: pointer;
  }

  .package-tab {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.75rem 1.5rem;
    width: 120px;
    font-size: 18px;
  }

  .package-active-tab {
    color: ${COLOR.darkBlue};
    border-bottom: 4px solid ${COLOR.darkBlue};
  }
`;

const StyledVoucherBanner = styled('div')`
  margin-bottom: 26px;
  margin-top: -16px;

  .countdown-mobile {
    display: none;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
      display: block;
      justify-content: center;
      margin-bottom: -16px;
    }
  }

  .banner-container {
    border-radius: 16px;
    padding: 4px 12px;
    height: 80px;
    border: 2px solid ${COLOR.yellow.default};
    background: #fff9db;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .countdown {
      display: flex;

      @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
        display: none;
      }
    }

    .content {
      margin-left: 8px;
      margin-right: 20px;

      .voucher-code-mobile {
        font-size: 14px;
        font-weight: 600;
        color: ${COLOR.darkV2[80]};
        display: none;
      }
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      color: ${COLOR.darkBlue};
      text-transform: uppercase;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.darkV2[80]};
    }

    .voucher-apply {
      margin-top: 4px;
      display: flex;
      gap: 8px;
      align-items: center;

      .voucher-code {
        font-weight: 700;
        color: ${COLOR.darkV2[80]};
      }
    }

    .box-gift-icon {
      height: 80px;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
      border-radius: 0px 0px 16px 16px;
      height: 120px;
      margin-top: 16px;

      .title {
        font-weight: 700;
        font-size: 20px;
        color: ${COLOR.darkBlue};
        font-size: 18px;
        margin-bottom: 2px;
      }
      .voucher-code {
        font-weight: 600;
        color: ${COLOR.darkV2[80]};
      }

      .content {
        margin-left: 8px;
        margin-right: 20px;

        .voucher-code {
          display: none;
        }

        .voucher-code-mobile {
          margin-top: 2px;
          display: flex;
          font-size: 14px;
          gap: 6px;
        }
      }
    }
  }
`;

export {
  StyledPackages,
  StyledNewPackageItem,
  StyledPackageItemWrapper,
  StyledCountdownBanner,
  StyledPackageTypes,
  StyledPackageItem,
  StyledVoucherBanner,
  StyledCountdownTime,
};
