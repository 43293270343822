import { RESOURCE } from '@src/constants';
import api from './api';

const switchVersion = async () => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.USERS}/switch-version`,
  });
  return response;
};

const updateUserBanner = async ({ id, display, name }) => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.USERS}/banner`,
    data: { id, display, name },
  });
  return response;
};

const switchTtsVersion = async (ttsVersion) => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.USERS}/tts-version`,
    data: { ttsVersion },
  });
  return response;
};

const agreeToTerms = async () => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.USERS}/agree-terms`,
  });
  return response;
};

const updateCreatedDevice = async () => {
  try {
    const response = await api({
      method: 'PUT',
      url: `${RESOURCE.USERS}/created-device`,
    });
    return response;
  } catch (error) {
    return error?.response?.data || {};
  }
};

const getTtsUser = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.USER_TTS}`,
  });
  return response;
};

const confirmPushUserToGTM = async () => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.USERS}/confirm-push-gtm`,
  });
  return response;
};

const addTrialDubbing = async () => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.USERS}/add-trial-dubbing`,
  });
  return response;
};

export {
  switchVersion,
  updateUserBanner,
  switchTtsVersion,
  agreeToTerms,
  updateCreatedDevice,
  getTtsUser,
  confirmPushUserToGTM,
  addTrialDubbing,
};
