import React from 'react';
import { useSelector } from 'react-redux';
import SubscriptionContainer from '@src/containers/Subscription';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import PageNotFound from './PageNotFound';

const Subscription = () => {
  const user = useSelector((state) => state.auth.user);
  const { getFeatureValue } = useFeatureFlags();

  const showSubscriptionPage = getFeatureValue(FEATURE_KEYS.SUBSCRIPTION, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  return showSubscriptionPage ? <SubscriptionContainer /> : <PageNotFound />;
};

export default Subscription;
