import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  Typography,
  DialogTitle,
  IconButton,
  Button,
  Divider,
  Box,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import i18n from '@src/languages';

import { ACTION_TYPE } from '@src/constants/banner';
import { LANGUAGE } from '@src/constants';
import moment from 'moment';
import { checkVietNam } from '@src/utils/checkCountry';
import { PACKAGE_TYPE } from '@src/constants/package';
import { StyledJoinGroupZalo } from './index.style';

const JoinGroupZalo = ({
  open,
  bannerIndex,
  banner,
  packageType,
  packageName,
  description,
  isAutoRenewalPackage,
  packagePrice,
  packageUsdPrice,
  packageExpiryDate,
  closeAction,
  onCloseBanner,
  onActionChange,
}) => {
  const { language } = i18n;
  const { t } = useTranslation();
  const isVietNam = checkVietNam();

  const getPackageTypeName = (type) => {
    switch (type) {
      case PACKAGE_TYPE.STUDIO:
        return t('studio');
      case PACKAGE_TYPE.DUBBING:
        return t('dubbing');
      default:
        return t('studio');
    }
  };

  const handleClose = (action) => (event, reason) => {
    const { url, notShowAgain, type } = action;
    if (reason === 'backdropClick') return;
    if (onActionChange) onActionChange(url, type);
    // TODO: check not show again from banner data
    onCloseBanner(bannerIndex, notShowAgain);
  };

  const nextPaymentDate = moment(packageExpiryDate).format('DD/MM/YYYY');
  const totalPrice = isVietNam ? packagePrice : packageUsdPrice;

  return (
    <StyledJoinGroupZalo open={open} fullWidth>
      <div className="dialog-content">
        <DialogTitle classes={{ root: 'dialog-title' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose(closeAction)}
            color="secondary"
            className="close-button"
          >
            <CloseRounded className="close-icon" sx={{ fontSize: 16 }} />
          </IconButton>
        </DialogTitle>

        <div className="dialog-wrapper">
          <div className="img-wrapper">
            <img src={banner.imageUrl} alt="icon" className="img" />
          </div>
          <Typography className="title">
            {banner.title &&
              (banner.title[language] || banner.title[LANGUAGE.EN])}
          </Typography>
          <Typography className="package">
            {`${getPackageTypeName(packageType)} - ${packageName}`}
            {isAutoRenewalPackage && (
              <span className="auto-renewal-label">{t('autoRenewal')}</span>
            )}
          </Typography>
          <Divider className="divider" />
          {isAutoRenewalPackage && (
            <Box mx={1} mb={2} display="flex" flexDirection="column" gap={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography>{t('nextPayment')}</Typography>
                <Typography>{nextPaymentDate}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="4px">
                  <Typography>{t('amountToBePaid')}</Typography>
                </Box>
                <div>
                  {!isVietNam && (
                    <span className="discount-unit-price text-16">$</span>
                  )}
                  {isVietNam && (
                    <span className="discount-unit-price underline text-16">
                      đ
                    </span>
                  )}
                  {totalPrice?.toLocaleString(language)}
                </div>
              </Box>
            </Box>
          )}
          <div
            className="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                description ||
                (banner.description &&
                  (banner.description[language] ||
                    banner.description[LANGUAGE.EN])),
            }}
          />
          <DialogActions classes={{ root: 'dialog-action' }}>
            {banner.actions.map((action, index) => (
              <Button
                variant={index === 0 ? 'contained' : 'outlined'}
                color={index === 0 ? 'primary' : 'secondary'}
                onClick={handleClose({ ...action, type: ACTION_TYPE.BUTTON })}
                className="action-button"
              >
                {action[language] || action[LANGUAGE.EN]}
              </Button>
            ))}
          </DialogActions>
        </div>
      </div>
    </StyledJoinGroupZalo>
  );
};

export default JoinGroupZalo;
