export const ORDER_BY = [
  { label: 'increase', value: 'increase' },
  { label: 'decrease', value: 'decrease' },
];

export const VOICE_CRITERIA_SORT = [
  { label: 'latestVoice', value: 'latest' },
  { label: 'mostPopular', value: 'popular' },
  { label: 'favorite', value: 'favorite' },
];

export const VOICE_OWNERSHIP = {
  VBEE: 'VBEE',
  PERSONAL: 'PERSONAL',
  COMMUNITY: 'COMMUNITY',
};

export const VOICE_LIBRARY = [
  { value: VOICE_OWNERSHIP.VBEE, label: 'vbeeVoice' },
  { value: VOICE_OWNERSHIP.PERSONAL, label: 'myVoice' },
  // { value: VOICE_OWNERSHIP.COMMUNITY, label: 'communityVoice' },
];

export const GENDER = [
  { value: 'female', label: 'female' },
  { value: 'male', label: 'male' },
];

export const VOICE_TONE = [
  { label: 'low', value: 'low' },
  { label: 'middle', value: 'middle' },
  { label: 'hight', value: 'hight' },
];

export const VOICE_DOMAIN = [
  { label: 'review', value: 'review' },
  { label: 'hotTrend', value: 'trend' },
  { label: 'chronicle', value: 'chronicle' },
  { label: 'news', value: 'news' },
  { label: 'commentary', value: 'commentary' },
  { label: 'demonstration', value: 'demonstration' },
];

export const BREAK_TIME = [0.5, 1, 2, 3];

export const SPEED = [
  { value: 0.25, title: 'verySlow' },
  { value: 0.5, title: 'slow' },
  { value: 0.75, title: 'slower' },
  { value: 1, title: 'normal' },
  { value: 1.05, title: 'faster' },
  { value: 1.1, title: 'fast' },
  { value: 1.15, title: 'veryFast' },
];

export const AUDIO_TYPE = ['mp3', 'wav'];

export const BITRATE = [8, 16, 32, 64, 128];

export const DEFAULT_BREAK_TIME = 1;

export const DEFAULT_SPEED = 1;

export const DEFAULT_SYNTHESIS_REQUEST = {
  title: '',
  breakTime: 0.5,
  speed: 1,
  audioType: 'mp3',
  bitrate: 128,
  volume: 100,
  backgroundMusic: { volume: 80 },
  emphasis: 'moderate',
  pitch: 'default',
};

export const REQUEST_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const AUDIO_FILE_FORMAT = ['mp3', 'wav'];

export const TEXT_FILE_FORMAT = ['docx', 'txt'];

export const LANGUAGE_CODE = { VIETNAMESE: 'vi-VN' };

export const MAX_TRY_LISTENING_CHARACTERS = 2000;

export const VOICE_PAGINATION_LIMIT = 12;

export const BREAK_TIME_REGEX = /[<]break\s+time[=]([0-9.]+)[s][/][>]/g;

export const TTS_ATTRIBUTE = {
  EMPHASIS: 'EMPHASIS',
  PITCH: 'PITCH',
  SPEED: 'SPEED',
  BREAK_TIME: 'BREAK_TIME',
};

export const VOICE_PROVIDER = {
  GOOGLE: 'google',
  AMAZON: 'amazon',
  VBEE: 'vbee',
  VBEE_VOICE_CLONING: 'vbee-voice-cloning',
};

export const BREAK_LINE = '\n';

export const EMPHASIS_LEVEL = ['strong', 'moderate', 'reduced'];

export const PITCH = ['low', 'medium', 'default', 'hight'];

export const REGEX = {
  ADVANCE_TAG:
    /[<]break\s+time[=]["]([0-9.]+)[s]["][/][>]|[<]emphasis\s+level[=]["](?:strong|moderate|reduced)["][>]|[<][/]emphasis[>]|[<]prosody\s+rate[=]["](?:x-fast|fast|medium|slow|x-slow|(?:(\+|-)[0-9.]+%))["][>]|[<][/]prosody[>]/g,
  EMPHASIS_TAG:
    /[<]emphasis\s+level[=]["](?:strong|moderate|reduced)["][>]|[<][/]emphasis[>]|[<]prosody\s+rate[=]["](?:x-fast|fast|medium|slow|x-slow|(?:(\+|-)[0-9.]+%))["][>]|[<][/]prosody[>]/g,
  ADVANCE_BREAK_TIME_TAG: /[<]break\s+time[=]["]([0-9.]+)[s]["][/][>]/g,
};

export const VOICE_FEATURE = {
  EMPHASIS: 'emphasis',
};

export const VOICE_LEVEL = {
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
};

export const ENGLISH_VOICE_CODES = [
  'en-US',
  'en-AU',
  'en-GB',
  'en-IN',
  'en-NZ',
  'en-ZA',
];

export const GLOBAL_VOICE_FEATURES = {
  GLOBAL: 'global-voice',
  STANDARD: 'standard-global-voice',
  PREMIUM: 'premium-global-voice',
};

export const VIETNAM_VOICE_FEATURES = {
  BASIC: 'basic-vietnam-voice',
  PRO: 'pro-vietnam-voice',
  STANDARD: 'standard-vietnam-voice',
  PREMIUM: 'premium-vietnam-voice',
};

export const OLD_VERSION_DUBBING_VOICE_CODES = [
  'hn_female_ngochuyen_full_48k-fhg',
  'sg_female_tuongvy_call_44k-fhg',
  'sg_female_thaotrinh_full_48k-fhg',
  'hn_female_maiphuong_vdts_48k-fhg',
  'sg_female_lantrinh_vdts_48k-fhg',
];
