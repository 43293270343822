import { RESOURCE } from '@src/constants';
import api from './api';

const getBanners = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.BANNERS}/active`,
  });
  return response;
};

export { getBanners };
