const WS_TYPE = {
  ORDER: 'ORDER',
  SYNTHESIS: 'SYNTHESIS',
  GET_REMAINING_PREVIEW: 'GET_REMAINING_PREVIEW',
  PING: 'PING',
  INIT: 'INIT',
  STREAM_REQUEST: 'STREAM_REQUEST',
  LOGOUT_DEVICES: 'logout-devices',
  STREAM_INIT: 'STREAM_INIT',
  STREAM_DATA: 'STREAM_DATA',
  STREAM_END: 'STREAM_END',
  AFFILIATE_NOTIFICATION: 'affiliate-notification',
  RESET_FREE_CHARACTERS: 'reset-free-characters',
  PUSH_ORDER_TO_GTM: 'push-order-to-gtm',
};

const SYNTHESIS_STATUS = {
  CREATED: 'CREATED',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  IN_PROGRESS: 'IN_PROGRESS',
};

const WS_ORDER_STATUS = {
  CREATED: 'CREATED',
  PAID: 'PAID',
  CANCEL: 'CANCEL',
};

const PING_INTERVAL = 5 * 1000;
const FETCH_REQUESTS_INTERVAL = 10 * 1000;

export {
  WS_TYPE,
  PING_INTERVAL,
  FETCH_REQUESTS_INTERVAL,
  SYNTHESIS_STATUS,
  WS_ORDER_STATUS,
};
