/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  CircularProgress,
  Tooltip,
  Button,
  Box,
} from '@mui/material';
import {
  Forward5Rounded,
  Replay5Rounded,
  PlayArrowRounded,
  PauseOutlined,
} from '@mui/icons-material';

import actions from '@src/redux/actions';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import apis from '@src/apis';
import { SILENCE_AUDIO_URL, TTS_VERSION } from '@src/constants/tts';
import { getNextStreamAudio } from '@src/utils/streamAudio';
import {
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '@src/styles/config';
import renderVoiceInfo from './VoiceInfo';
import renderActions from './Actions';
import Audio from './Audio';

import { StyledAudioPlayer } from './index.style';

const REWIND_TIME_IN_SECOND = 5;

const initialNextStreamAudio = {
  index: 0,
  subIndex: 0,
  phraseIndex: 0,
};

const AudioPlayer = ({ audioRef }) => {
  const {
    audioLink,
    selectedAudioRequest,
    isPlaying,
    currentTime,
    duration,
    isAudioLoading,
    streamAudios,
    isRunStreamAudio,
    ttsVersion,
    finalStreamAudio,
    showAudioPlayer,
  } = useSelector((state) => state.audioPlayer);
  const user = useSelector((state) => state.auth.user);
  const { openSidebar } = useSelector((state) => state.layout);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [audioEnd, setAudioEnd] = useState(false);
  const [nextStreamAudio, setNextStreamAudio] = useState(
    initialNextStreamAudio,
  );
  const [newPlayAudioLink, setNewPlayAudioLink] = useState(audioLink);
  const [isErrorPlaying, setIsErrorPlaying] = useState(false);

  const { getFeatureValue } = useFeatureFlags();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let useNewSideBar = getFeatureValue(FEATURE_KEYS.NEW_SIDEBAR);
  useNewSideBar = useNewSideBar && useNewTtsUI;

  const handleSeeked = (time) => {
    audioRef.current.currentTime = time;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleChangePlayAudio = () => {
    dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    setAudioEnd(true);
  };

  const handleLoadedMetadata = () => {
    const audioDuration = audioRef.current.duration;
    dispatch(actions.audioPlayer.updateMetaData({ duration: audioDuration }));
  };

  const handleAudioTimeUpdate = () => {
    const url = audioRef.current.src ? new URL(audioRef.current.src) : '';
    const time =
      url?.pathname === SILENCE_AUDIO_URL ? 0 : audioRef.current.currentTime;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleAudioEnd = () => {
    if (audioEnd) {
      audioRef.current.pause();
      dispatch(actions.audioPlayer.updateStatus(false));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      setAudioEnd(false);
      return;
    }

    if (streamAudios?.length && !!nextStreamAudio) {
      const nextAudio = streamAudios.find(
        (streamAudio) =>
          streamAudio.index === nextStreamAudio.index &&
          streamAudio.subIndex === nextStreamAudio.subIndex,
      );

      if (nextAudio) {
        if (ttsVersion === TTS_VERSION.EMPHASIS) {
          const { phraseIndex = 0 } = nextStreamAudio;
          const { audioLink: streamAudioLink } = nextAudio.phrases[phraseIndex];
          audioRef.current.src = streamAudioLink;
        } else {
          audioRef.current.src = nextAudio.audioLink;
        }
        audioRef.current.load();
        audioRef.current.play();
        const nextAudioToPlay = getNextStreamAudio({
          currStreamAudio: nextStreamAudio,
          tts: nextAudio.tts,
          lastPhraseIndex: nextAudio.phrases?.length - 1,
        });

        setNextStreamAudio(nextAudioToPlay);
        return;
      }
    }

    if (newPlayAudioLink) {
      setAudioEnd(true);
      audioRef.current.src = newPlayAudioLink;
      if (!finalStreamAudio && isRunStreamAudio) {
        audioRef.current.load();
        audioRef.current.play();
        return;
      }
      audioRef.current.pause();
      dispatch(actions.audioPlayer.updateStatus(false));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      return;
    }

    if (
      !nextStreamAudio &&
      isRunStreamAudio &&
      finalStreamAudio &&
      finalStreamAudio !== audioRef.current.src
    ) {
      dispatch(actions.audioPlayer.updateStatusStreamAudio(false));
      dispatch(actions.audioPlayer.updateAudioLink(finalStreamAudio, true));
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const handleGetPresignedAudioUrl = async (keyId) => {
    try {
      const res = await apis.requests.getPresignedAudioUrl(keyId);
      if (res?.status) {
        const audioUrl = res.result?.audio;
        setNewPlayAudioLink(audioUrl);
        return;
      }
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'downloadError',
        }),
      );
      setNewPlayAudioLink(audioLink);
      return;
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'downloadError',
        }),
      );
      setNewPlayAudioLink(audioLink);
    }
  };

  useEffect(() => {
    audioRef.current.load();
    audioRef.current.currentTime = currentTime;

    if ((newPlayAudioLink && isPlaying) || isRunStreamAudio) {
      audioRef.current.play().catch((error) => {
        // eslint-disable-next-line no-console
        console.log(`Error when play audio ${error}`);
        const ERROR_DUE_TO_EXPIRED_AUDIO_LINK = 'NotSupportedError';
        if (error.name === ERROR_DUE_TO_EXPIRED_AUDIO_LINK) {
          setIsErrorPlaying(true);
          handleGetPresignedAudioUrl(selectedAudioRequest);
        }
      });
    } else {
      audioRef.current.pause();
    }

    if (isPlaying && isAudioLoading) {
      dispatch(
        actions.audioPlayer.updateTryListeningSentence({
          sentenceId: '',
          isAudioLoading: false,
        }),
      );
    }
  }, [newPlayAudioLink, isPlaying, isRunStreamAudio]);

  useEffect(() => {
    if (newPlayAudioLink) {
      audioRef.current.src = newPlayAudioLink;
      if (isErrorPlaying) {
        audioRef.current.play();
        setIsErrorPlaying(false);
      }
      dispatch(actions.audioPlayer.updateStatusStreamAudio(false));
      setNextStreamAudio(null);
    }
    setAudioEnd(false);
  }, [newPlayAudioLink]);

  useEffect(() => {
    if (selectedAudioRequest) {
      handleGetPresignedAudioUrl(selectedAudioRequest);
    } else {
      setNewPlayAudioLink(audioLink);
    }
  }, [audioLink]);

  useEffect(() => {
    if (isRunStreamAudio) setNextStreamAudio(initialNextStreamAudio);
  }, [isRunStreamAudio]);

  const isShowLoading = isPlaying && !duration;
  const isShowPlaying = isPlaying && !!duration;

  const renderAudioAction = () => (
    <div className="audio-action">
      <Tooltip
        arrow
        title={t('rewind', { second: REWIND_TIME_IN_SECOND })}
        placement="top"
      >
        <div>
          <IconButton
            onClick={() => {
              const time = currentTime - REWIND_TIME_IN_SECOND;
              handleSeeked(time >= 0 ? time : 0);
            }}
            disabled={
              currentTime === 0 || !newPlayAudioLink || isRunStreamAudio
            }
            className="rewind-button"
            color="iconPrimary"
          >
            <Replay5Rounded sx={{ fontSize: 26 }} />
          </IconButton>
        </div>
      </Tooltip>
      <Button
        onClick={handleChangePlayAudio}
        disabled={!newPlayAudioLink || isRunStreamAudio}
        className="audio-action-button"
        color="buttonPrimary"
        variant="contained"
      >
        {isShowLoading && !isRunStreamAudio && (
          <CircularProgress size={15} thickness={8} className="loading" />
        )}
        {(isShowPlaying || isRunStreamAudio) && (
          <Tooltip arrow title={t('pause')} placement="top">
            <PauseOutlined sx={{ fontSize: 26 }} color="white" />
          </Tooltip>
        )}
        {!isShowLoading && !isShowPlaying && !isRunStreamAudio && (
          <Tooltip arrow title={t('playAudioGuide')} placement="top">
            <PlayArrowRounded sx={{ fontSize: 26 }} color="white" />
          </Tooltip>
        )}
      </Button>
      <Tooltip
        arrow
        title={t('forward', { second: REWIND_TIME_IN_SECOND })}
        placement="top"
      >
        <div>
          <IconButton
            onClick={() => handleSeeked(currentTime + REWIND_TIME_IN_SECOND)}
            disabled={
              currentTime > duration || !newPlayAudioLink || isRunStreamAudio
            }
            className="rewind-button"
            color="iconPrimary"
          >
            <Forward5Rounded sx={{ fontSize: 26 }} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );

  const getSidebarWidth = () => {
    let sidebarWidth = 0;
    if (useNewSideBar) {
      sidebarWidth = openSidebar ? NEW_SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED;
    } else {
      sidebarWidth = openSidebar ? SIDEBAR_WIDTH : 0;
    }
    return sidebarWidth;
  };

  return (
    <StyledAudioPlayer
      openSidebar={openSidebar}
      showaudioplayer={showAudioPlayer}
      sidebarwidth={getSidebarWidth()}
    >
      <Box className="container">
        {renderVoiceInfo()}
        <div className="audio-wrapper">
          {renderAudioAction()}
          <Audio
            currentTime={isRunStreamAudio ? 0 : currentTime}
            duration={isRunStreamAudio ? 0 : duration}
            onSeeked={handleSeeked}
            isRunStreamAudio={isRunStreamAudio}
            disabled={!newPlayAudioLink || isRunStreamAudio}
          />
        </div>
        <Box display="flex" justifyContent="flex-end">
          {renderActions({ audioRef })}
        </Box>
      </Box>

      <Box className="mobile-container">
        <div className="mobile-action">
          {renderVoiceInfo()}
          {renderAudioAction()}
          <Box display="flex" justifyContent="flex-end">
            {renderActions({ audioRef })}
          </Box>
        </div>
        <div className="audio-wrapper">
          <Audio
            currentTime={isRunStreamAudio ? 0 : currentTime}
            duration={isRunStreamAudio ? 0 : duration}
            onSeeked={handleSeeked}
            isRunStreamAudio={isRunStreamAudio}
            disabled={!newPlayAudioLink || isRunStreamAudio}
          />
        </div>
      </Box>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        autoPlay={isPlaying}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleAudioTimeUpdate}
        onEnded={handleAudioEnd}
      >
        <source src={newPlayAudioLink} type="audio/ogg" />
      </audio>
    </StyledAudioPlayer>
  );
};

export default AudioPlayer;
