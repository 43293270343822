import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { StyledToolbar } from './index.style';
import CollapsedButton from './CollapsedButton';
import Volume from './Volume';
import AudioActions from './AudioActions';
import ZoomActions from './ZoomActions';
import AudioSelection from './AudioSelection';

const Toolbar = ({
  dubbingRequests,
  availableLanguages,
  zoomLevel,
  isExpandTimeFrame,
  setZoomLevel,
  setIsExpandTimeFrame,
  timelineRef,
  setIsChangeTimeByClick,
  translatedAudioRef,
}) => {
  const handleCollapse = useCallback(
    () => setIsExpandTimeFrame(!isExpandTimeFrame),
    [isExpandTimeFrame],
  );

  return (
    <StyledToolbar>
      <Box className="toolbar-items-wrapper">
        <CollapsedButton
          isCollapsed={isExpandTimeFrame}
          handleCollapse={handleCollapse}
        />
        <Volume audioRef={translatedAudioRef} />
        {dubbingRequests?.length > 0 && (
          <AudioSelection availableLanguages={availableLanguages} />
        )}
      </Box>
      <AudioActions
        timelineRef={timelineRef}
        setIsChangeTimeByClick={setIsChangeTimeByClick}
        translatedAudioRef={translatedAudioRef}
      />
      <ZoomActions zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
    </StyledToolbar>
  );
};
export default Toolbar;
