import { AvatarGroup, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BREAKPOINTS } from '@src/styles/config';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledProjectTitle = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  table-layout: fixed;
  width: 100%;
  display: flex;
  gap: 4px;
  max-width: 505px;
  min-width: 350px;
  align-items: center;
  color: ${COLOR.darkBlue};
  font-weight: 550;

  .edit-title {
    outline: none;
    border: 0px;
    background-color: transparent;
    border-bottom: 1px solid ${COLOR.light};
    width: 100%;
    font-weight: 550;
    color: ${COLOR.darkBlue};
    font-size: 14px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.XL}px) {
    max-width: 392px;
  }

  .display-title {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .title-text {
        height: 28px;
        display: flex;
        align-items: center;
        width: fit-content;
        max-width: 80%;

        .text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 510;
          line-height: 24px;
        }
      }

      .edit-button {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        width: 12px;
        height: 12px;
        color: ${COLOR.darkBlue};
        opacity: 0.75;
        padding: 14px;
      }
    }

    .title-time {
      opacity: 0.8;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.4px;
      color: ${COLOR.label[80]};
    }
  }
  .action {
    display: flex;
    gap: 4px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '0px'};
  height: ${({ height }) => height || '10px'};
  border-radius: ${({ borderRadius }) => borderRadius || '100%'};
  border: 1px solid ${COLOR.light};
  padding: 18px;
  background: ${({ focus }) => focus && TRANSPARENT_COLOR.dark};

  color: ${({ color }) => (color ? COLOR[color] : COLOR.darkBlue)};
  opacity: 0.75;
  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    opacity: 1;
    .icon {
      color: ${({ colorHover }) => COLOR[colorHover]};
    }
  }

  .text-action {
    font-size: 14px;
    color: ${COLOR.darkBlue};
    &:hover {
      color: ${COLOR.black};
    }
  }
`;

export const StyledProjectsActionHeader = styled('div')`
  display: flex;
  gap: 12px;
  white-space: nowrap;
  z-index: 100000;
  width: 100%;
  align-items: center;

  .selected-requests {
    font-size: 14px;
    color: ${COLOR.darkBlue};
    font-weight: 500;
    line-height: 20px;
  }

  .notification-dialog {
    .description {
      color: red;
    }
  }

  .delete-button {
    border-radius: 500px;
    font-size: 12px;
    color: ${COLOR.red};
    background-color: ${COLOR.white};
    border-color: ${COLOR.functionalRed[32]};
    text-transform: none;
    padding: 2px 12px;
    width: 100px;

    :hover {
      background-color: ${TRANSPARENT_COLOR.dark};
    }
  }

  .download-button {
    border-radius: 500px;
    font-size: 12px;
    border-color: ${COLOR.indigo[32]};
    color: ${COLOR.darkBlue};
    background-color: ${COLOR.white};
    text-transform: none;
    padding: 2px 12px;

    :hover {
      background-color: ${TRANSPARENT_COLOR.dark};
    }
  }
`;

export const StyledActionWarning = styled('div')`
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;

  button {
    border-radius: 500px;
    padding: 4px 12px;
    text-transform: none;
  }

  .no-button {
    border-color: ${COLOR.indigo[32]};
  }
`;

export const StyledProjectVoice = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  z-index: 1;

  .voice-style {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px;
    opacity: 0.64;
  }
`;

export const StyledProjectActions = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: fit-content;
  margin-right: 16px;
  gap: 16px;
`;

export const StyledActionTooltip = styled(
  ({ className, disabled, ...props }) => (
    // eslint-disable-next-line react/jsx-filename-extension, react/react-in-jsx-scope
    <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
  ),
)(({ disabled }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: disabled ? COLOR.darkV2[80] : COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 20px',
    textAlign: 'center',
    maxWidth: 180,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: disabled ? COLOR.darkV2[80] : COLOR.label[80],
  },
}));

export const StyledVoiceAvatarMore = styled('div')`
  position: relative;
  margin-left: -6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .more-voice {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${COLOR.black[30]};
    z-index: 1;
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLOR.white};

    .more-voice-text {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    border: 0px;
  }
`;

export const StyledStackedVoiceAvatars = styled(AvatarGroup)`
  display: flex;
  align-items: center;
  z-index: 1;
  margin-left: 6px;
`;
