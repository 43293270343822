import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { StyledVideoPlayer } from './index.style';

const VideoPlayer = ({
  timeLineRefCurrent,
  isChangeTimeByClick,
  setIsChangeTimeByClick,
}) => {
  const [videoReady, setVideoReady] = useState(false);

  const { isSelectListenOriginalAudio } = useSelector(
    (state) => state.dubbingRequest,
  );

  const { volume } = useSelector((state) => state.audioPlayer);

  const { originalInfo } = useSelector(
    (state) => state.dubbingRequest.projectInfo,
  );

  const videoRef = useRef(null);

  const handleVideoReady = () => {
    setVideoReady(true);
    // Optionally fetch additional data or perform actions here
  };

  useEffect(() => {
    if (isChangeTimeByClick && videoReady) {
      videoRef.current.seekTo(timeLineRefCurrent?.getTime());
      setIsChangeTimeByClick(false);
    }
  }, [isChangeTimeByClick, videoReady]);

  return (
    <StyledVideoPlayer>
      {originalInfo?.videoLink ? (
        <ReactPlayer
          ref={videoRef}
          url={originalInfo?.videoLink}
          width="100%"
          height="100%"
          playing={timeLineRefCurrent?.isPlaying}
          onReady={handleVideoReady}
          volume={!isSelectListenOriginalAudio ? 0 : volume}
          muted={!isSelectListenOriginalAudio}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1, // Hide YouTube logo
                controls: 0, // Hide video controls
                playsinline: 1,
                rel: 0, // Do not show related videos at the end,
                enablejsapi: 1,
                fs: 0, // Disable fullscreen button
                iv_load_policy: 3, // Hide annotations
                color: 'white',
              },
            },
          }}
          className="react-player"
        />
      ) : null}
    </StyledVideoPlayer>
  );
};

export default React.memo(VideoPlayer);
