import { COLOR } from '@src/styles/color';
import styled from 'styled-components';

export const StyledMobileTTS = styled.div`
  width: 100%;
  .page-container {
    background-color: ${COLOR.white};
    padding: 12px 16px;
  }

  .text-area-input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    border: none;
    outline: none;
    font-family: 'SF Pro', sans-serif;
  }

  .count-characters {
    text-align: right;
    text-transform: lowercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.4px;
  }
  .count-characters-max {
    color: ${COLOR.label[64]};
  }
`;

export const StyledAudioPlayer = styled.div`
  width: 100%;
  height: 56px;
  box-sizing: border-box;

  .audio-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${COLOR.white};
    padding: 4px 24px;
    box-sizing: border-box;
  }

  .control-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .audio-action-button {
    padding: 6px;
    border-radius: 50%;
    min-width: 0;
  }

  .voice {
    border-radius: 50%;
    cursor: pointer;
  }

  .speed {
    color: ${COLOR.indigo[100]};
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid ${COLOR.indigo[32]};
    border-radius: 100px;
    cursor: pointer;
  }
`;

export const StyledSpeedDrawer = styled.div`
  width: 100%;

  .list-item {
    margin-bottom: 2px;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    min-width: 100px;
  }

  .list-item:last-child {
    margin-bottom: 0;
  }

  .list-item .MuiListItemButton-root {
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    justify-content: center;
  }

  .show-speed {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    width: 160px;

    .speed-value {
      width: 70px;
      text-align: left;
    }

    .speed-title {
      text-align: left;
    }
  }
`;

export const StyledLoading = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledVoiceDrawer = styled.div`
  width: 100%;
  max-height: 380px;
  overflow: auto;
  margin: 0px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLOR.white};
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLOR.indigo[64]};
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.indigo[80]};
    border-radius: 100px;
  }

  .list {
    padding: 0 16px;
  }

  .list-item {
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    min-width: 100px;
    margin: 0px;
    border-bottom: 1px solid ${COLOR.label[16]};
  }

  .list-item:last-child {
    margin-bottom: 0;
    border-bottom: 0px;
  }

  .MuiListItem-root {
    padding: 0px;
  }

  .list-item .MuiListItemButton-root {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
`;

export const StyledVoice = styled.button`
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px 0px;
  margin: 0px;
  border: none;
  background-color: transparent;
  .content {
    display: flex;
    flex-direction: column;
    padding: 0px 12px;
    flex: 1;
    gap: 4px;
  }

  .voice-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
    text-align: left;
    color: ${COLOR.label2[100]};
  }

  .voice-infor {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.4px;
    color: ${COLOR.label[64]};
  }

  .icon-button {
    padding: 6px;
  }

  .play-icon {
    padding: 0px;
  }
`;
