import {
  HomeRounded as HomeIcon,
  Groups as AffiliateIcon,
  PhoneAndroidRounded as MobileAppIcon,
  RecordVoiceOverRounded as VoiceCloningIconSidebar,
  TheatersRounded as DubbingIconMobileSidebar,
  ShoppingCartRounded as PaymentIconSidebar,
  GraphicEqRounded as TTSIconMobileSidebar,
  CodeRounded as AIVoiceAPIIcon,
} from '@mui/icons-material';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { VBEE_CONSOLE_URL } from '@src/configs';
import routes from '@src/constants/route';

import { useCheckDubbingRoute } from './useCheckDubbingRoute';

const useSidebarRoutes = () => {
  const isDubbingRoute = useCheckDubbingRoute();

  const sidebarRoutes = [
    {
      name: 'home',
      path: routes.HOME,
      isMobileSidebar: false,
      isDesktopSidebar: true,
      labelSidebar: 'home',
      iconSidebar: HomeIcon,
    },
    {
      name: 'convertText',
      path: routes.TTS,
      isMobileSidebar: true,
      isDesktopSidebar: false,
      isCreateProject: true,
      labelSidebar: 'STUDIO',
      iconSidebar: TTSIconMobileSidebar,
    },
    {
      name: 'myVoice',
      path: routes.VOICE_CLONING_VOICES,
      isMobileSidebar: true,
      isDesktopSidebar: true,
      labelSidebar: 'myVoice',
      iconSidebar: VoiceCloningIconSidebar,
      featureKey: FEATURE_KEYS.VOICE_CLONING,
      beta: true,
    },
    {
      name: 'dubbing',
      path: routes.DUBBING,
      isMobileSidebar: true,
      isDesktopSidebar: false,
      isCreateProject: true,
      labelSidebar: 'DUBBING',
      iconSidebar: DubbingIconMobileSidebar,
      featureKey: FEATURE_KEYS.DUBBING,
    },
    {
      name: 'voiceCloning',
      path: `${routes.VOICE_CLONING_VOICES}?show-qrcode=true`,
      isMobileSidebar: false,
      isDesktopSidebar: false,
      isCreateProject: true,
      labelSidebar: 'voiceCloningLable',
      iconSidebar: VoiceCloningIconSidebar,
      featureKey: FEATURE_KEYS.VOICE_CLONING,
      beta: true,
    },
    {
      name: 'api',
      path: VBEE_CONSOLE_URL,
      externalUrl: true,
      isMobileSidebar: true,
      isDesktopSidebar: true,
      labelSidebar: 'API',
      iconSidebar: AIVoiceAPIIcon,
    },
    {
      name: 'buyPackage',
      path: isDubbingRoute ? routes.PAYMENT_DUBBING : routes.PAYMENT,
      isMobileSidebar: true,
      isDesktopSidebar: true,
      labelSidebar: 'buyPackage',
      iconSidebar: PaymentIconSidebar,
    },
    {
      name: 'affiliate',
      path: routes.AFFILIATE,
      subPaths: [routes.AFFILIATE_DETAIL],
      isMobileSidebar: false,
      isDesktopSidebar: true,
      labelSidebar: 'affiliate',
      iconSidebar: AffiliateIcon,
    },
    {
      name: 'mobileApp',
      path: routes.MOBILE_APP,
      isMobileSidebar: false,
      isDesktopSidebar: true,
      labelSidebar: 'mobileApp',
      iconSidebar: MobileAppIcon,
    },
  ];

  const mobileSidebarRoutes = sidebarRoutes.filter(
    (route) => route.isMobileSidebar,
  );
  const desktopSidebarRoutes = sidebarRoutes.filter(
    (route) => route.isDesktopSidebar,
  );
  const newProjectRoutes = sidebarRoutes.filter(
    (route) => route?.isCreateProject,
  );

  return { mobileSidebarRoutes, desktopSidebarRoutes, newProjectRoutes };
};

export default useSidebarRoutes;
