import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledPagination = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '15px',

  '.pagination': {
    button: {
      '&.Mui-selected': {
        color: COLOR.darkBlue,
        background: TRANSPARENT_COLOR.primary,
        border: `1px solid ${COLOR.primary}`,
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    '.MuiPaginationItem-root': {
      minWidth: '25px',
      height: '25px',
      margin: '0',
    },
  },
}));
