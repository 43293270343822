import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import useSidebarRoutes from '@src/hooks/useSidebarRoutes';
import { StyledPopover, StyledSidebarTooltip } from './index.style';

const PopoverCreateNewProject = ({ createLinks, isSmall = false }) => {
  const WIDTH_BUTTON_SIDEBAR = 222;
  const { t } = useTranslation();
  const { newProjectRoutes } = useSidebarRoutes();

  const popoverRef = useRef(null);
  const buttonRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  useEffect(() => {
    if (showPopover) {
      const { top, left, width } = buttonRef.current.getBoundingClientRect();
      popoverRef.current.style.top = `${top + window.scrollY}px`;
      popoverRef.current.style.left = `${left + window.scrollX}px`;
      if (isSmall) {
        popoverRef.current.style.width = `${WIDTH_BUTTON_SIDEBAR}px`;
      } else {
        popoverRef.current.style.width = `${width}px`;
      }
    }
  }, [showPopover]);

  // Hide popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowPopover(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <StyledPopover>
      {isSmall ? (
        <StyledSidebarTooltip title={t('createNew')}>
          <div className="small-wrap-btn">
            <Button
              className="small-create-new-button"
              variant="contained"
              color="primary"
              onClick={togglePopover}
              ref={buttonRef}
            >
              <AddRounded />
            </Button>
          </div>
        </StyledSidebarTooltip>
      ) : (
        <div className="wrap-btn">
          <Button
            className="create-new-button"
            variant="contained"
            color="primary"
            onClick={togglePopover}
            ref={buttonRef}
          >
            <AddRounded />
            <Typography className="lable-create-new">
              {t('createNew')}
            </Typography>
          </Button>
        </div>
      )}

      {showPopover && (
        <>
          <div className="overlay" />
          <div ref={popoverRef} className="popover">
            {isSmall ? (
              <Button
                className="small-create-new-button"
                variant="contained"
                color="primary"
                onClick={togglePopover}
              >
                <AddRounded />
              </Button>
            ) : (
              <Button
                className="create-new-button"
                variant="contained"
                color="primary"
                onClick={togglePopover}
              >
                <AddRounded />
                <Typography className="lable-create-new">
                  {t('createNew')}
                </Typography>
              </Button>
            )}
            <div className="popover-content">
              {createLinks(newProjectRoutes, { callback: togglePopover })}
            </div>
          </div>
        </>
      )}
    </StyledPopover>
  );
};
export default PopoverCreateNewProject;
