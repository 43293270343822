import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClickAwayListener, IconButton } from '@mui/material';
import IconCaretDown from '@src/assets/icons/caret-down.svg';
import IconCaretUp from '@src/assets/icons/caret-up.svg';
import logoTTSEn from '@src/assets/logo/logo-tts-en-v3.svg';
import logoTTSVi from '@src/assets/logo/logo-tts-vi-v3.svg';
import logoDubbingEn from '@src/assets/logo/logo-dubbing-en-v3.svg';
import logoDubbingVi from '@src/assets/logo/logo-dubbing-vi-v3.svg';
import smallLogoTts from '@src/assets/logo/small-logo.svg';
import smallLogoApi from '@src/assets/logo/small-logo-api.svg';
import smallLogoDubbing from '@src/assets/logo/small-logo-dubbing.svg';
import smallLogoSTT from '@src/assets/logo/small-logo-stt.svg';
import logoSTT from '@src/assets/logo/logo-stt.svg';
import {
  CRM_URL,
  VBEE_CONSOLE_URL,
  VBEE_DUBBING_URL,
  VBEE_STT_URL,
} from '@src/configs';
import { LANGUAGE } from '@src/constants';
import {
  useCheckDubbingRoute,
  useCheckSTTRoute,
} from '@src/hooks/useCheckDubbingRoute';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledProductsDropdown, StyledLogo } from './index.style';

const AIProducts = {
  TTS: {
    id: 'tts',
    name: {
      en: 'Text to Speech',
      vi: 'Chữ thành lời',
    },
    logo: smallLogoTts,
  },
  API: {
    id: 'api',
    name: {
      en: 'AIVoice API',
      vi: 'AIVoice API',
    },
    logo: smallLogoApi,
  },
  DUBBING: {
    id: 'dubbing',
    name: {
      en: 'AI Dubbing',
      vi: 'Lồng tiếng AI',
    },
    logo: smallLogoDubbing,
  },
  STT: {
    id: 'stt',
    name: {
      en: 'AIVoice STT',
      vi: 'AIVoice STT',
    },
    logo: smallLogoSTT,
  },
};

const Logo = ({ height, isSmall, isMobile }) => {
  const isDubbingRoute = useCheckDubbingRoute();
  const isSTTRoute = useCheckSTTRoute();
  const { i18n } = useTranslation();
  const { language } = i18n;

  const { user } = useSelector((state) => state.auth);

  const activeProductId = () => {
    if (isDubbingRoute) return AIProducts.DUBBING.id;
    if (isSTTRoute) return AIProducts.STT.id;
    return AIProducts.TTS.id;
  };

  const { getFeatureValue } = useFeatureFlags();
  const newDubbing = getFeatureValue(FEATURE_KEYS.NEW_DUBBING, {
    email: user.email,
    userId: user.id,
  });

  const newSTT = getFeatureValue(FEATURE_KEYS.STT, {
    email: user.email,
    userId: user.id,
  });

  const products = { ...AIProducts };
  if (!newDubbing) delete products.DUBBING;
  if (!newSTT) delete products.STT;

  let logoSrc;

  if (isSmall) {
    logoSrc = smallLogoTts;
  } else if (language === LANGUAGE.VN) logoSrc = logoTTSVi;
  else logoSrc = logoTTSEn;

  if (isDubbingRoute) {
    if (isSmall) logoSrc = smallLogoDubbing;
    else if (language === LANGUAGE.VN) logoSrc = logoDubbingVi;
    else logoSrc = logoDubbingEn;
  }

  if (isSTTRoute) {
    if (isSmall) logoSrc = smallLogoSTT;
    else logoSrc = logoSTT;
  }

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => setOpenMenu(true);

  const handleSelectProduct = (productId) => {
    switch (productId) {
      case AIProducts.TTS.id:
        if (isDubbingRoute) window.open(`${CRM_URL}/studio/text-to-speech`);
        break;
      case AIProducts.API.id:
        window.open(VBEE_CONSOLE_URL);
        break;
      case AIProducts.DUBBING.id:
        if (!isDubbingRoute) window.open(VBEE_DUBBING_URL);
        break;
      case AIProducts.STT.id:
        window.open(VBEE_STT_URL);
        break;
      default:
        break;
    }
  };

  return (
    <StyledLogo isSmall={isSmall} isMobile={isMobile}>
      <div
        className="logo-wrapper"
        onClick={handleOpenMenu}
        role="button"
        tabIndex={0}
      >
        <img
          src={logoSrc}
          alt="vbee tts logo"
          className="logo-img"
          width={isSmall ? 32 : null}
          height={height || 'auto'}
        />
        <IconButton className="button-close" disableRipple>
          <div className={`close-icon ${openMenu && 'close-icon-active'}`}>
            <img
              src={openMenu ? IconCaretUp : IconCaretDown}
              alt="icon-arrow"
            />
          </div>
        </IconButton>
      </div>
      {openMenu && (
        <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
          <StyledProductsDropdown
            open={openMenu}
            isSmall={isSmall}
            isMobile={isMobile}
          >
            {Object.values(products).map((product) => (
              <div
                key={product.id}
                className={`product-item ${
                  product.id === activeProductId() && 'product-item-active'
                }`}
                onClick={() => handleSelectProduct(product.id)}
                role="button"
                tabIndex={0}
              >
                <img src={product.logo} alt={product.name[language]} />
                <span>{product.name[language]}</span>
              </div>
            ))}
          </StyledProductsDropdown>
        </ClickAwayListener>
      )}

      {/** Preload images to prevent lagging */}
      <div style={{ display: 'none' }}>
        <img src={smallLogoTts} alt="small-logo-tts" />
        <img src={smallLogoApi} alt="small-logo-api" />
        <img src={smallLogoDubbing} alt="small-logo-dubbing" />
      </div>
    </StyledLogo>
  );
};

export default Logo;
