import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledConvertAction = styled('div')`
  padding-top: ${(props) => (props.openSentences ? 0 : '8px')};
  display: ${(props) => (props.openSentences ? 'none' : 'flex')};

  .listening-button-wrapper {
    width: fit-content;
  }

  .right-item {
    margin: auto 0px auto auto;
  }

  .right-item-refresh {
    margin: auto 0px auto 0px;
  }

  .centered-item {
    margin: 0px auto;
  }

  .listening-button {
    text-transform: none;
    padding: 6px 16px 6px 12px;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    width: 120px;
    height: 44px;
  }

  .custom-button {
    text-transform: none;
    border-radius: 12px;
    padding: 4px 6px;
    min-width: fit-content;
    &:hover,
    &:focus {
      background: ${COLOR.secondary[80]};
    }
  }
`;

const StyledActionReset = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  .button {
    display: flex;
    height: 28px;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 500px;
    text-transform: none;
  }
  .outlined-button {
    border: 1px solid ${COLOR.indigo[32]};
  }
`;

const StyledTryListenNote = styled('div')`
  display: inline-flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  background: ${COLOR.secondary[80]};

  .description {
    color: ${COLOR.secondary[8]};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
  }

  .ignore-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 510;
    line-height: 20px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export { StyledConvertAction, StyledActionReset, StyledTryListenNote };
