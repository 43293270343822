import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledSelectionComponent = styled(TextField)`
  background-color: ${COLOR.white};
  flex: 6;

  .MuiOutlinedInput-root {
    border-radius: 284px;
  }

  .MuiInputLabel-root {
    font-size: 14px;
  }

  .MuiSelect-root {
    background-color: ${COLOR.white};
  }

  .MuiMenuItem-root {
    .Mui-selected {
      background-color: transparent;
    }
  }

  .language-text {
    font-size: 14px;
    font-weight: 500;
    color: ${COLOR.secondary[8]};
  }
`;

export const StyledLanguageSelection = styled('div')`
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  // align-items: center;
`;
