import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getMessage } from './message';
import actions from '../redux/actions';

const NotiHandler = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { code, message, severity, value } = useSelector((state) => state.noti);

  useEffect(() => {
    let msg;
    if (code) msg = getMessage(code);
    else if (message) msg = message;

    if (msg && severity) {
      enqueueSnackbar(t(msg, { value }), {
        variant: severity,
        autoHideDuration: 3000,
        onClick: (e) => {
          e.stopPropagation();
          closeSnackbar();
        },
      });
      dispatch(actions.noti.reset());
    }
  }, [code, message]);

  return <>{children}</>;
};

export default NotiHandler;
