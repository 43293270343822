export const actionTypes = {
  HANDLE_CHANGE_OPEN_SIDEBAR: 'HANDLE_CHANGE_OPEN_SIDEBAR',
};

const handleChangeOpenSidebar = (openSidebar) => ({
  type: actionTypes.HANDLE_CHANGE_OPEN_SIDEBAR,
  openSidebar,
});

export { handleChangeOpenSidebar };
