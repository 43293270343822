/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import CustomDrawer from '@src/components/Drawer';
import api from '@src/apis';
import {
  OLD_VERSION_DUBBING_VOICE_CODES,
  VOICE_PAGINATION_LIMIT,
} from '@src/constants/voice';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import ProcessHandler from '@src/components/ProcessHandler';
import actions from '@src/redux/actions';
import { PLAYING_AUDIO_TYPE, SILENCE_AUDIO_URL } from '@src/constants/tts';

import { SCROLL_THRESHOLD } from '@src/constants';
import { StyledLoading, StyledVoiceDrawer } from './index.styled';
import Voice from './Voice';

const VoiceDrawer = ({
  open,
  onClose,
  onChangeVoice,
  activeVoiceId,
  defaultFilter = {},
  oldDubbingVoices,
}) => {
  const { getFeatureValue } = useFeatureFlags();

  const { t } = useTranslation();
  const audioRef = createRef(null);

  const [voices, setVoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [audioLink, setAudioLink] = useState('');
  const [audioType, setAudioType] = useState(PLAYING_AUDIO_TYPE.SILENCE);
  const [hasMore, setHasMore] = useState(true);
  const refVoices = useRef(null);

  const { filterVoice } = useSelector((state) => state.voice);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const allowFilterByVoiceLibrary = getFeatureValue(
    FEATURE_KEYS.FILTER_BY_VOICE_LIBRARY,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
      screenWidth: window.innerWidth,
    },
  );

  const handleScroll = useCallback(() => {
    const { scrollHeight, scrollTop, clientHeight } = refVoices.current;
    if (
      scrollHeight - scrollTop <= clientHeight + SCROLL_THRESHOLD &&
      hasMore &&
      !loading
    ) {
      setPage(page + 1);
    }
  }, [hasMore, loading]);

  const fetchVoices = async () => {
    setLoading(true);
    const gender = filterVoice?.gender?.length
      ? filterVoice.gender.map((item) => item).join(',')
      : '';
    const languageCode = filterVoice?.languageCode?.length
      ? filterVoice.languageCode.map((item) => item).join(',')
      : '';
    const features = filterVoice?.features?.length
      ? filterVoice.features.map((item) => item).join(',')
      : '';
    const level = filterVoice?.level?.length
      ? filterVoice.level.map((item) => item).join(',')
      : '';
    const voiceOwnership = filterVoice?.voiceOwnership;

    let data = {};

    if (allowFilterByVoiceLibrary)
      data = await api.voices.getVoicesV2({
        search: search.trim(),
        gender,
        languageCode,
        level,
        features,
        active: true,
        offset: (page - 1) * VOICE_PAGINATION_LIMIT,
        limit: oldDubbingVoices ? undefined : VOICE_PAGINATION_LIMIT,
        voiceOwnership,
        ...defaultFilter,
      });
    else
      data = await api.voices.getVoices({
        search: search.trim(),
        gender,
        languageCode,
        level,
        features,
        active: true,
        offset: (page - 1) * VOICE_PAGINATION_LIMIT,
        limit: oldDubbingVoices ? undefined : VOICE_PAGINATION_LIMIT,
        ...defaultFilter,
      });
    setLoading(false);
    if (data.result) {
      const { hasDubbing } = defaultFilter;
      let newVoices;
      if (hasDubbing && oldDubbingVoices) {
        const oldVoices = data.result.voices.filter((voice) =>
          OLD_VERSION_DUBBING_VOICE_CODES.includes(voice.code),
        );
        newVoices = oldVoices;
        setTotal(OLD_VERSION_DUBBING_VOICE_CODES.length);
        setSelectedVoice(
          data.result.voices.find((voice) => voice.id === activeVoiceId),
        );
      } else {
        newVoices = data.result.voices;
        setTotal(data.result.metadata.total);
        setSelectedVoice(
          data.result.voices.find((voice) => voice.id === activeVoiceId),
        );
      }
      if (newVoices.length < VOICE_PAGINATION_LIMIT) {
        setHasMore(false);
      }
      setVoices((prevVoices) => [
        ...prevVoices,
        ...newVoices.filter(
          (newVoice) => !prevVoices.some((req) => req.id === newVoice.id),
        ),
      ]);
    }
  };

  const handleChangePlayAudio = (voice) => {
    dispatch(actions.audioPlayer.updateStatus(false));
    const audio = audioRef.current;

    if (!audioLink || voice.demo !== audioLink) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      if (audio) audio.src = SILENCE_AUDIO_URL;
      audio.load();
      audio.play();
      setAudioLink(voice.demo);
      audio.load();
    }

    if (voice.playStatus) {
      audio.pause();
    } else {
      audio.play();
    }

    const newVoices = voices.map((item) =>
      item.id === voice.id
        ? { ...voice, playStatus: !voice.playStatus }
        : { ...item, playStatus: false },
    );
    setVoices(newVoices);
  };

  const handleAudioEnd = () => {
    if (audioType === PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      const newVoices = voices.map((item) =>
        item.playStatus ? { ...item, playStatus: false } : item,
      );
      setVoices(newVoices);
      return;
    }

    if (audioLink) {
      setAudioType(PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC);
      audioRef.current.src = audioLink;
      audioRef.current.load();
      audioRef.current.play();
      return;
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const handleSelectedVoice = (voice) => {
    onChangeVoice(voice);
    onClose();
  };

  useEffect(() => {
    const currentRefVoices = refVoices?.current;
    currentRefVoices?.addEventListener('scroll', handleScroll);
    return () => {
      currentRefVoices?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (open) fetchVoices();
  }, [open, page, filterVoice]);

  return (
    <CustomDrawer
      title={t('chooseVoice')}
      anchor="bottom"
      open={open}
      onClose={onClose}
      minWidth="350px"
      padding="0px"
      titleFontSize="16px"
      titleFontStyle="normal"
      maxWidthContent="100%"
    >
      <StyledVoiceDrawer ref={refVoices}>
        <List className="list">
          {loading && voices.length === 0 ? (
            <StyledLoading>
              <CircularProgress />
            </StyledLoading>
          ) : (
            <div>
              {voices.map((voice) => (
                <ListItem className="list-item">
                  <ListItemButton>
                    <Voice
                      key={voice.id}
                      onClick={() => {
                        handleSelectedVoice(voice);
                      }}
                      onSelectedVoice={handleSelectedVoice}
                      onChangePlayAudio={handleChangePlayAudio}
                      voice={voice}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </div>
          )}
        </List>
        <audio ref={audioRef} onEnded={handleAudioEnd}>
          <source src={audioLink} type="audio/wav" />
        </audio>
      </StyledVoiceDrawer>
    </CustomDrawer>
  );
};

export default VoiceDrawer;
