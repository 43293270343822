/* eslint-disable no-param-reassign */

const getVietnamPhoneNumberPrefixMapping = () => {
  const VIETTEL = {
    '016966': '03966',
    '0169': '039',
    '0168': '038',
    '0167': '037',
    '0166': '036',
    '0165': '035',
    '0164': '034',
    '0163': '033',
    '0162': '032',
  };
  const MOBIFONE = {
    '0120': '070',
    '0121': '079',
    '0122': '077',
    '0126': '076',
    '0128': '078',
  };
  const VINAPHONE = {
    '0123': '083',
    '0124': '084',
    '0125': '085',
    '0127': '081',
    '0129': '082',
  };
  const VIETNAMOBILE = {
    '01862': '0562',
    '01863': '0563',
    '01864': '0564',
    '01865': '0565',
    '01866': '0566',
    '01867': '0567',
    '01868': '0568',
    '01869': '0569',
    '01882': '0582',
    '01883': '0583',
    '01884': '0584',
    '01885': '0585',
    '01886': '0586',
    '01887': '0587',
    '01888': '0588',
    '01889': '0589',
  };
  const GMOBILE = {
    '01992': '0592',
    '01993': '0593',
    '01998': '0598',
    '01999': '0599',
  };

  return [
    ...Object.entries(VIETTEL),
    ...Object.entries(MOBIFONE),
    ...Object.entries(VINAPHONE),
    ...Object.entries(VIETNAMOBILE),
    ...Object.entries(GMOBILE),
  ];
};

const PHONE_NUMBER_TYPE = {
  KOREA: { code: '82', length: 12 },
};

const convertKoreaTelephone = (phoneNumber) => `+${phoneNumber}`;

const convertVietnamTelephone = (phoneNumber) => {
  phoneNumber = phoneNumber.trim();

  if (phoneNumber.startsWith('1800') || phoneNumber.startsWith('1900'))
    return phoneNumber;

  if (phoneNumber.startsWith('84') && phoneNumber.length > 10) {
    phoneNumber = `${phoneNumber.slice(2)}`;
  }

  if (!phoneNumber.startsWith('0')) {
    phoneNumber = `${0}${phoneNumber}`;
  }

  const prefixMapping = getVietnamPhoneNumberPrefixMapping();

  // eslint-disable-next-line no-restricted-syntax
  for (const [prefix, convert] of prefixMapping) {
    const phoneNumberPrefix = phoneNumber.substr(0, prefix.length);
    if (phoneNumberPrefix === prefix) {
      phoneNumber = phoneNumber.replace(phoneNumberPrefix, convert);
      break;
    }
  }

  if (phoneNumber.length !== 10) return null;

  return phoneNumber;
};

const getCountryCode = (phoneNumber) => {
  const prefixCode = phoneNumber.substr(0, 2);

  const countryCode = Object.entries(PHONE_NUMBER_TYPE).find(
    ([, condition]) => {
      const { code, length } = condition;
      return prefixCode === code && phoneNumber.length === length;
    },
  );

  return countryCode || [];
};

const convertPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;
  phoneNumber = phoneNumber.toString();

  const REGEX_INTERNATIONAL_PHONE_NUMBER =
    /^\+?(\d{1,3})?[-\s\t]*\d{1,4}([-.\s\t]*\d{1,4})*$/;
  if (!phoneNumber.match(REGEX_INTERNATIONAL_PHONE_NUMBER)) return null;

  try {
    phoneNumber = phoneNumber.replace(/\D+/g, '').trim();
    const [, { code: countryCode } = {}] = getCountryCode(phoneNumber);

    switch (countryCode) {
      case PHONE_NUMBER_TYPE.KOREA.code:
        phoneNumber = convertKoreaTelephone(phoneNumber);
        break;
      default:
        phoneNumber = convertVietnamTelephone(phoneNumber);
    }

    return phoneNumber;
  } catch (error) {
    return null;
  }
};

const getPhoneNumber = (phoneNumber) =>
  convertPhoneNumber(phoneNumber) || convertPhoneNumber(`84${phoneNumber}}`);

export { convertPhoneNumber, getPhoneNumber };
