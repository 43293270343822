import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import { ArrowBack, TimerOutlined } from '@mui/icons-material';
import actions from '@src/redux/actions';
import {
  calculateDubbingDuration,
  convertMillisToDubbingDuration,
} from '@src/services/dubbing';
import { MAX_LENGTH_TITLE } from '@src/constants/request';
import { calculateElapsedDateTime } from '@src/utils/date';
import ResizeTextBox from '@src/components/ResizeTextBox';
import useElementWidth from '@src/hooks/useElementWidth';
import { getSRTDuration, parseSrtFromSentences } from '@src/utils/srt';
import { StyledTitleBar } from './index.style';
import GroupConvert from './GroupConvert';

const SAVE_TITLE_WIDTH = 100;

const TitleBar = ({
  onChangeRequestLoading,
  hasDeleteSentences,
  setHasDeleteSentences,
}) => {
  const isMobileScreen = window.innerWidth < 480;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const { projectInfo, changedProjectInfo, sentences } = useSelector(
    (state) => state.dubbingRequest,
  );

  const [duration, setDuration] = useState(0);

  const titleRef = useRef();
  const titleWidth = useElementWidth(titleRef);

  const getDuration = useCallback(async () => {
    if (!projectInfo.currentSubtitleLink) return 0;

    const fileDuration = await calculateDubbingDuration(
      projectInfo.currentSubtitleLink,
    );
    return fileDuration;
  }, [projectInfo.currentSubtitleLink]);

  const renderUpdatedTime = () =>
    useMemo(
      () =>
        projectInfo.updatedAt ? (
          <Box className="time-updated">
            {calculateElapsedDateTime(projectInfo.updatedAt, t)}
          </Box>
        ) : null,
      [projectInfo.updatedAt, t],
    );

  const handleValidateTitle = (value) => {
    if (value.length <= MAX_LENGTH_TITLE) return true;

    if (value.length > MAX_LENGTH_TITLE)
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );

    return false;
  };

  const handleChangeTitle = ({ newTitle }) => {
    if (newTitle.length > MAX_LENGTH_TITLE) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );
      return;
    }

    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
        ...changedProjectInfo,
        title: newTitle,
      }),
    );
  };

  const handleBack = () => history.push('/dubbing/projects');

  const memoizedParseSentences = useCallback(() => {
    const parsedSentences = parseSrtFromSentences(sentences);
    return parsedSentences;
  }, [sentences]);

  useEffect(() => {
    const fetchDuration = async () => {
      const fileDuration = await getDuration();
      setDuration(fileDuration);
    };

    fetchDuration();
  }, [getDuration]);

  useEffect(() => {
    const parsedSentences = memoizedParseSentences();
    const newDuration = getSRTDuration(parsedSentences);
    setDuration(newDuration);
  }, [memoizedParseSentences]);

  return (
    <StyledTitleBar ref={titleRef}>
      <Box className="title-wrapper">
        <IconButton className="back-button" onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <ResizeTextBox
          value={changedProjectInfo.title || ''}
          placeholder={t('noTitle')}
          onClick={() => {}}
          onValidate={handleValidateTitle}
          onChange={handleChangeTitle}
          maxWidth={titleWidth - SAVE_TITLE_WIDTH}
          footerLabel={renderUpdatedTime()}
        />
        {isMobileScreen && (
          <Tooltip arrow title={t('estimatedAudioLength')} placement="top">
            <Typography variant="body2" className="estimate-audio-length">
              <TimerOutlined sx={{ fontSize: 16 }} color="iconPrimary" />
              <span translate="no">
                {convertMillisToDubbingDuration(duration)}
              </span>
            </Typography>
          </Tooltip>
        )}
      </Box>
      <GroupConvert
        duration={duration}
        onChangeRequestLoading={onChangeRequestLoading}
        hasDeleteSentences={hasDeleteSentences}
        setHasDeleteSentences={setHasDeleteSentences}
      />
    </StyledTitleBar>
  );
};

export default React.memo(TitleBar);
