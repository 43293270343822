import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import classNames from 'classnames';

import {
  CUSTOMER_SUPPORT_PHONE_NUMBER,
  DOCUMENT_LINK,
  FAQ_LINK,
  VBEE_MESSENGER_URL,
  VBEE_ZALO_URL,
} from '@src/configs';
import actions from '@src/redux/actions';
import { BANNER_TYPE } from '@src/constants/banner';
import { useTheme } from '@mui/styles';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckProductName } from '@src/hooks/useCheckProduct';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  checkVoucherBypProductForHeaderBanner,
  verifyTime,
} from '@src/services/voucher';
import apis from '@src/apis';
import { checkShowAutoRenewalPayment } from '@src/services/order';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import {
  useCheckDubbingRoute,
  useCheckTTSRoute,
} from '@src/hooks/useCheckDubbingRoute';
import { MOBILE_BREAKPOINT } from '@src/constants';
import { checkUsingPrepaidMonthlyPkgOfStudio } from '@src/services/package';
import { checkVietNam } from '@src/utils/checkCountry';
import useSidebarRoutes from '@src/hooks/useSidebarRoutes';
import {
  StyledBetaWrapper,
  StyledDrawerNew,
  StyledSidebarTooltip,
  StyledSubMenu,
} from './index.style';
import SmallSidebar from './SmallSidebar';
import LargeSidebar from './LargeSidebar';

const SUPPORT_MENU = [
  {
    label: 'userGuide',
    href: DOCUMENT_LINK,
    isActive: true,
  },
  {
    label: 'contactZalo',
    href: VBEE_ZALO_URL,
    isActive: true,
  },
  {
    label: 'contactMessenger',
    href: VBEE_MESSENGER_URL,
    isActive: true,
  },
  {
    label: 'contactHotline',
    href: `tel:${CUSTOMER_SUPPORT_PHONE_NUMBER}`,
    isActive: true,
  },
];

const DesktopSidebar = ({
  openSidebar,
  handleOpenSidebar,
  handleCloseSidebar,
  ipLocation,
  useHeaderBanner,
  headerVoucher,
}) => {
  const product = useCheckProductName();
  const sidebarBodyRef = useRef(null);
  const [openSubMenu] = useState(false);
  const [, setShowScrollbar] = useState(false);
  const { getFeatureValue } = useFeatureFlags();
  const showSidebarVoucher = getFeatureValue(FEATURE_KEYS.SIDEBAR_VOUCHER);
  const [vouchers, setVouchers] = useState([]);
  const [showAutoRenewalBanner, setShowAutoRenewalBanner] = useState(false);
  const isVietNam = checkVietNam();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { usingPackage, subscriptionPurchase, lastExpiredOrder } = useSelector(
    (state) => state.user,
  );
  const { lockCharacters } = useSelector((state) => state.auth.user);

  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const checkCharacterReservationPeriod = () => {
    let preservedCharacterDate;

    preservedCharacterDate = user.preservationExpiryDate;
    if (user.preservationExpiryDate && usePreservationExpiryDate)
      preservedCharacterDate = moment(user.preservationExpiryDate);
    else if (user.packageExpiryDate) {
      preservedCharacterDate = moment(user.packageExpiryDate).add(30, 'days');
    } else if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      preservedCharacterDate = moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days');
    }

    if (!preservedCharacterDate) return false;
    return moment().isBefore(moment(preservedCharacterDate));
  };

  const hasLockCharacters =
    useCheckTTSRoute() &&
    lockCharacters > 0 &&
    checkCharacterReservationPeriod();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  const useAutoRenewalPayment =
    useNewTtsUI &&
    getFeatureValue(FEATURE_KEYS.CONFIG_AUTO_RENEW_PREPAID_PACKAGE, {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    }) &&
    checkUsingPrepaidMonthlyPkgOfStudio(usingPackage);
  const useNewSupportUI = getFeatureValue(FEATURE_KEYS.NEW_SUPPORT_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const activeRoute = (routePath) =>
    matchPath(location.pathname, { path: routePath, exact: true });

  const separateAIVoiceProducts = getFeatureValue(
    FEATURE_KEYS.SEPARATE_AIVOICE_PRODUCTS,
    { userId: user.id, email: user.email },
  );
  const isDubbingRoute = useCheckDubbingRoute();
  const { desktopSidebarRoutes } = useSidebarRoutes();
  const isShowingApiRedirect = !separateAIVoiceProducts;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // width >= 900px
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600px <= width < 900px

  const drawerProperties = {
    sx: {
      display: {
        xs: 'none',
        sm: isMiddleScreen ? 'block' : 'none',
        md: isLargeScreen ? 'block' : 'none',
      },
    },
    variant: openSidebar && isMiddleScreen ? 'temporary' : 'persistent',
    anchor: 'left',
    onClose: isMiddleScreen ? handleCloseSidebar : null,
    ModalProps: isMiddleScreen
      ? {
          keepMounted: true,
        }
      : {},
  };

  const isExpiredPackage =
    !usingPackage.id ||
    (user.packageExpiryDate && moment().isAfter(user.packageExpiryDate));

  const handleOpenAutoRenewalPayment = () => {
    const isNotActiveSubPurchase = ![
      SUBSCRIPTION_PURCHASE_STATUS.PENDING,
      SUBSCRIPTION_PURCHASE_STATUS.ACTIVE,
    ].includes(subscriptionPurchase?.status);

    const autoRenewalBanner =
      !isDubbingRoute &&
      useAutoRenewalPayment &&
      isVietNam &&
      usingPackage.active &&
      isNotActiveSubPurchase &&
      !isExpiredPackage &&
      checkShowAutoRenewalPayment({
        packageCode: usingPackage?.code,
        alwayShow: true,
      });
    setShowAutoRenewalBanner(autoRenewalBanner);
  };

  const handlePlayVideoTutorial = () =>
    dispatch(
      actions.banner.updateDisplayBanner(BANNER_TYPE.SERVICE_MANUAL, true),
    );

  const createLinks = (aRoutes, props) =>
    aRoutes.map((route) => {
      const hasEnabledRoute = route.featureKey
        ? getFeatureValue(route.featureKey, {
            userId: user?.id,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          })
        : true;
      if (!hasEnabledRoute) return null;

      const isActiveRoute =
        activeRoute(route.path) ||
        route.subPaths?.some((path) => activeRoute(path));

      if (route?.externalUrl) {
        return (
          <ListItem
            key={route?.labelSidebar}
            className="item"
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
          >
            <a
              href={route.path}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames('item-link', {
                'active-link': isActiveRoute,
              })}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              {openSidebar ? (
                <>
                  <route.iconSidebar
                    className={classNames('item-icon', {
                      'item-icon-active': isActiveRoute,
                    })}
                  />
                  <div className="item-wrapper">
                    <ListItemText
                      primary={t(route?.labelSidebar)}
                      disableTypography
                      className={classNames('item-text', {
                        'item-text-active': isActiveRoute,
                      })}
                    />
                    {route.beta && (
                      <StyledBetaWrapper active={isActiveRoute}>
                        <div className="beta-background" />
                        <Typography className="beta-text">Beta</Typography>
                      </StyledBetaWrapper>
                    )}
                    {route.isNewFeature && (
                      <StyledBetaWrapper active={isActiveRoute}>
                        <div className="beta-background" />
                        <Typography className="beta-text">New</Typography>
                      </StyledBetaWrapper>
                    )}
                  </div>
                </>
              ) : (
                <StyledSidebarTooltip title={t(route?.labelSidebar)}>
                  <route.iconSidebar className="item-icon" />
                </StyledSidebarTooltip>
              )}
            </a>
          </ListItem>
        );
      }

      if (route?.isCreateProject) {
        return (
          <ListItem
            key={route?.labelSidebar}
            className="item"
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
          >
            <NavLink
              to={route.path}
              className={classNames('item-link', {
                'active-link': isActiveRoute,
              })}
              onClick={() => {
                if (props?.callback) props.callback();
              }}
            >
              <route.iconSidebar
                className={classNames('item-icon', {
                  'item-icon-active': isActiveRoute,
                })}
              />
              <div className="item-wrapper">
                <ListItemText
                  primary={t(route?.labelSidebar)}
                  disableTypography
                  className={classNames('item-text', {
                    'item-text-active': isActiveRoute,
                  })}
                />
                {route.beta && (
                  <StyledBetaWrapper active={isActiveRoute}>
                    <div className="beta-background" />
                    <Typography className="beta-text">Beta</Typography>
                  </StyledBetaWrapper>
                )}
                {route.isNewFeature && (
                  <StyledBetaWrapper active={isActiveRoute}>
                    <Typography
                      className="new-text"
                      sx={{ textTransform: 'none !important' }}
                    >
                      {t('new')}
                    </Typography>
                  </StyledBetaWrapper>
                )}
              </div>
            </NavLink>
          </ListItem>
        );
      }

      return (
        <ListItem
          key={route?.labelSidebar}
          className="item"
          onMouseEnter={() => setShowScrollbar(true)}
          onMouseLeave={() => setShowScrollbar(false)}
        >
          <NavLink
            to={route.path}
            className={classNames('item-link', {
              'active-link': isActiveRoute,
            })}
            onClick={() => {
              if (props?.mobile) handleCloseSidebar();
              if (props?.callback) props.callback();
            }}
          >
            {openSidebar ? (
              <>
                <route.iconSidebar
                  className={classNames('item-icon', {
                    'item-icon-active': isActiveRoute,
                  })}
                />
                <div className="item-wrapper">
                  <ListItemText
                    primary={t(route?.labelSidebar)}
                    disableTypography
                    className={classNames('item-text', {
                      'item-text-active': isActiveRoute,
                    })}
                  />
                  {route.beta && (
                    <StyledBetaWrapper active={isActiveRoute}>
                      <div className="beta-background" />
                      <Typography className="beta-text">Beta</Typography>
                    </StyledBetaWrapper>
                  )}
                  {route.isNewFeature && (
                    <StyledBetaWrapper active={isActiveRoute}>
                      <Typography
                        className="new-text"
                        sx={{ textTransform: 'none !important' }}
                      >
                        {t('new')}
                      </Typography>
                    </StyledBetaWrapper>
                  )}
                </div>
              </>
            ) : (
              <StyledSidebarTooltip title={t(route?.labelSidebar)}>
                <route.iconSidebar className="item-icon" />
              </StyledSidebarTooltip>
            )}
          </NavLink>
        </ListItem>
      );
    });

  const renderSubMenuSupport = ({ props, className, itemClassName }) => (
    <StyledSubMenu className={className}>
      <List component="div" disablePadding>
        {useNewSupportUI ? (
          SUPPORT_MENU.filter((item) => item.isActive).map((item) => (
            <a
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className={`item-link ${itemClassName}`}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              <Typography className="item-text">{t(item.label)}</Typography>
            </a>
          ))
        ) : (
          <>
            <a
              href={DOCUMENT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={`item-link ${itemClassName}`}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              <Typography className="item-text">{t('userGuide')}</Typography>
            </a>
            <div
              className={`item-link ${itemClassName}`}
              role="button"
              tabIndex="0"
              onClick={handlePlayVideoTutorial}
            >
              <Typography className="item-text">
                {t('videoTutorial')}
              </Typography>
            </div>
            <a
              href={FAQ_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={`item-link ${itemClassName}`}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              <Typography className="item-text">{t('faq')}</Typography>
            </a>
          </>
        )}
      </List>
    </StyledSubMenu>
  );

  useEffect(() => {
    if (openSubMenu) {
      // Scroll to bottom when open sub menu on small screen
      // Delay to scroll to bottom when open sub menu on small screen to wait for the sub menu to be rendered
      setTimeout(() => {
        const sidebarBody = sidebarBodyRef.current;
        sidebarBody.lastElementChild.scrollIntoView({
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [openSubMenu]);

  const getSatisfiedVouchers = (voucherData) => {
    const newVouchers = voucherData.reduce((acc, currVoucher) => {
      const satisfiedTime = verifyTime(currVoucher);
      if (!satisfiedTime) return acc;

      const canUse =
        satisfiedTime &&
        currVoucher?.remainingAmount > 0 &&
        !currVoucher.isPrivate &&
        currVoucher.usedAmount < currVoucher.numReuse;
      return [...acc, { ...currVoucher, satisfiedTime, canUse }];
    }, []);

    const sortVouchers = newVouchers.sort((voucher) =>
      voucher.canUse ? -1 : 1,
    );

    setVouchers(sortVouchers);
    if (useVoucherByProduct && sortVouchers.length > 0) {
      const canUseVoucher = sortVouchers.filter(
        (voucher) =>
          voucher.canUse &&
          checkVoucherBypProductForHeaderBanner({ voucher, product }),
      );
      const checkVoucherForLockCharacterInListVouchers = canUseVoucher.find(
        (voucher) => voucher.isPreserveVoucher,
      );
      if (hasLockCharacters && !checkVoucherForLockCharacterInListVouchers) {
        canUseVoucher.push({
          canUse: true,
          isPreserveVoucher: true,
        });
      }
      const checkMobile = window.innerWidth <= MOBILE_BREAKPOINT;
      let finalHeaderVoucher = [];
      if (!checkMobile) {
        finalHeaderVoucher = canUseVoucher.filter(
          (item) => item.isHeaderBanner,
        );
      } else {
        finalHeaderVoucher = [...canUseVoucher];
      }
      if (finalHeaderVoucher.length > 0 && useHeaderBanner) {
        dispatch(actions.voucher.updateHeaderVoucher(finalHeaderVoucher));
      } else {
        dispatch(actions.voucher.updateHeaderVoucher(undefined));
      }
    } else if (!useVoucherByProduct && sortVouchers.length > 0) {
      const flashSaleVoucher = sortVouchers.filter(
        (voucher) => voucher.isHeaderBanner && voucher.canUse,
      );
      if (flashSaleVoucher && flashSaleVoucher.length > 0 && useHeaderBanner) {
        dispatch(actions.voucher.updateHeaderVoucher(flashSaleVoucher[0]));
      } else {
        dispatch(actions.voucher.updateHeaderVoucher(undefined));
      }
    }
  };

  const fetchVouchers = async () => {
    const data = await apis.vouchers.getVouchers();
    if (data.status) getSatisfiedVouchers(data.result);
  };

  useEffect(() => {
    handleOpenAutoRenewalPayment();
  }, [usingPackage, subscriptionPurchase]);

  // Fetch vouchers by product
  useEffect(() => {
    if (showSidebarVoucher) fetchVouchers();
  }, [product]);

  const renderSidebar = () => {
    if (openSidebar) {
      return (
        <LargeSidebar
          useHeaderBanner={useHeaderBanner}
          headerVoucher={headerVoucher}
          createLinks={createLinks}
          sidebarRoute={desktopSidebarRoutes}
          activeRoute={activeRoute}
          ipLocation={ipLocation}
          isExpiredPackage={isExpiredPackage}
          handleCloseSidebar={handleCloseSidebar}
          renderSubMenuSupport={renderSubMenuSupport}
          vouchers={vouchers}
          showAutoRenewalBanner={showAutoRenewalBanner}
          isShowingApiRedirect={isShowingApiRedirect}
        />
      );
    }

    return (
      <SmallSidebar
        useHeaderBanner={useHeaderBanner}
        headerVoucher={headerVoucher}
        createLinks={createLinks}
        sidebarRoute={desktopSidebarRoutes}
        activeRoute={activeRoute}
        ipLocation={ipLocation}
        isExpiredPackage={isExpiredPackage}
        handleCloseSidebar={handleCloseSidebar}
        handleOpenSidebar={handleOpenSidebar}
        renderSubMenuSupport={renderSubMenuSupport}
        vouchers={vouchers}
        showAutoRenewalBanner={showAutoRenewalBanner}
        isShowingApiRedirect={isShowingApiRedirect}
      />
    );
  };

  return (
    <>
      <StyledDrawerNew
        sx={drawerProperties.sx}
        variant={drawerProperties.variant}
        anchor={drawerProperties.anchor}
        open
        opensidebar={openSidebar}
        onClose={drawerProperties.onClose}
        ModalProps={drawerProperties.ModalProps}
      >
        {renderSidebar()}
      </StyledDrawerNew>
    </>
  );
};

export default DesktopSidebar;
