import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Editor, EditorState, ContentState } from 'draft-js';
import { OrderedSet } from 'immutable';
import actions from '@src/redux/actions';
import classNames from 'classnames';

import { Popper, Fade } from '@mui/material';
import PreviewButton from '@src/components/PreviewButton';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { BREAK_TIME_REGEX } from '@src/constants/voice';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { countTextLength } from '@src/services/tts';
import { getSelectedText } from '@src/services/sentence';
import { generateDecorator } from '@src/services/editor';
import {
  handleEditParagraphs,
  convertParagraphsToState,
  handleSelectedParagraph,
  countEditorStateLength,
} from '@src/services/paragraph';
import { handleSelection } from '@src/services/entity';
import debounce from '@src/utils/debounce';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

import BreakTimeHighlight from '@src/containers/TTS/BreakTimeHightLight';

import {
  // TTS_BOX_SIZE,
  TTS_VERSION,
} from '@src/constants/tts';
import Sentences from './Sentences';

import { StyledContent, StyledPreviewButton } from './index.style';
import { StyledMapEditor } from '../index.style';
// import ChangeBoxTTSSizeButton from './ChangeBoxTTSSizeButton';

const Content = ({
  openSentences,
  content,
  // toolbarRef,
  characterExceed,
  onChangeContent,
  onChangeInputLength,
  onChangeCharacterExceed,
  onchangeSynthesisSentence,
  onTryListeningSentence,
  onChangeVoice,
  audioRef,
  synthesisSentence,
}) => {
  const [isInputText, setIsInputText] = useState(false);

  const [openPreviewButton, setOpenPreviewButton] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  // const [isHaveScroll, setIsHaveScroll] = useState(false);
  // const [ttsBoxSize, setTtsBoxSize] = useState(TTS_BOX_SIZE.DEFAULT);
  const boxRef = useRef(null);
  const {
    synthesisRequest,
    loadingSynthesisConfig,
    paragraphs,
    isLoadingParagraphs,
    advanceFeature,
    previewParagraphs: previewContent,
  } = useSelector((state) => state.synthesisRequest);
  const { usingPackage, ttsUser } = useSelector((state) => state.user);
  const { isAudioLoading } = useSelector((state) => state.audioPlayer);

  const maxLengthInputText = ttsUser?.maxLengthInputText || 0;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ttsRef = useRef(null);
  const { getFeatureValue } = useFeatureFlags();

  const useOldSaveDraftFeatureFlag = getFeatureValue(
    FEATURE_KEYS.OLD_AUTO_SAVE_DRAFT,
  );
  const user = useSelector((state) => state.auth.user);

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let highlightToPreview = getFeatureValue(FEATURE_KEYS.HIGHLIGHT_TO_PREVIEW);
  highlightToPreview = highlightToPreview && useNewTtsUI;

  const locationRouter = useLocation();

  const canPreview = advanceFeature
    ? previewContent?.length && usingPackage.id && !isAudioLoading
    : synthesisSentence && usingPackage.id && !isAudioLoading;

  const handleOpenPreviewButton = () => {
    const selection = window.getSelection();

    const getBoundingClientRect = () => {
      if (selection && selection.rangeCount > 0) {
        const clientRects = selection?.getRangeAt(0)?.getClientRects();
        const position = clientRects[clientRects.length - 1];
        const fixPosition = new DOMRect(
          (position?.left || 0) + 3,
          position?.top || 0,
          position?.width || 0,
          position?.height || 0,
        );
        return fixPosition || {};
      }
      setOpenPreviewButton(false);
      return {};
    };

    setAnchorEl({
      getBoundingClientRect,
    });
    setOpenPreviewButton(true);
  };

  const handleChangeEditor = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const lengthContent = countTextLength(contentState.getPlainText());

    onChangeInputLength(lengthContent);

    let newContent = editorState;
    const currentStyle = editorState.getCurrentInlineStyle();
    if (currentStyle && currentStyle.size) {
      newContent = EditorState.setInlineStyleOverride(
        editorState,
        OrderedSet.of(),
      );
    }
    onChangeContent(newContent);

    if (lengthContent === 0)
      dispatch(actions.synthesisRequest.updateSynthesisRequest('title', ''));
    if (lengthContent > maxLengthInputText) {
      onChangeCharacterExceed(true);
    } else {
      onChangeCharacterExceed(false);
    }

    const selectedText = getSelectedText(editorState);
    onchangeSynthesisSentence(selectedText);
  };

  // Define event when editor state changes
  const handleChangeEditorState = (newEditorState) => {
    const selectionState = newEditorState.getSelection();
    // If dont focus on the editor, cant do anything
    if (!selectionState.toJS().hasFocus) {
      onChangeContent(newEditorState);
      return;
    }
    if (!isInputText) setIsInputText(true);

    const contentState = newEditorState.getCurrentContent();

    // Selection area when the user highlights
    const selection = handleSelection({
      selectionState,
      contentState,
      paragraphs,
    });
    if (selection) {
      onChangeContent(EditorState.forceSelection(newEditorState, selection));
      // previewParagraphs: preview data, format same paragraphs
      // selectedParagraphs: selected data, format same content editor state to support for emphasis, speed, break time
      const { previewParagraphs, selectedParagraph } = handleSelectedParagraph(
        selection,
        contentState,
        paragraphs,
      );
      dispatch(
        actions.synthesisRequest.updateSelectedParagraph(selectedParagraph),
      );
      dispatch(
        actions.synthesisRequest.updatePreviewParagraphs(previewParagraphs),
      );
    } else {
      dispatch(actions.synthesisRequest.updatePreviewParagraphs([]));
      onChangeContent(newEditorState);
    }
  };

  const handleChangeParagraphs = () => {
    // Update paragraphs when editor state changes
    const newParagraphs = handleEditParagraphs(paragraphs, content);
    if (newParagraphs !== null) {
      dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));

      const contentLength = countEditorStateLength(content, newParagraphs);

      onChangeInputLength(contentLength);

      if (contentLength > maxLengthInputText) {
        onChangeCharacterExceed(true);
      } else {
        onChangeCharacterExceed(false);
      }
    }
    setIsInputText(false);
  };

  const handleBlurEditor = () => {
    if (advanceFeature) {
      dispatch(actions.synthesisRequest.updatePreviewParagraphs([]));
    } else {
      onchangeSynthesisSentence();
    }
  };

  useEffect(() => {
    if (advanceFeature && isInputText)
      debounce(handleChangeParagraphs, 300)(content);
  }, [content]);

  useEffect(() => {
    // loadingSynthesisConfig: load again tts when user click to request in request table or change voice
    if (loadingSynthesisConfig) {
      const {
        text,
        sentences: synthesisConfigSentences,
        characters,
        voice,
        paragraphs: synthesisParagraphs = [],
      } = synthesisRequest;

      onChangeInputLength(characters);
      onChangeVoice(voice, loadingSynthesisConfig);

      // Reset audio
      dispatch(
        actions.audioPlayer.updateMetaData({ currentTime: 0, duration: 0 }),
      );
      dispatch(actions.audioPlayer.updateStatus(false));
      const audioLink = synthesisRequest.audioLink || '';
      dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      if (audioLink) {
        const selectedAudioRequest = synthesisRequest.id || '';
        dispatch(
          actions.audioPlayer.updateSelectedAudioRequest(selectedAudioRequest),
        );
      } else {
        dispatch(actions.audioPlayer.updateSelectedAudioRequest(''));
      }
      dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(false));

      const processBySentence = !!synthesisConfigSentences?.length;
      // When process method change => update open sentence
      if (processBySentence !== openSentences)
        dispatch(
          actions.synthesisRequest.updateOpenSentences(processBySentence),
        );

      // Sentences processing
      if (processBySentence) {
        const convertSentences = synthesisConfigSentences.map((item) => ({
          ...item,
          id: uuidv4(),
          onLoad: true,
          text: item.text,
        }));

        dispatch(actions.synthesisRequest.updateSentences(convertSentences));
        return;
      }

      // Paragraph process
      const isAdvanceFeature = voice?.version === TTS_VERSION.EMPHASIS;
      if (!isAdvanceFeature) {
        const contentEditor = EditorState.createWithContent(
          ContentState.createFromText(text),
          generateDecorator(BREAK_TIME_REGEX, BreakTimeHighlight),
        );
        const newEditorState = EditorState.moveFocusToEnd(contentEditor);
        onChangeContent(newEditorState);
      }

      if (isAdvanceFeature && isAdvanceFeature === advanceFeature) {
        dispatch(
          actions.synthesisRequest.updateParagraphs(synthesisParagraphs),
        );
        dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
      }

      if (isAdvanceFeature !== advanceFeature)
        dispatch(
          actions.synthesisRequest.switchAdvanceFeature(isAdvanceFeature),
        );
    }
  }, [loadingSynthesisConfig]);

  const handleShowContent = () => {
    const contentState = convertParagraphsToState(paragraphs);
    onChangeContent(EditorState.push(content, contentState));
  };
  // const handleChangeTtsBoxSize = (value) => setTtsBoxSize(value);

  const handleResetContent = () => {
    dispatch(actions.synthesisRequest.updateParagraphs([]));
    dispatch(actions.synthesisRequest.updateSelectedSentences([]));
    dispatch(actions.audioPlayer.updateSelectedAudioRequest(''));

    if (useOldSaveDraftFeatureFlag) {
      dispatch(actions.synthesisRequest.updateOpenSentences(false));
      dispatch(actions.synthesisRequest.switchAdvanceFeature(false));
    }
  };

  useEffect(() => {
    // isLoadingParagraphs: Load again editor state when paragraphs change
    if (isLoadingParagraphs) {
      handleShowContent();
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(false));
    }
  }, [isLoadingParagraphs]);

  useEffect(() => {
    if (advanceFeature && !openSentences) {
      dispatch(
        actions.synthesisRequest.updateParagraphs(synthesisRequest?.paragraphs),
      );
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
    }
  }, [advanceFeature]);

  // useEffect(() => {
  //   const box = boxRef.current;

  //   if (box.scrollHeight > box.clientHeight) {
  //     setIsHaveScroll(true);
  //   } else {
  //     setIsHaveScroll(false);
  //   }
  // }, [content]);

  useEffect(() => {
    dispatch(actions.synthesisRequest.updateLoadingParagraphs(false));
    return () => {
      handleResetContent();
    };
  }, [locationRouter]);

  useEffect(() => {
    if (!openSentences) {
      dispatch(actions.synthesisRequest.updateSelectedSentences([]));
    }
  }, [openSentences]);

  useEffect(() => {
    if (!canPreview) {
      setOpenPreviewButton(false);
      return;
    }
    if (highlightToPreview) debounce(handleOpenPreviewButton, 300)();
  }, [content]);

  const handlePastedText = () => {
    const currentScrollHeight = boxRef.current.scrollHeight;
    setTimeout(() => {
      const newScrollHeight = boxRef.current.scrollHeight;
      boxRef.current.scrollTop += newScrollHeight - currentScrollHeight;
    }, 100);
  };

  const handleTryListeningSentence = (sentence) => {
    setOpenPreviewButton(false);
    onTryListeningSentence(sentence);
  };

  return (
    <StyledContent
      ref={ttsRef}
      id={TTS_GUIDE.ENTER_TEXT_HERE}
      // boxSize={ttsBoxSize}
    >
      {openSentences ? (
        <Sentences
          advanceFeature={advanceFeature}
          characterExceed={characterExceed}
          // boxSize={ttsBoxSize}
          onChangeInputLength={onChangeInputLength}
          onChangeCharacterExceed={onChangeCharacterExceed}
          onTryListeningSentence={onTryListeningSentence}
          audioRef={audioRef}
        />
      ) : (
        <div
          className={classNames('editor-wrapper', {
            'character-exceed': characterExceed,
          })}
          ref={boxRef}
          onBlur={handleBlurEditor}
        >
          <Editor
            placeholder={`${t('enterTextHere')}...`}
            editorState={content}
            onChange={
              advanceFeature ? handleChangeEditorState : handleChangeEditor
            }
            customStyleMap={StyledMapEditor}
            stripPastedStyles
            handlePastedText={handlePastedText}
          />
        </div>
      )}
      <Popper
        id="virtual-element-popper"
        open={openPreviewButton}
        anchorEl={anchorEl}
        transition
        placement="right"
        style={{ zIndex: 1, cursor: 'pointer' }}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <StyledPreviewButton>
              <PreviewButton
                synthesisRequest={synthesisRequest}
                synthesisSentence={synthesisSentence}
                onTryListeningSentence={handleTryListeningSentence}
                audioRef={audioRef}
                canPreview={canPreview}
                isPopupPreview
              />
            </StyledPreviewButton>
          </Fade>
        )}
      </Popper>
      {/* <ChangeBoxTTSSizeButton
        isShow={isHaveScroll}
        ttsBoxSize={ttsBoxSize}
        toolbarRef={toolbarRef}
        onChangeTtsBoxSize={handleChangeTtsBoxSize}
          /> */}
    </StyledContent>
  );
};

export default Content;
