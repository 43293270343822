import { RESOURCE } from '@src/constants';
import api from './api';

const getClientPause = async () => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.CLIENT_PAUSE,
  });
  return response;
};

const updateClientPause = async (clientPause) => {
  const response = await api({
    method: 'POST',
    url: RESOURCE.CLIENT_PAUSE,
    data: clientPause,
  });
  return response;
};

export { getClientPause, updateClientPause };
