import { RESOURCE } from '@src/constants';
import api from './api';

export const getBgMusicPresignedUrlForUploading = async (
  fileName,
  extension,
) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.BACKGROUND_MUSICS}/presigned-url-for-uploading`,
    params: { extension, fileName },
  });
  return response;
};

export const getBgMusicPresignedUrlForSharing = async (key) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.BACKGROUND_MUSICS}/presigned-url-for-sharing`,
    params: { key },
  });
  return response;
};

export const createBackgroundMusic = async (name, link) => {
  const response = await api({
    method: 'POST',
    url: RESOURCE.BACKGROUND_MUSICS,
    data: { name, link },
  });
  return response;
};

export const getBackgroundMusics = async () => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.BACKGROUND_MUSICS,
  });
  return response;
};
