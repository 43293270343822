import { delimitNumber } from '@src/utils/number';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@src/components/Card';
import { COMMISSION_PAYMENT_LIMIT } from '@src/constants/affiliate';
import {
  CreditCardOutlined,
  ReportGmailerrorredOutlined,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import i18n from '@src/languages';
import { CommissionBoxStyled } from './index.style';
import CreatePaymentDrawer from './PaymentRequestDrawer';

const CommissionBox = ({
  currentCommission,
  pendingCommission,
  receiveCommission,
  onReloadCommission,
}) => {
  const { t } = useTranslation();

  const { language } = i18n;

  const [openPaymentRequestDrawer, setOpenPaymentRequestDrawer] =
    useState(false);

  const handleOpenPaymentRequestDrawer = () => {
    setOpenPaymentRequestDrawer(true);
  };

  const handleClosePaymentRequestDrawer = () =>
    setOpenPaymentRequestDrawer(false);

  return (
    <CommissionBoxStyled>
      <Card className="commission-box">
        <div className="commission">
          <div className="title">{t('amountOfCommissionReceived')}</div>
          <div className="current-commission">
            {delimitNumber(currentCommission, language)} <span>đ</span>
          </div>
          <div className="break-line" />
          <div className="">
            <div className="title">{t('amountInStandby')}</div>
            <div className="current-commission">
              {delimitNumber(pendingCommission, language)} <span>đ</span>
            </div>
          </div>
          <div className="">
            <div className="title">{t('amountReceived')}</div>
            <div className="current-commission">
              {delimitNumber(receiveCommission, language)} <span>đ</span>
            </div>
          </div>
          <div className="break-line" />
          <Button
            align="center"
            variant="contained"
            size="small"
            disabled={currentCommission < COMMISSION_PAYMENT_LIMIT}
            startIcon={<CreditCardOutlined />}
            onClick={handleOpenPaymentRequestDrawer}
          >
            {t('sendPaymentRequest')}
          </Button>
        </div>
        <div className="note">
          <ReportGmailerrorredOutlined />
          <div
            className="text-note"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('affiliatePolicyDetail', {
                paymentLimit: delimitNumber(COMMISSION_PAYMENT_LIMIT, language),
              }),
            }}
          />
        </div>
        <CreatePaymentDrawer
          open={openPaymentRequestDrawer}
          currentCommission={currentCommission}
          onReloadCommission={onReloadCommission}
          onClose={handleClosePaymentRequestDrawer}
        />
      </Card>
    </CommissionBoxStyled>
  );
};

export default CommissionBox;
