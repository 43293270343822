import React, { useEffect, useState } from 'react';
import {
  calculateDubbingDuration,
  convertMillisToDubbingDuration,
} from '@src/services/dubbing';

const ProjectDuration = ({ subtitleLink }) => {
  const [duration, setDuration] = useState(0);

  useEffect(async () => {
    const fileDuration = await calculateDubbingDuration(subtitleLink);
    setDuration(fileDuration);
  }, [subtitleLink]);

  return (
    <div>
      <span>{convertMillisToDubbingDuration(duration)}</span>
    </div>
  );
};

export default ProjectDuration;
