export const FEATURE_KEYS = {
  DUBBING: 'dubbing',
  SPEED_DUBBING: 'speed-dubbing',
  RECAPTCHA_TRY_LISTEN_TTS_TYPE: 'recaptcha-try-listen-tts-type',
  RECAPTCHA_TTS_TYPE: 'recaptcha-tts-type',
  TRY_LISTEN_TTS: 'try-listen-tts',
  OLD_AUTO_SAVE_DRAFT: 'old-auto-save-draft',
  OLD_REQUEST_TABLE: 'old-request-table',
  REFRESH_BUTTON: 'refresh-button',
  DOWNLOAD_AUDIO_LINK: 'download-audio-link',
  RESPONSIVE_TOOLBAR: 'responsive-toolbar',
  NEW_PLAY_AUDIO_LINK: 'new-play-audio-link',
  NEW_EDITOR: 'new-editor',
  NEW_EFFECT_GUIDE: 'new-effect-guide',
  HIGHLIGHT_TO_PREVIEW: 'highlight-to-preview',
  NEW_PAYMENT_PACKAGES: 'new-payment-packages',
  NEW_SIDEBAR: 'new-sidebar',
  SIDEBAR_VOUCHER: 'sidebar-voucher',
  NEW_PAYMENT: 'new-payment',
  NEW_TTS_UI: 'new-tts-ui', // new UI for user include: new sidebar, new editor, audio player
  NEW_PACKAGE_PRICE_UI: 'new-package-price-ui',
  AUTO_RENEWAL_PAYMENT: 'auto-renewal-payment',
  CONFIG_AUTO_RENEW_PREPAID_PACKAGE: 'config-auto-renew-prepaid-package',
  SUBSCRIPTION: 'subscription',
  DUBBING_BY_UNIT_SECOND: 'dubbing-by-unit-second',
  SEPARATE_AIVOICE_PRODUCTS: 'separate-aivoice-products',
  NEW_DUBBING: 'new-dubbing',
  SHOW_PACKAGE_DURATION: 'show-package-duration',
  LIXI_2024: 'lixi-2024',
  MULTIPLE_INPUT_DUBBING: 'multiple-input-dubbing',
  DISCOUNT_FOR_FIRST_PAID_USER: 'discount-for-first-paid-user',
  GOOGLE_ONE_TAP: 'google-one-tap',
  VOICE_CLONING: 'voice-cloning',
  LIMIT_DOWNLOAD_FREE_PACKAGE: 'limit-download-free-package',
  NEW_DUBBING_UI: 'new-dubbing-ui',
  NEW_LIST_PACKAGE_UI: 'new-list-package-ui',
  STREAMING_PREVIEW: 'streaming-preview',
  USE_FREE_PACKAGE: 'use-free-package',
  NEW_AUTO_SELECT_VOUCHER: 'new-auto-select-voucher',
  HOME_PAGE: 'home-page',
  DUBBING_PACKAGE_GIFT: 'dubbing-package-gift',
  DUBBING_SUBTITLE_TIMELINE: 'dubbing-subtitle-timeline',
  NEW_UPLOAD: 'new-upload-file-link', // New upload file and new import link
  DUBBING_MULTIPLE_VOICES: 'dubbing-multiple-voices',
  NEW_TOURGUIDE_TTS: 'new-tourguide-tts', // New tourguide for TTS
  UPLOAD_FILE_PDF: 'upload-file-pdf', // Upload file pdf for TTS
  NEW_SUPPORT_UI: 'new-support-ui', // New UI support
  NEW_FOLOW_PAYMENT_UI: 'new-flow-payment-ui', // New flow payment UI
  AUTO_RENEW_MOMO: 'auto-renew-momo', // Use auto-renew with momo
  GIVEAWAY_TRIAL_DUBBING_PACKAGE: 'giveaway-trial-dubbing-package', // New flow add trial dubbing
  NEW_VOUCHER_BANNER: 'new-voucher-banner',
  DUBBING_PROJECTS: 'dubbing-projects',
  REDIRECT_VOUCHER_LINK: 'redirect-voucher-link',
  MULTIPLE_LANGUAGES_SRT: 'multiple-languages-srt',
  VIEW_VIDEO_WITH_TIMELINE: 'view-video-with-timeline',
  STT: 'stt',
  USE_PRESERVATION_EXPIRY_DATE: 'use-preservation-expiry-date',
  NEW_BANK_TRANSFER: 'new-bank-transfer',
  NEW_PREVIEW_BUTTON: 'new-preview-button',
  NEW_DOWNLOAD_BUTTON: 'new-download-button',
  NEW_TOOLBAR: 'new-toolbar',
  DATASENSES: 'datasenses',
  HEADER_BANNER_FLASH_SALE: 'header-banner-flash-sale',
  NEW_SSO_STUDIO: 'new-sso-studio',
  FILTER_BY_VOICE_LIBRARY: 'filter-by-voice-library',
  FAKE_END_TIME_VOUCHER: 'fake-time-voucher',
  NEW_LEGAL: 'new-legal',
  MIGRATE_FREE_PLAN: 'migrate-free-plan',
  NEW_NAVIGATION_DUBBING: 'new-navigation-dubbing',
  NEW_PROFILE_MOBILE: 'new-profile-mobile',
  NEW_SIDEBAR_MOBILE: 'new-sidebar-mobile',
  NEW_SIDEBAR_DESKTOP: 'new-sidebar-desktop',
  NEW_HEADER_MOBILE: 'new-header-mobile',
  DEFAULT_BANK: 'default-bank',
  SAMPLE_SCRIPTS: 'sample-scripts',
  VOUCHER_BY_PRODUCT: 'voucher-by-product',
  SYNTHESIS_VOICE_CLONING: 'synthesis-voice-cloning',
  NEW_WARNING_BUY_PACKAGE: 'new-warning-buy-package',
  ALLOW_CREATE_CLONING_VOICE: 'allow-create-cloning-voice',
  SHOW_INFO_VC_REQUEST: 'show-info-vc-request',
  TITLE_PRODUCT: 'title-product',
  MOBILE_UI: 'mobile-ui',
};
