import { styled } from '@mui/material/styles';
import { DialogContent, IconButton, Typography } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS, MOBILE_BREAKPOINT } from '@src/styles/config';

export const StyledRequests = styled('div')`
  padding: 10px 0;
  position: relative;

  .request-filter {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .filter-title {
    min-width: 300px;
  }

  .filter-status {
    min-width: 140px;
  }

  .request-wrapper {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
  }

  .select-request {
    margin-left: 5px;
    display: flex;
    gap: 10px;
    align-items: center;

    .delete-requests {
      color: ${COLOR.danger};
      font-weight: 600;
      cursor: pointer;
    }
  }

  .filter-item {
    display: flex;
    gap: 10px;
  }

  .text-field-request {
    background-color: ${COLOR.white};
    width: 100%;
    height: fit-content;
    border-radius: ${BORDER_RADIUS};

    .MuiInputLabel-root {
      font-size: 14px;
    }
  }

  .button-adjust {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid ${COLOR.light};
    padding: 4px 8px;
    border-radius: ${BORDER_RADIUS};
    cursor: pointer;
    position: relative;
  }

  .fields-filter {
    // position: absolute;
    // top: 28px;
    // right: 0px;
    // border: 1px solid ${COLOR.light};
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
    // background-color: ${COLOR.white};
    // padding: 8px;
    width: 40px;
    // border-radius: ${BORDER_RADIUS};

    .filter-item {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  .select-request-popper {
    position: fixed;
    z-index: 1;
    bottom: 10%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: ${COLOR.white};
    box-shadow: 0px 4px 15px rgba(44, 63, 88, 0.35);
    border-radius: ${BORDER_RADIUS};
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 480px;

    .button-group {
      display: flex;
      gap: 8px;
    }
  }

  .refresh-icon {
    padding: 0;
    margin-left: 5px;

    svg {
      font-size: 32px;
    }
  }

  .transition-btn {
    text-transform: unset;
    background-color: ${COLOR.dark};
    font-weight: 600;
    &:hover {
      background-color: ${COLOR.light};
    }
    img {
      margin-left: 12px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .request-wrapper {
      display: flex;
      align-items: center;
    }

    .request-filter {
      display: flex;
    }

    .audio-storage-note {
      flex: 1;
    }
  }

  @media only screen and (min-width: 480px) and (max-width: 1024px) {
    .request-wrapper {
      flex-direction: column;
      display: flex;
    }

    .request-filter {
      display: grid;
      grid-template-columns: repeat(2, minmax(200px, 470px));
    }

    .filter-title {
      min-width: 0px;
    }

    .filter-status {
      min-width: 0px;
    }
  }

  @media only screen and (max-width: 480px) {
    .request-wrapper {
      flex-direction: row;
    }

    .request-filter {
      display: flex;
      flex-direction: column;
    }

    .filter-title {
      min-width: 0px;
    }

    .filter-status {
      min-width: 0px;
    }
  }
`;

export const StyledRequestTable = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .action-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    block-size: fit-content;
    gap: 10px;
  }

  .download-wrapper {
    padding: 4px 6px;
    border-radius: 8px;
    color: ${COLOR.darkBlue};
    opacity: 0.75;
    font-size: 14px;
    &:hover {
      background: ${TRANSPARENT_COLOR.dark};
      color: ${COLOR.success};
      opacity: 1;
    }

    &.disabled {
      background: inherit;
      pointer-events: none;
      cursor: default;
      color: ${COLOR.disableButton};
    }
  }

  .text-action {
    font-size: 14px;
    color: ${COLOR.darkBlue};
    &:hover {
      color: ${COLOR.black};
    }
  }

  .download-link {
    color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
  }

  .in-progress-status {
    min-width: 100px;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(3px, 0px);
  }

  .linear-progress-wrapper {
    width: 100%;
    margin-right: 10px;
  }

  .large-screen-hidden {
    display: none;
  }

  .large-screen-show {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  @media only screen and (max-width: 1440px) {
    .large-screen-hidden {
      display: block;
    }

    .large-screen-show {
      display: none;
    }
  }
`;

export const StyledViewRequest = styled(DialogContent)`
  margin-bottom: 15px;

  .header {
    margin-top: 25px;
    background-color: ${COLOR.gray};
    color: ${COLOR.dark};
    padding: 10px;

    .header-title {
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .information-wrapper {
    display: flex;
    border: 1px solid ${COLOR.gray};
  }

  .information-column {
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 15px;
    .title {
      font-weight: 500;
    }
  }

  .request-content {
    border: 1px solid ${COLOR.gray};
    border-top: 0;
  }

  .view-paragraph-content {
    .content {
      color: ${COLOR.dark};
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
  }

  .request-id {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .information-wrapper {
      justify-content: space-between;
      padding: 24px 0px 24px 40px;
      gap: 20px;
    }

    .information-column {
      width: 50%;
      grid-template-columns: 120px auto;
      grid-column-gap: 15px;
    }

    .view-paragraph-content {
      padding: 24px 40px;
    }

    .mobile-view-sentence-content {
      display: none;
    }

    .web-view-sentence-content {
      padding: 16px 32px;
      .header-cell {
        text-transform: uppercase;
        color: ${COLOR.dark};
      }

      th,
      td {
        border-bottom: 1px solid ${COLOR.light};
      }

      td {
        padding: 24px 16px;
      }
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .information-wrapper {
      flex-direction: column;
      padding: 7px;
    }

    .information-column {
      grid-template-columns: 90px auto;
      font-size: 14px;
    }

    .view-paragraph-content {
      padding: 7px;
      font-size: 14px;

      .content {
        font-size: 14px;
      }
    }

    .web-view-sentence-content {
      display: none;
    }

    .mobile-view-sentence-content {
      padding: 7px;
    }

    .sentence-item {
      border-bottom: 1px solid ${COLOR.light};
      padding: 7px 0;
    }

    .row {
      display: grid;
      grid-template-columns: 90px auto;
      margin: 10px 0;
    }

    .sentence-title {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
    }

    .sentence-value {
      font-size: 15px;
      font-weight: 400;
    }

    .sentence-text {
      margin: 7px 0;
    }
  }
`;

export const StyledChip = styled(Typography)`
  background-color: ${({ color }) => TRANSPARENT_COLOR[color]};
  color: ${({ color }) => COLOR[color]};
  padding: 1px 10px;
  border-radius: 17px;
  font-size: 13px;
  font-weight: 500;
  width: 80px;
  text-align: center;
  white-space: nowrap;
`;

export const StyledErrorReport = styled('div')`
  .title {
    margin: 0 15px 15px 15px;
    font-weight: 500;
  }

  .dialog-action {
    margin: 0 15px 15px 0;
  }

  .information-column {
    display: grid;
    grid-template-columns: 120px auto;
    grid-column-gap: 15px;
    grid-auto-rows: min-content;
  }
`;

export const StyledTableTitle = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  table-layout: fixed;
  width: 100%;
  display: flex;
  gap: 4px;
  max-width: 395px;
  min-width: 380px;
  align-items: center;
  color: ${COLOR.darkBlue};
  font-weight: 550;

  .edit-title {
    outline: none;
    border: 0px;
    background-color: transparent;
    border-bottom: 1px solid ${COLOR.light};
    width: 80%;
    font-weight: 550;
    color: ${COLOR.darkBlue};
    font-size: 14px;
  }

  .text {
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 510;
    line-height: 24px;
  }

  .action {
    display: flex;
    gap: 4px;
  }
`;

export const StyledActionWarning = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledFieldFilter = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledIconButton = styled(IconButton)`
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '0px'};
  height: ${({ height }) => height || '10px'};
  border-radius: ${({ borderRadius }) => borderRadius || '100%'};
  background: ${({ focus }) => focus && TRANSPARENT_COLOR.dark};

  color: ${({ color }) => (color ? COLOR[color] : COLOR.darkBlue)};
  opacity: 0.75;
  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    opacity: 1;
    .icon {
      color: ${({ colorHover }) => COLOR[colorHover]};
    }
  }

  .text-action {
    font-size: 14px;
    color: ${COLOR.darkBlue};
    &:hover {
      color: ${COLOR.black};
    }
  }
`;

export const StyleVoiceItem = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;

  .voice-style {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px;
    opacity: 0.64;
  }
`;

export const StyledTextIcon = styled(IconButton)`
  width: 100%;
  padding: 6px 4px;
  color: ${COLOR.darkBlue};
  border-radius: ${BORDER_RADIUS};
  opacity: 0.75;
  display: flex;
  justify-content: start;
  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    color: ${({ colorHover }) => COLOR[colorHover]};
    opacity: 1;
  }
  .text-action {
    color: ${COLOR.darkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
    &:hover {
      color: ${COLOR.black};
    }
  }
`;

export const StyledMenu = styled('div')`
  margin: 8px;
`;
