import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { delimitNumber } from '@src/utils/number';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { formatAudioTime } from '@src/utils/time';
import { CANCEL_ORDER_TIMER } from '@src/constants/order';
import { TIMER_INTERVAL } from '@src/constants';
import { StyledPaymentDialog } from './index.style';

const PaymentDialog = ({ open, payment, onClose, onCancelPayment }) => {
  const [validTime, setValidTime] = useState(
    formatAudioTime(CANCEL_ORDER_TIMER.ONLINE_GATEWAY),
  );
  const [paymentMethodName, setPaymentMethodName] = useState('');

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const getPaymentMethodName = (name) =>
    (language === 'vi' ? name?.vi : name?.en) || '';

  useEffect(() => {
    if (payment?.name) {
      const name = getPaymentMethodName(payment.name);
      setPaymentMethodName(name);
    }
  }, [payment]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (payment?.createdAt) {
      const timerInterval = setInterval(() => {
        const diffTime = moment().diff(moment(payment.createdAt), 'seconds');
        if (diffTime < CANCEL_ORDER_TIMER.ONLINE_GATEWAY) {
          const remainingSecond = CANCEL_ORDER_TIMER.ONLINE_GATEWAY - diffTime;
          setValidTime(formatAudioTime(remainingSecond));
        } else {
          onClose();
          onCancelPayment();
        }
      }, TIMER_INTERVAL);
      return () => clearInterval(timerInterval);
    }
  }, [payment]);

  return (
    <StyledPaymentDialog open={open}>
      <div className="dialog-web">
        <DialogTitle classes={{ root: 'dialog-title' }}>
          <div className="title-wrapper">
            <img src={payment?.icon} alt="icon" className="payment-icon" />
            <Typography className="title">
              {t('paymentBy', { paymentMethod: paymentMethodName })}
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={onClose}
            color="secondary"
            className="close-button"
          >
            <Close color="secondary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-content">
            <div>
              <div className="payment-method-qr">
                <img
                  src={payment?.paymentLink}
                  alt="icon"
                  className="payment-method-qr-img"
                />
              </div>
            </div>
            <div className="content">
              <Typography className="title">{t('scanQr')}</Typography>
              <div
                className="description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('onlinePaymentNote', {
                    paymentMethod: paymentMethodName,
                  }),
                }}
              />
            </div>
          </div>
          <div className="footer">
            <Typography className="total-money">
              {t('totalMoney')}:{' '}
              <b>
                {delimitNumber(payment?.price, language)} {t('đ')}
              </b>
            </Typography>
            <Typography className="time">
              {t('tradingTimeRemaining')}:{' '}
              <span className="clock">{validTime}</span>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <Button variant="outlined" color="primary" onClick={onCancelPayment}>
            {t('cancelOrder')}
          </Button>
        </DialogActions>
      </div>
      <div className="dialog-mobile">
        <DialogTitle classes={{ root: 'dialog-title' }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            color="secondary"
            className="close-button"
          >
            <Close color="secondary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-content">
            <div className="title-wrapper">
              <img src={payment?.icon} alt="icon" className="payment-icon" />
              <Typography className="title">{t('scanQr')}</Typography>
            </div>
            <div>
              <div className="payment-method-qr">
                <img
                  src={payment?.paymentLink}
                  alt="icon"
                  className="payment-method-qr-img"
                />
              </div>
            </div>
            <div className="money">
              <Typography className="total-money">
                {t('totalMoney')}:{' '}
                <b>
                  {delimitNumber(payment?.price, language)} {t('đ')}
                </b>
              </Typography>
            </div>
            <div className="content">
              <div
                className="description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('onlinePaymentNote', {
                    paymentMethod: paymentMethodName,
                  }),
                }}
              />
            </div>
            <Typography className="time">
              {t('tradingTimeRemaining')}:{' '}
              <span className="clock">{validTime}</span>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <Button variant="outlined" color="primary" onClick={onCancelPayment}>
            {t('cancelOrder')}
          </Button>
        </DialogActions>
      </div>
    </StyledPaymentDialog>
  );
};

export default PaymentDialog;
