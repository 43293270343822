import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCustomFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
  bottom: 0;
  background-color: ${({ isSticky }) =>
    isSticky ? COLOR.white : 'transparent'};
  z-index: 1;

  .footer-label {
    font-weight: 600;
    font-size: 12px;
    color: ${COLOR.black};
  }
`;
