export const DUB_FILE_FORMAT = ['srt'];

export const DUBBING_ERROR = {
  EMPTY_TITLE: 'textTitleError',
};

export const DUBBING_LENGTH_LIMIT = 4; // hours

export const SUBTITLE_SAMPLE_LINK =
  'https://vbee.s3.ap-southeast-1.amazonaws.com/subtitles/sample_file.srt';

export const MAX_FILE_NAME_LENGTH = 50;

export const SUBTITLES_S3_DIRECTORY = 'subtitles';

export const MAX_SENTENCE_LENGTH = 500;

export const ERROR_TYPES = {
  INVALID_SRT_FORMAT: 'invalidSrtFormat',
  INVALID_TIME_DELTA_FORMAT: 'invalidTimeDeltaFormat',
  START_TIME_GREATER_THAN_END_TIME: 'startTimeGreaterThanEndTime',
  AUDIO_TIMESTAMPS_NOT_IN_ORDER: 'audioTimestampsNotInOrder',
};

export const ERROR_SEPARATOR = '<br/>----------------<br/>';

export const DUBBING_VIDEO_SOURCE = {
  YOUTUBE: 'youtube',
  LOCAL: 'local',
};

export const INPUT_FILE_TYPES = {
  SRT: 'srt',
  YOUTUBE: 'youtube',
  VIDEO: 'video',
  MP4: 'mp4',
};

export const PROJECT_STATUS = {
  DRAFT: 'DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const REGEX_YOUTUBE_LINK =
  /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%]{11})/;

export const SRT_FRANC_LANGUAGE_CODE = {
  cmn: 'zh-CN',
  spa: 'es-ES',
  eng: 'en-US',
  rus: 'ru-RU',
  arb: 'ar-SA',
  ben: 'bn-IN',
  hin: 'hi-IN',
  por: 'pt-BR',
  ind: 'id-ID',
  jpn: 'ja-JP',
  fra: 'fr-FR',
  deu: 'de-DE',
  kor: 'ko-KR',
  tel: 'te-IN',
  vie: 'vi-VN',
  mar: 'mr-IN',
  ita: 'it-IT',
  tam: 'ta-IN',
  tur: 'tr-TR',
  urd: 'ur-PK',
  pol: 'pl-PL',
  ukr: 'uk-UA',
  mal: 'ml-IN',
  tha: 'th-TH',
};
