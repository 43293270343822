import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton } from '@mui/material';

import CustomDrawer from '@src/components/Drawer';
import { SPEED } from '@src/constants/voice';

import { StyledSpeedDrawer } from './index.styled';

const SpeedDrawer = ({ open, onClose, onChange }) => {
  const { t } = useTranslation();

  const handleSelectedValue = (value) => {
    if (onChange) onChange('speed', value);
    onClose();
  };

  return (
    // Your JSX code here
    <CustomDrawer
      title={t('speed')}
      anchor="bottom"
      open={open}
      minWidth="200px"
      maxWidthContent="100%"
      titleFontSize="16px"
      titleFontStyle="normal"
      onClose={onClose}
    >
      <StyledSpeedDrawer>
        <List>
          {SPEED.map((item) => (
            <ListItem key={item.value} className="list-item">
              <ListItemButton
                onClick={() => {
                  handleSelectedValue(item.value);
                }}
              >
                <div className="show-speed">
                  <div className="speed-value">{item.value}x</div>
                  <div className="speed-title">{t(item.title)}</div>
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </StyledSpeedDrawer>
    </CustomDrawer>
  );
};

export default SpeedDrawer;
