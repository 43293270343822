const PAYMENT_METHOD_TYPE = {
  BANK: 'BANK',
  ATM: 'ATM',
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  SHOPEEPAY: 'SHOPEEPAY',
  ADMIN: 'ADMIN',
  MOMOPAY: 'MOMOPAY',
  VNPAYQR: 'VNPAYQR',
};

const PAYMENT_STEP = {
  CHOOSE_PACKAGE: 0,
  PAYMENT_METHOD: 1,
  PAYMENT: 2,
  PENDING_PAYMENT: 3,
  COMPLETED: 4,
};

const GLOBAL_PAYMENT_METHOD = ['BANK', 'PAYPAL', '2CHECKOUT'];

const PRICE_UNIT = { VND: 'vnd', USD: '$' };

export { PAYMENT_METHOD_TYPE, PAYMENT_STEP, GLOBAL_PAYMENT_METHOD, PRICE_UNIT };

export const BANK_TEMPLATE_QR_CODE = {
  VIETCOMBANK: '5zjS8O9',
  TECHCOMBANK: '57t2y5D',
};

export const CHANGE_QR_NOTE = 'CHANGE_QR';

export const DEFAULT_BANK = {
  VIETCOMBANK: 'Vietcombank',
  TECHCOMBANK: 'Techcombank',
  DYNAMIC: 'Dynamic',
};
