import React from 'react';
import { CardMedia, Dialog } from '@mui/material';
import { StyledVideoGuideDialog } from './index.style';

const VideoGuideDialog = ({ open, video, onClose }) => (
  <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
    <StyledVideoGuideDialog>
      <div className="video">
        <CardMedia
          component="iframe"
          autoPlay
          className="video"
          image={video?.videoLink}
          alt="Service manual video"
        />
      </div>
    </StyledVideoGuideDialog>
  </Dialog>
);

export default VideoGuideDialog;
