import React, { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { Box } from '@mui/material';
import { COLOR } from '@src/styles/color';

const SwiperPagination = ({ totalSlides }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const swiper = useSwiper();

  useEffect(() => {
    swiper.on('slideChange', () => {
      setActiveIndex(swiper.realIndex);
    });

    return () => swiper.off('slideChange');
  }, [swiper]);

  return (
    <Box display="flex" justifyContent="center" gap={1}>
      {Array.from({ length: totalSlides }).map((_, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          height={8}
          borderRadius="24px"
          bgcolor={activeIndex === index ? COLOR.white : COLOR.black[16]}
          width={activeIndex === index ? 40 : 8}
        />
      ))}
    </Box>
  );
};

export default SwiperPagination;
