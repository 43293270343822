import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Card from '@src/components/Card';
import VerifyIcon from '@src/assets/icons/verify.svg';
import { PAGINATION_LIMIT } from '@src/constants';
import {
  AUTO_RENEW_STATUS,
  SUBSCRIPTION_PURCHASE_STATUS,
} from '@src/constants/subscription';
import { COLOR } from '@src/styles/color';
import { BREAKPOINTS } from '@src/styles/config';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

import { StyledPaymentMethod } from './index.style';
import PaymentMethodItem from './PaymentMethodItem';
import PaymentAccount from './PaymentAccount';

const PaymentMethod = ({
  subscriptionPurchase = {},
  loading,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BREAKPOINTS.SMD));
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = user;

  const useAutoRenewWithMomo = getFeatureValue(FEATURE_KEYS.AUTO_RENEW_MOMO, {
    userId,
    email,
    phoneNumber,
  });

  const isAutoRenewYearPackage = useMemo(() => {
    const { basePlanId } = subscriptionPurchase;
    return basePlanId?.includes('AUTO-RENEW') && basePlanId?.includes('YEAR');
  }, [subscriptionPurchase]);

  const usingPaymentMethod = useMemo(
    () =>
      paymentMethods.find(
        (method) =>
          method.id ===
          subscriptionPurchase?.paymentAccounts?.[0]?.paymentMethodId,
      ),
    [paymentMethods, subscriptionPurchase],
  );

  const isSubscriptionActive =
    subscriptionPurchase?.status === SUBSCRIPTION_PURCHASE_STATUS.ACTIVE;

  const handleSelectPaymentMethod = (method) => {
    setSelectedPaymentMethod(method.type);
  };

  const renderPaymentMethods = ({ isLoading }) => (
    <>
      <Typography className="text-content-bold">
        {t('choosePaymentMethod')}
      </Typography>
      {isLoading ? (
        <Grid container spacing={1}>
          {Array.from(new Array(PAGINATION_LIMIT)).map(() => (
            <Grid item xs={6} sm={isMobile && 4} lg={4} xl={4} key={uuidv4()}>
              <Skeleton
                animation="wave"
                width="100%"
                height={90}
                sx={{ bgcolor: COLOR.grayV2[100] }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={1}>
          {paymentMethods.map((method) => (
            <Grid
              item
              xs={6}
              sm={isMobile && 4}
              lg={4}
              xl={4}
              key={method.name}
            >
              <PaymentMethodItem
                method={method}
                isAutoRenewYearPackage={isAutoRenewYearPackage}
                useAutoRenewWithMomo={useAutoRenewWithMomo}
                selected={
                  selectedPaymentMethod?.toLowerCase() ===
                  method.type?.toLowerCase()
                }
                disabled={
                  method?.autoRenewStatus === AUTO_RENEW_STATUS.UPCOMING
                }
                onSelect={handleSelectPaymentMethod}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );

  const renderPaymentAccount = () => (
    <Box display="flex" flexDirection="column" gap="8px">
      <Typography className="text-content-bold">
        {t('paymentAccount')}
      </Typography>
      <PaymentAccount
        isActive
        method={usingPaymentMethod}
        paymentAccount={subscriptionPurchase?.paymentAccounts[0]}
      />
    </Box>
  );

  return (
    <StyledPaymentMethod>
      <Card className="card">
        <Typography className="title">{t('paymentMethod')}</Typography>
        <Divider />
        <Box display="flex" flexDirection="column" gap="8px">
          <Box className="content">
            <img src={VerifyIcon} alt="Verify icon" className="verify-icon" />
            <Typography className="text-content">
              {t('percentDiscountAutoRenew', {
                percent: 20,
              })}
            </Typography>
          </Box>
          <Box className="content">
            <img src={VerifyIcon} alt="Verify icon" className="verify-icon" />
            <Typography className="text-content">
              {t('cancelAutoRenewAnytime')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {isSubscriptionActive
          ? renderPaymentAccount()
          : renderPaymentMethods({ isLoading: loading })}
      </Card>
    </StyledPaymentMethod>
  );
};

export default PaymentMethod;
