import { actionTypes } from './actions';

export const initialState = {
  languages: [],
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGES_SUCCESS: {
      const { languages } = action;
      return { ...state, languages };
    }

    default:
      return state;
  }
};

export default languageReducer;
