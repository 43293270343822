import { useEffect, useState } from 'react';

const useElementHeight = (elementRef) => {
  // Initialize the height state with the initial height of the element
  const [height, setHeight] = useState(elementRef?.current?.offsetHeight);

  useEffect(() => {
    // Create a ResizeObserver to observe changes in the height of the element
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.target === elementRef.current) {
          // Check if the height has changed and update the state
          if (entry.contentRect.height !== height) {
            setHeight(entry.contentRect.height);
          }
        }
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, [elementRef, height]);

  return height; // Return the current height of the element
};

export default useElementHeight;
