import { actionTypes } from './actions';

export const initialState = {
  audioLink: '',
  selectedAudioRequest: '',
  isPlaying: '',
  sentenceId: '',
  isAudioLoading: '',
  currentTime: 0,
  duration: 0,
  requestAudioLink: '',
  sentenceAudioLink: '',
  isPreview: false,
  previewRequestId: '',
  audioType: '',
  isRunStreamAudio: false,
  streamAudios: [],
  finalStreamAudio: '',
  ttsVersion: '1.0',
  showAudioPlayer: false,
  previewRequestInfo: {},
  volume: 1,
};

const audioPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_AUDIO_PLAYER: {
      return initialState;
    }

    case actionTypes.UPDATE_AUDIO_LINK: {
      const { audioLink, isPreview } = action;
      return { ...state, audioLink, isPreview };
    }
    case actionTypes.UPDATE_SELECTED_AUDIO_REQUEST: {
      const { requestId } = action;
      return { ...state, selectedAudioRequest: requestId };
    }

    case actionTypes.UPDATE_STATUS: {
      const { isPlaying } = action;
      return { ...state, isPlaying };
    }

    case actionTypes.UPDATE_META_DATA: {
      const { currentTime, duration } = action;

      const newState = {
        ...state,
        currentTime:
          typeof currentTime === 'number' ? currentTime : state.currentTime,
        duration: typeof duration === 'number' ? duration : state.duration,
      };
      return newState;
    }

    case actionTypes.UPDATE_TRY_LISTENING_SENTENCE: {
      const { sentenceId, isAudioLoading, audioLink } = action;

      const newState = {
        ...state,
        sentenceId: sentenceId || state.sentenceId,
        isAudioLoading:
          isAudioLoading !== 'undefined'
            ? isAudioLoading
            : state.isAudioLoading,
        sentenceAudioLink:
          audioLink !== 'undefined' ? audioLink : state.audioLink,
      };
      return newState;
    }

    case actionTypes.UPDATE_REQUEST_AUDIO_LINK: {
      const { audioLink } = action;

      return { ...state, requestAudioLink: audioLink };
    }

    case actionTypes.UPDATE_REQUEST_PREVIEW_ID: {
      const { requestPreviewId } = action;

      return { ...state, requestPreviewId };
    }

    case actionTypes.UPDATE_AUDIO_TYPE: {
      const { audioType } = action;

      return { ...state, audioType };
    }

    case actionTypes.UPDATE_STREAM_AUDIOS: {
      const { streamAudio } = action;
      const updatedStreamAudios = [...state.streamAudios, streamAudio];
      return { ...state, streamAudios: updatedStreamAudios };
    }

    case actionTypes.UPDATE_FINAL_STREAM_AUDIO: {
      const { streamAudio } = action;
      return { ...state, finalStreamAudio: streamAudio };
    }

    case actionTypes.INIT_STREAM_AUDIOS: {
      const { ttsVersion } = action;
      return { ...state, streamAudios: [], ttsVersion };
    }

    case actionTypes.UPDATE_STATUS_STREAM_AUDIO: {
      return { ...state, isRunStreamAudio: action.isRunStreamAudio };
    }

    case actionTypes.UPDATE_SHOW_AUDIO_PLAYER: {
      return { ...state, showAudioPlayer: action.status };
    }

    case actionTypes.UPDATE_PREVIEW_REQUEST_INFO: {
      return { ...state, previewRequestInfo: action.request };
    }

    case actionTypes.UPDATE_VOLUME: {
      return { ...state, volume: action.volume };
    }

    default:
      return state;
  }
};

export default audioPlayerReducer;
