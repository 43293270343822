import React from 'react';

import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';

import Packages from '../Packages';

import { StyledBuyPackage } from './index.style';
import NewPackages from '../PackagesNew';

const BuyPackage = () => {
  const { getFeatureValue } = useFeatureFlags();
  const showNewPackages = getFeatureValue(FEATURE_KEYS.NEW_PAYMENT_PACKAGES);

  return (
    <StyledBuyPackage>
      {showNewPackages ? <NewPackages /> : <Packages />}
    </StyledBuyPackage>
  );
};

export default BuyPackage;
