/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BadgeAvatar from '@src/components/BadgeAvatar';
import { IconButton } from '@mui/material';
import { Forward5Rounded, Replay5Rounded } from '@mui/icons-material';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import { REQUEST_STATUS } from '@src/constants/voice';
import { COLOR } from '@src/styles/color';

import { StyledAudioPlayer } from './index.styled';
import SpeedDrawer from './SpeedDrawer';
import VoiceDrawer from './VoiceDrawer';
import Audio from '../AudioPlayer/Audio';
import PlayButton from './PlayButton';

const REWIND_TIME_IN_SECOND = 5;

const BUTTON_STATUS = {
  LOADING: 'loading',
  PROCESSED: 'processed',
  PLAYING: 'playing',
  PAUSE: 'pause',
  CREATE: 'create',
};

const AudioPlayer = ({
  audioRef,
  voice,
  speed,
  enableEditor,
  onEnableEditor,
  handleChangeVoice,
  onCreateSynthesis,
}) => {
  const [buttonStatus, setButtonStatus] = useState(BUTTON_STATUS.CREATE);

  const { synthesisRequest, advanceFeature } = useSelector(
    (state) => state.synthesisRequest,
  );

  const {
    audioLink,
    selectedAudioRequest,
    isPlaying,
    duration,
    currentTime,
    isRunStreamAudio,
    finalStreamAudio,
  } = useSelector((state) => state.audioPlayer);

  const dispatch = useDispatch();

  const [openSpeedDrawer, setOpenSpeedDrawer] = useState(false);
  const [openVoiceDrawer, setOpenVoiceDrawer] = useState(false);
  const [newPlayAudioLink, setNewPlayAudioLink] = useState(audioLink);

  const handleOpenSpeedDrawer = () => setOpenSpeedDrawer(true);

  const handleCloseSpeedDrawer = () => setOpenSpeedDrawer(false);

  const handleOpenVoiceDrawer = () => setOpenVoiceDrawer(true);

  const handleCloseVoiceDrawer = () => setOpenVoiceDrawer(false);
  const [audioEnd, setAudioEnd] = useState(false);

  const handleLoadedMetadata = () => {
    const audioDuration = audioRef.current.duration;
    dispatch(actions.audioPlayer.updateMetaData({ duration: audioDuration }));
  };

  const handleAudioTimeUpdate = () => {
    const url = audioRef.current.src ? new URL(audioRef.current.src) : '';
    const time =
      url?.pathname === SILENCE_AUDIO_URL ? 0 : audioRef.current.currentTime;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleAudioEnd = () => {
    if (audioEnd) {
      audioRef.current.pause();
      dispatch(actions.audioPlayer.updateStatus(false));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      setAudioEnd(false);
      return;
    }

    if (newPlayAudioLink) {
      setAudioEnd(true);
      audioRef.current.src = newPlayAudioLink;
      if (!finalStreamAudio && isRunStreamAudio) {
        audioRef.current.load();
        audioRef.current.play();
        return;
      }
      audioRef.current.pause();
      dispatch(actions.audioPlayer.updateStatus(false));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      return;
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const handleSeeked = (time) => {
    audioRef.current.currentTime = time;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleGetPresignedAudioUrl = async (keyId) => {
    try {
      const res = await apis.requests.getPresignedAudioUrl(keyId);
      if (res?.status) {
        const audioUrl = res.result?.audio;
        setNewPlayAudioLink(audioUrl);
        return;
      }
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'downloadError',
        }),
      );
      setNewPlayAudioLink(audioLink);
      return;
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'downloadError',
        }),
      );
      setNewPlayAudioLink(audioLink);
    }
  };

  const handlePlayAudio = () => {
    const newAudioLink = synthesisRequest.audioLink;
    if (newPlayAudioLink === newAudioLink) {
      dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    } else {
      // eslint-disable-next-line no-param-reassign
      audioRef.current.src = newAudioLink;
      audioRef.current.load();
      audioRef.current.play();
      dispatch(actions.audioPlayer.updateAudioLink(newAudioLink));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      dispatch(actions.audioPlayer.updateShowAudioPlayer(true));
    }
  };

  const handleChangePlayAudio = () => {
    dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    setAudioEnd(true);
  };

  const handleClickPlayButton = () => {
    switch (buttonStatus) {
      case BUTTON_STATUS.CREATE: {
        onCreateSynthesis();
        break;
      }

      case BUTTON_STATUS.PLAYING: {
        handleChangePlayAudio();
        break;
      }
      case BUTTON_STATUS.LOADING:
      case BUTTON_STATUS.PAUSE:
        break;

      default:
        break;
    }
  };

  const updateRequestStatus = () => {
    if (!enableEditor) {
      onEnableEditor(true);
      dispatch(actions.synthesisRequest.cloneSynthesisRequest());
    }
  };

  const handleChangeSynthesisRequest = (name, value) => {
    dispatch(actions.synthesisRequest.updateSynthesisRequest(name, value));
    updateRequestStatus();
  };

  const handleChangeSpeed = (name, value) => {
    handleChangeSynthesisRequest(name, value);
    updateRequestStatus();
  };

  useEffect(() => {
    if (selectedAudioRequest) {
      handleGetPresignedAudioUrl(selectedAudioRequest);
    } else {
      setNewPlayAudioLink(audioLink);
    }
  }, [audioLink]);

  useEffect(() => {
    if (!synthesisRequest.status) setButtonStatus(BUTTON_STATUS.CREATE);

    if (synthesisRequest.status === REQUEST_STATUS.IN_PROGRESS)
      setButtonStatus(BUTTON_STATUS.LOADING);
    else if (synthesisRequest.status === REQUEST_STATUS.SUCCESS) {
      setButtonStatus(BUTTON_STATUS.PLAYING);
      handlePlayAudio();
    }
  }, [synthesisRequest.processingAt, synthesisRequest.status]);

  useEffect(() => {
    audioRef.current.load();
    audioRef.current.currentTime = currentTime;

    if (isPlaying) {
      audioRef.current.play().catch((error) => {
        // eslint-disable-next-line no-console
        console.log(`Error when play audio ${error}`);
        const ERROR_DUE_TO_EXPIRED_AUDIO_LINK = 'NotSupportedError';
        if (error.name === ERROR_DUE_TO_EXPIRED_AUDIO_LINK) {
          handleGetPresignedAudioUrl(selectedAudioRequest);
        }
      });
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <StyledAudioPlayer>
      <Audio
        currentTime={currentTime}
        duration={duration}
        onSeeked={handleSeeked}
        disabled={!newPlayAudioLink}
        hiddenDuration
        color={COLOR.darkBlue}
        hightThumb="12px"
        widthThumb="12px"
      />
      <div className="audio-info">
        <div
          className="voice"
          tabIndex="0"
          role="button"
          onClick={handleOpenVoiceDrawer}
        >
          <BadgeAvatar
            width={40}
            smallImgWidth={10}
            img={voice && voice.roundImage}
            smallImg={voice && voice.language && voice.language.roundImage}
            type="image"
          />
        </div>

        <div className="control-audio">
          <div>
            <IconButton
              onClick={() => {
                const time = currentTime - REWIND_TIME_IN_SECOND;
                handleSeeked(time >= 0 ? time : 0);
              }}
              className="rewind-button"
              color="iconPrimary"
            >
              <Replay5Rounded sx={{ fontSize: 26 }} />
            </IconButton>
          </div>

          <PlayButton
            buttonStatus={buttonStatus}
            request={synthesisRequest}
            isPlaying={isPlaying}
            onClickPlayButton={handleClickPlayButton}
          />

          <div>
            <IconButton
              onClick={() => handleSeeked(currentTime + REWIND_TIME_IN_SECOND)}
              disabled={currentTime > duration}
              className="rewind-button"
              color="iconPrimary"
            >
              <Forward5Rounded sx={{ fontSize: 26 }} />
            </IconButton>
          </div>
        </div>
        <div
          onClick={handleOpenSpeedDrawer}
          className="speed"
          role="button"
          tabIndex={0}
        >
          {speed}x
        </div>
      </div>

      <SpeedDrawer
        open={openSpeedDrawer}
        onOpen={handleOpenSpeedDrawer}
        onClose={handleCloseSpeedDrawer}
        onChange={
          advanceFeature ? handleChangeSpeed : handleChangeSynthesisRequest
        }
      />
      <VoiceDrawer
        open={openVoiceDrawer}
        onOpen={handleOpenVoiceDrawer}
        onClose={handleCloseVoiceDrawer}
        onChangeVoice={handleChangeVoice}
      />

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        autoPlay={isPlaying}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleAudioTimeUpdate}
        onEnded={handleAudioEnd}
      >
        <source src={newPlayAudioLink} type="audio/ogg" />
      </audio>
    </StyledAudioPlayer>
  );
};

export default AudioPlayer;
