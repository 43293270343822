import { actionTypes } from './actions';

export const initialState = {
  openSidebar: true,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HANDLE_CHANGE_OPEN_SIDEBAR:
      return { ...state, openSidebar: action.openSidebar };

    default:
      return state;
  }
};

export default layoutReducer;
