import React from 'react';
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import {
  BackButton,
  CloseButton,
  NextButton,
  StyledTooltipBody,
  StyledTooltipContent,
  StyledTooltipFooter,
  StyledTooltipTitle,
} from './index.style';

const Tooltip = ({
  continuous,
  step,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
}) => (
  <StyledTooltipBody {...tooltipProps}>
    {step.title && (
      <StyledTooltipTitle>
        <Typography>{step.title}</Typography>
        <CloseButton {...skipProps} aria-label="close">
          <Close color="divider" />
        </CloseButton>
      </StyledTooltipTitle>
    )}

    {step.content && (
      <StyledTooltipContent>{step.content}</StyledTooltipContent>
    )}

    <StyledTooltipFooter>
      {/* Render DotsStep component  */}
      {step.locale?.dotsStep && step.locale.dotsStep}
      <div className="buttons-right">
        {step.locale.back && (
          <BackButton aria-label="back" {...backProps}>
            {step.locale.back}
          </BackButton>
        )}
        {continuous && (
          <NextButton aria-label="next" {...primaryProps}>
            {step.locale.next}
          </NextButton>
        )}
      </div>
    </StyledTooltipFooter>
  </StyledTooltipBody>
);

export default Tooltip;
