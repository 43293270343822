/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseRounded,
  FileDownloadOutlined,
  FlagRounded,
  VolumeUpRounded,
  VolumeMuteRounded,
  MoreHorizRounded,
  VolumeOff,
} from '@mui/icons-material';
import { IconButton, Box } from '@mui/material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { downloadFileUsingUrl } from '@src/utils/file';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import {
  DOWNLOAD_QUOTA_EXCEEDED_CODE,
  SILENCE_AUDIO_URL,
} from '@src/constants/tts';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import dataSenses from '@src/services/dataSenses';
import ErrorReport from '../TTSNew/Requests/ErrorReport';
import ActionPopper from './ActionPopper';
import { StyledVolume, StyledSlider } from './index.style';
import { StyledSidebarTooltip } from '../Layout/SidebarNew/index.style';

const VALID_VOLUME = { MIN: 0, MAX: 100 };

const renderActions = ({ audioRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [volume, setVolume] = useState(100);
  const [moreButtonAnchorEl, setMoreButtonAnchorEl] = useState(null);

  const { getFeatureValue } = useFeatureFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { audioLink, previewRequestInfo } = useSelector(
    (state) => state.audioPlayer,
  );
  const {
    id: requestId,
    title,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    userId,
    type,
  } = previewRequestInfo;

  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const handleCloseAudioPlayer = () => {
    dispatch(actions.audioPlayer.updateShowAudioPlayer(false));
    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.pause();
    dispatch(actions.audioPlayer.updateAudioLink(''));
    dispatch(actions.audioPlayer.updateSelectedAudioRequest(''));
    dispatch(actions.audioPlayer.updateStatus(false));
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
    dispatch(actions.audioPlayer.updateStatusStreamAudio(false));
  };

  const handleDownloadAudio = async () => {
    dataSenses.sendDownloadAudioEvent({
      userId,
      requestType: type,
    });

    const res = isLimitDownloadFreePackage
      ? await apis.requests.getAudioDownloadUrl(requestId)
      : await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      return;
    }
    if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const handleOpenErrorReport = () => setOpenErrorReport(true);
  const handleCloseErrorReport = () => setOpenErrorReport(false);

  const handleOpenChangeVolume = (e) => setAnchorEl(e.currentTarget);
  const handleCloseChangeVolume = () => setAnchorEl(null);
  const handleChangeVolume = (value) => {
    setVolume(value);
    audioRef.current.volume = value / 100;
  };

  const handleOpenMoreButtonAnchorEl = (e) =>
    setMoreButtonAnchorEl(e.currentTarget);
  const handleCloseMoreButtonAnchorEl = () => setMoreButtonAnchorEl(null);

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );
  const showDownloadAudio =
    previewRequestInfo?.id && audioLink && !audioExpired;
  const showErrorReport = previewRequestInfo?.id;
  const showChangeVolume = audioLink;
  const showMoreButton = showDownloadAudio || showErrorReport;

  return (
    <Box display="flex" justifyContent="flex-end" width="160px">
      <Box className="action" display={{ md: 'flex', xs: 'none' }}>
        {showDownloadAudio && (
          <StyledSidebarTooltip title={t('download')} placement="top">
            <div>
              <IconButton onClick={handleDownloadAudio}>
                <FileDownloadOutlined
                  sx={{ fontSize: 24 }}
                  color="iconPrimary"
                />
              </IconButton>
            </div>
          </StyledSidebarTooltip>
        )}
        {showChangeVolume && (
          <StyledSidebarTooltip title={t('volume')} placement="top">
            <div>
              <IconButton
                onClick={handleOpenChangeVolume}
                className={!!anchorEl && 'button-active'}
              >
                {volume ? (
                  <VolumeUpRounded sx={{ fontSize: 24 }} color="iconPrimary" />
                ) : (
                  <VolumeOff sx={{ fontSize: 24 }} color="iconPrimary" />
                )}
              </IconButton>
            </div>
          </StyledSidebarTooltip>
        )}
        {showErrorReport && (
          <StyledSidebarTooltip title={t('errorReport')} placement="top">
            <div>
              <IconButton
                onClick={handleOpenErrorReport}
                colorHover="error"
                height="30px"
                width="30px"
                padding="12px"
              >
                <FlagRounded sx={{ fontSize: 24 }} color="iconPrimary" />
              </IconButton>
            </div>
          </StyledSidebarTooltip>
        )}
      </Box>
      {showMoreButton && (
        <Box className="action" display={{ md: 'none', xs: 'flex' }}>
          <StyledSidebarTooltip title={t('showMore')} placement="top">
            <div>
              <IconButton onClick={handleOpenMoreButtonAnchorEl}>
                <MoreHorizRounded sx={{ fontSize: 24 }} color="iconPrimary" />
              </IconButton>
            </div>
          </StyledSidebarTooltip>
        </Box>
      )}
      <StyledSidebarTooltip title={t('close')} placement="top">
        <div>
          <IconButton onClick={handleCloseAudioPlayer}>
            <CloseRounded sx={{ fontSize: 24 }} color="iconPrimary" />
          </IconButton>
        </div>
      </StyledSidebarTooltip>

      <ErrorReport
        open={openErrorReport}
        request={previewRequestInfo}
        onClose={handleCloseErrorReport}
      />

      <StyledVolume
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseChangeVolume}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className="volume-wrapper">
          <IconButton onClick={() => handleChangeVolume(VALID_VOLUME.MAX)}>
            <VolumeUpRounded sx={{ fontSize: 24 }} color="iconPrimary" />
          </IconButton>
          <StyledSlider
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical',
              },
            }}
            orientation="vertical"
            value={volume}
            min={VALID_VOLUME.MIN}
            max={VALID_VOLUME.MAX}
            step={1}
            onChange={(e, value) => handleChangeVolume(value)}
          />
          <IconButton onClick={() => handleChangeVolume(VALID_VOLUME.MIN)}>
            <VolumeMuteRounded sx={{ fontSize: 24 }} color="iconPrimary" />
          </IconButton>
        </div>
      </StyledVolume>
      <ActionPopper
        anchorEl={moreButtonAnchorEl}
        showDownloadAudio={showDownloadAudio}
        showErrorReport={showErrorReport}
        onClose={handleCloseMoreButtonAnchorEl}
        onDownloadAudio={handleDownloadAudio}
        onHandleErrorReport={handleOpenErrorReport}
      />
    </Box>
  );
};

export default renderActions;
