import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import actions from '@src/redux/actions';
import { Box, Button, Skeleton } from '@mui/material';
import NotificationDialog from '@src/components/NotificationDialog';
import CustomFooter from '@src/components/CustomFooter';
import { PAGINATION_LIMIT } from '@src/constants';
import useElementHeight from '@src/hooks/useElementHeight';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  StyledSentences,
  StyledActionWarning,
  StyledSentence,
} from './index.style';
import SentencesHeader from './SentencesHeader';
import Sentence from './Sentence';
import ProcessingLoading from './ProcessingLoading';
import EmptyContent from './EmptyContent';

const Sentences = ({
  loadingDubbingSentences,
  isExpandRequest,
  requestTableHeight,
  setHasDeleteSentences,
  isProcessingSubtitles,
  availableLanguages,
  selectedSentencesId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const HEADER_HEIGHT = 100;
  const SENTENCE_HEIGHT = 52;

  const currentNumberOfSentencesRef = useRef(null);
  const firstTimeAllSentencesChangeRef = useRef(true);
  const isLanguageChanged = useRef(false);
  const sentencesRef = useRef(null);
  const contentHeight = useElementHeight(sentencesRef);
  const {
    projectInfo,
    sentences: sentencesInRedux,
    selectedSentencesKeys,
    originalSentences,
    changedProjectInfo,
  } = useSelector((state) => state.dubbingRequest);
  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();
  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      email: user.email,
      userId: user.id,
      phoneNumber: user.phoneNumber,
    },
  );

  const isShowOriginal =
    projectInfo?.originalInfo?.subtitleLink === '' ||
    Object.keys(originalSentences).length > 0;

  const [page, setPage] = useState(1);
  const [allSentences, setAllSentences] = useState([]);
  const [displayingSentences, setDisplayingSentences] = useState(
    [...Array(15)].map(() => ({})),
  );
  const [originalDisplayingSentences, setOriginalDisplayingSentences] =
    useState([...Array(15)].map(() => ({})));
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);

  const totalSentences = Object.keys(allSentences).length || 0;
  const paginationLimit = isExpandRequest
    ? PAGINATION_LIMIT
    : Math.floor((contentHeight - HEADER_HEIGHT) / SENTENCE_HEIGHT);

  const isSelected = (key) =>
    !!selectedSentencesKeys.find((item) => item === key);

  const handleSelectSentence = (key) => (event) => {
    const isSelectedSentence = isSelected(key);

    const newSelectedSentences =
      event.target.checked && !isSelectedSentence
        ? [...selectedSentencesKeys, key]
        : selectedSentencesKeys.filter((item) => item !== key);

    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        newSelectedSentences,
      ),
    );
  };

  const handleSelectAll = (event) => {
    const displayingKeys = displayingSentences?.map((item) => item.key);
    const newSelectedSentences = event.target.checked
      ? Array.from(new Set([...selectedSentencesKeys, ...displayingKeys]))
      : selectedSentencesKeys.filter((item) => !displayingKeys.includes(item));

    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        newSelectedSentences,
      ),
    );
  };

  const handleSelectDelete = (value) => {
    setOpenDeleteWarning(true);
    setIsDeleteAll(value);
  };

  const handleChangePage = (value) => setPage(value);

  const handleCloseDeleteWarning = () => setOpenDeleteWarning(false);

  const handleDeleteAllSentences = () => {
    dispatch(actions.dubbingRequest.resetDubbingRequest(['sentences']));
    const newProjectInfo = { ...projectInfo };
    newProjectInfo.hasChanged = true;
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'projectInfo',
        newProjectInfo,
      ),
    );
    setHasDeleteSentences(true);
    setDisplayingSentences([]);
    setOriginalDisplayingSentences([]);
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        [],
      ),
    );
    handleCloseDeleteWarning();
  };

  const getDisplayingSentences = (pageNum) => {
    const total = Object.keys(allSentences).length;
    const startIndex = (pageNum - 1) * paginationLimit;
    const endIndex = Math.min(pageNum * paginationLimit, total);

    return Object.entries(allSentences)
      .slice(startIndex, endIndex)
      .map(([key, value]) => ({ key, ...value }));
  };

  const getOriginalDisplayingSentences = (pageNum) => {
    const total = Object.keys(originalSentences).length;
    const startIndex = (pageNum - 1) * paginationLimit;
    const endIndex = Math.min(pageNum * paginationLimit, total);
    return Object.entries(originalSentences)
      .slice(startIndex, endIndex)
      .map(([key, value]) => ({ key, ...value }));
  };

  const handleDeleteSelectedSentences = async () => {
    if (totalSentences === selectedSentencesKeys.length) {
      handleDeleteAllSentences();
      return;
    }

    // Remove selected sentences in redux
    const newSentences = Object.fromEntries(
      Object.entries(allSentences).filter(
        ([key]) => !selectedSentencesKeys.includes(key),
      ),
    );
    const newOriginalSentences = Object.fromEntries(
      Object.entries(originalSentences).filter(
        ([key]) => !selectedSentencesKeys.includes(key),
      ),
    );
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        newSentences,
      ),
    );
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'originalSentences',
        newOriginalSentences,
      ),
    );
    setHasDeleteSentences(true);

    // Update has changed
    const newProjectInfo = { ...projectInfo };
    newProjectInfo.hasChanged = true;
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'projectInfo',
        newProjectInfo,
      ),
    );

    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        [],
      ),
    );
    handleCloseDeleteWarning();

    // Set number of page again
    const numberOfPage = Math.max(
      Math.ceil(Object.keys(newSentences).length / paginationLimit),
      1,
    );

    setPage(Math.min(page, numberOfPage));
    setDisplayingSentences(getDisplayingSentences(page));
    setOriginalDisplayingSentences(getOriginalDisplayingSentences(page));
  };

  const handleChangeSentence = ({ sentenceKey, name, value }) => {
    setAllSentences((prevSentences) => ({
      ...prevSentences,
      [sentenceKey]: {
        ...prevSentences[sentenceKey],
        [name]: value,
      },
    }));
  };

  const SkeletonLoadingContent = () => (
    <StyledSentence>
      <Skeleton className="checkbox" width="2%" animation="wave" />
      <div className="time-range">
        <Skeleton className="time-range-header" animation="wave" />
      </div>
      <Skeleton width="100%" animation="wave" />
    </StyledSentence>
  );

  const renderConfirmDeleteSentences = () => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleCloseDeleteWarning}>
        {t('noValue')}
      </Button>
      <Button
        variant="contained"
        onClick={
          isDeleteAll ? handleDeleteAllSentences : handleDeleteSelectedSentences
        }
      >
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const updatePageData = (checkOriginalSentences = false) => {
    const numberOfPage = Math.max(
      Math.ceil(Object.keys(allSentences).length / paginationLimit),
      1,
    );
    const currentPage = Math.min(page, numberOfPage);

    if (page !== currentPage) setPage(currentPage);

    const newData = getDisplayingSentences(currentPage);
    setDisplayingSentences(newData);

    if (!checkOriginalSentences || Object.keys(originalSentences).length > 0) {
      const newOriginalData = getOriginalDisplayingSentences(currentPage);
      setOriginalDisplayingSentences(newOriginalData);
    }
  };

  useEffect(() => {
    if (Object.keys(allSentences).length > 0) {
      updatePageData();
    }
  }, [page, paginationLimit]);

  useEffect(() => {
    if (
      firstTimeAllSentencesChangeRef.current &&
      Object.keys(allSentences).length > 0
    ) {
      if (isMultipleInputDubbing && Object.keys(originalSentences).length === 0)
        return;

      firstTimeAllSentencesChangeRef.current = false;
      updatePageData(true);

      return;
    }

    if (
      Object.keys(allSentences).length > 0 &&
      (currentNumberOfSentencesRef.current !==
        Object.keys(allSentences).length ||
        isLanguageChanged.current)
    ) {
      currentNumberOfSentencesRef.current = Object.keys(allSentences).length;
      updatePageData();
      isLanguageChanged.current = false;
    }
  }, [allSentences, originalSentences]);

  useEffect(() => {
    // Create a deep copy of sentencesInRedux to avoid reference issue
    const deepCopySentences = JSON.parse(JSON.stringify(sentencesInRedux));
    const changedSentences = changedProjectInfo?.sentencesChanged;
    if (changedSentences) {
      Object.keys(changedSentences).forEach((sentenceKey) => {
        Object.keys(changedSentences[sentenceKey]).forEach((key) => {
          deepCopySentences[sentenceKey][key] =
            changedSentences[sentenceKey][key];
        });
      });
    }

    setAllSentences(deepCopySentences);
  }, [sentencesInRedux]);

  useEffect(() => {
    isLanguageChanged.current = true;
  }, [changedProjectInfo?.voiceCode]);

  useEffect(() => {
    if (selectedSentencesId) {
      const selectedSentence = allSentences[selectedSentencesId];
      const selectedSentenceKey = Object.keys(allSentences).find(
        (key) => allSentences[key] === selectedSentence,
      );
      const selectedSentenceIndex =
        Object.keys(allSentences).indexOf(selectedSentenceKey);
      const selectedSentencePage = Math.ceil(
        selectedSentenceIndex / paginationLimit,
      );
      setPage(selectedSentencePage);
    }
  }, [selectedSentencesId]);

  return (
    <StyledSentences
      requestTableHeight={requestTableHeight}
      isSmallContent={isExpandRequest}
      ref={sentencesRef}
    >
      {isProcessingSubtitles && <ProcessingLoading />}
      {projectInfo?.currentSubtitleLink ? (
        <>
          <Box className="wrapper-sentences">
            <SentencesHeader
              isLoading={loadingDubbingSentences || isProcessingSubtitles}
              isSelected={isSelected}
              selected={selectedSentencesKeys}
              handleSelectAll={handleSelectAll}
              handleSelectDelete={handleSelectDelete}
              displayingSentences={displayingSentences}
              isSmallContent={isExpandRequest}
              isShowOriginal={isShowOriginal}
              availableLanguages={availableLanguages}
            />
            {/* Render skeletion loading */}
            {(loadingDubbingSentences || isProcessingSubtitles) && (
              <Box
                sx={{
                  height: isExpandRequest
                    ? 'auto'
                    : `${paginationLimit * SENTENCE_HEIGHT}px`,
                }}
              >
                {[...Array(15)]
                  .map(() => ({}))
                  .map(() => (
                    <SkeletonLoadingContent />
                  ))}
              </Box>
            )}

            {/* Render content */}
            <Box
              sx={{
                height: isExpandRequest
                  ? 'auto'
                  : `${paginationLimit * SENTENCE_HEIGHT}px`,
              }}
            >
              {displayingSentences.some(
                (sentence) => Object.keys(sentence).length > 0,
              ) &&
                displayingSentences?.map((item, index) => (
                  <Sentence
                    key={item?.id || item?.key}
                    data={item}
                    originalData={originalDisplayingSentences[index]}
                    isLoading={loadingDubbingSentences || isProcessingSubtitles}
                    sentenceKey={item?.key}
                    isSelected={isSelected(item?.key)}
                    onSelectSentence={handleSelectSentence}
                    handleChangeSentence={handleChangeSentence}
                    isShowOriginal={isShowOriginal}
                    isSelectedTimeFrame={selectedSentencesId === item?.id}
                  />
                ))}
            </Box>
          </Box>
          <CustomFooter
            total={totalSentences}
            page={page}
            onChangePage={handleChangePage}
            paginationLimit={paginationLimit}
            isSticky
          />
        </>
      ) : (
        <EmptyContent />
      )}
      <NotificationDialog
        title={t('confirmDeletion')}
        description={
          isDeleteAll
            ? t('deleteAllSentences')
            : t('deleteSelectedSentences', {
                value: selectedSentencesKeys.length,
              })
        }
        variant="warning"
        open={openDeleteWarning}
        onClose={handleCloseDeleteWarning}
        actionComponent={renderConfirmDeleteSentences()}
      />
    </StyledSentences>
  );
};

export default React.memo(Sentences);
