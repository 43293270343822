import { actionTypes } from './actions';

export const initialState = {};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DEVICE_RESPONSE: {
      const { fingerprint, status } = action;
      return { ...state, [fingerprint]: { status } };
    }
    case actionTypes.REMOVE_DEVICE: {
      const { fingerprints } = action;
      // eslint-disable-next-line no-param-reassign, no-restricted-syntax
      for (const fingerprint of fingerprints) delete state[fingerprint];
      return state;
    }
    default:
      return state;
  }
};

export default requestReducer;
