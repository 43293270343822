import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Card from '@src/components/Card';
import { Cached } from '@mui/icons-material';
import Table from '@src/components/Table';
import { delimitNumber } from '@src/utils/number';
import { PRICE_UNIT } from '@src/constants/payment';
import { PACKAGE_LEVEL } from '@src/constants/package';
import { IconButton, TextField, MenuItem } from '@mui/material';
import CustomDatePickerRange from '@src/components/CustomDatePickerRange';
import i18n from '@src/languages';
import { CommissionStyled, OrdersListStyled } from './index.style';

const filterPackageLevels = [
  PACKAGE_LEVEL.allPackages,
  PACKAGE_LEVEL.ADVANCE,
  PACKAGE_LEVEL.PRO,
  PACKAGE_LEVEL.PROFESSIONAL,
  PACKAGE_LEVEL.ENTERPRISE,
];

const OrdersList = ({
  orders,
  page,
  total,
  filter,
  sort,
  loading,
  onChangeFilter,
  onChangeDatePickerRange,
  onResetFilter,
  onChangePage,
  onChangeSort,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const columns = [
    {
      field: 'fullName',
      title: t('fullName'),
      align: 'left',
      sort: true,
      render: (row) => `${row.user?.lastName} ${row.user?.firstName}`,
    },
    {
      field: 'phoneNumber',
      title: t('phoneNumber'),
      align: 'left',
      sort: true,
      render: (row) => row.user?.phoneNumber,
    },
    {
      field: 'email',
      title: t('email'),
      align: 'left',
      sort: true,
      render: (row) => row.user?.email,
    },
    {
      field: 'package',
      title: t('package'),
      align: 'left',
      sortable: true,
      sort: true,
      render: (row) => row.packageName,
    },
    {
      field: 'commission',
      title: t('commission'),
      align: 'left',
      sortable: true,
      sort: true,
      render: (row) => (
        <CommissionStyled>{`+ ${delimitNumber(row.commission, language)} ${
          PRICE_UNIT.VND
        }`}</CommissionStyled>
      ),
    },
    {
      field: 'createdAt',
      title: t('purchaseDate'),
      align: 'left',
      sortable: true,
      sort: true,
      render: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
    },
  ];

  return (
    <OrdersListStyled>
      <Card>
        <div className="header">
          <div className="title">{t('historicalStatistics')}</div>
          <div className="filter-box">
            <TextField
              size="small"
              className="filter-package"
              variant="outlined"
              value={filter.packageLevel}
              name="packageLevel"
              select
              label={t('selectPackageName')}
              onChange={onChangeFilter}
            >
              {filterPackageLevels.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(item)}
                </MenuItem>
              ))}
            </TextField>
            <div className="filter-date ">
              <CustomDatePickerRange
                value={filter.createdAt}
                onChange={onChangeDatePickerRange}
              />
              <IconButton className="refresh-icon" onClick={onResetFilter}>
                <Cached />
              </IconButton>
            </div>
          </div>
        </div>
        <Table
          showNumber
          columns={columns}
          data={orders}
          sort={sort}
          loading={loading}
          page={page}
          total={total}
          onChangePage={onChangePage}
          onChangeSort={onChangeSort}
        />
      </Card>
    </OrdersListStyled>
  );
};

export default OrdersList;
