import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  COLOR,
  CONTAINED_BUTTON_COLOR,
  TRANSPARENT_COLOR,
} from '@src/styles/color';
import { BOX_SHADOW, BREAKPOINTS } from '@src/styles/config';

const StyledTitleBar = styled('div')`
  align-items: center;
  position: relative;
  margin-bottom: 8px;

  .request-info {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    &.web-title-bar {
      display: flex;
    }

    &.mobile-title-bar {
      display: none;
    }

    .base-input {
      .empty-title-footer {
        height: 16px;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.SMD}px) {
    &.web-title-bar {
      display: none;
    }

    &.mobile-title-bar {
      display: flex;
      gap: 8px;
    }

    .base-input {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 400px) {
    &.web-title-bar {
      display: none;
    }

    &.mobile-title-bar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .icon-button {
      // border-radius: 50%;
      width: 100%;
      height: 44px;
      padding: 4px;
      min-width: 44px;
    }
  }

  .icon-button {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 4px;
    min-width: 44px;
  }

  .base-input {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
    font-size: 19px;
    font-weight: 600;
  }

  .text-transfer-button {
    text-transform: none;
    padding: 8px 16px 8px 12px;
    min-width: 133px;
    border-radius: 25px;
    margin-left: 12px;
    display: flex;
    justify-content: space-around;
    width: 140px;
    color: ${COLOR.darkBlue};
    height: 44px;
    font-weight: 700;
    line-height: 20px;
  }

  .style-character {
    text-transform: lowercase;
  }

  .character-default {
    font-size: 14px;
    font-weight: 400;
    min-width: 44px;
    color: ${COLOR.subText};
    margin-left: 24px;
    justify-content: space-between;
    align-items: center;
    gap: 2px;

    .character-red {
      color: ${COLOR.red} !important;
    }

    .character-in-use {
      color: ${COLOR.info};
    }
  }

  @media (min-width: calc(${BREAKPOINTS.LG}px - 25px)) {
    .character-default {
      display: flex;
    }
  }

  @media (min-width: ${BREAKPOINTS.MD}px) and (max-width: calc(${BREAKPOINTS.LG}px - 25px)) {
    .character-default {
      display: none;
    }
  }

  @media (min-width: calc(${BREAKPOINTS.SMD}px + 50px)) and (max-width: calc(${BREAKPOINTS.MD}px - 2px)) {
    .character-default {
      display: flex;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: calc(${BREAKPOINTS.SMD}px +
    50px)) {
    .character-default {
      display: none;
    }
  }

  .estimate-audio-length {
    padding: 0px 12px 0px 16px;
    color: ${COLOR.subText};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 50px;
  }

  .create-new-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
    height: 44px;

    &:hover {
      .default-background {
        background: ${CONTAINED_BUTTON_COLOR.primary.hover};
      }
    }

    .default-background {
      background: ${COLOR.primary};
    }

    .clear-button {
      white-space: nowrap;
      border-radius: 20px 0px 0px 20px;
      border-right: 1px solid white;
      padding-right: 12px;
      padding-left: 16px;
      font-weight: 700;
      font-size: 14px;
      color: ${COLOR.darkBlue};
      height: 100%;
      line-height: 3;
      background: ${COLOR.primary} !important;
      &:hover {
        background: ${CONTAINED_BUTTON_COLOR.primary.hover} !important;
      }
    }

    .arrow-button {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 12px;
      padding-left: 6px;
      border-radius: 0px 20px 20px 0px;
    }
  }
`;

const StyledStatusSaving = styled('div')`
  @media (max-width: 600px) {
    margin-bottom: 0px;
  }

  @media (min-width: 600px) {
    margin-bottom: 14px;
  }

  .base-loading {
    display: flex;
    align-items: center;
    gap: 4px;

    .icon-rotate {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      color: ${COLOR.black[32]};
    }

    .text {
      font-weight: 400;
      font-size: 12px;
      color: ${(props) =>
        props.saved ? COLOR.darkV2.default : COLOR.darkV2[32]};
    }
  }
  .loading-saved {
    width: 16px;
    height: 16px;
    color: ${COLOR.black[32]};
  }
  .loading-saving {
    margin-right: 5px;
    color: ${COLOR.primary};
  }
  .font-weight {
    font-weight: light;
  }
`;

const StyledCreateNewEditor = styled('div')`
  cursor: pointer;
  display: flex;
  height: 34px;
  padding: 6px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  width: 140px;

  .text-action {
    color: ${COLOR.darkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
    &:hover {
      color: ${COLOR.black};
    }
  }

  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
  }
`;

const StyledPopover = styled(Popover)`
  box-shadow: ${BOX_SHADOW};
`;

export {
  StyledTitleBar,
  StyledStatusSaving,
  StyledCreateNewEditor,
  StyledPopover,
};
