import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { LANGUAGE, MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import { REQUEST_STATUS } from '@src/constants/voice';

import i18n from '@src/languages';
import DownloadButton from './DownloadButton';
import { StyledSuccessButton } from './index.style';

const SuccessDownloadButton = ({ request = {} }) => {
  const {
    id: requestId,
    title,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    status,
    userId,
  } = request;

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );
  const isDisabledAudio = !audioLink || audioExpired;
  const buttonRef = useRef(null);

  const { t } = useTranslation();
  const { language } = i18n;

  const getTitleActionTooltip = (action) => {
    if (status !== REQUEST_STATUS.SUCCESS) return t('');
    if (audioExpired) return t('notiAudioExpired', { retentionPeriod });

    return t(action);
  };

  return (
    <StyledSuccessButton langEng={language !== LANGUAGE.VN} ref={buttonRef}>
      <DownloadButton
        // onClick={handleClosePopup}
        hasDisable={isDisabledAudio}
        requestId={requestId}
        title={title}
        status={status}
        userId={userId}
        audioExpired={audioExpired}
        retentionPeriod={retentionPeriod}
        getTitleActionTooltip={getTitleActionTooltip}
      />
    </StyledSuccessButton>
  );
};

export default SuccessDownloadButton;
