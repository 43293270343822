/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useSelector } from 'react-redux';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import apis from '@src/apis';
import EmptyRequest from './EmptyRequest';
import Requests from './Requests';
import { StyledLoading } from './index.style';

const FIELDS =
  'id,title,characters,createdAt,progress,status,voice,audioType,audioLink,retentionPeriod,processingAt,endedAt';

const MobileTTSRequests = () => {
  const { getFeatureValue } = useFeatureFlags();
  const user = useSelector((state) => state.auth.user);

  const isShowInfoVCRequest = getFeatureValue(
    FEATURE_KEYS.SHOW_INFO_VC_REQUEST,
    { userId: user?.id, email: user.email, phoneNumber: user.phoneNumber },
  );
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState({
    offset: 1,
    limit: 10,
    search: '',
    sort: 'createdAt_desc',
    fields: FIELDS,
  });
  const fetchRequests = async (newParams, hasLoading = false) => {
    if (hasLoading) setLoading(true);

    const data = isShowInfoVCRequest
      ? await apis.requests.getRequestsV2(newParams)
      : await apis.requests.getRequests(newParams);

    setLoading(false);
    if (data && data.status) {
      const newRequests = data.result.requests.map((request) => {
        const req = requests.find((r) => r.id === request.id);
        if (req) {
          return {
            ...request,
            progress:
              req?.progress && request.progress < req?.progress
                ? req.progress
                : request.progress,
          };
        }
        return request;
      });

      if (newRequests.length < newParams.limit) {
        setHasMore(false);
      }
      setRequests((prevRequests) => [
        ...prevRequests,
        ...newRequests.filter(
          (newRequest) => !prevRequests.some((req) => req.id === newRequest.id),
        ),
      ]);
    }
  };

  useEffect(() => {
    fetchRequests(params, true);
  }, [params]);

  if (loading && requests.length === 0) {
    return (
      <StyledLoading>
        <CircularProgress />
      </StyledLoading>
    );
  }

  return requests?.length ? (
    <Requests
      hasMore={hasMore}
      requests={requests}
      loading={loading}
      setLoading={setLoading}
      setParams={setParams}
    />
  ) : (
    <EmptyRequest />
  );
};

export default MobileTTSRequests;
