/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import { Box, CircularProgress } from '@mui/material';

import Layout from '@src/containers/Layout';
import HeaderBanner from '@src/containers/Banner/HeaderBanner';
import SwiperHeaderBanner from '@src/containers/Banner/SwiperHeaderBanner';
import actions from '@src/redux/actions';
import { HOME_PAGE_URL, LANDING_PAGE_URL, VBEE_DOMAIN } from '@src/configs';
import { setCookie } from '@src/utils/cookie';
import { ERROR_MESSAGE_AUTH, SERVICE_SELECT_TYPE } from '@src/constants';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useCustomKeycloak from '@src/hooks/useCustomKeycloak';
import useLocation from '@src/hooks/useLocation';
import route from '@src/constants/route';
import LayoutMobileTTS from '@src/containers/Layout/LayoutMobileTTS';
import { checkUrlError } from '@src/utils/url';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { dubbingAppRoutes, ttsAppRoutes } from './newAppRoutes';
import appRoutes from './appRoutes';

const PrivateApp = () => {
  const { user } = useSelector((state) => state.auth);
  const { headerVoucher } = useSelector((state) => state.voucher);
  const { getFeatureValue } = useFeatureFlags();
  const separateDubbingPage = getFeatureValue(FEATURE_KEYS.NEW_DUBBING, {
    email: user.email,
    userId: user.id,
  });
  const useHeaderBanner = getFeatureValue(
    FEATURE_KEYS.HEADER_BANNER_FLASH_SALE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const useMobileUI = getFeatureValue(FEATURE_KEYS.MOBILE_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const newAppRoutes = separateDubbingPage
    ? [...ttsAppRoutes, ...dubbingAppRoutes]
    : appRoutes;
  const privateRoutes = newAppRoutes.filter((route) => route.isPrivate);

  return (
    <div>
      {useHeaderBanner &&
        headerVoucher &&
        !Array.isArray(headerVoucher) &&
        !useVoucherByProduct && (
          <HeaderBanner
            isAgreeToTerm={user?.hasAgreedToTerms}
            headerVoucher={headerVoucher}
          />
        )}
      {useHeaderBanner &&
        Array.isArray(headerVoucher) &&
        headerVoucher.length > 0 &&
        useVoucherByProduct && (
          <SwiperHeaderBanner
            headerVouchers={headerVoucher}
            isAgreeToTerm={user?.hasAgreedToTerms}
          />
        )}
      <Layout headerVoucher={headerVoucher} useHeaderBanner={useHeaderBanner}>
        <Switch>
          {privateRoutes.map((privateRoute) => (
            <PrivateRoute
              path={privateRoute.path}
              component={privateRoute.component}
              exact
              key={privateRoute.path}
              featureKey={privateRoute.featureKey}
            />
          ))}
          {useMobileUI && (
            <Route path={route.MOBILE} component={LayoutMobileTTS} />
          )}
          <Route render={() => window.location.assign(HOME_PAGE_URL)} />
        </Switch>
      </Layout>
    </div>
  );
};

const AppRouter = () => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const {
    initialized,
    keycloak,
    getKcTokenFromCookie,
    setKcToken,
    validateKcTokenCookie,
  } = useCustomKeycloak();

  const { ip } = useLocation();
  const errorParams = new URLSearchParams(window.location.search).get('error');

  const { getFeatureValue } = useFeatureFlags();
  const googleOneTap = getFeatureValue(FEATURE_KEYS.GOOGLE_ONE_TAP, { ip });

  const { verifying } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  if (!nprogress.isStarted()) nprogress.start();

  const SERVICE_SELECT_EXPIRED = 1000 * 60 * 60 * 24 * 14; // 14 days

  const validateTokenFromCookie = async (token, refreshToken, idToken) => {
    const isValidTokens = await validateKcTokenCookie(
      token,
      refreshToken,
      idToken,
    );
    if (isValidTokens) {
      setKcToken(token, refreshToken, idToken);
      dispatch(actions.auth.verifyToken(token));
    }
    setIsFirstTime(false);
  };

  useEffect(() => {
    nprogress.done();
  });

  useEffect(() => {
    dispatch(actions.featureFlag.getFeatureFlags());
    dispatch(actions.language.getLanguages());
    dispatch(actions.voice.getDubbingVoices());
  }, []);

  useEffect(() => {
    if (!initialized) return;

    if (keycloak.token) {
      dispatch(actions.auth.verifyToken(keycloak.token));
    } else if (googleOneTap) {
      const { token, refreshToken, idToken } = getKcTokenFromCookie();
      if (token) {
        validateTokenFromCookie(token, refreshToken, idToken);
        return;
      }
    }
    setIsFirstTime(false);
  }, [initialized, keycloak.token, keycloak?.authenticated]);

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      const path = window.location.pathname;
      const product = path.split('/')[1];
      const userId = keycloak?.idTokenParsed?.sub;
      setCookie({
        cname: `ServiceSelect-${userId}`,
        cvalue:
          product === 'dubbing'
            ? SERVICE_SELECT_TYPE.DUBBING
            : SERVICE_SELECT_TYPE.STUDIO,
        domain: VBEE_DOMAIN,
        extime: SERVICE_SELECT_EXPIRED,
      });
    }
  }, [initialized, keycloak?.authenticated]);

  const enableDatasenses = getFeatureValue(FEATURE_KEYS.DATASENSES, { ip });
  useEffect(() => {
    if (ip && enableDatasenses?.web_app) {
      // Create a script element
      const script = document.createElement('script');
      script.src = 'https://cdn.datasenses.io/datasenses-v1.1.9.min.js';
      script.async = true;

      // Append the script to the body
      document.body.appendChild(script);

      // Initialize datasenses when the script has loaded
      script.onload = () => {
        window.datasenses = window.datasenses || [];
        window.datasenses.init(process.env.REACT_APP_DATASENSES_KEY);
      };
    }
  }, [enableDatasenses, ip]);

  // Check page is error page
  const isUrlError = checkUrlError();

  // Check error is auth error
  const authError = ERROR_MESSAGE_AUTH[errorParams];

  // Redirect to error page if error is auth error
  if (authError && !isUrlError) {
    window.location.assign(`/error?error=${authError.message}`);
  }

  const shouldShowLoader = !initialized || verifying || isFirstTime;

  if (shouldShowLoader && !authError) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  const publicRoutes = appRoutes.filter((route) => !route.isPrivate);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => window.location.assign(LANDING_PAGE_URL)}
        />
        {publicRoutes.map((publicRoute) => (
          <PublicRoute
            exact
            path={publicRoute.path}
            component={publicRoute.component}
            restricted={publicRoute.restricted}
            key={publicRoute.path}
          />
        ))}

        <PrivateRoute component={PrivateApp} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
