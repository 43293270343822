import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import CustomDrawer from '@src/components/Drawer';
import actions from '@src/redux/actions';
import { EmailOutlined } from '@mui/icons-material';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import MultiFileDropzone from '@src/components/MultiFileDropzone';
import { IMAGE_TYPE_FILE } from '@src/constants';
import { COMMISSION_PAYMENT_LIMIT } from '@src/constants/affiliate';
import apis from '@src/apis';
import { uploadFileToS3 } from '@src/services/upload';
import ProcessHandler from '@src/components/ProcessHandler';
import { nonAccentVietnamese } from '@src/utils/accent';
import { delimitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import { PaymentRequestStyled } from './index.style';

const CreatePaymentDrawer = ({
  open,
  currentCommission,
  onReloadCommission,
  onClose,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [clearFile, setClearFile] = useState(false);
  const [bankList, setBankList] = useState([]);

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    setErrors({});
  };

  const handleChangeUserInfo = (event) => {
    const { name, value } = event.target;
    if (name === 'bankAccountOwner') {
      let newBankAccountOwner = nonAccentVietnamese(value);
      newBankAccountOwner = newBankAccountOwner.toUpperCase();
      setUserInfo({ ...userInfo, [name]: newBankAccountOwner });
      setErrors({ ...errors, [name]: null });
      return;
    }
    setUserInfo({ ...userInfo, [name]: value });
    setErrors({ ...errors, [name]: null });
  };

  const verifyUserInfo = () => {
    const newErrors = {};

    if (!userInfo.fullName) newErrors.fullName = 'fullNameRequired';
    if (!userInfo.phoneNumber) newErrors.phoneNumber = 'phoneNumberRequired';
    if (!userInfo.email) newErrors.email = 'emailRequired';
    if (!userInfo.withTheAmount)
      newErrors.withTheAmount = 'withTheAmountRequired';
    else if (Number(userInfo.withTheAmount) < COMMISSION_PAYMENT_LIMIT)
      newErrors.withTheAmount = 'withTheAmountMustBeGreaterThanLimit';
    else if (Number(userInfo.withTheAmount) > currentCommission)
      newErrors.withTheAmount = 'withTheAmountMustBeLessThanCommission';

    if (!userInfo.address) newErrors.address = 'addressRequired';
    if (!userInfo.bankName) newErrors.bankName = 'bankNameRequired';
    if (!userInfo.bankAccountOwner)
      newErrors.bankAccountOwner = 'bankAccountOwnerRequired';
    if (!userInfo.bankAccountNumber)
      newErrors.bankAccountNumber = 'bankAccountNumberRequired';
    if (!userInfo.files || userInfo.files.length === 0)
      newErrors.files = 'filesRequired';
    if (userInfo?.files?.length < 2) newErrors.files = 'NotEnoughFiles';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClearFile = () => setClearFile(true);

  const handleUploadImage = (images) => {
    setUserInfo({ ...userInfo, files: images });
  };

  const handleChangeBankName = (event, value) =>
    setUserInfo({ ...userInfo, bankName: value?.label });

  const handleUploadImageToS3 = async (fileName, file) => {
    const extension = fileName.split('.')[1];
    const name = fileName.split('.')[0];
    const directory = 'images/users';
    const s3Url = await uploadFileToS3(name, directory, extension, file);

    if (!s3Url) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'uploadError',
        }),
      );
      setIsLoading(false);
      return null;
    }

    return s3Url;
  };

  const handleSendPaymentRequest = async () => {
    if (!verifyUserInfo()) return;
    setIsLoading(true);
    const imageLinks = await Promise.all(
      userInfo.files.map(async (file) => {
        const { name: fileName } = file;
        const ext = fileName.split('.').pop();
        const imgName = fileName.slice(0, fileName.lastIndexOf('.'));
        const s3Url = await handleUploadImageToS3(
          `${imgName}_${uuid()}.${ext}`,
          file,
        );

        if (s3Url) return s3Url;
        return null;
      }),
    );
    userInfo.files = imageLinks;
    const {
      fullName,
      phoneNumber,
      email,
      withTheAmount,
      address,
      bankName,
      bankAccountOwner,
      bankAccountNumber,
      files,
    } = userInfo;
    const res = await apis.affiliateTransaction.createAffiliateTransaction({
      fullName,
      phoneNumber,
      email,
      withTheAmount,
      address,
      bankName,
      bankAccountOwner,
      bankAccountNumber,
      files,
    });
    setIsLoading(false);
    if (!res.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'createPaymentRequestError',
        }),
      );
    } else {
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'createPaymentRequestSuccess',
        }),
      );
      onReloadCommission();
      onClose();
    }
  };

  const handleDeleteFile = () => setUserInfo({ ...userInfo, file: null });

  const fetchBankList = async () => {
    const res = await apis.banks.getAllBanks();

    if (res?.data) {
      const list = res.data.map((item) => ({
        label: `${item.shortName} - ${item.name}`,
      }));
      setBankList(list);
    }
  };

  useEffect(() => {
    if (open && bankList.length === 0) fetchBankList();
  }, [open]);

  useEffect(() => {
    setUserInfo({ ...userInfo, withTheAmount: currentCommission });
  }, [currentCommission]);

  return (
    <CustomDrawer open={open} title={t('paymentRequest')} onClose={handleClose}>
      <PaymentRequestStyled>
        <div className="input-control">
          <Typography className="input-label">
            {t('fullName')} <span className="red">*</span>
          </Typography>
          <TextField
            name="fullName"
            type="text"
            value={userInfo.expiresIn}
            placeholder={t('fullNamePlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.fullName)}
            error={!!errors.fullName}
          />
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('phoneNumber')} <span className="red">*</span>
          </Typography>
          <TextField
            name="phoneNumber"
            type="text"
            value={userInfo.phoneNumber}
            placeholder={t('phoneNumberPlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.phoneNumber)}
            error={!!errors.phoneNumber}
          />
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('email')}
            <span className="red">*</span>
          </Typography>
          <TextField
            name="email"
            type="text"
            value={userInfo.email}
            placeholder={t('emailPlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.email)}
            error={!!errors.email}
          />
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('amountOfMoney')} <span className="red">*</span>
          </Typography>
          <TextField
            name="withTheAmount"
            type="number"
            value={userInfo.withTheAmount}
            placeholder={t('withTheAmountPlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.withTheAmount, {
              paymentLimit: delimitNumber(COMMISSION_PAYMENT_LIMIT, language),
              currentCommission: delimitNumber(currentCommission, language),
            })}
            error={!!errors.withTheAmount}
          />
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('address')} <span className="red">*</span>
          </Typography>
          <TextField
            name="address"
            type="text"
            value={userInfo.address}
            placeholder={t('addressPlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.address)}
            error={!!errors.address}
          />
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('bankName')} <span className="red">*</span>
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            name="bankName"
            placeholder={t('bankNamePlaceholder')}
            size="small"
            value={userInfo.bankName}
            onChange={handleChangeBankName}
            options={bankList}
            renderInput={(params) => <TextField {...params} />}
          />
          {errors.bankName && (
            <Typography fontSize={13} color="error" className="input-label">
              {t(errors.bankName)}
            </Typography>
          )}
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('bankAccountOwner')} <span className="red">*</span>
          </Typography>
          <TextField
            name="bankAccountOwner"
            type="text"
            value={userInfo.bankAccountOwner}
            placeholder={t('bankAccountOwnerPlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.bankAccountOwner)}
            error={!!errors.bankAccountOwner}
          />
        </div>
        <div className="input-control">
          <Typography className="input-label">
            {t('bankAccountNumber')} <span className="red">*</span>
          </Typography>
          <TextField
            name="bankAccountNumber"
            type="text"
            value={userInfo.bankAccountNumber}
            placeholder={t('bankAccountNumberPlaceholder')}
            size="small"
            disabled={isLoading}
            fullWidth
            onChange={handleChangeUserInfo}
            helperText={t(errors.bankAccountNumber)}
            error={!!errors.bankAccountNumber}
          />
        </div>

        <div className="input-control">
          <Typography className="input-label">
            {t('citizenIdPhotos')} <span className="red">*</span>
          </Typography>
          <MultiFileDropzone
            clearFile={clearFile}
            disabled={isLoading}
            fileType={IMAGE_TYPE_FILE}
            acceptFile=".jpeg,.jpg,.png,.gif,.svg"
            onAddFile={handleUploadImage}
            onClearFile={handleClearFile}
            onDeleteFile={handleDeleteFile}
            maxFiles={2}
          />
          {errors.files && (
            <Typography fontSize={13} color="error" className="input-label">
              {t(errors.files)}
            </Typography>
          )}
        </div>

        <Button
          className="send-request-button"
          variant="contained"
          onClick={handleSendPaymentRequest}
          startIcon={<EmailOutlined />}
          disabled={isLoading}
        >
          <ProcessHandler size={20} loading={isLoading}>
            {t('sendPaymentRequest')}
          </ProcessHandler>
        </Button>
      </PaymentRequestStyled>
    </CustomDrawer>
  );
};

export default CreatePaymentDrawer;
