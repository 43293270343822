import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import AffiliateBackground from '@src/assets/images/affiliate-background.png';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledAffiliate = styled('div')`
  .contributor-policy {
    color: ${COLOR.info};
    font-weight: 700;
    font-style: normal;
    cursor: pointer;
  }

  .top {
    background-image: url(${AffiliateBackground});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-radius: ${BORDER_RADIUS};
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      border-radius: ${BORDER_RADIUS};
    }
  }
  .content {
    width: 40%;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    @media (max-width: 900px) {
      width: 80%;
    }
  }
  .title {
    margin-bottom: 20px;
  }

  .description {
    margin-bottom: 20px;
  }

  .card-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .man {
    width: 20%;
    object-fit: contain;
    padding: 20px 20px;
    @media (max-width: 900px) {
      width: 60%;
    }
    @media (max-width: 600px) {
      width: 80%;
    }
  }
  .woman {
    width: 20%;
    object-fit: contain;
    padding: 20px 20px;
    @media (max-width: 900px) {
      display: none;
    }
  }
  .bottom {
    display: flex;
    gap: 20px;
    text-align: center;
    justify-content: center;
    margin: 50px 50px;
    @media (max-width: 900px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
  .icon {
    object-fit: none;
  }
`;
