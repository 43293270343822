import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import {
  BREAKPOINTS,
  TIMELINE_HEIGHT,
  TIMELINE_COLLAPSED_HEIGHT,
} from '@src/styles/config';

export const StyledTimeFrame = styled('div')`
  display: flex;
  flex-direction: column;
  background: ${COLOR.white};
  padding-bottom: 4px;
  height: ${(props) =>
    props.isExpandTimeFrame ? TIMELINE_HEIGHT : TIMELINE_COLLAPSED_HEIGHT}px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    left: 0 !important;
  }
  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    left: ${(props) => `${props.sidebarWidth}px`};
  }
`;
