export const actionTypes = {
  GET_LANGUAGES: 'GET_LANGUAGES',
  GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
};

const getLanguages = () => ({
  type: actionTypes.GET_LANGUAGES,
});

const getLanguagesSuccess = (languages) => ({
  type: actionTypes.GET_LANGUAGES_SUCCESS,
  languages,
});

export { getLanguages, getLanguagesSuccess };
