import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { GRADIENT_COLOR, COLOR } from '@src/styles/color';
import {
  BOX_SHADOW,
  MOBILE_BREAKPOINT,
  PACKAGE_BOX_SHADOW,
  PACKAGE_HEIGHT_EXTRA,
} from '@src/styles/config';

const StyledPackages = styled('div')`
  width: 100%;

  .tabs-header {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${COLOR.darkBlue};
  }
  .tabs-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .tab {
    min-width: 300px;
    background-color: ${COLOR.white};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tab-active {
    border: 1px solid ${COLOR.darkBlue};
    margin-bottom: -1px;
    border-bottom: none;
    color: ${COLOR.darkBlue};
  }
  .tab-inactive {
    background-color: ${COLOR.divider};
    color: ${COLOR.dark};
  }

  .duration-header {
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }
  .duration-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .duration {
    min-width: 200px;
    background-color: ${COLOR.primary};
    color: ${COLOR.darkBlue};
    text-transform: none;
  }
  .duration-inactive {
    background-color: ${COLOR.divider};
    color: ${COLOR.dark};
  }
  .empty-duration {
    height: 48px;
  }

  .packages-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 20px;
  }

  .packages {
    width: 100%;
    max-width: ${(props) => (props.num < 3 ? '800px' : '1200px')};
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
    grid-gap: 16px;
    margin-bottom: 24px;
  }

  .mobile-packages {
    display: none;
    width: 100%;
  }

  .step-buttons {
    display: none;
    position: fixed;
    top: 70%;
    z-index: 1000;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .packages {
      display: none;
    }

    .mobile-packages,
    .step-buttons {
      display: block;
    }

    .tab,
    .duration {
      min-width: 0px;
    }

    .packages-wrapper {
      margin: 0;
    }
  }
`;

const StyledPackageItem = styled(Card)`
  overflow: visible;
  box-shadow: ${(props) =>
    props.mostpopular ? PACKAGE_BOX_SHADOW : BOX_SHADOW};
  ${(props) =>
    props.mostpopular &&
    `position: relative;
    :before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 6px;
      padding: 1.5px; 
      background: ${GRADIENT_COLOR.darkBlue};
      -webkit-mask: ${GRADIENT_COLOR.darkBlue} content-box, ${
      GRADIENT_COLOR.darkBlue
    };
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    .chip-wrapper {
      position: absolute;
    }
    .card-info {
      margin-top: ${PACKAGE_HEIGHT_EXTRA + 33}px !important;
    }
`};

  margin-top: ${(props) => {
    if (!props.mostpopular) return `${PACKAGE_HEIGHT_EXTRA}px`;
    return '';
  }};

  .card-content {
    position: relative;
  }

  .card-info {
    text-align: center;
  }

  .disabled-icon-content {
    color: ${COLOR.light} !important;
  }

  .icon-content {
    color: ${COLOR.primary};
  }

  .most-popular {
    background: ${GRADIENT_COLOR.orange};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .package-name {
    font-size: 22px;
    font-weight: bold;
    color: ${COLOR.dark};
  }
  .chip-wrapper {
    display: flex;
    top: 0;
    left: 0;
    gap: 8px;
  }
  .price-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: ${(props) => (props.free ? '44px' : '75px')};
  }

  .price-en {
    margin-top: 10px;
  }

  .original-card {
    min-height: 52px;
  }

  .price {
    font-size: 27px;
    font-weight: bold;
    line-height: 44px;
    white-space: nowrap;
  }

  .sub-price {
    font-weight: 500;
    font-weight: normal !important;
    font-size: 18px;
    font-weight: bold;
  }

  .description {
  }

  .chip {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLOR.darkBlue};
    color: ${COLOR.primary};
    border-radius: 6px 0px;
    padding: 0px 6px;
    height: ${PACKAGE_HEIGHT_EXTRA}px;
  }

  .chip-none {
    height: ${PACKAGE_HEIGHT_EXTRA}px;
    padding: 0px 6px;
  }

  .text-chip {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: auto;
  }

  .button-start {
    font-weight: bold;
    margin: 16px 0px !important;
  }

  .free-package-button {
    height: 78px;
  }

  .text-limited {
    margin: 6px 0px 3px 0px;
    color: ${COLOR.lightText};
  }

  .icon-contents {
    margin-left: -16px;
    margin-right: -24px;
  }
  .clarify {
    opacity: 1 !important;
  }

  .original-price-wrapper {
    display: flex;
    align-items: flex-start;
    margin: ${(props) => (props.free ? 26 : 50)}px 0 3px 0;
  }

  .original-price {
    position: relative;
    height: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    padding-right: 8px;
    text-decoration-line: line-through;
    spacing: 4;
    margin: 0 auto;
    color: ${COLOR.lightText};
    .sub-text {
      position: absolute;
      margin-top: -7px;
      top: 0;
      right: 0;
    }
    .month {
      margin-right: 56px;
    }
    .year {
      margin-right: 45px;
    }

    .month-en {
      margin-right: 61px;
    }
  }
  .disabled-content {
    text-decoration-line: line-through;
    color: ${COLOR.light};
  }

  .content {
    color: ${COLOR.dark};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 0px;
    overflow: ${(props) => (props.mostpopular ? 'hidden' : 'visible')};

    .chip-none {
      height: 0px;
    }

    .card-info {
      margin-top: 0px !important;
    }

    .original-price-wrapper {
      margin-top: ${(props) => (props.free ? 0 : 24)}px;
    }
  }
`;

export { StyledPackages, StyledPackageItem };
