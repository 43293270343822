import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledSentenceHeader = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: ${({ height }) => height || '52px'};
  padding: ${({ padding }) => padding || '8px 12px'} ;
  border-bottom: 1px solid ${COLOR.divider};
  box-sizing: border-box;
  position: ${(props) => (props.isSmallContent ? 'relative' : 'sticky')};
  top: 0;
  z-index: 10;
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR.charcoal : COLOR.white};
  white-space: nowrap;
  .checkbox-cell {
    border-radius: 0%;
    color: ${COLOR.darkBlue} !important;
    border-right: 1px solid ${COLOR.divider};
  }

  .time-range {
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 0px 12px;

    .text-id {
      border-right: 1px solid ${COLOR.divider};
      padding-right: 8px;
      width: 40px;
      text-align: right;
    }

    .text-start-end {
      padding-left: 8px;
      width: 240px;
      text-align: left;
    }
  }

  .content {
    padding: 8px 28px;
    border-left: 1px solid ${COLOR.divider};
    outline: none;
    background-color: transparent;
  }


  .checkbox {
    border-radius: 0%;
    color: ${COLOR.darkBlue} !important;
  }

  .time-range-header {
    padding: 0px 16px;
    width: 202px;
    box-sizing: border-box;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
  }

  .voice-and-speed-header {
    padding: 0px 12px;
    width: 90px;
    box-sizing: border-box;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
    display: flex;
    justify-content: center;
  }

  .content-header {
    padding: 12px 16px;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
  }

  .delete-button {
    color: ${COLOR.red};
    border: 1px solid ${COLOR.gray};
    border-top: none;
    border-bottom: none;
    border-right: none;

    &:hover {
      background: ${COLOR.gray}
    },
  }
`;

export const StyledSentence = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: ${({ height }) => height || '52px'};
  padding: ${({ padding }) => padding || '4px 12px'} ;
  border-bottom: 1px solid ${COLOR.divider};
  box-sizing: border-box;
  background-color: ${({ isSelected, isSelectedTimeFrame }) => {
    if (isSelected) return COLOR.charcoal;

    return isSelectedTimeFrame ? COLOR.bluev2[10] : null;
  }};
  white-space: nowrap;

  .checkbox-cell {
    height: 100%;
    border-radius: 0%;
    color: ${COLOR.darkBlue} !important;
    border-right: 1px solid ${COLOR.divider};
  }

  .time-range {
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 0px 12px;
    border-right: ${(props) =>
      props.isDubbingMultipleVoices ? `1px solid ${COLOR.divider}` : '0px'} ;

    .text-id {
      border-right: 1px solid ${COLOR.divider};
      padding-right: 8px;
      width: 40px;
      text-align: right;
    }

    .text-start-end {
      padding-left: 8px;
      width: 170px;
      text-align: left;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .content {
    padding: 8px 16px;
    border-left: 1px solid ${COLOR.divider};
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    height: ${({ sentenceHeight }) => `${sentenceHeight}px` || 'auto'};
  }

  .enabled-style {
    color: inherit;
  }

  .checkbox {
    border-radius: 0%;
    color: ${COLOR.darkBlue} !important;
  }

  .time-range-header {
    padding: 0px 16px;
    width: 202px;
    box-sizing: border-box;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
  }

  .content-header {
    padding: 8px 26px;
    font-weight: 510;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.darkBlue};
  }

  .delete-button {
    color: ${COLOR.red};
    border: 1px solid ${COLOR.gray};
    border-top: none;
    border-bottom: none;
    border-right: none;

    &:hover {
      background: ${COLOR.gray}
    },
  }

  .voice-and-speed {
    display: flex;
    gap: 4px;
    padding: 0px 12px;
    min-width: 66px;
    justify-content: center;
    align-items: center;
    
    .speed {
      display: flex;
      align-items: center;
      height: 28px;
      font-weight: 400;
    }

    .voice {
      padding: 8px;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        background-color: ${COLOR.secondary[80]};
      }
    }

  }
`;

export const StyledSentences = styled('div')`
  position: relative;
  height: ${(props) =>
    props.requestTableHeight
      ? `calc(100vh -  ${props.requestTableHeight}px)`
      : `calc(100vh - 320px)`};
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.indigo[64]};
    border-radius: 4px;
  }

  .wrapper-sentences {
    box-sizing: border-box;
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: ${(props) => props.isSmallContent && 'auto'};

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${COLOR.indigo[64]};
      border-radius: 4px;
    }

    .select-request-popper {
      position: fixed;
      bottom: 10%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-color: ${COLOR.white};
      box-shadow: 0px 4px 15px rgba(44, 63, 88, 0.35);
      border-radius: ${BORDER_RADIUS};
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      width: 480px;

      .button-group {
        display: flex;
        gap: 8px;
      }
    }
  }

  @media (max-width: 600px) {
    overflow-x: auto;

    .wrapper-sentences {
      overflow-x: auto;
    }
  }
`;

export const StyledActionWarning = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledProcessingLoading = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .wrapper-title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      color: ${COLOR.black.default};

      .wrapper-dot {
        display: flex;
        gap: 2px;
        align-items: flex-end;
        margin-left: 4px;
        margin-bottom: 6px;

        @keyframes bounce {
          0%,
          80%,
          100% {
            transform: scale(0);
          }
          40% {
            transform: scale(1);
          }
        }
      }
    }

    .wrapper-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${COLOR.label[64]};
    }
  }
`;

export const StyledDot = styled('div')`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${COLOR.black.default};
  animation: bounce 1.5s infinite ease-in-out both;

  &:nth-of-type(1) {
    animation-delay: -0.32s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.16s;
  }
`;

export const StyledEmptyContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .wrapper-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .wrapper-title-icon {
        width: 20px;
        height: 20px;
      }

      .wrapper-title-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${COLOR.black.default};
      }
    }

    .wrapper-subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: ${COLOR.label2[100]};
    }

    .wrapper-button {
      padding: 8px 24px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: ${COLOR.secondary[100]};
      text-transform: none;
    }
  }
`;
