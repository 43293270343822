import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS, BREAKPOINTS } from '@src/styles/config';

export const StyledErrorReportButton = styled('div')`
  padding-left: 12px;

  .error-report-button {
    border-radius: 20px;
    font-weight: 700;
    text-transform: none;
    color: ${COLOR.red};
  }
`;

export const StyledSuccessButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 42px;

  .play-audio-button {
    white-space: nowrap;
    border-radius: 20px 0px 0px 20px;
    font-weight: 700;
    font-size: 14px;
    color: ${COLOR.darkBlue};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: ${COLOR.secondary[32]};
    border-style: solid none solid solid;
    border-width: 1px;
    background: inherit !important;
    &:hover {
      background: ${TRANSPARENT_COLOR.dark} !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD}px) {
    .play-audio-button {
      padding-right: 16px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    .play-audio-button {
      padding-right: 10px;
      padding-left: 10px;
    }

    .arrow-button {
      padding-left: 4px;
      padding-right: 10px;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-left: 12px;

    .play-audio-button {
      padding-right: ${(props) => (props.langEng ? '22px' : '22px')};
      padding-left: 16px;
    }

    .arrow-button {
      padding-left: 6px;
      padding-right: 12px;
    }
  }

  .arrow-button {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    border-color: ${COLOR.secondary[32]};
    border-style: solid;
    border-width: 1px;
    &:hover {
      background: ${TRANSPARENT_COLOR.dark};
    }
  }
`;

export const StyledDownloadButton = styled('div')`
  color: ${(props) =>
    props.disabled ? COLOR.secondary[32] : COLOR.darkBlue} !important;

  .text-play-audio {
    font-weight: 700;
    font-size: 14px;
    margin-left: 3px;
  }

  @media (max-width: calc(${BREAKPOINTS.MD}px + 80px)) {
    .text-play-audio {
      display: none;
    }
  }

  @media (min-width: calc(${BREAKPOINTS.MD}px + 80px)) {
    .text-play-audio {
      display: block;
    }
  }
`;

export const StyledPlayButton = styled('div')`
  color: ${(props) =>
    props.disabled ? COLOR.secondary[32] : COLOR.darkBlue} !important;

  .text-play-audio {
    font-weight: 700;
    font-size: 14px;
    margin-left: 3px;
  }

  @media (max-width: calc(${BREAKPOINTS.MD}px + 50px)) {
    .text-play-audio {
      display: none;
    }
  }

  @media (min-width: calc(${BREAKPOINTS.MD}px + 50px)) {
    .text-play-audio {
      display: block;
    }
  }
`;

export const StyledCreateNewEditor = styled('div')`
  cursor: pointer;
  display: flex;
  height: 34px;
  padding: 6px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 500px;
  font-size: 14px;
  font-style: normal;
  font-weight: 510;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: ${COLOR.darkBlue};
  width: 140px;
`;

export const StyledMenu = styled('div')`
  padding: 8px 0px;
  width: ${(props) => (props.engButton ? '190px' : '210px')};
`;

export const StyledTextIcon = styled(IconButton)`
  width: 100%;
  padding: ${(props) =>
    props.langEng ? '6px 0px 6px 18px' : '6px 0px 6px 20px'};
  color: ${(props) => (props.disabled ? COLOR.secondary[32] : COLOR.darkBlue)};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  justify-content: start;
  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    color: ${({ colorHover }) => COLOR[colorHover]};
    opacity: 1;
  }
  .text-action {
    color: ${(props) =>
      props.disabled ? COLOR.secondary[32] : COLOR.darkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: ${(props) => (props.langEng ? '4px' : '4px')};
    &:hover {
      color: ${COLOR.black};
    }
  }
`;
