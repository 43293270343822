import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, Typography, Button } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import ROUTES from '@src/constants/route';
import { delimitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import CountdownBanner from './CountdownBanner';
import { StyledAdsItem, StyledSidebarTooltip } from './index.style';

const LockCharactersBanner = () => {
  const { t } = useTranslation();
  const { language } = i18n;
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { lockCharacters } = useSelector((state) => state.auth.user);
  const { lastExpiredOrder } = useSelector((state) => state.user);
  const { getFeatureValue } = useFeatureFlags();
  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const handleRedirectPayment = () => history.push(ROUTES.PAYMENT);

  const getPackageExpired = () => {
    if (user.preservationExpiryDate && usePreservationExpiryDate)
      return moment(user.preservationExpiryDate);

    if (user.packageExpiryDate)
      return moment(user.packageExpiryDate).add(30, 'days');

    if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      return moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days');
    }

    return '';
  };

  const TooltipTitle = () => (
    <Box display="flex" justifyContent="center" paddingX="-2px">
      <Typography
        className="tooltip-title"
        dangerouslySetInnerHTML={{
          __html: t('lockCharactersNote'),
        }}
        fontSize="14px"
        lineHeight="20px"
        letterSpacing="-0.4px"
        fontWeight="400"
      />
    </Box>
  );

  const preservedDate = getPackageExpired();

  return (
    <StyledAdsItem>
      <Box className="banner-item">
        <div className="header">
          <div>
            <Typography
              className="title"
              dangerouslySetInnerHTML={{
                __html: t('preservedCharactersBannerTitle', {
                  numberOfCharacters: delimitNumber(lockCharacters, language),
                }),
              }}
            />
            <Typography className="description">
              {`(${t('preservedCharactersDate')}: ${
                preservedDate ? moment(preservedDate).format('DD/MM/YYYY') : ''
              })`}
            </Typography>
          </div>
          <StyledSidebarTooltip title={<TooltipTitle />}>
            <InfoRounded color="white" className="icon" fontSize="16px" />
          </StyledSidebarTooltip>
        </div>
        <div className="button-wrapper lock-character-button">
          <Button className="button" onClick={handleRedirectPayment}>
            {t('still')}
            <CountdownBanner date={new Date(preservedDate).getTime() + 100} />
            {`- ${t('renewNow')}`}
          </Button>
        </div>
      </Box>
    </StyledAdsItem>
  );
};

export default LockCharactersBanner;
