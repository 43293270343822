export const actionTypes = {
  DEVICE_RESPONSE: 'DEVICE_RESPONSE',
  REMOVE_DEVICE: 'REMOVE_DEVICE',
};

const addDeviceResponse = ({ fingerprint, status }) => ({
  type: actionTypes.DEVICE_RESPONSE,
  fingerprint,
  status,
});

const removeDevice = (fingerprints) => ({
  type: actionTypes.REMOVE_DEVICE,
  fingerprints,
});

export { addDeviceResponse, removeDevice };
