import { styled, Dialog } from '@mui/material';
import { COLOR } from '@src/styles/color';
import { BOX_SHADOW } from '@src/styles/config';
import DubbingGiftBannerBackground from '../../../assets/images/dubbing-gift-background.png';

const StyledDubbingGiftDialog = styled(Dialog)`
  .dialog {
    margin: 0;
    box-shadow: none;
    max-width: 640px;
    border-radius: 12px;
    box-shadow: ${BOX_SHADOW};
    background: url(${DubbingGiftBannerBackground});
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .dialog-content {
    padding: 40px 120px 24px 120px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .image {
    width: 100%;
    border-radius: 5px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .title {
    font-size: 20px;
    font-weight: 800;
    color: ${COLOR.indigo[100]};
    text-transform: uppercase;
  }

  .dubbing-logo {
    width: 220px;
  }

  .detail {
    color: ${COLOR.indigo[100]};
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .noti {
      font-weight: 500;
      font-size: 16px;
    }

    .content {
      margin-top: 8px;
      font-weight: 1000;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: flex-end;
      gap: 4px;

      .content-use {
        margin-bottom: 2px;
      }

      .content-gift {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }

  .button {
    border-radius: 480px;
    font-weight: 700;
    border: 7px solid ${COLOR.yellow.default};

    &:hover {
      opacity: 0.9;
    }
  }
`;

export { StyledDubbingGiftDialog };
