import React from 'react';
import { Box } from '@mui/material';
import noDataImage from '@src/assets/images/no-data.svg';
import { StyledNoData } from './index.style';

const NoData = ({ height }) => (
  <Box width="100%" margin="auto">
    <StyledNoData height={height}>
      <img src={noDataImage} alt="no data" />
    </StyledNoData>
  </Box>
);

export default NoData;
