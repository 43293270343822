/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import actions from '@src/redux/actions';
import { v4 as uuidv4 } from 'uuid';

import {
  Editor,
  EditorState,
  ContentState,
  getDefaultKeyBinding,
  Modifier,
  CompositeDecorator,
  SelectionState,
} from 'draft-js';
import {
  Checkbox,
  Typography,
  Stack,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Pause, PlayArrowRounded } from '@mui/icons-material';
import { VOICE_PROVIDER } from '@src/constants/voice';
import { BREAK_LINE_DELIMITER } from '@src/constants';
import BadgeAvatar from '@src/components/BadgeAvatar';
import BreakTime from '@src/components/BreakTime';
import Speed from '@src/components/Speed';
import {
  onHandleKeyCommand,
  handleEditElements,
  countSentenceLength,
  calTotalCharactersExcludingCurrSentence as newCalTotalCharactersExcludingCurrSentence,
  convertElementsToState,
  handlePastedManyBlocks,
  handleSelectedTextOfSentence,
} from '@src/services/sentence';
import Decorator from '@src/components/Decorator';
import { handleSelection } from '@src/services/entity';
import { removeElementInBlock } from '@src/services/paragraph';
import classNames from 'classnames';
import debounce from '@src/utils/debounce';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import { findWithRegexEditor, generateDecorator } from '@src/services/editor';
import VoicesDialog from '@src/components/VoicesDialog';
import BlackWordHighLight from '../BlackWordHighLight';
import { StyledSentenceItem } from './index.style';

const SentenceItem = ({
  sentence,
  isSelected,
  selectedList,
  characterExceed,
  onChangeInputLength,
  // setSelectedList,
  onChangeSentenceItem,
  onChangeCharacterExceed,
  onTryListeningSentence,
  onAddSentences,
  audioRef,
}) => {
  const [content, setContent] = useState(EditorState.createEmpty(Decorator));
  const [openVoices, setOpenVoices] = useState(false);

  const {
    isAudioLoading,
    sentenceId,
    isPlaying,
    audioLink,
    sentenceAudioLink,
    duration,
  } = useSelector((state) => state.audioPlayer);
  const { ttsUser } = useSelector((state) => state.user);
  const {
    sentences,
    ttsAttributeSentence,
    advanceFeature,
    blackWordsInText,
    search,
    replace,
  } = useSelector((state) => state.synthesisRequest);
  const [isInputText, setIsInputText] = useState(false);

  const totalCharacters = ttsUser?.maxLengthInputText || 0;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenVoices = () => setOpenVoices(true);
  const handleCloseVoices = () => setOpenVoices(false);

  const handleChangeVoice = (voice) => {
    onChangeSentenceItem(sentence.id, 'voice', voice);
    handleCloseVoices();
  };

  const handleCheckbox = (e) => {
    const selectedSentencesId = sentence.id;
    let newSelected = [];

    if (e.target.checked) {
      newSelected = [...selectedList, selectedSentencesId];
    } else {
      newSelected = selectedList.filter((item) => item !== selectedSentencesId);
    }
    // setSelectedList(newSelected);
    dispatch(actions.synthesisRequest.updateSelectedSentences(newSelected));
  };

  const calTotalCharactersExcludingCurrSentence = () => {
    const checkValidSentence = (sentenceItem) =>
      sentenceItem.text && sentenceItem.id !== sentence.id;

    return sentences.reduce(
      (sum, item) => (checkValidSentence(item) ? sum + item.text.length : sum),
      0,
    );
  };

  const handleEditorChange = (editorState) => {
    const selectionState = editorState.getSelection();
    if (!selectionState.toJS().hasFocus) return;

    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText('\n');

    const totalInputLength =
      calTotalCharactersExcludingCurrSentence() + text.length;

    onChangeInputLength(totalInputLength);

    const splitSentences = text.split(BREAK_LINE_DELIMITER);
    if (splitSentences.length > 1) {
      const sentenceText = splitSentences[0];
      setContent(
        EditorState.createWithContent(
          ContentState.createFromText(sentenceText),
        ),
      );
      const subSentences = splitSentences.reduce((acc, curr) => {
        if (!curr || !curr.trim()) return acc;
        if (!acc.length) return [{ ...sentence, text: curr }];

        return [
          ...acc,
          { ...sentence, id: uuidv4(), text: curr, onLoad: true },
        ];
      }, []);

      onAddSentences(sentence.id, subSentences);
    } else {
      setContent(editorState);
      onChangeSentenceItem(sentence.id, 'text', text);
    }
    if (totalInputLength > totalCharacters) {
      onChangeCharacterExceed(true);
    } else {
      onChangeCharacterExceed(false);
    }
  };

  const newHandleEditorChange = (editorState) => {
    const selectionState = editorState.getSelection();
    if (!selectionState.toJS().hasFocus) return;

    const contentState = editorState.getCurrentContent();

    const selection = handleSelection({
      selectionState,
      contentState,
      elements: sentence?.elements,
    });
    if (selection) {
      setContent(EditorState.forceSelection(editorState, selection));
      const newSelectedSentence = handleSelectedTextOfSentence({
        selectionState: selection,
        contentState,
        elements: sentence?.elements,
      });
      dispatch(
        actions.synthesisRequest.updateSelectedSentence({
          ...newSelectedSentence,
          sentenceId: sentence.id,
        }),
      );
    } else {
      if (!isInputText) setIsInputText(true);
      setContent(editorState);
    }
  };

  const handleChangeInputLength = (currentTextLength) => {
    const totalInputLength =
      newCalTotalCharactersExcludingCurrSentence(sentences, sentence.id) +
      currentTextLength;

    onChangeInputLength(totalInputLength);

    if (totalInputLength > totalCharacters) {
      onChangeCharacterExceed(true);
    } else {
      onChangeCharacterExceed(false);
    }
  };

  const handlePastedText = (text) => {
    const pastedText = text.trim();

    const textBlocks = pastedText
      .split(BREAK_LINE_DELIMITER)
      .filter((item) => item && item.trim());

    if (textBlocks.length > 1) {
      const pastedPosition = content.getSelection().getStartOffset();
      const currentSentenceLength = countSentenceLength(sentence);
      const pastedTextLength = textBlocks.reduce(
        (acc, cur) => acc + cur.length,
        0,
      );
      const currentTextLength = currentSentenceLength + pastedTextLength;
      handleChangeInputLength(currentTextLength);

      const subSentences = handlePastedManyBlocks(
        textBlocks,
        sentence,
        pastedPosition,
      );
      onAddSentences(sentence.id, subSentences);

      return true;
    }

    const newState = Modifier.replaceText(
      content.getCurrentContent(),
      content.getSelection(),
      pastedText,
    );
    const editorState = EditorState.push(content, newState, 'insert-fragment');
    setContent(editorState);
    if (!isInputText) setIsInputText(true);

    return true;
  };

  const handleChangeSentences = (newSentences) =>
    dispatch(actions.synthesisRequest.updateSentences(newSentences));

  const handleKeyCommand = (command) =>
    onHandleKeyCommand({
      content,
      command,
      sentence,
      sentences,
      onChangeSentences: handleChangeSentences,
    });

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 13) {
      return 'enter_command';
    }
    return getDefaultKeyBinding(e);
  };

  const handleChangeBreakTime = (value) => {
    onChangeSentenceItem(sentence.id, 'breakTime', value);
  };

  const handleChangeSpeed = (name, value) =>
    onChangeSentenceItem(sentence.id, name, value);

  const handleTryListening = () => {
    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
    if (
      sentenceId === sentence.id &&
      sentenceAudioLink &&
      sentenceAudioLink === audioLink
    ) {
      dispatch(actions.audioPlayer.updateStatus(!isPlaying));
      return;
    }

    if (
      sentenceId === sentence.id &&
      sentenceAudioLink &&
      sentenceAudioLink !== audioLink
    ) {
      dispatch(actions.audioPlayer.updateAudioLink(sentenceAudioLink));
      dispatch(actions.audioPlayer.updateSelectedAudioRequest(''));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(
        actions.audioPlayer.updateMetaData({ currentTime: 0, duration: 0 }),
      );
      return;
    }

    if (advanceFeature) {
      onTryListeningSentence(sentence);
    } else {
      onTryListeningSentence(sentence);
    }
  };

  const handleChangeElements = () => {
    // Update elements when editor state changes
    const elements = handleEditElements(content);
    const text = content.getCurrentContent().getPlainText('\n');

    const currentTextLength = countSentenceLength({ elements, text });
    handleChangeInputLength(currentTextLength);

    dispatch(
      actions.synthesisRequest.updateElementOfSentence({
        elements,
        text,
        sentenceId: sentence.id,
      }),
    );
    const start = content.getSelection().getStartOffset();
    dispatch(
      actions.synthesisRequest.updateSelectedSentence({
        startOffset: start,
        sentenceId: sentence.id,
      }),
    );
    setIsInputText(false);
  };

  const isShowLoading =
    (isAudioLoading || (isPlaying && !duration)) && sentenceId === sentence.id;

  const isShowPlaying =
    sentenceId === sentence.id &&
    sentenceAudioLink === audioLink &&
    isPlaying &&
    !!duration &&
    !isAudioLoading;

  const handleShowContent = ({
    elements,
    text,
    mousePointerPosition,
    sentenceItemId,
  }) => {
    const contentState = convertElementsToState(elements, text, sentenceItemId);
    const contentEditor = EditorState.push(content, contentState);
    if (mousePointerPosition !== undefined) {
      const selection = contentEditor.getSelection().merge({
        anchorOffset: mousePointerPosition,
        focusOffset: mousePointerPosition,
      });
      setContent(EditorState.forceSelection(contentEditor, selection));
      onChangeSentenceItem(sentence.id, 'mousePointerPosition', undefined);
    } else {
      setContent(contentEditor);
    }
  };

  const SearchHighlight = ({ children }) => (
    <span className="search-highlight">{children}</span>
  );

  useEffect(() => {
    if (sentence.text) {
      const { elements = [], text, id } = sentence;
      if (advanceFeature) {
        handleShowContent({ elements, text, sentenceItemId: id });
      } else {
        const contentEditor = EditorState.createWithContent(
          ContentState.createFromText(sentence.text),
        );
        setContent(contentEditor);
      }
    }
  }, []);

  useEffect(() => {
    const contentSentence = content.getCurrentContent().getPlainText('\n');
    if (contentSentence) {
      const regex = new RegExp(_.escapeRegExp(search), 'gi');

      const newContent = EditorState.set(content, {
        decorator: generateDecorator(regex, SearchHighlight, search),
      });
      setContent(newContent);
    }
  }, [search]);

  // Update content show for user
  useEffect(() => {
    const contentSentence = content.getCurrentContent().getPlainText('\n');
    if (contentSentence) {
      if (!search || !replace) return;
      const regex = new RegExp(search, 'gi');
      const selectionsToReplace = [];
      const blockMap = content.getCurrentContent().getBlockMap();
      const delta = replace.length - search.length;
      let blockIndex = 0;
      let currentBlockKey = '';
      blockMap.forEach((contentBlock) =>
        findWithRegexEditor(regex, contentBlock, (start, end) => {
          const blockKey = contentBlock.getKey();
          if (currentBlockKey !== blockKey) {
            blockIndex = 0;
            currentBlockKey = blockKey;
          }
          const blockSelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start + blockIndex * delta,
            focusOffset: end + blockIndex * delta,
          });
          blockIndex += 1;
          selectionsToReplace.push(blockSelection);
        }),
      );

      let contentState = content.getCurrentContent();

      selectionsToReplace.forEach((selectionState) => {
        contentState = Modifier.replaceText(
          contentState,
          selectionState,
          replace,
        );
      });
      const newContent = EditorState.push(content, contentState);
      setContent(newContent);
    }
  }, [replace]);

  useEffect(() => {
    if (isInputText && advanceFeature)
      debounce(handleChangeElements, 300)(content);
  }, [content]);

  useEffect(() => {
    if (sentence.onLoad) {
      const { elements = [], text, mousePointerPosition, id } = sentence;
      if (advanceFeature) {
        handleShowContent({
          elements,
          text,
          mousePointerPosition,
          sentenceItemId: id,
        });
      } else {
        const contentEditor = EditorState.createWithContent(
          ContentState.createFromText(sentence.text),
        );

        if (sentence.mousePointerPosition !== undefined) {
          const selection = contentEditor.getSelection().merge({
            anchorOffset: sentence.mousePointerPosition,
            focusOffset: sentence.mousePointerPosition,
          });
          setContent(EditorState.forceSelection(contentEditor, selection));
          onChangeSentenceItem(sentence.id, 'mousePointerPosition', undefined);
        } else {
          setContent(contentEditor);
        }
      }
    }
  }, [sentence.onLoad, sentence.mousePointerPosition]);

  useEffect(() => {
    if (advanceFeature && ttsAttributeSentence?.sentenceId === sentence?.id) {
      const { start, end } = ttsAttributeSentence;
      const newSentence = removeElementInBlock(sentence, start, end);

      const { elements, text, id } = newSentence;
      const currentTextLength = countSentenceLength({ elements, text });
      handleChangeInputLength(currentTextLength);

      handleShowContent({
        elements,
        text,
        mousePointerPosition: start,
        sentenceItemId: id,
      });
      dispatch(actions.synthesisRequest.removeTtsAttributeBySentence({}));
    }
  }, [ttsAttributeSentence?.sentenceId]);

  useEffect(() => {
    if (blackWordsInText?.length > 0) {
      const blackWordsInTextRegex = blackWordsInText.map((blackWord) =>
        blackWord.replaceAll(' ', ' +'),
      );
      const regex = new RegExp(blackWordsInTextRegex.join('|'), 'gi');
      let editorContent = EditorState.createWithContent(
        ContentState.createFromText(sentence.text),
      );
      if (sentence.mousePointerPosition !== undefined) {
        const selection = editorContent.getSelection().merge({
          anchorOffset: sentence.mousePointerPosition,
          focusOffset: sentence.mousePointerPosition,
        });
        editorContent = EditorState.forceSelection(editorContent, selection);
      }
      const stateContent = EditorState.set(editorContent, {
        decorator: new CompositeDecorator([
          {
            strategy: (contentBlock, callback) => {
              findWithRegexEditor(regex, contentBlock, callback);
            },
            component: BlackWordHighLight,
          },
        ]),
      });

      setContent(stateContent);
    }
  }, [blackWordsInText]);

  return (
    <StyledSentenceItem disabled={isSelected}>
      <div className="sentence-toolbar">
        <Checkbox
          checked={isSelected}
          onChange={handleCheckbox}
          classes={{ root: 'check-box' }}
        />
        {!advanceFeature && (
          <BreakTime
            currentBreakTime={sentence.breakTime}
            onChange={handleChangeBreakTime}
            disabled={
              isSelected ||
              sentence?.voice?.provider !== VOICE_PROVIDER.VBEE ||
              !!isShowLoading
            }
            isSentence
            tooltipTitle="endSentenceBreakTime"
            width="40px"
          />
        )}
        <div className="speed-action">
          <Speed
            currentSpeed={sentence.speed}
            voice={sentence.voice}
            onChange={handleChangeSpeed}
            disabled={
              isSelected ||
              (advanceFeature
                ? !!isShowLoading
                : sentence?.voice?.provider === VOICE_PROVIDER.AMAZON ||
                  !!isShowLoading)
            }
            isSentence
            width="60px"
          />
        </div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className="voice-info"
          onClick={!isSelected && !isShowLoading ? handleOpenVoices : undefined}
        >
          <BadgeAvatar
            img={sentence.voice && sentence.voice.roundImage}
            smallImg={
              sentence.voice &&
              sentence.voice.language &&
              sentence.voice.language.roundImage
            }
            type="image"
          />
          <Typography variant="body2">
            {sentence.voice && sentence.voice.name}
          </Typography>
        </Stack>
        <IconButton
          disabled={!sentence.text || !!isShowLoading}
          onClick={handleTryListening}
          className="play-audio"
        >
          {isShowLoading && (
            <CircularProgress size={15} thickness={8} className="loading" />
          )}
          {isShowPlaying && (
            <Tooltip arrow title={t('pause')} placement="bottom">
              <Pause />
            </Tooltip>
          )}
          {!isShowLoading && !isShowPlaying && (
            <Tooltip arrow title={t('playAudioGuide')} placement="bottom">
              <PlayArrowRounded />
            </Tooltip>
          )}
        </IconButton>
      </div>
      <div
        className={classNames('editor-container', {
          'character-exceed': characterExceed,
        })}
      >
        <Editor
          placeholder={t('enterNewSentenceHere')}
          editorState={content}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          stripPastedStyles
          onChange={advanceFeature ? newHandleEditorChange : handleEditorChange}
          handlePastedText={advanceFeature && handlePastedText}
        />
      </div>
      <VoicesDialog
        open={openVoices}
        activeVoiceId={sentence.voice && sentence.voice.id}
        onClose={handleCloseVoices}
        onChangeVoice={handleChangeVoice}
      />
    </StyledSentenceItem>
  );
};

export default SentenceItem;
