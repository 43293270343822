import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { AUTO_RENEW_STATUS } from '@src/constants/subscription';
import { PAYMENT_METHOD_TYPE } from '@src/constants/payment';
import useElementWidth from '@src/hooks/useElementWidth';
import { StyledPaymentMethodItem } from '../ConfigAutoRenew/index.style';

const PaymentMethodItem = ({
  method,
  isAutoRenewYearPackage,
  isAutoRenewalPackage,
  selected,
  onSelect,
  useAutoRenewWithMomo,
  useNewBankTransfer,
}) => {
  const { t } = useTranslation();

  const isUpcomingMethod =
    method.autoRenewStatus === AUTO_RENEW_STATUS.UPCOMING;
  const paymentMethodItemRef = useRef();
  const elementWidth = useElementWidth(paymentMethodItemRef);

  // Disable momo pay if auto renew year package and not use auto renew with momo
  const isDisabled =
    (method.type === PAYMENT_METHOD_TYPE.MOMOPAY && isAutoRenewYearPackage) ||
    (method.type === PAYMENT_METHOD_TYPE.MOMOPAY &&
      isAutoRenewalPackage &&
      !useAutoRenewWithMomo);

  const handleSelectBank = () => {
    if (!(isUpcomingMethod || isDisabled)) onSelect(method);
  };

  const paymentMethodName = useMemo(() => {
    if (!useNewBankTransfer) return method?.name;
    if (method.type === PAYMENT_METHOD_TYPE.BANK && elementWidth < 200)
      return method?.name?.replace('ngân hàng', '').trim();

    return method?.name;
  }, [elementWidth, method]);

  return (
    <StyledPaymentMethodItem
      disabled={isUpcomingMethod || isDisabled}
      selected={selected}
      onClick={handleSelectBank}
      ref={paymentMethodItemRef}
    >
      <div className="logo">
        <img src={method && method.logo} alt="i" className="logo-img" />
      </div>
      <Typography className="name">{`${paymentMethodName} ${
        isUpcomingMethod ? `(${t('haveSoon')})` : ''
      } `}</Typography>
    </StyledPaymentMethodItem>
  );
};
export default PaymentMethodItem;
