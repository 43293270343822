import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { StyledActionTooltip, StyledDownloadAudioButton } from './index.style';

const DownloadAudioButton = ({
  hasDisable,
  onClick,
  getTitleActionTooltip,
}) => {
  const { t } = useTranslation();

  const handleDownloadAudio = async () => {
    if (hasDisable) return;
    onClick();
  };

  return (
    <StyledActionTooltip
      title={getTitleActionTooltip('')}
      arrow
      placement="bottom"
      disabled={hasDisable}
    >
      <StyledDownloadAudioButton
        className="download-button default-background"
        onClick={handleDownloadAudio}
        role="button"
        tabIndex="0"
        disabled={hasDisable}
      >
        <FileDownloadOutlined className="download-icon" />
        <Typography className="text-download">{t('downloadAudio')}</Typography>
      </StyledDownloadAudioButton>
    </StyledActionTooltip>
  );
};

export default DownloadAudioButton;
