import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import route from '@src/constants/route';
import { StyledDialog } from './index.style';

const Dialog = ({
  dialogName,
  openDialog,
  selectedOrder,
  handleCloseAutoRenewalDialog,
  handleConfirmCancelAutoRenew,
  handleCloseRegisterAutoRenewalDialog,
}) => {
  const { t } = useTranslation();
  const [leftLoading, setLeftLoading] = useState(false);
  const [rightLoading, setRightLoading] = useState(false);
  const history = useHistory();
  const activeDialogName = Object.keys(openDialog).find(
    (key) => openDialog[key],
  );

  const DIALOG_LIST = [
    {
      name: dialogName.CANCEL_AUTO_RENEWAL,
      title: t('confirm'),
      description: t('confirmCancelAutoRenewDescription'),
    },
    {
      name: dialogName.REGISTER_AUTO_RENEWAL,
      title: t('confirm'),
      description: t('confirmRegisterAutoRenewDescription'),
    },
  ];

  const activeDialog = DIALOG_LIST.find(
    (dialog) => dialog.name === activeDialogName,
  );

  const handleCancelAutoRenew = async () => {
    setLeftLoading(true);
    await handleConfirmCancelAutoRenew();
    setLeftLoading(false);
  };

  const handleRegisterAutoRenew = async () => {
    setRightLoading(true);

    const { packageExpiryDate } = selectedOrder;
    const isPackageExpired =
      packageExpiryDate && moment(packageExpiryDate).isBefore(moment());
    const redirectUrl = isPackageExpired
      ? `${route.PAYMENT}?package=${selectedOrder?.package?.code}`
      : route.CONFIG_AUTO_RENEW;
    history.push(redirectUrl);

    setRightLoading(false);
  };

  const DialogContent = () => (
    <>
      <Box className="title-wrapper ">
        <Typography className="title-content">{activeDialog?.title}</Typography>
      </Box>
      <Divider />
      <Box className="content-wrapper">
        <Typography
          className="content"
          dangerouslySetInnerHTML={{
            __html: activeDialog?.description,
          }}
        />
      </Box>
    </>
  );

  const DialogActions = () => {
    switch (activeDialogName) {
      case dialogName.CANCEL_AUTO_RENEWAL:
        return (
          <Box className="action-wrapper">
            <LoadingButton
              className="action-button left-button"
              onClick={handleCancelAutoRenew}
              variant="outlined"
              loading={leftLoading}
              disabled={leftLoading}
            >
              {leftLoading ? '' : t('yesValue')}
            </LoadingButton>
            <Button
              variant="contained"
              className="action-button reject-button"
              onClick={handleCloseAutoRenewalDialog}
            >
              {t('notAgree')}
            </Button>
          </Box>
        );
      case dialogName.REGISTER_AUTO_RENEWAL:
        return (
          <Box className="action-wrapper">
            <Button
              className="action-button left-button"
              onClick={handleCloseRegisterAutoRenewalDialog}
              variant="outlined"
              loading={leftLoading}
              disabled={leftLoading}
            >
              {t('notAgree')}
            </Button>
            <LoadingButton
              onClick={handleRegisterAutoRenew}
              loading={rightLoading}
              disabled={rightLoading}
              variant="contained"
              className="action-button reject-button"
            >
              {rightLoading ? '' : t('yesValue')}
            </LoadingButton>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <StyledDialog open={openDialog[activeDialog?.name]} fullWidth maxWidth="sm">
      <Box className="wrapper">
        <DialogContent />
        <DialogActions />
      </Box>
    </StyledDialog>
  );
};

export default Dialog;
