import { styled } from '@mui/material/styles';
import { TableCell, TableContainer } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledTableCell = styled(TableCell)`
  .header-cell {
    background-color: ${COLOR.indigo[4]};
    color: ${COLOR.dark};
    padding: 6px 12px;
    width: ${(props) => (props.width ? props.width : 'auto')};
  }
  .header-title {
    font-weight: bold;
    font-size: 14px;
  }
  .header-checkbox-cell {
    background-color: ${COLOR.primary};
    color: ${COLOR.white};
    padding: 4px 6px;
    width: 2%;
  }
  .body-cell {
    border-bottom: 1px solid ${COLOR.divider};
    padding: 0px 12px;
    width: ${(props) => (props.cellWidth ? props.cellWidth : 'auto')};
  }
  .checkbox-cell {
    border-bottom: 1px solid ${COLOR.divider};
    padding: 0px 8px;
    width: ${(props) => (props.cellWidth ? props.cellWidth : 'auto')};
    width: 2%;
  }
  .hidden {
    display: none !important;
  }
`;

const StyledTableScrollbar = styled('div')`
  width: 100%;
  .sidebar-wrapper {
    overflow: auto;
    flex: 1;
    position: relative;
    color: inherit;
    @media screen and (max-width: 600px) {
      padding-bottom: 88px;
    }
  }

  .hidden-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar {
      width: 4px;
      background: ${COLOR.white};
    }
  }

  .small-scrollbar {
    // scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${COLOR.indigo[64]};
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${COLOR.indigo[80]};
      border-radius: 100px;
    }
  }
`;

const StyledTable = styled(TableContainer)`
  max-height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.height || 'auto'};
  max-width: 100%
  background: ${COLOR.white};

  .header-cell {
    background-color: ${(props) =>
      props.stickyHeader ? '#f6f6f8' : COLOR.indigo[4]};
    color: ${COLOR.dark};
    padding: ${(props) =>
      props.headerPadding ? props.headerPadding : '6px 12px'};
    z-index: 10;
  }

  .header-checkbox-cell {
    background-color: ${(props) =>
      props.stickyHeader ? '#f6f6f8' : COLOR.indigo[4]};
    color: ${COLOR.white};
    padding: 4px 6px;
    width: 2%;
  }

  .header-item {
    display: flex;
    align-items: center;
    & .MuiIconButton-root {
      padding-right: 0px;
      padding-left: 0px;
      margin-left: 8px;
      margin-right: -8px;
    }
  }

  .header-left {
    justify-content: left;
  }

  .header-right {
    justify-content: right;
  }

  .header-center {
    justify-content: center;
  }

  .header-title {
    font-weight: bold;
    font-size: 14px;
  }

  .header-icon,
  .header-checkbox {
    color: ${COLOR.darkBlue} !important;
  }

  .body-checkbox {
    color: ${COLOR.darkBlue} !important;
    margin-left: -1.3px;
  }

  .body-row {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
    &:hover {
      background: ${TRANSPARENT_COLOR.dark};
      position: relative;

      .hover-visible {
        display: flex;
        position: absolute;
        right: 0px;
        z-index: 1;
        bottom: 1; // Fix action buttons not appearing on the same line when hovering a table row in Safari. I don't know why it is not work with bottom: 0.
        border: 0;
        background: linear-gradient(
            ${TRANSPARENT_COLOR.dark},
            ${TRANSPARENT_COLOR.dark}
          ),
          linear-gradient(white, white);
      }
    }

    .hover-visible {
      display: none;
    }
    /* cursor: pointer; */
  }

  .body-cell {
    border-bottom: 1px solid ${COLOR.divider};
    padding: 12px 12px;
    width: ${(props) => (props.cellWidth ? props.cellWidth : 'auto')};
  }

  .checkbox-cell {
    border-bottom: 1px solid ${COLOR.divider};
    padding: 6px 8px;
    width: ${(props) => (props.cellWidth ? props.cellWidth : 'auto')};
    width: 2%;
  }
`;

export const StyledFooterNoData = styled('div')`
  height: 21px;
`;

export const StyledFooterLoading = styled('div')`
  height: 46px;
`;

export const StyledNodataLoading = styled('div')`
  height: ${(props) => props.height || 'auto'};
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: top;
`;

export const StyledTableFooter = styled('div')`
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding: ${({ footerpadding }) => footerpadding || '20px'};

  .Mui-selected {
    color: ${COLOR.darkBlue} !important;
  }

  .one-line {
    display: flex;
    justify-content: center;
    margin-top: 1px;
  }

  .pagination {
    margin-right: 8px;
  }

  .button-select-amount-page {
    width: 10px;
    height: 20px;
    border: 1px solid gray;
    margin-left: 5px;
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
  }

  .footer-label {
    font-weight: 600;
    padding-top: 10px;
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: row;

    .footer-label {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;

    .footer-label {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;

export { StyledTableCell, StyledTable, StyledTableScrollbar };
