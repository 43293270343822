import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BadgeAvatar from '@src/components/BadgeAvatar';
import ImportantTag from '@src/components/ImportantTag';
import { COLOR } from '@src/styles/color';
import IconPlay from '@src/assets/icons/play.svg';
import IconPause from '@src/assets/icons/pause.svg';
import IconPlusBold from '@src/assets/icons/plus-bold.svg';
import { VOICE_LEVEL } from '@src/constants/voice';
import actions from '@src/redux/actions';
import route from '@src/constants/route';
import ArrowRight from '@src/assets/icons/arrow-right-ios.svg';
import { PLAYING_AUDIO_TYPE, SILENCE_AUDIO_URL } from '@src/constants/tts';
import { StyledVoiceList } from './index.style';

const VoiceList = ({ voiceList, onChangeVoiceList }) => {
  const { t } = useTranslation();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const [audioLink, setAudioLink] = useState('');
  const containerRef = useRef(null);
  const audioRef = createRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [audioType, setAudioType] = useState(PLAYING_AUDIO_TYPE.SILENCE);

  const scrollLeft = () => {
    // Scroll left to next items
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const currentScrollLeft = containerRef.current.scrollLeft;
      const scrollAmount = Math.max(0, currentScrollLeft - containerWidth);
      containerRef.current.scroll({
        left: scrollAmount,
        behavior: 'smooth', // This property is not supported in some browsers
      });
    }
  };

  const scrollRight = () => {
    // Scroll right to next items
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const currentScrollLeft = containerRef.current.scrollLeft;
      const containerScrollWidth = containerRef.current.scrollWidth;
      const scrollAmount = Math.min(
        containerScrollWidth - containerWidth,
        currentScrollLeft + containerWidth,
      );
      containerRef.current.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    // This function use to length of list is small (not scroll), 2 buttons left and right will hide
    const container = containerRef.current;
    if (container) {
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(
        container.scrollLeft < container.scrollWidth - container.clientWidth,
      );
    }
  };

  const handleUseVoice = (voice) => {
    dispatch(actions.synthesisRequest.updateVoice(voice));
    history.push(route.TTS);
  };

  const getImportantTagName = (voice) => {
    if (voice?.beta) return t('beta');
    if (voice?.level === VOICE_LEVEL.PREMIUM) return t(voice?.level);
    return '';
  };

  const getImportantTagBg = (voice) => {
    if (voice?.beta) return COLOR.skyBlue;
    return COLOR.primary;
  };

  const handleAudioEnd = () => {
    if (audioType === PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      const newVoices = voiceList.map((item) =>
        item.playStatus ? { ...item, playStatus: false } : item,
      );
      onChangeVoiceList(newVoices);
      return;
    }

    if (audioLink) {
      setAudioType(PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC);
      audioRef.current.src = audioLink;
      audioRef.current.load();
      audioRef.current.play();
      return;
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const handleChangePlayAudio = (voice) => {
    const audio = audioRef.current;
    if (!audioLink || voice.sample?.audioLink !== audioLink) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      audio.src = SILENCE_AUDIO_URL;
      audio.load();
      audio.play();
      setAudioLink(voice.sample?.audioLink);
      audio.load();
    }

    if (voice.playStatus) {
      audio.pause();
    } else {
      audio.play();
    }

    const newVoices = voiceList.map((item) =>
      item.code === voice.code
        ? { ...voice, playStatus: !voice.playStatus }
        : { ...item, playStatus: false },
    );
    onChangeVoiceList(newVoices);
  };

  useEffect(() => {
    if (voiceList?.length > 0) {
      handleScroll();
      containerRef.current.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleScroll);
    };
  }, [voiceList?.length]);

  return (
    <StyledVoiceList>
      <span className="title">{t('startWithYourVoice')}</span>
      <div className="voice-container" ref={containerRef}>
        {voiceList.map((voice) => (
          <div className="voice-wrapper" key={voice.code}>
            <span className="title">{t(voice?.sample?.style)}</span>
            <div className="voice-info">
              <BadgeAvatar
                width={48}
                img={voice?.roundImage}
                type="image"
                smallImgWidth={16}
                smallImg={voice?.languageImage}
              />
              <div className="voice">
                <div className="">
                  <div className="name">{voice.name}</div>
                  {getImportantTagName(voice) && (
                    <ImportantTag
                      tag={getImportantTagName()}
                      top="0px"
                      right="0px"
                      padding="5px 5px 4px 15px"
                      borderRadius="0 4px 0px 20px"
                      bgColor={getImportantTagBg(voice)}
                      color={COLOR.darkBlue}
                    />
                  )}
                </div>
                <span className="type">
                  {voice?.styles?.length &&
                    voice.styles.map((item) => t(item)).join(' - ')}
                </span>
              </div>
            </div>
            <div className="use">
              {voice.playStatus ? (
                <div
                  className="play-btn play-btn-active"
                  onClick={() => handleChangePlayAudio(voice)}
                  tabIndex={0}
                  role="button"
                >
                  <img src={IconPause} alt="play" />
                </div>
              ) : (
                <div
                  className="play-btn"
                  onClick={() => handleChangePlayAudio(voice)}
                  tabIndex={0}
                  role="button"
                >
                  <img src={IconPlay} alt="play" />
                </div>
              )}
              <div
                className="use-now-btn"
                onClick={() => handleUseVoice(voice)}
                role="button"
                tabIndex={0}
              >
                <img src={IconPlusBold} alt="play" />
                <span className="use-now-txt">{t('useNow')}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} onEnded={handleAudioEnd}>
        <source src={audioLink} type="audio/wav" />
      </audio>
      {showLeftButton && (
        <div
          className="left-btn"
          onClick={scrollLeft}
          role="button"
          tabIndex={0}
        >
          <img src={ArrowRight} alt="arrow" />
        </div>
      )}
      {showRightButton && (
        <div
          className="right-btn"
          onClick={scrollRight}
          role="button"
          tabIndex={0}
        >
          <img src={ArrowRight} alt="arrow" />
        </div>
      )}
    </StyledVoiceList>
  );
};

export default VoiceList;
