import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledUserGuide = styled('div')`
  background: ${COLOR.indigo[8]} !important;
  color: ${COLOR.darkBlue};
  border-radius: ${BORDER_RADIUS};
  padding: 8px 20px;

  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  .display-flex {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    & .MuiIconButton-root {
      padding: 0px;
      margin-right: -4px;
    }
  }

  .user-guide-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLOR.darkBlue};
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 4px 16px;
    border-radius: 25px;

    &:focus {
      background: inherit;
    }
  }
`;
