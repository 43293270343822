import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { StyledSearch } from './index.style';

const SearchRadio = ({
  search,
  value,
  name,
  title,
  options,
  open,
  onChangeOpen,
  onChange,
}) => {
  const { t } = useTranslation();

  const isChecked = (searchItem) => value === searchItem;

  const handleChangeSearch = (e) => {
    const newSearch = { ...search, [name]: e.target.value };
    onChange(newSearch);
  };

  return (
    <StyledSearch expanded={open} onChange={() => onChangeOpen(name)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{ root: 'search-item-title', expanded: 'accordion-expanded' }}
      >
        <Typography variant="subtitle2">
          {title ? t(title) : t(name)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="search-item-content">
        <FormGroup aria-label="position" row className="form-group">
          {options.map((item) => (
            <FormControlLabel
              key={item.label}
              control={<Radio className="checkbox-color" />}
              labelPlacement="end"
              label={t(item.label)}
              value={item.value}
              checked={isChecked(item.value)}
              onChange={handleChangeSearch}
              classes={{ label: 'checkbox-label' }}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </StyledSearch>
  );
};

export default SearchRadio;
