import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '@src/constants/route';
import MobileTTSPage from '@src/pages/MobileTTS';
import MobileTTSRequests from '@src/pages/MobileTTSRequests';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { StyledContentMobileTTS, StyledLayoutMobileTTS } from './index.styled';

const HEADER_MOBILE_HEIGHT = 64;
const BANNER_MOBILE_HEIGHT = 48;
const TAB_HEIGHT = 48;
const MARGIN_OTHER = 32;

const LayoutMobileTTS = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { headerVoucher } = useSelector((state) => state.voucher);

  const heightContent =
    height -
    HEADER_MOBILE_HEIGHT -
    (headerVoucher ? BANNER_MOBILE_HEIGHT : 0) -
    TAB_HEIGHT -
    MARGIN_OTHER;

  const handleClickTTSMobile = () => history.push(routes.MOBILE_TTS);
  const handleClickTTSRequests = () => history.push(routes.MOBILE_REQUESTS);

  const location = useLocation();
  const path = useMemo(() => location.pathname, [location.pathname]);

  return (
    <StyledLayoutMobileTTS>
      <div className="tabs-header">
        <div
          role="button"
          tabIndex={0}
          className={`package-tab ${
            path === routes.MOBILE_TTS ? 'package-active-tab' : ''
          }`}
          onClick={handleClickTTSMobile}
        >
          {t('convert')}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`package-tab ${
            path === routes.MOBILE_REQUESTS ? 'package-active-tab' : ''
          }`}
          onClick={handleClickTTSRequests}
        >
          {t('history')}
        </div>
      </div>
      <StyledContentMobileTTS height={heightContent}>
        <Route path={routes.MOBILE_TTS} exact component={MobileTTSPage} />
        <Route
          path={routes.MOBILE_REQUESTS}
          exact
          component={MobileTTSRequests}
        />
      </StyledContentMobileTTS>
    </StyledLayoutMobileTTS>
  );
};

export default LayoutMobileTTS;
