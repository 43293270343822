import { styled } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledChip = styled(Typography)`
  background-color: ${({ color }) => TRANSPARENT_COLOR[color]};
  color: ${({ color }) => COLOR[color]};
  padding: 1px 10px;
  border-radius: 17px;
  font-size: 13px;
  font-weight: 500;
  width: 80px;
  text-align: center;
  white-space: nowrap;
`;

export const StyledErrorReport = styled('div')`
  .title {
    margin: 0 15px 15px 15px;
    font-weight: 500;
  }

  .dialog-action {
    margin: 0 15px 15px 0;
  }

  .information-column {
    display: grid;
    grid-template-columns: 120px auto;
    grid-column-gap: 15px;
    grid-auto-rows: min-content;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: ${({ padding }) => padding || '0px'};
  height: ${({ height }) => height || '10px'};
  width: ${({ width }) => width || 'auto'};
  border-radius: ${({ borderRadius }) => borderRadius || '100%'};

  .normal-button {
    transform: translate(0px, 3px);
    color: ${({ disabled }) => (disabled ? COLOR.light : COLOR.darkBlue)};
    &:hover {
      color: ${COLOR.success};
      opacity: 1;
    }
  }

  .delete-button {
    color: ${COLOR.red};
  }

  color: ${COLOR.darkBlue};
  opacity: 0.75;

  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    opacity: 1;
    .icon {
      color: ${({ colorHover }) => COLOR[colorHover]};
    }
  }

  .text-action {
    font-size: 14px;
    color: ${COLOR.darkBlue};
    &:hover {
      color: ${COLOR.black};
    }
  }

  .download-link {
    color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
  }
`;

export const StyledRequestAction = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: fit-content;
  gap: 8px;
`;

export const StyledListDubbing = styled('div')`
  margin-top: 20px;
  padding-bottom: 10px;
  background: ${COLOR.white};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .in-progress-status {
    min-width: 100px;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(3px, 0px);
  }

  .linear-progress-wrapper {
    width: 100%;
    margin-right: 10px;
  }
`;

export const StyleVoiceItem = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
`;

export const StyledTableTitle = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  table-layout: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 392px;
  min-width: 350px;
  justify-content: center;
  font-weight: 550;
  color: ${COLOR.darkBlue};

  .title-time {
    opacity: 0.8;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: ${COLOR.label[80]};
  }
`;
