import { styled } from '@mui/material/styles';
import { Popper } from '@mui/material';
import { COLOR } from '@src/styles/color';

const StyledPopconfirm = styled(Popper)`
  position: relative;
  z-index: 1000;
  width: ${(props) => props.width || 'auto'};
  .paper {
    overflow: auto;
    margin-bottom: 6px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
  }
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
  .content {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.lightText};
  }

  .description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0));
    bottom: 1px;
  }
`;

export { StyledPopconfirm };
