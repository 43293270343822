import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import apis from '@src/apis';
import { Box, Typography } from '@mui/material';
import { LocalOfferOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
// import giftIcon from '@src/assets/icons/gift.png';
import actions from '@src/redux/actions';
import { ORDER_STATUS } from '@src/constants/order';
import { verifyPolicy, verifyTime } from '@src/services/voucher';

import { PAYMENT_TOUR_GUIDE } from '@src/constants/tourGuide';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import { delimitNumber, getRandomIntNumber } from '@src/utils/number';
// import { BANNER_TYPE } from '@src/constants/banner';
import i18n from '@src/languages';
import CommonBox from '../CommonBox';
import { StyledVoucherBox } from './index.style';
import VoucherDialog from './VoucherDialog';

const VoucherBox = ({ currPackage }) => {
  const [vouchers, setVouchers] = useState([]);
  const [selectableAmount, setSelectableAmount] = useState(0);
  const [openVoucherDialog, setOpenVoucherDialog] = useState(false);

  const { t } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const { appliedVoucher } = useSelector((state) => state.voucher);
  const {
    latestOrder,
    // usingPackage
  } = useSelector((state) => state.user);
  const { autoVouchers } = useSelector((state) => state.voucher);

  const setVoucherList = (voucherData) => {
    let countSatisfied = 0;

    const newVouchers = voucherData.reduce((acc, currVoucher) => {
      const satisfiedTime = verifyTime(currVoucher);
      if (!satisfiedTime) return acc;

      const satisfiedPolicy = verifyPolicy(currVoucher, currPackage.id);
      const canUse =
        satisfiedPolicy &&
        satisfiedTime &&
        currVoucher.remainingAmount &&
        // !currVoucher.used &&
        !currVoucher.isPrivate &&
        currVoucher.usedAmount < currVoucher.numReuse;
      if (canUse) countSatisfied += 1;
      return [
        ...acc,
        { ...currVoucher, satisfiedPolicy, satisfiedTime, canUse },
      ];
    }, []);

    const sortVouchers = newVouchers.sort((voucher) =>
      voucher.canUse ? -1 : 1,
    );

    setSelectableAmount(countSatisfied);
    setVouchers(sortVouchers);
  };

  const fetchVouchers = async () => {
    const data = await apis.vouchers.getVouchers();
    if (data.status) setVoucherList(data.result);
  };

  const handleOpenVoucherDialog = () => setOpenVoucherDialog(true);

  const handleCloseVoucherDialog = () => setOpenVoucherDialog(false);

  const handleUnselectVoucher = () => {
    if (latestOrder?.status !== ORDER_STATUS.PENDING)
      dispatch(actions.voucher.unselectVoucher());
  };

  const getVoucherText = (voucher) => {
    const value = delimitNumber(voucher.value, language);

    switch (voucher.type) {
      case VOUCHER_TYPE.PERCENT:
        return `${t('reduce')} ${value}%`;
      case VOUCHER_TYPE.PRICE:
        return `${t('reduce')} ${value}VND`;
      case VOUCHER_TYPE.CHARACTER:
        return `x${value} ${t('characters')}`;
      case VOUCHER_TYPE.TIME:
        return `${t('extra')} ${value} ${t('day')}`;
      case VOUCHER_TYPE.PERCENT_TIME:
        return `${t('extra')} ${value}% ${t('day')}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (currPackage) fetchVouchers();
  }, [currPackage]);

  useEffect(() => {
    handleUnselectVoucher();
  }, []);

  const checkCanApplyVoucher = (autoVoucherCode, bannerType) => {
    let canApplyAutoVoucher;
    const isBuyingPaidPackage = currPackage.price > 0;
    switch (bannerType) {
      // case BANNER_TYPE.PACKAGE_EXPIRY: {
      //   const hasUpgradePackage =
      //     usingPackage &&
      //     usingPackage.price > 0 &&
      //     currPackage.rank > usingPackage.rank;

      //   canApplyAutoVoucher = hasUpgradePackage && isBuyingPaidPackage;
      //   break;
      // }
      default:
        canApplyAutoVoucher = isBuyingPaidPackage;
        break;
    }
    if (!canApplyAutoVoucher) return false;

    const matchedVoucher = vouchers.find(
      (voucher) => voucher.code === autoVoucherCode,
    );
    const validVoucher =
      matchedVoucher &&
      matchedVoucher.satisfiedPolicy &&
      matchedVoucher.satisfiedTime &&
      // !matchedVoucher.used &&
      matchedVoucher.usedAmount < matchedVoucher.numReuse &&
      matchedVoucher.remainingAmount;
    return validVoucher;
  };

  useEffect(() => {
    if (autoVouchers.length) {
      // TODO: Get voucher has max value
      const randomPosition = getRandomIntNumber(0, autoVouchers.length - 1);
      const autoVoucher = autoVouchers[randomPosition];
      const { voucherCode, bannerType } = autoVoucher;

      const canApplyVoucher = checkCanApplyVoucher(voucherCode, bannerType);
      if (canApplyVoucher) {
        const matchedVoucher = vouchers.find(
          (voucher) => voucher.code === voucherCode,
        );
        dispatch(
          actions.voucher.applyVoucher({
            ...matchedVoucher,
            displayText: getVoucherText(matchedVoucher),
          }),
        );
      }
    }
  }, [autoVouchers, vouchers]);

  return (
    <div id={PAYMENT_TOUR_GUIDE.USE_VOUCHER.title}>
      <CommonBox boldTitle title={t('promotion')}>
        <StyledVoucherBox>
          {!appliedVoucher ? (
            <>
              <Box className="choose" onClick={handleOpenVoucherDialog}>
                {/* <img src={giftIcon} alt="icon" /> */}
                <Typography className="choose-text">
                  {t('chooseVoucherTitle')}
                </Typography>
              </Box>
              <Typography className="note">
                {t('chooseVoucherNote', { amount: selectableAmount })}
              </Typography>
            </>
          ) : (
            <>
              <div className="applied-info">
                <Box className="choose">
                  <LocalOfferOutlined className="tag" />
                  <Typography className="tag">
                    {appliedVoucher?.displayText}
                  </Typography>
                </Box>
                <Typography
                  className="unselect"
                  onClick={handleUnselectVoucher}
                >
                  {t('unselect')}
                </Typography>
              </div>
              <Box className="choose">
                {/* <img src={giftIcon} alt="icon" /> */}
                <Typography
                  className="choose-text"
                  onClick={handleOpenVoucherDialog}
                >
                  {t('chooseVoucherOtherTitle')}
                </Typography>
              </Box>
            </>
          )}
        </StyledVoucherBox>
        <VoucherDialog
          open={openVoucherDialog}
          vouchers={vouchers}
          getVoucherText={getVoucherText}
          onClose={handleCloseVoucherDialog}
          appliedVoucher={appliedVoucher}
          autoVouchers={autoVouchers}
          checkCanApplyVoucher={checkCanApplyVoucher}
        />
      </CommonBox>
    </div>
  );
};

export default VoucherBox;
