import { styled } from '@mui/material/styles';
import { MenuItem, Popover, Slider } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { AUDIO_PLAYER_HEIGHT, BREAKPOINTS } from '@src/styles/config';

export const StyledAudioPlayer = styled('div')`
  padding: 6px 16px 6px 16px;
  height: ${AUDIO_PLAYER_HEIGHT - 12}px;
  position: fixed;
  bottom: 0;
  right: 0;
  background: ${COLOR.white};
  z-index: 10;
  display: ${(props) => (props.showaudioplayer ? 'block' : 'none')};

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    left: 0 !important;
  }

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    left: ${(props) => `${props.sidebarwidth}px`};
  }

  .container {
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .mobile-container {
    flex-direction: column;
    gap: 5px;
  }

  .mobile-action {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  .voice {
    display: flex;
    gap: 12px;
    align-items: center;

    .voice-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .voice-name {
        font-size: 14px;
        font-weight: 400;
        color: ${COLOR.lightText};
      }
    }
  }

  .audio-action {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .audio-action-button {
    padding: 6px;
    border-radius: 50%;
    min-width: 0;
  }

  .audio-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .action {
    justify-content: flex-end;
    align-items: center;
  }

  .button-active {
    background-color: ${COLOR.secondary[80]};
  }

  @media only screen and (max-width: ${BREAKPOINTS.LG}px) {
    .title {
      width: 150px;
    }

    .container {
      display: none;
      gap: 10px;
    }

    .mobile-container {
      display: flex;
    }

    .audio-wrapper {
      width: 100%;
    }
  }

  @media only screen and (min-width: ${BREAKPOINTS.LG}px) {
    .title {
      width: 200px;
    }

    .container {
      display: flex;
      gap: 24px;
    }

    .mobile-container {
      display: none;
    }
  }
`;

export const StyledAudio = styled('div')`
  min-width: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const StyledAudioSlider = styled(Slider)({
  height: 4,
  padding: 0,

  '& .MuiSlider-track': {
    border: 'none',
  },

  '& .MuiSlider-rail': {
    color: COLOR.secondary[24],
  },
});

export const StyledVolume = styled(Popover)`
  .MuiPaper-root {
    border-radius: 100px;
  }

  .volume-wrapper {
    display: flex;
    height: 200px;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    gap: 3px;
  }
`;

export const StyledSlider = styled(Slider)`
  color: ${COLOR.primary};
  height: 150px;

  & .MuiSlider-valueLabel {
    font-weight: bold;
    font-size: 14px;
    top: -8px;
    background-color: unset;
    color: ${COLOR.text};

    &:before {
      display: none;
    }
  }

  & .MuiSlider-thumb {
    height: 14px;
    width: 14px;
    background-color: ${COLOR.white};
    border: 1px solid currentColor;
  }

  & .MuiSlider-rail {
    color: ${COLOR.secondary[24]};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  gap: 5px;
  padding: 8px 16px;

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }

  .text-action {
    font-size: 16px;
    font-weight: 510;
    color: ${COLOR.darkBlue};
  }
`;
