import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledBuyPackage = styled('div')`
  .flex-align-center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .transition-btn {
    text-transform: unset;
    font-weight: 600;

    img {
      margin-left: 12px;
    }
  }

  .payment-history {
    transition: opacity 1s ease-out;
    opacity: ${(props) => (props.showPaymentHistory ? '1' : '0')};
  }

  .packages {
    transition: opacity 1s ease-out;
    height: ${(props) => (props.showPaymentHistory ? '0' : 'auto')};
    opacity: ${(props) => (props.showPaymentHistory ? '0' : '1')};
  }

  .policy-voice {
    font-bold: 500;
    color: ${COLOR.black};
    text-align: left;
  }

  .notice-ai-voice {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #3c3c43a3;
  }
`;

export { StyledBuyPackage };
