import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DialogActions, Typography, Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@src/assets/icons/warning-orange.svg';
import apis from '@src/apis';
import { CANCEL_ORDER_TIMER, ORDER_STATUS } from '@src/constants/order';
import shopeePayIcon from '@src/assets/icons/shopee-pay.svg';
import cameraScanIcon from '@src/assets/icons/camera-scan.svg';
import qrCodeIcon from '@src/assets/icons/qr_code.svg';
import actions from '@src/redux/actions';
import { PACKAGE_TYPE } from '@src/constants/package';
import { formatAudioTime } from '@src/utils/time';
import { delimitNumber } from '@src/utils/number';
import { checkVietNam } from '@src/utils/checkCountry';
import { TIMER_INTERVAL } from '@src/constants';
import moment from 'moment';
import { StyledPaymentDialog, StyledTransferInformation } from './index.style';

const QRPaymentDialog = ({
  open,
  paymentInfo,
  totalPrice,
  onChangeLoading,
  onCloseQrDialog,
  isDubbingRoute,
}) => {
  const [validTime, setValidTime] = useState(
    formatAudioTime(CANCEL_ORDER_TIMER.ONLINE_GATEWAY),
  );
  const [expiredPayment, setExpiredPayment] = useState(false);

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const isVietNam = checkVietNam();
  const { latestOrder, latestDubbingOrder } = useSelector(
    (state) => state.user,
  );

  const handleCancelPendingOrder = async () => {
    if (!isDubbingRoute && latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (isDubbingRoute && latestDubbingOrder.status !== ORDER_STATUS.PENDING)
      return;

    onChangeLoading(true);
    const data = await apis.orders.cancelOrder(
      isDubbingRoute ? latestDubbingOrder?.id : latestOrder.id,
    );
    onChangeLoading(false);
    if (!data.status) {
      dispatch(actions.noti.push({ severity: 'error', code: data.code }));
    } else if (data.result.type === PACKAGE_TYPE.STUDIO)
      dispatch(actions.user.getLatestOrderSuccess(data.result));
    else if (data.result.type === PACKAGE_TYPE.DUBBING)
      dispatch(actions.user.getLatestDubbingOrderSuccess(data.result));
    onCloseQrDialog();
  };

  const notes = [
    { logo: shopeePayIcon, title: 'loginApp', description: 'ShopeePay' },
    { logo: qrCodeIcon, title: 'chooseFeature', description: 'scanQRCode' },
    { logo: cameraScanIcon, title: 'move', description: 'cameraAndScan' },
  ];

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (paymentInfo?.createdAt) {
      const timerInterval = setInterval(() => {
        const diffTime = moment().diff(
          moment(paymentInfo.createdAt),
          'seconds',
        );
        if (diffTime < CANCEL_ORDER_TIMER.ONLINE_GATEWAY && diffTime >= 0) {
          const remainingSecond = CANCEL_ORDER_TIMER.ONLINE_GATEWAY - diffTime;
          setValidTime(formatAudioTime(remainingSecond));
        } else {
          setExpiredPayment(true);
        }
      }, TIMER_INTERVAL);
      return () => clearInterval(timerInterval);
    }
  }, [paymentInfo]);

  return (
    <StyledPaymentDialog open={open} fullWidth maxWidth="sm">
      <div className="wrapper">
        <Typography className="title">
          {t('paymentLinkUsingShopeePay')}
        </Typography>
        <StyledTransferInformation expiredPayment={expiredPayment}>
          <div className="information-wrapper">
            {expiredPayment ? (
              <div className="expired-transaction">
                <Typography className="text">
                  {t('expiredTransaction')}
                </Typography>
              </div>
            ) : (
              <>
                <div className="qr-wrapper">
                  <img
                    src={paymentInfo.paymentLink}
                    alt="bank-qr"
                    className="bank-qr"
                  />
                </div>
                <div className="content-wrapper">
                  <div className="qr-payment-content">
                    <Typography className="time">
                      {`${t('paymentScanningTime')} `}
                      <span className="time-value">{validTime}</span>
                      {` ${t('second')}`}
                    </Typography>
                    <Typography className="time">
                      {`${t('amountMoney')}: `}
                      {!isVietNam && <span className="unit-price">$</span>}
                      {isVietNam && (
                        <span className="unit-price underlined">đ</span>
                      )}
                      <span className="price-value">
                        {delimitNumber(totalPrice, language)}
                      </span>
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
          <Divider className="divider" />
          <div className="note">
            {notes.map((note) => (
              <div className="note-item align-items-center" key={note.title}>
                <img src={note.logo || WarningIcon} alt="warning" />
                <div className="note-content">
                  <Typography className="note-title">
                    {t(note.title)}
                  </Typography>
                  <Typography className="note-description">
                    {t(note.description)}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </StyledTransferInformation>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelPendingOrder}
            className="button"
            fullWidth
          >
            {t('cancelPayment')}
          </Button>
        </DialogActions>
      </div>
    </StyledPaymentDialog>
  );
};

export default QRPaymentDialog;
