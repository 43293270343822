import React from 'react';
import { useTranslation } from 'react-i18next';
// import CircularProgress from '@mui/material/CircularProgress';
import { Autorenew, DoneRounded } from '@mui/icons-material';
import { StyledStatusSaving } from './index.style';

const StatusSaving = ({ savingStatus }) => {
  const { t } = useTranslation();

  return (
    <StyledStatusSaving saved={!savingStatus}>
      {savingStatus ? (
        <div className="base-loading">
          <Autorenew className="loading-saved" />
          <div className="text">{t('saving')}</div>
        </div>
      ) : (
        <div className="base-loading">
          <DoneRounded className="loading-saved" />
          <div className="text">{t('saved')}!</div>
        </div>
      )}
    </StyledStatusSaving>
  );
};
export default StatusSaving;
