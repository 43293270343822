import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { TextField, Typography, MenuItem } from '@mui/material';
import CustomDatePickerRange from '@src/components/CustomDatePickerRange';
import {
  ORDER_STATUS,
  PACKAGE_LEVEL,
  PACKAGE_STATUS,
  PACKAGE_TYPE,
  PRODUCT_TYPE,
} from '@src/constants/package';
import { LANGUAGE, PAGINATION_LIMIT } from '@src/constants';
import Table from '@src/components/Table';
import apis from '@src/apis';
import i18n from '@src/languages';
import { delimitNumber } from '@src/utils/number';
// import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import actions from '@src/redux/actions';
import RefreshBtn from '@src/assets/images/refresh-btn.svg';
import RefreshBtnActive from '@src/assets/images/refresh-btn-active.svg';
import smallLogoTts from '@src/assets/logo/small-logo.svg';
import smallLogoApi from '@src/assets/logo/small-logo-api.svg';
import smallLogoDubbing from '@src/assets/logo/small-logo-dubbing.svg';
import ProcessHandler from '@src/components/ProcessHandler';
import { StyledChip, StyledPaymentHistory } from './index.style';

const initialFilter = {
  packageLevel: '',
  packageStatus: '',
  type: '',
  createdAt: [null, null],
};

// const filterStudioPackageLevels = [
//   PACKAGE_LEVEL.STANDARD,
//   PACKAGE_LEVEL.PROFESSIONAL,
//   PACKAGE_LEVEL.SPECIAL,
// ];

// const filterDubbingPackageLevels = [
//   PACKAGE_LEVEL.STANDARD,
//   PACKAGE_LEVEL.PROFESSIONAL,
//   PACKAGE_LEVEL.SPECIAL,
// ];

const productType = [
  PRODUCT_TYPE.aivoiceStudio,
  PRODUCT_TYPE.aivoiceApi,
  PRODUCT_TYPE.aivoiceDubbing,
];

const PaymentHistory = ({ open = true }) => {
  // const isDubbingRoute = useCheckDubbingRoute();
  const dispatch = useDispatch();

  const [orders, setOrders] = useState([]);
  const [paging, setPaging] = useState({ page: 1, total: 0 });
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState('createdAt_desc');
  const [isActiveReset, setActiveReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const { usingPackage, fetchPaymentHistory, usingDubbingPackage } =
    useSelector((state) => state.user);
  const { t } = useTranslation();
  const { language } = i18n;

  const handleChangePage = (newPage) => {
    setPaging({ ...paging, page: newPage });
  };

  const handleSetTotal = (total) => {
    setPaging({ ...paging, total });
  };

  const handleChangeFilter = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    handleChangePage(1);
  };

  const handleResetFilter = () => {
    setFilter(initialFilter);
    handleChangePage(1);
  };

  const handleChangeSort = (newSort) => {
    setSort(newSort);
    handleChangePage(1);
  };

  const handleChangeDatePickerRange = (value) => {
    setFilter({ ...filter, createdAt: value });
    handleChangePage(1);
  };

  // STANDARD package level is PRO level, special package level is ENTERPRISE level
  const mapPackageLevelValue = (value) => {
    switch (value) {
      case PACKAGE_LEVEL.STANDARD:
        return PACKAGE_LEVEL.PRO;
      case PACKAGE_LEVEL.SPECIAL:
        return PACKAGE_LEVEL.ENTERPRISE;
      default:
        return value;
    }
  };

  const renderTypography = (content, typeText) => (
    <Typography className={`text-${typeText}-body-cell`}>
      {content === 'null' || content === 'undefined' ? '' : content}
    </Typography>
  );

  const renderMoneyTypography = (content, typeText) => (
    <Typography className={`text-${typeText}-body-cell`}>
      <span className="vnd">đ</span>
      {content}
    </Typography>
  );

  const renderTypographyPkgName = (content, typeText, renewalType) => (
    <div className="pkgNameWrapper">
      <Typography className={`text-${typeText}-body-cell`}>
        {content}
      </Typography>
      {renewalType === 'AUTO_RENEW' && (
        <div className="typeOfExpire">
          {renewalType === 'AUTO_RENEW' ? t('autoRenewal') : ''}
        </div>
      )}
    </div>
  );

  const getPackageStatusText = (status) => {
    switch (status) {
      case PACKAGE_STATUS.PAID:
        return 'paid';
      case PACKAGE_STATUS.PENDING:
        return 'pending';
      case PACKAGE_STATUS.CANCEL:
        return 'canceled';
      default:
        return '';
    }
  };

  // const renderOrderStatus = (status, paymentMethodType) => {
  //   switch (status) {
  //     case ORDER_STATUS.PAID:
  //       return renderTypography(t('paid'), 'bold');
  //     case ORDER_STATUS.PENDING:
  //       return renderTypography(
  //         paymentMethodType === PAYMENT_METHOD_TYPE.BANK
  //           ? t('pending')
  //           : t('unpaid'),
  //         'bold',
  //       );
  //     case ORDER_STATUS.CANCEL:
  //       return renderTypography(t('cancel'), 'bold');
  //     default:
  //       return '';
  //   }
  // };

  const fetchOrders = async () => {
    setLoading(true);
    const { packageLevel, packageStatus, createdAt, type } = filter;

    // If packageLevel is PRO, we need to change it to STANDARD because standard package is pro level
    const packageLevelValue = mapPackageLevelValue(packageLevel);
    const data = await apis.orders.getOrders({
      packageLevel: packageLevelValue || undefined,
      status: packageStatus || undefined,
      type: type || undefined,
      startDate: createdAt[0]
        ? moment(createdAt[0]).startOf('day').toISOString()
        : undefined,
      endDate: createdAt[1]
        ? moment(createdAt[1]).endOf('day').toISOString()
        : undefined,
      sort,
      offset: (paging.page - 1) * PAGINATION_LIMIT,
    });
    if (data && data.status) {
      setOrders(data.result.orders);
      handleSetTotal(data.result.total);
      setLoading(false);
    }
  };

  const renderRemainingTime = (expiredIn) => {
    if (expiredIn < 30) return '';
    if (expiredIn === 30) return t('packageDurationMonth');
    if (expiredIn === 90) return t('quarterly');
    if (expiredIn === 365) return t('packageDurationYear');
    return t('packageDurationYear');
  };

  const renderPackageStatus = (status) => {
    let color;
    switch (status) {
      case PACKAGE_STATUS.PAID:
        color = 'success';
        break;
      case PACKAGE_STATUS.CANCEL:
        color = 'error';
        break;
      case PACKAGE_STATUS.PENDING:
        color = 'info';
        break;
      default:
        break;
    }
    return (
      <StyledChip color={color}>{t(getPackageStatusText(status))}</StyledChip>
    );
  };

  const renderLogoProd = (prodType) => {
    switch (prodType) {
      case PACKAGE_TYPE.STUDIO:
        return smallLogoTts;
      case PACKAGE_TYPE.API:
        return smallLogoApi;
      case PACKAGE_TYPE.DUBBING:
        return smallLogoDubbing;
      default:
        return smallLogoTts;
    }
  };

  useEffect(() => {
    if ((open && !orders.length) || orders.length) {
      fetchOrders();
      if (fetchPaymentHistory)
        dispatch(actions.user.fetchPaymentHistory(false));
    }
  }, [
    open,
    sort,
    paging.page,
    filter,
    usingPackage,
    fetchPaymentHistory,
    usingDubbingPackage,
  ]);

  useEffect(() => {
    if (
      filter.type ||
      // filter.packageLevel ||
      filter.packageStatus ||
      filter.createdAt[0]
    )
      setActiveReset(true);
    else setActiveReset(false);
  }, [filter]);

  const columns = [
    {
      field: 'createdAt',
      title: t('transactionTime'),
      sortable: false,
      align: 'left',
      render: (row) =>
        renderTypography(
          moment(row.createdAt).format('DD/MM/YYYY - HH:mm'),
          'light',
        ),
    },
    {
      field: '',
      title: '',
      sortable: false,
      align: 'right',
      render: (row) => (
        <img src={renderLogoProd(row?.type)} alt="small-logo-tts" />
      ),
    },
    {
      field: `package.name.${language}`,
      title: t('packageName'),
      sortable: false,
      align: 'left',
      render: (row) =>
        renderTypographyPkgName(
          `${
            row?.package?.name?.[language] ||
            row?.package?.name?.[LANGUAGE.EN] ||
            ''
          } ${
            renderRemainingTime(row?.package?.expiresIn) ? '-' : ''
          } ${renderRemainingTime(row?.package?.expiresIn)}`,
          'light',
          row?.renewalType,
        ),
    },
    {
      field: `paymentMethod.name.${language}`,
      title: t('paymentMethod'),
      sortable: false,
      align: 'left',
      render: (row) =>
        renderTypography(
          row?.paymentMethod?.name?.[language] ||
            row?.paymentMethod?.name?.[LANGUAGE.EN],
          'light',
        ),
    },
    {
      field: 'packagePrice',
      title: t('price'),
      sortable: false,
      align: 'right',
      render: (row) =>
        renderMoneyTypography(delimitNumber(row.price, language), 'light'),
    },
    {
      field: 'status',
      title: t('transactionStatus'),
      sortable: false,
      align: 'center',
      render: (row) => renderPackageStatus(row.status, row.paymentMethod?.type),
    },
  ];

  // const filterPackageLevels = isDubbingRoute
  //   ? filterDubbingPackageLevels
  //   : filterStudioPackageLevels;

  return (
    <StyledPaymentHistory>
      <div className="header-payment">
        <Typography className="title">{t('paymentHistory')}</Typography>
      </div>

      <div className="table-wrapper">
        <div className="filter-wrapper">
          <TextField
            size="small"
            className="text-field"
            variant="outlined"
            value={filter.type}
            name="type"
            select
            label={t('selectPackageName')}
            onChange={handleChangeFilter}
          >
            {productType.map((item) => (
              <MenuItem key={item} value={item}>
                {t(item)}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            size="small"
            className="text-field"
            variant="outlined"
            value={filter.packageLevel}
            name="packageLevel"
            select
            label={t('packageName')}
            onChange={handleChangeFilter}
          >
            {filterPackageLevels.map((item) => (
              <MenuItem key={item} value={item}>
                {t(item)}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            size="small"
            className="text-field"
            variant="outlined"
            value={filter.packageStatus}
            name="packageStatus"
            select
            label={t('chooseStatus')}
            onChange={handleChangeFilter}
          >
            {Object.keys(ORDER_STATUS).map((item) => (
              <MenuItem key={item} value={PACKAGE_STATUS[item]}>
                {t(getPackageStatusText(item))}
              </MenuItem>
            ))}
          </TextField>
          <div>
            <CustomDatePickerRange
              value={filter.createdAt}
              onChange={handleChangeDatePickerRange}
            />
          </div>
          <img
            src={isActiveReset ? RefreshBtnActive : RefreshBtn}
            alt="refresh"
            onClick={handleResetFilter}
            className="refresh-icon"
          />
        </div>
        <ProcessHandler loading={loading} mt="30px" mb="30px" align="center">
          <Table
            columns={columns}
            data={orders}
            total={paging.total}
            page={paging.page}
            sort={sort}
            onChangePage={handleChangePage}
            onChangeSort={handleChangeSort}
            headerStyle={{ height: 48 }}
          />
        </ProcessHandler>
      </div>
    </StyledPaymentHistory>
  );
};

export default PaymentHistory;
