import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import AutoRenewalPromotionIcon from '@src/assets/images/auto-renewal-promotion-sidebar.png';
import AutoRenewalBackgroundImage from '@src/assets/images/auto-renewal-background.jpg';
import route from '@src/constants/route';
import { StyledAdsItem } from '../SidebarNew/index.style';

const AutoRenewSidebar = ({
  isUserUseMonthlyPackage,
  autoRenewalPackageCode,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRegisterNow = () => {
    if (!isUserUseMonthlyPackage) {
      history.push(`${route.PAYMENT}?package=${autoRenewalPackageCode}`);
    } else {
      history.push(route.CONFIG_AUTO_RENEW);
    }
  };

  return (
    <StyledAdsItem background={AutoRenewalBackgroundImage}>
      <Box className="banner-item">
        <div className="auto-renewal-offer">
          <Typography className="title"> {t('autoRenewalOffer')}</Typography>
          <img
            className="image"
            src={AutoRenewalPromotionIcon}
            alt="banner"
            width="100%"
          />
        </div>
        <div className="button-wrapper">
          <Button
            variant="contained"
            color="white"
            fullWidth
            className="register-button"
            onClick={handleRegisterNow}
          >
            {t('registerNow')}
          </Button>
        </div>
      </Box>
    </StyledAdsItem>
  );
};

export default AutoRenewSidebar;
