import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from '@src/constants/voice';
import Table from '@src/components/Table';
import BadgeAvatar from '@src/components/BadgeAvatar';
import LinearStatus from '@src/components/LinearStatus';
import { Typography } from '@mui/material';
import {
  convertFormatDate,
  convertFormatTime,
  isSameToday,
} from '@src/utils/date';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import Tooltip from '@src/components/Tooltip';
import { extractVoiceName } from '@src/services/voice';
import moment from 'moment/moment';
import { TIME_CONVERT_THOUSANDS_CHARACTERS } from '@src/constants';
import i18n from '@src/languages';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import ViewRequest from './ViewRequest';
import RequestAction from './RequestAction';
import ErrorReport from './ErrorReport';

import { StyledRequestTable, StyledChip, StyleVoiceItem } from './index.style';
import TitleTable from './TitleTable';

const RequestTable = ({
  requests = [],
  page,
  total,
  sort,
  loading,
  selectedRequests,
  onUpdateRequest,
  onChangePage,
  onChangeSort,
  onSelectRequest,
  audioRef,
}) => {
  const [openView, setOpenView] = useState(false);
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const { getFeatureValue } = useFeatureFlags();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let useOldRequestTable = getFeatureValue(FEATURE_KEYS.OLD_REQUEST_TABLE);
  useOldRequestTable = useOldRequestTable && !useNewTtsUI;

  const { t } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();

  const { languages } = useSelector((state) => state.language);

  const handleOpenView = (request) => {
    setOpenView(true);
    setSelectedRequest(request);
  };

  const handleCloseView = () => {
    setOpenView(false);
    setSelectedRequest(null);
  };

  const handleOpenErrorReport = (request) => {
    setOpenErrorReport(true);
    setSelectedRequest(request);
  };
  const handleCloseErrorReport = () => {
    setOpenErrorReport(false);
    setSelectedRequest(null);
  };

  const renderStatus = ({ status, progress, processingAt, characters }) => {
    const timeConvert = (characters / 1000) * TIME_CONVERT_THOUSANDS_CHARACTERS;
    let processConvert;
    if (progress > 0) processConvert = progress;
    else processConvert = processingAt ? 1 : 0; // 1 is 1% - start percent when request start processing

    switch (status) {
      case REQUEST_STATUS.IN_PROGRESS:
      case REQUEST_STATUS.SUCCESS:
        return (
          <LinearStatus
            color="info"
            progressConvert={processConvert}
            startTime={processingAt}
            time={timeConvert}
            requestStatus={status}
          />
        );
      case REQUEST_STATUS.FAILURE:
        return <StyledChip color="error">{t(status)}</StyledChip>;
      default:
        return <div />;
    }
  };

  const renderVoiceName = (voice) => {
    const { voiceName, voiceType } = extractVoiceName({
      name: voice?.name,
      provider: voice?.provider,
    });
    const smallImgSrc = languages.find(
      (lang) => lang?.value === voice?.languageCode,
    )?.roundImage;

    return (
      <StyleVoiceItem>
        <BadgeAvatar
          width={30}
          img={voice?.roundImage}
          type="image"
          smallImgWidth={10}
          smallImg={smallImgSrc}
        />
        <div>
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
            {voiceName}
          </Typography>
          {(voice?.styles?.length || voiceType) && (
            <Typography variant="body2" className="voice-style">
              {voiceType || t(voice.styles[0])}
            </Typography>
          )}
        </div>
      </StyleVoiceItem>
    );
  };

  const renderDateTime = (time) => {
    const timeValue = convertFormatTime(time);
    const dateValue = convertFormatDate(time);

    if (isSameToday(time))
      return (
        <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
          {timeValue}
        </Typography>
      );

    return (
      <Tooltip title={timeValue} arrow>
        <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
          {dateValue}
        </Typography>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: 'title',
      title: t('title'),
      sortable: false,
      align: 'left',
      width: 'full',
      cellWidth: 'full',
      render: (row) => (
        <TitleTable
          requestId={row.id}
          title={row.title}
          audioLink={row.audioLink}
          requestStatus={row.status}
          onUpdateRequest={onUpdateRequest}
        />
      ),
    },
    {
      field: 'voice',
      title: t('voice'),
      sortable: true,
      align: 'left',
      width: '140px',
      cellWidth: '140px',
      render: (row) => renderVoiceName(row.voice),
    },
    {
      field: 'characters',
      title: t('characters'),
      sortable: true,
      align: 'right',
      width: '100px',
      cellWidth: '100px',
      render: (row) => row?.characters?.toLocaleString(language) || 0,
    },
    {
      field: 'audioType',
      title: t('audioType'),
      align: 'center',
      width: '100px',
      cellWidth: '100px',
    },
    {
      id: TTS_GUIDE.TIME_REQUEST,
      field: 'createdAt',
      title: t('time'),
      sortable: true,
      align: 'center',
      width: '150px',
      cellWidth: '150px',
      render: (row) => renderDateTime(row?.createdAt),
    },
    {
      id: TTS_GUIDE.STATUS_REQUEST,
      field: 'status',
      title: t('status'),
      sortable: false,
      align: 'left',
      width: '100px',
      cellWidth: '100px',
      render: (row) =>
        renderStatus({
          status: row.status,
          progress: row.progress,
          processingAt: row.processingAt,
          characters: row.characters,
        }),
    },
  ];

  const hoverColumns = [
    {
      id: TTS_GUIDE.ACTION,
      field: 'action',
      title: t('action'),
      sortable: false,
      align: 'center',
      width: '100px',
      cellWidth: '100px',
      render: (row) => (
        <RequestAction
          audioRef={audioRef}
          request={row}
          onOpenView={handleOpenView}
          onOpenErrorReport={handleOpenErrorReport}
        />
      ),
    },
  ];

  const handleRowClick = async (requestId) => {
    document.getElementById('scroll-point-top').scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    });
    const data = await apis.requests.getRequest(requestId);
    if (data?.status) {
      const synthesisConfig = data.result;
      // check if audio is expires ,not return request id to get audio link
      const { createdAt, retentionPeriod } = synthesisConfig;
      if (createdAt && retentionPeriod) {
        const audioExpired = moment().isAfter(
          moment(createdAt).add(retentionPeriod, 'days'),
        );
        if (audioExpired) delete synthesisConfig?.id;
      }

      const bitrateOfBps = synthesisConfig.bitrate || 0;
      const newSynthesisConfig = {
        ...synthesisConfig,
        bitrate: bitrateOfBps % 1000 === 0 ? bitrateOfBps / 1000 : bitrateOfBps,
      };

      dispatch(
        actions.synthesisRequest.updateSynthesisConfig(newSynthesisConfig),
      );
      dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
    }
  };

  return (
    <StyledRequestTable>
      <Table
        columns={useOldRequestTable ? [...columns, ...hoverColumns] : columns}
        hoverColumns={useOldRequestTable ? [] : hoverColumns}
        data={requests}
        page={page}
        total={total}
        sort={sort}
        loading={loading}
        selection
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        onRowClick={handleRowClick}
        selected={selectedRequests}
        onSelect={onSelectRequest}
        minWidth={useOldRequestTable ? '1170px' : '1000px'}
        footerPadding="0px 20px 40px"
      />
      <ViewRequest
        open={openView}
        requestId={selectedRequest?.id}
        onClose={handleCloseView}
      />
      <ErrorReport
        open={openErrorReport}
        request={selectedRequest}
        onClose={handleCloseErrorReport}
      />
    </StyledRequestTable>
  );
};

export default RequestTable;
