import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ERROR_MESSAGE_AUTH } from '@src/constants';
import useCustomSSO from '@src/hooks/useCustomSSO';
import route from '@src/constants/route';
import { CRM_URL } from '@src/configs';
import { StyledError } from './index.style';

const ErrorContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { keycloak } = useCustomSSO();
  const { error } = queryString.parse(location.search) || {};
  const description = ERROR_MESSAGE_AUTH[error]?.description || '';

  return (
    <StyledError>
      <Typography>{t(description)}</Typography>
      <Button
        align="center"
        color="primary"
        variant="contained"
        size="small"
        onClick={() => {
          const url = `${CRM_URL}${route.TTS}`;
          keycloak.login({ redirectUri: url });
        }}
      >
        {t('login')}
      </Button>
    </StyledError>
  );
};

export default ErrorContainer;
