import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import StarsIcon from '@mui/icons-material/Stars';
import { Button } from '@mui/material';
import {
  getYoutubeVideoDuration,
  getYoutubeVideoInfo,
} from '@src/services/youtube';
import actions from '@src/redux/actions';
import ProcessHandler from '@src/components/ProcessHandler';
import UpgradeImg from '@src/assets/images/space-shuttle.png';
import ROUTES from '@src/constants/route';
import apis from '@src/apis';
import NotificationDialog from '@src/components/NotificationDialog';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import { createSRTFile } from '@src/services/dubbing';
import { CUSTOMER_SUPPORT_PHONE_NUMBER } from '@src/configs';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { checkFeaturePermission } from '@src/services/tts';
import { DUBBING_VIDEO_SOURCE, INPUT_FILE_TYPES } from '@src/constants/dubbing';
import { uploadFileToGoogleStorage } from '@src/services/upload';
import { REQUEST_TYPE } from '@src/constants/request';
import { REQUEST_STATUS } from '@src/constants/voice';
import datasenses from '@src/services/dataSenses';
import { StyledUpdateButton, StyledConvertButton } from './index.style';

const ConvertButton = ({
  totalCharacters,
  totalSeconds,
  onChangeLoadingRequest,
  onResetFile,
  onChangeRequestLoading,
  fileSelectedForDubbing,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [openWrongSyntaxDialog, setOpenWrongSyntaxDialog] = useState(null);
  const [actionDialog, setActionDialog] = useState();

  const { user } = useSelector((state) => state.auth);
  const { infoForDubbingAPI, youtubeLink } = useSelector(
    (state) => state.dubbingRequest,
  );
  const { getFeatureValue } = useFeatureFlags();

  const newDubbing = getFeatureValue(FEATURE_KEYS.NEW_DUBBING, {
    email: user.email,
    userId: user.id,
  });
  const isDubbingRoute = newDubbing && useCheckDubbingRoute();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isExpiredPackage = isProcessDubbingByUnitSecond
    ? user?.dubbing?.packageExpiryDate &&
      moment().isAfter(user?.dubbing?.packageExpiryDate)
    : user?.packageExpiryDate && moment().isAfter(user.packageExpiryDate);

  const { title, voice, audioType, fileName, sentences, fileId, speed } =
    useSelector((state) => state.dubbingRequest);

  const {
    user: { remainingCharacters, bonusCharacters, dubbing },
  } = useSelector((state) => state.auth);
  const { ttsUser } = useSelector((state) => state.user);

  const handleUpgradeNow = () =>
    isDubbingRoute
      ? history.push(ROUTES.PAYMENT_DUBBING)
      : history.push(ROUTES.PAYMENT);

  const renderUpgradeButton = () => (
    <StyledUpdateButton onClick={handleUpgradeNow} variant="contained">
      <StarsIcon />
      {t('upgradeNow')}
    </StyledUpdateButton>
  );

  const checkVoicePermission = () =>
    !voice?.features?.length ||
    voice.features.every((item) =>
      checkFeaturePermission(
        isDubbingRoute ? ttsUser?.dubbing?.features : ttsUser?.features,
        item,
      ),
    );

  const validateInputDubbingRequest = () => {
    if (isExpiredPackage) {
      setActionDialog({
        image: UpgradeImg,
        title: t('packageExpired'),
        description: t('notePackageExpired'),
        onClose: () => setActionDialog(),
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    const canUseVoice = checkVoicePermission();
    if (!canUseVoice) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'limitedVoiceDescription',
        }),
      );
      return false;
    }

    if (!title)
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey('title', fileName),
      );

    if (
      isDubbingRoute &&
      (totalSeconds || 0) > (dubbing?.remainingSeconds || 0)
    ) {
      setActionDialog({
        image: UpgradeImg,
        title: t('notEnoughDuration'),
        description: t('noteNotEnoughDuration'),
        onClose: () => setActionDialog(),
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    if (
      !isDubbingRoute &&
      (totalCharacters || 0) > remainingCharacters + bonusCharacters
    ) {
      setActionDialog({
        image: UpgradeImg,
        title: t('notEnoughCharacters'),
        description: t('noteNotEnoughCharacters'),
        onClose: () => setActionDialog(),
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    const detailErrors = [];
    if (!fileId && !youtubeLink) detailErrors.push(t('mustUploadSubtitleFile'));
    if (!voice) detailErrors.push(t('voiceCannotBeEmpty'));
    if (!audioType) detailErrors.push(t('audioTypeCannotBeEmpty'));
    if (isMultipleInputDubbing) {
      if (!youtubeLink && !fileSelectedForDubbing) {
        detailErrors.push(
          t('uploadFileFormatDubbing', { format: '.srt, .mp4' }),
        );
        detailErrors.push(t('enterYoutubeLinkURL'));
      }

      if (youtubeLink && fileSelectedForDubbing) {
        detailErrors.push(t('onlyOneSource'));
      }
    }

    if (detailErrors.length > 0) {
      setOpenWrongSyntaxDialog({
        name: 'error-syntax',
        variant: 'warning',
        title: t('dubbingRequestInvalid'),
        description: t('dubbingRequestInvalidDescription'),
        subDescription: detailErrors.join('; '),
      });
      return false;
    }
    return true;
  };

  const handleConvertSubtitle = async (sentencesConvert = sentences) => {
    const isValidRequest = validateInputDubbingRequest();
    if (!isValidRequest) return;
    setIsLoading(true);
    const { error, fileUrl } = await createSRTFile(
      `${fileName}.srt`,
      sentencesConvert,
    );
    if (error) {
      dispatch(
        actions.noti.push({
          message: t('systemError', { hotline: CUSTOMER_SUPPORT_PHONE_NUMBER }),
          severity: 'error',
        }),
      );
      setIsLoading(false);
      return;
    }

    const response = await apis.dubbing.createDubbingRequest({
      title: title || fileName,
      subtitleLink: fileUrl,
      voiceCode: voice?.code,
      audioType,
      speed,
    });

    datasenses.sendMakeRequestEvent({
      userId: user.id,
      requestType: REQUEST_TYPE.DUBBING,
      voice: voice?.code,
      seconds: totalSeconds,
      status: REQUEST_STATUS.IN_PROGRESS,
    });

    onChangeLoadingRequest(true);
    onChangeRequestLoading(true);
    setIsLoading(false);
    if (response?.status === 1) {
      onResetFile();
      dispatch(
        actions.dubbingRequest.resetDubbingRequest([
          'title',
          'fileId',
          'error',
          'fileName',
          'sentences',
        ]),
      );
    } else {
      dispatch(
        actions.noti.push({
          message: t(response?.errorMessage || 'systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
  };

  const createDubbingRequestWithYoutubeLink = async () => {
    setIsLoading(true);
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('isConverting', true),
    );

    let videoDuration;
    let videoTitle;

    if (!infoForDubbingAPI.title) {
      const { isVideoNotFound, data: videoInfo } = await getYoutubeVideoInfo(
        youtubeLink,
      );
      if (isVideoNotFound) {
        dispatch(
          actions.noti.push({
            message: t('videoNotFound'),
            severity: 'error',
          }),
        );
        setIsLoading(false);
        dispatch(
          actions.dubbingRequest.updateDubbingRequestByKey(
            'isConverting',
            false,
          ),
        );
        return;
      }
      videoTitle = videoInfo?.title;
    }

    if (!infoForDubbingAPI?.videoDuration) {
      videoDuration = await getYoutubeVideoDuration(youtubeLink);
    }

    const response = await apis.dubbing.createDubbingRequestWithFileVideo({
      title: infoForDubbingAPI?.title || fileName || videoTitle,
      linkVideo: youtubeLink,
      voiceCode: voice?.code,
      audioType,
      speed,
      source: DUBBING_VIDEO_SOURCE.YOUTUBE,
      videoDuration: infoForDubbingAPI?.videoDuration || videoDuration,
    });
    onChangeLoadingRequest(true);
    onChangeRequestLoading(true);

    if (response?.status === 1) {
      onResetFile();
      dispatch(
        actions.dubbingRequest.resetDubbingRequest([
          'title',
          'fileId',
          'error',
          'fileName',
          'sentences',
          'youtubeLink',
          'infoForDubbingAPI',
          'isConverting',
        ]),
      );
    } else {
      dispatch(
        actions.noti.push({
          message: t(response?.errorMessage || 'systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
    setIsLoading(false);
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('isConverting', false),
    );
  };

  const createDubbingRequestWithLocalVideo = async () => {
    setIsLoading(true);
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('isConverting', true),
    );
    const extension = fileSelectedForDubbing.name.split('.').pop();
    let name = fileSelectedForDubbing.name.split('.')[0];
    // remove special character like [ ] ( ) . , - _ and replace space by -
    name = name.replace(/[^a-zA-Z0-9]/g, '');
    const directory = 'videos';
    const fileLocation = await uploadFileToGoogleStorage(
      name,
      directory,
      extension,
      fileSelectedForDubbing,
    );

    const response = await apis.dubbing.createDubbingRequestWithFileVideo({
      title: fileName,
      linkVideo: fileLocation,
      voiceCode: voice?.code,
      audioType,
      speed,
      source: DUBBING_VIDEO_SOURCE.LOCAL,
      videoDuration: infoForDubbingAPI?.videoDuration,
    });
    onChangeLoadingRequest(true);
    onChangeRequestLoading(true);

    if (response?.status === 1) {
      onResetFile();
      dispatch(
        actions.dubbingRequest.resetDubbingRequest([
          'title',
          'fileId',
          'error',
          'fileName',
          'sentences',
          'youtubeLink',
          'infoForDubbingAPI',
          'isConverting',
        ]),
      );
    } else {
      dispatch(
        actions.noti.push({
          message: t(response?.errorMessage || 'systemError', {
            hotline: CUSTOMER_SUPPORT_PHONE_NUMBER,
          }),
          severity: 'error',
        }),
      );
    }
    setIsLoading(false);
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('isConverting', false),
    );
  };

  const handleConvertSubtitleWithVideo = async () => {
    const isValidRequest = validateInputDubbingRequest();
    if (!isValidRequest) return;

    if (Object.keys(sentences).length > 0) {
      handleConvertSubtitle();
      return;
    }

    if (
      infoForDubbingAPI?.fileType === INPUT_FILE_TYPES.SRT &&
      Object.keys(infoForDubbingAPI?.sentences).length > 0
    ) {
      const subtitleSentences = infoForDubbingAPI?.sentences.reduce(
        (allSentences, currBlock) => {
          const sentenceId = uuid();
          // eslint-disable-next-line no-param-reassign
          allSentences[sentenceId] = currBlock;
          return allSentences;
        },
        {},
      );
      handleConvertSubtitle(subtitleSentences);
      return;
    }

    if (youtubeLink.length > 0) {
      await createDubbingRequestWithYoutubeLink();
      return;
    }

    if (fileSelectedForDubbing) {
      await createDubbingRequestWithLocalVideo();
    }
  };

  const renderCloseButton = (handleClose) => (
    <Button variant="contained" onClick={handleClose}>
      {t('understood')}
    </Button>
  );

  return (
    <>
      <StyledConvertButton
        className="convert-button"
        color="primary"
        variant="contained"
        onClick={() => {
          if (isMultipleInputDubbing) {
            handleConvertSubtitleWithVideo();
          } else {
            handleConvertSubtitle();
          }
        }}
        disabled={isLoading}
      >
        <ProcessHandler
          loading={isLoading}
          ml="46px"
          mr="46px"
          size={24}
          align="center"
          color="divider"
        >
          {t('convertSubtitle')}
        </ProcessHandler>
      </StyledConvertButton>
      <NotificationDialog
        name={openWrongSyntaxDialog?.name}
        title={openWrongSyntaxDialog?.title}
        description={openWrongSyntaxDialog?.description}
        subDescription={openWrongSyntaxDialog?.subDescription}
        variant="warning"
        open={!!openWrongSyntaxDialog?.title}
        onClose={() => setOpenWrongSyntaxDialog(null)}
        actionComponent={renderCloseButton(() =>
          setOpenWrongSyntaxDialog(null),
        )}
      />
      <ActionDialog
        image={actionDialog?.image}
        open={!!actionDialog?.title}
        title={actionDialog?.title}
        description={actionDialog?.description}
        onClose={actionDialog?.onClose}
        actionComponents={actionDialog?.actionComponents}
      />
    </>
  );
};

export default ConvertButton;
