import { COLOR } from '@src/styles/color';

const { default: styled } = require('styled-components');

export const StyledLayoutMobileTTS = styled.div`
  // background-color: ${COLOR.white};
  margin: -8px -16px -8px -16px;

  .tabs-header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: ${COLOR.black[48]};
    height: 48px;

    :hover {
      cursor: pointer;
    }

    .package-tab {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      width: 50%;
      padding: 8px 0px;
      color: ${COLOR.indigo[64]};
      border-bottom: 2px solid ${COLOR.background};
    }

    .package-active-tab {
      color: ${COLOR.darkBlue};
      border-bottom: 2px solid ${COLOR.darkBlue};
    }
  }
`;

export const StyledContentMobileTTS = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : 0)};
  overflow-y: scroll;
`;
