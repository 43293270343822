import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from '@src/languages';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import { LANGUAGE } from '@src/constants';
import ROUTES from '@src/constants/route';
import {
  ACTION_BANNERS,
  BANNER_TYPE,
  ACTION_TYPE,
} from '@src/constants/banner';
import { isSameToday } from '@src/utils/time';
import {
  FREE_PACKAGE_CODE,
  ORDER_STATUS,
  PACKAGE_EXPIRED,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
} from '@src/constants/package';

import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import ImageBanner from './ImageBanner';
import TextBanner from './TextBanner';
import ServiceManual from './ServiceManual';
import JoinGroupZalo from './JoinGroupZalo';

const BANNERS_OPEN_OTHER_BANNERS = [BANNER_TYPE.FIRST_LOGIN];

const checkTodayDisplay = (bannerType) => {
  const today = moment().format('DD/MM/YYYY');
  const prevDisplayInfo = JSON.parse(localStorage.getItem(bannerType));

  const todayDisplay =
    prevDisplayInfo &&
    prevDisplayInfo.date === today &&
    prevDisplayInfo.display;

  return todayDisplay;
};

const Banners = ({ showDubbingGiftBanner, banners, onChangeBanners }) => {
  const [openBanner, setOpenBanner] = useState(-1);

  const { t, language } = i18n;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const { actionBanner } = useSelector((state) => state.banner);
  const { getFeatureValue } = useFeatureFlags();
  const autoRenewPayment = getFeatureValue(FEATURE_KEYS.AUTO_RENEWAL_PAYMENT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const isDubbingRoute = useCheckDubbingRoute();

  const handleCloseBanner = (bannerIndex, notShowAgain) => {
    const banner = banners[bannerIndex];
    const { id, name, type, showAgain } = banner;
    const firstDisplayedBanners = banners.filter(
      (item) => !ACTION_BANNERS.includes(item.type),
    );

    const isSeenAllBanner =
      bannerIndex === firstDisplayedBanners.length - 1 &&
      !BANNERS_OPEN_OTHER_BANNERS.includes(type);

    if (isSeenAllBanner) {
      dispatch(actions.banner.updateAllowShowAutoRenewalBanner(true));
    }

    // Handle action when close banner
    switch (type) {
      case BANNER_TYPE.SERVICE_MANUAL: {
        dispatch(actions.banner.updateDisplayBanner(type, false));
        setOpenBanner(-1);
        break;
      }
      case BANNER_TYPE.JOIN_ZALO_GROUP: {
        if (notShowAgain) {
          apis.user.updateUserBanner({ id, name, display: false });
          onChangeBanners(banners.filter((item) => item.id !== id));
        }
        dispatch(actions.banner.updateDisplayBanner(type, false));
        setOpenBanner(-1);
        break;
      }
      case BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL: {
        localStorage.setItem(
          type,
          JSON.stringify({
            date: moment().format('DD/MM/YYYY'),
            display: true,
          }),
        );

        if (isSameToday(user?.createdAt)) {
          // Remove this banner to array
          onChangeBanners(
            banners.filter(
              (item) => item.type !== BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL,
            ),
          );
          dispatch(actions.banner.updateDisplayBanner(type, false));
          setOpenBanner(-1);
        }
        break;
      }
      case BANNER_TYPE.LAST_DAY_TRIAL:
      case BANNER_TYPE.TRIAL_EXPIRY:
      case BANNER_TYPE.FIRST_BUY_PACKAGE_BASIC:
      case BANNER_TYPE.PACKAGE_EXPIRY:
      case BANNER_TYPE.RECENT_NO_ANNUAL_PURCHASE_USER:
      case BANNER_TYPE.USER_RETURN: {
        localStorage.setItem(
          type,
          JSON.stringify({
            date: moment().format('DD/MM/YYYY'),
            display: true,
          }),
        );
        break;
      }
      case BANNER_TYPE.GROUP_CUSTOMERS_BUY_PACKAGE: {
        localStorage.setItem(`${type}-${id}`, moment().format('DD/MM/YYYY'));
        break;
      }
      case BANNER_TYPE.IMAGE: {
        if (notShowAgain) {
          const { value } = showAgain || {};
          if (!value) apis.user.updateUserBanner({ id, name, display: false });
          else localStorage.setItem(`${type}-${id}`, moment());
        }
        break;
      }
      default: {
        if (notShowAgain)
          apis.user.updateUserBanner({ id, name, display: false });
        break;
      }
    }

    if (!ACTION_BANNERS.includes(type)) {
      const nextBanners = banners.filter(
        (item, nextIndex) => nextIndex > bannerIndex,
      );
      const nextBannerIsNotActionBanner = nextBanners.find(
        (item) => !ACTION_BANNERS.includes(item.type),
      );
      if (nextBannerIsNotActionBanner) {
        const nextBannerIndex = banners.findIndex(
          (item) => item.id === nextBannerIsNotActionBanner.id,
        );
        setOpenBanner(nextBannerIndex);
      } else setOpenBanner(-1);
    }
  };

  const handleRedirectPayment = () => history.push(ROUTES.PAYMENT);

  const handleRedirectBanner = (type) =>
    dispatch(actions.banner.updateDisplayBanner(type, true));

  const fetchBanners = async () => {
    const bannersResponse = await apis.banners.getBanners();
    if (!bannersResponse?.status) return;

    let countPaidOrders;
    const countOrdersResponse = await apis.orders.countPaidOrders();
    if (countOrdersResponse?.status)
      countPaidOrders = countOrdersResponse.result;

    let recentOrders;
    const recentOrdersResponse = await apis.orders.getOrders({
      userId: user.id,
      status: ORDER_STATUS.PAID,
      sort: 'createdAt_desc',
      type: isDubbingRoute ? PACKAGE_TYPE.DUBBING : PACKAGE_TYPE.STUDIO,
      limit: 20, // Get 20 orders in 2 months
      startDate: moment().subtract(2, 'months').toISOString(),
    });

    if (recentOrdersResponse.status)
      recentOrders = recentOrdersResponse.result?.orders;

    const activeBanners = bannersResponse?.result?.banners || [];
    const { banners: userBanners = [] } = user;
    const today = moment().format('DD/MM/YYYY');

    const displayBanners = activeBanners.filter((activeBanner) => {
      const userBanner = userBanners.find(
        (uBanner) => uBanner.id === activeBanner.id,
      );
      const {
        id: bannerId,
        voucherCode,
        type: bannerType,
        showAgain = {},
      } = activeBanner;

      const todayDisplay = checkTodayDisplay(bannerType);

      switch (bannerType) {
        case BANNER_TYPE.FIRST_LOGIN:
          return userBanner?.display;
        case BANNER_TYPE.JOIN_ZALO_GROUP:
          return !userBanner || userBanner?.display;
        case BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL: {
          const { packageCode, packageExpiryDate, createdAt } = user;
          const lastApplyVoucherDate = moment(packageExpiryDate).subtract(
            1,
            'days',
          );
          const satisfiedCondition =
            packageCode?.includes(PACKAGE_LEVEL.TRIAL) &&
            moment().isSameOrBefore(lastApplyVoucherDate);

          if (isSameToday(createdAt) && !todayDisplay)
            ACTION_BANNERS.push(BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL);
          if (voucherCode && satisfiedCondition)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const showBanner = satisfiedCondition && !todayDisplay;
          return showBanner;
        }
        case BANNER_TYPE.LAST_DAY_TRIAL: {
          const { packageCode, packageExpiryDate } = user;
          const lastApplyVoucherDate = moment(packageExpiryDate).subtract(
            1,
            'days',
          );
          const satisfiedCondition =
            packageCode?.includes(PACKAGE_LEVEL.TRIAL) &&
            moment().isSameOrAfter(lastApplyVoucherDate) &&
            moment().isSameOrBefore(packageExpiryDate);
          if (voucherCode && satisfiedCondition)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const showBanner = satisfiedCondition && !todayDisplay;
          return showBanner;
        }
        case BANNER_TYPE.TRIAL_EXPIRY: {
          const { packageCode, packageExpiryDate } = user;
          const satisfiedCondition =
            packageCode?.includes(PACKAGE_LEVEL.TRIAL) &&
            moment().isSameOrAfter(packageExpiryDate);
          if (voucherCode && satisfiedCondition)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const showBanner = satisfiedCondition && !todayDisplay;
          return showBanner;
        }
        case BANNER_TYPE.FIRST_BUY_PACKAGE_BASIC: {
          const { packageCode } = user;
          const satisfiedCondition = packageCode?.includes(PACKAGE_LEVEL.BASIC);
          if (voucherCode && satisfiedCondition)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const showBanner = satisfiedCondition && !todayDisplay;
          return showBanner;
        }
        case BANNER_TYPE.PACKAGE_EXPIRY: {
          const { packageCode, packageExpiryDate } = user;
          const lastApplyVoucherDate = moment(packageExpiryDate).subtract(
            3,
            'days',
          );
          const satisfiedCondition =
            FREE_PACKAGE_CODE.every((item) => packageCode !== item) &&
            moment().isSameOrAfter(lastApplyVoucherDate) &&
            moment().isSameOrBefore(packageExpiryDate);
          if (voucherCode && satisfiedCondition)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const showBanner = satisfiedCondition && !todayDisplay;
          return showBanner;
        }
        case BANNER_TYPE.USER_RETURN: {
          const { firstDay, lastDay } = activeBanner;
          if (!firstDay && firstDay !== 0) return false;
          const { packageCode, packageExpiryDate } = user;
          const firstApplyVoucherDate = moment(packageExpiryDate).add(
            firstDay,
            'days',
          );
          let satisfiedCondition;
          if (lastDay) {
            const lastApplyVoucherDate = moment(packageExpiryDate).add(
              lastDay,
              'days',
            );
            satisfiedCondition =
              FREE_PACKAGE_CODE.every((item) => packageCode !== item) &&
              moment().isSameOrAfter(firstApplyVoucherDate) &&
              moment().isBefore(lastApplyVoucherDate);
          } else {
            satisfiedCondition =
              FREE_PACKAGE_CODE.every((item) => packageCode !== item) &&
              moment().isSameOrAfter(firstApplyVoucherDate);
          }
          if (voucherCode && satisfiedCondition)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const showBanner = satisfiedCondition && !todayDisplay;
          return showBanner;
        }
        case BANNER_TYPE.SERVICE_MANUAL: {
          dispatch(actions.voucher.addManualBanner(activeBanner));
          return true;
        }
        case BANNER_TYPE.IMAGE: {
          const { value, reopenDays } = showAgain;
          if (!value) return !userBanner || userBanner.display;
          const showedBannerLastDay = localStorage.getItem(
            `${bannerType}-${bannerId}`,
          );
          const showBannerNextDay = moment(showedBannerLastDay).add(
            reopenDays,
            'days',
          );
          const showBanner =
            !showedBannerLastDay ||
            moment().isSameOrAfter(showBannerNextDay, 'day');
          return showBanner;
        }
        case BANNER_TYPE.GROUP_CUSTOMERS_BUY_PACKAGE: {
          const { numOfBoughtPackages, displayTime } = activeBanner;
          const { from, to } = displayTime;
          if (!from) return false;
          const satisfiedTime = moment().isBetween(from, to, 'minutes');

          const { min, max } = numOfBoughtPackages;
          let satisfiedCountPackages = false;
          if (min && max)
            satisfiedCountPackages =
              countPaidOrders >= min && countPaidOrders <= max;
          if (!max) satisfiedCountPackages = countPaidOrders >= min;
          const showBanner = satisfiedTime && satisfiedCountPackages;
          if (showBanner && voucherCode)
            dispatch(actions.voucher.addAutoVoucher(voucherCode, bannerType));

          const displayBannerLastDay = localStorage.getItem(
            `${bannerType}-${bannerId}`,
          );
          const notShowToday =
            !displayBannerLastDay || displayBannerLastDay !== today;
          return showBanner && notShowToday;
        }

        case BANNER_TYPE.RECENT_NO_ANNUAL_PURCHASE_USER: {
          const existYearlyOrder = recentOrders.find(
            (order) => order?.package.expiresIn === PACKAGE_EXPIRED.YEARLY,
          );

          return !existYearlyOrder;
        }
        default:
          return false;
      }
    });

    const firstDisplayedBanners = displayBanners.filter(
      (item) => !ACTION_BANNERS.includes(item.type),
    );
    if (!firstDisplayedBanners.length) {
      dispatch(actions.banner.updateAllowShowAutoRenewalBanner(true));
    } else {
      setOpenBanner(0);
    }

    // Check first login to disable banner image
    const bannerFirstLogin = displayBanners.find(
      (banner) => banner.type === BANNER_TYPE.FIRST_LOGIN,
    );
    if (bannerFirstLogin) {
      const displayBannersNotImage = displayBanners.filter(
        (banner) => banner.type !== BANNER_TYPE.IMAGE,
      );
      onChangeBanners(displayBannersNotImage.sort((a, b) => a.rank - b.rank));
      return;
    }

    onChangeBanners(displayBanners.sort((a, b) => a.rank - b.rank));
  };

  useEffect(() => {
    if (!showDubbingGiftBanner) fetchBanners();
  }, [showDubbingGiftBanner]);

  useEffect(() => {
    const activeBannerIndex = Object.values(actionBanner).findIndex(
      (item) => item.display,
    );

    if (activeBannerIndex !== -1) {
      const bannerIndex = banners.findIndex(
        (item) => item.type === Object.keys(actionBanner)[activeBannerIndex],
      );
      setOpenBanner(bannerIndex);
    }
  }, [actionBanner]);

  if (!user.hasAgreedToTerms) return <></>;

  return (
    <div className="">
      {banners.map((banner, index) => {
        switch (banner.type) {
          case BANNER_TYPE.FIRST_LOGIN: {
            const closeAction = { url: banner.actionUrl, notShowAgain: true };
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON &&
                  handleRedirectBanner(BANNER_TYPE.SERVICE_MANUAL)
                }
              />
            );
          }
          case BANNER_TYPE.JOIN_ZALO_GROUP: {
            const closeAction = { notShowAgain: false };
            const joinZaloBanner = actionBanner?.[BANNER_TYPE.JOIN_ZALO_GROUP];
            if (joinZaloBanner?.display) {
              if (autoRenewPayment)
                return (
                  <JoinGroupZalo
                    key={banner.id}
                    open={openBanner === index}
                    bannerIndex={index}
                    banner={banner}
                    description={t(
                      banner.description &&
                        (banner.description[language] ||
                          banner.description[LANGUAGE.EN]),
                    )}
                    packageType={joinZaloBanner?.packageType}
                    packageName={joinZaloBanner?.packageName}
                    isAutoRenewalPackage={joinZaloBanner?.isAutoRenewalPackage}
                    packagePrice={joinZaloBanner?.packagePrice}
                    packageUsdPrice={joinZaloBanner?.packageUsdPrice}
                    packageExpiryDate={joinZaloBanner?.packageExpiryDate}
                    closeAction={closeAction}
                    onCloseBanner={handleCloseBanner}
                    onActionChange={(url) => url && window.open(url, '_blank')}
                  />
                );

              return (
                <TextBanner
                  key={banner.id}
                  open={openBanner === index}
                  bannerIndex={index}
                  banner={banner}
                  description={t(
                    banner.description &&
                      (banner.description[language] ||
                        banner.description[LANGUAGE.EN]),
                    { packageName: joinZaloBanner?.packageName?.toUpperCase() },
                  )}
                  closeAction={closeAction}
                  onCloseBanner={handleCloseBanner}
                  onActionChange={(url) => url && window.open(url, '_blank')}
                />
              );
            }
            return <div />;
          }
          case BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL: {
            const closeAction = { notShowAgain: false };
            const minutes = moment(user.packageExpiryDate).diff(
              moment(),
              'minutes',
            );
            const days = Math.round(minutes / (24 * 60));
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                description={t(
                  banner.description &&
                    (banner.description[language] ||
                      banner.description[LANGUAGE.EN]),
                  { days },
                )}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON && handleRedirectPayment()
                }
              />
            );
          }
          case BANNER_TYPE.LAST_DAY_TRIAL: {
            const closeAction = { notShowAgain: false };
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON && handleRedirectPayment()
                }
              />
            );
          }
          case BANNER_TYPE.TRIAL_EXPIRY: {
            const closeAction = { notShowAgain: false };
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON && handleRedirectPayment()
                }
              />
            );
          }
          case BANNER_TYPE.FIRST_BUY_PACKAGE_BASIC: {
            const closeAction = { notShowAgain: false };
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON && handleRedirectPayment()
                }
              />
            );
          }
          case BANNER_TYPE.PACKAGE_EXPIRY: {
            const closeAction = { notShowAgain: false };
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON && handleRedirectPayment()
                }
              />
            );
          }
          case BANNER_TYPE.USER_RETURN:
          case BANNER_TYPE.GROUP_CUSTOMERS_BUY_PACKAGE: {
            const closeAction = { notShowAgain: false };
            return (
              <TextBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url, type) =>
                  type === ACTION_TYPE.BUTTON && handleRedirectPayment()
                }
              />
            );
          }
          case BANNER_TYPE.SERVICE_MANUAL: {
            const closeAction = { notShowAgain: false };
            return (
              <ServiceManual
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                closeAction={closeAction}
                onCloseBanner={handleCloseBanner}
                onActionChange={(url) => url && window.open(url, '_blank')}
              />
            );
          }
          default:
            return (
              <ImageBanner
                key={banner.id}
                open={openBanner === index}
                bannerIndex={index}
                banner={banner}
                onClose={handleCloseBanner}
              />
            );
        }
      })}
    </div>
  );
};

export default Banners;
