import React from 'react';
import { Typography } from '@mui/material';
import { CallOutlined } from '@mui/icons-material';
import { StyledCallButton } from './index.style';

const CallButton = ({ icon, title, phoneNumber, label = 'call-button' }) => (
  <StyledCallButton href={`tel:${phoneNumber}`} aria-label={label}>
    {icon || <CallOutlined />}
    <Typography>
      {title}: {phoneNumber}
    </Typography>
  </StyledCallButton>
);

export default CallButton;
