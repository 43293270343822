import React from 'react';
import { useTranslation } from 'react-i18next';
import ProcessHandler from '@src/components/ProcessHandler';
import Card from '@src/components/Card';
import clickIon from '@src/assets/icons/click.svg';
import userCheckIcon from '@src/assets/icons/user-check.svg';
import barChartIcon from '@src/assets/icons/bar-chart.svg';
import { delimitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import { StatisticsBoxStyled } from './index.style';

const Statistics = ({ isLoading, numberOfClicks, numberOfCustomers }) => {
  const { t } = useTranslation();
  const { language } = i18n;

  return (
    <StatisticsBoxStyled>
      <Card className="stats-box">
        <div className="stats-item">
          <div className="stats-title">
            <img src={clickIon} alt="icon" />
            <div className="">{t('clicks')}</div>
          </div>
          <ProcessHandler loading={isLoading} size={25}>
            <div className="stats-value">
              {delimitNumber(numberOfClicks, language)}
            </div>
          </ProcessHandler>
        </div>
        <div className="stats-item">
          <div className="stats-title">
            <img src={userCheckIcon} alt="icon" />
            <div className="">{t('subcribers')}</div>
          </div>
          <ProcessHandler loading={isLoading} size={25}>
            <div className="stats-value">
              {delimitNumber(numberOfCustomers, language)}
            </div>
          </ProcessHandler>
        </div>
        <div className="stats-item">
          <div className="stats-title">
            <img src={barChartIcon} alt="icon" />
            <div className="">{t('ratio')}</div>
          </div>
          <div className="stats-value">
            <ProcessHandler loading={isLoading} size={25}>
              {parseFloat(
                ((numberOfCustomers * 100) / (numberOfClicks || 1)).toFixed(2),
              )?.toLocaleString(language)}
              <span>%</span>
            </ProcessHandler>
          </div>
        </div>
      </Card>
    </StatisticsBoxStyled>
  );
};

export default Statistics;
