export const ENV = process.env.REACT_APP_ENV;
export const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;
export const CRM_URL = process.env.REACT_APP_CRM_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const IAM_URL = process.env.REACT_APP_IAM_URL;
export const IAM_REALM = process.env.REACT_APP_IAM_REALM;
export const IAM_CLIENT_ID = process.env.REACT_APP_IAM_CLIENT_ID;
export const TTS_SYNTHESIS_URL = process.env.REACT_APP_WS_SYNTHESIS_URL;
export const CRAWL_NEWS_BY_URL = process.env.REACT_APP_CRAWL_NEWS_BY_URL;
export const NOTIFICATION_URL = process.env.REACT_APP_WS_NOTIFICATION_URL;
export const VBEE_CONSOLE_URL = process.env.REACT_APP_VBEE_CONSOLE_URL;
export const VBEE_DUBBING_URL = `${CRM_URL}/dubbing`;
export const VBEE_STT_URL = `${CRM_URL}/studio/speech-to-text`;
export const ASR_URL = process.env.REACT_APP_ASR_URL;
export const WS_ASR_URL = process.env.REACT_APP_WS_ASR_URL;
export const HOME_PAGE_URL = `${LANDING_PAGE_URL}/home`;
export const BLOCK_USER_PAGE_URL = `${LANDING_PAGE_URL}/user-lock`;
export const AFFILIATE_URL = `${LANDING_PAGE_URL}/affiliate`;
export const DOCUMENT_LINK = 'https://help.vbee.vn';
export const FAQ_LINK = 'https://help.vbee.vn/tai-lieu-chung/faq';
export const USER_GUIDE_DUBBING_URL =
  'https://help.vbee.vn/huong-dan-su-dung/thuyet-minh';
export const USER_GUIDE_DUBBING_PROJECTS_URL = `https://help.vbee.vn/huong-dan-su-dung/long-tieng/huong-dan-su-dung`;
export const CUSTOMER_SUPPORT_PHONE_NUMBER = '024 9999 3399';
export const BUSINESS_PHONE_NUMBER = '0901 939 889';
export const VBEE_HOTLINE = '0901 533 799';
export const VBEE_ZALO_URL = 'https://zalo.me/vbeeai';
export const VBEE_FACEBOOK_SOCIAL_URL = 'https://www.facebook.com/vbeeai';
export const VBEE_MESSENGER_URL =
  'https://www.facebook.com/messages/t/738986426449704';
export const VERSION = '1.0.0';
export const VBEE_POLICY =
  'https://policy.vbee.vn/chinh-sach-and-dieu-khoan/chinh-sach-bao-mat';
export const VBEE_TERMS =
  'https://policy.vbee.vn/chinh-sach-va-dieu-khoan/dieu-khoan-su-dung';
export const SUPPORT_CENTER_URL = 'https://help.vbee.vn/';
export const AUDIO_EFFECTS_URL = process.env.REACT_APP_AUDIO_EFFECTS_URL;
export const VBEE_APP = 'Vbee AI Voice Studio App';
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const VBEE_VIP_ZALO_URL = 'http://vip.vbee.vn/';
export const VBEE_FACEBOOK_GROUP_URL = 'https://group.vbee.vn/';
export const SWITCH_VERSION_BONUS_CHARACTERS = 100000;
export const VBEE_HELP_CENTER = 'https://help.vbee.vn/';
export const VBEE_YOUTUBE_VIDEO = 'https://www.youtube.com/c/VbeeGroup';
export const APP_STORE_URL =
  'https://apps.apple.com/vn/app/vbee-text-to-speech/id1598093001';
export const CH_PLAY_URL =
  'https://play.google.com/store/apps/details?id=vn.vbee.tts&hl=vi&gl=US';
export const IP_INFO_URL = 'https://ipinfo.io/json?token=ipinfo.io';
export const QR_CODE_URL = 'https://img.vietqr.io';
export const TEMPLATE_QR_CODE = process.env.REACT_APP_TEMPLATE_QR_CODE;
export const VBEE_DOMAIN = '.vbee.vn';
export const AFFILIATE_RESOURCE_LINK =
  'https://drive.google.com/drive/folders/1PePWk-WmuaiSDx6PplO0-xqHEFHhMpjs';
export const AFFILIATE_POLICY_LINK =
  'https://drive.google.com/file/d/1vHawZ8Vkvu-tcwzMMTCmXnFh4ggg_YNz/view';
export const VIETQR_API_URL = 'https://api.vietqr.io/v2';
export const GB_API_HOST = process.env.REACT_APP_GB_API_HOST;
export const GB_CLIENT_KEY = process.env.REACT_APP_GB_CLIENT_KEY;
export const RECAPTCHA_V3_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY;
export const RECAPTCHA_ENTERPRISE_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;
export const MAX_TITLE_LENGTH = 50;
export const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
export const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';
export const YOUTUBE_THUMBNAIL_URL = 'https://img.youtube.com';
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const SSO_URL = process.env.REACT_APP_SSO_URL;
export const SSO_API_URL = process.env.REACT_APP_SSO_API_URL;
