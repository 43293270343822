import React from 'react';
import { Box } from '@mui/material';

import { REQUEST_STATUS } from '@src/constants/voice';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import CircularProcess from '@src/components/CircularProcess';

const Status = ({ request }) => {
  const { displayProgress, displayStatus } = useRequestAnimationStatus(request);

  return (
    <Box>
      {displayStatus === REQUEST_STATUS.IN_PROGRESS && (
        <CircularProcess
          color="iconPrimary"
          value={displayProgress}
          size={48}
          thickness={3}
        />
      )}
    </Box>
  );
};

export default Status;
