import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BadgeAvatar from '@src/components/BadgeAvatar';
import VoicesDialog from '@src/components/VoicesDialog';
import { PACKAGE_TYPE } from '@src/constants/package';
import { Button, Typography } from '@mui/material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { StyledMenuVoice } from './index.style';

const MenuVoice = () => {
  const dispatch = useDispatch();
  const [openVoices, setOpenVoices] = useState(false);
  const [voiceSelected, setVoiceSelected] = useState({});

  const isMobileScreen = window.innerWidth < 480;

  // Redux
  const { user } = useSelector((state) => state.auth);
  const {
    voice: defaultProjectVoice,
    sentences,
    projectInfo,
    changedProjectInfo,
    selectedSentencesKeys,
    openSplitVoices,
  } = useSelector((state) => state.dubbingRequest);
  const { dubbingVoices } = useSelector((state) => state.voice);

  const { getFeatureValue } = useFeatureFlags();
  const newDubbing = getFeatureValue(FEATURE_KEYS.NEW_DUBBING, {
    email: user.email,
    userId: user.id,
  });

  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const isDubbingRoute = newDubbing && useCheckDubbingRoute();

  const handleOpenVoices = (value) => setOpenVoices(value);

  const handleCloseVoices = () => setOpenVoices(false);

  const handleChangeVoice = async (value) => {
    if (!isDubbingMultipleVoices) {
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
          ...changedProjectInfo,
          voiceCode: value?.code,
          voice: value,
        }),
      );

      return;
    }

    if (
      selectedSentencesKeys.length === Object.keys(sentences).length ||
      selectedSentencesKeys.length === 0
    ) {
      // Update voice for all sentences
      const newSentences = JSON.parse(JSON.stringify({ ...sentences }));
      const sentencesUpdatedVoice = Object.keys(newSentences).reduce(
        (acc, key) => {
          const newSentence = newSentences[key];
          newSentence.voiceCode = value?.code;
          acc[key] = newSentence;
          return acc;
        },
        {},
      );

      // Remove voice code in changed sentences
      const changedSentences = { ...changedProjectInfo.sentencesChanged };
      Object.keys(changedSentences).forEach((key) => {
        delete changedSentences[key].voiceCode;
      });

      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
          ...changedProjectInfo,
          voiceCode: value?.code,
          voice: value,
        }),
      );
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey(
          'sentences',
          sentencesUpdatedVoice,
        ),
      );
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey(
          'selectedSentencesKeys',
          [],
        ),
      );
      return;
    }

    const changedSentences = { ...changedProjectInfo.sentencesChanged };
    selectedSentencesKeys.forEach((key) => {
      const newSentenceChanged = changedSentences[key] || {};
      newSentenceChanged.voiceCode = value?.code;
      changedSentences[key] = newSentenceChanged;
    });

    const listActions = [
      actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
        ...changedProjectInfo,
        sentencesChanged: changedSentences,
      }),
      actions.dubbingRequest.updateDubbingRequestByKey(
        'selectedSentencesKeys',
        [],
      ),
    ];

    listActions.forEach((action) => dispatch(action));
  };

  const fetchVoiceData = async (code) => {
    const response = await apis.voices.getVoices({
      code,
    });
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'voice',
        response?.result?.voices[0],
      ),
    );

    // Save voice data to changedProjectInfo
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
        ...changedProjectInfo,
        voice: response?.result?.voices[0],
      }),
    );
  };

  const checkSelectedSentencesHaveSameVoice = () => {
    const newSentences = JSON.parse(JSON.stringify({ ...sentences }));
    const changedSentences = { ...changedProjectInfo.sentencesChanged };
    Object.keys(changedProjectInfo.sentencesChanged || {}).forEach((key) => {
      if (changedSentences[key]?.voiceCode && newSentences[key])
        newSentences[key].voiceCode = changedSentences[key]?.voiceCode;
    });

    const selectedSentences = selectedSentencesKeys.map(
      (key) => newSentences[key]?.voiceCode || changedProjectInfo?.voice?.code,
    );

    return new Set(selectedSentences).size === 1;
  };

  const changeDisplayVoice = () => {
    // If dubbing use single voice, display voice of project
    if (!openSplitVoices) {
      setVoiceSelected(changedProjectInfo.voice || defaultProjectVoice);
      return;
    }

    // If selected all sentences, display voice of project
    if (selectedSentencesKeys.length === 0) {
      setVoiceSelected(changedProjectInfo.voice || defaultProjectVoice);
      return;
    }

    // If selected sentences have different voice, display empty voice
    if (!checkSelectedSentencesHaveSameVoice()) {
      setVoiceSelected({});
      return;
    }

    // If selected sentences have same voice, display voice of selected sentences
    const newSentences = JSON.parse(JSON.stringify({ ...sentences }));
    const changedSentences = { ...changedProjectInfo.sentencesChanged };
    Object.keys(changedProjectInfo.sentencesChanged || {}).forEach((key) => {
      if (changedSentences[key]?.voiceCode && newSentences[key])
        newSentences[key].voiceCode = changedSentences[key].voiceCode;
    });

    const voiceCode =
      newSentences[selectedSentencesKeys[0]]?.voiceCode ||
      changedProjectInfo?.voice?.code;

    setVoiceSelected(dubbingVoices.find((voice) => voice.code === voiceCode));
  };

  useEffect(() => {
    if (projectInfo.voiceCode) {
      fetchVoiceData(projectInfo.voiceCode);
    }
  }, [projectInfo.voiceCode]);

  useEffect(() => {
    setVoiceSelected(changedProjectInfo.voice);
  }, [changedProjectInfo?.voice]);

  useEffect(() => {
    changeDisplayVoice();
  }, [
    selectedSentencesKeys,
    openSplitVoices,
    changedProjectInfo?.sentencesChanged,
  ]);

  return (
    <StyledMenuVoice>
      <Button
        id="buttonMenu"
        className="open-menu-voice"
        onClick={handleOpenVoices}
      >
        <BadgeAvatar
          width={25}
          smallImgWidth={10}
          img={voiceSelected?.roundImage}
          smallImg={
            voiceSelected &&
            voiceSelected?.language &&
            voiceSelected?.language?.roundImage
          }
          type="image"
        />
        {!isMobileScreen && (
          <Typography variant="body2" className="name-voice-select">
            {voiceSelected && voiceSelected?.name}
          </Typography>
        )}
      </Button>

      <VoicesDialog
        open={openVoices}
        activeVoiceId={voiceSelected?.id}
        hasLanguageFilter={false}
        usingPackageType={isDubbingRoute ? PACKAGE_TYPE.DUBBING : ''}
        onClose={handleCloseVoices}
        onChangeVoice={handleChangeVoice}
        defaultFilter={{
          hasDubbing: true,
        }}
        oldDubbingVoices={!isDubbingRoute}
      />
    </StyledMenuVoice>
  );
};

export default MenuVoice;
