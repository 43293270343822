import { checkVietNam } from '@src/utils/checkCountry';

const getTotalPrice = ({ voucher, currPrice, currUsdPrice, savedPrice }) => {
  const isVietNam = checkVietNam();
  const money = isVietNam ? currPrice : currUsdPrice;
  if (!voucher) return money;
  let price = money - savedPrice >= 0 ? money - savedPrice : 0;
  // round price to at most 2 decimal
  price = Math.round(price * 100) / 100;
  return price >= 0 ? price : 0;
};

const getOrderPrice = (order) => {
  const { price, usdPrice } = order;
  const isVietNam = checkVietNam();

  let orderPrice = isVietNam ? price : usdPrice;
  orderPrice = Math.round(orderPrice * 100) / 100;
  return orderPrice >= 0 ? orderPrice : 0;
};

export { getTotalPrice, getOrderPrice };
