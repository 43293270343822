import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import Dialog from '@src/components/Dialog';
import { checkValidPhoneNumber } from '@src/services/checkValid';
import { StyledChangePhoneNumberDialog } from './index.style';

const ChangePhoneNumberDialog = ({
  open,
  onClose,
  name,
  currentPhoneNumber,
  onChangePhoneNumber,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const { t } = useTranslation();

  const handleChangePhoneNumber = (e) => {
    setPhoneNumberError(null);
    setPhoneNumber(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setPhoneNumberError(null);
    setPhoneNumber('');
  };

  const handleFinish = () => {
    if (!phoneNumber) {
      setPhoneNumberError('phoneNumberRequired');
      return;
    }
    if (!checkValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError('phoneNumberInvalid');
      return;
    }
    onChangePhoneNumber(phoneNumber);
    handleClose();
  };

  useEffect(() => {
    if (currentPhoneNumber) setPhoneNumber(currentPhoneNumber);
  }, [open]);

  return (
    <Dialog
      title={t('editInfo')}
      subTitle={t('noteEditInfo')}
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <StyledChangePhoneNumberDialog>
        <DialogContent classes={{ root: 'dialog-content' }}>
          <div className="row">
            <Typography className="title">
              <b>{t('fullName')}</b>
            </Typography>
            <TextField
              fullWidth
              color="secondary"
              value={name}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div className="row">
            <Typography className="title">
              <b>{t('phoneNumber')}</b>
            </Typography>
            <TextField
              error={!!phoneNumberError}
              helperText={t(phoneNumberError)}
              fullWidth
              color="secondary"
              placeholder={t('phoneNumberPlaceHolder')}
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
            />
          </div>
        </DialogContent>
        <DialogActions classes={{ root: 'dialog-actions' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFinish}
            disabled={!!phoneNumberError}
          >
            {t('finish')}
          </Button>
        </DialogActions>
      </StyledChangePhoneNumberDialog>
    </Dialog>
  );
};

export default ChangePhoneNumberDialog;
