import { ENGLISH_VOICE_CODES, VOICE_OWNERSHIP } from '@src/constants/voice';
import { checkVietNam } from '@src/utils/checkCountry';
import { PACKAGE_CODE } from '@src/constants/package';
import { actionTypes } from './actions';

const initialFilter = {
  gender: [],
  languageCode: [],
  features: [],
  level: [],
  voiceOwnership: VOICE_OWNERSHIP.VBEE,
};
export const initialState = {
  defaultGlobalVoice: '',
  filterVoice: initialFilter,
  dubbingVoices: [],
};

export default function voiceReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_GLOBAL_VOICE: {
      const { voiceCode } = action;
      return { ...state, defaultGlobalVoice: voiceCode };
    }
    case actionTypes.CHANGE_FILTER_VOICE: {
      const { value } = action;

      return { ...state, filterVoice: value };
    }
    case actionTypes.RESET_FILTER_VOICE: {
      const isVietNam = checkVietNam();
      const { packageCode } = action;
      return {
        ...state,
        filterVoice: {
          languageCode:
            isVietNam || packageCode === PACKAGE_CODE.STUDIO_FREE
              ? []
              : ENGLISH_VOICE_CODES,
        },
      };
    }
    case actionTypes.GET_DUBBING_VOICES_SUCCESS: {
      const { dubbingVoices } = action;
      return { ...state, dubbingVoices };
    }

    default:
      return state;
  }
}
