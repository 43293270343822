import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import WarningIcon from '@src/assets/icons/warning-orange.svg';
import { StyledEmptyContent } from './index.style';

const EmptyContent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleBack = () => history.push('/dubbing/projects?open=true');

  return (
    <StyledEmptyContent>
      <Box className="wrapper">
        <Box className="wrapper-title">
          <img src={WarningIcon} alt="warning" className="wrapper-title-icon" />
          <Typography className="wrapper-title-text">
            {t('cannotFoundSubtitle')}
          </Typography>
        </Box>
        <Typography className="wrapper-subtitle">
          {t('videoNotHaveSubtitle')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className="wrapper-button"
          onClick={handleBack}
        >
          {t('back')}
        </Button>
      </Box>
    </StyledEmptyContent>
  );
};

export default EmptyContent;
