import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledHome = styled('div')`
  width: 100%;

  .section {
    padding: 24px;
    background-color: white;
    border-radius: 16px;
    position: relative;
    margin-top: 16px;

    .title {
      font-size: 18px;
      font-weight: 700;
      color: ${COLOR.darkBlue};
      margin-bottom: 16px;
      display: block;
    }

    .voice-container {
      display: flex;
      width: 100%;
      overflow-x: scroll;
      gap: 16px;
      scroll-snap-type: x mandatory; /* Add scroll-snap-type */

      .voice-wrapper {
        padding: 12px;
        border: 1px solid #3c3c4329;
        border-radius: 12px;
        width: 306px;
        scroll-snap-align: start; /* Add scroll-snap-align */

        .voice-info {
          display: flex;
          align-items: center;
          gap: 12px;

          .voice-avatar {
            height: 48px;
          }

          .voice {
            .name {
              display: block;
              font-size: 14px;
              font-weight: 510;
              color: ${COLOR.darkGray};
              line-height: 20px;
            }
            .type {
              display: block;
              font-size: 12px;
              font-weight: 400;
              color: ${COLOR.label[64]};
            }
          }
        }

        .use {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          gap: 8px;
          margin-top: 16px;

          .play-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border: 1px solid #1e164e29;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
             background: ${COLOR.primary};
            }
          }

          .play-btn-active {
            background: ${COLOR.primary};
          }

          .use-now-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 234px;
            height: 40px;
            border: 1px solid #1e164e29;
            border-radius: 8px;
            gap: 8px;
            cursor: pointer;

            .use-now-txt {
              font-size: 14px;
              font-weight: 700;
              color: ${COLOR.darkBlue};
            }

            &:hover {
              background: ${COLOR.primary};
            }
          }
        }
      }
    }

    .voice-container::-webkit-scrollbar {
      display: none;
    }

    .left-btn,
    .right-btn {
      z-index: 999;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background: ${COLOR.charcoal};
      box-shadow: 0px 2px 4px 0px #00000029;
      position: absolute;
      top: 50%;
      cursor: pointer;
    }

    .left-btn {
      left: 5px;
      img {
        transform: rotateY(180deg);
      }
    }

    .right-btn {
      right: 5px;
    }

    .blur-txt {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.secondary[64]};
      display: block;
    }

    .inputs-wrapper{
      max-width: 2000px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 16px;
      width: 100%;
      margin-top: 16px;

      .input-box {
        height: 96px;
        border-radius: 12px;
        border: 1px solid #3c3c4329;
        background: #1e164e0a;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        cursor: pointer;
      }
    }

    .video-guide-wrapper {
      max-width: 2000px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 16px;
      width: 100%;

      .video-guide-box {
        cursor: pointer;
        margin-top: 24px;
        margin-bottom: 16px;

        .title {
          font-size: 14px;
          font-weight: 700;
          color: ${COLOR.darkV2[64]};
          margin-top: 8px;
          position: absolute;

        }
      }
    }

    .other-product-wrapper {
      max-width: 2000px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
      gap: 16px;
      width: 100%;
      margin-top: 16px;

      .other-product-box {
        height: 88px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        background: ${COLOR.charcoal};
        padding: 24px;
        box-sizing: border-box;
        cursor: pointer;

       

        .button {
          color: ${COLOR.indigo[64]};

          &:hover {
            color: ${COLOR.indigo[100]} !important;
            background: ${COLOR.primary};
          },
        }

        &:hover {
          .button {
            color: ${COLOR.indigo[100]} !important;
            background: ${COLOR.primary};
          }
        }
      
        .product-info {
          display: flex;
          align-items: center;
          gap: 8px;

          .logo {
            width: 46px;
          }

          .name {
            font-size: 16px;
            font-weight: 700;
            color: black;
            display: block;
            line-height: 24px;
          }

          .value {
            font-size: 12px;
            font-weight: 510;
            color: ${COLOR.darkBlue};
            display: block;
          }
        }
      }
    }
  }

  .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    display: none; /* Initially hidden */
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .video-container {
      width: 878px;
      height: 500px;

      .video {
        width: 100%;
        height: 100%;
      }
    }
  }

  .backdrop.active {
    display: flex; /* Show when active */
  }
`;

export const StyledVoiceList = styled('div')``;

export const StyledGuideVideo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid ${COLOR.secondary[80]};
  background: ${(props) => `url(${props.img}) no-repeat center center`};

  .play-video {
    width: 40px;
    height: 40px;
    display: none;
  }

  &:hover {
    .play-video {
      width: 40px;
      height: 40px;
      display: block;
    }
  }
`;

export const StyledVoiceListLoading = styled('div')`
  .loading-item {
    height: 180px;
    width: 100%;
    border-radius: 12px;
    background-color: ${COLOR.secondary[32]};
  }

  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledVideoGuideDialog = styled('div')`
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .video {
    width: 100%;
    height: 100%;
  }
`;
