import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryMusicRounded, History } from '@mui/icons-material';
import Speed from '@src/components/Speed';
import { Box, Stack, Typography } from '@mui/material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import CustomSwitch from '@src/components/Switch';
import { COLOR } from '@src/styles/color';
import MenuVoice from '../MenuVoice';
import { StyledToolBar } from './index.style';

const ToolBar = ({ onShowDialogHistoryChanged, dubbingRequests }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[3];
  const DEFAULT_DUBBING_TYPE = 'mp3';

  const {
    speed,
    projectInfo,
    changedProjectInfo,
    showOriginalSubtitle,
    originalSentences,
  } = useSelector((state) => state.dubbingRequest);
  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();
  const isSpeedDubbing = getFeatureValue(FEATURE_KEYS.SPEED_DUBBING);

  const useDubbingSubtitleTimeline = getFeatureValue(
    FEATURE_KEYS.DUBBING_SUBTITLE_TIMELINE,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const handleChangeProcessSwitch = (checkedSwitch) =>
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'showOriginalSubtitle',
        checkedSwitch,
      ),
    );

  const handleChangeSpeed = async (name, value) =>
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
        ...changedProjectInfo,
        speed: value,
      }),
    );

  const setDefaultSpeed = async () => {
    const res = await apis.dubbing.updateDubbingProject(projectId, {
      speed: 1,
    });

    if (res.status) {
      const newProjectInfo = { ...projectInfo };
      newProjectInfo.updatedAt = res?.result?.updatedAt;

      if (res?.result?.status !== projectInfo.projectStatus)
        newProjectInfo.projectStatus = res?.result?.status;

      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey(
          'projectInfo',
          newProjectInfo,
        ),
      );
    }
  };

  useEffect(() => {
    if (!speed) {
      setDefaultSpeed();
    }
  }, []);

  return (
    <StyledToolBar>
      <Box display="flex" alignItems="center">
        <MenuVoice />
        {isSpeedDubbing && (
          <Box className="speed">
            <Speed
              currentSpeed={changedProjectInfo.speed}
              onChange={handleChangeSpeed}
              disabled={!projectId}
            />
          </Box>
        )}
        <Box className="border-horizontal">
          <Box className="icon-content">
            <LibraryMusicRounded sx={{ fontSize: 20, opacity: 0.8 }} />
            <Typography variant="body2" className="title">
              {DEFAULT_DUBBING_TYPE}
            </Typography>
          </Box>
        </Box>
        {!!dubbingRequests?.length && useDubbingSubtitleTimeline && (
          <Box className="border-horizontal no-border no-margin">
            <Box
              className="icon-content no-text-transform cursor-pointer"
              onClick={onShowDialogHistoryChanged}
            >
              <History sx={{ fontSize: 20, opacity: 0.8 }} />
              <Typography variant="body2" className="title">
                {t('ttsHistory')}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {isMultipleInputDubbing && Object.keys(originalSentences).length > 0 && (
        <Box display="flex" alignItems="center">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className="switch-wrapper"
          >
            <CustomSwitch
              className="custom-switch"
              checked={showOriginalSubtitle}
              noneCheckedColor={COLOR.secondary[80]}
              checkedColor={COLOR.success}
              onChange={handleChangeProcessSwitch}
            />
            <Typography variant="body2" className="sentence-process">
              {t('viewOriginalSubtitle')}
            </Typography>
          </Stack>
        </Box>
      )}
    </StyledToolBar>
  );
};

export default React.memo(ToolBar);
