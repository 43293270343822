import React from 'react';
import SrtFileIcon from '@src/assets/icons/icon-srt-file.svg';
import Mp4FileIcon from '@src/assets/icons/icon-mp4-file.svg';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { INPUT_FILE_TYPES } from '@src/constants/dubbing';
import { StyledFileSelectedMultipleInput } from './index.style';

const FileSelected = ({
  fileName,
  handleResetFile,
  isLoading,
  youtubeThumbnail = '',
  isYoutubeVideo = false,
  multipleInputDubbing = false,
}) => {
  let fileIcon = multipleInputDubbing ? youtubeThumbnail : SrtFileIcon;

  if (!isYoutubeVideo && multipleInputDubbing) {
    const fileExtension = fileName?.split('.').pop();
    fileIcon =
      fileExtension === INPUT_FILE_TYPES.SRT ? SrtFileIcon : Mp4FileIcon;
  }

  const OldFileSelected = () => (
    <StyledFileSelectedMultipleInput
      className="multiple-input-dubbing"
      onClick={(e) => e.stopPropagation()}
    >
      <Box className="file-info">
        <img src={fileIcon} alt="srt-icon" />
        <Typography className="file-name">{fileName}</Typography>
      </Box>
      {isLoading ? (
        <CircularProgress size={20} className="blue" />
      ) : (
        <Cancel className="cancel-icon" onClick={handleResetFile} />
      )}
    </StyledFileSelectedMultipleInput>
  );

  const MultipleInputFileSelected = () => (
    <StyledFileSelectedMultipleInput
      className="multiple-input-dubbing"
      onClick={(e) => e.stopPropagation()}
    >
      <Box className="file-info">
        <Box className={`${isYoutubeVideo ? 'image-wrapper' : ''}`}>
          <img
            src={fileIcon}
            alt="srt-icon"
            className={`${isYoutubeVideo ? 'thumbnail-image' : ''}`}
          />
        </Box>
        <Typography className="file-name">{fileName}</Typography>
      </Box>
      {isLoading ? (
        <CircularProgress size={20} className="blue" />
      ) : (
        <Cancel className="cancel-icon" onClick={handleResetFile} />
      )}
    </StyledFileSelectedMultipleInput>
  );

  return multipleInputDubbing ? (
    <MultipleInputFileSelected />
  ) : (
    <OldFileSelected />
  );
};

export default FileSelected;
