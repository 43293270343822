import api from './api';

const getMe = async () => {
  const response = await api({
    method: 'GET',
    url: '/me',
  });

  return response;
};

const verifyToken = async (accessToken) => {
  const response = await api({
    method: 'GET',
    url: '/auths/verify',
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

const updateOnlineStatus = async () => {
  const response = await api({
    method: 'POST',
    url: '/online',
  });
  return response;
};

const getTokenFromRefreshToken = async (refreshToken) => {
  try {
    const response = await api({
      method: 'POST',
      url: 'auths/refresh-token',
      data: { refreshToken },
    });
    const { status, result } = response;
    if (status !== 1) throw new Error('Failed to get token from refresh token');
    return result;
  } catch (error) {
    return error?.response?.data || {};
  }
};

export { getMe, verifyToken, updateOnlineStatus, getTokenFromRefreshToken };
