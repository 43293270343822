import { actionTypes } from './actions';

export const initialState = {
  appliedVoucher: null,
  autoVouchers: [],
  bannerVoucher: {},
  manualBanner: { loading: true },
};

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.APPLY_VOUCHER: {
      const { voucher } = action;
      return { ...state, appliedVoucher: voucher };
    }

    case actionTypes.UNSELECT_VOUCHER:
      return { ...state, appliedVoucher: null };

    case actionTypes.ADD_AUTO_VOUCHER: {
      const { voucherCode, bannerType } = action;
      const autoVouchers = [...state.autoVouchers, { voucherCode, bannerType }];
      return { ...state, autoVouchers };
    }

    case actionTypes.UPDATE_BANNER_VOUCHER: {
      const { voucher } = action;
      return { ...state, bannerVoucher: voucher };
    }

    case actionTypes.ADD_MANUAL_BANNER: {
      const { banner } = action;
      return { ...state, manualBanner: banner || {} };
    }

    case actionTypes.UPDATE_HEADER_VOUCHER: {
      const { voucher } = action;
      return { ...state, headerVoucher: voucher };
    }

    default:
      return state;
  }
}
