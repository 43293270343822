import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CustomRadio from '@src/components/CustomRadio';
import { PAYMENT_METHOD_TYPE } from '@src/constants/payment';

import { StyledPaymentAccount } from './index.style';

const PaymentAccount = ({ isActive, method = {}, paymentAccount }) => {
  const { t } = useTranslation();

  const renderAccountNumber = useCallback(() => {
    switch (method.type) {
      case PAYMENT_METHOD_TYPE.MOMOPAY:
        return (
          <Typography className="account-name">{`${t('phoneNumber')}: ${
            paymentAccount.phoneNumber
          }`}</Typography>
        );

      case PAYMENT_METHOD_TYPE.VNPAYQR:
        return (
          <Typography className="account-name">{`${t('paymentAccountName')}: ${
            paymentAccount.cardNumber
          }`}</Typography>
        );

      default:
        return null;
    }
  }, [method, paymentAccount]);

  return (
    <StyledPaymentAccount isActive={isActive}>
      <div className="account-info">
        <CustomRadio checked={isActive} />
        <div className="logo">
          <img src={method && method?.logo} alt="i" className="logo-img" />
        </div>
        <div>
          <Typography className="payment-method">{t(method?.name)}</Typography>
          {renderAccountNumber()}
        </div>
      </div>
      <div className="right-icon">
        <Typography className="sub-text">{t('default')}</Typography>
      </div>
    </StyledPaymentAccount>
  );
};

export default PaymentAccount;
