import React from 'react';
import { Typography } from '@mui/material';
import { StyledSubtitle } from './index.style';

const Subtitle = ({ action }) => (
  <StyledSubtitle>
    <Typography className="subtitle">{action.value}</Typography>
  </StyledSubtitle>
);

export default Subtitle;
