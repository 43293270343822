/* eslint-disable no-undef */
import { DATASENSES_EVENT_TYPE } from '../constants';

const sendLoginEvent = ({ userId }) => {
  if (typeof datasenses === 'undefined') return;

  datasenses.event?.push(DATASENSES_EVENT_TYPE.LOGIN, {
    customer_id: userId,
  });
};

const sendAddToCartEvent = ({
  userId,
  product,
  packageCode,
  price,
  packagePrice,
  packageId,
}) => {
  if (typeof datasenses === 'undefined') return;
  // eslint-disable-next-line no-undef
  datasenses.event?.push(DATASENSES_EVENT_TYPE.ADD_TO_CART, {
    customer_id: userId,
    category: product,
    name: packageCode,
    package_price: packagePrice,
    price,
    item_id: packageId,
    qty: 1,
  });
};

const sendChargedEvent = ({
  orderId,
  product,
  packageCode,
  packageId,
  price,
  paidAmount,
}) => {
  if (typeof datasenses === 'undefined') return;
  // eslint-disable-next-line no-undef
  datasenses.event?.push(DATASENSES_EVENT_TYPE.CHARGED, {
    items: [
      {
        category: product,
        name: packageCode,
        price,
        item_id: packageId,
        qty: 1,
      },
    ],
    charged_id: orderId,
    amount: paidAmount,
  });
};

const sendMakeRequestEvent = ({
  userId,
  requestType,
  voice,
  characters,
  seconds,
  status,
}) => {
  if (typeof datasenses === 'undefined') return;
  // eslint-disable-next-line no-undef
  datasenses.event?.push(DATASENSES_EVENT_TYPE.MAKE_REQUEST, {
    customer_id: userId,
    request_type: requestType,
    voice,
    characters,
    seconds,
    status,
  });
};

const sendPreviewEvent = ({
  userId,
  requestType,
  voice,
  characters,
  status,
}) => {
  if (typeof datasenses === 'undefined') return;
  // eslint-disable-next-line no-undef
  datasenses.event?.push(DATASENSES_EVENT_TYPE.PREVIEW, {
    customer_id: userId,
    request_type: requestType,
    voice,
    characters,
    status,
  });
};

const getDataSensesRequireFields = () => {
  if (typeof datasenses === 'undefined') return null;
  // eslint-disable-next-line no-undef
  return datasenses.getSdkRequiredFields();
};

const sendSignUpSuccessEvent = ({ userId }) => {
  if (typeof datasenses === 'undefined') return;
  datasenses.event?.push(DATASENSES_EVENT_TYPE.SIGN_UP_SUCCESS, {
    customer_id: userId,
  });
};

const sendViewPageEvent = ({ userId, domain, pathname, search }) => {
  if (typeof datasenses === 'undefined') return;
  datasenses.event?.push(DATASENSES_EVENT_TYPE.VIEW, {
    customer_id: userId,
    domain,
    pathname,
    search,
  });
};

const sendViewPaymentPageEvent = ({
  userId,
  category,
  name,
  price,
  packagePrice,
  voucherCode,
}) => {
  if (typeof datasenses === 'undefined') return;
  datasenses.event?.push(DATASENSES_EVENT_TYPE.VIEW_PACKAGE, {
    customer_id: userId,
    category,
    name,
    package_price: packagePrice,
    price,
    voucher_code: voucherCode,
  });
};

// TODO: Send download audio event from backend, this function is not used
const sendDownloadAudioEvent = () => {};

export default {
  sendLoginEvent,
  sendAddToCartEvent,
  sendChargedEvent,
  sendMakeRequestEvent,
  sendDownloadAudioEvent,
  sendPreviewEvent,
  getDataSensesRequireFields,
  sendSignUpSuccessEvent,
  sendViewPageEvent,
  sendViewPaymentPageEvent,
};
