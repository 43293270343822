import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledPreviewButton = styled('div')`
  margin-right: 16px;

  .wrapper {
    width: 48px;
    display: flex;
    border: 1px solid ${COLOR.indigo[32]};
    border-radius: 480px;
  }

  .preview-button {
    text-transform: none;
    padding: 2px;
    min-width: fit-content;
    width: 24px;
    height: 24px;
    background-color: transparent;

    &:active {
      background-color: transparent;
    }
  }

  .play-button {
    position: relative;
    display: inline-flex;
    height: 32px;
    padding: 0 !important;
    min-width: 48px !important;
    border-radius: 480px;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }

  .button-wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .preview-button-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledPopupPreviewButton = styled('div')`
  .custom-button {
    border-radius: 100%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export { StyledPreviewButton, StyledPopupPreviewButton };
