import React from 'react';
import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import AffiliateDetailContainer from '@src/containers/AffiliateDetail';

const AffiliateDetail = () => (
  <Feature name={FEATURE_FLAG.AFFILIATE}>
    <AffiliateDetailContainer />
  </Feature>
);

export default AffiliateDetail;
