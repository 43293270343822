import { styled } from '@mui/material';
import { FLASH_SALE_BANNER_HEIGHT } from '@src/styles/config';

export const StyledSwiperHeaderBanner = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${FLASH_SALE_BANNER_HEIGHT}px;
  z-index: ${(props) => (props.isAgreeToTerm ? 1399 : 1300)};
  display: flex;
  overflow: hidden;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.1);

    &.active {
      left: 0;
      opacity: 1;
      z-index: 1;
    }

    &.prev {
      left: -100%;
      opacity: 0;
      z-index: 0;
    }

    &.next {
      left: 100%;
      opacity: 0;
      z-index: 0;
    }
  }
`;
