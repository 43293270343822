import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { TimerOutlined } from '@mui/icons-material';
import RequestStatus from '@src/components/RequestStatus';
import { convertMillisToDubbingDuration } from '@src/services/dubbing';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FETCH_REQUESTS_INTERVAL } from '@src/constants/websocket';
import apis from '@src/apis';
import { PROJECT_STATUS } from '@src/constants/dubbing';
import Tooltip from '@src/components/Tooltip';
import { StyledGroupConvert } from './index.style';
import SaveDraftButton from './SaveDraftButton';
import ConvertButton from './ConvertButton';
import DownloadButton from './DownloadButton';

const GroupConvert = ({
  duration,
  onChangeRequestLoading,
  hasDeleteSentences,
  setHasDeleteSentences,
}) => {
  const isMobileScreen = window.innerWidth < 480;
  const { t } = useTranslation();

  const { projectInfo } = useSelector((state) => state.dubbingRequest);

  const [synthesisRequest, setSynthesisRequest] = useState({});

  const fetchProgressRequest = async (requestId) => {
    const data = await apis.requests.getProgressRequest(requestId);
    if (data && data.status) {
      const { progress, status, audioLink } = data.result;

      setSynthesisRequest((prevState) => ({
        ...prevState,
        progress,
        status,
        audioLink,
      }));
    }
  };

  const fetchRequest = async () => {
    if (projectInfo.latestRequestId) {
      const data = await apis.requests.getRequest(projectInfo.latestRequestId);
      if (data && data.status) {
        const {
          audioType,
          characters,
          createdAt,
          id,
          processingAt,
          progress,
          seconds,
          retentionPeriod,
          status,
          title,
        } = data.result;

        const newSynthesisRequest = {
          audioType,
          characters,
          createdAt,
          id,
          processingAt,
          progress,
          seconds,
          retentionPeriod,
          status,
          title,
        };
        setSynthesisRequest(newSynthesisRequest);
      }
    }
  };

  const isShowRequestStatus =
    !projectInfo.hasChanged &&
    projectInfo?.latestRequestId === synthesisRequest.id &&
    projectInfo?.projectStatus !== PROJECT_STATUS.DRAFT;
  const isShowDownloadButton =
    !projectInfo.hasChanged &&
    projectInfo?.latestRequestId === synthesisRequest.id &&
    synthesisRequest?.status === REQUEST_STATUS.SUCCESS &&
    projectInfo?.projectStatus !== PROJECT_STATUS.DRAFT;

  useEffect(() => {
    fetchRequest();
  }, [projectInfo.latestRequestId]);

  useEffect(() => {
    if (
      !projectInfo.latestRequestId ||
      projectInfo?.projectStatus !== PROJECT_STATUS.IN_PROGRESS
    )
      return null;

    const fetchRequestsInterval = setInterval(async () => {
      await fetchProgressRequest(projectInfo.latestRequestId);
    }, FETCH_REQUESTS_INTERVAL);

    return () => clearInterval(fetchRequestsInterval);
  }, [projectInfo.latestRequestId]);

  return (
    <StyledGroupConvert>
      {!isMobileScreen && (
        <Tooltip arrow title={t('estimatedAudioLength')} placement="top">
          <Typography variant="body2" className="estimate-audio-length">
            <TimerOutlined sx={{ fontSize: 16 }} color="iconPrimary" />
            <span translate="no">
              {convertMillisToDubbingDuration(duration)}
            </span>
          </Typography>
        </Tooltip>
      )}
      {isShowRequestStatus && <RequestStatus request={synthesisRequest} />}
      <SaveDraftButton
        hasDeleteSentences={hasDeleteSentences}
        setHasDeleteSentences={setHasDeleteSentences}
      />
      {isShowDownloadButton && <DownloadButton />}
      <ConvertButton
        totalSeconds={Math.ceil(duration / 1000)}
        onChangeRequestLoading={onChangeRequestLoading}
        hasDeleteSentences={hasDeleteSentences}
        setHasDeleteSentences={setHasDeleteSentences}
      />
    </StyledGroupConvert>
  );
};

export default GroupConvert;
