import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Card from '@src/components/Card';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import { nonAccentVietnamese } from '@src/utils/accent';
import { PACKAGE_LEVEL } from '@src/constants/package';
import { ORDER_STATUS } from '@src/constants/order';
import { PAGINATION_LIMIT } from '@src/constants';
import { getPackageName } from '@src/services/package';
import SuperTabs from '@src/components/Tabs';
import { AFFILIATE_TABS } from '@src/constants/affiliate';
import CommissionBox from './CommissionBox';
import Statistics from './Statistics';
import { AffiliateDetailStyled } from './index.style';
import LinkAffiliate from './LinkAffiliate';
import OrdersList from './OrdersList';
import Resource from './Resource';
import TransactionList from './TransactionList';
import ResignedCustomerList from './ResignedCustomerList';

const initialFilter = {
  packageLevel: '',
  packageStatus: '',
  createdAt: [null, null],
};

const AffiliateDetail = () => {
  const [numberOfClicks, setNumberOfClicks] = useState(0);
  const [numberOfCustomers, setNumberOfCustomers] = useState(0);
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [code, setCode] = useState('');
  const [currentCommission, setCurrentCommission] = useState(0);
  const [pendingCommission, setPendingCommission] = useState(0);
  const [receiveCommission, setReceiveCommission] = useState(0);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState('createdAt_desc');
  const [getOrderLoading, setGetOrderLoading] = useState(false);
  const [callApiGetCommission, setCallApiGetCommission] = useState(true);
  const [activeTab, setActiveTab] = useState(AFFILIATE_TABS.CUSTOMERS_LIST);

  const { i18n } = useTranslation();
  const { t, language } = i18n;

  const dispatch = useDispatch();

  const handleChangeSort = (newSort) => {
    setSort(newSort);
    setPage(1);
  };

  const fetchAffiliateMarketer = async () => {
    setIsLoadingStats(true);

    const response = await apis.affiliateMarketer.getAffiliateMarketer();

    if (!response?.status)
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'getAffiliateMarketerFailed',
        }),
      );
    setNumberOfCustomers(
      response?.result?.affiliateMarketer?.customers?.length || 0,
    );
    setNumberOfClicks(response?.result?.affiliateMarketer?.numberOfClicks || 0);
    setCode(response?.result?.affiliateMarketer?.code);
    setIsLoadingStats(false);
  };

  const fetchAffiliateCommission = async () => {
    const response = await apis.affiliateMarketer.getAffiliateCommission();

    if (!response?.status)
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'getAffiliateCommissionFailed',
        }),
      );

    setCurrentCommission(response?.result?.currentCommission || 0);
    setPendingCommission(response?.result?.pendingCommission || 0);
    setReceiveCommission(response?.result?.approvalCommission || 0);
    setCallApiGetCommission(false);
  };

  const fetchAffiliateOrders = async () => {
    const { packageLevel, createdAt } = filter;

    setGetOrderLoading(true);
    const response = await apis.affiliateMarketer.getAffiliateOrders({
      packageLevel:
        packageLevel && packageLevel !== PACKAGE_LEVEL.allPackages
          ? packageLevel
          : undefined,
      startDate: createdAt[0]
        ? moment(createdAt[0]).startOf('day').toISOString()
        : undefined,
      endDate: createdAt[1]
        ? moment(createdAt[1]).endOf('day').toISOString()
        : undefined,
      offset: (page - 1) * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
      sort,
      status: ORDER_STATUS.PAID,
      fields: 'user,commission,package,createdAt',
    });
    setGetOrderLoading(false);

    if (!response?.status)
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'getAffiliateOrdersFailed',
        }),
      );

    const processedOrders = response?.result?.orders?.map((order) => ({
      ...order,
      packageName: getPackageName(
        order.package?.name,
        order.package?.code,
        language,
        t,
      ),
    }));

    setOrders(processedOrders || []);
    setTotal(response?.result?.total || 0);
  };

  const handleChangeCode = (event) => {
    const refactorCode = event.target.value.trim().replace(/ /g, '-');
    setCode(nonAccentVietnamese(refactorCode));
  };

  const handleChangePage = (newPage) => setPage(newPage);

  const handleChangeFilter = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    handleChangePage(1);
  };

  const handleChangeDatePickerRange = (value) => {
    setFilter({ ...filter, createdAt: value });
    handleChangePage(1);
  };

  const handleResetFilter = () => {
    setFilter(initialFilter);
    handleChangePage(1);
  };

  const handleReloadCommission = () =>
    setCallApiGetCommission(!callApiGetCommission);

  const handleChangeTab = (value) => setActiveTab(value);

  const tabs = [
    {
      label: t('customerList'),
      id: AFFILIATE_TABS.CUSTOMERS_LIST,
      panel: <ResignedCustomerList activeTab={activeTab} />,
    },
    {
      label: t('orderList'),
      id: AFFILIATE_TABS.ORDER_LIST,
      panel: (
        <OrdersList
          orders={orders}
          page={page}
          total={total}
          filter={filter}
          sort={sort}
          loading={getOrderLoading}
          onChangeFilter={handleChangeFilter}
          onChangeDatePickerRange={handleChangeDatePickerRange}
          onResetFilter={handleResetFilter}
          onChangePage={handleChangePage}
          onChangeSort={handleChangeSort}
        />
      ),
    },
    {
      label: t('TransactionList'),
      id: AFFILIATE_TABS.AFFILIATE_TRANSACTION,
      panel: <TransactionList activeTab={activeTab} />,
    },
  ];

  useEffect(() => {
    fetchAffiliateMarketer();
  }, []);

  useEffect(() => {
    if (callApiGetCommission) fetchAffiliateCommission();
  }, [callApiGetCommission]);

  useEffect(() => {
    fetchAffiliateOrders();
  }, [page, filter, sort]);

  return (
    <div className="">
      <Card flexDirection="column" padding="20px" margin="25px 0">
        <AffiliateDetailStyled>
          <div className="stats">
            <CommissionBox
              currentCommission={currentCommission}
              pendingCommission={pendingCommission}
              receiveCommission={receiveCommission}
              onReloadCommission={handleReloadCommission}
            />
            <div className="statistic-and-link">
              <Statistics
                isLoading={isLoadingStats}
                numberOfClicks={numberOfClicks}
                numberOfCustomers={numberOfCustomers}
              />
              <LinkAffiliate
                affiliateCode={code}
                onChangeCode={handleChangeCode}
              />
            </div>
          </div>
          <SuperTabs
            tabs={tabs}
            activeTab={tabs.findIndex((tab) => tab.id === activeTab)}
            // actionComponents={renderOrderAction()}
            onChangeTab={handleChangeTab}
          />
          <Resource />
        </AffiliateDetailStyled>
      </Card>
    </div>
  );
};

export default AffiliateDetail;
