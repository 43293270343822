export const actionTypes = {
  SEEN_ALL_BANNER: 'SEEN_ALL_BANNER',
  UPDATE_DISPLAY_BANNER: 'UPDATE_DISPLAY_BANNER',
  ALLOW_SHOW_AUTO_RENEWAL_BANNER: 'ALLOW_SHOW_AUTO_RENEWAL_BANNER',
};

const addSeenAllBanner = () => ({
  type: actionTypes.SEEN_ALL_BANNER,
});

const updateDisplayBanner = (bannerType, display, extraData = {}) => ({
  type: actionTypes.UPDATE_DISPLAY_BANNER,
  bannerType,
  display,
  extraData,
});

const updateAllowShowAutoRenewalBanner = (allow) => ({
  type: actionTypes.ALLOW_SHOW_AUTO_RENEWAL_BANNER,
  allow,
});

export {
  addSeenAllBanner,
  updateDisplayBanner,
  updateAllowShowAutoRenewalBanner,
};
