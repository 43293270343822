import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress } from '@mui/material';
import { PlayCircleFilled } from '@mui/icons-material';
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded';

import Tooltip from '@src/components/Tooltip';
import { isSameToday } from '@src/utils/time';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import actions from '@src/redux/actions';
import { BANNER_TYPE } from '@src/constants/banner';
import { POPUP_TIME_OUT } from '@src/constants';
import { COLOR } from '@src/styles/color';
import { StyledPreviewButton, StyledPopupPreviewButton } from './index.style';

const PreviewButton = ({
  synthesisRequest,
  synthesisSentence,
  openSentences,
  onTryListeningSentence,
  audioRef,
  canPreview,
  isPopupPreview,
}) => {
  const { t } = useTranslation();

  const { isAudioLoading, isRunStreamAudio } = useSelector(
    (state) => state.audioPlayer,
  );
  const user = useSelector((state) => state.auth.user);
  const { previewParagraphs, advanceFeature } = useSelector(
    (state) => state.synthesisRequest,
  );
  const { voice } = useSelector((state) => state.synthesisRequest);

  const dispatch = useDispatch();

  const handleTryListening = () => {
    if (isSameToday(user?.createdAt))
      setTimeout(
        () =>
          dispatch(
            actions.banner.updateDisplayBanner(
              BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL,
              true,
            ),
          ),
        POPUP_TIME_OUT,
      );

    let sentence;

    // eslint-disable-next-line no-param-reassign
    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();

    if (advanceFeature) {
      sentence = {
        voice,
        speed: synthesisRequest.speed,
        previewParagraphs,
      };
    } else {
      sentence = {
        text: synthesisSentence,
        voice,
        speed: synthesisRequest.speed,
      };
    }

    onTryListeningSentence(sentence);
  };

  if (isPopupPreview) {
    return (
      <StyledPopupPreviewButton>
        <Tooltip arrow title={t('previewNote')} placement="top">
          <div
            role="button"
            id={TTS_GUIDE.TRY_LISTENING}
            color="secondary"
            variant="outlined"
            className="custom-button"
            onClick={handleTryListening}
            onMouseDown={(e) => e.preventDefault()}
            disabled={!canPreview}
            style={{ border: 'none' }}
          >
            <PlayCircleFilled size={20} />
          </div>
        </Tooltip>
      </StyledPopupPreviewButton>
    );
  }

  return (
    <StyledPreviewButton>
      {!openSentences && (
        <div className="wrapper">
          <Tooltip
            arrow
            title={t(canPreview ? 'previewNote' : 'previewConditionNote')}
            placement="top"
          >
            <Box>
              <Button
                className="play-button"
                id={TTS_GUIDE.TRY_LISTENING}
                disabled={!canPreview || isRunStreamAudio}
                onClick={handleTryListening}
                onMouseDown={(e) => e.preventDefault()}
              >
                {isAudioLoading && (
                  <CircularProgress
                    variant="indeterminate"
                    color="info"
                    size={16}
                    thickness={4}
                  />
                )}

                {!isAudioLoading && (
                  <Box className="button-wrapper">
                    <Box className="preview-button">
                      <SlowMotionVideoRoundedIcon
                        sx={{ fontSize: 24 }}
                        color={COLOR.indigo[80]}
                      />
                    </Box>
                  </Box>
                )}
              </Button>
            </Box>
          </Tooltip>
        </div>
      )}
    </StyledPreviewButton>
  );
};

export default PreviewButton;
