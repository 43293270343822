import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import appRoutes from '@src/router/appRoutes';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import Feature from '@src/components/Feature';

import Banner from './Banner';
import { StyledContent } from './index.style';

const Content = ({
  children,
  openSidebar,
  useNewSideBar,
  useHeaderBanner,
  headerVoucher,
}) => {
  const location = useLocation();

  const currentRoute = appRoutes.find((route) =>
    matchPath(location.pathname, { path: route.path, exact: true }),
  );

  return (
    <StyledContent
      useHeaderBanner={useHeaderBanner}
      headerVoucher={headerVoucher}
      useNewSideBar={useNewSideBar}
      openSidebar={openSidebar}
    >
      {currentRoute?.banner && (
        <Feature name={FEATURE_FLAG.BANNER}>
          <Banner />
        </Feature>
      )}
      <div className="content-layout">{children}</div>
    </StyledContent>
  );
};

export default Content;
