import { styled, Dialog } from '@mui/material';
import { COLOR } from '@src/styles/color';
import { BOX_SHADOW, TEXT_SHADOW } from '@src/styles/config';
import AutoRenewalBackgroundImage from '../../../assets/images/auto-renewal-background.jpg';

const StyledAutoRenewalDialog = styled(Dialog)`
  .dialog {
    margin: 0;
    box-shadow: none;
    max-width: 640px;
    border-radius: 12px;
    box-shadow: ${BOX_SHADOW};
    background: url(${AutoRenewalBackgroundImage});
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .dialog-content {
    padding: 40px 24px 24px 24px;
  }

  .image {
    width: 100%;
    border-radius: 5px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      text-shadow: ${TEXT_SHADOW};
      text-align: center;
      font-size: 32px;
      font-weight: 1000;
      color: ${COLOR.white};
      text-transform: uppercase;
    }

    .sub-title {
      text-shadow: ${TEXT_SHADOW};
      text-align: center;
      font-size: 16px;
      font-weight: 1000;
      color: ${COLOR.white};
      text-transform: uppercase;
    }
  }

  .not-show-again {
    display: flex;
    justify-content: center;
    color: ${COLOR.white};
  }

  .not-show-again-text {
    font-size: 14px;
    font-weight: 400;
    text-shadow: 1px 0px 2px 0px ${COLOR.black[24]};
  }

  .checkbox {
    padding: 0 9px;
    color: ${COLOR.white};
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: ${COLOR.white};
    text-align: center;
    line-height: 16px;
    letter-spacing: -0.4px;
    text-shadow: 1px 0px 2px 0px ${COLOR.black[24]};
  }

  .button {
    border-radius: 480px;
    font-weight: 700;
    text-transform: none;
    box-shadow: 2px 0px 4px 0px ${COLOR.black[24]};

    &:hover {
      opacity: 0.9;
    }
  }
`;

export { StyledAutoRenewalDialog };
