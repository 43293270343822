import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

import { StyledCountdownBanner } from './index.style';
import CountdownTime from './CountdownTime';

const CountdownBanner = ({
  date,
  onComplete,
  completeComponent,
  backgroundColor,
}) => {
  const { t } = useTranslation();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return completeComponent || <div className="h-20" />;

    return (
      <div className="countdown-box">
        <div className="countdown-deal">{t('dealsEndIn')}</div>
        <div className="countdown">
          <CountdownTime
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      </div>
    );
  };
  return (
    <StyledCountdownBanner backgroundColor={backgroundColor}>
      <Countdown date={date} renderer={renderer} onComplete={onComplete} />
    </StyledCountdownBanner>
  );
};

export default CountdownBanner;
