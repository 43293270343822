import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { FilterAltOutlined, Search } from '@mui/icons-material';

import api from '@src/apis';
import actions from '@src/redux/actions';
import {
  OLD_VERSION_DUBBING_VOICE_CODES,
  VOICE_PAGINATION_LIMIT,
} from '@src/constants/voice';
import { MOBILE_BREAKPOINT } from '@src/constants';
import { PLAYING_AUDIO_TYPE, SILENCE_AUDIO_URL } from '@src/constants/tts';
import debounce from '@src/utils/debounce';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import Feature from '@src/components/Feature';

import Dialog from '@src/components/Dialog';
import BadgeAvatar from '@src/components/BadgeAvatar';
import ProcessHandler from '@src/components/ProcessHandler';
import CustomPagination from '@src/components/Pagination';

import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import VoiceItem from './VoiceItem';
import VoicesSort from './VoicesSort';
import VoicesFilter from './VoicesFilter';
import VoiceDetail from './VoiceDetail';
import MobileVoicesFilter from './MobileVoicesFilter';
import { StyledVoices } from './index.style';

const initFilterOpens = {
  gender: true,
  features: true,
  level: true,
  languageCode: true,
  voiceOwnership: true,
};

const VoicesDialog = ({
  open,
  activeVoiceId,
  defaultFilter = {},
  hasLanguageFilter,
  usingPackageType,
  onClose,
  onChangeVoice,
  oldDubbingVoices,
}) => {
  const { getFeatureValue } = useFeatureFlags();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState({});
  const [openSort, setOpenSort] = useState(false);
  const [openFilter, setOpenFilter] = useState(
    window.innerWidth > MOBILE_BREAKPOINT,
  );
  const [loading, setLoading] = useState(false);
  const [audioLink, setAudioLink] = useState('');
  const [search, setSearch] = useState('');
  const [filterOpens, setFilterOpens] = useState(initFilterOpens);

  const [audioType, setAudioType] = useState(PLAYING_AUDIO_TYPE.SILENCE);

  const country = localStorage.getItem('country');
  const { filterVoice } = useSelector((state) => state.voice);
  const { featureFlags } = useSelector((state) => state.featureFlag);
  const { user } = useSelector((state) => state.auth);

  const { t } = useTranslation();
  const audioRef = createRef(null);
  const dispatch = useDispatch();

  const newVoiceDialogFeature = featureFlags.find(
    (featureFlag) => featureFlag.name === FEATURE_FLAG.NEW_VOICE_DIALOG,
  );

  const allowFilterByVoiceLibrary = getFeatureValue(
    FEATURE_KEYS.FILTER_BY_VOICE_LIBRARY,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
      screenWidth: window.innerWidth,
    },
  );

  const handleChangeFilter = (value) => {
    dispatch(actions.voice.changeFilterVoice(value));
    setPage(1);
  };

  const handleChangeFilterOpens = (name) => {
    const newFilterOpen = { ...filterOpens, [name]: !filterOpens[name] };
    setFilterOpens(newFilterOpen);
  };

  const handleChangePage = (newPage) => setPage(newPage);
  const handleReset = () => {
    setSearch('');
    dispatch(actions.voice.resetFilterVoice());
  };

  const fetchVoices = async () => {
    setLoading(true);
    const gender = filterVoice?.gender?.length
      ? filterVoice.gender.map((item) => item).join(',')
      : '';
    const languageCode = filterVoice?.languageCode?.length
      ? filterVoice.languageCode.map((item) => item).join(',')
      : '';
    const features = filterVoice?.features?.length
      ? filterVoice.features.map((item) => item).join(',')
      : '';
    const level = filterVoice?.level?.length
      ? filterVoice.level.map((item) => item).join(',')
      : '';
    const voiceOwnership = filterVoice?.voiceOwnership;

    let data = {};

    if (allowFilterByVoiceLibrary)
      data = await api.voices.getVoicesV2({
        search: search.trim(),
        gender,
        languageCode,
        level,
        features,
        active: true,
        offset: (page - 1) * VOICE_PAGINATION_LIMIT,
        limit: oldDubbingVoices ? undefined : VOICE_PAGINATION_LIMIT,
        voiceOwnership,
        ...defaultFilter,
      });
    else
      data = await api.voices.getVoices({
        search: search.trim(),
        gender,
        languageCode,
        level,
        features,
        active: true,
        offset: (page - 1) * VOICE_PAGINATION_LIMIT,
        limit: oldDubbingVoices ? undefined : VOICE_PAGINATION_LIMIT,
        ...defaultFilter,
      });
    setLoading(false);
    if (data.result) {
      const { hasDubbing } = defaultFilter;
      if (hasDubbing && oldDubbingVoices) {
        const oldVoices = data.result.voices.filter((voice) =>
          OLD_VERSION_DUBBING_VOICE_CODES.includes(voice.code),
        );
        setVoices(oldVoices);
        setTotal(OLD_VERSION_DUBBING_VOICE_CODES.length);
        setSelectedVoice(
          data.result.voices.find((voice) => voice.id === activeVoiceId),
        );
      } else {
        setVoices(data.result.voices);
        setTotal(data.result.metadata.total);
        setSelectedVoice(
          data.result.voices.find((voice) => voice.id === activeVoiceId),
        );
      }
    }
  };

  const handleOpenFilter = (value) => {
    setOpenFilter(value);
    if (value) setOpenSort(false);
  };

  const handleCloseVoiceFilter = () => setOpenFilter(false);

  const handleAudioEnd = () => {
    if (audioType === PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      const newVoices = voices.map((item) =>
        item.playStatus ? { ...item, playStatus: false } : item,
      );
      setVoices(newVoices);
      return;
    }

    if (audioLink) {
      setAudioType(PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC);
      audioRef.current.src = audioLink;
      audioRef.current.load();
      audioRef.current.play();
      return;
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const handleClose = () => {
    setOpenFilter(window.innerWidth > MOBILE_BREAKPOINT);
    setOpenSort(false);
    setFilterOpens(initFilterOpens);
    setAudioLink('');
    handleAudioEnd();
    setSearch('');
    onClose();
  };

  const handleChangePlayAudio = (voice) => {
    dispatch(actions.audioPlayer.updateStatus(false));
    const audio = audioRef.current;
    if (!audioLink || voice.demo !== audioLink) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      audio.src = SILENCE_AUDIO_URL;
      audio.load();
      audio.play();
      setAudioLink(voice.demo);
      audio.load();
    }

    if (voice.playStatus) {
      audio.pause();
    } else {
      audio.play();
    }

    const newVoices = voices.map((item) =>
      item.id === voice.id
        ? { ...voice, playStatus: !voice.playStatus }
        : { ...item, playStatus: false },
    );
    setVoices(newVoices);
  };

  const handleSelectedVoice = (voice) => {
    onChangeVoice(voice);
    handleClose();
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (open) fetchVoices();
  }, [open, page, filterVoice]);

  useEffect(() => {
    debounce(fetchVoices, 500)(search);
  }, [search]);

  useEffect(() => {
    dispatch(actions.voice.resetFilterVoice(user.packageCode));
  }, [country]);

  return (
    <Dialog
      maxWidth={newVoiceDialogFeature?.active ? 'lg' : 'md'}
      title={t('chooseVoice')}
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <StyledVoices openSearch={openSort || openFilter}>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <div className="button-wrapper">
            <div
              className={classNames('action-button', { active: openFilter })}
              onClick={() => handleOpenFilter(!openFilter)}
              role="presentation"
            >
              <Typography variant="body2" color="darkBlue">
                {t('filter')}
              </Typography>
              <FilterAltOutlined className="action-icon" />
            </div>
            {/*  <div
            className={classNames('action-button', { active: openSort })}
            onClick={() => handleOpenSort(!openSort)}
            role="presentation"
          >
            <Typography variant="body2"> {t('sort')} </Typography>
            <Shuffle className="action-icon" />
          </div> */}
          </div>
          <TextField
            size="small"
            variant="standard"
            className="search-voice"
            value={search}
            placeholder={t('search')}
            onChange={handleChangeSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </DialogActions>
        <DialogContent className="content">
          <div className="web">
            <div className={classNames('voice-search', { active: openSort })}>
              <VoicesSort />
            </div>
            <div className={classNames('voice-search', { active: openFilter })}>
              <VoicesFilter
                filter={filterVoice}
                filterOpens={filterOpens}
                hasLanguageFilter={hasLanguageFilter}
                onChangeFilter={handleChangeFilter}
                onChangeFilterOpens={handleChangeFilterOpens}
                onHandleReset={handleReset}
              />
            </div>
          </div>
          <div className="voice-content">
            <ProcessHandler loading={loading} mt="20px" ml="20px" size={50}>
              <div className="voice-list">
                {voices.map((voiceItem) => (
                  <VoiceItem
                    voice={voiceItem}
                    activeVoiceId={activeVoiceId}
                    key={voiceItem.id}
                    usingPackageType={usingPackageType}
                    onChangePlayAudio={handleChangePlayAudio}
                    onSelectedVoice={handleSelectedVoice}
                    audioRef={audioRef}
                    setAudioType={setAudioType}
                  />
                ))}
              </div>
            </ProcessHandler>
            <div className="pagination">
              {total > VOICE_PAGINATION_LIMIT && !loading && (
                <CustomPagination
                  count={Math.ceil(total / VOICE_PAGINATION_LIMIT)}
                  page={page}
                  onChangePage={handleChangePage}
                />
              )}
            </div>
          </div>
          <Feature name={FEATURE_FLAG.NEW_VOICE_DIALOG}>
            <div className="voice-detail">
              {!loading && (
                <div>
                  <VoiceDetail selectedVoice={selectedVoice} />
                </div>
              )}
            </div>
          </Feature>
        </DialogContent>
        <Feature name={FEATURE_FLAG.NEW_VOICE_DIALOG}>
          <DialogActions classes={{ root: 'dialog-action-footer' }}>
            <div className="voice-active">
              <Typography variant="subtitle2">
                {t('usingVoice')} :&ensp;
              </Typography>
              <BadgeAvatar
                img={selectedVoice?.roundImage}
                smallImg={
                  selectedVoice &&
                  selectedVoice?.language &&
                  selectedVoice?.language?.roundImage
                }
                width={25}
                type="image"
              />
              <Typography variant="subtitle2">
                &ensp;{selectedVoice?.name}
              </Typography>
            </div>
            <div className="voice-action">
              <FormControlLabel
                control={<Checkbox />}
                labelPlacement="end"
                label={t('setDefault')}
                checked="true"
                className="label-checkbox"
              />
              <Button
                color="primary"
                variant="contained"
                className="btn-choose-voice"
              >
                {t('confirm')}
              </Button>
            </div>
          </DialogActions>
        </Feature>
      </StyledVoices>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} onEnded={handleAudioEnd}>
        <source src={audioLink} type="audio/wav" />
      </audio>

      <MobileVoicesFilter
        open={openFilter}
        hasLanguageFilter={hasLanguageFilter}
        onClose={handleCloseVoiceFilter}
        filterVoice={filterVoice}
        filterOpens={filterOpens}
        onChangeFilter={handleChangeFilter}
        onChangeFilterOpens={handleChangeFilterOpens}
        onHandleReset={handleReset}
      />
    </Dialog>
  );
};

export default VoicesDialog;
