import { PAGINATION_LIMIT, RESOURCE } from '@src/constants';
import dataSenses from '@src/services/dataSenses';
import { API_URL } from '@src/configs';
import { omitIsNil } from '@src/utils/omit';
import api from './api';

export const getRequests = async ({
  search,
  offset,
  limit = PAGINATION_LIMIT,
  fields,
  sort,
  startDate,
  endDate,
  status,
}) => {
  const params = omitIsNil({
    search,
    offset,
    limit,
    fields,
    sort,
    startDate,
    endDate,
    status,
  });

  const response = await api({
    method: 'GET',
    url: RESOURCE.REQUESTS,
    params,
  });
  return response;
};

export const getRequestsV2 = async ({
  search,
  offset,
  limit = PAGINATION_LIMIT,
  fields,
  sort,
  startDate,
  endDate,
  status,
}) => {
  const params = omitIsNil({
    search,
    offset,
    limit,
    fields,
    sort,
    startDate,
    endDate,
    status,
  });

  const response = await api({
    method: 'GET',
    url: `${API_URL}/api/v2${RESOURCE.REQUESTS}`,
    params,
  });
  return response;
};

export const getRequest = async (requestId) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.REQUESTS}/${requestId}`,
  });
  return response;
};

export const getRequestV2 = async (requestId) => {
  const response = await api({
    method: 'GET',
    url: `${API_URL}/api/v2${RESOURCE.REQUESTS}/${requestId}`,
  });
  return response;
};

export const getProgressRequest = async (requestId) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${RESOURCE.REQUESTS}/${requestId}/progress`,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const createErrorReport = async (requestId, description) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${RESOURCE.REQUESTS}/${requestId}/error-report`,
      data: { description },
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const deleteRequest = async ({ requestIds, isDeleteAll, type }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `${RESOURCE.REQUESTS}/delete`,
      data: { requestIds, isDeleteAll, type },
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const updateRequest = async (requestId, data) => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.REQUESTS}/${requestId}`,
    data,
  });
  return response;
};

export const getPresignedAudioUrl = async (requestId) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.REQUESTS}/${requestId}/audio`,
  });
  return response;
};

export const downloadAudioLinks = (audioLinks, index = 0) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = audioLinks[index];
  downloadLink.download = audioLinks[index].substring(
    audioLinks[index].lastIndexOf('/') + 1,
  );
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  setTimeout(() => {
    downloadAudioLinks(audioLinks, index + 1);
  }, 1000);
};

export const getAudioDownloadUrl = async (requestId) => {
  const datasenses = dataSenses.getDataSensesRequireFields();

  const response = await api({
    method: 'GET',
    url: `${RESOURCE.REQUESTS}/${requestId}/audio-download-url`,
    params: { datasenses },
  });
  return response;
};
