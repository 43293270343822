import React from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import SrtFileIcon from '@src/assets/icons/icon-srt-file.svg';
import Mp4FileIcon from '@src/assets/icons/icon-mp4-file.svg';

import { StyledFileSelected } from './index.style';

const FileSelected = ({
  fileName,
  handleResetFile,
  fileImageUrl = '',
  isYoutubeVideo = false,
}) => {
  const { isConverting } = useSelector((state) => state.dubbingRequest);
  const fileExtension = isYoutubeVideo ? '' : fileName?.split('.').pop();
  let fileIcon = fileImageUrl;
  if (!isYoutubeVideo) {
    fileIcon = fileExtension === 'srt' ? SrtFileIcon : Mp4FileIcon;
  }

  return (
    <StyledFileSelected onClick={(e) => e.stopPropagation()}>
      <Box className="file-info">
        <img
          src={fileIcon}
          className={isYoutubeVideo ? 'youtube-thumbnail' : ''}
          alt="srt-icon"
        />
        <Typography className="file-name">{fileName}</Typography>
      </Box>
      {isConverting ? (
        <CircularProgress size={20} className="blue" />
      ) : (
        <Cancel className="cancel-icon" onClick={handleResetFile} />
      )}
    </StyledFileSelected>
  );
};

export default FileSelected;
