import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import ROUTES from '@src/constants/route';
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import { Check, Clear } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GiftIcon from '@src/assets/icons/gift-filled-icon.svg';
import {
  DURATION,
  FREE_PACKAGE_CODES,
  PACKAGE_CODE,
  PACKAGE_EXPIRED,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
} from '@src/constants/package';
import actions from '@src/redux/actions';
import i18n from '@src/languages';
import apis from '@src/apis';
import ProcessHandler from '@src/components/ProcessHandler';
import { LANGUAGE } from '@src/constants';
import { ORDER_STATUS } from '@src/constants/order';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import NotificationDialog from '@src/components/NotificationDialog';
import StackedAvatars from '@src/components/StackedAvatars';
import { VBEE_CONSOLE_URL } from '@src/configs';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { checkVietNam } from '@src/utils/checkCountry';
import { delay } from '@src/utils/delay';
import { COLOR } from '@src/styles/color';
import {
  getFakeEndTimeDiscountForPackage,
  getPackageCurrentPrice,
} from '@src/services/package';
import PopularIcon from '@src/assets/icons/popular.svg';

import avatarMP11 from '@src/assets/avatars/avatar-mp-1-1.png';
import avatarMP12 from '@src/assets/avatars/avatar-mp-1-2.png';
import avatarMP13 from '@src/assets/avatars/avatar-mp-1-3.png';
import avatarMP21 from '@src/assets/avatars/avatar-mp-2-1.png';
import avatarMP22 from '@src/assets/avatars/avatar-mp-2-2.png';
import avatarMP23 from '@src/assets/avatars/avatar-mp-2-3.png';
import avatarMP31 from '@src/assets/avatars/avatar-mp-3-1.png';
import avatarMP32 from '@src/assets/avatars/avatar-mp-3-2.png';
import avatarMP33 from '@src/assets/avatars/avatar-mp-3-3.png';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

import { getSavedPrice } from '@src/services/voucher';
import dataSenses from '@src/services/dataSenses';
import { StyledNewPackageItem, StyledPackageItemWrapper } from './index.style';
import NewCountdownBanner from './NewCountdownBanner';

const checkPackageDiscountForFirstUser = ({
  packageId,
  isFirstTimePaid,
  firstPaidVoucher,
}) => {
  if (!isFirstTimePaid) return false;
  if (!firstPaidVoucher) return false;
  const { packages } = firstPaidVoucher?.policy || {};
  const packageIds = packages?.map((item) => item.id);

  return packageIds?.includes(packageId);
};

const getPriceForFirstPaidUser = ({
  isVietNam,
  voucher,
  currPrice,
  currUsdPrice,
}) => {
  const savedMoney = getSavedPrice({ voucher, currPrice, currUsdPrice });
  return isVietNam ? currPrice - savedMoney : currUsdPrice - savedMoney;
};

const getPriceFromVoucher = ({
  isVietNam,
  voucher,
  currPrice,
  currUsdPrice,
  duration,
}) => {
  if (!voucher) return isVietNam ? currPrice : currUsdPrice;
  // if not have duration mean not calculate price per month
  if (!duration || voucher.type === VOUCHER_TYPE.PERCENT) {
    const savedMoney = getSavedPrice({ voucher, currPrice, currUsdPrice });
    const price = isVietNam
      ? currPrice - savedMoney
      : currUsdPrice - savedMoney;
    return Math.max(0, price);
  }

  if (isVietNam) {
    let savedMoney;

    switch (duration) {
      case DURATION.MONTHLY:
        savedMoney = voucher.value;
        break;
      case DURATION.QUARTERLY:
        savedMoney = Math.floor(voucher.value / 3 / 1000) * 1000;
        break;
      case DURATION.YEARLY:
        savedMoney = Math.floor(voucher.value / 12 / 1000) * 1000;
        break;
      default:
        savedMoney = voucher.value;
    }

    const price = currPrice - savedMoney;

    return Math.max(0, price);
  }

  return isVietNam ? currPrice : currUsdPrice;
};

const NewPackageItem = ({
  data,
  hasDiscountPackage,
  duration,
  isShowAllPackageInfo,
  setIsShowAllPackageInfo,
  isFirstTimePaid,
  firstPaidVoucher,
  canUseVouchers = [],
  currentUsingPackage,
}) => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { voucherCode, bannerType } = camelcaseKeys(
    queryString.parse(location.search) || {},
  );
  const { user = {} } = useSelector((state) => state.auth);

  const { usingPackage, latestOrder, usingDubbingPackage, latestDubbingOrder } =
    useSelector((state) => state.user);

  const { dubbing } = useSelector((state) => state.auth.user);

  const userRef = useRef(user);

  const [bestVoucher, setBestVoucher] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState({
    createFreeOrder: false,
    cancelOrder: false,
  });
  const [highlight, setHighlight] = useState(false);
  const [isDiscountPackage, setIsDiscountPackage] = useState(false);
  const [packageContents, setPackageContents] = useState([]);
  const { getFeatureValue } = useFeatureFlags();
  const isNewAutoSelectVoucher = getFeatureValue(
    FEATURE_KEYS.NEW_AUTO_SELECT_VOUCHER,
  );

  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSensesFromServer = dataSensesFeatures?.s2s;

  // if current package is dubbing trial && user using package in dubbing && (package user use is not dubbing trial || package is dubbing trial but expires)
  const isUsedDubbingTrialPackage =
    data.code === PACKAGE_CODE.DUBBING_TRIAL &&
    currentUsingPackage?.code &&
    (currentUsingPackage?.code !== PACKAGE_CODE.DUBBING_TRIAL ||
      currentUsingPackage?.isExpires);

  const isVietNam = checkVietNam();
  const isDiscountForFirstUser = checkPackageDiscountForFirstUser({
    packageId: data.id,
    isFirstTimePaid,
    firstPaidVoucher,
  });

  const { language } = i18n;

  const {
    code,
    price: originalPrice,
    usdPrice: originalUsdPrice,
    monthlyPrice: originalMonthlyPrice,
    usdMonthlyPrice: originalMonthlyUsdPrice,
  } = data;
  const { price, usdPrice } = getPackageCurrentPrice(data, true);
  const isDiscountUsingVoucher = bestVoucher?.value;

  const isUsingFreePackage =
    [PACKAGE_LEVEL.BASIC, PACKAGE_LEVEL.DUBBING_BASIC].includes(data.level) &&
    ((!isDubbingRoute && usingPackage.code === data.code) ||
      (isDubbingRoute && usingDubbingPackage?.code === data.code));
  const isPaygPackage =
    code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2 && isVietNam;
  const showPackageTotalPrice =
    duration !== DURATION.MONTHLY && price !== 0 && !isPaygPackage;

  const LIMIT_NUMBER_FEATURES_DISPLAYED = 6;

  const isApplyFirstPaidVoucher =
    (bestVoucher?.description || 0) === (firstPaidVoucher?.description || 1);

  const getBestVoucherForUser = () => {
    // Check if not using free package or not have voucher to use or not api enterprise year
    if (
      !canUseVouchers ||
      canUseVouchers.length === 0 ||
      [
        PACKAGE_LEVEL.BASIC,
        PACKAGE_LEVEL.DUBBING_BASIC,
        PACKAGE_LEVEL.DUBBING_TRIAL,
      ].includes(data.level) ||
      data.code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2
    ) {
      setBestVoucher();
      return;
    }

    // List voucher includes firstPaidVoucher if sastified time
    const listVoucherSortByRanking = canUseVouchers
      .map((voucherItem) => {
        const reducedPrice = getSavedPrice({
          voucher: voucherItem,
          currPrice: isDiscountPackage
            ? data?.discountConfig?.price || price
            : price,
          currUsdPrice: isDiscountPackage
            ? data?.discountConfig?.usdPrice || usdPrice
            : usdPrice,
        });
        return { ...voucherItem, reducedPrice };
      })
      .sort((a, b) => b.reducedPrice - a.reducedPrice);
    if (listVoucherSortByRanking.length > 0)
      setBestVoucher(listVoucherSortByRanking[0]);
  };

  const handleCreateFreeOrder = async (packageId) => {
    if (!isDubbingRoute && usingPackage.level === PACKAGE_LEVEL.BASIC) return;

    if (
      isDubbingRoute &&
      usingDubbingPackage?.level === PACKAGE_LEVEL.DUBBING_BASIC
    )
      return;

    if (latestOrder.status === ORDER_STATUS.PENDING) {
      setOpenDialog(true);
      return;
    }

    setLoading({ ...loading, createFreeOrder: true });
    const orderData = await apis.orders.createOrder({
      packageId,
      isGlobal: !isVietNam,
      downgradePackage: true,
      datasenses: sendDataSensesFromServer
        ? dataSenses.getDataSensesRequireFields()
        : undefined,
    });
    setLoading({ ...loading, createFreeOrder: false });

    if (orderData.status) {
      if (isDubbingRoute)
        dispatch(actions.user.getLatestDubbingOrderSuccess(orderData.result));
      else dispatch(actions.user.getLatestOrderSuccess(orderData.result));
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'buyPackageSuccessfully',
        }),
      );
      if (orderData.result.type === PACKAGE_TYPE.DUBBING) {
        dispatch(
          actions.user.getUsingDubbingPackageSuccess(orderData.result.package),
        );
        dispatch(
          actions.auth.updateUserInfo({
            ...userRef.current,
            dubbing: {
              ...userRef.current.dubbing,
              packageCode: orderData.result?.package?.code,
              packageExpiryDate: orderData.result?.packageExpiryDate,
            },
          }),
        );
        history.push(ROUTES.HOME_DUBBING);
      } else {
        dispatch(actions.user.getUsingPackageSuccess(orderData.result.package));
        history.push(ROUTES.TTS);
      }
      return;
    }

    dispatch(
      actions.noti.push({ severity: 'error', message: 'buyPackageFailure' }),
    );
  };

  const handleBuyPackage = async () => {
    if (data.price === 0) {
      if (isUsingFreePackage) return;
      // window.dataLayer.push({
      //   event: EVENT_TYPE.STUDIO_ACTIVE_FREE_PLAN,
      //   userId: user?.id,
      //   email: user.email,
      //   package: data.code,
      //   createdAt: moment().format('DD/MM/YYYY - HH:mm'),
      // });
      await delay(1000);
      handleCreateFreeOrder(data.id);
      return;
    }
    const search = voucherCode
      ? `?package=${data.code}&&voucherCode=${voucherCode}&&bannerType=${bannerType}`
      : `?package=${data.code}`;

    if (data.type === PACKAGE_TYPE.API) {
      window.location.assign(`${VBEE_CONSOLE_URL}/payments${search}`);
    } else {
      history.replace({ search });
    }
  };

  const handleCancelPendingOrder = async () => {
    if (!isDubbingRoute && latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (isDubbingRoute && latestDubbingOrder.status !== ORDER_STATUS.PENDING)
      return;

    setLoading({ ...loading, cancelOrder: true });
    const orderData = await apis.orders.cancelOrder(latestOrder.id);
    setLoading({ ...loading, cancelOrder: false });
    if (!orderData.status)
      dispatch(actions.noti.push({ severity: 'error', code: orderData.code }));
    else {
      if (data.result.type === PACKAGE_TYPE.STUDIO)
        dispatch(actions.user.getLatestOrderSuccess(data.result));
      else if (data.result.type === PACKAGE_TYPE.DUBBING)
        dispatch(actions.user.getLatestDubbingOrderSuccess(data.result));

      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'cancelOrderSuccessfully',
        }),
      );
    }

    setOpenDialog(false);
    setLoading({ ...loading, createFreeOrder: false });
  };

  const handleContinuePayingDialog = () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (latestOrder.paymentLink)
      window.location.assign(latestOrder.paymentLink);
    else history.replace({ search: `?package=${data.code}` });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const renderButtonText = () => {
    const { startAt, endAt } = data?.discountConfig || {};
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);
    if (!currentUsingPackage?.rank) {
      if (FREE_PACKAGE_CODES.includes(data.code)) return t('activeNow');
      return data.mostPopular || inDiscountTime ? t('buyNow') : t('choosePack');
    }

    if (isUsedDubbingTrialPackage) return t('used');

    const isDowngradePackage =
      currentUsingPackage && currentUsingPackage?.rank > data?.rank;
    const isUpgradePackage =
      currentUsingPackage && currentUsingPackage?.rank < data?.rank;
    const isKeepPackage =
      currentUsingPackage && currentUsingPackage?.rank === data?.rank;

    let textBtn = '';

    if (FREE_PACKAGE_CODES.includes(data.code))
      textBtn = isKeepPackage ? t('using') : t('activeNow');
    else if (isDowngradePackage)
      textBtn =
        inDiscountTime || data.mostPopular ? t('buyNow') : t('choosePack');
    else if (isKeepPackage)
      textBtn = inDiscountTime || data.mostPopular ? t('buyNow') : t('buyMore');
    else if (isUpgradePackage)
      textBtn =
        inDiscountTime || data.mostPopular ? t('upgradeNow') : t('upgrade');
    return textBtn;
  };

  const renderPackagePriceUnit = (value) => {
    if (!value) return '';
    if (value < 1) return `/${t('character')}`;
    return `/${t('month')}`;
  };

  const renderBilledTotalDesc = (packageDuration) => {
    switch (packageDuration) {
      case PACKAGE_EXPIRED.MONTHLY:
        return t('billedMonthly');
      case PACKAGE_EXPIRED.QUARTERLY:
        return t('billedQuarterly');
      case PACKAGE_EXPIRED.YEARLY:
        return t('billedAnnually');
      default:
        return '';
    }
  };

  const getPackageContents = ({ contents, maxSeconds, packageType }) => {
    if (packageType !== PACKAGE_TYPE.DUBBING) return contents;

    // Calculate unit price / minute
    let unitPrice;
    if (isVietNam) unitPrice = Math.round((price / maxSeconds) * 60);
    else {
      unitPrice = (usdPrice / maxSeconds) * 60;
      unitPrice = Math.round(unitPrice * 100) / 100;
    }

    // Create unit price content
    const viUnitPriceContent = {
      vi: `Đơn giá <br><b>₫${unitPrice.toLocaleString(language)} / phút</b>`,
      en: `Unit price <br><b>₫${unitPrice.toLocaleString(
        language,
      )} / minute</b>`,
      active: true,
    };

    const usdUnitPriceContent = {
      vi: `Đơn giá <br><b>$${unitPrice.toLocaleString(language)} / phút</b>`,
      en: `Unit price <br><b>$${unitPrice.toLocaleString(
        language,
      )} / minute</b>`,
      active: true,
    };

    const unitPriceContent = isVietNam
      ? viUnitPriceContent
      : usdUnitPriceContent;
    const newContents = [...contents];
    newContents.splice(1, 0, unitPriceContent);

    return newContents;
  };

  // display buy button when
  // 1. package item is free and user is not using package, paid order expired
  // 2. package item is free and user is using trial package
  // 3. package item is not free
  const checkDisplayBuyButton = (studioPackages, dubbingPackages) => {
    const basicTrialStudioPackages = [PACKAGE_LEVEL.TRIAL];

    if (isDubbingRoute) return data.price > 0 || !dubbingPackages?.id;

    return (
      data.price > 0 ||
      !studioPackages?.id ||
      basicTrialStudioPackages.includes(studioPackages?.level)
    );
  };
  const displayBuyButton = checkDisplayBuyButton(
    usingPackage,
    usingDubbingPackage,
  );

  const renderPrice = (priceValue, monthlyPrice) => {
    let formattedPrice =
      monthlyPrice > 1
        ? monthlyPrice.toLocaleString(language)
        : priceValue.toLocaleString(language);

    if (isNewAutoSelectVoucher) {
      formattedPrice =
        monthlyPrice?.toLocaleString(language) ||
        priceValue.toLocaleString(language);
    }

    if (!isVietNam && formattedPrice !== '0') {
      const number = +Number(formattedPrice.replace(',', '.'));
      formattedPrice = number.toLocaleString(language, {
        minimumFractionDigits: 2,
      });
    }

    const renderInternationPrice = () => {
      if (data.code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2)
        return (
          <span className="three-first">
            {/* Case have code === Enterprise API */}
            {priceValue.toLocaleString(language) ||
              monthlyPrice.toLocaleString(language)}
          </span>
        );
      if (isVietNam)
        return (
          <>
            <span className="three-first">
              {/* Format before 4 last character is big */}
              {formattedPrice.slice(0, -4) || '0'}
            </span>
            {/* Format last 4 character is small */}
            <span className="three-last">
              {formattedPrice.slice(-4) === '0' ? '' : formattedPrice.slice(-4)}
            </span>
          </>
        );
      return formattedPrice;
    };

    return (
      <>
        <Typography
          className={classnames(
            isDiscountPackage || data.mostPopular ? 'price-highlight' : 'price',
            {
              'price-en': !isVietNam && priceValue > 0,
            },
          )}
        >
          <span
            className={`${
              isDiscountPackage || data.mostPopular
                ? 'unit-price-highlight'
                : 'unit-price'
            } ${isVietNam ? 'underlined' : ''}`}
          >
            {isVietNam ? 'đ' : '$'}
          </span>
          {/* PSUGO pakage have month price < 1 */}
          {renderInternationPrice()}
          {isNewAutoSelectVoucher
            ? !isDiscountPackage &&
              !isDiscountUsingVoucher &&
              data.monthlyPrice !== 0 && (
                <span className="per-month">
                  &nbsp;
                  {`/${t(
                    data.code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2
                      ? 'character'
                      : 'month',
                  )}`}
                </span>
              )
            : !isDiscountPackage &&
              data.monthlyPrice !== 0 && (
                <span className="per-month">
                  &nbsp;
                  {`/${t(
                    data.code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2
                      ? 'character'
                      : 'month',
                  )}`}
                </span>
              )}
        </Typography>
      </>
    );
  };

  const renderTotalPrice = (value) => (
    <>
      <Typography
        className={classnames('original-price', {
          'price-en': !isVietNam && value > 0,
        })}
      >
        {!isVietNam && (
          <span className="original-price-unit font-size-14">$</span>
        )}
        {isVietNam && (
          <span className="original-price-unit underlined font-size-14">đ</span>
        )}
        {value}
      </Typography>
      <Typography className="sub-origin-price">
        {renderBilledTotalDesc(data.expiresIn)}
      </Typography>
    </>
  );

  const MostPopularBanner = () => (
    <div className="most-popular-banner">
      <img src={PopularIcon} alt="most-popular" />
      <Typography className="most-popular-text">{t('mostPopular')}</Typography>
      <img src={PopularIcon} alt="most-popular" />
    </div>
  );

  const EmptyBanner = () => <div className="regular-package-banner" />;

  useEffect(() => {
    const { startAt, endAt } = data.discountConfig || {};
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);
    if (inDiscountTime || data.mostPopular) {
      setHighlight(true);
    } else setHighlight(false);

    if (inDiscountTime) setIsDiscountPackage(true);
    else setIsDiscountPackage(false);
  }, [data, hasDiscountPackage]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (isNewAutoSelectVoucher) getBestVoucherForUser();
  }, [canUseVouchers, isDiscountPackage, isNewAutoSelectVoucher]);

  useEffect(() => {
    if (data?.contents) {
      const contents = getPackageContents({
        contents: data.contents,
        maxSeconds: data.maxSeconds,
        packageType: data.type,
      });
      setPackageContents(contents);
    }
  }, [data?.contents, language, isVietNam]);

  const renderBannerPackage = (discount = {}, mostPopular) => {
    const { startAt, endAt } = discount;
    const inDiscountTime = startAt && moment().isBetween(startAt, endAt);
    let endAtToShow = endAt;
    const useFakeEndAt = getFeatureValue(FEATURE_KEYS.FAKE_END_TIME_VOUCHER); // hours
    if (useFakeEndAt) {
      endAtToShow = getFakeEndTimeDiscountForPackage({
        startTimeToCountdown: startAt,
        timeToResetCountdown: useFakeEndAt,
        endTimeToCountdown: endAt,
      });
    }
    if (inDiscountTime)
      return (
        <NewCountdownBanner
          endAt={!useFakeEndAt ? data.discountConfig?.endAt : endAtToShow || {}}
        />
      );
    if (mostPopular) return <MostPopularBanner />;

    return <EmptyBanner />;
  };

  const getDiscountValue = (voucher) => {
    if (!voucher) return '';
    switch (voucher?.type) {
      // TODO: return price $ when have voucher price $
      case VOUCHER_TYPE.PRICE:
        return `<u>đ</u>${(voucher?.value || 0) / 1000}k`;
      case VOUCHER_TYPE.PERCENT:
        return `${voucher?.value || 0}%`;
      default:
        return '';
    }
  };

  const PackageHeader = () => (
    <div className="package-header">
      <div className="">
        <Typography
          className={
            isDiscountPackage || data.mostPopular
              ? 'package-name-popular'
              : 'package-name'
          }
        >
          {data.name && (data.name[language] || data.name[LANGUAGE.EN])}
        </Typography>
        {isDiscountForFirstUser && (
          <Typography
            className="package-limited-deal"
            dangerouslySetInnerHTML={{
              __html: t('discountForFirstPurchase', {
                percent: firstPaidVoucher.value || 0,
              }),
            }}
          />
        )}
        {bestVoucher && !isApplyFirstPaidVoucher && (
          <div className="voucher-gift-icon">
            <img src={GiftIcon} alt="gift-icon" />
            <Typography
              className="voucher-name"
              dangerouslySetInnerHTML={{
                __html: t('discount', {
                  discount: getDiscountValue(bestVoucher),
                }),
              }}
            />
          </div>
        )}
      </div>
      {data.icon ? (
        <div
          className={`${highlight ? 'package-icon-active' : 'package-icon'}`}
        >
          <img src={data.icon} alt="icon-type" />
        </div>
      ) : (
        <SchoolIcon
          fontSize="small"
          className={classnames({ 'most-popular': data.mostPopular })}
        />
      )}
    </div>
  );

  const PackagePrice = () => {
    const originalVndPackagePrice = originalMonthlyPrice || originalPrice;
    const originalUsdPackagePrice = originalMonthlyUsdPrice || originalUsdPrice;

    let monthlyPrice = isVietNam ? data.monthlyPrice : data.usdMonthlyPrice;
    let totalPrice = isVietNam ? data.price : data.usdPrice;

    if (isNewAutoSelectVoucher) {
      if (isDiscountPackage) {
        monthlyPrice = getPriceFromVoucher({
          isVietNam,
          voucher: bestVoucher,
          currPrice: data?.discountConfig?.monthlyPrice,
          currUsdPrice: data?.discountConfig?.usdMonthlyPrice,
          duration,
        });
        totalPrice = getPriceFromVoucher({
          isVietNam,
          voucher: bestVoucher,
          currPrice: data?.discountConfig?.price,
          currUsdPrice: data?.discountConfig?.usdPrice,
        });
      }

      if (isDiscountUsingVoucher && !isDiscountPackage) {
        monthlyPrice = getPriceFromVoucher({
          isVietNam,
          voucher: bestVoucher,
          currPrice: data.monthlyPrice || data.price,
          currUsdPrice: data.usdMonthlyPrice || data.usdPrice,
          duration,
        });

        totalPrice = getPriceFromVoucher({
          isVietNam,
          voucher: bestVoucher,
          currPrice: data.price,
          currUsdPrice: data.usdPrice,
        });
      }
    } else {
      if (isDiscountPackage) {
        monthlyPrice = isVietNam
          ? data?.discountConfig?.monthlyPrice
          : data?.discountConfig?.usdMonthlyPrice;

        totalPrice = isVietNam
          ? data?.discountConfig?.price
          : data?.discountConfig?.usdPrice;
      }
      if (isDiscountForFirstUser) {
        monthlyPrice = getPriceForFirstPaidUser({
          isVietNam,
          voucher: firstPaidVoucher,
          currPrice: data.monthlyPrice,
          currUsdPrice: data.usdMonthlyPrice,
        });
        totalPrice = getPriceForFirstPaidUser({
          isVietNam,
          voucher: firstPaidVoucher,
          currPrice: data.price,
          currUsdPrice: data.usdPrice,
        });
      }
    }

    return (
      <>
        <div className="price-wrapper">
          {/* Render monthly price */}
          <div className="price-package">
            {isVietNam
              ? renderPrice(price, monthlyPrice)
              : renderPrice(price, monthlyPrice)}
          </div>
          {(isDiscountPackage ||
            isDiscountForFirstUser ||
            isDiscountUsingVoucher) && (
            <div className="price-original-discount">
              <Typography>
                {!isVietNam && (
                  <span className="discount-unit-price font-size-14">$</span>
                )}
                {isVietNam && (
                  <span className="discount-unit-price underlined font-size-14">
                    đ
                  </span>
                )}
                <span className="original-price">
                  {isVietNam
                    ? originalVndPackagePrice?.toLocaleString(language)
                    : originalUsdPackagePrice?.toLocaleString(language)}
                </span>
                <span className="original-price-not-line-through">
                  &nbsp;{renderPackagePriceUnit(data.price)}
                </span>
              </Typography>
            </div>
          )}
        </div>
        {showPackageTotalPrice ? (
          <div className="original-price-wrapper">
            {isVietNam
              ? renderTotalPrice(totalPrice?.toLocaleString(language))
              : renderTotalPrice(`${totalPrice?.toLocaleString(language)}`)}
          </div>
        ) : (
          <div className="original-price-wrapper" />
        )}
      </>
    );
  };

  const displayedContents = isShowAllPackageInfo
    ? packageContents
    : packageContents?.slice(0, LIMIT_NUMBER_FEATURES_DISPLAYED);

  const renderRandomAvatars = () => {
    const avatarsDay1List = [avatarMP11, avatarMP12, avatarMP13];
    const avatarsDay2List = [avatarMP21, avatarMP22, avatarMP23];
    const avatarsDay3List = [avatarMP31, avatarMP32, avatarMP33];
    const allAvatars = [avatarsDay1List, avatarsDay2List, avatarsDay3List];

    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    return allAvatars[dayOfWeek % allAvatars.length];
  };

  const checkExpiredDate = (expiryDate) => {
    if (!expiryDate) return false;
    return moment().isAfter(expiryDate);
  };

  const expiryDate = isDubbingRoute
    ? dubbing?.packageExpiryDate
    : user.packageExpiryDate;

  const isExpiredPackage = checkExpiredDate(expiryDate);

  return (
    <StyledPackageItemWrapper>
      {renderBannerPackage(data.discountConfig, data.mostPopular)}
      <StyledNewPackageItem
        highlight={highlight}
        isDiscountPackage={
          isDiscountPackage || (data.mostPopular && !hasDiscountPackage)
        }
        isDisableButton={
          (!displayBuyButton && !isExpiredPackage) ||
          data.code === PACKAGE_CODE.DUBBING_TRIAL
        }
        isDiscountPkg={isDiscountPackage}
        isMostPopular={data.mostPopular}
        free={data.price ? 0 : 1}
      >
        <div className="card-package">
          {/** Package Header */}
          <PackageHeader />

          {/** Package Price */}
          <PackagePrice />

          {/** Button buy package */}
          <div className="card-content">
            <Button
              fullWidth
              className={classnames('button-start', {
                'button-highlight': highlight && data.mostPopular,
              })}
              variant={highlight && data.mostPopular ? 'contained' : 'outlined'}
              onClick={handleBuyPackage}
              disabled={
                (!displayBuyButton && !isExpiredPackage) ||
                data.code === PACKAGE_CODE.DUBBING_TRIAL
              }
            >
              {renderButtonText()}
            </Button>

            {/* Package popularity */}
            {data.mostPopular ? (
              <div className="popularity">
                <StackedAvatars images={renderRandomAvatars(false)} />
                <Typography
                  className="popularity-text"
                  variant="body2"
                  sx={{ color: COLOR.black[64] }}
                >
                  {t('mostPeopleChooseThis', {
                    percent: data?.popularPercent || 86,
                  })}
                </Typography>
              </div>
            ) : (
              <div style={{ height: '30px' }} />
            )}

            <Divider sx={{ mt: '12px' }} />
            {/* Package features */}
            <List>
              {packageContents?.map((item) => (
                <Collapse
                  in={
                    isShowAllPackageInfo ||
                    (displayedContents && displayedContents.includes(item))
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <Box display="flex" justifyContent="space-between">
                    <ListItem className="clarify" key={item.vi}>
                      <Box display="flex" justifyContent="space-between">
                        <div alignItems="flex-start" className="icon-contents">
                          {item.active ? (
                            <Check fontSize="small" color="success" />
                          ) : (
                            <Clear fontSize="small" color="light" />
                          )}
                        </div>
                        <div
                          className={
                            item.active ? 'content' : 'content-deactivate'
                          }
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              language === LANGUAGE.VN
                                ? item?.[language]
                                : item[LANGUAGE.EN],
                            ),
                          }}
                        />
                      </Box>
                    </ListItem>
                    {[PACKAGE_LEVEL.BASIC, PACKAGE_LEVEL.TRIAL].includes(
                      data.level,
                    ) &&
                      item.en.includes('Voices') && (
                        <Box paddingTop="8px">
                          <Tooltip
                            arrow
                            title={t('internationalVoicesNotSupport')}
                            placement="top"
                          >
                            <InfoOutlinedIcon
                              sx={{
                                width: '16px',
                                height: '16px',
                                color: COLOR.darkV2[32],
                              }}
                            />
                          </Tooltip>
                        </Box>
                      )}
                  </Box>
                </Collapse>
              ))}
            </List>
            {packageContents?.length > LIMIT_NUMBER_FEATURES_DISPLAYED && (
              <Button
                variant="text"
                className="show-more-btn"
                onClick={() => setIsShowAllPackageInfo(!isShowAllPackageInfo)}
                disableRipple
              >
                {isShowAllPackageInfo ? t('collapse') : t('showMore')}
              </Button>
            )}
          </div>
        </div>
        {data.price === 0 && (
          <NotificationDialog
            name="pendingOrder"
            title={t('havePendingOrderTitle')}
            description={t('hasOrderWaitForConfirmTransferDescription')}
            variant="warning"
            open={openDialog}
            onClose={handleCloseDialog}
            actionComponent={
              <>
                <Button variant="outlined" onClick={handleCancelPendingOrder}>
                  <ProcessHandler loading={loading.cancelOrder} size={25}>
                    {t('cancelOrder')}
                  </ProcessHandler>
                </Button>
                <Button
                  variant="contained"
                  onClick={handleContinuePayingDialog}
                >
                  {t('continue')}
                </Button>
              </>
            }
          />
        )}
      </StyledNewPackageItem>
    </StyledPackageItemWrapper>
  );
};

export default NewPackageItem;
