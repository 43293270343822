import { LANDING_PAGE_URL } from '@src/configs';

const VN_SAMPLE_AUDIOS = [
  {
    code: 'hn_female_ngochuyen_full_48k-fhg',
    name: 'HN - Ngọc Huyền',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/ngoc-huyen.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/ngoc-huyen.mp3`,
      style: 'entertainment',
      text: 'Xin chào quý vị và các bạn! Quy Nhơn là thành phố biển xinh đẹp của tỉnh Bình Định thuộc khu vực Duyên Hải Nam Trung Bộ. Thành phố này cách thủ đô Hà Nội 1065km.',
    },
  },
  {
    code: 'hn_male_phuthang_stor80dt_48k-fhg',
    name: 'HN - Anh Khôi',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/anh-khoi.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/anh-khoi.mp3`,
      style: 'audioBook',
      text: 'Thời Tuyên Đức, trong vùng rất chuộng chơi chọi dế, hàng năm bắt dân gian cung tiến. Trò đó không phải nảy sinh từ đất Thiểm Tây, mà do viên quan huyện lệnh huyện Hoa Âm muốn lấy lòng quan trên đem tiến một con.',
    },
    styles: ['story'],
  },
  {
    code: 'hn_male_manhdung_news_48k-fhg',
    name: 'HN - Mạnh Dũng',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/manh-dung.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/manh-dung.mp3`,
      style: 'advertisement',
      text: 'Kamito TA11 là một sự kết hợp giữa Kamito và nhà ảo thuật gia tiền vệ tài hoa Nguyễn Tuấn Anh của câu lạc bộ Hoàng Anh Gia Lai. Đây là mẫu giày mà nhà sản xuất đã mất rất nhiều thời gian để nghiên cứu, phát triển và hoàn thiện.',
    },
  },
  {
    code: 'sg_female_tuongvy_call_44k-fhg',
    name: 'SG - Tường Vy',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/tuong-vy.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/tuong-vy.mp3`,
      style: 'callCenter',
      text: 'Bệnh viện đa khoa Vbee kính chào anh/chị, đây là cuộc gọi nhắc lịch khám từ bệnh viện. Anh/chị có lịch hẹn khám vào lúc 2h chiều ngay 27/8. Xin vui lòng đến phòng P205 tầng 2 khoa da liễu để tiếp tục theo dõi và điều trị.',
    },
    styles: ['call-center'],
  },
  {
    code: 'sg_female_thaotrinh_full_44k-phg',
    name: 'SG - Thảo Trinh',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/thao-trinh.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/thao-trinh.mp3`,
      style: 'podcast',
      text: 'Mình là cá, việc của mình là bơi - Trái tim tôi dường như đã tan vỡ. Tôi vốn là một người theo chủ nghĩa hoàn hảo và không tin tưởng người khác. Do đó, tôi thường ôm tất cả vào người và tự mình giải quyết.',
    },
    styles: ['emphasis'],
  },
  {
    code: 'hn_male_thanhlong_talk_48k-fhg',
    name: 'HN - Thanh Long',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/thanh-long.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/thanh-long.mp3`,
      style: 'broadcasting',
      text: 'Trong khi vẫn đang loay hoay tìm câu trả lời cho câu hỏi “Tôi là ai”, “Tôi sinh ra để làm gì” Cậu đã vô tình tìm thấy một lá thư từ rất lâu, từ người cha yêu dấu gửi cho mình.',
    },
  },
  {
    code: 'hn_female_maiphuong_vdts_48k-fhg',
    name: 'HN - Mai Phương',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/mai-phuong.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/mai-phuong.mp3`,
      style: 'news',
      text: 'Dự án cầu Vĩnh Tuy 2 khởi công vào tháng 1/2021 được xem là công trình có ý nghĩa quan trọng với thủ đô nhằm hoàn thiện toàn bộ đường vành đai 2 của thành phố Hà Nội.',
    },
  },
  {
    code: 'sg_female_lantrinh_vdts_48k-fhg',
    name: 'SG - Lan Trinh',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/lan-trinh.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/lan-trinh.mp3`,
      style: 'history',
      text: 'Ngày 26/9, Hồ Chủ tịch đọc lời kêu gọi: “Tôi tin vào đồng bào cả nước đều tin vào lòng kiên quyết, ái quốc của đồng bào Nam Bộ, thà chết tự do còn hơn sống nô lệ”',
    },
  },
];

const GLOBAL_SAMPLE_AUDIOS = [
  {
    code: 'en-AU-Wavenet-B',
    name: 'Lucas',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/male.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/au.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/lucas.mp3`,
      style: 'announcementEN',
      text: '“Good afternoon, ladies and gentlemen. Captain Perez and his crew welcome you aboard Iberia flight IB341 to Madrid. I’m sorry to announce a slight delay. We are still waiting for clearance from Air Traffic Control. The delay won’t be too long and we hope to arrive in Madrid on time”. “This is your captain speaking. We are now passing over the English coast. Our Boeing 727 is cruising at a height of 30.000 feet and our speed is approximately 560 miles per hour. The temperature in Madrid is 18oC and it is a clear and sunny day. We expect to pass through some slight turbulence and would recommend passengers to remain in their seats and keep their belts fastened”. Thank you',
    },
    level: 'PREMIUM',
  },
  {
    code: 'en-GB-Wavenet-A',
    name: 'Emily',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/female.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/emily.mp3`,
      style: 'noticeEN',
      text: 'Vbee General Hospital greets you. This is a reminder call for your upcoming medical appointment. Your scheduled appointment time is at 2:00 PM on August 27th. Kindly proceed to Room B205, 2nd floor, Dermatology Department for further evaluation and treatment. When you come, please ensure to bring the following documents: medical appointment card, ID card, health insurance card, and appointment rescheduling slip (if applicable). Thank you',
    },
    level: 'PREMIUM',
  },
  {
    code: 'en-GB-Wavenet-B',
    level: 'PREMIUM',
    name: 'James',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/male.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/james.mp3`,
      style: 'storyEN',
      text: 'NEVER TELL A LIE On his deathbed, a father advised his son to always speak truth. The son promised that he would never tell a lie. One day, while going to the city through a forest, he got surrounded by some robbers. One of them asked, “What do you have?” The boy answered, “I have fifty rupees.” They searched him but couldn’t find anything.When they were about to go, the boy called out, “I am not telling a lie. See this fifty rupee note which I had hidden in my shirt.” The leader of the robbers felt pleased at the truthfulness of the boy, gave him hundred rupees as reward and went away.”',
    },
  },
  {
    code: 'en-GB-Wavenet-F',
    level: 'PREMIUM',
    name: 'Mary',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/female.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/mary.mp3`,
      style: 'podcastEN',
      text: 'In all these years both as a student and a teacher, observing my own behaviour, that of my friends and that of my students, the people I was teaching, one thing was abundantly clear: learning is 100 times easier if you are actually engaged with what you are learning, if you find it really interesting.',
    },
  },
  {
    code: 'en-AU-Wavenet-D',
    level: 'PREMIUM',
    name: 'Vicky',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/male.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/au.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/vicky.mp3`,
      style: 'notificationEN',
      text: 'Hello, we are Vbee Delivery Services. Your order is currently in transit, but there has been a delay compared to the initial estimate. We apologize for this inconvenience. The expected delivery date is August 30th. Please feel free to contact us immediately at hotline 0379284041 if you have any issues that need clarification. Thank you',
    },
  },
  {
    code: 'en-GB-Standard-F',
    level: 'STANDARD',
    name: 'Sarah',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/female.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/sarah.mp3`,
      style: 'travelGuideEN',
      text: "Hello everyone. On behalf of Suntan Tours I'd like to welcome you all to Los Cabos. The bus ride to your hotel will take about fifteen minutes. Right now I'd like to take a minute to familiarize you with the area and discuss some brief safety precautions. Firstly, I ask that you remain seated until we reach our destination and that you not eat or drink while on the bus. Secondly, please realize that it is against the law to get drunk in public. Thank you",
    },
  },
  {
    code: 'en-GB-Wavenet-D',
    level: 'PREMIUM',
    name: 'Robert',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/male.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/robert.mp3`,
      style: 'reviewMovie',
      text: "The Shawshank Redemption tells the story of a man named Andy Dufresne, who is sentenced to life in prison for a crime he didn't commit. The movie explores themes of hope, friendship, and the human spirit. The plot of the movie is both engaging and thought-provoking. The viewer is drawn into Andy's world and the challenges he faces as an inmate in Shawshank prison. The film also features a number of memorable characters, including Red, played by Morgan Freeman, who befriends Andy and becomes a mentor to him",
    },
  },
  {
    code: 'Olivia',
    level: 'PREMIUM',
    name: 'Amelia',
    roundImage:
      'https://vbee-studio-data.s3.ap-southeast-1.amazonaws.com/images/voices/round/female.png',
    languageImage:
      'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/au.png',
    sample: {
      audioLink: `${LANDING_PAGE_URL}/audios/samples/amelia.mp3`,
      style: 'beautyAppointment',
      text: 'Hello, this is a reminder call for your beauty appointment. Your appointment is scheduled for March 20th at 10 AM. You can reschedule your appointment by directly contacting us during this call. We are genuinely eager to assist you in achieving your desired appearance and providing you a relaxing experience. Thank you',
    },
  },
];

export { VN_SAMPLE_AUDIOS, GLOBAL_SAMPLE_AUDIOS };
