import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Popover, Button } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import { StyledSelectionButton, StyledMenuItem } from './index.style';

const SelectionButtonNew = ({
  currentValue,
  name,
  tooltipTitle,
  icon,
  text,
  options = [],
  width,
  disabled,
  onChange,
  handleCloseSearchBoxAndMoreMenu,
  handleCloseSearchBox,
  isMoreButton,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    if (isMoreButton) handleCloseSearchBox();
    else handleCloseSearchBoxAndMoreMenu();
  };
  const handleClose = () => setAnchorEl(null);

  const handleChange = (value) => {
    handleClose();
    if (onChange) onChange(name, value);
  };

  return (
    <StyledSelectionButton
      width={width}
      active={!!anchorEl}
      disabled={disabled}
    >
      <Tooltip arrow title={t(tooltipTitle)} placement="top">
        <Button className="custom-button" onClick={handleClick}>
          {typeof icon === 'string' ? (
            <img src={icon} alt={name} className="button-img" />
          ) : (
            icon
          )}
          <Typography variant="body2" className="toolbar-text">
            <span>{currentValue}</span> {text && ` ${text}`}
          </Typography>
        </Button>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {options.map((item) => (
          <StyledMenuItem
            key={item}
            active={currentValue === item}
            onClick={() => handleChange(item)}
          >
            <Typography variant="body2" className="text">
              <span>{t(item)}</span> {text && ` ${text}`}
            </Typography>
          </StyledMenuItem>
        ))}
      </Popover>
    </StyledSelectionButton>
  );
};
export default SelectionButtonNew;
