import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '@src/components/Table';
import BadgeAvatar from '@src/components/BadgeAvatar';
import { Typography } from '@mui/material';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { extractVoiceName } from '@src/services/voice';
import RequestStatus from '@src/components/RequestStatus';
import i18n from '@src/languages';
import {
  MAX_BODY_CONTENT_HEIGHT,
  MIN_OTHER_ELEMENT_HEIGHT,
} from '@src/constants/request';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { PACKAGE_TYPE } from '@src/constants/package';
import ProjectVoice from '@src/containers/NewDubbing/ListProject/ProjectTableItem/ProjectVoice';
import { convertSecondsToHMS } from '@src/utils/time';
import ViewRequest from './ViewRequest';
import RequestAction from './RequestAction';
import ErrorReport from './ErrorReport';

import { StyledRequestTable, StyleVoiceItem } from './index.style';
import TitleTable from './TitleTable';
import RequestsActionHeader from './RequestsActionHeader';

const RequestTable = ({
  requests = [],
  page,
  total,
  sort,
  loading,
  selectedRequests,
  isProcessBySeconds = false,
  onUpdateRequest,
  onChangePage,
  onChangeSort,
  onSelectRequest,
  onDeleteSelectedRequests,
  onEnableEditor,
  audioRef,
  playable = true,
  tableHeight,
  isShowCheckbox,
  limit,
}) => {
  const requestTableRef = useRef(null);

  const [openView, setOpenView] = useState(false);
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const screenHeight = window.innerHeight;
  const user = useSelector((state) => state.auth.user);

  const { getFeatureValue } = useFeatureFlags();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });

  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );
  let useOldRequestTable = getFeatureValue(FEATURE_KEYS.OLD_REQUEST_TABLE);
  useOldRequestTable = useOldRequestTable && !useNewTtsUI;

  const isShowInfoVCRequest = getFeatureValue(
    FEATURE_KEYS.SHOW_INFO_VC_REQUEST,
    { userId: user?.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const { t } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();

  const { languages } = useSelector((state) => state.language);

  const handleOpenView = (request) => {
    setOpenView(true);
    setSelectedRequest(request);
  };

  const handleCloseView = () => {
    setOpenView(false);
    setSelectedRequest(null);
  };

  const handleOpenErrorReport = (request) => {
    setOpenErrorReport(true);
    setSelectedRequest(request);
  };
  const handleCloseErrorReport = () => {
    setOpenErrorReport(false);
    setSelectedRequest(null);
  };

  const renderVoiceName = (voice) => {
    const { voiceName, voiceType } = extractVoiceName({
      name: voice?.name,
      provider: voice?.provider,
    });
    const smallImgSrc = languages.find(
      (lang) => lang?.value === voice?.languageCode,
    )?.roundImage;

    return (
      <StyleVoiceItem>
        <BadgeAvatar
          width={30}
          img={voice?.roundImage}
          type="image"
          smallImgWidth={10}
          smallImg={smallImgSrc}
        />
        <div>
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
            {voiceName}
          </Typography>
          {(voice?.styles?.length || voiceType) && (
            <Typography variant="body2" className="voice-style">
              {voiceType || t(voice.styles[0])}
            </Typography>
          )}
        </div>
      </StyleVoiceItem>
    );
  };

  const columns = [
    {
      field: 'title',
      title: t('title'),
      sortable: false,
      align: 'left',
      width: 'full',
      cellWidth: 'full',
      render: (row) => (
        <TitleTable
          requestId={row.id}
          title={row.title}
          audioLink={row.audioLink}
          requestStatus={row.status}
          onUpdateRequest={onUpdateRequest}
          createdAt={row.createdAt}
        />
      ),
    },
    {
      field: 'voice',
      title: t('voice'),
      sortable: true,
      align: 'left',
      width: '140px',
      cellWidth: '140px',
      render: (row) =>
        isDubbingMultipleVoices &&
        row?.sentencesVoiceCode &&
        row?.type === PACKAGE_TYPE.DUBBING ? (
          <ProjectVoice
            voiceCode={row.voice.code}
            sentencesVoiceCode={JSON.parse(row?.sentencesVoiceCode || {})}
          />
        ) : (
          renderVoiceName(row.voice)
        ),
    },
    {
      field: 'characters',
      title: isProcessBySeconds ? t('duration') : t('characters'),
      sortable: true,
      align: 'right',
      width: '100px',
      cellWidth: '100px',
      render: (row) =>
        isProcessBySeconds
          ? convertSecondsToHMS(row?.seconds)
          : row?.characters?.toLocaleString(language) || 0,
    },
    {
      field: 'audioType',
      title: t('audioType'),
      align: 'center',
      width: '100px',
      cellWidth: '100px',
    },
    {
      id: TTS_GUIDE.STATUS_REQUEST,
      field: 'status',
      title: t('status'),
      sortable: false,
      align: 'left',
      width: '100px',
      cellWidth: '100px',
      render: (row) => <RequestStatus request={row} />,
    },
  ];

  const hoverColumns = [
    {
      id: TTS_GUIDE.ACTION,
      field: 'action',
      title: t('action'),
      sortable: false,
      align: 'center',
      width: '100px',
      cellWidth: '100px',
      render: (row) => (
        <RequestAction
          audioRef={audioRef}
          request={row}
          playable={playable}
          onOpenView={handleOpenView}
          onOpenErrorReport={handleOpenErrorReport}
          isDisabledErrorReport={row?.isDisabledErrorReport}
        />
      ),
    },
  ];

  const handleRowClick = async (requestId) => {
    if (document.getElementById('scroll-point-top'))
      document.getElementById('scroll-point-top').scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    const data = isShowInfoVCRequest
      ? await apis.requests.getRequestV2(requestId)
      : await apis.requests.getRequest(requestId);
    if (data?.status) {
      const synthesisConfig = data.result;

      const bitrateOfBps = synthesisConfig.bitrate || 0;
      const newSynthesisConfig = {
        ...synthesisConfig,
        bitrate: bitrateOfBps % 1000 === 0 ? bitrateOfBps / 1000 : bitrateOfBps,
      };

      onEnableEditor(false);
      dispatch(
        actions.synthesisRequest.updateSynthesisConfig(newSynthesisConfig),
      );
      dispatch(
        actions.request.addSynthesisResponse({
          requestId: data.result.id,
          ...newSynthesisConfig,
        }),
      );
      dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
    }
  };

  const renderExtraHeader = (colPosition) => (
    <RequestsActionHeader
      selectedRequests={selectedRequests}
      onDeleteSelectedRequests={onDeleteSelectedRequests}
      colPosition={colPosition}
    />
  );

  return (
    <StyledRequestTable ref={requestTableRef}>
      <Table
        columns={useOldRequestTable ? [...columns, ...hoverColumns] : columns}
        hoverColumns={useOldRequestTable ? [] : hoverColumns}
        data={requests}
        page={page}
        total={total}
        sort={sort}
        loading={loading}
        selection
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        onRowClick={handleRowClick}
        selected={selectedRequests}
        onSelect={onSelectRequest}
        minWidth={useOldRequestTable ? '1170px' : '1000px'}
        footerPadding="0px 20px 0px"
        showExtraHeaderComponent={selectedRequests?.length > 0}
        renderExtraHeader={renderExtraHeader}
        tableHeight={
          tableHeight ||
          `${Math.min(
            screenHeight / 2 - MIN_OTHER_ELEMENT_HEIGHT,
            MAX_BODY_CONTENT_HEIGHT,
          )}px`
        }
        isShowCheckbox={isShowCheckbox}
        headerStyle={{ zIndex: 10 }}
        isStickyHeader
        limit={limit}
      />
      <ViewRequest
        open={openView}
        requestId={selectedRequest?.id}
        onClose={handleCloseView}
      />
      <ErrorReport
        open={openErrorReport}
        request={selectedRequest}
        onClose={handleCloseErrorReport}
      />
    </StyledRequestTable>
  );
};

export default RequestTable;
