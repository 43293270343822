import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledTabsWrapper = styled('div')`
  width: 100%;
  margin: 16px 0px;

  .MuiTabs-indicator {
    background-color: ${COLOR.darkBlue};
  }

  .Mui-selected {
    color: ${COLOR.darkBlue} !important;
    font-weight: 700;
  }

  .tabs {
    width: 100%;
    display: flex;
    color: ${COLOR.red};
    border-color: ${COLOR.background};
    justify-content: ${(props) =>
      props.isCenter ? 'center' : 'space-between'};
    flex-wrap: wrap-reverse;
  }

  .MuiTab-root {
    color: ${COLOR.black[64]};
    font-weight: 400;
    &:focus,
    &:active {
      background-color: none !important;
    }
  }

  // .MuiTouchRipple-root {
  //   &:focus,
  //   &:active {
  //     background-color: gray !important;
  //   }
  // }
`;

export const StyledTabPanel = styled(Box)`
  padding: 12px 0px;
`;
