import * as React from 'react';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  BORDER_RADIUS,
  TOOLTIP_BOX_SHADOW,
  MOBILE_BREAKPOINT,
  AUDIO_PLAYER_HEIGHT,
} from '@src/styles/config';

export const StyledTTS = styled('div')`
  padding-bottom: ${(props) =>
    props.showaudioplayer ? AUDIO_PLAYER_HEIGHT : '0px'};

  .scroll-point-top {
    transform: translate(0px, -100px);
  }

  .action-header {
    display: flex;
    align-items: center;
  }

  .request {
    margin-top: 8px;
  }

  /* .scroll-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: ${({ openRequest }) => !openRequest && '20px'};
    margin-bottom: 10px;
  } */

  .download-audio-note {
    color: ${COLOR.error};
  }

  .opacity-none {
    opacity: 0;
  }

  .display-none {
    display: none;
  }

  .tts {
    transition: opacity 1s ease-out;
    /* visibility: ${(props) => (props.openRequest ? 'hidden' : 'visible')};
    height: ${(props) => (props.openRequest ? '0' : 'auto')};
    opacity: ${(props) => (props.openRequest ? '0' : '1')}; */
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }

  /* .transition-btn {
    text-transform: unset;
    background-color: ${COLOR.dark};
    font-weight: 600;
    &:hover {
      background-color: ${COLOR.light};
    }
    img {
      margin-left: 12px;
    }
  } */

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: block;
    }

    .mobile {
      display: none;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }
`;

export const StyledImportToolbar = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;

  .upload-button {
    text-transform: initial;
    padding: 4px 6px;
    border-radius: 8px;
    width: 124px;
  }

  .import-toolbar-more-button {
    padding: 4px 12px;
    border-radius: 12px;
    text-transform: none;
    height: 32px;
    width: 138px;
  }

  .margin-right-8 {
    margin-right: 8px;
  }

  .upload-button-disabled {
    min-width: 180px;
    text-transform: initial;
    cursor: default;
    color: ${COLOR.light};
    background: 'inherit';
    border: 1px solid ${COLOR.light};

    &:hover {
      background: inherit;
      border: 1px solid ${COLOR.light};
    }

    &:focus {
      background: inherit;
    }
  }

  @media only screen and (min-width: 1600px) {
    .web-toolbar {
      display: block;
    }

    .mobile-toolbar {
      display: none;
    }

    .paste {
      margin-left: 6px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .web-toolbar {
      display: none;
    }

    .mobile-toolbar {
      display: flex;
    }
  }
`;

export const StyledProcessingMethods = styled('div')`
  display: flex;
  align-items: center;

  .switch-wrapper {
    margin-left: 15px;
  }

  .icon {
    cursor: pointer;
  }

  .sentence-process {
    color: ${COLOR.darkBlue};
    font-weight: 510;
  }
`;

export const StyledHandleByUrl = styled('div')`
  .actions {
    padding: 20px 24px;
  }
`;

export const StyledMapEditor = {
  SPEED: {
    backgroundColor: COLOR.orange,
    color: COLOR.white,
    padding: '0 1px',
  },
  EMPHASIS: {
    backgroundColor: COLOR.lightGreen,
    color: COLOR.white,
    padding: '0 1px',
  },
  PITCH: {
    backgroundColor: COLOR.lightBlue,
    color: COLOR.white,
    padding: '0 1px',
  },
  BREAK_TIME: {
    backgroundColor: COLOR.purple,
    color: COLOR.white,
    padding: '0 1px',
  },
};

export const StyledHightLightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.white,
    color: COLOR.text,
    borderRadius: BORDER_RADIUS,
    fontSize: 14,
    fontWeight: 400,
    boxShadow: TOOLTIP_BOX_SHADOW,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.white,
  },
}));

export const StyledTooltipTitle = styled('div')`
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    font-size: 14px;
  }

  .icon-button {
    padding: 0 0 0 8px;
  }

  .icon {
    font-size: 14px;
  }
`;
