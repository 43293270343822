import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PAYMENT_STEP, PRICE_UNIT } from '@src/constants/payment';
import { delimitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import { ORDER_STATUS } from '@src/constants/order';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import { VBEE_TERMS } from '@src/configs';
import actions from '@src/redux/actions';

import classNames from 'classnames';
import { LANGUAGE } from '@src/constants';
import { PAYMENT_TOUR_GUIDE } from '@src/constants/tourGuide';
import { checkVietNam } from '@src/utils/checkCountry';
import { getPackageCurrentPrice } from '@src/services/package';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import ChangePhoneNumberDialog from './ChangePhoneNumberDialog';
import CommonBox from '../CommonBox';
import { StyledOrderBox } from './index.style';

const OrderBox = ({
  currPackage,
  isBankTransfer,
  activeStep,
  hasPaid,
  onAgreeToPay,
  onConfirmTransferOrder,
  onContinueToPay,
  onOpenConfirmCancelOrder,
  savedPrice,
  totalPrice,
}) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [openChangePhoneNumber, setOpenChangePhoneNumber] = useState(false);
  const [missingPhoneNumber, setMissingPhoneNumber] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = i18n;
  const { latestOrder } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  const { appliedVoucher } = useSelector((state) => state.voucher);

  const { getFeatureValue } = useFeatureFlags();
  const enableNewPaymentPackage = getFeatureValue(
    FEATURE_KEYS.NEW_PAYMENT_PACKAGES,
  );

  const isVietNam = checkVietNam();
  const moneyUnit = isVietNam ? PRICE_UNIT.VND : PRICE_UNIT.USD;
  const { price: originalPrice, usdPrice: originalUsdPrice } = currPackage;
  const { price, usdPrice } = getPackageCurrentPrice(
    currPackage,
    enableNewPaymentPackage,
  );

  const handleOpenChangePhoneNumber = () => setOpenChangePhoneNumber(true);
  const handleCloseChangePhoneNumber = () => setOpenChangePhoneNumber(false);

  const handleChangePhoneNumber = (value) => {
    setPhoneNumber(value);
    setMissingPhoneNumber(false);
  };

  const handleAgreeToPay = () => {
    if (!phoneNumber && isVietNam) {
      setMissingPhoneNumber(true);
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'pleaseUpdatePhoneNumber',
        }),
      );
      return;
    }
    onAgreeToPay(phoneNumber);
  };

  const showContinueToPay =
    activeStep === PAYMENT_STEP.PAYMENT &&
    latestOrder.paymentLink &&
    latestOrder.status === ORDER_STATUS.PENDING;

  const showConfirmButton =
    activeStep === PAYMENT_STEP.PAYMENT &&
    isBankTransfer &&
    !latestOrder.isConfirmedByCustomer;

  const showCancelPendingOrderButton =
    activeStep === PAYMENT_STEP.PENDING_PAYMENT &&
    isBankTransfer &&
    latestOrder.isConfirmedByCustomer &&
    latestOrder.status === ORDER_STATUS.PENDING;

  const getPromotion = () => {
    switch (appliedVoucher?.type) {
      case VOUCHER_TYPE.TIME:
        return (
          <b className="reduction" translate="no">
            + {appliedVoucher.value} &nbsp; {t('day')}
          </b>
        );
      case VOUCHER_TYPE.PERCENT_TIME:
        return (
          <b className="reduction" translate="no">
            + {Math.round((appliedVoucher.value * currPackage.expiresIn) / 100)}
            &nbsp; {t('day')}
          </b>
        );
      case VOUCHER_TYPE.CHARACTER:
        return (
          <b className="reduction">
            {delimitNumber(
              currPackage.maxCharacters * (appliedVoucher.value - 1),
              language,
            )}
            &nbsp;{t('characters')}
          </b>
        );
      default:
        return (
          <span translate="no">
            <b className="reduction">- {delimitNumber(savedPrice, language)}</b>
            {t(moneyUnit)}
          </span>
        );
    }
  };

  const renderPaymentButton = () => {
    if (hasPaid) return '';
    if (showContinueToPay)
      return (
        <Button fullWidth variant="contained" onClick={onContinueToPay}>
          {t('continueToPay')}
        </Button>
      );
    if (showConfirmButton)
      return (
        <Button fullWidth variant="contained" onClick={onConfirmTransferOrder}>
          {t('transferredConfirmation')}
        </Button>
      );
    if (showCancelPendingOrderButton)
      return (
        <Button fullWidth variant="outlined" onClick={onOpenConfirmCancelOrder}>
          {t('cancelOrder')}
        </Button>
      );
    if (activeStep === PAYMENT_STEP.PAYMENT_METHOD)
      return (
        <Button fullWidth variant="contained" onClick={handleAgreeToPay}>
          {t('agreeToPay')}
        </Button>
      );
    return '';
  };

  useEffect(() => {
    if (user?.phoneNumber) setPhoneNumber(user.phoneNumber);
  }, []);

  useEffect(() => {
    if (!user?.phoneNumber && latestOrder?.user?.phoneNumber)
      setPhoneNumber(latestOrder.user.phoneNumber);
  }, [latestOrder]);

  return (
    <CommonBox boldTitle title={t('yourOrder')}>
      <StyledOrderBox>
        <Box className="information">
          <div className="row">
            <Typography className="label">{t('fullName')}</Typography>
            <Typography className="value" variant="">
              <b>{`${user.lastName} ${user.firstName}`}</b>
            </Typography>
          </div>
          <div className={classNames('row', { error: missingPhoneNumber })}>
            <Typography className="label">{t('phoneNumber')}</Typography>
            <Typography className="value" variant="">
              <b>{phoneNumber || '--'}</b>
            </Typography>
          </div>
          <div
            className={classNames('row', {
              'change-phone-number': !missingPhoneNumber,
            })}
          >
            {missingPhoneNumber && (
              <Typography className="update-phone-number-text">
                {t('pleaseUpdatePhoneNumber')}
              </Typography>
            )}
            <Typography
              className="update-phone-number-button"
              onClick={handleOpenChangePhoneNumber}
            >
              {missingPhoneNumber ? t('update') : t('change')}
            </Typography>
          </div>
          <Divider />
          <div className="row">
            <Typography className="label">{t('package')}</Typography>
            <Typography className="value" variant="">
              <b>
                {currPackage.name &&
                  (currPackage.name[language] || currPackage.name[LANGUAGE.EN])}
              </b>{' '}
              - {delimitNumber(currPackage.maxCharacters, language)}{' '}
              {t('characters')}
            </Typography>
          </div>
          <div className="row">
            <Typography className="label">{t('expiry')}</Typography>
            <Typography className="value">
              <b>{currPackage.expiresIn}</b> {t('day')}
            </Typography>
          </div>
          <div className="row">
            <Typography className="label">{t('price')}</Typography>
            <Typography className="label value">
              <b>
                {delimitNumber(
                  isVietNam ? originalPrice : originalUsdPrice,
                  language,
                )}
              </b>
              {t(moneyUnit)}
            </Typography>
          </div>
          {price !== originalPrice && (
            <div className="row">
              <Typography className="label">{t('packageDiscount')}</Typography>
              <Typography className="label value">
                <b>
                  {'- '}
                  {delimitNumber(
                    isVietNam
                      ? originalPrice - price
                      : originalUsdPrice - usdPrice,
                    language,
                  )}
                </b>
                {t(moneyUnit)}
              </Typography>
            </div>
          )}
          <div className="row">
            <Typography className="label">{t('promotion')}</Typography>
            <Typography className="label value">{getPromotion()}</Typography>
          </div>
        </Box>
        <Box className="payment-sum">
          <Typography className="label">{t('paymentSum')}</Typography>
          <div className="sum">
            <Typography className="price">
              <b>{delimitNumber(totalPrice, language)}</b> {t(moneyUnit)}
            </Typography>
            <Typography className="vat">{t('includeVAT')}</Typography>
          </div>
        </Box>
        <div
          id={PAYMENT_TOUR_GUIDE.MAKE_PAYMENT.title}
          className="make-payment"
        >
          {renderPaymentButton()}
        </div>
        <Typography color="blue">
          <a
            className="text-link"
            href={VBEE_TERMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('policyAndTerms')}
          </a>
        </Typography>
      </StyledOrderBox>

      <ChangePhoneNumberDialog
        open={openChangePhoneNumber}
        name={`${user.lastName} ${user.firstName}`}
        currentPhoneNumber={phoneNumber}
        onClose={handleCloseChangePhoneNumber}
        onChangePhoneNumber={handleChangePhoneNumber}
      />
    </CommonBox>
  );
};
export default OrderBox;
