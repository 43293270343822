import { ERROR_SEPARATOR, ERROR_TYPES } from '@src/constants/dubbing';
import { readFileAsText } from './file';
import { removeHtmlTags, removeSpecialCharacters } from './string';
import { convertTimeDeltaToMillis, isValidTimeDelta } from './time';

const getInfoFromSRTBlock = (block = '') => {
  let [id, time, ...content] = block.split('\n');
  id = removeHtmlTags(id || '');
  time = removeHtmlTags(time || '');
  content = content?.map((c) => removeHtmlTags(c || ''))?.join('\n');
  return [id, time, content];
};

const generateErrorMessage = (errorType, blocks) => {
  switch (errorType) {
    case ERROR_TYPES.INVALID_SRT_FORMAT:
      return blocks
        .map((block) => {
          const [id, time, ...content] = getInfoFromSRTBlock(block);
          return `<div><span style="color: red">${id}</span><br/>${time}<br/>${content}</div>`;
        })
        .join(ERROR_SEPARATOR);
    case ERROR_TYPES.INVALID_TIME_DELTA_FORMAT:
    case ERROR_TYPES.START_TIME_GREATER_THAN_END_TIME:
      return blocks
        .map((block) => {
          const [id, time, ...content] = getInfoFromSRTBlock(block);
          return `<div>${id}<br/><span style="color: red">${time}</span><br/>${content}</div>`;
        })
        .join(ERROR_SEPARATOR);
    case ERROR_TYPES.AUDIO_TIMESTAMPS_NOT_IN_ORDER:
      return blocks
        .map((block) => {
          const [firstBlock, secondBlock] = block;
          const [id1, time1, ...content1] = getInfoFromSRTBlock(firstBlock);
          const [id2, time2, ...content2] = getInfoFromSRTBlock(secondBlock);
          return `<div>${id1}<br/><span style="color: red">${time1}</span><br/>${content1}<br/><br/>${id2}<br/><span style="color: red">${time2}</span><br/>${content2}</div>`;
        })
        .join(ERROR_SEPARATOR);
    default:
      return null;
  }
};

/**
 * parse SRT file
 * @param {File} file
 * @returns {Promise<Array>} parsed subtitles in format [{ id, start, end, content }]
 */
const parseSRTFile = async (file) => {
  const subtitleContent = await readFileAsText(file);
  if (!subtitleContent) return null;

  const srtText = subtitleContent.trim().replace(/\r/g, '');
  const subtitleBlocks = srtText.split(/\n\s*\n/);
  const parsedSubtitles = [];
  const srtErrors = {};

  for (let i = 0; i < subtitleBlocks.length; i += 1) {
    const block = subtitleBlocks[i];
    try {
      const [id, time, ...content] = block.split('\n');

      if (Number.isNaN(Number(id)) || !time || !content)
        throw new Error('invalidSrtFormat');

      const [start, end] = time
        .split('-->')
        .map((t) => removeSpecialCharacters(t).trim());
      const isValidTimeDeltas = [start, end].every(isValidTimeDelta);
      if (!isValidTimeDeltas) throw new Error('invalidTimeDeltaFormat');

      const startTime = convertTimeDeltaToMillis(start);
      const endTime = convertTimeDeltaToMillis(end);
      if (startTime > endTime) throw new Error('startTimeGreaterThanEndTime');

      const lastSubtitle = parsedSubtitles[parsedSubtitles.length - 1];
      if (lastSubtitle) {
        const lastStartTime = convertTimeDeltaToMillis(lastSubtitle.start);
        if (startTime < lastStartTime)
          throw new Error('audioTimestampsNotInOrder');
      }

      parsedSubtitles.push({
        id,
        start,
        end,
        content: removeHtmlTags(content.join(' ')),
      });
    } catch (error) {
      const errorType = error.message;

      if (!srtErrors[errorType]) srtErrors[errorType] = [];
      if (errorType === ERROR_TYPES.AUDIO_TIMESTAMPS_NOT_IN_ORDER)
        srtErrors[errorType].push([subtitleBlocks[i - 1], block]);
      else srtErrors[errorType].push(block);
    }
  }

  const hasErrors = Object.keys(srtErrors).length > 0;
  if (hasErrors) {
    const errorType = Object.keys(srtErrors)[0];
    return {
      error: true,
      message: errorType,
      detail: generateErrorMessage(errorType, srtErrors[errorType]),
      parsedSubtitles,
    };
  }

  return { error: false, parsedSubtitles };
};

const parseSrtFromSentences = (sentences = {}) => {
  const parsedSubtitles = Object.values(sentences).map((sentence, index) => {
    const { start, end, content } = sentence;
    return {
      id: index + 1,
      start,
      end,
      content,
    };
  });

  return parsedSubtitles;
};

const getSRTDuration = (parsedSubtitles = []) => {
  const duration = parsedSubtitles.reduce((acc, subtitle) => {
    const { start, end } = subtitle;
    const startTime = convertTimeDeltaToMillis(start);
    const endTime = convertTimeDeltaToMillis(end);

    return Math.max(startTime, endTime, acc);
  }, 0);

  return duration;
};

const countSubtitleCharacters = (sentences) => {
  const totalLength = Object.values(sentences).reduce(
    (accumulator, { content }) => accumulator + content.length,
    0,
  );

  return totalLength;
};

export {
  parseSRTFile,
  getSRTDuration,
  countSubtitleCharacters,
  parseSrtFromSentences,
};
