import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Popover, Button } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { PopoverUploadButton, PopoverUploadMenuItem } from './index.style';

const PopoverUploadNew = ({
  name,
  tooltipTitle,
  icon,
  options = [],
  width,
  disabled,
  onSelect,
  onClick,
  title,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    onClick();
  };
  const handleClose = () => setAnchorEl(null);

  const handleChange = (item) => {
    handleClose();
    if (onSelect) onSelect(name, item);
  };

  return (
    <PopoverUploadButton width={width} active={!!anchorEl} disabled={disabled}>
      <Tooltip arrow title={t(tooltipTitle)} placement="top">
        <Button className="custom-button" onClick={handleClick}>
          {typeof icon === 'string' ? (
            <img src={icon} alt={name} className="button-img" />
          ) : (
            icon
          )}
          <span className="title-button">{title}</span>
          <ArrowDropDownRoundedIcon width={24} height={24} />
        </Button>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '16px' }}>
          {options.map((item) => (
            <PopoverUploadMenuItem
              key={item.label}
              onClick={() => handleChange(item)}
            >
              {typeof item.icon === 'string' ? (
                <img src={item.icon} alt={name} className="button-img" />
              ) : (
                item.icon
              )}
              <div className="wrap-label">
                <Typography variant="body2" className="text">
                  {t(item.label)}
                </Typography>
                <Typography variant="body2" className="text">
                  {t(item.formatFile)}
                </Typography>
              </div>
            </PopoverUploadMenuItem>
          ))}
        </div>
      </Popover>
    </PopoverUploadButton>
  );
};
export default PopoverUploadNew;
