import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Code,
  ContactSupport,
  ExpandLess,
  ExpandMore,
  Launch,
  BookRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import classNames from 'classnames';

import {
  CUSTOMER_SUPPORT_PHONE_NUMBER,
  DOCUMENT_LINK,
  FAQ_LINK,
  VBEE_CONSOLE_URL,
  VBEE_MESSENGER_URL,
  VBEE_ZALO_URL,
} from '@src/configs';
import actions from '@src/redux/actions';
import { BANNER_TYPE } from '@src/constants/banner';
import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import ROUTES from '@src/constants/route';
import vbeeGrayIcon from '@src/assets/icons/vbee-icon-gray.svg';
import vbeeWhiteIcon from '@src/assets/icons/vbee-icon-white.svg';
import { getCollapseInitialState } from '@src/services/sidebar';
import { useTheme } from '@mui/styles';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckProductName } from '@src/hooks/useCheckProduct';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  checkVoucherBypProductForHeaderBanner,
  verifyTime,
} from '@src/services/voucher';
import apis from '@src/apis';
import { checkShowAutoRenewalPayment } from '@src/services/order';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import {
  useCheckDubbingRoute,
  useCheckTTSRoute,
} from '@src/hooks/useCheckDubbingRoute';
import { dubbingAppRoutes, ttsAppRoutes } from '@src/router/newAppRoutes';
import { MOBILE_BREAKPOINT } from '@src/constants';
import { checkUsingPrepaidMonthlyPkgOfStudio } from '@src/services/package';
import { checkVietNam } from '@src/utils/checkCountry';
import {
  StyledBetaWrapper,
  StyledDrawerNew,
  StyledSidebarNew,
  StyledSidebarTooltip,
  StyledSubMenu,
} from './index.style';
import Logo from './Logo';
import SmallSidebar from './SmallSidebar';
import LargeSidebar from './LargeSidebar';
import SidebarImgIcon from '../Icon';
import MobileSidebar from './MobileSidebar';
import DesktopSidebar from './DesktopSidebar';

const SUPPORT_MENU = [
  {
    label: 'contactZalo',
    href: VBEE_ZALO_URL,
    isActive: true,
  },
  {
    label: 'contactMessenger',
    href: VBEE_MESSENGER_URL,
    isActive: true,
  },
  {
    label: 'contactHotline',
    href: `tel:${CUSTOMER_SUPPORT_PHONE_NUMBER}`,
    isActive: true,
  },
];

const Sidebar = ({
  openSidebar,
  handleOpenSidebar,
  handleCloseSidebar,
  ipLocation,
  useHeaderBanner,
  headerVoucher,
}) => {
  const product = useCheckProductName();
  const sidebarBodyRef = useRef(null);
  const [collapseState, setCollapseState] = useState({});
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const { getFeatureValue } = useFeatureFlags();
  const showSidebarVoucher = getFeatureValue(FEATURE_KEYS.SIDEBAR_VOUCHER);
  const [vouchers, setVouchers] = useState([]);
  const [showAutoRenewalBanner, setShowAutoRenewalBanner] = useState(false);
  const isVietNam = checkVietNam();

  // let scrollTimeout = null;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { usingPackage, subscriptionPurchase, lastExpiredOrder } = useSelector(
    (state) => state.user,
  );
  const { lockCharacters } = useSelector((state) => state.auth.user);

  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const checkCharacterReservationPeriod = () => {
    let preservedCharacterDate;

    preservedCharacterDate = user.preservationExpiryDate;
    if (user.preservationExpiryDate && usePreservationExpiryDate)
      preservedCharacterDate = moment(user.preservationExpiryDate);
    else if (user.packageExpiryDate) {
      preservedCharacterDate = moment(user.packageExpiryDate).add(30, 'days');
    } else if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      preservedCharacterDate = moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days');
    }

    if (!preservedCharacterDate) return false;
    return moment().isBefore(moment(preservedCharacterDate));
  };

  const hasLockCharacters =
    useCheckTTSRoute() &&
    lockCharacters > 0 &&
    checkCharacterReservationPeriod();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  const useAutoRenewalPayment =
    useNewTtsUI &&
    getFeatureValue(FEATURE_KEYS.CONFIG_AUTO_RENEW_PREPAID_PACKAGE, {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    }) &&
    checkUsingPrepaidMonthlyPkgOfStudio(usingPackage);
  const useNewSupportUI = getFeatureValue(FEATURE_KEYS.NEW_SUPPORT_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const useNewMobileSidebar = getFeatureValue(FEATURE_KEYS.NEW_SIDEBAR_MOBILE, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const useNewDesktopSidebar = getFeatureValue(
    FEATURE_KEYS.NEW_SIDEBAR_DESKTOP,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const activeRoute = (routePath) =>
    matchPath(location.pathname, { path: routePath, exact: true });

  const separateAIVoiceProducts = getFeatureValue(
    FEATURE_KEYS.SEPARATE_AIVOICE_PRODUCTS,
    { userId: user.id, email: user.email },
  );
  const newDubbing = getFeatureValue(FEATURE_KEYS.NEW_DUBBING, {
    email: user.email,
    userId: user.id,
  });
  const isDubbingRoute = useCheckDubbingRoute();
  const appRoutes =
    isDubbingRoute && newDubbing ? dubbingAppRoutes : ttsAppRoutes;
  let sidebarRoute = appRoutes.filter((route) => route.sidebar);

  if (separateAIVoiceProducts && newDubbing)
    sidebarRoute = sidebarRoute.filter(
      (route) => route.path !== ROUTES.DUBBING,
    );
  if (useNewSupportUI) {
    sidebarRoute = [
      ...sidebarRoute,
      {
        name: 'userGuide',
        path: DOCUMENT_LINK,
        subPaths: [],
        component: null,
        icon: BookRounded,
        exact: true,
        restricted: false,
        isPrivate: true,
        sidebar: true,
        externalUrl: true,
      },
    ];
  }
  const isShowingApiRedirect = !separateAIVoiceProducts;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // width >= 900px
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600px <= width < 900px

  const drawerProperties = {
    sx: {
      display: {
        xs: 'none',
        sm: isMiddleScreen ? 'block' : 'none',
        md: isLargeScreen ? 'block' : 'none',
      },
    },
    variant: openSidebar && isMiddleScreen ? 'temporary' : 'persistent',
    anchor: 'left',
    onClose: isMiddleScreen ? handleCloseSidebar : null,
    ModalProps: isMiddleScreen
      ? {
          keepMounted: true,
        }
      : {},
  };

  const isExpiredPackage =
    !usingPackage.id ||
    (user.packageExpiryDate && moment().isAfter(user.packageExpiryDate));

  const handleOpenAutoRenewalPayment = () => {
    const isNotActiveSubPurchase = ![
      SUBSCRIPTION_PURCHASE_STATUS.PENDING,
      SUBSCRIPTION_PURCHASE_STATUS.ACTIVE,
    ].includes(subscriptionPurchase?.status);

    const autoRenewalBanner =
      !isDubbingRoute &&
      useAutoRenewalPayment &&
      isVietNam &&
      usingPackage.active &&
      isNotActiveSubPurchase &&
      !isExpiredPackage &&
      checkShowAutoRenewalPayment({
        packageCode: usingPackage?.code,
        alwayShow: true,
      });
    setShowAutoRenewalBanner(autoRenewalBanner);
  };

  const handlePlayVideoTutorial = () =>
    dispatch(
      actions.banner.updateDisplayBanner(BANNER_TYPE.SERVICE_MANUAL, true),
    );

  // const handleScroll = () => {
  //   setShowScrollbar(true);

  //   clearTimeout(scrollTimeout);

  //   scrollTimeout = setTimeout(() => {
  //     setShowScrollbar(false);
  //   }, 2000);
  // };

  // useEffect(() => () => clearTimeout(scrollTimeout), [showScrollbar]);

  const createLinks = (aRoutes, props) =>
    aRoutes.map((route) => {
      const hasEnabledRoute = route.featureKey
        ? getFeatureValue(route.featureKey, {
            userId: user?.id,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          })
        : true;
      if (!hasEnabledRoute) return null;

      const isActiveRoute =
        activeRoute(route.path) ||
        route.subPaths?.some((path) => activeRoute(path));

      if (route.collapse) {
        const collapse = {};
        collapse[route.name] = !collapseState[route.name];

        return (
          <ListItem
            key={route.name}
            className="item"
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
          >
            <NavLink
              to="#"
              className={classNames('item-link', {
                'collapse-active': getCollapseInitialState(route.items),
              })}
              onClick={(e) => {
                e.preventDefault();
                setCollapseState(collapse);
              }}
            >
              {openSidebar ? (
                <>
                  <route.icon
                    className={classNames('item-icon', {
                      'item-icon-active': isActiveRoute,
                    })}
                  />
                  <ListItemText
                    primary={t(route.name)}
                    secondary={
                      collapseState[route.name] ? (
                        <ArrowDropUp className="caret" />
                      ) : (
                        <ArrowDropDown className="caret" />
                      )
                    }
                    disableTypography
                    className={classNames('item-text', {
                      'item-text-active': isActiveRoute,
                    })}
                  />
                </>
              ) : (
                <StyledSidebarTooltip title={t(route.name)}>
                  <route.icon className="item-icon" />
                </StyledSidebarTooltip>
              )}
            </NavLink>
            <Collapse in={collapseState[route.state]} unmountOnExit>
              <List className="list collapse-list">
                {createLinks(route.items)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      if (route?.externalUrl) {
        return (
          <ListItem
            key={route.name}
            className="item"
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
          >
            <a
              href={route.path}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames('item-link', {
                'active-link': isActiveRoute,
              })}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              {openSidebar ? (
                <>
                  <route.icon
                    className={classNames('item-icon', {
                      'item-icon-active': isActiveRoute,
                    })}
                  />
                  <div className="item-wrapper">
                    <ListItemText
                      primary={t(route.name)}
                      disableTypography
                      className={classNames('item-text', {
                        'item-text-active': isActiveRoute,
                      })}
                    />
                    {route.beta && (
                      <StyledBetaWrapper active={isActiveRoute}>
                        <div className="beta-background" />
                        <Typography className="beta-text">Beta</Typography>
                      </StyledBetaWrapper>
                    )}
                    {route.isNewFeature && (
                      <StyledBetaWrapper active={isActiveRoute}>
                        <div className="beta-background" />
                        <Typography className="beta-text">New</Typography>
                      </StyledBetaWrapper>
                    )}
                  </div>
                </>
              ) : (
                <StyledSidebarTooltip title={t(route.name)}>
                  <route.icon className="item-icon" />
                </StyledSidebarTooltip>
              )}
            </a>
          </ListItem>
        );
      }

      return (
        <ListItem
          key={route.name}
          className="item"
          onMouseEnter={() => setShowScrollbar(true)}
          onMouseLeave={() => setShowScrollbar(false)}
        >
          <NavLink
            to={route.path}
            className={classNames('item-link', {
              'active-link': isActiveRoute,
            })}
            onClick={() => {
              if (props?.mobile) handleCloseSidebar();
            }}
          >
            {openSidebar ? (
              <>
                <route.icon
                  className={classNames('item-icon', {
                    'item-icon-active': isActiveRoute,
                  })}
                />
                <div className="item-wrapper">
                  <ListItemText
                    primary={t(route.name)}
                    disableTypography
                    className={classNames('item-text', {
                      'item-text-active': isActiveRoute,
                    })}
                  />
                  {route.beta && (
                    <StyledBetaWrapper active={isActiveRoute}>
                      <div className="beta-background" />
                      <Typography className="beta-text">Beta</Typography>
                    </StyledBetaWrapper>
                  )}
                  {route.isNewFeature && (
                    <StyledBetaWrapper active={isActiveRoute}>
                      <Typography
                        className="new-text"
                        sx={{ textTransform: 'none !important' }}
                      >
                        {t('new')}
                      </Typography>
                    </StyledBetaWrapper>
                  )}
                </div>
              </>
            ) : (
              <StyledSidebarTooltip title={t(route.name)}>
                <route.icon className="item-icon" />
              </StyledSidebarTooltip>
            )}
          </NavLink>
        </ListItem>
      );
    });

  // Add user guide to the first position of support menu for new mobile sidebar
  if (useNewMobileSidebar && SUPPORT_MENU.length === 3) {
    SUPPORT_MENU.unshift({
      label: 'userGuide',
      href: DOCUMENT_LINK,
      isActive: true,
    });
  }

  const renderSubMenuSupport = ({ props, className, itemClassName }) => (
    <StyledSubMenu className={className}>
      <List component="div" disablePadding>
        {useNewSupportUI ? (
          SUPPORT_MENU.filter((item) => item.isActive).map((item) => (
            <a
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className={`item-link ${itemClassName}`}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              <Typography className="item-text">{t(item.label)}</Typography>
            </a>
          ))
        ) : (
          <>
            <a
              href={DOCUMENT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={`item-link ${itemClassName}`}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              <Typography className="item-text">{t('userGuide')}</Typography>
            </a>
            <div
              className={`item-link ${itemClassName}`}
              role="button"
              tabIndex="0"
              onClick={handlePlayVideoTutorial}
            >
              <Typography className="item-text">
                {t('videoTutorial')}
              </Typography>
            </div>
            <a
              href={FAQ_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={`item-link ${itemClassName}`}
              onClick={() => {
                if (props?.mobile) handleCloseSidebar();
              }}
            >
              <Typography className="item-text">{t('faq')}</Typography>
            </a>
          </>
        )}
      </List>
    </StyledSubMenu>
  );

  useEffect(() => {
    if (openSubMenu) {
      // Scroll to bottom when open sub menu on small screen
      // Delay to scroll to bottom when open sub menu on small screen to wait for the sub menu to be rendered
      setTimeout(() => {
        const sidebarBody = sidebarBodyRef.current;
        sidebarBody.lastElementChild.scrollIntoView({
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [openSubMenu]);

  const renderSideBarItems = (props) => (
    <div
      ref={sidebarBodyRef}
      onMouseEnter={() => setShowScrollbar(true)}
      onMouseLeave={() => setShowScrollbar(false)}
      className={`sidebar-wrapper ${
        showScrollbar ? 'small-scrollbar' : 'hidden-scrollbar'
      } `}
      // onScroll={handleScroll}
    >
      <List className="list">{createLinks(sidebarRoute, props)}</List>
      <Divider />
      <Box marginY={1}>
        <Box
          className="item-link"
          onClick={() => setOpenSubMenu((prev) => !prev)}
        >
          <ContactSupport className="item-icon" />
          <Box className="item-wrapper">
            <Typography className="item-text">{t('support')}</Typography>
            {openSubMenu ? (
              <ExpandLess className="item-icon" />
            ) : (
              <ExpandMore className="item-icon" />
            )}
          </Box>
        </Box>
      </Box>

      <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
        {renderSubMenuSupport({ props, itemClassName: 'sub-menu-item' })}
      </Collapse>
      <Feature name={FEATURE_FLAG.OTHER_SERVICES}>
        <NavLink
          to={ROUTES.OTHER_SERVICES}
          className={classNames('item-link', {
            'active-link': activeRoute(ROUTES.OTHER_SERVICES),
          })}
          onClick={() => {
            if (props?.mobile) handleCloseSidebar();
          }}
        >
          <SidebarImgIcon
            src={
              activeRoute(ROUTES.OTHER_SERVICES) ? vbeeWhiteIcon : vbeeGrayIcon
            }
            name={t('otherServices')}
          />
        </NavLink>
      </Feature>
      <Divider />
      <a
        href={VBEE_CONSOLE_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="item-link"
        onClick={() => {
          if (props?.mobile) handleCloseSidebar();
        }}
      >
        <Code className="item-icon" />
        <div className="item-wrapper">
          <Typography className="item-text">{t('api')}</Typography>
          <Launch className="item-icon" />
        </div>
      </a>
    </div>
  );

  const getSatisfiedVouchers = (voucherData) => {
    const newVouchers = voucherData.reduce((acc, currVoucher) => {
      const satisfiedTime = verifyTime(currVoucher);
      if (!satisfiedTime) return acc;

      const canUse =
        satisfiedTime &&
        currVoucher?.remainingAmount > 0 &&
        !currVoucher.isPrivate &&
        currVoucher.usedAmount < currVoucher.numReuse;
      return [...acc, { ...currVoucher, satisfiedTime, canUse }];
    }, []);

    const sortVouchers = newVouchers.sort((voucher) =>
      voucher.canUse ? -1 : 1,
    );

    setVouchers(sortVouchers);
    // this below code is the logic to check dispatch headerVoucher or not
    // if feature flag flash-sale is off => always dispatch undefined
    if (useVoucherByProduct && sortVouchers.length > 0) {
      const canUseVoucher = sortVouchers.filter(
        (voucher) =>
          voucher.canUse &&
          checkVoucherBypProductForHeaderBanner({ voucher, product }),
      );
      const checkVoucherForLockCharacterInListVouchers = canUseVoucher.find(
        (voucher) => voucher.isPreserveVoucher,
      );
      if (hasLockCharacters && !checkVoucherForLockCharacterInListVouchers) {
        canUseVoucher.push({
          canUse: true,
          isPreserveVoucher: true,
        });
      }
      const checkMobile = window.innerWidth <= MOBILE_BREAKPOINT;
      let finalHeaderVoucher = [];
      if (!checkMobile) {
        finalHeaderVoucher = canUseVoucher.filter(
          (item) => item.isHeaderBanner,
        );
      } else {
        finalHeaderVoucher = [...canUseVoucher];
      }
      if (finalHeaderVoucher.length > 0 && useHeaderBanner) {
        dispatch(actions.voucher.updateHeaderVoucher(finalHeaderVoucher));
      } else {
        dispatch(actions.voucher.updateHeaderVoucher(undefined));
      }
    } else if (!useVoucherByProduct && sortVouchers.length > 0) {
      const flashSaleVoucher = sortVouchers.filter(
        (voucher) => voucher.isHeaderBanner && voucher.canUse,
      );
      if (flashSaleVoucher && flashSaleVoucher.length > 0 && useHeaderBanner) {
        dispatch(actions.voucher.updateHeaderVoucher(flashSaleVoucher[0]));
      } else {
        dispatch(actions.voucher.updateHeaderVoucher(undefined));
      }
    }
  };

  const fetchVouchers = async () => {
    const data = await apis.vouchers.getVouchers();
    if (data.status) getSatisfiedVouchers(data.result);
  };

  useEffect(() => {
    handleOpenAutoRenewalPayment();
  }, [usingPackage, subscriptionPurchase]);

  useEffect(() => {
    if (showSidebarVoucher) fetchVouchers();
  }, []);

  const renderSidebar = () => {
    if (openSidebar) {
      return (
        <LargeSidebar
          useHeaderBanner={useHeaderBanner}
          headerVoucher={headerVoucher}
          createLinks={createLinks}
          sidebarRoute={sidebarRoute}
          activeRoute={activeRoute}
          ipLocation={ipLocation}
          isExpiredPackage={isExpiredPackage}
          handleCloseSidebar={handleCloseSidebar}
          renderSubMenuSupport={renderSubMenuSupport}
          vouchers={vouchers}
          showAutoRenewalBanner={showAutoRenewalBanner}
          isShowingApiRedirect={isShowingApiRedirect}
        />
      );
    }

    return (
      <SmallSidebar
        useHeaderBanner={useHeaderBanner}
        headerVoucher={headerVoucher}
        createLinks={createLinks}
        sidebarRoute={sidebarRoute}
        activeRoute={activeRoute}
        ipLocation={ipLocation}
        isExpiredPackage={isExpiredPackage}
        handleCloseSidebar={handleCloseSidebar}
        handleOpenSidebar={handleOpenSidebar}
        renderSubMenuSupport={renderSubMenuSupport}
        vouchers={vouchers}
        showAutoRenewalBanner={showAutoRenewalBanner}
        isShowingApiRedirect={isShowingApiRedirect}
      />
    );
  };

  return (
    <>
      {useNewDesktopSidebar ? (
        <DesktopSidebar
          openSidebar={openSidebar}
          handleOpenSidebar={handleOpenSidebar}
          handleCloseSidebar={handleCloseSidebar}
          ipLocation={ipLocation}
          useHeaderBanner={useHeaderBanner}
          headerVoucher={headerVoucher}
        />
      ) : (
        <StyledDrawerNew
          sx={drawerProperties.sx}
          variant={drawerProperties.variant}
          anchor={drawerProperties.anchor}
          open
          opensidebar={openSidebar}
          onClose={drawerProperties.onClose}
          ModalProps={drawerProperties.ModalProps}
        >
          {renderSidebar()}
        </StyledDrawerNew>
      )}
      {useNewMobileSidebar ? (
        <MobileSidebar
          openSidebar={openSidebar}
          handleCloseSidebar={handleCloseSidebar}
          useHeaderBanner={useHeaderBanner}
          headerVoucher={headerVoucher}
          renderSideBarItems={renderSideBarItems}
          renderSubMenuSupport={renderSubMenuSupport}
          createLinks={createLinks}
        />
      ) : (
        <StyledDrawerNew
          sx={{ display: { md: 'none', xs: 'block', sm: 'none' } }}
          variant="temporary"
          anchor="left"
          open={openSidebar}
          onClose={handleCloseSidebar}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledSidebarNew
            useHeaderBanner={useHeaderBanner}
            headerVoucher={headerVoucher}
          >
            <div className="mobile package-wrapper-mobile">
              <Logo isMobile />
              <IconButton
                aria-label="close"
                onClick={handleCloseSidebar}
                color="secondary"
                variant="outlined"
                className="close-button"
              >
                <Close color="secondary" fontSize="small" />
              </IconButton>
            </div>
            <Divider />
            {renderSideBarItems({ mobile: true })}
          </StyledSidebarNew>
        </StyledDrawerNew>
      )}
    </>
  );
};

export default Sidebar;
