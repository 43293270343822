import { RESOURCE } from '@src/constants';
import api from './api';

const registerAffiliateMArketer = async () => {
  const response = await api({
    method: 'POST',
    url: RESOURCE.AFFILIATE_MARKETER,
  });
  return response;
};

const getAffiliateMarketer = async () => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.AFFILIATE_MARKETER,
  });
  return response;
};

const getAffiliateMarketerStats = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.AFFILIATE_MARKETER}/stats`,
  });
  return response;
};

const editAffiliateMarketerCode = async (code) => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.AFFILIATE_MARKETER}/code`,
    data: { code },
  });
  return response;
};

const getAffiliateCommission = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.AFFILIATE_MARKETER}/commission`,
  });
  return response;
};

const getAffiliateOrders = async ({
  packageLevel,
  startDate,
  endDate,
  query,
  offset,
  limit,
  fields,
  sort,
}) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.ORDERS}/affiliate`,
    params: {
      packageLevel,
      startDate,
      endDate,
      query,
      offset,
      limit,
      fields,
      sort,
    },
  });
  return response;
};

const getAffiliateCustomers = async ({
  search,
  searchFields,
  dateField,
  offset,
  limit,
  fields,
  sort,
}) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.AFFILIATE_MARKETER}/customers`,
    params: {
      search,
      searchFields,
      dateField,
      offset,
      limit,
      fields,
      sort,
    },
  });
  return response;
};

const updateAffiliateMarketer = async ({ affiliateCode, affiliateTime }) => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.USERS}/update-affiliate-marketer`,
    data: { affiliateCode, affiliateTime },
  });
  return response;
};

export {
  registerAffiliateMArketer,
  getAffiliateMarketer,
  getAffiliateMarketerStats,
  editAffiliateMarketerCode,
  getAffiliateCommission,
  getAffiliateOrders,
  getAffiliateCustomers,
  updateAffiliateMarketer,
};
