import React from 'react';
import { useSelector } from 'react-redux';

import ConfigAutoRenewContainer from '@src/containers/ConfigAutoRenew';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { checkVietNam } from '@src/utils/checkCountry';
import { checkUsingPrepaidMonthlyPkgOfStudio } from '@src/services/package';
import PageNotFound from './PageNotFound';

const ConfigAutoRenew = () => {
  const user = useSelector((state) => state.auth.user);
  const { usingPackage } = useSelector((state) => state.user);

  const { getFeatureValue } = useFeatureFlags();
  const isVietNam = checkVietNam();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });

  const isRegisterAutoRenewal =
    getFeatureValue(FEATURE_KEYS.CONFIG_AUTO_RENEW_PREPAID_PACKAGE, {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    }) && checkUsingPrepaidMonthlyPkgOfStudio(usingPackage);

  const isPaymentAutoRenewal = getFeatureValue(
    FEATURE_KEYS.AUTO_RENEWAL_PAYMENT,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const useAutoRenewalPayment =
    useNewTtsUI &&
    (isPaymentAutoRenewal || (isRegisterAutoRenewal && isVietNam));

  return useAutoRenewalPayment ? (
    <ConfigAutoRenewContainer />
  ) : (
    <PageNotFound />
  );
};

export default ConfigAutoRenew;
