const DEVICE_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  RESTRICTED: 'restricted',
};

const TIME_STORE_FINGERPRINT = 1000 * 60 * 60 * 24 * 365 * 10; // 10 years

const TIME_STORE_USER_GUIDE_DUBBING = 1000 * 60 * 60 * 24 * 365 * 10; // 10 years

const TIME_STORE_TRY_LISTEN_NOTE = 1000 * 60 * 60 * 24 * 365 * 10; // 10 years

const TIME_STORE_LANGUAGE = 1000 * 60 * 60 * 24 * 365 * 10; // 10 years

export {
  DEVICE_STATUS,
  TIME_STORE_FINGERPRINT,
  TIME_STORE_USER_GUIDE_DUBBING,
  TIME_STORE_TRY_LISTEN_NOTE,
  TIME_STORE_LANGUAGE,
};
