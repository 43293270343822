export const actionTypes = {
  GET_USING_PACKAGE: 'GET_USING_PACKAGE',
  GET_USING_PACKAGE_SUCCESS: 'GET_USING_PACKAGE_SUCCESS',
  GET_USING_DUBBING_PACKAGE_SUCCESS: 'GET_USING_DUBBING_PACKAGE_SUCCESS',
  GET_USING_PACKAGE_FAILURE: 'GET_USING_PACKAGE_FAILURE',
  GET_TTS_USER: 'GET_TTS_USER',
  GET_TTS_USER_SUCCESS: 'GET_TTS_USER_SUCCESS',
  GET_TTS_USER_FAILURE: 'GET_TTS_USER_FAILURE',
  GET_LATEST_ORDER: 'GET_LATEST_ORDER',
  GET_LATEST_ORDER_SUCCESS: 'GET_LATEST_ORDER_SUCCESS',
  GET_LATEST_ORDER_FAILURE: 'GET_LATEST_ORDER_FAILURE',
  GET_LATEST_DUBBING_ORDER: 'GET_LATEST_DUBBING_ORDER',
  GET_LATEST_DUBBING_ORDER_SUCCESS: 'GET_LATEST_DUBBING_ORDER_SUCCESS',
  GET_LATEST_DUBBING_ORDER_FAILURE: 'GET_LATEST_DUBBING_ORDER_FAILURE',
  GET_LAST_EXPIRED_ORDER: 'GET_LAST_EXPIRED_ORDER',
  GET_LAST_EXPIRED_ORDER_SUCCESS: 'GET_LAST_EXPIRED_ORDER_SUCCESS',
  GET_LAST_EXPIRED_ORDER_FAILURE: 'GET_LAST_EXPIRED_ORDER_FAILURE',
  BACK_TO_TTS: 'BACK_TO_TTS',
  RESET_BACK_TO_TTS: 'RESET_BACK_TO_TTS',
  BACK_TO_DUBBING: 'BACK_TO_DUBBING',
  RESET_BACK_TO_DUBBING: 'RESET_BACK_TO_DUBBING',
  CLOSE_QR_PAYMENT: 'CLOSE_QR_PAYMENT',
  RESET_CLOSE_QR_PAYMENT: 'RESET_CLOSE_QR_PAYMENT',
  RUNNING_TOUR_GUIDE: 'RUNNING_TOUR_GUIDE',
  FETCH_PAYMENT_HISTORY: 'FETCH_PAYMENT_HISTORY',
  UPDATE_SUBSCRIPTION_PURCHASE: 'UPDATE_SUBSCRIPTION_PURCHASE',
  UPDATE_SHOW_BLOCK_DOWNLOAD_DIALOG: 'UPDATE_SHOW_BLOCK_DOWNLOAD_DIALOG',
  UPDATE_BONUS_CHARACTERS: 'UPDATE_BONUS_CHARACTERS',
};

const getUsingPackage = (userId) => ({
  type: actionTypes.GET_USING_PACKAGE,
  userId,
});

const getUsingPackageSuccess = (usingPackage) => ({
  type: actionTypes.GET_USING_PACKAGE_SUCCESS,
  usingPackage,
});

const getUsingDubbingPackageSuccess = (usingDubbingPackage) => ({
  type: actionTypes.GET_USING_DUBBING_PACKAGE_SUCCESS,
  usingDubbingPackage,
});

const getUsingPackageFailure = () => ({
  type: actionTypes.GET_USING_PACKAGE_FAILURE,
});

const getTtsUser = () => ({
  type: actionTypes.GET_TTS_USER,
});

const getTtsUserSuccess = (ttsUser) => ({
  type: actionTypes.GET_TTS_USER_SUCCESS,
  ttsUser,
});

const getTtsUserFailure = () => ({
  type: actionTypes.GET_TTS_USER_FAILURE,
});

const getLatestOrder = (userId) => ({
  type: actionTypes.GET_LATEST_ORDER,
  userId,
});

const getLatestOrderSuccess = (latestOrder) => ({
  type: actionTypes.GET_LATEST_ORDER_SUCCESS,
  latestOrder,
});

const getLatestDubbingOrder = (userId) => ({
  type: actionTypes.GET_LATEST_DUBBING_ORDER,
  userId,
});

const getLatestDubbingOrderSuccess = (latestDubbingOrder) => ({
  type: actionTypes.GET_LATEST_DUBBING_ORDER_SUCCESS,
  latestDubbingOrder,
});

const getLatestDubbingOrderFailure = () => ({
  type: actionTypes.GET_LATEST_DUBBING_ORDER_FAILURE,
});

const getLastExpiredOrder = () => ({
  type: actionTypes.GET_LAST_EXPIRED_ORDER,
});

const getLastExpiredOrderSuccess = (lastExpiredOrder) => ({
  type: actionTypes.GET_LAST_EXPIRED_ORDER_SUCCESS,
  lastExpiredOrder,
});

const backToTTS = () => ({
  type: actionTypes.BACK_TO_TTS,
});
const resetBackToTTS = () => ({
  type: actionTypes.RESET_BACK_TO_TTS,
});

const backToDubbing = () => ({
  type: actionTypes.BACK_TO_DUBBING,
});
const resetBackToDubbing = () => ({
  type: actionTypes.RESET_BACK_TO_DUBBING,
});

const isRunningTourGuide = (status) => ({
  type: actionTypes.RUNNING_TOUR_GUIDE,
  status,
});

const closeQRPayment = () => ({
  type: actionTypes.CLOSE_QR_PAYMENT,
});

const resetCloseQRPayment = () => ({
  type: actionTypes.RESET_CLOSE_QR_PAYMENT,
});

const fetchPaymentHistory = (status) => ({
  type: actionTypes.FETCH_PAYMENT_HISTORY,
  status,
});

const updateSubscriptionPurchase = (subscriptionPurchase) => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_PURCHASE,
  subscriptionPurchase,
});

const updateShowBlockDownloadDialog = (showBlockDownloadDialog) => ({
  type: actionTypes.UPDATE_SHOW_BLOCK_DOWNLOAD_DIALOG,
  showBlockDownloadDialog,
});

const updateBonusCharacters = (bonusCharacters) => ({
  type: actionTypes.UPDATE_BONUS_CHARACTERS,
  bonusCharacters,
});

export {
  getUsingPackage,
  getUsingPackageSuccess,
  getUsingDubbingPackageSuccess,
  getUsingPackageFailure,
  getTtsUser,
  getTtsUserSuccess,
  getTtsUserFailure,
  getLatestOrder,
  getLatestOrderSuccess,
  getLastExpiredOrder,
  getLastExpiredOrderSuccess,
  backToTTS,
  resetBackToTTS,
  backToDubbing,
  resetBackToDubbing,
  isRunningTourGuide,
  closeQRPayment,
  resetCloseQRPayment,
  fetchPaymentHistory,
  updateSubscriptionPurchase,
  getLatestDubbingOrder,
  getLatestDubbingOrderSuccess,
  getLatestDubbingOrderFailure,
  updateShowBlockDownloadDialog,
  updateBonusCharacters,
};
