import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledScale = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.white};

  .scale {
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR.darkV2[60]};
  }
`;
