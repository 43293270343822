import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import { REGEX_YOUTUBE_LINK } from '@src/constants/dubbing';
import { getYoutubeVideoInfo } from '@src/services/youtube';
import { YouTube } from '@mui/icons-material';
import { StyledYoutubeContent } from './index.style';

const YoutubeContent = ({ handleWithYoutubeLink }) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');

  const validateYoutubeLink = (link) => REGEX_YOUTUBE_LINK.test(link);

  const loadYoutubeVideoInformation = async (videoLink) => {
    if (!videoLink) {
      setErrorMessage('');
      return;
    }

    const isValid = validateYoutubeLink(videoLink);

    if (!isValid) {
      setErrorMessage(t('urlNotValidPleaseCheckAgain'));
      return;
    }

    const { isVideoNotFound, data } = await getYoutubeVideoInfo(videoLink);

    if (isVideoNotFound) {
      setErrorMessage(t('videoNotFound'));
      return;
    }

    setErrorMessage('');
    handleWithYoutubeLink(data, videoLink);
  };

  const handleChangeYoutubeLink = (e) => {
    setIsLoading(true);
    const { value } = e.target;
    setYoutubeLink(value);
    loadYoutubeVideoInformation(value);
    setIsLoading(false);
  };

  return (
    <StyledYoutubeContent error={errorMessage.length}>
      <Box
        className="youtube-input"
        onClick={() => {
          inputRef.current.focus();
        }}
      >
        <YouTube className="youtube-icon " />
        <input
          type="text"
          ref={inputRef}
          placeholder={t('enterYoutubeLink')}
          value={youtubeLink}
          onChange={(e) => handleChangeYoutubeLink(e)}
          className="youtube-text"
        />
        {isLoading && <CircularProgress className="loading-icon" />}
      </Box>
      {errorMessage.length > 0 && (
        <Typography className="error-message">{errorMessage}</Typography>
      )}
    </StyledYoutubeContent>
  );
};

export default YoutubeContent;
