import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box } from '@mui/material';
import BadgeAvatar from '@src/components/BadgeAvatar';
import {
  StyledStackedVoiceAvatars,
  StyledVoiceAvatarMore,
} from './index.style';

const MAX_DISPLAY_AVATAR = 3;

const StackedVoiceAvatars = ({ voices }) => {
  const { languages } = useSelector((state) => state.language);

  const VoiceAvatar = ({ voice }) => {
    const languageImg = languages.find(
      (lang) => lang?.value === voice?.languageCode,
    );

    return (
      <Box sx={{ zIndex: 10 }}>
        <BadgeAvatar
          width={30}
          img={voice?.roundImage}
          type="image"
          smallImgWidth={10}
          smallImg={languageImg?.roundImage}
        />
      </Box>
    );
  };

  const VoiceAvatarMore = ({ voice }) => (
    <StyledVoiceAvatarMore>
      <Box className="more-voice">
        <Box className="more-voice-text">
          +{voices?.length - MAX_DISPLAY_AVATAR}
        </Box>
      </Box>
      <Avatar src={voice?.roundImage} className="avatar" />
    </StyledVoiceAvatarMore>
  );

  const numberVoiceDisplay =
    voices?.length > MAX_DISPLAY_AVATAR
      ? MAX_DISPLAY_AVATAR - 1
      : MAX_DISPLAY_AVATAR;

  return (
    <StyledStackedVoiceAvatars>
      {voices?.slice(0, numberVoiceDisplay).map((voice) => (
        <VoiceAvatar key={voice?.code} voice={voice} />
      ))}
      {voices?.length > MAX_DISPLAY_AVATAR && (
        <VoiceAvatarMore key="more" voice={voices[MAX_DISPLAY_AVATAR]} />
      )}
    </StyledStackedVoiceAvatars>
  );
};

export default StackedVoiceAvatars;
