/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import useCustomKeycloak from '@src/hooks/useCustomKeycloak';
import { getCookie, setCookie } from '@src/utils/cookie';
import {
  CUSTOMER_SUPPORT_PHONE_NUMBER,
  LANDING_PAGE_URL,
  SUPPORT_CENTER_URL,
  VBEE_DOMAIN,
  VBEE_HOTLINE,
  VBEE_TERMS,
} from '@src/configs';
import apis from '@src/apis';
import i18n from '@src/languages';
import { TIME_STORE_LANGUAGE } from '@src/constants/device';
import { COUNTRY, LANGUAGE } from '@src/constants';
import ROUTES from '@src/constants/route';
import { checkVietNam } from '@src/utils/checkCountry';
import {
  Book,
  CallOutlined,
  ContactSupport,
  Devices,
  KeyboardArrowRight,
  Language,
  LogoutOutlined,
  MenuBook,
  RadioButtonChecked,
  RadioButtonUnchecked,
  CreditCardRounded,
  CardMembershipRounded,
} from '@mui/icons-material';
import WorkHistory from '@src/assets/icons/work_history.svg';
import { Box, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { COLOR } from '@src/styles/color';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { checkShowAutoRenewalPayment } from '@src/services/order';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { checkUsingPrepaidMonthlyPkgOfStudio } from '@src/services/package';
import {
  StyledUserActions,
  StyledUserSubActions,
  StyledPopper,
} from './index.style';

const languages = [
  {
    value: LANGUAGE.VN,
    country: COUNTRY.VN,
    label: 'vietnamese',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
  },
  {
    value: LANGUAGE.EN,
    country: COUNTRY.EN,
    label: 'english',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
  },
  // {
  //   value: LANGUAGE.PH,
  //   country: COUNTRY.PH,
  //   label: 'filipino',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/Philippines.png',
  // },
  // {
  //   value: LANGUAGE.TH,
  //   country: COUNTRY.TH,
  //   label: 'thailand',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/Thailand.png',
  // },
  // {
  //   value: LANGUAGE.CN,
  //   country: COUNTRY.CN,
  //   label: 'chinese',
  //   icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/China.png',
  // },
];

const PopperHelp = ({ openHelpAnchor, setOpenHelpAnchor }) => {
  const { t } = useTranslation();
  return (
    <StyledPopper
      open={Boolean(openHelpAnchor)}
      placement="right-start"
      anchorEl={openHelpAnchor}
      onClose={() => setOpenHelpAnchor(null)}
      style={{ zIndex: 20000 }}
      modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]}
    >
      <StyledUserSubActions>
        <a
          href={SUPPORT_CENTER_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="text-link"
        >
          <MenuItem className="item">
            <ContactSupport style={{ opacity: 0.8 }} />
            {t('supportCenter')}
          </MenuItem>
        </a>
        <a className="text-link" href={`tel:${CUSTOMER_SUPPORT_PHONE_NUMBER}`}>
          <MenuItem className="item">
            <CallOutlined style={{ opacity: 0.8 }} />
            <Typography>
              {t('customerSupport')}: {CUSTOMER_SUPPORT_PHONE_NUMBER}
            </Typography>
          </MenuItem>
        </a>
        <a className="text-link" href={`tel:${VBEE_HOTLINE}`}>
          <MenuItem className="item">
            <CallOutlined style={{ opacity: 0.8 }} />
            <Typography>
              {t('hotline')}: {VBEE_HOTLINE}
            </Typography>
          </MenuItem>
        </a>
      </StyledUserSubActions>
    </StyledPopper>
  );
};

const PopperLanguage = ({
  openLanguageAnchor,
  setOpenLanguageAnchor,
  language,
  setLanguage,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
    setLanguage(lng);
  };

  const handleClickChangeLanguage = (lng) => {
    handleChangeLanguage(lng);
    setCookie({
      cname: 'lng',
      cvalue: lng.value,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_LANGUAGE,
    });
    if (onClose) onClose();
  };

  return (
    <StyledPopper
      open={Boolean(openLanguageAnchor)}
      placement="right-start"
      anchorEl={openLanguageAnchor}
      onClose={() => setOpenLanguageAnchor(null)}
      style={{ zIndex: 20000 }}
      modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]}
    >
      <StyledUserSubActions>
        {languages.map((lng) => (
          <MenuItem
            className={`item justify-content-between mb-4 ${
              lng.value === language.value ? '' : 'unselected-language'
            }`}
            onClick={() => handleClickChangeLanguage(lng)}
          >
            <Box display="flex" gap="12px" alignItems="center">
              {lng.value === language.value ? (
                <RadioButtonChecked style={{ opacity: 0.8 }} />
              ) : (
                <RadioButtonUnchecked color={COLOR.label[80]} />
              )}
              {t(lng.label)}
            </Box>
            <img src={lng.icon} style={{ height: 24 }} alt="language" />
          </MenuItem>
        ))}
      </StyledUserSubActions>
    </StyledPopper>
  );
};

const UserActions = ({
  useNewProfileMobile,
  location,
  setScreenOpenMobile,
  onShowProfile,
}) => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t } = useTranslation();
  const history = useHistory();
  const { keycloak, removeKcTokenCookie } = useCustomKeycloak();
  const [language, setLanguage] = useState({});
  const [openLanguageAnchor, setOpenLanguageAnchor] = useState(null);
  const [openHelpAnchor, setOpenHelpAnchor] = useState(null);
  const theme = useTheme();
  const { getFeatureValue } = useFeatureFlags();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector((state) => state.auth);
  const { usingPackage } = useSelector((state) => state.user);
  const isVietNam = checkVietNam();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  const useConfigAutoRenewalPrepaidPayment =
    useNewTtsUI &&
    getFeatureValue(FEATURE_KEYS.CONFIG_AUTO_RENEW_PREPAID_PACKAGE, {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    }) &&
    checkUsingPrepaidMonthlyPkgOfStudio(usingPackage);

  const showSubscription = getFeatureValue(FEATURE_KEYS.SUBSCRIPTION, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const handleGoToProfile = () => keycloak.accountManagement();

  const handleLogout = async () => {
    const fingerprint =
      getCookie('fingerprint') || localStorage.getItem('fingerprint');

    await apis.devices.deactivateDevice(fingerprint);
    removeKcTokenCookie();
    keycloak.logout({ redirectUri: LANDING_PAGE_URL });
  };

  const handleHoverToLanguage = (e) => {
    if (!openLanguageAnchor && !isMobile)
      setOpenLanguageAnchor(e.currentTarget);
    if (openHelpAnchor) setOpenHelpAnchor(null);
  };

  const handleHoverToHelp = (e) => {
    if (!openHelpAnchor && !isMobile) setOpenHelpAnchor(e.currentTarget);
    if (openLanguageAnchor) setOpenLanguageAnchor(null);
  };

  const handleHoverToTheOther = () => {
    if (openHelpAnchor) setOpenHelpAnchor(null);
    if (openLanguageAnchor) setOpenLanguageAnchor(null);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
    setLanguage(lng);
    if (useNewProfileMobile) {
      const languageIcons = document.querySelectorAll(
        '.language-icon-new-profile',
      );
      languageIcons.forEach((lngIcon) => {
        if (lngIcon.dataset.value === lng.value) {
          // eslint-disable-next-line no-param-reassign
          lngIcon.style.filter = 'none';
        } else {
          // eslint-disable-next-line no-param-reassign
          lngIcon.style.filter = 'grayscale(0.9)';
        }
      });
    }
  };

  const handleClickChangeLanguage = (lng) => {
    handleChangeLanguage(lng);
    setCookie({
      cname: 'lng',
      cvalue: lng.value,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_LANGUAGE,
    });
  };

  const renderMenuItem = ({
    icon,
    label,
    onClick,
    onMouseEnter,
    justifyContentBetween,
    className,
  }) => (
    <MenuItem
      className={`user-action-item ${
        justifyContentBetween ? 'justify-content-between' : ''
      } ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {icon}
      {label}
    </MenuItem>
  );

  const renderExternalLink = ({ icon, label, url }) => (
    <MenuItem className="user-action-item" onMouseEnter={handleHoverToTheOther}>
      {icon}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-link"
      >
        {t(label)}
      </a>
    </MenuItem>
  );

  useEffect(() => {
    const defaultLanguageValue = localStorage.getItem('i18nextLng');
    const defaultLanguage = languages.find(
      (lng) => lng.value === defaultLanguageValue,
    );

    const currentLanguageValue = getCookie('lng');
    const currentLanguage = languages.find(
      (lng) => lng.value === currentLanguageValue,
    );

    if (location) {
      const { country: countryCode } = location;
      localStorage.setItem('country', COUNTRY[countryCode] || COUNTRY.EN);
    }

    if (currentLanguage) {
      handleChangeLanguage(currentLanguage);
      return;
    }

    const countryLanguageValue = localStorage.getItem('country');
    const countryLanguage = languages.find(
      (lng) => lng.country === countryLanguageValue,
    );
    if (countryLanguage) {
      handleChangeLanguage(countryLanguage);
      return;
    }

    if (defaultLanguage) {
      setLanguage(defaultLanguage);
      return;
    }
    handleChangeLanguage(languages[0]);
  }, [location]);

  const isExpiredPackage =
    !usingPackage.id ||
    (user.packageExpiryDate && moment().isAfter(user.packageExpiryDate));

  const showConfigAutoRenewal =
    !isDubbingRoute &&
    useConfigAutoRenewalPrepaidPayment &&
    isVietNam &&
    usingPackage.active &&
    !isExpiredPackage &&
    checkShowAutoRenewalPayment({
      packageCode: usingPackage?.code,
      alwayShow: true,
    });

  return (
    <StyledUserActions useNewProfileMobile={useNewProfileMobile}>
      {renderMenuItem({
        icon: <MenuBook />,
        label: t('profile'),
        onClick: handleGoToProfile,
        onMouseEnter: handleHoverToTheOther,
      })}
      {renderMenuItem({
        icon: <Devices />,
        label: t('manageDevice'),
        onClick: () => {
          history.push(ROUTES.MANAGE_DEVICE);
          onShowProfile();
        },
        onMouseEnter: handleHoverToTheOther,
      })}
      {renderMenuItem({
        icon: (
          <img src={WorkHistory} style={{ width: '24px' }} alt="work history" />
        ),
        label: t('paymentHistory'),
        onClick: () => {
          isDubbingRoute
            ? history.push(ROUTES.PAYMENT_HISTORY_DUBBING)
            : history.push(ROUTES.PAYMENT_HISTORY);
          onShowProfile();
        },
        onMouseEnter: handleHoverToTheOther,
      })}
      {showSubscription &&
        renderMenuItem({
          icon: <CardMembershipRounded />,
          label: t('myPackages'),
          onClick: () => {
            isDubbingRoute
              ? history.push(ROUTES.SUBSCRIPTION_DUBBING)
              : history.push(ROUTES.SUBSCRIPTION);
            onShowProfile();
          },
          onMouseEnter: handleHoverToTheOther,
        })}
      {showConfigAutoRenewal &&
        renderMenuItem({
          icon: <CreditCardRounded />,
          label: t('configAutoRenew'),
          onClick: () => history.push(ROUTES.CONFIG_AUTO_RENEW),
          onMouseEnter: handleHoverToTheOther,
        })}
      {renderExternalLink({
        icon: <Book />,
        label: 'termOfUse',
        url: VBEE_TERMS,
      })}
      {!useNewProfileMobile &&
        renderMenuItem({
          icon: (
            <Box className="user-action-has-submenu">
              <ContactSupport />
              {t('help')}
            </Box>
          ),
          label: <KeyboardArrowRight />,
          onMouseEnter: handleHoverToHelp,
          justifyContentBetween: true,
          onClick: () => {
            if (isMobile) {
              setScreenOpenMobile('help');
            }
          },
        })}
      {!useNewProfileMobile &&
        renderMenuItem({
          icon: (
            <Box className="user-action-has-submenu">
              <Language />
              {t('language')}
            </Box>
          ),
          label: (
            <Box display="flex">
              <img style={{ height: 24 }} src={language.icon} alt="language" />
              <KeyboardArrowRight />
            </Box>
          ),
          justifyContentBetween: true,
          onMouseEnter: handleHoverToLanguage,
          onClick: () => {
            if (isMobile) {
              setScreenOpenMobile('language');
            }
          },
        })}
      {useNewProfileMobile &&
        renderMenuItem({
          icon: (
            <Box className="user-action-has-submenu">
              <Language />
              {t('language')}
            </Box>
          ),
          label: (
            <Box className="language-choose-container">
              {languages.map((lng) => (
                <img
                  key={lng.value}
                  data-value={lng.value}
                  onClick={() => handleClickChangeLanguage(lng)}
                  src={lng.icon}
                  alt="language"
                  style={{
                    height: 24,
                    filter:
                      language.value === lng.value ? 'none' : 'grayscale(0.9)',
                  }}
                  className="language-icon-new-profile"
                />
              ))}
            </Box>
          ),
          justifyContentBetween: true,
        })}
      {renderMenuItem({
        icon: <LogoutOutlined />,
        label: t('logout'),
        className: 'red-color',
        onClick: handleLogout,
        onMouseEnter: handleHoverToTheOther,
      })}
      {openHelpAnchor && (
        <PopperHelp
          openHelpAnchor={openHelpAnchor}
          setOpenHelpAnchor={setOpenHelpAnchor}
        />
      )}
      {openLanguageAnchor && (
        <PopperLanguage
          openLanguageAnchor={openLanguageAnchor}
          setOpenLanguageAnchor={setOpenLanguageAnchor}
          language={language}
          setLanguage={setLanguage}
          onClose={handleHoverToTheOther}
        />
      )}
    </StyledUserActions>
  );
};

export default UserActions;
