import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledNext } from './index.style';

const NextButton = ({ title, isDone }) => {
  const { t } = useTranslation();
  return (
    <StyledNext>
      <div className="button-next">
        {t(title)}
        {!isDone && <NavigateNextIcon />}
      </div>
    </StyledNext>
  );
};

export default NextButton;
