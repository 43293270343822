import MuiDateRangePickerDay from '@mui/lab/DateRangePickerDay';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledCustomDatePickerRange = styled('div')`
  width: 100%;
  .text-field {
    background-color: ${COLOR.white};
    width: 100%;
    min-width: 240px;
    height: ${(props) => (props.showNewUI ? '' : 'fit-content')};

    .MuiOutlinedInput-root {
      font-size: 14px;
      border-radius: ${(props) => (props.showNewUI ? '100px' : '6px')};
    }

    .MuiInputBase-input {
      padding: ${(props) => (props.showNewUI ? '10px 0px 10px 14px' : '')};
    }
  }
`;

export const CustomDateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      backgroundColor: TRANSPARENT_COLOR.primary,
      color: COLOR.primary,
      '&:hover, &:focus': {
        backgroundColor: COLOR.primary,
        // color: COLOR.secondary,
      },
    }),
    ...(isStartOfHighlighting && {
      backgroundColor: COLOR.primary,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      // '.Mui-selected': {
      //   color: `${COLOR.white} !important`,
      // },
    }),
    ...(isEndOfHighlighting && {
      backgroundColor: COLOR.primary,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      // '.Mui-selected': {
      //   color: `${COLOR.white} !important`,
      // },
    }),
  }),
);
