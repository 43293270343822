import { CompositeDecorator } from 'draft-js';

const findWithRegexEditor = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr;
  let start;

  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const generateDecorator = (regex, ComponentHighlight, text) =>
  new CompositeDecorator([
    {
      strategy: (contentBlock, callback) => {
        if (text !== '') findWithRegexEditor(regex, contentBlock, callback);
      },
      component: ComponentHighlight,
    },
  ]);

export { findWithRegexEditor, generateDecorator };
