import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { StyledDot, StyledProcessingLoading } from './index.style';

const ProcessingLoading = () => {
  const { t } = useTranslation();
  return (
    <StyledProcessingLoading>
      <Box className="wrapper">
        <Box className="wrapper-title">
          <Typography>{t('IN_PROGRESS')}</Typography>
          <Box className="wrapper-dot">
            <StyledDot />
            <StyledDot />
            <StyledDot />
          </Box>
        </Box>
        <Typography className="wrapper-subtitle">
          {t('systemProcessing')}
        </Typography>
      </Box>
    </StyledProcessingLoading>
  );
};

export default ProcessingLoading;
