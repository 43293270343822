import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import moment from 'moment';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import { REQUEST_STATUS } from '@src/constants/voice';
import { AUDIO_EFFECTS_URL } from '@src/configs';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { downloadFileUsingUrl } from '@src/utils/file';
import { Tooltip, Typography } from '@mui/material';
import {
  FileDownloadOutlined,
  FlagRounded,
  TuneRounded,
} from '@mui/icons-material';
import { StyledIconButton, StyledRequestAction } from './index.style';

const RequestAction = ({ request, onOpenErrorReport, onOpenRatingPopUp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    id: requestId,
    title,
    characters,
    voice,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    status,
  } = request;

  const growthbook = useGrowthBook();
  const useNewEffectGuide = growthbook.getFeatureValue(
    FEATURE_KEYS.NEW_EFFECT_GUIDE,
  );

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );

  const isDisabledDownload =
    !audioLink || audioExpired || status === REQUEST_STATUS.FAILURE;

  const handleOpenErrorReport = (e) => {
    e.stopPropagation();
    onOpenErrorReport({ id: requestId, title, characters, voice });
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    onOpenRatingPopUp();
  };

  const handleDownloadAudio = async (e) => {
    e.stopPropagation();
    const res = await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
    onOpenRatingPopUp();
  };

  const handleOpenAudioEffects = () =>
    window.open(`${AUDIO_EFFECTS_URL}/?audio=${audioLink}`, '_blank');

  const handleOpenNewAudioEffects = async (e) => {
    e.stopPropagation();
    const res = await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      window.open(
        `${AUDIO_EFFECTS_URL}/?audio=${res.result?.audio || audioLink}`,
        '_blank',
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  return (
    <StyledRequestAction>
      {!useFeatureIsOn(FEATURE_KEYS.DOWNLOAD_AUDIO_LINK) ? (
        <StyledIconButton
          disabled={isDisabledDownload}
          onClick={handleDownloadAudio}
          colorHover="success"
          height="30px"
          borderRadius="8px"
          padding="4px 8px 4px 4px"
        >
          <div className="download-link">
            <FileDownloadOutlined className="icon" />
            <Typography className="text-action">{t('download')}</Typography>
          </div>
        </StyledIconButton>
      ) : (
        <StyledIconButton
          disabled={isDisabledDownload}
          onClick={handleDownload}
          colorHover="success"
          height="30px"
          borderRadius="8px"
          padding="4px 8px 4px 4px"
        >
          <a href={audioLink} download className="download-link">
            <FileDownloadOutlined className="icon" />
            <Typography className="text-action">{t('download')}</Typography>
          </a>
        </StyledIconButton>
      )}

      <Tooltip title={t('effectGuide')} arrow>
        <StyledIconButton
          onClick={
            useNewEffectGuide
              ? handleOpenNewAudioEffects
              : handleOpenAudioEffects
          }
          disabled={isDisabledDownload}
          colorHover="warning"
          height="30px"
          width="30px"
        >
          <TuneRounded className="icon" />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title={t('error')}>
        <StyledIconButton
          onClick={handleOpenErrorReport}
          colorHover="error"
          height="30px"
          width="30px"
        >
          <FlagRounded className="icon" />
        </StyledIconButton>
      </Tooltip>
    </StyledRequestAction>
  );
};
export default RequestAction;
