import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import _ from 'lodash';
import {
  AUDIO_TYPE,
  BITRATE,
  LANGUAGE_CODE,
  TTS_ATTRIBUTE,
  VOICE_PROVIDER,
  VOICE_FEATURE,
} from '@src/constants/voice';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  EditorState,
  CompositeDecorator,
  Modifier,
  SelectionState,
} from 'draft-js';
import { OrderedSet } from 'immutable';
import disableVolumeImg from '@src/assets/images/volume-disabled.png';
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Button,
  Divider,
} from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import {
  // UndoRounded,
  // RedoRounded,
  BarChartRounded,
  LibraryMusicRounded,
  FormatQuoteRounded,
  MenuBookRounded,
  MusicNoteRounded,
  VolumeUpRounded,
  SearchRounded,
  TextFieldsRounded,
  CloseRounded,
  CachedRounded,
  MoreHorizRounded,
} from '@mui/icons-material';
import BreakTime from '@src/components/BreakTime';
import Speed from '@src/components/Speed';
import Volume from '@src/components/Volume';
import BackgroundMusic from '@src/containers/BackgroundMusic';
import Dictionary from '@src/containers/Dictionary';
import ClientPause from '@src/containers/ClientPause';
import actions from '@src/redux/actions';
import BadgeAvatar from '@src/components/BadgeAvatar';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { FEATURE } from '@src/constants/package';
import SelectionButton from '@src/components/SelectionButton';

import {
  handleInsertElement,
  handleReplace,
  handleEditParagraphs,
  handleSelectedElement,
  handleSingleSelectedParagraph,
} from '@src/services/paragraph';
import { getEntityStrategy } from '@src/services/entity';
import { generateDecorator, findWithRegexEditor } from '@src/services/editor';
import { handleInsertElementOfSentence } from '@src/services/sentence';
import { checkFeaturePermission } from '@src/services/tts';

import HightLightSpan from '@src/components/Decorator/HightLightSpan';
import PreviewButton from '@src/components/PreviewButton';
import EmphasisButton from '@src/containers/EmphasisButton';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { VC_VOICE_CODE_REGEX } from '@src/constants/voiceCloning';
import {
  StyledToolbar,
  StyleMoreButtonPopper,
  StyleContentPopper,
} from './index.style';
import ImportToolbar from '../ImportToolbar';
import ProcessingMethods from '../ProcessingMethods';

const Toolbar = ({
  content,
  onOpenVoice,
  toolbarRef,
  openSentences,
  handleChangeContent,
  onChangeInputLength,
  onChangeCharacterExceed,
  onOpenSentences,
  synthesisSentence,
  onTryListeningSentence,
  audioRef,
}) => {
  const [search, setSearch] = useState('');
  const [replace, setReplace] = useState('');
  const [openBonusToolbar, setOpenBonusToolbar] = useState(false);
  const [openMusic, setOpenMusic] = useState(false);
  const [openDictionary, setOpenDictionary] = useState(false);
  const [openClientPause, setOpenClientPause] = useState(false);
  const [openMoreButton, setOpenMoreButton] = useState(false);
  const [anchorElMoreButton, setAnchorElMoreButton] = useState(null);
  const {
    synthesisRequest,
    paragraphs,
    selectedParagraph,
    selectedSentence,
    sentences,
    advanceFeature,
    previewParagraphs,
  } = useSelector((state) => state.synthesisRequest);
  const { usingPackage, ttsUser } = useSelector((state) => state.user);
  const { voice } = useSelector((state) => state.synthesisRequest);
  const { isAudioLoading } = useSelector((state) => state.audioPlayer);
  const user = useSelector((state) => state.auth.user);

  const canPreview = advanceFeature
    ? previewParagraphs?.length && usingPackage.id && !isAudioLoading
    : synthesisSentence && usingPackage.id && !isAudioLoading;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOpenMoreButton = useRef(false);
  const currentAnchorElMoreButton = useRef(null);

  const { getFeatureValue } = useFeatureFlags();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let useNewPreviewAudio = getFeatureValue(FEATURE_KEYS.TRY_LISTEN_TTS);
  useNewPreviewAudio = useNewPreviewAudio && useNewTtsUI;

  const handleChangeOpenBonusToolbar = () => {
    if (openMoreButton) {
      setOpenMoreButton(false);
      setOpenBonusToolbar(true);
    } else {
      setOpenBonusToolbar((prev) => !prev);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const MAX_RESPONSIVE_WIDTH = 1440;
      if (
        window.innerWidth > MAX_RESPONSIVE_WIDTH &&
        currentOpenMoreButton.current
      ) {
        currentOpenMoreButton.current = false;
        setOpenMoreButton(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClickMoreButton = (event) => {
    setAnchorElMoreButton(openMoreButton ? null : event.currentTarget);
    setOpenMoreButton((previousOpen) => !previousOpen);
    currentOpenMoreButton.current = !currentOpenMoreButton.current;
    currentAnchorElMoreButton.current = event.currentTarget;
  };

  const SearchHighlight = ({ children }) => (
    <span className="search-highlight">{children}</span>
  );

  const handleChangeSearch = (e) => {
    e.persist();
    setSearch(e.target.value);

    if (openSentences) return;

    const regex = new RegExp(_.escapeRegExp(e.target.value), 'gi');
    const stateContent = EditorState.set(content, {
      decorator: generateDecorator(regex, SearchHighlight, e.target.value),
    });
    handleChangeContent(stateContent);
  };

  const newHandleChangeSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === '') {
      const stateContent = EditorState.set(content, {
        decorator: new CompositeDecorator([
          {
            strategy: getEntityStrategy('MUTABLE'),
            component: HightLightSpan,
          },
        ]),
      });
      handleChangeContent(stateContent);
      return;
    }
    const regex = new RegExp(_.escapeRegExp(e.target.value), 'gi');
    const stateContent = EditorState.set(content, {
      decorator: new CompositeDecorator([
        {
          strategy: getEntityStrategy('MUTABLE'),
          component: HightLightSpan,
        },
        {
          strategy: (contentBlock, callback) => {
            if (e.target.value !== '')
              findWithRegexEditor(regex, contentBlock, callback);
          },
          component: SearchHighlight,
        },
      ]),
    });
    handleChangeContent(stateContent);
  };

  const handleSearch = () => {
    if (openSentences)
      dispatch(actions.synthesisRequest.changeSearchContent(search));
    else {
      const regex = new RegExp(search, 'gi');
      const stateContent = EditorState.set(content, {
        decorator: new CompositeDecorator([
          {
            strategy: getEntityStrategy('MUTABLE'),
            component: HightLightSpan,
          },
          {
            strategy: (contentBlock, callback) => {
              if (search !== '')
                findWithRegexEditor(regex, contentBlock, callback);
            },
            component: SearchHighlight,
          },
        ]),
      });
      handleChangeContent(stateContent);
    }
  };

  const handleEnterKeySearch = (e) => {
    if (e.key === 'Enter') handleSearch();
  };

  const handleChangeReplace = () => {
    if (!search || !replace) return;
    if (openSentences)
      dispatch(actions.synthesisRequest.replaceInSentences(replace));
    else {
      const regex = new RegExp(search, 'gi');
      const selectionsToReplace = [];
      const blockMap = content.getCurrentContent().getBlockMap();
      const delta = replace.length - search.length;
      let blockIndex = 0;
      let currentBlockKey = '';
      blockMap.forEach((contentBlock) =>
        findWithRegexEditor(regex, contentBlock, (start, end) => {
          const blockKey = contentBlock.getKey();
          if (currentBlockKey !== blockKey) {
            blockIndex = 0;
            currentBlockKey = blockKey;
          }
          const blockSelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start + blockIndex * delta,
            focusOffset: end + blockIndex * delta,
          });
          blockIndex += 1;
          selectionsToReplace.push(blockSelection);
        }),
      );

      let contentState = content.getCurrentContent();

      selectionsToReplace.forEach((selectionState) => {
        contentState = Modifier.replaceText(
          contentState,
          selectionState,
          replace,
        );
      });
      handleChangeContent(EditorState.push(content, contentState));
    }
  };

  const newHandleChangeReplace = () => {
    if (!search || !replace) return;

    const contentState = handleReplace({
      search,
      replace,
      editorState: content,
      paragraphs,
    });
    const newEditorState = EditorState.push(content, contentState);
    handleChangeContent(EditorState.push(content, contentState));

    const newParagraphs = handleEditParagraphs(paragraphs, newEditorState);
    if (newParagraphs !== null) {
      dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
    }
  };

  const handleEnterKeyReplace = (event) => {
    if (event.key === 'Enter') handleChangeReplace();
  };

  const handleOpenMusic = () => setOpenMusic(true);
  const handleCloseMusic = () => setOpenMusic(false);

  const handleOpenDictionary = () => setOpenDictionary(true);
  const handleCloseDictionary = () => setOpenDictionary(false);

  const hasClientPause =
    ttsUser.id &&
    checkFeaturePermission(ttsUser.features, FEATURE.CLIENT_PAUSE) &&
    voice?.language?.code === LANGUAGE_CODE.VIETNAMESE;
  const handleOpenClientPause = () => {
    if (!hasClientPause) {
      dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
      return;
    }
    setOpenClientPause(true);
  };
  const handleCloseClientPause = () => setOpenClientPause(false);

  // const handleUndoContent = () =>
  //   handleChangeContent(EditorState.undo(content));

  // const handleRedoContent = () =>
  //   handleChangeContent(EditorState.redo(content));

  const handleChangeSynthesisRequest = (name, value) =>
    dispatch(actions.synthesisRequest.updateSynthesisRequest(name, value));

  const handleChangeBreakTime = (value) => {
    handleChangeSynthesisRequest('breakTime', value);
    if (openSentences) return;
    const currentContent = content.getCurrentContent();
    const selection = content.getSelection();
    const label = `<break time=${value}s/>`;
    const textWithEntity = Modifier.insertText(
      currentContent,
      selection,
      label,
      OrderedSet.of('BOLD'),
    );

    handleChangeContent(
      EditorState.push(content, textWithEntity, 'insert-characters'),
    );
  };

  const handleBreakTimeOfSentence = (value) => {
    const { sentenceId, focusPosition } = selectedSentence;
    if (sentenceId && focusPosition !== undefined) {
      const sentence = sentences.find((item) => item.id === sentenceId);
      if (!sentence) return;

      const newSentence = handleInsertElementOfSentence({
        elements: sentence.elements,
        sentenceText: sentence.text,
        focusOffset: selectedSentence.focusPosition,
        elementName: TTS_ATTRIBUTE.BREAK_TIME,
        elementValue: value,
      });

      const { elements, text } = newSentence;
      dispatch(actions.synthesisRequest.updateSelectedSentence({}));
      dispatch(
        actions.synthesisRequest.updateElementOfSentence({
          elements,
          text,
          sentenceId,
          onLoad: true,
          mousePointerPosition: focusPosition + `${value}s`.length,
        }),
      );
    }
  };

  const handleEmphasisOfSentence = (value) => {
    const { sentenceId, startOffset, endOffset } = selectedSentence;

    if (!sentenceId || (startOffset === endOffset) === undefined) return;

    const sentence = sentences.find((item) => item.id === sentenceId);
    if (!sentence) return;

    const newSentence = handleSingleSelectedParagraph({
      currentParagraph: sentence,
      startOffset,
      endOffset,
      elementName: TTS_ATTRIBUTE.EMPHASIS,
      elementValue: value,
    });

    const { elements, text } = newSentence;
    dispatch(actions.synthesisRequest.updateSelectedSentence({}));
    dispatch(
      actions.synthesisRequest.updateElementOfSentence({
        elements,
        text,
        sentenceId,
        onLoad: true,
        mousePointerPosition: endOffset,
      }),
    );
  };

  const newHandleChangeBreakTime = (value) => {
    handleChangeSynthesisRequest('breakTime', value);
    if (advanceFeature && openSentences) {
      handleBreakTimeOfSentence(value);
    } else {
      const newParagraphs = handleInsertElement({
        paragraphs,
        editorState: content,
        elementName: TTS_ATTRIBUTE.BREAK_TIME,
        elementValue: value,
      });

      if (newParagraphs !== null) {
        dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
        dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
      }
    }
  };

  const handleChangeBackgroundMusic = (name, link) =>
    dispatch(actions.synthesisRequest.updateBackgroundMusic(name, link));

  const handleChangeVolumeBackgroundMusic = (value) =>
    dispatch(actions.synthesisRequest.updateVolumeBackgroundMusic(value));

  const handleChangeEmphasis = (value) => {
    handleChangeSynthesisRequest(TTS_ATTRIBUTE.EMPHASIS.toLowerCase(), value);

    if (advanceFeature && openSentences) {
      handleEmphasisOfSentence(value);
    } else {
      const newParagraphs = handleSelectedElement({
        paragraphs,
        selectedParagraph,
        elementName: TTS_ATTRIBUTE.EMPHASIS,
        elementValue: value,
      });

      if (newParagraphs !== null) {
        dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
        dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
      }
      dispatch(actions.synthesisRequest.updateSelectedParagraph([]));
    }
  };

  const handleChangeSpeed = (name, value) => {
    handleChangeSynthesisRequest(name, value);

    // Handle phrase text when choose speed
    // const newParagraphs = handleSelectedElement({
    //   paragraphs,
    //   selectedParagraph,
    //   elementName: TTS_ATTRIBUTE.SPEED,
    //   elementValue: value,
    // });

    // if (newParagraphs !== null) {
    //   dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
    //   dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
    // }
    // dispatch(actions.synthesisRequest.updateSelectedParagraph([]));
  };

  const handleOpenVoices = () => onOpenVoice(true);

  // const GroupSupportEnterText = () => (
  //   <div className="group group-support-enter-text">
  //     <Tooltip arrow title={t('undo')} placement="top">
  //       <div className={content.getUndoStack().isEmpty() && 'disabled'}>
  //         <IconButton
  //           onClick={handleUndoContent}
  //           disabled={content.getUndoStack().isEmpty()}
  //           color="iconPrimary"
  //         >
  //           <UndoRounded className="icon" sx={{ fontSize: 20 }} />
  //         </IconButton>
  //       </div>
  //     </Tooltip>
  //     <Tooltip arrow title={t('redo')} placement="top">
  //       <div
  //         className={`redo-button ${
  //           content.getRedoStack().isEmpty() && 'disabled'
  //         }`}
  //       >
  //         <IconButton
  //           onClick={handleRedoContent}
  //           disabled={content.getRedoStack().isEmpty()}
  //           color="iconPrimary"
  //         >
  //           <RedoRounded className="icon" sx={{ fontSize: 20 }} />
  //         </IconButton>
  //       </div>
  //     </Tooltip>

  //     <Divider orientation="vertical" flexItem className="divider" />
  //   </div>
  // );

  const renderGroupAdjustVoice = () => (
    <div className="group group-adjust-voice">
      <Tooltip arrow title={t('chooseVoice')} placement="top">
        <Button
          id={TTS_GUIDE.VOICE}
          onClick={handleOpenVoices}
          className="choose-voice margin-right"
        >
          <BadgeAvatar
            width={25}
            smallImgWidth={10}
            img={voice && voice.roundImage}
            smallImg={voice && voice.language && voice.language.roundImage}
            type="image"
          />
          <Typography variant="body2" className="voice-name">
            {voice && voice.name}
          </Typography>
        </Button>
      </Tooltip>
      <Speed
        currentSpeed={synthesisRequest.speed}
        disabled={
          advanceFeature
            ? openSentences
            : voice?.provider === VOICE_PROVIDER.AMAZON
        }
        onChange={
          advanceFeature ? handleChangeSpeed : handleChangeSynthesisRequest
        }
        width="55px"
      />

      <Divider orientation="vertical" flexItem className="divider" />
    </div>
  );

  const renderGroupAdjustAudio = ({ isMoreButton } = {}) => (
    <div
      className={`group group-adjust-audio ${
        isMoreButton && 'group-adjust-audio-more-button'
      }`}
    >
      <div className="margin-right">
        <SelectionButton
          currentValue={synthesisRequest.bitrate}
          name="bitrate"
          tooltipTitle="adjustAudioQuality"
          text="kbps"
          icon={<BarChartRounded sx={{ fontSize: 20 }} />}
          options={BITRATE}
          width="110px"
          onChange={handleChangeSynthesisRequest}
        />
      </div>
      <SelectionButton
        currentValue={synthesisRequest.audioType}
        name="audioType"
        tooltipTitle="changeAudioFormat"
        icon={<LibraryMusicRounded sx={{ fontSize: 20 }} />}
        options={AUDIO_TYPE}
        width="70px"
        onChange={handleChangeSynthesisRequest}
      />

      <Divider orientation="vertical" flexItem className="divider" />
    </div>
  );

  const renderGroupAdjustReadingStyle = ({ isMoreButton } = {}) => (
    <div
      className={`group group-adjust-reading-style ${
        isMoreButton && 'group-adjust-reading-style-more-button'
      }`}
    >
      <BreakTime
        currentBreakTime={synthesisRequest.breakTime}
        disabled={
          !advanceFeature &&
          !VC_VOICE_CODE_REGEX.test(voice?.code) &&
          voice?.provider !== VOICE_PROVIDER.VBEE
        }
        onChange={
          advanceFeature ? newHandleChangeBreakTime : handleChangeBreakTime
        }
        tooltipTitle="customBreakTime"
        width="45px"
      />
      <Tooltip arrow title={t('configClientPause')} placement="top">
        <div>
          <Button
            className="custom-button margin-right"
            onClick={handleOpenClientPause}
            disabled={!hasClientPause}
            color="iconPrimary"
          >
            <FormatQuoteRounded sx={{ fontSize: 20 }} />
          </Button>
        </div>
      </Tooltip>
      <EmphasisButton
        currentEmphasis={synthesisRequest.emphasis}
        disabled={
          !advanceFeature || !voice?.features?.includes(VOICE_FEATURE.EMPHASIS)
        }
        onChange={handleChangeEmphasis}
        iconStyle
        className="custom-button margin-right"
      />

      <Tooltip arrow title={t('dictionary')} placement="top">
        <Button
          className="custom-button"
          onClick={handleOpenDictionary}
          color="iconPrimary"
        >
          <MenuBookRounded sx={{ fontSize: 20 }} />
        </Button>
      </Tooltip>

      <Divider orientation="vertical" flexItem className="divider" />
    </div>
  );

  const renderGroupAdjustBackgroundMusic = ({ isMoreButton } = {}) => (
    <div
      className={`group group-adjust-background-music ${
        isMoreButton && 'group-adjust-background-music-more-button'
      }`}
    >
      <Tooltip
        arrow
        title={`${t('backgroundMusicOption')} ${
          synthesisRequest.backgroundMusic?.name || ''
        }`}
        placement="top"
      >
        <Button
          className="custom-button margin-right"
          onClick={handleOpenMusic}
        >
          <MusicNoteRounded sx={{ fontSize: 20 }} />
          {synthesisRequest?.backgroundMusic?.name && (
            <Typography
              variant="body2"
              className="toolbar-text background-music-name"
            >
              {synthesisRequest?.backgroundMusic?.name}
            </Typography>
          )}
        </Button>
      </Tooltip>

      <Volume
        active={
          synthesisRequest.backgroundMusic?.link &&
          synthesisRequest.backgroundMusic?.volume !== 0
        }
        disabled={!synthesisRequest.backgroundMusic?.link}
        volume={synthesisRequest.backgroundMusic?.volume || 0}
        tooltipTitle={t('changeBackgroundMusicVolume')}
        icon={<VolumeUpRounded sx={{ fontSize: 20 }} />}
        disableIcon={disableVolumeImg}
        max={100}
        width="215px"
        onChange={(value) => handleChangeVolumeBackgroundMusic(value)}
      />

      <Divider orientation="vertical" flexItem className="divider" />
    </div>
  );

  const renderGroupSearchAndReplace = ({ isMoreButton } = {}) => (
    <div
      className={`group group-search-and-replace ${
        isMoreButton && 'group-search-and-replace-more-button'
      }`}
    >
      {isMoreButton ? (
        <>
          <Button
            className="custom-button search-and-replace-more-button-web"
            onClick={handleChangeOpenBonusToolbar}
            color="iconPrimary"
            startIcon={<SearchRounded sx={{ fontSize: 20 }} />}
          >
            {t('searchAndReplace')}
          </Button>
          <Tooltip arrow title={t('searchAndReplace')} placement="top">
            <Button
              className="custom-button search-and-replace-more-button-mobile"
              onClick={handleChangeOpenBonusToolbar}
              color="iconPrimary"
            >
              <SearchRounded sx={{ fontSize: 20 }} />
            </Button>
          </Tooltip>
        </>
      ) : (
        <Tooltip arrow title={t('searchAndReplace')} placement="top">
          <Button
            className="custom-button search-and-replace"
            onClick={handleChangeOpenBonusToolbar}
            color="iconPrimary"
          >
            <SearchRounded sx={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}

      <Divider orientation="vertical" flexItem className="divider" />
    </div>
  );

  const renderGroupImportData = ({ isMoreButton } = {}) => (
    <div
      className={`group group-import-data ${
        isMoreButton && 'group-import-data-more-button'
      }`}
    >
      <ImportToolbar
        openSentences={openSentences}
        onChangeContent={handleChangeContent}
        onChangeInputLength={onChangeInputLength}
        onChangeCharacterExceed={onChangeCharacterExceed}
        isMoreButton={isMoreButton}
      />
    </div>
  );

  const renderMoreButton = () => {
    const canBeOpen = currentOpenMoreButton.current;
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
      <>
        <div className="more-button">
          <IconButton
            className={`custom-button ${openMoreButton && 'active-button'}`}
            color="iconPrimary"
            onClick={handleClickMoreButton}
            aria-describedby={id}
          >
            <MoreHorizRounded sx={{ fontSize: 20 }} />
          </IconButton>
        </div>

        <StyleMoreButtonPopper
          id={id}
          open={openMoreButton}
          anchorEl={anchorElMoreButton}
          placement="bottom"
          modifiers={[
            {
              name: 'preventOverflow',
              options: {
                padding: { right: 40, left: 40 },
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ]}
        >
          <StyleContentPopper>
            {renderGroupAdjustAudio({ isMoreButton: true })}
            {renderGroupAdjustReadingStyle({ isMoreButton: true })}
            {renderGroupAdjustBackgroundMusic({ isMoreButton: true })}
            {renderGroupSearchAndReplace({ isMoreButton: true })}
            {renderGroupImportData({ isMoreButton: true })}
          </StyleContentPopper>
        </StyleMoreButtonPopper>
      </>
    );
  };

  const renderNormalToolbar = () => (
    <div className="left-toolbar">
      {renderGroupAdjustVoice()}
      {renderGroupAdjustAudio()}
      {renderGroupAdjustReadingStyle()}
      {renderGroupAdjustBackgroundMusic()}
      {renderGroupSearchAndReplace()}
      {renderGroupImportData()}
    </div>
  );

  const renderResponsiveToolbar = () => (
    <div className="left-toolbar">
      {renderGroupAdjustVoice()}
      {renderGroupAdjustAudio()}
      {renderGroupAdjustReadingStyle()}
      {renderGroupAdjustBackgroundMusic()}
      {renderGroupSearchAndReplace()}
      {renderGroupImportData()}
      {renderMoreButton()}
    </div>
  );

  return (
    <StyledToolbar
      ref={toolbarRef}
      openbonus={openBonusToolbar}
      allowresponsive={useFeatureIsOn(FEATURE_KEYS.RESPONSIVE_TOOLBAR)}
    >
      <div className="toolbar-row">
        {useFeatureIsOn(FEATURE_KEYS.RESPONSIVE_TOOLBAR)
          ? renderResponsiveToolbar()
          : renderNormalToolbar()}
        <div className="toolbar-right">
          {useNewPreviewAudio && (
            <PreviewButton
              synthesisRequest={synthesisRequest}
              synthesisSentence={synthesisSentence}
              openSentences={openSentences}
              onTryListeningSentence={onTryListeningSentence}
              audioRef={audioRef}
              canPreview={canPreview}
            />
          )}
          <Divider
            orientation="vertical"
            flexItem
            className="divider margin-0"
          />
          <ProcessingMethods
            openSentences={openSentences}
            onOpenSentences={onOpenSentences}
          />
        </div>
      </div>
      <div className="toolbar-row-bonus">
        <TextField
          variant="outlined"
          className="search-input margin-right-bonus-toolbar"
          placeholder={t('search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchRounded
                  onClick={handleSearch}
                  className="icon"
                  sx={{ fontSize: 20 }}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={advanceFeature ? newHandleChangeSearch : handleChangeSearch}
          onKeyPress={handleEnterKeySearch}
        />
        <TextField
          variant="outlined"
          className="search-input margin-right-bonus-toolbar"
          placeholder={t('replace')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <TextFieldsRounded
                  className="icon"
                  onClick={
                    advanceFeature
                      ? newHandleChangeReplace
                      : handleChangeReplace
                  }
                  sx={{ fontSize: 20 }}
                />
              </InputAdornment>
            ),
          }}
          value={replace}
          onChange={(e) => setReplace(e.target.value)}
          onKeyPress={handleEnterKeyReplace}
        />
        <Button
          variant="outlined"
          onClick={handleChangeReplace}
          color="secondary"
          startIcon={<CachedRounded />}
          className="replace-button"
          disabled={!replace || !search}
        >
          {t('replace')}
        </Button>
        <IconButton
          color="iconPrimary"
          className="close-button"
          onClick={() => setOpenBonusToolbar(false)}
        >
          <CloseRounded className="icon" sx={{ fontSize: 20 }} />
        </IconButton>
      </div>

      <BackgroundMusic
        open={openMusic}
        currentBackgroundMusic={synthesisRequest.backgroundMusic}
        name="backgroundMusic"
        onClose={handleCloseMusic}
        onChange={handleChangeBackgroundMusic}
      />
      <Dictionary open={openDictionary} onClose={handleCloseDictionary} />
      <ClientPause open={openClientPause} onClose={handleCloseClientPause} />
    </StyledToolbar>
  );
};

export default Toolbar;
