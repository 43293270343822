import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledEmptyRequest = styled('div')`
  margin-top: 46px;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .button {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 500px;
    text-transform: none;
    height: 48px;
  }
  .text-empty-list {
    color: #1c1c1e;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  .text {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }
`;

export const StyledRequests = styled('div')`
  height: 100%;
  overflow-y: auto;
  padding: 0 16px 32px 16px;
  .request-item {
    display: flex;
    gap: 16px;
    align-items: center;
    min-height: 74px;
    border-bottom: 1px solid #e0e0e0;
  }
  .action {
    display: flex;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const StyledTitle = styled('div')`
  flex-grow: 1;
  .title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #1c1c1e;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-time {
    font-size: 12px;
    color: #6f6f6f;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledBtnCreate = styled('div')`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  z-index: 100000;
  .btn-create-new {
    width: 100%;
    border-radius: 0;
    display: flex;
    gap: 4px;
    height: 48px;
  }
  .text-create-new {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.4px;
    text-transform: none;
  }
`;

export const StyledLoading = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledListAction = styled('div')`
  padding: 8px 16px;
  .action {
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    gap: 8px;
    border-bottom: 1px solid #e0e0e0;
  }
  .action:last-child {
    border-bottom: none;
  }
`;
export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    border-radius: 16px 16px 0px 0px;
  }
  .drawer-title {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    padding: 0 16px;
    background-color: ${COLOR.divider};
    height: 48px;

    svg:hover {
      cursor: pointer;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      text-transform: none;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
