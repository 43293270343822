import { VOICE_PROVIDER } from '@src/constants/voice';

const extractVoiceName = ({ name = '', provider = '' }) => {
  if (provider === VOICE_PROVIDER.VBEE)
    return { voiceName: name.split(' - ')[1] };
  if (name.includes(' - ')) {
    const [voiceName, voiceType] = name.split(/-(?=[^-]*$)/);
    return { voiceName, voiceType };
  }

  return { voiceName: name };
};

export { extractVoiceName };
