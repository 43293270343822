import React from 'react';
import { Typography } from '@mui/material';
import { StyledImportantTag } from './index.style';

const ImportantTag = ({
  tag,
  bgColor,
  color,
  right,
  top,
  padding,
  borderRadius,
}) => (
  <StyledImportantTag
    bgColor={bgColor}
    color={color}
    right={right}
    top={top}
    padding={padding}
    borderRadius={borderRadius}
  >
    <Typography className="new-label-text">{tag}</Typography>
  </StyledImportantTag>
);
export default ImportantTag;
