export const actionTypes = {
  UPDATE_AUDIO_LINK: 'UPDATE_AUDIO_LINK',
  UPDATE_SELECTED_AUDIO_REQUEST: 'UPDATE_SELECTED_AUDIO_REQUEST',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_META_DATA: 'UPDATE_META_DATA',
  UPDATE_VOLUME: 'UPDATE_VOLUME',

  UPDATE_TRY_LISTENING_SENTENCE: 'UPDATE_TRY_LISTENING_SENTENCE',

  UPDATE_REQUEST_AUDIO_LINK: 'UPDATE_REQUEST_AUDIO_LINK',
  UPDATE_REQUEST_PREVIEW_ID: 'UPDATE_REQUEST_PREVIEW_ID',

  UPDATE_AUDIO_TYPE: 'UPDATE_AUDIO_TYPE',
  UPDATE_STREAM_AUDIOS: 'UPDATE_STREAM_AUDIOS',
  INIT_STREAM_AUDIOS: 'INIT_STREAM_AUDIOS',
  UPDATE_STATUS_STREAM_AUDIO: 'UPDATE_STATUS_STREAM_AUDIO',
  UPDATE_FINAL_STREAM_AUDIO: 'UPDATE_FINAL_STREAM_AUDIO',

  UPDATE_SHOW_AUDIO_PLAYER: 'UPDATE_SHOW_AUDIO_PLAYER',
  UPDATE_PREVIEW_REQUEST_INFO: 'UPDATE_PREVIEW_REQUEST_INFO',

  RESET_AUDIO_PLAYER: 'RESET_AUDIO_PLAYER',
};

const resetAudioPlayer = () => ({
  type: actionTypes.RESET_AUDIO_PLAYER,
});

const updateAudioLink = (audioLink, isPreview) => ({
  type: actionTypes.UPDATE_AUDIO_LINK,
  audioLink,
  isPreview,
});

const updateSelectedAudioRequest = (requestId) => ({
  type: actionTypes.UPDATE_SELECTED_AUDIO_REQUEST,
  requestId,
});

const updateStatus = (isPlaying) => ({
  type: actionTypes.UPDATE_STATUS,
  isPlaying,
});

const updateMetaData = ({ currentTime, duration }) => ({
  type: actionTypes.UPDATE_META_DATA,
  currentTime,
  duration,
});

const updateTryListeningSentence = ({
  sentenceId,
  isAudioLoading,
  audioLink,
}) => ({
  type: actionTypes.UPDATE_TRY_LISTENING_SENTENCE,
  sentenceId,
  isAudioLoading,
  audioLink,
});

const updateRequestAudioLink = (audioLink) => ({
  type: actionTypes.UPDATE_REQUEST_LINK_AUDIO_PLAYER,
  audioLink,
});

const updateRequestPreviewId = (requestPreviewId) => ({
  type: actionTypes.UPDATE_REQUEST_PREVIEW_ID,
  requestPreviewId,
});

const updateAudioType = (audioType) => ({
  type: actionTypes.UPDATE_AUDIO_TYPE,
  audioType,
});

const updateStreamAudios = (streamAudio) => ({
  type: actionTypes.UPDATE_STREAM_AUDIOS,
  streamAudio,
});

const initStreamAudios = (ttsVersion) => ({
  type: actionTypes.INIT_STREAM_AUDIOS,
  ttsVersion,
});

const updateStatusStreamAudio = (isRunStreamAudio) => ({
  type: actionTypes.UPDATE_STATUS_STREAM_AUDIO,
  isRunStreamAudio,
});

const updateFinalStreamAudio = (streamAudio) => ({
  type: actionTypes.UPDATE_FINAL_STREAM_AUDIO,
  streamAudio,
});

const updateShowAudioPlayer = (status) => ({
  type: actionTypes.UPDATE_SHOW_AUDIO_PLAYER,
  status,
});

const updatePreviewRequestInfo = (request) => ({
  type: actionTypes.UPDATE_PREVIEW_REQUEST_INFO,
  request,
});

const updateVolume = (volume) => ({
  type: actionTypes.UPDATE_VOLUME,
  volume,
});

export {
  resetAudioPlayer,
  updateAudioLink,
  updateSelectedAudioRequest,
  updateStatus,
  updateMetaData,
  updateTryListeningSentence,
  updateRequestAudioLink,
  updateRequestPreviewId,
  updateAudioType,
  updateStreamAudios,
  initStreamAudios,
  updateStatusStreamAudio,
  updateFinalStreamAudio,
  updateShowAudioPlayer,
  updatePreviewRequestInfo,
  updateVolume,
};
