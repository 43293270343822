import React, { createRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import apis from '@src/apis';
import { ListItem, Typography, CircularProgress, Box } from '@mui/material';
import {
  FiberManualRecordOutlined,
  PauseOutlined,
  VolumeUpOutlined,
} from '@mui/icons-material';
import { PLAYING_AUDIO_TYPE, SILENCE_AUDIO_URL } from '@src/constants/tts';

const initialPlayMusic = {
  musicLink: '',
  playing: false,
};

const BackgroundMusicItem = ({
  backgroundMusic,
  isSelected,
  onSelectedMusic,
}) => {
  const [playMusic, setPlayMusic] = useState(initialPlayMusic);
  const [loading, setLoading] = useState(false);
  const [audioType, setAudioType] = useState(PLAYING_AUDIO_TYPE.SILENCE);

  const audioRef = createRef();

  const handleSelectedMusic = () => {
    if (isSelected) {
      onSelectedMusic({});
      return;
    }
    onSelectedMusic(backgroundMusic);
  };

  const handlePlayAudio = (url) => {
    setPlayMusic({ playing: true, musicLink: url });
    setLoading(false);
  };

  const handleAudioEnd = () => {
    if (audioType === PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC) {
      setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      setPlayMusic(initialPlayMusic);
      return;
    }

    if (playMusic?.musicLink) {
      setAudioType(PLAYING_AUDIO_TYPE.BACKGROUND_MUSIC);
      audioRef.current.src = playMusic?.musicLink;
      audioRef.current.load();
      audioRef.current.play();
      return;
    }

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();
  };

  const fetchSharingSignedUrl = async (key) => {
    if (!key) return;
    const data = await apis.backgroundMusic.getBgMusicPresignedUrlForSharing(
      key,
    );
    if (data?.status) {
      const { url } = data.result;
      handlePlayAudio(url);
    }
  };

  const handlePlayBackgroundMusic = () => {
    setAudioType(PLAYING_AUDIO_TYPE.SILENCE);
    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();

    if (playMusic.musicLink) {
      audioRef.current.play();
      setPlayMusic({ ...playMusic, playing: true });
      return;
    }
    setLoading(true);
    if (backgroundMusic.userId) {
      const { pathname } = new URL(backgroundMusic.link);
      const key = pathname.substring(1);
      fetchSharingSignedUrl(key);
    } else {
      handlePlayAudio(backgroundMusic.link);
    }
  };

  const handleStopBackgroundMusic = () => {
    if (isSelected) {
      audioRef.current.pause();
      setPlayMusic((prev) => ({ ...prev, playing: false }));
    }
  };

  const MusicAction = () => {
    if (loading)
      return (
        <div>
          <CircularProgress size={15} className="loading" />
        </div>
      );
    if (playMusic.playing)
      return (
        <Box onClick={handleStopBackgroundMusic}>
          <PauseOutlined />
        </Box>
      );
    return (
      <div
        onClick={handlePlayBackgroundMusic}
        role="presentation"
        className="play-audio-button"
      >
        <VolumeUpOutlined />
      </div>
    );
  };

  useEffect(() => {
    if (!isSelected) {
      audioRef.current.load();
      setPlayMusic({ ...playMusic, playing: false });
    }
  }, [isSelected]);

  useEffect(() => {
    if (playMusic.musicLink) audioRef.current.load();
  }, [playMusic.musicLink]);

  return (
    <ListItem
      className={classNames('list-item', { selected: isSelected })}
      onClick={handleSelectedMusic}
      secondaryAction={<MusicAction />}
    >
      <FiberManualRecordOutlined
        sx={{ fontSize: 12 }}
        className="list-item-icon"
      />
      <Typography variant="body2">{backgroundMusic?.name}</Typography>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        autoPlay={playMusic.playing}
        onEnded={handleAudioEnd}
      >
        <source src={playMusic.musicLink} />
      </audio>
    </ListItem>
  );
};

export default BackgroundMusicItem;
