import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import NewCopyIcon from '@src/assets/icons/new-copy-icon.svg';
import { CRM_URL } from '@src/configs';
import MobileWeb from '@src/assets/images/mobile-desktop.svg';
import CheckIcon from '@src/assets/icons/check.svg';
import {
  ContentMobileDubbing,
  Feature,
  FeatureDubbingContainer,
  ListFeatureDubbing,
  MobileDubbingContainer,
} from './index.style';

const NewDubbingOnMobile = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = React.useState(false);
  const handleClickCopy = () => {
    navigator.clipboard.writeText(`${CRM_URL}/dubbing`);
    setCopied(true);
  };

  return (
    <MobileDubbingContainer>
      <div style={{ textAlign: 'center' }}>
        <img
          className="icon-mobile-web"
          src={MobileWeb}
          alt="icon-mobile-web"
        />
      </div>
      <ContentMobileDubbing>{t('notiMobileDubbing')}</ContentMobileDubbing>
      <ListFeatureDubbing>
        <FeatureDubbingContainer>
          <img className="small-check-icon" src={CheckIcon} alt="check-icon" />
          <Feature>{t('easyDubbing')}</Feature>
        </FeatureDubbingContainer>
        <FeatureDubbingContainer>
          <img className="small-check-icon" src={CheckIcon} alt="check-icon" />
          <Feature>{t('diverseVoice')}</Feature>
        </FeatureDubbingContainer>
        <FeatureDubbingContainer>
          <img className="small-check-icon" src={CheckIcon} alt="check-icon" />
          <Feature>{t('configContent')}</Feature>
        </FeatureDubbingContainer>
      </ListFeatureDubbing>
      <Button onClick={handleClickCopy} className="button-copy-url">
        {!copied ? (
          <img src={NewCopyIcon} alt="copy-icon" className="copy-icon" />
        ) : (
          <Check className="checked-icon" />
        )}
        <Typography className="btn-copy-text">
          {!copied ? t('copyUrl') : t('copiedv2')}
        </Typography>
      </Button>
    </MobileDubbingContainer>
  );
};

export default NewDubbingOnMobile;
