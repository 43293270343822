import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledActionWarning = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledDialogHistoryChanged = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: ${(props) => `${props.borderRadius} !important` || '5px'};
    min-width: ${(props) => `${props.width} !important` || 'auto'};
    margin: 0;
    height: ${(props) => `${props.height} !important` || 'auto'};
  }

  .dialog-title {
    margin: 0 8px;
    font-size: ${(props) => `${props.titlefontsize} !important` || '20px'};
    font-weight: ${(props) => `${props.titlefontweight} !important` || '500'};
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .sub-title {
    color: ${COLOR.dark};
    margin: 5px 0;
  }

  .request-table-wrapper {
    display: flex;
    padding: 0 16px;
    justify-content: center;
    height: calc(100% - 80px);
  }
`;
