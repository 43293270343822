import IconLink from '@src/assets/icons/icon-link.png';
import IconUpload from '@src/assets/icons/icon-upload.png';

// Import icon for file upload
import IconFileWord from '@src/assets/icons/icon-file-word.png';
import IconFileText from '@src/assets/icons/icon-file-text.png';
import IconFileImage from '@src/assets/icons/icon-file-image.png';

// Import icon for web sample crawler
import IconCafeF from '@src/assets/icons/icon-cafe-f.png';
import IconExpress from '@src/assets/icons/icon-express.png';
import IconDanTri from '@src/assets/icons/icon-dan-tri.png';
import IconKenh14 from '@src/assets/icons/icon-kenh-14.png';
import IconBaoMoi from '@src/assets/icons/icon-bao-moi.png';
import IconZing from '@src/assets/icons/icon-zing.png';

// Maximum file size is 15MB
export const MEGA_BYTE = 15 * 1024 * 1024;

export const TYPE_UPLOAD = {
  LINK: 'link',
  FILE: 'file',
};

export const OPTION_UPLOAD_FILE = [
  {
    icon: IconFileWord,
    type: ['doc', 'docx'],
    isActive: true,
    alt: 'file-word',
  },
  {
    icon: IconFileText,
    type: ['txt'],
    isActive: true,
    alt: 'file-text',
  },
  {
    icon: IconFileImage,
    type: ['jpg', 'png'],
    isActive: false,
    alt: 'file-image',
  },
];

export const OPTIONS_UPLOAD = [
  {
    icon: IconLink,
    label: 'webLink',
    type: TYPE_UPLOAD.LINK,
    isActive: true,
    formatFile: null,
  },
  {
    icon: IconUpload,
    label: 'uploadDocuments',
    type: TYPE_UPLOAD.FILE,
    isActive: false,
    formatFile: null,
  },
  {
    icon: IconFileWord,
    label: 'wordFile',
    type: TYPE_UPLOAD.FILE,
    isActive: true,
    formatFile: '.docx',
  },
  {
    icon: IconFileText,
    label: 'textFile',
    type: TYPE_UPLOAD.FILE,
    isActive: true,
    formatFile: '.txt',
  },
  {
    icon: IconFileImage,
    label: 'imageFile',
    type: TYPE_UPLOAD.FILE,
    isActive: false,
    formatFile: '.jpg, .png',
  },
];

export const LIST_ICON_WEB_SAMPLE = [
  {
    icon: IconCafeF,
    alt: 'CafeF',
  },
  {
    icon: IconExpress,
    alt: 'Express',
  },
  {
    icon: IconDanTri,
    alt: 'Dan Tri',
  },
  {
    icon: IconKenh14,
    alt: 'Kenh14',
  },
  {
    icon: IconBaoMoi,
    alt: 'Bao Moi',
  },
  {
    icon: IconZing,
    alt: 'Zing',
  },
];
