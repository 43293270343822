import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useGrowthBook } from '@growthbook/growthbook-react';

import { Button, Typography, Box } from '@mui/material';
import { TimerOutlined, CachedRounded, AddRounded } from '@mui/icons-material';
import Tooltip from '@src/components/Tooltip';
import ResizeTextBox from '@src/components/ResizeTextBox';
import ProcessHandler from '@src/components/ProcessHandler';
import RequestStatus from '@src/components/RequestStatus';
import { delay } from '@src/utils/delay';
import { splitParagraphIntoSentence } from '@src/services/sentence';
import { BREAK_TIME_REGEX, REGEX, REQUEST_STATUS } from '@src/constants/voice';

import actions from '@src/redux/actions';
import { TTS_GUIDE, TTS_TOUR_GUIDE } from '@src/constants/tourGuide';
import { MAX_LENGTH_TITLE } from '@src/constants/request';
import {
  TIME_DELAY_TRANSFER_BUTTON,
  LOCAL_STORAGE_EXPIRES_TIME,
  SET_TIME_AUTO_SAVE,
  SILENCE_AUDIO_URL,
} from '@src/constants/tts';
import { PREDICT_SECONDS_PER_CHARACTER, SCREEN_TYPE } from '@src/constants';
import { FEATURE_KEYS } from '@src/configs/featureKeys';

import {
  setDataLocalStorage,
  getDataLocalStorage,
  deleteDataLocalStorage,
} from '@src/utils/localStorage';

import debounce from '@src/utils/debounce';
import { formatAudioTime } from '@src/utils/time';
import { calculateElapsedDateTime } from '@src/utils/date';
import useElementWidth from '@src/hooks/useElementWidth';
import { MAX_TITLE_LENGTH } from '@src/configs';

import i18n from '@src/languages';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import StatusSaving from './StatusSaving';
import ActionButtons from './ActionButton';
import ActionButtonsNew from './ActionButtonNew';
import ActionDownloadButtons from './ActionDownloadButton';

import {
  StyledTitleBar,
  StyledCreateNewEditor,
  StyledPopover,
} from './index.style';

const SAVE_TITLE_WIDTH = 100;
const TITLE_MARGIN_RIGHT = 20;

const TitleBar = ({
  loading,
  onCreateSynthesis,
  characterExceed,
  inputLength = 0,
  isLoadDraft,
  onLoadEditContent,
  onChangeInputLength,
  onOpenConfirmDialog,
  content,
  enableEditor,
  onCreateNewEditor,
  audioRef,
  onChangeRequestLoading,
  onEnableEditor,
  setCloseMoreMenu,
}) => {
  const {
    synthesisRequest,
    openSentences,
    voice,
    advanceFeature,
    sentences,
    paragraphs,
    defaultSample,
  } = useSelector((state) => state.synthesisRequest);
  const { audioLink: audioPlayerLink } = useSelector(
    (state) => state.audioPlayer,
  );
  const { ttsUser } = useSelector((state) => state.user);
  const user = useSelector((state) => state.auth.user);
  const hasConvertTts = !loading;
  const totalCharacters = ttsUser?.maxLengthInputText || 0;
  const estimateAudioLength = formatAudioTime(
    inputLength * PREDICT_SECONDS_PER_CHARACTER,
  );

  const requestInfoRef = useRef();
  const requestInfoWidth = useElementWidth(requestInfoRef);
  const titleRef = useRef();
  const titleWidth = useElementWidth(titleRef);
  const { getFeatureValue } = useFeatureFlags();

  const growthbook = useGrowthBook();
  const useOldSaveDraftFeatureFlag = growthbook.getFeatureValue(
    FEATURE_KEYS.OLD_AUTO_SAVE_DRAFT,
  );

  const isNewDownloadButton = getFeatureValue(
    FEATURE_KEYS.NEW_DOWNLOAD_BUTTON,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const useSampleScripts = getFeatureValue(FEATURE_KEYS.SAMPLE_SCRIPTS, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const routerPath = useLocation();
  const [isOpenDialog, setIsOpenDialog] = useState(true);

  const { t } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();

  const [isShowUp, setIsShowUp] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isClickTransfer, setIsClickTransfer] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [mobileCreateButtonAnchorEl, setMobileCreateButtonAnchorEl] =
    useState(null);

  const checkExpiredDate = (expiryDate) => {
    if (!expiryDate) return false;
    return moment().isAfter(expiryDate);
  };

  const isExpiredPackage = checkExpiredDate(user.packageExpiryDate);

  const handleOpenMobileCreateButton = (e) =>
    setMobileCreateButtonAnchorEl(e.target);
  const handleCloseMobileCreateButton = () =>
    setMobileCreateButtonAnchorEl(null);

  const handleChangeShowUp = () => {
    if (!isSaving) setIsShowUp(false);
  };

  const handleCreateNewEditor = (isNew = true) => {
    // true when refresh all and create new,false when clone data
    onCreateNewEditor(isNew);
    handleCloseMobileCreateButton();
  };

  const handleValidateTTSTitle = (value) => {
    if (value.length <= MAX_LENGTH_TITLE) return true;
    dispatch(
      actions.noti.push({
        severity: 'warning',
        message: 'updateTitleMaxLength',
        value: MAX_LENGTH_TITLE,
      }),
    );
    return false;
  };

  const handleChangePlayAudio = (audioLink) => {
    if (audioPlayerLink === audioLink) {
      dispatch(actions.audioPlayer.updateStatus(true));
    } else {
      // eslint-disable-next-line no-param-reassign
      audioRef.current.src = SILENCE_AUDIO_URL;
      audioRef.current.load();
      audioRef.current.play();
      dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      dispatch(actions.audioPlayer.updateShowAudioPlayer(true));
    }
  };

  const handleCheckIsSampleScript = () => {
    if (!useSampleScripts) return; // Check if feature is disabled

    const { text: sampleText, audios } = defaultSample;
    const defaultSampleData = audios?.[0] || {};
    const {
      audioType: sampleAudioType,
      bitrate: sampleBitrate,
      sampleRate: sampleSampleRate,
      voiceCode: sampleVoiceCode,
      audioLink: sampleAudioLink,
      speed: sampleSpeed,
    } = defaultSampleData;

    const { audioType, bitrate, sampleRate, text, speed } = synthesisRequest;

    const isDifferent = (value, sampleValue) =>
      value !== undefined && value !== sampleValue;

    if (
      isDifferent(text, sampleText) ||
      isDifferent(audioType, sampleAudioType) ||
      isDifferent(bitrate, sampleBitrate) ||
      isDifferent(sampleRate, sampleSampleRate) ||
      isDifferent(voice?.code, sampleVoiceCode) ||
      isDifferent(speed, sampleSpeed)
    )
      return;

    const dataSynthesis = {
      text: sampleText,
      title: synthesisRequest.title,
      audioLink: sampleAudioLink,
      voice,
    };

    handleChangePlayAudio(sampleAudioLink);
    dispatch(actions.synthesisRequest.updateSynthesisConfig(dataSynthesis));
    dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
    dispatch(actions.audioPlayer.updatePreviewRequestInfo(dataSynthesis));
    dispatch(actions.synthesisRequest.updateShowSampleScripts(false));
    onChangeInputLength(sampleText.length);
    setIsDefault(false);
  };

  const checkDefaultState = () => {
    if (!useSampleScripts) return; // Check if feature is disabled

    const { text: sampleText, audios } = defaultSample;
    const defaultSampleData = audios?.[0] || {};
    const {
      audioType: sampleAudioType,
      bitrate: sampleBitrate,
      sampleRate: sampleSampleRate,
      voiceCode: sampleVoiceCode,
      speed: sampleSpeed,
    } = defaultSampleData;

    const { audioType, bitrate, sampleRate, text, speed } = synthesisRequest;

    const isDifferent = (value, sampleValue) =>
      value !== undefined && value !== sampleValue;

    if (
      inputLength !== 0 ||
      isDifferent(text, sampleText) ||
      isDifferent(audioType, sampleAudioType) ||
      isDifferent(bitrate, sampleBitrate) ||
      isDifferent(sampleRate, sampleSampleRate) ||
      isDifferent(voice?.code, sampleVoiceCode) ||
      isDifferent(speed, sampleSpeed)
    ) {
      setIsDefault(false);
    } else setIsDefault(true);
  };
  useEffect(() => {
    checkDefaultState();
  }, [inputLength, defaultSample, synthesisRequest, voice]);

  const updateRequestStatus = () => {
    if (!enableEditor) {
      onEnableEditor(true);
      dispatch(actions.synthesisRequest.cloneSynthesisRequest());
    }
  };

  const handleChangeTTSTitle = async ({ newTitle }) => {
    if (!synthesisRequest.id) {
      dispatch(
        actions.synthesisRequest.updateSynthesisRequest('title', newTitle),
      );
      return;
    }
    try {
      if (newTitle === synthesisRequest.title) return;

      if (newTitle.length > MAX_LENGTH_TITLE) {
        dispatch(
          actions.noti.push({
            severity: 'warning',
            message: 'updateTitleMaxLength',
            value: MAX_LENGTH_TITLE,
          }),
        );
        return;
      }
      // await apis.requests.updateRequest(synthesisRequest.id, {
      //   title: newTitle,
      // });
      if (!enableEditor) updateRequestStatus();
      dispatch(
        actions.synthesisRequest.updateSynthesisRequest('title', newTitle),
      );
      // dispatch(
      //   actions.noti.push({
      //     severity: 'success',
      //     message: 'updateSuccess',
      //   }),
      // );
      onChangeRequestLoading(true);
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: error?.response?.data?.error_message || 'updateFailure',
        }),
      );
    }
  };

  const handleClickTransfer = async () => {
    if (!isClickTransfer) {
      await onCreateSynthesis(useSampleScripts && isDefault);
      handleCheckIsSampleScript();
      setIsClickTransfer(true);
      await delay(TIME_DELAY_TRANSFER_BUTTON);
      setIsClickTransfer(false);
    }
  };

  const handleSaveDraftOld = () => {
    if (isOpenDialog && !inputLength) return;
    let sentencesSave;
    if (advanceFeature) sentencesSave = paragraphs;

    if (!advanceFeature) {
      if (openSentences) {
        sentencesSave = sentences;
      } else {
        sentencesSave = splitParagraphIntoSentence(
          content.getCurrentContent().getPlainText('\n'),
          voice,
        );
      }
    }
    const dataDraft = {
      advanceFeature,
      openSentences,
      inputLength,
      synthesisRequest,
      sentencesSave,
      voice,
      enableEditor,
    };
    setDataLocalStorage('dataDraft', dataDraft, LOCAL_STORAGE_EXPIRES_TIME);
    setIsSaving(false);
  };

  const processContentDraft = () => {
    if (inputLength) handleSaveDraftOld();

    if (!isOpenDialog && !inputLength) {
      setIsSaving(false);
      deleteDataLocalStorage('dataDraft');
    }
  };

  const handleLoadDraft = () => {
    const data = getDataLocalStorage('dataDraft');
    if (!data) return;

    // Switch opensentences types
    dispatch(actions.synthesisRequest.updateOpenSentences(data.openSentences));

    // Load Voice Type
    dispatch(actions.synthesisRequest.updateVoice(data.voice));

    // Load inputLengh
    onChangeInputLength(data.inputLength);
    // Load setting of voice type
    const synthesisRequestSave = data.synthesisRequest;
    Object.keys(synthesisRequestSave).map((key) =>
      dispatch(
        actions.synthesisRequest.updateSynthesisRequest(
          key,
          synthesisRequestSave[key],
        ),
      ),
    );
    // Load content  edit save
    onLoadEditContent(
      data.sentencesSave,
      data.advanceFeature,
      data.openSentences,
      data.enableEditor,
    );
  };

  const handleSaveDraft = () => {
    const configSave = {
      ...synthesisRequest,
      paragraphs: [],
      sentences: [],
      voice,
      characters: inputLength,
      characterExceed,
      enableEditor,
    };

    if (advanceFeature) configSave.paragraphs = paragraphs;

    if (!advanceFeature) {
      if (openSentences) {
        configSave.sentences = sentences;
      } else {
        configSave.text = content.getCurrentContent().getPlainText('\n');
      }
    }

    setDataLocalStorage(
      `dataDraft-${user.id}`,
      configSave,
      LOCAL_STORAGE_EXPIRES_TIME,
    );
    setIsSaving(false);
  };

  const getTitle = (plainText, voiceLanguage) => {
    const SPACE = ' ';
    const CHINESE_CHARACTERS_LANGUAGE_CODES = ['cmn-CN', 'ja-JP', 'ko-KR'];

    if (plainText.length <= MAX_LENGTH_TITLE) {
      return plainText;
    }

    const lastSpaceIndex = plainText.lastIndexOf(SPACE, MAX_TITLE_LENGTH - 1);
    const firstSpaceIndex = plainText.indexOf(SPACE);

    if (
      firstSpaceIndex !== -1 &&
      firstSpaceIndex <= MAX_TITLE_LENGTH - 1 &&
      !CHINESE_CHARACTERS_LANGUAGE_CODES.includes(voiceLanguage)
    )
      return plainText.substring(0, lastSpaceIndex + 1);

    return plainText.substring(0, MAX_TITLE_LENGTH);
  };

  const handleClickTitle = () => {
    if (synthesisRequest.title) return;

    let sentencesSave;
    if (advanceFeature) sentencesSave = paragraphs;
    if (!advanceFeature) {
      if (openSentences) {
        sentencesSave = sentences;
      } else {
        sentencesSave = splitParagraphIntoSentence(
          content.getCurrentContent().getPlainText('\n'),
          voice,
        );
      }
    }

    const text = sentencesSave.reduce(
      (acc, sentence) => acc + sentence.text,
      ' ',
    );

    const plainText = text
      .replace(REGEX.ADVANCE_TAG, '')
      .replace(BREAK_TIME_REGEX, ' ')
      .trim();
    if (plainText) {
      const newTitle = getTitle(plainText, voice.languageCode);
      handleChangeTTSTitle({ newTitle });
    }
  };

  useEffect(() => {
    if (useOldSaveDraftFeatureFlag) {
      if (inputLength) {
        setIsOpenDialog(false);
        setIsSaving(true);
        setIsShowUp(true);
      }
      debounce(processContentDraft, SET_TIME_AUTO_SAVE)();
      return;
    }
    if (inputLength) {
      setIsSaving(true);
      setIsShowUp(true);
      debounce(handleSaveDraft, SET_TIME_AUTO_SAVE)();
      return;
    }
    setIsShowUp(false);
  }, [
    advanceFeature,
    openSentences,
    inputLength,
    sentences,
    voice,
    synthesisRequest,
    paragraphs,
  ]);

  useEffect(() => {
    if (useOldSaveDraftFeatureFlag) {
      const data = getDataLocalStorage('dataDraft');
      const doneTourGuide = localStorage.getItem(TTS_TOUR_GUIDE);
      if (data && user.hasAgreedToTerms && doneTourGuide) {
        onOpenConfirmDialog();
      }
      setIsOpenDialog(true);
    }

    return () => {
      setIsOpenDialog(true);
    };
  }, [routerPath, user.hasAgreedToTerms]);

  useEffect(() => {
    if (isLoadDraft && useOldSaveDraftFeatureFlag) handleLoadDraft();
  }, [isLoadDraft]);

  useEffect(() => {
    if (isSaving) {
      setIsShowUp(true);
    } else {
      debounce(handleChangeShowUp, 1000)();
    }
  }, [isSaving]);

  const renderSynthesisRequestDateTime = () => {
    if (synthesisRequest?.createdAt && !enableEditor) {
      return calculateElapsedDateTime(synthesisRequest?.createdAt, t);
    }
    return <div className="empty-title-footer" />;
  };

  const renderMobileTitleBar = () => (
    <StyledTitleBar className="mobile-title-bar">
      <div className="base-input">
        <ResizeTextBox
          value={synthesisRequest.title || ''}
          placeholder={t('noTitle')}
          onValidate={handleValidateTTSTitle}
          onChange={handleChangeTTSTitle}
          onClick={handleClickTitle}
          maxWidth={100}
          maxWidthxl={350}
        />
        {/* {!isShowUp && !synthesisRequest?.status && ( */}
        <StatusSaving savingStatus={isSaving} />
        {/* )} */}
      </div>
      <Box display="flex" gap="5px" alignItems="center">
        <RequestStatus
          request={synthesisRequest}
          screenType={SCREEN_TYPE.MOBILE}
        />
        <ActionButtons
          request={synthesisRequest}
          audioRef={audioRef}
          setCloseMoreMenu={setCloseMoreMenu}
        />
        {enableEditor ? (
          <Button
            id={TTS_GUIDE.CONVERT}
            color="primary"
            variant="contained"
            disabled={!hasConvertTts}
            onClick={handleClickTransfer}
            className="icon-button"
          >
            <CachedRounded />
          </Button>
        ) : (
          <Button
            id={TTS_GUIDE.CONVERT}
            color="primary"
            variant="contained"
            disabled={!hasConvertTts || isClickTransfer}
            onClick={handleOpenMobileCreateButton}
            className="icon-button"
          >
            <AddRounded />
          </Button>
        )}
      </Box>
      <StyledPopover
        open={Boolean(mobileCreateButtonAnchorEl)}
        anchorEl={mobileCreateButtonAnchorEl}
        onClose={handleCloseMobileCreateButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'right',
        }}
      >
        <StyledCreateNewEditor onClick={() => handleCreateNewEditor(true)}>
          <Typography className="text-action">{t('createNew')}</Typography>
        </StyledCreateNewEditor>
        <StyledCreateNewEditor onClick={() => handleCreateNewEditor(false)}>
          <Typography className="text-action">
            {t('copyAndCreateNew')}
          </Typography>
        </StyledCreateNewEditor>
      </StyledPopover>
    </StyledTitleBar>
  );

  return (
    <>
      <StyledTitleBar className="web-title-bar" ref={titleRef}>
        <div className="base-input">
          <ResizeTextBox
            value={synthesisRequest.title || ''}
            placeholder={t('noTitle')}
            onValidate={handleValidateTTSTitle}
            onClick={handleClickTitle}
            onChange={handleChangeTTSTitle}
            maxWidth={
              isShowUp
                ? titleWidth - requestInfoWidth - SAVE_TITLE_WIDTH
                : titleWidth - requestInfoWidth - TITLE_MARGIN_RIGHT
            }
            maxWidthxl={350}
            footerLabel={renderSynthesisRequestDateTime()}
          />
          {isShowUp && !synthesisRequest?.status && (
            <StatusSaving savingStatus={isSaving} />
          )}
        </div>
        <div className="request-info" ref={requestInfoRef}>
          {!isExpiredPackage && (
            <Tooltip arrow title={t('charactersInUse')} placement="left">
              <Typography variant="body2" className="character-default">
                <span
                  className={classNames('', {
                    'character-red': characterExceed,
                    'character-in-use': !synthesisRequest?.status,
                  })}
                  translate="no"
                >
                  {inputLength?.toLocaleString(language) || 0}
                </span>
                {synthesisRequest?.status && !isOpenDialog ? (
                  <span className="style-character" translate="no">
                    {t('characters')}
                  </span>
                ) : (
                  <span translate="no">
                    {' '}
                    {`/ ${totalCharacters?.toLocaleString(language) || 0}`}
                  </span>
                )}
              </Typography>
            </Tooltip>
          )}
          {!synthesisRequest?.status ||
          synthesisRequest.status === REQUEST_STATUS.IN_PROGRESS ||
          (isOpenDialog && estimateAudioLength) ? (
            <Tooltip arrow title={t('estimatedAudioLength')} placement="top">
              <Typography variant="body2" className="estimate-audio-length">
                <TimerOutlined sx={{ fontSize: 16 }} color="iconPrimary" />
                <span translate="no"> {estimateAudioLength}</span>
              </Typography>
            </Tooltip>
          ) : (
            <div style={{ marginRight: 10 }} />
          )}
          {!isOpenDialog && (
            <RequestStatus request={synthesisRequest} marginLeft="8px" />
          )}
          {!isNewDownloadButton && !isOpenDialog && (
            <ActionButtons
              request={synthesisRequest}
              audioRef={audioRef}
              setCloseMoreMenu={setCloseMoreMenu}
            />
          )}
          {isNewDownloadButton && !isOpenDialog && (
            <>
              <ActionButtonsNew
                request={synthesisRequest}
                audioRef={audioRef}
                setCloseMoreMenu={setCloseMoreMenu}
              />
              <ActionDownloadButtons
                request={synthesisRequest}
                audioRef={audioRef}
              />
            </>
          )}
          <Button
            id={TTS_GUIDE.CONVERT}
            color="primary"
            variant="contained"
            disabled={!hasConvertTts || isClickTransfer}
            onClick={handleClickTransfer}
            className="text-transfer-button"
          >
            <ProcessHandler
              loading={loading}
              ml="46px"
              mr="46px"
              size={24}
              align="center"
              color="divider"
            >
              {useSampleScripts && isDefault
                ? t('playAudio')
                : t('textTransfer')}
            </ProcessHandler>
          </Button>
        </div>
      </StyledTitleBar>
      {renderMobileTitleBar()}
    </>
  );
};

export default TitleBar;
