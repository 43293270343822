import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, Tooltip } from '@mui/material';
import {
  Forward5Rounded,
  PauseOutlined,
  PlayArrowRounded,
  Replay5Rounded,
} from '@mui/icons-material';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import actions from '@src/redux/actions';
import { StyledAudioActions } from './index.style';

const REWIND_TIME_IN_SECOND = 5;

const AudioActions = ({
  timelineRef,
  setIsChangeTimeByClick,
  translatedAudioRef,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isPlaying, duration, audioLink } = useSelector(
    (state) => state.audioPlayer,
  );

  const { isSelectListenOriginalAudio } = useSelector(
    (state) => state.dubbingRequest,
  );

  const handleLoadedMetadata = () => {
    const audioDuration = translatedAudioRef.current?.duration;
    dispatch(actions.audioPlayer.updateMetaData({ duration: audioDuration }));
  };

  const handleAudioTimeUpdate = () => {
    const url = translatedAudioRef.current?.src
      ? new URL(translatedAudioRef.current?.src)
      : '';
    const time =
      url?.pathname === SILENCE_AUDIO_URL
        ? 0
        : translatedAudioRef.current.currentTime;
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
  };

  const handleSeeked = (time) => {
    // eslint-disable-next-line no-param-reassign
    if (translatedAudioRef) translatedAudioRef.current.currentTime = time;
    timelineRef.current.setTime(time);
    dispatch(actions.audioPlayer.updateMetaData({ currentTime: time }));
    setIsChangeTimeByClick(true);
  };

  const handleChangePlayAudio = () => {
    const isCurrentlyPlaying = !timelineRef.current?.isPaused;

    if (isCurrentlyPlaying) {
      if (!isSelectListenOriginalAudio && translatedAudioRef)
        translatedAudioRef.current.pause();
      timelineRef.current.pause();
    } else {
      if (!isSelectListenOriginalAudio && translatedAudioRef)
        translatedAudioRef.current.play();
      timelineRef.current.play();
    }

    dispatch(actions.audioPlayer.updateStatus(!isPlaying));
  };

  return (
    <StyledAudioActions>
      <Tooltip
        arrow
        title={t('rewind', { second: REWIND_TIME_IN_SECOND })}
        placement="top"
      >
        <div>
          <IconButton
            onClick={() => {
              const time =
                timelineRef.current?.getTime() - REWIND_TIME_IN_SECOND;
              handleSeeked(Math.max(time, 0));
            }}
            disabled={false}
            className="rewind-button"
            color="iconPrimary"
          >
            <Replay5Rounded sx={{ fontSize: 26 }} />
          </IconButton>
        </div>
      </Tooltip>
      <Button
        onClick={handleChangePlayAudio}
        disabled={false}
        className="audio-action-button"
        color="buttonPrimary"
        variant="contained"
      >
        {isPlaying && (
          <Tooltip arrow title={t('pause')} placement="top">
            <PauseOutlined sx={{ fontSize: 26 }} color="white" />
          </Tooltip>
        )}
        {!isPlaying && (
          <Tooltip arrow title={t('playAudioGuide')} placement="top">
            <PlayArrowRounded sx={{ fontSize: 26 }} color="white" />
          </Tooltip>
        )}
      </Button>
      <Tooltip
        arrow
        title={t('forward', { second: REWIND_TIME_IN_SECOND })}
        placement="top"
      >
        <div>
          <IconButton
            onClick={() => {
              const timelineTime =
                timelineRef.current?.getTime() + REWIND_TIME_IN_SECOND;

              const time = audioLink
                ? Math.min(timelineTime, duration)
                : timelineTime;

              handleSeeked(time);
            }}
            disabled={false}
            className="rewind-button"
            color="iconPrimary"
          >
            <Forward5Rounded sx={{ fontSize: 26 }} />
          </IconButton>
        </div>
      </Tooltip>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={translatedAudioRef}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleAudioTimeUpdate}
      >
        <source src={audioLink} type="audio/ogg" />
      </audio>
    </StyledAudioActions>
  );
};

export default AudioActions;
