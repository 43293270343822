import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import actions from '@src/redux/actions';
import { PACKAGE_LEVEL, PACKAGE_TYPE } from '@src/constants/package';
import apis from '@src/apis';
import { MOBILE_BREAKPOINT, PAGINATION_LIMIT } from '@src/constants';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FETCH_REQUESTS_INTERVAL } from '@src/constants/websocket';
import { VBEE_DOMAIN } from '@src/configs';
import { TIME_STORE_USER_GUIDE_DUBBING } from '@src/constants/device';
import { getCookie, setCookie } from '@src/utils/cookie';
import { countSubtitleCharacters } from '@src/utils/srt';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import Card from '@src/components/Card';
import route from '@src/constants/route';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';

import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import UpgradeImg from '@src/assets/images/space-shuttle.png';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import { StyledDubbing, StyledDubbingList } from './index.style';
import TitleBar from './TitleBar';
import UpgradePrompt from './UpgradePrompt';
import ToolBar from './ToolBar';
import ListDubbing from './ListDubbing';
import UploadSubtitleFile from './UploadSubtitleFile';
import UserGuide from './UserGuide';
import DubbingSentences from './DubbingSentences';
import ListDubbingNew from './ListDubbingNew';
import NotiOldVersion from './NotiOldVersion';
import NewDubbingOnMobile from './NewDubbingOnMobile';

const Dubbing = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasVisitedDubbing = getCookie('hasVisitedDubbing');

  const [isMobileDubbing, setIsMobileDubbing] = useState(false);
  const [openUserGuide, setOpenUserGuide] = useState(hasVisitedDubbing);
  const [totalCharacters, setTotalCharacters] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  const [dubbingRequests, setDubbingRequests] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('createdAt_desc');
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [fileSelectedForDubbing, setFileSelectedForDubbing] = useState(null);
  const [actionDialog, setActionDialog] = useState();
  const isDubbingRoute = useCheckDubbingRoute();

  const { user } = useSelector((state) => state.auth);
  const { sentences } = useSelector((state) => state.dubbingRequest);
  const responseRequest = useSelector((state) => state.request);
  const history = useHistory();
  const { getFeatureValue } = useFeatureFlags();
  const { limitedFeature } = useSelector((state) => state.dialog);
  const { latestDubbingOrder } = useSelector((state) => state.user);

  const isHideOldVersionNoti = getCookie(`isHideOldVersionNoti_${user.id}`);
  const [showNotiOldVersion, setShowNotiOldVersion] = useState(
    !isHideOldVersionNoti,
  );

  const handleCloseNoti = () => {
    setCookie({
      cname: `isHideOldVersionNoti_${user.id}`,
      cvalue: true,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_USER_GUIDE_DUBBING,
    });
    setShowNotiOldVersion(false);
  };

  const handleCloseActionDialog = () => setActionDialog();

  const handleCloseLimitedFeatureDialog = () => {
    dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
    handleCloseActionDialog();
  };

  const handleUpgradeNow = () => history.push(route.PAYMENT_DUBBING);

  const renderUpgradeButton = () => (
    <Button variant="contained" onClick={handleUpgradeNow}>
      {t('upgradeNow')}
    </Button>
  );
  const useNewNaviagtionDubbingOnMobile = getFeatureValue(
    FEATURE_KEYS.NEW_NAVIGATION_DUBBING,
    {
      useId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const showNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let showNewListDubbing = getFeatureValue(FEATURE_KEYS.NEW_EDITOR);
  showNewListDubbing = showNewListDubbing && showNewTtsUI;

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isGiveDubbingPackageGift = getFeatureValue(
    FEATURE_KEYS.DUBBING_PACKAGE_GIFT,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const newDubbingUi = getFeatureValue(FEATURE_KEYS.NEW_DUBBING_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const dubbingRequestsRef = useRef(dubbingRequests);
  const uploadComponentRef = useRef(null);

  const canUseDubbing =
    (user?.packageCode?.includes(PACKAGE_TYPE.STUDIO) ||
      user?.packageCode?.includes(PACKAGE_TYPE.DUBBING)) &&
    !(user?.packageCode === PACKAGE_LEVEL.BASIC);

  const handleCheckMobileOnDubbing = () => {
    const checkMobileOnDubbing = window.innerWidth <= MOBILE_BREAKPOINT;
    setIsMobileDubbing(checkMobileOnDubbing);
  };
  const handleResetFile = () => uploadComponentRef.current?.click();

  const handleChangeRequestLoading = (value) => setRequestLoading(value);

  const handleCloseUserGuide = () => {
    setCookie({
      cname: 'hasVisitedDubbing',
      cvalue: true,
      domain: VBEE_DOMAIN,
      extime: TIME_STORE_USER_GUIDE_DUBBING,
    });
    setOpenUserGuide(true);
  };

  const handleChangePage = (newPage) => setPage(newPage);

  const handleChangeSort = (newSort) => {
    setSort(newSort);
    handleChangePage(1);
  };

  const handleChangeLoadingRequest = (isLoadingRequests) =>
    setLoadingRequests(isLoadingRequests);

  const handleChangeTotalSeconds = (seconds) => setTotalSeconds(seconds);

  const createTrialDubbingOrder = async () => {
    if (latestDubbingOrder?.id) return;
    await apis.orders.createTrialDubbingOrder();
  };

  const fetchProgressRequest = async (requestId) => {
    const data = await apis.requests.getProgressRequest(requestId);
    if (data && data.status) {
      const newRequests = dubbingRequestsRef.current.map((request) => {
        if (request.id === requestId) {
          const progress =
            request.progress <= data?.result?.progress
              ? data?.result?.progress
              : request.progress;
          const { status, audioLink } = data.result;
          return {
            ...request,
            progress,
            status,
            audioLink,
          };
        }
        return request;
      });
      setDubbingRequests(newRequests);
    }
  };

  const updateInprogressRequest = (requestIds) => {
    Promise.all(
      requestIds.map(async (requestId) => {
        await fetchProgressRequest(requestId);
        return null;
      }),
    );
  };

  const fetchDubbingRequests = async (hasLoading) => {
    if (hasLoading) setIsLoading(true);
    const response = await apis.dubbing.getDubbingRequests({
      offset: (page - 1) * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
      sort,
      fields:
        'id,title,characters,seconds,createdAt,progress,status,voice,audioType,audioLink,retentionPeriod,processingAt',
    });
    setIsLoading(false);

    if (response?.status) {
      const newRequests = response.result.requests.map((request) => {
        const req = dubbingRequestsRef.current.find((r) => r.id === request.id);
        if (req) {
          return {
            ...request,
            progress:
              req?.progress && request.progress < req?.progress
                ? req.progress
                : request.progress,
          };
        }
        return request;
      });
      setDubbingRequests(newRequests);
      setTotalRequests(response.result.metadata.total);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      handleCheckMobileOnDubbing();
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dubbingRequestsRef.current = dubbingRequests;
  }, [dubbingRequests]);

  useEffect(() => {
    fetchDubbingRequests(true);
  }, [page]);

  useEffect(() => {
    if (loadingRequests) {
      fetchDubbingRequests(false);
      setLoadingRequests(false);
    }
  }, [loadingRequests]);

  useEffect(() => {
    const isOldDubbingRoute = window.location.pathname === route.DUBBING;
    if (isProcessDubbingByUnitSecond && isOldDubbingRoute)
      history.push(route.HOME_DUBBING);
  }, [isProcessDubbingByUnitSecond]);

  useEffect(() => {
    if (!showNewListDubbing) {
      const fetchRequestsInterval = setInterval(async () => {
        const inProgressRequest = dubbingRequestsRef.current
          .filter((request) => request.status === REQUEST_STATUS.IN_PROGRESS)
          .map((request) => request.id);
        if (inProgressRequest?.length)
          updateInprogressRequest(inProgressRequest);
      }, FETCH_REQUESTS_INTERVAL);

      return () => clearInterval(fetchRequestsInterval);
    }
    return null;
  }, []);

  useEffect(
    () => () => {
      dispatch(actions.dubbingRequest.resetDubbingRequest());
    },
    [],
  );

  useEffect(() => {
    const newDubbingRequests = dubbingRequests.map((request) => {
      const isInProgressRequest =
        request.status === REQUEST_STATUS.IN_PROGRESS &&
        responseRequest[request.id];
      if (isInProgressRequest) {
        request.status = responseRequest[request.id].status;
        request.audioLink = responseRequest[request.id].audioLink;
        request.progress = responseRequest[request.id].progress;
      }
      return request;
    });
    setDubbingRequests(newDubbingRequests);
  }, [responseRequest]);

  useEffect(() => {
    const updatedTotalCharacters = countSubtitleCharacters(sentences);
    setTotalCharacters(updatedTotalCharacters);
  }, [Object.keys(sentences)]);

  useEffect(() => {
    if (isDubbingRoute && isGiveDubbingPackageGift) createTrialDubbingOrder();
  }, [isDubbingRoute, latestDubbingOrder, isGiveDubbingPackageGift]);

  useEffect(() => {
    if (limitedFeature) {
      setActionDialog({
        image: UpgradeImg,
        title: t('limitedFeature'),
        description: t('limitedFeatureDescription'),
        onClose: handleCloseLimitedFeatureDialog,
        actionComponents: renderUpgradeButton(),
      });
      dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
    }
  }, [limitedFeature]);

  // Show dialog block download cause user already download
  const { showBlockDownloadDialog } = useSelector((state) => state.user);
  useEffect(() => {
    if (showBlockDownloadDialog) {
      setActionDialog({
        image: UpgradeImg,
        title: t('unableToDownload'),
        description: t('unableToDownloadDescription', { numberDownload: 1 }),
        onClose: () => {
          dispatch(actions.user.updateShowBlockDownloadDialog(false));
          handleCloseActionDialog();
        },
        actionComponents: renderUpgradeButton(),
      });
    }
  }, [showBlockDownloadDialog]);

  return canUseDubbing ? (
    <>
      {useNewNaviagtionDubbingOnMobile && isMobileDubbing ? (
        <NewDubbingOnMobile />
      ) : (
        <StyledDubbingList>
          {newDubbingUi && showNotiOldVersion && (
            <NotiOldVersion handleCloseNoti={handleCloseNoti} />
          )}
          {!openUserGuide && !newDubbingUi && (
            <UserGuide onCloseUserGuide={handleCloseUserGuide} />
          )}
          {!newDubbingUi && (
            <StyledDubbing>
              <TitleBar
                newUI={showNewListDubbing}
                totalCharacters={totalCharacters}
                totalSeconds={totalSeconds}
                onChangeLoadingRequest={handleChangeLoadingRequest}
                isUploadingFile={isUploadingFile}
                onResetFile={handleResetFile}
                onChangeRequestLoading={handleChangeRequestLoading}
                fileSelectedForDubbing={fileSelectedForDubbing}
              />
              <ToolBar />
              <UploadSubtitleFile
                onChangeUploadingStatus={setIsUploadingFile}
                resetRef={uploadComponentRef}
                totalCharacters={totalCharacters}
                totalSeconds={totalSeconds}
                onChangeTotalSeconds={handleChangeTotalSeconds}
                onResetFile={handleResetFile}
                setFileSelectedForDubbing={setFileSelectedForDubbing}
              />
              {totalCharacters ? (
                <DubbingSentences onResetFile={handleResetFile} />
              ) : null}
            </StyledDubbing>
          )}
          {(totalRequests && showNewListDubbing) || newDubbingUi ? (
            <div className="request" id="requests">
              <Card
                flexDirection="column"
                margin-top="4px"
                padding="4px 16px 0px 16px"
              >
                <ListDubbingNew
                  showNotiOldVersion={showNotiOldVersion}
                  requests={dubbingRequests}
                  loading={isLoading}
                  total={totalRequests}
                  sort={sort}
                  page={page}
                  requestLoading={requestLoading}
                  onChangePage={handleChangePage}
                  onChangeSort={handleChangeSort}
                  onChangeRequestLoading={handleChangeRequestLoading}
                />
              </Card>
            </div>
          ) : null}
          {totalRequests && !showNewListDubbing ? (
            <ListDubbing
              data={dubbingRequests}
              loading={isLoading}
              total={totalRequests}
              sort={sort}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeSort={handleChangeSort}
            />
          ) : null}
          <ActionDialog
            image={actionDialog?.image}
            open={!!actionDialog?.title}
            title={actionDialog?.title}
            description={actionDialog?.description}
            onClose={actionDialog?.onClose}
            actionComponents={actionDialog?.actionComponents}
          />
        </StyledDubbingList>
      )}
    </>
  ) : (
    <>
      {useNewNaviagtionDubbingOnMobile && isMobileDubbing ? (
        <NewDubbingOnMobile />
      ) : (
        <UpgradePrompt />
      )}
    </>
  );
};

export default Dubbing;
