import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@src/redux/actions';
import { Button, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import BadgeAvatar from '@src/components/BadgeAvatar';
import VoicesDialog from '@src/components/VoicesDialog';
import { PACKAGE_TYPE } from '@src/constants/package';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { StyledMenuVoice } from './index.style';

const MenuVoice = () => {
  const dispatch = useDispatch();
  const { getFeatureValue } = useFeatureFlags();
  const { voice } = useSelector((state) => state.dubbingRequest);
  const { dubbingVoices } = useSelector((state) => state.voice);
  const [openVoices, setOpenVoices] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const newDubbing = getFeatureValue(FEATURE_KEYS.NEW_DUBBING, {
    email: user.email,
    userId: user.id,
  });
  const isDubbingRoute = newDubbing && useCheckDubbingRoute();

  const setDefaultVoice = () => {
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'voice',
        dubbingVoices[0] || {},
      ),
    );
  };

  const handleChangeVoice = (value) => {
    dispatch(actions.dubbingRequest.updateDubbingRequestByKey('voice', value));
  };

  const handleOpenVoices = (value) => setOpenVoices(value);

  const handleCloseVoices = () => setOpenVoices(false);

  useEffect(() => {
    setDefaultVoice();
  }, []);

  return (
    <StyledMenuVoice>
      <Button
        id="buttonMenu"
        className="open-menu-voice"
        onClick={handleOpenVoices}
      >
        <BadgeAvatar
          width={25}
          smallImgWidth={10}
          img={voice?.roundImage}
          smallImg={voice && voice.language && voice.language.roundImage}
          type="image"
        />
        <Typography variant="body2" className="name-voice-select">
          {voice && voice.name}
        </Typography>
        <ExpandMoreRoundedIcon />
      </Button>

      <VoicesDialog
        open={openVoices}
        activeVoiceId={voice?.id}
        hasLanguageFilter={false}
        usingPackageType={isDubbingRoute ? PACKAGE_TYPE.DUBBING : ''}
        onClose={handleCloseVoices}
        onChangeVoice={handleChangeVoice}
        defaultFilter={{
          hasDubbing: true,
        }}
        oldDubbingVoices={!isDubbingRoute}
      />
    </StyledMenuVoice>
  );
};

export default MenuVoice;
