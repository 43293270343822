import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import {
  AddShoppingCartRounded,
  Code,
  ContactSupport,
  // ExpandLess,
  // ExpandMore,
  Launch,
} from '@mui/icons-material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import vbeeGrayIcon from '@src/assets/icons/vbee-icon-gray.svg';
import vbeeWhiteIcon from '@src/assets/icons/vbee-icon-white.svg';
import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import ROUTES from '@src/constants/route';
import { VBEE_CONSOLE_URL } from '@src/configs';
import { COLOR } from '@src/styles/color';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';

import SidebarImgIcon from '../Icon';
import { StyledSidebarNew, StyledSidebarTooltip } from './index.style';
import Logo from './Logo';
import UserInfo from './UserInfo';
import PackageInfo from './PackageInfo';
import VoucherAds from './VoucherAds';

const LargeSidebar = ({
  createLinks,
  sidebarRoute,
  activeRoute,
  ipLocation,
  handleCloseSidebar,
  renderSubMenuSupport,
  isExpiredPackage,
  vouchers,
  showAutoRenewalBanner,
  isShowingApiRedirect,
  useHeaderBanner,
  headerVoucher,
}) => {
  const sidebarBodyRef = useRef(null);
  const isDubbingRoute = useCheckDubbingRoute();
  // let scrollTimeout = null;
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (openSubMenu) {
      // Scroll to bottom when open sub menu
      // Delay to scroll to bottom when open sub menu to wait for the sub menu to be rendered
      setTimeout(() => {
        const sidebarBody = sidebarBodyRef.current;
        sidebarBody.lastElementChild.scrollIntoView({
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [openSubMenu]);

  // const handleScroll = () => {
  //   setShowScrollbar(true);

  //   clearTimeout(scrollTimeout);
  //   scrollTimeout = setTimeout(() => {
  //     setShowScrollbar(false);
  //   }, 2000);
  // };

  const renderSideBarItems = (props) => (
    <div
      ref={sidebarBodyRef}
      onMouseEnter={() => setShowScrollbar(true)}
      onMouseLeave={() => setShowScrollbar(false)}
      className={`sidebar-wrapper ${
        showScrollbar ? 'small-scrollbar' : 'hidden-scrollbar'
      } `}
      // onScroll={handleScroll}
    >
      <List className="list" style={{ paddingBottom: '0px' }}>
        {createLinks(sidebarRoute, props)}
      </List>
      <Box>
        <Box
          className="item-link"
          onClick={() => setOpenSubMenu((prev) => !prev)}
        >
          <ContactSupport className="item-icon" />
          <Box className="item-wrapper">
            <Typography className="item-text">{t('support')}</Typography>
            {openSubMenu ? (
              <KeyboardArrowDownRoundedIcon className="item-icon" />
            ) : (
              <ChevronRightRoundedIcon className="item-icon" />
            )}
          </Box>
        </Box>
      </Box>

      <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
        {renderSubMenuSupport({ props, itemClassName: 'sub-menu-item' })}
      </Collapse>
      <Feature name={FEATURE_FLAG.OTHER_SERVICES}>
        <NavLink
          to={ROUTES.OTHER_SERVICES}
          className={classNames('item-link', {
            'active-link': activeRoute(ROUTES.OTHER_SERVICES),
          })}
          onClick={() => {
            if (props?.mobile) handleCloseSidebar();
          }}
        >
          <SidebarImgIcon
            src={
              activeRoute(ROUTES.OTHER_SERVICES) ? vbeeWhiteIcon : vbeeGrayIcon
            }
            name={t('otherServices')}
          />
        </NavLink>
      </Feature>
      {isShowingApiRedirect ? (
        <a
          href={VBEE_CONSOLE_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="item-link"
          onClick={() => {
            if (props?.mobile) handleCloseSidebar();
          }}
        >
          <Code className="item-icon" />
          <div className="item-wrapper">
            <Typography className="item-text">{t('api')}</Typography>
            <Launch className="item-icon" />
          </div>
        </a>
      ) : null}
    </div>
  );

  return (
    <StyledSidebarNew
      useHeaderBanner={useHeaderBanner}
      headerVoucher={headerVoucher}
    >
      <div className="brand">
        <Logo />
      </div>
      <Divider />
      {renderSideBarItems()}
      <VoucherAds
        isExpiredPackage={isExpiredPackage}
        showAutoRenewalBanner={showAutoRenewalBanner}
        vouchers={vouchers}
      />

      <div className="wallet">
        <Box className="wallet-character">
          <StyledSidebarTooltip title={t('buyPackage')} placement="top">
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                if (isDubbingRoute) history.push(ROUTES.PAYMENT_DUBBING);
                else history.push(ROUTES.PAYMENT);
              }}
              className="icon"
            >
              <AddShoppingCartRounded
                sx={{ color: COLOR.darkBlue, marginLeft: '2px' }}
              />
            </IconButton>
          </StyledSidebarTooltip>
          <PackageInfo isExpiredPackage={isExpiredPackage} />
        </Box>
      </div>
      <Divider />
      <UserInfo
        handleCloseSidebar={handleCloseSidebar}
        ipLocation={ipLocation}
      />
    </StyledSidebarNew>
  );
};

export default LargeSidebar;
