import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  ClearOutlined,
  EditOutlined,
} from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import apis from '@src/apis';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
// import Tooltip from '@src/components/Tooltip';
import { MAX_LENGTH_TITLE } from '@src/constants/request';
import { REQUEST_STATUS } from '@src/constants/voice';
import { calculateElapsedDateTime } from '@src/utils/date';
import { StyledIconButton, StyledTableTitle } from './index.style';

const TitleTable = ({
  requestId,
  title,
  audioLink,
  requestStatus,
  onUpdateRequest,
  createdAt,
}) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();

  const handleOnMouseEnter = () => setIsHover(true);

  const handleOnMouseLeave = () => setIsHover(false);

  const handleClickTitle = (e) => e.stopPropagation();

  const handleClickEdit = (e) => {
    e.stopPropagation();
    setEdit(true);
  };

  const handleChangeTitle = (e) => {
    e.stopPropagation();
    if (e.target.value.length > MAX_LENGTH_TITLE) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );
      return;
    }
    setNewTitle(e.target.value);
  };

  const handleCloseEdit = (e) => {
    if (e) e.stopPropagation();
    setEdit(false);
    setNewTitle(title);
  };

  const updateRequestTitle = async (e) => {
    try {
      e.stopPropagation();
      if (newTitle === title || newTitle.length <= 0) {
        setEdit(false);
        setNewTitle(title);
        return;
      }
      if (newTitle.length > MAX_LENGTH_TITLE) {
        setEdit(false);
        setNewTitle(title);
        dispatch(
          actions.noti.push({
            severity: 'warning',
            message: 'updateTitleMaxLength',
            value: MAX_LENGTH_TITLE,
          }),
        );
        return;
      }
      setIsLoading(true);
      const res = await apis.requests.updateRequest(requestId, {
        title: newTitle,
      });
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'updateSuccess',
        }),
      );
      setIsLoading(false);
      setEdit(false);
      setNewTitle(res?.result?.request?.title);
      onUpdateRequest(requestId, {
        title: res?.result?.request?.title,
        audioLink: res?.result?.request?.audioLink || audioLink,
      });
    } catch (error) {
      setEdit(false);
      setIsLoading(false);
      setNewTitle(title);
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: error?.response?.data?.error_message || 'updateFailure',
        }),
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) handleCloseEdit();
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [title]);

  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  return (
    <StyledTableTitle
      ref={ref}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {edit ? (
        <input
          className="edit-title"
          value={newTitle}
          maxLength={MAX_LENGTH_TITLE + 1}
          onChange={handleChangeTitle}
          onClick={handleClickTitle}
        />
      ) : (
        <>
          <div className="display-title">
            <div className="title">
              {/* <Tooltip arrow title={newTitle} placement="top" maxWidth="unset"> */}
              <div className="title-text">
                <Typography className="text">{newTitle}</Typography>
              </div>
              {/* </Tooltip> */}
              {isHover && requestStatus !== REQUEST_STATUS.IN_PROGRESS && (
                <IconButton
                  className="edit-button"
                  onClick={handleClickEdit}
                  aria-label="delete"
                  type="button"
                  size="small"
                >
                  <EditOutlined sx={{ fontSize: 20 }} />
                </IconButton>
              )}
            </div>

            <div className="title-time">
              {calculateElapsedDateTime(createdAt, t)}
            </div>
          </div>
        </>
      )}

      {edit && (
        <div className="action">
          <StyledIconButton
            onClick={updateRequestTitle}
            disabled={isLoading}
            aria-label="agree"
            color="success"
            height="30px"
            width="30px"
          >
            <CheckOutlined />
          </StyledIconButton>
          <StyledIconButton
            onClick={handleCloseEdit}
            disabled={isLoading}
            aria-label="cancel"
            color="error"
            height="30px"
            width="30px"
          >
            <ClearOutlined />
          </StyledIconButton>
        </div>
      )}
    </StyledTableTitle>
  );
};

export default TitleTable;
