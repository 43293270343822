import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { checkVietNam } from '@src/utils/checkCountry';

import { useSelector } from 'react-redux';
import Invoice from './Invoice';
import UserOrderInfo from './UserOrderInfo';
import { StyledOrderInfo } from './index.style';
import Voucher from './Voucher';
import Order from './Order';

const OrderInfo = ({
  totalPrice = 0,
  isPhoneNumberValid,
  onChangePhoneNumberValid,
  isAutoRenewalPackage,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { package: packageState } = useSelector((state) => state.order);

  const isVietNam = checkVietNam();

  return (
    <StyledOrderInfo>
      <Typography className="title">{t('orderInfo')}</Typography>
      <UserOrderInfo
        isPhoneNumberValid={isPhoneNumberValid}
        onChangePhoneNumberValid={onChangePhoneNumberValid}
      />
      <Order isAutoRenewalPackage={isAutoRenewalPackage} />
      <Divider />
      <Voucher currPackage={packageState} />
      <Box className="total">
        <Typography className="info-title">{t('total')}</Typography>
        <Typography className="info-title">
          {!isVietNam && (
            <span className="discount-unit-price font-size-16">$</span>
          )}
          {isVietNam && (
            <span className="discount-unit-price underline font-size-16">
              đ
            </span>
          )}
          {totalPrice.toLocaleString(language)}
        </Typography>
      </Box>
      <Invoice />
    </StyledOrderInfo>
  );
};

export default OrderInfo;
