import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography, Popover, Button } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import { TextFormatRounded } from '@mui/icons-material';
import { EMPHASIS_LEVEL } from '@src/constants/voice';
import { StyledEmphasisButton, StyledMenuItem } from './index.style';

const EmphasisButton = ({
  disabled,
  active,
  currentEmphasis,
  onChange,
  iconStyle,
  className,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  const { t } = useTranslation();

  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleChange = (value) => {
    handleClose();
    if (onChange) onChange(value);
  };

  return (
    <StyledEmphasisButton disabled={disabled}>
      <Tooltip
        arrow
        title={
          disabled ? (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('onlyAdvanceVoice'),
              }}
            />
          ) : (
            t('customEmphasis')
          )
        }
        placement="top"
        open={openTooltip}
        disableHoverListener
        onMouseEnter={() => setOpenTooltip(!anchorEl)}
        onMouseLeave={() => setOpenTooltip(false)}
      >
        <div>
          <Button
            className={className || 'emphasis-button'}
            aria-label="emphasis"
            disabled={disabled}
            onClick={handleClick}
          >
            <TextFormatRounded sx={{ fontSize: 20, opacity: 0.8 }} />
            {!iconStyle && (
              <Typography
                variant="body2"
                className={classNames('toolbar-text', { active })}
              >
                {t('emphasis')}
              </Typography>
            )}
          </Button>
        </div>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {EMPHASIS_LEVEL.map((item) => (
          <StyledMenuItem
            key={item}
            active={currentEmphasis === item}
            onClick={() => handleChange(item)}
          >
            <Typography variant="body2" className="text">
              {t(item)}
            </Typography>
          </StyledMenuItem>
        ))}
      </Popover>
    </StyledEmphasisButton>
  );
};
export default EmphasisButton;
