import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BORDER_RADIUS, BREAKPOINTS } from '@src/styles/config';

export const StyledDubbing = styled('div')`
  background: ${COLOR.white};
  border-radius: ${BORDER_RADIUS};

  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    padding: 20px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    padding: 10px;
  }
`;

export const StyledDubbingWithVideo = styled('div')`
  background: ${COLOR.white};
  border-radius: ${BORDER_RADIUS};

  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    padding: 16px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    padding: 10px;
  }
`;

export const StyledUploadFile = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 6px;
  margin-bottom: 16px;
  margin-top: 16px;

  .dub-file-title {
    font-size: 16px;
    font-weight: 500;
  }

  .dub-file-dropzone {
    .dropzone {
      margin: 0px !important;
    }
  }
`;

export const StyledDemo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  .sample-file {
    color: ${COLOR.lightBlue};
  }
`;

export const StyledDubbingList = styled('div')`
  position: relative;

  .request {
    margin-top: 16px;
  }
`;
