import { Box, Button, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COLOR } from '@src/styles/color';
import { AutoAwesome, Close } from '@mui/icons-material';
import { StyledNotiNewFeature } from './index.style';

const NotiOldVersion = ({ handleCloseNoti }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleDiscoverNewFeature = () => history.push('/dubbing/projects');

  return (
    <StyledNotiNewFeature>
      <Box className="title-wrapper">
        <Typography
          className="title"
          dangerouslySetInnerHTML={{
            __html: t('systemNotSupportCreateProjectInHere'),
          }}
        />
        <Box className="title">
          {t('systemNotSupportCreateProjectInHereDescription')}
        </Box>
      </Box>
      <Box display="flex" gap="12px">
        <Button
          className="guide-button"
          sx={{ border: `1px solid ${COLOR.functionYellow[100]}` }}
          onClick={handleDiscoverNewFeature}
        >
          <AutoAwesome className="icon" />
          <Typography className="text">{t('discoverNewFeature')}</Typography>
        </Button>
        <IconButton onClick={handleCloseNoti}>
          <Close className="icon" />
        </IconButton>
      </Box>
    </StyledNotiNewFeature>
  );
};

export default NotiOldVersion;
