import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledUpdateButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.black.default};
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px;
`;

export const StyledConvertButton = styled(Button)`
  text-transform: none;
  padding: 8px 16px 8px 12px;
  border-radius: 25px;
  margin-left: 12px;
  display: flex;
  justify-content: space-around;
  width: 140px;
  color: ${COLOR.darkBlue};
  height: 44px;
  font-weight: 700;
  line-height: 20px;
`;
