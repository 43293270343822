import { actionTypes } from './actions';

export const initialState = {
  fileId: '',
  title: '',
  voice: {},
  audioType: 'mp3',
  error: { title: '' },
  fileName: '',
  sentences: {}, // { [uuid]: { id: 1, start: "00:00:01,123" , end: "00:01:01,123", content: "text" } }
  originalSentences: {}, // { [uuid]: { id: 1, start: "00:00:01,123" , end: "00:01:01,123", content: "text" } }
  speed: 1,
  youtubeLink: '',
  infoForDubbingAPI: {},
  isConverting: false,
  openSplitVoices: true,
  showOriginalSubtitle: true,
  sentenceWidth: 0,
  projectInfo: {
    hasChanged: false,
    voiceCode: '',
    currentSubtitleLink: '',
    latestRequestId: '',
    projectStatus: '',
    showSavingDialog: false,
    updatedAt: '',
    originalInfo: {},
  },
  changedProjectInfo: {
    title: '',
    voiceCode: '',
    speed: 1,
    voice: {},
    sentencesChanged: {},
  },
  selectedSentencesKeys: [], // [uuid]
  isSelectListenOriginalAudio: true,
};

const dubbingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DUBBING_REQUEST_BY_KEY: {
      const { name, value } = action;
      return { ...state, [name]: value };
    }

    case actionTypes.UPDATE_DUBBING_REQUEST_ERROR_BY_KEY: {
      const { name, value } = action;
      return { ...state, error: { ...state.error, [name]: value } };
    }

    case actionTypes.RESET_DUBBING_REQUEST: {
      const { keys = [] } = action;
      if (!keys?.length) return initialState;

      const dubbingState = state;
      // eslint-disable-next-line array-callback-return
      keys.map((key) => {
        dubbingState[key] = initialState[key];
      });

      return dubbingState;
    }

    default:
      return state;
  }
};

export default dubbingReducer;
