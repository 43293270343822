import { RESOURCE } from '@src/constants';
import { REQUEST_TYPE } from '@src/constants/request';
import api from './api';

export const sendWarningInvalidRequest = async ({
  text,
  invalidTexts,
  type,
  requestType = REQUEST_TYPE.STUDIO,
}) => {
  const response = await api({
    method: 'POST',
    url: `/${RESOURCE.WARNINGS}/invalid-requests`,
    data: { text, invalidTexts, type, requestType },
  });
  return response;
};
