import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { LockRounded } from '@mui/icons-material';
import { delimitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import { convertSecondsToHMS } from '@src/utils/time';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { StyledSidebarTooltip } from './index.style';

const FORMAT_DATETIME = 'DD/MM/YYYY - HH:mm';
const FORMAT_DATE = 'DD/MM/YYYY';

const checkExpiredDate = (expiryDate) => {
  if (!expiryDate) return false;
  return moment().isAfter(expiryDate);
};

const PackageInfo = () => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t } = useTranslation();
  const { language } = i18n;
  const { user } = useSelector((state) => state.auth);
  const { lastExpiredOrder } = useSelector((state) => state.user);
  const { remainingCharacters, bonusCharacters, lockCharacters, dubbing } =
    useSelector((state) => state.auth.user);

  const { getFeatureValue } = useFeatureFlags();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const usePreservationExpiryDate = getFeatureValue(
    FEATURE_KEYS.USE_PRESERVATION_EXPIRY_DATE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const isShowingDubbingUnitSecond =
    isProcessDubbingByUnitSecond && isDubbingRoute;

  const packageCode = isShowingDubbingUnitSecond
    ? dubbing?.packageCode
    : user.packageCode;
  const expiryDate = isShowingDubbingUnitSecond
    ? dubbing?.packageExpiryDate
    : user.packageExpiryDate;

  const preservationExpiryDate = isShowingDubbingUnitSecond
    ? dubbing?.preservationExpiryDate
    : user.preservationExpiryDate;

  const isExpiredPackage = checkExpiredDate(expiryDate);

  const getPackageExpired = () => {
    if (isExpiredPackage) return t('outOfDate');
    if (expiryDate)
      return `${t('exp')}: ${moment(expiryDate).format(FORMAT_DATETIME)}`;
    if (!expiryDate && packageCode) return `${t('exp')}: ${t('endless')}`;

    return '';
  };

  const getPreservedDate = () => {
    if (preservationExpiryDate && usePreservationExpiryDate)
      return moment(preservationExpiryDate).format(FORMAT_DATE);

    if (expiryDate)
      return moment(expiryDate).add(30, 'days').format(FORMAT_DATE);

    if (lastExpiredOrder?.id) {
      const expiryIn =
        lastExpiredOrder.customPackage?.expiresIn ||
        lastExpiredOrder.package?.expiresIn;
      return moment(lastExpiredOrder.confirmedAt)
        .add(expiryIn, 'days')
        .add(30, 'days')
        .format(FORMAT_DATE);
    }

    return '';
  };

  const LockCharacterTooltip = () => (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="body2">
        {`${t('expiryDate')}: `}
        {(expiryDate && moment(expiryDate).format(FORMAT_DATE)) ||
          (!expiryDate && packageCode && t('endless'))}
        {` (${t('over').toLowerCase()})`}
      </Typography>
      <Typography variant="body2">
        {`${
          isShowingDubbingUnitSecond
            ? t('preservedSecondsDate')
            : t('preservedCharactersDate')
        }: `}
        {getPreservedDate()}
      </Typography>
    </Box>
  );

  const totalCharacters =
    remainingCharacters + bonusCharacters > 0
      ? remainingCharacters + bonusCharacters
      : 0;

  const totalSeconds =
    dubbing?.remainingSeconds > 0 ? dubbing?.remainingSeconds : 0;

  const remainingText = isDubbingRoute
    ? t('remainSeconds', {
        numberSeconds: convertSecondsToHMS(totalSeconds || 0),
      })
    : t('remainCharacters', {
        numberCharacters: delimitNumber(totalCharacters, language),
      });

  const lockValue = isShowingDubbingUnitSecond
    ? dubbing?.lockSeconds
    : lockCharacters;

  return (
    <div className="wallet-package">
      {!isExpiredPackage && (
        <Typography
          className={`remain-characters ${
            ((isDubbingRoute && totalSeconds === 0) ||
              (!isDubbingRoute && totalCharacters === 0)) &&
            'danger-text'
          }`}
        >
          {remainingText}
        </Typography>
      )}

      {isExpiredPackage && (
        <Box className="characters-wrapper">
          <Typography className="remain-characters">
            {t(isDubbingRoute ? 'remainSeconds' : 'stillLockCharacters', {
              numberSeconds: convertSecondsToHMS(lockValue || 0),
              numberCharacters: delimitNumber(lockValue, language),
            })}
          </Typography>
          {lockValue > 0 && (
            <StyledSidebarTooltip title={<LockCharacterTooltip />}>
              <LockRounded sx={{ fontSize: 16 }} className="lock-icon" />
            </StyledSidebarTooltip>
          )}
        </Box>
      )}

      <Typography
        className={`expired-date ${isExpiredPackage && 'danger-text'}`}
      >
        {getPackageExpired()}
      </Typography>
    </div>
  );
};

export default PackageInfo;
