import { RESOURCE } from '@src/constants';
import api from './api';

const getVouchers = async () => {
  const packages = await api({
    method: 'GET',
    url: RESOURCE.VOUCHERS,
  });
  return packages;
};

const getBannerVouchers = async () => {
  const packages = await api({
    method: 'GET',
    url: '/vouchers/banner',
  });
  return packages;
};

const getVoucherForFirstPaidOrder = async () => {
  const voucher = await api({
    method: 'GET',
    url: '/vouchers/paid-orders',
  });
  return voucher;
};

const getBannerVouchersNew = async (accessToken) => {
  const voucherBanners = await api({
    method: 'GET',
    url: '/vouchers/banner/user',
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return voucherBanners;
};

const getBannerVouchersFinal = async (accessToken) => {
  if (accessToken) {
    return getBannerVouchersNew(accessToken);
  }
  return getBannerVouchers();
};

export {
  getVouchers,
  getBannerVouchers,
  getVoucherForFirstPaidOrder,
  getBannerVouchersNew,
  getBannerVouchersFinal,
};
