import React from 'react';
import { MobileStepper } from '@mui/material';
import { StyledStep } from './index.style';

const Step = ({ steps, activeStep, stepDisplay }) => (
  <StyledStep>
    {stepDisplay && (
      <MobileStepper
        variant="dots"
        steps={steps}
        position="static"
        activeStep={activeStep}
        sx={{ backgroundColor: 'unset' }}
      />
    )}
  </StyledStep>
);

export default Step;
