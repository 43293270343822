/* eslint-disable no-param-reassign */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import actions from '@src/redux/actions';

import { Typography } from '@mui/material';
import {
  PlayArrowRounded,
  FileDownloadOutlined,
  PauseOutlined,
  TuneRounded,
  FlagRounded,
} from '@mui/icons-material';
import apis from '@src/apis';
import { downloadFileUsingUrl } from '@src/utils/file';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { AUDIO_EFFECTS_URL } from '@src/configs';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import {
  DOWNLOAD_QUOTA_EXCEEDED_CODE,
  SILENCE_AUDIO_URL,
} from '@src/constants/tts';
import Tooltip from '@src/components/Tooltip';
import classNames from 'classnames';
import dataSenses from '@src/services/dataSenses';
import { REQUEST_TYPE } from '@src/constants/request';
import { StyledIconButton } from './index.style';

const RequestAction = ({ request, onOpenErrorReport, audioRef }) => {
  const { t } = useTranslation();
  const {
    id: requestId,
    title,
    characters,
    voice,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    userId,
  } = request;

  const {
    audioLink: audioPlayerLink,
    isPlaying,
    duration,
  } = useSelector((state) => state.audioPlayer);

  const { getFeatureValue } = useFeatureFlags();
  const useNewEffectGuide = getFeatureValue(FEATURE_KEYS.NEW_EFFECT_GUIDE);

  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const dispatch = useDispatch();

  const handleOpenErrorReport = (e) => {
    e.stopPropagation();
    onOpenErrorReport({ id: requestId, title, characters, voice });
  };

  const handleChangePlayAudio = (e) => {
    e.stopPropagation();
    if (audioPlayerLink === audioLink) {
      dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    } else {
      audioRef.current.src = SILENCE_AUDIO_URL;
      audioRef.current.load();
      audioRef.current.play();
      dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      dispatch(actions.audioPlayer.updateSelectedAudioRequest(requestId));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
    }
  };

  const handleOpenAudioEffects = (e) => {
    e.stopPropagation();
    window.open(`${AUDIO_EFFECTS_URL}/?audio=${audioLink}`, '_blank');
  };

  const handleOpenNewAudioEffects = async (e) => {
    e.stopPropagation();
    const res = await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      window.open(
        `${AUDIO_EFFECTS_URL}/?audio=${res.result?.audio || audioLink}`,
        '_blank',
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const handleDownloadAudio = async (e) => {
    e.stopPropagation();

    dataSenses.sendDownloadAudioEvent({
      userId,
      requestType: REQUEST_TYPE.STUDIO,
    });
    const res = isLimitDownloadFreePackage
      ? await apis.requests.getAudioDownloadUrl(requestId)
      : await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      return;
    }

    if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const isShowPlaying =
    audioLink && audioPlayerLink === audioLink && isPlaying && !!duration;

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );
  const isDisabledAudio = !audioLink || audioExpired;

  return (
    <div className="action-cell">
      <StyledIconButton
        onClick={handleChangePlayAudio}
        disabled={isDisabledAudio}
        colorHover="info"
        height="30px"
        padding="4px 8px 4px 4px"
        borderRadius="8px"
      >
        {isShowPlaying ? (
          <PauseOutlined style={{ width: '30px' }} className="icon" />
        ) : (
          <PlayArrowRounded style={{ fontSize: '30px' }} className="icon" />
        )}
        <Typography className="text-action">{t('playAudio')}</Typography>
      </StyledIconButton>
      {useFeatureIsOn(FEATURE_KEYS.DOWNLOAD_AUDIO_LINK) ? (
        <div
          className={classNames('download-wrapper', {
            disabled: isDisabledAudio,
          })}
        >
          <a
            href={audioLink}
            download
            title={t('download')}
            className="download-link"
            onClick={(e) => e.stopPropagation()}
          >
            <FileDownloadOutlined />
            <Typography className="text-action">{t('download')}</Typography>
          </a>
        </div>
      ) : (
        <StyledIconButton
          onClick={handleDownloadAudio}
          disabled={isDisabledAudio}
          colorHover="success"
          height="30px"
          padding="4px 8px 4px 4px"
          borderRadius="8px"
        >
          <FileDownloadOutlined />
          <Typography className="text-action">{t('download')}</Typography>
        </StyledIconButton>
      )}

      <Tooltip title={t('effectGuide')} arrow>
        <StyledIconButton
          onClick={
            useNewEffectGuide
              ? handleOpenNewAudioEffects
              : handleOpenAudioEffects
          }
          disabled={isDisabledAudio}
          colorHover="warning"
          height="30px"
          width="30px"
        >
          <TuneRounded className="icon" />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title={t('error')} arrow>
        <StyledIconButton
          onClick={handleOpenErrorReport}
          colorHover="error"
          height="30px"
          width="30px"
        >
          <FlagRounded className="icon" />
        </StyledIconButton>
      </Tooltip>
    </div>
  );
};
export default RequestAction;
