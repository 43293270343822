import * as React from 'react';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const StyledPopover = styled('div')`
  position: relative;
  // display: inline-block;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Nền đen với độ mờ 0.5 */
    z-index: 1000; /* Đặt dưới popover */
  }

  .popover {
    position: fixed;
    z-index: 1001;
  }
  .popover-content {
    background-color: white;
    margin-top: 8px;
    border-radius: 8px;
  }
  .small-wrap-btn {
    margin-top: 12px;
  }
  .small-create-new-button {
    width: 100%;
    min-width: unset;
    border-radius: 8px;
    width: 44px;
    height: 44px;
  }
  .wrap-btn {
    margin-top: 12px;
  }
  .create-new-button {
    width: 100%;
    border-radius: 8px;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    gap: 8px;
    height: 48px;
  }
  .lable-create-new {
    font-size: 14px;
    font-weight: 500;
    text-transform: initial;
  }
`;

const StyledSidebarTooltip = styled(
  ({ className, placement = 'right', ...props }) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow
      placement={placement}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 16px',
    textAlign: 'center',
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.label[80],
  },
}));

export { StyledPopover, StyledSidebarTooltip };
