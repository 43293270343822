import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledRibbon = styled('div')`
  .card-package {
    position: relative;
    text-align: center;

    .ribbon {
      position: absolute;
      top: 0;
      right: -13px;
    }

    .ribbon .text,
    .ribbon .banner {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
    }

    .ribbon .banner {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      color: ${COLOR.white};
      -webkit-font-smoothing: antialiased;
      display: block;
      float: right;
      position: relative;
      right: 0px;
      top: 22px;
      width: 100px;
    }

    .ribbon .banner::after,
    .ribbon .banner::before {
      content: '';
      display: block;
      height: 12px;
      position: absolute;
      width: 30px;
    }

    .ribbon .banner::before {
      -webkit-transform: skewY(-45deg) translate(50%, 15px);
      -moz-transform: skewY(-45deg) translate(50%, 15px);
      -ms-transform: skewY(-45deg) translate(50%, 15px);
      -o-transform: skewY(-45deg) translate(50%, 15px);
      -webkit-transform-origin: 100% center;
      -moz-transform-origin: 100% center;
      -ms-transform-origin: 100% center;
      -o-transform-origin: 100% center;
      left: -45px;
    }

    .ribbon .banner::after {
      -webkit-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
      -moz-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
      -ms-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
      -o-transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
      -webkit-transform-origin: 0 center;
      -moz-transform-origin: 0 center;
      -ms-transform-origin: 0 center;
      -o-transform-origin: 0 center;
      right: -17px;
    }

    .no-css-transforms .ribbon .banner {
      position: static;
      width: 100%;
      float: none;
      font-size: 10px;
    }

    .ribbon .text {
      position: relative;
      z-index: 2;
      padding: 6px 0px;
      font-size: 14px;
      font-weight: bold;
      min-height: 18px;
      line-height: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ribbon .text::before,
    .ribbon .text::after {
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .ribbon .text::before {
      -webkit-transform: translateX(-15%) skewX(-45deg);
      -moz-transform: translateX(-15%) skewX(-45deg);
      -ms-transform: translateX(-15%) skewX(-45deg);
      -o-transform: translateX(-15%) skewX(-45deg);
    }

    .ribbon .text::after {
      -webkit-transform: translateX(15%) skewX(45deg);
      -moz-transform: translateX(15%) skewX(45deg);
      -ms-transform: translateX(15%) skewX(45deg);
      -o-transform: translateX(15%) skewX(45deg);
    }

    .no-css-transforms .ribbon .text {
      height: 25px;
      padding: 3px;
    }

    .ribbon-red .banner::after,
    .ribbon-red .banner::before {
      background-color: ${COLOR.darkRed};
    }

    .ribbon-red .text::before,
    .ribbon-red .text::after,
    .no-css-transforms .ribbon-red .text {
      background-color: ${COLOR.red};
    }

    .text-cheaper {
      background: ${COLOR.primary};
      padding: 0px 5px;
      position: relative;
      width: fit-content;
      border-radius: 2px 2px 0px 0px;
      color: ${COLOR.darkBlue};
      margin: 0px auto;
    }

    .arrow {
      width: 0;
      height: 0;
      line-height: 0;
      border-right: 7px solid transparent;
      border-top: 10px solid ${COLOR.primary};
      top: 100%;
      right: 0px;
      position: absolute;
    }
  }
`;

export { StyledRibbon };
