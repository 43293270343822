import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { DialogActions, Typography, Button, Divider } from '@mui/material';
import { DownloadRounded, RefreshRounded } from '@mui/icons-material';
import { delimitNumber } from '@src/utils/number';
import { useTranslation } from 'react-i18next';
import CopyableButton from '@src/components/CopyableButton';
import { removeVietnameseTones } from '@src/utils/string';
import { QR_CODE_URL, TEMPLATE_QR_CODE } from '@src/configs';
import WarningIcon from '@src/assets/icons/warning-orange.svg';
import { checkVietNam } from '@src/utils/checkCountry';
import { COLOR } from '@src/styles/color';
import apis from '@src/apis';
import { ORDER_STATUS } from '@src/constants/order';
import actions from '@src/redux/actions';
import { PACKAGE_TYPE } from '@src/constants/package';
import { BANK_TEMPLATE_QR_CODE, CHANGE_QR_NOTE } from '@src/constants/payment';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledPaymentDialog, StyledTransferInformation } from './index.style';

const BANK_TRANSFERRED_TIMEOUT = 15;

const notes = [
  { title: 'enterCorrectly', description: 'amountMoneyExactly' },
  { title: 'enterExactly', description: 'content' },
  { title: 'shouldTransferMoney', description: 'sameBank' },
];

const BankTransferDialog = ({
  open,
  selectedBank,
  totalPrice,
  onChangeLoading,
  onRedirectToPaymentHistory,
  onCloseBankTransferDialog,
  isDubbingRoute,
  onChangeQR,
}) => {
  const [usdToVndRate, setUsdToVndRate] = useState(1);
  const [time, setTime] = useState(BANK_TRANSFERRED_TIMEOUT);

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { latestOrder, latestDubbingOrder } = useSelector(
    (state) => state.user,
  );

  const [nextTime, setNextTime] = useState(moment());

  const { getFeatureValue } = useFeatureFlags();
  const useNewBankTransfer = getFeatureValue(FEATURE_KEYS.NEW_BANK_TRANSFER, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const isVietNam = checkVietNam();

  const handleCancelPendingOrder = async (note) => {
    if (!isDubbingRoute && latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (isDubbingRoute && latestDubbingOrder.status !== ORDER_STATUS.PENDING)
      return;

    onChangeLoading(true);
    const data = await apis.orders.cancelOrder(
      isDubbingRoute ? latestDubbingOrder?.id : latestOrder.id,
      note,
    );
    onChangeLoading(false);
    if (!data.status) {
      dispatch(actions.noti.push({ severity: 'error', code: data.code }));
    } else if (data.result.type === PACKAGE_TYPE.STUDIO)
      dispatch(actions.user.getLatestOrderSuccess(data.result));
    else if (data.result.type === PACKAGE_TYPE.DUBBING)
      dispatch(actions.user.getLatestDubbingOrderSuccess(data.result));
  };

  const handleCancelPendingOrderByUser = async () => {
    handleCancelPendingOrder();
    onCloseBankTransferDialog();
  };

  const handleConfirmTransferOrder = async () => {
    if (!isDubbingRoute && latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (isDubbingRoute && latestDubbingOrder.status !== ORDER_STATUS.PENDING)
      return;
    onChangeLoading(true);
    const data = await apis.orders.confirmByCustomer(
      isDubbingRoute ? latestDubbingOrder?.id : latestOrder.id,
    );
    onChangeLoading(false);
    if (data?.status) {
      if (data.result.type === PACKAGE_TYPE.STUDIO)
        dispatch(actions.user.getLatestOrderSuccess(data.result));
      if (data.result.type === PACKAGE_TYPE.DUBBING)
        dispatch(actions.user.getLatestDubbingOrderSuccess(data.result));
      onRedirectToPaymentHistory();
      return;
    }
    dispatch(actions.noti.push({ severity: 'error', message: data?.message }));
  };

  const fetchUsdToVndRate = async () => {
    const data = await apis.banks.getUSDToVNDRate();
    if (data.status) {
      const { result } = data;
      setUsdToVndRate(result || 1);
    }
  };

  useEffect(() => {
    fetchUsdToVndRate();
  }, [isVietNam]);

  const currentOrder = useMemo(
    () => (isDubbingRoute ? latestDubbingOrder : latestOrder),
    [isDubbingRoute, latestDubbingOrder, latestOrder],
  );

  const getQRTemplate = (bankName) => {
    if (!useNewBankTransfer) return TEMPLATE_QR_CODE;

    switch (bankName) {
      case 'Vietcombank':
        return BANK_TEMPLATE_QR_CODE.VIETCOMBANK;
      case 'Techcombank':
        return BANK_TEMPLATE_QR_CODE.TECHCOMBANK;
      default:
        return TEMPLATE_QR_CODE;
    }
  };

  const qrCodeURL = useMemo(() => {
    const qrTemplage = getQRTemplate(currentOrder.bank?.name);
    return `${QR_CODE_URL}/image/${selectedBank?.name}-${
      selectedBank?.number
    }-${qrTemplage}.png?amount=${
      isVietNam ? totalPrice : Math.ceil(totalPrice * usdToVndRate)
    }&addInfo=${currentOrder.code}&accountName=${selectedBank?.holder}`;
  }, [selectedBank, currentOrder, isVietNam, totalPrice, usdToVndRate]);

  const handleDownloadQR = async () => {
    const response = await fetch(qrCodeURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'qr-code.png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const changeQRCode = async () => {
    await handleCancelPendingOrder(CHANGE_QR_NOTE);
    await onChangeQR(currentOrder?.bank?.number);
    setNextTime(moment());
  };

  useEffect(() => {
    if (nextTime) {
      const interval = setInterval(() => {
        const diffTime = moment().diff(moment(nextTime), 'seconds');
        if (diffTime <= BANK_TRANSFERRED_TIMEOUT) {
          const remainingSecond = BANK_TRANSFERRED_TIMEOUT - diffTime;
          setTime(remainingSecond);
        } else {
          setTime(0);
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    return null;
  }, [nextTime]);

  return (
    <StyledPaymentDialog open={open}>
      <div className="wrapper">
        <Typography className="title">{t('transferPayments')}</Typography>
        <StyledTransferInformation>
          <div className="qr-wrapper">
            <img src={qrCodeURL} alt="bank-qr" className="bank-qr" />

            {useNewBankTransfer && (
              <div className="button-group">
                <Button
                  size="small"
                  className="saveQR"
                  variant="outlined"
                  onClick={handleDownloadQR}
                >
                  <DownloadRounded />
                  {t('download')}
                </Button>
                <Button
                  size="small"
                  className="saveQR"
                  variant="outlined"
                  onClick={changeQRCode}
                >
                  <RefreshRounded />
                  {t('change')}
                </Button>
              </div>
            )}
          </div>

          <div className="content-wrapper">
            <div className="content-information">
              <div className="row-content">
                <Typography className="label">{t('bank')}:</Typography>
                <Typography className="value">
                  {removeVietnameseTones(selectedBank?.address, language)}
                </Typography>
              </div>
              <div className="row-content">
                <Typography className="label">{t('accountOwner')}:</Typography>
                <Typography className="value">
                  {removeVietnameseTones(selectedBank?.holder, language)}
                </Typography>
              </div>
              <div className="row-content">
                <Typography className="label">{t('cardNumber')}:</Typography>
                <Typography className="value card-number">
                  <CopyableButton
                    color={COLOR.secondary[64]}
                    content={selectedBank?.bankNumber || selectedBank?.number}
                    fontSize={20}
                  />
                  {selectedBank?.bankNumber || selectedBank?.number}
                </Typography>
              </div>
              <Divider className="divider" />
              <div className="row-content">
                <Typography className="label">
                  {t('amountToTransfer')}:
                </Typography>
                <Typography className="value">
                  {!isVietNam && <span className="unit-price">$</span>}
                  {isVietNam && (
                    <span className="unit-price underlined">đ</span>
                  )}
                  {delimitNumber(totalPrice, language)}
                </Typography>
              </div>
              <div className="row-content">
                <Typography className="label">
                  {t('transferContent')}:
                </Typography>
                <Typography className="value card-number">
                  <CopyableButton
                    content={
                      isDubbingRoute
                        ? latestDubbingOrder.code
                        : latestOrder.code
                    }
                    color={COLOR.secondary[64]}
                    fontSize={20}
                  />
                  {isDubbingRoute ? latestDubbingOrder.code : latestOrder.code}
                </Typography>
              </div>
              <Divider className="divider-note" />
            </div>
          </div>
          <div className="note">
            {useNewBankTransfer ? (
              <div className="note-item">
                <img className="icon" src={WarningIcon} alt="warning" />
                <div className="note-content">
                  <Typography className="note-description">
                    {t('bankTransferNote')}
                  </Typography>
                </div>
              </div>
            ) : (
              notes.map((note) => (
                <div className="note-item" key={note.title}>
                  <img src={WarningIcon} alt="warning" />
                  <div className="note-content">
                    <Typography className="note-title">
                      {t(note.title)}
                    </Typography>
                    <Typography className="note-description">
                      {t(note.description)}
                    </Typography>
                  </div>
                </div>
              ))
            )}
          </div>
        </StyledTransferInformation>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelPendingOrderByUser}
            className="button"
            fullWidth
          >
            {t('cancelPayment')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="button"
            fullWidth
            disabled={time > 0 && useNewBankTransfer}
            onClick={handleConfirmTransferOrder}
            sx={{
              '&.Mui-disabled': {
                background: COLOR.primary,
                color: COLOR.indigo[100],
                opacity: 0.5,
              },
            }}
          >
            {`${t('transferredConfirmation')} ${
              time > 0 && useNewBankTransfer ? `(${time}s)` : ''
            }`}
          </Button>
        </DialogActions>
      </div>
    </StyledPaymentDialog>
  );
};

export default BankTransferDialog;
