import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCopyableButton = styled('div')`
  cursor: pointer;

  .copy {
    color: ${(props) => props.color || COLOR.info};
  }

  .check {
    color: ${(props) => props.color || COLOR.info} !important;
  }
`;
