const moment = require('moment');
const { PACKAGE_TYPE } = require('@src/constants/package');
const { VOUCHER_TYPE } = require('@src/constants/voucher');
const { DEFAULT_BANK } = require('@src/constants/payment');

const getDefaultBank = (defaultBankValue) => {
  switch (defaultBankValue) {
    case DEFAULT_BANK.DYNAMIC: {
      // Default bank is Techcombank from Monday to Thursday and Vietcombank from Friday to Sunday
      const today = new Date().getDay();
      const defaultBank =
        today >= 1 && today <= 4 ? 'Techcombank' : 'Vietcombank';
      return defaultBank;
    }

    case DEFAULT_BANK.TECHCOMBANK:
    case DEFAULT_BANK.VIETCOMBANK:
      return defaultBankValue;

    default:
      return 'Vietcombank';
  }
};

const getProductInfoForUserPackagePurchase = ({
  user,
  packageState,
  voucher,
}) => {
  const {
    lockCharacters = 0,
    remainingCharacters = 0,
    preservationExpiryDate: preservationExpiryDateTTS,
  } = user || {};

  const {
    lockSeconds = 0,
    remainingSeconds = 0,
    preservationExpiryDate: preservationExpiryDateDubbing,
  } = user?.dubbing || {};

  const { type: voucherType, value: voucherValue } = voucher || {};

  const {
    maxCharacters,
    maxSeconds,
    type: packageType,
    expiresIn,
  } = packageState || {};

  // Total characters or seconds that user can use
  let total = 0;
  // Default expires date is current date + expiresIn days
  let expires = moment().add(expiresIn, 'days');

  // Check if voucher type is time, percent time or character
  const isVoucherTime = voucherType === VOUCHER_TYPE.TIME;
  const isVoucherTimePercent = voucherType === VOUCHER_TYPE.PERCENT_TIME;
  const isVoucherCharacter = voucherType === VOUCHER_TYPE.CHARACTER;

  // If voucher is time or percent time, expires date is current date + expiresIn days + voucherValue days
  if (isVoucherTime) {
    expires = moment().add(expiresIn + voucherValue, 'days');
  }
  // If voucher is percent time, expires date is current date + expiresIn days + expiresIn * voucherValue / 100 days
  if (isVoucherTimePercent) {
    expires = moment().add(
      expiresIn + (expiresIn * voucherValue) / 100,
      'days',
    );
  }
  // Package type is studio
  if (packageType === PACKAGE_TYPE.STUDIO) {
    // Check expiration date of lock characters
    const isTTSExpired =
      lockCharacters && moment().isBefore(moment(preservationExpiryDateTTS));
    // Total characters that user can use is sum of max characters, remaining characters, voucher value * max characters and lock characters
    total =
      (isVoucherCharacter ? maxCharacters * voucherValue : maxCharacters) +
      remainingCharacters +
      (isTTSExpired ? lockCharacters : 0);
    // Package type is dubbing
  } else if (packageType === PACKAGE_TYPE.DUBBING) {
    // Check expiration date of lock seconds
    const isDubbingExpired =
      lockSeconds && moment().isBefore(moment(preservationExpiryDateDubbing));
    // Total seconds that user can use is sum of max seconds, remaining seconds and lock seconds
    total =
      maxSeconds + remainingSeconds + (isDubbingExpired ? lockSeconds : 0);
  }

  return { total, expires, packageType };
};

module.exports = { getDefaultBank, getProductInfoForUserPackagePurchase };
