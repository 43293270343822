import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { MenuItem } from '@mui/material';
import { BREAKPOINTS } from '@src/styles/config';

const StyledToolBar = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  background: ${COLOR.secondary[40]};
  border-radius: 8px;
  padding: 8px;

  .border-horizontal {
    display: flex;
    align-items: center;
    border-left: 1px solid ${COLOR.light};
    height: 28px;

    .icon-content {
      display: flex;
      gap: 4px;
      text-transform: lowercase;
    }
  }

  .speed {
    display: flex;
    align-items: center;
    border-left: 1px solid ${COLOR.light};
    height: 28px;
  }

  .title {
    color: ${COLOR.darkBlue};
    font-weight: 500;
  }

  .character-default {
    font-size: 14px;
    font-weight: 500;
    color: ${COLOR.darkBlue};
    text-transform: lowercase;
  }

  .character-blue {
    color: ${COLOR.info};
  }

  .convert-button {
    margin-left: auto;
    padding: 8px 22px;
  }

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    .speed {
      padding-left: 16px;
    }

    .border-horizontal {
      padding-right: 16px;
      padding-left: 16px;
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    .speed {
      padding-left: 8px;
    }

    .border-horizontal {
      padding-left: 8px;
      margin-left: 8px;
    }
  }
`;

const StyledSelectionButton = styled('div')`
  width: auto;

  .custom-button {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    color: ${COLOR.text};
    gap: 8px;
    width: 92px;
  }

  .icon-content {
    display: flex;
    gap: 4px;
    text-transform: lowercase;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  cursor: pointer;
  padding: 6px 16px;
  border-radius: 4px;
  display: flex;
  gap: 8px;

  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }
`;

const StyledMenu = styled('div')`
  margin: 8px;
`;

export { StyledToolBar, StyledSelectionButton, StyledMenuItem, StyledMenu };
