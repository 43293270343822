import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@src/components/Dialog';
import ProcessHandler from '@src/components/ProcessHandler';
import {
  TextField,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  Box,
} from '@mui/material';
import { PACKAGE_TYPE } from '@src/constants/package';
import { delimitNumber } from '@src/utils/number';
import { convertMillisToDubbingDuration } from '@src/services/dubbing';
import actions from '@src/redux/actions';
import apis from '@src/apis';
import i18n from '@src/languages';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import ProjectVoice from '@src/containers/NewDubbing/ListProject/ProjectTableItem/ProjectVoice';
import { StyledErrorReport } from './index.style';

const ErrorReport = ({ open, request, onClose }) => {
  const [error, setError] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState('');
  const { t } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();

  const isDubbingRequest = request?.type === PACKAGE_TYPE.DUBBING;

  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();

  const isMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    {
      userId: user?.id,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
    },
  );

  const getVoiceOfRequest = () => {
    if (!isMultipleVoices || !request?.sentencesVoiceCode)
      return <Typography>{request?.voice?.name}</Typography>;

    const parsedSentencesVoiceCode = JSON.parse(
      request?.sentencesVoiceCode || {},
    );

    return (
      <Box display="flex" alignContent="flex-start">
        <ProjectVoice
          voiceCode={request?.voice}
          sentencesVoiceCode={parsedSentencesVoiceCode}
        />
      </Box>
    );
  };

  const handleChangeDescription = (e) => {
    setError(false);
    setDescription(e.target.value);
  };

  const handleClose = () => {
    setError(false);
    setDescription('');
    onClose();
  };

  const handleSendReport = async () => {
    if (!description) {
      setError(true);
      return;
    }
    setLoading(true);

    const data = await apis.requests.createErrorReport(request.id, description);
    setLoading(false);
    handleClose();
    if (data && data.status) {
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'sendErrorReportSuccess',
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'sendErrorReportFailure',
      }),
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title={t('errorReport')}
      fullWidth
    >
      <StyledErrorReport>
        <DialogContent>
          <div className="information-column">
            <Typography className="title">{t('title')}</Typography>
            <Typography>{request?.title}</Typography>
            <Typography className="title">
              {t(isDubbingRequest ? 'duration' : 'numberCharacters')}
            </Typography>
            <Typography>
              {isDubbingRequest
                ? convertMillisToDubbingDuration(request?.seconds * 1000)
                : delimitNumber(request?.characters, language)}
            </Typography>
            <Typography className="title" sx={{ marginY: 'auto !important' }}>
              {t('voice')}
            </Typography>
            {getVoiceOfRequest()}
          </div>
          <Typography className="title" sx={{ marginTop: '15px !important' }}>
            {t('descriptionError')} (*)
          </Typography>
          <TextField
            error={error}
            helperText={error && t('descriptionRequired')}
            fullWidth
            color="secondary"
            placeholder={t('descriptionErrorPlaceholder')}
            value={description}
            onChange={handleChangeDescription}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <ProcessHandler loading={loading} align="center" size={30}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendReport}
            >
              {loading}
              {t('sendReport')}
            </Button>
          </ProcessHandler>
        </DialogActions>
      </StyledErrorReport>
    </Dialog>
  );
};
export default ErrorReport;
