import { combineReducers } from 'redux';
import auth, { initialState as authInitialState } from './auth/reducer';
import user, { initialState as userInitialState } from './user/reducer';
import audioPlayer, {
  initialState as audioPlayerInitialState,
} from './audioPlayer/reducer';
import noti, { initialState as notiInitialState } from './noti/reducer';
import dialog, { initialState as dialogInitialState } from './dialog/reducer';
import request, { initialState as requestInitState } from './request/reducer';
import synthesisRequest, {
  initialState as synthesisRequestInitState,
} from './synthesisRequest/reducer';
import featureFlag, {
  initialState as featureFlagInitialState,
} from './featureFlag/reducer';
import voucher, {
  initialState as voucherInitialState,
} from './voucher/reducer';
import banner, { initialState as bannerInitialState } from './banner/reducer';
import language, {
  initialState as languageInitialState,
} from './language/reducer';
import voice, { initialState as voiceInitialState } from './voice/reducer';
import device, { initialState as deviceInitialState } from './device/reducer';
import configTable, {
  initialState as configTableInitialState,
} from './configTable/reducer';
import dubbingRequest, {
  initialState as dubbingRequestInitState,
} from './dubbingRequest/reducer';
import layout, { initialState as layoutInitState } from './layout/reducer';
import audioPreview, {
  initialState as audioPreviewInitState,
} from './audioPreview/reducer';
import order, { initialState as orderState } from './order/reducer';
import category, { initialState as categoryState } from './category/reducer';
import audioVoiceCloning, {
  initialState as audioVoiceCloningState,
} from './audioVoiceCloning/reducer';
import province, { initialState as provinceState } from './province/reducer';
import voiceVoiceCloning, {
  initialState as voiceVoiceCloningState,
} from './voiceVoiceCloning/reducer';

export const initialState = {
  auth: authInitialState,
  user: userInitialState,
  audioPlayer: audioPlayerInitialState,
  noti: notiInitialState,
  dialog: dialogInitialState,
  request: requestInitState,
  synthesisRequest: synthesisRequestInitState,
  featureFlag: featureFlagInitialState,
  voucher: voucherInitialState,
  banner: bannerInitialState,
  language: languageInitialState,
  voice: voiceInitialState,
  device: deviceInitialState,
  configTable: configTableInitialState,
  dubbingRequest: dubbingRequestInitState,
  layout: layoutInitState,
  audioPreview: audioPreviewInitState,
  order: orderState,
  category: categoryState,
  audioVoiceCloning: audioVoiceCloningState,
  province: provinceState,
  voiceVoiceCloning: voiceVoiceCloningState,
};

export default combineReducers({
  auth,
  user,
  audioPlayer,
  noti,
  dialog,
  request,
  synthesisRequest,
  featureFlag,
  voucher,
  banner,
  language,
  voice,
  device,
  configTable,
  dubbingRequest,
  layout,
  audioPreview,
  order,
  category,
  audioVoiceCloning,
  province,
  voiceVoiceCloning,
});
