import { GrowthBook, useGrowthBook } from '@growthbook/growthbook-react';

const useFeatureFlags = () => {
  const growthbook = useGrowthBook();

  const getFeatureValue = (featureKey, attributes = {}) => {
    const currentFeatures = growthbook.getFeatures();
    const currentAttributes = growthbook.getAttributes() || {};

    const localGrowthbook = new GrowthBook();
    localGrowthbook.setFeatures(currentFeatures);
    localGrowthbook.setAttributes({ ...currentAttributes, ...attributes });

    const featureValue = localGrowthbook.getFeatureValue(featureKey);
    localGrowthbook.destroy();

    return featureValue;
  };

  return { getFeatureValue };
};

export default useFeatureFlags;
