import { PAGINATION_LIMIT, RESOURCE } from '@src/constants';
import { PACKAGE_STATUS, PACKAGE_TYPE } from '@src/constants/package';
import { ERROR_CODE } from '@src/errors/code';
import api from './api';

const createOrder = async ({
  packageId,
  paymentMethodId,
  bankCode,
  callbackUrl,
  downgradePackage,
  confirmCancelSubscription,
  phoneNumber,
  voucherId,
  invoice,
  isGlobal,
  affiliateMarketerId,
  datasenses,
}) => {
  // TODO: check response data
  try {
    const response = await api({
      method: 'POST',
      url: RESOURCE.ORDERS,
      data: {
        packageId,
        paymentMethodId,
        bankCode,
        callbackUrl,
        downgradePackage,
        confirmCancelSubscription,
        phoneNumber,
        voucherId,
        invoice,
        isGlobal,
        affiliateMarketerId,
        datasenses,
      },
      timeout: 30 * 1000,
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

const createTrialDubbingOrder = async () => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.ORDERS}/dubbing-for-old-user`,
  });
  return response;
};

const getOrderState = async (token) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.ORDERS}/state`,
    params: { token },
  });
  return response;
};

const getOrderById = async (orderId) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.ORDERS}/${orderId}`,
  });
  return response;
};

const getOrders = async ({
  userId,
  packageLevel,
  packageStatus,
  type,
  startDate,
  endDate,
  search,
  searchFields,
  offset,
  limit = PAGINATION_LIMIT,
  fields,
  sort,
  status,
}) => {
  const response = await api({
    method: 'GET',
    url: RESOURCE.ORDERS,
    params: {
      userId,
      packageLevel,
      packageStatus,
      type,
      startDate,
      endDate,
      search,
      searchFields,
      offset,
      limit,
      fields,
      sort,
      status,
    },
  });
  return response;
};

const cancelOrder = async (orderId, note) => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.ORDERS}/${orderId}/cancel`,
    data: { note },
  });
  return response;
};

const confirmByCustomer = async (orderId) => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.ORDERS}/${orderId}/confirm`,
  });
  return response;
};

const countPaidOrders = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.ORDERS}/count-paid-orders`,
  });
  return response;
};

const getLastExpiredOrder = async () => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.ORDERS}/last-expired`,
    params: { type: PACKAGE_TYPE.STUDIO },
  });
  return response;
};

const updateMoeStatus = async (orderId) => {
  const response = await api({
    method: 'PUT',
    url: `${RESOURCE.ORDERS}/${orderId}/moe`,
  });
  return response;
};

const getUserPaidOrders = async (usagePackage) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${RESOURCE.ORDERS}`,
      params: {
        type: PACKAGE_TYPE.STUDIO,
        usagePackage,
        status: PACKAGE_STATUS.PAID,
      },
    });
    return response;
  } catch (error) {
    return { status: 0, errorCode: ERROR_CODE.BAD_REQUEST };
  }
};

const getUsingOrders = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: `${RESOURCE.ORDERS}/latest-paid`,
    });
    return response;
  } catch (error) {
    return { status: 0, errorCode: ERROR_CODE.BAD_REQUEST };
  }
};

const migrateFreePlan = async () => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.ORDERS}/migrate-free-plan`,
  });
  return response;
};

export {
  createOrder,
  createTrialDubbingOrder,
  getOrderState,
  getOrderById,
  getOrders,
  cancelOrder,
  confirmByCustomer,
  countPaidOrders,
  getLastExpiredOrder,
  updateMoeStatus,
  getUserPaidOrders,
  getUsingOrders,
  migrateFreePlan,
};
