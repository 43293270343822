import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BREAKPOINTS } from '@src/styles/config';

const StyledResizeTextBox = styled('div')`
  max-width: ${(props) => `${props.maxWidth}px` || '450px'};
  height: 40px;
  display: block;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    .input-wrapper {
      padding: 4px 8px;
    }
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    .input-wrapper {
      padding: 0;
    }
  }

  .input-wrapper {
    border: 1px solid white;
    margin-left: -4px;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 4px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 28px;

    &:hover {
      border: 1px solid ${COLOR.indigo[32]};
    }
    &:focus-within {
      border: 1px solid ${COLOR.primary};
    }
  }

  .size-span {
    white-space: pre;
    min-height: 1em;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    position: relative;
    opacity: 0;
    background: yellow;
    min-width: 2px;
    user-select: none;
    vertical-align: top;

    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;
    box-sizing: border-box;
  }

  .size-input {
    white-space: pre;
    position: absolute;
    top: 0;
    left: 4px;
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    background: none;
    border: none;
    outline: none;
    color: inherit;

    font-size: 16px;
    color: ${COLOR.indigo.default};
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;
    box-sizing: border-box;
  }

  .footer-label {
    opacity: 0.8;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    padding-left: 8px;
    margin-left: -6px;
  }

  input::placeholder {
    font: inherit;
    color: ${COLOR.dark};
    opacity: 0.4;
  }
`;

export { StyledResizeTextBox };
