import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import ROUTES from '@src/constants/route';
import {
  CardContent,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import { Check, Clear } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import { PACKAGE_LEVEL, PACKAGE_TYPE } from '@src/constants/package';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import actions from '@src/redux/actions';
import i18n from '@src/languages';
import apis from '@src/apis';
import ProcessHandler from '@src/components/ProcessHandler';
import { EVENT_TYPE, LANGUAGE } from '@src/constants';
import { ORDER_STATUS } from '@src/constants/order';
import NotificationDialog from '@src/components/NotificationDialog';
import { VBEE_CONSOLE_URL } from '@src/configs';
import { StyledRibbon } from '@src/components/Ribbon/index.style';
import { checkVietNam } from '@src/utils/checkCountry';
import moment from 'moment';
import dataSenses from '@src/services/dataSenses';
import { delay } from '@src/utils/delay';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledPackageItem } from './index.style';

const PackageItem = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { voucherCode, bannerType } = camelcaseKeys(
    queryString.parse(location.search) || {},
  );
  const { user = {} } = useSelector((state) => state.auth);

  const { usingPackage, latestOrder } = useSelector((state) => state.user);
  const { featureFlags } = useSelector((state) => state.featureFlag);

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState({
    createFreeOrder: false,
    cancelOrder: false,
  });

  const isVietNam = checkVietNam();

  const { language } = i18n;
  const buyApiPackageFeature = featureFlags.find(
    (featureFlag) => featureFlag.name === FEATURE_FLAG.BUY_API_PACKAGE,
  );

  const { getFeatureValue } = useFeatureFlags();
  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSensesFromServer = dataSensesFeatures?.s2s;

  const isUsingFreePackage =
    [PACKAGE_LEVEL.BASIC, PACKAGE_LEVEL.TRIAL].includes(data.level) &&
    usingPackage.code === data.code;

  const handleCreateFreeOrder = async (packageId) => {
    if (usingPackage.level === PACKAGE_LEVEL.BASIC) return;

    if (latestOrder.status === ORDER_STATUS.PENDING) {
      setOpenDialog(true);
      return;
    }

    setLoading({ ...loading, createFreeOrder: true });
    const orderData = await apis.orders.createOrder({
      packageId,
      isGlobal: !isVietNam,
      datasenses: sendDataSensesFromServer
        ? dataSenses.getDataSensesRequireFields()
        : undefined,
    });
    setLoading({ ...loading, createFreeOrder: false });

    if (orderData.status) {
      dispatch(actions.user.getLatestOrderSuccess(orderData.result));
      dispatch(actions.user.getUsingPackageSuccess(orderData.result.package));
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'buyPackageSuccessfully',
        }),
      );
      history.push(ROUTES.TTS);
      return;
    }

    dispatch(
      actions.noti.push({ severity: 'error', message: 'buyPackageFailure' }),
    );
  };

  const handleBuyPackage = async () => {
    if (data.price === 0) {
      if (isUsingFreePackage) return;
      window.dataLayer.push({
        event: EVENT_TYPE.STUDIO_ACTIVE_FREE_PLAN,
        userId: user?.id,
        email: user.email,
        package: data.code,
        createdAt: moment().format('DD/MM/YYYY - HH:mm'),
      });
      await delay(1000);
      handleCreateFreeOrder(data.id);
      return;
    }
    const search = voucherCode
      ? `?package=${data.code}&&voucherCode=${voucherCode}&&bannerType=${bannerType}`
      : `?package=${data.code}`;

    if (data.type === PACKAGE_TYPE.API) {
      window.location.assign(`${VBEE_CONSOLE_URL}/payments${search}`);
    } else {
      history.replace({ search });
    }
  };

  const handleCancelPendingOrder = async () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;

    setLoading({ ...loading, cancelOrder: true });
    const orderData = await apis.orders.cancelOrder(latestOrder.id);
    setLoading({ ...loading, cancelOrder: false });
    if (!orderData.status)
      dispatch(actions.noti.push({ severity: 'error', code: orderData.code }));
    else {
      if (orderData.result.type !== PACKAGE_TYPE.API)
        dispatch(actions.user.getLatestOrderSuccess(orderData.result));

      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'cancelOrderSuccessfully',
        }),
      );
    }

    setOpenDialog(false);
    setLoading({ ...loading, createFreeOrder: false });
  };

  const handleContinuePayingDialog = () => {
    if (latestOrder.status !== ORDER_STATUS.PENDING) return;
    if (latestOrder.paymentLink)
      window.location.assign(latestOrder.paymentLink);
    else history.replace({ search: `?package=${data.code}` });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const renderButtonText = () => {
    const displayContactButton = buyApiPackageFeature?.active
      ? data.type === PACKAGE_TYPE.API && data.maxCharacters < 0
      : data.type !== PACKAGE_TYPE.STUDIO;
    if (displayContactButton) return t('contactNow');
    if (data.price > 0) return t('buyNow');
    if (isUsingFreePackage) return t('using');

    return (
      <ProcessHandler loading={loading.createFreeOrder} size={25}>
        {t('activeNow')}
      </ProcessHandler>
    );
  };

  const renderPackagePriceUnit = (price) => {
    if (price <= 0 || !price) return '';
    if (price < 1) return `/${t('character')}`;
    return `/${t('month')}`;
  };

  // display buy button when
  // 1. package item is free and user is not using package
  // 2. package item is free and user is using basic package
  // 3. package item is not free
  const displayBuyButton =
    !usingPackage.id ||
    usingPackage.level === PACKAGE_LEVEL.BASIC ||
    usingPackage.level === PACKAGE_LEVEL.TRIAL ||
    data.price > 0;

  const renderDiscount = (discount) => {
    if (!discount) return '';

    return (
      <div className="ribbon ribbon-red">
        <div className="banner">
          <div className="text">{t('discount', { discount })}</div>
        </div>
      </div>
    );
  };

  const renderYearDiscount = (discount) => {
    if (!discount) return <Typography className="text-cheaper" />;

    return (
      <Typography className="text-cheaper">
        {t('savingYear')} {discount}
        <span className="arrow" />
      </Typography>
    );
  };

  const renderPrice = (price, priceUnit) => (
    <>
      <Typography
        className={classnames('price', {
          'price-en': !isVietNam && price > 0,
        })}
      >
        {price === 0 && `${t('free')}`}
        {price > 0 && priceUnit}
        {isVietNam && price > 0 && <sup>đ</sup>}
      </Typography>
      <Typography className="sub-price">
        {renderPackagePriceUnit(data.price)}
      </Typography>
    </>
  );

  return (
    <StyledPackageItem
      mostpopular={data.mostPopular ? 1 : 0}
      free={data.price ? 0 : 1}
    >
      <StyledRibbon mostpopular={data.mostPopular ? 1 : 0}>
        <div className="card-package">
          {renderDiscount(isVietNam ? data.discount : data.usdDiscount)}

          <CardContent className="card-content">
            <Box className="chip-wrapper">
              {data.mostPopular && (
                <div className="chip">
                  <Typography className="text-chip">{t('popular')}</Typography>
                </div>
              )}
              {data.recommended && (
                <div className="chip">
                  <Typography className="text-chip">
                    {t('recommended')}
                  </Typography>
                </div>
              )}
              {!data.mostPopular && !data.recommended && (
                <div className="chip-none" />
              )}
            </Box>
            <div className="card-info">
              {data.icon ? (
                <img src={data.icon} alt="icon-type" />
              ) : (
                <SchoolIcon
                  fontSize="large"
                  className={classnames({ 'most-popular': data.mostPopular })}
                />
              )}
              <Typography className="package-name">
                {data.name && (data.name[language] || data.name[LANGUAGE.EN])}
              </Typography>
              <div className="original-card">
                <>
                  {data.yearDiscount &&
                    renderYearDiscount(
                      isVietNam ? data.yearDiscount : data.usdYearDiscount,
                    )}
                </>
              </div>
              <Box className="price-wrapper">
                {isVietNam
                  ? renderPrice(
                      data.price,
                      data.monthlyPrice?.toLocaleString() ||
                        data.price?.toLocaleString(),
                    )
                  : renderPrice(
                      data.usdPrice,
                      `$${
                        data.usdMonthlyPrice?.toLocaleString() ||
                        data.usdPrice?.toLocaleString()
                      }`,
                    )}
              </Box>
              {((data.price === 0 && isVietNam) ||
                (data.usdPrice === 0 && !isVietNam)) && (
                <Typography className="text-limited">
                  {t('limitedUsageFeature')}
                </Typography>
              )}
            </div>
            <Button
              fullWidth
              className="button-start"
              variant={data.mostPopular ? 'contained' : 'outlined'}
              onClick={handleBuyPackage}
              disabled={!displayBuyButton}
            >
              {renderButtonText()}
            </Button>
            <List>
              {data?.contents?.length &&
                data.contents.map((item) => (
                  <ListItem className="clarify" key={item.vi}>
                    <ListItemIcon className="icon-contents">
                      {item.active ? (
                        <Check color="success" />
                      ) : (
                        <Clear color="error" />
                      )}
                    </ListItemIcon>
                    <div
                      className="content"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          item?.[language] || item[LANGUAGE.EN],
                        ),
                      }}
                    />
                  </ListItem>
                ))}
            </List>
            <Button
              fullWidth
              className="button-start"
              variant={data.mostPopular ? 'contained' : 'outlined'}
              onClick={handleBuyPackage}
              disabled={!displayBuyButton}
            >
              {renderButtonText()}
            </Button>
          </CardContent>
        </div>
      </StyledRibbon>
      {data.price === 0 && (
        <NotificationDialog
          name="pendingOrder"
          title={t('havePendingOrderTitle')}
          description={t('hasOrderWaitForConfirmTransferDescription')}
          variant="warning"
          open={openDialog}
          onClose={handleCloseDialog}
          actionComponent={
            <>
              <Button variant="outlined" onClick={handleCancelPendingOrder}>
                <ProcessHandler loading={loading.cancelOrder} size={25}>
                  {t('cancelOrder')}
                </ProcessHandler>
              </Button>
              <Button variant="contained" onClick={handleContinuePayingDialog}>
                {t('continue')}
              </Button>
            </>
          }
        />
      )}
    </StyledPackageItem>
  );
};

export default PackageItem;
