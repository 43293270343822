import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Typography } from '@mui/material';

import SimpleDialog from '@src/components/Dialog/SimpleDialog';
import DownloadIcon from '@src/assets/icons/download.svg';
import DeleteIcon from '@src/assets/icons/delete.svg';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { downloadAudioLinks } from '@src/apis/requests';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FREE_PACKAGE_CODE } from '@src/constants/package';
import { DOWNLOAD_QUOTA_EXCEEDED_CODE } from '@src/constants/tts';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { StyledActionWarning, StyledRequestsActionHeader } from './index.style';

const RequestsActionHeader = ({
  selectedRequests,
  onDeleteSelectedRequests,
  colPosition,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getFeatureValue } = useFeatureFlags();
  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const { usingPackage, usingDubbingPackage } = useSelector(
    (state) => state.user,
  );

  const [notification, setNotification] = useState({});
  const isDubbingRoute = useCheckDubbingRoute();

  const renderConfirmActions = ({ handleClose, handleConfirm }) => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleClose} className="no-button">
        {t('noValue')}
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const getDownloadableRequests = (requests) => {
    const downloadableRequests = requests.filter(
      ({ status, retentionPeriod, createdAt }) => {
        const audioSuccess = status === REQUEST_STATUS.SUCCESS;
        const audioExpired = moment().isAfter(
          moment(createdAt).add(retentionPeriod, 'days'),
        );
        return audioSuccess && !audioExpired;
      },
    );

    return downloadableRequests;
  };

  const getExpiredRequests = (requests) => {
    const expiredRequests = requests.filter(
      ({ status, retentionPeriod, createdAt }) =>
        status === REQUEST_STATUS.SUCCESS &&
        moment().isAfter(moment(createdAt).add(retentionPeriod, 'days')),
    );
    return expiredRequests;
  };

  const getFailedRequests = (requests) => {
    const failedRequests = requests.filter(
      ({ status }) => status !== REQUEST_STATUS.SUCCESS,
    );
    return failedRequests;
  };

  const handleCloseNotification = () => setNotification({});

  const handleDeleteSelectedRequests = () => onDeleteSelectedRequests();

  const handleOpenDeleteRequests = () => {
    setNotification({
      open: true,
      title: t('deleteRequestTitle'),
      description: t('deleteRequestTitleDescription', {
        total: selectedRequests.length,
      }),
      actionComponent: renderConfirmActions({
        handleClose: handleCloseNotification,
        handleConfirm: () => {
          handleDeleteSelectedRequests();
          handleCloseNotification();
        },
      }),
    });
  };

  const handleDownloadRequests = async () => {
    const packageCode = isDubbingRoute
      ? usingDubbingPackage.code
      : usingPackage.code;

    if (
      isLimitDownloadFreePackage &&
      selectedRequests.length > 1 &&
      FREE_PACKAGE_CODE.includes(packageCode)
    ) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }
    const validRequests = getDownloadableRequests(selectedRequests);

    const useOldDownloadAll = getFeatureValue(FEATURE_KEYS.DOWNLOAD_AUDIO_LINK);
    if (useOldDownloadAll) {
      const listAudioLink = validRequests.map((req) => req.audioLink);
      downloadAudioLinks(listAudioLink);
      return;
    }

    const presignedUrls = [];
    await Promise.all(
      validRequests.map(async ({ id, title }) => {
        try {
          const res = isLimitDownloadFreePackage
            ? await apis.requests.getAudioDownloadUrl(id)
            : await apis.requests.getPresignedAudioUrl(id);
          if (res?.status === 1) presignedUrls.push(res.result?.audio);
          else {
            if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
              dispatch(actions.user.updateShowBlockDownloadDialog(true));
              return;
            }
            throw new Error();
          }
        } catch (error) {
          dispatch(
            actions.noti.push({
              severity: 'error',
              message: 'downloadError',
              value: title,
            }),
          );
        }
      }),
    );

    downloadAudioLinks(presignedUrls);
  };

  const validateRequestsForDownload = (requests) => {
    const validAudios = getDownloadableRequests(requests);
    const expiredAudios = getExpiredRequests(requests);
    const failedAudios = getFailedRequests(requests);

    return { validAudios, expiredAudios, failedAudios };
  };

  const handleOpenDownloadPopup = () => {
    let open = true;
    let description = '';
    let actionComponent;

    const { validAudios, expiredAudios, failedAudios } =
      validateRequestsForDownload(selectedRequests);

    const totalValid = validAudios?.length || 0;
    const totalExpired = expiredAudios?.length || 0;
    const totalFailed = failedAudios?.length || 0;

    if (!totalValid) {
      // There is no valid audios
      const expireTime = expiredAudios[0]?.retentionPeriod;
      if (totalExpired > 0) description = t('invalidAudios', { expireTime });
      else description = t('cannotDownloadFailedRequests');
      actionComponent = (
        <StyledActionWarning>
          <Button onClick={handleCloseNotification} variant="contained">
            {t('understood')}
          </Button>
        </StyledActionWarning>
      );
    } else if (totalValid !== selectedRequests.length) {
      // There are some valid audios, some invalid
      if (totalFailed === 0) {
        description = t('downloadDescriptionForExpired', {
          total: selectedRequests.length,
          totalValid,
          totalInvalid: selectedRequests.length - totalValid,
          expire: expiredAudios[0]?.retentionPeriod,
        });
      } else if (totalExpired === 0) {
        description = t('downloadDescriptionForFailed', {
          total: selectedRequests.length,
          totalValid,
          totalInvalid: selectedRequests.length - totalValid,
        });
      } else {
        description = t('downloadDescription', {
          total: selectedRequests.length,
          totalValid,
          totalExpired,
          totalFailed,
          expire: expiredAudios[0]?.retentionPeriod,
        });
      }
      actionComponent = renderConfirmActions({
        handleClose: handleCloseNotification,
        handleConfirm: () => {
          handleDownloadRequests();
          handleCloseNotification();
        },
      });
    } else open = false;

    if (open)
      setNotification({
        open,
        title: t('downloadTitle'),
        description,
        actionComponent,
      });
    else handleDownloadRequests();
  };

  return (
    <StyledRequestsActionHeader>
      {colPosition === 0 ? (
        <>
          <Typography className="selected-requests ">
            {t('selectedRequests', { value: selectedRequests?.length || 0 })}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenDownloadPopup}
            disabled={selectedRequests?.length === 0}
            className="download-button"
            startIcon={<img src={DownloadIcon} alt="download" />}
          >
            {t('download')}
          </Button>
        </>
      ) : (
        <Button
          variant="outlined"
          color="error"
          onClick={handleOpenDeleteRequests}
          disabled={selectedRequests?.length === 0}
          className="delete-button"
          startIcon={<img src={DeleteIcon} alt="delete" />}
        >
          {t('delete')}
        </Button>
      )}

      <SimpleDialog
        name="notification-dialog"
        className="notification-dialog"
        title={notification?.title}
        description={notification?.description || ''}
        open={notification?.open}
        onClose={handleCloseNotification}
        actionComponents={notification?.actionComponent}
        PaperProps={{ sx: { borderRadius: '12px', padding: '20px' } }}
      />
    </StyledRequestsActionHeader>
  );
};

export default RequestsActionHeader;
