import { v4 as uuidv4 } from 'uuid';
import { convertToRaw, convertFromRaw } from 'draft-js';
import {
  DEFAULT_BREAK_TIME,
  DEFAULT_SPEED,
  REGEX,
  TTS_ATTRIBUTE,
} from '@src/constants/voice';
import { getElement } from '@src/services/paragraph';
import { convertElementsToText } from '@src/services/entity';

const changeDifferentPositionOfElements = (elements, startPosition) => {
  const firstElement = elements[0];
  if (!firstElement) return [];

  const offset = startPosition - (firstElement?.startOffset || 0);

  const customElements = elements.map((item) => {
    const { text: itemText, startOffset, endOffset } = item;
    const elementItem = {
      ...item,
      startOffset: startOffset + offset,
      endOffset: endOffset + offset,
      text: itemText,
    };

    return elementItem;
  });

  return customElements;
};

const splitElementsByOffset = (elements, offset) => {
  if (!elements?.length) return { firstElements: [], lastElements: [] };

  const elementIndex = elements.findIndex(
    (item) => item.startOffset <= offset && item.endOffset >= offset,
  );
  if (elementIndex === -1) return { firstElements: [], lastElements: [] };

  let firstElements = elements.slice(0, elementIndex);
  const betweenElement = elements[elementIndex];
  const { startOffset, endOffset, text } = betweenElement;
  if (startOffset !== offset) {
    const firstBetweenElement = {
      ...betweenElement,
      endOffset: offset,
      text: text.substring(0, offset - startOffset),
    };
    firstElements = [...firstElements, firstBetweenElement];
  }

  const lastBetweenText = text.substring(
    offset - startOffset,
    endOffset - startOffset,
  );
  const lastBetweenElement = {
    ...betweenElement,
    startOffset: 0,
    endOffset: lastBetweenText.length,
    text: lastBetweenText,
  };
  const afterElements = changeDifferentPositionOfElements(
    elements.slice(elementIndex + 1),
    lastBetweenText.length,
  );
  const lastElements = [lastBetweenElement, ...afterElements];

  return { firstElements, lastElements };
};

const onHandleEnterCommand = ({
  selectedText1,
  selectedText2,
  sentence,
  sentences,
  onChangeSentences,
}) => {
  const { elements } = sentence;
  const offset = selectedText1.length;
  const { firstElements, lastElements } = splitElementsByOffset(
    elements,
    offset,
  );

  const splitSentences = [
    { ...sentence, text: selectedText1, onLoad: true, elements: firstElements },
    {
      ...sentence,
      text: selectedText2,
      elements: lastElements,
      id: uuidv4(),
      onLoad: true,
      mousePointerPosition: 0,
    },
  ];
  const index = sentences.findIndex((item) => item.id === sentence.id);
  if (index >= 0) {
    const newSentences = [
      ...sentences.slice(0, index),
      ...splitSentences,
      ...sentences.slice(index + 1),
    ];
    onChangeSentences(newSentences);
  }
};

const onHandleBackspaceCommand = ({
  selectedText2,
  sentence,
  sentences,
  onChangeSentences,
}) => {
  const index = sentences.findIndex((item) => item.id === sentence.id);
  if (index >= 1) {
    const prevSentence = sentences[index - 1];
    const currentSentence = sentences[index];
    const { text: prevText, elements: prevElements = [] } = prevSentence;
    const { elements: currentElements = [] } = currentSentence;

    const afterElements = changeDifferentPositionOfElements(
      currentElements,
      prevText.length,
    );
    const elements = [...prevElements, ...afterElements];

    const newSentences = [
      ...sentences.slice(0, index - 1),
      {
        ...prevSentence,
        text: prevText + selectedText2,
        elements,
        onLoad: true,
        mousePointerPosition: prevText.length,
      },
      ...sentences.slice(index + 1),
    ];
    onChangeSentences(newSentences);
  }
};

const onHandleKeyCommand = ({
  content,
  command,
  sentence,
  sentences,
  onChangeSentences,
}) => {
  const selectionState = content.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = content.getCurrentContent();
  const offset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();

  const blockMap = currentContent.getBlockMap();
  const currentBlock = blockMap.get(anchorKey);

  const beforeBlocks = blockMap.toSeq().takeUntil((v) => v === currentBlock);
  const beforeBlocksText = beforeBlocks.map((block) => block.text).join('\n');
  const beforeCurrentBlockText = currentBlock.getText().slice(0, offset);
  const selectedText1 = beforeBlocksText
    ? `${beforeBlocksText}\n${beforeCurrentBlockText}`
    : beforeCurrentBlockText;

  const afterBlocks = blockMap
    .toSeq()
    .skipUntil((v) => v === currentBlock)
    .rest();
  const afterBlocksText = afterBlocks.map((block) => block.text).join('\n');
  const afterCurrentBlockText = currentBlock.getText().slice(offset);
  const selectedText2 = afterBlocksText
    ? `${afterCurrentBlockText}\n${afterBlocksText}`
    : afterCurrentBlockText;

  if (command === 'enter_command') {
    onHandleEnterCommand({
      selectedText1,
      selectedText2,
      sentence,
      sentences,
      onChangeSentences,
    });
    return 'handled';
  }

  if (!selectedText1 && offset === endOffset && command === 'backspace') {
    onHandleBackspaceCommand({
      selectedText2,
      sentence,
      sentences,
      onChangeSentences,
    });
    return 'handled';
  }
  return 'not-handled';
};

const getSelectedText = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blocks = contentState.getBlockMap();
  let lastWasEnd = false;

  const selectedBlock = blocks
    .skipUntil((block) => block?.getKey() === startKey)
    .takeUntil((block) => {
      const result = lastWasEnd;
      if (block?.getKey() === endKey) lastWasEnd = true;
      return result;
    });

  const selectedText = selectedBlock
    .map((block) => {
      const key = block?.getKey() || '';
      const text = block?.getText() || '';
      const start = key === startKey ? selectionState.getStartOffset() : 0;
      const end = key === endKey ? selectionState.getEndOffset() : text.length;
      return text.slice(start, end);
    })
    .join('\n');

  return selectedText;
};

const getSentencesPayload = (sentences) => {
  const sentencesPayload = sentences.reduce((acc, current) => {
    if (current.text) {
      return [
        ...acc,
        {
          text: current.text,
          voiceCode: current.voice && current.voice.code,
          voiceProvider: current?.voice?.provider,
          breakTime: current.breakTime,
          speed: current.speed,
        },
      ];
    }
    return acc;
  }, []);
  return sentencesPayload;
};

const newGetSentencesPayload = (sentences) => {
  const sentencesPayload = sentences.reduce((acc, current) => {
    const { text, elements } = current;
    if (text && text.trim()) {
      return [
        ...acc,
        {
          elements,
          text: convertElementsToText(elements),
          voiceCode: current.voice && current.voice.code,
          voiceProvider: current?.voice?.provider,
          speed: current.speed,
        },
      ];
    }
    return acc;
  }, []);
  return sentencesPayload;
};

const convertSentenceToParagraphs = (sentences) =>
  sentences.reduce((acc, curr) => {
    const { id: key, text, elements } = curr;
    if (!elements?.length) return acc;
    return [...acc, { key, text, elements }];
  }, []);

const newSplitParagraphIntoSentence = (paragraphs, voice) =>
  paragraphs.map((paragraph) => {
    const { key, text, elements } = paragraph;
    return {
      id: key,
      voice,
      text,
      speed: DEFAULT_SPEED,
      elements,
    };
  });

const splitParagraphIntoSentence = (paragraph, voice) => {
  const BREAK_LINE_DELIMITER = /\r?\n/;
  const EMPTY_LINE = /^\s*\n/gm;
  const stringRemoveEmptyLine = paragraph.trim().replace(EMPTY_LINE, '');
  const sentencesTokenizer = stringRemoveEmptyLine.split(BREAK_LINE_DELIMITER);

  const newSentences = sentencesTokenizer.map((item) => {
    const text = item.trim();
    return {
      id: uuidv4(),
      voice,
      text,
      breakTime: DEFAULT_BREAK_TIME,
      speed: DEFAULT_SPEED,
      elements: [
        {
          key: uuidv4(),
          text,
          startOffset: 0,
          endOffset: text.length,
          name: null,
          value: null,
        },
      ],
    };
  });

  return newSentences;
};

const handleEditElements = (editorState) => {
  const selectionState = editorState.getSelection();

  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const selectedText = currentContentBlock.getText();
  const { entityMap } = convertToRaw(currentContent);

  const { entityRanges } = convertToRaw(currentContent).blocks[0];

  let startIndex = 0;
  let endIndex = 0;
  const elements = [];

  if (entityRanges && entityRanges.length !== 0) {
    entityRanges.forEach((entity) => {
      if (startIndex < entity.offset) {
        const element = getElement(selectedText, startIndex, entity.offset);
        elements.push(element);
        startIndex = entity.offset;
        endIndex = entity.offset;
      }

      const element = {
        key: uuidv4(),
        endOffset: entity.offset + entity.length,
        startOffset: entity.offset,
        name: entityMap[entity.key].data.name,
        value: entityMap[entity.key].data.value,
        text: selectedText.substring(
          entity.offset,
          entity.offset + entity.length,
        ),
      };

      elements.push(element);
      startIndex = entity.offset + entity.length;
      endIndex = entity.offset + entity.length;
    });

    if (endIndex !== selectedText.length) {
      const element = getElement(selectedText, endIndex, selectedText.length);
      elements.push(element);
    }
  } else {
    const data = getElement(selectedText, 0, selectedText.length);
    elements.push(data);
  }

  return elements;
};

const countSentenceLength = (sentence) => {
  const { elements, text } = sentence;
  const textLength = text.length || 0;

  const breakTimeElements = elements.filter(
    (item) => item.name === TTS_ATTRIBUTE.BREAK_TIME,
  );
  if (!breakTimeElements?.length) return textLength;
  const breakTimeLength = breakTimeElements.reduce((acc, curr) => {
    const length = curr.text ? curr.text.length : 0;
    return acc + length;
  }, 0);
  return textLength - breakTimeLength || 0;
};

const calTotalCharactersExcludingCurrSentence = (sentences, sentenceId) => {
  const checkValidSentence = (sentenceItem) =>
    sentenceItem.text && sentenceItem.id !== sentenceId;

  return sentences.reduce(
    (sum, item) =>
      checkValidSentence(item) ? sum + countSentenceLength(item) : sum,
    0,
  );
};

const convertElementsToState = (elements, text, sentenceId) => {
  const rawContent = {
    blocks: [{ key: sentenceId, text, type: 'unstyled', entityRanges: [] }],
    entityMap: {},
  };

  const elementsHasStyle = elements.filter((item) => item.name);

  elementsHasStyle.forEach((param) => {
    const { startOffset, endOffset, name, value } = param;
    const style = `${name}-${value}`;

    rawContent.blocks[0].entityRanges.push({
      offset: startOffset,
      length: endOffset - startOffset,
      key: style,
    });

    rawContent.entityMap[style] = {};
    rawContent.entityMap[style].data = { name, value };
    rawContent.entityMap[style].type = 'TOKEN';
    rawContent.entityMap[style].mutability = 'MUTABLE';
  });

  return convertFromRaw(rawContent);
};

const handlePastedManyBlocks = (textBlocks, currentSentence, pastedPos) => {
  // Current sentence info
  const {
    elements,
    text: sentenceText,
    speed,
    voice: sentenceVoice,
  } = currentSentence;

  // First text is pasted in
  const firstPastedText = textBlocks[0];
  // Last text is pasted in
  const lastPastedText = textBlocks[textBlocks.length - 1];

  // Position difference of the last element
  let gap = 0;
  // Elements of this sentence at before paste position
  let firstElements = [];
  // Elements of this sentence at after paste position
  let lastElements = [];

  // eslint-disable-next-line consistent-return
  elements.forEach((element) => {
    const { startOffset, endOffset, text } = element;

    // Preceding element has nothing to do with pasted text
    if (startOffset < pastedPos && endOffset < pastedPos)
      firstElements = [...firstElements, element];

    // Ambiguous element at pasted position
    if (startOffset <= pastedPos && endOffset > pastedPos) {
      if (startOffset !== pastedPos) {
        const firstPasteElement = getElement(
          firstPastedText,
          0,
          firstPastedText.length,
        );
        const firstBetweenElement = {
          ...element,
          text: sentenceText.substring(startOffset, pastedPos),
          endOffset: pastedPos,
        };
        firstElements = [
          ...firstElements,
          firstBetweenElement,
          firstPasteElement,
        ];
      }

      const lastPasteEle = getElement(lastPastedText, 0, lastPastedText.length);

      const eleText = sentenceText.substring(pastedPos, endOffset);
      const lastBetweenEle = {
        ...element,
        text: eleText,
        startOffset: lastPastedText.length,
        endOffset: lastPastedText.length + eleText.length,
      };

      gap = lastPastedText.length + eleText.length;
      lastElements = [...lastElements, lastPasteEle, lastBetweenEle];
    }

    // The following element is not related to the pasted text
    if (startOffset > pastedPos && endOffset > pastedPos) {
      lastElements = [
        ...lastElements,
        {
          ...element,
          startOffset: gap,
          endOffset: gap + text.length,
        },
      ];
      gap += text.length;
    }
  });
  // The first sentence when pasted in
  const firstSentences = {
    ...currentSentence,
    text: `${sentenceText.substring(0, pastedPos)}${firstPastedText}`,
    elements: firstElements,
    onLoad: true,
  };

  // The between sentences when pasted in
  const betweenSentences = textBlocks
    .slice(1, textBlocks.length - 1)
    .reduce((acc, curr) => {
      if (!curr || !curr.trim()) return acc;
      return [
        ...acc,
        {
          id: uuidv4(),
          voice: sentenceVoice,
          speed,
          text: curr,
          elements: [getElement(curr, 0, curr.length)],
          onLoad: true,
        },
      ];
    }, []);

  // The last sentence when pasted in
  const lastText = `${lastPastedText}${sentenceText.substring(pastedPos)}`;
  const lastSentences = {
    voice: sentenceVoice,
    speed,
    id: uuidv4(),
    text: lastText,
    elements: lastElements,
    onLoad: true,
    mousePointerPosition: lastText.length,
  };

  const newSentences = [firstSentences, ...betweenSentences, lastSentences];

  return newSentences;
};

const handleSelectedTextOfSentence = ({
  selectionState,
  contentState,
  elements,
}) => {
  const anchorKey = selectionState.getAnchorKey();
  const currentContentBlock = contentState.getBlockForKey(anchorKey);
  let startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();

  // Check case of choose break time at the start of selected block
  const firstSelectedEle = elements?.length && elements[0];
  if (
    firstSelectedEle &&
    firstSelectedEle?.name === TTS_ATTRIBUTE.BREAK_TIME &&
    startOffset === 0
  ) {
    const breakTimeValue = `${firstSelectedEle.value}s`;
    startOffset += breakTimeValue.length;
  }
  const selectedText = currentContentBlock
    .getText()
    .slice(startOffset, endOffset);

  return { startOffset, endOffset, selectedText };
};

const handleInsertElementOfSentence = ({
  elements,
  sentenceText,
  focusOffset,
  elementName,
  elementValue,
}) => {
  const insertedTextLength = `${elementValue}s`.length;

  const insertedElement = {
    key: uuidv4(),
    name: elementName,
    value: elementValue,
    text: `${elementValue}s`,
    startOffset: focusOffset,
    endOffset: focusOffset + insertedTextLength,
  };

  const newElements = [];

  const newSentenceText =
    `${sentenceText.slice(0, focusOffset)}` +
    `${elementValue}s` +
    `${sentenceText.slice(focusOffset, sentenceText.length)}`;
  let isAddedBreakTime = false;

  let delta = 0;
  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    const { startOffset, endOffset, name, value } = element;

    if (startOffset === focusOffset) {
      if (!isAddedBreakTime) {
        newElements.push(insertedElement);
        delta += insertedTextLength;
        isAddedBreakTime = true;
      }
      const newElement = {
        ...element,
        startOffset: startOffset + delta,
        endOffset: endOffset + delta,
      };
      newElements.push(newElement);
    }

    if (startOffset < focusOffset && endOffset > focusOffset) {
      const firstElement = getElement(
        newSentenceText,
        startOffset,
        focusOffset,
        name,
        value,
      );

      newElements.push(firstElement);
      newElements.push(insertedElement);

      delta += insertedTextLength;

      const secondElement = getElement(
        newSentenceText,
        focusOffset + delta,
        endOffset + delta,
        name,
        value,
      );
      newElements.push(secondElement);
    }

    if (endOffset === focusOffset) {
      newElements.push(element);
      if (!isAddedBreakTime) {
        delta += insertedTextLength;
        newElements.push(insertedElement);
        isAddedBreakTime = true;
      }
    }

    if (startOffset > focusOffset || endOffset < focusOffset) {
      const newElement = {
        ...element,
        startOffset: startOffset + delta,
        endOffset: endOffset + delta,
      };
      newElements.push(newElement);
    }
  }
  return { elements: newElements, text: newSentenceText };
};

const convertOldSentencesToNewSentences = (oldSentences) => {
  const newSentences = oldSentences.map((sentence) => {
    if (sentence?.elements?.length) {
      const sentenceText = sentence.elements.map((item) => item.text).join('');
      return {
        ...sentence,
        id: uuidv4(),
        text: sentenceText,
        elements: sentence.elements,
        onload: true,
      };
    }

    let sentenceText = sentence.text;
    let elements = [
      {
        endOffset: sentenceText.length,
        key: uuidv4(),
        name: null,
        startOffset: 0,
        text: sentenceText,
        value: null,
      },
    ];
    const { breakTime } = sentence;
    if (breakTime !== 1) {
      const breakTimeText = `${breakTime}s`;
      elements = [
        ...elements,
        {
          key: uuidv4(),
          name: TTS_ATTRIBUTE.BREAK_TIME,
          value: breakTime,
          startOffset: sentenceText.length,
          endOffset: sentenceText.length + breakTimeText.length,
          text: breakTimeText,
        },
      ];
      sentenceText = `${sentenceText}${breakTimeText}`;
    }

    return {
      ...sentence,
      id: uuidv4(),
      text: sentenceText,
      elements,
      onload: true,
    };
  });

  return newSentences;
};

const convertNewSentencesToOldSentences = (sentences) => {
  const newSentences = sentences.map((sentence) => {
    const { elements } = sentence;
    const breakTimeElement = elements.filter(
      (item) => item.name === TTS_ATTRIBUTE.BREAK_TIME,
    );
    const lastBreakTimeElement = breakTimeElement[breakTimeElement.length - 1];
    const breakTime = lastBreakTimeElement?.value || 1;

    const text = convertElementsToText(elements)
      .replace(REGEX.ADVANCE_TAG, '')
      .trim();

    return {
      ...sentence,
      breakTime,
      elements: [],
      text,
      onLoad: true,
    };
  });

  return newSentences;
};

export {
  onHandleKeyCommand,
  getSelectedText,
  newSplitParagraphIntoSentence,
  splitParagraphIntoSentence,
  getSentencesPayload,
  handleEditElements,
  countSentenceLength,
  calTotalCharactersExcludingCurrSentence,
  convertElementsToState,
  handlePastedManyBlocks,
  handleSelectedTextOfSentence,
  handleInsertElementOfSentence,
  newGetSentencesPayload,
  convertSentenceToParagraphs,
  convertOldSentencesToNewSentences,
  convertNewSentencesToOldSentences,
};
