import React, { useState } from 'react';
import { SpeedDial, SpeedDialIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { faPhone, faXmark } from '@fortawesome/free-solid-svg-icons';
import QuestionAnswer from '@src/assets/images/quest-ans.svg';
import { CUSTOMER_SUPPORT_PHONE_NUMBER, VBEE_ZALO_URL } from '@src/configs';
import { HELP_CONTACT_METHOD } from '@src/constants';
import { StyledCustomerCare, StyledIconWraper } from './index.style';

const helpMenu = [
  {
    type: HELP_CONTACT_METHOD.ZALO,
    icon: <StyledIconWraper>Zalo</StyledIconWraper>,
    url: VBEE_ZALO_URL,
  },
  {
    type: HELP_CONTACT_METHOD.CALL,
    icon: (
      <StyledIconWraper>
        <FontAwesomeIcon icon={faPhone} />
      </StyledIconWraper>
    ),
    url: `tel:${CUSTOMER_SUPPORT_PHONE_NUMBER}`,
  },
];

const CustomerCare = () => {
  const [openContacts, setOpenContacts] = useState(false);

  const handleClickContact = (contactMethod) => {
    const { url, type } = contactMethod;
    switch (type) {
      case HELP_CONTACT_METHOD.CALL:
        window.location.assign(url);
        break;
      case HELP_CONTACT_METHOD.ZALO:
        window.open(url);
        break;

      default:
        break;
    }
  };

  const handleOpenContacts = () => {
    setOpenContacts(!openContacts);
    setTimeout(() => {
      document.getElementById('fb-root').style.display = openContacts
        ? 'none'
        : 'block';
    }, 100);
  };

  return (
    <StyledCustomerCare>
      <div className="customer-care-container">
        <SpeedDial
          ariaLabel="SpeedDial"
          open={openContacts}
          onClick={handleOpenContacts}
          icon={
            <SpeedDialIcon
              openIcon={
                <StyledIconWraper>
                  <FontAwesomeIcon icon={faXmark} className="close-icon" />
                </StyledIconWraper>
              }
              icon={<img src={QuestionAnswer} alt="question-answer" />}
            />
          }
          style={{ width: 44 }}
        >
          {helpMenu.map((contactMethod) => (
            <SpeedDialAction
              className={contactMethod.type}
              key={contactMethod.type}
              icon={contactMethod.icon}
              tooltipOpen
              onClick={() => handleClickContact(contactMethod)}
            />
          ))}
        </SpeedDial>
      </div>
    </StyledCustomerCare>
  );
};
export default CustomerCare;
