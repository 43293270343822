import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import UpgradeImg from '@src/assets/images/space-shuttle.png';
import route from '@src/constants/route';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { LANGUAGE, PAGINATION_LIMIT } from '@src/constants';
import { createFileFromText } from '@src/utils/file';
import { parseSRTFile } from '@src/utils/srt';
import { Box, Button } from '@mui/material';
import { REQUEST_STATUS } from '@src/constants/voice';
import { FETCH_REQUESTS_INTERVAL } from '@src/constants/websocket';
import {
  StyledDubbing,
  StyledDubbingList,
  StyledDubbingWithVideo,
} from './index.style';
import HistoryChanged from './HistoryChanged';
import Sentences from './Sentences';
import TitleBar from './TitleBar';
import ToolBar from './ToolBar';
import TimeFrame from './TimeFrame';
import VideoPlayer from './VideoPlayer';

const OTHER_ELEMENT_HEIGHT = 120;
const OLD_NAVBAR_HEIGHT = 90;
const ProjectDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[3];
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVietNameseLanguage = language === LANGUAGE.VN;

  // Ref
  const checkFirstTimeGetData = useRef(true);
  const requestTableRef = useRef();
  const timeFrameRef = useRef();
  const timeLineRef = useRef(null);

  // State
  const [isExpandTimeFrame, setIsExpandTimeFrame] = useState(true);
  const [isExpandRequest, setIsExpandRequest] = useState(false);
  const [dubbingRequests, setDubbingRequests] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [page, setPage] = useState(1);
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestTableHeight, setRequestTableHeight] = useState(0);
  const [loadingDubbingSentences, setLoadingDubbingSentences] = useState(false);
  const [actionDialog, setActionDialog] = useState();
  const [showDialogHistoryChanged, setShowDialogHistoryChanged] =
    useState(false);
  const [hasDeleteSentences, setHasDeleteSentences] = useState(false);
  const [isProcessingSubtitles, setIsProcessingSubtitles] = useState(true);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedSentencesId, setSelectedSentencesId] = useState();
  const [isChangeTimeByClick, setIsChangeTimeByClick] = useState(false);

  // Redux
  const { user } = useSelector((state) => state.auth);
  const { dubbingRequest: dubbingRequestState } = useSelector((state) => state);
  const {
    projectInfo,
    changedProjectInfo,
    title: updatedTitle,
    voice: updatedVoice,
  } = useSelector((state) => state.dubbingRequest);
  const { limitedFeature } = useSelector((state) => state.dialog);
  const { showBlockDownloadDialog } = useSelector((state) => state.user);

  // Feature flag
  const { getFeatureValue } = useFeatureFlags();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const useDubbingSubtitleTimeline = getFeatureValue(
    FEATURE_KEYS.DUBBING_SUBTITLE_TIMELINE,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isDubbingMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const viewVideoWithTimeline = getFeatureValue(
    FEATURE_KEYS.VIEW_VIDEO_WITH_TIMELINE,
    { email: user.email, userId: user.id, phoneNumber: user.phoneNumber },
  );

  const calculateRequestTableHeight = () => {
    const reqHeight = dubbingRequests?.length
      ? (requestTableRef?.current?.offsetHeight || 0) +
        OTHER_ELEMENT_HEIGHT +
        OLD_NAVBAR_HEIGHT
      : OTHER_ELEMENT_HEIGHT + OLD_NAVBAR_HEIGHT;
    setRequestTableHeight(reqHeight);
  };

  const calculateTimeFrameHeight = () => {
    const OTHER_HEIGHT = 180;

    const timeFrameOffsetHeight =
      (timeFrameRef?.current?.offsetHeight || 0) + OTHER_HEIGHT;

    setRequestTableHeight(timeFrameOffsetHeight);
  };

  const handleChangeRequestLoading = useCallback(
    (value) => setRequestLoading(value),
    [],
  );

  const handleChangePage = (newPage) => setPage(newPage);

  const handleChangeIsExpandRequest = () =>
    setIsExpandRequest(!isExpandRequest);

  const getSubtitlesData = async (url) => {
    if (!url) return {};

    const response = await fetch(url);
    const text = await response.text();
    if (text.length === 0) return {};
    const srtFile = createFileFromText('subtitle.srt', text);
    const parseResult = await parseSRTFile(srtFile);

    return parseResult?.parsedSubtitles?.reduce((sentences, currBlock) => {
      const sentenceId = uuid();
      // eslint-disable-next-line no-param-reassign
      sentences[sentenceId] = currBlock;
      return sentences;
    }, {});
  };

  const getOriginalDataWhenReceiveResponse = async (
    url,
    translatedSentences,
  ) => {
    if (!url) return {};

    const response = await fetch(url);
    const text = await response.text();
    if (text.length === 0) return {};
    const srtFile = createFileFromText('subtitle.srt', text);
    const parseResult = await parseSRTFile(srtFile);

    const translatedSentencesKeys = Object.keys(translatedSentences || {});

    return parseResult?.parsedSubtitles?.reduce(
      (sentences, currBlock, index) => {
        const sentenceId = translatedSentencesKeys[index];
        // eslint-disable-next-line no-param-reassign
        sentences[sentenceId] = {
          ...currBlock,
        };
        return sentences;
      },
      {},
    );
  };

  const getVoiceCodeUsedInSentence = ({
    sentencesVoiceCode = {},
    sentenceIndex,
    projectVoiceCode,
  }) => {
    const voiceCodes = Object.keys(sentencesVoiceCode);

    if (voiceCodes.length === 0) return projectVoiceCode;

    return (
      voiceCodes.find((voice) =>
        sentencesVoiceCode[voice].includes(sentenceIndex),
      ) || ''
    );
  };

  const fetchLanguages = async () => {
    const res = await apis.dubbing.getLanguages({});

    if (res?.status) {
      const totalLanguages = res.result.languages
        .filter((lang) => lang.available)
        .sort((a, b) => a.rank - b.rank)
        .map((lang) => ({
          value: lang.code,
          country: lang.code,
          label: isVietNameseLanguage ? lang.vietnameseName : lang.globalName,
          flag: lang.roundImage,
        }));

      setAvailableLanguages(totalLanguages);
    }
  };

  const fetchProjectDetail = async () => {
    setLoadingDubbingSentences(true);
    const res = await apis.dubbing.getDubbingProjectDetail(projectId);
    const newProjectInfo = { ...projectInfo };

    if (res.status) {
      const {
        title,
        voiceCode,
        speed,
        currentSubtitleLink,
        latestRequestId,
        status,
        updatedAt,
        sentencesVoiceCode,
        originalInfo,
      } = res.result;
      // Save information to redux
      newProjectInfo.voiceCode = voiceCode;
      newProjectInfo.currentSubtitleLink = currentSubtitleLink;
      newProjectInfo.latestRequestId = latestRequestId;
      newProjectInfo.projectStatus = status;
      newProjectInfo.updatedAt = updatedAt;
      newProjectInfo.originalInfo = originalInfo;

      const updateActions = [
        actions.dubbingRequest.updateDubbingRequestByKey('changedProjectInfo', {
          ...changedProjectInfo,
          title,
          voiceCode,
          speed: speed || 1,
        }),
        actions.dubbingRequest.updateDubbingRequestByKey('title', title),
        actions.dubbingRequest.updateDubbingRequestByKey('speed', speed || 1),
        actions.dubbingRequest.updateDubbingRequestByKey(
          'projectInfo',
          newProjectInfo,
        ),
      ];

      updateActions.forEach((action) => dispatch(action));

      if (currentSubtitleLink !== undefined) {
        setIsProcessingSubtitles(false);
        const subtitleSentences = await getSubtitlesData(currentSubtitleLink);

        let sentences = subtitleSentences;
        if (isDubbingMultipleVoices) {
          const parsedSentencesVoiceCode = sentencesVoiceCode
            ? JSON.parse(sentencesVoiceCode)
            : {};

          sentences = Object.keys(subtitleSentences).map((sentence, index) => {
            const sentenceVoiceCode = getVoiceCodeUsedInSentence({
              sentencesVoiceCode: parsedSentencesVoiceCode,
              sentenceIndex: index,
              projectVoiceCode: voiceCode,
            });

            return {
              ...subtitleSentences[sentence],
              voiceCode: sentenceVoiceCode,
            };
          });
        }

        dispatch(
          actions.dubbingRequest.updateDubbingRequestByKey(
            'sentences',
            sentences,
          ),
        );
      }

      if (originalInfo?.subtitleLink !== undefined && isMultipleInputDubbing) {
        const originalSubtitleSentences = await getSubtitlesData(
          originalInfo?.subtitleLink,
        );

        let sentences = originalSubtitleSentences;
        if (isDubbingMultipleVoices) {
          const parsedSentencesVoiceCode = sentencesVoiceCode
            ? JSON.parse(sentencesVoiceCode)
            : {};

          sentences = Object.keys(originalSubtitleSentences).map(
            (sentence, index) => {
              const sentenceVoiceCode = getVoiceCodeUsedInSentence({
                sentencesVoiceCode: parsedSentencesVoiceCode,
                sentenceIndex: index,
                projectVoiceCode: voiceCode,
              });

              return {
                ...originalSubtitleSentences[sentence],
                voiceCode: sentenceVoiceCode,
              };
            },
          );
        }
        dispatch(
          actions.dubbingRequest.updateDubbingRequestByKey(
            'originalSentences',
            sentences,
          ),
        );
      }
    } else {
      history.push(route.HOME_DUBBING);
    }
    checkFirstTimeGetData.current = false;
    setLoadingDubbingSentences(false);
  };

  const fetchDubbingRequests = async () => {
    const payload = {
      offset: (page - 1) * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
      sort: 'createdAt_desc',
      fields:
        'id,title,characters,seconds,createdAt,progress,status,voice,audioType,audioLink,retentionPeriod,processingAt,type,sentencesVoiceCode',
      projectId,
    };
    const res = await apis.dubbing.getDubbingRequests(payload);

    if (res.status) {
      setTotalRequests(res?.result?.metadata?.total);
      setDubbingRequests(res.result.requests);

      const latestRequest = res.result.requests?.[0];

      if (
        latestRequest?.status === REQUEST_STATUS.SUCCESS &&
        latestRequest?.audioLink
      ) {
        dispatch(
          actions.audioPlayer.updateAudioLink(latestRequest.audioLink, false),
        );
        dispatch(
          actions.dubbingRequest.updateDubbingRequestByKey(
            'isSelectListenOriginalAudio',
            false,
          ),
        );
      }
    }
  };

  const checkSentenceChanged = (oldSentence, changedSentence) => {
    if (!oldSentence) return false;

    const oldSentenceKeys = Object.keys(oldSentence || {});
    const changedSentenceKeys = Object.keys(changedSentence || {});

    if (
      !oldSentenceKeys.includes('voiceCode') &&
      changedSentenceKeys.includes('voiceCode')
    )
      return true;

    const commonKeys = oldSentenceKeys.filter((key) =>
      changedSentenceKeys.includes(key),
    );

    const isSentencesChanged = commonKeys.some(
      (key) => oldSentence[key] !== changedSentence[key],
    );

    return isSentencesChanged;
  };

  const checkToShowSaveProjectChanged = useCallback(() => {
    if (checkFirstTimeGetData.current) return;

    const { title, voiceCode, speed, sentencesChanged } = changedProjectInfo;
    const {
      title: oldTitle,
      speed: oldSpeed,
      sentences: oldSentences,
    } = dubbingRequestState;
    const { voiceCode: oldVoiceCode } = projectInfo;

    const isTitleChanged = title !== oldTitle;
    const isVoiceChanged = voiceCode !== oldVoiceCode;
    const isSpeedChanged = speed !== oldSpeed;
    const isSentencesChanged = Object.keys(sentencesChanged).some((key) => {
      const isSentenceChanged = checkSentenceChanged(
        oldSentences[key],
        sentencesChanged[key],
      );
      return isSentenceChanged;
    });

    const hasChanged =
      isTitleChanged || isVoiceChanged || isSpeedChanged || isSentencesChanged;

    if (hasChanged !== projectInfo.hasChanged)
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey('projectInfo', {
          ...projectInfo,
          hasChanged,
        }),
      );
  }, [changedProjectInfo, dubbingRequestState, projectInfo]);

  const handleShowDialogHistoryChanged = useCallback(
    () => setShowDialogHistoryChanged(true),
    [],
  );

  const handleCloseDialogHistoryChanged = () =>
    setShowDialogHistoryChanged(false);

  const handleCloseActionDialog = () => setActionDialog();

  const handleCloseLimitedFeatureDialog = () => {
    dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
    handleCloseActionDialog();
  };

  const handleUpgradeNow = () => {
    dispatch(actions.user.updateShowBlockDownloadDialog(false));
    history.push(route.PAYMENT_DUBBING);
  };

  const updateLatestProjectRequest = async (latestRequest) => {
    const response = await apis.dubbing.updateDubbingProject(projectId, {
      latestRequestId: latestRequest?.id || '',
    });
    if (response?.status) {
      dispatch(
        actions.dubbingRequest.updateDubbingRequestByKey('projectInfo', {
          ...projectInfo,
          latestRequestId: latestRequest?.id || '',
          projectStatus: response?.result?.status,
        }),
      );
    }
  };

  const fetchCheckSubtitles = async () => {
    const res = await apis.dubbing.getDubbingProjectDetail(projectId);

    if (!res.status) return;

    const {
      currentSubtitleLink,
      originalInfo,
      voiceCode,
      status,
      updatedAt,
      sentencesVoiceCode,
    } = res.result;

    if (currentSubtitleLink === undefined) return;

    const newProjectInfo = { ...projectInfo };
    newProjectInfo.voiceCode = voiceCode;
    newProjectInfo.currentSubtitleLink = currentSubtitleLink;
    newProjectInfo.projectStatus = status;
    newProjectInfo.updatedAt = updatedAt;
    newProjectInfo.originalInfo = originalInfo;

    let subtitleSentences = await getSubtitlesData(currentSubtitleLink);
    subtitleSentences = Object.keys(subtitleSentences).map(
      (sentence, index) => {
        const sentenceVoiceCode = getVoiceCodeUsedInSentence({
          sentencesVoiceCode,
          sentenceIndex: index,
          projectVoiceCode: voiceCode,
        });

        return {
          ...subtitleSentences[sentence],
          voiceCode: sentenceVoiceCode,
        };
      },
    );
    const originalSubtitleSentences = await getOriginalDataWhenReceiveResponse(
      originalInfo.subtitleLink,
      subtitleSentences,
    );

    const listActions = [
      actions.dubbingRequest.updateDubbingRequestByKey(
        'originalSentences',
        originalSubtitleSentences,
      ),
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        subtitleSentences,
      ),
      actions.dubbingRequest.updateDubbingRequestByKey(
        'projectInfo',
        newProjectInfo,
      ),
    ];

    listActions.forEach((action) => dispatch(action));
    setIsProcessingSubtitles(false);
  };

  const renderUpgradeButton = () => (
    <Button variant="contained" onClick={handleUpgradeNow}>
      {t('upgradeNow')}
    </Button>
  );

  useEffect(() => {
    fetchProjectDetail();
    fetchDubbingRequests();
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (!useDubbingSubtitleTimeline) calculateRequestTableHeight();
  }, [dubbingRequests, isExpandRequest]);

  useEffect(() => {
    if (useDubbingSubtitleTimeline) calculateTimeFrameHeight();
  }, [isExpandTimeFrame]);

  useEffect(() => {
    const isOldDubbingRoute = window.location.pathname === route.DUBBING;
    if (isProcessDubbingByUnitSecond && isOldDubbingRoute)
      history.push(route.HOME_DUBBING);
  }, [isProcessDubbingByUnitSecond]);

  // Reset redux state when leave page
  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(actions.dubbingRequest.resetDubbingRequest());
      dispatch(actions.audioPlayer.resetAudioPlayer());
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // Fetch request again when convert new request
  useEffect(() => {
    if (checkFirstTimeGetData.current) return;
    fetchDubbingRequests();
    if (requestLoading) {
      setRequestLoading(false);
    }
  }, [requestLoading, projectInfo, updatedTitle, updatedVoice]);

  useEffect(() => {
    checkToShowSaveProjectChanged();
  }, [changedProjectInfo]);

  useEffect(() => {
    if (showBlockDownloadDialog) {
      setActionDialog({
        image: UpgradeImg,
        title: t('unableToDownload'),
        description: t('unableToDownloadDescription', { numberDownload: 1 }),
        onClose: () => {
          dispatch(actions.user.updateShowBlockDownloadDialog(false));
          handleCloseActionDialog();
        },
        actionComponents: renderUpgradeButton(),
      });
    }
  }, [showBlockDownloadDialog]);

  useEffect(() => {
    if (limitedFeature) {
      setActionDialog({
        image: UpgradeImg,
        title: t('limitedFeature'),
        description: t('limitedFeatureDescription'),
        onClose: handleCloseLimitedFeatureDialog,
        actionComponents: renderUpgradeButton(),
      });
      dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
    }
  }, [limitedFeature]);

  useEffect(() => {
    if (page !== 1) return;
    const latestRequest = dubbingRequests?.[0];
    if (!latestRequest?.id && projectInfo?.latestRequestId?.length === 0)
      return;
    if (latestRequest?.id === projectInfo?.latestRequestId) return;
    if (checkFirstTimeGetData.current) return;
    updateLatestProjectRequest(latestRequest);
  }, [dubbingRequests]);

  useEffect(() => {
    fetchDubbingRequests();
  }, [page, projectInfo?.projectStatus]);

  useEffect(() => {
    if (!isProcessingSubtitles) return null;

    const fetchCheckSubtitlesData = setInterval(async () => {
      await fetchCheckSubtitles();
    }, FETCH_REQUESTS_INTERVAL);

    return () => clearInterval(fetchCheckSubtitlesData);
  }, [isProcessingSubtitles]);

  return viewVideoWithTimeline ? (
    <StyledDubbingList>
      <StyledDubbingWithVideo>
        <TitleBar
          onChangeRequestLoading={handleChangeRequestLoading}
          hasDeleteSentences={hasDeleteSentences}
          setHasDeleteSentences={setHasDeleteSentences}
        />
      </StyledDubbingWithVideo>
      <Box display="flex" gap="16px" marginTop="12px" width="100%">
        <Box width="70%">
          <StyledDubbing>
            <ToolBar
              onShowDialogHistoryChanged={handleShowDialogHistoryChanged}
              dubbingRequests={dubbingRequests}
            />
            <Sentences
              loadingDubbingSentences={loadingDubbingSentences}
              isExpandRequest={isExpandRequest}
              requestTableHeight={requestTableHeight}
              hasDeleteSentences={hasDeleteSentences}
              setHasDeleteSentences={setHasDeleteSentences}
              isProcessingSubtitles={isProcessingSubtitles}
              availableLanguages={availableLanguages}
              selectedSentencesId={selectedSentencesId}
            />
          </StyledDubbing>
        </Box>

        {/* render */}
        <Box width="30%">
          <StyledDubbing sx={{ height: '100%' }}>
            <VideoPlayer
              timeLineRefCurrent={timeLineRef.current}
              isChangeTimeByClick={isChangeTimeByClick}
              setIsChangeTimeByClick={setIsChangeTimeByClick}
            />
          </StyledDubbing>
        </Box>
      </Box>
      <HistoryChanged
        page={page}
        openDialogHistoryChanged={showDialogHistoryChanged}
        isExpandRequest={isExpandRequest}
        dubbingRequests={dubbingRequests}
        requestLoading={requestLoading}
        isExpand={isExpandRequest}
        totalRequests={totalRequests}
        requestTableRef={requestTableRef}
        onChangeRequestLoading={handleChangeRequestLoading}
        onCloseDialogHistoryChanged={handleCloseDialogHistoryChanged}
        onChangePage={handleChangePage}
        onChangeIsExpand={handleChangeIsExpandRequest}
      />

      {/* render */}
      {useDubbingSubtitleTimeline && (
        <TimeFrame
          dubbingRequests={dubbingRequests}
          ref={timeLineRef}
          timeFrameRef={timeFrameRef}
          availableLanguages={availableLanguages}
          isExpandTimeFrame={isExpandTimeFrame}
          selectedSentencesId={selectedSentencesId}
          setIsExpandTimeFrame={setIsExpandTimeFrame}
          setSelectedSentencesId={setSelectedSentencesId}
          setIsChangeTimeByClick={setIsChangeTimeByClick}
        />
      )}
    </StyledDubbingList>
  ) : (
    <StyledDubbingList>
      <StyledDubbing>
        <TitleBar
          onChangeRequestLoading={handleChangeRequestLoading}
          hasDeleteSentences={hasDeleteSentences}
          setHasDeleteSentences={setHasDeleteSentences}
        />
        <ToolBar
          onShowDialogHistoryChanged={handleShowDialogHistoryChanged}
          dubbingRequests={dubbingRequests}
        />
        <Sentences
          loadingDubbingSentences={loadingDubbingSentences}
          isExpandRequest={isExpandRequest}
          requestTableHeight={requestTableHeight}
          hasDeleteSentences={hasDeleteSentences}
          setHasDeleteSentences={setHasDeleteSentences}
          isProcessingSubtitles={isProcessingSubtitles}
          availableLanguages={availableLanguages}
          selectedSentencesId={selectedSentencesId}
        />
      </StyledDubbing>
      <HistoryChanged
        page={page}
        openDialogHistoryChanged={showDialogHistoryChanged}
        isExpandRequest={isExpandRequest}
        dubbingRequests={dubbingRequests}
        requestLoading={requestLoading}
        isExpand={isExpandRequest}
        totalRequests={totalRequests}
        requestTableRef={requestTableRef}
        onChangeRequestLoading={handleChangeRequestLoading}
        onCloseDialogHistoryChanged={handleCloseDialogHistoryChanged}
        onChangePage={handleChangePage}
        onChangeIsExpand={handleChangeIsExpandRequest}
      />

      {useDubbingSubtitleTimeline && (
        <TimeFrame
          ref={timeLineRef}
          timeFrameRef={timeFrameRef}
          isExpandTimeFrame={isExpandTimeFrame}
          selectedSentencesId={selectedSentencesId}
          setIsExpandTimeFrame={setIsExpandTimeFrame}
          setSelectedSentencesId={setSelectedSentencesId}
        />
      )}
      <ActionDialog
        image={actionDialog?.image}
        open={!!actionDialog?.title}
        title={actionDialog?.title}
        description={actionDialog?.description}
        onClose={actionDialog?.onClose}
        actionComponents={actionDialog?.actionComponents}
      />
    </StyledDubbingList>
  );
};

export default ProjectDetail;
