import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Close } from '@mui/icons-material';
import React, {
  Checkbox,
  DialogContent,
  IconButton,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledImageBanner } from './index.style';

const { useState } = require('react');

const ImageBanner = ({ open, bannerIndex, banner, onClose }) => {
  const [notShowAgain, setNotShowAgain] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeNotShowAgain = () => setNotShowAgain(!notShowAgain);

  const handleClickBanner = () => {
    onClose(bannerIndex);
    const currentUrl = window.location.href;
    const currentDomain = new URL(currentUrl).hostname;
    const bannerUrl = new URL(banner.actionUrl);
    const bannerUrlDomain = bannerUrl.hostname;
    if (currentUrl === banner.actionUrl) return;

    if (currentDomain === bannerUrlDomain) {
      const path = bannerUrl.pathname;
      history.push(path);
    } else window.open(banner.actionUrl, '_blank', 'noopener,noreferrer');
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    onClose(bannerIndex, notShowAgain);
  };

  return (
    <StyledImageBanner
      classes={{ paper: 'dialog' }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <img
          className="image"
          src={banner.imageUrl}
          onClick={handleClickBanner}
          alt="banner"
        />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="secondary"
          className="close-button"
        >
          <Close color="secondary" />
        </IconButton>
      </DialogContent>
      <div className="not-show-again">
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: 'checkbox' }}
              checked={notShowAgain}
              onChange={handleChangeNotShowAgain}
            />
          }
          label={t('notShowAgain')}
          labelPlacement={t('notShowAgain')}
        />
      </div>
    </StyledImageBanner>
  );
};

export default ImageBanner;
