import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';

import apis from '@src/apis';
import { PAYMENT_METHOD_TYPE } from '@src/constants/payment';

import { useDispatch, useSelector } from 'react-redux';
import actions from '@src/redux/actions';
import { getCookie, setCookie } from '@src/utils/cookie';
import { VBEE_DOMAIN } from '@src/configs';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import ConfigAutoRenewFooter from './ConfigAutoRenewFooter';
import { StyledConfigAutoRenew } from './index.style';
import PaymentMethod from './PaymentMethod';
import SubscriptionInfo from './SubscriptionInfo';
import NotificationDialog from './NotificationDialog';

const AUTO_RENEW_STATUS = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  UPCOMING: 'UPCOMING',
};

const ConfigAutoRenew = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const location = useLocation();
  const dispatch = useDispatch();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('VNPAYQR');
  const [loading, setLoading] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);

  const { subscriptionPurchase, latestOrder } = useSelector(
    (state) => state.user,
  );

  const selectedPaymentMethodId = paymentMethods.find(
    ({ type }) => type.toLowerCase() === selectedPaymentMethod.toLowerCase(),
  )?.id;

  const fetchSubscriptionPurchase = async (purchaseId, status) => {
    const data = await apis.subscription.getSubscriptionInfo();
    const { result } = data || {};

    if (data?.status === 1) {
      dispatch(actions.user.updateSubscriptionPurchase(result));

      const hasAutoRenewNotification = getCookie(
        `auto-renewal-notification-${purchaseId}`,
      );
      if (
        !hasAutoRenewNotification &&
        purchaseId &&
        status === SUBSCRIPTION_PURCHASE_STATUS.ACTIVE
      ) {
        setOpenNotificationDialog(true);
        setCookie({
          cname: `auto-renewal-notification-${purchaseId}`,
          cvalue: true,
          domain: VBEE_DOMAIN,
          extime: 1000 * 60 * 60 * 24 * 14,
        });
      }
    }
  };

  const fetchPaymentMethods = async () => {
    setLoading(true);
    const data = await apis.paymentMethods.getPaymentMethods({
      sort: 'rank_asc',
    });
    if (data.status) {
      const { result } = data;
      let displayPaymentMethods = [];

      displayPaymentMethods = result.paymentMethods.filter(
        (method) =>
          method.active &&
          method.type !== PAYMENT_METHOD_TYPE.APPLE &&
          method.type !== PAYMENT_METHOD_TYPE.GOOGLE &&
          method.type !== PAYMENT_METHOD_TYPE.BANK,
      );

      const mappedPaymentMethods = [];

      displayPaymentMethods.forEach((method) => {
        if (method.autoRenewStatus !== AUTO_RENEW_STATUS.UNAVAILABLE)
          mappedPaymentMethods.push({
            id: method.id,
            name: method.autoRenewName?.[language] || method.name[language],
            logo: method.autoRenewIcon || method.icon,
            autoRenewStatus: method.autoRenewStatus,
            type: method.type,
            rank:
              method.autoRenewStatus === AUTO_RENEW_STATUS.AVAILABLE ? 1 : 0,
          });
      });

      const sortedPaymentMethods = mappedPaymentMethods.sort((a) =>
        a?.rank ? -1 : 1,
      );

      setPaymentMethods(sortedPaymentMethods);
    }
    setLoading(false);
  };

  const handleCloseOpenNotificationDialog = () => {
    setOpenNotificationDialog(false);
  };

  const handleSelectPaymentMethod = (paymentMethodType) => {
    const selectedMethod = paymentMethods.find(
      (item) =>
        item.type === paymentMethodType &&
        item.autoRenewStatus === AUTO_RENEW_STATUS.AVAILABLE,
    );
    if (selectedMethod) setSelectedPaymentMethod(paymentMethodType);
  };

  useEffect(() => {
    // Handle receive subscription redirect result
    const { purchaseId, status } = camelcaseKeys(
      queryString.parse(location.search) || {},
    );
    if (purchaseId) {
      fetchSubscriptionPurchase(purchaseId, status);
    }
  }, [location]);

  useEffect(() => {
    fetchPaymentMethods();
    fetchSubscriptionPurchase();
  }, []);

  useEffect(() => {
    const latestPaymentMethodType = latestOrder?.paymentMethod?.type;
    handleSelectPaymentMethod(latestPaymentMethodType);
  }, [latestOrder, paymentMethods]);

  return (
    <>
      <StyledConfigAutoRenew>
        <Box className="header-config">
          <Typography className="title">{t('configAutoRenew')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} smd={6}>
            <PaymentMethod
              subscriptionPurchase={subscriptionPurchase}
              paymentMethods={paymentMethods}
              loading={loading}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          </Grid>
          <Grid item xs={12} smd={6}>
            <SubscriptionInfo />
          </Grid>
        </Grid>
      </StyledConfigAutoRenew>
      <ConfigAutoRenewFooter paymentMethodId={selectedPaymentMethodId} />
      <NotificationDialog
        open={openNotificationDialog}
        onClose={handleCloseOpenNotificationDialog}
      />
    </>
  );
};

export default ConfigAutoRenew;
