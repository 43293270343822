import { ERROR_CODE } from './code';

export const getMessage = (code) => {
  switch (code) {
    case ERROR_CODE.PENDING_ORDER:
      return 'pendingOrderError';
    case ERROR_CODE.ADMIN_ORDER:
      return 'adminOrderError';
    case ERROR_CODE.USER_NOT_ACTIVATED:
      return 'userNotActivatedError';
    // case ERROR_CODE.USED_VOUCHER:
    //   return 'usedVoucherError';
    case ERROR_CODE.INVALID_VOUCHER:
      return 'invalidVoucherError';
    case ERROR_CODE.OUT_OF_VOUCHERS:
      return 'outOfVouchersError';
    case ERROR_CODE.NO_ENOUGH_CONDITION_SUBMIT:
      return 'noEnoughConditionSubmitError';
    case ERROR_CODE.VOICE_USERNAME_INVALID:
      return 'usernameInvalidError';
    default:
      return '';
  }
};
