import { styled } from '@mui/material/styles';

const { COLOR } = require('@src/styles/color');
const { MOBILE_BREAKPOINT } = require('@src/styles/config');

const SwiperVoucherBannerContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  margin-bottom: ${({ bannerHeight }) => bannerHeight}px;

  > div {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    transition: right 0.6s ease-in-out, opacity 0.6s ease-in-out;
    opacity: 0;
    z-index: 0;

    &.active {
      left: 0;
      opacity: 1;
      z-index: 1;
      left: 50%;
      transform: translate(-50%, -10%);
      width: max-content;
    }

    &.prev {
      left: -100%;
      opacity: 0;
      z-index: 0;
    }

    &.next {
      left: 100%;
      opacity: 0;
      z-index: 0;
    }
  }
`;

const StyledVoucherBanner = styled('div')`
  margin-bottom: 26px;
  width: 700px;
  .countdown-mobile {
    display: none;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
      display: block;
      justify-content: center;
      margin-bottom: -16px;
    }
  }

  .banner-container {
    border-radius: 16px;
    padding: 4px 12px;
    height: 100%;
    border: 2px solid ${COLOR.yellow.default};
    background: #fff9db;

    display: flex;
    align-items: center;

    .countdown {
      display: flex;
      float: right;
      margin-left: auto;
      @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
        display: none;
      }
    }

    .content {
      margin-left: 8px;
      margin-right: 20px;

      .voucher-code-mobile {
        font-size: 14px;
        font-weight: 600;
        color: ${COLOR.darkV2[80]};
        display: none;
      }
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      color: ${COLOR.darkBlue};
      text-transform: uppercase;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.darkV2[80]};
    }

    .voucher-apply {
      margin-top: 4px;
      display: flex;
      gap: 8px;
      align-items: center;

      .voucher-code {
        font-weight: 700;
        color: ${COLOR.darkV2[80]};
      }
    }

    .box-gift-icon {
      height: 48px;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
      border-radius: 0px 0px 16px 16px;
      margin-top: 16px;

      .title {
        font-weight: 700;
        font-size: 20px;
        color: ${COLOR.darkBlue};
        font-size: 18px;
        margin-bottom: 2px;
      }
      .voucher-code {
        font-weight: 600;
        color: ${COLOR.darkV2[80]};
      }

      .content {
        margin-left: 8px;
        margin-right: 20px;

        .voucher-code {
          display: none;
        }

        .voucher-code-mobile {
          margin-top: 2px;
          display: flex;
          font-size: 14px;
          gap: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    width: 340px;
    margin-top: 10px;
  }
`;

export { StyledVoucherBanner, SwiperVoucherBannerContainer };
