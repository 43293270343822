import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@src/redux/actions';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Typography } from '@mui/material';
import { LibraryMusicRounded } from '@mui/icons-material';
import Speed from '@src/components/Speed';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import MenuVoice from '../MenuVoice';
// import AudioFormat from './AudioFormat';

import { StyledToolBar } from './index.style';

const ToolBar = () => {
  const dispatch = useDispatch();

  const { audioType, speed, fileId } = useSelector(
    (state) => state.dubbingRequest,
  );

  const handleChangeSpeed = (name, value) => {
    dispatch(actions.dubbingRequest.updateDubbingRequestByKey('speed', value));
  };

  return (
    <StyledToolBar>
      <MenuVoice />
      {useFeatureIsOn(FEATURE_KEYS.SPEED_DUBBING) && (
        <div className="speed">
          <Speed
            currentSpeed={speed}
            onChange={handleChangeSpeed}
            disabled={!fileId}
            width="35px"
          />
        </div>
      )}
      <div className="border-horizontal">
        {/* <AudioFormat /> */}
        <div className="icon-content">
          <LibraryMusicRounded color="iconPrimary" sx={{ fontSize: 20 }} />
          <Typography variant="body2" className="title">
            {audioType}
          </Typography>
        </div>
      </div>
    </StyledToolBar>
  );
};

export default ToolBar;
