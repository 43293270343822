import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomPagination from '@src/components/Pagination';
import { PAGINATION_LIMIT } from '@src/constants';
import { StyledCustomFooter } from './index.style';

const CustomFooter = ({
  total,
  page,
  paginationLimit = PAGINATION_LIMIT,
  onChangePage,
  isSticky = false,
}) => {
  const { t } = useTranslation();

  const from = (page - 1) * paginationLimit + 1;
  const to = Math.min(page * paginationLimit, total);

  return (
    <StyledCustomFooter isSticky={isSticky}>
      {total > 0 && (
        <Typography className="footer-label">
          {t('footerLabel', { from, to, total })}
        </Typography>
      )}
      {total > paginationLimit && (
        <CustomPagination
          count={Math.ceil(total / paginationLimit)}
          page={page}
          onChangePage={onChangePage}
        />
      )}
    </StyledCustomFooter>
  );
};

export default CustomFooter;
