import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BoxGiftIcon from '@src/assets/icons/box_gift.png';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { getFakeEndTimeDiscountForPackage } from '@src/services/package';
import { COLOR } from '@src/styles/color';
import CopyableButton from '@src/components/CopyableButton';
import Countdown from 'react-countdown';
import { StyledVoucherBanner } from './index.style';
import CountdownTime from './CountdownTime';
import CountdownBanner from './CountdownBanner';

const VoucherBanner = () => {
  const { bannerVoucher } = useSelector((state) => state.voucher);
  const { user } = useSelector((state) => state.auth);
  const { getFeatureValue } = useFeatureFlags();
  const useFeatureFakeEndTime = getFeatureValue(
    FEATURE_KEYS.FAKE_END_TIME_VOUCHER,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const { i18n } = useTranslation();
  const { t, language } = i18n;

  const {
    detail = {},
    title = {},
    code = '',
    endTime,
    startTime,
  } = bannerVoucher;

  let endTimeToShow = endTime;
  if (useFeatureFakeEndTime) {
    endTimeToShow =
      bannerVoucher?.fakeEndTimeForVoucher ||
      getFakeEndTimeDiscountForPackage({
        startTimeToCountdown: startTime,
        realEndTime: endTime,
        timeToResetCountdown: useFeatureFakeEndTime,
      });
  }

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="countdown-box">
      <div className="countdown">
        <CountdownTime
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      </div>
    </div>
  );

  return (
    <StyledVoucherBanner>
      <div className="countdown-mobile">
        {endTime && (
          <CountdownBanner
            date={new Date(endTimeToShow).getTime() + 100}
            backgroundColor="#fff9db"
          />
        )}
      </div>
      <div className="banner-container">
        <img src={BoxGiftIcon} alt="Voucher Banner" className="box-gift-icon" />

        <div className="content">
          <div className="title">{title[language]}</div>
          <div className="voucher-apply">
            <div className="description">
              {detail[language]}
              <span className="voucher-code">
                {` - ${t('voucherCode')}: ${code}`}
              </span>
            </div>
            <div className="voucher-code">
              <CopyableButton
                fontSize={18}
                color={COLOR.lightText}
                content={code}
                className="copyable-button"
              />
            </div>
          </div>
          <div className="voucher-code-mobile">
            <div className="">
              {t('voucherCode')}: {code}
            </div>
            <CopyableButton
              fontSize={16}
              color={COLOR.lightText}
              content={code}
              className="copyable-button"
            />
          </div>
        </div>

        <div className="countdown">
          {endTime && (
            <Countdown
              date={new Date(endTimeToShow).getTime() + 100} //  + 100ms to fix countdown bug
              renderer={renderer}
            />
          )}
        </div>
      </div>
    </StyledVoucherBanner>
  );
};

export default VoucherBanner;
