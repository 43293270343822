import * as React from 'react';
import { Box, Drawer, Popper } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ReserveBackground from '@src/assets/banners/reserve-banner.png';
import { COLOR } from '@src/styles/color';
import {
  BOX_SHADOW,
  FLASH_SALE_BANNER_HEIGHT,
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_BOX_SHADOW,
  SIDEBAR_WIDTH_COLLAPSED,
  TEXT_SHADOW,
} from '@src/styles/config';

const StyledMobileProfileAction = styled(Drawer)(
  ({ theme, headerVoucher, useNewProfileMobile, heightScreen }) => ({
    '& .MuiDrawer-paper': {
      border: 'none',
      position: 'fixed',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: '10000',
      transitionProperty: 'top, bottom, width',
      transitionDuration: '.2s, .2s, .35s',
      transitionTimingFunction: 'linear, linear, ease',
      overflow: 'hidden',
      width: '100vw',
      boxShadow: BOX_SHADOW,
      display: 'block',
      height:
        headerVoucher && useNewProfileMobile
          ? `calc(${heightScreen}px - ${FLASH_SALE_BANNER_HEIGHT}px)`
          : `calc(${heightScreen}px - 2px)`, // 64px is height of navbar, 2px is space between navbar and content
      visibility: 'visible',
      scrollbarColor: `${COLOR.indigo[64]} ${COLOR.white}`,

      '&::-webkit-scrollbar': {
        width: useNewProfileMobile ? '0' : '4px',
      },

      '&::-webkit-scrollbar-track': {
        background: useNewProfileMobile ? 'transparent' : `${COLOR.white}`,
      },

      '&::-webkit-scrollbar-thumb': {
        background: useNewProfileMobile ? 'transparent' : `${COLOR.indigo[64]}`,
        borderRadius: useNewProfileMobile ? '0' : '100px',
      },

      scrollbarWidth: useNewProfileMobile ? 'thin' : 'none', // Hides the scrollbar on Firefox
      overflowY: useNewProfileMobile ? 'hidden' : 'scroll', // Prevents showing the scrollbar

      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      backgroundColor: COLOR.white,

      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

const StyledMobileProfileContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${(props) =>
    props.useNewProfileMobile ? '12px 8px' : '16px 10px 0 10px'};
  gap: ${(props) => (props.useNewProfileMobile ? '8px' : '16px')};

  .package-wrapper-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: auto;
    z-index: 4;
    margin: 0 4px 12px 4px;
    .close-button {
      height: 24px;
      width: 24px;
      font-size: 14px;
      color: ${COLOR.darkBlue};
    }
  }

  .collapse-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-info {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: ${COLOR.darkBlue};
    }
  }

  .profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .profile-item {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .profile-item-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: ${COLOR.label[80]};
      }

      .profile-item-value {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.4px;
        color: ${COLOR.darkBlue};
      }
    }
  }

  .sub-setting {
    display: flex;
    flex-direction: column;
    width: 100%;

    .sub-setting-back {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      padding: 8px 0px;

      .icon {
        width: 24px;
        height: 24px;
        color: ${COLOR.secondary[8]};
      }

      .text {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.4px;
        color: ${COLOR.secondary[8]};
      }
    }

    .sub-setting-item {
      display: flex;
      width: 100%;
      padding: 8px;
      gap: 12px;
      border-radius: 8px;
      color: ${COLOR.secondary[8]};
    }

    .selected-language {
      background-color: ${COLOR.secondary[80]};
    }

    .unselected-language {
      color: ${COLOR.label[80]};
    }

    .text-link {
      color: ${COLOR.indigo.default};
      text-decoration: none;
    }

    .item {
      display: flex;
      padding: 8px;
      gap: 12px;
      border-radius: 8px;
    }
  }
`;

const StyledVoucherBanner = styled('div')`
  background-image: url(${(props) => props.voucherImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 125px;
  padding: 8px 12px 12px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .title-wrapper {
    display: flex;
    width: 100%;
    text-align: ${(props) => (props.applyAllPackages ? 'center' : 'left')};
    justify-content: center;
    align-items: start;
    height: 56px;
    gap: 0px;
    flex-direction: column;

    .title {
      font-size: 20px;
      font-weight: 800;
      line-height: 26px;
      text-align: center;
    }

    .tooltip-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
`;

const StyledCountdownBanner = styled('div')`
  .value {
    font-size: ${(props) => (props.isHeaderBanner ? '10px' : '12px')};
    font-weight: ${(props) => (props.isHeaderBanner ? 700 : 510)};
    line-height: 16px;
    letter-spacing: ${(props) => !props.isHeaderBanner && '-0.4px'};
  }
`;

const StyledAdsItem = styled('div')`
  margin: 0 10px;

  .banner-item {
    background-color: ${COLOR.primary};
    background-image: ${(props) =>
      `url(${props.background || ReserveBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    height: 125px;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
  }

  .auto-renewal-offer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .title {
      text-shadow: ${TEXT_SHADOW};
      font-size: 12px;
      font-weight: 860;
      text-transform: uppercase;
    }
  }

  .register-button {
    font-size: 12px;
    border-radius: 480px;
    font-weight: 510;
    padding: 6px;
    text-transform: none;
    height: 32px;
    margin-bottom: 8px;

    &:hover {
      opacity: 0.9;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    cursor: pointer;
  }

  .tooltip-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
  }

  .title {
    font-size: 18px;
    font-weight: 860;
    letter-spacing: -0.4px;
    line-height: 24px;
    color: ${COLOR.white};
    flex: 1;
  }

  .description {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: ${COLOR.white};
  }

  .button-wrapper {
    display: flex;
    justify-content: center;

    .button-highlight {
      text-transform: uppercase;
      color: ${COLOR.darkBlue};
      font-weight: 700;
      font-size: 14px;
    }
  }

  .button {
    background: ${COLOR.white};
    height: 32px;
    padding: 6px;
    border-radius: 500px;
    text-transform: none;
    display: flex;
    gap: 3px;
    font-size: 12px;
    font-weight: 510;
    line-height: 16px;
    letter-spacing: -0.4px;
    width: 100%;

    &:hover {
      opacity: 0.9;
      background: ${COLOR.white};
    }
  }

  .lock-character-button {
    margin-bottom: 8px;
  }
`;

const StyledUserActions = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${`${NEW_SIDEBAR_WIDTH - 32}px`};
  background-color: ${COLOR.white};
  border-radius: 12px;
  padding: ${(props) => (props.useNewProfileMobile ? '0' : '8px')};
  color: ${COLOR.secondary[8]};

  .user-action-item {
    display: flex;
    padding: 8px;
    gap: 12px;
    border-radius: 8px;
    margin-bottom: 4px;
    font-size: 14px;
    color: ${COLOR.indigo.default};

    svg {
      opacity: 0.8;
    }
  }

  .red-color {
    color: ${COLOR.red};
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .user-action-has-submenu {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .text-link {
    color: ${COLOR.indigo.default};
    text-decoration: none;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .color-red {
    color: ${COLOR.red};
  }

  .language-choose-container {
    display: flex;
    gap: 8px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const StyledUserSubActions = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 8px;
  color: ${COLOR.secondary[8]};
  width: ${`${NEW_SIDEBAR_WIDTH - 16}px`};
  .item {
    display: flex;
    padding: 8px;
    gap: 12px;
    border-radius: 8px;
    font-size: 14px;
    p {
      font-size: 14px;
    }
    color: ${COLOR.indigo.default};
  }
  .text-link {
    color: ${COLOR.indigo.default};
    text-decoration: none;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .unselected-language {
    color: ${COLOR.label[80]};
  }
  .mb-4 {
    margin-bottom: 4px;
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 1299;
  box-shadow: ${SIDEBAR_BOX_SHADOW};
  border-radius: 12px;
  background-color: ${COLOR.white};
  color: ${COLOR.indigo.default};
`;

const StyledSidebarTooltip = styled(
  ({ className, placement = 'right', ...props }) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow
      placement={placement}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 16px',
    textAlign: 'center',
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.label[80],
  },
}));

const StyledUserInfoTooltip = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
`;

const StyledUserSidebarNew = styled(Box)`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : '16px 10px')};
  gap: 5px;
  cursor: pointer;

  .avatar-wrapper {
    padding: 0 !important;
  }

  .avatar {
    position: relative;
    border: 2px solid ${COLOR.primary};
    border-radius: 100%;

    .box-icon {
      border-radius: 50%;
      z-index: 10000;
      background-color: ${COLOR.primary};
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 20px;
        height: 20px;
        color: ${COLOR.darkBlue};
      }
    }

    :before {
      content: '';
      position: absolute;
      inset: 0;
      margin: -3px;
      background-color: ${(props) => props.colorpackageavatar};
      border-radius: inherit;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex: 1;

    .name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: ${COLOR.darkBlue};
      width: fit-content;
    }

    .package {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.4px;
      color: ${COLOR.label[80]};
      width: fit-content;
    }
  }

  .close-sidebar {
    margin: auto;

    .button-close {
      padding: 8px;
      border-radius: 50%;
      background-color: ${COLOR.secondary[40]};
    }
  }
`;

const StyledSidebarNew = styled('div')`
  height: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `calc(100% - ${FLASH_SALE_BANNER_HEIGHT}px)`
      : '100%'};
  top: ${(props) =>
    props.useHeaderBanner && props.headerVoucher
      ? `${FLASH_SALE_BANNER_HEIGHT}px`
      : '0'};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .brand {
    padding: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    .logo {
      height: 32px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .logo-small {
      height: 58px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 2px;
    }
    .logo-img {
      cursor: pointer;
      height: 32px;
    }
  }

  .sidebar-wrapper {
    overflow-y: auto;
    flex: 1;
    padding: 0 8px 0 10px;
    position: relative;
    color: inherit;
    @media screen and (max-width: 600px) {
      padding-bottom: 88px;
    }
  }

  .hidden-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar {
      width: 4px;
      background: ${COLOR.white};
    }
  }

  .small-scrollbar {
    // scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${COLOR.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${COLOR.indigo[64]};
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${COLOR.indigo[80]};
      border-radius: 2px;
    }
  }

  .menu-submenu {
    position: relative;
    &:hover {
      & > .placement-right-top {
        display: block;
      }
    }
  }

  .list {
    padding: 8px 0 16px 0;
    list-style: none;
    &:before,
    &:after {
      display: table;
      content: '';
    }
    &:after {
      clear: both;
    }
  }

  .wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 10px;
    gap: 10px;
    box-sizing: border-box;

    .wallet-ads {
      width: ${`${NEW_SIDEBAR_WIDTH - 20}px`};
    }

    .wallet-character {
      display: flex;
      gap: 12px;
      align-items: center;
      width: 100%;

      .icon {
        padding: 10px;
        border-radius: 50%;
        background-color: ${COLOR.primary};
      }
    }
  }

  .danger-text {
    color: ${COLOR.red} !important;
  }

  .wallet-package {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;

    .characters-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 2px;
      align-items: center;
    }

    .lock-icon {
      color: ${COLOR.red};
      cursor: pointer;
    }

    .remain-characters {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: ${COLOR.darkBlue};
    }

    .expired-date {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.4px;
      color: ${COLOR.label[80]};
    }
  }

  .collapse-list {
    margin-top: 0;
    &.caret {
      margin-top: 8px;
    }
  }

  .collapse-active {
    outline: none;
    background-color: ${COLOR.primary};
    box-shadow: none;
  }

  .item {
    display: block;
    padding: 0;
  }

  .item-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 2px 0;
    height: 28px;
    border-radius: 8px;
    padding: 8px;
    text-decoration: none;
    &:hover {
      outline: none;
      background-color: ${COLOR.label[8]};
      box-shadow: none;
    }
    &,
    &:focus {
      color: inherit;
    }

    .item-wrapper {
      flex: 1;
      display: flex;
      gap: 0px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .item-icon {
    color: ${COLOR.indigo[100]};
    width: 24px;
    height: 24px;
    float: left;
    top: 3px;
    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
  }

  .item-icon-active {
    color: ${COLOR.indigo[100]};
  }

  .item-text {
    color: ${COLOR.indigo[100]};
    margin: 0;
    line-height: 20px;
    letter-spacing: -0.4px;
    display: block;
    height: auto;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
  }

  .item-text-active {
    font-weight: 600;
    color: ${COLOR.indigo[100]};
  }

  .caret {
    margin-top: 4px;
    position: absolute;
    right: 18px;
  }

  .active-link {
    &,
    &:hover,
    &:focus {
      background-color: ${COLOR.indigo[8]};
    }
  }

  .convert-ole-version-button {
    margin: 10px 15px 0;
    border-radius: 5px;
    padding: 10px 15px;
    min-width: 225px;
  }

  .vbee-v3-img {
    margin-right: 10px;
  }

  .package-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin: 0 20px;
  }

  .package-wrapper-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 15px;
    margin-top: 15px;
    position: relative;
    overflow: auto;
    z-index: 4;
    .close-button {
      height: 28px;
      width: 28px;
      background-color: ${COLOR.secondary[40]};
    }
  }

  .sidebar-package-info {
    padding: 16px 0;
  }

  .package-item-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .text {
      font-size: 14px;
    }
    .value {
      font-size: 14px;
      font-weight: bold;
    }
    .error-text {
      color: ${COLOR.danger};
    }
  }

  .gift-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 16px 0;

    .gift-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      background-color: ${COLOR.darkBlue};
      cursor: pointer;

      .number-of-gift {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: ${COLOR.red};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 16px;
        color: ${COLOR.white};
      }
    }
  }

  .footer-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 10px 4px 10px;
    gap: 4px;
  }
`;

const StyledProductsDropdown = styled('div')`
  position: ${(props) =>
    props.isSmall || props.isMobile ? 'fixed' : 'absolute'};
  background-color: ${COLOR.white};
  display: ${(props) => (props.open ? 'flex' : 'none')};
  width: ${(props) => (props.isMobile ? '100%' : `${NEW_SIDEBAR_WIDTH}px`)};
  top: 74px;
  left: 0px;
  padding: 8px 6px 8px 5px;
  box-sizing: border-box;
  border-radius: ${(props) =>
    props.isMobile ? '0px 0px 12px 12px' : '0px 12px 12px 12px'};
  z-index: 10;
  gap: 4px;
  flex-direction: column;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.09);

  .product-item {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    cursor: pointer;
    font-weight: 700;
    padding: 8px 12px;

    &:hover {
      background-color: ${COLOR.label[8]};
    }
  }

  .product-item-active {
    background-color: ${COLOR.indigo[8]};
  }
`;

const StyledDrawerNew = styled(Drawer)(({ theme, opensidebar }) => ({
  '& .MuiDrawer-paper': {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: NEW_SIDEBAR_WIDTH,
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      width: opensidebar
        ? `${NEW_SIDEBAR_WIDTH}px`
        : `${SIDEBAR_WIDTH_COLLAPSED}px`,
      position: 'fixed',
      height: '100%',
    },

    [theme.breakpoints.down('md')]: {
      width: opensidebar
        ? `${NEW_SIDEBAR_WIDTH}px`
        : `${SIDEBAR_WIDTH_COLLAPSED}px`,
      boxShadow: BOX_SHADOW,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: 'auto',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      boxShadow: BOX_SHADOW,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: 'auto',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },
  },
}));

const StyledBetaWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.bluev2[10]};
  border-radius: 100px;
  padding: 2px 0px;
  width: 52px;

  .beta-background {
    background-color: ${COLOR.paleBlue};
    border-radius: 100px;
    width: 40px;
    height: 25px;
    margin-top: 3px;
  }

  .beta-text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 700;
    color: ${COLOR.info};
    font-size: 12px;
    text-transform: uppercase;
  }

  .new-text {
    margin: 0;
    color: ${COLOR.bluev2[100]};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
  }
`;

const StyledSubMenu = styled('div')`
  &.small-sub-menu-wrapper {
    background: ${COLOR.white};
    box-shadow: ${BOX_SHADOW};
    border-radius: 12px;
  }

  .small-sub-menu-item {
    width: 160px;
    padding: 8px 16px !important;
  }

  .sub-menu-item {
    padding-left: 45px !important;
  }

  .item-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 2px 0;
    border-radius: 8px;
    padding: 10px;
    text-decoration: none;

    &:hover {
      outline: none;
      background-color: ${COLOR.label[8]};
      box-shadow: none;
    }

    &,
    &:focus {
      color: inherit;
    }

    .item-wrapper {
      flex: 1;
      display: flex;
      gap: 0px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .active-link {
    &,
    &:hover,
    &:focus {
      background-color: ${COLOR.indigo[8]};
    }
  }

  .item-icon {
    color: ${COLOR.indigo[80]};
    width: 24px;
    height: 24px;
    float: left;
    top: 3px;
    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
  }

  .item-icon-active {
    color: ${COLOR.indigo[100]};
  }

  .item-text {
    color: ${COLOR.indigo[80]};
    margin: 0;
    line-height: 30px;
    display: block;
    height: auto;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
  }

  .item-text-active {
    font-weight: 600;
    color: ${COLOR.indigo[100]};
  }
`;

const StyledLogo = styled('div')`
  height: 40px;
  display: flex;
  justify-content: ${(props) => (props.isSmall ? 'center' : 'space-between')};
  width: ${(props) => (props.isMobile ? '172px' : '100%')};
  flex-direction: ${(props) => (props.isSmall ? 'column' : 'row')};
  align-items: center;
  gap: 2px;
  cursor: pointer;

  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.isSmall ? 'center' : 'space-between')};
    flex-direction: ${(props) => (props.isSmall ? 'column' : 'row')};
  }

  .logo-img {
    height: 40px;
  }

  .button-close {
    padding: 4px 0px 0px 0px;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  .close-icon {
    width: 24px;
    height: 24px;
  }

  .close-icon-active {
    border-radius: 100%;
    background-color: ${COLOR.indigo[8]};
    &:hover {
      background-color: ${COLOR.indigo[8]};
    }
  }
`;

export {
  StyledVoucherBanner,
  StyledCountdownBanner,
  StyledAdsItem,
  StyledUserActions,
  StyledUserSubActions,
  StyledPopper,
  StyledSidebarTooltip,
  StyledUserInfoTooltip,
  StyledUserSidebarNew,
  StyledSidebarNew,
  StyledDrawerNew,
  StyledBetaWrapper,
  StyledSubMenu,
  StyledMobileProfileAction,
  StyledMobileProfileContent,
  StyledProductsDropdown,
  StyledLogo,
};
