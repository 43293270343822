import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import UpgradeImg from '@src/assets/images/space-shuttle.png';
import { Button } from '@mui/material';
import route from '@src/constants/route';
import actions from '@src/redux/actions';

const DubbingDialog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [actionDialog, setActionDialog] = useState({});
  const { showBlockDownloadDialog } = useSelector((state) => state.user);
  const { limitedFeature } = useSelector((state) => state.dialog);

  const handleCloseActionDialog = () => setActionDialog();

  const handleUpgradeNow = () => {
    dispatch(actions.user.updateShowBlockDownloadDialog(false));
    history.push(route.PAYMENT_DUBBING);
  };

  const renderUpgradeButton = () => (
    <Button variant="contained" onClick={handleUpgradeNow}>
      {t('upgradeNow')}
    </Button>
  );

  useEffect(() => {
    if (showBlockDownloadDialog) {
      setActionDialog({
        image: UpgradeImg,
        title: t('unableToDownload'),
        description: t('unableToDownloadDescription', { numberDownload: 1 }),
        onClose: () => {
          dispatch(actions.user.updateShowBlockDownloadDialog(false));
          handleCloseActionDialog();
        },
        actionComponents: renderUpgradeButton(),
      });
    }
  }, [showBlockDownloadDialog]);

  useEffect(() => {
    if (limitedFeature) {
      setActionDialog({
        image: UpgradeImg,
        title: t('limitedFeature'),
        description: t('limitedFeatureDescription'),
        onClose: () => {
          dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
          handleCloseActionDialog();
        },
        actionComponents: renderUpgradeButton(),
      });
    }
  }, [limitedFeature]);

  return (
    <ActionDialog
      image={actionDialog?.image}
      open={!!actionDialog?.title}
      title={actionDialog?.title}
      description={actionDialog?.description}
      onClose={actionDialog?.onClose}
      actionComponents={actionDialog?.actionComponents}
    />
  );
};

export default DubbingDialog;
