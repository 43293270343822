import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@mui/material';

import ProcessHandler from '@src/components/ProcessHandler';
import { DURATION, PACKAGE_TYPE } from '@src/constants/package';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import api from '@src/apis';
import { filterPackages } from '@src/services/package';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';

import PackageItem from './PackageItem';
import AutoPlayMobileStepper from '../../components/AutoPlayMobileStepper';
import { StyledPackages } from './index.style';

const Packages = () => {
  const [packageType, setPackageType] = useState(PACKAGE_TYPE.STUDIO);
  const [packageDuration, setPackageDuration] = useState(DURATION.MONTHLY);
  const [packages, setPackages] = useState([]);
  const [currentPackages, setCurrentPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayTrialPackage, setDisplayTrialPackage] = useState(false);
  const isDubbingRoute = useCheckDubbingRoute();

  const { t } = useTranslation();
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);

  const useFreePackage = getFeatureValue(FEATURE_KEYS.USE_FREE_PACKAGE, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const { usingPackage, latestOrder } = useSelector((state) => state.user);

  const handleChangePackageType = (type) => {
    setPackageType(type);
    setPackageDuration(DURATION.MONTHLY);
  };

  const handleSetCurrentPackages = (duration, allPackages) => {
    const displayBasicPackage =
      packageType === PACKAGE_TYPE.API || !displayTrialPackage;

    const displayPackages = filterPackages({
      duration,
      data: allPackages,
      packageType,
      displayTrialPackage,
      displayBasicPackage,
      useFreePackage,
      isDubbingPackages: isDubbingRoute,
    });
    setCurrentPackages(displayPackages);
  };

  const fetchPackages = async () => {
    setLoading(true);
    const { status, result } = await api.packages.getPackages({
      type: packageType,
    });
    setLoading(false);
    if (status) setPackages(result.packages);
  };

  useEffect(() => {
    fetchPackages();
  }, [packageType]);

  useEffect(() => {
    handleSetCurrentPackages(packageDuration, packages);
  }, [usingPackage, packageDuration, packages]);

  useEffect(() => {
    if (latestOrder.id) setDisplayTrialPackage(false);
    else setDisplayTrialPackage(true);
  }, [latestOrder]);

  return (
    <StyledPackages num={currentPackages.length}>
      <div className="tabs-header">
        <div className="tabs-item">
          <Button
            className={classNames('tab', {
              'tab-active': packageType === PACKAGE_TYPE.STUDIO,
              'tab-inactive': packageType !== PACKAGE_TYPE.STUDIO,
            })}
            onClick={() => handleChangePackageType(PACKAGE_TYPE.STUDIO)}
          >
            VBEE STUDIO
          </Button>
          <Button
            className={classNames('tab', {
              'tab-active': packageType === PACKAGE_TYPE.API,
              'tab-inactive': packageType !== PACKAGE_TYPE.API,
            })}
            onClick={() => handleChangePackageType(PACKAGE_TYPE.API)}
          >
            VBEE API
          </Button>
        </div>
      </div>

      <div className="duration-header">
        <div className="duration-item">
          <Button
            className={classNames('duration ', {
              'duration-inactive': packageDuration !== DURATION.MONTHLY,
            })}
            onClick={() => setPackageDuration(DURATION.MONTHLY)}
          >
            {t('monthlyPackage')}
          </Button>
          <Button
            className={classNames('duration ', {
              'duration-inactive': packageDuration !== DURATION.QUARTERLY,
            })}
            onClick={() => setPackageDuration(DURATION.QUARTERLY)}
          >
            {t('quarterlyPackage')}
          </Button>
          <Button
            className={classNames('duration ', {
              'duration-inactive': packageDuration !== DURATION.YEARLY,
            })}
            onClick={() => setPackageDuration(DURATION.YEARLY)}
          >
            {t('yearlyPackage')}
          </Button>
        </div>
      </div>

      <div className="packages-wrapper">
        <ProcessHandler loading={loading} mt="30px" mb="30px" align="center">
          <div className="packages">
            {currentPackages
              .sort((a, b) => a.rank - b.rank)
              .map((pkg) => (
                <PackageItem data={pkg} duration={packageDuration} />
              ))}
          </div>
          <div className="mobile-packages">
            <AutoPlayMobileStepper items={currentPackages}>
              {currentPackages.map((pkg) => (
                <div key={pkg.label}>
                  <PackageItem data={pkg} duration={packageDuration} />
                </div>
              ))}
            </AutoPlayMobileStepper>
          </div>
        </ProcessHandler>
      </div>
    </StyledPackages>
  );
};

export default Packages;
