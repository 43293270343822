import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import {
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { isArray } from 'lodash';

import ProcessHandler from '@src/components/ProcessHandler';
import {
  DURATION,
  PACKAGE_DURATION_LABEL,
  PACKAGE_TYPE,
} from '@src/constants/package';
import api from '@src/apis';
import {
  checkHasDiscountPackage,
  filterPackages,
  getMostPopularPackage,
  checkPackageDiscount,
} from '@src/services/package';
import { LANGUAGE } from '@src/constants';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import classNames from 'classnames';
import useElementWidth from '@src/hooks/useElementWidth';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import moment from 'moment';
import {
  checkVoucherByProduct,
  verifyPolicy,
  verifyTime,
} from '@src/services/voucher';
import { VOUCHER_TYPE } from '@src/constants/voucher';
import { checkVietNam } from '@src/utils/checkCountry';
import PackageItem from './PackageItem';
import { StyledPackages } from './index.style';
import PackageTypes from './PackageTypes';
import NewPackageItem from './NewPackageItem';
import VoucherBanner from './VoucherBanner';
import NewPackageItemOld from './NewPackageItemOld';
import SwiperVoucherBanner from './SwiperVoucherBanner';

const STUDIO_BASIC_PACKAGE_CODE = 'STUDIO-BASIC';
const STUDIO_FREE_PACKAGE_CODE = 'STUDIO-FREE';
// const DUBBING_BASIC_PACKAGE_CODE = 'DUBBING-BASIC';
const DUBBING_TRIAL_PACKAGE_CODE = 'DUBBING-TRIAL';
const PACKAGE_RESPONSIVE_BREAKPOINT = 1167;

const NewPackages = () => {
  const isDubbingRoute = useCheckDubbingRoute();
  const isVietNam = checkVietNam();
  const localtion = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(localtion.search);
  const getPackageType = () => {
    const packageTypeParam =
      queryParams.get('packageType') || PACKAGE_TYPE.STUDIO;
    return Object.values(PACKAGE_TYPE).includes(packageTypeParam)
      ? packageTypeParam
      : PACKAGE_TYPE.STUDIO;
  };

  const getPackageDuration = () => {
    const packageDurationParam = queryParams.get('duration') || DURATION.YEARLY;
    return Object.values(DURATION).includes(packageDurationParam)
      ? packageDurationParam
      : DURATION.YEARLY;
  };

  const initialPackageType = isDubbingRoute
    ? PACKAGE_TYPE.DUBBING
    : getPackageType();
  const initialPackageDuration = getPackageDuration();

  const [packageType, setPackageType] = useState(
    isDubbingRoute ? PACKAGE_TYPE.DUBBING : initialPackageType,
  );

  const [packageDuration, setPackageDuration] = useState(
    initialPackageDuration,
  );
  const handleChangePackageDuration = (value) => {
    setPackageDuration(value);
    queryParams.set('duration', value);
    history.replace({ search: queryParams.toString() });
  };
  const [packages, setPackages] = useState([]);
  const [totalVoucher, setTotalVoucher] = useState([]);
  const [currentPackages, setCurrentPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayTrialPackage, setDisplayTrialPackage] = useState(false);
  const [saveUpToPercent, setSaveUpToPercent] = useState(0);
  const [hasDiscountPackage, setHasDiscountPackage] = useState(false);
  const [isShowAllPackageInfo, setIsShowAllPackageInfo] = useState(false);
  const [isFirstTimePaid, setIsFirstTimePaid] = useState(false);
  const [firstPaidVoucher, setFirstPaidVoucher] = useState(null);
  const [voucherBannerToShow, setVoucherBannerToShow] = useState([]);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const packagesRef = useRef();
  const packagesWidth = useElementWidth(packagesRef);

  const { usingPackage, latestOrder } = useSelector((state) => state.user);
  const { bannerVoucher } = useSelector((state) => state.voucher);
  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();
  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const showNewPackagePriceUI = getFeatureValue(
    FEATURE_KEYS.NEW_PACKAGE_PRICE_UI,
  );

  const showPackageDuration = getFeatureValue(
    FEATURE_KEYS.SHOW_PACKAGE_DURATION,
  );

  const discountForFirstPaidUser = getFeatureValue(
    FEATURE_KEYS.DISCOUNT_FOR_FIRST_PAID_USER,
  );

  const showNewListPackageUI = getFeatureValue(
    FEATURE_KEYS.NEW_LIST_PACKAGE_UI,
  );

  const isNewAutoSelectVoucher = getFeatureValue(
    FEATURE_KEYS.NEW_AUTO_SELECT_VOUCHER,
  );

  const useFreePackage = getFeatureValue(FEATURE_KEYS.USE_FREE_PACKAGE, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const handleChangePackageType = (type) => {
    setPackageType(type);
    setPackageDuration(DURATION.YEARLY);
    queryParams.set('packageType', type);
    queryParams.set('duration', DURATION.YEARLY);
    history.replace({ search: queryParams.toString() });
  };

  const getHighlightPackageCode = (pkgs) => {
    const popularPackage = getMostPopularPackage(pkgs);
    return popularPackage?.code;
  };

  const packageTypeTabs = [
    {
      label: 'PKG_STUDIO',
      id: PACKAGE_TYPE.STUDIO,
    },
    { label: 'PKG_API', id: PACKAGE_TYPE.API },
  ];

  const handleSetCurrentPackages = (duration, allPackages) => {
    const displayBasicPackage =
      packageType === PACKAGE_TYPE.API ||
      packageType === PACKAGE_TYPE.DUBBING ||
      !displayTrialPackage;

    const displayPackages = filterPackages({
      duration,
      data: allPackages,
      packageType,
      displayTrialPackage,
      displayBasicPackage,
      useFreePackage,
      isDubbingPackages: isDubbingRoute,
    });
    setCurrentPackages(displayPackages?.sort((a, b) => a.rank - b.rank));
    setSaveUpToPercent(displayPackages[1]?.yearDiscount || null);
    const isHasDiscountPackage = checkHasDiscountPackage(displayPackages);
    setHasDiscountPackage(isHasDiscountPackage);
  };

  const handleDiscountEnd = () => {
    const isHasDiscountPackage = checkHasDiscountPackage(currentPackages);
    setHasDiscountPackage(isHasDiscountPackage);
  };

  const fetchPackages = async () => {
    setLoading(true);
    const { status, result } = await api.packages.getPackages({
      type: packageType,
      active: true,
    });
    setLoading(false);
    if (status) setPackages(result.packages);
  };

  const fetchVoucherForFirstPaidUser = async () => {
    const response = await api.vouchers.getVoucherForFirstPaidOrder();
    if (response.status) {
      const voucher = response.result?.[0];
      const satisfiedTime = verifyTime(voucher);
      if (satisfiedTime) setFirstPaidVoucher(voucher);
    }
  };

  const fetchTotalVouchers = async () => {
    if (!isNewAutoSelectVoucher) return;
    const data = await api.vouchers.getVouchers();
    if (data.status) {
      setTotalVoucher(data.result);
    }
  };

  const filterUseableVoucher = (currPackageId) =>
    totalVoucher
      .filter((voucher) => {
        const satisfiedTime = verifyTime(voucher);
        const satisfiedPolicy = verifyPolicy(voucher, currPackageId);

        const isValidVoucherTypeInCountry =
          isVietNam || voucher.type !== VOUCHER_TYPE.PRICE;

        const isPriceOrPercentVoucher =
          voucher.type === VOUCHER_TYPE.PERCENT ||
          voucher.type === VOUCHER_TYPE.PRICE;

        const canUse =
          satisfiedTime &&
          satisfiedPolicy &&
          voucher.remainingAmount &&
          !voucher.isPrivate &&
          voucher.usedAmount < voucher.numReuse &&
          isValidVoucherTypeInCountry &&
          isPriceOrPercentVoucher;

        return canUse;
      })
      .map((voucher) => ({
        ...voucher,
        satisfiedTime: verifyTime(voucher),
        satisfiedPolicy: verifyPolicy(voucher, currPackageId),
        canUse: true,
      }))
      .sort((a) => (a.canUse ? -1 : 1));

  const checkShowBannerVoucher = () => {
    if (!bannerVoucher) return false;

    const { endTime } = bannerVoucher;
    const isValidTime = endTime && moment().isBefore(endTime);
    if (!isValidTime) return false;

    const { applyAllPackages, packages: validPackages } =
      bannerVoucher?.policy || {};
    if (applyAllPackages) return true;

    return validPackages.some(
      (pkg) => pkg.type.toLowerCase() === packageType.toLowerCase(),
    );
  };

  const checkShowBannerVoucherNew = () => {
    if (!bannerVoucher || !bannerVoucher.length) return false;
    const result = bannerVoucher.filter((voucher) => {
      const { endTime } = voucher;
      const isValidTime = endTime && moment().isBefore(endTime);
      if (!isValidTime) return false;

      const { applyAllPackages, packages: validPackages } =
        voucher?.policy || {};
      if (applyAllPackages) return true;

      return validPackages.some(
        (pkg) => pkg.type.toLowerCase() === packageType.toLowerCase(),
      );
    });

    return result;
  };

  // Show package highlight in the first row when responsive
  const packageSort = (pkg1, pkg2) => {
    const { code: code1, discountConfig: discountConfig1 } = pkg1;
    const { code: code2, discountConfig: discountConfig2 } = pkg2;
    const highlightPackage = getHighlightPackageCode(currentPackages);
    if ([STUDIO_BASIC_PACKAGE_CODE, STUDIO_FREE_PACKAGE_CODE].includes(code1))
      return -1;
    if ([STUDIO_BASIC_PACKAGE_CODE, STUDIO_FREE_PACKAGE_CODE].includes(code2))
      return 1;

    // if (code1 === DUBBING_BASIC_PACKAGE_CODE) return -1;
    // if (code2 === DUBBING_BASIC_PACKAGE_CODE) return 1;
    if (code1 === DUBBING_TRIAL_PACKAGE_CODE) return -1;
    if (code2 === DUBBING_TRIAL_PACKAGE_CODE) return 1;

    if (code1 === highlightPackage) return -1;
    if (code2 === highlightPackage) return 1;

    if (
      checkPackageDiscount(discountConfig1) &&
      !checkPackageDiscount(discountConfig2)
    )
      return -1;
    if (
      !checkPackageDiscount(discountConfig1) &&
      checkPackageDiscount(discountConfig2)
    )
      return 1;

    return 0;
  };

  const checkExpiredDate = (expiryDate) => {
    if (!expiryDate) return false;
    return moment().isAfter(expiryDate);
  };

  const getUsingPackage = (pkgType) => {
    switch (pkgType) {
      case PACKAGE_TYPE.STUDIO: {
        const studioPackage = packages.find(
          (pkg) => pkg.code === user?.packageCode,
        );
        return {
          ...studioPackage,
          isExpires: checkExpiredDate(user?.packageExpiryDate),
        };
      }
      case PACKAGE_TYPE.API: {
        const apiPackage = packages.find(
          (pkg) => pkg.code === user?.apiPackage?.packageCode,
        );
        return {
          ...apiPackage,
          isExpires: checkExpiredDate(user?.apiPackage?.packageExpiryDate),
        };
      }
      case PACKAGE_TYPE.DUBBING: {
        const dubbingPackage = packages.find(
          (pkg) => pkg.code === user?.dubbing?.packageCode,
        );
        return {
          ...dubbingPackage,
          isExpires: checkExpiredDate(user?.dubbing?.packageExpiryDate),
        };
      }
      default:
        return null;
    }
  };

  const renderFlagUI = (item) => {
    if (showNewListPackageUI) {
      return (
        <NewPackageItem
          data={item}
          duration={packageDuration}
          hasDiscountPackage={hasDiscountPackage}
          onDiscountEnd={handleDiscountEnd}
          isShowAllPackageInfo={isShowAllPackageInfo}
          setIsShowAllPackageInfo={setIsShowAllPackageInfo}
          isFirstTimePaid={isFirstTimePaid}
          discountForFirstPaidUser={discountForFirstPaidUser}
          firstPaidVoucher={firstPaidVoucher}
          canUseVouchers={filterUseableVoucher(item?.id)}
          currentUsingPackage={getUsingPackage(item?.type)}
        />
      );
    }
    return (
      <NewPackageItemOld
        data={item}
        duration={packageDuration}
        hasDiscountPackage={hasDiscountPackage}
        onDiscountEnd={handleDiscountEnd}
        isShowAllPackageInfo={isShowAllPackageInfo}
        setIsShowAllPackageInfo={setIsShowAllPackageInfo}
        isFirstTimePaid={isFirstTimePaid}
        discountForFirstPaidUser={discountForFirstPaidUser}
        firstPaidVoucher={firstPaidVoucher}
      />
    );
  };

  const renderPackages = (pkgs = []) =>
    pkgs.map((item) =>
      showNewPackagePriceUI ? (
        renderFlagUI(item)
      ) : (
        <PackageItem
          data={item}
          duration={packageDuration}
          hasDiscountPackage={hasDiscountPackage}
          onDiscountEnd={handleDiscountEnd}
        />
      ),
    );

  const checkUserFirstTimePaid = async () => {
    const payFees = 'pay_fees';
    const response = await api.orders.getUserPaidOrders(payFees);
    if (response.status && response.result?.orders.length < 1)
      if (discountForFirstPaidUser) setIsFirstTimePaid(true);
  };

  const filterVoucherBannerByProduct = () => {
    const result =
      isArray(bannerVoucher) &&
      bannerVoucher.length > 0 &&
      bannerVoucher.filter((voucher) => {
        const check = checkVoucherByProduct({ voucher, product: packageType });
        return check;
      });

    return result;
  };

  useEffect(() => {
    checkUserFirstTimePaid();
  }, [discountForFirstPaidUser]);

  useEffect(() => {
    fetchVoucherForFirstPaidUser();
    fetchTotalVouchers();
  }, []);

  useEffect(() => {
    fetchPackages();
  }, [packageType]);

  useEffect(() => {
    const result = filterVoucherBannerByProduct();
    setVoucherBannerToShow(result);
  }, [bannerVoucher, packageType]);

  useEffect(() => {
    handleSetCurrentPackages(packageDuration, packages);
  }, [usingPackage, packageDuration, packages]);

  useEffect(() => {
    if (latestOrder.id) setDisplayTrialPackage(false);
    else setDisplayTrialPackage(true);
  }, [latestOrder]);

  return (
    <StyledPackages
      isVietNam={language === LANGUAGE.VN}
      num={currentPackages.length}
    >
      <div className="tabs-header">
        {isDubbingRoute ? (
          <Typography className="package-type-label">
            {t('priceList')}
          </Typography>
        ) : (
          <PackageTypes
            types={packageTypeTabs}
            activeType={packageType}
            onChangeType={handleChangePackageType}
          />
        )}
      </div>
      <div className="duration-header">
        {showNewListPackageUI ? (
          <div className="tab-group-wrapper">
            {Object.values(DURATION).map((value) =>
              showPackageDuration ? (
                <div
                  className={
                    packageDuration === value
                      ? `tabs-active`
                      : `${value === DURATION.YEARLY ? 'pr-4' : ''} tabs`
                  }
                  onClick={() => handleChangePackageDuration(value)}
                  role="button"
                  tabIndex={0}
                >
                  {t(PACKAGE_DURATION_LABEL[value])}
                  {value === DURATION.YEARLY && saveUpToPercent ? (
                    <div className="save">
                      {t('saveUpTo', {
                        percent: saveUpToPercent,
                      }).toUpperCase()}
                    </div>
                  ) : (
                    <div className="no-save" />
                  )}
                </div>
              ) : null,
            )}
          </div>
        ) : (
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-package-duration"
            name="row-radio-buttons-group"
          >
            {Object.values(DURATION).map((value) =>
              showPackageDuration ? (
                <div className="package-duration-item">
                  <FormControlLabel
                    value={value}
                    control={
                      <Checkbox
                        checked={packageDuration === value}
                        className="checkbox-button"
                        icon={<RadioButtonUnchecked color="gray" />}
                        checkedIcon={<CheckCircle />}
                      />
                    }
                    label={
                      <Typography
                        className={classNames('package-duration-label', {
                          'package-duration-label-active':
                            packageDuration === value,
                        })}
                      >
                        {t(PACKAGE_DURATION_LABEL[value])}
                      </Typography>
                    }
                    onChange={() => setPackageDuration(value)}
                  />
                  {value === DURATION.YEARLY && saveUpToPercent && (
                    <div className="discount-year">
                      {t('saveUpTo', { percent: saveUpToPercent })}
                    </div>
                  )}
                </div>
              ) : null,
            )}
          </RadioGroup>
        )}

        {!isDubbingRoute && packageDuration !== DURATION.YEARLY ? (
          <div className="discount-year-mobile">
            {t('buyYearSaveUpTo', { percent: saveUpToPercent })}
          </div>
        ) : (
          <div className="discount-year-height" />
        )}
      </div>
      {!useVoucherByProduct && checkShowBannerVoucher() && (
        <div className="packages-wrapper">
          <VoucherBanner />
        </div>
      )}
      {useVoucherByProduct &&
        checkShowBannerVoucherNew() &&
        voucherBannerToShow.length > 0 && (
          <div className="packages-wrapper">
            <SwiperVoucherBanner bannerVoucher={voucherBannerToShow} />
          </div>
        )}
      <div ref={packagesRef} className="packages-wrapper">
        <ProcessHandler loading={loading} mt="30px" mb="30px" align="center">
          <div className="packages">
            {packagesWidth > PACKAGE_RESPONSIVE_BREAKPOINT
              ? renderPackages(currentPackages)
              : renderPackages(currentPackages?.slice()?.sort(packageSort))}
          </div>
        </ProcessHandler>
      </div>
      {showNewListPackageUI ? (
        <div style={{ paddingBottom: '16px' }}>
          <span className="notice-ai-voice">{t('noticeAIVoice1')}</span>
          <span className="notice-ai-voice">{t('noticeAIVoice2')}</span>
        </div>
      ) : (
        <div
          className="policy-voice"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(t('aiVoicePricingNote')),
          }}
        />
      )}
    </StyledPackages>
  );
};

export default NewPackages;
