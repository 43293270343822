import { franc } from 'franc';
import moment from 'moment';
import {
  DUBBING_LENGTH_LIMIT,
  DUB_FILE_FORMAT,
  ERROR_SEPARATOR,
  ERROR_TYPES,
  MAX_SENTENCE_LENGTH,
  SUBTITLES_S3_DIRECTORY,
} from '@src/constants/dubbing';
import { VALID_CHARACTERS_LENGTH_REGEX } from '@src/constants/tts';
import { isValidFile } from '@src/utils/checkValid';
import { createFileFromText } from '@src/utils/file';
import { getSRTDuration, parseSRTFile } from '@src/utils/srt';
import { uploadToS3FromFile } from './upload';

const getInvalidTextsErrorMessage = (content, invalidTexts) => {
  let errorMsg = content;
  invalidTexts.forEach((invalidText) => {
    errorMsg = errorMsg.replace(
      invalidText,
      `<span style="color: red">${invalidText}</span>`,
    );
  });
  return errorMsg;
};

const validateSubtitleFile = async (srtFile, acceptMultiLanguage = false) => {
  if (!srtFile) return { error: true, message: 'fileCannotBeEmpty' };

  const isValidSrtFile = isValidFile(srtFile.name, DUB_FILE_FORMAT);
  if (!isValidSrtFile) return { error: true, message: 'fileFormatError' };

  try {
    const { error, message, detail, parsedSubtitles } = await parseSRTFile(
      srtFile,
    );
    if (message === ERROR_TYPES.INVALID_SRT_FORMAT)
      return { error: true, message, detail };

    if (!parsedSubtitles?.length) throw new Error('invalidSrtFormat');

    // Check language of subtitle file is vietnamese or not
    const VIETNAMESE = 'vie';
    const subtitleContent = parsedSubtitles
      .map((subtitle) => subtitle.content)
      .join(' ');
    const detectLanguage = franc(subtitleContent);
    if (detectLanguage !== VIETNAMESE && !acceptMultiLanguage) {
      throw new Error('subtitleLanguageNotVietnamese');
    }

    if (error) return { error, message, detail };

    // Check if total audio duration is more than dubbing audio limit hours
    const subtitleDuration = getSRTDuration(parsedSubtitles);
    const subtitleDurationTooLong =
      subtitleDuration > DUBBING_LENGTH_LIMIT * 3600 * 1000;
    if (subtitleDurationTooLong) throw new Error('audioLengthTooLong');

    // Check if every sentences is less than MAX_SENTENCE_LENGTH characters
    const errBlocks = parsedSubtitles.filter(
      (subtitle) => subtitle.content.length > MAX_SENTENCE_LENGTH,
    );
    if (errBlocks?.length) {
      const err = new Error('sentenceTooLong');
      err.detail = errBlocks
        .map((block) => {
          const { id, start, end, content } = block;
          return `<div>${id}<br/>${start} --> ${end}<br/><span style="color: red">${content}</span></div>`;
        })
        .join(ERROR_SEPARATOR);
      throw err;
    }

    // Check if all words in subtitle file have less than MAX_CHARACTER_LENGTH characters
    const wordErrors = [];
    parsedSubtitles.forEach((subtitle) => {
      const { id, start, end, content } = subtitle;
      const invalidTexts = content.match(VALID_CHARACTERS_LENGTH_REGEX);
      if (invalidTexts?.length) {
        const errorMessage = `<div>${id}<br/>${start} --> ${end}<br/><span>${getInvalidTextsErrorMessage(
          content,
          invalidTexts,
        )}</span></div>`;
        wordErrors.push(errorMessage);
      }
    });
    if (wordErrors?.length) {
      const err = new Error('invalidCharacterLength');
      err.detail = wordErrors.join(ERROR_SEPARATOR);
      throw err;
    }

    return {
      detectLanguage,
      error: false,
      subtitles: parsedSubtitles,
      totalSeconds: subtitleDuration / 1000,
    };
  } catch (error) {
    return { error: true, message: error.message, detail: error.detail };
  }
};

const createSRTFile = async (fileName, sentences) => {
  try {
    const subtitleBlocks = Object.values(sentences);
    const srtContent = subtitleBlocks.reduce((result, block) => {
      const { id, start, end, content } = block;
      return `${result}${id}\n${start} --> ${end}\n${content}\n\n`;
    }, '');
    const file = createFileFromText(fileName, srtContent);
    const fileUrl = await uploadToS3FromFile(file, SUBTITLES_S3_DIRECTORY);
    return { error: false, fileUrl };
  } catch (error) {
    return { error: true };
  }
};

const checkCreateSrtFileSuccess = async (fileName, sentences) => {
  try {
    const subtitleBlocks = Object.values(sentences);
    const srtContent = subtitleBlocks.reduce((result, block) => {
      const { id, start, end, content } = block;
      return `${result}${id}\n${start} --> ${end}\n${content}\n\n`;
    }, '');
    const file = createFileFromText(fileName, srtContent);
    const { error } = await validateSubtitleFile(file);
    return { error };
  } catch (error) {
    return { error: true };
  }
};

const calculateDubbingDuration = async (subtitleLink) => {
  const response = await fetch(subtitleLink);
  const text = await response.text();
  if (text.length === 0) return 0;
  const srtFile = createFileFromText('subtitle.srt', text);
  const parseResult = await parseSRTFile(srtFile);
  const subtitleDuration = getSRTDuration(parseResult?.parsedSubtitles);
  return subtitleDuration;
};

const convertMillisToDubbingDuration = (millis) => {
  const timeDuration = moment.duration(millis);
  const formatNumber = (number) => (number < 10 ? `0${number}` : number);

  const hours = formatNumber(Math.floor(timeDuration.asHours()));
  const minutes = formatNumber(timeDuration.minutes());
  const seconds = formatNumber(timeDuration.seconds());

  if (timeDuration.milliseconds() > 0) {
    return `${hours}:${minutes}:${formatNumber(timeDuration.seconds() + 1)}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};

export {
  validateSubtitleFile,
  createSRTFile,
  calculateDubbingDuration,
  convertMillisToDubbingDuration,
  checkCreateSrtFileSuccess,
};
