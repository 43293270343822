import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@src/languages';
import { Typography } from '@mui/material';

import SrtFileIcon from '@src/assets/icons/srt-file.svg';
import { getShortFileName } from '@src/services/file';
import DirectBoxSendIcon from '@src/assets/icons/directbox-send.svg';
import { convertSecondsToHMS } from '@src/utils/time';
import {
  StyledDefaultDropzoneContent,
  StyledSuccessDropzoneContent,
} from './index.style';
import FileSelected from './FileSelected';
import SampleFileReference from './SampleFileReference';

const SuccessDropzoneContent = ({
  fileName,
  totalCharacters = 0,
  totalSeconds = 0,
  isProcessDubbingByUnitSecond = false,
  fileFormat,
  handleResetFile,
  isMultipleInputDubbing,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const OldDropzone = () => (
    <>
      <img src={SrtFileIcon} alt="srt-icon" />
      <Typography className="file-name">
        {getShortFileName(fileName)}
      </Typography>
      <Typography align="center" variant="h5" className="upload-file-title">
        {t('dropFileSuccessInstruct', { fileFormat })}
      </Typography>
      {isProcessDubbingByUnitSecond ? (
        <div className="total-characters">
          <Typography variant="h5" className="total-characters-text">
            {t('duration')}:
          </Typography>
          <Typography variant="h5" className="total-characters-number">
            {convertSecondsToHMS(totalSeconds)}
          </Typography>
        </div>
      ) : (
        <div className="total-characters">
          <Typography variant="h5" className="total-characters-text">
            {t('totalCharacters')}:
          </Typography>
          <Typography variant="h5" className="total-characters-number">
            {totalCharacters?.toLocaleString(language)}
          </Typography>
        </div>
      )}
    </>
  );

  const NewDropzone = () => (
    <StyledDefaultDropzoneContent>
      <img src={DirectBoxSendIcon} alt="srt-icon" />
      <div className="upload-instruction">
        <Typography
          align="center"
          variant="h5"
          className="upload-file-title text-blue"
        >
          {t('dropFileSuccessInstructSubtitle', { fileFormat })}
        </Typography>
        <SampleFileReference isMultipleInputDubbing />
      </div>
      {fileName?.length > 0 && (
        <FileSelected fileName={fileName} handleResetFile={handleResetFile} />
      )}
    </StyledDefaultDropzoneContent>
  );

  return (
    <StyledSuccessDropzoneContent>
      {isMultipleInputDubbing ? <NewDropzone /> : <OldDropzone />}
    </StyledSuccessDropzoneContent>
  );
};

export default SuccessDropzoneContent;
