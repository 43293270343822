import moment from 'moment';

const isSameToday = (date) => moment().isSame(date, 'day');

const convertFormatDateTime = (createdAt) => {
  if (isSameToday(createdAt)) return moment(createdAt).format('HH:mm');
  return moment(createdAt).format('DD/MM/YYYY');
};

const convertFormatDate = (date) => moment(date).format('DD/MM/YYYY');

const convertFormatTime = (date) => moment(date).format('HH:mm');

const calculateElapsedTime = (date, language) => {
  const currentTime = moment();
  const compareTime = moment(date);
  const differentMinutes = currentTime.diff(compareTime, 'minutes');

  if (differentMinutes < 0) return '';

  if (differentMinutes < 1) return language('justNow');

  if (differentMinutes < 60)
    return language('minutesAgo', { minutes: differentMinutes });

  return convertFormatTime(date);
};

const calculateElapsedDate = (date, language) => {
  const currentTime = moment();
  const compareTime = moment(date);

  const differentHours = currentTime.diff(compareTime, 'hours');
  if (differentHours <= 0) return '';

  const isToday = isSameToday(date);
  if (isToday) return language('today');

  const yesterday = moment().subtract(1, 'days').startOf('day');
  const isYesterday = moment(yesterday).isSame(date, 'day');
  if (isYesterday) return language('yesterday');

  return moment(date).format('DD/MM/YYYY');
};

const calculateElapsedDateTime = (date, language) => {
  const calculateTime = calculateElapsedTime(date, language);
  const calculateDate = calculateElapsedDate(date, language);

  if (calculateDate) return `${calculateDate} - ${calculateTime}`;
  return calculateTime;
};

export {
  convertFormatDateTime,
  convertFormatDate,
  convertFormatTime,
  isSameToday,
  calculateElapsedTime,
  calculateElapsedDate,
  calculateElapsedDateTime,
};
