import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  FileDownloadOutlined,
  FlagRounded,
} from '@mui/icons-material';
import { ClickAwayListener, Paper, Popper, Typography } from '@mui/material';
import apis from '@src/apis';
import { LANGUAGE } from '@src/constants';
import i18n from '@src/languages';
import { REQUEST_STATUS } from '@src/constants/voice';
import ErrorReport from '@src/containers/Dubbing/ListDubbing/ErrorReport';
import { downloadFileUsingUrl } from '@src/utils/file';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';

import {
  StyledDownloadButton,
  StyledMenu,
  StyledTextIcon,
} from './index.style';
import DownloadAudioButton from './DownloadAudioButton';

const DownloadButton = () => {
  const { t } = useTranslation();

  const { projectInfo } = useSelector((state) => state.dubbingRequest);
  const { user } = useSelector((state) => state.auth);

  const { getFeatureValue } = useFeatureFlags();
  const isMultipleInputDubbing = getFeatureValue(
    FEATURE_KEYS.MULTIPLE_INPUT_DUBBING,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const { language } = i18n;
  const [anchorEl, setAnchorEl] = useState(null);
  const [request, setRequest] = useState({});
  const [openErrorReport, setOpenErrorReport] = useState(false);

  const audioExpired = moment().isAfter(
    moment(request?.createdAt).add(request?.retentionPeriod, 'days'),
  );
  const isDisabledAudio = !request?.audioLink || audioExpired;

  const handleOpenErrorReport = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setOpenErrorReport(true);
  };
  const handleCloseErrorReport = () => setOpenErrorReport(false);

  const buttonRef = useRef(null);

  const handleClosePopup = () => setAnchorEl(null);

  const handleDownloadAudio = () => {
    downloadFileUsingUrl(request?.audioLink);
    handleClosePopup();
  };

  const handleOpenListAction = () => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(buttonRef.current);
  };

  const fetchRequestInfo = async (requestId) => {
    const response = await apis.requests.getRequest(requestId);
    if (response.status) setRequest(response.result);
  };

  const getTitleActionTooltip = (action) => {
    if (request?.status !== REQUEST_STATUS.SUCCESS) return t('');
    if (audioExpired)
      return t('notiAudioExpired', {
        retentionPeriod: request?.retentionPeriod,
      });

    return t(action);
  };

  const handleDownloadSrt = () => {
    // TODO: Implement download srt
    downloadFileUsingUrl(projectInfo.currentSubtitleLink);
  };

  const handleDownloadOriginalSrt = () => {
    if (projectInfo.originalInfo?.subtitleLink)
      downloadFileUsingUrl(projectInfo.originalInfo?.subtitleLink);
  };

  useEffect(() => {
    fetchRequestInfo(projectInfo.latestRequestId);
  }, [projectInfo.latestRequestId]);

  return (
    <StyledDownloadButton langEng={language !== LANGUAGE.VN} ref={buttonRef}>
      <DownloadAudioButton
        onClick={handleDownloadAudio}
        hasDisable={isDisabledAudio}
        getTitleActionTooltip={getTitleActionTooltip}
      />
      <div
        className="arrow-button default-background"
        onClick={handleOpenListAction}
        role="button"
        tabIndex="0"
      >
        {anchorEl ? (
          <ArrowDropUpRounded
            className="option-button"
            sx={{ fontSize: '26px' }}
          />
        ) : (
          <ArrowDropDownRounded
            className="option-button"
            sx={{ fontSize: '26px' }}
          />
        )}
      </div>
      {anchorEl && (
        <ClickAwayListener onClickAway={handleClosePopup}>
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            style={{
              zIndex: 11,
              background: 'white',
              left: language !== LANGUAGE.VN ? '-48px' : '-5px',
            }}
            disablePortal
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 5],
                },
              },
            ]}
          >
            <Paper>
              <StyledMenu engButton={language !== LANGUAGE.VN}>
                <StyledTextIcon
                  disableRipple
                  onClick={handleOpenErrorReport}
                  langEng={language !== LANGUAGE.VN}
                >
                  <FlagRounded />
                  <Typography className="text-action">
                    {t('errorReport')}
                  </Typography>
                </StyledTextIcon>
                <StyledTextIcon
                  disableRipple
                  onClick={() => {
                    if (isMultipleInputDubbing) handleDownloadOriginalSrt();
                    else handleDownloadSrt();
                  }}
                  langEng={language !== LANGUAGE.VN}
                >
                  <FileDownloadOutlined className="download-icon" />
                  <Typography className="text-download">
                    {isMultipleInputDubbing
                      ? t('downloadOriginalSrtFile')
                      : t('downloadSrtFile')}
                  </Typography>
                </StyledTextIcon>
                {isMultipleInputDubbing &&
                  projectInfo?.originalInfo?.subtitleLink && (
                    <StyledTextIcon
                      disableRipple
                      onClick={handleDownloadSrt}
                      langEng={language !== LANGUAGE.VN}
                    >
                      <FileDownloadOutlined className="download-icon" />
                      <Typography className="text-download">
                        {t('downloadTranslatedSrtFile')}
                      </Typography>
                    </StyledTextIcon>
                  )}
              </StyledMenu>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
      <ErrorReport
        open={openErrorReport}
        request={request}
        onClose={handleCloseErrorReport}
      />
    </StyledDownloadButton>
  );
};

export default DownloadButton;
