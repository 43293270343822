import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledPreviewButton = styled('div')`
  padding: 10px 10px 0px 0;
  bottom: -10px;

  .wrapper {
    width: 110px;
    display: flex;
    border-radius: 55px;
  }

  .wrapper-disable {
    border: 1px solid ${COLOR.indigo[8]};
  }

  .wrapper-able {
    border: 1px solid ${COLOR.indigo[32]};
  }

  .preview-button {
    text-transform: none;
    padding: 0px;
    min-width: fit-content;
    width: 24px;
    height: 24px;
    line-height: 32px;
    background-color: ${COLOR.white};
    border-radius: 55px;
    &:active {
      background-color: ${COLOR.black};
    }
  }

  .play-button {
    position: relative;
    display: flex;
    height: 32px;
    padding: 0;
    min-width: 105px;
    border-radius: 480px;
    align-items: base-line;
    justify-content: center;
    border-radius: 55px;

    &:hover {
      background-color: ${COLOR.white};
    }

    &:active {
      background-color: ${COLOR.black};
    }
  }

  .button-wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.white};
    border-radius: 55px;
  }

  .preview-button-content {
    line-height: 32px;
    font-size: 12px;
    font-weight: 600;
    padding-right: 6px;
    text-transform: none !important;
  }
`;

const StyledPopupPreviewButton = styled('div')`
  .custom-button {
    border-radius: 100%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export { StyledPreviewButton, StyledPopupPreviewButton };
