import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import apis from '@src/apis';
import { downloadFileUsingUrl } from '@src/utils/file';
import actions from '@src/redux/actions';
import { Typography } from '@mui/material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { DOWNLOAD_QUOTA_EXCEEDED_CODE } from '@src/constants/tts';
import dataSenses from '@src/services/dataSenses';
import { REQUEST_TYPE } from '@src/constants/request';
import { FileDownloadOutlined } from '@mui/icons-material';
import { StyledDownloadButton } from './index.style';
import { StyledActionTooltip } from '../Requests/index.style';

const DownloadButton = ({
  hasDisable,
  // onClick,
  requestId,
  title,
  userId,
  getTitleActionTooltip,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getFeatureValue } = useFeatureFlags();
  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const handleDownloadAudio = async () => {
    if (hasDisable) return;
    // onClick();
    dataSenses.sendDownloadAudioEvent({
      userId,
      requestType: REQUEST_TYPE.STUDIO,
    });
    const res = isLimitDownloadFreePackage
      ? await apis.requests.getAudioDownloadUrl(requestId)
      : await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      return;
    }
    if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  return (
    <StyledActionTooltip
      title={getTitleActionTooltip('')}
      arrow
      placement="bottom"
      disabled={hasDisable}
    >
      <StyledDownloadButton
        className="download-button default-background"
        onClick={handleDownloadAudio}
        role="button"
        tabIndex="0"
        disabled={hasDisable}
      >
        <FileDownloadOutlined className="download-icon" />
        <Typography className="text-download">{t('download')}</Typography>
      </StyledDownloadButton>
    </StyledActionTooltip>
  );
};

export default DownloadButton;
