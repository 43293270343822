import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material';
import { getCookie } from '@src/utils/cookie';
import i18n from '@src/languages';
import { COUNTRY, LANGUAGE } from '@src/constants';
import { getPackageName } from '@src/services/package';
import BadgeAvatar from '@src/components/BadgeAvatar';
import IconArrowLeft from '@src/assets/icons/arrow-left.svg';
import { delimitNumber } from '@src/utils/number';
import { BREAKPOINTS } from '@src/styles/config';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { convertSecondsToHMS } from '@src/utils/time';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useCustomSSO from '@src/hooks/useCustomSSO';
import UserActions from './UserActions';
import {
  StyledPopper,
  StyledSidebarTooltip,
  StyledUserInfoTooltip,
  StyledUserSidebarNew,
} from './index.style';

const languages = [
  {
    value: LANGUAGE.VN,
    country: COUNTRY.VN,
    label: 'vietnamese',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
  },
  {
    value: LANGUAGE.EN,
    country: COUNTRY.EN,
    label: 'english',
    icon: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/gb.png',
  },
];

const UserInfo = ({
  handleCloseSidebar,
  ipLocation,
  smallSidebar = false,
  isMobile,
}) => {
  const [openActionsAnchor, setOpenActionsAnchor] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const isDubbingRoute = useCheckDubbingRoute();

  const { getFeatureValue } = useFeatureFlags();

  const { keycloak } = useCustomSSO();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isShowingDubbingUnitSecond =
    isProcessDubbingByUnitSecond && isDubbingRoute;

  const { usingPackage, lastExpiredOrder, usingDubbingPackage } = useSelector(
    (state) => state.user,
  );
  const { language } = i18n;
  const { remainingCharacters, bonusCharacters, dubbing } = useSelector(
    (state) => state.auth.user,
  );

  let packageName;
  if (isDubbingRoute)
    packageName = getPackageName(
      usingDubbingPackage?.name,
      usingDubbingPackage?.code,
      language,
    );
  else
    packageName = usingPackage.id
      ? getPackageName(usingPackage.name, usingPackage.code, language)
      : getPackageName(
          lastExpiredOrder?.package?.name,
          user.packageCode,
          language,
        );
  const packageExpiryDate = isDubbingRoute
    ? dubbing?.packageExpiryDate
    : user.packageExpiryDate;

  const totalSeconds =
    dubbing?.remainingSeconds > 0 ? dubbing?.remainingSeconds : 0;

  const remainingText = isShowingDubbingUnitSecond
    ? t('remainSeconds', { numberSeconds: convertSecondsToHMS(totalSeconds) })
    : t('remainCharacters', {
        numberCharacters: delimitNumber(
          remainingCharacters + bonusCharacters,
          language,
        ),
      });

  const handleAvatarClick = (e) => setOpenActionsAnchor(e.currentTarget);

  const TooltipTitle = () => (
    <StyledUserInfoTooltip>
      <Typography variant="body2">{`${user.lastName} ${user.firstName}`}</Typography>
      <Typography variant="body2">{`${t('pack')}: ${packageName}`}</Typography>
      <Typography variant="body2">{remainingText}</Typography>
      <Typography variant="body2" className="expired-date">
        {`${t('expiredDate')}: ${
          packageExpiryDate
            ? moment(packageExpiryDate).format('DD/MM/YYYY')
            : t('endless')
        }`}
      </Typography>
    </StyledUserInfoTooltip>
  );

  const checkWindowWidth = () => {
    if (window.innerWidth <= BREAKPOINTS.SM) {
      setOpenActionsAnchor(null);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', checkWindowWidth);
    return () => window.removeEventListener('resize', checkWindowWidth);
  }, []);

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
  };

  useEffect(() => {
    const currentLanguageValue = getCookie('lng');
    const currentLanguage = languages.find(
      (lng) => lng.value === currentLanguageValue,
    );

    if (ipLocation) {
      const { country: countryCode } = ipLocation;
      localStorage.setItem('country', COUNTRY[countryCode] || COUNTRY.EN);
    }

    if (currentLanguage) {
      handleChangeLanguage(currentLanguage);
      return;
    }

    const countryLanguageValue = localStorage.getItem('country');
    const countryLanguage = languages.find(
      (lng) => lng.country === countryLanguageValue,
    );
    if (countryLanguage) {
      handleChangeLanguage(countryLanguage);
      return;
    }

    handleChangeLanguage(languages[0]);
  }, [ipLocation]);

  if (isMobile) {
    return (
      <StyledUserSidebarNew padding="0px">
        <IconButton className="avatar-wrapper">
          <div className="avatar">
            <BadgeAvatar
              img={user.avatar}
              active={keycloak && keycloak.authenticated}
              name={user.firstName || user.lastName}
              number={new Date(user.createdAt)}
              icon={
                usingPackage.price > 0 ? (
                  <Box className="box-icon">
                    <img
                      src={usingPackage.icon}
                      alt="package-icon"
                      className="icon"
                    />
                  </Box>
                ) : null
              }
              smallImgWidth={20}
              type="icon"
              removeDefaultIcon
            />
          </div>
        </IconButton>
      </StyledUserSidebarNew>
    );
  }

  return (
    <>
      {smallSidebar && (
        <StyledSidebarTooltip title={<TooltipTitle />}>
          <StyledUserSidebarNew padding="0px">
            <IconButton className="avatar-wrapper" onClick={handleAvatarClick}>
              <div className="avatar">
                <BadgeAvatar
                  img={user.avatar}
                  active={keycloak && keycloak.authenticated}
                  name={user.firstName || user.lastName}
                  number={new Date(user.createdAt)}
                  icon={
                    usingPackage.price > 0 ? (
                      <Box className="box-icon">
                        <img
                          src={usingPackage.icon}
                          alt="package-icon"
                          className="icon"
                        />
                      </Box>
                    ) : null
                  }
                  smallImgWidth={20}
                  type="icon"
                  removeDefaultIcon
                />
              </div>
            </IconButton>
          </StyledUserSidebarNew>
        </StyledSidebarTooltip>
      )}
      {!smallSidebar && (
        <StyledUserSidebarNew onClick={handleAvatarClick}>
          <div>
            <IconButton className="avatar-wrapper">
              <div className="avatar">
                <BadgeAvatar
                  img={user.avatar}
                  active={keycloak && keycloak.authenticated}
                  name={user.firstName || user.lastName}
                  number={new Date(user.createdAt)}
                  icon={
                    usingPackage.price > 0 ? (
                      <Box className="box-icon">
                        <img
                          src={usingPackage.icon}
                          alt="package-icon"
                          className="icon"
                        />
                      </Box>
                    ) : null
                  }
                  smallImgWidth={20}
                  type="icon"
                  removeDefaultIcon
                />
              </div>
            </IconButton>
          </div>
          <Box className="user-info">
            <StyledSidebarTooltip
              title={`${user.lastName} ${user.firstName}`}
              placement="right"
            >
              <Typography
                className="name"
                noWrap
              >{`${user.firstName}`}</Typography>
            </StyledSidebarTooltip>
            <Typography className="package" noWrap>
              {packageName}
            </Typography>
          </Box>
          <Box className="close-sidebar">
            <StyledSidebarTooltip title={t('collapse')} placement="right">
              <IconButton className="button-close" onClick={handleCloseSidebar}>
                <img src={IconArrowLeft} alt="icon-arrow" />
              </IconButton>
            </StyledSidebarTooltip>
          </Box>
        </StyledUserSidebarNew>
      )}

      {openActionsAnchor && (
        <ClickAwayListener onClickAway={() => setOpenActionsAnchor(null)}>
          <StyledPopper
            open={Boolean(openActionsAnchor)}
            placement="top-end"
            anchorEl={openActionsAnchor}
            onClose={() => setOpenActionsAnchor(null)}
            modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]}
            onClick={() => setOpenActionsAnchor(null)}
          >
            <UserActions location={ipLocation} />
          </StyledPopper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default UserInfo;
