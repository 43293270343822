import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

const StyledManagerDevice = styled('div')`
  padding: 16px 0px;

  .manager-device-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .title {
    padding: 0px 20px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .description {
    padding: 0px 20px;
    margin-bottom: 16px;
    font-size: 16px;
  }

  .blue-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3f51b5;
  }
`;

const StyledChip = styled(Typography)`
  background-color: ${({ color }) => TRANSPARENT_COLOR[color]};
  color: ${({ color }) => COLOR[color]};
  padding: 1px 10px;
  border-radius: 17px;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOSName = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;

  .blue-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${COLOR.primary};
  }
`;

const StyledAction = styled('div')`
  color: ${COLOR.primary};
  cursor: pointer;
  font-size: 14px;
`;

const StyledActionSelectedDevices = styled('div')`
  margin-top: 16px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action-buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }
`;

export {
  StyledManagerDevice,
  StyledChip,
  StyledOSName,
  StyledAction,
  StyledActionSelectedDevices,
};
