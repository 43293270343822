import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import Dropzone from '@src/components/Dropzone';
import actions from '@src/redux/actions';
import { validateSubtitleFile } from '@src/services/dubbing';
import {
  DUBBING_LENGTH_LIMIT,
  DUBBING_VIDEO_SOURCE,
  DUB_FILE_FORMAT,
  INPUT_FILE_TYPES,
  MAX_FILE_NAME_LENGTH,
} from '@src/constants/dubbing';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { isValidFile } from '@src/utils/checkValid';
import apis from '@src/apis';
import { getSRTDuration } from '@src/utils/srt';
import {
  getYoutubeCaptions,
  getYoutubeVideoDuration,
} from '@src/services/youtube';
import { getVideoDuration } from '@src/utils/file';

import { StyledUploadSubtitleFile } from './index.style';
import DefaultDropzoneContent from './DefaultDropzoneContent';
import SuccessDropzoneContent from './SuccessDropzoneContent';
import FailedDropzoneContent from './FailedDropzoneContent';
import YoutubeContent from './YoutubeContent';

const UPLOAD_STATUS = {
  INIT: 'INIT',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const UploadSubtitleFile = ({
  resetRef,
  onChangeUploadingStatus,
  totalCharacters,
  totalSeconds,
  onChangeTotalSeconds,
  onResetFile,
  setFileSelectedForDubbing,
}) => {
  const { t } = useTranslation();
  const [isGenerateSubtitle, setIsGenerateSubtitle] = useState(false);
  const [isShowingSubtitle, setIsShowingSubtitle] = useState(false);
  const [isYoutubeVideo, setIsYoutubeVideo] = useState(false);
  const [subtitleFile, setSubtitleFile] = useState(null);
  const [status, setStatus] = useState(UPLOAD_STATUS.INIT);
  const [error, setError] = useState();
  const [youtubeData, setYoutubeData] = useState({});
  const [errorWhenGenerateSubtitle, setErrorWhenGenerateSubtitle] =
    useState('');

  const { user } = useSelector((state) => state.auth);
  const {
    sentences: dubbingSentences,
    youtubeLink,
    infoForDubbingAPI,
  } = useSelector((state) => state.dubbingRequest);
  const { getFeatureValue } = useFeatureFlags();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isMultipleInputDubbing = false;

  const dubbingFileFormat = isMultipleInputDubbing
    ? [...DUB_FILE_FORMAT, 'mp4']
    : DUB_FILE_FORMAT;

  const fileFormat = dubbingFileFormat?.map((type) => `.${type}`)?.join(', ');

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleResetFile = () => {
    onResetFile();
    setSubtitleFile(null);
    setError();
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('fileName', null),
    );
    if (isMultipleInputDubbing) {
      dispatch(
        actions.dubbingRequest.resetDubbingRequest([
          'title',
          'fileId',
          'error',
          'fileName',
          'sentences',
          'infoForDubbingAPI',
        ]),
      );
    }
  };

  const handleChangeFileInput = (e) => {
    const { files } = e.target;
    if (files[0]) setSubtitleFile(files[0]);
  };

  const handleUploadSubtitleFileFail = () => {
    dispatch(actions.dubbingRequest.updateDubbingRequestByKey('fileId', null));
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('fileName', null),
    );
    dispatch(actions.dubbingRequest.updateDubbingRequestByKey('sentences', {}));
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('infoForDubbingAPI', {}),
    );
  };

  const handleUploadSubtitleFileSuccess = async (
    file,
    type = INPUT_FILE_TYPES.SRT,
  ) => {
    const displayName =
      type === INPUT_FILE_TYPES.YOUTUBE
        ? file
        : file.name?.slice(0, file.name.lastIndexOf('.'));
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'fileName',
        displayName?.trim()?.slice(0, MAX_FILE_NAME_LENGTH),
      ),
    );

    const fileId = uuid();
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('fileId', fileId),
    );
  };

  const handleReceiveVideoFile = async (file) => {
    onChangeUploadingStatus(true);
    setYoutubeData({});
    setIsYoutubeVideo(false);
    dispatch(
      actions.dubbingRequest.resetDubbingRequest([
        'youtubeLink',
        'title',
        'sentences',
        'infoForDubbingAPI',
      ]),
    );
    const displayName = file.name?.slice(0, file.name.lastIndexOf('.'));
    // If video file is uploaded, get video duration and save into infoForDubbingAPI in redux
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'fileName',
        displayName?.trim()?.slice(0, MAX_FILE_NAME_LENGTH),
      ),
    );

    const fileId = uuid();
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('fileId', fileId),
    );
    const videoDuration = await getVideoDuration(file);
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey('infoForDubbingAPI', {
        videoDuration,
        fileType: INPUT_FILE_TYPES.VIDEO,
      }),
    );

    setStatus(UPLOAD_STATUS.SUCCESS);
    onChangeUploadingStatus(false);
  };

  const handleSaveSubtitleSentences = (subtitles = []) => {
    const subtitleSentences = subtitles.reduce((sentences, currBlock) => {
      const sentenceId = uuid();
      // eslint-disable-next-line no-param-reassign
      sentences[sentenceId] = currBlock;
      return sentences;
    }, {});
    dispatch(
      actions.dubbingRequest.updateDubbingRequestByKey(
        'sentences',
        subtitleSentences,
      ),
    );
  };

  const handleExtractDataFromSrtFile = async (file) => {
    try {
      const {
        error: isError,
        message,
        detail,
        subtitles,
        totalSeconds: subtitleDuration,
      } = await validateSubtitleFile(file);

      if (isError) {
        const err = new Error(message);
        err.detail = detail;
        throw err;
      }
      setYoutubeData({});
      setIsYoutubeVideo(false);
      dispatch(actions.dubbingRequest.resetDubbingRequest(['youtubeLink']));
      setError();
      onChangeTotalSeconds(subtitleDuration);
      onChangeUploadingStatus(true);
      handleUploadSubtitleFileSuccess(file); // Save fileName and fileUrl to redux
      if (isMultipleInputDubbing) {
        dispatch(
          actions.dubbingRequest.updateDubbingRequestByKey(
            'infoForDubbingAPI',
            {
              sentences: subtitles,
              fileType: INPUT_FILE_TYPES.SRT,
            },
          ),
        );
      } else {
        handleSaveSubtitleSentences(subtitles); // Save subtitle sentences to redux
      }
      setStatus(UPLOAD_STATUS.SUCCESS);
      onChangeUploadingStatus(false);
    } catch (err) {
      onChangeUploadingStatus(false);
      handleUploadSubtitleFileFail(); // Reset fileName, fileUrl, sentences in redux
      setError({ message: err.message, detail: err.detail });
      setStatus(UPLOAD_STATUS.FAILED);
    }
  };

  const handleChangeSubtitleFile = async (file) => {
    if (!file) {
      setStatus(UPLOAD_STATUS.INIT);
      return;
    }
    if (isValidFile(file.name, INPUT_FILE_TYPES.SRT)) {
      handleExtractDataFromSrtFile(file);
    } else if (
      isValidFile(file.name, INPUT_FILE_TYPES.MP4) &&
      isMultipleInputDubbing
    ) {
      handleReceiveVideoFile(file);
    } else {
      setStatus(UPLOAD_STATUS.FAILED);
      setError({
        message: t('fileFormatError', { fileType: fileFormat }),
      });
    }
  };

  const generateSubtitleFromYoutube = async () => {
    try {
      setIsGenerateSubtitle(true);

      const videoDuration = await getYoutubeVideoDuration(youtubeLink);
      if (videoDuration / 1000 > DUBBING_LENGTH_LIMIT * 3600) {
        setErrorWhenGenerateSubtitle('durationOfVideoTooLong');
        setIsGenerateSubtitle(false);
        return;
      }

      const { hasSubtitle, captionsData } = await getYoutubeCaptions(
        youtubeLink,
      );
      if (!hasSubtitle) {
        setErrorWhenGenerateSubtitle('systemOnlySupportVideosContainSubtitle');
        setIsGenerateSubtitle(false);
        return;
      }

      const hasVnSubtitle = captionsData.items.some(
        (caption) => caption.snippet.language === 'vi',
      );

      if (!hasVnSubtitle) {
        setErrorWhenGenerateSubtitle('systemOnlySupportVietnameseSubtitle');
        setIsGenerateSubtitle(false);
        return;
      }

      const response = await apis.dubbing.generateDubbingSubtitles(
        youtubeLink,
        DUBBING_VIDEO_SOURCE.YOUTUBE,
      );
      if (response.status) {
        const subtitles = response?.result.content.map((subtitle, idx) => ({
          id: idx + 1,
          start: subtitle.startTime,
          end: subtitle.endTime,
          content: subtitle.content,
        }));
        const timeOfFile = getSRTDuration(subtitles);
        onChangeTotalSeconds(timeOfFile / 1000);
        handleUploadSubtitleFileSuccess(
          youtubeData.title,
          INPUT_FILE_TYPES.YOUTUBE,
        );
        handleSaveSubtitleSentences(subtitles);
        setStatus(UPLOAD_STATUS.SUCCESS);
        setIsShowingSubtitle(true);
      }
      setIsGenerateSubtitle(false);
    } catch (err) {
      handleUploadSubtitleFileFail(); // Reset fileName, fileUrl, sentences in redux
      setError({ message: err.message, detail: err.detail });
      setStatus(UPLOAD_STATUS.FAILED);
      setIsGenerateSubtitle(false);
    }
  };

  const handleClickViewSubtitle = () => {
    if (
      subtitleFile &&
      isValidFile(subtitleFile.name, INPUT_FILE_TYPES.MP4) &&
      youtubeLink
    ) {
      setErrorWhenGenerateSubtitle('onlyOneSource');
      return;
    }

    setErrorWhenGenerateSubtitle('');

    if (subtitleFile && isValidFile(subtitleFile.name, INPUT_FILE_TYPES.SRT)) {
      handleSaveSubtitleSentences(infoForDubbingAPI.sentences);
      return;
    }

    if (isYoutubeVideo && youtubeLink) {
      generateSubtitleFromYoutube();
    }
  };

  const renderDropzoneContent = () => {
    switch (status) {
      case UPLOAD_STATUS.INIT:
        return (
          <DefaultDropzoneContent
            isMultipleInputDubbing={isMultipleInputDubbing}
            fileFormat={fileFormat}
          />
        );
      case UPLOAD_STATUS.SUCCESS:
        return (
          <SuccessDropzoneContent
            fileName={subtitleFile?.name}
            totalCharacters={totalCharacters}
            totalSeconds={totalSeconds}
            isProcessDubbingByUnitSecond={isProcessDubbingByUnitSecond}
            fileFormat={fileFormat}
            isMultipleInputDubbing={isMultipleInputDubbing}
            handleResetFile={handleResetFile}
            isYoutubeVideo={isYoutubeVideo}
          />
        );
      case UPLOAD_STATUS.FAILED:
        return (
          <FailedDropzoneContent
            fileName={subtitleFile?.name}
            error={error}
            fileFormat={fileFormat}
            dubbingFileFormat={dubbingFileFormat}
            isMultipleInputDubbing={isMultipleInputDubbing}
          />
        );
      default:
        return null;
    }
  };

  const resetErrorWhenStatusFailed = () => {
    if (status === UPLOAD_STATUS.FAILED) {
      setSubtitleFile(null);
      setFileSelectedForDubbing();
      setError();
      setStatus(UPLOAD_STATUS.INIT);
    }
  };

  useEffect(() => {
    handleChangeSubtitleFile(subtitleFile);
    setFileSelectedForDubbing(subtitleFile);
  }, [subtitleFile]);

  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (!fileInput) return () => {};

    // Reset input value to fix bug when user upload same file
    const handleResetInputValue = () => {
      fileInput.value = null;
    };
    fileInput.addEventListener('click', handleResetInputValue);
    return () => {
      fileInput.removeEventListener('click', handleResetInputValue);
    };
  }, [fileInputRef]);

  useEffect(() => {
    if (!isMultipleInputDubbing) return;

    if (
      isYoutubeVideo &&
      Object.keys(dubbingSentences).length === 0 &&
      isShowingSubtitle
    ) {
      dispatch(actions.dubbingRequest.resetDubbingRequest(['youtubeLink']));
      setYoutubeData({});
      setIsYoutubeVideo(false);
      setIsShowingSubtitle(false);
      setStatus(UPLOAD_STATUS.INIT);
    }
  }, [dubbingSentences]);

  return (
    <StyledUploadSubtitleFile isError={status === UPLOAD_STATUS.FAILED}>
      <Button
        ref={resetRef}
        sx={{ display: 'none' }}
        onClick={handleResetFile}
      />
      <Dropzone
        onDropFiles={(files) => {
          if (Object.keys(dubbingSentences).length > 0) {
            setErrorWhenGenerateSubtitle(
              'systemOnlySupportProcessingOneFileAtOneTime',
            );
            setIsGenerateSubtitle(false);
            return;
          }
          setSubtitleFile(files[0]);
        }}
      >
        <Box className="dropzone">
          <div
            role="button"
            onClick={() => fileInputRef.current.click()}
            tabIndex={0}
            className="dropzone-content"
          >
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              accept={dubbingFileFormat?.map((type) => `.${type}`)?.join(',')}
              onChange={handleChangeFileInput}
            />
            {renderDropzoneContent()}
          </div>
          {isMultipleInputDubbing && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Divider />
                <Typography className="divider">{t('or')}</Typography>
                <Divider />
              </Box>
              <YoutubeContent
                setIsYoutubeVideo={setIsYoutubeVideo}
                youtubeData={youtubeData}
                setYoutubeData={setYoutubeData}
                setStatus={setStatus}
                onResetFile={onResetFile}
                errorWhenGenerateSubtitle={errorWhenGenerateSubtitle}
                setErrorWhenGenerateSubtitle={setErrorWhenGenerateSubtitle}
                resetErrorWhenStatusFailed={resetErrorWhenStatusFailed}
              />

              {(isYoutubeVideo || subtitleFile) &&
                !error &&
                Object.keys(infoForDubbingAPI).length > 0 &&
                infoForDubbingAPI?.fileType !== INPUT_FILE_TYPES.VIDEO &&
                Object.keys(dubbingSentences).length === 0 && (
                  <Tooltip
                    title={
                      <Box className="view-subtitle-tooltip">
                        <Typography>{t('viewSubtitlesDetail')}</Typography>
                      </Box>
                    }
                    arrow
                    placement="right"
                    sx={{
                      maxWidth: '232px',
                    }}
                  >
                    <Button
                      className={`${
                        isGenerateSubtitle
                          ? 'view-subtitle-disabled'
                          : 'view-subtitle'
                      }`}
                      onClick={handleClickViewSubtitle}
                      disabled={isGenerateSubtitle}
                    >
                      <Typography className="view-subtitle-text">
                        {isGenerateSubtitle
                          ? t('processing')
                          : t('viewSubtitles')}
                      </Typography>
                    </Button>
                  </Tooltip>
                )}
            </>
          )}
        </Box>
      </Dropzone>
    </StyledUploadSubtitleFile>
  );
};

export default UploadSubtitleFile;
