import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import Card from '@src/components/Card';
import { EditOutlined } from '@mui/icons-material';

import { LANDING_PAGE_URL } from '@src/configs';
import apis from '@src/apis';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import ProcessHandler from '@src/components/ProcessHandler';
import { nonAccentVietnamese } from '@src/utils/accent';
import { SPECIAL_CHARACTER_REGEX } from '@src/constants';
import { LinkBoxStyled } from './index.style';
import CopyButton from './CopyButton';

const LinkAffiliate = ({ affiliateCode, onChangeCode }) => {
  const [link, setLink] = useState(`${LANDING_PAGE_URL}/?aff=${affiliateCode}`);
  const [code, setCode] = useState(affiliateCode);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeCode = (event) => {
    const refactorCode = event.target.value
      .trim()
      .replace(SPECIAL_CHARACTER_REGEX, '');

    setCode(nonAccentVietnamese(refactorCode));
  };

  const handleConfirmChangeCode = async () => {
    if (code === affiliateCode) return;
    if (!code) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'affiliateCodeEmpty',
        }),
      );
      return;
    }
    setLoading(true);
    const response = await apis.affiliateMarketer.editAffiliateMarketerCode(
      code,
    );
    setLoading(false);

    if (!response?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: response?.message ?? 'editAffiliateMarketerCodeFailed',
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'success',
        message: 'editAffiliateMarketerCodeSuccess',
      }),
    );
    setLink(`${LANDING_PAGE_URL}/?aff=${code}`);
    onChangeCode(`${LANDING_PAGE_URL}/?aff=${affiliateCode}`);
  };

  useEffect(() => {
    setCode(affiliateCode);
    setLink(`${LANDING_PAGE_URL}/?aff=${affiliateCode}`);
  }, [affiliateCode]);

  return (
    <LinkBoxStyled>
      <Card className="link-box">
        <div className="title-link">{t('referralLink')}</div>
        <div className="link-affiliate">
          <TextField
            variant="outlined"
            size="small"
            disabled
            className="text-link"
            value={link}
          />
          <CopyButton content={link} />
        </div>
      </Card>
      <Card className="link-box">
        <div className="title-link">{t('customizeAffiliateLink')}</div>
        <div className="title-link-description">
          {t('customizeAffiliateLinkDecs')}
        </div>
        <div className="title-link-note">{t('customizeAffiliateLinkNote')}</div>
        <div className="link-affiliate">
          <TextField
            variant="outlined"
            size="small"
            disabled={loading}
            className="text-link"
            value={code}
            onChange={handleChangeCode}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            className="button-copy"
            disabled={loading}
            startIcon={<EditOutlined />}
            onClick={handleConfirmChangeCode}
          >
            <ProcessHandler loading={loading} size={24}>
              {code === affiliateCode ? t('edit') : t('saveChange')}
            </ProcessHandler>
          </Button>
        </div>
      </Card>
    </LinkBoxStyled>
  );
};

export default LinkAffiliate;
