import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import tickGreenIcon from '@src/assets/images/tick-green.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getPackageName } from '@src/services/package';
import { checkVietNam } from '@src/utils/checkCountry';
import { StyledNotificationDialog } from './index.style';

const NotificationDialog = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVietNam = checkVietNam();
  const { user } = useSelector((state) => state.auth);
  const { usingPackage, lastExpiredOrder } = useSelector((state) => state.user);

  const packageName = usingPackage.id
    ? getPackageName(usingPackage.name, usingPackage.code, language)
    : getPackageName(
        lastExpiredOrder?.package?.name,
        user.packageCode,
        language,
      );

  const nextPaymentDate = moment(user.packageExpiryDate).format('DD/MM/YYYY');
  const originalPrice = usingPackage?.price;

  const renderLocalizationPrice = (price) => (
    <div>
      {!isVietNam && <span className="discount-unit-price text-16">$</span>}
      {isVietNam && (
        <span className="discount-unit-price text-16 underline">đ</span>
      )}
      {price?.toLocaleString(language)}
    </div>
  );

  return (
    <StyledNotificationDialog open={open} maxWidth="xs" fullWidth>
      <Box className="wrapper">
        <Box className="title-wrapper">
          <img src={tickGreenIcon} alt="icon" className="img" />
          <Typography className="title-content">
            {t('associateAutoRenewSuccessTitle')}
          </Typography>
        </Box>
        <Divider />
        <Box className="content-wrapper">
          <Box className="row">
            <Typography className="label">{t('pack')}</Typography>
            <Typography className="value">{packageName}</Typography>
          </Box>
          <Box className="row">
            <Typography className="label">{t('subscriptionCycle')}</Typography>
            <Typography className="value">
              {usingPackage?.expiresIn} {t('days')}
            </Typography>
          </Box>
          <Box className="row">
            <Typography className="label">{t('nextPayment')}</Typography>
            <Typography className="value">{nextPaymentDate}</Typography>
          </Box>
          <Box className="row">
            <Typography className="label">{t('amountToBePaid')}</Typography>
            <Typography className="value">
              {renderLocalizationPrice(originalPrice)}
            </Typography>
          </Box>
        </Box>
        <Box className="action">
          <Button
            className="action-button"
            onClick={onClose}
            variant="contained"
            color="primary"
          >
            {t('ok')}
          </Button>
        </Box>
      </Box>
    </StyledNotificationDialog>
  );
};

export default NotificationDialog;
