const {
  YOUTUBE_API_URL,
  YOUTUBE_API_KEY,
  YOUTUBE_THUMBNAIL_URL,
} = require('@src/configs');

const getVideoIdFromYoutubeLink = (link) => {
  let videoId = link.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }

  return videoId;
};

const getYoutubeVideoDuration = async (link) => {
  const videoId = getVideoIdFromYoutubeLink(link);

  const response = await fetch(
    `${YOUTUBE_API_URL}/videos?id=${videoId}&part=contentDetails&key=${YOUTUBE_API_KEY}`,
  );
  const data = await response.json();
  let videoDuration = data?.items[0]?.contentDetails?.duration;
  videoDuration = videoDuration
    .replace('PT', '')
    .replace('H', ':')
    .replace('M', ':')
    .replace('S', '');
  const videoDurationArr = videoDuration.split(':');
  let videoDurationSeconds = 0;
  if (videoDurationArr.length === 3) {
    videoDurationSeconds =
      parseInt(videoDurationArr[0], 10) * 3600 +
      parseInt(videoDurationArr[1], 10) * 60 +
      parseInt(videoDurationArr[2], 10);
  } else if (videoDurationArr.length === 2) {
    videoDurationSeconds =
      parseInt(videoDurationArr[0], 10) * 60 +
      parseInt(videoDurationArr[1], 10);
  } else {
    videoDurationSeconds = parseInt(videoDurationArr[0], 10);
  }

  return videoDurationSeconds * 1000;
};

const getYoutubeVideoInfo = async (link) => {
  const videoId = getVideoIdFromYoutubeLink(link);

  const response = await fetch(
    `${YOUTUBE_API_URL}/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`,
  );

  const data = await response.json();

  const isVideoNotFound = data.items.length === 0;
  return { isVideoNotFound, data: data.items[0]?.snippet };
};

const getYoutubeCaptions = async (link) => {
  const videoId = getVideoIdFromYoutubeLink(link);

  const captionsResponse = await fetch(
    `${YOUTUBE_API_URL}/captions?part=snippet&videoId=${videoId}&key=${YOUTUBE_API_KEY}`,
  );
  const captionsData = await captionsResponse.json();
  const hasSubtitle = captionsData.items.length > 0;

  return { hasSubtitle, captionsData };
};

const getImageThumbnailFromYoutubeLink = (link) => {
  const parts = link.split('/');
  const videoId = parts[parts.length - 1];
  const thumbnailUrl = `${YOUTUBE_THUMBNAIL_URL}/vi/${videoId}/0.jpg`;

  return thumbnailUrl;
};

export {
  getYoutubeVideoDuration,
  getYoutubeVideoInfo,
  getYoutubeCaptions,
  getImageThumbnailFromYoutubeLink,
};
