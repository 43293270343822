import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  & .MuiOutlinedInput-input {
    text-align: center;
    color: ${COLOR.dark};
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-left: 1px solid ${COLOR.light};
    border-right: 1px solid ${COLOR.light};
  }

  .icon-input {
    cursor: pointer;
    font-size: 16px;
    color: ${COLOR.darkBlue};
  }
`;

export { StyledTextField };
