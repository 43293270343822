import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import { SpeedRounded } from '@mui/icons-material';
import { SPEED } from '@src/constants/voice';
import actions from '@src/redux/actions';
import { checkValidNumber } from '@src/services/checkValid';
import { VALID_SPEED } from '@src/constants/tts';
import { StyledSpeed, StyledMenuItem, StyledPopper } from './index.style';

const SpeedNew = ({
  currentSpeed = 1,
  isSentence,
  width,
  disabled,
  onChange,
  onClick,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const getTextValue = (value) => {
    if (value <= 0) return t('voiceSpeed');
    return `${value}x`;
  };

  const [speed, setSpeed] = useState(currentSpeed);
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState(getTextValue(currentSpeed));
  const [isFocus, setIsFocus] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const handleCloseSpeed = (value) => {
    setSpeed(value);
    setTextValue(getTextValue(value));
    setOpen(false);
    inputRef.current?.blur();
  };

  const handleSelectedValue = (value) => {
    if (onChange) onChange('speed', value);
    handleCloseSpeed(value);
  };

  const checkMoreThanTwoDecimalDigits = (number) =>
    Number.isInteger(number * 100);

  const checkInValidSpeed = (value) =>
    value < VALID_SPEED.min || value > VALID_SPEED.max;

  const getValidSpeed = (value) => {
    const speedNumber = Number(value);
    if (checkInValidSpeed(value)) return currentSpeed;
    if (!checkMoreThanTwoDecimalDigits(speedNumber))
      return parseFloat(parseFloat(value).toFixed(2));
    return speedNumber;
  };

  const handleKeyDown = (e) => {
    const { value } = e.target;

    if (e.key === 'Enter') {
      const isNumber = checkValidNumber(value);
      if (!isNumber) {
        dispatch(
          actions.noti.push({
            severity: 'warning',
            message: 'speedIsNotNumber',
          }),
        );
        handleCloseSpeed(currentSpeed);
        return;
      }
      if (checkInValidSpeed(value)) {
        dispatch(
          actions.noti.push({
            severity: 'warning',
            message: 'speedInvalidRange',
            value: t('range', {
              min: `${VALID_SPEED.min}x`,
              max: `${VALID_SPEED.max}x`,
            }),
          }),
        );
        handleCloseSpeed(currentSpeed);
        return;
      }

      const validSpeed = getValidSpeed(value);
      handleSelectedValue(validSpeed);
    }
  };

  const handleFocusInput = () => {
    setIsFocus(true);
    setTextValue(speed);
  };

  const handleBlurInput = () => {
    setIsFocus(false);
    handleCloseSpeed(currentSpeed);
  };

  const handleChangeInput = (event) => {
    setSpeed(event.target.value);
  };

  const handleIsOptionEqualToValue = (option, value) => {
    if (value && typeof value === 'string') {
      const lastWord = value.split(' ').pop();
      const speedValue = lastWord.slice(0, -1);
      return option?.value === getValidSpeed(speedValue);
    }
    return option?.value === value;
  };

  useEffect(() => {
    setTextValue(getTextValue(speed));
  }, [language]);

  useEffect(() => {
    setSpeed(currentSpeed);
    setTextValue(getTextValue(currentSpeed));
  }, [currentSpeed]);

  return (
    <StyledSpeed
      isSentence={isSentence}
      isFocus={isFocus}
      active={open}
      disabled={disabled}
      width={width}
    >
      <SpeedRounded
        color={disabled ? 'disabled' : 'iconPrimary'}
        sx={{ fontSize: 20, opacity: 0.8 }}
      />
      <Autocomplete
        PopperComponent={StyledPopper}
        value={textValue}
        isOptionEqualToValue={handleIsOptionEqualToValue}
        open={open}
        disabled={disabled}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={SPEED}
        filterOptions={(option) => option}
        disableClearable
        renderInput={(params) => (
          <Tooltip
            arrow
            title={t('readSpeedAdjustment')}
            placement="top"
            open={openTooltip}
            disableHoverListener
            onMouseEnter={() => setOpenTooltip(!open)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <TextField
              {...params}
              value={speed}
              inputRef={inputRef}
              className="text-field"
              onFocus={handleFocusInput}
              onClick={onClick}
              onBlur={handleBlurInput}
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
              placeholder={t('speed')}
              color="iconPrimary"
            />
          </Tooltip>
        )}
        getOptionLabel={(option) => option?.value || option}
        renderOption={(props, option) => (
          <StyledMenuItem
            key={option.title}
            active={String(speed) === String(option.value)}
            onClick={() => handleSelectedValue(option.value)}
            isSentence={isSentence}
          >
            <Typography variant="body2" className="text">
              {option.value}x
            </Typography>
            <Typography variant="body2" className="text">
              {t(option.title)}
            </Typography>
          </StyledMenuItem>
        )}
      />
    </StyledSpeed>
  );
};
export default SpeedNew;
