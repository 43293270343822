import React from 'react';
import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import AffiliateContainer from '@src/containers/Affiliate';

const Affiliate = () => (
  <Feature name={FEATURE_FLAG.AFFILIATE}>
    <AffiliateContainer />
  </Feature>
);

export default Affiliate;
