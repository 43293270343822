import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDownloadOutlined, FlagRounded } from '@mui/icons-material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { downloadFileUsingUrl } from '@src/utils/file';
import dataSenses from '@src/services/dataSenses';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import { PROJECT_STATUS } from '@src/constants/dubbing';
import { REQUEST_STATUS } from '@src/constants/voice';
import { DOWNLOAD_QUOTA_EXCEEDED_CODE } from '@src/constants/tts';
import { REQUEST_TYPE } from '@src/constants/request';
import {
  StyledActionTooltip,
  StyledIconButton,
  StyledProjectActions,
} from './index.style';

const ProjectActions = ({ project, onOpenErrorReport }) => {
  const { t } = useTranslation();
  const { getFeatureValue } = useFeatureFlags();
  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const dispatch = useDispatch();
  const [audioExpired, setAudioExpired] = useState(false);
  const [retentionPeriod, setRetentionPeriod] = useState(false);
  const [disableDownload, setDisableDownload] = useState(true);

  const { title, latestRequestId, status } = project;

  const getTitleActionTooltip = (action) => {
    if (status !== REQUEST_STATUS.SUCCESS) return t('');
    if (audioExpired && action === 'download')
      return t('notiAudioExpired', { retentionPeriod });

    return t(action);
  };

  const handleDownloadAudio = async (e) => {
    e.stopPropagation();
    dataSenses.sendDownloadAudioEvent({
      userId: project.userId,
      requestType: REQUEST_TYPE.DUBBING,
    });

    const res = isLimitDownloadFreePackage
      ? await apis.requests.getAudioDownloadUrl(latestRequestId)
      : await apis.requests.getPresignedAudioUrl(latestRequestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      return;
    }

    if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const fetchLatestRequestData = async () => {
    const res = await apis.requests.getRequest(latestRequestId);
    if (res?.status) {
      return res.result;
    }
    return null;
  };

  const handleOpenErrorReport = async (e) => {
    e.stopPropagation();
    const latestRequestData = await fetchLatestRequestData();
    const { type, seconds, voice, sentencesVoiceCode } = latestRequestData;
    onOpenErrorReport({
      id: latestRequestId,
      title,
      voice,
      type,
      seconds,
      sentencesVoiceCode,
    });
  };

  const checkDisableDownload = async () => {
    if (status !== REQUEST_STATUS.SUCCESS) return true;

    if (!latestRequestId) return true;

    const latestRequestData = await fetchLatestRequestData();
    if (!latestRequestData) return true;

    const {
      audioLink: latestAudioLink,
      retentionPeriod: latestRetentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
      createdAt,
    } = latestRequestData;
    setRetentionPeriod(latestRetentionPeriod);

    const isAudioExpired = moment().isAfter(
      moment(createdAt).add(latestRetentionPeriod, 'days'),
    );

    setAudioExpired(isAudioExpired);

    return !latestAudioLink || isAudioExpired;
  };

  useEffect(async () => {
    if (latestRequestId) {
      const isDisableDownload = await checkDisableDownload();
      setDisableDownload(isDisableDownload);
    }
  }, []);

  return (
    <StyledProjectActions>
      <StyledActionTooltip
        title={getTitleActionTooltip('download')}
        arrow
        placement="top"
        disabled={!latestRequestId || status !== REQUEST_STATUS.SUCCESS}
      >
        <StyledIconButton
          onClick={handleDownloadAudio}
          disabled={disableDownload}
          height="30px"
          width="30px"
          padding="12px"
        >
          <FileDownloadOutlined />
        </StyledIconButton>
      </StyledActionTooltip>
      <StyledActionTooltip
        title={getTitleActionTooltip('errorReport')}
        arrow
        placement="top"
      >
        <StyledIconButton
          onClick={handleOpenErrorReport}
          height="30px"
          width="30px"
          padding="12px"
          disabled={!latestRequestId || status === PROJECT_STATUS.DRAFT}
        >
          <FlagRounded className="icon" />
        </StyledIconButton>
      </StyledActionTooltip>
    </StyledProjectActions>
  );
};

export default ProjectActions;
