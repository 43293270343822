import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledListProject = styled('div')`
  padding: 12px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .title-request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .select-request {
    margin-left: 5px;
    display: flex;
    gap: 10px;
    align-items: center;

    .delete-requests {
      color: ${COLOR.danger};
      font-weight: 600;
      cursor: pointer;
    }
  }

  .button-adjust {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid ${COLOR.light};
    padding: 4px 8px;
    border-radius: ${BORDER_RADIUS};
    cursor: pointer;
    position: relative;
  }

  .select-request-popper {
    position: fixed;
    z-index: 1;
    bottom: 10%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: ${COLOR.white};
    box-shadow: 0px 4px 15px rgba(44, 63, 88, 0.35);
    border-radius: ${BORDER_RADIUS};
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 480px;

    .button-group {
      display: flex;
      gap: 8px;
    }
  }

  .disable-color {
    color: ${COLOR.disableButton} !important;
  }

  .transition-btn {
    text-transform: unset;
    background-color: ${COLOR.dark};
    font-weight: 600;
    &:hover {
      background-color: ${COLOR.light};
    }
    img {
      margin-left: 12px;
    }
  }

  .project-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    height: 90%;

    .project-info {
      font-size: 16px;
      font-weight: 400;
      color: ${COLOR.label2[100]};
    }

    .project-button {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: ${COLOR.indigo.default};
      text-transform: none;
      width: fit-content;
      border-radius: 100px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .audio-storage-note {
      flex: 1;
    }
  }
`;

export const StyledProjectTable = styled('div')`
  height: 90%;
`;

export const StyledListProjectHeader = styled('div')`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .list-project-title {
      font-weight: 700;
      font-size: 16px;
      color: ${COLOR.indigo.default};
    }

    .button-create-project {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 8px;
      background-color: ${COLOR.functionYellow.default};
      min-width: 0px;
      width: 40px;
      height: 40px;
    }
  }

  .request-list-title {
    font-weight: 700;
    font-size: 16px;
    color: ${COLOR.indigo.default};
    min-width: 195px;
  }

  .request-filter {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .filter-item {
    display: flex;
    gap: 10px;
  }

  .filter-title {
    min-width: 240px;
  }

  .filter-status {
    min-width: 160px;
  }

  .fields-filter {
    width: 40px;

    .filter-item {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  .date-field {
    .MuiOutlinedInput-root {
      border-radius: 100px;
    }
  }

  .text-field-request {
    background-color: ${COLOR.white};
    width: 133px;

    .MuiOutlinedInput-root {
      border-radius: 100px;
    }

    .MuiInputLabel-root {
      font-size: 14px;
    }
  }

  .disable-color {
    color: ${COLOR.disableButton} !important;
  }

  .refresh-icon {
    margin-top: auto;
    margin-bottom: auto;
    width: 40px;
    padding: 18px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid ${COLOR.light};
    color: ${COLOR.darkBlue};
    &:hover {
      background: ${TRANSPARENT_COLOR.dark};
      opacity: 1;
      .icon {
        color: ${({ colorHover }) => COLOR[colorHover]};
      }
    }
  }

  .create-project-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 8px;
    background-color: ${COLOR.functionYellow.default};
    min-width: 0px;
    width: 38px;
    height: 38px;
  }

  @media only screen and (min-width: 1200px) {
    display: flex;
    align-items: center;

    .request-filter {
      display: flex;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    display: flex;
    align-items: center;

    .request-filter {
      display: flex;
    }

    .filter-title {
      min-width: 0px;
    }

    .filter-status {
      min-width: 0px;
    }
  }

  @media only screen and (max-width: 800px) {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .request-list-title {
      min-width: 0px;
    }

    .filter-title {
      min-width: 0px;
    }

    .filter-status {
      min-width: 0px;
    }

    .request-filter {
      display: flex;
      flex-direction: column;
    }

    .text-field-request {
      width: 211px;
    }
  }
`;
