import api from './api';

export const registerAutoRenew = async ({ paymentMethodId }) => {
  try {
    const response = await api({
      method: 'POST',
      url: '/auto-renew/user',
      data: { paymentMethodId },
    });
    return response;
  } catch (err) {
    return err.response?.data;
  }
};

export const getSubscriptionInfo = async (purchaseId) => {
  try {
    const response = await api({
      method: 'GET',
      url: '/auto-renew/user',
      params: { purchaseId },
    });
    return response;
  } catch (err) {
    return err.response?.data;
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await api({
      method: 'POST',
      url: '/auto-renew/cancel',
    });
    return response;
  } catch (err) {
    return err.response?.data;
  }
};
