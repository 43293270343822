import React from 'react';
import { styled } from '@mui/material/styles';
import { Radio } from '@mui/material';
import { COLOR } from '@src/styles/color';

const Icon = styled('span')(() => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: `inset 0 0 0 1.5px ${COLOR.darkV2[80]}, inset 0 0px 0 ${COLOR.darkV2[80]}`,
  backgroundColor: COLOR.white,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto COLOR.indigo.default',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: `inset 0 0 0 1.5px ${COLOR.darkV2[32]}, inset 0 0px 0 ${COLOR.darkV2[32]}`,
  },
}));

const CheckedIcon = styled(Icon)({
  backgroundColor: COLOR.indigo.default,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: `radial-gradient(${COLOR.yellow.default},${COLOR.yellow.default} 28%,transparent 32%)`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: COLOR.indigo.default,
  },
});

const CustomRadio = (props) => (
  <Radio
    disableRipple
    color="default"
    checkedIcon={<CheckedIcon />}
    icon={<Icon />}
    {...props}
  />
);

export default CustomRadio;
