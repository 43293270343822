import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { checkVietNam } from '@src/utils/checkCountry';
import { useSelector } from 'react-redux';
import { getPackageCurrentPrice, getPackageName } from '@src/services/package';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { StyledOrder } from './index.style';

const Order = ({ isAutoRenewalPackage }) => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { package: packageState } = useSelector((state) => state.order);

  const isVietNam = checkVietNam();

  const { price, usdPrice } = getPackageCurrentPrice(packageState, true);

  return (
    <StyledOrder>
      <Typography className="info-title">{t('order')}</Typography>
      <Box className="package-info-card">
        <Typography className="package-info-title">{t('pack')}</Typography>
        <Box className="package-info-detail">
          <Box display="flex" gap="8px">
            <Typography>
              {getPackageName(packageState?.name, packageState?.code, language)}
            </Typography>
            {isAutoRenewalPackage && (
              <Box className="auto-renewal">{t('autoRenewal')}</Box>
            )}
          </Box>
          {isDubbingRoute ? (
            <Typography>
              (
              {Math.floor(packageState?.maxSeconds / 60)?.toLocaleString(
                language,
              )}{' '}
              {t('minute')})
            </Typography>
          ) : (
            <Typography>
              ({packageState?.maxCharacters?.toLocaleString(language)}{' '}
              {t('character')})
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="package-info-card">
        <Typography className="package-info-card-title">
          {t('packagePrice')}
        </Typography>
        <Typography>
          {!isVietNam && (
            <span className="discount-unit-price font-size-16">$</span>
          )}
          {isVietNam && (
            <span className="discount-unit-price underline font-size-16">
              đ
            </span>
          )}
          {isVietNam
            ? price?.toLocaleString(language) ||
              packageState.price?.toLocaleString(language)
            : usdPrice?.toLocaleString(language) ||
              packageState.usdPrice?.toLocaleString(language)}
        </Typography>
      </Box>
    </StyledOrder>
  );
};

export default Order;
