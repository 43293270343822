import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledStep = styled('div')``;

const StyledSkip = styled('div')`
  .button-back {
    color: ${COLOR.backTour};
    border: 1px solid ${COLOR.light};
    border-radius: 6px;
    cursor: pointer;
    padding: 8px 12px;
  }
`;

const StyledNext = styled('div')`
  .button-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledBack = styled('div')`
  .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTooltipBody = styled('div')`
  background-color: ${COLOR.white};
  border-radius: 6px;
  width: 352px;
  min-height: 157px;
`;

const StyledTooltipTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR.primary};
  border-radius: 6px 6px 0px 0px;
  color: ${COLOR.darkBlue};
  align-text: left;
  padding: 9px 0px 9px 12px;
  font-size: 16px;

  .button-close {
    color: ${COLOR.white};
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
`;

const StyledTooltipContent = styled('div')`
  align-text: left;
  padding: 0px 12px;
  color: ${COLOR.contentTour};
  .text-content {
    font-size: 14px;
  }
  .grid-status {
    margin: 6px 0px;
  }
`;

const StepButton = styled('button')`
  border: none;
  background-color: ${COLOR.white};
  color: ${COLOR.backTour};
  padding: 0px;
  cursor: pointer;
`;

const NextButton = styled('button')`
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${COLOR.primary};
  color: ${COLOR.darkBlue};
  padding: 8px 12px;
`;

const BackButton = styled('button')`
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid ${COLOR.light};
  background-color: ${COLOR.white};
`;

const SkipButton = styled('button')`
  color: ${COLOR.backTour};
  border: none;
  background-color: ${COLOR.white};
  margin-right: 12px;
  cursor: pointer;
`;

const CloseButton = styled('button')`
  color: ${COLOR.white};
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const StyledTooltipFooter = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 12px;
  margin-top: 15px;

  .buttons-right {
    display: flex;
    algin-items: center;
    gap: 8px;
  }
`;

export {
  StyledStep,
  StyledSkip,
  StyledNext,
  StyledBack,
  StyledTooltipBody,
  StyledTooltipTitle,
  StyledTooltipContent,
  StepButton,
  NextButton,
  SkipButton,
  BackButton,
  StyledTooltipFooter,
  CloseButton,
};
