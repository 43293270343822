import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

const BlackWordHighLight = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Tooltip arrow title={t('blackWord')} placement="top">
      <span className="black-word-highlight">{children}</span>
    </Tooltip>
  );
};

export default BlackWordHighLight;
