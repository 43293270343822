import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 580px;
    border-radius: 16px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 32px 32px 32px;
    gap: 16px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }

  .title-content {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: ${COLOR.indigo[100]};
  }

  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.black.default};
    padding: 8px 0px;
  }

  .action-wrapper {
    display: flex;
    justify-content: center;
    gap: 24px;

    .action-button {
      width: 100%;
      text-transform: none;
      height: 44px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: -0.4px;
      border-radius: 500px;
    }

    .confirm-button {
      border: 1px solid ${COLOR.secondary[32]};
      color: ${COLOR.indigo[100]};
    }

    .reject-button {
      border: 1px solid transparent;
      color: ${COLOR.indigo[100]};
    }
  }
`;
