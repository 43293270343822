import * as React from 'react';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  BORDER_RADIUS,
  TOOLTIP_BOX_SHADOW,
  MOBILE_BREAKPOINT,
} from '@src/styles/config';

export const StyledTTS = styled('div')`
  .scroll-point-top {
    transform: translate(0px, -100px);
  }

  .action-header {
    display: flex;
    align-items: center;
  }

  /* .scroll-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: ${({ openRequest }) => !openRequest && '20px'};
    margin-bottom: 10px;
  } */

  .download-audio-note {
    color: ${COLOR.error};
  }

  .opacity-none {
    opacity: 0;
  }

  .display-none {
    display: none;
  }

  .tts {
    transition: opacity 1s ease-out;
    /* visibility: ${(props) => (props.openRequest ? 'hidden' : 'visible')};
    height: ${(props) => (props.openRequest ? '0' : 'auto')};
    opacity: ${(props) => (props.openRequest ? '0' : '1')}; */
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }

  /* .transition-btn {
    text-transform: unset;
    background-color: ${COLOR.dark};
    font-weight: 600;
    &:hover {
      background-color: ${COLOR.light};
    }
    img {
      margin-left: 12px;
    }
  } */

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: block;
    }

    .mobile {
      display: none;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .web {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }
`;

export const StyledImportToolbar = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;

  .upload-button {
    text-transform: initial;
    padding: 4px 6px;
    border-radius: 8px;
    width: 124px;
  }

  .import-toolbar-more-button {
    padding: 4px 12px;
    border-radius: 12px;
    text-transform: none;
    height: 32px;
    width: 148px;
    font-weight: 400;
  }

  .margin-right {
    margin-right: 0px;
  }

  .margin-right-8 {
    margin-right: 8px;
  }

  .upload-button-disabled {
    min-width: 180px;
    text-transform: initial;
    cursor: default;
    color: ${COLOR.light};
    background: 'inherit';
    border: 1px solid ${COLOR.light};

    &:hover {
      background: inherit;
      border: 1px solid ${COLOR.light};
    }

    &:focus {
      background: inherit;
    }
  }

  .web-toolbar {
    .custom-button {
      font-weight: 400;
    }
  }

  @media only screen and (min-width: 1600px) {
    .web-toolbar {
      display: block;
    }

    .mobile-toolbar {
      display: none;
    }

    .paste {
      margin-left: 6px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .web-toolbar {
      display: none;
    }

    .mobile-toolbar {
      display: flex;
    }
  }
`;

export const StyledProcessingMethods = styled('div')`
  display: flex;
  align-items: center;

  .switch-wrapper {
    margin-left: 15px;
  }

  .icon {
    cursor: pointer;
  }

  .sentence-process {
    color: ${COLOR.darkBlue};
    font-weight: 510;
  }
`;

export const StyledHandleByUrl = styled('div')`
  .actions {
    padding: 20px 24px;
  }
`;

export const StyledMapEditor = {
  SPEED: {
    backgroundColor: COLOR.orange,
    color: COLOR.white,
    padding: '0 1px',
  },
  EMPHASIS: {
    backgroundColor: COLOR.lightGreen,
    color: COLOR.white,
    padding: '0 1px',
  },
  PITCH: {
    backgroundColor: COLOR.lightBlue,
    color: COLOR.white,
    padding: '0 1px',
  },
  BREAK_TIME: {
    backgroundColor: COLOR.purple,
    color: COLOR.white,
    padding: '0 1px',
  },
};

export const StyledHightLightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: BORDER_RADIUS,
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 10px',
    boxShadow: TOOLTIP_BOX_SHADOW,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.white,
  },
}));

export const StyledTooltipTitle = styled('div')`
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    font-size: 14px;
  }

  .icon-button {
    padding: 0 0 0 8px;
  }

  .icon {
    font-size: 14px;
  }
`;

export const StyledHandleByNewUrl = styled('div')`
  .handle-url-content {
    padding: 24px;
    border-radius: 12px;
    border: 2px dashed #3c3c4329;
    background: ${TRANSPARENT_COLOR.bgrGray};
  }
  .wrap-icon {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 16px;
  }
  .file-icon {
    width: 32px;
    height: 32px;
  }
  .actions {
    padding: 20px 24px;
  }
`;

export const StyleBoxAddSuccess = styled('div')`
  .wrap-file {
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #3c3c4329;
    border-radius: 12px;
    padding: 16px;
  }
  .success-file-name {
    flex: 1;
  }
  .img-thumbnail {
    width: 96px;
    height: 48px;
  }
  .file-icon {
    width: 40px;
    height: 40px;
  }
  .clear-icon {
    width: 24px;
    height: 24px;
  }
  .btn-continue {
    margin-left: auto;
  }
`;

export const StyleButtonSuccess = styled('div')`
  display: flex;
  justify-content: right;
  .btn-continue {
    text-transform: none;
    font-weight: 700;
  }
  margin: 20px 0 8px 0;
`;
