import React from 'react';
import classNames from 'classnames';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledPackageInfoItem, StyledTooltip } from './index.style';

const PackageInfoItem = ({
  name,
  icon,
  dangerIcon,
  iconAfter,
  value,
  isDangerText,
  tooltipTitle,
}) => {
  const { t } = useTranslation();

  return (
    <StyledPackageInfoItem>
      <div className="package-info-item normal-package-info-item">
        <Typography
          className={classNames('text', {
            'error-text': isDangerText,
          })}
        >
          {t(name)}:
        </Typography>
        <Typography
          className={classNames('text bold', {
            'error-text': isDangerText,
          })}
        >
          {value}
        </Typography>
        {iconAfter && (
          <StyledTooltip title={tooltipTitle} arrow>
            {iconAfter}
          </StyledTooltip>
        )}
      </div>
      <StyledTooltip title={t(name)} arrow>
        <div className="package-info-item small-package-info-item">
          <img
            iconDanger
            src={isDangerText && dangerIcon ? dangerIcon : icon}
            alt={`icon-${name}`}
            className={classNames('package-info-icon')}
          />
          <Typography
            className={classNames('text bold', {
              'error-text': isDangerText,
            })}
          >
            {value}
          </Typography>
          {iconAfter && (
            <StyledTooltip title={tooltipTitle}>{iconAfter}</StyledTooltip>
          )}
        </div>
      </StyledTooltip>
    </StyledPackageInfoItem>
  );
};

export default PackageInfoItem;
