import { RESOURCE } from '@src/constants';
import api from './api';

const checkUserDevice = async (fingerprint) => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.DEVICES}/check`,
    data: { fingerprint },
  });
  return response;
};

const deactivateDevice = async (fingerprint) => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.DEVICES}/deactivate`,
    data: { fingerprint },
  });
  return response;
};

const getDevices = async ({
  search,
  searchFields,
  offset,
  limit,
  fields,
  sort,
  query,
}) => {
  try {
    const response = await api({
      method: 'GET',
      url: RESOURCE.DEVICES,
      params: { search, searchFields, offset, limit, fields, sort, ...query },
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

const sendEmailConfirmLogoutDevices = async (email, deviceIds) => {
  const response = await api({
    method: 'POST',
    url: `${RESOURCE.DEVICES}/confirm-logout`,
    data: { email, deviceIds },
  });
  return response;
};

export {
  checkUserDevice,
  deactivateDevice,
  getDevices,
  sendEmailConfirmLogoutDevices,
};
