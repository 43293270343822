import { actionTypes } from './actions';

export const initialState = {
  seenAllBanner: false,
  actionBanner: {},
  allowShowAutoRenewalBanner: false,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEEN_ALL_BANNER: {
      return { ...state, seenAllBanner: true };
    }

    case actionTypes.UPDATE_DISPLAY_BANNER: {
      const { bannerType, display, extraData } = action;
      return {
        ...state,
        actionBanner: {
          ...state.actionBanner,
          [bannerType]: { display, ...extraData },
        },
      };
    }

    case actionTypes.ALLOW_SHOW_AUTO_RENEWAL_BANNER: {
      const { allow } = action;
      return {
        ...state,
        allowShowAutoRenewalBanner: allow,
      };
    }

    default:
      return state;
  }
};

export default bannerReducer;
