import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import WarningIcon from '@src/assets/icons/warning-orange.svg';

import actions from '@src/redux/actions';
import InvoiceDialog from './CreateInvoice';
import { StyledInvoice } from './index.style';

const Invoice = () => {
  const [openInvoiceCollapse, setOpenInvoiceCollapse] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { invoice, hasInvoice, invoiceError } = useSelector(
    (state) => state.order,
  );
  const { latestOrder } = useSelector((state) => state.user);

  useEffect(() => {
    // Scroll to bottom when open invoice collapse
    if (openInvoiceCollapse) {
      const elementMainPanel = document.querySelector('.main-panel');
      if (elementMainPanel) {
        elementMainPanel.scrollTo({
          top: elementMainPanel.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [openInvoiceCollapse]);

  const handleChangeCheckedInvoice = () => {
    const value = !hasInvoice;
    dispatch(actions.order.updateHasInvoice(value));
    const invoiceEmpty = Object.values(invoice).some((val) => !val);
    if (value && invoiceEmpty) setOpenInvoiceCollapse(true);
    if (!value) dispatch(actions.order.updateErrorInvoice({}));
  };

  const handleChangeInvoice = (event) => {
    const { name, value } = event.target;
    dispatch(
      actions.order.updateErrorInvoice({ ...invoiceError, [name]: null }),
    );
    dispatch(actions.order.updateInvoice({ ...invoice, [name]: value }));
  };

  useEffect(() => {
    if (latestOrder?.invoice) {
      const { companyName, companyAddress, companyTax, companyEmail } =
        latestOrder.invoice;
      dispatch(actions.order.updateHasInvoice(true));
      dispatch(
        actions.order.updateInvoice({
          companyName,
          companyAddress,
          companyTax,
          companyEmail,
        }),
      );
    }
  }, [latestOrder]);

  return (
    <StyledInvoice openCollapse={openInvoiceCollapse}>
      <Box className="invoice-wrapper">
        <Box className="invoice-check-box">
          <Checkbox
            checked={hasInvoice}
            onChange={handleChangeCheckedInvoice}
            inputProps={{ 'aria-label': 'controlled' }}
            size="medium"
            className="checkbox"
          />
          <Typography
            className="invoice-info"
            onClick={handleChangeCheckedInvoice}
          >
            {t('exportInvoice')}
          </Typography>
        </Box>

        <Box onClick={() => setOpenInvoiceCollapse(!openInvoiceCollapse)}>
          {openInvoiceCollapse ? (
            <ExpandLess className="expand-icon" />
          ) : (
            <ExpandMore className="expand-icon" />
          )}
        </Box>
      </Box>

      <Box className="invoice-collapse-wrapper">
        <div className="note-item">
          <img className="icon" src={WarningIcon} alt="warning" />
          <div className="note-content">
            <Typography className="note-description">
              {t('exportInvoiceNote')}
            </Typography>
          </div>
        </div>
        <InvoiceDialog
          invoice={invoice}
          error={invoiceError}
          onChangeInvoice={handleChangeInvoice}
        />
      </Box>
    </StyledInvoice>
  );
};

export default Invoice;
