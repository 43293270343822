import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';
import { EditorState, ContentState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import {
  LinkRounded,
  PublishRounded,
  CloudUploadRounded,
} from '@mui/icons-material';
import {
  checkFeaturePermission,
  removeEmptyLineOfText,
} from '@src/services/tts';
import { splitParagraphIntoSentence } from '@src/services/sentence';
import { FEATURE } from '@src/constants/package';
import { handleEditParagraphs } from '@src/services/paragraph';
import actions from '@src/redux/actions';

import Decorator from '@src/components/Decorator';
import { BREAK_TIME_REGEX, REGEX } from '@src/constants/voice';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { INPUT_TTS_TYPE } from '@src/constants/tts';
// import PopoverUpload from '@src/components/PopoverUpload';
import PopoverUploadNew from '@src/components/PopoverUploadNew';
import { OPTIONS_UPLOAD, TYPE_UPLOAD } from '@src/constants/upload';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import IconFilePdf from '@src/assets/icons/icon-file-pdf.png';
import UploadFile from './UploadFile';
import { StyledImportToolbar } from './index.style';
import ImportURL from './ImportURL';
import NewImportURL from './NewImportURL';

const ImportToolbarNew = ({
  useNewTtsUI,
  openSentences,
  onChangeContent,
  onChangeInputLength,
  onChangeCharacterExceed,
  handleCloseSearchBoxAndMoreMenu,
  handleCloseSearchBox,
  isMoreButton,
}) => {
  const [openUpload, setOpenUpload] = useState(false);
  const [openImportUrl, setOpenImportUrl] = useState(false);
  let optionUpload = OPTIONS_UPLOAD;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = user;

  const useNewUpload = getFeatureValue(FEATURE_KEYS.NEW_UPLOAD, {
    userId,
    email,
    phoneNumber,
  });

  const useUploadPdf = getFeatureValue(FEATURE_KEYS.UPLOAD_FILE_PDF, {
    userId,
    email,
  });

  if (useUploadPdf) {
    optionUpload = [
      ...optionUpload,
      {
        icon: IconFilePdf,
        label: 'pdfFile',
        type: TYPE_UPLOAD.FILE,
        isActive: true,
        formatFile: '.pdf',
      },
    ];
  }

  const { voice } = useSelector((state) => state.synthesisRequest);

  const { ttsUser } = useSelector((state) => state.user);
  const { advanceFeature } = useSelector((state) => state.synthesisRequest);

  const maxLengthInputText = ttsUser?.maxLengthInputText || 0;

  const hasTtsByFile =
    ttsUser.id && checkFeaturePermission(ttsUser.features, FEATURE.TTS_BY_FILE);

  const hasTtsByUrl =
    ttsUser.id && checkFeaturePermission(ttsUser.features, FEATURE.TTS_BY_URL);

  const handleOnClick = () => {
    if (isMoreButton) handleCloseSearchBox();
    else handleCloseSearchBoxAndMoreMenu();
  };

  const handleOpenUpload = () => {
    if (!hasTtsByFile) {
      dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
      return;
    }
    setOpenUpload(true);
  };
  const handleCloseUpload = () => setOpenUpload(false);

  const handleOpenImportUrl = () => {
    if (!hasTtsByUrl) {
      dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
      return;
    }
    setOpenImportUrl(true);
  };
  const handleCloseImportUrl = () => setOpenImportUrl(false);

  const handleUploadContent = (text) => {
    const MAX_LENGTH_TEXT = 500000;
    const handledText = removeEmptyLineOfText(text);

    let textFormat = handledText;
    if (handledText.length > MAX_LENGTH_TEXT) {
      textFormat = handledText.slice(0, MAX_LENGTH_TEXT);
    }

    if (openSentences) {
      const regex = advanceFeature ? REGEX.ADVANCE_TAG : BREAK_TIME_REGEX;
      const newSentences = splitParagraphIntoSentence(textFormat, voice, regex);
      dispatch(actions.synthesisRequest.updateSentences(newSentences));
    } else {
      const contentEditor = EditorState.createWithContent(
        ContentState.createFromText(textFormat),
        Decorator,
      );
      const newEditorState = EditorState.moveFocusToEnd(contentEditor);
      onChangeContent(newEditorState);
      if (advanceFeature) {
        const newParagraphs = handleEditParagraphs([], newEditorState);
        dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
      }
    }
    const textLength = textFormat.length;
    onChangeInputLength(textLength);

    if (textLength > maxLengthInputText) onChangeCharacterExceed(true);
    if (useNewTtsUI) dispatch(actions.synthesisRequest.cloneSynthesisRequest());

    // Scroll to top of editor when upload file or import url
    const elementEditor = document.getElementById('editor-wrapper');
    if (elementEditor) {
      elementEditor.scrollTop = 0;
    }
  };

  const handleSelectOptionUpload = (name, item) =>
    item.type === TYPE_UPLOAD.LINK ? handleOpenImportUrl() : handleOpenUpload();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const inputType = queryParams.get('inputType');
    if (inputType === INPUT_TTS_TYPE.FILE) setOpenUpload(true);
    else if (inputType === INPUT_TTS_TYPE.LINK) setOpenImportUrl(true);
  }, [location]);

  if (useNewUpload) {
    return (
      <StyledImportToolbar id={TTS_GUIDE.UPLOAD_FILE_OR_IMPORT_URL}>
        <div id={TTS_GUIDE.UPLOAD_TEXT_FILE} className="upload">
          <div className="margin-right">
            <PopoverUploadNew
              name="bitrate"
              tooltipTitle="tooltipUpload"
              icon={
                <CloudUploadRounded
                  sx={{
                    fontSize: 20,
                    opacity: 0.8,
                  }}
                />
              }
              onClick={handleOnClick}
              options={optionUpload.filter((item) => item.isActive)}
              onSelect={handleSelectOptionUpload}
              title={t('uploadFile')}
            />
          </div>
        </div>
        <UploadFile
          open={openUpload}
          onClose={handleCloseUpload}
          onHandleUploadContent={handleUploadContent}
        />
        <NewImportURL
          open={openImportUrl}
          onClose={handleCloseImportUrl}
          onHandleUploadContent={handleUploadContent}
        />
      </StyledImportToolbar>
    );
  }

  return (
    <StyledImportToolbar>
      <div id={TTS_GUIDE.UPLOAD_TEXT_FILE} className="upload">
        {isMoreButton ? (
          <Button
            className="import-toolbar-more-button margin-right-8"
            disabled={!hasTtsByFile}
            startIcon={<PublishRounded sx={{ fontSize: 20, opacity: 0.8 }} />}
            color="iconPrimary"
            onClick={handleOpenUpload}
            variant="outlined"
          >
            {t('importByFile')}
          </Button>
        ) : (
          <>
            <Tooltip arrow title={t('importByFile')} placement="top">
              <Button
                className="custom-button mobile-toolbar"
                color="iconPrimary"
                onClick={handleOpenUpload}
                disable={!hasTtsByFile}
                aria-label={t('importByFile')}
              >
                <PublishRounded sx={{ fontSize: 20, opacity: 0.8 }} />
              </Button>
            </Tooltip>
            <div className="web-toolbar">
              <Button
                className="custom-button"
                disabled={!hasTtsByFile}
                startIcon={
                  <PublishRounded sx={{ fontSize: 20, opacity: 0.8 }} />
                }
                color="iconPrimary"
                onClick={handleOpenUpload}
              >
                {t('importByFile')}
              </Button>
            </div>
          </>
        )}
      </div>
      <div id={TTS_GUIDE.HANDLE_BY_URL} className="paste">
        {isMoreButton ? (
          <Button
            className="import-toolbar-more-button"
            disabled={!hasTtsByFile}
            color="iconPrimary"
            startIcon={<LinkRounded sx={{ fontSize: 20, opacity: 0.8 }} />}
            onClick={handleOpenImportUrl}
            variant="outlined"
          >
            {t('importByUrl')}
          </Button>
        ) : (
          <>
            <Tooltip arrow title={t('importByUrl')} placement="top">
              <Button
                className="custom-button mobile-toolbar"
                color="iconPrimary"
                onClick={handleOpenImportUrl}
                disable={!hasTtsByFile}
                aria-label={t('importByUrl')}
              >
                <LinkRounded sx={{ fontSize: 20, opacity: 0.8 }} />
              </Button>
            </Tooltip>
            <div className="web-toolbar">
              <Button
                className="custom-button"
                disabled={!hasTtsByFile}
                color="iconPrimary"
                startIcon={<LinkRounded sx={{ fontSize: 20, opacity: 0.8 }} />}
                onClick={handleOpenImportUrl}
              >
                {t('importByUrl')}
              </Button>
            </div>
          </>
        )}
      </div>
      <UploadFile
        open={openUpload}
        onClose={handleCloseUpload}
        onHandleUploadContent={handleUploadContent}
      />
      <ImportURL
        open={openImportUrl}
        onClose={handleCloseImportUrl}
        onHandleUploadContent={handleUploadContent}
      />
    </StyledImportToolbar>
  );
};

export default ImportToolbarNew;
