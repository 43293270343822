import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { isValidFile } from '@src/utils/checkValid';
import { AddOutlined } from '@mui/icons-material';
import ImageBanner from '@src/assets/images/cloud-upload.svg';
import { StyledFileDropzone } from './index.style';
import PreviewImage from './PreviewImage';

const MEGA_BYTE = 15 * 1024 * 1024;

const MultiFileDropzone = ({
  fileUrl,
  clearFile,
  fileType,
  acceptFile,
  disabled,
  onAddFile,
  onClearFile,
  onDeleteFile,
  maxFiles,
}) => {
  const [file, setFile] = useState([]);
  const [preview, setPreview] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [oversize, setOverSize] = useState(false);
  const fileInputRef = useRef(null);
  const addFileInputRef = useRef(null);
  const { t } = useTranslation();

  const isValidSize = (size) => size <= MEGA_BYTE;

  const handleReset = () => {
    onDeleteFile(null);
    setFile([]);
    setPreview([]);
    setFormatError(false);
    setOverSize(false);
    setHighlight(false);
  };

  const handleChangeFile = (e) => {
    if (disabled) return;
    const { files } = e.target;
    if (maxFiles && file.length >= maxFiles) return;
    handleReset();
    if (!files || files.length !== 1) return;
    if (!isValidFile(files[0].name, fileType)) {
      setFormatError(true);
      return;
    }
    if (!isValidSize(files[0].size)) {
      setOverSize(true);
      return;
    }
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview([...preview, objectUrl]);
    setFile([...file, files[0]]);
    onAddFile([...file, files[0]]);
  };

  const handleDeleteFilePreview = (image) => {
    if (disabled) return;
    const newPreview = [];
    const newFile = [];

    for (let i = 0; i < preview.length; i += 1) {
      if (preview[i] !== image) {
        newPreview.push(preview[i]);
        newFile.push(file[i]);
      }
    }
    setPreview(newPreview);
    setFile(newFile);
    onAddFile(newFile);
    if (newPreview.length === 0) {
      setFile([]);
      // onDeleteFile(null);
      setPreview([]);
      setFile([]);
      onAddFile([]);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setHighlight(true);
  };

  const onDragLeave = () => setHighlight(false);

  const onDrop = (e) => {
    if (maxFiles && file.length >= maxFiles) return;
    e.preventDefault();
    handleReset();
    const { files } = e.dataTransfer;
    if (!files || files.length !== 1) return;
    if (!isValidFile(files[0].name, fileType)) {
      setFormatError(true);
      return;
    }
    if (!isValidSize(files[0].size)) {
      setOverSize(true);
      return;
    }
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview([...preview, objectUrl]);
    setFile([...file, files[0]]);
    onAddFile([...file, files[0]]);
  };

  const openFileDialog = () => fileInputRef.current?.click();
  const openAddFileDialog = () => addFileInputRef.current?.click();

  const UploadSuccess = () => (
    <div
      className="upload-success"
      onClick={(e) => e.stopPropagation()}
      role="presentation"
    >
      <div className="preview-image-upload">
        {preview.map((item) => (
          <PreviewImage
            key={item}
            image={item}
            onDelete={handleDeleteFilePreview}
          />
        ))}
        {maxFiles && file.length < maxFiles && (
          <div
            className="add-file"
            role="button"
            tabIndex="0"
            onClick={openAddFileDialog}
          >
            <AddOutlined color="primary" />
            <Typography color="primary" variant="body2">
              {t('extra')}
            </Typography>
            <input
              ref={addFileInputRef}
              className="file-input"
              type="file"
              disabled={disabled}
              accept={acceptFile}
              onChange={handleChangeFile}
            />
          </div>
        )}
      </div>
    </div>
  );

  const UploadError = () => (
    <>
      <Typography variant="body1" className="allow-format-file">
        {t('allowedFileFormats', { fileType: fileType.join(', ') })}
      </Typography>
      {formatError && (
        <Typography variant="subtitle2" color="error">
          {t('onlyUpload', { fileType: fileType.join(', ') })}
        </Typography>
      )}
      {oversize && (
        <Typography variant="subtitle2" color="error">
          {t('oversizeError')}
        </Typography>
      )}
    </>
  );

  const UploadNote = () => (
    <div className="upload-note">
      <img
        src={fileUrl || ImageBanner}
        className="preview-image"
        alt="banner"
      />
      <Typography variant="body2">{t('uploadFile')}</Typography>
    </div>
  );

  useEffect(() => {
    if (clearFile) {
      handleReset();
      onClearFile(false);
    }
  }, [clearFile]);

  return (
    <StyledFileDropzone>
      <div
        className={classNames('dropzone', { highlight })}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={openFileDialog}
        role="presentation"
      >
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          disabled={disabled}
          accept={acceptFile}
          onChange={handleChangeFile}
        />
        {(() => {
          if (file?.length === 0) {
            if (formatError || oversize) return <UploadError />;
            return <UploadNote />;
          }
          return <UploadSuccess />;
        })()}
      </div>
    </StyledFileDropzone>
  );
};

export default MultiFileDropzone;
