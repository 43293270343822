import { actionTypes } from './actions';

export const initialState = {
  audioLink: '',
  selectedAudioRequest: '',
  isPlaying: '',
  sentenceId: '',
  isAudioLoading: false,
  currentTime: 0,
  duration: 0,
  sentenceAudioLink: '',
  isPreview: false,
  previewRequestId: '',
  audioType: '',
  isRunStreamAudio: false,
  streamAudios: [],
  finalStreamAudio: '',
  ttsVersion: '1.0',
  popupPreviewAnchorEl: null,
};

const audioPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_AUDIO_PREVIEW: {
      return initialState;
    }

    case actionTypes.UPDATE_POPUP_PREVIEW_ANCHOR_El: {
      return { ...state, popupPreviewAnchorEl: action.value };
    }

    case actionTypes.UPDATE_AUDIO_LINK_PREVIEW: {
      const { audioLink, isPreview } = action;
      return { ...state, audioLink, isPreview };
    }
    case actionTypes.UPDATE_SELECTED_AUDIO_REQUEST_PREVIEW: {
      const { requestId } = action;
      return { ...state, selectedAudioRequest: requestId };
    }

    case actionTypes.UPDATE_STATUS_PREVIEW: {
      const { isPlaying } = action;
      return { ...state, isPlaying };
    }

    case actionTypes.UPDATE_META_DATA_PREVIEW: {
      const { currentTime, duration } = action;

      const newState = {
        ...state,
        currentTime:
          typeof currentTime === 'number' ? currentTime : state.currentTime,
        duration: typeof duration === 'number' ? duration : state.duration,
      };
      return newState;
    }

    case actionTypes.UPDATE_TRY_LISTENING_SENTENCE_PREVIEW: {
      const { sentenceId, isAudioLoading, audioLink } = action;

      const newState = {
        ...state,
        sentenceId: sentenceId || state.sentenceId,
        isAudioLoading:
          isAudioLoading !== undefined ? isAudioLoading : state.isAudioLoading,
        sentenceAudioLink:
          audioLink !== 'undefined' ? audioLink : state.audioLink,
      };
      return newState;
    }

    case actionTypes.UPDATE_REQUEST_PREVIEW_ID_PREVIEW: {
      const { previewRequestId } = action;

      return { ...state, previewRequestId };
    }

    case actionTypes.UPDATE_AUDIO_TYPE_PREVIEW: {
      const { audioType } = action;

      return { ...state, audioType };
    }

    case actionTypes.UPDATE_STREAM_AUDIOS_PREVIEW: {
      const { streamAudio } = action;
      const updatedStreamAudios = [...state.streamAudios, streamAudio];
      return { ...state, streamAudios: updatedStreamAudios };
    }

    case actionTypes.UPDATE_FINAL_STREAM_AUDIO_PREVIEW: {
      const { streamAudio } = action;
      return { ...state, finalStreamAudio: streamAudio };
    }

    case actionTypes.INIT_STREAM_AUDIOS_PREVIEW: {
      const { ttsVersion } = action;
      return { ...state, streamAudios: [], ttsVersion };
    }

    case actionTypes.UPDATE_STATUS_STREAM_AUDIO_PREVIEW: {
      return { ...state, isRunStreamAudio: action.isRunStreamAudio };
    }

    default:
      return state;
  }
};

export default audioPreviewReducer;
