import { styled } from '@mui/material/styles';
import { MenuItem, Popper } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

const StyledSpeed = styled('div')(({ disabled, active, width }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 6px',
  borderRadius: '12px',
  width: 'fit-content',

  '&:hover': {
    background: COLOR.secondary[80],
  },

  '&focus': {
    background: COLOR.secondary[80],
  },

  [disabled]: {
    cursor: 'none',
    color: COLOR.light,
    '&:hover': {
      background: 'inherit',
    },
  },

  [active]: {
    background: TRANSPARENT_COLOR.primary,
    '&:hover': {
      background: TRANSPARENT_COLOR.primary,
    },
  },

  '.MuiAutocomplete-root': {
    width: '100%',
  },

  '.text-field': {
    width: width || '100%',
    minWidth: width || '55px',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '.MuiOutlinedInput-root': {
    padding: '0 5px !important',
    width: '100%',
  },

  '.MuiAutocomplete-input': {
    color: COLOR.darkBlue,
  },

  input: {
    padding: '0 !important',
    color: COLOR.text,
    fontSize: '14px',
  },

  '.MuiAutocomplete-endAdornment': {
    right: '-7px !important',
  },
}));

const StyledMenuItem = styled(MenuItem)`
  padding: 10px;
  grid-template-columns: 50px 100px;
  grid-gap: 10px;
  display: grid;
  cursor: pointer;
  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }

  .text {
    font-weight: ${(props) => (props.active ? '500' : '400')};
  }
`;

const StyledPopper = styled(Popper)`
  width: 190px !important;
`;

export { StyledSpeed, StyledMenuItem, StyledPopper };
