import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import classNames from 'classnames';
// import getBrowserFingerprint from 'get-browser-fingerprint';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';

import camelcaseKeys from 'camelcase-keys';
import { LANDING_PAGE_URL, NOTIFICATION_URL, VBEE_DOMAIN } from '@src/configs';
import {
  EVENT_TYPE,
  TIME_DELAY_CALL_API_GET_USER_INFO,
  UPDATE_ONLINE_STATUS_INTERVAL,
  VIETNAM_LOCATION,
} from '@src/constants';
import ROUTES from '@src/constants/route';
import {
  PING_INTERVAL,
  SYNTHESIS_STATUS,
  WS_TYPE,
  WS_ORDER_STATUS,
} from '@src/constants/websocket';
import {
  PACKAGE_CODE,
  PACKAGE_EXPIRED,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
} from '@src/constants/package';
import { BANNER_TYPE } from '@src/constants/banner';
import {
  checkAutoRenewalPackage,
  checkUsingPrepaidMonthlyPkgOfStudio,
  getPackageName,
} from '@src/services/package';
import { getCookie, removeCookie, setCookie } from '@src/utils/cookie';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@src/redux/actions';
import ProcessHandler from '@src/components/ProcessHandler';
import Banners from '@src/containers/Banner';
import NotificationDialog from '@src/components/NotificationDialog';
import apis from '@src/apis';
import { DEVICE_STATUS, TIME_STORE_FINGERPRINT } from '@src/constants/device';
import { Button } from '@mui/material';
import { checkIsNumber } from '@src/utils/number';
import { AFFILIATE_NOTIFICATION_TYPE } from '@src/constants/affiliate';
import { ORDER_STATUS } from '@src/constants/order';
import moment from 'moment';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import useCustomKeycloak from '@src/hooks/useCustomKeycloak';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { checkShowAutoRenewalPayment } from '@src/services/order';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import { checkVietNam } from '@src/utils/checkCountry';
import { delay } from '@src/utils/delay';
import dataSenses from '@src/services/dataSenses';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import TitleProduct from '@src/components/TitleProduct';

import Sidebar from './Sidebar';
import NewSidebar from './SidebarNew/index';
import Navbar from './Navbar';
import Content from './Content';
import CustomerCare from './CustomerCare';
import ServiceTerm from './ServiceTerm';
import { StyledWrapper, StyledMainPanel } from './index.style';
import NewNavbar from './NewNavbar';
import AutoRenewDialog from './AutoRenewal/AutoRenewDialog';
import DubbingGiftBanner from './DubbingGiftBanner';

const Layout = ({ children, headerVoucher, useHeaderBanner }) => {
  const location = useLocation();
  const [ipLocation, seIpLocation] = useState(null);
  const [isExceedDevices, setIsExceedDevices] = useState(false);
  const [showAutoRenewalBanner, setShowAutoRenewalBanner] = useState(false);

  const [showDubbingGiftBanner, setShowDubbingGiftBanner] = useState(false);
  const [giftExpiryIn, setGiftExpiryIn] = useState(false);
  const [dubbingGiftOrder, setDubbingGiftOrder] = useState(null);
  const [banners, setBanners] = useState([]);

  const { notificationDialog } = useSelector((state) => state.dialog);
  const { accessToken, user } = useSelector((state) => state.auth);
  const userInfo = useSelector((state) => state.user || {});
  const { synthesisRequest } = useSelector((state) => state.synthesisRequest);
  const { openSidebar } = useSelector((state) => state.layout);
  const { allowShowAutoRenewalBanner } = useSelector((state) => state.banner);
  const { remainingCharacters, bonusCharacters } = user;
  const { remainingSeconds } = user?.dubbing || 0;
  const { usingPackage, subscriptionPurchase, latestDubbingOrder } = userInfo;

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const ws = useRef(null);
  const remainingCharactersRef = useRef(remainingCharacters);
  const bonusCharactersRef = useRef(bonusCharacters);
  const remainingSecondsRef = useRef(remainingSeconds);
  const userRef = useRef(user);
  const locationRef = useRef(location);
  const languageRef = useRef(language);
  const synthesisRequestRef = useRef(synthesisRequest);
  const translationRef = useRef(t);
  const { keycloak, removeKcTokenCookie } = useCustomKeycloak();
  const isVietNam = checkVietNam();
  const { width: widthScreen } = useWindowDimensions();

  const history = useHistory();
  const dispatch = useDispatch();

  const isDubbingRoute = useCheckDubbingRoute();

  const { getFeatureValue } = useFeatureFlags();

  const useVoucherByProduct = getFeatureValue(FEATURE_KEYS.VOUCHER_BY_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let useNewSideBar = getFeatureValue(FEATURE_KEYS.NEW_SIDEBAR);
  useNewSideBar = useNewSideBar && useNewTtsUI;
  const useAutoRenewalPayment =
    useNewTtsUI &&
    getFeatureValue(FEATURE_KEYS.CONFIG_AUTO_RENEW_PREPAID_PACKAGE, {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    }) &&
    checkUsingPrepaidMonthlyPkgOfStudio(usingPackage);

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const isGiveDubbingPackageGift = getFeatureValue(
    FEATURE_KEYS.DUBBING_PACKAGE_GIFT,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const useNewSupportUI = getFeatureValue(FEATURE_KEYS.NEW_SUPPORT_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const useNewFolowAddTrialDubbing = getFeatureValue(
    FEATURE_KEYS.GIVEAWAY_TRIAL_DUBBING_PACKAGE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSendFromServer = dataSensesFeatures?.s2s;

  const handleChangeBanners = (value) => setBanners(value);

  const handleOpenAutoRenewalPayment = () => {
    const isExpiredPackage =
      !usingPackage.id ||
      (user.packageExpiryDate && moment().isAfter(user.packageExpiryDate));

    const isNotActiveSubPurchase = ![
      SUBSCRIPTION_PURCHASE_STATUS.PENDING,
      SUBSCRIPTION_PURCHASE_STATUS.ACTIVE,
    ].includes(subscriptionPurchase?.status);

    const autoRenewalBanner =
      useAutoRenewalPayment &&
      isVietNam &&
      allowShowAutoRenewalBanner &&
      usingPackage.active &&
      !isExpiredPackage &&
      isNotActiveSubPurchase &&
      checkShowAutoRenewalPayment({
        packageCode: usingPackage?.code,
        alwayShow: false,
      });

    const isSeenAllBanner = allowShowAutoRenewalBanner && !autoRenewalBanner;
    if (isSeenAllBanner) dispatch(actions.banner.addSeenAllBanner());
    setShowAutoRenewalBanner(autoRenewalBanner);
  };

  const handleOpenDubbingGiftBanner = () => {
    if (!isProcessDubbingByUnitSecond) return;
    if (!isGiveDubbingPackageGift) {
      setShowDubbingGiftBanner(false);
      return;
    }

    const checkShowDubbingGiftBanner = JSON.parse(
      localStorage.getItem('DUBBING_GIFT_BANNER'),
    );
    if (checkShowDubbingGiftBanner) return;

    if (latestDubbingOrder?.id) {
      setShowDubbingGiftBanner(false);
      return;
    }

    const isPaidUser = usingPackage?.price > 0;

    if (isPaidUser) {
      setDubbingGiftOrder(PACKAGE_LEVEL.STANDARD);
      setGiftExpiryIn(PACKAGE_EXPIRED.MONTHLY);
    } else {
      setGiftExpiryIn(3); // package expired in 3 days
      setDubbingGiftOrder(PACKAGE_LEVEL.TRIAL);
    }

    setShowDubbingGiftBanner(true);
  };

  const handleCloseAutoRenewDialog = (notShowAgain = false) => {
    localStorage.setItem(
      'AUTO_RENEWAL_BANNER',
      JSON.stringify({
        date: moment().format('DD/MM/YYYY'),
        notShowAgain,
      }),
    );
    setShowAutoRenewalBanner(false);
    dispatch(actions.banner.addSeenAllBanner());
  };

  const handleCloseDubbingGiftBanner = () => {
    setShowDubbingGiftBanner(false);
    localStorage.setItem('DUBBING_GIFT_BANNER', true);
  };

  const handleOpenSidebar = () =>
    dispatch(actions.layout.handleChangeOpenSidebar(true));

  const handleCloseSidebar = () =>
    dispatch(actions.layout.handleChangeOpenSidebar(false));

  const handleBackToTTSWhenConfirmedOrder = () => {
    const { pathname, search } = locationRef.current;
    if (pathname === ROUTES.PAYMENT && search.includes('?package='))
      dispatch(actions.user.backToTTS());
    if (pathname === ROUTES.PAYMENT_DUBBING && search.includes('?package='))
      dispatch(actions.user.backToDubbing());
  };

  const handleCloseQRPaymentWhenCanceledOrder = () => {
    const { pathname, search } = locationRef.current;
    if (
      (pathname === ROUTES.PAYMENT || pathname === ROUTES.PAYMENT_DUBBING) &&
      search.includes('?package=')
    )
      dispatch(actions.user.closeQRPayment());
    if (
      pathname === ROUTES.PAYMENT_HISTORY ||
      pathname === ROUTES.PAYMENT_HISTORY_DUBBING
    )
      dispatch(actions.user.fetchPaymentHistory(true));
  };

  const fetchCountry = async () => {
    try {
      const data = await apis.country.getCountry();
      if (!data?.country) {
        seIpLocation(VIETNAM_LOCATION);
        return;
      }
      seIpLocation(data);
    } catch (error) {
      seIpLocation(VIETNAM_LOCATION);
    }
  };

  const fetchSubscriptionPurchase = async (purchaseId, purchaseStatus) => {
    const data = await apis.subscription.getSubscriptionInfo(purchaseId);
    const { status, result = {} } = data || {};
    if (status === 1) {
      dispatch(actions.user.updateSubscriptionPurchase(result));

      const { orderInfo } = result;
      if (!orderInfo) return;
      const { price, usdPrice, packageExpiryDate, packageCode } = orderInfo;
      const packageName = getPackageName(null, packageCode, language, t, true);

      const hasAutoRenewNotification = getCookie(
        `auto-renewal-notification-${purchaseId}`,
      );
      if (
        !hasAutoRenewNotification &&
        purchaseId &&
        purchaseStatus === SUBSCRIPTION_PURCHASE_STATUS.ACTIVE
      ) {
        dispatch(
          actions.banner.updateDisplayBanner(
            BANNER_TYPE.JOIN_ZALO_GROUP,
            true,
            {
              packageName,
              isAutoRenewalPackage: true,
              packagePrice: price,
              packageUsdPrice: usdPrice,
              packageExpiryDate,
            },
          ),
        );
        setCookie({
          cname: `auto-renewal-notification-${purchaseId}`,
          cvalue: true,
          domain: VBEE_DOMAIN,
          extime: 1000 * 60 * 60 * 24 * 14,
        });
      }
    }
  };

  const fetchBannerVoucher = async () => {
    const response = await apis.vouchers.getBannerVouchersFinal(accessToken);

    if (response?.status !== 1) return;
    const bannerVouchers = response?.result?.vouchers || [];
    if (bannerVouchers?.length) {
      if (!useVoucherByProduct) {
        dispatch(actions.voucher.updateBannerVoucher(bannerVouchers[0]));
      } else {
        dispatch(actions.voucher.updateBannerVoucher(bannerVouchers));
      }
    }
  };

  // const pushDataDeviceToMoe = async () => {
  //   const { result: apiResult = {} } = await apis.devices.getDevices({});
  //   const onlineDevices =
  //     apiResult?.devices?.filter(
  //       (device) => device.status === DEVICE_STATUS.ONLINE,
  //     ).length || 0;
  //   const restrictedDevices =
  //     apiResult?.devices?.filter(
  //       (device) => device.status === DEVICE_STATUS.RESTRICTED,
  //     ).length || 0;

  //   window.dataLayer.push({
  //     event: EVENT_TYPE.DEVICE_MANAGER,
  //     userId: user?.id,
  //     maxDevices: user.maxDevices || userInfo?.usingPackage?.device,
  //     onlineDevices,
  //     restrictedDevices,
  //     time: moment().format('DD/MM/YYYY - HH:mm'),
  //   });
  // };

  const pushNewUserToGTM = async () => {
    const { createdAt, email, id, affiliateMarketerId, pushedToGtm } =
      user || {};
    if (pushedToGtm) return;

    // Only push new user to GTM if user created today
    const checkNewUser = moment().diff(moment(createdAt), 'days') === 0;
    if (!checkNewUser) return;
    dataSenses.sendSignUpSuccessEvent({ userId: id });

    window.dataLayer.push({
      event: EVENT_TYPE.SIGN_UP_SUCCESS,
      email,
      userId: id,
      affiliateMarketerId,
    });

    apis.user.confirmPushUserToGTM();
  };

  const handleSetMoEngageUniqueId = async () => {
    // eslint-disable-next-line no-undef
    await Moengage.add_unique_user_id(user.id);
  };

  const checkUserDevice = async () => {
    try {
      let fingerprintCookie =
        getCookie('fingerprint') || localStorage.getItem('fingerprint');
      const newFingerprint = uuidv4();

      // fingerprint version 1 is number
      const isV1TypeFingerprint = checkIsNumber(fingerprintCookie);
      if (fingerprintCookie && isV1TypeFingerprint) {
        await apis.devices.deactivateDevice(fingerprintCookie);
        fingerprintCookie = newFingerprint;
      }

      const fingerprint = fingerprintCookie || newFingerprint;
      setCookie({
        cname: 'fingerprint',
        cvalue: fingerprint,
        domain: VBEE_DOMAIN,
        extime: TIME_STORE_FINGERPRINT,
      });
      localStorage.setItem('fingerprint', fingerprint);
      const data = await apis.devices.checkUserDevice(fingerprint);
      const { check } = data;
      if (
        check === DEVICE_STATUS.OFFLINE ||
        check === DEVICE_STATUS.RESTRICTED
      ) {
        setIsExceedDevices(true);
        // pushDataDeviceToMoe();
      } else setIsExceedDevices(false);
    } catch (error) {
      setIsExceedDevices(false);
    }
  };

  const trackingEvents = async () => {
    const { result: apiResult } = await apis.orders.getOrders({
      userId: userRef.current.id,
      status: ORDER_STATUS.PAID,
      sort: 'createdAt_desc',
      limit: 1,
    });
    if (apiResult?.orders?.length === 0) return;
    const order = apiResult?.orders[0] || {};
    const totalPaidOrders = apiResult?.total || 0;
    if (order.pushDataToMoe) return;

    // Send data to GTM
    window.dataLayer.push({
      event: EVENT_TYPE.BUY_PACKAGE,
      customerName: `${user.lastName} ${user.firstName}`,
      email: user.email,
      phoneNumber: user.phoneNumber || order?.user?.phoneNumber,
      orderId: order.id,
      package: order.package?.code,
      type: order.type,
      packageExpiryDate: order.packageExpiryDate,
      packagePrice: order.packagePrice || order?.package?.price,
      paidCharacters:
        order?.customPackage?.maxCharacters || order?.package?.maxCharacters,
      paymentMethodSource:
        order?.paymentMethod?.source || order?.paymentMethodSource,
      confirmedFrom: order?.confirmedFrom,
      confirmedAt: order?.confirmedAt,
      packageType: order?.type,
      totalPaidOrders,
      affiliateMarketerId: order?.affiliateMarketerId,
      userId: user.id,
    });

    // Send data to DataSenses
    dataSenses.sendChargedEvent({
      orderId: order.id,
      product: order?.type,
      packageCode: order.package?.code,
      packageId: order.package?.id,
      price: order.price || order.actualPrice,
      paidAmount: order.actualPrice || order.price,
    });

    apis.orders.updateMoeStatus(order.id);
  };

  const sendOrderToGTM = async ({
    orderId,
    phoneNumber,
    packageId,
    packageCode,
    type,
    packageExpiryDate,
    paidCharacters,
    paymentMethodSource,
    confirmedFrom,
    confirmedAt,
    packageType,
    userId,
    actualPrice,
  }) => {
    window.dataLayer.push({
      event: EVENT_TYPE.BUY_PACKAGE,
      customerName: `${user.lastName} ${user.firstName}`,
      email: user.email,
      phoneNumber: user.phoneNumber || phoneNumber,
      orderId,
      packageCode,
      type,
      packageExpiryDate,
      paidCharacters,
      paymentMethodSource,
      confirmedFrom,
      confirmedAt,
      packageType,
      userId,
    });

    if (!sendDataSendFromServer)
      dataSenses.sendChargedEvent({
        orderId,
        product: type,
        packageCode,
        packageId,
        price: actualPrice,
        paidAmount: actualPrice,
      });

    apis.orders.updateMoeStatus(orderId);
  };

  const handleUpdateAffiliateMarketer = async () => {
    const affiliateCode = getCookie('affiliateCode');
    const affiliateTime = getCookie('affiliateTime');
    if (!affiliateCode || !affiliateTime) return;

    await apis.affiliateMarketer.updateAffiliateMarketer({
      affiliateCode,
      affiliateTime,
      accessToken,
    });

    // Remove cookies after update
    removeCookie('affiliateCode');
    removeCookie('affiliateTime');
  };

  const handleUpdateAdsProvider = async () => {
    const adsProvider = getCookie('adsProvider');
    const adsProviderTime = getCookie('adsProviderTime');
    if (!adsProvider || !adsProviderTime) return;

    await apis.adsProvider.updateAdsProvider({
      adsProvider,
      adsProviderTime,
    });

    // Remove cookies after update
    removeCookie('adsProvider');
    removeCookie('adsProviderTime');
  };

  const handleShowNotiWhenCancelSubscription = async () => {
    const { search } = locationRef.current;
    const params = new URLSearchParams(search);
    const purchaseId = params.get('purchaseId');
    const status = params.get('status');

    if (purchaseId && status.includes(ORDER_STATUS.CANCEL)) {
      // Delay 2s for waiting display ui
      await delay(2000);

      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'transactionFailed',
        }),
      );

      // Remove query params after display notification
      history.replace({
        pathname: location.pathname,
        search: '',
      });
    }
  };

  const redirectToManageDevicePage = () => history.push(ROUTES.MANAGE_DEVICE);

  const renderCloseExceedDeviceDialog = (handleClick) => (
    <Button variant="contained" onClick={handleClick}>
      {t('goToManageDevicePage')}
    </Button>
  );

  // Default open sidebar
  // Close sidebar when screen width < 600px
  useEffect(() => {
    if (widthScreen < 600) {
      handleCloseSidebar();
    }
  }, [widthScreen]);

  // Push login event to datasenses
  useEffect(() => {
    dataSenses.sendLoginEvent({ userId: user.id });
    ReactGA.set({ userId: user.id });
  }, [keycloak.authenticated]);

  useEffect(() => {
    handleOpenAutoRenewalPayment();
  }, [usingPackage, allowShowAutoRenewalBanner, subscriptionPurchase]);

  useEffect(() => {
    handleOpenDubbingGiftBanner();
  }, [isProcessDubbingByUnitSecond, usingPackage, latestDubbingOrder]);

  useEffect(() => {
    handleUpdateAffiliateMarketer();
    handleUpdateAdsProvider();
  }, []);

  useEffect(() => {
    pushNewUserToGTM();
    handleSetMoEngageUniqueId();
  }, [user]);

  useEffect(() => {
    remainingCharactersRef.current = remainingCharacters;
  }, [remainingCharacters]);

  useEffect(() => {
    bonusCharactersRef.current = bonusCharacters;
  }, [bonusCharacters]);

  useEffect(() => {
    remainingSecondsRef.current = remainingSeconds;
  }, [remainingSeconds]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    locationRef.current = location;
    handleShowNotiWhenCancelSubscription();
  }, [location]);

  useEffect(() => {
    languageRef.current = language;
  }, [language]);

  useEffect(() => {
    synthesisRequestRef.current = synthesisRequest;
  }, [synthesisRequest]);

  useEffect(() => {
    translationRef.current = t;
  }, [t]);

  useEffect(() => {
    apis.account.updateOnlineStatus();

    const updateOnlineStatusInterval = setInterval(() => {
      apis.account.updateOnlineStatus();
    }, UPDATE_ONLINE_STATUS_INTERVAL);

    return () => clearInterval(updateOnlineStatusInterval);
  }, []);

  useEffect(() => {
    checkUserDevice();
  }, [location?.pathname]);

  useEffect(() => {
    fetchSubscriptionPurchase();
    fetchBannerVoucher();
    dispatch(actions.user.getTtsUser());
  }, []);

  useEffect(() => {
    ws.current = new WebSocket(NOTIFICATION_URL);
    let pingInterval;
    ws.current.onopen = () => {
      ws.current.send(JSON.stringify({ type: WS_TYPE.INIT, accessToken }));
      pingInterval = setInterval(() => {
        ws.current.send(JSON.stringify({ type: WS_TYPE.PING }));
      }, PING_INTERVAL);
    };

    ws.current.onmessage = (res) => {
      const responseData = camelcaseKeys(JSON.parse(res.data), { deep: true });
      const { type, result } = responseData;

      const handleSynthesis = () => {
        const {
          requestId,
          audioLink,
          status,
          characters,
          seconds,
          progress,
          processingAt,
          endedAt,
        } = result;

        switch (status) {
          case SYNTHESIS_STATUS.CREATED: {
            if (seconds) {
              let currentSeconds = remainingSecondsRef.current;
              currentSeconds -= seconds;
              dispatch(
                actions.auth.updateUserInfo({
                  ...userRef.current,
                  dubbing: {
                    ...userRef.current.dubbing,
                    remainingSeconds: currentSeconds,
                  },
                }),
              );
            } else {
              let bonus = bonusCharactersRef.current;
              let remaining = remainingCharactersRef.current;

              if (characters >= bonusCharactersRef.current) {
                bonus = 0;
                remaining -= characters - bonus;
              } else {
                bonus -= characters;
              }
              dispatch(
                actions.auth.updateUserInfo({
                  ...userRef.current,
                  remainingCharacters: remaining,
                  bonusCharacters: bonus,
                }),
              );
            }
            break;
          }
          case SYNTHESIS_STATUS.SUCCESS: {
            if (!processingAt || !endedAt) break; // To avoid update synthesisRequest when backend send message with status SUCCESS but not send processingAt and endedAt
            dispatch(
              actions.request.addSynthesisResponse({
                requestId,
                audioLink,
                status,
                processingAt,
                endedAt,
              }),
            );
            break;
          }

          case SYNTHESIS_STATUS.FAILURE: {
            const paid = result.paidCharacters || 0;
            const bonus = result.bonusCharacters || 0;
            dispatch(
              actions.request.addSynthesisResponse({
                requestId,
                audioLink,
                status,
              }),
            );
            dispatch(
              actions.auth.updateUserInfo({
                ...userRef.current,
                remainingCharacters: remainingCharactersRef.current + paid,
                bonusCharacters: bonusCharactersRef.current + bonus,
              }),
            );
            break;
          }
          case SYNTHESIS_STATUS.IN_PROGRESS: {
            const updateData = { requestId, audioLink, status, progress };
            if (processingAt) updateData.processingAt = processingAt;
            dispatch(actions.request.addSynthesisResponse(updateData));

            const startProcessSynthesisRequest =
              synthesisRequestRef.current.id === requestId && progress === 1; // 1 is 1% - start percent when request start processing
            if (startProcessSynthesisRequest) {
              if (processingAt)
                synthesisRequestRef.current.processingAt = processingAt;
              dispatch(
                actions.synthesisRequest.updateSynthesisConfig(
                  synthesisRequestRef.current,
                ),
              );
            }
            break;
          }
          default:
            break;
        }

        if (synthesisRequestRef.current.id === requestId) {
          if (
            status === SYNTHESIS_STATUS.SUCCESS &&
            (!processingAt || !endedAt)
          )
            return; // return to avoid update synthesisRequest when backend send message with status SUCCESS but not send processingAt and endedAt

          dispatch(
            actions.synthesisRequest.updateSynthesisConfig({
              ...synthesisRequestRef.current,
              progress,
              status,
              audioLink,
            }),
          );
        }
      };

      const updateUserInfo = async () => {
        // Delay time for wait update database reset bonus characters
        await delay(TIME_DELAY_CALL_API_GET_USER_INFO);
        dispatch(actions.user.getTtsUser());
        const userRes = await apis.account.getMe();
        dispatch(actions.auth.updateUserInfo(userRes.result));
      };

      const handleOrder = async () => {
        const {
          type: packageType,
          packageCode,
          renewalType,
          packagePrice,
          packageUsdPrice,
          packageExpiryDate,
          status,
          remainingCharacters: remaining,
          bonusCharacters: bonus,
          lockCharacters,
          remainingSeconds: resultSeconds,
          lockSeconds,
          isGift,
        } = result;

        const isAutoRenewalPackage = checkAutoRenewalPackage({ renewalType });
        switch (status) {
          case WS_ORDER_STATUS.PAID: {
            if (packageType === PACKAGE_TYPE.API) {
              handleBackToTTSWhenConfirmedOrder();
              break;
            }

            if (packageCode) {
              const isFreePackage =
                packageCode.includes(PACKAGE_LEVEL.TRIAL) ||
                packageCode.includes(PACKAGE_LEVEL.BASIC) ||
                packageCode.includes(PACKAGE_CODE.STUDIO_FREE);

              if (!isFreePackage) {
                const packageName = getPackageName(
                  null,
                  packageCode,
                  languageRef.current,
                  translationRef.current,
                  true,
                );
                if (!isGift) {
                  dispatch(
                    actions.banner.updateDisplayBanner(
                      BANNER_TYPE.JOIN_ZALO_GROUP,
                      true,
                      {
                        packageType,
                        packageName,
                        isAutoRenewalPackage,
                        packagePrice,
                        packageUsdPrice,
                        packageExpiryDate,
                      },
                    ),
                  );
                  dispatch(
                    actions.noti.push({
                      severity: 'success',
                      message: 'buyPackageSuccessfully',
                    }),
                  );
                }
              }

              const updatedUserInfo = { ...userRef.current };
              if (packageType === PACKAGE_TYPE.DUBBING) {
                updatedUserInfo.dubbing = {
                  ...userRef.current.dubbing,
                  packageCode,
                  packageExpiryDate,
                };
                dispatch(
                  actions.user.getLatestDubbingOrder(userRef.current.id),
                );
              } else {
                updatedUserInfo.packageCode = packageCode;
                updatedUserInfo.packageExpiryDate = packageExpiryDate;
                dispatch(actions.user.getLatestOrder(userRef.current.id));
              }
              dispatch(actions.auth.updateUserInfo(updatedUserInfo));
              dispatch(actions.user.getUsingPackage(userRef.current.id));
              dispatch(actions.order.updateVoucher(null));
              dispatch(actions.user.getTtsUser());
              handleBackToTTSWhenConfirmedOrder();

              trackingEvents();
            }

            if (resultSeconds || lockSeconds) {
              dispatch(
                actions.auth.updateUserInfo({
                  ...userRef.current,
                  dubbing: {
                    ...userRef.current.dubbing,
                    remainingSeconds: resultSeconds,
                    lockSeconds,
                  },
                }),
              );
            }

            if (remaining || bonus || lockCharacters) {
              dispatch(
                actions.auth.updateUserInfo({
                  ...userRef.current,
                  remainingCharacters: remaining,
                  bonusCharacters: bonus,
                  lockCharacters,
                }),
              );
            }

            break;
          }

          case WS_ORDER_STATUS.CANCEL: {
            if (packageType === PACKAGE_TYPE.DUBBING) {
              dispatch(actions.user.getLatestDubbingOrder(userRef.current.id));
              if (isDubbingRoute) {
                dispatch(
                  actions.noti.push({
                    severity: 'success',
                    message: 'notiCanceledOrder',
                  }),
                );
                handleCloseQRPaymentWhenCanceledOrder();
              }
            } else {
              dispatch(actions.user.getLatestOrder(userRef.current.id));
              if (!isDubbingRoute) {
                dispatch(
                  actions.noti.push({
                    severity: 'success',
                    message: 'notiCanceledOrder',
                  }),
                );
                handleCloseQRPaymentWhenCanceledOrder();
              }
            }

            dispatch(actions.order.updateVoucher(null));
            break;
          }

          default:
            break;
        }
      };

      const handleLogout = (fingerprintDevice) => {
        const fingerprint =
          getCookie('fingerprint') || localStorage.getItem('fingerprint');
        if (fingerprintDevice === fingerprint) {
          removeKcTokenCookie();
          keycloak.logout({ redirectUri: LANDING_PAGE_URL });
        }
      };

      const handleAffiliateNotification = () => {
        const { notificationType } = result;
        switch (notificationType) {
          case AFFILIATE_NOTIFICATION_TYPE.CREATE_AFFILIATE_TRANSACTION_FAILURE: {
            dispatch(
              actions.noti.push({
                severity: 'error',
                message: 'createAffiliateTransactionFailure',
              }),
            );
            break;
          }

          // case AFFILIATE_NOTIFICATION_TYPE.CREATE_AFFILIATE_TRANSACTION_SUCCESS: {
          //   dispatch(
          //     actions.noti.push({
          //       severity: 'success',
          //       message: 'createAffiliateTransactionSuccess',
          //     }),
          //   );
          //   break;
          // }

          case AFFILIATE_NOTIFICATION_TYPE.AFFILIATE_TRANSACTION_APPROVED: {
            dispatch(
              actions.noti.push({
                severity: 'success',
                message: 'paymentRequestApproved',
              }),
            );
            break;
          }

          case AFFILIATE_NOTIFICATION_TYPE.AFFILIATE_TRANSACTION_REJECTED: {
            dispatch(
              actions.noti.push({
                severity: 'error',
                message: 'paymentRequestRejected',
              }),
            );
            break;
          }

          default: {
            break;
          }
        }
      };

      switch (type) {
        case WS_TYPE.SYNTHESIS: {
          handleSynthesis();
          break;
        }

        case WS_TYPE.INIT: {
          updateUserInfo();
          break;
        }

        case WS_TYPE.ORDER: {
          handleOrder();
          break;
        }

        case WS_TYPE.LOGOUT_DEVICES: {
          const { fingerprint } = result;
          dispatch(
            actions.device.addDeviceResponse({
              fingerprint,
              status: DEVICE_STATUS.OFFLINE,
            }),
          );
          handleLogout(fingerprint);
          break;
        }

        case WS_TYPE.AFFILIATE_NOTIFICATION: {
          handleAffiliateNotification();
          break;
        }

        case WS_TYPE.RESET_FREE_CHARACTERS: {
          dispatch(actions.auth.updateBonusCharacters(result.bonusCharacters));
          dispatch(actions.user.updateBonusCharacters(result.bonusCharacters));
          break;
        }

        case WS_TYPE.PUSH_ORDER_TO_GTM: {
          sendOrderToGTM(result);
          break;
        }

        default:
          break;
      }
    };

    ws.current.onclose = (event) => {
      const { code, reason, wasClean } = event;
      // eslint-disable-next-line no-console
      console.log('Websocket is closed.', { event, code, reason, wasClean });
      clearInterval(pingInterval);
    };
  }, []);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (!banners.length) return;
    // Handle receive subscription redirect result
    const { purchaseId, status } = camelcaseKeys(
      queryString.parse(location.search) || {},
    );
    if (purchaseId) {
      fetchSubscriptionPurchase(purchaseId, status);
    }
  }, [location, banners]);

  useEffect(() => {
    // Add trial dubbing for user when user access to dubbing page and user has not dubbing package
    if (
      !user ||
      user?.dubbing?.packageCode ||
      !location.pathname.includes('/dubbing') ||
      !useNewFolowAddTrialDubbing
    )
      return;
    apis.user.addTrialDubbing();
  }, [user, location.pathname]);

  useEffect(() => {
    dataSenses.sendViewPageEvent({
      userId: user.id,
      domain: window.location.hostname,
      pathname: location.pathname,
      search: location.search,
    });
  }, [location.pathname, location.search]);

  const renderContent = () => {
    if (!ipLocation) return <ProcessHandler loading={!location} />;
    if (
      ipLocation &&
      isExceedDevices &&
      location?.pathname !== ROUTES.MANAGE_DEVICE
    )
      return (
        <>
          <StyledWrapper>
            {useNewSideBar ? (
              <NewSidebar
                openSidebar={openSidebar}
                handleOpenSidebar={handleOpenSidebar}
                handleCloseSidebar={handleCloseSidebar}
                ipLocation={ipLocation}
              />
            ) : (
              <Sidebar
                openSidebar={openSidebar}
                handleOpenSidebar={handleOpenSidebar}
                handleCloseSidebar={handleCloseSidebar}
              />
            )}

            <StyledMainPanel
              className={classNames({ 'open-sidebar': openSidebar })}
            >
              {useNewSideBar ? (
                <NewNavbar
                  openSidebar={openSidebar}
                  handleOpenSidebar={handleOpenSidebar}
                  ipLocation={ipLocation}
                />
              ) : (
                <Navbar
                  openSidebar={openSidebar}
                  handleOpenSidebar={handleOpenSidebar}
                  location={ipLocation}
                  useNewSideBar={useNewSideBar}
                />
              )}

              <Content useNewSideBar={useNewSideBar} openSidebar={openSidebar}>
                <NotificationDialog
                  name="check-devices"
                  title={t('deviceExceedTitle')}
                  description={t('maxDeviceDescription')}
                  variant="warning"
                  open={isExceedDevices}
                  actionComponent={renderCloseExceedDeviceDialog(
                    redirectToManageDevicePage,
                  )}
                />
              </Content>
            </StyledMainPanel>
          </StyledWrapper>
          {!useNewSupportUI && <CustomerCare />}
        </>
      );

    return (
      <>
        <StyledWrapper
          useHeaderBanner={useHeaderBanner}
          headerVoucher={headerVoucher}
        >
          {useNewSideBar ? (
            <NewSidebar
              useHeaderBanner={useHeaderBanner}
              headerVoucher={headerVoucher}
              openSidebar={openSidebar}
              handleOpenSidebar={handleOpenSidebar}
              handleCloseSidebar={handleCloseSidebar}
              ipLocation={ipLocation}
            />
          ) : (
            <Sidebar
              openSidebar={openSidebar}
              handleOpenSidebar={handleOpenSidebar}
              handleCloseSidebar={handleCloseSidebar}
            />
          )}

          <StyledMainPanel
            useNewSideBar={useNewSideBar}
            openSidebar={openSidebar}
            className="main-panel"
          >
            {useNewSideBar ? (
              <NewNavbar
                openSidebar={openSidebar}
                handleOpenSidebar={handleOpenSidebar}
                ipLocation={ipLocation}
              />
            ) : (
              <Navbar
                openSidebar={openSidebar}
                handleOpenSidebar={handleOpenSidebar}
                location={ipLocation}
                useNewSideBar={useNewSideBar}
              />
            )}
            <Content
              useHeaderBanner={useHeaderBanner}
              headerVoucher={headerVoucher}
              useNewSideBar={useNewSideBar}
              openSidebar={openSidebar}
            >
              <TitleProduct />
              {children}
            </Content>
          </StyledMainPanel>
          <Banners
            showDubbingGiftBanner={showDubbingGiftBanner}
            banners={banners}
            onChangeBanners={handleChangeBanners}
          />
          {notificationDialog?.open && (
            <NotificationDialog
              name={notificationDialog.name}
              title={notificationDialog.title}
              description={notificationDialog.description}
              subDescription={notificationDialog.subDescription}
              variant={notificationDialog.variant}
              open={notificationDialog.open}
              width={notificationDialog.width}
              contentComponent={notificationDialog.contentComponent}
              actionComponent={notificationDialog.actionComponent}
              onClose={notificationDialog.onClose}
            />
          )}
          <ServiceTerm open={!user?.hasAgreedToTerms} />
          <AutoRenewDialog
            open={showAutoRenewalBanner}
            onClose={handleCloseAutoRenewDialog}
          />
          <DubbingGiftBanner
            open={showDubbingGiftBanner}
            expiryIn={giftExpiryIn}
            onClose={handleCloseDubbingGiftBanner}
            dubbingGiftOrder={dubbingGiftOrder}
          />
        </StyledWrapper>
        {!useNewSupportUI && <CustomerCare />}
      </>
    );
  };

  return renderContent();
};

export default Layout;
