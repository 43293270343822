import React, { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import { GrowthBookProvider, GrowthBook } from '@growthbook/growthbook-react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SSOProvider } from 'sso-package';

import store from './redux/store';
import AppRouter from './router';
import theme from './styles/theme';
import {
  IAM_URL,
  IAM_REALM,
  IAM_CLIENT_ID,
  GB_API_HOST,
  GB_CLIENT_KEY,
  RECAPTCHA_V3_SITE_KEY,
  CLIENT_ID,
  SSO_URL,
  SSO_API_URL,
  CRM_URL,
} from './configs';
import { getCookie } from './utils/cookie';
import { handleEvent, handleReceivingTokens } from './services/auth';
import NotiHandler from './errors/NotiHandler';
import FontLoader from './containers/FontLoader';
import useFeatureFlags from './hooks/useFeatureFlags';
import { FEATURE_KEYS } from './configs/featureKeys';
import { getDomain } from './utils/url';

const growthbook = new GrowthBook({
  apiHost: GB_API_HOST,
  clientKey: GB_CLIENT_KEY,
  enableDevMode: true,
  subscribeToChanges: true,
});

const keycloak = Keycloak({
  url: `${IAM_URL}/auth`,
  realm: IAM_REALM,
  clientId: IAM_CLIENT_ID,
});

const notistackRef = React.createRef();

const SSOProviderWrapper = () => {
  const { user: userStore } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = userStore;
  const { getFeatureValue } = useFeatureFlags();
  const useSSO = getFeatureValue(FEATURE_KEYS.NEW_SSO_STUDIO, {
    userId,
    email,
    phoneNumber,
  });
  const configSSO = {
    clientId: CLIENT_ID,
    ssoUrl: SSO_URL,
    ssoApiUrl: SSO_API_URL,
    clientDomain: getDomain(CRM_URL),
    options: {
      autoLogin: true,
      useSSO,
    },
  };
  return (
    <SSOProvider config={configSSO}>
      <AppRouter />
    </SSOProvider>
  );
};

const App = () => {
  const refreshToken = getCookie('refreshToken');

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        refreshToken,
      }}
      onTokens={handleReceivingTokens}
      onEvent={handleEvent}
    >
      <ThemeProvider theme={theme}>
        <GrowthBookProvider growthbook={growthbook}>
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
            <Provider store={store()}>
              <SnackbarProvider
                preventDuplicate
                maxSnack={3}
                ref={notistackRef}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <NotiHandler>
                  <SSOProviderWrapper />
                </NotiHandler>
              </SnackbarProvider>
            </Provider>
          </GoogleReCaptchaProvider>
          <FontLoader />
        </GrowthBookProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
