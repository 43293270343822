import { COLOR } from './color';

const SIDEBAR_WIDTH = 260;

const NEW_SIDEBAR_WIDTH = 240;

const SIDEBAR_WIDTH_COLLAPSED = 62;

const AUDIO_PLAYER_HEIGHT = 78;

const PAYMENT_FOOTER_HEIGHT = 40;

const TITLE_PRODUCT_HEIGHT = 60;

const BOX_SHADOW = '0px 4px 24px rgba(0, 0, 0, 0.06)';

const SIDEBAR_BOX_SHADOW = '0px 4px 16px 0px rgba(0, 0, 0, 0.16)';

const PAYMENT_FOOTER_SHADOW = '0px 4px 16px 0px rgba(0, 0, 0, 0.24)';

const TOOLTIP_BOX_SHADOW = '0px 4px 15px 0px #2C3F5859';

const PACKAGE_BOX_SHADOW = '0px 8px 32px rgba(30, 22, 78, 0.4)';

const TOOLBAR_BOX_SHADOW = '0px 0px 20px 0px rgba(28, 27, 27, 0.15)';

const TEXT_SHADOW = '0px 2px 8px rgba(0, 0, 0, 0.16)';

const BORDER_RADIUS = '12px';

const AVATAR_COLORS = [COLOR.primary, COLOR.success, COLOR.warning, COLOR.info];

const PACKAGE_HEIGHT_EXTRA = 32;

const TIMELINE_HEIGHT = 160;

const TIMELINE_COLLAPSED_HEIGHT = 60;

const MOBILE_BREAKPOINT = '768px';

const BREAKPOINTS = {
  XS: 0,
  MXS: 420,
  SM: 600,
  SMD: 801,
  MD: 1002,
  LMD: 1101,
  LG: 1200,
  MLG: 1280,
  CMLG: 1370,
  XL: 1536,
  XXL: 1561,
};

const FLASH_SALE_BANNER_HEIGHT = 48;

export {
  SIDEBAR_WIDTH,
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
  SIDEBAR_BOX_SHADOW,
  AUDIO_PLAYER_HEIGHT,
  PAYMENT_FOOTER_HEIGHT,
  BOX_SHADOW,
  PAYMENT_FOOTER_SHADOW,
  BORDER_RADIUS,
  AVATAR_COLORS,
  TOOLTIP_BOX_SHADOW,
  PACKAGE_BOX_SHADOW,
  TOOLBAR_BOX_SHADOW,
  TEXT_SHADOW,
  PACKAGE_HEIGHT_EXTRA,
  MOBILE_BREAKPOINT,
  BREAKPOINTS,
  TIMELINE_HEIGHT,
  TIMELINE_COLLAPSED_HEIGHT,
  FLASH_SALE_BANNER_HEIGHT,
  TITLE_PRODUCT_HEIGHT,
};
