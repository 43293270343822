import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import yebeeImg from '@src/assets/images/Yebee.svg';
import { StyledRatingSuccess } from './index.style';

const RatingSuccess = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <StyledRatingSuccess>
      <Typography className="title">{t('thankYouSoMuch')}</Typography>
      <img src={yebeeImg} alt="thank you" className="img" />
      <Typography className="description">
        {t('thanksRatingDescription')}
      </Typography>
      <div className="button">
        <Button variant="contained" onClick={onClose} className="submit-button">
          OK
        </Button>
      </div>
    </StyledRatingSuccess>
  );
};

export default RatingSuccess;
