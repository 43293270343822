import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import PageNotFoundImg from '@src/assets/images/error/404.png';
import { StyledPageNotFound } from './index.style';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <StyledPageNotFound>
      <Typography className="title">{t('pageNotFound')}</Typography>
      <Box
        sx={{
          width: { xs: '100%', sm: '80%', lg: '50%' },
        }}
      >
        <img src={PageNotFoundImg} alt="page not found" width="100%" />
      </Box>
    </StyledPageNotFound>
  );
};

export default PageNotFound;
