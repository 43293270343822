import React, { useState } from 'react';
import mammoth from 'mammoth';
import { useTranslation } from 'react-i18next';
import { DialogContent } from '@mui/material';
import Dialog from '@src/components/Dialog';
import FileDropzone from '@src/components/FileDropzone';
import NewFileDropzone from '@src/components/NewFileDropzone';
import { TEXT_FILE_FORMAT } from '@src/constants/voice';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useSelector } from 'react-redux';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import Tesseract from 'tesseract.js';
import { franc } from 'franc-min';

// Add the workerSrc for pdfjs
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const LANGUGE_VN = 'vie';
const TYPE_FILE = {
  TEXT: 'text/plain',
  PDF: 'application/pdf',
};

const UploadFile = ({ open, onClose, onHandleUploadContent }) => {
  const { t } = useTranslation();
  const [loadingGetContentFile, setLoadingGetContentFile] = useState(false);
  let textFileFormat = TEXT_FILE_FORMAT;

  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = user;

  const useNewUpload = getFeatureValue(FEATURE_KEYS.NEW_UPLOAD, {
    userId,
    email,
    phoneNumber,
  });

  const useUploadPdf = getFeatureValue(FEATURE_KEYS.UPLOAD_FILE_PDF, {
    userId,
    email,
  });

  if (useUploadPdf) {
    textFileFormat = [...textFileFormat, 'pdf'];
  }

  function replaceMultipleSpacesWithNewline(inputString) {
    const MULTIPLE_SPACES_REGEX = / {3,}/g;
    return inputString.replace(MULTIPLE_SPACES_REGEX, '\n');
  }

  function replaceDoubleSpaces(str) {
    return str.replace(/ {2,}/g, ' ');
  }

  const checkStrMoreThanTwoSpaces = (str) => str.split(' ').length > 2;

  const getTextContentPdf = async (pages) => {
    const textContentPromises = pages.map((page) => page.getTextContent());
    const textContents = await Promise.all(textContentPromises);

    // Check srt in the first item of textContents to determine if the pdf has more than two spaces
    // If the pdf has more than two spaces, join all items in textContents without space
    // Otherwise, join all items in textContents with space
    const srtMoreThanTwoSpaces = textContents[0].items.some((item) =>
      checkStrMoreThanTwoSpaces(item.str),
    );

    const text = textContents
      .flatMap((textContent) => textContent.items)
      .map((item) => item.str)
      .join(srtMoreThanTwoSpaces ? '' : ' ');

    // Get the language of the first 100 characters
    const first100Chars = text.substring(0, 100);
    const language = franc(first100Chars);

    // If the language is Vietnamese, replace multiple spaces with newline
    if (language === LANGUGE_VN) {
      const textWithNewline = replaceMultipleSpacesWithNewline(text);
      const textReplaceDoubleSpaces = replaceDoubleSpaces(textWithNewline);
      return textReplaceDoubleSpaces;
    }

    return text;
  };

  const getTextContentPdfScanned = async (pages) => {
    const textContentScannedPromises = pages.map(async (page) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const viewport = page.getViewport({ scale: 2 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;

      const dataUrl = canvas.toDataURL();
      return Tesseract.recognize(dataUrl, LANGUGE_VN, {
        // eslint-disable-next-line
        logger: (m) => console.log(m),
      });
    });

    const textContentsScanned = await Promise.all(textContentScannedPromises);

    return textContentsScanned.map((result) => result.data.text).join(' ');
  };

  const handleAddFile = (newFile, handleReset) => {
    setLoadingGetContentFile(true);
    const reader = new FileReader();
    const fileType = newFile.type;
    // TODO: Change logic
    switch (fileType) {
      // Handle text file
      case TYPE_FILE.TEXT: {
        reader.onloadend = async () => {
          const uploadContent = reader.result;
          onHandleUploadContent(uploadContent);
          setLoadingGetContentFile(false);
          onClose();
          handleReset();
        };
        reader.readAsText(newFile);

        break;
      }
      // Handle pdf file
      case TYPE_FILE.PDF: {
        reader.onloadend = async () => {
          const arrayBuffer = reader.result;

          // Load the pdf
          const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;

          // Get the number of pages
          const numPages = await pdf.numPages;

          // Get the text content of each page
          const pagePromises = Array.from({ length: numPages }, (_, i) =>
            pdf.getPage(i + 1),
          );
          const pages = await Promise.all(pagePromises);

          let uploadContent = '';
          let isScanned = false;

          // Get the text content of each page
          uploadContent = await getTextContentPdf(pages);

          // Check if the pdf is scanned
          // If uploadContent is empty, it means the pdf is scanned
          if (uploadContent === '') {
            isScanned = true;
          }

          if (isScanned) {
            // Get the text content of each page for scanned pdf
            uploadContent = await getTextContentPdfScanned(pages);
          }
          onHandleUploadContent(uploadContent);
          setLoadingGetContentFile(false);
          onClose();
          handleReset();
        };
        reader.readAsArrayBuffer(newFile);

        break;
      }
      // Handle docx file
      default: {
        reader.onloadend = async () => {
          const arrayBuffer = reader.result;
          const { value: uploadContent } = await mammoth.extractRawText({
            arrayBuffer,
          });
          onHandleUploadContent(uploadContent);
          setLoadingGetContentFile(false);
          onClose();
          handleReset();
        };
        reader.readAsArrayBuffer(newFile);

        break;
      }
    }
  };

  if (useNewUpload) {
    return (
      <Dialog
        title={t('uploadYourFile')}
        open={open}
        fullWidth
        onClose={onClose}
      >
        <DialogContent
          dividers
          sx={{
            '&.MuiDialogContent-root': {
              paddingTop: '24px !important',
            },
          }}
        >
          <NewFileDropzone
            fileType={textFileFormat}
            onAddFile={handleAddFile}
            loadingGetContentFile={loadingGetContentFile}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      title={t('uploadYourTextFile')}
      open={open}
      maxWidth="md"
      fullWidth
      onClose={onClose}
    >
      <DialogContent>
        <FileDropzone fileType={textFileFormat} onAddFile={handleAddFile} />
      </DialogContent>
    </Dialog>
  );
};

export default UploadFile;
