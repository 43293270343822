export const qualities = [
  {
    id: 'noiseness',
    text: 'environment',
  },
  {
    id: 'loudness',
    text: 'volume',
  },
  {
    id: 'clearly',
    text: 'readClearly',
  },
  {
    id: 'speed',
    text: 'evaluateSpeed',
  },
];
