import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Table from '@src/components/Table';
import { COLOR } from '@src/styles/color';
import RequestStatus from '@src/components/RequestStatus';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { PROJECT_STATUS } from '@src/constants/dubbing';
import ProjectTitle from './ProjectTableItem/ProjectTitle';
import { StyledProjectTable } from './index.style';
import ProjectsActionHeader from './ProjectTableItem/ProjectsActionHeader';
import ProjectVoice from './ProjectTableItem/ProjectVoice';
import ProjectDuration from './ProjectTableItem/ProjectDuration';
import ProjectActions from './ProjectTableItem/ProjectActions';

const ProjectTable = ({
  projects = [],
  page,
  total,
  sort,
  loading,
  selectedProjects,
  onUpdateProject,
  onChangePage,
  onChangeSort,
  onSelectRequest,
  onDeleteSelectedRequests,
  handleOpenErrorReport,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = [
    {
      field: 'title',
      title: t('title'),
      sortable: false,
      align: 'left',
      width: 'full',
      cellWidth: 'full',
      render: (row) => (
        <ProjectTitle
          projectId={row.id}
          title={row.title}
          audioLink={row.audioLink}
          requestStatus={row.status}
          onUpdateProject={onUpdateProject}
          updatedAt={row.updatedAt}
        />
      ),
    },
    {
      field: 'voice',
      title: t('voice'),
      sortable: false,
      align: 'left',
      width: '140px',
      cellWidth: '140px',
      render: (row) => (
        <ProjectVoice
          voiceCode={row?.voiceCode}
          sentencesVoiceCode={JSON.parse(row?.sentencesVoiceCode || '{}')}
        />
      ),
    },
    {
      field: 'duration',
      title: t('duration'),
      sortable: false,
      align: 'left',
      width: '140px',
      cellWidth: '140px',
      render: (row) => (
        <ProjectDuration subtitleLink={row?.currentSubtitleLink} />
      ),
    },
    {
      field: 'audioType',
      title: t('audioType'),
      align: 'center',
      width: '100px',
      cellWidth: '100px',
      render: () => (
        <Typography fontSize="14px" fontWeight={400} color={COLOR.black[80]}>
          mp3
        </Typography>
      ),
    },
    {
      id: TTS_GUIDE.STATUS_REQUEST,
      field: 'status',
      title: t('status'),
      sortable: false,
      align: 'center',
      width: '140px',
      cellWidth: '140px',
      render: (row) => (
        <Box display="flex" width="100%" justifyContent="center">
          <RequestStatus
            request={
              row?.status === PROJECT_STATUS.IN_PROGRESS
                ? row?.requestInfo
                : row
            }
          />
        </Box>
      ),
    },
  ];

  const hoverColumns = [
    {
      id: TTS_GUIDE.ACTION,
      field: 'action',
      title: t('action'),
      sortable: false,
      align: 'center',
      width: '100px',
      cellWidth: '100px',
      render: (row) => (
        <ProjectActions
          project={row}
          onOpenErrorReport={handleOpenErrorReport}
        />
      ),
    },
  ];

  const handleRowClick = (projectId) => {
    // TODO: Access to the project detail page
    history.push(`/dubbing/projects/${projectId}`);
  };

  const renderExtraHeader = (colPosition) => (
    <ProjectsActionHeader
      selectedProjects={selectedProjects}
      onDeleteSelectedRequests={onDeleteSelectedRequests}
      colPosition={colPosition}
    />
  );

  return (
    <StyledProjectTable>
      <Table
        columns={columns}
        hoverColumns={hoverColumns}
        data={projects}
        page={page}
        total={total}
        sort={sort}
        loading={loading}
        selection
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        onRowClick={handleRowClick}
        selected={selectedProjects}
        onSelect={onSelectRequest}
        minWidth="1000px"
        footerPadding="0px 20px 0px"
        showExtraHeaderComponent={selectedProjects?.length > 0}
        renderExtraHeader={renderExtraHeader}
        tableHeight="100%"
        isStickyHeader
        limit={10}
      />
    </StyledProjectTable>
  );
};

export default ProjectTable;
