import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { ArrowForwardRounded } from '@mui/icons-material';
import { MAX_FILE_NAME_LENGTH } from '@src/constants/dubbing';
import { isValidFile } from '@src/utils/checkValid';
import IconFileWord from '@src/assets/icons/icon-file-word.png';
import IconFileText from '@src/assets/icons/icon-file-text.png';
import IconFilePdf from '@src/assets/icons/icon-file-pdf.png';
import IconFileImage from '@src/assets/icons/icon-file-image.png';
import IconClose from '@src/assets/icons/icon-close.png';
import { MEGA_BYTE, OPTION_UPLOAD_FILE } from '@src/constants/upload';
import { COLOR } from '@src/styles/color';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { useSelector } from 'react-redux';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  StyleBoxAddSuccess,
  StyleButtonSuccess,
  StyledFileDropzone,
} from './index.style';

const iconMapping = {
  '.doc': IconFileWord,
  '.docx': IconFileWord,
  '.txt': IconFileText,
  '.pdf': IconFilePdf,
  '.jpg': IconFileImage,
  '.png': IconFileImage,
};

export default function NewFileDropzone({
  fileType,
  onAddFile,
  onChangeFile,
  onChangeFileError,
  className = '',
  hasResetFile,
  onChangeHasResetFile,
  loadingGetContentFile,
}) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [hightlight, setHightlight] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [oversize, setOverSize] = useState(false);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = user;
  let optionUploadFile = OPTION_UPLOAD_FILE;

  const useUploadPdf = getFeatureValue(FEATURE_KEYS.UPLOAD_FILE_PDF, {
    userId,
    email,
    phoneNumber,
  });

  if (useUploadPdf) {
    optionUploadFile = [
      ...optionUploadFile,
      {
        icon: IconFilePdf,
        type: ['pdf'],
        isActive: true,
        alt: 'file-pdf',
      },
    ];
  }

  const isValidSize = (size) => size <= MEGA_BYTE;

  const acceptFileTypes = fileType?.map((type) => `.${type}`)?.join(',');

  const getShortFileName = (value) => {
    if (value && value?.length > MAX_FILE_NAME_LENGTH) {
      return `${value.slice(0, MAX_FILE_NAME_LENGTH - 3)}...`;
    }
    return value;
  };

  const handleReset = () => {
    setFile(null);
    setFileName(null);
    setFormatError(false);
    setOverSize(false);
    setHightlight(false);
    setErrorMessage('');
  };

  const handleChangeFileError = () => {
    if (!onChangeFileError) return;
    onChangeFileError();
  };

  const handleChangeFile = (e) => {
    const { files } = e.target;
    handleReset();
    const shortFileName = getShortFileName(files[0]?.name);
    setFileName(shortFileName);
    if (!files || files.length !== 1) return;
    if (!isValidFile(files[0].name, fileType)) {
      setFormatError(true);
      handleChangeFileError();
      return;
    }
    if (!isValidSize(files[0].size)) {
      setOverSize(true);
      handleChangeFileError();
      return;
    }
    setFile(files[0]);
  };

  const handleChangeFileState = async (fileState) => {
    if (!onChangeFile) return;
    try {
      setIsUploadingFile(true);
      await onChangeFile(fileState);
      setIsUploadingFile(false);
    } catch (err) {
      setIsUploadingFile(false);
      setErrorMessage(err.message);
      setFile(null);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setHightlight(true);
  };

  const onDragLeave = () => setHightlight(false);

  const onDrop = (e) => {
    e.preventDefault();
    handleReset();
    const { files } = e.dataTransfer;
    const shortFileName = getShortFileName(files[0]?.name);
    setFileName(shortFileName);
    if (!files || files.length !== 1) return;
    if (!isValidFile(files[0].name, fileType)) {
      setFormatError(true);
      handleChangeFileError();
      return;
    }
    if (!isValidSize(files[0].size)) {
      setOverSize(true);
      handleChangeFileError();
      return;
    }
    setFile(files[0]);
  };

  const openFileDialog = () => fileInputRef.current.click();

  const handleAddSuccessFile = () => {
    onAddFile(file, handleReset);
  };

  useEffect(() => {
    if (hasResetFile) {
      handleReset();
      onChangeHasResetFile(false);
    }
  }, [hasResetFile]);

  const ChooseOtherFileButton = () => (
    <div className="other-case">
      <label htmlFor="contained-other-file">
        <Typography
          align="center"
          variant="body1"
          className="choose-other-file"
        >
          {t('chooseOtherFile')}
        </Typography>
      </label>
    </div>
  );

  const UploadError = () => (
    <>
      <Typography variant="body1" className="success-file-name">
        {fileName}
      </Typography>
      <ChooseOtherFileButton />
      {formatError && (
        <Typography align="center" variant="subtitle2" color="error">
          {t('fileFormatError', { fileType: acceptFileTypes })}
        </Typography>
      )}
      {oversize && (
        <Typography align="center" variant="subtitle2" color="error">
          {t('oversizeError')}
        </Typography>
      )}
      {errorMessage && (
        <Typography align="center" variant="subtitle2" color="error">
          {t(errorMessage)}
        </Typography>
      )}
    </>
  );

  useEffect(() => {
    if (file) handleChangeFileState(file);
  }, [file]);

  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (!fileInput) return () => {};

    // Reset input value to fix bug when user upload same file
    const handleResetInputValue = () => {
      fileInput.value = null;
    };
    fileInput.addEventListener('click', handleResetInputValue);
    return () => {
      fileInput.removeEventListener('click', handleResetInputValue);
    };
  }, [fileInputRef]);

  const renderIconTypeFile = () => {
    const matchingEntry = Object.entries(iconMapping).find(([extension]) =>
      file?.name.includes(extension),
    );
    if (matchingEntry) {
      return matchingEntry[1];
    }
    return IconFileText; // Default icon if no match is found
  };

  if (isUploadingFile) {
    return (
      <StyledFileDropzone className={className}>
        <div className={classNames('dropzone', { hightlight })}>
          <CircularProgress />
        </div>
      </StyledFileDropzone>
    );
  }

  const boxUploadOrError = () => {
    const isError = formatError || oversize || errorMessage;
    return (
      <StyledFileDropzone className={className} isError={isError}>
        <div
          className={classNames('dropzone', { hightlight })}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          role="presentation"
          onClick={openFileDialog}
        >
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            accept={acceptFileTypes}
            onChange={handleChangeFile}
          />
          {isError ? (
            <UploadError />
          ) : (
            <div>
              <div className="wrap-title">
                <Typography
                  align="center"
                  variant="h5"
                  className="upload-file-title"
                >
                  {t('dropFile')}
                </Typography>
                <Button
                  size="small"
                  className="upload-button"
                  variant="text"
                  sx={{ color: '#007AFF' }}
                >
                  {t('chooseFile')}
                </Button>
              </div>
              <div className="wrap-file-icon">
                {optionUploadFile
                  .filter((item) => item.isActive)
                  .map((item) => (
                    <img
                      key={item.alt}
                      src={item.icon}
                      alt={item.alt}
                      className="file-icon"
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </StyledFileDropzone>
    );
  };

  const boxAddSuccess = () => (
    <StyleBoxAddSuccess>
      <div className="wrap-file">
        <img src={renderIconTypeFile()} alt="file-word" className="file-icon" />
        <Typography variant="body1" className="success-file-name">
          {fileName}
        </Typography>
        <IconButton onClick={handleReset} disabled={loadingGetContentFile}>
          <img src={IconClose} alt="file-word" className="clear-icon" />
        </IconButton>
      </div>
    </StyleBoxAddSuccess>
  );

  return (
    <>
      {file === null ? boxUploadOrError() : boxAddSuccess()}
      <StyleButtonSuccess>
        <Button
          variant="contained"
          color="primary"
          className="btn-continue"
          endIcon={
            loadingGetContentFile ? (
              <CircularProgress size={24} className="btn-loading" />
            ) : (
              <ArrowForwardRounded />
            )
          }
          onClick={handleAddSuccessFile}
          sx={{
            '&.Mui-disabled': {
              background: COLOR.yellow[32],
              color: '#3c164e99',
            },
          }}
          disabled={!file || loadingGetContentFile}
        >
          {t('continue')}
        </Button>
      </StyleButtonSuccess>
    </>
  );
}
