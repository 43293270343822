import { actionTypes } from './actions';

export const initialState = {};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SYNTHESIS_RESPONSE: {
      const { requestId, audioLink, status, progress, processingAt, endedAt } =
        action;
      return {
        ...state,
        [requestId]: {
          audioLink,
          status,
          progress,
          processingAt: processingAt || state[requestId]?.processingAt,
          endedAt: endedAt || state[requestId]?.endedAt,
        },
      };
    }
    default:
      return state;
  }
};
export default requestReducer;
