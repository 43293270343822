import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';

import { StyledCountdownBanner } from './index.style';

const CountdownBanner = ({ date, isHeaderBanner }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return <span />;

    return (
      <div className="countdown">
        {days > 0 && <span className="value">{zeroPad(days)}:</span>}
        {(days > 0 || hours > 0) && (
          <span className="value">{zeroPad(hours)}:</span>
        )}
        <span className="value">{zeroPad(minutes)}:</span>
        <span className="value">{zeroPad(seconds)}</span>
      </div>
    );
  };

  return (
    <StyledCountdownBanner isHeaderBanner={isHeaderBanner}>
      <Countdown date={date} renderer={renderer} />
    </StyledCountdownBanner>
  );
};

export default CountdownBanner;
