import { PauseOutlined, PlayArrowRounded } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Status from './Status';

const BUTTON_STATUS = {
  LOADING: 'loading',
  PROCESSED: 'processed',
  PLAYING: 'playing',
  PAUSE: 'pause',
  CREATE: 'create',
};

const PlayButton = ({
  buttonStatus,
  isPlaying,
  request,
  onClickPlayButton,
}) => {
  const { t } = useTranslation();

  const renderPlayButton = () => {
    switch (buttonStatus) {
      case BUTTON_STATUS.LOADING:
        return <Status request={request} />;

      default:
        return (
          <Button
            onClick={onClickPlayButton}
            className="audio-action-button"
            color="primary"
            variant="contained"
          >
            {isPlaying ? (
              <Tooltip arrow title={t('pause')} placement="top">
                <PauseOutlined sx={{ fontSize: 26 }} color="iconPrimary" />
              </Tooltip>
            ) : (
              <Tooltip arrow title={t('playAudioGuide')} placement="top">
                <PlayArrowRounded sx={{ fontSize: 26 }} color="iconPrimary" />
              </Tooltip>
            )}
          </Button>
        );
    }
  };

  return renderPlayButton();
};

export default PlayButton;
