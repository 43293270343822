import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

const PopoverUploadButton = styled('div')`
  width: ${(props) => props.width || 'auto'};

  .custom-button {
    width: fit-content;
    border-radius: 12px;
    padding: 4px 6px;
  }

  .title-button {
    padding-left: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.4px;
    color: ${COLOR.indigo[80]};
  }

  .arrow-icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    color: ${COLOR.indigo[80]};
  }

  .toolbar-text {
    text-transform: lowercase;
  }
`;

const PopoverUploadMenuItem = styled(MenuItem)`
  padding: 12px;
  cursor: pointer;
  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};
  display: flex;
  gap: 10px;
  border-radius: 8px;

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }
  .wrap-label {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${COLOR.secondary[100]};
  }
  .button-img {
    width: 24px;
    height: 24px;
  }
`;

export { PopoverUploadButton, PopoverUploadMenuItem };
