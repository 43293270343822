import {
  BREAK_TIME_REGEX,
  GLOBAL_VOICE_FEATURES,
  VIETNAM_VOICE_FEATURES,
} from '@src/constants/voice';

const removeEmptyLineOfText = (text) => {
  const EMPTY_LINE = /^\s*\n/gm;
  const stringRemoveEmptyLine = text.trim().replace(EMPTY_LINE, '');
  return stringRemoveEmptyLine;
};

const handleDownload = async (url) => {
  // TODO: pass name variable
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const countTextLengthOfSentences = (sentences) => {
  const contentLength = sentences.reduce(
    (sum, item) => (item.text ? sum + item.text.trim().length : sum),
    0,
  );
  return contentLength;
};

const countTextLength = (text, regex) => {
  const checkRegex = regex || BREAK_TIME_REGEX;
  const normalizeText = text.trim().replace(checkRegex, '');
  const textLength = normalizeText.length;
  return textLength;
};

const checkFeaturePermission = (userFeatures = [], feature = '') => {
  let migrateUserFeatures = userFeatures;
  if (migrateUserFeatures.includes(GLOBAL_VOICE_FEATURES.GLOBAL))
    migrateUserFeatures = [
      ...migrateUserFeatures,
      ...Object.values(GLOBAL_VOICE_FEATURES),
    ];

  if (migrateUserFeatures.includes(VIETNAM_VOICE_FEATURES.BASIC))
    migrateUserFeatures = [
      ...migrateUserFeatures,
      VIETNAM_VOICE_FEATURES.STANDARD,
    ];

  if (migrateUserFeatures.includes(VIETNAM_VOICE_FEATURES.PRO))
    migrateUserFeatures = [
      ...migrateUserFeatures,
      VIETNAM_VOICE_FEATURES.PREMIUM,
    ];

  return migrateUserFeatures.includes(feature);
};

const checkValidXml = (xml) => {
  const oParser = new DOMParser();
  const oDOM = oParser.parseFromString(xml, 'application/xml');
  const isInvalidXML =
    oDOM.documentElement.getElementsByTagName('parsererror').length;
  return !isInvalidXML;
};

export {
  handleDownload,
  countTextLengthOfSentences,
  countTextLength,
  checkFeaturePermission,
  checkValidXml,
  removeEmptyLineOfText,
};
