import { REQUEST_STATUS } from '@src/constants/voice';
import React from 'react';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import SuccessDownloadButton from '../ActionDownloadButton/SuccessDownloadButton';

const ActionDownloadButtons = ({ request, audioRef }) => {
  const { displayStatus } = useRequestAnimationStatus(request);

  if (!request.id) return <></>;

  switch (displayStatus) {
    case REQUEST_STATUS.SUCCESS:
      // TODO
      return <SuccessDownloadButton request={request} audioRef={audioRef} />;

    default:
      return <div />;
  }
};

export default ActionDownloadButtons;
