import React, { useState } from 'react';
import { Clear } from '@mui/icons-material';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledImagePreview } from './index.style';

const PreviewImage = ({ image, onDelete }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  const handleDelete = () => {
    onDelete(image);
  };

  return (
    <StyledImagePreview
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img src={image} className="preview-image" alt="banner" />
      {isHover && (
        <div
          onClick={handleDelete}
          role="button"
          tabIndex="0"
          className="delete-image"
        >
          <Clear className="delete-icon" />
          <Typography>{t('delete')}</Typography>
        </div>
      )}
    </StyledImagePreview>
  );
};

export default PreviewImage;
