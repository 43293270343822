import React from 'react';
import useCountdownTimer from '@src/hooks/useCountdownTimer';
import { useTranslation } from 'react-i18next';
import PopularIcon from '@src/assets/icons/popular.svg';
import { Typography } from '@mui/material';

const timeArr = [
  { type: 'days', label: 'day' },
  { type: 'hours', label: 'hour' },
  { type: 'minutes', label: 'minute' },
  { type: 'seconds', label: 'second' },
];

const addLeadingZeros = (value) => (value < 10 ? `0${value}` : value);

const NewCountdownBanner = ({ endAt }) => {
  const { t } = useTranslation();
  const timeLeft = useCountdownTimer(endAt);
  return (
    <div className="countdown-banner">
      <img src={PopularIcon} alt="most-popular" />
      {timeArr.map((ta, index) => (
        <>
          <div className="time-block">
            <Typography className="time">
              {addLeadingZeros(timeLeft[ta.type])}
            </Typography>
            <Typography className="label">{t(ta.label)}</Typography>
          </div>
          {index !== timeArr.length - 1 && (
            <Typography className="time transform-up">:</Typography>
          )}
        </>
      ))}
      <img src={PopularIcon} alt="most-popular" />
    </div>
  );
};

export default NewCountdownBanner;
