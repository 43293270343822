import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { extractVoiceName } from '@src/services/voice';
import BadgeAvatar from '@src/components/BadgeAvatar';

const renderVoiceInfo = () => {
  const { previewRequestInfo } = useSelector((state) => state.audioPlayer);
  const { languages } = useSelector((state) => state.language);
  const { voice, title } = previewRequestInfo;

  const { voiceName } = extractVoiceName({
    name: voice?.name,
    provider: voice?.provider,
  });
  const smallImgSrc = languages.find(
    (lang) => lang?.value === voice?.languageCode,
  )?.roundImage;

  return (
    <>
      <Box
        display={{ md: 'flex', xs: 'none' }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <div className="voice">
          <BadgeAvatar
            width={38}
            smallImgWidth={18}
            img={voice?.roundImage}
            smallImg={smallImgSrc}
            type="image"
          />
          <Box className="voice-info">
            {title && (
              <Typography className="title" noWrap>
                {title}
              </Typography>
            )}
            <Typography className="voice-name">{voiceName}</Typography>
          </Box>
        </div>
      </Box>
      <Box
        display={{ md: 'none', xs: 'flex' }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <BadgeAvatar
          width={32}
          smallImgWidth={16}
          img={voice?.roundImage}
          smallImg={smallImgSrc}
          type="image"
        />
      </Box>
    </>
  );
};

export default renderVoiceInfo;
