const ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,

  USER_NOT_FOUND: 1001,
  WRONG_PASSWORD: 1002,
  USER_EXISTS: 1003,
  USER_NOT_ACTIVATED: 1004,
  INVALID_SYNTAX: 1006,

  PAYMENT_HUB_ERROR: 2001,
  PENDING_ORDER: 2002,
  ADMIN_ORDER: 2004,

  USED_VOUCHER: 3003,
  INVALID_VOUCHER: 3004,
  OUT_OF_VOUCHERS: 3005,

  NO_ENOUGH_CONDITION_SUBMIT: 4005,
  VOICE_USERNAME_INVALID: 4006,
};

export { ERROR_CODE };
