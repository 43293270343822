import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import apis from '@src/apis';
import { extractVoiceName } from '@src/services/voice';
import BadgeAvatar from '@src/components/BadgeAvatar';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { Typography } from '@mui/material';
import { StyledProjectVoice } from './index.style';
import StackedVoiceAvatars from './StackedVoiceAvatars';

const ProjectVoice = ({ voiceCode, sentencesVoiceCode }) => {
  const { t } = useTranslation();

  // State
  const [voice, setVoice] = useState({});
  const [multipleVoices, setMultipleVoices] = useState([]);

  // Redux
  const { user } = useSelector((state) => state.auth);
  const { languages } = useSelector((state) => state.language);
  const { dubbingVoices } = useSelector((state) => state.voice);

  const { getFeatureValue } = useFeatureFlags();

  const isMultipleVoices = getFeatureValue(
    FEATURE_KEYS.DUBBING_MULTIPLE_VOICES,
    {
      userId: user?.id,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
    },
  );

  const { voiceName, voiceType } = extractVoiceName({
    name: voice?.name,
    provider: voice?.provider,
  });
  const smallImgSrc = languages.find(
    (lang) => lang?.value === voice?.languageCode,
  )?.roundImage;

  const fetchVoiceData = async () => {
    const response = await apis.voices.getVoices({
      code: voiceCode,
    });

    setVoice(response?.result?.voices[0]);
  };

  const getSentencesVoiceCodeInfo = () => {
    const checkSentencesVoiceCodeEmpty =
      !sentencesVoiceCode || Object.keys(sentencesVoiceCode || {}).length === 0;

    if (checkSentencesVoiceCodeEmpty || !isMultipleVoices) return;

    const sortedVoiceCodes = Object.keys(sentencesVoiceCode || {}).sort(
      (a, b) => sentencesVoiceCode[b].length - sentencesVoiceCode[a].length,
    );

    const voicesInformation = sortedVoiceCodes.map((code) =>
      dubbingVoices.find((dubbingVoice) => dubbingVoice.code === code),
    );

    setMultipleVoices(voicesInformation);

    const voiceInformation = dubbingVoices.find(
      (dubbingVoice) => dubbingVoice.code === voicesInformation[0]?.code,
    );
    setVoice(voiceInformation);
  };

  useEffect(() => {
    if (!voiceCode) return;
    if (dubbingVoices.length === 0) {
      fetchVoiceData();
      return;
    }

    const voiceInformation = dubbingVoices.find(
      (dubbingVoice) => dubbingVoice.code === voiceCode,
    );
    setVoice(voiceInformation);
  }, [voiceCode, dubbingVoices]);

  useEffect(() => {
    getSentencesVoiceCodeInfo();
  }, [sentencesVoiceCode]);

  const MultipleVoicesAvatar = () => (
    <StackedVoiceAvatars voices={multipleVoices} />
  );

  const SingleVoiceAvatar = () => (
    <>
      <BadgeAvatar
        width={30}
        img={voice?.roundImage}
        type="image"
        smallImgWidth={10}
        smallImg={smallImgSrc}
      />
      <div>
        <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
          {voiceName}
        </Typography>
        {(voice?.styles?.length || voiceType) && (
          <Typography variant="body2" className="voice-style">
            {voiceType || t(voice.styles[0])}
          </Typography>
        )}
      </div>
    </>
  );

  return (
    <StyledProjectVoice>
      {isMultipleVoices && Object.keys(sentencesVoiceCode || {}).length > 1 && (
        <MultipleVoicesAvatar />
      )}

      {(!isMultipleVoices ||
        Object.keys(sentencesVoiceCode || {}).length <= 1) &&
        Object.keys(voice).length > 0 && <SingleVoiceAvatar />}

      {(!isMultipleVoices ||
        Object.keys(sentencesVoiceCode || {}).length <= 1) &&
        Object.keys(voice).length === 0 && (
          <Typography
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            -
          </Typography>
        )}
    </StyledProjectVoice>
  );
};

export default ProjectVoice;
