import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleRounded } from '@mui/icons-material';

import { SCREEN_TYPE } from '@src/constants';
import { REQUEST_STATUS } from '@src/constants/voice';
import CircularProcess from '@src/components/CircularProcess';

import { StyledChip } from '@src/containers/TTS/Requests/index.style';
import { StyledLinearStatus } from './index.style';

const END_POINT = 99;
const SWAP_POINT = 50;
const TIME_LOAD_THE_REST_WHEN_SUCCESS = 2000; // 2 seconds

const LinearStatus = ({
  color,
  progressConvert,
  time,
  startTime,
  requestStatus,
  type,
}) => {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const isInProgressRequest = progressConvert > 0 || !!startTime;
  const timeProcess = useRef(time);
  const currentProgress = useRef(0);
  const finishTime = useRef(0);
  const status = useRef(requestStatus);
  const [doneConvert, setDoneConvert] = useState(
    requestStatus === REQUEST_STATUS.SUCCESS,
  );

  useEffect(() => {
    if (requestStatus === REQUEST_STATUS.SUCCESS) {
      setDoneConvert(true);
      return null;
    }

    setDoneConvert(false);
    status.current = REQUEST_STATUS.IN_PROGRESS;
    return null;
  }, [requestStatus]);

  useEffect(() => {
    if (status.current === REQUEST_STATUS.IN_PROGRESS && progressConvert <= 0)
      return;

    const updateProgress = () => {
      if (status.current === REQUEST_STATUS.IN_PROGRESS) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - new Date(startTime).getTime();
        if (elapsedTime < timeProcess.current) {
          const newProgress = (elapsedTime / timeProcess.current) * END_POINT;
          if (newProgress > 0) {
            setProgress(newProgress);
            currentProgress.current = newProgress;
            finishTime.current = currentTime;
          }

          requestAnimationFrame(updateProgress);
        } else setProgress(END_POINT);
      } else {
        const currentTime = Date.now();
        const elapsedTime = currentTime - finishTime.current;

        if (elapsedTime < TIME_LOAD_THE_REST_WHEN_SUCCESS) {
          const newProgress =
            currentProgress.current +
            (elapsedTime / TIME_LOAD_THE_REST_WHEN_SUCCESS) *
              (END_POINT - currentProgress.current);
          if (newProgress > 0) setProgress(newProgress);
          requestAnimationFrame(updateProgress);
        }
      }
    };
    // Start the animation
    updateProgress();
  }, [isInProgressRequest, startTime, timeProcess.current, requestStatus]);

  useEffect(() => {
    timeProcess.current = time;
    status.current = requestStatus;
  }, [time, startTime]);

  if (type === SCREEN_TYPE.MOBILE && doneConvert) {
    return <CheckCircleRounded color="success" />;
  }

  if (type === SCREEN_TYPE.MOBILE && !doneConvert) {
    return <CircularProcess value={progress} size={28} thickness={6} />;
  }

  return doneConvert ? (
    <StyledChip color="success">{t(REQUEST_STATUS.SUCCESS)}</StyledChip>
  ) : (
    <StyledLinearStatus
      color={color}
      progress={Math.max(progress, END_POINT - progress)}
      toright={progress > SWAP_POINT}
    >
      {`${Math.round(progress)}%`}
    </StyledLinearStatus>
  );
};

export default LinearStatus;
