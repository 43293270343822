import {
  convertFromRaw,
  convertToRaw,
  SelectionState,
  Modifier,
} from 'draft-js';
import {
  TTS_ATTRIBUTE,
  BREAK_TIME_REGEX,
  BREAK_LINE,
} from '@src/constants/voice';
import { v4 as uuidv4 } from 'uuid';
import { findWithRegexEditor } from '@src/services/editor';
import { BREAK_LINE_DELIMITER } from '@src/constants';
import {
  checkSyntaxTagsElements,
  convertElementsToText,
} from '@src/services/entity';

const BREAK_TIME_UNIT_LENGTH = 1;
const BREAK_TIME_LENGTH = 15;

const convertParagraphsToState = (paragraphs = []) => {
  const rawContent = { blocks: [], entityMap: {} };

  paragraphs.forEach((paragraph) => {
    const { elements, text, key } = paragraph;

    const newBlocks = {
      key,
      text,
      type: 'unstyled',
      entityRanges: [],
    };

    const elementsHasStyle = elements.filter((item) => item.name);

    elementsHasStyle.forEach((param) => {
      const { startOffset, endOffset, name, value } = param;
      const style = `${name}-${value}`;

      newBlocks.entityRanges.push({
        offset: startOffset,
        length: endOffset - startOffset,
        key: style,
      });

      // Add an element in entityMap if value dont exists yet
      const entityMapExist = Object.keys(rawContent.entityMap).findIndex(
        (item) => item === style,
      );

      if (entityMapExist === -1) {
        rawContent.entityMap[style] = {};
        rawContent.entityMap[style].data = {
          name,
          value,
        };
        rawContent.entityMap[style].type = 'TOKEN';
        rawContent.entityMap[style].mutability = 'MUTABLE';
      }
    });
    rawContent.blocks.push(newBlocks);
  });

  return convertFromRaw(rawContent);
};

const getElement = (
  text,
  startOffset,
  endOffset,
  name = null,
  value = null,
) => ({
  key: uuidv4(),
  name,
  value,
  startOffset,
  endOffset,
  text: text.substring(startOffset, endOffset),
});

const handleEditParagraphOfSingleBlock = (block, entityMap) => {
  const { entityRanges, key, text } = block;
  let startIndex = 0;
  let endIndex = 0;
  const elements = [];

  if (entityRanges.length !== 0) {
    entityRanges.forEach((entity) => {
      if (startIndex < entity.offset) {
        const element = getElement(text, startIndex, entity.offset);
        elements.push(element);
        startIndex = entity.offset;
        endIndex = entity.offset;
      }

      const element = {
        key: uuidv4(),
        endOffset: entity.offset + entity.length,
        startOffset: entity.offset,
        name: entityMap[entity.key].data.name,
        value: entityMap[entity.key].data.value,
        text: text.substring(entity.offset, entity.offset + entity.length),
      };

      elements.push(element);
      startIndex = entity.offset + entity.length;
      endIndex = entity.offset + entity.length;
    });

    if (endIndex !== text.length) {
      const element = getElement(text, endIndex, text.length);
      elements.push(element);
    }
  } else {
    const data = getElement(text, 0, text.length);
    elements.push(data);
  }
  return { key, text, elements };
};

const handleEditParagraphs = (paragraphs = [], editorState) => {
  const newParagraphs = [...paragraphs];

  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const { entityMap } = convertToRaw(currentContent);

  const blockMaps = convertToRaw(currentContent).blocks;
  const blockIndex = blockMaps.findIndex((item) => item.key === anchorKey);
  if (blockIndex === -1) return null;

  // In case of adding or removing a block, the paragraph will change
  if (blockMaps.length !== newParagraphs.length) {
    const paragraphArray = [];

    blockMaps.forEach((block) => {
      const paragraphExist = newParagraphs.find(
        (item) => item.key === block.key,
      );
      if (paragraphExist && paragraphExist.text === block.text) {
        // paragraph already exists, push to the array
        paragraphArray.push(paragraphExist);
      } else {
        // paragraph dont exists, create new paragraph with information of block
        const newParagraph = handleEditParagraphOfSingleBlock(block, entityMap);
        paragraphArray.push(newParagraph);
      }
    });

    return paragraphArray;
  }

  // In case of add text in block
  const currentBlock = blockMaps[blockIndex];
  const { elements, text } = handleEditParagraphOfSingleBlock(
    currentBlock,
    entityMap,
  );
  // update current paragraph
  newParagraphs[blockIndex].elements = elements;
  newParagraphs[blockIndex].text = text;

  return newParagraphs;
};

const getPreviewParagraph = (start, end, text, paragraph) => {
  let offset = 0;
  const previewParagraph = paragraph.elements.reduce((preview, element) => {
    const { startOffset, endOffset, name, value } = element;
    if (startOffset <= start && endOffset > start) {
      const endPosition = end < endOffset ? end : endOffset;
      const elementText = text.slice(start, endPosition);
      const curr = {
        name,
        value,
        text: elementText,
        startOffset: offset,
        endOffset: offset + elementText.length,
      };

      offset += elementText.length;
      return [...preview, curr];
    }
    if (startOffset >= start && startOffset < end) {
      const endPosition = end < endOffset ? end : endOffset;
      const elementText = text.slice(startOffset, endPosition);

      const curr = {
        name,
        value,
        text: text.slice(startOffset, endPosition),
        startOffset: offset,
        endOffset: offset + elementText.length,
      };
      offset += elementText.length;
      return [...preview, curr];
    }
    return preview;
  }, []);

  return { elements: previewParagraph, text: text.slice(start, end) };
};

const handleSelectedParagraph = (selectionState, contentState, paragraphs) => {
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blocks = contentState.getBlockMap();
  let lastWasEnd = false;

  const selectedBlock = blocks
    .skipUntil((block) => block?.getKey() === startKey)
    .takeUntil((block) => {
      const result = lastWasEnd;
      if (block?.getKey() === endKey) lastWasEnd = true;
      return result;
    });

  const selectedParagraph = [];
  let previewParagraphs = [];

  // eslint-disable-next-line array-callback-return
  selectedBlock.map((block) => {
    const key = block?.getKey() || '';
    const currentParagraph = paragraphs.find((item) => item.key === key);
    if (!currentParagraph) return;
    const text = block?.getText() || '';
    let startOffset = key === startKey ? selectionState.getStartOffset() : 0;
    const endOffset =
      key === endKey ? selectionState.getEndOffset() : text.length;

    // Check case of choose break time at the start of selected block
    const firstSelectedEle =
      currentParagraph.elements?.length && currentParagraph.elements[0];
    if (
      firstSelectedEle &&
      firstSelectedEle?.name === TTS_ATTRIBUTE.BREAK_TIME &&
      startOffset === 0
    ) {
      const breakTimeValue = `${firstSelectedEle.value}s`;
      startOffset += breakTimeValue.length;
    }

    if (text) selectedParagraph.push({ blockKey: key, startOffset, endOffset });

    const previewParagraph = getPreviewParagraph(
      startOffset,
      endOffset,
      text,
      currentParagraph,
    );
    previewParagraphs = [...previewParagraphs, previewParagraph];
  });

  return { previewParagraphs, selectedParagraph };
};

const getPositionSelectedElement = ({ elements, startOffset, endOffset }) => {
  let replaceIndex = null;
  let newStartOffset = null;
  let newEndOffset = null;

  let withinElements = [];

  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];

    const hasChangeElement =
      (element.startOffset < endOffset && element.endOffset > endOffset) ||
      (element.startOffset < startOffset && element.endOffset > startOffset);

    const isWithinSelectedElement =
      element.startOffset >= startOffset && element.endOffset <= endOffset;

    if (hasChangeElement) {
      if (replaceIndex == null) replaceIndex = i;
      if (newStartOffset == null) newStartOffset = element.startOffset;
      newEndOffset = element.endOffset;
      elements.splice(i, 1);
      i -= 1;
    } else if (isWithinSelectedElement) {
      if (!withinElements.length) replaceIndex = i;
      withinElements = [...withinElements, element];
    }
  }

  return { replaceIndex, newStartOffset, newEndOffset, withinElements };
};

const getElementsWithinSelected = ({
  withinElements,
  startOffset,
  endOffset,
  text,
  elementName,
  elementValue,
}) => {
  // get breakTime elements within selection elements
  const breakTimeElements = withinElements.filter(
    (item) => item.name === TTS_ATTRIBUTE.BREAK_TIME,
  );

  if (!breakTimeElements.length)
    return [
      {
        key: uuidv4(),
        startOffset,
        endOffset,
        text: text.substring(startOffset, endOffset),
        name: elementName,
        value: elementValue,
      },
    ];

  let flag = startOffset;
  const elements = breakTimeElements.reduce((acc, currElement, index) => {
    const { startOffset: start, endOffset: end } = currElement;
    if (flag === start) {
      flag = end;
      return [...acc, currElement];
    }

    const prevElement = {
      key: uuidv4(),
      startOffset: flag,
      endOffset: start,
      text: text.substring(flag, start),
      name: elementName,
      value: elementValue,
    };
    flag = end;

    if (index === breakTimeElements.length - 1 && end < endOffset) {
      const nextElement = {
        key: uuidv4(),
        startOffset: end,
        endOffset,
        text: text.substring(end, endOffset),
        name: elementName,
        value: elementValue,
      };
      return [...acc, prevElement, currElement, nextElement];
    }

    return [...acc, prevElement, currElement];
  }, []);

  return elements;
};

const handleSingleSelectedParagraph = ({
  currentParagraph,
  startOffset,
  endOffset,
  elementName,
  elementValue,
}) => {
  const { elements, text } = currentParagraph;

  // replaceIndex: The position of the element begins to change
  // newStartOffset: The starting position of the element has changed
  // newEndOffset: The ending position of the element has changed
  // withinElements: Elements inside the selected element

  // eslint-disable-next-line prefer-const
  let { replaceIndex, newStartOffset, newEndOffset, withinElements } =
    getPositionSelectedElement({
      elements,
      startOffset,
      endOffset,
    });

  // change prev element
  if (newStartOffset !== null && newStartOffset !== startOffset) {
    const space = getElement(text, newStartOffset, startOffset);
    elements.splice(replaceIndex, 0, space);
    replaceIndex += 1;
  }

  // change current element
  if (startOffset !== endOffset) {
    if (withinElements.length) {
      const newSubElements = getElementsWithinSelected({
        withinElements,
        startOffset,
        endOffset,
        text,
        elementName,
        elementValue,
      });

      elements.splice(replaceIndex, withinElements.length, ...newSubElements);
      replaceIndex += newSubElements.length;
    } else {
      const currElement = {
        key: uuidv4(),
        startOffset,
        endOffset,
        text: text.substring(startOffset, endOffset),
        name: elementName,
        value: elementValue,
      };
      elements.splice(replaceIndex, 0, currElement);
      replaceIndex += 1;
    }
  }

  // change next element
  if (newEndOffset !== null && endOffset !== newEndOffset) {
    const space = getElement(text, endOffset, newEndOffset);
    elements.splice(replaceIndex, 0, space);
  }

  return { ...currentParagraph, elements };
};

const handleSelectedElement = ({
  paragraphs,
  selectedParagraph,
  elementName,
  elementValue,
}) => {
  const newParagraphs = [...paragraphs];

  // eslint-disable-next-line array-callback-return
  selectedParagraph.map((elem) => {
    const { blockKey, startOffset, endOffset } = elem;

    const paragraphIndex = newParagraphs.findIndex(
      (item) => item.key === blockKey,
    );

    if (paragraphIndex === -1) return;
    const currentParagraph = newParagraphs[paragraphIndex];
    const newParagraph = handleSingleSelectedParagraph({
      currentParagraph,
      startOffset,
      endOffset,
      elementName,
      elementValue,
    });

    newParagraphs[paragraphIndex] = newParagraph;
  });

  return newParagraphs;
};

const handleInsertElement = ({
  paragraphs,
  editorState,
  elementName,
  elementValue,
}) => {
  const newParagraphs = [...paragraphs];

  const selectionState = editorState.getSelection();
  const focusOffset = selectionState.getFocusOffset();
  const focusKey = selectionState.getFocusKey();
  if (typeof focusOffset !== 'number') return null;

  const paragraphIndex = newParagraphs.findIndex(
    (item) => item.key === focusKey,
  );
  if (paragraphIndex === -1) return null;
  const currentParagraph = newParagraphs[paragraphIndex];

  const insertedTextLength = `${elementValue}s`.length;
  const insertedElement = {
    key: uuidv4(),
    name: elementName,
    value: elementValue,
    text: `${elementValue}s`,
    startOffset: focusOffset,
    endOffset: focusOffset + insertedTextLength,
  };

  const { elements, text: textParagraph } = currentParagraph;
  const newElements = [];

  const newTextParagraph =
    `${textParagraph.slice(0, focusOffset)}` +
    `${elementValue}s` +
    `${textParagraph.slice(focusOffset, textParagraph.length)}`;

  let delta = 0;
  let isAddedBreakTime = false;
  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    const { startOffset, endOffset, name, value } = element;

    if (startOffset === focusOffset) {
      if (!isAddedBreakTime) {
        newElements.push(insertedElement);
        delta += insertedTextLength;
        isAddedBreakTime = true;
      }
      if (endOffset !== 0) {
        const newElement = {
          ...element,
          startOffset: startOffset + delta,
          endOffset: endOffset + delta,
        };
        newElements.push(newElement);
      }
    }

    if (startOffset < focusOffset && endOffset > focusOffset) {
      const firstElement = getElement(
        newTextParagraph,
        startOffset,
        focusOffset,
        name,
        value,
      );

      newElements.push(firstElement);
      newElements.push(insertedElement);

      delta += insertedTextLength;

      const secondElement = getElement(
        newTextParagraph,
        focusOffset + delta,
        endOffset + delta,
        name,
        value,
      );
      newElements.push(secondElement);
    }

    if (endOffset === focusOffset && endOffset !== 0) {
      newElements.push(element);
      if (!isAddedBreakTime) {
        delta += insertedTextLength;
        newElements.push(insertedElement);
        isAddedBreakTime = true;
      }
    }

    if (startOffset > focusOffset || endOffset < focusOffset) {
      const newElement = {
        ...element,
        startOffset: startOffset + delta,
        endOffset: endOffset + delta,
      };
      newElements.push(newElement);
    }
  }

  newParagraphs[paragraphIndex] = {
    ...currentParagraph,
    elements: newElements,
    text: newTextParagraph,
  };

  return newParagraphs;
};

const convertParagraphsToText = (paragraphs = []) => {
  const text = paragraphs.reduce((prev, paragraph) => {
    const textElement = convertElementsToText(paragraph.elements || []);
    return prev ? `${prev}${BREAK_LINE}${textElement}` : textElement;
  }, '');

  return text;
};

const checkSyntaxTagsParagraphs = (paragraphs = []) => {
  const needCheckSyntaxElements = paragraphs.reduce((acc, cur) => {
    const { elements } = cur;
    if (!elements?.length) return acc;

    return [...acc, ...elements.filter((item) => !!item.name)];
  }, []);

  const { error, detailError } = checkSyntaxTagsElements(
    needCheckSyntaxElements,
  );

  return { error, detailError };
};

const countBreakTimeLength = (paragraphs) =>
  paragraphs.reduce((prev, paragraph) => {
    const { elements } = paragraph;
    const breakTimeElements = elements.filter(
      (item) => item.name === TTS_ATTRIBUTE.BREAK_TIME,
    );
    if (!breakTimeElements?.length) return prev;
    const breakTimeLength = breakTimeElements.reduce((acc, curr) => {
      const length = curr.text ? curr.text.length : 0;
      return acc + length;
    }, 0);

    return prev + breakTimeLength;
  }, 0);

const countEditorStateLength = (editorState, paragraphs = []) => {
  const editorStateText = editorState.getCurrentContent().getPlainText('\n');
  const breakTimeLength = countBreakTimeLength(paragraphs);
  const textLength = editorStateText.trim().length - breakTimeLength;
  return textLength;
};

const handleReplace = ({ search, replace, editorState }) => {
  if (!search || !replace) return null;
  const regex = new RegExp(search, 'gi');
  const selectionsToReplace = [];
  const blockMap = editorState.getCurrentContent().getBlockMap();

  const delta = replace.length - search.length;
  let blockIndex = 0;
  let currentBlockKey = '';

  blockMap.forEach((contentBlock) =>
    findWithRegexEditor(regex, contentBlock, (start, end) => {
      const blockKey = contentBlock.getKey();
      if (currentBlockKey !== blockKey) {
        blockIndex = 0;
        currentBlockKey = blockKey;
      }
      const blockSelection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start + blockIndex * delta,
        focusOffset: end + blockIndex * delta,
      });

      selectionsToReplace.push(blockSelection);
      blockIndex += 1;
    }),
  );

  let contentState = editorState.getCurrentContent();

  selectionsToReplace.forEach((selectionState) => {
    contentState = Modifier.replaceText(contentState, selectionState, replace);
  });

  return contentState;
};

const getAroundElements = (elements, index) => {
  let currElement = elements[index];

  if (currElement.name === TTS_ATTRIBUTE.BREAK_TIME) {
    currElement = undefined;
  } else {
    currElement = { ...elements[index], name: null, value: null };
  }

  const beforeElement = elements[index - 1];
  const afterElement = elements[index + 1];

  return [beforeElement, currElement, afterElement].filter(
    (item) => item !== undefined,
  );
};

const removeElementInBlock = (block, start, end) => {
  const { elements } = block;
  const eleIndex = elements.findIndex(
    (item) => item.startOffset === start && item.endOffset === end,
  );
  if (!eleIndex === -1) return null;

  const aroundElements = getAroundElements(elements, eleIndex);

  const currentElement = elements[eleIndex];
  const { name } = currentElement;
  let offset = eleIndex > 1 ? elements[eleIndex - 2].endOffset : 0;

  const customElements = aroundElements.reduce((acc, curr) => {
    const lastIndex = acc.length - 1;
    const prev = acc[lastIndex];

    if (!acc.length || prev?.name !== curr?.name) {
      const { text: currText } = curr;

      const currElement = {
        ...curr,
        startOffset: offset,
        endOffset: offset + currText.length,
      };
      offset += currText.length;

      return [...acc, currElement];
    }

    const { text: currText } = curr;
    const { text: prevText } = prev;

    const text = `${prevText}${currText}`;

    acc[lastIndex] = {
      ...prev,
      startOffset: offset - prevText.length,
      endOffset: offset + currText.length,
      text,
    };

    offset += currText.length;
    return acc;
  }, []);

  let afterElements = [];
  if (name === TTS_ATTRIBUTE.BREAK_TIME) {
    afterElements = elements.slice(eleIndex + 2).map((element) => {
      const { text } = element;
      const afterElement = {
        ...element,
        startOffset: offset,
        endOffset: offset + text.length,
      };

      offset += text.length;
      return afterElement;
    });
  } else {
    afterElements = elements.slice(eleIndex + 2);
  }

  let newElements = [];

  if (eleIndex === 0) {
    newElements = [...customElements, ...afterElements];
  } else {
    newElements = [
      ...elements.slice(0, eleIndex - 1),
      ...customElements,
      ...afterElements,
    ];
  }

  const text = newElements.map((item) => `${item.text}`).join('');

  return { ...block, elements: newElements, text };
};

const removeElementInParagraphs = ({ paragraphs, blockKey, start, end }) => {
  const blockIndex = paragraphs.findIndex((item) => item.key === blockKey);
  if (blockIndex === -1) return null;

  const currentBlock = paragraphs[blockIndex];
  const newBlock = removeElementInBlock(currentBlock, start, end);

  if (!newBlock) return null;

  const newParagraphs = [...paragraphs];
  newParagraphs[blockIndex] = newBlock;
  return newParagraphs;
};

const convertTextToElements = (text) => {
  const elementsText = text.split(BREAK_TIME_REGEX);

  let showedText = '';

  let textFlag = 0;
  let eleFlag = 0;
  const elements = elementsText.reduce((accElements, currText) => {
    if (currText === '') return accElements;

    const startOffset = eleFlag;
    const endOffset = eleFlag + currText.length;

    if (text.slice(textFlag, textFlag + currText.length) === currText) {
      eleFlag = endOffset;
      textFlag += currText.length;
      showedText = `${showedText}${currText}`;
      return [
        ...accElements,
        {
          key: uuidv4(),
          name: null,
          value: null,
          text: currText,
          startOffset,
          endOffset,
        },
      ];
    }

    eleFlag = endOffset + BREAK_TIME_UNIT_LENGTH;
    textFlag = textFlag + currText.length + BREAK_TIME_LENGTH;
    showedText = `${showedText}${currText}s`;
    return [
      ...accElements,
      {
        key: uuidv4(),
        name: TTS_ATTRIBUTE.BREAK_TIME,
        value: currText,
        text: `${currText}s`,
        startOffset,
        endOffset: endOffset + 1,
      },
    ];
  }, []);

  return { elements, showedText };
};

const convertTextToParagraphs = (text) => {
  const paragraphsText = text.split(BREAK_LINE_DELIMITER);

  const paragraphs = paragraphsText.reduce((acc, curr) => {
    if (curr === '') {
      return [
        ...acc,
        {
          elements: [
            {
              key: uuidv4(),
              name: null,
              value: null,
              text: '',
              startOffset: 0,
              endOffset: 0,
            },
          ],
          text: '',
          key: uuidv4(),
        },
      ];
    }

    const { elements, showedText } = convertTextToElements(curr);

    return [...acc, { elements, text: showedText, key: uuidv4() }];
  }, []);

  return paragraphs;
};

export {
  convertParagraphsToState,
  handleEditParagraphs,
  handleSelectedParagraph,
  handleSelectedElement,
  handleInsertElement,
  convertParagraphsToText,
  countBreakTimeLength,
  countEditorStateLength,
  handleReplace,
  removeElementInParagraphs,
  convertTextToParagraphs,
  checkSyntaxTagsParagraphs,
  getElement,
  handleSingleSelectedParagraph,
  removeElementInBlock,
};
