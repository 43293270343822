import { RESOURCE } from '@src/constants';
import api from './api';

const getFilePresignedUrlForUploading = async (
  fileName,
  directory,
  extension,
  nonSuffix,
) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.FILES}/presigned-url-for-uploading`,
    params: { extension, directory, fileName, nonSuffix },
  });
  return response;
};

const getGooglePresignedUrlForUploading = async (fileName, contentType) => {
  const response = await api({
    method: 'GET',
    url: `${RESOURCE.FILES}/google-presigned-url-for-uploading`,
    params: { fileName, contentType },
  });
  return response;
};

export { getFilePresignedUrlForUploading, getGooglePresignedUrlForUploading };
