import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import VoicesFilter from './VoicesFilter';
import { StyledMobileVoicesFilter } from './index.style';

const MobileVoicesFilter = ({
  open,
  onClose,
  hasLanguageFilter,
  filterVoice,
  filterOpens,
  onChangeFilter,
  onChangeFilterOpens,
  onHandleReset,
}) => (
  <StyledMobileVoicesFilter open={open} onclose={onClose}>
    <IconButton
      aria-label="close"
      onClick={onClose}
      color="secondary"
      className="close-button"
    >
      <Close color="secondary" />
    </IconButton>
    <div className="mobile-filter-voice-wrapper">
      <VoicesFilter
        filter={filterVoice}
        hasLanguageFilter={hasLanguageFilter}
        filterOpens={filterOpens}
        onChangeFilter={onChangeFilter}
        onChangeFilterOpens={onChangeFilterOpens}
        onHandleReset={onHandleReset}
      />
    </div>
  </StyledMobileVoicesFilter>
);

export default MobileVoicesFilter;
