import React from 'react';
import logo from '@src/assets/logo/logo.svg';
import { HOME_PAGE_URL } from '@src/configs';

const Logo = ({ logoSrc = logo, height }) => (
  <div className="logo">
    <img
      src={logoSrc}
      alt="vbee tts logo"
      className="logo-img"
      width={logoSrc === logo ? null : 32}
      height={height || 'auto'}
      onClick={() => window.location.assign(HOME_PAGE_URL)}
    />
  </div>
);

export default Logo;
