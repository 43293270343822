import { styled, Dialog } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import {
  FLASH_SALE_BANNER_HEIGHT,
  MOBILE_BREAKPOINT,
} from '@src/styles/config';

const StyledImageBanner = styled(Dialog)`
  .dialog {
    background-color: inherit;
    box-shadow: none;
    max-width: 550px;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .image {
    width: 100%;
    border-radius: 5px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .not-show-again {
    display: flex;
    justify-content: center;
    color: ${COLOR.white};
  }

  .checkbox {
    color: ${COLOR.white};
  }
`;

const StyledJoinGroupZalo = styled(Dialog)`
  .dialog-title {
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: ${COLOR.label[16]};

    &:hover {
      opacity: 0.8;
    }
  }

  .close-icon {
    color: ${COLOR.indigo[80]};
  }

  .dialog-wrapper {
    padding: 16px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
  }

  .img {
    width: 76px;
  }

  .title {
    margin-top: 20px;
    margin-bottom: 6px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: ${COLOR.secondary[100]};
  }

  .package {
    font-weight: 700;
    font-size: 14px;
    color: ${COLOR.black.default};
    text-align: center;
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 32px;
    color: ${COLOR.label[16]};
  }

  .description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: ${COLOR.black.default};
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .action-button {
    text-transform: normal;
    font-weight: 510;
    color: ${COLOR.label2[100]};
  }

  .auto-renewal-label {
    border-radius: 480px;
    font-size: 12px;
    color: ${COLOR.secondary[100]};
    background-color: ${COLOR.secondary[80]};
    padding: 4px 10px;
    margin-left: 6px;
  }

  .discount-unit-price {
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
  }

  .text-16 {
    font-size: 16px !important;
  }

  .underline {
    text-decoration: underline;
  }
`;

const StyledTextBanner = styled(Dialog)`
  .dialog-title {
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .dialog-wrapper {
    padding: 10px 30px 23px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
  }

  .img {
    // width: 185px;
    height: 145px;
  }

  .title {
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: ${COLOR.subText};
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .action-button {
    text-transform: normal;
  }
`;

const StyledServiceManual = styled('div')`
  padding: 10px 30px 23px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .guide-steps {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 311px;
  }

  .detail-guide-step {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 10px;
      background-color: ${COLOR.divider};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${COLOR.lightText};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px ${COLOR.lightText};
      background-color: ${COLOR.divider};
      border-radius: 10px;
    }
  }

  .video-wrapper {
    margin: 0 10px;
  }

  .video {
    height: 311px;
    width: 100%;
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .action-button {
    text-transform: normal;
  }

  .guide-steps-mobile {
    display: none;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0px 5px;
    .dialog-action {
      flex-direction: column;
      margin-top: 5px;
      gap: 10px;
    }

    .action-button {
      width: 100%;
      margin-left: 0px !important;
    }

    .guide-steps {
      display: none;
    }

    .guide-steps-mobile {
      display: block;
    }

    .video-wrapper {
      overflow: hidden;
      margin: 0;
    }

    .video {
      height: 200px;
    }
  }
`;

const StyledGuideStep = styled('div')`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.active ? COLOR.primary : TRANSPARENT_COLOR.primary};
  color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
  margin: 15px;
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }

  .number {
    padding: 10px;
    border: 4px solid ${COLOR.white};
    border-radius: 100%;
    background: ${COLOR.primary};
    color: ${COLOR.darkBlue};
    text-align: center;
    width: 22px;
    margin-left: -3px;
    font-weight: 500;
    font-size: 16px;
  }

  .content {
    margin-left: 15px;
    font-weight: 500;
    font-size: 16px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    background: ${TRANSPARENT_COLOR.primary};
    color: ${COLOR.text};

    .content {
      font-size: 14px;
    }

    .number {
      padding: 5px;
      font-size: 14px;
    }
  }
`;

const StyledHeaderBanner = styled('div')`
  cursor: ${(props) => (props.redirectUrl ? 'pointer' : 'default')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${FLASH_SALE_BANNER_HEIGHT}px;
  z-index: ${(props) => (props.isAgreeToTerm ? 1399 : 1300)};
  display: flex;
  align-items: center;
  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner-information {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.useVoucherByProduct ? '100vw' : '100%')};
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .empty-div {
    width: 240px;
  }
  .banner-content {
    color: ${COLOR.white};
    font-size: 16px;
    font-style: italic;
    font-weight: 858;
    text-transform: uppercase;
    line-height: 17.14px;
    text-align: center;
    word-wrap: break-word;
    max-height: 100%;
    max-width: calc(100% - 500px);
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .empty-div {
      display: none;
    }
    .banner-information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .banner-content {
      font-size: 12px;
      line-height: 20px;
      align-content: center;
      height: 100%;
      max-width: calc(100% - 40px);
      display: flex;
      align-items: center;
    }
    .banner-image {
      object-fit: none;
    }
  }
`;

const StyledCountdownTimeInBanner = styled('div')`
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    gap: 10.29px;
    color: ${COLOR.white};
  }
  .countdown-time {
    display: flex;
    gap: 2.5px;
    align-items: center;
    justify-content: center;
    color: ${COLOR.white};
    .countdown-time-value {
      color: ${COLOR.white};
      font-size: 17.14px;
      font-weight: 700;
      word-wrap: break-word;
    }
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
      align-items: center;
      justify-content: center;
      gap: 8px;
      .countdown-time-value {
        font-size: 12px;
      }
      .unit-time {
        text-transform: lowercase;
      }
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    align-items: center;
    justify-content: center;
    .two-dot {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 10px;
      font-weight: 700;
      word-wrap: break-word;
    }
    .unit-time {
      height: 100%;
    }
  }
  .unit-time {
    color: ${COLOR.white};
    font-weight: 510;
    font-size: 12px;
    word-wrap: break-word;
    align-items: center;
    justify-content: center;
  }
`;

const StyledCountdownInBanner = styled('div')`
  min-width: ${(props) => props.isHeader && '240px'};
  .countdown-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 100%;
    position: relative;
    z-index: 1035;
    margin-right: 16px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
      margin-right: 0px;
      margin-bottom: 2.5px;
    }
  }

  .h-20 {
    width: 200px;
  }
`;

export {
  StyledImageBanner,
  StyledJoinGroupZalo,
  StyledTextBanner,
  StyledServiceManual,
  StyledGuideStep,
  StyledHeaderBanner,
  StyledCountdownInBanner,
  StyledCountdownTimeInBanner,
};
