import React, { useState } from 'react';
import { Paper, ClickAwayListener, Typography } from '@mui/material';
import { StyledPopconfirm } from './index.style';

const Popconfirm = ({
  placement = 'top-end',
  anchorRef,
  useArrow,
  onClose,
  icon,
  width,
  title,
  content,
  actionComponent,
}) => {
  const [arrowRef, setArrowRef] = useState(null);

  const handleClose = () => onClose();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledPopconfirm
        open={!!anchorRef}
        anchorEl={anchorRef}
        disablePortal
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        placement={placement}
        width={width}
      >
        {useArrow && <span className="arrow" ref={setArrowRef} />}
        <Paper className="paper">
          {icon}
          <div>
            {title && <Typography className="title">{title}</Typography>}
            <Typography className={title ? 'content' : 'description'}>
              {content}
            </Typography>
            {actionComponent && (
              <div style={{ width: '100%', marginTop: '16px' }}>
                {actionComponent}
              </div>
            )}
          </div>
        </Paper>
      </StyledPopconfirm>
    </ClickAwayListener>
  );
};

export default Popconfirm;
