import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { AUTO_PLAY_INTERVAL } from '@src/constants';
import { StyledMobileStepper } from './index.style';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AutoPlayMobileStepper = ({ items, children, play = true }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [autoplay, setAutoplay] = useState(false);

  const { t } = useTranslation();

  const maxSteps = items.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClickMouse = () => {
    setAutoplay(false);
  };

  useEffect(() => {
    setAutoplay(false);
  }, [play]);

  return (
    <StyledMobileStepper onClick={handleClickMouse}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        autoplay={autoplay}
        interval={AUTO_PLAY_INTERVAL}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {children}
      </AutoPlaySwipeableViews>
      <MobileStepper
        className="mobile-stepper"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {t('after')}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {t('before')}
          </Button>
        }
      />
    </StyledMobileStepper>
  );
};

export default AutoPlayMobileStepper;
