import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import {
  AUDIO_PLAYER_HEIGHT,
  BORDER_RADIUS,
  BREAKPOINTS,
  PAYMENT_FOOTER_SHADOW,
} from '@src/styles/config';

export const StyledConfigAutoRenew = styled(Box)`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 70px;

  .header-config {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .card {
    padding: 24px 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .show-mobile {
    display: none;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: ${COLOR.indigo.default};
    line-height: 28px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    max-width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: ${BREAKPOINTS.SMD - 1}px) {
    .card {
      padding: 32px;
    }

    .show-desktop {
      display: none;
    }

    .show-mobile {
      display: block;
    }
  }

  @media (max-width: 599px) {
    .card {
      padding: 16px;
    }
  }

  @media (max-width: 650px) {
    .header-payment {
      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .icon {
        display: none;
      }

      .icon-button {
        display: block;
      }
    }
  }
`;

export const StyledConfigAutoRenewFooter = styled(Box)`
  min-width: 80px;
  padding: 12px 80px 12px 20px;
  height: ${AUDIO_PLAYER_HEIGHT - 12}px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  height: 40px;
  background-color: ${COLOR.white};
  box-shadow: ${PAYMENT_FOOTER_SHADOW};

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    left: 0 !important;
  }

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    left: ${(props) => `${props.sidebarwidth}px`};
  }

  .container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .term {
      font-size: 12px;
      font-weight: 400;
      // line-height: 16px;
      // letter-spacing: -0.4px;
      color: ${COLOR.darkV2[64]};

      a:-webkit-any-link {
        color: ${COLOR.darkV2[64]};
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .cancelNote {
      margin-top: -10px;
    }

    .agreeToPayButton {
      text-transform: none;
      padding: 2px;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      display: flex;
      height: 44px;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 480px;
      min-width: 240px;
    }
  }

  @media only screen and (max-width: 460px) {
    left: 0 !important;
    height: ${AUDIO_PLAYER_HEIGHT - 12}px;

    .container {
      display: flex;
      flex-direction: column;
    }
    .price {
      margin-bottom: 8px;
    }
  }
`;

export const StyledPaymentMethod = styled(Box)`
  .card {
    padding: 24px 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: ${COLOR.indigo.default};
      line-height: 28px;
    }

    .text-content-bold {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${COLOR.black[80]};
    }

    .content {
      display: flex;
      gap: 12px;
      align-items: center;

      .verify-icon {
        width: 16px;
        height: 16px;
      }

      .text-content {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: ${COLOR.black[80]};
      }
    }
  }
`;

export const StyledPaymentMethodItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  border-radius: ${BORDER_RADIUS};
  padding: 12px 8px;
  background: ${COLOR.charcoal};
  border: 2px solid ${COLOR.charcoal};
  &:hover {
    cursor: pointer;
    background: ${COLOR.gray};
    border: 2px solid ${COLOR.gray};
  }

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.32;
    &:hover {
      cursor: default ;
      background: ${COLOR.charcoal};
      border: 2px solid transparent;
    }
  `}

  ${({ selected }) =>
    selected &&
    `border: 2px solid ${COLOR.primary};
     background: none;
     &:hover {
      background: none;
      border: 2px solid ${COLOR.primary}
     }
  `}

  .name {
    font-size: 14px;
    font-weight: 510;
  }

  .logo {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-img {
    max-width: 64px;
  }
`;

export const StyledSubscriptionInfo = styled(Box)`
  .card {
    padding: 24px 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: ${COLOR.indigo.default};
      line-height: 28px;
    }

    .description-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .auto-renewal {
      padding: 4px 10px;
      border-radius: 16px;
      background-color: ${COLOR.indigo[8]};
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.4px;
      color: ${COLOR.indigo[100]};
    }

    .content {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .text-content-bold {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${COLOR.black[80]};
    }

    .discount-title {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .sub-text {
      font-size: 14px;
      color: ${COLOR.black[64]};
    }

    .text-16 {
      font-size: 16px !important;
    }

    .discount-unit-price {
      font-size: 14px;
      font-weight: 400;
      text-transform: lowercase;
    }

    .underline {
      text-decoration: underline;
    }

    .voucher-gift-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: ${COLOR.yellow.default};
      border-radius: 50%;
      padding: 4px;

      .gift-icon {
        width: 16px;
        height: 16px;
        color: ${COLOR.indigo.default};
      }
    }
  }
`;

export const StyledPaymentAccount = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 12px;
  border: ${(props) =>
    props.isActive ? `2px solid ${COLOR.yellow.default}` : 'none'};
  cursor: pointer;

  .account-info {
    display: flex;
    align-items: center;
  }

  .payment-method {
    font-weight: 500;
  }

  .account-name {
    color: ${COLOR.secondary[64]};
    font-size: 14px;
  }

  .sub-text {
    font-size: 16px;
    font-weight: 400;
    color: ${COLOR.secondary[64]};
  }

  .right-icon {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${COLOR.secondary[64]};
  }

  .logo {
    padding-right: 12px;
  }
`;

export const StyledNotificationDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 480px;
    border-radius: 16px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 32px 32px 32px;
    gap: 16px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .discount-unit-price {
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
  }

  .underline {
    text-decoration: underline;
  }

  .title-content {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: ${COLOR.black.default};
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 8px 0px;

    .label {
      color: ${COLOR.black.default};
      font-size: 13px;
      font-weight: 510;
    }

    .value {
      color: #1c1c1e;
      font-size: 15px;
    }
  }

  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.black.default};
  }

  .action {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;

    .action-button {
      text-transform: none;
      height: 44px;
      width: 240px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: -0.4px;
      border-radius: 500px;
      text-transform: uppercase;
    }
  }
`;
