import { Popover, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledToolbar = styled('div')`
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${COLOR.label[16]};
  border-top: 0.5px solid ${COLOR.label[16]};
  .toolbar-items-wrapper {
    display: flex;
    gap: 16px;
  }
`;

export const StyledVolume = styled(Popover)`
  .MuiPaper-root {
    border-radius: 100px;
  }
  .volume-wrapper {
    display: flex;
    height: 200px;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    gap: 3px;
  }
`;

export const StyledSlider = styled(Slider)`
  color: ${COLOR.primary};
  height: 150px;
  & .MuiSlider-valueLabel {
    font-weight: bold;
    font-size: 14px;
    top: -8px;
    background-color: unset;
    color: ${COLOR.text};
    &:before {
      display: none;
    }
  }
  & .MuiSlider-thumb {
    height: 14px;
    width: 14px;
    background-color: ${COLOR.white};
    border: 1px solid currentColor;
  }
  & .MuiSlider-rail {
    color: ${COLOR.secondary[24]};
  }
`;

export const StyledSidebarTooltip = styled(
  ({ className, placement = 'right', ...props }) => (
    // eslint-disable-next-line react/jsx-filename-extension, react/react-in-jsx-scope
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow
      placement={placement}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.label[80],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 16px',
    textAlign: 'center',
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLOR.label[80],
  },
}));

export const StyledAudioActions = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  .audio-action-button {
    padding: 6px;
    border-radius: 50%;
    min-width: 0;
  }
`;

export const StyledZoomActions = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  .zoom-progress-wrapper {
    width: 100px;
    height: 4px;
    border-radius: 4px;
    background-color: ${COLOR.label[16]};

    .zoom-progress {
      width: ${(props) => props.progressValue}%;
      height: 100%;
      border-radius: 4px;
      background-color: ${COLOR.secondary[8]};
    }
  }
`;

export const StyledAudioSelection = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 100px;
  border: 1px solid ${COLOR.label[16]};
`;
