import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectionComponent from './SelectionComponent';
import { StyledLanguageSelection } from './index.style';

const LanguageSelection = ({
  isSrtFile,
  languages,
  originalLanguage,
  setOriginalLanguage,
  targetLanguage,
  setTargetLanguage,
  languageError,
  setLanguageError,
}) => {
  const { t } = useTranslation();

  const DEFAULT_LANGUAGE = {
    value: 'vi-VN',
    country: 'vi-VN',
    label: 'vietnamese',
    flag: 'https://vbee.s3.ap-southeast-1.amazonaws.com/images/nations/round/vn.png',
  };

  const handleChangeOriginalLanguage = (event) => {
    setOriginalLanguage(event.target.value);
    setLanguageError(false);
  };

  const handleChangeTargetLanguage = (event) =>
    setTargetLanguage(event.target.value);

  return (
    <StyledLanguageSelection>
      <SelectionComponent
        value={originalLanguage}
        label={t('originalLanguage')}
        handleSelectLanguage={handleChangeOriginalLanguage}
        languages={languages}
        canSelect={!isSrtFile}
        languageError={languageError}
        setLanguageError={setLanguageError}
      />
      <SelectionComponent
        value={targetLanguage}
        label={t('translateLanguage')}
        handleSelectLanguage={handleChangeTargetLanguage}
        defaultLanguage={DEFAULT_LANGUAGE}
        canSelect={false}
      />
    </StyledLanguageSelection>
  );
};

export default LanguageSelection;
