import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import SrtFileIcon from '@src/assets/icons/srt-file.svg';
import DirectBoxSendIcon from '@src/assets/icons/directbox-send.svg';
import { StyledDefaultDropzoneContent } from './index.style';
import SampleFileReference from './SampleFileReference';

const DefaultDropzoneContent = ({ isMultipleInputDubbing, fileFormat }) => {
  const { t } = useTranslation();

  const OldDefaultDropzone = () => (
    <StyledDefaultDropzoneContent>
      <img src={SrtFileIcon} alt="srt-icon" />
      <div className="upload-instruction">
        <Typography align="center" variant="h5" className="upload-file-title">
          {t('dropFileInstruct', { fileFormat })}
        </Typography>
        <SampleFileReference />
      </div>
    </StyledDefaultDropzoneContent>
  );

  const NewDefaultDropzone = () => (
    <StyledDefaultDropzoneContent>
      <img src={DirectBoxSendIcon} alt="srt-icon" />
      <div className="upload-instruction">
        <Typography
          align="center"
          variant="h5"
          className="upload-file-title text-blue"
        >
          {t('dropFileSuccessInstructSubtitle', { fileFormat })}
        </Typography>
        <SampleFileReference isMultipleInputDubbing />
      </div>
    </StyledDefaultDropzoneContent>
  );

  return isMultipleInputDubbing ? (
    <NewDefaultDropzone />
  ) : (
    <OldDefaultDropzone />
  );
};

export default DefaultDropzoneContent;
