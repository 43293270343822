import React, { useState } from 'react';
import { StyledDropzone } from './index.style';

const Dropzone = ({ onDropFiles, className, children }) => {
  const [highlight, setHighlight] = useState(false);

  const handleDropFiles = (e) => {
    e.preventDefault();
    setHighlight(false);
    const { files } = e.dataTransfer;
    if (onDropFiles) onDropFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setHighlight(true);
  };

  const handleDragLeave = () => setHighlight(false);

  return (
    <StyledDropzone>
      <div
        className={`${className} ${highlight ? 'highlight' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDropFiles}
        role="presentation"
      >
        {children}
      </div>
    </StyledDropzone>
  );
};

export default Dropzone;
