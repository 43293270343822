import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { convertToCamelCase } from '@src/utils/convertCamelCase';
import { removeElementInParagraphs } from '@src/services/paragraph';
import actions from '@src/redux/actions';

import {
  StyledMapEditor,
  StyledHightLightTooltip,
  StyledTooltipTitle,
} from './index.style';

const HightLightSpan = ({
  contentState,
  entityKey,
  offsetKey,
  children,
  blockKey,
  decoratedText,
  end,
  start,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const childrenRef = useRef(null);
  const { paragraphs, openSentences } = useSelector(
    (state) => state.synthesisRequest,
  );

  const { name, value } = contentState.getEntity(entityKey).getData();

  const displayName = convertToCamelCase(name);

  const displayValue = value?.toString()?.toLowerCase() || '';

  const hasValue = (val) => val === 'TRUE';
  const displayTitle = hasValue(value)
    ? `${t(displayName)}`
    : `${t(displayName)} - ${t(displayValue)}`;

  const handleRemoveElement = () => {
    if (openSentences) {
      dispatch(
        actions.synthesisRequest.removeTtsAttributeBySentence({
          sentenceId: blockKey,
          removeText: decoratedText,
          end,
          start,
        }),
      );
      return;
    }
    const newParagraphs = removeElementInParagraphs({
      paragraphs,
      blockKey,
      text: decoratedText,
      end,
      start,
    });
    if (newParagraphs) {
      dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
    }
  };

  return (
    <StyledHightLightTooltip
      arrow
      onOpen={(e) => {
        let anchorTop;
        const childrenTop = childrenRef.current?.getBoundingClientRect()?.top;
        if (childrenTop && Math.abs(childrenTop - e.clientY) < 19) {
          anchorTop = childrenRef.current.getBoundingClientRect().top;
        } else {
          anchorTop = e.clientY;
        }
        setAnchorEl({
          top: anchorTop,
          left: e.clientX,
          right: e.clientX,
          bottom: e.clientY,
          width: 0,
          height: 0,
        });
      }}
      PopperProps={
        anchorEl
          ? {
              anchorEl: {
                getBoundingClientRect: () => anchorEl,
              },
            }
          : {}
      }
      title={
        <StyledTooltipTitle>
          <Typography className="title">{displayTitle}</Typography>
          <IconButton
            color="secondary"
            onClick={handleRemoveElement}
            classes={{ root: 'icon-button' }}
          >
            <Close className="icon" />
          </IconButton>
        </StyledTooltipTitle>
      }
    >
      <span
        data-offset-key={offsetKey}
        style={StyledMapEditor[name]}
        ref={childrenRef}
      >
        {children}
      </span>
    </StyledHightLightTooltip>
  );
};

export default HightLightSpan;
