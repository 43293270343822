import { useLocation } from 'react-router-dom';

const useCheckDubbingRoute = () => {
  const location = useLocation();

  const arrayPath = location.pathname.split('/');
  return arrayPath[1] === 'dubbing';
};

const useCheckSTTRoute = () => {
  const location = useLocation();

  const arrayPath = location.pathname.split('/');
  return arrayPath[2] === 'speech-to-text';
};

const useCheckCloningRoute = () => {
  const location = useLocation();
  const arrayPath = location.pathname.split('/');
  return arrayPath[2] === 'voice-cloning';
};

const useCheckTTSRoute = () => {
  const location = useLocation();
  const arrayPath = location.pathname.split('/');
  return arrayPath[1] === 'studio';
};

const useCheckAPIRoute = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const packageType = queryParams.get('packageType');

  return packageType === 'API';
};

export {
  useCheckDubbingRoute,
  useCheckSTTRoute,
  useCheckCloningRoute,
  useCheckTTSRoute,
  useCheckAPIRoute,
};
