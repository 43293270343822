import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledSubtitle = styled('div')`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0px 8px;

  .subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${COLOR.bluev2[100]};
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines you want to limit to */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;
