export default {
  HOME: '/studio/home',
  DASHBOARD: '/studio/dashboard',
  TTS: '/studio/text-to-speech',
  VOICE_CLONING_VOICES: '/studio/voice-cloning/voices',
  VOICE_CLONING_CREATE: '/studio/voice-cloning/create',
  VOICE_CLONING_VOICE_DETAIL: '/studio/voice-cloning/voices/:voiceId',
  STT: '/studio/speech-to-text',
  PAYMENT: '/studio/payments',
  DUBBING: '/studio/dubbing',
  PAYMENT_HISTORY: '/studio/payment-history',
  SUBSCRIPTION: '/studio/subscription',
  AFFILIATE: '/studio/affiliate',
  AFFILIATE_DETAIL: '/studio/affiliate/detail',
  LIVE_SUPPORT: '/studio/live-support',
  OTHER_SERVICES: '/studio/other-services',
  MOBILE_APP: '/studio/mobile-app',
  MANAGE_DEVICE: '/studio/manage-device',
  CONFIG_AUTO_RENEW: '/studio/auto-renewal-payment',
  DUBBING_HOME: '/dubbing/home',
  HOME_DUBBING: '/dubbing',
  PAYMENT_DUBBING: '/dubbing/payments',
  PAYMENT_HISTORY_DUBBING: '/dubbing/payments-history',
  SUBSCRIPTION_DUBBING: '/dubbing/subscription',
  AFFILIATE_DUBBING: '/dubbing/affiliate',
  AFFILIATE_DETAIL_DUBBING: '/dubbing/affiliate/detail',
  DUBBING_PROJECT_DETAIL: '/dubbing/projects/:projectId',
  DUBBING_PROJECT: '/dubbing/projects',
  ERROR: '/error',
  MOBILE: '/mobile',
  MOBILE_TTS: '/mobile/text-to-speech',
  MOBILE_REQUESTS: '/mobile/requests',
};
