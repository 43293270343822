import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledButton = styled('div')`
  padding: 8px 8px 0px 0;
  min-width: fit-content;

  .wrapper {
    min-width: fit-content;
    height: 32px !important;
    display: flex;
    border-radius: 55px;
    border: 1px solid ${COLOR.indigo[16]};
  }

  .support-button {
    display: flex;
    height: 32px;
    padding: 0;
    border-radius: 480px;
    justify-content: flex-start;
    border-radius: 55px;

    &:hover {
      background-color: ${COLOR.white};
    }

    &:active {
      background-color: ${COLOR.black};
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: ${COLOR.white};
    border-radius: 55px;
    &:hover {
      background-color: ${COLOR.black[8]};
    }
  }

  .icon-button {
    text-transform: none;
    padding: 0 8px 0 16px;
    min-width: fit-content;
    background-color: transparent;
    border-radius: 55px;
  }

  .button-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    min-width: fit-content;
    line-height: 32px;
    letter-spacing: -0.4px;
    text-transform: none !important;
    text-align: left;
    padding-right: 16px;
    color: ${COLOR.indigo[100]};
  }

  @media (max-width: 1800px) and (min-width: 1600px) {
    .sample-button-8 {
      display: none;
    }
  }

  @media (max-width: 1600px) and (min-width: 1400px) {
    .sample-button-7,
    .sample-button-8 {
      display: none;
    }
  }

  @media (max-width: 1400px) and (min-width: 1200px) {
    .sample-button-5,
    .sample-button-6,
    .sample-button-7,
    .sample-button-8 {
      display: none;
    }
  }
  @media (max-width: 1200px) and (min-width: 850px) {
    .sample-button-4,
    .sample-button-5,
    .sample-button-6,
    .sample-button-7,
    .sample-button-8 {
      display: none;
    }
  }
  @media (max-width: 850px) and (min-width: 800px) {
    .sample-button-3,
    .sample-button-4,
    .sample-button-5,
    .sample-button-6,
    .sample-button-7,
    .sample-button-8 {
      display: none;
    }
  }
  @media (max-width: 800px) and (min-width: 500px) {
    .sample-button-2,
    .sample-button-3,
    .sample-button-4,
    .sample-button-5,
    .sample-button-6,
    .sample-button-7,
    .sample-button-8 {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .sample-button-1,
    .sample-button-2,
    .sample-button-3,
    .sample-button-4,
    .sample-button-5,
    .sample-button-6,
    .sample-button-7,
    .sample-button-8 {
      display: none;
    }
  }
`;

const StyledSampleScripts = styled('div')`
  flex: 1;

  .tool-row-left-content {
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tool-row-left-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.4px;
    color: ${COLOR.label2[48]};
  }

  @media (max-width: 499px) {
    .tool-row-left-title {
      display: none;
    }
  }
`;

export { StyledButton, StyledSampleScripts };
