import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CardMedia,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import Card from '@src/components/Card';
import QRCode from '@src/assets/images/qr_code.png';
import AppStore from '@src/assets/images/app_store.png';
import CHPlay from '@src/assets/images/googleplay.png';
import Iphones from '@src/assets/images/iphones.png';
import { APP_STORE_URL, CH_PLAY_URL } from '@src/configs';
import { StyledMobilePage } from './index.style';

const descriptions = [
  'usability',
  'createQualityAudio',
  'professionalAudioCorrection',
  'supportConvertFormats',
  'anyConvert',
];

const MobileApp = () => {
  const { t } = useTranslation();

  const handleClickDownload = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card>
      <StyledMobilePage>
        <div>
          <Typography className="tag">{t('vbeeTTSApp')}</Typography>
          <Typography className="title">{t('emotionalTTS')}</Typography>
          <div className="content">
            <Typography>{t('contentApp')}</Typography>
            <List>
              {descriptions &&
                descriptions.map((description) => (
                  <ListItem className="description">
                    <ListItemIcon>
                      <CircleIcon className="icon" />
                    </ListItemIcon>
                    <ListItemText primary={t(description)} />
                  </ListItem>
                ))}
            </List>
          </div>

          <Typography className="text-download">
            {t('downloadAppContent')}
          </Typography>
          <div className="download-app">
            <img src={QRCode} alt="vbee-qr-code" className="qr-code" />
            <div className="download-button">
              <Button onClick={() => handleClickDownload(APP_STORE_URL)}>
                <img src={AppStore} alt="App Store" className="button-img" />
              </Button>
              <Button onClick={() => handleClickDownload(CH_PLAY_URL)}>
                <img src={CHPlay} alt="CH Play" className="button-img" />
              </Button>
            </div>
          </div>
        </div>
        <div className="image-iphones">
          <CardMedia
            component="img"
            className="iphones"
            image={Iphones}
            alt="Iphone"
          />
        </div>
      </StyledMobilePage>
    </Card>
  );
};

export default MobileApp;
