/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import SmallLockCharacterBanner from '../CustomHeaderBanner/SmallLockCharacterBanner';
import HeaderBanner from '../HeaderBanner';
import { StyledSwiperHeaderBanner } from './index.style';
import OtherVoucherBanner from '../CustomHeaderBanner/OtherVoucherBanner';

const SwiperHeaderBanner = ({ headerVouchers, isAgreeToTerm }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === headerVouchers.length - 1 ? 0 : prevSlide + 1,
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [headerVouchers.length]);

  const getSlideClass = (index) => {
    if (index === currentSlide) return 'active';
    if (
      index === currentSlide - 1 ||
      (currentSlide === 0 && index === headerVouchers.length - 1)
    )
      return 'prev';
    return 'next';
  };

  return (
    <StyledSwiperHeaderBanner isAgreeToTerm={isAgreeToTerm}>
      {headerVouchers &&
        headerVouchers.length &&
        headerVouchers.map((voucher, index) => {
          const slideClass = getSlideClass(index);

          const isFlashSaleVoucher = voucher?.isHeaderBanner;
          if (isFlashSaleVoucher) {
            return (
              <div
                key={voucher.id || `flash-sale-${index}`}
                className={slideClass}
              >
                <HeaderBanner
                  headerVoucher={voucher}
                  isAgreeToTerm={isAgreeToTerm}
                />
              </div>
            );
          }

          if (voucher.isPreserveVoucher) {
            return (
              <div key={`preserve-${index}`} className={slideClass}>
                <SmallLockCharacterBanner />
              </div>
            );
          }

          return (
            <div key={voucher.id || `other-${index}`} className={slideClass}>
              <OtherVoucherBanner voucher={voucher} />
            </div>
          );
        })}
    </StyledSwiperHeaderBanner>
  );
};

export default SwiperHeaderBanner;
