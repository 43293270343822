export const actionTypes = {
  APPLY_VOUCHER: 'APPLY_VOUCHER',
  UNSELECT_VOUCHER: 'UNSELECT_VOUCHER',
  ADD_AUTO_VOUCHER: 'ADD_AUTO_VOUCHER',
  UPDATE_BANNER_VOUCHER: 'UPDATE_BANNER_VOUCHER',
  ADD_MANUAL_BANNER: 'ADD_MANUAL_BANNER',
  UPDATE_HEADER_VOUCHER: 'UPDATE_HEADER_VOUCHER',
};

const applyVoucher = ({ id, type, value, displayText }) => ({
  type: actionTypes.APPLY_VOUCHER,
  voucher: { id, type, value, displayText },
});

const unselectVoucher = () => ({
  type: actionTypes.UNSELECT_VOUCHER,
});

const addAutoVoucher = (voucherCode, bannerType) => ({
  type: actionTypes.ADD_AUTO_VOUCHER,
  voucherCode,
  bannerType,
});

const updateBannerVoucher = (voucher) => ({
  type: actionTypes.UPDATE_BANNER_VOUCHER,
  voucher,
});

const addManualBanner = (banner) => ({
  type: actionTypes.ADD_MANUAL_BANNER,
  banner,
});

const updateHeaderVoucher = (voucher) => ({
  type: actionTypes.UPDATE_HEADER_VOUCHER,
  voucher,
});

export {
  applyVoucher,
  unselectVoucher,
  addAutoVoucher,
  updateBannerVoucher,
  addManualBanner,
  updateHeaderVoucher,
};
