import React from 'react';
import { Typography } from '@mui/material';
import { StyledScale } from './index.style';

const Scale = ({ scale }) => (
  <StyledScale>
    <Typography className="scale">{scale}s</Typography>
  </StyledScale>
);

export default Scale;
