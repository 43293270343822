import { validateKcToken } from '@src/services/auth';
import apis from '@src/apis';
import api from '@src/apis/api';
import { jwtDecode } from '../utils/jwt';
import {
  getCookie,
  removeCookieFromAllSubdomains,
  setCookie,
} from '../utils/cookie';
import { ENV, VBEE_DOMAIN } from '../configs';
import useCustomSSO from './useCustomSSO';

const COOKIE_ACCESS_TOKEN = `KC_ACCESS_TOKEN_${ENV}`;
const COOKIE_REFRESH_TOKEN = `KC_REFRESH_TOKEN_${ENV}`;
const COOKIE_ID_TOKEN = `KC_ID_TOKEN_${ENV}`;

const useCustomKeycloak = () => {
  const { keycloak, initialized } = useCustomSSO();

  const setKcTokenCookie = (token, refreshToken, idToken, expires) => {
    setCookie({
      cname: COOKIE_ACCESS_TOKEN,
      cvalue: token,
      extime: expires,
      domain: VBEE_DOMAIN,
    });
    setCookie({
      cname: COOKIE_REFRESH_TOKEN,
      cvalue: refreshToken,
      extime: expires,
      domain: VBEE_DOMAIN,
    });
    setCookie({
      cname: COOKIE_ID_TOKEN,
      cvalue: idToken,
      extime: expires,
      domain: VBEE_DOMAIN,
    });
  };

  const setKcToken = (token, refreshToken, idToken, timeLocal) => {
    if (keycloak.tokenTimeoutHandle) {
      clearTimeout(keycloak.tokenTimeoutHandle);
      keycloak.tokenTimeoutHandle = null;
    }

    if (keycloak.handleTimeoutRefresh) {
      clearTimeout(keycloak.handleTimeoutRefresh);
      keycloak.handleTimeoutRefresh = null;
    }

    if (refreshToken) {
      keycloak.refreshToken = refreshToken;
      keycloak.refreshTokenParsed = jwtDecode(refreshToken);
    } else {
      delete keycloak.refreshToken;
      delete keycloak.refreshTokenParsed;
    }

    if (idToken) {
      keycloak.idToken = idToken;
      keycloak.idTokenParsed = jwtDecode(idToken);
    } else {
      delete keycloak.idToken;
      delete keycloak.idTokenParsed;
    }

    if (token) {
      keycloak.token = token;
      keycloak.tokenParsed = jwtDecode(token);
      keycloak.sessionId = keycloak.tokenParsed.session_state;
      keycloak.authenticated = true;
      keycloak.subject = keycloak.tokenParsed.sub;
      keycloak.realmAccess = keycloak.tokenParsed.realm_access;
      keycloak.resourceAccess = keycloak.tokenParsed.resource_access;

      // Handle refresh token 5 seconds before it expires
      const currentTime = new Date().getTime();
      const timeBeforeExpires = keycloak.tokenParsed.exp * 1000 - currentTime;
      keycloak.handleTimeoutRefresh = setTimeout(async () => {
        const {
          accessToken: newToken,
          refreshToken: newRefreshToken,
          idToken: newIdToken,
          expiresIn: newExpiresIn,
        } = await apis.account.getTokenFromRefreshToken(refreshToken);
        api.defaults.headers.common.Authorization = `Bearer ${newToken}`;
        setKcToken(newToken, newRefreshToken, newIdToken);
        setKcTokenCookie(
          newToken,
          newRefreshToken,
          newIdToken,
          newExpiresIn * 1000,
        );
      }, timeBeforeExpires - 5 * 1000);

      if (timeLocal) {
        keycloak.timeSkew =
          Math.floor(timeLocal / 1000) - keycloak.tokenParsed.iat;
      }

      if (keycloak.timeSkew != null) {
        if (keycloak.onTokenExpired) {
          const expiresIn =
            (keycloak.tokenParsed.exp -
              new Date().getTime() / 1000 +
              keycloak.timeSkew) *
            1000;
          if (expiresIn <= 0) {
            keycloak.onTokenExpired();
          } else {
            keycloak.tokenTimeoutHandle = setTimeout(
              keycloak.onTokenExpired,
              expiresIn,
            );
          }
        }
      }
    } else {
      delete keycloak.token;
      delete keycloak.tokenParsed;
      delete keycloak.subject;
      delete keycloak.realmAccess;
      delete keycloak.resourceAccess;

      keycloak.authenticated = false;
    }
  };

  const removeKcTokenCookie = () => {
    removeCookieFromAllSubdomains(COOKIE_ACCESS_TOKEN);
    removeCookieFromAllSubdomains(COOKIE_REFRESH_TOKEN);
    removeCookieFromAllSubdomains(COOKIE_ID_TOKEN);
  };

  const getKcTokenFromCookie = () => {
    const token = getCookie(COOKIE_ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
    const idToken = getCookie(COOKIE_ID_TOKEN);
    return { token, refreshToken, idToken };
  };

  const validateKcTokenCookie = async (accessToken, refreshToken, idToken) => {
    let isValidToken = true;
    if (!accessToken || !refreshToken || !idToken) isValidToken = false;

    try {
      isValidToken = await validateKcToken(accessToken, refreshToken, idToken);
    } catch (err) {
      isValidToken = false;
    }

    if (!isValidToken) removeKcTokenCookie();
    return isValidToken;
  };

  const loginInNewTab = () => {
    const loginUrl = keycloak.createLoginUrl();
    // TODO: push to moengage event and product name
    window.location.href = loginUrl;
  };

  return {
    keycloak,
    initialized,
    setKcToken,
    setKcTokenCookie,
    getKcTokenFromCookie,
    removeKcTokenCookie,
    validateKcTokenCookie,
    loginInNewTab,
  };
};

export default useCustomKeycloak;
