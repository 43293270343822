import { LANGUAGE } from '@src/constants';

const formatCommasThousand = (number) =>
  Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

const delimitNumber = (number, lang = LANGUAGE.VN) => {
  const delimiter = lang === LANGUAGE.VN ? '.' : ',';
  if (number)
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  return 0;
};

const SI_PREFIXES = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

const abbreviateNumber = (number) => {
  if (number === 0) return number;

  const tier = SI_PREFIXES.filter((n) => number >= n.value).pop();
  const numberFixed = (number / tier.value).toFixed(1);

  return `${numberFixed}${tier.symbol}`;
};

const validateNumber = (number) => {
  const NUMBER_REGEX = /^[0-9]+(\.[0-9]+)?$/;
  return NUMBER_REGEX.test(number);
};

const getRandomIntNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const checkIsNumber = (number) => {
  const NUMBER_REGEX = /^[0-9]+(\.[0-9]+)?$/;
  return NUMBER_REGEX.test(number);
};

const formatPhoneNumber = (rawPhoneNumber) => {
  // Format the phone number as per your requirement
  const formattedPhoneNumber = rawPhoneNumber.replace(
    /(\d{4})(\d{3})(\d{3})/,
    '$1 $2 $3',
  );
  return formattedPhoneNumber;
};

const twoDigitNumber = (num) => (num < 10 ? `0${num}` : num.toString());

export {
  formatCommasThousand,
  delimitNumber,
  abbreviateNumber,
  validateNumber,
  getRandomIntNumber,
  checkIsNumber,
  formatPhoneNumber,
  twoDigitNumber,
};
