import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import actions from '@src/redux/actions';
import {
  VolumeMuteRounded,
  VolumeOff,
  VolumeUpRounded,
} from '@mui/icons-material';
import {
  StyledSidebarTooltip,
  StyledSlider,
  StyledVolume,
} from './index.style';

const VALID_VOLUME = { MIN: 0, MAX: 100 };

const Volume = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  // const [volume, setVolume] = useState(100);

  const { audioLink, volume } = useSelector((state) => state.audioPlayer);

  const showChangeVolume = audioLink || true;

  const handleOpenChangeVolume = (e) => setAnchorEl(e.currentTarget);
  const handleCloseChangeVolume = () => setAnchorEl(null);

  const handleChangeVolume = (value) => {
    dispatch(actions.audioPlayer.updateVolume(value / 100));
  };

  return (
    <>
      {showChangeVolume && (
        <StyledSidebarTooltip title={t('volume')} placement="top">
          <div>
            <IconButton
              onClick={handleOpenChangeVolume}
              className={!!anchorEl && 'button-active'}
            >
              {volume ? (
                <VolumeUpRounded sx={{ fontSize: 24 }} color="iconPrimary" />
              ) : (
                <VolumeOff sx={{ fontSize: 24 }} color="iconPrimary" />
              )}
            </IconButton>
          </div>
        </StyledSidebarTooltip>
      )}

      <StyledVolume
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseChangeVolume}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className="volume-wrapper">
          <IconButton onClick={() => handleChangeVolume(VALID_VOLUME.MAX)}>
            <VolumeUpRounded sx={{ fontSize: 24 }} color="iconPrimary" />
          </IconButton>
          <StyledSlider
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical',
              },
            }}
            orientation="vertical"
            value={volume * 100}
            min={VALID_VOLUME.MIN}
            max={VALID_VOLUME.MAX}
            step={1}
            onChange={(e, value) => handleChangeVolume(value)}
          />
          <IconButton onClick={() => handleChangeVolume(VALID_VOLUME.MIN)}>
            <VolumeMuteRounded sx={{ fontSize: 24 }} color="iconPrimary" />
          </IconButton>
        </div>
      </StyledVolume>
    </>
  );
};

export default Volume;
