import { Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { TOOLBAR_BOX_SHADOW } from '@src/styles/config';

const StyledToolbar = styled('div')`
  position: relative;

  .block-wrapper {
    opacity: 0.3;
    background: white;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
  }

  .toolbar-row {
    display: flex;
    flex-wrap: wrap !important;
    align-items: center !important;
    background-color: ${COLOR.secondary[40]};
    padding: 8px;
    border-radius: 8px;
    justify-content: space-between;
  }

  .toolbar-row-bonus {
    margin-top: ${(props) => (props.openbonus ? '8px' : 0)};
    padding: ${(props) => (props.openbonus ? '6px 8px' : 0)};
    border-radius: 8px;
    height: ${(props) => (props.openbonus ? 'auto' : 0)};
    width: ${(props) => (props.openbonus ? 'auto' : 0)};
    opacity: ${(props) => (props.openbonus ? 1 : 0)};
    display: flex;
    flex-wrap: wrap !important;
    align-items: center !important;
    position: relative;
    transition: width 400ms, opacity 400ms;

    &:hover {
      background-color: ${COLOR.secondary[40]};

      .close-button {
        display: flex;
        position: absolute;
        right: 6px;
        align-items: center;
      }
    }

    .close-button {
      display: none;
    }

    .replace-button {
      border-radius: 24px;
      padding: 8px 12px 8px 16px;
      height: 36px;
      text-transform: none;
      border-color: ${COLOR.light};
      font-weight: 700;
      display: ${(props) => (props.openbonus ? 'flex' : 'none')};
    }
  }

  .left-toolbar {
    display: flex;
    flex-wrap: wrap !important;
  }

  .active-button {
    background-color: ${COLOR.secondary[80]};
  }

  .group {
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .divider {
    margin: 0 10px;
    border: 1px solid ${COLOR.secondary[80]};
  }

  .choose-voice {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    padding: 4px 6px;
    border-radius: 16px;

    &:hover,
    &:focus {
      background: ${COLOR.secondary[80]};
    }
  }

  .voice-name {
    margin-left: 10px;
    color: ${COLOR.darkBlue};
  }

  .margin-right {
    margin-right: 6px;
  }

  .margin-right-bonus-toolbar {
    margin-right: 12px;
  }

  .search-and-replace {
    ${(props) =>
      props.openbonus && {
        background: COLOR.secondary[80],
      }}
  }

  .custom-button {
    text-transform: none;
    border-radius: 12px;
    padding: 4px 6px;
    min-width: fit-content;

    &:hover,
    &:focus {
      background: ${COLOR.secondary[80]};
    }
  }

  .toolbar-text {
    margin-left: 4px;
    font-size: 14px;
  }

  .toolbar-right {
    display: flex;

    .margin-0 {
      margin: 0;
    }
  }

  .background-music-name {
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-input {
    display: ${(props) => (props.openbonus ? 'block' : 'none')};

    .MuiInputBase-root {
      border-radius: 24px;
      background: ${COLOR.white};
    }

    .MuiInputBase-input {
      padding: 8px 8px 8px 12px;
      height: 16px;
    }
  }

  @media only screen and (min-width: 1440px) {
    .more-button,
    .group-import-data-more-button,
    .group-search-and-replace-more-button {
      display: ${(props) => (props.allowresponsive ? 'none' : 'flex')};
    }

    .group-import-data,
    .group-search-and-replace,
    .group-adjust-background-music,
    .group-adjust-reading-style,
    .group-adjust-audio {
      display: flex;
    }
  }

  @media only screen and (min-width: 1395px) and (max-width: 1440px) {
    // .more-button,
    .group-search-and-replace,
    .group-adjust-background-music,
    .group-adjust-reading-style,
    .group-adjust-audio,
    .group-import-data-more-button {
      display: flex;
    }

    .more-button {
      display: none;
    }

    // .group-import-data,
    .group-search-and-replace-more-button {
      display: ${(props) => (props.allowresponsive ? 'none' : 'flex')};
    }
  }

  @media only screen and (min-width: 1340px) and (max-width: 1395px) {
    .more-button,
    .group-try-listening,
    .group-adjust-background-music,
    .group-adjust-reading-style,
    .group-adjust-audio,
    .group-import-data-more-button,
    .group-search-and-replace-more-button {
      display: flex;
    }

    .group-import-data,
    .group-search-and-replace {
      display: ${(props) => (props.allowresponsive ? 'none' : 'flex')};
    }
  }

  @media only screen and (min-width: 1215px) and (max-width: 1340px) {
    .more-button,
    .group-adjust-reading-style,
    .group-adjust-audio,
    .group-import-data-more-button,
    .group-search-and-replace-more-button {
      display: flex;
    }

    .group-import-data,
    .group-search-and-replace,
    .group-adjust-background-music {
      display: ${(props) => (props.allowresponsive ? 'none' : 'flex')};
    }
  }

  @media only screen and (min-width: 1010px) and (max-width: 1215px) {
    .more-button,
    .group-adjust-audio,
    .group-import-data-more-button,
    .group-search-and-replace-more-button {
      display: flex;
    }

    .group-import-data,
    .group-search-and-replace,
    .group-adjust-background-music,
    .group-adjust-reading-style {
      display: ${(props) => (props.allowresponsive ? 'none' : 'flex')};
    }
  }

  @media only screen and (max-width: 1010px) {
    .more-button,
    .group-import-data-more-button,
    .group-search-and-replace-more-button {
      display: flex;
    }

    .group-import-data,
    .group-search-and-replace,
    .group-adjust-background-music,
    .group-adjust-reading-style,
    .group-adjust-audio {
      display: ${(props) => (props.allowresponsive ? 'none' : 'flex')};
    }
  }
`;

const StyledWebToolbar = styled('div')(() => ({
  '.web-border-right': {
    borderRight: `1px solid ${COLOR.light}`,
  },

  '.button-img': {
    width: '20px',
    height: '20px',
  },

  '.custom-button': {
    margin: '0 8px',
  },

  '.toolbar-text': {
    marginLeft: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },

  '.toolbar-text-disable': {
    color: COLOR.light,
  },

  '.toolbar-row': {
    display: 'flex',
    flexWrap: 'wrap !important',
    alignItems: 'flex-start !important',
    marginTop: '10px',
  },

  '.search-input': {
    width: '190px',
  },

  '.redo-button': {
    marginRight: '8px',
  },

  '.action-icon-button': {
    padding: '3px 8px',
  },
}));

const StyledMobileToolbar = styled('div')(() => ({
  '.row1': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '.row2': {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
  },

  '.row3': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },

  '.row4': {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },

  '.button-img': {
    width: '18px',
    height: '18px',
  },

  '.toolbar-text': {
    fontSize: '12px',
    fontWeight: '500',
    marginLeft: '3px',
  },

  '.toolbar-text-disable': {
    color: COLOR.light,
  },

  '.action-icon-button': {
    padding: '3px 5px',
  },

  '.mobile-voice': {
    padding: '10px 0',
    marginLeft: '5px',
  },
}));

const StyleMoreButtonPopper = styled(Popper)`
  margin-top: 8px;
  padding: 8px;
  border-radius: 12px;
  box-shadow: ${TOOLBAR_BOX_SHADOW};
  background-color: ${COLOR.white} !important;
  z-index: 1040;
  width: auto;
  transform: translate(15%, auto) !important;
  max-width: calc(100% - 100px);

  @media only screen and (max-width: 1010px) {
    max-width: calc(100% - 120px);
    width: fit-content;
  }

  .search-input {
    .MuiInputBase-root {
      border-radius: 24px;
      background: ${COLOR.white};
    }

    .MuiInputBase-input {
      padding: 8px 8px 8px 12px;
      height: 18px;
    }
  }

  .margin-right-bonus-toolbar {
    margin-right: 12px;
  }

  .replace-button {
    border-radius: 24px;
    padding: 8px 12px 8px 16px;
    height: 36px;
    text-transform: none;
    border-color: ${COLOR.light};
    font-weight: 700;
  }
`;

const StyleContentPopper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding-bottom: 2px;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLOR.divider};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${COLOR.light};
    border-radius: 8px;
  }

  .custom-button {
    text-transform: none;
    border-radius: 12px;
    padding: 4px 6px;
    min-width: fit-content;

    &:hover,
    &:focus {
      background: ${COLOR.secondary[80]};
    }
  }

  .divider {
    margin: 0 10px;
    border: 1px solid ${COLOR.secondary[80]};
  }

  .group {
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .background-music-name {
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-and-replace-more-button-web {
    font-weight: 400;
  }

  @media only screen and (min-width: 1010px) {
    .search-and-replace-more-button-mobile {
      display: none;
    }

    .search-and-replace-more-button-web {
      display: flex;
    }
  }

  @media only screen and (max-width: 1010px) {
    .search-and-replace-more-button-mobile {
      display: flex;
    }

    .search-and-replace-more-button-web {
      display: none;
    }
  }

  @media only screen and (min-width: 1440px) {
    .group-import-data-more-button,
    .group-search-and-replace-more-button,
    .group-adjust-background-music-more-button,
    .group-adjust-reading-style-more-button,
    .group-adjust-audio-more-button {
      display: none;
    }
  }

  @media only screen and (min-width: 1395px) and (max-width: 1440px) {
    .group-import-data-more-button {
      display: flex;
    }

    .group-search-and-replace-more-button,
    .group-adjust-background-music-more-button,
    .group-adjust-reading-style-more-button,
    .group-adjust-audio-more-button {
      display: none;
    }
  }

  @media only screen and (min-width: 1340px) and (max-width: 1395px) {
    .group-import-data-more-button,
    .group-search-and-replace-more-button {
      display: flex;
    }

    .group-adjust-background-music-more-button,
    .group-adjust-reading-style-more-button,
    .group-adjust-audio-more-button {
      display: none;
    }
  }

  @media only screen and (min-width: 1215px) and (max-width: 1340px) {
    .group-import-data-more-button,
    .group-search-and-replace-more-button,
    .group-adjust-reading-style-more-button {
      display: flex;
    }

    .group-adjust-reading-style-more-button,
    .group-adjust-audio-more-button {
      display: none;
    }
  }

  @media only screen and (min-width: 1010px) and (max-width: 1215px) {
    .group-import-data-more-button,
    .group-search-and-replace-more-button,
    .group-adjust-background-music-more-button,
    .group-adjust-reading-style-more-button {
      display: flex;
    }

    .group-adjust-audio-more-button {
      display: none;
    }
  }

  @media only screen and (max-width: 1010px) {
    .group-import-data-more-button,
    .group-search-and-replace-more-button,
    .group-adjust-background-music-more-button,
    .group-adjust-reading-style-more-button,
    .group-adjust-audio-more-button {
      display: flex;
    }
  }
`;

export {
  StyledToolbar,
  StyledWebToolbar,
  StyledMobileToolbar,
  StyleMoreButtonPopper,
  StyleContentPopper,
};
