import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledTimeLine = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  height: 100%;
  margin-left: 8px;

  .effect-row-name {
    display: flex;
    padding: 4px 24px;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.charcoal};
    height: 32px;
    border-radius: 8px;

    .effect-row-name-avatar-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${COLOR.bluev2[16]};
      color: ${COLOR.bluev2[100]};
      font-size: 12px;
      font-weight: 700;
    }

    .effect-row-name-avatar-text {
      font-size: 16px;
      line-height: 16px;
      font-weight: 900;
      color: ${COLOR.darkBlue};
    }
  }

  .timeline-editor {
    height: 100%;

    .timeline-editor-time-area {
      .timeline-editor-time-unit {
        border-right: 1px solid ${COLOR.darkV2[60]} !important;
      }

      .timeline-editor-time-area-interact {
        height: 24px !important;
      }
    }

    .timeline-editor-time-area {
      > :first-of-type {
        > :first-of-type {
          > :first-of-type {
            height: 24px !important;
          }
        }
      }
    }

    .timeline-editor-edit-area {
      .timeline-editor-edit-row {
        background-image: none !important;
      }

      .timeline-editor-action {
        width: auto;
        height: 100%;
        padding: 4px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: ${COLOR.bluev2[16]} !important;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .timeline-editor-action-selected {
        width: auto;
        height: 100%;
        padding: 4px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: ${COLOR.bluev2[16]} !important;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid ${COLOR.bluev2[100]} !important;
      }
    }

    :hover {
      .timeline-editor-edit-area {
        > :first-of-type {
          > :first-of-type {
            ::-webkit-scrollbar {
              height: 8px !important;
            }

            :: -webkit-scrollbar-thumb {
              background-color: ${COLOR.secondary[100]} !important;
              border-radius: 16px !important;
            }
          }
        }
      }
    }
  }
`;
