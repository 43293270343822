import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DownloadRounded,
  EditRounded,
  FlagRounded,
  PlayArrowRounded,
  DeleteRounded,
  BlockRounded,
  CancelRounded,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import dataSenses from '@src/services/dataSenses';
import apis from '@src/apis';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { downloadFileUsingUrl } from '@src/utils/file';
import { DOWNLOAD_QUOTA_EXCEEDED_CODE } from '@src/constants/tts';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import { REQUEST_TYPE } from '@src/constants/request';
import { REQUEST_STATUS } from '@src/constants/voice';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import { COLOR } from '@src/styles/color';
import { StyledDrawer, StyledListAction } from './index.style';
import DeleteRequestDialog from './Actions/DeleteRequestDialog';

const ActionDrawer = ({ open, onClose, request }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    id: requestId,
    title,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    userId,
  } = request;
  const { displayStatus } = useRequestAnimationStatus(request);
  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] = useState(false);

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );
  const isDisabledAudio =
    !audioLink || audioExpired || displayStatus !== REQUEST_STATUS.SUCCESS;

  const { getFeatureValue } = useFeatureFlags();
  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );

  const handleDownloadAudio = async (e) => {
    e.stopPropagation();
    dataSenses.sendDownloadAudioEvent({
      userId,
      requestType: REQUEST_TYPE.STUDIO,
    });
    const res = isLimitDownloadFreePackage
      ? await apis.requests.getAudioDownloadUrl(requestId)
      : await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      onClose();
      return;
    }

    if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const commonActions = [
    {
      icon: FlagRounded,
      color: '#1E164E',
      label: 'Báo lỗi',
      onClick: () => {},
    },
    {
      icon: EditRounded,
      color: '#1E164E',
      label: 'Đổi tên tiêu đề',
      onClick: () => {},
    },
    {
      icon: DeleteRounded,
      color: '#FC5555',
      label: 'Xóa',
      onClick: () => setOpenDeleteRequestDialog(true),
    },
  ];

  const moreActions = isDisabledAudio
    ? [
        {
          icon: BlockRounded,
          color: '#FC5555',
          label: 'Thời gian lưu trữ audio (3 ngày) đã hết hạn',
          onClick: () => {},
        },
        ...commonActions,
      ]
    : [
        {
          icon: DownloadRounded,
          color: '#007AFF',
          label: 'Tải xuống Audio',
          onClick: handleDownloadAudio,
        },
        {
          icon: PlayArrowRounded,
          color: '#1E164E',
          label: 'Phát Audio',
          onClick: () => {},
        },
        ...commonActions,
      ];

  return (
    <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
      <Box className="drawer-title">
        <Typography className="title">{request?.title}</Typography>
        <CancelRounded sx={{ color: COLOR.darkV2[32] }} onClick={onClose} />
      </Box>
      <Box className="drawer-content">
        <StyledListAction>
          {moreActions.map((action) => (
            <Box
              key={action.label}
              className="action"
              role="button"
              tabIndex="0"
              onClick={action.onClick}
            >
              <action.icon sx={{ color: action.color }} />
              <Typography sx={{ color: action.color }}>
                {t(action.label)}
              </Typography>
            </Box>
          ))}
        </StyledListAction>
      </Box>
      <DeleteRequestDialog
        request={request}
        open={openDeleteRequestDialog}
        onClose={() => setOpenDeleteRequestDialog(false)}
      />
    </StyledDrawer>
  );
};

export default ActionDrawer;
