import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Tooltip from '@src/components/Tooltip';
import { AccessTime } from '@mui/icons-material';
import { BREAK_TIME } from '@src/constants/voice';
import actions from '@src/redux/actions';
import { checkValidNumber } from '@src/services/checkValid';
import { StyledBreakTime, StyledMenuItem, StyledPopper } from './index.style';

const BreakTime = ({
  currentBreakTime = 1,
  isSentence,
  disabled,
  width,
  onChange,
  tooltipTitle,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [isFocus, setIsFocus] = useState(false);
  const getTextValue = (value) => {
    if (value <= 0) return t('breakTime');

    return `${value}s`;
  };
  const [breakTime, setBreakTime] = useState(currentBreakTime);
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState(getTextValue(currentBreakTime));
  const [openTooltip, setOpenTooltip] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const handleCloseBreakTime = (value) => {
    setBreakTime(value);
    setTextValue(getTextValue(value));
    setOpen(false);
    inputRef.current?.blur();
  };

  const handleSelectedValue = (value) => {
    if (onChange) onChange(value);
    handleCloseBreakTime(value);
  };

  const getValidBreakTime = (value) => {
    const breakTimeNumber = Number(value);
    if (value < 0 || value > 60) return currentBreakTime;
    if (!Number.isInteger(breakTimeNumber))
      return parseFloat(parseFloat(value).toFixed(1));
    return breakTimeNumber;
  };

  const handleKeyDown = (e) => {
    const { value } = e.target;

    if (e.key === 'Enter') {
      const isNumber = checkValidNumber(value);
      if (!isNumber) {
        dispatch(
          actions.noti.push({
            severity: 'warning',
            message: 'breakTimeIsNotNumber',
          }),
        );
        handleCloseBreakTime(currentBreakTime);
        return;
      }
      if (value < 0 || value > 60) {
        dispatch(
          actions.noti.push({
            severity: 'warning',
            message: 'breakTimeInvalidRange',
          }),
        );
        handleCloseBreakTime(currentBreakTime);
        return;
      }

      const validBreakTime = getValidBreakTime(value);
      handleSelectedValue(validBreakTime);
    }
  };

  const handleFocusInput = () => {
    setTextValue(breakTime);
    setIsFocus(true);
  };

  const handleBlurInput = () => {
    setIsFocus(false);
    handleCloseBreakTime(currentBreakTime);
  };

  const handleChangeInput = (event) => {
    setBreakTime(event.target.value);
  };

  const handleIsOptionEqualToValue = (option, value) => {
    if (value && typeof value === 'string') {
      const lastWord = value.split(' ').pop();
      return option === getValidBreakTime(lastWord);
    }
    return option === value;
  };

  useEffect(() => {
    setTextValue(getTextValue(breakTime));
  }, [language]);

  useEffect(() => {
    // if (currentBreakTime) {
    setBreakTime(currentBreakTime);
    setTextValue(getTextValue(currentBreakTime));
    // }
  }, [currentBreakTime]);

  return (
    <StyledBreakTime
      isSentence={isSentence}
      isFocus={isFocus}
      active={open}
      disabled={disabled}
      width={width}
    >
      <AccessTime
        color={disabled ? 'disabled' : 'iconPrimary'}
        sx={{ fontSize: 20, opacity: 0.8 }}
      />
      <Autocomplete
        PopperComponent={StyledPopper}
        value={textValue}
        isOptionEqualToValue={handleIsOptionEqualToValue}
        open={open}
        disabled={disabled}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={BREAK_TIME}
        filterOptions={(option) => option}
        disableClearable
        renderInput={(params) => (
          <Tooltip
            arrow
            title={t(tooltipTitle)}
            placement="top"
            open={openTooltip}
            disableHoverListener
            onMouseEnter={() => setOpenTooltip(!open)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <TextField
              {...params}
              value={breakTime}
              inputRef={inputRef}
              className="text-field"
              onFocus={handleFocusInput}
              onBlur={handleBlurInput}
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
              placeholder={t('breakTime')}
              color="iconPrimary"
            />
          </Tooltip>
        )}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <StyledMenuItem
            key={option}
            active={String(breakTime) === String(option)}
            onClick={() => handleSelectedValue(option)}
          >
            <Typography variant="body2" className="text">
              {option}s
            </Typography>
          </StyledMenuItem>
        )}
      />
    </StyledBreakTime>
  );
};
export default BreakTime;
