import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import apis from '@src/apis';
import actions from '@src/redux/actions';
import Table from '@src/components/Table';
import { delimitNumber } from '@src/utils/number';
import { PRICE_UNIT } from '@src/constants/payment';
import {
  AFFILIATE_TABS,
  AFFILIATE_TRANSACTION_STATUS,
} from '@src/constants/affiliate';
import { PAGINATION_LIMIT } from '@src/constants';
import i18n from '@src/languages';
import { CommissionStyled, StyledChip } from './index.style';

const TransactionList = ({ activeTab }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState('createdAt_desc');

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = i18n;

  const handleChangePage = (newPage) => setPage(newPage);

  const handleChangeSort = (newSort) => {
    setSort(newSort);
    handleChangePage(1);
  };

  const fetchTransactions = async () => {
    setIsLoading(true);
    const response = await apis.affiliateTransaction.getAffiliateTransaction({
      offset: (page - 1) * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
      sort,
    });
    setIsLoading(false);
    if (!response?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'getAffiliateTransactionFailed',
        }),
      );
    } else {
      setTransactions(response?.result?.transactions);
      setTotal(response?.result?.total);
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case AFFILIATE_TRANSACTION_STATUS.PENDING:
        return <StyledChip color="primary">{t('pending')}</StyledChip>;
      case AFFILIATE_TRANSACTION_STATUS.APPROVED:
        return <StyledChip color="success">{t('approved')}</StyledChip>;
      case AFFILIATE_TRANSACTION_STATUS.REJECTED:
        return <StyledChip color="red">{t('rejected')}</StyledChip>;
      default:
        return '';
    }
  };

  const columns = [
    {
      field: 'fullName',
      title: t('fullName'),
      align: 'left',
      sort: true,
      render: (row) => row.user?.name,
    },
    {
      field: 'phoneNumber',
      title: t('phoneNumber'),
      align: 'left',
      sort: true,
      render: (row) => row.user?.phoneNumber,
    },
    {
      field: 'email',
      title: t('email'),
      align: 'left',
      sort: true,
      render: (row) => row.user?.email,
    },
    {
      field: 'address',
      title: t('address'),
      align: 'left',
      sort: true,
      render: (row) => row.user?.address,
    },
    {
      field: 'bankName',
      title: t('bankName'),
      align: 'left',
      sort: true,
      render: (row) => row.bank?.name,
    },
    {
      field: 'bankAccountOwner',
      title: t('bankAccountOwner'),
      align: 'left',
      sort: true,
      render: (row) => row.bank?.accountOwner,
    },
    {
      field: 'bankAccountNumber',
      title: t('bankAccountNumber'),
      align: 'left',
      sort: true,
      render: (row) => row.bank?.accountNumber,
    },
    {
      field: 'status',
      title: t('status'),
      sort: true,
      sortable: true,
      align: 'left',
      render: (row) => renderStatus(row.status),
    },
    {
      field: 'money',
      title: t('amountOfMoney'),
      sortable: true,
      align: 'left',
      sort: true,
      render: (row) => (
        <CommissionStyled color="danger">{`${delimitNumber(
          row.money,
          language,
        )} ${PRICE_UNIT.VND}`}</CommissionStyled>
      ),
    },
    {
      field: 'createdAt',
      title: t('withdrawalDate'),
      sortable: true,
      align: 'left',
      sort: true,
      render: (row) => moment(row.createdAt).format('DD/MM/YYYY - HH:mm'),
    },
  ];

  useEffect(() => {
    if (activeTab === AFFILIATE_TABS.AFFILIATE_TRANSACTION) fetchTransactions();
  }, [activeTab, page, sort]);

  return (
    <div>
      <Table
        showNumber
        columns={columns}
        data={transactions}
        loading={isLoading}
        sort={sort}
        page={page}
        total={total}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
      />
    </div>
  );
};

export default TransactionList;
