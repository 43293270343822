import React from 'react';
import { useTranslation } from 'react-i18next';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Typography } from '@mui/material';

import { SUBTITLE_SAMPLE_LINK } from '@src/constants/dubbing';
import { StyledSampleFileReference } from './index.style';

const SampleFileReference = () => {
  const { t } = useTranslation();

  return (
    <StyledSampleFileReference>
      <Typography className="sample-text">
        {t('referenceExampleFile')}
      </Typography>
      <a
        href={SUBTITLE_SAMPLE_LINK}
        download
        className="sample-file"
        onClick={(e) => e.stopPropagation()}
      >
        <CloudDownloadIcon />
        {t('sampleFileName')}.srt
      </a>
    </StyledSampleFileReference>
  );
};

export default SampleFileReference;
