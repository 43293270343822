import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { REQUEST_STATUS } from '@src/constants/voice';

const END_POINT = 99;

const TIME_LOAD_THE_REST_WHEN_SUCCESS = 2000; // 2 seconds

const calculateProgress = (characters) => {
  if (characters < 100) return 16 * 1000; // 16s
  if (characters < 500) return 27 * 1000; // 27s
  if (characters < 1000) return 30 * 1000; // 30s
  if (characters < 2000) return (characters / 1000) * 30 * 1000; // 30s/1k characters
  if (characters < 3000) return (characters / 1000) * 15 * 1000; // 15s/1k characters
  if (characters < 6000) return (characters / 1000) * 10 * 1000; // 10s/1k characters
  if (characters < 10000) return (characters / 1000) * 6 * 1000; // 6s/1k characters
  if (characters < 30000) return (characters / 1000) * 3 * 1000; // 3s/1k characters
  if (characters < 60000) return (characters / 1000) * 2 * 1000; // 2s/1k characters

  return (characters / 1000) * 1 * 1000; // 1s/1k characters
};

const getPredictedEndTime = (characters, startTime, seconds) => {
  const predictedCharacters = characters || seconds * 20;

  return startTime + calculateProgress(predictedCharacters);
};

const getEndTimeWhenRequestSuccess = (
  characters,
  startTime,
  endTime,
  seconds,
) => {
  const predictedEndTime = getPredictedEndTime(characters, startTime, seconds);
  const twoSecondsAfterEndTime = endTime + TIME_LOAD_THE_REST_WHEN_SUCCESS;
  return Math.min(predictedEndTime, twoSecondsAfterEndTime);
};

const getDisplayStatus = (currentTime, request) => {
  const { processingAt, status, characters, endedAt, seconds } = request;
  const processingTime = moment(processingAt).valueOf();
  const endTime = moment(endedAt).valueOf();
  const predictedEndTime = getPredictedEndTime(
    characters,
    processingTime,
    seconds,
  );

  if (status === REQUEST_STATUS.SUCCESS) {
    // If request is now success, run animation for 2 more seconds
    const endAnimationTime = getEndTimeWhenRequestSuccess(
      characters,
      processingTime,
      endTime,
      seconds,
    );
    const endTimeProgress =
      (100 * (endTime - processingTime)) / (predictedEndTime - processingTime);
    let progress = endTimeProgress;
    if (endTimeProgress < 100) {
      progress =
        endTimeProgress +
        ((currentTime - endTime) / (endAnimationTime - endTime)) *
          (100 - endTimeProgress);
    }

    return {
      status: progress < END_POINT ? REQUEST_STATUS.IN_PROGRESS : status,
      progress: Math.max(Math.round(progress), 0),
    };
  }

  const elapsed = currentTime - processingTime;
  const progress = Math.round(
    (elapsed / (predictedEndTime - processingTime)) * 100,
  );

  return { status, progress: Math.max(Math.min(progress, END_POINT), 0) };
};

const useRequestAnimationStatus = (request) => {
  const [displayStatus, setDisplayStatus] = useState(request.status);
  const [displayProgress, setDisplayProgress] = useState(0);

  const requestRef = useRef(request);

  const animateProgress = () => {
    const currentTime = Date.now();
    const requestInfo = requestRef.current || {};
    if (!requestInfo.id) return;

    const { progress, status } = getDisplayStatus(currentTime, requestInfo);
    setDisplayStatus(status);
    setDisplayProgress(progress);

    if (requestInfo.status === REQUEST_STATUS.IN_PROGRESS && progress < 99) {
      requestAnimationFrame(() => animateProgress());
    }
    if (
      requestInfo.status === REQUEST_STATUS.SUCCESS &&
      status !== REQUEST_STATUS.SUCCESS
    ) {
      requestAnimationFrame(() => animateProgress());
    }
  };

  useEffect(() => {
    if (request.id !== requestRef.current?.id) {
      // Reset animation when request is changed
      setDisplayStatus();
      setDisplayProgress(0);
    }
    requestRef.current = request;
    if (request.id && request.processingAt) animateProgress();
  }, [
    request.id,
    request.processingAt,
    request.status,
    request.characters,
    request.endedAt,
  ]);

  return { displayStatus, displayProgress };
};

export default useRequestAnimationStatus;
