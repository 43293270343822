import React from 'react';
import { Typography } from '@mui/material';
import { formatAudioTime } from '@src/utils/time';
import { COLOR } from '@src/styles/color';
import { StyledAudio, StyledAudioSlider } from './index.style';

const Audio = (props) => {
  const {
    currentTime,
    duration,
    onSeeked,
    isRunStreamAudio,
    disabled,
    hiddenDuration = false,
    color,
    hightThumb,
    widthThumb,
  } = props;

  return (
    <StyledAudio>
      {!hiddenDuration && (
        <Typography variant="body2">
          {formatAudioTime(isRunStreamAudio ? 0 : currentTime)}
        </Typography>
      )}
      <StyledAudioSlider
        value={currentTime * 1000}
        step={1}
        min={0}
        max={duration === 0 ? 1 : duration * 1000}
        onChange={(e, value) => onSeeked(value / 1000)}
        disabled={disabled}
        sx={{
          color: color || COLOR.primary,
          '& .MuiSlider-thumb': {
            height: hightThumb || '20px',
            width: widthThumb || '20px',
            backgroundColor: color || COLOR.white,
            border: `1px solid currentColor`,
          },
        }}
      />
      {!hiddenDuration && (
        <Typography variant="body2">
          {formatAudioTime(isRunStreamAudio ? 0 : duration)}
        </Typography>
      )}
    </StyledAudio>
  );
};

export default Audio;
