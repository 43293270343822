import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';

import actions from '@src/redux/actions';
import CustomSwitch from '@src/components/Switch';
import { COLOR } from '@src/styles/color';
import { checkFeaturePermission } from '@src/services/tts';
import { FEATURE } from '@src/constants/package';
import { CONVERT_METHOD } from '@src/constants/tourGuide';
import { TTS_VERSION } from '@src/constants/tts';
import { INIT_NOTIFICATION_DIALOG } from '@src/constants/dialog';
import { StyledProcessingMethods } from './index.style';

const ProcessingMethods = ({ openSentences, onOpenSentences }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { ttsUser } = useSelector((state) => state.user);
  const { advanceFeature } = useSelector((state) => state.synthesisRequest);
  const { voice } = useSelector((state) => state.synthesisRequest);

  const hasTtsBySentence =
    ttsUser.id &&
    checkFeaturePermission(ttsUser.features, FEATURE.TTS_BY_SENTENCE);

  const handleCloseNotificationDialog = () =>
    dispatch(
      actions.dialog.displayDialog({
        notificationDialog: INIT_NOTIFICATION_DIALOG,
      }),
    );

  const handleChangeProcessSwitch = (checkedSwitch) => {
    if (
      advanceFeature &&
      checkedSwitch &&
      voice?.version === TTS_VERSION.EMPHASIS
    ) {
      dispatch(
        actions.dialog.displayDialog({
          notificationDialog: {
            name: 'cannotTtsBySentence',
            title: t('cannotTtsBySentence'),
            description: t('cannotTtsBySentenceDes'),
            variant: 'warning',
            open: true,
            actionComponent: (
              <Button
                variant="contained"
                onClick={handleCloseNotificationDialog}
              >
                {t('understood')}
              </Button>
            ),
          },
        }),
      );
      return;
    }

    if (!hasTtsBySentence) {
      dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
      return;
    }

    onOpenSentences(checkedSwitch);
  };

  return (
    <StyledProcessingMethods
      id={CONVERT_METHOD}
      hasttsbysentence={hasTtsBySentence ? 1 : 0}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        className="switch-wrapper"
      >
        <Typography variant="body2" className="sentence-process">
          {t('splitSentence')}
        </Typography>
        <CustomSwitch
          className="custom-switch"
          checked={openSentences}
          noneCheckedColor={COLOR.secondary[80]}
          checkedColor={COLOR.success}
          onChange={handleChangeProcessSwitch}
        />
      </Stack>
    </StyledProcessingMethods>
  );
};

export default ProcessingMethods;
