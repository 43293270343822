export const actionTypes = {
  SET_DEFAULT_GLOBAL_VOICE: 'SET_DEFAULT_GLOBAL_VOICE',
  CHANGE_FILTER_VOICE: 'CHANGE_FILTER_VOICE',
  RESET_FILTER_VOICE: 'RESET_FILTER_VOICE',
  GET_DUBBING_VOICES: 'GET_DUBBING_VOICES',
  GET_DUBBING_VOICES_SUCCESS: 'GET_DUBBING_VOICES_SUCCESS',
};

const setDefaultGlobalVoice = (voiceCode) => ({
  type: actionTypes.SET_DEFAULT_GLOBAL_VOICE,
  voiceCode,
});

const changeFilterVoice = (value) => ({
  type: actionTypes.CHANGE_FILTER_VOICE,
  value,
});

const resetFilterVoice = (packageCode) => ({
  type: actionTypes.RESET_FILTER_VOICE,
  packageCode,
});

const getDubbingVoices = () => ({
  type: actionTypes.GET_DUBBING_VOICES,
});

const getDubbingVoicesSuccess = (dubbingVoices) => ({
  type: actionTypes.GET_DUBBING_VOICES_SUCCESS,
  dubbingVoices,
});

export {
  setDefaultGlobalVoice,
  changeFilterVoice,
  resetFilterVoice,
  getDubbingVoices,
  getDubbingVoicesSuccess,
};
