import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import actions from '@src/redux/actions';
import { Typography } from '@mui/material';
import { PauseOutlined, PlayCircleFilledRounded } from '@mui/icons-material';
import { StyledTextIcon } from './index.style';

const PlayAudioButton = ({ request, onClick, audioRef, langEng }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    id: requestId,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
  } = request;

  const {
    audioLink: audioPlayerLink,
    isPlaying,
    duration,
  } = useSelector((state) => state.audioPlayer);
  const isShowPlaying =
    audioLink && audioPlayerLink === audioLink && isPlaying && !!duration;

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );

  const isDisabledAudio = !audioLink || audioExpired;

  const handleChangePlayAudio = () => {
    if (onClick) onClick();
    if (audioPlayerLink === audioLink) {
      dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    } else {
      // eslint-disable-next-line no-param-reassign
      audioRef.current.src = SILENCE_AUDIO_URL;
      audioRef.current.load();
      audioRef.current.play();
      dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      dispatch(actions.audioPlayer.updateSelectedAudioRequest(requestId));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      dispatch(actions.audioPlayer.updateShowAudioPlayer(true));
      dispatch(actions.audioPlayer.updatePreviewRequestInfo(request));
    }
  };

  return (
    <StyledTextIcon
      disableRipple
      onClick={handleChangePlayAudio}
      disabled={isDisabledAudio}
      langEng={langEng}
    >
      {isShowPlaying ? (
        <PauseOutlined className="icon" />
      ) : (
        <PlayCircleFilledRounded className="icon" />
      )}
      <Typography className="text-action">{t('playAudio')}</Typography>
    </StyledTextIcon>
  );
};

export default PlayAudioButton;
