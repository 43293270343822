import { styled } from '@mui/material/styles';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyledClientPause = styled('div')`
  .dialog-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 5px 20px;

    .button {
      text-transform: initial;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .button {
      padding: 5px 10px;
    }
  }
`;

const StyledDialogContent = styled('div')`
  .list-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
  }

  .number-input-wrapper {
    display: flex;
    justify-content: end;
  }

  .number-input {
    width: 158px;
  }

  .dashed-border {
    .MuiOutlinedInput-notchedOutline {
      border-style: solid;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .list-item {
      padding: 8px 0;
    }
  }
`;

export { StyledClientPause, StyledDialogContent };
