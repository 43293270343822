/* eslint-disable no-param-reassign */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import actions from '@src/redux/actions';

import {
  PlayArrowRounded,
  FileDownloadOutlined,
  PauseOutlined,
  TuneRounded,
  FlagRounded,
} from '@mui/icons-material';
import apis from '@src/apis';
import { downloadFileUsingUrl } from '@src/utils/file';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { AUDIO_EFFECTS_URL } from '@src/configs';
import { MAX_AUDIO_RETENTION_PERIOD } from '@src/constants';
import { Box } from '@mui/material';
import {
  DOWNLOAD_QUOTA_EXCEEDED_CODE,
  SILENCE_AUDIO_URL,
} from '@src/constants/tts';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { REQUEST_STATUS } from '@src/constants/voice';
import Tooltip from '@src/components/Tooltip';
import useRequestAnimationStatus from '@src/hooks/useRequestAnimationStatus';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import classNames from 'classnames';
import dataSenses from '@src/services/dataSenses';
import { REQUEST_TYPE } from '@src/constants/request';
import { StyledIconButton, StyledActionTooltip } from './index.style';

const RequestAction = ({
  request,
  onOpenErrorReport,
  audioRef,
  playable,
  isDisabledErrorReport = false,
}) => {
  const isDubbingRoute = useCheckDubbingRoute();
  const { t } = useTranslation();
  const {
    id: requestId,
    title,
    characters,
    voice,
    audioLink,
    retentionPeriod = MAX_AUDIO_RETENTION_PERIOD,
    createdAt,
    status,
    type,
    seconds,
    sentencesVoiceCode,
    userId,
  } = request;

  const {
    audioLink: audioPlayerLink,
    isPlaying,
    duration,
  } = useSelector((state) => state.audioPlayer);

  const { getFeatureValue } = useFeatureFlags();

  const useNewEffectGuide = getFeatureValue(FEATURE_KEYS.NEW_EFFECT_GUIDE);
  const isLimitDownloadFreePackage = getFeatureValue(
    FEATURE_KEYS.LIMIT_DOWNLOAD_FREE_PACKAGE,
  );
  const isDownloadAudioLink = getFeatureValue(FEATURE_KEYS.DOWNLOAD_AUDIO_LINK);

  const dispatch = useDispatch();

  const { displayStatus } = useRequestAnimationStatus(request);

  const handleOpenErrorReport = (e) => {
    e.stopPropagation();
    onOpenErrorReport({
      id: requestId,
      title,
      characters,
      voice,
      type,
      seconds,
      sentencesVoiceCode,
    });
  };

  const handleChangePlayAudio = (e) => {
    e.stopPropagation();
    if (audioPlayerLink === audioLink) {
      dispatch(actions.audioPlayer.updateStatus(!isPlaying));
    } else {
      audioRef.current.src = SILENCE_AUDIO_URL;
      audioRef.current.load();
      audioRef.current.play();
      dispatch(actions.audioPlayer.updateAudioLink(audioLink));
      dispatch(actions.audioPlayer.updateSelectedAudioRequest(requestId));
      dispatch(actions.audioPlayer.updateStatus(true));
      dispatch(actions.audioPlayer.updateMetaData({ currentTime: 0 }));
      dispatch(actions.audioPlayer.updateShowAudioPlayer(true));
      dispatch(actions.audioPlayer.updatePreviewRequestInfo(request));
    }
  };

  const handleOpenAudioEffects = (e) => {
    e.stopPropagation();
    window.open(`${AUDIO_EFFECTS_URL}/?audio=${audioLink}`, '_blank');
  };

  const handleOpenNewAudioEffects = async (e) => {
    e.stopPropagation();
    const res = await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      window.open(
        `${AUDIO_EFFECTS_URL}/?audio=${res.result?.audio || audioLink}`,
        '_blank',
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const handleDownloadAudio = async (e) => {
    e.stopPropagation();
    dataSenses.sendDownloadAudioEvent({
      userId,
      requestType: REQUEST_TYPE.STUDIO,
    });
    const res = isLimitDownloadFreePackage
      ? await apis.requests.getAudioDownloadUrl(requestId)
      : await apis.requests.getPresignedAudioUrl(requestId);
    if (res?.status) {
      const audioUrl = res.result?.audio;
      downloadFileUsingUrl(audioUrl);
      return;
    }

    if (res?.errorCode === DOWNLOAD_QUOTA_EXCEEDED_CODE) {
      dispatch(actions.user.updateShowBlockDownloadDialog(true));
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'error',
        message: 'downloadError',
        value: title,
      }),
    );
  };

  const isShowPlaying =
    audioLink && audioPlayerLink === audioLink && isPlaying && !!duration;

  const audioExpired = moment().isAfter(
    moment(createdAt).add(retentionPeriod, 'days'),
  );
  const isDisabledAudio =
    !audioLink || audioExpired || displayStatus !== REQUEST_STATUS.SUCCESS;

  const getTitleActionTooltip = (action) => {
    // Request failed and processing
    if (status !== REQUEST_STATUS.SUCCESS) return t('');
    // Audio expired
    if (audioExpired) return t('notiAudioExpired', { retentionPeriod });

    return t(action);
  };

  return (
    <div className="action-cell">
      {playable && (
        <StyledActionTooltip
          title={getTitleActionTooltip('playAudio')}
          arrow
          placement="top"
          disabled={isDisabledAudio}
        >
          <span>
            <StyledIconButton
              onClick={handleChangePlayAudio}
              disabled={isDisabledAudio}
              height="30px"
              width="30px"
              padding="12px"
            >
              {isShowPlaying ? (
                <PauseOutlined style={{ width: '30px' }} className="icon" />
              ) : (
                <PlayArrowRounded
                  style={{ fontSize: '30px' }}
                  className="icon"
                />
              )}
            </StyledIconButton>
          </span>
        </StyledActionTooltip>
      )}

      <StyledActionTooltip
        title={getTitleActionTooltip('download')}
        arrow
        placement="top"
        disabled={isDisabledAudio}
      >
        <span>
          {isDownloadAudioLink ? (
            <div
              className={classNames('download-wrapper', {
                disabled: isDisabledAudio,
              })}
            >
              <a
                href={audioLink}
                download
                title={t('download')}
                className="download-link"
                onClick={(e) => e.stopPropagation()}
              >
                <FileDownloadOutlined />
              </a>
            </div>
          ) : (
            <StyledIconButton
              onClick={handleDownloadAudio}
              disabled={isDisabledAudio}
              height="30px"
              width="30px"
              padding="12px"
            >
              <FileDownloadOutlined />
            </StyledIconButton>
          )}
        </span>
      </StyledActionTooltip>
      {!isDubbingRoute && (
        <Box>
          {isDisabledAudio ? (
            <StyledActionTooltip
              title={getTitleActionTooltip('effectGuide')}
              arrow
              placement="top"
              disabled={isDisabledAudio}
            >
              <span>
                <StyledIconButton
                  onClick={
                    useNewEffectGuide
                      ? handleOpenNewAudioEffects
                      : handleOpenAudioEffects
                  }
                  disabled={isDisabledAudio}
                  height="30px"
                  width="30px"
                  padding="12px"
                >
                  <TuneRounded className="icon" />
                </StyledIconButton>
              </span>
            </StyledActionTooltip>
          ) : (
            <Tooltip title={t('effectGuide')} placement="top" arrow>
              <StyledIconButton
                onClick={
                  useNewEffectGuide
                    ? handleOpenNewAudioEffects
                    : handleOpenAudioEffects
                }
                disabled={isDisabledAudio}
                height="30px"
                width="30px"
                padding="12px"
              >
                <TuneRounded className="icon" />
              </StyledIconButton>
            </Tooltip>
          )}
        </Box>
      )}

      <Tooltip title={t('errorReport')} placement="top" arrow>
        <StyledIconButton
          onClick={handleOpenErrorReport}
          height="30px"
          width="30px"
          padding="12px"
          disabled={isDisabledErrorReport}
        >
          <FlagRounded className="icon" />
        </StyledIconButton>
      </Tooltip>
    </div>
  );
};
export default RequestAction;
