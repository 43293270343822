import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import {
  BORDER_RADIUS,
  BREAKPOINTS,
  PAYMENT_FOOTER_SHADOW,
} from '@src/styles/config';

const StyledPackageInfoItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100% !important;
  padding-top: 4.5px;

  .line-height-24 {
    line-height: 24px;
  }

  .price {
    display: flex;
    align-items: flex-end;
    line-height: 24px;

    .discount-price {
      font-size: 16px;
      font-weight: 400;
      color: ${COLOR.label2[100]};
    }

    .discount-price-unit {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.label2[100]};
    }

    .underline {
      text-decoration: underline;
    }

    .line-through {
      text-decoration: line-through;
    }

    .original-price {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.darkV2[64]};
    }

    .original-price-unit {
      font-size: 12px;
      font-weight: 400;
      color: ${COLOR.darkV2[64]};
    }

    .font-size-14 {
      font-size: 14px !important;
    }
  }

  .package-info-title-and-price {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 390px) {
      align-items: flex-start;
    }

    .title-wrapper {
      display: flex;
      gap: 4px;
      align-items: center;

      .title {
        font-size: 16px;
        font-weight: 700;
        color: ${COLOR.label2[100]};
        line-height: 24px;
        white-space: nowrap;
      }

      .banner {
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 12px;
        font-weight: 510;
        line-height: 16px;
        white-space: nowrap;
      }

      .auto-renewal {
        background-color: ${COLOR.secondary[80]};
        color: ${COLOR.secondary[100]};
      }

      .save-percent {
        background: ${COLOR.yellow.default};
        color: ${COLOR.indigo.default};
      }

      .most-popular {
        background: ${COLOR.yellow.default};
        color: ${COLOR.indigo.default};
        text-transform: uppercase;
      }
    }

    .price-wrapper {
      display: flex;
      gap: 4px;
      align-items: center;

      @media (max-width: 390px) {
        flex-direction: column-reverse;
      }
    }
  }
`;

const StyledPayment = styled('div')`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 70px;

  .MuiGrid-root {
    margin-bottom: ${(props) =>
      props.useNewLegal && !props.isShowAutoRenewalPackage && '30px'};
  }

  .header-payment {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon {
      display: flex;
    }

    .icon-button {
      display: none;
    }

    .back-to-package {
      font-size: 16px;
      text-transform: none;
      padding: 8px 12px 8px 12px;
      border-radius: 100px;
      background-color: ${COLOR.white};
      position: absolute;
      left: 0;

      :hover {
        background-color: ${COLOR.black[16]};
      }
    }

    .back-to-package-mobile {
      color: ${COLOR.darkV2[80]};
      margin-right: 4px;
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .mobile-button {
    display: none;
  }

  .website-button {
    display: flex;
  }

  @media (max-width: ${BREAKPOINTS.SMD}px) {
    .mobile-button {
      display: flex !important;
    }

    .website-button,
    .medium-website-button {
      display: none !important;
    }

    .header-payment {
      justify-content: flex-start !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.SMD}) and (max-width: ${BREAKPOINTS.MD}px) {
    .medium-website-button {
      display: flex !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.MD}px) {
    .website-button {
      display: none !important;
    }

    .header-payment {
      .back-to-package {
        padding: 8px;
      }
    }
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    .mobile-button,
    .medium-website-button {
      display: none !important;
    }

    .website-button {
      display: flex !important;
    }
  }

  .header {
    margin-bottom: 24px;
    text-align: center;

    .content {
      padding: 8px;

      color: ${COLOR.indigo.default};
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.4px;
    }
  }

  .card {
    padding: 24px 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .subscription-wrapper {
    margin-top: 16px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    max-width: 100%;
    box-sizing: border-box;
  }

  .show-mobile {
    display: none;
  }

  @media (min-width: ${BREAKPOINTS.MLG}px) and (max-width: ${BREAKPOINTS.CMLG -
    1}px) {
    .card {
      padding: 32px;
    }

    .show-desktop {
      display: ${(props) => (props.openSidebar ? 'none' : 'block')};
    }

    .show-mobile {
      display: ${(props) => (props.openSidebar ? 'block' : 'none')};
    }
  }

  @media (max-width: ${BREAKPOINTS.MLG - 1}px) {
    .card {
      padding: 32px;
    }

    .show-desktop {
      display: none;
    }

    .show-mobile {
      display: block;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: ${COLOR.indigo.default};
    line-height: 28px;
  }

  @media (max-width: 599px) {
    margin-bottom: ${(props) =>
      props.isShowAutoRenewalPackage ? '128px' : '72px'};
    .card {
      padding: 16px;
    }
  }

  @media (max-width: 650px) {
    .header-payment {
      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .icon {
        display: none;
      }

      .icon-button {
        display: block;
      }
    }
  }

  .discount-unit-price {
    font-size: 14px;
    text-transform: lowercase;
  }

  .font-size-14 {
    font-size: 14px !important;
  }

  .font-size-16 {
    font-size: 16px !important;
  }

  .underline {
    text-decoration: underline;
  }

  .align-item-center {
    align-items: center;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .text-indigo {
    color: ${COLOR.indigo.default};
  }

  .text-16 {
    font-size: 16px !important;
  }

  .font-bold-510 {
    font-weight: 510 !important;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .line-through {
    text-decoration: line-through;
  }
`;

const StyledPackageInfo = styled('div')`
  .package-header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
    flex-wrap: wrap;
  }

  .banner {
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 510;
    line-height: 16px;
    white-space: nowrap;
  }

  .auto-renewal {
    background-color: ${COLOR.secondary[80]};
    color: ${COLOR.secondary[100]};
  }

  .save-percent {
    background: ${COLOR.yellow.default};
    color: ${COLOR.indigo.default};
  }

  .package-icon-active {
    display: flex;
    align-items: center;
    border-radius: 9999px;
    background-color: ${COLOR.primary};
    width: 48px;
    height: 48px;
  }

  .package-name {
    font-size: 20px;
    font-weight: bold;
    color: ${COLOR.darkBlue};
    margin: auto;
  }

  @media (max-width: ${BREAKPOINTS.SMD}px) {
    .package-name {
      font-size: 16px !important;
    }

    .mobile-label {
      display: block !important;
    }

    .website-label {
      display: none !important;
    }

    .package-toggle {
      gap: 4px !important;
    }

    .banner {
      font-size: 10px !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.SMD}px) {
    .mobile-label {
      display: none !important;
    }

    .website-label {
      display: block !important;
    }
  }

  .vertical-center {
    display: flex;
    align-items: center;
  }

  .hidden {
    display: none;
  }

  .select-package {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
  }

  .py-20 {
    padding: 20px 0;
  }

  .package-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .package-duration-select {
    cursor: pointer;
    .css-j204z7-MuiFormControlLabel-root {
      margin-right: 0;
    }

    margin-top: 4px;

    .flex-col {
      display: flex;
      flex-direction: column;
    }

    .flex-row {
      flex-direction: row;
    }

    .flex {
      display: flex;
    }

    .space-between {
      justify-content: space-between;
    }

    .flex-start {
      justify-content: flex-start;
    }

    .gap-8 {
      gap: 8px;
    }

    .gap-4 {
      gap: 4px;
    }

    .align-item-start {
      align-items: flex-start;
    }

    .full-width {
      width: 100% !important;
    }

    .pt-12 {
      padding-top: 12px;
    }

    .package-duration-card {
      padding-top: 4.5px;
    }

    .pb-12 {
      padding-bottom: 12px;
    }

    .font-bold {
      font-weight: 700;
    }

    .gap-2 {
      gap: 2px;
    }

    .flex-end {
      justify-content: flex-end;
    }

    .text-dark-v2-64 {
      color: ${COLOR.darkV2[64]};
    }

    .text-14 {
      font-size: 14px;
    }

    .py-3 {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }

    .py-4 {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }

    .px-6 {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }

    .most-popular {
      border-radius: 480px;
      background: ${COLOR.yellow.default};
      color: ${COLOR.indigo.default};
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .label {
    border-radius: 480px;
    font-size: 12px;

    &.auto-renewal {
      color: ${COLOR.secondary[100]};
      background-color: ${COLOR.secondary[80]};
    }

    &.save-percent {
      background: ${COLOR.yellow.default};
      color: ${COLOR.indigo.default};
    }
  }
`;

const StyledPopover = styled(Popover)`
  .menu {
    padding: 4px 12px 12px 12px;

    .menu-item {
      border-radius: ${BORDER_RADIUS} !important;
      margin-top: 8px;
      padding: 0px !important;

      :hover {
        cursor: pointer;
        background-color: ${COLOR.indigo[8]};
      }
    }

    .selected {
      background-color: ${COLOR.yellow[16]};
    }
  }

  .name {
    font-weight: 700;
    margin-left: 4px;
    width: 160px;
  }

  .package-icon {
    display: flex;
    align-items: center;
  }
`;

const StyledInvoice = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .invoice-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .expand-icon {
      width: 24px;
      height: 24px;
      font-weight: 400;
      color: ${COLOR.indigo[80]};
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: ${COLOR.indigo.default};
    line-height: 28px;
  }

  .invoice-check-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  .checkbox {
    padding: 0;
    color: ${COLOR.darkBlue} !important;
  }

  .invoice-info {
    cursor: pointer;
  }

  .invoice-collapse-wrapper {
    margin-top: ${(props) => (props.openCollapse ? '16px' : '0')};
    transition: height 300ms linear, opacity 250ms linear;
    display: ${(props) => (props.openCollapse ? 'block' : 'none')};
    height: ${(props) => (props.openCollapse ? 'auto' : '0px')};
    opacity: ${(props) => (props.openCollapse ? '1' : '0')};

    .note-item {
      display: flex;
      gap: 5px;
      align-items: flex-start;
      background-color: ${TRANSPARENT_COLOR.alert};
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;

      &.align-items-center {
        align-items: center !important;
      }

      .note-content {
        display: flex;
        flex-direction: column;
      }

      .note-title {
        font-size: 12px;
        font-weight: 400;
        color: ${COLOR.label[80]};
      }

      .note-description {
        font-size: 14px;
        font-weight: 400;
        color: ${COLOR.label[100]};
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;

const StyledCreateInvoice = styled('div')`
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  gap: 12px;

  span {
    color: ${COLOR.functionalRed.default};
  }

  .invoice-input {
    margin-top: 8px;
  }

  .MuiOutlinedInput-root {
    border-radius: ${BORDER_RADIUS};
  }

  p {
    font-weight: 500;
  }
`;

const StyledPaymentMethods = styled('div')`
  .title {
    margin-bottom: 16px;
  }

  .note {
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    color: ${COLOR.darkV2[60]};
    margin-bottom: 4px;
    margin-bottom: 4px;
    margin-top: -8px;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const StyledPaymentMethodItem = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  border-radius: ${BORDER_RADIUS};
  padding: 12px 8px;
  background: ${COLOR.charcoal};
  border: 2px solid ${COLOR.charcoal};
  &:hover {
    cursor: pointer;
    background: ${COLOR.gray};
    border: 2px solid ${COLOR.gray};
  }

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.32;
    &:hover {
      cursor: default ;
      background: ${COLOR.charcoal};
      border: 2px solid transparent;
    }
  `}

  ${({ selected }) =>
    selected &&
    `border: 2px solid ${COLOR.primary};
     background: none;
     &:hover {
      background: none;
      border: 2px solid ${COLOR.primary}
     }
  `}

  .name {
    font-size: 14px;
    font-weight: 600;
  }

  .logo {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-img {
    max-width: 64px;
  }
`;

const StyledOrderInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .info-title {
    font-size: 16px;
    font-weight: 700;
  }

  .total {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledUserOrderInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .user-info-content {
    display: flex;
    flex-direction: column;
  }

  .user-info-item {
    display: flex;
    align-items: start;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;

    .user-info-item-label {
      font-weight: 700;
    }

    .user-info-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .edit-icon {
      width: 20px;
      color: ${COLOR.black[32]};
      cursor: pointer;
    }

    .filter-item {
      display: flex;
      gap: 10px;
    }

    .phone-number-card {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .text-field-request {
      background-color: ${COLOR.white};
      width: 133px;

      .MuiOutlinedInput-root {
        border-radius: 100px;
        color: ${COLOR.functionYellow.default};
      }

      .MuiOutlinedInput-input {
        padding: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: -0.4px;
        color: ${COLOR.indigo.default};
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${COLOR.functionYellow.default};
      }
    }

    .phone-number-warning {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      letter-spacing: -0.4px;
      color: ${COLOR.functionalRed.default};
      margin-left: 8px;
    }

    .user-info-phone-number {
      height: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0px 8px;
      border-radius: 100px;
      border: 1px solid transparent;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ml-8 {
    margin-left: 8px;
  }

  .ml-4 {
    margin-left: 4px;
  }
`;

const StyledOrder = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .package-info-card {
    display: flex;
    justify-content: space-between;
    align-items: start;

    .package-info-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
    }

    .package-info-detail {
      display: flex;
      gap: 4px;
      flex-direction: column;
      text-align: right;

      .auto-renewal {
        padding: 4px 10px;
        border-radius: 16px;
        background-color: ${COLOR.indigo[8]};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: ${COLOR.indigo[100]};
      }
    }
  }
`;

const StyledVoucherBox = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .text-field-request {
    background-color: ${COLOR.white};
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 100px;
      color: ${COLOR.darkV2[32]};
      height: 44px;
    }

    .MuiOutlinedInput-input {
      padding: 8px 16px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.4px;
      color: ${COLOR.indigo.default};
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${COLOR.darkV2[32]};
    }
  }

  .apply-button {
    text-transform: none;
    padding: 8px 24px;
    min-width: 111px;
    border-radius: 25px;
    font-size: 16px;
    display: flex;
    justify-content: space-around;
    color: ${COLOR.darkBlue};
    height: 44px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
  }

  .voucher-error {
    color: ${COLOR.danger};
    font-size: 13px;
    margin: 0px 0px 0px 16px;
  }

  .add-voucher-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .add-voucher-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
    }

    .expand-icon {
      width: 24px;
      height: 24px;
      font-weight: 400;
      color: ${COLOR.label[80]};
    }
  }

  .selected-voucher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .voucher-gift-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: ${COLOR.yellow.default};
      border-radius: 50%;
      padding: 4px;

      .gift-icon {
        width: 16px;
        height: 16px;
        color: ${COLOR.indigo.default};
      }
    }

    .voucher-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
    }

    .voucher-value {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.4px;
    }
    .voucher-amount-unit {
      font-weight: 400 !important;
      color: ${COLOR.label[80]} !important;
      border-bottom: 1px solid ${COLOR.label[80]} !important;
      margin-left: 2px;
    }

    .selected-voucher-button {
      text-transform: none;
      padding: 6px 24px;
      min-width: 95px;
      border-radius: 25px;
      font-size: 12px;
      display: none;
      justify-content: space-around;
      color: ${COLOR.darkBlue};
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.4px;
    }

    &:hover {
      .selected-voucher-button {
        display: flex;
      }

      .voucher-value {
        display: none;
      }
    }
  }

  .voucher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    border: 2px solid ${COLOR.label[8]};
    min-height: 58px;

    .voucher-name {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: ${COLOR.darkV2.default};
      letter-spacing: -0.4px;
    }

    .voucher-info-tooltip {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: left;
      gap: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${COLOR.white};
      letter-spacing: -0.4px;
    }

    .voucher-button {
      text-transform: none;
      padding: 6px 24px;
      min-width: 95px;
      border-radius: 25px;
      font-size: 12px;
      display: flex;
      justify-content: space-around;
      color: ${COLOR.darkBlue};
      min-height: 30px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.4px;
    }

    .selected-voucher-button {
      display: none;
    }

    &:hover {
      .selected-voucher-button {
        display: flex;
      }
    }
  }
`;

const StyledPaymentFooter = styled('div')`
  display: flex;
  justify-content: ${(props) =>
    props.autorenew ? 'space-between' : 'flex-end'};
  justify-content: ${(props) => props.useNewLegal && 'space-between'};
  align-items: ${(props) => props.useNewLegal && 'center'};
  min-width: 80px;
  padding: ${(props) =>
    props.useNewFlowPaymentUI ? '12px 16px' : '12px 80px 12px 20px'};
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: ${COLOR.white};
  box-shadow: ${PAYMENT_FOOTER_SHADOW};

  ${(props) =>
    props.useNewLegal &&
    `
    .auto-renew-term-container {
      display: flex;
    }
    .checkbox {
      padding: 0 9px 0 0px !important;
      color: ${COLOR.darkBlue} !important;
      height: 50%;
    }
    .not-agree-auto-renew-term-text {
      font-size: 14px;
      color: ${COLOR.functionalRed.default};
      font-weight: 400;
      line-height: 20px;
    }
    .agree-auto-renew-term-text {
      display: flex;
      flex-direction: column;
    }
  `}
  .term {
    font-size: 12px;
    font-weight: 400;
    color: ${COLOR.darkV2[64]};
    margin-top: -8px;

    a:-webkit-any-link {
      color: ${COLOR.darkV2[64]};
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .cancelNote {
    margin-top: -14px;
  }

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    min-width: ${(props) => props.useNewLegal && '310px'};
  }

  .price {
    display: flex;
    line-height: 24px;
    align-items: flex-end;
    margin-right: 12px;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    .price-text {
      margin-right: 4px;
    }
  }

  .text-16 {
    font-size: 16px !important;
  }

  .discount-unit-price {
    font-size: 14px;
    text-transform: lowercase;
  }

  .underline {
    text-decoration: underline;
  }

  .agreeToPayButton {
    text-transform: none;
    padding: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    display: flex;
    height: 44px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 480px;
    text-wrap: no-wrap;
  }

  @media only screen and (min-width: ${BREAKPOINTS.SM}px) {
    left: ${(props) => `${props.sidebarwidth}px`};
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    left: 0 !important;
  }

  @media only screen and (width: ${BREAKPOINTS.SM}px) {
    left: 62px !important;
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    padding: ${(props) =>
      props.useNewFlowPaymentUI ? '12px 40px' : '12px 80px 12px 20px'};
    .container {
      display: flex;
      align-items: center !important;
      justify-content: flex-end !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.MD + 1}px) {
    gap: ${(props) => (props.useNewLegal ? '32px' : '0')};
    padding: ${(props) => props.useNewLegal && '12px 16px'};
  }

  @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD}px) {
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: ${(props) => props.useNewLegal && '16px'};
    padding: ${(props) => props.useNewLegal && '12px 16px'};
    .container {
      display: flex;
      justify-content: space-between !important;
      width: 100%;
    }
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: ${(props) => props.useNewLegal && '16px'};
    padding: ${(props) => props.useNewLegal && '12px 16px'};
    .container {
      display: flex;
      justify-content: space-between !important;
      flex-wrap: wrap;
      width: 100%;
      margin-top: ${(props) => (props.autorenew ? '4px' : '0')};
    }
  }
`;

const StyledSubscriptionInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .subscription-info-note {
    width: 18px;
    height: 18px;
    color: ${COLOR.indigo[32]};
    cursor: pointer;
  }
`;

export {
  StyledPackageInfoItem,
  StyledPayment,
  StyledCreateInvoice,
  StyledInvoice,
  StyledPackageInfo,
  StyledPopover,
  StyledPaymentMethods,
  StyledPaymentMethodItem,
  StyledOrderInfo,
  StyledOrder,
  StyledUserOrderInfo,
  StyledVoucherBox,
  StyledPaymentFooter,
  StyledSubscriptionInfo,
};
