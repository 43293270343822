import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BREAKPOINTS } from '@src/styles/config';

export const StyledPaymentDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 640px;
    width: 575px;
  }

  .wrapper {
    padding: 16px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    color: ${COLOR.indigo[100]};
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${COLOR.divider};
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0;

    .button {
      padding: 8px 24px;
      border-radius: 480px;
      text-transform: none;
      margin-left: 0;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    .dialog-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 0;

      .button {
        padding: 8px 24px;
        border-radius: 480px;
        text-transform: none;
        margin-left: 0;
      }
    }
  }

  .unit-price {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-transform: lowercase;
    padding-bottom: -2px;
  }

  .underlined {
    text-decoration: underline;
  }

  .qr-payment-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .time {
    font-size: 14px;
    font-weight: 400;
    color: ${COLOR.label[80]};
  }

  .price-value {
    font-weight: 700;
    font-size: 20px;
    color: ${COLOR.black.default} !important;
  }

  .time-value {
    font-weight: 700;
    color: ${COLOR.black.default} !important;
    width: 40px;
    display: inline-block;
  }

  .divider {
    margin: 16px 0;
  }
`;

export const StyledTransferInformation = styled(Box)`
  display: flex;
  flex-direction: column;

  .saveQR {
    font-size: 12px;
    font-weight: 500;
    color: ${COLOR.indigo[100]};
    text-transform: none;
    height: 30px;
    border-radius: 8px;
    padding: 4px 16px 4px 8px;
    width: 110px;
    border: 1px solid ${COLOR.indigo[100]};
  }

  .information-wrapper {
    height: 240px;
    position: relative;
  }

  .expired-transaction {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text {
      font-size: 16px;
      font-weight: 510;
      text-align: center;
    }
  }

  .qr-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;

    .button-group {
      display: flex;
      gap: 8px;
      margin-top: 8px;
    }

    .bank-qr {
      width: 160px;
      height: 160px;
    }
  }

  .content-information {
    align-items: baseline;
    flex: 1;

    .row-content {
      display: grid;
      grid-template-columns: 40% 60%;
      margin-bottom: 6px;
    }

    .label {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }

    .value {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      justify-content: end;
      text-align: end;
      padding-right: 10px;
    }

    .card-number {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .divider {
      margin: 16px 0;
    }

    .divider-note {
      margin: 16px 0;
    }
  }

  .note {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    opacity: ${(props) => (props.expiredPayment ? 0.4 : 1)};
  }

  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    .note {
      display: flex;
      flex-direction: column;
      gap: 4px;
      opacity: ${(props) => (props.expiredPayment ? 0.4 : 1)};
    }

    .content-information {
      .row-content {
        display: block;
        margin-bottom: 6px;
      }

      .value {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        padding-right: 10px;
      }

      .card-number {
        display: flex;
        align-items: center;
        justify-content: start;
      }
    }
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .note-item {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    background-color: ${TRANSPARENT_COLOR.alert};
    padding: 12px;
    border-radius: 8px;

    &.align-items-center {
      align-items: center !important;
    }

    .note-content {
      display: flex;
      flex-direction: column;
    }

    .note-title {
      font-size: 12px;
      font-weight: 400;
      color: ${COLOR.label[80]};
    }

    .note-description {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.label[100]};
    }
  }
`;

export const StyledNotConfirmOrderDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 580px;
    border-radius: 16px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 32px 32px 32px;
    gap: 16px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }

  .title-content {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: ${COLOR.indigo[100]};
  }

  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: ${COLOR.indigo[100]};
    padding: 8px 0px;
  }

  .action-wrapper {
    display: flex;
    justify-content: center;
    gap: 24px;

    .action-button {
      width: 100%;
      text-transform: none;
      height: 44px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: -0.4px;
      border-radius: 500px;
    }

    .cancel-button {
      border: 1px solid ${COLOR.secondary[32]};
      color: ${COLOR.indigo[100]};
    }

    .pending-button {
      border: 1px solid transparent;
      color: ${COLOR.indigo[100]};
    }
  }
  @media only screen and (max-width: ${BREAKPOINTS.SM}px) {
    .action-wrapper {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 14px;
    }
  }
`;
