import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGrowthBook } from '@growthbook/growthbook-react';
import moment from 'moment';
import {
  IconButton,
  TextField,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  InputAdornment,
  // Button
} from '@mui/material';
import {
  RestartAltOutlined,
  Close,
  DeleteOutlineOutlined,
  DownloadOutlined,
  Search,
} from '@mui/icons-material';
import Tooltip from '@src/components/Tooltip';
import CustomDatePickerRange from '@src/components/CustomDatePickerRange';
import { REQUEST_STATUS } from '@src/constants/voice';
import { PAGINATION_LIMIT } from '@src/constants';
import { FETCH_REQUESTS_INTERVAL } from '@src/constants/websocket';
import debounce from '@src/utils/debounce';
import apis from '@src/apis';
import { COLOR } from '@src/styles/color';
// import arrowIconUp from '@src/assets/icons/arrow-icon-up.png';

import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import NotificationDialog from '@src/components/NotificationDialog';
import actions from '@src/redux/actions';
import {
  FIELDS_FILTER,
  MAX_AUDIO_DOWNLOAD,
  MAX_BODY_CONTENT_HEIGHT,
  MAX_REQUEST_DELETE,
  REQUEST_TYPE,
} from '@src/constants/request';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { downloadAudioLinks } from '@src/apis/requests';
import RequestTable from '@src/containers/TTSNew/Requests/RequestTable';
import { StyledRequests } from '@src/containers/TTSNew/Requests/index.style';
import { StyledActionWarning } from '../DubbingSentences/index.style';

const initialFilter = {
  status: '',
  createdAt: [null, null],
};

const ListDubbingNew = ({
  showNotiOldVersion,
  requestLoading,
  onChangeRequestLoading,
}) => {
  const [requests, setRequests] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState('createdAt_desc');
  const [loading, setLoading] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [validateDeleteRequest, setValidateDeleteRequest] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [allowDownload, setAllowDownload] = useState(false);
  const [selectedFields, setSelectedFields] = useState(
    Object.values(FIELDS_FILTER),
  );
  const [anchorFieldFilter, setAnchorFieldFilter] = useState(null);

  const growthbook = useGrowthBook();
  const useOldDownloadAll = growthbook.getFeatureValue(
    FEATURE_KEYS.DOWNLOAD_AUDIO_LINK,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requestsRef = useRef(requests);
  const pageRef = useRef(page);
  const filterRef = useRef(filter);
  const sortRef = useRef(sort);

  const responseRequest = useSelector((state) => state.request);
  const { user } = useSelector((state) => state.auth);

  const handleUpdateRequests = (newRequests) => {
    setRequests(newRequests);
  };
  const { getFeatureValue } = useFeatureFlags();

  const isProcessDubbingByUnitSecond = getFeatureValue(
    FEATURE_KEYS.DUBBING_BY_UNIT_SECOND,
    { userId: user.id, email: user.email, phoneNumber: user.phoneNumber },
  );

  const useNewDubbingUi = getFeatureValue(FEATURE_KEYS.NEW_DUBBING_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const REQUEST_HEIGHT = 70;

  const pageLimit = useNewDubbingUi
    ? Math.floor((window.innerHeight * 0.8 - 68) / REQUEST_HEIGHT)
    : PAGINATION_LIMIT;

  const handleChangePage = (newPage) => setPage(newPage);

  const handleChangeFilter = (name, value) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };

  const handleChangeSort = (newSort) => {
    setSort(newSort);
    setPage(1);
  };

  const handleResetFilter = () => {
    setFilter(initialFilter);
    setSearch('');
    setPage(1);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleSelectFields = (field) => {
    if (selectedFields.includes(field)) {
      setSelectedFields(selectedFields.filter((f) => f !== field));
    } else setSelectedFields([...selectedFields, field]);
  };

  const handleChangeDatePickerRange = (value) =>
    handleChangeFilter('createdAt', value);

  const fetchRequests = async (hasLoading) => {
    if (hasLoading) setLoading(true);
    const { status, createdAt } = filterRef.current;

    const data = await apis.dubbing.getDubbingRequests({
      offset: (page - 1) * pageLimit,
      limit: pageLimit,
      sort,
      search,
      status,
      startDate: createdAt[0]
        ? moment(createdAt[0]).startOf('day').toISOString()
        : undefined,
      endDate: createdAt[1]
        ? moment(createdAt[1]).endOf('day').toISOString()
        : undefined,
      fields:
        'id,title,characters,seconds,createdAt,progress,status,voice,audioType,audioLink,retentionPeriod,processingAt',
      notExistsProject: true,
    });

    if (data && data.status) {
      const newRequests = data.result.requests.map((request) => {
        const req = requests.find((r) => r.id === request.id);
        if (req) {
          return {
            ...request,
            progress:
              req?.progress && request.progress < req?.progress
                ? req.progress
                : request.progress,
          };
        }
        return request;
      });

      onChangeRequestLoading(false);
      setLoading(false);
      setRequests(newRequests);
      setTotalRequests(data.result.metadata.total);
    }
  };

  const fetchProgressRequest = async (requestId) => {
    const data = await apis.requests.getProgressRequest(requestId);
    if (data && data.status) {
      const newRequests = requestsRef.current.map((request) => {
        if (request.id === requestId) {
          const { progress, status, audioLink } = data.result;
          return {
            ...request,
            progress,
            status,
            audioLink,
          };
        }
        return request;
      });
      handleUpdateRequests(newRequests);
    }
  };

  const handleCloseDeleteWarningDialog = () => {
    setDeleteWarning(false);
  };

  const handleCloseValidateDeleteRequestDialog = () => {
    setValidateDeleteRequest(false);
  };

  const handleCloseFieldsFilter = () => setAnchorFieldFilter(null);

  const handleDeleteSelectRequest = () => {
    const numSelectedRequest = selectedRequests.length;
    if (numSelectedRequest > 0 && numSelectedRequest <= MAX_REQUEST_DELETE) {
      setDeleteWarning(true);
    } else {
      setValidateDeleteRequest(true);
    }
  };

  const handleDownloadAudios = async () => {
    if (selectedRequests.length > MAX_AUDIO_DOWNLOAD) {
      setValidateDeleteRequest(true);
      return;
    }
    if (useOldDownloadAll) {
      const listAudioLink = selectedRequests.map((req) => req.audioLink);
      downloadAudioLinks(listAudioLink);
      return;
    }
    const listPresignedUrl = [];
    const listAudioId = selectedRequests.map((req) => ({
      id: req.id,
      title: req.title,
    }));
    const promises = listAudioId.map(async ({ id, title }) => {
      try {
        const res = await apis.requests.getPresignedAudioUrl(id);
        if (res?.status) {
          listPresignedUrl.push(res.result?.audio);
        } else {
          dispatch(
            actions.noti.push({
              severity: 'error',
              message: 'downloadError',
              value: title,
            }),
          );
        }
      } catch (error) {
        dispatch(
          actions.noti.push({
            severity: 'error',
            message: 'downloadError',
            value: title,
          }),
        );
      }
    });

    await Promise.all(promises);
    downloadAudioLinks(listPresignedUrl);
  };

  const handleSelectRequest = (selectReqs) => {
    setSelectedRequests(selectReqs);
    const allowDownloadAudios = selectReqs.every((req) => req.audioLink);
    setAllowDownload(allowDownloadAudios);
  };

  const handleDeleteRequestConfirm = async () => {
    try {
      const requestIds = selectedRequests.map((req) => req.id);
      const data = await apis.requests.deleteRequest({ requestIds });
      if (!data?.status) {
        dispatch(
          actions.noti.push({
            severity: 'error',
            message: 'deleteRequestFailure',
          }),
        );
        setDeleteWarning(false);
        return;
      }
      dispatch(
        actions.noti.push({
          severity: 'success',
          message: 'deleteRequestSuccess',
        }),
      );
      setDeleteWarning(false);
      setSelectedRequests([]);
      setPage(1);
      fetchRequests(false);
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: error?.response?.data?.error_message,
        }),
      );
      setDeleteWarning(false);
    }
  };

  const handleDeleteAllRequests = async () => {
    const data = await apis.requests.deleteRequest({
      isDeleteAll: true,
      type: REQUEST_TYPE.DUBBING,
    });
    if (!data?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'deleteRequestFailure',
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'success',
        message: 'deleteRequestSuccess',
      }),
    );
    setSelectedRequests([]);
    setPage(1);
    fetchRequests(false);
  };

  const updateInprogressRequest = (requestIds) => {
    Promise.all(
      requestIds.map(async (requestId) => {
        await fetchProgressRequest(requestId);
        return null;
      }),
    );
  };

  const updateRequest = (requestId, updateInfo) => {
    const newRequests = requestsRef.current.map((request) => {
      if (request.id === requestId) {
        return {
          ...request,
          ...updateInfo,
        };
      }
      return request;
    });
    handleUpdateRequests(newRequests);
  };

  const renderConfirmDeleteRequestACtion = (handleClose, handleConfirm) => (
    <StyledActionWarning>
      <Button variant="outlined" onClick={handleClose}>
        {t('noValue')}
      </Button>
      <Button variant="contained" onClick={handleConfirm}>
        {t('yesValue')}
      </Button>
    </StyledActionWarning>
  );

  const renderCloseButton = (handleClose) => (
    <Button variant="contained" onClick={handleClose}>
      {t('understood')}
    </Button>
  );

  // const handleBackToTTS = () => {
  //   handleOpenRequest(false);
  //   setPage(1);
  //   handleResetFilter();
  // };

  useEffect(() => {
    requestsRef.current = requests;
  }, [requests]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  useEffect(() => {
    sortRef.current = sort;
  }, [sort]);

  useEffect(() => {
    fetchRequests(true);
  }, [page, filter, sort]);

  useEffect(() => {
    debounce(fetchRequests, 500)(search);
  }, [search]);

  useEffect(() => {
    if (requestLoading) fetchRequests(false);
  }, [requestLoading]);

  useEffect(() => {
    const newRequests = requests.map((request) => {
      const isInProgressRequest =
        request.status === REQUEST_STATUS.IN_PROGRESS &&
        responseRequest[request.id];
      if (isInProgressRequest) {
        request.status = responseRequest[request.id].status;
        request.audioLink = responseRequest[request.id].audioLink;
        request.progress = responseRequest[request.id].progress;
        request.processingAt = responseRequest[request.id].processingAt;
      }
      return request;
    });
    handleUpdateRequests(newRequests);
  }, [responseRequest]);

  useEffect(() => {
    const fetchRequestsInterval = setInterval(async () => {
      const inProgressRequest = requestsRef.current
        .filter((request) => request.status === REQUEST_STATUS.IN_PROGRESS)
        .map((request) => request.id);
      if (inProgressRequest?.length) updateInprogressRequest(inProgressRequest);
    }, FETCH_REQUESTS_INTERVAL);

    return () => clearInterval(fetchRequestsInterval);
  }, []);

  return (
    <StyledRequests>
      <div className="request-wrapper" role="button" tabIndex="0">
        <div className="request-list-title">
          <span>{t('listRequest')}</span>
        </div>
        <div className="action-table">
          <div className="request-filter">
            <div
              className="filter-item"
              role="button"
              tabIndex="0"
              onClick={(e) => e.stopPropagation()}
            >
              <TextField
                size="small"
                className="text-field-request filter-title"
                variant="outlined"
                value={filter.title}
                label={t('findTitle')}
                onChange={handleChangeSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                className="text-field-request filter-status"
                variant="outlined"
                value={filter.status}
                select
                label={t('status')}
                onChange={(e) => handleChangeFilter('status', e.target.value)}
              >
                {Object.keys(REQUEST_STATUS).map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(item)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="filter-item"
              role="button"
              tabIndex="0"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="date-field">
                <CustomDatePickerRange
                  value={filter.createdAt}
                  onChange={handleChangeDatePickerRange}
                />
              </div>
              <Tooltip arrow title={t('reset')} placement="bottom">
                <div>
                  <IconButton
                    className={`refresh-icon ${
                      filter === initialFilter && 'disable-color'
                    }`}
                    onClick={handleResetFilter}
                    disabled={filter === initialFilter}
                  >
                    <RestartAltOutlined />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            <Menu
              anchorEl={anchorFieldFilter}
              open={Boolean(anchorFieldFilter)}
              onClose={handleCloseFieldsFilter}
              className="fields-filter"
            >
              {Object.keys(FIELDS_FILTER).map((key) => (
                <MenuItem
                  key={key}
                  value={FIELDS_FILTER[key]}
                  onClick={() => handleSelectFields(FIELDS_FILTER[key])}
                >
                  <Checkbox
                    checked={selectedFields.includes(FIELDS_FILTER[key])}
                    onClick={handleSelectFields}
                    style={{ color: COLOR.darkBlue }}
                  />
                  <div>{t(key)}</div>
                </MenuItem>
              ))}
            </Menu>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div id={TTS_GUIDE.REQUESTS_TABLE}>
        <RequestTable
          requests={requests}
          selectedFields={selectedFields}
          page={page}
          total={totalRequests}
          sort={sort}
          loading={loading}
          playable={false}
          tableHeight={
            useNewDubbingUi
              ? `calc(80vh - ${showNotiOldVersion ? 68 : 12}px)`
              : `${MAX_BODY_CONTENT_HEIGHT}px`
          }
          selectedRequests={selectedRequests}
          isProcessBySeconds={isProcessDubbingByUnitSecond}
          onUpdateRequest={updateRequest}
          onChangeSort={handleChangeSort}
          onChangePage={handleChangePage}
          onSelectRequest={handleSelectRequest}
          onDeleteSelectedRequests={handleDeleteRequestConfirm}
          onDeleteAllRequests={handleDeleteAllRequests}
          limit={pageLimit}
          onEnableEditor={() => {}}
        />
      </div>
      {selectedRequests?.length ? (
        <div className="select-request-popper" style={{ display: 'none' }}>
          <div className="">
            {t('selectRequests', { numRequests: selectedRequests.length })}
          </div>
          <Button
            variant="outlined"
            size="small"
            color="error"
            startIcon={<DeleteOutlineOutlined />}
            onClick={handleDeleteSelectRequest}
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            disabled={!allowDownload}
            startIcon={<DownloadOutlined />}
            onClick={handleDownloadAudios}
          >
            {t('download')}
          </Button>
          <IconButton color="secondary" onClick={() => handleSelectRequest([])}>
            <Close />
          </IconButton>
        </div>
      ) : null}
      <NotificationDialog
        name="ssml"
        title={t('deleteRequestTitle')}
        description={t('deleteRequestTitleDescription')}
        variant="warning"
        open={deleteWarning}
        onClose={handleCloseDeleteWarningDialog}
        actionComponent={renderConfirmDeleteRequestACtion(
          handleCloseDeleteWarningDialog,
          handleDeleteRequestConfirm,
        )}
      />
      <NotificationDialog
        name="ssml"
        title={t('exceedRequests')}
        description={t('validateRequestDescription', {
          maxRequests: MAX_REQUEST_DELETE,
        })}
        variant="warning"
        open={validateDeleteRequest}
        onClose={handleCloseValidateDeleteRequestDialog}
        actionComponent={renderCloseButton(
          handleCloseValidateDeleteRequestDialog,
        )}
      />
    </StyledRequests>
  );
};

export default ListDubbingNew;
