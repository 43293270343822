import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InputBase } from '@mui/material';
import actions from '@src/redux/actions';
import { MAX_LENGTH_TITLE } from '@src/constants/request';
import { StyledTitleBar } from './index.style';
import ConvertButton from '../ConvertButton';

const TitleBar = ({
  newUI,
  totalCharacters,
  totalSeconds,
  onChangeLoadingRequest,
  isUploadingFile,
  onResetFile,
  onChangeRequestLoading,
  fileSelectedForDubbing,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { title } = useSelector((state) => state.dubbingRequest);

  const handleChangeTitle = (e) => {
    const { name, value } = e.target;
    if (value.length > MAX_LENGTH_TITLE) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'updateTitleMaxLength',
          value: MAX_LENGTH_TITLE,
        }),
      );
      return;
    }
    dispatch(actions.dubbingRequest.updateDubbingRequestByKey(name, value));
    dispatch(actions.dubbingRequest.updateDubbingRequestErrorByKey(name, ''));
  };

  return (
    <StyledTitleBar>
      <InputBase
        id="add-title"
        value={title}
        placeholder={newUI ? t('noTitle') : t('addTitle')}
        name="title"
        maxLength={MAX_LENGTH_TITLE + 1}
        className="base-input"
        onChange={handleChangeTitle}
        fullWidth
      />
      <ConvertButton
        isUploadingFile={isUploadingFile}
        totalCharacters={totalCharacters}
        totalSeconds={totalSeconds}
        onChangeLoadingRequest={onChangeLoadingRequest}
        onResetFile={onResetFile}
        onChangeRequestLoading={onChangeRequestLoading}
        fileSelectedForDubbing={fileSelectedForDubbing}
      />
    </StyledTitleBar>
  );
};

export default TitleBar;
