import { INIT_NOTIFICATION_DIALOG } from '@src/constants/dialog';
import { actionTypes } from './actions';

export const initialState = {
  limitedFeature: false,
  notificationDialog: INIT_NOTIFICATION_DIALOG,
};

export default function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DISPLAY_DIALOG: {
      const { limitedFeature, notificationDialog } = action;
      return { ...state, limitedFeature, notificationDialog };
    }

    default:
      return state;
  }
}
