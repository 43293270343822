import React from 'react';
import {
  DialogActions,
  Typography,
  DialogTitle,
  IconButton,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import i18n from '@src/languages';

import { ACTION_TYPE } from '@src/constants/banner';
import { LANGUAGE } from '@src/constants';
import { StyledTextBanner } from './index.style';

const TextBanner = ({
  open,
  bannerIndex,
  banner,
  description,
  closeAction,
  onCloseBanner,
  onActionChange,
}) => {
  const { language } = i18n;

  const handleClose = (action) => (event, reason) => {
    const { url, notShowAgain, type } = action;
    if (reason === 'backdropClick') return;
    if (onActionChange) onActionChange(url, type);
    // TODO: check not show again from banner data
    onCloseBanner(bannerIndex, notShowAgain);
  };

  return (
    <StyledTextBanner open={open} fullWidth>
      <div className="dialog-content">
        <DialogTitle classes={{ root: 'dialog-title' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose(closeAction)}
            color="secondary"
            className="close-button"
          >
            <Close color="secondary" />
          </IconButton>
        </DialogTitle>

        <div className="dialog-wrapper">
          <div className="img-wrapper">
            <img src={banner.imageUrl} alt="icon" className="img" />
          </div>
          <Typography className="title">
            {banner.title &&
              (banner.title[language] || banner.title[LANGUAGE.EN])}
          </Typography>
          <div
            className="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                description ||
                (banner.description &&
                  (banner.description[language] ||
                    banner.description[LANGUAGE.EN])),
            }}
          />
          <DialogActions classes={{ root: 'dialog-action' }}>
            {banner.actions.map((action, index) => (
              <Button
                variant={index === 0 ? 'contained' : 'outlined'}
                color={index === 0 ? 'primary' : 'secondary'}
                onClick={handleClose({ ...action, type: ACTION_TYPE.BUTTON })}
                className="action-button"
              >
                {action[language] || action[LANGUAGE.EN]}
              </Button>
            ))}
          </DialogActions>
        </div>
      </div>
    </StyledTextBanner>
  );
};

export default TextBanner;
