import React from 'react';
import { NavigateBefore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { StyledBack } from './index.style';

const BackButton = () => {
  const { t } = useTranslation();
  return (
    <StyledBack>
      <div className="button-back">
        <NavigateBefore color="divider" /> {t('back')}
      </div>
    </StyledBack>
  );
};

export default BackButton;
