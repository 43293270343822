import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPackageTypes } from './index.style';

const PackageTypes = ({ types, activeType, onChangeType }) => {
  const { t } = useTranslation();

  return (
    <StyledPackageTypes>
      {types.map((packageType) => (
        <div
          role="button"
          tabIndex={0}
          onClick={() => onChangeType(packageType.id)}
          className={`package-tab ${
            packageType.id === activeType ? 'package-active-tab' : ''
          }`}
        >
          {t(packageType.label)}
        </div>
      ))}
    </StyledPackageTypes>
  );
};

export default PackageTypes;
