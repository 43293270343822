/* eslint-disable no-restricted-syntax */
import {
  DURATION,
  PACKAGE_CODE,
  PACKAGE_DURATION,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
} from '@src/constants/package';
import { checkVietNam } from '@src/utils/checkCountry';
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from '@src/constants';
import moment from 'moment';
import { PAYMENT_HUB_RENEWAL_TYPE } from '@src/constants/subscription';

const filterPackages = ({
  duration,
  data,
  packageType,
  useFreePackage,
  isDubbingPackages,
}) => {
  const packageLevels = [
    PACKAGE_LEVEL.DUBBING_BASIC,
    PACKAGE_LEVEL.BASIC,
    PACKAGE_LEVEL.ADVANCE,
    PACKAGE_LEVEL.PRO,
    PACKAGE_LEVEL.ENTERPRISE,
    PACKAGE_LEVEL.PROFESSIONAL,
    PACKAGE_LEVEL.STANDARD,
  ];

  let packages = [];

  switch (duration) {
    case DURATION.MONTHLY: {
      packages = data.filter(
        (pkg) =>
          pkg.active &&
          (pkg.expiresIn < 0 || pkg.expiresIn === 30) &&
          packageLevels.includes(pkg.level),
      );
      break;
    }
    case DURATION.QUARTERLY: {
      packages = data.filter(
        (pkg) =>
          pkg.active &&
          (pkg.expiresIn < 0 || pkg.expiresIn === 90) &&
          packageLevels.includes(pkg.level),
      );
      break;
    }
    case DURATION.YEARLY: {
      packages = data.filter(
        (pkg) =>
          pkg.active &&
          (pkg.expiresIn < 0 || pkg.expiresIn === 365) &&
          packageLevels.includes(pkg.level),
      );
      break;
    }
    default:
      break;
  }

  const shouldAddApiEnterprisePackage =
    packageType === PACKAGE_TYPE.API &&
    !packages.some((pkg) => pkg.code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2);

  if (shouldAddApiEnterprisePackage) {
    const apiEnterprisePackage = data.find(
      (pkg) => pkg.code === PACKAGE_CODE.API_ENTERPRISE_YEAR_V2,
    );
    if (apiEnterprisePackage) packages.push(apiEnterprisePackage);
  }

  const isVietNam = checkVietNam();
  if (!isVietNam)
    packages = packages.filter(
      (pkg) =>
        pkg.code !== PACKAGE_CODE.API_PAYG &&
        pkg.code !== PACKAGE_CODE.API_ENTERPRISE_YEAR_V2,
    );

  // Remove auto renew package
  packages = packages.filter(
    (pkg) => pkg.renewalType !== PAYMENT_HUB_RENEWAL_TYPE.AUTO_RENEW,
  );

  // Check use free package
  if (useFreePackage)
    packages = packages.filter((pkg) => pkg.code !== PACKAGE_CODE.STUDIO_BASIC);
  else
    packages = packages.filter((pkg) => pkg.code !== PACKAGE_CODE.STUDIO_FREE);

  const dubbingTrialPackage = data.find(
    (pkg) => pkg.code === PACKAGE_CODE.DUBBING_TRIAL,
  );

  // push dubbing trial package to first packages
  if (isDubbingPackages && dubbingTrialPackage) {
    packages = packages.filter(
      (pkg) => pkg.code !== PACKAGE_CODE.DUBBING_TRIAL,
    );
    packages.unshift(dubbingTrialPackage);
  }

  return packages;
};

const getPackageName = (
  name,
  code,
  language,
  translation,
  onlyShowName = false,
) => {
  try {
    let t = translation;
    if (!translation) t = useTranslation().t;

    if (!code) return '';
    let duration = '';
    let displayName = '';

    if (code.includes('MONTH')) duration = t('packageDurationMonth');
    if (code.includes('YEAR')) duration = t('packageDurationYear');
    if (code.includes('QUARTER')) duration = t('quarterly');
    if (
      name &&
      !code.includes(PACKAGE_LEVEL.TRIAL) &&
      !code.includes(PACKAGE_LEVEL.BASIC)
    ) {
      displayName = name[language] || name[LANGUAGE.EN];
    } else if (code.includes(PACKAGE_LEVEL.TRIAL)) {
      displayName = onlyShowName ? t('trial') : t('packageLevelTrial');
    } else if (code.includes(PACKAGE_LEVEL.BASIC)) {
      displayName = t(onlyShowName ? 'basic' : 'packageLevelBasic');
    } else if (code.includes(PACKAGE_LEVEL.PROFESSIONAL)) {
      displayName = t(
        onlyShowName ? 'professional' : 'packageLevelProfessional',
      );
    } else if (code.includes('ADV')) {
      displayName = t(onlyShowName ? 'advance' : 'packageLevelAdvance');
    } else if (code.includes(PACKAGE_LEVEL.PRO)) {
      displayName = t(onlyShowName ? 'pro' : 'packageLevelPro');
    } else if (code.includes(PACKAGE_LEVEL.STANDARD)) {
      displayName = t(onlyShowName ? 'standard' : 'packageLevelStandard');
    } else if (code.includes(PACKAGE_LEVEL.ENTERPRISE)) {
      displayName = t(onlyShowName ? 'enterprise' : 'packageLevelEnterPrise');
    }

    return duration ? `${displayName} - ${duration}` : displayName;
  } catch (error) {
    return '';
  }
};

const getPackagesName = (packages, language, translation) => {
  if (!packages?.length) return '';
  const names = packages.map(({ name, code }) =>
    getPackageName(name, code, language, translation),
  );

  // group names by display name, format: "displayName - duration1/duration2"
  const groupByDisplayName = names.reduce((acc, name) => {
    const [displayName, duration] = name.split(' - ');
    if (!acc[displayName]) acc[displayName] = [];
    acc[displayName].push(duration);
    return acc;
  }, {});

  // sort duration month - quarterly - year
  const sortDuration = (a, b) => {
    switch (a) {
      case translation('packageDurationMonth'):
        return -1;
      case translation('quarterly'): {
        if (b === translation('packageDurationMonth')) return 1;
        return -1;
      }
      case translation('packageDurationYear'):
        return 1;
      default:
        return 0;
    }
  };

  return Object.entries(groupByDisplayName)
    .map(([displayName, durations]) => {
      const duration = durations.sort(sortDuration).join('/');
      return duration ? `${displayName} - ${duration}` : displayName;
    })
    .join(', ');
};

const checkPackageDiscount = (discountConfig = {}) => {
  const { startAt, endAt } = discountConfig || {};
  const inDiscountTime = startAt && moment().isBetween(startAt, endAt);

  return inDiscountTime;
};

const checkHasDiscountPackage = (listPackages) => {
  let hasDiscountPackage = false;
  for (const currentPackage of listPackages) {
    const inDiscountTime = checkPackageDiscount(currentPackage?.discountConfig);
    if (inDiscountTime) {
      hasDiscountPackage = true;
      break;
    }
  }

  return hasDiscountPackage;
};

const getNewPackageCurrentPrice = (pkg = {}) => {
  const { price, usdPrice, monthlyPrice, usdMonthlyPrice, discountConfig } =
    pkg;
  const isInDiscount = checkPackageDiscount(discountConfig);

  if (isInDiscount)
    return {
      price: discountConfig.price,
      usdPrice: discountConfig.usdPrice,
      monthlyPrice: discountConfig.monthlyPrice,
      usdMonthlyPrice: discountConfig.usdMonthlyPrice,
    };

  return { price, usdPrice, monthlyPrice, usdMonthlyPrice };
};

const getOldPackageCurrentPrice = (pkg = {}) => {
  const { price, usdPrice, monthlyPrice, usdMonthlyPrice } = pkg;
  return { price, usdPrice, monthlyPrice, usdMonthlyPrice };
};

const getPackageCurrentPrice = (pkg, enableNewPackage) => {
  if (enableNewPackage) return getNewPackageCurrentPrice(pkg);
  return getOldPackageCurrentPrice(pkg);
};

const getDiscountPackage = (packages) =>
  packages.find((pkg) => checkPackageDiscount(pkg?.discountConfig));

const getMostPopularPackage = (packages) =>
  packages.find((pkg) => pkg.mostPopular);

const checkAutoRenewalPackage = (pkg) =>
  pkg?.renewalType === PAYMENT_HUB_RENEWAL_TYPE.AUTO_RENEW;

const checkUsingPrepaidMonthlyPkgOfStudio = (usingPackage) => {
  if (!usingPackage) return false;

  const { type, code, renewalType } = usingPackage;
  return (
    type === PACKAGE_TYPE.STUDIO &&
    code.includes(PACKAGE_DURATION.MONTH) &&
    renewalType !== PAYMENT_HUB_RENEWAL_TYPE.AUTO_RENEW
  );
};

const getPackageDuration = (code) => {
  if (code.includes(PACKAGE_DURATION.MONTH)) return DURATION.MONTHLY;
  if (code.includes(PACKAGE_DURATION.QUARTER)) return DURATION.QUARTERLY;
  if (code.includes(PACKAGE_DURATION.YEAR)) return DURATION.YEARLY;
  return '';
};

// TO-DO: handle with other package type
const getPackageTypeFromCode = (code) => {
  if (code.includes(PACKAGE_TYPE.STUDIO)) return PACKAGE_TYPE.STUDIO;
  if (code.includes(PACKAGE_TYPE.DUBBING)) return PACKAGE_TYPE.DUBBING;
  if (code.includes(PACKAGE_TYPE.API)) return PACKAGE_TYPE.API;
  return '';
};

const getFakeEndTimeDiscountForPackage = ({
  startTimeToCountdown,
  realEndTime,
  timeToResetCountdown,
}) => {
  const startTime = new Date(startTimeToCountdown).getTime();
  const endTime = new Date(realEndTime).getTime();
  const now = new Date().getTime();

  const resetInterval = timeToResetCountdown * 60 * 60 * 1000;

  const timeSinceStart = now - startTime;
  const cycles = Math.floor(timeSinceStart / resetInterval);
  let fakeEndTime = startTime + (cycles + 1) * resetInterval;

  if (fakeEndTime > endTime) {
    fakeEndTime = endTime;
  }

  return new Date(fakeEndTime);
};

export {
  filterPackages,
  getPackageName,
  getPackagesName,
  checkHasDiscountPackage,
  getNewPackageCurrentPrice,
  getOldPackageCurrentPrice,
  getPackageCurrentPrice,
  getDiscountPackage,
  getMostPopularPackage,
  checkAutoRenewalPackage,
  checkPackageDiscount,
  checkUsingPrepaidMonthlyPkgOfStudio,
  getPackageDuration,
  getPackageTypeFromCode,
  getFakeEndTimeDiscountForPackage,
};
