import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Skeleton, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '@src/styles/config';
import actions from '@src/redux/actions';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import apis from '@src/apis';
import {
  GLOBAL_PAYMENT_METHOD,
  PAYMENT_METHOD_TYPE,
} from '@src/constants/payment';
import { COLOR } from '@src/styles/color';
import { COUNTRY, PAGINATION_LIMIT } from '@src/constants';
import { AUTO_RENEW_STATUS } from '@src/constants/subscription';
import { getPackageDuration } from '@src/services/package';
import { DURATION } from '@src/constants/package';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { StyledPaymentMethods } from './index.style';
import PaymentMethodItem from './PaymentMethodItem';

const PaymentMethods = ({ isAutoRenewalPackage }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const dispatch = useDispatch();
  const { paymentMethod: selectedPaymentMethod, package: packageState } =
    useSelector((state) => state.order);
  const { latestOrder } = useSelector((state) => state.user);
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);
  const { id: userId, email, phoneNumber } = user;
  const { getFeatureValue } = useFeatureFlags();

  const useAutoRenewWithMomo = getFeatureValue(FEATURE_KEYS.AUTO_RENEW_MOMO, {
    userId,
    email,
    phoneNumber,
  });

  const useNewBankTransfer = getFeatureValue(FEATURE_KEYS.NEW_BANK_TRANSFER, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const isAutoRenewYearPackage = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const packageCode = queryParams.get('package');
    if (!packageCode) return false;

    const packageDuration = getPackageDuration(packageCode);

    return packageDuration === DURATION.YEARLY && isAutoRenewalPackage;
  }, [location.search]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BREAKPOINTS.SMD));

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [prepaidPaymentMethods, setPrepaidPaymentMethods] = useState([]);
  const [autoRenewalPaymentMethods, setAutoRenewalPaymentMethods] = useState(
    [],
  );
  const [loading, setLoading] = useState(true);

  const fetchBanks = async (getBankApi) => {
    const data = await getBankApi();
    if (data?.status) {
      const banks = data?.result || [];
      return banks;
    }
    return [];
  };

  const handleSelectMethod = (method) =>
    dispatch(actions.order.updatePaymentMethod(method));

  const getPrepaidPaymentMethods = async (paymentMethodsData, isVietNam) => {
    let displayPaymentMethods = [];

    displayPaymentMethods = paymentMethodsData.filter(
      (method) =>
        method.active &&
        method.type !== PAYMENT_METHOD_TYPE.APPLE &&
        method.type !== PAYMENT_METHOD_TYPE.GOOGLE,
    );

    if (!isVietNam)
      displayPaymentMethods = paymentMethodsData.filter((method) =>
        GLOBAL_PAYMENT_METHOD.includes(method.type),
      );

    const mappedPaymentMethods = [];

    const bankMethodExists = displayPaymentMethods.find(
      (method) => method.type === PAYMENT_METHOD_TYPE.BANK,
    );

    if (bankMethodExists && !useNewBankTransfer) {
      const banks = await fetchBanks(apis.banks.getTransferBanks);
      banks.forEach(({ name, logo, bankNumber }) => {
        mappedPaymentMethods.push({
          name,
          logo,
          bankNumber,
          id: bankMethodExists.id,
        });
      });
    }

    displayPaymentMethods.forEach((method) => {
      if (method.type !== PAYMENT_METHOD_TYPE.BANK || useNewBankTransfer)
        mappedPaymentMethods.push({
          id: method.id,
          name: method.name[language],
          logo: method.icon,
          type: method.type,
        });
    });
    return mappedPaymentMethods;
  };

  const getAutoRenewalPaymentMethods = (paymentMethodsData = []) => {
    const displayPaymentMethods = paymentMethodsData.filter(
      (method) =>
        method.active &&
        method.type !== PAYMENT_METHOD_TYPE.APPLE &&
        method.type !== PAYMENT_METHOD_TYPE.GOOGLE &&
        method.type !== PAYMENT_METHOD_TYPE.BANK,
    );

    const mappedPaymentMethods = [];
    displayPaymentMethods.forEach((method) => {
      if (method.autoRenewStatus !== AUTO_RENEW_STATUS.UNAVAILABLE)
        mappedPaymentMethods.push({
          id: method.id,
          name: method.autoRenewName?.[language] || method.name[language],
          logo: method.autoRenewIcon || method.icon,
          autoRenewStatus: method.autoRenewStatus,
          type: method.type,
          rank: method.autoRenewStatus === AUTO_RENEW_STATUS.AVAILABLE ? 1 : 0,
        });
    });

    const sortedPaymentMethods = mappedPaymentMethods.sort((a) =>
      a?.rank ? -1 : 1,
    );
    return sortedPaymentMethods;
  };

  const updateSelectedPrepaidPaymentMethod = (prepaidMethods) => {
    const { paymentMethod = {}, bank } = latestOrder || {};
    const { type, id } = paymentMethod;
    let selectPaymentMethod;
    if (id) {
      if (type !== PAYMENT_METHOD_TYPE.BANK)
        selectPaymentMethod = prepaidMethods.find((item) => item.id === id);
      else if (bank?.name) {
        selectPaymentMethod = prepaidMethods.find(
          (item) => item.name === bank?.name,
        );
      }
    }

    if (selectPaymentMethod?.name)
      dispatch(actions.order.updatePaymentMethod(selectPaymentMethod));
    else dispatch(actions.order.updatePaymentMethod(prepaidMethods[0] || {}));
  };

  const updatePaymentMethods = (
    prepaidMethods = prepaidPaymentMethods,
    autoRenewalMethods = autoRenewalPaymentMethods,
  ) => {
    if (isAutoRenewalPackage) {
      dispatch(actions.order.updatePaymentMethod(autoRenewalMethods[0] || {}));
    } else {
      updateSelectedPrepaidPaymentMethod(prepaidMethods);
    }

    setPaymentMethods(
      isAutoRenewalPackage ? autoRenewalMethods : prepaidMethods,
    );
  };

  const fetchPaymentMethods = async () => {
    setLoading(true);
    let isVietNam = true;
    try {
      const ipInfo = await apis.country.getCountry();
      if (ipInfo?.country && ipInfo?.country !== COUNTRY.VN) isVietNam = false;
      else isVietNam = true;
    } catch (error) {
      isVietNam = true;
    }

    const data = await apis.paymentMethods.getPaymentMethods({
      sort: 'rank_asc',
    });
    if (data.status) {
      const { result } = data;

      const paymentMethodsData = result.paymentMethods || [];
      const prepaidMethods = await getPrepaidPaymentMethods(
        paymentMethodsData,
        isVietNam,
      );
      const autoRenewalMethods =
        getAutoRenewalPaymentMethods(paymentMethodsData);

      setAutoRenewalPaymentMethods(autoRenewalMethods);
      setPrepaidPaymentMethods(prepaidMethods);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    if (
      Object.keys(packageState).length &&
      prepaidPaymentMethods.length &&
      autoRenewalPaymentMethods.length
    ) {
      updatePaymentMethods(prepaidPaymentMethods, autoRenewalPaymentMethods);
    }
  }, [
    packageState,
    prepaidPaymentMethods,
    autoRenewalPaymentMethods,
    isAutoRenewalPackage,
  ]);

  return (
    <StyledPaymentMethods>
      <Typography className="title">{t('choosePaymentMethod')}</Typography>
      {useAutoRenewWithMomo && isAutoRenewYearPackage && (
        <Typography className="note">{t('momoAutoRenewNote')}</Typography>
      )}
      {loading ? (
        <Grid container spacing={1}>
          {Array.from(new Array(PAGINATION_LIMIT)).map(() => (
            <Grid item xs={6} sm={isMobile && 3} lg={4} xl={3} key={uuidv4()}>
              <Skeleton
                animation="wave"
                width="100%"
                height={90}
                sx={{ bgcolor: COLOR.grayV2[100] }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={1}>
          {paymentMethods.map((method) => (
            <Grid
              item
              xs={6}
              sm={isMobile && 3}
              lg={4}
              xl={4}
              key={method.name}
            >
              <PaymentMethodItem
                method={method}
                isAutoRenewYearPackage={isAutoRenewYearPackage}
                isAutoRenewalPackage={isAutoRenewalPackage}
                selected={
                  selectedPaymentMethod?.name?.toLowerCase() ===
                  method.name?.toLowerCase()
                }
                useNewBankTransfer={useNewBankTransfer}
                onSelect={handleSelectMethod}
                useAutoRenewWithMomo={useAutoRenewWithMomo}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </StyledPaymentMethods>
  );
};

export default PaymentMethods;
