import { Avatar, AvatarGroup } from '@mui/material';
import React from 'react';

const StackedAvatars = ({ images }) => (
  <AvatarGroup>
    {images.map((img, index) => (
      <Avatar
        key={img}
        alt=""
        src={img}
        sx={{
          width: '24px',
          height: '24px',
          border: `0px !important`,
          zIndex: index,
          margin: '2px',
        }}
      />
    ))}
  </AvatarGroup>
);

export default StackedAvatars;
