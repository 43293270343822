/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import { FileUploadOutlined, Mic, Stop } from '@mui/icons-material';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import Card from '@src/components/Card';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import actions from '@src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isValidFile, validateFileSize } from '@src/utils/checkValid';
import { AUDIO_FILE_FORMAT } from '@src/constants/voice';
import { MAX_BACKGROUND_MUSIC_FILE_SIZE } from '@src/constants/backgroundMusic';
import { WS_ASR_URL } from '@src/configs';
import apis from '@src/apis';
import { formatAudioTime } from '@src/utils/time';
import { PING_INTERVAL, WS_TYPE } from '@src/constants/websocket';

import { upperCaseFirstLetter } from '@src/utils/string';
import MediaStreamRecorder from 'msr';
import getWavHeaders from 'wav-headers';
import { decodeWAV, toBuffer, toArrayBuffer } from './audio';
import { StyledSTT } from './index.style';
import { CONFIG, META } from './config';

const STT = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const accessTokenRef = useRef(accessToken);
  const ws = useRef(null);

  // upload
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [preUrlLoading, setPreUrlLoading] = useState(false);
  const hiddenFileInput = useRef(null);

  // record
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const transcripts = useRef({});
  const recorder = useRef(null);
  const stream = useRef(null);
  const audioBlobs = useRef([]);
  const audioBuffer = useRef(Buffer.from([]));

  // result
  const [audioUrl, setAudioUrl] = useState();
  const [sttResult, setSttResult] = useState({});
  const [content, setContent] = useState([]);
  const [highlightedSentence, setHighlightedSentence] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getUploadPresignedUrl = async (filename) => {
    setPreUrlLoading(true);
    const extension = filename.split('.').pop();
    const name = filename.split('.').shift().trim().replace(/\s+/g, '-');
    const data = await apis.backgroundMusic.getBgMusicPresignedUrlForUploading(
      name,
      extension,
    );
    setPreUrlLoading(false);
    if (data && data.status) return data.result.url;
    return null;
  };

  const recognize = async (fileUrl) => {
    setIsProcessing(true);
    const payload = {
      fileUrl,
      responseType: 'direct',
    };

    setAudioUrl(fileUrl);
    const stt = await apis.stt.recognizeSpeechToText(payload);
    if (stt && stt.status) {
      setSttResult(stt.result);
    } else {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: JSON.stringify(stt),
        }),
      );
    }
    setIsProcessing(false);
  };

  const getFile = async (event) => {
    const { files } = event.target;
    if (!files || files.length !== 1) return undefined;

    const { name } = files[0];
    if (!isValidFile(name, AUDIO_FILE_FORMAT)) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: '',
        }),
      );
      return undefined;
    }
    if (!validateFileSize(files[0].size, MAX_BACKGROUND_MUSIC_FILE_SIZE)) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: '',
        }),
      );
      return undefined;
    }

    setIsUploading(true);
    const fileContent = event.target.files[0];

    hiddenFileInput.current.value = null;
    return { fileContent, name };
  };

  const handleClick = async () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const file = await getFile(event);
    if (!file) return;
    const { fileContent, name } = file;
    const uploadURL = await getUploadPresignedUrl(name);
    const data = await apis.upload.uploadByPresignedUrl(uploadURL, fileContent);
    if (data.status === 200) {
      const { pathname, origin } = new URL(uploadURL);
      const fileUrl = `${origin}${pathname}`;
      await recognize(fileUrl);
    }
    setIsUploading(false);
  };

  const convertSttResultToParagraph = () => {
    const utterancesAll = sttResult?.uploadRequestResult?.result;
    const utterancesSort = utterancesAll.sort(
      (a, b) => a.startTime - b.startTime,
    );
    return utterancesSort.map((utterance) => ({
      text: utterance.transcript,
      startTime: utterance.start,
      endTime: utterance.end,
      isHighlighted: false,
    }));
  };

  const handleChangePosition = (event) => {
    const newPosition = event.target.currentTime;
    // check position with content
    const currentSection = content.find(
      (section) =>
        // compare time
        section.startTime <= newPosition && section.endTime >= newPosition,
    );
    if (currentSection) {
      setHighlightedSentence(currentSection.text);
    }
  };

  useEffect(() => {
    if (sttResult && sttResult.uploadRequestResult) {
      const paragraphs = convertSttResultToParagraph();
      setContent(paragraphs);
    }
  }, [sttResult]);

  const sendAudioData = async (buffer) => {
    try {
      if (!Buffer.isBuffer(buffer)) return;
      // eslint-disable-next-line camelcase
      const audio_content = buffer.toString('base64');
      if (ws.current.readyState === 1) {
        ws.current.send(
          JSON.stringify({
            type: WS_TYPE.STREAM_DATA,
            payload: {
              audio_content,
            },
          }),
        );
      }
    } catch (error) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: JSON.stringify(error),
        }),
      );
    }
  };

  const sendAudioEnd = () => {
    try {
      const doneSignal = Buffer.from('Done').toString('base64');
      if (ws.current.readyState === 1) {
        ws.current.send(
          JSON.stringify({
            type: WS_TYPE.STREAM_DATA,
            payload: {
              audio_content: doneSignal,
            },
          }),
        );
      }
    } catch (e) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: JSON.stringify(e),
        }),
      );
    }
    // automatically close ws after 10 seconds
    setTimeout(() => {
      ws.current.close();
    }, 10000);
  };

  const exportAudioBufferToAudio = async (toExportAudioBuffer) => {
    const wavHeader = getWavHeaders({
      channels: 1,
      sampleRate: 48000,
      bitDepth: 16,
    });
    const encodedWav = Buffer.concat([wavHeader, toExportAudioBuffer]);
    const blob = new Blob([toArrayBuffer(encodedWav)], { type: 'audio/wav' });

    const blobUrl = URL.createObjectURL(blob);
    setAudioUrl(blobUrl);
  };

  const startRecording = async () => {
    // buffer
    audioBlobs.current = [];
    audioBuffer.current = Buffer.from([]);
    // audio stream
    stream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    // audio stream recorder
    recorder.current = new MediaStreamRecorder(stream.current);
    recorder.current.mimeType = 'audio/wav';
    recorder.current.audioChannels = 1;
    recorder.current.ondataavailable = async (e) => {
      const blobData = e;

      // blobs
      audioBlobs.current.push(blobData);

      // array buffer
      const arrayBufferData = await blobData.arrayBuffer();

      // buffer
      const bufferData = toBuffer(arrayBufferData);
      const decodedWav = decodeWAV(bufferData);
      const headlessBufferData = Buffer.from(decodedWav);
      audioBuffer.current = Buffer.concat([
        audioBuffer.current,
        headlessBufferData,
      ]);

      // send audio to server
      await sendAudioData(headlessBufferData);
    };
    recorder.current.start(250);
  };

  const stopRecording = async () => {
    // close stream and export
    recorder.current.stop();
    const tracks = stream.current.getTracks();
    tracks.forEach((track) => track.stop());
    sendAudioEnd();
    await exportAudioBufferToAudio(audioBuffer.current);

    // reset buffer
    stream.current = null;
    recorder.current = null;
    audioBlobs.current = [];
    audioBuffer.current = Buffer.from([]);
  };
  const toggleRecording = async () => {
    const currentState = isRecording;
    setIsRecording((prev) => !prev);
    if (recorder && !currentState) {
      await startRecording();
    } else {
      await stopRecording();
    }
  };

  // set interval recording duration and return 0 when stop recording
  useEffect(() => {
    let interval = null;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingDuration((prev) => prev + 1);
      }, 1000);
    } else if (!isRecording && recordingDuration !== 0) {
      clearInterval(interval);
      setRecordingDuration(0);
    }
    return () => clearInterval(interval);
  }, [isRecording, recordingDuration]);

  useEffect(() => {
    accessTokenRef.current = accessToken;
  }, [accessToken]);

  useEffect(() => {
    if (isRecording) {
      if (ws.current) ws.current.close();
      ws.current = new WebSocket(`${WS_ASR_URL}/api/v1/recognize`);
      let pingInterval;
      ws.current.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('Websocket is connected.');
        transcripts.current = {};
        ws.current.send(
          JSON.stringify({
            type: WS_TYPE.STREAM_INIT,
            accessToken: accessTokenRef.current,
            payload: {
              meta: META(),
              config: CONFIG(),
            },
          }),
        );
        pingInterval = setInterval(() => {
          ws.current.send(JSON.stringify({ type: WS_TYPE.PING }));
        }, PING_INTERVAL);
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // console.log(JSON.stringify(data));
        if (data.type === WS_TYPE.STREAM_DATA) {
          transcripts.current[data.id] = {
            ...transcripts.current[data.id],
            startTime: data.start_second,
            endTime: data.end_second,
            text: data.text_raw,
          };
          setContent(Object.values(transcripts.current));

          // last sentence
          const lastSentence = Object.values(transcripts.current).slice(-1)[0];
          if (lastSentence) {
            setHighlightedSentence(lastSentence.text);
          }
        }
      };

      ws.current.onclose = (event) => {
        const { code, reason, wasClean } = event;
        // eslint-disable-next-line no-console
        console.log('Websocket is closed.', { event, code, reason, wasClean });
        transcripts.current = {};
        clearInterval(pingInterval);
      };
    }
  }, [isRecording]);

  return (
    <Card>
      <StyledSTT>
        <div className="title">
          <Typography variant="h3" className="">
            {t('sttBetaTitle')}
          </Typography>
          <div
            className="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('sttBetaDescription', {
                language: t('vietnamese'),
              }),
            }}
          />
        </div>
        <Grid container spacing={2} padding={10} paddingTop={5}>
          <Grid item xs={12} md={5} className="recording">
            <Typography variant="h5" className="recording-title">
              {t('record')}
            </Typography>
            <Typography variant="body1" className="recording-description">
              {t('recordDescription')}
            </Typography>
            <div
              className={classNames('recording-button-wrapper', {
                isRecording,
              })}
            >
              <Button onClick={toggleRecording} className="recording-button">
                {isRecording ? (
                  <Stop className="record-icon" />
                ) : (
                  <Mic className="record-icon" />
                )}
              </Button>
            </div>
            <Typography variant="h6">
              {formatAudioTime(recordingDuration)}
            </Typography>
            <Typography variant="body1" className="recording-description">
              {t('or')}
            </Typography>
            <Button
              size="small"
              variant="contained"
              onClick={handleClick}
              disabled={isProcessing || isProcessing}
              className="upload-audio-button"
            >
              {isUploading || isProcessing ? (
                <CircularProgress size={15} thickness={8} className="loading" />
              ) : (
                <FileUploadOutlined />
              )}
              {isProcessing ? t('processAudio') : t('uploadAudio')}
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              accept=".mp3,.wav"
              onChange={handleChange}
              className="hide-input"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <div className="convert">
              <div className="upload-audio">
                <Typography variant="h5" className="upload-audio-title">
                  {t('result')}
                </Typography>
                {audioUrl && (
                  <audio
                    src={audioUrl}
                    controls
                    className="audio-box"
                    onTimeUpdate={handleChangePosition}
                  />
                )}
              </div>
              <div className="convert-box">
                {content
                  .filter((section) => section.text && section.text?.length)
                  .map((section) => (
                    <span
                      key={uuidv4()}
                      className={classNames('convert-item', {
                        active: highlightedSentence === section.text,
                      })}
                    >
                      {upperCaseFirstLetter(section.text)}.{' '}
                    </span>
                  ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </StyledSTT>
    </Card>
  );
};

export default STT;
