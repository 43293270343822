import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import React, {
  Checkbox,
  DialogContent,
  IconButton,
  FormControlLabel,
  Typography,
  Button,
} from '@mui/material';
import AutoRenewalPromotionIcon from '@src/assets/images/auto-renewal-promotion.png';
import route from '@src/constants/route';
import { StyledAutoRenewalDialog } from './index.style';

const AutoRenewDialog = ({ open, onClose }) => {
  const [notShowAgain, setNotShowAgain] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeNotShowAgain = () => setNotShowAgain(!notShowAgain);

  const handleRegisterNow = () => {
    history.push(route.CONFIG_AUTO_RENEW);
    onClose(notShowAgain);
  };

  const handleClose = () => {
    onClose(notShowAgain);
  };

  return (
    <StyledAutoRenewalDialog
      classes={{ paper: 'dialog' }}
      open={open}
      fullWidth
      max-maxWidth="sm"
    >
      <DialogContent className="dialog-content">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="secondary"
          className="close-button"
        >
          <Close color="white" />
        </IconButton>
        <div className="content-wrapper">
          <div className="title-wrapper">
            <Typography className="title" color="white">
              {t('autoRenewalTitle')}
            </Typography>
            <img
              className="image"
              src={AutoRenewalPromotionIcon}
              alt="banner"
            />
            <Typography className="sub-title" color="white">
              {t('autoRenewalSubTitle')}
            </Typography>
          </div>
          <Typography className="description" color="white">
            {t('autoRenewalDescription')}
          </Typography>
          <Button
            variant="contained"
            color="white"
            className="button"
            onClick={handleRegisterNow}
          >
            {t('registerNow')}
          </Button>
          <div className="not-show-again">
            <FormControlLabel
              control={
                <Checkbox
                  classes={{ root: 'checkbox' }}
                  checked={notShowAgain}
                  defaultChecked
                  color="white"
                  onChange={handleChangeNotShowAgain}
                />
              }
              label={
                <Typography className="not-show-again-text">
                  {t('notShowAgain')}
                </Typography>
              }
              labelPlacement={t('notShowAgain')}
            />
          </div>
        </div>
      </DialogContent>
    </StyledAutoRenewalDialog>
  );
};

export default AutoRenewDialog;
