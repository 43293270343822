import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createArrayFromLength } from '@src/utils/array';
import { StyledVoiceListLoading } from './index.style';

const NUMBER_LOADING_ITEM = 5;

const SecctionLoading = () => {
  const loadingItems = createArrayFromLength(NUMBER_LOADING_ITEM);
  return (
    <StyledVoiceListLoading>
      <div className="video-guide-wrapper">
        {loadingItems.map(() => (
          <div className="video-guide-box" key={uuidv4()}>
            <div className="loading-item animate-pulse" />
          </div>
        ))}
      </div>
    </StyledVoiceListLoading>
  );
};

export default SecctionLoading;
