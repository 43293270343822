/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys';
import {
  Backdrop,
  Grid,
  CircularProgress,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import Card from '@src/components/Card';
import { ORDER_STATE, ORDER_STATUS } from '@src/constants/order';
import ROUTES from '@src/constants/route';
import { checkVietNam } from '@src/utils/checkCountry';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { COLOR } from '@src/styles/color';
import { getPackageCurrentPrice, getPackageName } from '@src/services/package';
import { CRM_URL } from '@src/configs';
import { PAYMENT_METHOD_TYPE } from '@src/constants/payment';
import { getSavedPrice } from '@src/services/voucher';
import { getOrderPrice, getTotalPrice } from '@src/services/price';
import { validateEmail } from '@src/utils/checkValid';
import {
  FREE_PACKAGE_CODE,
  PACKAGE_LEVEL,
  PACKAGE_TYPE,
  PREFIX_AUTO_RENEW,
} from '@src/constants/package';
import { BANNER_TYPE } from '@src/constants/banner';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { SUBSCRIPTION_PURCHASE_STATUS } from '@src/constants/subscription';
import dataSenses from '@src/services/dataSenses';
import {
  getDefaultBank,
  getProductInfoForUserPackagePurchase,
} from '@src/services/payment';

import PackageInfo from './PackageInfo';
import { StyledPayment } from './index.style';
import OrderInfo from './OrderInfo';
import PaymentMethods from './PaymentMethods';
import PaymentFooter from './PaymentFooter';
import BankTransferDialog from './Dialog/BankTransferDialog';
import QRPaymentDialog from './Dialog/QRPaymentDialog';
import PaymentNotiDialog from './Dialog/PaymentNotiDialog';
import SubscriptionInfo from './SubscriptionInfo';

const DIALOG_NAMES = {
  DOWNGRADE_PACKAGE: 'downgradePackage',
  UPGRADE_PACKAGE_OR_BUY_MORE: 'upgradePackageOrBuyMore',
  DOWNGRADE_PACKAGE_AND_CANCEL_SUBSCRIPTION:
    'downgradePackageAndCancelSubscription',
  CANCEL_SUBSCRIPTION: 'cancelSubscription',
  UNPAID_ORDER: 'unpaidOrder',
  NOT_CONFIRMED_ORDER: 'notConfirmedOrder', // Customer confirmed transfer, waiting for confirmation form customer service
  COMPLETED_ORDER: 'completedOrder',
};
const Payment = () => {
  const location = useLocation();
  const isDubbingRoute = useCheckDubbingRoute();
  const [loading, setLoading] = useState(false);
  const [qrPaymentDialog, setQRPaymentDialog] = useState(null);
  const [bankTransferDialog, setBankTransferDialog] = useState(null);
  const [openDialog, setOpenDialog] = useState({});
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [showPendingOrder, setShowPendingOrder] = useState(true);
  const [selectedPackageCode, setSelectedPackageCode] = useState('');
  const [productInfo, setProductInfo] = useState({
    total: null,
    expires: null,
    packageType: null,
  });
  const [isShowAutoRenewalPackage, setIsShowAutoRenewalPackage] = useState(
    location?.search?.includes(PREFIX_AUTO_RENEW),
  );
  const [checkedAgreeTermAutoRenew, setCheckedAgreeTermAutoRenew] =
    useState(false);
  const [errorNotCheckAgreeTermAutoRenew, setErrorNotCheckAgreeTermAutoRenew] =
    useState(false);

  const { user } = useSelector((state) => state.auth);
  const {
    latestOrder,
    usingPackage,
    backToTTS,
    closeQRPayment,
    backToDubbing,
    usingDubbingPackage,
    latestDubbingOrder,
    subscriptionPurchase,
  } = useSelector((state) => state.user);
  const { openSidebar } = useSelector((state) => state.layout);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVietNam = checkVietNam();
  const [banks, setBanks] = useState([]);

  const fetchBanks = async () => {
    const data = await apis.banks.getTransferBanks();
    if (data?.status) setBanks(data?.result || []);
  };

  const { getFeatureValue } = useFeatureFlags();
  const autoRenewPayment = getFeatureValue(FEATURE_KEYS.AUTO_RENEWAL_PAYMENT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const useNewLegal = getFeatureValue(FEATURE_KEYS.NEW_LEGAL, {
    userId: user.id,
    phoneNumber: user.phoneNumber,
    email: user.email,
  });
  const useNewBankTransfer = getFeatureValue(FEATURE_KEYS.NEW_BANK_TRANSFER, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const bankDefaultValue = getFeatureValue(FEATURE_KEYS.DEFAULT_BANK, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const useNewWarningBuyPackage = getFeatureValue(
    FEATURE_KEYS.NEW_WARNING_BUY_PACKAGE,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const dataSensesFeatures = getFeatureValue(FEATURE_KEYS.DATASENSES);
  const sendDataSensesFromServer = dataSensesFeatures?.s2s;

  const {
    voucher,
    package: packageState,
    userOrder,
    paymentMethod,
    invoice,
    hasInvoice,
  } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const handleChangeSelectedPackageCode = (value) =>
    setSelectedPackageCode(value);

  const handleCloseDialog = (dialogName) => {
    setOpenDialog({ ...openDialog, [dialogName]: false });
  };

  const handleOpenDialog = (dialogName) =>
    setOpenDialog({ ...openDialog, [dialogName]: true });

  const handleOpenConfirmDialog = ({
    user,
    packageState,
    voucher,
    dialogName,
  }) => {
    const { total, expires, packageType } =
      getProductInfoForUserPackagePurchase({
        user,
        packageState,
        voucher,
      });
    setProductInfo({ total, expires, packageType });
    handleOpenDialog(dialogName);
  };

  const handleChangeLoading = (value) => setLoading(value);

  const handleChangeIsPhoneNumberValid = (value) =>
    setIsPhoneNumberValid(value);

  const handleRedirectToPaymentHistory = () =>
    history.push(
      isDubbingRoute ? ROUTES.PAYMENT_HISTORY_DUBBING : ROUTES.PAYMENT_HISTORY,
    );

  const handleRedirectToPackage = () =>
    history.push(isDubbingRoute ? ROUTES.PAYMENT_DUBBING : ROUTES.PAYMENT);

  const handleRedirectToTTS = () =>
    history.push(isDubbingRoute ? ROUTES.DUBBING : ROUTES.TTS);

  const checkNoneRedirectOnlineGateway = (paymentMethodType) => {
    const NONE_REDIRECT_ONLINE_GATEWAY = [PAYMENT_METHOD_TYPE.SHOPEEPAY];
    return paymentMethodType.includes(NONE_REDIRECT_ONLINE_GATEWAY);
  };

  const getBankDefault = () => {
    const bankDefaultName = getDefaultBank(bankDefaultValue);
    const bankDefault = banks.find((bank) => bank.name === bankDefaultName);

    return bankDefault;
  };

  const { price: currPrice, usdPrice: currUsdPrice } = getPackageCurrentPrice(
    packageState,
    true,
  );

  const savedPrice = getSavedPrice({
    voucher,
    currPrice,
    currUsdPrice,
  });

  const totalPrice = getTotalPrice({
    voucher,
    currPrice,
    currUsdPrice,
    savedPrice,
  });

  const verifyInvoice = () => {
    const { companyName, companyAddress, companyTax, companyEmail } = invoice;
    const errors = {};

    if (!companyName.trim()) errors.companyName = 'companyNameRequired';
    if (companyName?.trim() && companyName?.trim().length < 2)
      errors.companyName = 'companyNameInvalid';
    if (!companyAddress?.trim())
      errors.companyAddress = 'companyAddressRequired';
    if (!companyTax?.trim()) errors.companyTax = 'companyTaxRequired';
    if (!companyEmail?.trim()) errors.companyEmail = 'emailRequired';
    if (companyEmail && !validateEmail(companyEmail))
      errors.companyEmail = 'emailInvalid';

    return errors;
  };

  const getCallbackUrl = () => {
    if (isDubbingRoute) return `${CRM_URL}${ROUTES.PAYMENT_DUBBING}`;
    if (isShowAutoRenewalPackage) return `${CRM_URL}${ROUTES.TTS}`;
    return `${CRM_URL}${ROUTES.PAYMENT}`;
  };

  const handleCreateOrder = async ({
    confirmDowngrade = false,
    confirmCancelSubscription = false,
    bankCode,
    confirmUpgradeOrBuyMore = false,
  }) => {
    if (useNewLegal && isShowAutoRenewalPackage && !checkedAgreeTermAutoRenew) {
      setErrorNotCheckAgreeTermAutoRenew(true);
      return;
    }
    if (useNewLegal) {
      setErrorNotCheckAgreeTermAutoRenew(false);
    }
    if (hasInvoice) {
      const invoiceError = verifyInvoice();
      if (Object.values(invoiceError).length > 0) {
        dispatch(actions.order.updateErrorInvoice(invoiceError));
        return;
      }
    }
    if (!userOrder.phoneNumber) {
      setIsPhoneNumberValid(false);
      // Focus to phone number input field when phone number is empty
      const inputPhoneNumber = document.getElementById(
        'input-phone-number-payment',
      );
      if (inputPhoneNumber) inputPhoneNumber.focus();
      return;
    }

    const comparisonUsingPackage = isDubbingRoute
      ? usingDubbingPackage
      : usingPackage;
    const checkLatestOrderPending = isDubbingRoute
      ? latestDubbingOrder.status !== ORDER_STATUS.PENDING
      : latestOrder.status !== ORDER_STATUS.PENDING;

    const isDowngradePackage =
      comparisonUsingPackage &&
      comparisonUsingPackage?.rank > packageState.rank &&
      checkLatestOrderPending;

    const SHOULD_CANCEL_SUBSCRIPTION_STATUS = [
      SUBSCRIPTION_PURCHASE_STATUS.ACTIVE,
      SUBSCRIPTION_PURCHASE_STATUS.PENDING,
    ];

    const shouldCancelSubscription =
      autoRenewPayment &&
      packageState.type === PACKAGE_TYPE.STUDIO &&
      SHOULD_CANCEL_SUBSCRIPTION_STATUS.includes(
        subscriptionPurchase?.status,
      ) &&
      !confirmCancelSubscription;

    if (isDowngradePackage && !confirmDowngrade) {
      if (shouldCancelSubscription) {
        handleOpenDialog(
          DIALOG_NAMES.DOWNGRADE_PACKAGE_AND_CANCEL_SUBSCRIPTION,
        );
      } else if (useNewWarningBuyPackage) {
        handleOpenConfirmDialog({
          user,
          packageState,
          voucher,
          dialogName: DIALOG_NAMES.DOWNGRADE_PACKAGE,
        });
      } else {
        handleOpenDialog(DIALOG_NAMES.DOWNGRADE_PACKAGE);
      }
      return;
    }

    if (shouldCancelSubscription) {
      handleOpenDialog(DIALOG_NAMES.CANCEL_SUBSCRIPTION);
      return;
    }

    if (!confirmUpgradeOrBuyMore && useNewWarningBuyPackage) {
      handleOpenConfirmDialog({
        user,
        packageState,
        voucher,
        dialogName: DIALOG_NAMES.UPGRADE_PACKAGE_OR_BUY_MORE,
      });
      return;
    }

    const bankDefault = getBankDefault();

    setLoading(true);
    const createFields = {
      packageId: packageState.id,
      paymentMethodId: paymentMethod.id,
      bankCode: useNewBankTransfer
        ? bankCode || bankDefault?.bankNumber
        : paymentMethod.bankNumber,
      callbackUrl: getCallbackUrl(),
      downgradePackage: confirmDowngrade,
      confirmCancelSubscription,
      phoneNumber: userOrder.phoneNumber,
      voucherId: voucher?.id,
      isGlobal: !isVietNam,
      affiliateMarketerId: user.affiliateMarketerId,
    };
    if (hasInvoice) createFields.invoice = invoice;
    if (sendDataSensesFromServer)
      createFields.datasenses = dataSenses.getDataSensesRequireFields();

    const data = await apis.orders.createOrder(createFields);
    dispatch(actions.order.updateErrorInvoice({}));
    setLoading(false);

    if (!data?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'buyPackageFailure',
        }),
      );
      return;
    }

    const newOrder = data.result;

    dataSenses.sendAddToCartEvent({
      userId: newOrder.userId,
      product: newOrder.type,
      packageCode: newOrder.package?.code,
      price: newOrder.price,
      packagePrice: newOrder.packagePrice,
      packageId: newOrder.id,
    });

    const isRenderedBankTransfer =
      newOrder.paymentMethod?.type === PAYMENT_METHOD_TYPE.BANK &&
      !newOrder.paymentLink;

    if (isRenderedBankTransfer) {
      setBankTransferDialog({
        selectedBank: newOrder.bank,
        price: newOrder.price,
      });
      if (newOrder.type === PACKAGE_TYPE.STUDIO)
        dispatch(actions.user.getLatestOrderSuccess(newOrder));
      if (newOrder.type === PACKAGE_TYPE.DUBBING)
        dispatch(actions.user.getLatestDubbingOrderSuccess(newOrder));
      return;
    }

    if (checkNoneRedirectOnlineGateway(newOrder.paymentMethod?.type)) {
      setQRPaymentDialog({
        name: newOrder.paymentMethod?.name,
        icon: newOrder.paymentMethod?.icon,
        paymentLink: newOrder.paymentLink,
        price: newOrder.price,
        createdAt: newOrder.createdAt,
      });
      if (newOrder.type === PACKAGE_TYPE.STUDIO)
        dispatch(actions.user.getLatestOrderSuccess(newOrder));
      if (newOrder.type === PACKAGE_TYPE.DUBBING)
        dispatch(actions.user.getLatestDubbingOrderSuccess(newOrder));
      return;
    }
    window.location.href = data.result.paymentLink;
  };

  const handleContinuePaying = () => {
    handleCloseDialog(DIALOG_NAMES.UNPAID_ORDER);

    const latestOrderData = isDubbingRoute ? latestDubbingOrder : latestOrder;

    if (checkNoneRedirectOnlineGateway(latestOrderData?.paymentMethod?.type)) {
      setQRPaymentDialog({
        name: latestOrderData?.paymentMethod?.name,
        icon: latestOrderData?.paymentMethod?.icon,
        paymentLink: latestOrderData?.paymentLink,
        price: latestOrderData?.price,
        createdAt: latestOrderData?.createdAt,
      });
      return;
    }

    const isRenderedBankTransfer =
      latestOrderData.paymentMethod?.type === PAYMENT_METHOD_TYPE.BANK &&
      !latestOrderData.paymentLink;

    if (isRenderedBankTransfer) {
      setBankTransferDialog({
        selectedBank: latestOrderData.bank,
        price: latestOrderData?.price,
      });
      return;
    }

    if (latestOrderData.paymentLink)
      window.location.assign(latestOrderData.paymentLink);
  };

  const handleConfirmDowngradePackageAndCancelSubscription = async () => {
    handleCloseDialog(DIALOG_NAMES.DOWNGRADE_PACKAGE_AND_CANCEL_SUBSCRIPTION);
    handleCreateOrder({
      confirmDowngrade: true,
      confirmCancelSubscription: true,
      confirmUpgradeOrBuyMore: true,
    });
  };

  const handleConfirmCancelSubscription = async () => {
    handleCloseDialog(DIALOG_NAMES.CANCEL_SUBSCRIPTION);
    handleCreateOrder({
      confirmCancelSubscription: true,
      confirmUpgradeOrBuyMore: true,
    });
  };

  const handleConfirmDowngradePackage = () => {
    handleCreateOrder({
      confirmDowngrade: true,
      confirmUpgradeOrBuyMore: true,
    });
    handleCloseDialog(DIALOG_NAMES.DOWNGRADE_PACKAGE);
  };

  const handleConfirmUpgradeOrBuyMore = () => {
    handleCreateOrder({
      confirmUpgradeOrBuyMore: true,
    });
    handleCloseDialog(DIALOG_NAMES.UPGRADE_PACKAGE_OR_BUY_MORE);
  };

  const handleCloseBankTransferDialog = () => setBankTransferDialog(false);

  const handleCloseQrDialog = () => setQRPaymentDialog(false);

  const fetchOrderState = async (token, packageCode) => {
    const data = await apis.orders.getOrderState(token);

    if (data.status) {
      switch (data.result.state) {
        case ORDER_STATE.SUCCESS: {
          handleOpenDialog(DIALOG_NAMES.COMPLETED_ORDER);
          const packageName = getPackageName(
            null,
            packageCode,
            language,
            t,
            true,
          );
          dispatch(
            actions.banner.updateDisplayBanner(
              BANNER_TYPE.JOIN_ZALO_GROUP,
              true,
              { packageName: packageName.toUpperCase() },
            ),
          );
          break;
        }
        case ORDER_STATE.FAILED:
          // TODO: handle Failed Dialog
          break;
        case ORDER_STATE.PROCESSING:
          // TODO: handle processing dialog
          break;
        default:
          break;
      }
    }
  };

  const fetchOrder = async (orderId) => {
    const data = await apis.orders.getOrderById(orderId);

    if (data.status) {
      handleChangeSelectedPackageCode(data.result.package.code);
      dispatch(actions.order.updatePackage(data.result.package));
      // TODO: Add voucher

      if (isVietNam) {
        dispatch(actions.order.updatePaymentMethod(data.result.paymentMethod));
      }
    }
  };

  const handleChangeQRCode = async (currBankNumber) => {
    setLoading(true);
    const otherBank = banks.find((bank) => bank.bankNumber !== currBankNumber);

    await handleCreateOrder({
      confirmDowngrade: true,
      confirmCancelSubscription: true,
      bankCode: otherBank?.bankNumber,
      confirmUpgradeOrBuyMore: true,
    });
    setLoading(false);
  };

  const checkPendingOrder = (latestOrderData) => {
    if (latestOrderData?.status === ORDER_STATUS.PENDING) {
      if (latestOrderData?.isConfirmedByCustomer && showPendingOrder) {
        handleOpenDialog(DIALOG_NAMES.NOT_CONFIRMED_ORDER);
        return;
      }
      if (showPendingOrder) handleOpenDialog(DIALOG_NAMES.UNPAID_ORDER);
    } else if (latestOrderData?.status) setShowPendingOrder(false);
  };

  useEffect(() => {
    if (!isVietNam && location?.search?.includes(PREFIX_AUTO_RENEW)) {
      history.push(ROUTES.TTS);
    }
  }, []);

  useEffect(() => {
    if (isDubbingRoute) checkPendingOrder(latestDubbingOrder);
    else checkPendingOrder(latestOrder);
  }, [latestOrder, latestDubbingOrder, showPendingOrder]);

  useEffect(() => {
    if (!isVietNam && location?.search?.includes(PREFIX_AUTO_RENEW)) {
      history.push(ROUTES.TTS);
      return;
    }

    const {
      token,
      orderId,
      package: packageCode,
    } = camelcaseKeys(queryString.parse(location.search) || {});
    if (FREE_PACKAGE_CODE.includes(packageCode)) history.push(ROUTES.PAYMENT);

    if (token) fetchOrderState(token, packageCode);

    if (orderId) fetchOrder(orderId);
  }, [location]);

  useEffect(() => {
    if (backToTTS) {
      dispatch(actions.user.resetBackToTTS());
      history.push(ROUTES.TTS);
    }

    // Redirect to dubbing page after payment success not trial package
    if (
      backToDubbing &&
      user?.dubbing?.packageCode &&
      user?.dubbing?.packageCode !== PACKAGE_LEVEL.DUBBING_TRIAL
    ) {
      dispatch(actions.user.resetBackToDubbing());
      history.push(ROUTES.HOME_DUBBING);
    }
  }, [backToTTS, backToDubbing, user?.dubbing]);

  useEffect(() => {
    if (closeQRPayment) {
      dispatch(actions.user.resetCloseQRPayment());
      handleCloseBankTransferDialog();
      handleCloseQrDialog();
    }
  }, [closeQRPayment]);

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    if (Object.keys(packageState).length === 0 && totalPrice) return;
    dataSenses.sendViewPaymentPageEvent({
      userId: user.id,
      category: packageState.type,
      name: packageState.code,
      packagePrice: packageState.price,
      price: totalPrice,
      voucherCode: voucher?.code,
    });
  }, [location.search, totalPrice]);

  return (
    <>
      <Backdrop sx={{ color: COLOR.white, zIndex: '9999999' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledPayment
        useNewLegal={useNewLegal}
        openSidebar={openSidebar}
        isShowAutoRenewalPackage={isShowAutoRenewalPackage}
      >
        <div className="header-payment">
          <Button
            variant="contained"
            startIcon={<KeyboardArrowLeft />}
            className="back-to-package icon website-button"
            onClick={handleRedirectToPackage}
          >
            {t('backToPackage')}
          </Button>
          <IconButton
            variant="contained"
            className="back-to-package icon medium-website-button"
            onClick={handleRedirectToPackage}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <KeyboardArrowLeft className="back-to-package-mobile mobile-button" />
          <Typography className="title">{t('payment')}</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} mlg={openSidebar ? 12 : 6} cmlg={6}>
            <Card className="card">
              <PackageInfo
                selectedPackageCode={selectedPackageCode}
                onChangeSelectedPackageCode={handleChangeSelectedPackageCode}
                isShowAutoRenewalPackage={isShowAutoRenewalPackage}
                setIsShowAutoRenewalPackage={setIsShowAutoRenewalPackage}
              />
              <PaymentMethods isAutoRenewalPackage={isShowAutoRenewalPackage} />
              <div className="show-mobile">
                <OrderInfo
                  totalPrice={totalPrice}
                  isPhoneNumberValid={isPhoneNumberValid}
                  onChangePhoneNumberValid={handleChangeIsPhoneNumberValid}
                  isShowAutoRenewalPackage={isShowAutoRenewalPackage}
                />
              </div>
              {isShowAutoRenewalPackage && (
                <div className="show-mobile">
                  <SubscriptionInfo
                    buyPackage={packageState}
                    isAutoRenewalPackage={isShowAutoRenewalPackage}
                  />
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className="card show-desktop">
              <OrderInfo
                totalPrice={totalPrice}
                isPhoneNumberValid={isPhoneNumberValid}
                onChangePhoneNumberValid={handleChangeIsPhoneNumberValid}
                isAutoRenewalPackage={isShowAutoRenewalPackage}
              />
            </Card>
            {isShowAutoRenewalPackage && (
              <Card className="card show-desktop subscription-wrapper">
                <SubscriptionInfo
                  buyPackage={packageState}
                  isAutoRenewalPackage={isShowAutoRenewalPackage}
                />
              </Card>
            )}
          </Grid>
        </Grid>
      </StyledPayment>
      <PaymentFooter
        checkedAgreeTermAutoRenew={checkedAgreeTermAutoRenew}
        setCheckedAgreeTermAutoRenew={setCheckedAgreeTermAutoRenew}
        totalPrice={totalPrice}
        onCreateOrder={handleCreateOrder}
        errorNotCheckAgreeTermAutoRenew={errorNotCheckAgreeTermAutoRenew}
        setErrorNotCheckAgreeTermAutoRenew={setErrorNotCheckAgreeTermAutoRenew}
      />

      {bankTransferDialog && (
        <BankTransferDialog
          open
          selectedBank={bankTransferDialog?.selectedBank}
          totalPrice={getOrderPrice(
            isDubbingRoute ? latestDubbingOrder : latestOrder,
          )}
          onComeBackToPackage={handleRedirectToPackage}
          onRedirectToPaymentHistory={handleRedirectToPaymentHistory}
          onChangeLoading={handleChangeLoading}
          onCloseBankTransferDialog={handleCloseBankTransferDialog}
          isDubbingRoute={isDubbingRoute}
          onChangeQR={handleChangeQRCode}
        />
      )}
      {qrPaymentDialog && (
        <QRPaymentDialog
          open
          paymentInfo={qrPaymentDialog}
          totalPrice={totalPrice}
          onChangeLoading={handleChangeLoading}
          onComeBackToPackage={handleRedirectToPackage}
          onCloseQrDialog={handleCloseQrDialog}
          isDubbingRoute={isDubbingRoute}
        />
      )}
      <PaymentNotiDialog
        DIALOG_NAMES={DIALOG_NAMES}
        openDialog={openDialog}
        onCloseDialog={handleCloseDialog}
        onChangeLoading={handleChangeLoading}
        onComeBackToPackage={handleRedirectToPackage}
        onConfirmDowngradePackage={handleConfirmDowngradePackage}
        onConfirmDowngradePackageAndCancelSubscription={
          handleConfirmDowngradePackageAndCancelSubscription
        }
        onConfirmUpgradeOrBuyMore={handleConfirmUpgradeOrBuyMore}
        onConfirmCancelSubscription={handleConfirmCancelSubscription}
        onContinuePaying={handleContinuePaying}
        onRedirectToTTS={handleRedirectToTTS}
        useNewWarningBuyPackage={useNewWarningBuyPackage}
        isDubbingRoute={isDubbingRoute}
        productInfo={productInfo}
      />
    </>
  );
};

export default Payment;
