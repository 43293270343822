export const REQUEST_TYPE = {
  API: 'API',
  API_CACHING: 'API_CACHING',
  STUDIO: 'STUDIO',
  DUBBING: 'DUBBING',
};

export const WARNING_REQUEST_TYPE = {
  CHARACTER_EXCEED: 'CHARACTER_EXCEED',
  BLACK_WORD: 'BLACK_WORD',
};

export const FIELDS_FILTER = {
  title: 'title',
  characters: 'characters',
  time: 'createdAt',
  status: 'status',
  voice: 'voice',
  audioType: 'audioType',
};

export const MAX_REQUEST_DELETE = 10;
export const MAX_AUDIO_DOWNLOAD = 10;

export const MAX_LENGTH_TITLE = 50;

export const TIME_CONVERT_THOUSANDS_CHARACTERS = 10 * 1000; // 10s

export const EDITOR_HEIGHT_DIFFERENCE = {
  0: '490px',
  1: '410px',
  2: '490px',
  3: '550px',
  4: '610px',
  5: '710px',
  max: '230px',
};

export const MAX_BODY_CONTENT_HEIGHT = 400;
export const MIN_OTHER_ELEMENT_HEIGHT = 135;
