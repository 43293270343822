/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, CircularProgress } from '@mui/material';
import {
  PlayArrowRounded,
  ReportProblemRounded,
  RestartAltRounded,
} from '@mui/icons-material';
import Tooltip from '@src/components/Tooltip';
import Popconfirm from '@src/components/Popconfirm';
import { isSameToday } from '@src/utils/time';
import { TTS_GUIDE } from '@src/constants/tourGuide';
import { SILENCE_AUDIO_URL } from '@src/constants/tts';
import actions from '@src/redux/actions';
import { BANNER_TYPE } from '@src/constants/banner';
import { POPUP_TIME_OUT } from '@src/constants';
import debounce from '@src/utils/debounce';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import TryListenNote from './TryListenNote';
import { StyledConvertAction, StyledActionReset } from './index.style';

const ConvertAction = ({
  synthesisRequest,
  synthesisSentence,
  openSentences,
  onTryListeningSentence,
  audioRef,
  onResetContentEditor,
  inputLength,
}) => {
  const { isAudioLoading } = useSelector((state) => state.audioPlayer);
  const { usingPackage } = useSelector((state) => state.user);
  const user = useSelector((state) => state.auth.user);
  const { previewParagraphs, advanceFeature } = useSelector(
    (state) => state.synthesisRequest,
  );
  const { voice } = useSelector((state) => state.synthesisRequest);
  const { getFeatureValue } = useFeatureFlags();

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  let useNewPreviewAudio = getFeatureValue(FEATURE_KEYS.TRY_LISTEN_TTS);
  useNewPreviewAudio = useNewPreviewAudio && useNewTtsUI;

  let enableRefreshButton = getFeatureValue(FEATURE_KEYS.REFRESH_BUTTON);
  enableRefreshButton = enableRefreshButton && useNewTtsUI;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorResetEl, setAnchorResetEl] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTryListening = () => {
    if (isSameToday(user?.createdAt))
      setTimeout(
        () =>
          dispatch(
            actions.banner.updateDisplayBanner(
              BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL,
              true,
            ),
          ),
        POPUP_TIME_OUT,
      );

    let sentence;

    audioRef.current.src = SILENCE_AUDIO_URL;
    audioRef.current.load();
    audioRef.current.play();

    if (advanceFeature) {
      sentence = {
        voice,
        speed: synthesisRequest.speed,
        previewParagraphs,
      };
    } else {
      sentence = {
        text: synthesisSentence,
        voice,
        speed: synthesisRequest.speed,
      };
    }

    onTryListeningSentence(sentence);
  };

  const canPreview = advanceFeature
    ? previewParagraphs?.length && usingPackage.id && !isAudioLoading
    : synthesisSentence && usingPackage.id && !isAudioLoading;

  const handleOpenResetPopper = (e) => {
    // Delay after event click outside in component
    debounce(setAnchorResetEl, 300)(e.currentTarget);
  };

  const handleCloseResetPopper = () => setAnchorResetEl(null);

  const handleResetContentEditor = () => {
    handleCloseResetPopper();
    onResetContentEditor();
  };

  const renderActionResetEditor = () => (
    <StyledActionReset>
      <Button
        className="button outlined-button"
        variant="outlined"
        onClick={handleCloseResetPopper}
      >
        {t('cancel')}
      </Button>
      <Button
        className="button"
        variant="contained"
        onClick={handleResetContentEditor}
      >
        {t('agree')}
      </Button>
    </StyledActionReset>
  );

  return (
    <StyledConvertAction
      openSentences={
        openSentences && !useNewPreviewAudio && !enableRefreshButton
      }
    >
      {useNewPreviewAudio ? (
        <div className="centered-item">
          {!openSentences && <TryListenNote />}
        </div>
      ) : (
        <Tooltip
          arrow
          title={t(canPreview ? 'previewNote' : 'previewConditionNote')}
          placement="right"
        >
          <div className="listening-button-wrapper">
            {!openSentences && (
              <Button
                id={TTS_GUIDE.TRY_LISTENING}
                color="secondary"
                variant="outlined"
                className={classNames('listening-button', {
                  hide: openSentences,
                })}
                onClick={handleTryListening}
                onMouseDown={(e) => e.preventDefault()}
                disabled={!canPreview}
              >
                {isAudioLoading ? (
                  <CircularProgress
                    size={15}
                    thickness={8}
                    className="loading"
                  />
                ) : (
                  <PlayArrowRounded />
                )}
                <span>{t('preview')}</span>
              </Button>
            )}
          </div>
        </Tooltip>
      )}
      {enableRefreshButton && (
        <div
          className={useNewPreviewAudio ? 'right-item-refresh' : 'right-item'}
        >
          <Tooltip
            arrow
            title={t('refresh')}
            placement="top"
            open={openTooltip && !anchorResetEl}
          >
            <div>
              <Button
                className="custom-button"
                onClick={handleOpenResetPopper}
                color="iconPrimary"
                disabled={!inputLength}
                onMouseOver={() => setOpenTooltip(true)}
                onMouseLeave={() => setOpenTooltip(false)}
              >
                <RestartAltRounded sx={{ fontSize: 20 }} />
              </Button>
            </div>
          </Tooltip>
          <Popconfirm
            anchorRef={anchorResetEl}
            useArrow
            onClose={handleCloseResetPopper}
            icon={
              <ReportProblemRounded sx={{ fontSize: 20 }} color="warning" />
            }
            width="200px"
            content={t('confirmDeleteAllContentTTS')}
            actionComponent={renderActionResetEditor()}
          />
        </div>
      )}
    </StyledConvertAction>
  );
};

export default ConvertAction;
