import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

const StyledSelectionButton = styled('div')`
  width: ${(props) => props.width || 'auto'};

  .custom-button {
    border-radius: 12px;
    padding: 4px 6px;
  }

  .toolbar-text {
    text-transform: lowercase;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 20px;
  cursor: pointer;
  color: ${(props) => props.active && COLOR.darkBlue};
  background-color: ${(props) => props.active && COLOR.primary40};

  &:hover {
    color: ${(props) => (props.active ? COLOR.darkBlue : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary40 : TRANSPARENT_COLOR.dark};
  }

  .text {
    font-weight: ${(props) => (props.active ? '500' : '400')};
  }
`;

export { StyledSelectionButton, StyledMenuItem };
