import React, { forwardRef, useEffect } from 'react';
import { Timeline } from '@xzdarcy/react-timeline-editor';
import { Box, Typography } from '@mui/material';
import { COLOR } from '@src/styles/color';
import Scale from '../Scale';
import Subtitle from '../Action';
import { StyledTimeLine } from './index.style';

const MOCK_EFFECT = {
  subtitle: {
    id: 'subtitle',
    name: 'Subtitle',
    avatarIcon: '',
    avatarWord: 'T',
  },
};

const TimeLine = forwardRef(
  (
    {
      data,
      zoomLevel,
      handleChangeData,
      handleSelectSentence,
      handleClickTimeArea,
      handleChangeTimePositionWhenClick,
    },
    ref,
  ) => {
    const renderAction = (action) => {
      switch (action.effectId) {
        case 'subtitle':
          return <Subtitle action={action} />;

        default:
          return null;
      }
    };

    const onClickActionOnly = (e, { action }) => {
      e.stopPropagation();
      handleSelectSentence(action);
    };

    const EffectRowName = ({ effect }) => (
      <Box className="effect-row-name">
        {effect.avatarIcon ? (
          <Box className="effect-row-name-avatar-image">
            {effect.avatarIcon}
          </Box>
        ) : (
          <Typography className="effect-row-name-avatar-text">
            {effect.avatarWord}
          </Typography>
        )}
      </Box>
    );

    useEffect(() => {
      if (!ref.current) return;

      ref.current.listener.on('setTimeByTick', ({ time }) => {
        const autoScrollFrom = 600;
        const left = time * (100 / zoomLevel) + 10 - autoScrollFrom;
        ref.current.setScrollLeft(left);
      });
    }, [ref, zoomLevel]);

    return (
      <StyledTimeLine>
        <Box>
          <Box height="42px" />
          {Object.keys(MOCK_EFFECT).map((effectKey) => (
            <EffectRowName key={effectKey} effect={MOCK_EFFECT[effectKey]} />
          ))}
        </Box>

        <Timeline
          style={{
            width: '100%',
            backgroundColor: COLOR.white,
          }}
          ref={ref}
          rowHeight={32}
          onChange={handleChangeData}
          editorData={data}
          effects={MOCK_EFFECT}
          hideCursor={false}
          autoScroll
          scale={zoomLevel || 1}
          scaleSplitCount={5}
          startLeft={10}
          scaleWidth={100}
          dragLine
          disableDrag
          onClickActionOnly={onClickActionOnly}
          getScaleRender={(scale) => <Scale scale={scale} />}
          getActionRender={(action) => renderAction(action)}
          onClickTimeArea={handleClickTimeArea}
          onCursorDragEnd={(time) => handleChangeTimePositionWhenClick(time)}
        />
      </StyledTimeLine>
    );
  },
);

export default TimeLine;
