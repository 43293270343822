import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import Tooltip from '@src/components/Tooltip';
import FrameCloseIcon from '@src/assets/icons/frame-close.svg';
import FrameOpenIcon from '@src/assets/icons/frame-open.svg';

const CollapsedButton = ({ isCollapsed, handleCollapse }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={isCollapsed ? t('collapse') : t('expand')}
      arrow
      placement="top"
    >
      <IconButton onClick={handleCollapse}>
        <img
          src={isCollapsed ? FrameCloseIcon : FrameOpenIcon}
          alt="frame-open"
        />
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(CollapsedButton);
