import { PAGINATION_LIMIT } from '@src/constants';
import { actionTypes } from './actions';

export const initialState = {
  rowPerPagination: PAGINATION_LIMIT,
};

export default function paginationLimitReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SELECT_PAGINATION_LIMIT: {
      const { rowPerPagination } = action;
      return { ...state, rowPerPagination };
    }

    case actionTypes.RESET_PAGINATION_LIMIT:
      return initialState;

    default:
      return state;
  }
}
