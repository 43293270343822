import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { StyledActiveVoiceDialog } from './index.style';

const ActiveVoiceDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <StyledActiveVoiceDialog open={open} onClose={onClose}>
      <Box className="box-dialog">
        <Box className="box-title">
          <Typography className="title">{t('notice')}</Typography>
          <Close className="close-icon" onClick={onClose} />
        </Box>
        <Box className="box-content">
          <Typography className="content">
            {t('cannotActiveVoiceMoreThanLimit')}
          </Typography>
        </Box>
        <Box className="box-action">
          <Button className="action-btn" fullWidth onClick={onClose}>
            {t('close')}
          </Button>
        </Box>
      </Box>
    </StyledActiveVoiceDialog>
  );
};

export default ActiveVoiceDialog;
