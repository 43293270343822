import { styled } from '@mui/material/styles';
import { Popover, Slider } from '@mui/material';
import { COLOR } from '@src/styles/color';

const StyledVolume = styled('div')`
  .custom-button {
    ${(props) =>
      props.disabled && {
        cursor: 'default',
        color: COLOR.light,
        background: 'inherit',
        '&:hover': {
          background: 'inherit',
        },
      }}
  }
`;

const StyledPopover = styled(Popover)`
  .volume-wrapper {
    width: 210px;
    margin: 30px 20px 10px 20px;
  }
`;

const StyledSlider = styled(Slider)`
  color: ${COLOR.primary};
  height: 6px;

  & .MuiSlider-valueLabel {
    font-weight: bold;
    font-size: 14px;
    top: -8px;
    background-color: unset;
    color: ${COLOR.text};

    &:before {
      display: none;
    }
  }

  & .MuiSlider-thumb {
    height: 14px;
    width: 14px;
    background-color: ${COLOR.white};
    border: 1px solid currentColor;
  }

  & .MuiSlider-rail {
    color: ${COLOR.secondary[24]};
  }
`;

export { StyledVolume, StyledPopover, StyledSlider };
