import { styled } from '@mui/material/styles';
import { MOBILE_BREAKPOINT } from '@src/styles/config';

const StyleDictionary = styled('div')`
  .dialog-action {
    display: flex;
    justify-content: space-between;
    margin: 0 10px 20px 10px;
  }

  .button {
    margin-right: 10px;
    text-transform: initial;
  }

  .add {
    flex: 1;
  }

  .confirm {
    display: flex;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .dialog-action {
      margin: 0 0 20px 10px;
    }

    .button {
      padding: 5px 10px;
      margin-right: 7px;
    }
  }
`;

const StyledWordTable = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .actions {
    display: flex;
  }
`;

export { StyleDictionary, StyledWordTable };
