import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { AddCircleRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import FirstVoiceBoxImg from '@src/assets/images/create-first-voice-box.svg';
import { StyledEmptyRequest } from './index.style';

const EmptyRequest = () => {
  const { t } = useTranslation();

  return (
    <StyledEmptyRequest>
      <Box className="box">
        <img src={FirstVoiceBoxImg} alt="first-voice-box" className="image" />
        <Box>
          <Typography className="text-empty-list">
            {t('noConversionRequests')}
          </Typography>
          <Typography className="text-empty-list">
            {t('clickCreateNew')}
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          className="button"
          onClick={() => {}}
        >
          <AddCircleRounded className="icon" />
          <Typography className="text">{t('createNew')}</Typography>
        </Button>
      </Box>
    </StyledEmptyRequest>
  );
};

export default EmptyRequest;
