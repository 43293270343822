import React from 'react';
import { useTranslation } from 'react-i18next';
import { AUDIO_EFFECTS_URL } from '@src/configs';

import { Typography } from '@mui/material';
import { TuneRounded } from '@mui/icons-material';
import { StyledTextIcon } from './index.style';

const EffectGuideButton = ({ hasDisable, onClick, audioLink, langEng }) => {
  const { t } = useTranslation();

  const handleOpenAudioEffects = async () => {
    onClick();
    window.open(`${AUDIO_EFFECTS_URL}/?audio=${audioLink}`, '_blank');
  };

  return (
    <StyledTextIcon
      disableRipple
      onClick={handleOpenAudioEffects}
      disabled={hasDisable}
      langEng={langEng}
    >
      <TuneRounded className="action-icon" />
      <Typography className="text-action">{t('effectGuide')}</Typography>
    </StyledTextIcon>
  );
};

export default EffectGuideButton;
