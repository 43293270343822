/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import SrtFailIcon from '@src/assets/icons/srt-fail.svg';
import {
  DUBBING_LENGTH_LIMIT,
  ERROR_SEPARATOR,
  MAX_SENTENCE_LENGTH,
} from '@src/constants/dubbing';
import { MAX_CHARACTER_LENGTH } from '@src/constants/tts';

import { StyledFailedDropzoneContent } from './index.style';
import SampleFileReference from './SampleFileReference';

const FailedDropzoneContent = ({
  error = {},
  fileFormat,
  dubbingFileFormat,
}) => {
  const { t } = useTranslation();

  const ErrorMessage = ({ errorMessage }) => (
    <Box
      className="error-detail"
      dangerouslySetInnerHTML={{
        __html: t(errorMessage),
      }}
    />
  );

  return (
    <StyledFailedDropzoneContent>
      <img src={SrtFailIcon} alt="srt-icon" />
      <div className="upload-instruction">
        <Typography
          align="center"
          variant="h5"
          className="upload-file-title text-blue"
        >
          {t('dropFileSuccessInstructSubtitle', { fileFormat })}
        </Typography>
        <SampleFileReference isMultipleInputDubbing />
      </div>
      <div className="error">
        <div
          className="error-text"
          dangerouslySetInnerHTML={{
            __html: t(error.message, {
              maxAudioLength: DUBBING_LENGTH_LIMIT,
              fileType: dubbingFileFormat?.map((type) => `.${type}`)?.join(','),
              maxSentenceLength: MAX_SENTENCE_LENGTH,
              maxCharacterLength: MAX_CHARACTER_LENGTH,
            }),
          }}
        />
        {error.detail && (
          <Box
            className="error-detail-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            {error.detail.split(ERROR_SEPARATOR).map((message) => (
              <ErrorMessage errorMessage={message} />
            ))}
          </Box>
        )}
      </div>
    </StyledFailedDropzoneContent>
  );
};
export default FailedDropzoneContent;
