import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Edit } from '@mui/icons-material';
import { Box, TextField, Typography } from '@mui/material';
import { formatPhoneNumber } from '@src/utils/number';
import actions from '@src/redux/actions';

import { convertPhoneNumber } from '@src/services/phoneNumber';
import { StyledUserOrderInfo } from './index.style';

const UserOrderInfo = ({ isPhoneNumberValid, onChangePhoneNumberValid }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { latestOrder } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  const { phoneNumber: phoneNumberState = '' } = useSelector(
    (state) => state.order.userOrder,
  );

  const [isEditPhoneNumber, setIsEditPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberState);

  const handleClickPhoneNumber = (e) => e.stopPropagation();

  const handleChangePhoneNumber = (e) => {
    if (!isPhoneNumberValid) onChangePhoneNumberValid(true);
    if (!isEditPhoneNumber) setIsEditPhoneNumber(true);
    setPhoneNumber(e.target.value);
  };

  const verifyPhoneNumber = () => convertPhoneNumber(phoneNumber);

  const handleBlurPhoneNumber = () => {
    if (verifyPhoneNumber()) {
      onChangePhoneNumberValid(true);
      dispatch(actions.order.updateUserOrder({ phoneNumber }));
    } else {
      onChangePhoneNumberValid(false);
      dispatch(actions.order.updateUserOrder({ phoneNumber: '' }));
    }
    setIsEditPhoneNumber(false);
  };

  useEffect(() => {
    const data = {};
    if (user?.phoneNumber) data.phoneNumber = user?.phoneNumber;
    if (user?.firstName || user?.lastName)
      data.user = `${user.lastName} ${user.firstName}`;
    if (user?.email) data.email = user.email;
    dispatch(actions.order.updateUserOrder(data));
  }, []);

  useEffect(() => {
    if (latestOrder?.user?.phoneNumber || user?.phoneNumber) {
      dispatch(
        actions.order.updateUserOrder({
          phoneNumber: latestOrder?.user?.phoneNumber || user?.phoneNumber,
        }),
      );
    }
  }, [latestOrder]);

  const displayPhoneNumber = () => (
    <Box className="user-info-phone-number">
      <span>{formatPhoneNumber(phoneNumberState)}</span>
      <Edit className="edit-icon" onClick={() => setIsEditPhoneNumber(true)} />
    </Box>
  );

  const renderPhoneNumberInput = () => (
    <TextField
      id="input-phone-number-payment"
      size="small"
      className="text-field-request filter-item"
      variant="outlined"
      value={phoneNumber}
      onChange={handleChangePhoneNumber}
      onClick={handleClickPhoneNumber}
      onBlur={handleBlurPhoneNumber}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleBlurPhoneNumber();
        }
      }}
      autoFocus={isEditPhoneNumber}
      placeholder={t('phoneNumberPlaceholder')}
      InputProps={{
        style: {
          padding: '4px 8px',
          height: '25.6px',
        },
      }}
    />
  );

  return (
    <StyledUserOrderInfo>
      <Typography className="info-title">{t('buyerInformation')}</Typography>
      <Box className="user-info-content">
        <Typography className="user-info-item">
          <span className="user-info-item-label">{t('account')}:</span>
          <span>{`${user.lastName} ${user.firstName}`}</span>
        </Typography>
        <Typography className="user-info-item">
          <span className="user-info-item-label" style={{ paddingTop: '3px' }}>
            {t('phoneNumber')}:
          </span>
          <Box className="phone-number-card">
            {phoneNumberState.length > 0 &&
              !isEditPhoneNumber &&
              displayPhoneNumber()}
            {(isEditPhoneNumber ||
              !isPhoneNumberValid ||
              (isPhoneNumberValid && !phoneNumberState?.length)) &&
              renderPhoneNumberInput()}
            {!isPhoneNumberValid && (
              <Typography className="phone-number-warning">
                {!phoneNumber
                  ? t('phoneNumberIsRequired')
                  : t('phoneNumberIsInvalid')}
              </Typography>
            )}
          </Box>
        </Typography>
      </Box>
    </StyledUserOrderInfo>
  );
};

export default UserOrderInfo;
