import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { enGB, vi } from 'date-fns/locale';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  CalendarToday,
  FilterList,
  RestartAltOutlined,
} from '@mui/icons-material';
import { REQUEST_STATUS } from '@src/constants/voice';
import CustomDatePickerRange from '@src/components/CustomDatePickerRange';
import { FIELDS_FILTER } from '@src/constants/request';
import { COLOR } from '@src/styles/color';
import debounce from '@src/utils/debounce';
import { LANGUAGE } from '@src/constants';

import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import actions from '@src/redux/actions';
import { StyledListProjectHeader } from './index.style';

const initialFilter = {
  status: '',
  createdAt: [null, null],
};

const ListProjectHeader = ({
  page,
  showModalCreateProject,
  selectedFields,
  setSelectedFields,
  fetchProjects,
  sort,
  setIsFilter,
  searchField,
  setSearch,
  filterField,
  setFilter,
  setPage,
  headerRef,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const isMobileScreen = window.innerWidth < 480;

  const { user } = useSelector((state) => state.auth);

  const [anchorFieldFilter, setAnchorFieldFilter] = useState(null);
  const [anchorRequestStatusFilter, setAnchorRequestStatusFilter] =
    useState(null);
  const [anchorDateFilter, setAnchorDateFilter] = useState(null);

  const isFirstRender = useRef(true);

  const requestStatus = [...Object.keys(REQUEST_STATUS), 'DRAFT'];

  const handleCloseFieldsFilter = () => setAnchorFieldFilter(null);

  const handleSelectFields = (field) => {
    if (selectedFields.includes(field)) {
      setSelectedFields(selectedFields.filter((f) => f !== field));
    } else setSelectedFields([...selectedFields, field]);
  };

  const handleChangeFilter = (name, value) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };
  const handleChangeDatePickerRange = (value) =>
    handleChangeFilter('createdAt', value);

  // Reset filter to initial state
  const handleResetFilter = () => {
    if (searchField !== '') setSearch('');

    setFilter(initialFilter);
    setPage(1);
  };

  useEffect(() => {
    debounce(() => {
      fetchProjects({
        hasLoading: true,
        newPage: 1,
      });
    }, 500)(searchField);
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (searchField?.length > 0) setIsFilter(true);
  }, [searchField]);

  useEffect(() => {
    if (
      isFirstRender.current &&
      filterField.createdAt[0] === null &&
      filterField.createdAt[1] === null &&
      filterField.status === ''
    )
      return;

    debounce(() => {
      fetchProjects({
        hasLoading: true,
        newPage: 1,
      });
    }, 200)(filterField);

    if (
      filterField.createdAt[0] ||
      filterField.createdAt[1] ||
      filterField.status
    )
      setIsFilter(true);
  }, [filterField]);

  useEffect(() => {
    if (isFirstRender.current) return;

    fetchProjects({
      hasLoading: true,
      newPage: page,
    });
  }, [sort, page]);

  const renderMobileActionTable = () => (
    <div className="action-table">
      <div className="request-filter">
        <Box className="filter-item" onClick={(e) => e.stopPropagation()}>
          <Box
            className="filter-status"
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorRequestStatusFilter(e.currentTarget);
            }}
          >
            <FilterList />
          </Box>
          <Box
            className="filter-status"
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorDateFilter(e.currentTarget);
            }}
          >
            <CalendarToday />
          </Box>
          <Box>
            <IconButton
              className={`refresh-icon ${
                _.isEqual(filterField, initialFilter) &&
                searchField === '' &&
                'disable-color'
              }`}
              onClick={handleResetFilter}
              disabled={
                _.isEqual(filterField, initialFilter) && searchField === ''
              }
            >
              <RestartAltOutlined />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorRequestStatusFilter}
            open={Boolean(anchorRequestStatusFilter)}
            onClose={() => setAnchorRequestStatusFilter(null)}
            className="fields-filter"
          >
            {requestStatus.map((item) => (
              <MenuItem
                key={item}
                value={item}
                selected={filterField.status === item}
                onClick={() => {
                  handleChangeFilter('status', item);
                  setAnchorRequestStatusFilter(null);
                }}
              >
                {t(item)}
              </MenuItem>
            ))}
          </Menu>
          <Menu
            anchorEl={anchorDateFilter}
            open={Boolean(anchorDateFilter)}
            onClose={() => setAnchorDateFilter(null)}
          >
            <div className="date-field">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={{ start: t('start'), end: t('end') }}
                locale={language === LANGUAGE.VN ? vi : enGB}
              >
                <StaticDatePicker
                  open
                  onClose={() => setAnchorDateFilter(null)}
                  value={filterField.createdAt[0]}
                  onChange={(newValue) => {
                    handleChangeDatePickerRange([newValue, newValue]);
                  }}
                  pickerProps={{ readOnly: true }}
                  displayStaticWrapperAs="desktop"
                />
              </LocalizationProvider>
            </div>
          </Menu>
        </Box>
      </div>
    </div>
  );

  const renderDesktopActionTable = () => (
    <Box className="request-filter">
      <div
        className="filter-item"
        role="button"
        tabIndex="0"
        onClick={(e) => e.stopPropagation()}
      >
        <TextField
          size="small"
          className="text-field-request filter-status"
          variant="outlined"
          value={filterField.status}
          select
          label={t('status')}
          onChange={(e) => handleChangeFilter('status', e.target.value)}
        >
          {requestStatus.map((item) => (
            <MenuItem key={item} value={item}>
              {t(item)}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div
        className=" filter-item"
        role="button"
        tabIndex="0"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="date-field">
          <CustomDatePickerRange
            showNewUI
            value={filterField.createdAt}
            onChange={handleChangeDatePickerRange}
          />
        </div>
        <Tooltip arrow title={t('reset')} placement="bottom">
          <div>
            <IconButton
              className={`refresh-icon ${
                _.isEqual(filterField, initialFilter) &&
                searchField === '' &&
                'disable-color'
              }`}
              onClick={handleResetFilter}
              disabled={
                _.isEqual(filterField, initialFilter) && searchField === ''
              }
            >
              <RestartAltOutlined />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorFieldFilter}
        open={Boolean(anchorFieldFilter)}
        onClose={handleCloseFieldsFilter}
        className="fields-filter"
      >
        {Object.keys(FIELDS_FILTER).map((key) => (
          <MenuItem
            key={key}
            value={FIELDS_FILTER[key]}
            onClick={() => handleSelectFields(FIELDS_FILTER[key])}
          >
            <Checkbox
              checked={selectedFields.includes(FIELDS_FILTER[key])}
              onClick={handleSelectFields}
              style={{ color: COLOR.darkBlue }}
            />
            <div>{t(key)}</div>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );

  return (
    <StyledListProjectHeader ref={headerRef}>
      <Box className="title-wrapper">
        <Typography className="list-project-title">
          {t('listProject')}
        </Typography>
        <Button
          variant="primary"
          className="button-create-project"
          onClick={() => {
            const isPackageExpired = moment().isAfter(
              user?.dubbing?.packageExpiryDate,
            );

            if (isPackageExpired || user?.dubbing?.remainingSeconds === 0) {
              dispatch(actions.dialog.displayDialog({ limitedFeature: true }));
              return;
            }

            showModalCreateProject();
          }}
        >
          <Add />
        </Button>
      </Box>

      {isMobileScreen ? renderMobileActionTable() : renderDesktopActionTable()}
    </StyledListProjectHeader>
  );
};

export default ListProjectHeader;
