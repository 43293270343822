import React, { useState } from 'react';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Step from './Step';
import Next from './NextButton';
import Skip from './SkipButton';
import Back from './BackButton';
import Tooltip from './Tooltip';
import DotsStep from './DotsStep';

const MOBILE_BREAKPOINT = 900;

const NewTourGuide = ({
  stepsGuide,
  run,
  onChangeRunTourGuide,
  stepDisplay = true,
}) => {
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(0);
  const [isSkip, setIsSkip] = useState(false);

  // Mảng lưu trữ số thứ tự bước đầu tiên của các cụm bước và bước cuối cùng
  const indexArray = stepsGuide.reduce(
    (acc, curr) => {
      const lengthSteps = curr.steps.length;
      return [...acc, lengthSteps + acc[acc.length - 1]];
    },
    [0],
  );

  // Số thứ tự của bước cuối cùng
  // Mảng indexArray chỉ lưu số thứ thự các bước
  const indexLastStep = indexArray.pop() - 1;
  const lengthIndexArray = indexArray.length;

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
    if (isSkip) setIsSkip(false);
    if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type) &&
      !isSkip
    ) {
      const activeStep = action === ACTIONS.PREV ? index - 1 : index + 1;
      if (action === ACTIONS.NEXT && index === indexLastStep) {
        onChangeRunTourGuide(false);
        return;
      }
      setStepIndex(activeStep);
    }
    if (action === ACTIONS.SKIP) onChangeRunTourGuide(false);
  };

  const handleSkipTour = () => {
    setIsSkip(true);
    if (lengthIndexArray < 2) {
      onChangeRunTourGuide(false);
      return;
    }

    for (let i = 0; i < lengthIndexArray - 1; i += 1) {
      if (stepIndex >= indexArray[i] && stepIndex < indexArray[i + 1]) {
        setStepIndex(indexArray[i + 1]);
        return;
      }
    }
    if (stepIndex >= indexArray[lengthIndexArray - 1]) {
      onChangeRunTourGuide(false);
    }
  };

  const steps = stepsGuide.reduce((acc, curr, indexStep) => {
    const allSteps = curr.steps?.length ?? 0;
    const lastCluster = indexStep === stepsGuide.length - 1;
    const newSteps = curr.steps.map((item, index) => {
      const lastStep = lastCluster && index === allSteps - 1;
      const firstStep = index === 0 && indexStep === 0;
      return {
        content: item.name ? (
          <Typography className="text-content">{t(item.name)}</Typography>
        ) : (
          <div className="text-content">{item.content}</div>
        ),
        title: (
          <Typography className="text-content">{t(curr.title)}</Typography>
        ),
        locale: {
          skip: <Skip handleSkipTour={handleSkipTour} />,
          step: (
            <Step
              steps={allSteps}
              activeStep={index}
              stepDisplay={stepDisplay}
            />
          ),
          dotsStep: (
            // Total steps equal to the last step index plus 1
            <DotsStep totalSteps={indexLastStep + 1} currentStep={stepIndex} />
          ),
          back: !firstStep && <Back steps={allSteps} activeStep={index} />,
          next: (
            <Next
              title={lastStep ? t('completed') : t('next')}
              isDone={lastStep}
            />
          ),
        },
        target: item.target,
        disableBeacon: true,
      };
    });
    const newAcc = [...acc, ...newSteps];
    return newAcc;
  }, []);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      tooltipComponent={Tooltip}
      continuous
      run={window.innerWidth <= MOBILE_BREAKPOINT ? false : run}
      scrollToFirstStep
      disableCloseOnEsc
      disableOverlayClose
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
        spotlight: {
          borderRadius: 12,
        },
      }}
    />
  );
};

export default NewTourGuide;
