import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Typography, Button, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import AffiliateMan from '@src/assets/images/affiliate-man.png';
import AffiliateWoman from '@src/assets/images/affiliate-woman.png';
import Cooperate from '@src/assets/icons/affiliate-cooperate.svg';
import Tree from '@src/assets/icons/affiliate-tree.svg';
import { useTranslation } from 'react-i18next';
import route from '@src/constants/route';
import apis from '@src/apis';
import { AFFILIATE_POLICY_LINK } from '@src/configs';
// import { EVENT_TYPE } from '@src/constants';
import actions from '@src/redux/actions';
import { StyledAffiliate } from './index.style';

const Affiliate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const handleClickAffiliateManager = () =>
    history.push(route.AFFILIATE_DETAIL);

  const handleRegisterAffiliateMarketer = async () => {
    const response = await apis.affiliateMarketer.registerAffiliateMArketer();
    if (!response?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'registerAffiliateMarketerFailed',
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        severity: 'success',
        message: 'registerAffiliateMarketerSuccess',
      }),
    );
    // window.dataLayer.push({
    //   event: EVENT_TYPE.AFFILIATE_REGISTRATION,
    //   time: moment().format('DD/MM/YYYY - HH:mm'),
    //   userId: user?.id,
    // });
    setTimeout(() => {}, 1000);

    history.push(route.AFFILIATE_DETAIL);
  };

  const handleClickAffiliatePolicy = () => {
    window.open(AFFILIATE_POLICY_LINK, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (
      user?.isAffiliateMarketer &&
      history.location?.state?.from !== route.AFFILIATE_DETAIL
    )
      history.push(route.AFFILIATE_DETAIL);
  }, [user]);

  return (
    <StyledAffiliate>
      <div className="top">
        <img src={AffiliateMan} alt="affiliate-man" className="man" />
        <div className="content">
          <Typography className="title" align="center" variant="h4">
            {t('affiliateTitle')}
          </Typography>
          <Typography className="description" align="center" variant="body2">
            {t('affiliateDescription')}
          </Typography>
          {user?.isAffiliateMarketer ? (
            <Button
              align="center"
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClickAffiliateManager}
            >
              {t('affiliateManage')}
            </Button>
          ) : (
            <Button
              align="center"
              color="primary"
              variant="contained"
              size="small"
              onClick={handleRegisterAffiliateMarketer}
            >
              {t('affiliateRegister')}
            </Button>
          )}
        </div>

        <img src={AffiliateWoman} alt="affiliate-woman" className="woman" />
      </div>
      <div className="bottom">
        <Card sx={{ maxWidth: 485 }} className="card">
          <CardActionArea>
            <CardMedia
              component="img"
              height="100"
              image={Cooperate}
              className="icon"
              alt="icon"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" className="card-title">
                {t('revenueSharing')}
              </Typography>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('revenueSharingDescription', {
                    revenuePercent: '20%',
                  }),
                }}
              />
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 485 }} className="card">
          <CardActionArea>
            <CardMedia
              component="img"
              height="100"
              image={Tree}
              className="icon"
              alt="icon"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" className="card-title">
                {t('lifetimeCommission')}
              </Typography>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('lifetimeCommissionDescription', {
                    commissionPercent: '20%',
                  }),
                }}
              />
              <Typography
                variant="body2"
                className="contributor-policy"
                onClick={handleClickAffiliatePolicy}
              >
                {t('contributorPolicy')}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </StyledAffiliate>
  );
};

export default Affiliate;
