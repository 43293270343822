import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '@src/languages';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Checkbox,
} from '@mui/material';
import { checkVietNam } from '@src/utils/checkCountry';
import {
  NEW_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '@src/styles/config';
import { checkAutoRenewalPackage } from '@src/services/package';
import theme from '@src/styles/theme';
import { StyledPaymentFooter } from './index.style';

const PaymentFooter = ({
  totalPrice = 0,
  onCreateOrder,
  checkedAgreeTermAutoRenew,
  setCheckedAgreeTermAutoRenew,
  errorNotCheckAgreeTermAutoRenew,
  setErrorNotCheckAgreeTermAutoRenew,
}) => {
  const { t } = useTranslation();
  const { getFeatureValue } = useFeatureFlags();
  const user = useSelector((state) => state.auth.user);
  const { openSidebar } = useSelector((state) => state.layout);
  const { package: packageState } = useSelector((state) => state.order);
  const { language } = i18n;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // check if the text of the button should be changed
  const isChangeTextButtonPayment = useMediaQuery(
    theme.breakpoints.down('xxl'),
  );

  const useNewTtsUI = getFeatureValue(FEATURE_KEYS.NEW_TTS_UI, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    screenWidth: window.innerWidth,
  });
  const useNewFlowPaymentUI = getFeatureValue(
    FEATURE_KEYS.NEW_FOLOW_PAYMENT_UI,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );
  const useNewLegal = getFeatureValue(FEATURE_KEYS.NEW_LEGAL, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  let useNewSideBar = getFeatureValue(FEATURE_KEYS.NEW_SIDEBAR);
  useNewSideBar = useNewSideBar && useNewTtsUI;
  const isVietNam = checkVietNam();
  const { paymentMethod } = useSelector((state) => state.order);
  const [isLoading, setIsLoading] = useState(false);
  const isAutoRenewalPackage = checkAutoRenewalPackage(packageState);

  const getSidebarWidth = () => {
    let sidebarWidth = 0;
    if (useNewSideBar) {
      sidebarWidth = openSidebar ? NEW_SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED;
    } else {
      sidebarWidth = openSidebar ? SIDEBAR_WIDTH : 0;
    }
    return sidebarWidth;
  };

  useEffect(() => {
    if (Object.keys(paymentMethod).length === 0) {
      setIsLoading(true);
    } else setIsLoading(false);
  }, [paymentMethod]);

  return (
    <StyledPaymentFooter
      useNewLegal={useNewLegal}
      autorenew={isAutoRenewalPackage}
      sidebarwidth={getSidebarWidth()}
      useNewFlowPaymentUI={useNewFlowPaymentUI}
    >
      {isAutoRenewalPackage && !useNewLegal && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography
            className="term"
            dangerouslySetInnerHTML={{ __html: t('autoRenewTerm') }}
          />
          {isVietNam && (
            <Typography className="term cancelNote">
              {t('cancelAnyTime')}
            </Typography>
          )}
        </Box>
      )}
      {isAutoRenewalPackage && useNewLegal && (
        <Box className="auto-renew-term-container">
          <Checkbox
            className="checkbox"
            checked={checkedAgreeTermAutoRenew}
            onChange={() => {
              setErrorNotCheckAgreeTermAutoRenew(checkedAgreeTermAutoRenew);
              setCheckedAgreeTermAutoRenew(!checkedAgreeTermAutoRenew);
            }}
          />
          <Box className="agree-auto-renew-term-text">
            <Typography
              dangerouslySetInnerHTML={{ __html: t('agreeAutoRenewTerm') }}
            />
            {errorNotCheckAgreeTermAutoRenew && (
              <Typography className="not-agree-auto-renew-term-text">
                {t('agreeToTermsNoteNew')}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {!isAutoRenewalPackage && useNewLegal && (
        <Box>
          <Typography dangerouslySetInnerHTML={{ __html: t('paymentTerm') }} />
        </Box>
      )}
      <div className="container">
        <div className="price">
          <div className="price-text">{t('total')}:</div>

          {!isVietNam && <span className="discount-unit-price text-16">$</span>}
          {isVietNam && (
            <span className="discount-unit-price text-16 underline">đ</span>
          )}
          <div className="">{totalPrice?.toLocaleString(language)}</div>
        </div>
        <Button
          className="agreeToPayButton"
          variant="contained"
          disabled={isLoading}
          onClick={onCreateOrder}
        >
          <>
            {(!useNewLegal && isMobile) ||
            (useNewLegal && isChangeTextButtonPayment)
              ? t('payment')
              : t('agreeToPay')}
          </>
        </Button>
      </div>
    </StyledPaymentFooter>
  );
};

export default PaymentFooter;
