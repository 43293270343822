/* eslint-disable no-unused-vars */
import React from 'react';
import { useLocation } from 'react-router-dom';
import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';

import { FEATURE_KEYS } from '@src/configs/featureKeys';

import BuyPackageContainer from '@src/containers/BuyPackage';
import PaymentContainer from '@src/containers/Payment';
import NewPaymentContainer from '@src/containers/NewPayment';
import { useSelector } from 'react-redux';
import useFeatureFlags from '@src/hooks/useFeatureFlags';

const Payment = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const { getFeatureValue } = useFeatureFlags();
  const useNewPayment = getFeatureValue(FEATURE_KEYS.NEW_PAYMENT, {
    userId: user.id,
    email: user.email,
  });

  const { orderId, package: packageCode } = camelcaseKeys(
    queryString.parse(location.search) || {},
  );

  if (orderId || packageCode)
    return useNewPayment ? <NewPaymentContainer /> : <PaymentContainer />;

  return <BuyPackageContainer />;
};

export default Payment;
