import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledSTT = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;

    padding: 62px;
  }

  .description {
    margin-top: 28px;
    text-align: center;
    max-width: 700px;
  }

  .recording {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    align-items: center;

    .recording-title {
      margin-top: 20px;
    }

    .recording-description {
      color: ${COLOR.subText};
    }

    .recording-button-wrapper {
      border-radius: 50%;

      .recording-button {
        background: ${COLOR.primary};
        color: ${COLOR.white};
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin: 30px;

        .record-icon {
          font-size: 50px;
        }
      }
    }

    .isRecording {
      animation: wave 1s infinite;

      @keyframes wave {
        0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(252, 102, 52, 0.7);
          background: ${TRANSPARENT_COLOR.primary};
          border: 1px solid ${COLOR.primary};
          color: ${COLOR.primary};
        }

        70% {
          transform: scale(1.1);
          box-shadow: 0 0 0 10px rgba(252, 102, 52, 0);
          background: ${COLOR.primary};
          border: 1px solid ${COLOR.primary};
          color: ${COLOR.white};
          opacity: 0.5;
          transition: all 0.4s ease-out;
          -webkit-transition: all 0.4s ease-out;
          -moz-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          -ms-transition: all 0.4s ease-out;
          -webkit-animation-timing-function: ease-out;
          -moz-animation-timing-function: ease-out;
          -o-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          -o-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-iteration-count: infinite;
          -moz-animation-iteration-count: infinite;
          -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-direction: alternate;
          -moz-animation-direction: alternate;
        }

        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(252, 102, 52, 0);
          background: ${TRANSPARENT_COLOR.primary};
          border: 1px solid ${COLOR.primary};
          color: ${COLOR.primary};
          opacity: 1;
          transition: all 0.4s ease-out;
          -webkit-transition: all 0.4s ease-out;
          -moz-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          -ms-transition: all 0.4s ease-out;
          -webkit-animation-timing-function: ease-out;
          -moz-animation-timing-function: ease-out;
          -o-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          -o-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-iteration-count: infinite;
          -moz-animation-iteration-count: infinite;
          -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-direction: alternate;
          -moz-animation-direction: alternate;
        }
      }
    }

    .hide-input {
      display: none;
    }

    .upload-audio-button {
      width: 160px;
    }
  }

  .convert {
    margin: 10px 20px;
    border-radius: 10px;
    padding: 20px;
    background: ${TRANSPARENT_COLOR.secondary};

    .upload-audio {
      display: flex;
      align-items: center;

      .upload-audio-title {
        flex-grow: 1;
      }

      audio {
        width: 500px;
        height: 45px;
      }

      audio::-webkit-media-controls-panel {
        background-color: white;
      }
    }

    .convert-box {
      min-height: 300px;
      margin-top: 10px;
      border: none;
      overflow: auto;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;
      border-radius: ${BORDER_RADIUS};
      background: ${COLOR.white};
      padding: 24px;
      font-size: 16px;
      line-height: 32px;

      &:focus {
        outline: 0.5px solid ${COLOR.primary};
      }

      .convert-item {
        width: fit-content;
      }

      .active {
        background: ${TRANSPARENT_COLOR.lightOrange};

        &:hover {
          background: ${COLOR.primary};
          color: ${COLOR.white};
        }
      }
    }
  }
`;
