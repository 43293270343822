import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
  TheatersRounded,
  RecordVoiceOverRounded,
  GraphicEqRounded,
} from '@mui/icons-material';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import { useSelector } from 'react-redux';
import { StyledTitleProduct } from './index.style';

const TitleProduct = () => {
  const { t } = useTranslation();
  const { pathname } = window.location;
  const { getFeatureValue } = useFeatureFlags();
  const { user } = useSelector((state) => state.auth);

  const useTitleProduct = getFeatureValue(FEATURE_KEYS.TITLE_PRODUCT, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const ttsPaths = ['/studio/text-to-speech'];
  const dubbingPaths = ['/dubbing', '/dubbing/projects'];
  const voiceCloningPaths = ['/studio/voice-cloning/voices'];

  if (!useTitleProduct) {
    return <></>;
  }

  if (ttsPaths.includes(pathname)) {
    return (
      <StyledTitleProduct>
        <GraphicEqRounded className="icon" />
        <Typography className="lable-create-new">{t('STUDIO')}</Typography>
      </StyledTitleProduct>
    );
  }

  if (dubbingPaths.includes(pathname)) {
    return (
      <StyledTitleProduct>
        <TheatersRounded className="icon" />
        <Typography className="lable-create-new">{t('DUBBING')}</Typography>
      </StyledTitleProduct>
    );
  }

  if (voiceCloningPaths.includes(pathname)) {
    return (
      <StyledTitleProduct>
        <RecordVoiceOverRounded className="icon" />
        <Typography className="lable-create-new">
          {t('voiceCloning')}
        </Typography>
      </StyledTitleProduct>
    );
  }

  return <></>;
};
export default TitleProduct;
