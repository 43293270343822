import moment from 'moment';

const { PACKAGE_LEVEL, PACKAGE_CODE } = require('@src/constants/package');

const checkTodayDisplay = () => {
  const today = moment().format('DD/MM/YYYY');
  const prevDisplayInfo = JSON.parse(
    localStorage.getItem('AUTO_RENEWAL_BANNER'),
  );

  const todayDisplay =
    (!prevDisplayInfo || prevDisplayInfo?.date !== today) &&
    !prevDisplayInfo?.notShowAgain;
  return todayDisplay;
};

const checkShowAutoRenewalPayment = ({
  packageCode = '',
  alwayShow = true,
}) => {
  if (!packageCode) return false;

  const isFreePackage =
    packageCode.includes(PACKAGE_LEVEL.TRIAL) ||
    packageCode.includes(PACKAGE_LEVEL.BASIC) ||
    packageCode.includes(PACKAGE_CODE.STUDIO_FREE);

  if (alwayShow) return !isFreePackage;
  return !isFreePackage && checkTodayDisplay();
};

export { checkShowAutoRenewalPayment };
