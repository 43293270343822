import moment from 'moment';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import apis from '@src/apis';
import { checkVietNam } from '@src/utils/checkCountry';
import actions from '@src/redux/actions';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { PACKAGE_CODE, PACKAGE_LEVEL } from '@src/constants/package';
import {
  BITRATE,
  ENGLISH_VOICE_CODES,
  LANGUAGE_CODE,
  REQUEST_STATUS,
  VOICE_PROVIDER,
  REGEX,
} from '@src/constants/voice';
import datasenses from '@src/services/dataSenses';
import { BANNER_TYPE } from '@src/constants/banner';
import { isSameToday } from '@src/utils/time';
import {
  EVENT_TYPE,
  POPUP_TIME_OUT,
  ZERO_BREAK_TIME_REGEX,
} from '@src/constants';
import { checkFeaturePermission, countTextLength } from '@src/services/tts';
import {
  TTS_VERSION,
  VALID_CHARACTERS_LENGTH_REGEX,
  VALID_CHARACTERS_REGEX,
  VALID_SPEED,
} from '@src/constants/tts';
import { REQUEST_TYPE, WARNING_REQUEST_TYPE } from '@src/constants/request';
import { ERROR_CODE } from '@src/errors/code';
import route from '@src/constants/route';
import {
  convertParagraphsToText,
  convertTextToParagraphs,
} from '@src/services/paragraph';

import { StyledMobileTTS } from './index.styled';
import SampleScripts from '../TTSNew/SampleScripts';
import AudioPlayer from './AudioPlayer';

const MobileTTS = () => {
  const { t } = useTranslation();
  const isVietNam = checkVietNam();
  const audioRef = createRef();
  const history = useHistory();

  const { getFeatureValue } = useFeatureFlags();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [sampleScripts, setSampleScripts] = useState([]);
  const [defaultSampleScript, setDefaultSampleScript] = useState(null);
  const [enableEditor, setEnableEditor] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [inputLength, setInputLength] = useState(0);

  const { synthesisRequest, voice, paragraphs, openSentences } = useSelector(
    (state) => state.synthesisRequest,
  );
  const { usingPackage, ttsUser } = useSelector((state) => state.user);
  const {
    user: { remainingCharacters, bonusCharacters, packageExpiryDate },
  } = useSelector((state) => state.auth);
  const { defaultGlobalVoice } = useSelector((state) => state.voice);
  const { isPlaying } = useSelector((state) => state.audioPlayer);

  const handleChangeText = (event) => {
    const dataSynthesis = {
      ...synthesisRequest,
      text: event.target.value,
      status: '',
    };
    dispatch(actions.synthesisRequest.updateSynthesisConfig(dataSynthesis));
  };

  const handleEnableEditor = (value) => setEnableEditor(value);

  const useTextSample = getFeatureValue(FEATURE_KEYS.SAMPLE_SCRIPTS, {
    userId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });

  const fetchSampleScript = async () => {
    const response = await apis.sampleScripts.getSampleScript();
    const totalSampleScript = response?.result?.sampleScripts;
    let sampleScript = [];
    if (isVietNam)
      sampleScript = totalSampleScript.filter(
        (item) => item.isVietnamese && !item.isDefault,
      );
    else
      sampleScript = totalSampleScript.filter(
        (item) => !item.isVietnamese && !item.isDefault,
      );
    setSampleScripts(sampleScript);

    const defaultSample = totalSampleScript.find(
      (item) =>
        item.isDefault && (isVietNam ? item.isVietnamese : !item.isVietnamese),
    );
    setDefaultSampleScript(defaultSample);
    dispatch(actions.synthesisRequest.updateDefaultSample(defaultSample));
  };

  const placeholder = useMemo(() => {
    if (useTextSample) return defaultSampleScript?.text || '';
    return t('enterTextHere');
  }, [useTextSample, defaultSampleScript]);

  const fetchVoicesByViLanguage = async () => {
    const data = await apis.voices.getVoices({
      languageCode: LANGUAGE_CODE.VIETNAMESE,
      active: true,
    });
    if (data.result) {
      const vietnameseVoices = data.result.voices;

      if (vietnameseVoices.length) {
        // setVoice(vietnameseVoices[0]);
        dispatch(actions.synthesisRequest.updateVoice(vietnameseVoices[0]));
      }
    }
  };

  const fetchVoicesByEnglishLanguage = async () => {
    const data = await apis.voices.getVoices({
      languageCode: ENGLISH_VOICE_CODES.toString(),
      active: true,
      sort: 'rank_asc',
    });
    if (data.result) {
      const englishVoices = data.result.voices;
      if (englishVoices.length) {
        // setVoice(englishVoices[0]);
        dispatch(actions.synthesisRequest.updateVoice(englishVoices[0]));
        dispatch(actions.voice.setDefaultGlobalVoice(englishVoices[0].code));
      }
    }
  };

  const getVoiceDefault = async () => {
    const isUseFreePackage = user.packageCode === PACKAGE_CODE.STUDIO_FREE;
    if (isVietNam || isUseFreePackage) fetchVoicesByViLanguage();
    else fetchVoicesByEnglishLanguage();
  };

  const handleChangeVoice = (value, loadingSynthesisConfig) => {
    // Update with normal voice
    if (synthesisRequest?.voice?.code !== value?.code && !enableEditor) {
      setEnableEditor(true);
      dispatch(actions.synthesisRequest.cloneSynthesisRequest());
    }

    dispatch(actions.synthesisRequest.updateVoice(value));
    if (
      value?.version !== TTS_VERSION.EMPHASIS &&
      voice?.version !== TTS_VERSION.EMPHASIS
    )
      return;

    if (
      value?.code !== voice?.code &&
      !openSentences &&
      !loadingSynthesisConfig
    ) {
      let newParagraphs = [];
      let convertText = '';
      const isAdvanceFeature = value?.version === TTS_VERSION.EMPHASIS;
      if (isAdvanceFeature) {
        convertText = synthesisRequest.text
          .getCurrentContent()
          .getPlainText('\n');
        newParagraphs = convertTextToParagraphs(convertText);
      } else {
        const text = convertParagraphsToText(paragraphs);
        convertText = text
          .replace(REGEX.EMPHASIS_TAG, '')
          .replace(
            REGEX.ADVANCE_BREAK_TIME_TAG,
            (p1, p2) => `<break time=${p2}s/>`,
          );
      }

      const { id, status, createdAt, endedAt, progress, ...rest } =
        synthesisRequest;
      const newSynthesisRequest =
        synthesisRequest?.voice?.code !== value?.code ? rest : synthesisRequest;

      const newSynthesisConfig = {
        ...newSynthesisRequest,
        paragraphs: newParagraphs,
        text: convertText,
        voice: value,
        characters: inputLength,
        sentences: [],
      };

      dispatch(
        actions.synthesisRequest.updateSynthesisConfig(newSynthesisConfig),
      );
      dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
    }

    // Update with emphasis voice
    if (synthesisRequest?.voice?.code !== value?.code && !enableEditor) {
      setEnableEditor(true);
      dispatch(actions.synthesisRequest.cloneSynthesisRequest());
    }
  };

  const checkVoicePermission = () => {
    const isDefaultGlobalVoice =
      !isVietNam &&
      usingPackage?.level === PACKAGE_LEVEL.BASIC &&
      voice?.code === defaultGlobalVoice;

    return (
      !voice?.features?.length ||
      isDefaultGlobalVoice ||
      voice.features.every((item) =>
        checkFeaturePermission(ttsUser.features, item),
      )
    );
  };

  const checkValidCreateSynthesis = () => {
    const canUseVoice = checkVoicePermission();
    if (!canUseVoice) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'limitedVoiceDescription',
        }),
      );
      return false;
    }

    const countLength = countTextLength(synthesisRequest.text);

    if (!countLength) return false;

    if (countLength > ttsUser.maxLengthInputText) {
      // setOpenExceedCharactersDialog(true);
      return false;
    }
    if (countLength > remainingCharacters + bonusCharacters) {
      // setActionDialog({
      //   image: UpgradeImg,
      //   title: t('notEnoughCharacters'),
      //   description: t('noteNotEnoughCharacters'),
      //   onClose: handleCloseActionDialog,
      //   actionComponents: renderUpgradeButton(),
      // });
      return false;
    }

    const isNotUsingPackage = !usingPackage.id;
    if (isNotUsingPackage) {
      // setActionDialog({
      //   image: UpgradeImg,
      //   title: t('noPackage'),
      //   description: t('noteNoPackage'),
      //   onClose: handleCloseActionDialog,
      //   actionComponents: renderUpgradeButton(),
      // });
      return false;
    }

    const isExpiredPackage =
      packageExpiryDate && moment().isAfter(packageExpiryDate);
    if (isExpiredPackage) {
      // setActionDialog({
      //   image: UpgradeImg,
      //   title: t('packageExpired'),
      //   description: t('notePackageExpired'),
      //   onClose: handleCloseActionDialog,
      //   actionComponents: renderUpgradeButton(),
      // });
      return false;
    }

    const { speed } = synthesisRequest;

    const invalidSpeed = speed < VALID_SPEED.min || speed > VALID_SPEED.max;
    if (invalidSpeed) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'speedInvalidRange',
          value: t('range', {
            min: `${VALID_SPEED.min}x`,
            max: `${VALID_SPEED.max}x`,
          }),
        }),
      );
      return false;
    }
    return true;
  };

  const handleCreateSynthesis = async (isDefaultText) => {
    if (isDefaultText) return;

    const { title, audioType, bitrate, backgroundMusic, volume } =
      synthesisRequest;

    if (isSameToday(user?.createdAt))
      setTimeout(
        () =>
          dispatch(
            actions.banner.updateDisplayBanner(
              BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL,
              true,
            ),
          ),
        POPUP_TIME_OUT,
      );

    let synthesisPayload = { audioType, volume };

    synthesisPayload.bitrate = bitrate || BITRATE[BITRATE.length - 1];

    if (title) synthesisPayload.title = title.trim();

    const hasBackgroundMusic =
      backgroundMusic && Object.keys(backgroundMusic).length;
    if (hasBackgroundMusic) {
      const { link, name, volume: bgVolume } = backgroundMusic;
      synthesisPayload.backgroundMusic = { link, name, volume: bgVolume };
    }

    if (!checkValidCreateSynthesis()) return;

    // if (advanceFeature) {
    //   synthesisPayload.version = TTS_VERSION.EMPHASIS;
    //   const needCheckParagraphs = openSentences
    //     ? synthesisSentences
    //     : paragraphs;

    //   const { error, detailError } =
    //     checkSyntaxTagsParagraphs(needCheckParagraphs);
    //   if (error) {
    //     setOpenWrongSyntaxDialog({
    //       name: 'error-syntax',
    //       variant: 'warning',
    //       title: t('errorSyntax'),
    //       description: t('errorSyntaxDescription'),
    //       subDescription: t(detailError),
    //     });
    //     return;
    //   }
    // }

    const { text } = synthesisRequest;
    const hasZeroBreakTime = ZERO_BREAK_TIME_REGEX.test(text);
    if (hasZeroBreakTime) {
      // handleChangeOpenWrongBreakTimeDialog(true);
      return;
    }

    // validate blackWords
    // const plainText = text
    //   .replace(REGEX.ADVANCE_TAG, '')
    //   .replace(BREAK_TIME_REGEX, ' ')
    //   .replace(/  +/g, ' ');
    // const blackWordsInText = blackWords.filter((blackWord) =>
    //   plainText.toLowerCase().includes(blackWord),
    // );
    // const blackWordsInTextRegex = blackWordsInText.map((blackWord) =>
    //   blackWord.replaceAll(' ', ' +'),
    // );
    // if (blackWordsInText.length > 0) {
    //   // handleChangeContent(stateContent);

    //   apis.warning.sendWarningInvalidRequest({
    //     text,
    //     invalidTexts: blackWordsInText,
    //     type: WARNING_REQUEST_TYPE.BLACK_WORD,
    //   });

    // setOpenWrongSyntaxDialog({
    //   name: 'error-syntax',
    //   variant: 'warning',
    //   title: t('sentenceHasBlackWords'),
    //   description: t('sentenceHasBlackWordsDescription'),
    // });
    //   return;
    // }

    if (voice?.provider === VOICE_PROVIDER.VBEE) {
      const invalidTexts = text.match(VALID_CHARACTERS_LENGTH_REGEX) || [];
      const isValidText = VALID_CHARACTERS_REGEX.test(text);
      if (!isValidText) invalidTexts.push(text);

      if (invalidTexts?.length) {
        // setOpenWrongSyntaxDialog({
        //   name: 'error-syntax',
        //   variant: 'warning',
        //   title: t('errorSyntax'),
        //   description: t('errorSyntaxDescription'),
        //   subDescription: t('invalidCharacter', { invalidText }),
        // });
        apis.warning.sendWarningInvalidRequest({
          text,
          invalidTexts,
          type: WARNING_REQUEST_TYPE.CHARACTER_EXCEED,
        });
        return;
      }
    }

    // if (isSSML) {
    //   if (checkValidXml(text)) {
    //     // TODO: map ssml to payload before call api
    //   } else setOpenSSMLDialog(true);
    //   return;
    // }

    synthesisPayload = {
      ...synthesisPayload,
      text,
      voiceCode: voice?.code,
      speed: Number(synthesisRequest.speed),
    };

    // setLoading(false);

    const isStandardData = getFeatureValue(
      FEATURE_KEYS.STANDARDIZATION_USER_DATA_MOE,
    );
    if (!isStandardData)
      window.dataLayer.push({
        event: EVENT_TYPE.STUDIO_CONVERT_TTS,
        userId: user?.id,
        email: user.email,
        requestType: 'requestTTS',
        voice: voice?.name,
        characters: text.length,
        package: user.packageCode,
        remainingCharacters: user.remainingCharacters,
        packageExpiryDate: moment(user.packageExpiryDate).format(
          'DD/MM/YYY - HH:mm',
        ),
      });

    const options = {};
    // const recaptchaTtsType = growthbook.getFeatureValue(
    //   FEATURE_KEYS.RECAPTCHA_TTS_TYPE,
    // );

    let recaptchaToken;
    // switch (recaptchaTtsType) {
    //   case RECAPTCHA_TYPE.V3:
    //     if (executeRecaptcha)
    //       recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION.SYNTHESIS);
    //     break;
    //   case RECAPTCHA_TYPE.ENTERPRISE:
    //     if (window.grecaptcha) {
    //       recaptchaToken = await window?.grecaptcha?.enterprise?.execute(
    //         RECAPTCHA_ENTERPRISE_SITE_KEY,
    //       );
    //     }
    //     break;
    //   default:
    //     break;
    // }
    if (recaptchaToken) options.recaptchaToken = recaptchaToken;

    synthesisPayload.datasenses = datasenses.getDataSensesRequireFields();
    const data = await apis.synthesis.createSynthesis(
      synthesisPayload,
      options,
    );

    history.push(route.MOBILE_REQUESTS);

    datasenses.sendMakeRequestEvent({
      userId: user.id,
      requestType: REQUEST_TYPE.STUDIO,
      voice: voice?.code,
      characters: text.length,
      status: REQUEST_STATUS.IN_PROGRESS,
    });

    const { bitrate: bitrateResponse } = data.result || {};

    const newSynthesisRequest = {
      ...data.result,
      bitrate:
        bitrateResponse % 1000 === 0 ? bitrateResponse / 1000 : bitrateResponse,
      id: data.result?.requestId,
    };
    dispatch(
      actions.synthesisRequest.updateSynthesisConfig(newSynthesisRequest),
    );
    // handleEnableEditor(false);
    // setLoading(false);
    if (data?.status) {
      // handleChangeRequestLoading(true);
      // document.getElementById('requests').scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'end',
      //   inline: 'nearest',
      // });
    } else {
      const { errorMessage, errorCode } = data;

      if (errorCode === ERROR_CODE.INVALID_SYNTAX) {
        // setOpenWrongSyntaxDialog({
        //   name: 'error-syntax',
        //   variant: 'warning',
        //   title: t('errorSyntax'),
        //   description: t('errorSyntaxDescription'),
        // });
      } else {
        dispatch(
          actions.noti.push({
            severity: 'error',
            message:
              errorCode === ERROR_CODE.BAD_REQUEST
                ? 'errorOccurred'
                : 'synthesisFailure',
            value: errorMessage,
          }),
        );
      }
    }
  };

  useEffect(() => {
    fetchSampleScript();
  }, [isVietNam]);

  const onMobile = true;
  useEffect(() => {
    getVoiceDefault();
  }, []);

  return (
    <StyledMobileTTS>
      <div className="page-container">
        <textarea
          rows={15}
          className="text-area-input"
          placeholder={placeholder}
          value={synthesisRequest.text}
          disabled={isPlaying}
          onChange={handleChangeText}
        />
        <div className="count-characters">
          {synthesisRequest.text?.length || 0}
          <span className="count-characters-max">
            /{ttsUser.maxLengthInputText} {t('characters')}
          </span>
        </div>

        <div className="tool-row">
          <SampleScripts
            audioRef={audioRef}
            sampleScripts={sampleScripts}
            onMobile={onMobile}
          />
        </div>
      </div>
      <AudioPlayer
        audioRef={audioRef}
        voice={voice}
        speed={synthesisRequest.speed}
        enableEditor={enableEditor}
        onEnableEditor={handleEnableEditor}
        handleChangeVoice={handleChangeVoice}
        onCreateSynthesis={handleCreateSynthesis}
      />
    </StyledMobileTTS>
  );
};

export default MobileTTS;
