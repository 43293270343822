import React, { useState, useEffect } from 'react';
import NewVoucherBanner from './NewVoucherBanner';
import { SwiperVoucherBannerContainer } from './index.style';

const SwiperVoucherBanner = ({ bannerVoucher }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bannerHeights, setBannerHeights] = useState([]);

  useEffect(() => {
    setCurrentSlide(0);
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === bannerVoucher.length - 1 ? 0 : prevSlide + 1,
      );
    }, 8000);
    return () => clearInterval(interval);
  }, [bannerVoucher]);

  const getSlideClass = (index) => {
    if (index === currentSlide) return 'active';
    if (
      index ===
      (currentSlide - 1 + bannerVoucher.length) % bannerVoucher.length
    )
      return 'prev';
    return 'next';
  };

  const handleBannerHeightChange = (height, index) => {
    setBannerHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      if (newHeights[index] !== height) {
        newHeights[index] = height;
        return newHeights;
      }
      return prevHeights;
    });
  };

  const currentBannerHeight = bannerHeights[currentSlide] || 0;

  return (
    <SwiperVoucherBannerContainer bannerHeight={currentBannerHeight}>
      {bannerVoucher &&
        bannerVoucher.length > 0 &&
        bannerVoucher.map((voucher, index) => (
          <div
            key={voucher.id || `banner-voucher-${index}`}
            className={getSlideClass(index)}
          >
            <NewVoucherBanner
              voucher={voucher}
              onBannerHeightChange={(height) =>
                handleBannerHeightChange(height, index)
              }
            />
          </div>
        ))}
    </SwiperVoucherBannerContainer>
  );
};

export default SwiperVoucherBanner;
