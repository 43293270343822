import React from 'react';
import { Avatar, Badge } from '@mui/material';
import { randomColor } from '@src/services/color';
import { AVATAR_COLORS } from '@src/styles/config';
import { COLOR } from '@src/styles/color';
import { SmallAvatarStyled, BadgeAvatarStyled } from './index.style';

const BADGE_TYPE = { DOT: 'dot', IMAGE: 'image', ICON: 'icon' };

const BadgeAvatar = ({
  img,
  name,
  number,
  shape = '',
  width = 40,
  icon,
  smallImg,
  smallImgShape = '',
  smallImgWidth = 15,
  type = BADGE_TYPE.DOT,
  active,
  removeDefaultIcon = false,
}) => {
  const styledAvatar = {
    width: width || 40,
    height: width || 40,
    fontWeight: '500',
    backgroundColor:
      type === BADGE_TYPE.IMAGE
        ? COLOR.gray
        : randomColor(AVATAR_COLORS, number),
  };

  const avatarContent = !img && name && name.slice(0, 1).toUpperCase();

  if (type === BADGE_TYPE.DOT)
    return (
      <BadgeAvatarStyled
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        active={active}
        variant="dot"
      >
        <Avatar src={img} variant={shape || ''} sx={styledAvatar}>
          {avatarContent}
        </Avatar>
      </BadgeAvatarStyled>
    );

  const renderBadgeContent = () => {
    if (icon) return icon;
    if (removeDefaultIcon) return null;
    return (
      <SmallAvatarStyled
        src={smallImg}
        variant={smallImgShape}
        sx={{
          width: smallImgWidth,
          height: smallImgWidth,
          border: type === BADGE_TYPE.ICON && 'none',
        }}
      />
    );
  };

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: type === BADGE_TYPE.ICON ? 'top' : 'bottom',
        horizontal: 'right',
      }}
      badgeContent={renderBadgeContent()}
    >
      <Avatar src={img} variant={shape} sx={styledAvatar}>
        {avatarContent}
      </Avatar>
    </Badge>
  );
};

export default BadgeAvatar;
