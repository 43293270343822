import {
  Home as HomeIcon,
  GridViewOutlined as DashboardIcon,
  TextFieldsRounded as TTSIcon,
  ShoppingCartOutlined as PackageIcon,
  Groups as AffiliateIcon,
  LocalOfferOutlined as LiveSupportIcon,
  PhoneAndroidRounded as MobileAppIcon,
  SubtitlesOutlined as DubbingIcon,
} from '@mui/icons-material';
import Home from '@src/pages/Home';
import ComingSoon from '@src/pages/ComingSoon';
import TTS from '@src/pages/TTS';
import STT from '@src/pages/STT';
import Dubbing from '@src/pages/Dubbing';
import Payment from '@src/pages/Payment';
import PaymentHistory from '@src/pages/PaymentHistory';
import MobileApp from '@src/pages/MobileApp';
import ManagerDevice from '@src/pages/ManageDevice';
import ConfigAutoRenew from '@src/pages/ConfigAutoRenew';

import Affiliate from '@src/pages/Affiliate';
import AffiliateDetail from '@src/pages/AffliateDetail';
import routes from '@src/constants/route';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import Error from '@src/pages/Error';

export default [
  {
    name: 'home',
    path: routes.HOME,
    component: Home,
    icon: HomeIcon,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    dubbingSidebar: false,
    featureKey: FEATURE_KEYS.HOME_PAGE,
  },
  {
    name: 'dashboard',
    path: routes.DASHBOARD,
    component: ComingSoon,
    icon: DashboardIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'convertText',
    path: routes.TTS,
    component: TTS,
    icon: TTSIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    dubbingSidebar: false,
  },
  {
    name: 'dubbing',
    path: routes.DUBBING,
    component: Dubbing,
    icon: DubbingIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    beta: true,
    featureKey: FEATURE_KEYS.DUBBING,
    dubbingSidebar: true,
  },
  {
    name: 'buyPackage',
    path: routes.PAYMENT,
    component: Payment,
    icon: PackageIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    banner: true,
    dubbingSidebar: true,
  },
  {
    name: 'buyPackage',
    path: routes.PAYMENT_HISTORY,
    component: PaymentHistory,
    icon: PackageIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    banner: true,
    dubbingSidebar: false,
  },
  {
    name: 'speechToText',
    path: routes.STT,
    component: STT,
    icon: TTSIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: false,
    beta: true,
    dubbingSidebar: false,
  },
  {
    name: 'mobileApp',
    path: routes.MOBILE_APP,
    component: MobileApp,
    icon: MobileAppIcon,
    exact: true,
    restricted: true,
    isPrivate: true,
    sidebar: true,
    dubbingSidebar: false,
  },
  {
    name: 'affiliate',
    path: routes.AFFILIATE,
    subPaths: [routes.AFFILIATE_DETAIL],
    component: Affiliate,
    icon: AffiliateIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: true,
    dubbingSidebar: true,
  },
  {
    name: 'affiliate-detail',
    path: routes.AFFILIATE_DETAIL,
    component: AffiliateDetail,
    icon: AffiliateIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'onlineSupport',
    path: routes.LIVE_SUPPORT,
    component: ComingSoon,
    icon: LiveSupportIcon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'otherServices',
    path: routes.OTHER_SERVICES,
    component: ComingSoon,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'manageDevice',
    path: routes.MANAGE_DEVICE,
    component: ManagerDevice,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'configAutoRenew',
    path: routes.CONFIG_AUTO_RENEW,
    component: ConfigAutoRenew,
    exact: true,
    restricted: false,
    isPrivate: true,
    sidebar: false,
    dubbingSidebar: false,
  },
  {
    name: 'error',
    path: routes.ERROR,
    component: Error,
    exact: true,
    restricted: true,
    isPrivate: false,
    sidebar: false,
    dubbingSidebar: false,
  },
];
