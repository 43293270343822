import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { BORDER_RADIUS, BREAKPOINTS } from '@src/styles/config';

export const StyledSuccessButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 42px;

  .download-button {
    white-space: nowrap;
    border-radius: 20px 20px 20px 20px;
    font-weight: 700;
    font-size: 14px;
    color: ${COLOR.darkBlue};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: ${COLOR.secondary[32]};
    border-style: solid solid solid solid;
    border-width: 1px;
    background: inherit !important;
    &:hover {
      background: ${TRANSPARENT_COLOR.dark} !important;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD}px) {
    .download-button {
      padding-right: 16px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    .download-button {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-left: 12px;

    .download-button {
      padding-right: ${(props) => (props.langEng ? '30px' : '20px')};
      padding-left: 16px;
    }
  }
`;

export const StyledDownloadButton = styled('div')`
  color: ${(props) =>
    props.disabled ? COLOR.secondary[32] : COLOR.darkBlue} !important;

  .text-download {
    font-weight: 700;
    font-size: 14px;
    margin-left: 3px;
  }

  @media (max-width: calc(${BREAKPOINTS.MD}px + 80px)) {
    .text-download {
      display: none;
    }
  }

  @media (min-width: calc(${BREAKPOINTS.MD}px + 80px)) {
    .text-download {
      display: block;
    }
  }
`;

export const StyledTextIcon = styled(IconButton)`
  width: 100%;
  padding: ${(props) =>
    props.langEng ? '6px 0px 6px 18px' : '6px 0px 6px 20px'};
  color: ${(props) => (props.disabled ? COLOR.secondary[32] : COLOR.darkBlue)};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  justify-content: start;
  &:hover {
    background: ${TRANSPARENT_COLOR.dark};
    color: ${({ colorHover }) => COLOR[colorHover]};
    opacity: 1;
  }
  .text-action {
    color: ${(props) =>
      props.disabled ? COLOR.secondary[32] : COLOR.darkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: ${(props) => (props.langEng ? '4px' : '4px')};
    &:hover {
      color: ${COLOR.black};
    }
  }
`;
