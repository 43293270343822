import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { Cached } from '@mui/icons-material';
import Dialog from '@src/components/Dialog';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import {
  CLIENT_PAUSE_VALUE_RANGE,
  DEFAULT_CLIENT_PAUSE,
  VALID_CLIENT_PAUSE,
} from '@src/constants/tts';
import ProcessHandler from '@src/components/ProcessHandler';

import CustomNumberInput from '@src/components/CustomNumberInput';
import { StyledClientPause, StyledDialogContent } from './index.style';

const ClientPause = ({ open, onClose }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [clientPause, setClientPause] = useState(DEFAULT_CLIENT_PAUSE);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const fetchClientPause = async () => {
    setLoadingData(true);
    const data = await apis.clientPause.getClientPause();
    if (data?.status) {
      const { clientPause: dataClientPause } = data.result || {};
      if (dataClientPause) {
        const secClientPause = Object.keys(DEFAULT_CLIENT_PAUSE).reduce(
          (acc, key) => {
            acc[key] = dataClientPause[key]
              ? dataClientPause[key]
              : DEFAULT_CLIENT_PAUSE[key];
            return acc;
          },
          {},
        );
        setClientPause(secClientPause);
      }
    }
    setLoadingData(false);
  };

  const handleClose = () => {
    setLoadingData(false);
    onClose();
  };

  const handleChangeSeconds = (key, sec) =>
    setClientPause({
      ...clientPause,
      [key]: Number(Number(sec).toFixed(2)),
    });

  const handleSave = async (data) => {
    setLoadingSave(true);
    const response = await apis.clientPause.updateClientPause(data);
    setLoadingSave(false);
    if (!response?.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'updateClientPauseFailure',
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        severity: 'success',
        message: 'updateClientPauseSuccess',
      }),
    );
    onClose();
  };

  const handleReset = () => setClientPause(DEFAULT_CLIENT_PAUSE);

  useEffect(() => {
    fetchClientPause();
  }, [open]);

  return (
    <Dialog
      title={t('configClientPause')}
      open={open}
      onClose={handleClose}
      fullWidth={false}
    >
      <StyledClientPause>
        <ProcessHandler loading={loadingData} size={25}>
          <DialogContent>
            <StyledDialogContent>
              <List className="music-list">
                {Object.keys(clientPause).map((punctuation) => (
                  <ListItem className="list-item" key={punctuation}>
                    <Typography variant="body2">{t(punctuation)}</Typography>
                    <div className="number-input-wrapper">
                      <CustomNumberInput
                        className="number-input dashed-border mr-18"
                        onChange={(sec) =>
                          handleChangeSeconds(punctuation, sec)
                        }
                        value={clientPause[punctuation]}
                        min={VALID_CLIENT_PAUSE.min}
                        max={VALID_CLIENT_PAUSE.max}
                        suffix={t('seconds')}
                        valueRange={CLIENT_PAUSE_VALUE_RANGE}
                      />
                    </div>
                  </ListItem>
                ))}
              </List>
            </StyledDialogContent>
          </DialogContent>
        </ProcessHandler>
        <DialogActions classes={{ root: 'dialog-action' }}>
          <Button
            className="button"
            variant="contained"
            onClick={() => handleSave(clientPause)}
            disabled={loadingData || loadingSave}
          >
            {t('saveChange')}
          </Button>
          <Button
            className="button"
            variant="outlined"
            color="secondary"
            startIcon={<Cached />}
            onClick={handleReset}
            disabled={loadingData || loadingSave}
          >
            {t('default')}
          </Button>
        </DialogActions>
      </StyledClientPause>
    </Dialog>
  );
};

export default ClientPause;
