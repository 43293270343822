import React, { useState, useEffect, useRef, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import camelcaseKeys from 'camelcase-keys';
import { EditorState, ContentState, CompositeDecorator } from 'draft-js';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useGrowthBook } from '@growthbook/growthbook-react';

import {
  LANGUAGE_CODE,
  REQUEST_STATUS,
  MAX_TRY_LISTENING_CHARACTERS,
  BREAK_TIME_REGEX,
  DEFAULT_SYNTHESIS_REQUEST,
  REGEX,
  ENGLISH_VOICE_CODES,
  VOICE_PROVIDER,
} from '@src/constants/voice';
import { WS_TYPE, PING_INTERVAL } from '@src/constants/websocket';
import { RECAPTCHA_ENTERPRISE_SITE_KEY, TTS_SYNTHESIS_URL } from '@src/configs';
import {
  countTextLength,
  checkValidXml,
  countTextLengthOfSentences,
  checkFeaturePermission,
} from '@src/services/tts';
import {
  newSplitParagraphIntoSentence,
  splitParagraphIntoSentence,
  getSentencesPayload,
  newGetSentencesPayload,
  convertSentenceToParagraphs,
} from '@src/services/sentence';
import { findWithRegexEditor, generateDecorator } from '@src/services/editor';
// import FEATURE_FLAG from '@src/constants/featureFlags.json';
import {
  getDataLocalStorage,
  deleteDataLocalStorage,
} from '@src/utils/localStorage';

import {
  convertParagraphsToText,
  countEditorStateLength,
  checkSyntaxTagsParagraphs,
  convertTextToParagraphs,
} from '@src/services/paragraph';

import Card from '@src/components/Card';
import { Button } from '@mui/material';
import { FEATURE_KEYS } from '@src/configs/featureKeys';

// import arrowIconDown from '@src/assets/icons/arrow-icon-down.png';
import ConfirmDialog from '@src/components/Dialog/ConfirmDialog';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import ROUTES from '@src/constants/route';

import { checkVietNam } from '@src/utils/checkCountry';
import NotificationDialog from '@src/components/NotificationDialog';
import {
  EVENT_TYPE,
  POPUP_TIME_OUT,
  ZERO_BREAK_TIME_REGEX,
} from '@src/constants';
import Decorator from '@src/components/Decorator';
import { TTS_GUIDE, TTS_TOUR_GUIDE } from '@src/constants/tourGuide';
// import Feature from '@src/components/Feature';
import {
  checkSyntaxTagsElements,
  convertElementsToText,
  getEntityStrategy,
} from '@src/services/entity';
import {
  VALID_SPEED,
  TTS_VERSION,
  VALID_CHARACTERS_LENGTH_REGEX,
  VALID_CHARACTERS_REGEX,
  PLAYING_AUDIO_TYPE,
  VOICE_VERSION,
} from '@src/constants/tts';
import { ERROR_CODE } from '@src/errors/code';
import {
  FEATURE,
  LIMITED_PREVIEW_PACKAGE_LEVEL,
  PACKAGE_LEVEL,
} from '@src/constants/package';
import { RECAPTCHA_ACTION, RECAPTCHA_TYPE } from '@src/constants/recaptcha';
import { REQUEST_TYPE, WARNING_REQUEST_TYPE } from '@src/constants/request';
import TrialPreviewImage from '@src/assets/icons/icon-preview.png';
import UpgradeImg from '@src/assets/images/space-shuttle.png';
import HightLightSpan from '@src/components/Decorator/HightLightSpan';
import ActionDialog from '@src/components/Dialog/ActionDialog';
import { BANNER_TYPE } from '@src/constants/banner';
import VoicesDialog from '@src/components/VoicesDialog';
import { isSameToday } from '@src/utils/time';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import datasenses from '@src/services/dataSenses';

import TourGuide from '../TourGuide';
import TitleBar from './TitleBar';
import Toolbar from './Toolbar';
import Content from './Content';
import ConvertAction from './ConvertAction';
import TTSDemo from './TTSDemo';
import OldTTSDemo from './TTSDemo/OldTTSDemo';
import Requests from './Requests';
import BreakTimeHighlight from './BreakTimeHightLight';

import { StyledTTS } from './index.style';
import BlackWordHighLight from './BlackWordHighLight';
// import SwitchAdva../TTSNew from './SwitchAdvanceFeature';

const TTS = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const audioRef = createRef();
  const growthbook = useGrowthBook();
  const useOldSaveDraftFeatureFlag = growthbook.getFeatureValue(
    FEATURE_KEYS.OLD_AUTO_SAVE_DRAFT,
  );
  const useNewTTSDemo = growthbook.getFeatureValue(
    FEATURE_KEYS.NEW_PLAY_AUDIO_LINK,
  );

  const [synthesisSentence, setSynthesisSentence] = useState('');
  const [openVoices, setOpenVoices] = useState(false);
  const [content, setContent] = useState(
    EditorState.createEmpty(
      generateDecorator(BREAK_TIME_REGEX, BreakTimeHighlight),
    ),
  );
  // const [voice, setVoice] = useState();
  const [runTourGuide, setRunTourGuide] = useState(false);
  const [inputLength, setInputLength] = useState(0);
  // const [openRequest, setOpenRequest] = useState(false);
  const [characterExceed, setCharacterExceed] = useState(false);
  const [confirmSwitchParagraph, setConfirmSwitchParagraph] = useState(false);
  const [isSSML, setIsSSML] = useState(false);
  const [openSSMLDialog, setOpenSSMLDialog] = useState(false);
  const [openWrongBreakTimeDialog, setOpenWrongBreakTimeDialog] =
    useState(false);
  const [openWrongSyntaxDialog, setOpenWrongSyntaxDialog] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openDraft, setOpenDraft] = useState(false);
  const [loadDraft, setLoadDraft] = useState(false);

  const [previewPayload, setPreviewPayload] = useState({});

  const [openExceedCharactersDialog, setOpenExceedCharactersDialog] =
    useState(false);
  const [actionDialog, setActionDialog] = useState();
  const [remainingPreview, setRemainingPreview] = useState(null);

  const [blackWords, setBlackWords] = useState([]);

  const runStreamAudioRef = useRef(false);

  const {
    isPlaying,
    requestPreviewId,
    audioType: playAudioType,
    isRunStreamAudio,
  } = useSelector((state) => state.audioPlayer);
  const {
    synthesisRequest,
    paragraphs,
    sentences,
    openSentences,
    advanceFeature,
    voice,
    selectedSentences,
  } = useSelector((state) => state.synthesisRequest);
  const user = useSelector((state) => state.auth.user);
  const { usingPackage, ttsUser } = useSelector((state) => state.user);
  const { seenAllBanner } = useSelector((state) => state.banner);
  const {
    accessToken,
    user: {
      remainingCharacters,
      bonusCharacters,
      packageExpiryDate,
      hasAgreedToTerms,
    },
  } = useSelector((state) => state.auth);
  const { limitedFeature } = useSelector((state) => state.dialog);
  const { defaultGlobalVoice } = useSelector((state) => state.voice);

  const maxLengthInputText = ttsUser?.maxLengthInputText || 0;

  const { t } = useTranslation();
  const ws = useRef(null);
  const currentIsPlayingAudio = useRef(null);
  const toolbarRef = useRef(null);
  const accessTokenRef = useRef(accessToken);
  const currentReqPreviewId = useRef(requestPreviewId);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAudioLoading } = useSelector((state) => state.audioPlayer);
  const { getFeatureValue } = useFeatureFlags();

  const isUseStreamingPreview = getFeatureValue(
    FEATURE_KEYS.STREAMING_PREVIEW,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  );

  const stepsGuide = [
    {
      title: 'textInput',
      steps: [
        { name: 'enterContentHere', target: `#${TTS_GUIDE.ENTER_TEXT_HERE}` },
      ],
    },
    {
      title: 'chooseVoice',
      steps: [
        {
          name: 'voiceAdjustmentGuide',
          target: `#${TTS_GUIDE.VOICE}`,
        },
      ],
    },
    {
      title: 'preview',
      steps: [
        {
          name: 'tryListeningGuide',
          target: `#${TTS_GUIDE.TRY_LISTENING}`,
        },
      ],
    },
    {
      title: 'textTransfer',
      steps: [{ name: 'convertTTSGuide', target: `#${TTS_GUIDE.CONVERT}` }],
    },
    {
      title: 'history',
      steps: [
        { name: 'timeRequestGuide', target: `#${TTS_GUIDE.REQUESTS_TABLE}` },
      ],
    },
  ];

  const handleOpenVoices = (value) => setOpenVoices(value);
  const handleCloseVoices = () => setOpenVoices(false);
  const handleChangeContent = (value) => setContent(value);
  const handleChangeInputLength = (value) => setInputLength(value);

  const handleCloseDraft = () => {
    setOpenDraft(false);
    deleteDataLocalStorage('dataDraft');
  };
  const handleOpenDraft = () => setOpenDraft(true);
  const handleLoadDraft = () => {
    setLoadDraft(true);
    setOpenDraft(false);
  };

  // const handleOpenRequest = (value) => setOpenRequest(value);
  const handleChangeCharacterExceed = (value) => setCharacterExceed(value);
  const handleChangeRequestLoading = (value) => setRequestLoading(value);
  const handleChangeSynthesisSentence = (value) => setSynthesisSentence(value);

  const handleChangeVoice = (value, loadingSynthesisConfig) => {
    // setVoice(value);
    dispatch(actions.synthesisRequest.updateVoice(value));
    if (
      value?.version !== TTS_VERSION.EMPHASIS &&
      voice?.version !== TTS_VERSION.EMPHASIS
    )
      return;

    if (
      value?.code !== voice?.code &&
      !openSentences &&
      !loadingSynthesisConfig
    ) {
      let newParagraphs = [];
      let convertText = '';
      const isAdvanceFeature = value?.version === TTS_VERSION.EMPHASIS;
      if (isAdvanceFeature) {
        convertText = content.getCurrentContent().getPlainText('\n');
        newParagraphs = convertTextToParagraphs(convertText);
      } else {
        const text = convertParagraphsToText(paragraphs);
        convertText = text
          .replace(REGEX.EMPHASIS_TAG, '')
          .replace(
            REGEX.ADVANCE_BREAK_TIME_TAG,
            (p1, p2) => `<break time=${p2}s/>`,
          );
      }
      const newSynthesisConfig = {
        ...synthesisRequest,
        paragraphs: newParagraphs,
        text: convertText,
        voice: value,
        characters: inputLength,
        sentences: [],
      };

      dispatch(
        actions.synthesisRequest.updateSynthesisConfig(newSynthesisConfig),
      );
      dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
    }
  };

  const handleChangeIsSSML = (value) => setIsSSML(value);
  const handleChangeOpenWrongBreakTimeDialog = (value) =>
    setOpenWrongBreakTimeDialog(value);

  const handleChangeRunTourGuide = (value) => {
    setRunTourGuide(value);
    dispatch(actions.user.isRunningTourGuide(value));
  };

  const handleCloseExceedCharactersDialog = () =>
    setOpenExceedCharactersDialog(false);

  const handleOpenSentences = (checkedSwitch) => {
    if (checkedSwitch) {
      dispatch(actions.synthesisRequest.updateOpenSentences(checkedSwitch));
      let newSentences;

      if (advanceFeature) {
        newSentences = newSplitParagraphIntoSentence(paragraphs, voice);
        dispatch(actions.synthesisRequest.updateParagraphs([]));
      } else {
        const plainTextContent = content.getCurrentContent().getPlainText('\n');
        newSentences = splitParagraphIntoSentence(plainTextContent, voice);
      }

      dispatch(actions.synthesisRequest.updateSentences(newSentences));
    } else {
      setConfirmSwitchParagraph(true);
    }
  };

  const handleCloseConfirmDialog = () => setConfirmSwitchParagraph(false);
  const handleCloseSSMLDialog = () => setOpenSSMLDialog(false);
  const handleCloseActionDialog = () => setActionDialog();
  const handleCloseLimitedFeatureDialog = () => {
    dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
    handleCloseActionDialog();
  };

  const handleUpgradeNow = () => {
    dispatch(actions.user.updateShowBlockDownloadDialog(false));
    history.push(ROUTES.PAYMENT);
  };
  const renderUpgradeButton = () => (
    <Button variant="contained" onClick={handleUpgradeNow}>
      {t('upgradeNow')}
    </Button>
  );

  const handleAcceptConfirmDialog = () => {
    dispatch(actions.synthesisRequest.updateOpenSentences(false));
    setConfirmSwitchParagraph(false);

    if (advanceFeature) {
      const newParagraphs = convertSentenceToParagraphs(sentences);
      dispatch(actions.synthesisRequest.updateParagraphs(newParagraphs));
      dispatch(actions.synthesisRequest.updateLoadingParagraphs(true));
    } else {
      const sentencesText = sentences.map((item) => item.text);
      const paragraphText = sentencesText.join('\n');

      const paragraphEditorStyle = EditorState.createWithContent(
        ContentState.createFromText(paragraphText),
        generateDecorator(BREAK_TIME_REGEX, BreakTimeHighlight),
      );
      setContent(paragraphEditorStyle);
    }
  };

  const handleLoadContentEditor = (
    sentencesSave,
    isAdvanceFeature,
    isOpenSentences,
  ) => {
    setLoadDraft(false);
    if (isOpenSentences) {
      const newSentences = sentencesSave.filter(
        (item) => !selectedSentences.includes(item.id),
      );
      dispatch(actions.synthesisRequest.updateSentences(newSentences));
      return;
    }
    if (!isOpenSentences) {
      if (!isAdvanceFeature) {
        const sentencesText = sentencesSave.map((item) => item.text);
        const paragraphText = sentencesText.join('\n');
        const paragraphEditorStyle = EditorState.createWithContent(
          ContentState.createFromText(paragraphText),
          generateDecorator(BREAK_TIME_REGEX, BreakTimeHighlight),
        );
        setContent(paragraphEditorStyle);
      }
      if (isAdvanceFeature) {
        dispatch(actions.synthesisRequest.switchAdvanceFeature(true));
        dispatch(
          actions.synthesisRequest.updateSynthesisRequest(
            'paragraphs',
            sentencesSave,
          ),
        );
      }
    }
  };

  const checkValidatePreviewSentence = (sentence) => {
    const {
      elements,
      previewParagraphs,
      voice: sentenceVoice,
      speed,
    } = sentence;

    let text;
    if (advanceFeature) {
      const { error, detailError } = openSentences
        ? checkSyntaxTagsElements(elements)
        : checkSyntaxTagsParagraphs(previewParagraphs, sentenceVoice?.provider);

      if (error) {
        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('errorSyntax'),
          description: t('errorSyntaxDescription'),
          subDescription: t(detailError),
        });
        return { error: true };
      }
      text = openSentences
        ? convertElementsToText(elements)
        : convertParagraphsToText(previewParagraphs);
      if (BREAK_TIME_REGEX.test(text)) {
        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('errorSyntax'),
          description: t('errorSyntaxDescription'),
          subDescription: t('invalidBreakTime'),
        });
        return { error: true };
      }
    } else {
      text = sentence?.text;
    }

    if (!text || !text.trim()) {
      setOpenWrongSyntaxDialog({
        name: 'error-syntax',
        variant: 'warning',
        title: t('errorSyntax'),
        description: t('errorSyntaxDescription'),
      });
      return { error: true };
    }

    const plainText = text
      .replace(REGEX.ADVANCE_TAG, '')
      .replace(BREAK_TIME_REGEX, ' ')
      .replace(/  +/g, ' ');
    const blackWordsInText = blackWords.filter((blackWord) =>
      plainText.toLowerCase().includes(blackWord),
    );
    if (blackWordsInText.length > 0) {
      const blackWordsInTextRegex = blackWordsInText.map((blackWord) =>
        blackWord.replaceAll(' ', ' +'),
      );
      const regex = new RegExp(blackWordsInTextRegex.join('|'), 'gi');
      const stateContent = EditorState.set(content, {
        decorator: new CompositeDecorator([
          {
            strategy: (contentBlock, callback) => {
              findWithRegexEditor(regex, contentBlock, callback);
            },
            component: BlackWordHighLight,
          },
          {
            strategy: getEntityStrategy('MUTABLE'),
            component: HightLightSpan,
          },
        ]),
      });
      handleChangeContent(stateContent);

      apis.warning.sendWarningInvalidRequest({
        text,
        invalidTexts: blackWordsInText,
        type: WARNING_REQUEST_TYPE.BLACK_WORD,
      });

      setOpenWrongSyntaxDialog({
        name: 'error-syntax',
        variant: 'warning',
        title: t('sentenceHasBlackWords'),
        description: t('sentenceHasBlackWordsDescription'),
      });
      return { error: true };
    }

    if (sentenceVoice?.provider === VOICE_PROVIDER.VBEE) {
      const invalidTexts = text.match(VALID_CHARACTERS_LENGTH_REGEX) || [];
      const isValidText = VALID_CHARACTERS_REGEX.test(text);
      if (!isValidText) invalidTexts.push(text);

      if (invalidTexts?.length) {
        const invalidText = invalidTexts.join(', ');
        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('errorSyntax'),
          description: t('errorSyntaxDescription'),
          subDescription: t('invalidCharacter', { invalidText }),
        });
        apis.warning.sendWarningInvalidRequest({
          text,
          invalidTexts,
          type: WARNING_REQUEST_TYPE.CHARACTER_EXCEED,
        });
        return { error: true };
      }
    }

    const invalidSpeed = speed < VALID_SPEED.min || speed > VALID_SPEED.max;
    if (invalidSpeed) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'speedInvalidRange',
          value: t('range', {
            min: `${VALID_SPEED.min}x`,
            max: `${VALID_SPEED.max}x`,
          }),
        }),
      );
      return { error: true };
    }

    const regex = advanceFeature ? REGEX.ADVANCE_TAG : BREAK_TIME_REGEX;
    const countLength = countTextLength(text, regex);
    const maxTryListeningCharacters =
      ttsUser.maxLengthDemoInput || MAX_TRY_LISTENING_CHARACTERS;
    if (!countLength || countLength > maxTryListeningCharacters) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'warningListening',
          value: maxTryListeningCharacters,
        }),
      );
      return { error: true };
    }
    return { error: false, previewData: { ...sentence, text } };
  };

  const handlePreviewSentence = (sentence) => {
    const { error, previewData } = checkValidatePreviewSentence(sentence);
    if (error) return;
    const { id: sentenceId, text, voice: sentenceVoice, speed } = previewData;

    const checkEmphasisInText = REGEX.ADVANCE_TAG.test(text);
    const suitableVersion = checkEmphasisInText
      ? VOICE_VERSION.ADVANCE
      : VOICE_VERSION.NORMAL;

    dispatch(actions.audioPlayer.updateAudioLink(''));
    dispatch(actions.audioPlayer.updateSelectedAudioRequest(''));
    dispatch(actions.audioPlayer.updateStatus(false));
    dispatch(actions.audioPlayer.updateStatusStreamAudio(false));
    dispatch(
      actions.audioPlayer.initStreamAudios(
        suitableVersion || sentenceVoice?.version,
      ),
    );
    dispatch(actions.audioPlayer.updateAudioType(PLAYING_AUDIO_TYPE.SILENCE));
    dispatch(
      actions.audioPlayer.updateMetaData({ currentTime: 0, duration: 0 }),
    );
    dispatch(
      actions.audioPlayer.updateTryListeningSentence({
        isAudioLoading: true,
        sentenceId,
      }),
    );

    const { audioType, bitrate, backgroundMusic, volume } = synthesisRequest;

    const payload = {
      text,
      voiceCode: sentenceVoice?.code,
      speed,
      audioType,
      volume,
    };

    if (advanceFeature) {
      payload.bitrate = bitrate;
      payload.version = TTS_VERSION.EMPHASIS;
    } else {
      payload.bitrate = bitrate;
    }

    const hasBackgroundMusic =
      backgroundMusic && Object.keys(backgroundMusic).length;
    if (hasBackgroundMusic) {
      const { link, name, volume: bgVolume } = backgroundMusic;
      payload.backgroundMusic = { link, name, volume: bgVolume };
    }

    const isStandardData = getFeatureValue(
      FEATURE_KEYS.STANDARDIZATION_USER_DATA_MOE,
    );
    if (!isStandardData)
      window.dataLayer.push({
        event: EVENT_TYPE.STUDIO_PREVIEW_TTS,
        userId: user?.id,
        email: user.email,
        requestType: 'Preview',
        voice: sentenceVoice?.name,
        characters: payload.text?.length || 0,
        package: user.packageCode,
        remainingCharacters: user.remainingCharacters,
        packageExpiryDate: moment(user.packageExpiryDate).format(
          'DD/MM/YYYY - HH:mm',
        ),
      });
    payload.datasenses = datasenses.getDataSensesRequireFields();
    setPreviewPayload(payload);
  };

  const handlePreviewTrialAction = (previewSentence) => {
    handlePreviewSentence(previewSentence);
    handleCloseActionDialog();
  };

  const checkVoicePermission = () => {
    const isVietNam = checkVietNam();
    const isDefaultGlobalVoice =
      !isVietNam &&
      usingPackage?.level === PACKAGE_LEVEL.BASIC &&
      voice?.code === defaultGlobalVoice;

    return (
      !voice?.features?.length ||
      isDefaultGlobalVoice ||
      voice.features.every((item) =>
        checkFeaturePermission(ttsUser.features, item),
      )
    );
  };

  const checkConditionPackageToPreview = (sentence) => {
    if (!ttsUser.features.includes(FEATURE.PREVIEW)) {
      setActionDialog({
        image: UpgradeImg,
        title: t('premiumFeature'),
        description: t('premiumFeatureDescription'),
        onClose: handleCloseActionDialog,
        actionComponents: renderUpgradeButton(),
      });
      return;
    }

    if (LIMITED_PREVIEW_PACKAGE_LEVEL.includes(usingPackage.level)) {
      if (!remainingPreview)
        setActionDialog({
          image: UpgradeImg,
          title: t('previewOver'),
          description: t('previewOverDescription'),
          onClose: handleCloseActionDialog,
          actionComponents: renderUpgradeButton(),
        });
      else {
        setActionDialog({
          image: TrialPreviewImage,
          title: t('previewTrial', { remainingPreview }),
          description: t('previewTrialDescription'),
          onClose: handleCloseActionDialog,
          actionComponents: (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseActionDialog}
              >
                {t('noValue')}
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePreviewTrialAction(sentence)}
              >
                {t('preview')}
              </Button>
            </>
          ),
        });
      }
      return;
    }

    const canUseVoice = checkVoicePermission();
    if (!canUseVoice) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'limitedVoiceDescription',
        }),
      );
      return;
    }

    const { id: sentenceId } = sentence;
    dispatch(
      actions.audioPlayer.updateTryListeningSentence({
        isAudioLoading: true,
        sentenceId,
      }),
    );
    handlePreviewSentence(sentence);
  };

  const checkValidCreateSynthesis = () => {
    const canUseVoice = checkVoicePermission();
    if (!canUseVoice) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'limitedVoiceDescription',
        }),
      );
      return false;
    }

    let countLength;

    if (openSentences) {
      const detailSelectedSentences = sentences.filter((item) =>
        selectedSentences.includes(item.id),
      );
      const synthesisSentences = selectedSentences?.length
        ? detailSelectedSentences
        : sentences;

      countLength = countTextLengthOfSentences(synthesisSentences);
    } else if (advanceFeature)
      countLength = countEditorStateLength(content, paragraphs);
    else {
      const text = content.getCurrentContent().getPlainText('\n');
      countLength = countTextLength(text);
    }

    if (!countLength) return false;

    if (countLength > maxLengthInputText) {
      setOpenExceedCharactersDialog(true);
      return false;
    }
    if (countLength > remainingCharacters + bonusCharacters) {
      setActionDialog({
        image: UpgradeImg,
        title: t('notEnoughCharacters'),
        description: t('noteNotEnoughCharacters'),
        onClose: handleCloseActionDialog,
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    const isNotUsingPackage = !usingPackage.id;
    if (isNotUsingPackage) {
      setActionDialog({
        image: UpgradeImg,
        title: t('noPackage'),
        description: t('noteNoPackage'),
        onClose: handleCloseActionDialog,
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    const isExpiredPackage =
      packageExpiryDate && moment().isAfter(packageExpiryDate);
    if (isExpiredPackage) {
      setActionDialog({
        image: UpgradeImg,
        title: t('packageExpired'),
        description: t('notePackageExpired'),
        onClose: handleCloseActionDialog,
        actionComponents: renderUpgradeButton(),
      });
      return false;
    }

    const { speed } = synthesisRequest;

    const invalidSpeed =
      !openSentences && (speed < VALID_SPEED.min || speed > VALID_SPEED.max);
    if (invalidSpeed) {
      dispatch(
        actions.noti.push({
          severity: 'warning',
          message: 'speedInvalidRange',
          value: t('range', {
            min: `${VALID_SPEED.min}x`,
            max: `${VALID_SPEED.max}x`,
          }),
        }),
      );
      return false;
    }

    return true;
  };

  const handleResetContentEditor = () => {
    setInputLength(0);
    deleteDataLocalStorage(`dataDraft-${user.id}`);
    dispatch(actions.synthesisRequest.updateSynthesisRequest('title', ''));
    if (openSentences) {
      const { voice: sentenceVoice = {} } = sentences[0];
      const initSentences = splitParagraphIntoSentence('', sentenceVoice);

      dispatch(actions.synthesisRequest.updateSentences(initSentences));
      dispatch(actions.synthesisRequest.updateSelectedSentences([]));

      return;
    }

    dispatch(actions.synthesisRequest.updateParagraphs([]));
    if (advanceFeature) {
      setContent(EditorState.createEmpty(Decorator));
      return;
    }
    setContent(
      EditorState.createEmpty(
        generateDecorator(BREAK_TIME_REGEX, BreakTimeHighlight),
      ),
    );
  };

  const handleCreateSynthesis = async () => {
    const { title, audioType, bitrate, backgroundMusic, volume } =
      synthesisRequest;
    const detailSelectedSentences = sentences.filter((item) =>
      selectedSentences.includes(item.id),
    );
    const synthesisSentences = selectedSentences?.length
      ? detailSelectedSentences
      : sentences;

    if (isSameToday(user?.createdAt))
      setTimeout(
        () =>
          dispatch(
            actions.banner.updateDisplayBanner(
              BANNER_TYPE.FIRST_BUY_PACKAGE_TRIAL,
              true,
            ),
          ),
        POPUP_TIME_OUT,
      );

    let synthesisPayload = { audioType, volume };
    if (advanceFeature) {
      synthesisPayload.bitrate = bitrate;
    } else {
      synthesisPayload.bitrate = bitrate;
    }

    if (title) synthesisPayload.title = title.trim();

    const hasBackgroundMusic =
      backgroundMusic && Object.keys(backgroundMusic).length;
    if (hasBackgroundMusic) {
      const { link, name, volume: bgVolume } = backgroundMusic;
      synthesisPayload.backgroundMusic = { link, name, volume: bgVolume };
    }

    if (!checkValidCreateSynthesis()) return;

    if (advanceFeature) {
      synthesisPayload.version = TTS_VERSION.EMPHASIS;
      const needCheckParagraphs = openSentences
        ? synthesisSentences
        : paragraphs;

      const { error, detailError } =
        checkSyntaxTagsParagraphs(needCheckParagraphs);
      if (error) {
        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('errorSyntax'),
          description: t('errorSyntaxDescription'),
          subDescription: t(detailError),
        });
        return;
      }
    }

    if (openSentences) {
      let sentencesPayload;

      if (advanceFeature) {
        sentencesPayload = newGetSentencesPayload(synthesisSentences);
        const invalidBreakTime = sentencesPayload.some((sentence) =>
          BREAK_TIME_REGEX.test(sentence.text),
        );
        if (invalidBreakTime) {
          setOpenWrongSyntaxDialog({
            name: 'error-syntax',
            variant: 'warning',
            title: t('errorSyntax'),
            description: t('errorSyntaxDescription'),
            subDescription: t('invalidBreakTime'),
          });
          return;
        }
      } else {
        sentencesPayload = getSentencesPayload(synthesisSentences);
        const hasZeroBreakTime = synthesisSentences.some((sentence) =>
          ZERO_BREAK_TIME_REGEX.test(sentence.text),
        );
        if (hasZeroBreakTime) {
          handleChangeOpenWrongBreakTimeDialog(true);
          return;
        }
      }
      const invalidTexts = sentencesPayload.reduce((acc, curr) => {
        const { text, voiceProvider } = curr;

        if (voiceProvider === VOICE_PROVIDER.VBEE) {
          const invalidSentenceText = text.match(VALID_CHARACTERS_LENGTH_REGEX);
          if (invalidSentenceText?.length) return invalidSentenceText;
          const isValidText = VALID_CHARACTERS_REGEX.test(text);
          if (!isValidText) return [text];
        }

        return acc;
      }, []);
      if (invalidTexts.length) {
        const invalidText = invalidTexts.join(', ');
        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('errorSyntax'),
          description: t('errorSyntaxDescription'),
          subDescription: t('invalidCharacter', { invalidText }),
        });
        const text = sentencesPayload
          .map((sentence) => sentence.text)
          .join('\n');
        apis.warning.sendWarningInvalidRequest({
          text,
          invalidTexts,
          type: WARNING_REQUEST_TYPE.CHARACTER_EXCEED,
        });

        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('sentenceHasBlackWords'),
          description: t('sentenceHasBlackWordsDescription'),
        });
        return;
      }
      // validate black words in sentences when open sentences
      const text = sentencesPayload.map((sentence) => sentence.text).join('\n');
      const plainText = text
        .replace(REGEX.ADVANCE_TAG, '')
        .replace(BREAK_TIME_REGEX, ' ')
        .replace(/  +/g, ' ');
      const blackWordsInText = blackWords.filter((blackWord) =>
        plainText.toLowerCase().includes(blackWord),
      );
      if (blackWordsInText.length > 0) {
        dispatch(
          actions.synthesisRequest.updateBlackWordsInText(blackWordsInText),
        );

        apis.warning.sendWarningInvalidRequest({
          text,
          invalidTexts: blackWordsInText,
          type: WARNING_REQUEST_TYPE.BLACK_WORD,
        });

        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('sentenceHasBlackWords'),
          description: t('sentenceHasBlackWordsDescription'),
        });
        return;
      }
      synthesisPayload = { ...synthesisPayload, sentences: sentencesPayload };
    } else {
      let text;

      if (advanceFeature) {
        text = convertParagraphsToText(paragraphs);
        if (BREAK_TIME_REGEX.test(text)) {
          setOpenWrongSyntaxDialog({
            name: 'error-syntax',
            variant: 'warning',
            title: t('errorSyntax'),
            description: t('errorSyntaxDescription'),
            subDescription: t('invalidBreakTime'),
          });
          return;
        }
      } else {
        text = content.getCurrentContent().getPlainText('\n');
        const hasZeroBreakTime = ZERO_BREAK_TIME_REGEX.test(text);
        if (hasZeroBreakTime) {
          handleChangeOpenWrongBreakTimeDialog(true);
          return;
        }
      }

      // validate blackWords
      const plainText = text
        .replace(REGEX.ADVANCE_TAG, '')
        .replace(BREAK_TIME_REGEX, ' ')
        .replace(/  +/g, ' ');
      const blackWordsInText = blackWords.filter((blackWord) =>
        plainText.toLowerCase().includes(blackWord),
      );
      const blackWordsInTextRegex = blackWordsInText.map((blackWord) =>
        blackWord.replaceAll(' ', ' +'),
      );
      if (blackWordsInText.length > 0) {
        const regex = new RegExp(blackWordsInTextRegex.join('|'), 'gi');
        const stateContent = EditorState.set(content, {
          decorator: new CompositeDecorator([
            {
              strategy: (contentBlock, callback) => {
                findWithRegexEditor(regex, contentBlock, callback);
              },
              component: BlackWordHighLight,
            },
            {
              strategy: getEntityStrategy('MUTABLE'),
              component: HightLightSpan,
            },
          ]),
        });
        handleChangeContent(stateContent);

        apis.warning.sendWarningInvalidRequest({
          text,
          invalidTexts: blackWordsInText,
          type: WARNING_REQUEST_TYPE.BLACK_WORD,
        });

        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('sentenceHasBlackWords'),
          description: t('sentenceHasBlackWordsDescription'),
        });
        return;
      }

      if (voice?.provider === VOICE_PROVIDER.VBEE) {
        const invalidTexts = text.match(VALID_CHARACTERS_LENGTH_REGEX) || [];
        const isValidText = VALID_CHARACTERS_REGEX.test(text);
        if (!isValidText) invalidTexts.push(text);

        if (invalidTexts?.length) {
          const invalidText = invalidTexts.join(', ');
          setOpenWrongSyntaxDialog({
            name: 'error-syntax',
            variant: 'warning',
            title: t('errorSyntax'),
            description: t('errorSyntaxDescription'),
            subDescription: t('invalidCharacter', { invalidText }),
          });
          apis.warning.sendWarningInvalidRequest({
            text,
            invalidTexts,
            type: WARNING_REQUEST_TYPE.CHARACTER_EXCEED,
          });
          return;
        }
      }

      if (isSSML) {
        if (checkValidXml(text)) {
          // TODO: map ssml to payload before call api
        } else setOpenSSMLDialog(true);
        return;
      }

      synthesisPayload = {
        ...synthesisPayload,
        text,
        paragraphs: advanceFeature && !openSentences ? paragraphs : undefined,
        voiceCode: voice?.code,
        speed: Number(synthesisRequest.speed),
      };
    }

    setLoading(false);

    window.dataLayer.push({
      event: EVENT_TYPE.STUDIO_CONVERT_TTS,
      userId: user?.id,
      email: user.email,
      requestType: 'requestTTS',
      voice: voice?.name,
      characters: inputLength,
      package: user.packageCode,
      remainingCharacters: user.remainingCharacters,
      packageExpiryDate: moment(user.packageExpiryDate).format(
        'DD/MM/YYY - HH:mm',
      ),
    });

    const options = {};
    const recaptchaTtsType = growthbook.getFeatureValue(
      FEATURE_KEYS.RECAPTCHA_TTS_TYPE,
    );

    let recaptchaToken;
    switch (recaptchaTtsType) {
      case RECAPTCHA_TYPE.V3:
        if (executeRecaptcha)
          recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION.SYNTHESIS);
        break;
      case RECAPTCHA_TYPE.ENTERPRISE:
        if (window.grecaptcha) {
          recaptchaToken = await window?.grecaptcha?.enterprise?.execute(
            RECAPTCHA_ENTERPRISE_SITE_KEY,
          );
        }
        break;
      default:
        break;
    }
    if (recaptchaToken) options.recaptchaToken = recaptchaToken;

    synthesisPayload.datasenses = datasenses.getDataSensesRequireFields();

    const data = await apis.synthesis.createSynthesis(
      synthesisPayload,
      options,
    );

    datasenses.sendMakeRequestEvent({
      userId: user.id,
      requestType: REQUEST_TYPE.STUDIO,
      voice: voice?.code,
      characters: inputLength,
      status: REQUEST_STATUS.IN_PROGRESS,
    });

    setLoading(false);
    if (data?.status) {
      handleChangeRequestLoading(true);
      document.getElementById('requests').scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    } else {
      const { errorMessage, errorCode } = data;

      if (errorCode === ERROR_CODE.INVALID_SYNTAX) {
        setOpenWrongSyntaxDialog({
          name: 'error-syntax',
          variant: 'warning',
          title: t('errorSyntax'),
          description: t('errorSyntaxDescription'),
        });
      } else {
        dispatch(
          actions.noti.push({
            severity: 'error',
            message:
              errorCode === ERROR_CODE.BAD_REQUEST
                ? 'errorOccurred'
                : 'synthesisFailure',
            value: errorMessage,
          }),
        );
      }
    }
  };

  const fetchVoicesByViLanguage = async () => {
    const data = await apis.voices.getVoices({
      languageCode: LANGUAGE_CODE.VIETNAMESE,
      active: true,
    });
    if (data.result) {
      const vietnameseVoices = data.result.voices;

      if (vietnameseVoices.length) {
        // setVoice(vietnameseVoices[0]);
        dispatch(actions.synthesisRequest.updateVoice(vietnameseVoices[0]));
      }
    }
  };

  const fetchVoicesByEnglishLanguage = async () => {
    const data = await apis.voices.getVoices({
      languageCode: ENGLISH_VOICE_CODES.toString(),
      active: true,
      sort: 'rank_asc',
    });
    if (data.result) {
      const englishVoices = data.result.voices;
      if (englishVoices.length) {
        // setVoice(englishVoices[0]);
        dispatch(actions.synthesisRequest.updateVoice(englishVoices[0]));
        dispatch(actions.voice.setDefaultGlobalVoice(englishVoices[0].code));
      }
    }
  };

  const fetchBlackWords = async () => {
    const data = await apis.blackWords.getBlackWords();
    if (data.result) {
      const blackWordsData = data.result.blackWords;
      setBlackWords(
        blackWordsData.map((blackWord) => blackWord.word?.toLowerCase()),
      );
    }
  };

  useEffect(() => {
    if (advanceFeature && !openSentences)
      setContent(EditorState.createEmpty(Decorator));
  }, [advanceFeature]);

  const validateDataDraftConfig = (data) => {
    if (!Object.keys(data).length) return false;
    if (!data?.text && !data?.paragraphs?.length && !data?.sentences?.length)
      return false;
    return true;
  };

  useEffect(() => {
    if (!useOldSaveDraftFeatureFlag) {
      const draftConfigSave = getDataLocalStorage(`dataDraft-${user.id}`) || {};
      const isValidDraftConfig = validateDataDraftConfig(draftConfigSave);
      if (isValidDraftConfig) {
        handleChangeCharacterExceed(!!draftConfigSave?.characterExceed);
        dispatch(actions.synthesisRequest.updateVoice(draftConfigSave.voice));
        dispatch(
          actions.synthesisRequest.updateSynthesisConfig(draftConfigSave),
        );
        dispatch(actions.synthesisRequest.updateLoadingSynthesisConfig(true));
        return;
      }
    }

    const isVietNam = checkVietNam();
    if (isVietNam) fetchVoicesByViLanguage();
    else fetchVoicesByEnglishLanguage();
    dispatch(
      actions.synthesisRequest.updateSynthesisConfig(DEFAULT_SYNTHESIS_REQUEST),
    );
  }, []);

  useEffect(() => {
    currentIsPlayingAudio.current = isPlaying;
  }, [isPlaying]);

  useEffect(() => {
    currentReqPreviewId.current = requestPreviewId;
  }, [requestPreviewId]);

  useEffect(() => {
    accessTokenRef.current = accessToken;
  }, [accessToken]);

  useEffect(() => {
    runStreamAudioRef.current = isRunStreamAudio;
  }, [isRunStreamAudio]);

  const connectGetRemainingPreviewWS = () => {
    ws.current = new WebSocket(TTS_SYNTHESIS_URL);
    let pingInterval;
    ws.current.onopen = () => {
      ws.current.send(
        JSON.stringify({
          type: WS_TYPE.INIT,
          accessToken: accessTokenRef.current,
        }),
      );
      pingInterval = setInterval(() => {
        ws.current.send(JSON.stringify({ type: WS_TYPE.PING }));
      }, PING_INTERVAL);
    };

    ws.current.onmessage = (res) => {
      const responseData = camelcaseKeys(JSON.parse(res.data), { deep: true });
      const { type, remainingPreview: newRemainingPreview } = responseData;

      if (type === WS_TYPE.INIT)
        ws.current.send(
          JSON.stringify({ type: WS_TYPE.GET_REMAINING_PREVIEW }),
        );

      if (type === WS_TYPE.GET_REMAINING_PREVIEW)
        setRemainingPreview(newRemainingPreview);
    };

    ws.current.onclose = (event) => {
      const { code, reason, wasClean } = event;
      // eslint-disable-next-line no-console
      console.log('Websocket is closed.', { event, code, reason, wasClean });
      clearInterval(pingInterval);
    };
  };

  const connectSynthesisWS = async () => {
    const recaptchaTryListeningTtsType = growthbook.getFeatureValue(
      FEATURE_KEYS.RECAPTCHA_TRY_LISTEN_TTS_TYPE,
    );

    let recaptchaToken;
    switch (recaptchaTryListeningTtsType) {
      case RECAPTCHA_TYPE.V3:
        if (executeRecaptcha)
          recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION.SYNTHESIS);
        break;
      case RECAPTCHA_TYPE.ENTERPRISE:
        if (window.grecaptcha) {
          recaptchaToken = await window?.grecaptcha?.enterprise?.execute(
            RECAPTCHA_ENTERPRISE_SITE_KEY,
          );
        }
        break;
      default:
        break;
    }

    ws.current = new WebSocket(TTS_SYNTHESIS_URL);
    let pingInterval;
    ws.current.onopen = () => {
      ws.current.send(
        JSON.stringify({
          type: WS_TYPE.INIT,
          accessToken: accessTokenRef.current,
        }),
      );
      pingInterval = setInterval(() => {
        ws.current.send(JSON.stringify({ type: WS_TYPE.PING }));
      }, PING_INTERVAL);
    };

    const handleTryListeningError = () => {
      const failurePayload = { sentenceId: '', isAudioLoading: false };
      dispatch(actions.audioPlayer.updateTryListeningSentence(failurePayload));
      dispatch(
        actions.noti.push({
          severity: 'error',
          message: 'listeningError',
        }),
      );
      dispatch(actions.audioPlayer.updateRequestPreviewId(''));
    };

    ws.current.onmessage = (res) => {
      const responseData = camelcaseKeys(JSON.parse(res.data), { deep: true });
      const {
        type,
        result,
        status,
        remainingPreview: newRemainingPreview,
      } = responseData;

      if (status === 0) handleTryListeningError();
      if (type === WS_TYPE.INIT) {
        ws.current.send(
          JSON.stringify({
            type: WS_TYPE.SYNTHESIS,
            payload: previewPayload,
            accessToken: accessTokenRef.current,
            recaptchaToken,
          }),
        );

        datasenses.sendPreviewEvent({
          userId: user.id,
          requestType: REQUEST_TYPE.STUDIO,
          voice: previewPayload.voiceCode,
          characters: previewPayload.text?.length || 0,
          status: REQUEST_STATUS.IN_PROGRESS,
        });
      }
      if (type === WS_TYPE.SYNTHESIS) {
        switch (result && result.status) {
          case REQUEST_STATUS.IN_PROGRESS: {
            dispatch(
              actions.audioPlayer.updateRequestPreviewId(result.requestId),
            );
            break;
          }
          case REQUEST_STATUS.SUCCESS: {
            const getPublicAudioLink = async (requestId) => {
              const presignedAudioUrlResponse =
                await apis.requests.getPresignedAudioUrl(requestId);

              if (presignedAudioUrlResponse.status) {
                return presignedAudioUrlResponse.result.audio;
              }

              return null;
            };

            const playTryListeningAudio = async () => {
              if (
                runStreamAudioRef.current &&
                checkFeaturePermission(
                  ttsUser.features,
                  FEATURE.STREAMING_PREVIEW,
                )
              ) {
                if (useNewTTSDemo) {
                  dispatch(
                    actions.audioPlayer.updateSelectedAudioRequest(
                      result?.requestId,
                    ),
                  );
                }
                dispatch(
                  actions.audioPlayer.updateFinalStreamAudio(result?.audioLink),
                );
                return;
              }

              ws.current.send(
                JSON.stringify({ type: WS_TYPE.GET_REMAINING_PREVIEW }),
              );
              if (currentReqPreviewId.current !== result.requestId) return;
              if (
                currentIsPlayingAudio.current &&
                playAudioType === PLAYING_AUDIO_TYPE.SYNTHESIS
              ) {
                const cancelPayload = { sentenceId: '', isAudioLoading: false };
                dispatch(
                  actions.audioPlayer.updateTryListeningSentence(cancelPayload),
                );
              } else {
                const publicAudioLink = useNewTTSDemo
                  ? await getPublicAudioLink(result?.requestId)
                  : null;

                const audioLink = publicAudioLink || result?.audioLink;

                dispatch(actions.audioPlayer.updateAudioLink(audioLink, true));
                dispatch(actions.audioPlayer.updateSelectedAudioRequest(''));
                dispatch(
                  actions.audioPlayer.updateAudioType(
                    PLAYING_AUDIO_TYPE.SYNTHESIS,
                  ),
                );
                dispatch(
                  actions.audioPlayer.updateTryListeningSentence({ audioLink }),
                );
                dispatch(actions.audioPlayer.updateStatus(true));
              }
              dispatch(actions.audioPlayer.updateRequestPreviewId(''));
            };

            playTryListeningAudio();
            break;
          }
          case REQUEST_STATUS.FAILURE: {
            handleTryListeningError();
            break;
          }
          default:
            break;
        }
      }

      if (type === WS_TYPE.STREAM_REQUEST) {
        if (
          checkFeaturePermission(ttsUser.features, FEATURE.STREAMING_PREVIEW) &&
          isUseStreamingPreview
        ) {
          const { index, subIndex, audioLink, phrases, tts } = result;
          if (index !== undefined) {
            dispatch(
              actions.audioPlayer.updateStreamAudios({
                index,
                subIndex,
                audioLink,
                phrases,
                tts,
              }),
            );
          }
          if (index === 0 && subIndex === 0) {
            dispatch(actions.audioPlayer.updateStatusStreamAudio(true));
            dispatch(
              actions.audioPlayer.updateTryListeningSentence({
                isAudioLoading: false,
              }),
            );
          }
        }
      }

      if (type === WS_TYPE.GET_REMAINING_PREVIEW)
        setRemainingPreview(newRemainingPreview);
    };

    ws.current.onclose = (event) => {
      const { code, reason, wasClean } = event;
      // eslint-disable-next-line no-console
      console.log('Websocket is closed', { code, reason, wasClean });
      clearInterval(pingInterval);
    };
  };

  // Show dialog block download cause user already download
  const { showBlockDownloadDialog } = useSelector((state) => state.user);
  useEffect(() => {
    if (showBlockDownloadDialog) {
      setActionDialog({
        image: UpgradeImg,
        title: t('unableToDownload'),
        description: t('unableToDownloadDescription', { numberDownload: 1 }),
        onClose: () => {
          dispatch(actions.user.updateShowBlockDownloadDialog(false));
          handleCloseActionDialog();
        },
        actionComponents: renderUpgradeButton(),
      });
    }
  }, [showBlockDownloadDialog]);

  useEffect(() => {
    connectGetRemainingPreviewWS();
  }, []);

  useEffect(() => {
    if (isAudioLoading) {
      ws?.current?.close();
      connectSynthesisWS();
    }
  }, [isAudioLoading]);

  useEffect(() => {
    fetchBlackWords();
  }, []);

  const renderCloseButton = (handleClose) => (
    <Button variant="contained" onClick={handleClose}>
      {t('understood')}
    </Button>
  );

  useEffect(() => {
    const doneTourGuide = localStorage.getItem(TTS_TOUR_GUIDE);
    if (!doneTourGuide && seenAllBanner && hasAgreedToTerms) {
      handleChangeRunTourGuide(true);
      localStorage.setItem(TTS_TOUR_GUIDE, true);
    }
  }, [seenAllBanner, hasAgreedToTerms]);

  useEffect(() => {
    if (limitedFeature) {
      setActionDialog({
        image: UpgradeImg,
        title: t('limitedFeature'),
        description: t('limitedFeatureDescription'),
        onClose: handleCloseLimitedFeatureDialog,
        actionComponents: renderUpgradeButton(),
      });
      dispatch(actions.dialog.displayDialog({ limitedFeature: false }));
    }
  }, [limitedFeature]);

  return (
    <StyledTTS>
      {/* Point to get Element scroll to top of the page */}
      <div id="scroll-point-top" className="scroll-point-top" />

      <div className="tts" id="request-editor">
        <Card flexDirection="column" padding="16px">
          <TitleBar
            loading={loading}
            onCreateSynthesis={handleCreateSynthesis}
            characterExceed={characterExceed}
            inputLength={inputLength}
            isLoadDraft={loadDraft}
            onLoadEditContent={handleLoadContentEditor}
            onChangeInputLength={handleChangeInputLength}
            onOpenConfirmDialog={handleOpenDraft}
            content={content}
          />
          {/* TODO: Delete SSML */}
          <Toolbar
            content={content}
            openSentences={openSentences}
            toolbarRef={toolbarRef}
            isSSML={isSSML}
            onOpenVoice={handleOpenVoices}
            handleChangeIsSSML={handleChangeIsSSML}
            handleChangeContent={handleChangeContent}
            onChangeInputLength={handleChangeInputLength}
            onChangeCharacterExceed={handleChangeCharacterExceed}
            onOpenSentences={handleOpenSentences}
            synthesisSentence={synthesisSentence}
            onTryListeningSentence={checkConditionPackageToPreview}
            audioRef={audioRef}
          />
          <Content
            openSentences={openSentences}
            content={content}
            toolbarRef={toolbarRef}
            characterExceed={characterExceed}
            onChangeContent={handleChangeContent}
            onChangeInputLength={handleChangeInputLength}
            onChangeCharacterExceed={handleChangeCharacterExceed}
            onchangeSynthesisSentence={handleChangeSynthesisSentence}
            onTryListeningSentence={checkConditionPackageToPreview}
            onChangeVoice={handleChangeVoice}
            audioRef={audioRef}
            synthesisSentence={synthesisSentence}
          />
          {/* <Divider /> */}
          <ConvertAction
            synthesisRequest={synthesisRequest}
            synthesisSentence={synthesisSentence}
            openSentences={openSentences}
            onTryListeningSentence={checkConditionPackageToPreview}
            audioRef={audioRef}
            onResetContentEditor={handleResetContentEditor}
            inputLength={inputLength}
          />
          <VoicesDialog
            open={openVoices}
            activeVoiceId={voice && voice.id}
            onClose={handleCloseVoices}
            onChangeVoice={handleChangeVoice}
          />
        </Card>
        {useNewTTSDemo ? (
          <TTSDemo audioRef={audioRef} />
        ) : (
          <OldTTSDemo audioRef={audioRef} />
        )}
        {/* <div className="scroll-wrapper">
          <Typography
            variant="subtitle2"
            color="error"
            className="opacity-none"
          >
            *{t('note')}: {t('audioStorageNote')}
          </Typography>
          <div className="align-center">
            <Button
              variant="contained"
              className="transition-btn"
              onClick={() => handleOpenRequest(true)}
            >
              {t('ttsHistory')} <img src={arrowIconDown} alt="icon" />
            </Button>
          </div>
        </div> */}
      </div>
      <div className="request" id="requests">
        <Requests
          // openRequest={openRequest}
          requestLoading={requestLoading}
          // handleOpenRequest={handleOpenRequest}
          onChangeRequestLoading={handleChangeRequestLoading}
          audioRef={audioRef}
        />
      </div>
      {useOldSaveDraftFeatureFlag && (
        <ConfirmDialog
          title={t('note')}
          content={t('reloadPreviousWorkContent')}
          open={openDraft}
          onClose={handleCloseDraft}
          onConfirm={handleLoadDraft}
        />
      )}

      <ConfirmDialog
        title={t('confirmSwitchProcessingMethod')}
        content={t('switchProcessingMethodNote')}
        open={confirmSwitchParagraph}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleAcceptConfirmDialog}
      />

      <NotificationDialog
        name="ssml"
        title={t('convertTTSBySSML')}
        description={t('inValidSSML')}
        variant="warning"
        open={openSSMLDialog}
        onClose={handleCloseSSMLDialog}
        actionComponent={renderCloseButton(handleCloseSSMLDialog)}
      />

      <NotificationDialog
        name="exceed characters"
        title={t('exceedCharacters')}
        description={t('exceedCharactersDescription', { maxLengthInputText })}
        variant="warning"
        open={openExceedCharactersDialog}
        onClose={handleCloseExceedCharactersDialog}
        actionComponent={renderCloseButton(handleCloseExceedCharactersDialog)}
      />

      {runTourGuide && (
        <TourGuide
          stepsGuide={stepsGuide}
          run={runTourGuide}
          onChangeRunTourGuide={handleChangeRunTourGuide}
          stepDisplay={false}
        />
      )}

      <NotificationDialog
        name="break-time"
        title={t('errorSyntax')}
        description={t('errorInvalidBreakTimeDesc')}
        variant="warning"
        open={openWrongBreakTimeDialog}
        onClose={() => handleChangeOpenWrongBreakTimeDialog(false)}
        actionComponent={renderCloseButton(() =>
          handleChangeOpenWrongBreakTimeDialog(false),
        )}
      />

      <NotificationDialog
        name={openWrongSyntaxDialog?.name}
        title={openWrongSyntaxDialog?.title}
        description={openWrongSyntaxDialog?.description}
        subDescription={openWrongSyntaxDialog?.subDescription}
        variant={openWrongSyntaxDialog?.variant}
        open={!!openWrongSyntaxDialog?.title}
        onClose={() => setOpenWrongSyntaxDialog(null)}
        actionComponent={renderCloseButton(() =>
          setOpenWrongSyntaxDialog(null),
        )}
      />

      {actionDialog?.title && (
        <ActionDialog
          image={actionDialog?.image}
          open={!!actionDialog?.title}
          title={actionDialog?.title}
          description={actionDialog?.description}
          onClose={actionDialog?.onClose}
          actionComponents={actionDialog?.actionComponents}
        />
      )}
    </StyledTTS>
  );
};

export default TTS;
