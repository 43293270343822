import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledSubscription = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 36px;
  gap: 0 16px;

  .header-payment {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .title {
      font-size: 24px;
      font-weight: 700;
      color: ${COLOR.indigo.default};
      line-height: 28px;
      text-transform: uppercase;
    }
  }

  .using-package-list {
    display: grid;
    gap: 16px;
    width: 100%;
    grid-template-rows: auto;
  }

  @media (max-width: 900px) {
    .using-package-list {
      grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
  }

  @media (min-width: 900px) and (max-width: 1500px) {
    .using-package-list {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
  }

  @media (min-width: 1500px) {
    .using-package-list {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }
  }
`;

export const StyledSubscriptionItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border-radius: 16px;
  background-color: ${COLOR.white};
  height: calc(100% - 32px);

  .package-name {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid ${COLOR.label[16]};
    height: 50px;
    gap: 8px;

    .logo {
      height: 40px;
    }

    .package-name-text {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: ${COLOR.black[80]};
      }

      .auto-renewal {
        display: flex;
        width: fit-content;
        padding: 4px 10px;
        border-radius: 16px;
        background-color: ${COLOR.indigo[8]};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: ${COLOR.indigo[100]};
      }
    }
  }

  .margin-bottom-8 {
    margin-bottom: 8px !important;
  }

  .margin-right-2 {
    margin-right: 2px !important;
  }

  .text-16 {
    font-size: 16px !important;
  }

  .underline {
    text-decoration: underline;
  }

  .package-info-item {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .expire-date {
      color: ${COLOR.red} !important;
    }

    .font-weight-400 {
      font-weight: 400 !important;
    }

    .label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${COLOR.black[80]};
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${COLOR.black[80]};
    }

    .discount-unit-price {
      font-size: 14px;
      font-weight: 400;
      color: ${COLOR.black[80]};
      text-transform: lowercase;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  .cancel-auto-renewal-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 12px;
    text-transform: none;
    color: ${COLOR.indigo.default};
    border: 1px solid ${COLOR.indigo[32]};
    width: 100%;
  }

  .buy-more-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 12px;
    text-transform: none;
    background-color: ${COLOR.yellow.default};
    width: 100%;
  }

  .collapse {
    ${(props) =>
      props.showdetail && {
        borderBottom: `1px solid ${COLOR.label[16]}`,
        marginTop: '16px',
      }}
  }
`;
