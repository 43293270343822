import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography, IconButton } from '@mui/material';
import { Cached } from '@mui/icons-material';
import i18n from '@src/languages';
import { LANGUAGE } from '@src/constants';
import {
  GENDER,
  VOICE_FEATURE,
  VOICE_LEVEL,
  VOICE_LIBRARY,
  VOICE_OWNERSHIP,
  // VOICE_DOMAIN,
  // VOICE_TONE,
  // VOICE_FEATURE,
} from '@src/constants/voice';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import Search from './Search';
import { StyledVoicesSearch } from './index.style';
import SearchRadio from './SearchRadio';

const VoicesFilter = ({
  filterOpens,
  onChangeFilterOpens,
  filter = {},
  hasLanguageFilter = true,
  onChangeFilter,
  onHandleReset,
}) => {
  const { getFeatureValue } = useFeatureFlags();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(LANGUAGE.VN);

  const { advanceFeature } = useSelector((state) => state.synthesisRequest);
  const { languages } = useSelector((state) => state.language);
  const { user } = useSelector((state) => state.auth);

  const allowFilterByVoiceLibrary = getFeatureValue(
    FEATURE_KEYS.FILTER_BY_VOICE_LIBRARY,
    {
      userId: user.id,
      email: user.email,
      phoneNumber: user.phoneNumber,
      screenWidth: window.innerWidth,
    },
  );

  useEffect(() => {
    if (i18n?.language === LANGUAGE.VN) setLanguage(LANGUAGE.VN);
    else setLanguage(LANGUAGE.EN);
  }, [i18n?.language]);

  return (
    <StyledVoicesSearch>
      <div className="header">
        <Typography variant="subtitle1" className="title">
          {t('quickVoiceFilter')}
        </Typography>
        <Tooltip title={t('reset')}>
          <IconButton onClick={onHandleReset}>
            <Cached />
          </IconButton>
        </Tooltip>
      </div>
      {allowFilterByVoiceLibrary && (
        <SearchRadio
          search={filter}
          value={filter.voiceOwnership || VOICE_OWNERSHIP.VBEE}
          options={VOICE_LIBRARY}
          name="voiceOwnership"
          open={filterOpens.voiceOwnership}
          onChange={onChangeFilter}
          onChangeOpen={onChangeFilterOpens}
        />
      )}
      <Search
        search={filter}
        value={filter.gender || []}
        options={GENDER}
        name="gender"
        open={filterOpens.gender}
        onChange={onChangeFilter}
        onChangeOpen={onChangeFilterOpens}
      />
      {advanceFeature && (
        <Search
          search={filter}
          value={filter.features || []}
          options={Object.values(VOICE_FEATURE).map((feature) => ({
            value: feature,
            label: feature,
          }))}
          title="effect"
          name="features"
          open={filterOpens.features}
          onChange={onChangeFilter}
          onChangeOpen={onChangeFilterOpens}
        />
      )}
      <Search
        search={filter}
        value={filter.level || []}
        options={Object.values(VOICE_LEVEL).map((level) => ({
          value: level,
          label: `${level.toLowerCase()}`,
        }))}
        title="voiceLevel"
        name="level"
        open={filterOpens.level}
        onChange={onChangeFilter}
        onChangeOpen={onChangeFilterOpens}
      />
      {/* <Search
        search={filter}
        value={filter.tone}
        options={VOICE_TONE}
        name="tone"
        onChange={handleChangeFilter}
      />
      <Search
        search={filter}
        value={filter.domain}
        options={VOICE_DOMAIN}
        name="domain"
        onChange={handleChangeFilter}
      />
      <Search
        search={filter}
        value={filter.feature}
        options={VOICE_FEATURE}
        name="feature"
        onChange={handleChangeFilter}
      /> */}
      {hasLanguageFilter && (
        <Search
          search={filter}
          value={filter.languageCode || []}
          options={languages.map((item) => ({
            ...item,
            label: item.label[language] || item.label[LANGUAGE.EN],
          }))}
          name="languageCode"
          title="language"
          open={filterOpens.languageCode}
          onChange={onChangeFilter}
          onChangeOpen={onChangeFilterOpens}
        />
      )}
    </StyledVoicesSearch>
  );
};

export default VoicesFilter;
