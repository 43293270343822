import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { StyledDialog } from './index.style';

import Rating from './Rating';

const RatingPopUp = ({ open, onClose, setShowRatingPopUpCookie }) => (
  <StyledDialog open={open} onClose={onClose}>
    <div className="dialog-wrapper">
      <div className="close-button">
        <IconButton aria-label="close" onClick={onClose} color="secondary">
          <Close color="secondary" />
        </IconButton>
      </div>
      <Rating
        onClose={onClose}
        setShowRatingPopUpCookie={setShowRatingPopUpCookie}
      />
    </div>
  </StyledDialog>
);

export default RatingPopUp;
