import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import ROUTES from '@src/constants/route';
import StarsIcon from '@mui/icons-material/Stars';
import { Button } from '@mui/material';
import upgradePromptImg from '@src/assets/images/upgrade-prompt.svg';
import { StyledUpgradePrompt } from './index.style';

const UpgradePrompt = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRedirectPayment = () => history.push(ROUTES.PAYMENT);

  return (
    <StyledUpgradePrompt>
      <img src={upgradePromptImg} alt="coming soon" />
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(t('upgradePromptTitle')),
        }}
        className="upgrade-prompt-title "
      />
      <Button
        onClick={handleRedirectPayment}
        variant="contained"
        className="upgrade-button"
      >
        <StarsIcon />
        {t('upgradeNow')}
      </Button>
    </StyledUpgradePrompt>
  );
};

export default UpgradePrompt;
