import {
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  Popper,
} from '@mui/material';
import { Code, HelpRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import vbeeGrayIcon from '@src/assets/icons/vbee-icon-gray.svg';
import vbeeWhiteIcon from '@src/assets/icons/vbee-icon-white.svg';
import Feature from '@src/components/Feature';
import FEATURE_FLAG from '@src/constants/featureFlags.json';
import ROUTES from '@src/constants/route';
import { VBEE_CONSOLE_URL } from '@src/configs';
import { SIDEBAR_BOX_SHADOW } from '@src/styles/config';
import IconArrowRight from '@src/assets/icons/arrow-right.svg';
import smallLogoAiVoice from '@src/assets/logo/small-logo-AiVoice-new.svg';
import PopoverCreateNewProject from '@src/components/PopoverCreateNewProject';

import SidebarImgIcon from '../../Icon';
import { StyledSidebarNew, StyledSidebarTooltip } from './index.style';
import UserInfo from '../UserInfo';
import VoucherAds from '../VoucherAds';

const SmallSidebar = ({
  createLinks,
  sidebarRoute,
  activeRoute,
  ipLocation,
  isExpiredPackage,
  handleCloseSidebar,
  renderSubMenuSupport,
  handleOpenSidebar,
  vouchers,
  showAutoRenewalBanner,
  isShowingApiRedirect,
  useHeaderBanner,
  headerVoucher,
}) => {
  const { t } = useTranslation();
  const [supportMenuAnchor, setSupportMenuAnchor] = useState(null);

  const renderSmallSideBarItems = (props) => (
    <div className="sidebar-wrapper hidden-scrollbar">
      <PopoverCreateNewProject createLinks={createLinks} isSmall />
      <List className="list" style={{ paddingBottom: '0px' }}>
        {createLinks(sidebarRoute, props)}
      </List>
      <StyledSidebarTooltip disableTouchListener title={t('support')}>
        <Box
          className={`item-link ${Boolean(supportMenuAnchor) && 'active-link'}`}
          onClick={(e) =>
            supportMenuAnchor
              ? setSupportMenuAnchor(null)
              : setSupportMenuAnchor(e.currentTarget)
          }
        >
          <HelpRounded className="item-icon" />
        </Box>
      </StyledSidebarTooltip>

      <Feature name={FEATURE_FLAG.OTHER_SERVICES}>
        <NavLink
          to={ROUTES.OTHER_SERVICES}
          className={classNames('item-link', {
            'active-link': activeRoute(ROUTES.OTHER_SERVICES),
          })}
          onClick={() => {
            if (props?.mobile) handleCloseSidebar();
          }}
        >
          <SidebarImgIcon
            src={
              activeRoute(ROUTES.OTHER_SERVICES) ? vbeeWhiteIcon : vbeeGrayIcon
            }
          />
        </NavLink>
      </Feature>
      {isShowingApiRedirect ? (
        <StyledSidebarTooltip title={t('api')}>
          <a
            href={VBEE_CONSOLE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="item-link"
            onClick={() => {
              if (props?.mobile) handleCloseSidebar();
            }}
          >
            <Code className="item-icon" />
          </a>
        </StyledSidebarTooltip>
      ) : null}
      {supportMenuAnchor && (
        <ClickAwayListener onClickAway={() => setSupportMenuAnchor(null)}>
          <Popper
            open={Boolean(supportMenuAnchor)}
            anchorEl={supportMenuAnchor}
            onClose={() => setSupportMenuAnchor(null)}
            placement="right"
            style={{
              zIndex: 1299,
              boxShadow: SIDEBAR_BOX_SHADOW,
              borderRadius: 12,
              overflow: 'hidden',
            }}
            modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]}
          >
            {renderSubMenuSupport({
              props,
              className: 'small-sub-menu-wrapper',
              itemClassName: 'small-sub-menu-item',
            })}
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
  return (
    <StyledSidebarNew
      useHeaderBanner={useHeaderBanner}
      headerVoucher={headerVoucher}
    >
      <div className="brand">
        <img
          src={smallLogoAiVoice}
          alt="vbee tts logo"
          className="logo-img"
          width={108}
        />
      </div>
      <Divider />
      {renderSmallSideBarItems()}
      <VoucherAds
        handleOpenSidebar={handleOpenSidebar}
        isSmallSidebar
        isExpiredPackage={isExpiredPackage}
        showAutoRenewalBanner={showAutoRenewalBanner}
        vouchers={vouchers}
      />
      <Divider />
      <Box className="footer-sidebar">
        <UserInfo smallSidebar ipLocation={ipLocation} />
        <StyledSidebarTooltip title={t('expand')} placement="right">
          <IconButton color="iconPrimary" onClick={handleOpenSidebar}>
            <img src={IconArrowRight} alt="icon-arrow" />
          </IconButton>
        </StyledSidebarTooltip>
      </Box>
    </StyledSidebarNew>
  );
};

export default SmallSidebar;
