import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ROUTES from '@src/constants/route';
import useFeatureFlags from '@src/hooks/useFeatureFlags';
import { InfoRounded } from '@mui/icons-material';
import AdsBackground from '@src/assets/banners/ads.png';
import AdsBackground1 from '@src/assets/banners/ads-01.svg';
import { getPackagesName } from '@src/services/package';
import { useCheckDubbingRoute } from '@src/hooks/useCheckDubbingRoute';
import { FEATURE_KEYS } from '@src/configs/featureKeys';
import {
  StyledAdsItem,
  StyledSidebarTooltip,
  StyledVoucherBanner,
} from './index.style';
import CountdownBanner from './CountdownBanner';

export const VOUCHER_BANNER_TEMPLATE = {
  STANDARD: 'voucher',
  FIRST_PAID_VOUCHER: 'firstPaidVoucher',
};

const bannerStyles = {
  [VOUCHER_BANNER_TEMPLATE.STANDARD]: {
    backgroundImg: AdsBackground,
    showCountdown: true,
  },
  [VOUCHER_BANNER_TEMPLATE.FIRST_PAID_VOUCHER]: {
    backgroundImg: AdsBackground1,
    showCountdown: false,
  },
};

const VoucherBanner = ({
  voucher = {},
  template = VOUCHER_BANNER_TEMPLATE.STANDARD,
}) => {
  const { i18n } = useTranslation();
  const { t, language } = i18n;
  const history = useHistory();
  const isDubbingRoute = useCheckDubbingRoute();
  const { getFeatureValue } = useFeatureFlags();
  const newVoucherBanner = getFeatureValue(FEATURE_KEYS.NEW_VOUCHER_BANNER);

  const useFeatureFakeEndTime = getFeatureValue(
    FEATURE_KEYS.FAKE_END_TIME_VOUCHER,
  );
  const { policy = {} } = voucher;
  const { applyAllPackages, packages = [] } = policy;

  const { backgroundImg, showCountdown } = bannerStyles[template];

  const handleRedirectPayment = (e) => {
    e?.target?.blur();
    return isDubbingRoute
      ? history.push(ROUTES.PAYMENT_DUBBING)
      : history.push(ROUTES.PAYMENT);
  };

  const TooltipTitle = () => (
    <Box display="flex" justifyContent="center" paddingX="-2px">
      <Typography
        className="tooltip-title"
        dangerouslySetInnerHTML={{
          __html: t('applyVoucherWithPackages', {
            packageNames: getPackagesName(packages, language, t),
          }),
        }}
        fontSize="14px"
        lineHeight="20px"
        letterSpacing="-0.4px"
        fontWeight="400"
      />
    </Box>
  );

  const renderTitle = () => (
    <Box className="title-wrapper">
      {!applyAllPackages && (
        <div className="tooltip-wrapper">
          <StyledSidebarTooltip title={<TooltipTitle />}>
            <InfoRounded color="white" className="icon" fontSize="16px" />
          </StyledSidebarTooltip>
        </div>
      )}
      {newVoucherBanner ? (
        <Typography
          className="title"
          dangerouslySetInnerHTML={{
            __html: t(voucher.title?.[language]).slice(0, 31),
          }}
        />
      ) : (
        <Typography
          className="title"
          dangerouslySetInnerHTML={{
            __html: t(voucher.description),
          }}
        />
      )}
    </Box>
  );

  const renderActionButton = () => {
    if (showCountdown)
      return (
        <div className="button-wrapper">
          <Button className="button" onClick={handleRedirectPayment}>
            {t('still')}
            <CountdownBanner
              date={
                useFeatureFakeEndTime && voucher.fakeEndTimeForVoucher
                  ? new Date(voucher.fakeEndTimeForVoucher).getTime() + 100
                  : new Date(voucher?.endTime).getTime() + 100
              }
            />
            {`- ${t('renewNow')}`}
          </Button>
        </div>
      );
    return (
      <div className="button-wrapper">
        <Button
          className="button button-highlight"
          onClick={handleRedirectPayment}
        >
          {t('buyNow')}
        </Button>
      </div>
    );
  };

  return (
    <StyledAdsItem>
      <StyledVoucherBanner
        voucherImg={backgroundImg}
        applyAllPackages={applyAllPackages}
      >
        {renderTitle()}
        {renderActionButton()}
      </StyledVoucherBanner>
    </StyledAdsItem>
  );
};

export default VoucherBanner;
