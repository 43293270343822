import api from '@src/apis/api';
import { ASR_URL } from '@src/configs';

export const recognizeSpeechToText = async (payload) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${ASR_URL}/api/v1/stt`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error.response?.data;
  }
};
