export const VALID_SPEED = {
  min: 0.25,
  max: 1.9,
};

export const TTS_VERSION = { NORMAL: '1.0', EMPHASIS: '1.1' };

export const VIETNAMESE_LETTERS =
  'ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝÝỶỸửữựỳýỵỷỹ';

export const VALID_LETTERS =
  'aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ';
export const NUMBERS = '0123456789';

export const MAX_CHARACTER_LENGTH = 30;

export const VALID_CHARACTERS_LENGTH_REGEX = new RegExp(
  `([a-zA-Z${VIETNAMESE_LETTERS}]|[0-9]){${MAX_CHARACTER_LENGTH},}`,
  'gi',
);

export const VALID_CHARACTERS_REGEX = new RegExp(
  `[${VALID_LETTERS}${NUMBERS}]`,
);

export const CLIENT_PAUSE_VALUE_RANGE = 0.1;

export const VALID_CLIENT_PAUSE = {
  min: 0.1,
  max: 10,
};

export const DEFAULT_CLIENT_PAUSE = {
  sentenceBreak: 0.45,
  mediumBreak: 0.25,
  majorBreak: 0.3,
  paragraphBreak: 0.6,
};

export const PLAYING_AUDIO_TYPE = {
  SILENCE: 'SILENCE',
  SYNTHESIS: 'SYNTHESIS',
  REQUEST: 'REQUEST',
  BACKGROUND_MUSIC: 'BACKGROUND_MUSIC',
};

export const SILENCE_AUDIO_URL = '/audios/silence.mp3';

export const VOICE_VERSION = {
  NORMAL: '1.0',
  ADVANCE: '1.1',
};

export const TTS_BOX_SIZE = {
  DEFAULT: '356px',
  EXPANDED: '80vh',
};

export const LOCAL_STORAGE_EXPIRES_TIME = 24 * 60 * 60 * 1000;

export const SET_TIME_AUTO_SAVE = 1 * 1000;

export const TIME_DELAY_TRANSFER_BUTTON = 2000;

export const DOWNLOAD_QUOTA_EXCEEDED_CODE = 1031;

export const INPUT_TTS_TYPE = {
  TEXT: 'TEXT',
  FILE: 'FILE',
  LINK: 'LINK',
  SRT: 'SRT',
};
