import React from 'react';
import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CountdownBanner from '@src/containers/Layout/SidebarNew/CountdownBanner';
import ROUTES from '@src/constants/route';
import { useCheckProductName } from '@src/hooks/useCheckProduct';
import { PACKAGE_TYPE } from '@src/constants/package';
import { OtherVoucherBannerContainer } from './index.style';

const OtherVoucherBanner = ({ voucher }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const product = useCheckProductName();
  const endTime = voucher?.fakeEndTimeForVoucher || voucher?.endTime;

  const handleRedirectPayment = () => {
    const { policy } = voucher;
    if (policy?.applyAllPackages) {
      history.push(ROUTES.PAYMENT);
      return;
    }

    const { packages } = policy;
    const isAllDubbing = packages.every((pkg) =>
      pkg.code.includes(PACKAGE_TYPE.DUBBING),
    );
    if (isAllDubbing) {
      history.push(ROUTES.PAYMENT_DUBBING);
      return;
    }

    const checkDubbingExist = packages.some((pkg) =>
      pkg.code.includes(PACKAGE_TYPE.DUBBING),
    );
    if (product === PACKAGE_TYPE.DUBBING && checkDubbingExist) {
      history.push(ROUTES.PAYMENT_DUBBING);
      return;
    }

    history.push(ROUTES.PAYMENT);
  };

  return (
    <OtherVoucherBannerContainer>
      <div className="banner-item">
        <div className="header">
          <Typography className="title">{voucher?.title?.vi}</Typography>
          <div className="description">
            {t('dealsEndIn')}:{' '}
            <CountdownBanner
              isHeaderBanner
              date={new Date(endTime).getTime() + 100}
            />
          </div>
        </div>
        <div className="button-wrapper">
          <Button className="button" onClick={handleRedirectPayment}>
            {t('buyNow')}
          </Button>
        </div>
      </div>
    </OtherVoucherBannerContainer>
  );
};

export default OtherVoucherBanner;
