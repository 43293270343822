import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import route from '@src/constants/route';
import { PACKAGE_EXPIRED } from '@src/constants/package';
import { twoDigitNumber } from '@src/utils/number';
import i18n from '@src/languages';
import { LANGUAGE } from '@src/constants';
import { StyledDubbingGiftDialog } from './index.style';
import aivoiceDubbingViIcon from '../../../assets/logo/new-logo-dubbing-vi.svg';
import aivoiceDubbingEnIcon from '../../../assets/logo/logo-dubbing.svg';

const DubbingGiftBanner = ({
  open = true,
  onClose,
  dubbingGiftOrder,
  expiryIn,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const [giftDuration, setGiftDuration] = useState(0);
  const [giftUnit, setGiftUnit] = useState(t('days'));
  const history = useHistory();

  const handleClose = () => {
    onClose();
  };

  const handleRegisterNow = () => {
    history.push(route.DUBBING);
    onClose();
  };

  const handleGetGiftDuration = () => {
    if (expiryIn < PACKAGE_EXPIRED.MONTHLY) {
      setGiftDuration(twoDigitNumber(expiryIn));
      setGiftUnit(t('days'));
    } else if (expiryIn < PACKAGE_EXPIRED.YEARLY) {
      setGiftDuration(twoDigitNumber(expiryIn / PACKAGE_EXPIRED.MONTHLY));
      setGiftUnit(t('month'));
    } else {
      setGiftDuration(twoDigitNumber(expiryIn / PACKAGE_EXPIRED.YEARLY));
      setGiftUnit(t('year'));
    }
  };

  useEffect(() => {
    handleGetGiftDuration();
  }, [expiryIn]);

  return (
    <StyledDubbingGiftDialog classes={{ paper: 'dialog' }} open={open}>
      <DialogContent className="dialog-content">
        <IconButton
          aria-label="close"
          color="secondary"
          className="close-button"
          onClick={handleClose}
        >
          <Close color="white" />
        </IconButton>
        <div className="content-wrapper">
          <div className="title">{t('launchNewDubbing')}</div>
          <img
            className="dubbing-logo"
            src={
              language === LANGUAGE.VN
                ? aivoiceDubbingViIcon
                : aivoiceDubbingEnIcon
            }
            alt="banner"
          />
          <div className="detail">
            <div className="noti">{t('youReceivedGift')}</div>
            <div className="content">
              <div className="content-gift">{`${t(
                giftDuration,
              )} ${giftUnit}`}</div>
              <div className="content-use">{t('giftDubbingContent')}</div>
              <div className="content-gift">{t(dubbingGiftOrder)}</div>
            </div>
          </div>
          <Button
            variant="contained"
            color="white"
            className="button"
            onClick={handleRegisterNow}
          >
            {t('experienceNow')}
          </Button>
        </div>
      </DialogContent>
    </StyledDubbingGiftDialog>
  );
};

export default DubbingGiftBanner;
